import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { styled as muiStyled } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";

const StyledGridOverlay = muiStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

export const dataGridStyles = {
  '& .MuiDataGrid-cell': {
    fontSize: '12px',
    fontFamily: 'Averta-regular',
    color: '#081D37',
    borderTop: 'none'
  },
  '& .MuiDataGrid-columnHeader': {
    fontSize: '12px',
    fontFamily: 'Averta-regular',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Averta-Regular',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '0.17px',
    color: '#081D37',
  },
  '.MuiDataGrid-columnSeparator': {
    visibility: 'visible !important',
    opacity: '1 !important',
  },
  ".MuiDataGrid-columnSeparator--resizable:hover": {
    color: "rgb(0 0 0 / 40%)"
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none'
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none'
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  '& .MuiDataGrid-row--borderBottom .MuiDataGrid-columnHeader': {
    backgroundColor: '#EEEEEE',
  },
  '& .MuiDataGrid-row--borderBottom .MuiDataGrid-filler': {
    backgroundColor: '#EEEEEE',
  },
  '& .MuiDataGrid-row--borderBottom .MuiDataGrid-scrollbarFiller': {
    backgroundColor: '#EEEEEE',
  },
  '& .MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--borderTop': {
    borderTop: 'none',
  },
  '& .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row': {
    borderBottom: "0.5px solid #e0e0e0"
  },
  '.css-1jlz3st': {
    borderTop: 'none',
  },
};

function CustomNoRowsOverlay({ message }: { message: string }) {
  return (
    <StyledGridOverlay>
      <Box style={{ color: "#5A6874" }}>{message}</Box>
    </StyledGridOverlay>
  );
}

const DataGridTable = ({
  columns,
  rows,
  noDataMessage,
  sortModelValue,
  isRowHeightDyanmic,
  disableSorting = false,
  disableRowHover = false,
  enableRowAlternatingColors = false,
  hideVerticalScroll = false,
  isOptionsRow = false,
}: {
  columns: any;
  rows: any;
  noDataMessage: string;
  sortModelValue?: any;
  isRowHeightDyanmic?: boolean;
  disableSorting?: boolean;
  disableRowHover?: boolean; 
  enableRowAlternatingColors?: boolean;
  hideVerticalScroll?: boolean;
  isOptionsRow?: boolean;
}) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  useEffect(() => {
    if(sortModelValue) {
      setSortModel(sortModelValue as GridSortModel);
    }
  }, [sortModelValue]);
  const modifiedColumns = useMemo(() => {
    return columns.map((column: GridColDef) => ({
      ...column,
      sortable: !disableSorting ? column.sortable : false,
    }));
  }, [columns, disableSorting]);

  const dataGridStyleWithoutVerticalScroll = hideVerticalScroll ? {
    ...dataGridStyles, 
      "& .MuiDataGrid-scrollbar--vertical": {
      overflowY: "hidden",
      
    }, "& .MuiDataGrid-scrollbarFiller": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)"
    }
  } : dataGridStyles

  const dataGridStyleWithHover = disableRowHover
    ? {
        ...dataGridStyleWithoutVerticalScroll,
        '& .MuiDataGrid-row:hover': {
          backgroundColor: 'transparent',
        },
      }
    : dataGridStyleWithoutVerticalScroll;

  const dataGridStyleWithAlternatingRows = enableRowAlternatingColors
    ? {
        ...dataGridStyleWithHover,
        '& .MuiDataGrid-row:nth-of-type(even)': {
          backgroundColor: '#FAFAFA',
        },
        '& .MuiDataGrid-row:nth-of-type(odd)': {
          backgroundColor: '#ffffff',
        },
      }
    : dataGridStyleWithHover;

  return (
    <Box sx={{ position: "absolute", top: 0, bottom: 0, width: "100%" }}>
      <DataGrid
        rowHeight={isOptionsRow ? 30 : 36}
        slots={{
          noRowsOverlay: () => <CustomNoRowsOverlay message={noDataMessage} />,
          noResultsOverlay: () => <CustomNoRowsOverlay message={noDataMessage} />,
        }}
        rows={rows}
        columns={modifiedColumns}
        disableRowSelectionOnClick
        disableColumnMenu
        hideFooterPagination
        sx={dataGridStyleWithAlternatingRows}
        sortModel={sortModel}
        onSortModelChange={(newSortModel:GridSortModel) => {
          setSortModel(newSortModel)
        }}
        getRowHeight={() => isRowHeightDyanmic ? 'auto' : undefined}
      />
    </Box>
  );
};

export default DataGridTable;
