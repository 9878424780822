import { MenuItem, Select } from "@mui/material";
import { positionsFilter } from "../../../types";

const PositionsFilter = ({
  activeFilter,
  setActiveFilter,
  isSmallPanel
}: {
  activeFilter: string;
  setActiveFilter: React.Dispatch<React.SetStateAction<string>>;
  isSmallPanel: boolean;
}) => {
  const selectPositionType = (id: string) => {
    setActiveFilter(id);
  };
  return (
    <div className="positions-type-wrapper">
      {isSmallPanel ? (
        <Select
          value={activeFilter}
          onChange={(e) => selectPositionType(e.target.value as string)}
          displayEmpty
          sx={{
            padding: 0,
            minWidth: "128px",
            fontSize: '12px',
            overflow: 'hidden',
            '& .MuiSelect-select': {
              padding: '5px',
              fontSize: '12px',
              background: 'white',
            },
          }}
        >
          {Object.keys(positionsFilter).map((filter) => (
            <MenuItem key={filter} value={filter}>
              {filter}
            </MenuItem>
          ))}
        </Select>
      ) : (
        Object.keys(positionsFilter).map((filter) => {
          const isActive = activeFilter === filter ? "positions-type-active" : "";
          return (
            <button onClick={() => selectPositionType(filter)} className={`positions-type ${isActive}`} key={filter}>
              {filter.toString()}
            </button>
          );
        }))}
    </div>
  );
};

export default PositionsFilter;
