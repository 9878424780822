import { Modal, Box } from "@mui/material";
import { InstrumentMarketData } from "../../../../../types";
import { Contract } from "../../types";
import { useState, useEffect, useMemo } from "react";
import ContractChip from "./ContractChip";
import ModalTable from "./ModalTable";
import ModalFooter from "./ModalFooter";
import { modalBoxContainstyles } from "../../marketStyles";
import ModalHeader from "./ModalHeader";
import { DisplayUtil } from "../../../../../cqg-api/utils/DisplayUtil";
import { useRealTimeMarketData } from "../../../../../cqg-api/hooks/ServiceHooks";
import { mapContractData } from "../../../../../utils/utils";

interface MarketMobileModalProps {
  open: boolean;
  onClose: () => void;
  instrument?: InstrumentMarketData;
  contract: Contract[];
  openTradeWindow: (contract: Contract) => void;
}

const MarketMobileModal = ({ open, onClose, instrument, contract, openTradeWindow }: MarketMobileModalProps) => {
  const { realTimeMarketData } = useRealTimeMarketData();
  const [selectedContract, setSelectedContract] = useState<Contract | null>(null);

  const handleContractSelect = (selected: Contract) => {
    setSelectedContract(selected);
  };

  useEffect(() => {
    if (contract?.length > 0) {
      setSelectedContract(contract[0]);
    }
  }, [contract]);

  useEffect(() => {
    if (selectedContract) {
      setSelectedContract(mapContractData(selectedContract, realTimeMarketData) as Contract);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData]);

  const changeCell = (row: any) => {
    const color = row?.labelPriceNetChange < 0 ? "#C62828" : "#00695C";
    const sign = row?.labelPriceNetChange > 0 ? "+" : "";
    if (row?.labelPriceNetChange) {
      return (
        <div style={{ color }}>
          <span>
            {`${sign}${row?.labelPriceNetChange?.toFixed(2)} (${sign}${row?.labelPriceNetChangePercent?.toFixed(2)}%)`}
          </span>
        </div>
      );
    }

    return <span></span>;
  };
  const yesterdaySettlement = useMemo(
    () =>
      parseFloat(
        DisplayUtil.toDisplayPrice(selectedContract?.yesterdaySettlement ?? 0, selectedContract as any)?.toString() ||
          "",
      ) || 0,
    [selectedContract],
  );

  const rowData = useMemo(
    () => [
      {
        labels: ["Last Price", "Change", "Volume"],
        values: [
          DisplayUtil.toDisplayPrice(selectedContract?.lastPrice as number, selectedContract as any),
          changeCell(selectedContract),
          selectedContract?.labelTotalVolume,
        ],
      },
      {
        labels: ["Open", "Prior Settle", "High", "Low"],
        values: [
          selectedContract?.labelOpen,
          yesterdaySettlement,
          selectedContract?.labelHigh,
          selectedContract?.labelLow,
        ],
      },
    ],
    [yesterdaySettlement, selectedContract],
  );
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalBoxContainstyles}>
        <ModalHeader instrumentName={instrument?.name} onClose={onClose} />
        <ContractChip
          contract={contract}
          selectedContract={selectedContract}
          handleContractSelect={handleContractSelect}
        />
        {selectedContract && (
          <>
            <ModalTable rowData={rowData} />
            <hr style={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />
            <ModalFooter openTradeWindow={() => openTradeWindow(selectedContract)} />
          </>
        )}
      </Box>
    </Modal>
  );
};

export default MarketMobileModal;
