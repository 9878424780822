import * as React from "react";
import { useEffect } from "react";
import { WatchListMode } from "./types";
import { useSelector } from "react-redux";
import watchlistSelectors from "../../../../../redux/watchlist/watchlistSelector";

type WatchlistManagerProps = {
  watchlistMode: WatchListMode;
  onWatchListModeChange: (watchlistMode: WatchListMode) => void;
};

const WatchlistManager: React.FC<WatchlistManagerProps> = ({ watchlistMode, onWatchListModeChange }) => {
  const isWatchlistEditMode = useSelector(watchlistSelectors.getIsEdit);

  useEffect(() => {
    if (isWatchlistEditMode) {
      onWatchListModeChange(WatchListMode.Edit);
    } else {
      onWatchListModeChange(WatchListMode.Creation);
    }
  }, [isWatchlistEditMode, onWatchListModeChange]);

  return (
    <>
      <div className="widget-dialog-description">
        {watchlistMode === WatchListMode.Creation ? "Create New Watchlist" : "Update Watchlist"}
      </div>
    </>
  );
};

export default WatchlistManager;
