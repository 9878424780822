import React from 'react';
import { IconButton, Menu, MenuItem } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface ProfileSectionProps {
  userName: string;
  profileInitial: string;
  open: boolean;
  anchorShow: HTMLElement | null;
  handleAccountClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  handleLogoutClick: () => void;
  handleAccountSettingsClick: () => void;
}

const ProfileSection = ({
  userName,
  profileInitial,
  open,
  anchorShow,
  handleAccountClick,
  handleClose,
  handleLogoutClick,
  handleAccountSettingsClick,
}:ProfileSectionProps) => (
  <div className="profile-section">
    <div className="profile-icon">{profileInitial}</div>
    <div className="profile-text">
      <div className="profile-sec-top" onClick={handleAccountClick}>
        <span className="profile-name">{userName}</span>
        <IconButton className="dropdown-button" sx={{ padding: 0, fontSize: 'inherit', '& svg': { fontSize: '1.3rem' } }}>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
      <div className="profile-sec-bottom">
        <span>Practice Mode</span>
      </div>
    </div>
    <Menu
      anchorEl={anchorShow}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      PaperProps={{ style: { width: '40ch', marginTop: '10px' } }}
      className="menu-paper"
    >
      <div className="account-section" style={{ display: 'flex', padding: '0px 12px' }}>
        <div className="profile-icon">{profileInitial}</div>
        <div className="profile-text">
          <span className="profile-name">{userName}</span>
          <div className="acc-sec">
            <span>Challenge account</span>
          </div>
        </div>
      </div>
      <hr className="divider-menu" />
      <MenuItem onClick={handleAccountSettingsClick}>
        <span className="menu-item-text">Account Settings</span>
      </MenuItem>
      <MenuItem onClick={handleLogoutClick}>
        <span className="menu-item-text">Logout</span>
      </MenuItem>
    </Menu>
  </div>
);

export default ProfileSection;
