import { useCallback, useState } from "react";
import { Instrument } from "../../../../cqg-api/models/Instrument";
import useOrderCancel from "../hooks/useOrderCancel";
import { cancelOrders } from "../../orders/actions";
import LadderTable from "./LadderTable";
import { OrderState } from "../../../../cqg-api/models/OrderState";
import { Depth, StopLoss, TakeProfit } from "../types";
import { OrderSide, OrderType } from "../../../../types";
import ConfirmCancelDialog from "../../../shared/ConfirmCancelDialog";
import { CQGEnvironment } from "../../../../cqg-api/services/CQGEnvironment";
import { ordersPlacedOnDepth } from "../utils";
import { useOrdersData } from "../../../../OrdersDataProvider";

interface PriceLadderBookProps {
  centerDivRef: React.MutableRefObject<any>;
  priceLadderBookRef: React.MutableRefObject<any>;
  quantityValue: number;
  realTimeInstrument: Instrument;
  createOrderHandler: (dorderSide: OrderSide, orderType: OrderType, price: number | null) => Promise<void>;
  contract: Instrument;
  oco: {
    BUY: {
      takeProfit: TakeProfit;
      stopLoss: StopLoss;
    };
    SELL: {
      takeProfit: TakeProfit;
      stopLoss: StopLoss;
    };
  };
}

const PriceLadderBook = ({
  centerDivRef,
  priceLadderBookRef,
  quantityValue,
  realTimeInstrument,
  contract,
  createOrderHandler,
  oco,
}: PriceLadderBookProps) => {
  const { ordersList } = useOrdersData();
  const [draggedRowDepth, setDraggedRowDepth] = useState<Depth | null>(null);
  const { isDialogOpen, requestCancel, confirmCancel, closeDialog } = useOrderCancel();

  const handleCancelOrder = (orders: OrderState[]) => {
    cancelOrders(orders);
  };

  const handleCancelClick = (orders: OrderState[]) => {
    requestCancel(() => handleCancelOrder(orders));
  };

  const createOrderHandlerClick = (orderSide: OrderSide, orderType: OrderType, price: number | null) => {
    createOrderHandler(orderSide, orderType, price);
  };

  const handleDragStart = useCallback((dragStartDepth: Depth, index: number) => {
    setDraggedRowDepth(dragStartDepth);
  }, []);

  const handleDrop = useCallback(
    (newDepth: Depth, orderSide: OrderSide) => {
      // Skip processing if the drag and drop indices are the same or invalid
      if (!draggedRowDepth || draggedRowDepth.displayPrice === newDepth.displayPrice) {
        return;
      }

      // Find the matching order
      const matchingOrders = ordersPlacedOnDepth(ordersList, draggedRowDepth, orderSide);

      if (!matchingOrders) {
        console.warn("No matching order found for modification.");
        setDraggedRowDepth(null);
        return;
      }

      matchingOrders.forEach((matchingOrder) => {
        // Update order properties
        if (matchingOrder.stopPrice) {
          matchingOrder.displayStopPrice = Number(newDepth.displayPrice);
          matchingOrder.stopPrice = Number(newDepth.displayPrice);
        } else {
          matchingOrder.displayLimitPrice = Number(newDepth.displayPrice);
          matchingOrder.limitPrice = Number(newDepth.displayPrice);
        }

        // Attempt to modify the order
        try {
          CQGEnvironment.Instance.cqgService.modifyOrder(matchingOrder);
        } catch (error) {
          console.error("Failed to modify order:", { error, matchingOrder });
        }
      });

      // Reset the dragged row
      setDraggedRowDepth(null);
    },
    [draggedRowDepth, ordersList],
  );

  return (
    <>
      <LadderTable
        priceLadderBookRef={priceLadderBookRef}
        instrumentWithMarketData={realTimeInstrument}
        centerDivRef={centerDivRef}
        quantityValue={quantityValue}
        onDragStart={handleDragStart}
        onDrop={handleDrop}
        createOrderHandlerClick={createOrderHandlerClick}
        onCancelClick={handleCancelClick}
        oco={oco}
        contract={contract}
      />

      <ConfirmCancelDialog
        open={isDialogOpen}
        onClose={() => closeDialog()}
        onConfirm={confirmCancel}
        loading={false}
        title="Confirm Order Cancellation"
        message="Are you sure you want to cancel this orders?"
      />
    </>
  );
};

export default PriceLadderBook;
