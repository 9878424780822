import { useMemo } from "react";

const HeaderTable = ({ rows }: { rows: any }) => {
  const high = useMemo(() => rows?.[0]?.bidPrice, [rows]);
  const low = useMemo(() => rows?.[0]?.askQty, [rows]);
  const volume = useMemo(() => rows?.[0]?.labelTotalVolume, [rows]);
  const change = useMemo(() => rows?.[0]?.change, [rows]);

  return (
    <table className="mobile-options-table">
      <tr className="mobile-options-tr">
        <th>HIGH</th>
        <th>LOW</th>
        <th>VOLUME</th>
        <th>CHANGE</th>
      </tr>
      <tr className="mobile-options-tr mobile-options-tr--data">
        <td>{high}</td>
        <td>{low}</td>
        <td>{volume}</td>
        <td style={{ color: change < 0 ? "#C62828" : "#00695C" }}>{change?.toFixed(2)}</td>
      </tr>
    </table>
  );
};

export default HeaderTable;
