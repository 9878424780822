import { GridRenderCellParams } from "@mui/x-data-grid";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";

const RenderWatchlistCell = (params: GridRenderCellParams) => {
  const field = params.colDef.field;
  if (field === "labelPriceNetChange") {
    const color = params?.row?.[field] < 0 ? "red" : "green";
    return (
      <span style={{ color }}>
        {params?.row?.[field]
          ? `${params?.row?.[field]?.toFixed(2)} (${params?.row?.labelPriceNetChangePercent?.toFixed(2)}%)`
          : "-"}
      </span>
    );
  } else if (field === "lastPrice") {
    const color = params?.row?.[field] < 0 ? "red" : "green";
    return <span style={{ color }}>{DisplayUtil.toDisplayPrice(params?.row?.[field], params.row)}</span>;
  }
  return <span>{params?.row?.[field] ? `${params?.row?.[field]}` : "-"}</span>;
};

export default RenderWatchlistCell;
