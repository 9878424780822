import React, { useCallback, useState } from "react";
import Header from "./components/Header";
import Sidebar from "./components/SideMenu/SideMenu";
import Layout from "./components/Layout";
import useInitiateCQGEnvironment from "../../hooks/api/subscription";
import { useGetCurrentUser } from "../../hooks/api/authentication";
import { useWorkspaceManager } from "./components/dashboard/hooks/userWorkspaceManger";
import Dashboard from "./components/dashboard/Dashboard";
import { Widget } from "../../types";
import Notification from "../shared/notification/Notification";
import useAvailableProducts from "../widgets/availableProducts/useAvailableProducts";
import { AvailableProductsContext } from "../../App";

// import useManageReduxEvents from "./useManageReduxEvents";

const Home = () => {
  const currentUser = useGetCurrentUser();
  useInitiateCQGEnvironment(currentUser);
  const { availableProducts } = useAvailableProducts();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };
  const [newWidgetToAddToPanel, setNewWidgetToAddToPanel] = useState<Widget | null>(null);
  const [selectedComponent, setSelectedComponent] = useState<JSX.Element | null>(null);

  const {
    activeWorkspace,
    workSpaces,
    onDeleteWorkspace,
    onNewSpaceCreated,
    onActiveWorkSpaceChange,
    workspaceLoading,
    deleteWorkspaceLoading,
    onSetDefaultWorkspace,
    handleRenameWorkspace,
    updateWorkspaceLoaded,
    onWorkSpaceUpdated,
    onDuplicateWorkspace,
    workspaceTabsLimit,
  } = useWorkspaceManager(currentUser);

  const onAddNewWidgetToWorkSpaceRequestHandler = useCallback(
    (widget: Widget) => {
      if (workSpaces.length === 0) {
        Notification.error("You must create a workspace before adding a widget.");
      } else {
        setNewWidgetToAddToPanel(widget);
      }
    },
    [workSpaces],
  );

  // useManageReduxEvents(onAddNewWidgetToWorkSpaceRequestHandler);

  const handleNewWidgetProcessed = useCallback(() => {
    setNewWidgetToAddToPanel(null);
  }, []);

  const handleMenuItemClick = (component: JSX.Element | null) => {
    setSelectedComponent(component);
  };

  return (
    <AvailableProductsContext.Provider value={availableProducts}>
      <Layout>
        <Header />
        <main>
          <Sidebar
            isOpen={isSidebarOpen}
            onToggle={toggleSidebar}
            onAddNewWidgetToWorkSpaceRequest={onAddNewWidgetToWorkSpaceRequestHandler}
            activeWorkspace={activeWorkspace}
            onMenuItemClick={handleMenuItemClick}
          />
          {selectedComponent || (
            <Dashboard
              workspaceLoading={workspaceLoading}
              onDeleteWorkspace={onDeleteWorkspace}
              activeWorkspace={activeWorkspace}
              workSpaces={workSpaces}
              onNewWorkSpaceCreated={onNewSpaceCreated}
              onActiveWorkSpaceChange={onActiveWorkSpaceChange}
              deleteWorkspaceLoading={deleteWorkspaceLoading}
              onSetDefaultWorkspace={onSetDefaultWorkspace}
              handleRenameWorkspace={handleRenameWorkspace}
              updateWorkspaceLoaded={updateWorkspaceLoaded}
              onWorkSpaceUpdated={onWorkSpaceUpdated}
              onDuplicateWorkspace={onDuplicateWorkspace}
              onSidebarToggle={toggleSidebar}
              isSidebarOpen={isSidebarOpen}
              workspaceTabsLimit={workspaceTabsLimit}
              newWidgetToAddToPanel={newWidgetToAddToPanel}
              onNewWidgetProcessed={handleNewWidgetProcessed}
            />
          )}
        </main>
      </Layout>
    </AvailableProductsContext.Provider>
  );
};

export default Home;
