// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
  padding: 12px;
  width: 134px;
}
.userProfilePicture {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: #081D37;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userName {
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: left;
  white-space: nowrap;  
  font-family: Averta-Regular;
  color: var(--text-primary, #081D37);
}`, "",{"version":3,"sources":["webpack://./src/components/common/userinfo/userinfo.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,8BAA8B;EAC9B,4DAA4D;EAC5D,aAAa;EACb,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,2BAA2B;EAC3B,mCAAmC;AACrC","sourcesContent":[".userInfo {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  justify-content: space-between;\n  border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12));\n  padding: 12px;\n  width: 134px;\n}\n.userProfilePicture {\n  width: 32px;\n  height: 32px;\n  border-radius: 4px;\n  background-color: #081D37;\n  color: #fff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.userName {\n  font-size: 11px;\n  font-weight: 600;\n  line-height: 14px;\n  letter-spacing: 1px;\n  text-align: left;\n  white-space: nowrap;  \n  font-family: Averta-Regular;\n  color: var(--text-primary, #081D37);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
