import { useEffect, useMemo } from "react";
import { Product, User } from "../../types";
import { subscribeToInstruments, unsubscribeInstuments } from "../../utils/subscriptions";
import { Instrument } from "../../cqg-api/models/Instrument";
import { getStreamingAccount } from "../../utils/user";
import { CQGEnvironment } from "../../cqg-api/services/CQGEnvironment";

export const useSubscribeProducts = (products: Product[]) => {
  useEffect(() => {
    let resolvedInstruments: Instrument[] = [];
    subscribeToInstruments(products).then((symbols) => {
      resolvedInstruments = symbols;
    });
    return () => {
      unsubscribeInstuments(resolvedInstruments);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
};

const useInitiateCQGEnvironment = (currentUser: User) => {
  // Memoize the account object based on the currentUser dependency
  const cqgAccount = useMemo(() => {
    return getStreamingAccount(currentUser);
  }, [currentUser]);
  console.log('cqgAccount', cqgAccount);
  // Side effect to update CQGEnvironment when cqgAccount changes
  useEffect(() => {
    if (cqgAccount) {
      CQGEnvironment.setCQGAccountAuthInfo(cqgAccount);
      // console.log("initiated CQG environment and set an account on that");
    }
  }, [cqgAccount]); // Effect depends on cqgAccount

  return cqgAccount; // Optionally return cqgAccount if needed elsewhere
};

export default useInitiateCQGEnvironment;
