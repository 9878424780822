import { OrderStatus_Status } from "../../../cqg-api/proto/common/shared_1";

export const orderFiltersMobile = [
  { label: "Show All", orderStatusId: 0 },
  { label: "Working", orderStatusId: OrderStatus_Status.WORKING },
  { label: "Filled", orderStatusId: OrderStatus_Status.FILLED },
  { label: "Cancelled", orderStatusId: OrderStatus_Status.CANCELLED },
];

export const orderFiltersDesktop = [
  { label: "All", orderStatusId: 0 },
  { label: "Working", orderStatusId: OrderStatus_Status.WORKING },
  { label: "Filled", orderStatusId: OrderStatus_Status.FILLED },
  { label: "Cancelled", orderStatusId: OrderStatus_Status.CANCELLED },
];
