export const menuStyles = {
  fontSize: "14px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontFamily: "Averta-Regular",
  minHeight: "32px",
};

export const checkIconStyles = { height: "20px", width: "20px", color: "#006EB6" };

export const selectStyles = {
  padding: 0,
  fontSize: "14px",
  overflow: "hidden",
  fontFamily: "Averta-Regular",
  "& .MuiSelect-select": {
    padding: "5px 5px 5px 10px",
    fontSize: "14px",
    background: "white",
    width: "100%",
    display: "flex",
    alignItems: "center"
  },
};

export const ContractChipStyles = {
  cursor: "pointer",
  fontFamily: "Averta-Regular",
  padding: "5px",
  fontSize: "13px",
};

export const modalTableContainerStyles = {
  maxHeight: "400px",
  overflowY: "auto",
  padding: "0 16px",
  boxShadow: "none",
  width: "auto",
};

export const modalTableRowStyles = { height: "24px", borderBottom: "none" };

export const modalTableCellLabelStyles = { padding: "4px 8px", borderBottom: "none" };
export const modalTableCellValuesStyles = { padding: "2px 8px", borderBottom: "none" };

export const modalTableTypographyStyles = { fontFamily: "Averta-Regular", fontSize: "12px" };

export const modalBoxContainstyles = {
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  bgcolor: "background.paper",
  paddingTop: 0,
  border: "1px solid white",
  height: "296px",
};

export const modalBoxHeadingStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 16px",
};
export const modalTypographyHeadingStyles = {
  fontWeight: 600,
  fontFamily: "Averta-Regular",
  color: "#081D37",
  letterSpacing: "1.5px",
  fontSize: "13px",
  lineHeight: "20px",
  textTransform: "uppercase",
};
