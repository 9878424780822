import { useEffect, useState } from "react";
import { SimulatorOptionsControllers } from "../../cqg-api/controllers/SimulatorOptionsControllers";
import { Instrument } from "../../cqg-api/models/Instrument";

const useStrikePrice = (instrument: Instrument) => {
  const [controller, setController] = useState<SimulatorOptionsControllers | null>(null);

  useEffect(() => {
    const newController = new SimulatorOptionsControllers({
      addOrUpdate: (contractMetadata) => {},
      instruments: instrument,
    });
    setController(newController);
  }, [instrument]);

  useEffect(() => {
    if (controller && instrument) {
      controller.onShowOptions({ show: true, instrument });
    }
  }, [controller, instrument]);
  return {
    controller
  }
};

export default useStrikePrice;
