import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Select,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { TradeLog, TradeFormValues } from "../../../../hooks/api/types";
import SelectField from "./SelectField";
import { Close, Edit } from "@mui/icons-material";
import { getOrderDuration, getOrderType } from "../../../../utils/utils";
import { useForm, Controller } from "react-hook-form";
import ControlledCheckboxGroup from "../tradePlan/CheckboxGroup";
import TechnicalApproach from "../tradePlan/TechnicalApproach";
import { OrderType, OrderSide } from "../../../../types";
import { StyledCancelButton, StyledSaveButton } from "./styles";
import { FUNDAMENTAL_OPTIONS, MARKET_TREND_OPTIONS, RISK_REWARD_RATIO_OPTIONS } from "../tradePlan/types";

interface EditTradeFormProps {
  trade: TradeLog;
  open: boolean;
  onClose: () => void;
  onSave: (formData: TradeFormValues) => void;
  initialData?: TradeFormValues;
  sideType?: string;
  title?: string;
  loading?: boolean;
  saveText?: string;
  isMobileView?: boolean;
}
const typographyStyles = {
  width: "100px",
  height: "28px",
  fontFamily: "Averta-Regular",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "175%",
  letterSpacing: "0.15px",
  color: "#081D37",
  flex: "none",
  order: 0,
  flexGrow: 0,
};

const labelStyle = {
  margin: "0 auto",
  width: "100%",
  height: "30px",
  fontFamily: "Averta-Regular",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  display: "flex",
  alignItems: "left",
  letterSpacing: "0.17px",
  color: "rgba(8, 29, 55, 0.87)",
  flex: "none",
  order: 0,
  flexGrow: 1,
};

const EditTradeForm: React.FC<EditTradeFormProps> = ({
  trade,
  open,
  onClose,
  onSave,
  initialData,
  sideType,
  title,
  saveText,
  loading,
  isMobileView,
}) => {
  const getOrderValues = useCallback(
    (side: string) => {
      const order = side === OrderSide.Buy ? trade.buyOrder : trade.sellOrder;
      return {
        id: order?.id,
        side: side.toUpperCase(),
        quantity: trade.qty || 0,
        type: order?.orderType ? getOrderType(order?.orderType).toString() : "",
        limitPrice: order?.limitPrice || "",
        stopPrice: order?.stopPrice || "",
        timeInForce: order?.duration ? getOrderDuration(order?.duration).toString() : "",
        useStopLoss: order?.tradeLogStopLoss || "",
        trend: order?.tradeLogTrend || "",
        ratio: order?.tradeLogRatio || "",
        approach: order?.tradeLogApproach || "",
        approachFundamentalAnalysis: order?.tradePlanApproachFundamentalAnalysis || [],
        approachTechnicalChartType: order?.tradePlanTechnicalChartType || "",
        approachTechnicalIndicator: order?.tradePlanTechnicalIndicator || [],
        approachTechnicalIndicatorOther: order?.tradePlanTechnicalIndicatorOther || "",
        approachTechnicalType: order?.tradePlanTechnicalType || "",
        comments: order?.comments || "",
      };
    },
    [trade.buyOrder, trade.qty, trade.sellOrder],
  );

  const defaultValues = getOrderValues(sideType || OrderSide.Buy);
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: defaultValues,
  });

  const [formData, setFormData] = useState<TradeFormValues>(defaultValues);

  const [approach, setApproach] = useState<string>(defaultValues.approach);
  const [type, setType] = useState(defaultValues.approachTechnicalType || "");

  useEffect(() => {
    if (sideType) {
      setFormData(getOrderValues(sideType));
    }
  }, [getOrderValues, sideType, trade]);

  const labelStyles = {
    fontFamily: "Averta-Regular",
    fontWeight: 400,
    fontSize: "16px",

    letterSpacing: "0.15px",
    color: "#5A6874",
  };

  const valueStyles = {
    fontFamily: "Averta-Regular",
    fontWeight: 400,
    fontSize: "16px",

    letterSpacing: "0.15px",
    color: "#25323C",
  };

  const valueStylesSide = {
    ...valueStyles,
    color: sideType === OrderSide.Sell ? "#D32F2F" : sideType === OrderSide.Buy ? "#00695C" : "#000",
  };
  const fieldData = [
    { label: "Side", value: formData.side, valueStyle: valueStylesSide },
    { label: "Quantity", value: formData.quantity },
    { label: "Type", value: formData.type },
    ...(formData.type === OrderType.Lmt || formData.type === OrderType.Stl
      ? [{ label: "Limit Price", value: formData.limitPrice }]
      : []),
    ...(formData.type === OrderType.Stp || formData.type === OrderType.Stl
      ? [{ label: "Stop Price", value: formData.stopPrice }]
      : []),
    { label: "TIF", value: formData.timeInForce },
  ];

  const onSubmit = (data: TradeFormValues) => {
    onSave(data);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        width: isMobileView ? "100%" : "452px",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .MuiDialog-container": {
          height: "95%",
        },
        "& .MuiPaper-root": {
          maxWidth: isMobileView ? "100%" : "600px",
          width: isMobileView ? "100%" : "calc(100% - 64px)",
          margin: isMobileView ? "16px" : "32px",
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: "4px 16px ",
          display: "flex",
          justifyContent: "space-between",
          background: "#FAFAFA",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          height: "36px",
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Edit sx={{ width: "16px", height: "16px" }} />
          <Typography
            variant="body1"
            color="initial"
            sx={{
              fontFamily: "Averta-Regular",
              fontWeight: 600,
              fontSize: "14px",
              letterSpacing: "1.1px",
              textTransform: "uppercase",
              color: "rgba(8, 29, 55, 0.87)",
            }}
          >
            {title ? title : "EDIT TRADE LOG"}
          </Typography>
        </Box>
        <IconButton onClick={onClose}>
          <Close sx={{ width: "20px", height: "20px" }} />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box>
            <Typography variant="subtitle2" mb={1} mt={2} sx={typographyStyles}>
              Order Details
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                padding: "16px 32px",
                background: "#FAFAFA",
                borderRadius: "4px",
                margin: "auto",
                width: isMobileView ? "100%" : "420px",
                gap: '4px'
              }}
            >
              {fieldData.map((field, index) => (
                <Grid
                  key={index}
                  container
                  sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "4px" }}
                >
                  <Typography variant="body1" sx={labelStyles}>
                    {field.label}
                  </Typography>
                  <Typography variant="body1" sx={field.valueStyle || valueStyles}>
                    {field.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>

            <Box mt={2}>
              <Typography variant="subtitle2" mb={1} sx={typographyStyles}>
                Trade Log
              </Typography>
              <Box
                sx={{
                  display: isMobileView ? "column" : "flex",
                  flexDirection: "row",
                  gap: "16px",
                  width: isMobileView ? "100%" : "420px",
                }}
              >
                <Controller
                  name="useStopLoss"
                  control={control}
                  render={({ field }) => (
                    <SelectField
                      label="Use Stop Loss"
                      value={field.value}
                      onChange={(e) => setValue("useStopLoss", e.target.value)}
                      options={["Yes", "No"]}
                      helperText="Choose whether to use stop loss."
                      testId="useStopLoss"
                    />
                  )}
                />

                <Controller
                  name="trend"
                  control={control}
                  render={({ field }) => (
                    <SelectField
                      label="Trend"
                      value={field.value}
                      onChange={(e) => setValue("trend", e.target.value)}
                      options={MARKET_TREND_OPTIONS}
                      helperText="Choose whether to use stop loss."
                      testId="trend"
                    />
                  )}
                />

                <Controller
                  name="ratio"
                  control={control}
                  render={({ field }) => (
                    <SelectField
                      label="Ratio"
                      value={field.value}
                      onChange={(e) => setValue("ratio", e.target.value)}
                      options={RISK_REWARD_RATIO_OPTIONS}
                      helperText="Choose whether to use stop loss."
                      testId="ratio"
                    />
                  )}
                />
              </Box>
            </Box>

            <Box mt={1}>
              <Typography variant="subtitle2" sx={labelStyle} mb={2}>
                Approach
              </Typography>
              <Controller
                name="approach"
                control={control}
                render={({ field }) => (
                  <Select
                    fullWidth
                    value={field.value}
                    onChange={(e) => {
                      setValue("approach", e.target.value);
                      setApproach(e.target.value);
                    }}
                    sx={{
                      height: "32px",
                      ".MuiSelect-select": {
                        padding: "6px 4px",
                      },
                    }}
                    data-testid="approach-select"
                  >
                    <MenuItem value="Fundamental">Fundamental</MenuItem>
                    <MenuItem value="Technical">Technical</MenuItem>
                  </Select>
                )}
              />
            </Box>

            <Box mt={3}>
              {approach === "Fundamental" && (
                <ControlledCheckboxGroup
                  control={control}
                  title="What is the foundation of your analysis?"
                  options={FUNDAMENTAL_OPTIONS}
                  name="approachFundamentalAnalysis"
                />
              )}
              {approach === "Technical" && (
                <TechnicalApproach control={control} setType={setType} type={type} isOther={true} />
              )}
            </Box>

            <Box mt={2}>
              <Typography variant="subtitle2" color="text.secondary" mb={0}>
                Notes
              </Typography>
              <Controller
                name="comments"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    multiline
                    rows={0}
                    value={field.value}
                    onChange={(e) => setValue("comments", e.target.value)}
                    sx={{
                      height: "42px",
                      ".MuiInputBase-root": {
                        height: "36px",
                      },
                      ".MuiInputBase-multiline": {
                        padding: 0,
                      },
                      ".MuiInputBase-input": {
                        padding: "6px",
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", gap: "8px",justifyContent: "flex-end", padding: "8px 16px" }}>
          <StyledCancelButton onClick={onClose} color="secondary">
            Cancel
          </StyledCancelButton>

          <StyledSaveButton loading={loading ?? false} type="submit">
            {loading ? (
              <>
                <CircularProgress size={16} sx={{ color: '#006EB6', marginRight: '8px' }} />
                Saving...
              </>
            ) : (
              saveText || 'Save Changes'
            )}
          </StyledSaveButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditTradeForm;
