import { Grid, Typography } from "@mui/material";
import { formatCurremcy } from "../../../../cqg-api/utils/lib";
import { useCollateralStatus } from "../../../../cqg-api/hooks/ServiceHooks";
import { useMemo } from "react";
import { styled } from '@mui/material/styles';

// Define the styles for labels and values
const labelStyle = {
  fontFamily: 'Averta-Regular',
  fontWeight: 600,
  fontSize: '10px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  color: 'rgba(8, 29, 55, 0.6)',
  display: 'flex',
  alignItems: 'center',
};

const valueStyle = {
  fontFamily: 'Averta-Regular',
  fontWeight: 600,
  fontSize: '11px',
  color: 'rgba(8, 29, 55, 0.87)',
  lineHeight: '1.6',
};
const WrapperGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  columnGap: '10px',
  '&:last-child': {
    marginLeft: '10px',
  },
});
const FundsInfo = () => {
  const { collateralStatus } = useCollateralStatus();

  const defaultStatus = useMemo(() => ({ purchasingPower: 0, positionMargin: 0 }), []);

  const displayStatus = useMemo(() => {
    const status = collateralStatus.length > 0 ? collateralStatus[0] : defaultStatus;
    return {
      purchasingPower: status.purchasingPower ?? 0,
      positionMargin: status.positionMargin ?? 0,
    };
  }, [collateralStatus, defaultStatus]);

  const formattedPurchasingPower = useMemo(() => formatCurremcy(displayStatus.purchasingPower), [displayStatus.purchasingPower]);
  const formattedPositionMargin = useMemo(() => formatCurremcy(displayStatus.positionMargin), [displayStatus.positionMargin]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: "12px",
        backgroundColor: "#006EB614",
        alignItems: 'center',
        margin: 0,
        width: "calc(100% + 0px)",
        '& .MuiGrid-item': {
          padding: 0,
        },
        rowGap: "6px",
        borderRadius: "6px"
      }}
    >
      {/* FUNDS */}
      <WrapperGrid>
        <Typography variant="body2" sx={labelStyle}>FUNDS</Typography>
        <Typography variant="h6" sx={valueStyle}>{formattedPurchasingPower}</Typography>
        <Typography variant="body2" sx={labelStyle}>PROFIT/LOSS</Typography>
        <Typography variant="h6" sx={valueStyle}>$0.00</Typography>
        <Typography variant="body2" sx={labelStyle}>MARGIN</Typography>
        <Typography variant="h6" sx={valueStyle}>{formattedPositionMargin}</Typography>
        <Typography variant="body2" sx={labelStyle}>AVAILABLE</Typography>
        <Typography variant="h6" sx={valueStyle}>$0.00</Typography>
      </WrapperGrid>
    </Grid>
  );
};

export default FundsInfo;
