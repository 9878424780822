import React from 'react';
import { Box, Typography } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

interface MenuItemProps {
  text: string;
  icon?: SvgIconComponent | string;
}

const MenuItem = ({ text, icon }: MenuItemProps) => (
  <Box
    display="flex"
    alignItems="center"
    sx={{
      color: '#25323C',
    }}
  >
    {typeof icon === 'string' ? (
      <img src={icon} alt={`${text} icon`}
        style={{ width: '15px', height: '15px', color: '#5A6874' }}
      />
    ) : (
      icon && React.createElement(icon, { sx: { fontSize: '15px', color: '#5A6874'}})
    )}
    <Typography 
      variant="body1"
      sx={{
        marginLeft: 1,
        color: '#081D37',
        fontSize: '13px',
        fontFamily:'Averta-Regular'
      }}
    >
      {text}
    </Typography>
  </Box>
);

export default MenuItem;
