import { useCallback, useEffect, useMemo, useState } from "react";
import { useFetchProducts } from "../../../hooks/api/product";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DataGridTable from "../../shared/DataGrid";
import { useOrdersList } from "../../../cqg-api/hooks/ServiceHooks";
import OrdersFilter from "./OrdersFilter";
import "./Orders.css";
import { OrderStatus_Status } from "../../../cqg-api/proto/common/shared_1";
import PriceIcon from "../availableProducts/Price";
import { cancelOrders, capitalizeFirstLetter, getOrderStatusLabel } from "./actions";
import clsx from "clsx";
import { OrderState } from "../../../cqg-api/models/OrderState";
import { OrderSide } from "../../../types";
import useResponsivePanel from "../../../hooks/useResponsivePanel";
import ConfirmDialog from "../../shared/ConfirmDialog";
import { Box } from "@mui/material";

const Orders = (props: { workspaceClassName: string; workspaceId: number }) => {
  const [activeFilter, setActiveFilter] = useState(0);
  const { loadProducts } = useFetchProducts();
  const { ordersList } = useOrdersList();
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const { panelRef, isSmallPanel } = useResponsivePanel(600);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const getNoDataMessage = useMemo(() => {
    switch (activeFilter) {
      case 0: return "No orders to display";
      case OrderStatus_Status.WORKING: return "No working orders";
      case OrderStatus_Status.FILLED: return "No filled orders";
      case OrderStatus_Status.CANCELLED: return "No cancelled orders";
      default: return "No data available";
    }
  },[activeFilter]);

  const handleCancelOrder = useCallback((order: OrderState) => {
    cancelOrders([order]);
  }, []);

  const hasWorkingOrders = useMemo(() => {
    return ordersList.some(order => 
      order.status === OrderStatus_Status.WORKING &&
      (activeFilter === 0 || Number(order.status) === activeFilter)
    );
  }, [ordersList, activeFilter]);

  const handleCancelAllOrders = useCallback(() => {
    setLoading(true);
    try {
      cancelOrders(ordersList);
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  }, [ordersList]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "side",
        headerName: "Side",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "order-table--header",
        renderCell: (params) => (
          <span
            className={clsx({
              "order-side-value--sell": params.value === OrderSide.Sell,
              "order-side-value--buy": params.value === OrderSide.Buy,
            })}
          >
            {params.value}
          </span>
        ),
      },
      {
        field: "symbol",
        headerName: "Symbol",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "order-table--header",
      },
      {
        field: "mo",
        headerName: "Mo",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "order-table--header",
      },
      {
        field: "strike",
        headerName: "Strike",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "order-table--header",
        renderCell: (params) => <span>{params.value === 0 ? "" : params.value}</span>,
      },
      {
        field: "cp",
        headerName: "C/P",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "order-table--header",
      },
      {
        field: "qty",
        headerName: "Qty",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "order-table--header",
      },
      {
        field: "leaves",
        headerName: "Leaves",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "order-table--header",
      },
      {
        field: "type",
        headerName: "Type",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "order-table--header",
      },
      {
        field: "fillPX",
        headerName: "Fill PX",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "order-table--header",
      },
      {
        field: "limitPX",
        headerName: "Limit PX",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "order-table--header",
      },
      {
        field: "stopPX",
        headerName: "Stop PX",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "order-table--header",
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 110,
        sortable: true,
        headerClassName: "order-table--header",
        renderCell: (params) => (
          <span
            className={clsx({
              "order-status-value": true,
              "order-status-value--expired":
                params.value === OrderStatus_Status.EXPIRED ||
                params.value === OrderStatus_Status.REJECTED ||
                params.value === OrderStatus_Status.CANCELLED,
              "order-status-value--working": params.value === OrderStatus_Status.WORKING,
            })}
          >
            {capitalizeFirstLetter(getOrderStatusLabel(OrderStatus_Status, params.value))}
          </span>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        minWidth: 120,
        sortable: false,
        headerClassName: "order-table--header",
        renderCell: (params: GridRenderCellParams) => {
          const isWorkingOrder = params.row.status === OrderStatus_Status.WORKING;
          return (
            <button className="starIcon">
              <div className="icon">
                <PriceIcon />
              </div>
              {isWorkingOrder && (
                <>
                  <Box
                    sx={{
                      width: '2px',
                      height: '18px',
                      background: 'rgba(0, 0, 0, 0.12)',
                    }}
                  />
                  <div className="icon" onClick={() => handleCancelOrder(params.row.order)}>
                    CANCEL
                  </div>
                </>
              )}
            </button>
          );
        },
      },
    ],
    [handleCancelOrder],
  );

  const dataRows = useMemo(() => {
    return ordersList
      .map((order, i) => ({
        id: i,
        side: order.displaySide,
        symbol: order.displayContract,
        mo: order.month,
        strike: order.strikePrice,
        cp: order.putCall,
        qty: order.displaySize,
        leaves: order.displayUnfilledSize,
        type: order.displayType,
        fillPX: order.displayAvgFillPrice,
        limitPX: order.displayLimitPrice,
        stopPX: order.displayStopPrice,
        status: order.status,
        order: order,
      }))
      .filter((order) => activeFilter === 0 || Number(order.status) === activeFilter);
  }, [ordersList, activeFilter]);

  return (
    <>
      <div ref={panelRef} className="widget-parent-container">
        <div className="draggableCancelSelector widget-grid-container order-container">
          <div className="order-header">
            <OrdersFilter activeFilter={activeFilter} setActiveFilter={setActiveFilter} isSmallPanel={isSmallPanel}/>
            <div className={`cancel-all-orders ${!hasWorkingOrders ? "disabled" : ""}`}
              onClick={hasWorkingOrders ? () => setOpenDialog(true) : undefined}
            >
              CANCEL ALL
            </div>
          </div>
          <div className="order-grid-panel">
            <DataGridTable columns={columns} rows={dataRows} noDataMessage={getNoDataMessage} />
          </div>
        </div>
      </div>
      {openDialog && 
        <ConfirmDialog
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          onDelete={handleCancelAllOrders}
          loading={loading}
          confirmText="Are you sure you want to cancel all orders"
        />
      }
    </>
  );
};

export default Orders;
