import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useRealTimeMarketData } from './cqg-api/hooks/ServiceHooks';
import { Instrument } from './cqg-api/models/Instrument';


interface ContextProps {
  realTimeMarketData: Instrument[];
}

const defautContextProps: ContextProps = {
  realTimeMarketData: [],
};

const MarketDataContext = createContext<ContextProps>(defautContextProps);

export const MarketDataProvider = ({ children }: { children: ReactNode }) => {
  const { realTimeMarketData } = useRealTimeMarketData();

  const contextValue = useMemo(() => ({
    realTimeMarketData,
  }), [realTimeMarketData]);

  return (
    <MarketDataContext.Provider value={contextValue}>
      {children}
    </MarketDataContext.Provider>
  );
};

export const useMarketData = () => {
  const context = useContext(MarketDataContext);
  return context;
};
