import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import SelectContract from "../../availableProducts/SelectContract";
import RenderColorCell from "../../availableProducts/RenderColorCell";
import { DisplayUtil } from "../../../../cqg-api/utils/DisplayUtil";
import { IconButton } from "@mui/material";
import { ChartIcon, MenuMoreVertIcon } from "../../../../images/Icons";
import { Widget, WidgetType } from "../../../../types";
import { setChartWidgetValue } from "../../../../redux/products/chartWidget";
import { useDispatch } from "react-redux";

function changeCell() {
  return (params: GridRenderCellParams) => {
    const stateOfRow = params.row.monthlyContract;
    const color = stateOfRow?.labelPriceNetChange < 0 ? "#C62828" : "#00695C";
    if (stateOfRow?.labelPriceNetChange) {
      return (
        <span
          style={{ color }}
        >{`${stateOfRow?.labelPriceNetChange?.toFixed(2)} (${stateOfRow?.labelPriceNetChangePercent?.toFixed(2)}%)`}</span>
      );
    }
    return <span></span>;
  };
}

const useHeaderGridColumns = (handleRowStateChange: (id: string, contract: any) => void) => {
  const dispatch = useDispatch();
  const renderChangeCell = changeCell();
  const openChartForInstrument = useCallback(
    (params: GridRenderCellParams) => {
      const { row } = params;
      const stateOfRow = row.monthlyContract;

      const selectedInstrument = stateOfRow
        ? row.monthlyContracts.find((contract: any) => contract.displayName === stateOfRow.displayName)
        : row.monthlyContracts[0];

      if (selectedInstrument) {
        const chartWidgetValue: Widget = {
          key: 3,
          title: WidgetType.Chart,
          widgetType: `${selectedInstrument.displayName} Chart`,
          contractIds: [selectedInstrument.displayName],
        };
        dispatch(setChartWidgetValue(chartWidgetValue));
      } else {
        console.log("Instrument not found");
      }
    },
    [dispatch],
  );

  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const [paramRowData, setParamRowData] = useState<null | any>(null);

  const openMarketMenu = useCallback((event: React.MouseEvent<HTMLElement>, params: any) => {
    setAnchorMenu(event.currentTarget);
    setParamRowData(params?.row?.monthlyContract);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorMenu(null);
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "UNDERLYING FUTURE",
        flex: 1,
        minWidth: 140,
        sortable: false,
        headerClassName: "options-table--header",
        renderCell: (params: GridRenderCellParams) => <span className="options-underlying-future">{params.row.cmeSymbolName}</span>,
      },
      {
        field: "monthlyContracts",
        headerName: "CONTRACT",
        flex: 1,
        minWidth: 100,
        sortable: false,
        headerClassName: "options-table--header",
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div className="options-select-contract">
              <SelectContract
                selectedState={params.row.monthlyContract}
                id={params.row.id}
                contracts={params.value}
                onSelect={handleRowStateChange}
              />
            </div>
          );
        },
      },
      {
        field: "lastPrice",
        headerName: "LAST PRICE",
        flex: 1,
        minWidth: 75,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const stateOfRow = params.row.monthlyContract;
          return <RenderColorCell value={DisplayUtil.toDisplayPrice(stateOfRow?.lastPrice, stateOfRow)} />;
        },
        headerClassName: "options-table--header",
      },
      {
        field: "change",
        headerName: "CHANGE ",
        flex: 1,
        minWidth: 75,
        sortable: false,
        renderCell: renderChangeCell,
        headerClassName: "options-table--header",
      },
      {
        field: "yesterdaySettlement",
        headerName: "PRIOR SETTLE",
        flex: 1,
        minWidth: 75,
        sortable: false,
        headerClassName: "options-table--header",
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span>
              {parseFloat(
                DisplayUtil.toDisplayPrice(
                  params.row.monthlyContract?.yesterdaySettlement ?? 0,
                  params.row.monthlyContract as any,
                )?.toString() || "",
              ) || null}
            </span>
          );
        },
      },
      {
        field: "bidPrice",
        headerName: "HIGH",
        flex: 1,
        minWidth: 75,
        sortable: false,
        headerClassName: "options-table--header",
        renderCell: (params: GridRenderCellParams) => {
          return <span>{params.row.monthlyContract?.labelHigh}</span>;
        },
      },
      {
        field: "bidQty",
        headerName: "LOW",
        flex: 1,
        minWidth: 75,
        sortable: false,
        headerClassName: "options-table--header",
        renderCell: (params: GridRenderCellParams) => {
          return <span>{params.row.monthlyContract?.labelLow}</span>;
        },
      },
      {
        field: "labelTotalVolume",
        headerName: "VOLUME",
        flex: 1,
        minWidth: 75,
        sortable: false,
        headerClassName: "options-table--header",
        renderCell: (params: GridRenderCellParams) => {
          return <span>{params.row.monthlyContract?.labelTotalVolume}</span>;
        },
      },
      {
        field: "selected",
        headerName: "ACTIONS",
        headerClassName: "options-table--header",
        flex: 1,
        minWidth: 130,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span className="starIcon">
              <IconButton onClick={() => openChartForInstrument(params)}>
                <ChartIcon />
              </IconButton>
              <IconButton onClick={(event) => openMarketMenu(event, params)} sx={{ padding: "5px 10px" }}>
                <MenuMoreVertIcon />
              </IconButton>
            </span>
          );
        },
      },
    ],
    [
      handleRowStateChange,
      openChartForInstrument,
      openMarketMenu,
      renderChangeCell,
    ],
  );

  return { columns, anchorMenu, paramRowData, handleCloseMenu };
};

export default useHeaderGridColumns;
