import React from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { orderFiltersMobile } from "../widgets/orders/types";
import CheckIcon from "@mui/icons-material/Check";
import { checkIconStyles } from "../widgets/availableProducts/marketStyles";

export const selectStyle = {
  minWidth: "fit-content",
  width: "50%",
  fontSize: "14px",
  padding: "5px",
  fontFamily: "Averta-Regular",
  "& .MuiSelect-select": {
    padding: "5px",
    fontSize: "14px",
    background: "white",
  },
};
export type FilterOrdersProps = {
  value: number;
  selectFilter: (event: number) => void;
};
const FilterOrders = ({ value, selectFilter }: FilterOrdersProps) => {
  const handleFilterSelection = (event: SelectChangeEvent<number>) => {
    selectFilter(event.target.value as number);
  };
  return (
    <Select
      sx={selectStyle}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      MenuProps={{
        PaperProps: {
          sx: {
            width: "100%",
          },
        },
      }}
      renderValue={(selected) => orderFiltersMobile.find((i) => i.orderStatusId === selected)?.label}
      value={value}
      onChange={handleFilterSelection}
    >
      {orderFiltersMobile.map((option) => (
        <MenuItem
          key={option.label}
          value={option.orderStatusId}
          sx={{
            fontSize: "14px",
            fontFamily: "Averta-Regular",
            display: "flex",
            justifyContent: "space-between",
            minHeight: "32px",
          }}
        >
          <span>{option.label}</span>
          {option.orderStatusId === value && <CheckIcon style={checkIconStyles} />}
        </MenuItem>
      ))}
    </Select>
  );
};

export default FilterOrders;
