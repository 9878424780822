import { formatCurremcy } from "../../../../cqg-api/utils/lib";
import { useCollateralStatus } from "../../../../cqg-api/hooks/ServiceHooks";
import { useMemo } from "react";

const FundsInfo = () => {
  const { collateralStatus } = useCollateralStatus();

  const defaultStatus = useMemo(() => ({ purchasingPower: 0, positionMargin: 0 }), []);

  const displayStatus = useMemo(() => {
    const status = collateralStatus.length > 0 ? collateralStatus[0] : defaultStatus;

    return {
      purchasingPower: status.purchasingPower ?? 0,
      positionMargin: status.positionMargin ?? 0,
    };
  }, [collateralStatus, defaultStatus]);

  const formattedPurchasingPower = useMemo(() => formatCurremcy(displayStatus.purchasingPower), [displayStatus.purchasingPower]);
  const formattedPositionMargin = useMemo(() => formatCurremcy(displayStatus.positionMargin), [displayStatus.positionMargin]);

  return (
    <>
      <div className="header-right">
        <div className="info-item">
          <span>FUNDS</span>
          <strong>{formattedPurchasingPower}</strong>
        </div>
        <div className="info-item">
          <span>PROFIT/LOSS</span>
          <strong>$0.00</strong>
        </div>
        <div className="info-item">
          <span>MARGIN</span>
          <strong>{formattedPositionMargin}</strong>
        </div>
        <div className="info-item">
          <span>AVAILABLE</span>
          <strong>$0.00</strong>
        </div>
      </div>
    </>
  );
};

export default FundsInfo;
