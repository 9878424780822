import { Instrument } from "../../../cqg-api/models/Instrument";

export type TakeProfit = {
  isSelected: boolean;
  tick: number | null;
  price: number | null;
  profit: number | null;
  profitPercentage: number | null;
};
export type StopLoss = {
  isSelected: boolean;
  tick: number | null;
  price: number | null;
  loss: number | null;
  lossPercentage: number | null;
};

export type SectionKeyType = "takeProfit" | "stopLoss" 

export enum SectionKey {
  TakeProfit = "takeProfit",
  StopLoss = "stopLoss"
};

export enum askOrBid {
  ask = 0,
  bid = 1,
}

export interface Depth {
  price: number;
  displayPrice: number | null;
  volume: number | null;
  askBid: askOrBid;
  instrumentDisplayName:string | undefined;
}

export const ocoInitial = {
  BUY: {
    takeProfit: {
      isSelected: false,
      tick: 0,
      price: 0,
      profit: 0,
      profitPercentage: 0,
    },
    stopLoss: {
      isSelected: false,
      tick: 0,
      price: 0,
      loss: 0,
      lossPercentage: 0,
    },
  },
  SELL: {
    takeProfit: {
      isSelected: false,
      tick: 0,
      price: 0,
      profit: 0,
      profitPercentage: 0,
    },
    stopLoss: {
      isSelected: false,
      tick: 0,
      price: 0,
      loss: 0,
      lossPercentage: 0,
    },
  },
};
