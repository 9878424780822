import React, { useState, useEffect } from "react";
import { Box, Select, MenuItem, FormControl, Typography, SelectChangeEvent } from "@mui/material";
import { StrikePriceOptionsController } from "../../cqg-api/controllers/StrikePriceOptionsController";
import { WidgetAction } from "../../types";

interface StrikePriceProps {
  putCall?: string;
  selectedStrike: number | null;
  onStrikeChange: (strikeDetails: { strike: number | null; callOption: any; putOption: any }) => void;
  onError?: (hasError: boolean) => void;
  hide?: boolean;
  widgetData?: { data?: { strikePrice?: number }; action?: WidgetAction };
  controller?: StrikePriceOptionsController;
  name: string;
}

const StrikePriceOptions: React.FC<StrikePriceProps> = ({
  putCall,
  selectedStrike,
  onStrikeChange,
  onError,
  hide,
  widgetData,
  controller,
  name,
}) => {
  const [atmStrike, setAtmStrike] = useState<number | null>(null);

  useEffect(() => {
    setAtmStrike(controller?.atmStrike ?? null);
  }, [controller?.atmStrike]);

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    const newStrike = event.target.value;
    const strikeAsNumber = typeof newStrike === "string" ? parseFloat(newStrike) : newStrike;

    if (!isNaN(strikeAsNumber)) {
      if (controller?.strikeOptions) {
        const selectedOption = controller?.strikeOptions.find((option) => option.strike === strikeAsNumber);

        if (selectedOption) {
          const { callOption, putOption } = selectedOption;
          onStrikeChange({
            strike: strikeAsNumber,
            callOption: putCall === "C" ? callOption : null,
            putOption: putCall === "P" ? putOption : null,
          });
        }
      }
    } else {
      console.warn("Selected strike value is not a number");
    }
  };

  const isWidgetStrikeAvailable =
    widgetData?.action === WidgetAction.Options ? false : widgetData?.data?.strikePrice !== undefined;

  return (
    <Box sx={{ display: `${hide ? "none" : "flex"}` }}>
      <FormControl fullWidth>
        <Select
          name={name}
          value={isWidgetStrikeAvailable ? widgetData?.data?.strikePrice : (selectedStrike ?? atmStrike ?? "")}
          displayEmpty
          sx={{
            width: "100%",
            height: "38px",
            color: "text.primary",
          }}
          onChange={handleChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
                overflow: "auto",
              },
            },
          }}
          disabled={isWidgetStrikeAvailable}
          data-testid={name}
        >
          {isWidgetStrikeAvailable ? (
            <MenuItem value={widgetData?.data?.strikePrice}>{widgetData?.data?.strikePrice}</MenuItem>
          ) : (
            controller?.strikeOptions?.map((strike: any) => {
              return (
                <MenuItem key={`strike-${strike.strike}`} value={strike.strike}>
                  {strike.strike}
                </MenuItem>
              );
            })
          )}
        </Select>
      </FormControl>

      {controller?.loadError && controller.loadStatus === "This product does not have an options contract" && (
        <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>
          {controller.loadStatus}
        </Typography>
      )}
    </Box>
  );
};

export default StrikePriceOptions;
