import { useEffect, useMemo, useState } from "react";
import { AssetProductsDictionary, ProductWithContracts } from "../../../types";

export const useFilteredAssets = (
  assetProductsDictionary: AssetProductsDictionary,
  searchQuery: string,
): AssetProductsDictionary => {
  return useMemo(() => {
    // Return the full object if the search query is empty
    if (searchQuery.trim() === "") {
      return assetProductsDictionary;
    }

    const inputSearchTextLower = searchQuery.toLowerCase();
    const filteredAssets: AssetProductsDictionary = {};

    for (const asset in assetProductsDictionary) {
      const products = assetProductsDictionary[asset].filter((item: ProductWithContracts) => {
        const nameInLowerCase = item.name?.toLowerCase() ?? "";

        // Check if the item matches the search query
        const matchesItem = nameInLowerCase.includes(inputSearchTextLower);

        // Check if any monthly contracts match the search query
        const matchesContracts = item.monthlyContracts?.some((contract) =>
          contract.displayName?.toLowerCase().includes(inputSearchTextLower),
        );

        return matchesItem || matchesContracts;
      });

      // Only keep assets that have matching products
      if (products.length > 0) {
        filteredAssets[asset] = products;
      }
    }

    return filteredAssets;
  }, [assetProductsDictionary, searchQuery]);
};

export function useFilteredAssetKeys(
  assetProductsDictionary: AssetProductsDictionary,
  selectedTreeItems: string[],
  isMobileView?: boolean,
  value?: string
): string[] {
  const [filteredAssetKeys, setFilteredAssetKeys] = useState<string[]>([]);

  useEffect(() => {
    if (isMobileView && selectedTreeItems[0] === value) {
      const inputSearchTextLower = selectedTreeItems[0].toLowerCase();
      const filteredAssets: AssetProductsDictionary = {};

      for (const asset in assetProductsDictionary) {
        const products = assetProductsDictionary[asset].filter((item) => {
          const nameInLowerCase = item.name?.toLowerCase() ?? "";

          // Check if the item matches the search query
          const matchesItem = nameInLowerCase.includes(inputSearchTextLower);
          // Check if any monthly contracts match the search query
          const matchesContracts = item.monthlyContracts?.some((contract) =>
            contract.displayName?.toLowerCase().includes(inputSearchTextLower)
        );
        
        return matchesItem || matchesContracts;
      });
      
      if (products.length > 0) {
          filteredAssets[asset] = products;
        }
      }
      const searchPrefix = inputSearchTextLower.slice(0, 2);
      const idsFromSymbols = Object.values(filteredAssets).flatMap((products) =>
        products.filter((item) => item.cmeSymbol.toLowerCase().startsWith(searchPrefix)).map((item) => item.id.toString())
      );

      setFilteredAssetKeys([...Object.keys(filteredAssets), ...idsFromSymbols]);
    } else {
      setFilteredAssetKeys([]);
    }
  }, [assetProductsDictionary, isMobileView, selectedTreeItems, value]);

  return filteredAssetKeys;
}
