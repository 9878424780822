// EmptyWorkspace.tsx
import React from "react";
import { Button } from "@mui/material";
import WidgetSelectionDialog from "../widget-selection/WidgetSelectionDialog";

interface EmptyWorkspaceProps {
  onAddWidgetClick: () => void;
  showWidgetSelectionDialog: boolean;
  onWidgetSelection: (widgets: any[]) => void;
  setShowWidgetSelectionDialog: (show: boolean) => void;
}

const EmptyWorkspace: React.FC<EmptyWorkspaceProps> = ({
  onAddWidgetClick,
  showWidgetSelectionDialog,
  onWidgetSelection,
  setShowWidgetSelectionDialog,
}) => (
  <div className="emptyWorkspace">
    <img
      src="/images/icons/workspace.svg"
      alt="Workspace"
      height="48"
      width="46"
      style={{ color: "#5A6874" }}
    />
    <h3>Your workspace is empty</h3>
    <p>Add a new widget from the menu on the left to get started!</p>
    {/* <Button variant="contained" onClick={onAddWidgetClick}>
      Add Widget
    </Button> */}
    {showWidgetSelectionDialog && (
      <WidgetSelectionDialog
        title="Select Widgets"
        show={true}
        onOkay={onWidgetSelection}
        onCancel={() => setShowWidgetSelectionDialog(false)}
        isShowTitle={false}
      />
    )}
  </div>
);

export default EmptyWorkspace;
