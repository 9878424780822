import React from "react";
import PlusIcon from "../../TradeTicketDrawer/PlusIcon";
import MinusIcon from "../../TradeTicketDrawer/MinusIcon";
import "./PriceLadder.css";

interface QuantityButtonProps {
  onClick: () => void;
  increment: boolean;
}

const QuantityButton = ({ onClick, increment }: QuantityButtonProps) => {
  return (
    <button type="button" onClick={onClick} className="price-ladder-minus-button">
      {increment ? <PlusIcon /> : <MinusIcon />}
    </button>
  );
};

export default QuantityButton;
