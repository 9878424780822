const prefix = "WATCHLIST";

export const watchlistActions = {
  WATCHLIST_GET_SUCCESS: `${prefix}_GET_SUCCESS`,
  WATCHLIST_SET_SUCCESS: `${prefix}_SET_SUCCESS`,
  WATCHLIST_CLEAR_SUCCESS: `${prefix}_CLEAR_SUCCESS`,
  WATCHLIST_REMOVE: `${prefix}_REMOVE`,
  WATCHLIST_ADD: `${prefix}_ADD`,
  WATCHLIST_DELETE: `${prefix}_DELETE`,

  dispatchSetWatchlist:
  (data) =>
  async (dispatch) => {
    dispatch({
      type: watchlistActions.WATCHLIST_SET_SUCCESS,
      payload: data,
    });
  },
  dispatchClearWatchlist:
  () =>
  async (dispatch) => {
    dispatch({
      type: watchlistActions.WATCHLIST_CLEAR_SUCCESS,
    });
  },
  dispatchRemoveProduct:
  (data) =>
  async (dispatch) => {
    dispatch({
      type: watchlistActions.WATCHLIST_REMOVE,
      payload: data,
    });
  },
  dispatchAddProduct:
  (data) =>
  async (dispatch) => {
    dispatch({
      type: watchlistActions.WATCHLIST_ADD,
      payload: data,
    });
  },
  dispatchDeleteWatchlist: (id) => async (dispatch) => {
    dispatch({
      type: watchlistActions.WATCHLIST_DELETE,
      payload: id,
    });
  },
};

export default watchlistActions;

