import { styled } from '@mui/system';
import { 
  Select, Button, FormControl,
} from '@mui/material';

export const redErrorColor = '#D32F2F';
export const defaultBorderColor = 'rgba(0, 0, 0, 0.23)';
// Custom styling using MUI's styled utility
export const StyledFormControl = styled(FormControl)({
  marginBottom: '16px',
});
export const toggleButtonStyles = { padding: '0px !important', display: 'flex', width: '96%', justifyContent: 'space-between', alignItems: 'center', marginLeft: '4%' };
export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'orderSide' && prop !== 'disabled',
})<{
  orderSide?: 'BUY' | 'SELL';
  disabled?: boolean;
}>(({ orderSide, disabled }) => ({
  fontFamily: "Averta-Regular",
  fontWeight: "700",
  lineHeight: "26px",
  letterSpacing: "1.5px",
  backgroundColor: orderSide === 'BUY'
    ? 'var(--buy-increase-main, #4CAF50)'
    : orderSide === 'SELL'
    ? 'var(--text-sell-decrease, #D32F2F)'
    : 'var(--action-disabledBackground, #081D371F)',
  color: orderSide === 'BUY'
  ? '#fff'
  : orderSide === 'SELL'
  ? '#fff'
  : '#081D3761',
  height: '42px',
  borderRadius: '8px',
  fontSize: '12px',
  textTransform: 'none',
  cursor: 'pointer',
  pointerEvents: 'auto',
  '&:hover': {
    backgroundColor: orderSide === 'BUY'
      ? '#388E3C'
      : orderSide === 'SELL'
      ? '#C62828'
      : 'var(--action-disabledBackground, #081D371F)',
  },
}));

export const StyledSelect = styled(Select)({
  fontFamily: "Averta-Regular",
  height: '38px',
  borderRadius: '4px',
  backgroundColor: '#fff',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#d1d1d1',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#1976d2',
  },
});

export const gridItemStyles = { paddingTop: '6px !important', height: '67px' };
export const inputStyles = {
  height: '36px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    backgroundColor: 'transparent',
    '& input': {
      padding: '6.5px 12px',
      border: 'none',
      textAlign: 'center',
      fontSize: '15px',
    },
    '& fieldset': {
      border: 'none',
    },
  },
};
export const tabStyles = {
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-around',
  },
  '& .MuiTab-root': {
    borderBottom: '1px solid #d1d1d1',
    fontFamily:'Averta-Regular',
    fontWeight: 600,
    fontSize: '14px',
    letterSpacing: "1.5px",
  },
  padding:'8px 0 0 16px',
  marginBottom: '12px'
};
