import React from 'react';
import { Menu, MenuItem as MuiMenuItem, Box, Typography } from '@mui/material';
import { ViewColumn, Watch } from '@mui/icons-material';

interface ActionMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
}

const menuItems = [
  { text: 'Price Ladder', icon: <ViewColumn /> },
  { text: 'Add to Watchlist', icon: <Watch /> },
];

const ActionMenu = ({ anchorEl, open, handleClose }: ActionMenuProps) => (
  <Menu
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    PaperProps={{
      sx: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 0,
        width: '180px',
        borderRadius: '4px',
        color: 'rgba(8, 29, 55, 0.56)'
      },
    }}
    anchorOrigin={{
      vertical: 'bottom',horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top', horizontal: 'right',
    }}
  >
    {menuItems.map((item) => (
      <MuiMenuItem key={item.text} onClick={handleClose} sx={{ display: 'flex', alignItems: 'center', padding: '6px 16px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            minWidth: '36px',
            flexShrink: 0,
          }}
        >
          {item.icon && (
            <Box sx={{ width: '36px', height: '24px' }}>
              {item.icon}
            </Box>
          )}

          <Typography
            sx={{
              fontFamily: 'Averta-Regular',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '21px',
              color: 'rgba(8, 29, 55, 0.87)',
            }}
          >
            {item.text}
          </Typography>
        </Box>
      </MuiMenuItem>
    ))}
  </Menu>
);

export default ActionMenu;
