import React, { useState } from "react";
import Header from "./components/Header";
import Sidebar from "./components/SideBar";
import Layout from "./components/Layout";
import useInitiateCQGEnvironment from "../../hooks/api/subscription";
import { useGetCurrentUser } from "../../hooks/api/authentication";
import { useWorkspaceManager } from "./components/dashboard/hooks/userWorkspaceManger";
import Dashboard from "./components/dashboard/Dashboard";
import { Widget } from "../../types";

const Home = () => {
  const currentUser = useGetCurrentUser();
  useInitiateCQGEnvironment(currentUser);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const toggleSidebar = () => {setIsSidebarOpen(prevState => !prevState);};
  const [newWidgetToAddToPanel, setNewWidgetToAddToPanel] = useState<Widget | null>(null);

  const {
    activeWorkspace,
    workSpaces,
    onDeleteWorkspace,
    onNewSpaceCreated,
    onActiveWorkSpaceChange,
    workspaceLoading,
    deleteWorkspaceLoading,
    onNewWorkspaceRequested,
    onSetDefaultWorkspace,
    handleRenameWorkspace,
    updateWorkspaceLoaded,
    onWorkSpaceUpdated,
    onDuplicateWorkspace,
    workspaceTabsLimit
  } = useWorkspaceManager(currentUser);

  const onRequestToAddNewWidgetHandler = (widget: Widget) => {
    if (activeWorkspace){
      setNewWidgetToAddToPanel(widget)
    }
  }

  return (
    <Layout>
      <Header />
      <main>
        <Sidebar isOpen={isSidebarOpen} onToggle={toggleSidebar} onRequestToAddNewWidget={onRequestToAddNewWidgetHandler}/>
        <Dashboard
          workspaceLoading={workspaceLoading}
          onDeleteWorkspace={onDeleteWorkspace}
          activeWorkspace={activeWorkspace}
          workSpaces={workSpaces}
          onNewWorkSpaceCreated={onNewSpaceCreated}
          onActiveWorkSpaceChange={onActiveWorkSpaceChange}
          deleteWorkspaceLoading={deleteWorkspaceLoading}
          onNewWorkspaceRequested={onNewWorkspaceRequested}
          onSetDefaultWorkspace={onSetDefaultWorkspace}
          handleRenameWorkspace={handleRenameWorkspace}
          updateWorkspaceLoaded={updateWorkspaceLoaded}
          onWorkSpaceUpdated={onWorkSpaceUpdated}
          onDuplicateWorkspace={onDuplicateWorkspace}
          onSidebarToggle={toggleSidebar}
          isSidebarOpen={isSidebarOpen}
          workspaceTabsLimit={workspaceTabsLimit}
          newWidgetToAddToPanel={newWidgetToAddToPanel}
          setNewWidgetToAddToPanel={setNewWidgetToAddToPanel}
        />
      </main>
    </Layout>
  );
};

export default Home;
