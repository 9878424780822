import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Box, DialogContent, IconButton, Paper, Table, TableBody, TableContainer, TableHead, Typography } from "@mui/material";
import { buttonStyles, StyledTableCell, StyledTableRow, typographyStyles } from "./style";
import moment from "moment";
import { useFetchEconodayEvent } from "../../../hooks/api/useEconodayCalender";
import { useEffect } from "react";
import { CloseIcon } from "../../../images/Icons";
import { TIME_ZONE } from "../../../types";
import "moment-timezone";

interface EventDialogProps {
    open: boolean;
    onClose: () => void;
    eventId?: number;
    isMobileView?: boolean
}

const EventDialog = ({
    open,
    eventId,
    onClose,
    isMobileView,
}: EventDialogProps) => {

  const { loadEconodayEvent, event } = useFetchEconodayEvent();

  useEffect(() => {
    if (eventId) loadEconodayEvent(eventId);
  }, [eventId, loadEconodayEvent]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
            width: isMobileView ? "100%" :"504px",
            maxWidth: isMobileView ? "100%" : "600px",
            height: "643px",
            borderRadius: "1px",
            margin: isMobileView? "8px" : "32px"
        },
      }}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "7px 16px", borderBottom: "1px solid #0000001F" }}>
        <Typography sx={typographyStyles.title}>
          EVENT DETAILS
        </Typography>
        <IconButton onClick={onClose} sx={{ color: "#081D37" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="event-dialog-content" sx={{ padding: '20px 16px' }}>
        <Box mt={2}>
          <Typography variant="h6" sx={typographyStyles.heading}>
            {event?.name}
          </Typography>
          <Typography sx={typographyStyles.subTitle}>
            Released on: {moment(event?.releasedOn).tz(TIME_ZONE).format("LLL") || null}
          </Typography>
        </Box>

        <Box sx={{ width: '100%' }} mt={2}>
          <Paper sx={{ width: "100%", overflow: "hidden", flexGrow: 1, boxShadow: "none" }}>
            <TableContainer sx={{ maxHeight: { xs: "100%", sm: "700px" }, overflow: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell />
                    <StyledTableCell>Actual</StyledTableCell>
                    <StyledTableCell align="left">Consensus</StyledTableCell>
                    <StyledTableCell align="left">Previous</StyledTableCell>
                    <StyledTableCell align="left">Revised</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody sx={{ height: "100%" }}>
                  {event?.econodayEventValues.map((value, index) => (
                    <StyledTableRow key={`${value.valueName}-${index}`}>
                      <StyledTableCell align="left">
                        {value.valueName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {[value.prefix, value.actual, value.suffix].join(' ')}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {[value.prefix, value.consensus, value.suffix].join(' ')}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {[value.prefix, value.previous, value.suffix].join(' ')}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {[value.prefix, value.revised, value.suffix].join(' ')}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        <Box mt={2}>
          <Typography sx={typographyStyles.header}>
            DESCRIPTION
          </Typography>
          <Typography sx={typographyStyles.content}>
            {event?.description}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography sx={typographyStyles.header}>
            DEFINITION
          </Typography>
          <Typography
            sx={typographyStyles.content}
            dangerouslySetInnerHTML={{
              __html: event?.definition 
                ? event.definition.includes('&quot;') 
                  ? event.definition.replace(/&quot;/g, '"') 
                  : event.definition 
                : "",
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button sx={buttonStyles} onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventDialog;
