import { Instrument } from "../../../cqg-api/models/Instrument";
import { OrderState } from "../../../cqg-api/models/OrderState";
import { OrderStatus_Status } from "../../../cqg-api/proto/common/shared_1";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
import { OrderSide } from "../../../types";
import { askOrBid, Depth } from "./types";

export const onlyNumbersRules = {
  pattern: {
    value: /^[0-9]*$/,
    message: "Please enter a valid number",
  },
};

const generateNewDepth = (price: number) => ({
  price,
  displayPrice: price,
  volume: null,
});

export function getDecimalPlaces(number: number): number {
  const numberStr = number?.toString();

  // Handle scientific notation efficiently
  const eIndex = numberStr?.indexOf("e");
  if (eIndex !== -1) {
    return Math.abs(Number(numberStr?.slice(eIndex + 1)));
  }

  // Handle decimal places for regular numbers
  const dotIndex = numberStr?.indexOf(".");
  return dotIndex !== -1 ? numberStr.length - dotIndex - 1 : 0;
}

export const generateDepthList = (depthList: any[], tickSize: number, count = 50, isBids?: boolean): Depth[] => {
  if (!Array.isArray(depthList) || depthList.length === 0) return [];

  const decimals = getDecimalPlaces(tickSize) ?? 0;
  const lastPrice = depthList[depthList.length - 1]?.displayPrice ?? 0;
  const result = [...depthList];

  for (let i = depthList.length; i < count; i++) {
    const updatedPrice = lastPrice + (isBids ? -tickSize : tickSize) * (i - depthList.length + 1);
    result.push(generateNewDepth(Number(updatedPrice?.toFixed?.(decimals))));
  }

  return result;
};

export function removeLeadingNulls(array: any[]) {
  let firstNonNullIndex = 0;

  // Find the index of the first non-null element
  while (firstNonNullIndex < array?.length && array[firstNonNullIndex]?.volume === null) {
    firstNonNullIndex++;
  }

  // Return the array starting from the first non-null index
  return array?.slice(firstNonNullIndex);
}

export function convertToKFormat(value: number | null) {
  if (!value) {
    return;
  }
  if (typeof value !== "number") return value; // Return the value as-is if it's not a number

  if (value >= 1000) {
    return (value / 1000).toFixed(3).replace(/\.0$/, "") + "K";
  }

  return value.toString(); // Return the number as a string if less than 1000
}

export function createPriceSeries(lastPrice: number | null, incremental: number, length = 100) {
  if (!lastPrice) return;

  const endPrice = lastPrice + (length / 2) * incremental;
  const decimals = getDecimalPlaces(incremental) ?? 0;
  return Array.from({ length }, (_, i) => {
    const price = endPrice - i * incremental;
    const formattedPrice = price.toFixed(decimals);
    return { displayPrice: formattedPrice, price: formattedPrice, volume: null };
  });
}

export const createPriceLookup = (items: any, decimals: any) =>
  items?.reduce((map: any, item: any) => {
    map[item.displayPrice.toFixed(decimals)] = { volume: item.volume, displayPrice: item.displayPrice };
    return map;
  }, {});

export const updatePriceSeries = (priceSeries: any, priceLookup: any, decimals: any, askBid: number) => {
  return priceSeries?.map((item: any) => {
    const key = Number(item.price).toFixed(decimals);
    if (priceLookup[key] && !item?.volume) {
      return {
        ...item,
        volume: priceLookup[key].volume,
        displayPrice: priceLookup[key].displayPrice.toFixed(decimals),
        askBid: askBid,
      };
    }
    return { ...item };
  });
};

export const generateDepth = (depthList: any[], instrumentDisplayName: string | undefined): Depth[] => {
  const sortedList = depthList;
  if (sortedList?.length) {
    const firstBid = sortedList?.find((depth) => depth?.askBid === askOrBid.bid);
    return sortedList.map((depth, index) => {
      if (Number(depth.displayPrice) > Number(firstBid?.displayPrice)) {
        return { ...depth, askBid: askOrBid.ask, instrumentDisplayName };
      } else {
        return { ...depth, askBid: askOrBid.bid, instrumentDisplayName };
      }
    });
  }
  return sortedList;
};

export const getSign = (isProfit: boolean, orderSide: OrderSide) =>
  orderSide === OrderSide.Buy ? (isProfit ? 1 : -1) : isProfit ? -1 : 1;

export const getTakeProfitAndStopPrice = (displayPrice: number, contract: Instrument, sign: 1 | -1, tick: number) =>
  DisplayUtil.rawStepPrice(Number(displayPrice), contract, sign * tick);

export const ordersPlacedOnDepth = (ordersList: OrderState[], depth: Depth, orderSide: OrderSide) => {
  return ordersList.filter(
    (i) =>
      (i.displayStopPrice === Number(depth.displayPrice) || i.displayLimitPrice === Number(depth.displayPrice)) &&
      i?.status === OrderStatus_Status.WORKING &&
      i?.displaySide === orderSide &&
      i?.displayContract === depth?.instrumentDisplayName,
  );
};

export const findMax = (items: any[], propertyName: string) => {
  if (!items || items.length === 0) return null;
  if (items.length === 1) return items[0][propertyName];
  return items.reduce((maxObj, obj) => (obj[propertyName] > maxObj[propertyName] ? obj : maxObj), items[0]);
};

export const calculateVolumePercentage = (volume: number, maxVolume: number) => {
  if (maxVolume === 0) {
    return "0%";
  }

  const percentage = (Number(volume) / maxVolume) * 100;
  return `${percentage}%`;
};

export const getValueFromNestedObject = (obj: any, key: any) => {
  if (!obj || typeof obj !== "object") {
    return undefined;
  }

  if (obj.hasOwnProperty(key)) {
    return obj[key];
  }

  for (const nestedKey of Object.keys(obj)) {
    const result: any = getValueFromNestedObject(obj[nestedKey], key);
    if (result !== undefined) {
      return result;
    }
  }

  return undefined;
};
