import React from 'react';

interface RenderColorCellProps {
  value: number;
}

const RenderColorCell: React.FC<RenderColorCellProps> = ({ value }) => {
  const color = value < 0 ? 'red' : 'green';
  return (
    <span style={{ color }}>
      {value?.toFixed(2)}
    </span>
  );
};

export default RenderColorCell;