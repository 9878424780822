import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import './confirmTradeDialog.css';
import { Instrument } from '../../../cqg-api/models/Instrument';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TradePlanManager from '../../../cqg-api/services/TradePlanManager';
import * as _ from "../../../vendors/underscore-esm";
import { useFetchMyTradePlan } from '../../../hooks/api/tradePlan';
import { CQGEnvironment } from '../../../cqg-api/services/CQGEnvironment';
import { Account } from '../../../cqg-api/models/Account';
import { Warning } from '../../../images/Icons';
import EditTradeForm from '../../home/components/TradeLog/EditTradeForm';
import { BuyOrderOrSellOrder, TradeLog } from '../../../hooks/api/types';
import * as Order2 from "../../../../src/cqg-api/proto/order_2";
import { getDuration, getOrderTypeLabel } from '../../../utils/utils';
import { OrderSide, OrderType } from '../../../types';

type ConfirmTradeDialogProps = {
  open: boolean;
  onClose: () => void;
  cancel: () => void;
  confirm: () => void;
  title: string;
  trade: {
    contractSize?: number | null;
    contract?: string;
    tickSize?: number;
    tickValue?: number;  
    lastPrice?: number | null;
    nationalValue?: number;
    marginCommited?: number | null;
    side?: string;
    quantity: number;
    orderType: string;
    limitPrice: number | null;
    tif: string;
    stopPrice: number | null;
    strikePrice?: number | null;
    putCall?: string;
    instrument: Instrument;
    account: Account;
    takeProfit?: number;
    stopLossPrice?: number;
  },
};
function formatNumber(num: number): string {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(num));
}

function hasNotionalValue( instrument: Instrument ): boolean {
  if(!instrument.assetClassName) return false;
  return instrument !== null && instrument.isFuture() && ['Interest Rates', 'FX'].indexOf(instrument.assetClassName) == -1
}

const getOrderType = (orderType: string) => {
  if (orderType === OrderType.Mkt) {
    return 'Market';
  }
  if (orderType === OrderType.Lmt) {
    return 'Limit';
  }
  if (orderType === OrderType.Stp) {
    return 'Stop';
  }
  if (orderType === OrderType.Stl) {
    return 'Stop Limit';
  }
}
const getPutCallText = (text: string) => {
  if (text === 'C') {
    return 'CALL';
  }
  if (text === 'P') {
    return 'PUT';
  }
}

const ConfirmTradeDialog = ({ open, onClose, cancel, confirm, trade, title }: ConfirmTradeDialogProps) => {

  const dialogTitle = title || 'Confirm Order';

  const [tradingTips, setTradingTips] = useState([]);
  const { myTradePlan, loadMyTradePlanAsync } = useFetchMyTradePlan();
  const [openTradeLogDialog, setOpenTradeLogDialog] = useState<boolean>(false);

  useEffect(() => {
    loadMyTradePlanAsync();
}, [loadMyTradePlanAsync]);

  const handleOnOk = () => {
    setOpenTradeLogDialog(true);
  }

  const onHandleClose = () => {
    setOpenTradeLogDialog(false)
  }

  useEffect(() => {
    if(trade && myTradePlan) {
      if(CQGEnvironment.Instance.selectedAccount) {
        CQGEnvironment.Instance.accountSummariesManager.updateAccountSummaryThrottled(CQGEnvironment.Instance.selectedAccount)
      }

      TradePlanManager.initializeTradePlan(myTradePlan, trade.account )
      const tips = TradePlanManager.checkForTradingTips( trade, null );
      setTradingTips(tips); 
    }

  }, [trade, myTradePlan]);

  const hasTradingTips = useCallback(() => {
      return tradingTips && _.keys(tradingTips).length > 0;
  }, [tradingTips]);

  const orderData = useMemo(() => {
    const tradeLog: TradeLog = {
      cqgAccountId: 0,
      cmeSymbolId: 0,
      memberId: 0,
      realizedProfitLoss: '',
      buyOrderId: 0,
      sellOrderId: 0,
      tradeTime: '',
      qty: 0,
      created_at: '',
      updated_at: '',
      instrumentId: 0,
      instrument: trade.instrument,
      id: 0,
    }
    if(trade && myTradePlan) {
      const order: BuyOrderOrSellOrder = {
        side: trade.side === OrderSide.Buy ? Order2.Order_Side.SIDE_BUY : Order2.Order_Side.SIDE_SELL,
        duration: getDuration(trade.tif),
        orderType: getOrderTypeLabel(trade.orderType),
        limitPrice: Number(trade.limitPrice).toString(),
        stopPrice: Number(trade.stopPrice).toString(),
        tradeLogApproach: myTradePlan?.approach,
        tradePlanApproachFundamentalAnalysis: myTradePlan.approachFundamentalAnalysis ? JSON.parse(myTradePlan.approachFundamentalAnalysis.toString()) : [],
        tradePlanTechnicalType: myTradePlan?.approachTechnicalType,
        tradePlanTechnicalChartType: myTradePlan?.approachTechnicalChartType,
        tradePlanTechnicalIndicator: myTradePlan.approachTechnicalIndicator ? JSON.parse(myTradePlan.approachTechnicalIndicator.toString()) : [],
        tradePlanTechnicalIndicatorOther: myTradePlan?.approachTechnicalIndicatorOther,
        tradeLogRatio: myTradePlan?.ratio,
        tradeLogTrend: myTradePlan?.trend,
        tradeLogStopLoss: myTradePlan?.useStopLoss
      }
      tradeLog.instrument = trade.instrument
      tradeLog.qty = trade.quantity
      tradeLog.sellOrder = trade.side === OrderSide.Sell ? order : undefined
      tradeLog.buyOrder = trade.side === OrderSide.Buy ? order : undefined    
    }

    return tradeLog;
  }, [trade, myTradePlan])

  return (
<>
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '444px',
          padding: '6px',
          borderRadius: '4px',
        },
        '& .MuiDialogContent-root': {
          width: '80%',
          alignSelf: 'center',
        },
        '& .MuiDialogTitle-root + .MuiDialogContent-root': {
          paddingTop: '16px',
        },
      }}
    >
    <DialogTitle className="dialogTitle">{dialogTitle}</DialogTitle>
    <DialogContent className='DialogContent'>
      <table className='table'>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="dialogTextValue highlight">{trade.contract}</td>
            <td className="dialogTextValue highlight" data-testid="dlg-trade-put-call">{getPutCallText(trade?.putCall || '')}</td>
          </tr>
          <tr>
            <td className="dialogText">Contract Size</td>
            <td className="dialogTextValue">{trade.contractSize}</td>
          </tr>
          <tr>
            <td className="dialogText">Tick Size</td>
            <td className="dialogTextValue">{trade.tickSize}</td>
          </tr>
          <tr>
            <td className="dialogText">Tick Value</td>
            <td className="dialogTextValue">${trade.tickValue}</td>
          </tr>
          <tr>
            <td className="dialogText">Last Price</td>
            <td className="dialogTextValue">${trade.lastPrice}</td>
          </tr>
          {trade.instrument && hasNotionalValue(trade.instrument) && !trade.putCall &&
          <tr>
            <td className="dialogText">Notional Value of Order</td>
            <td className="dialogTextValue">${formatNumber(Number(trade.instrument?.multiplier) * trade.quantity * Number(trade.instrument?.lastPrice))}</td>
          </tr>}
          {trade.instrument && !trade.putCall &&
          <tr>
            <td className="dialogText">Margin committed to trade</td>
            <td className="dialogTextValue">${formatNumber(Number(trade.instrument.marginInitialRate) * trade.quantity)}</td>
          </tr>}
          {!!trade.strikePrice &&
          <tr>
            <td className="dialogText">Strike Price</td>
            <td className="dialogTextValue" data-testid="dlg-trade-strike-price">{trade.strikePrice}</td>
          </tr>}
        </tbody>
      </table>
      <div className='divider'></div>
      <table className='table'>
        <tbody>
          <tr>
            <td className="dialogText">Side</td>
            <td  className="dialogTextValue" data-testid="dlg-trade-side" style={{ color: trade.side === OrderSide.Buy ? '#00695C' : '#C62828' }}>{trade.side}</td>
          </tr>
          <tr>
            <td className="dialogText">Quantity</td>
            <td className="dialogTextValue" data-testid="dlg-trade-quantity">{trade.quantity}</td>
          </tr>
          <tr>
            <td className="dialogText">Type</td>
            <td className="dialogTextValue" data-testid="dlg-trade-order-type">{getOrderType(trade.orderType)}</td>
          </tr>
            {trade.orderType === 'LMT' && (<tr>
              <td className="dialogText">Limit Price</td>
              <td className="dialogTextValue" data-testid="dlg-trade-limit-price">${trade.limitPrice}</td>
            </tr>)}
            {trade.orderType === 'STP' && (<tr>
              <td className="dialogText">Stop Price</td>
              <td className="dialogTextValue" data-testid="dlg-trade-stop-price">${trade.stopPrice}</td>
            </tr>)}
            {trade.orderType === 'STL' && (<><tr>
              <td className="dialogText">Limit Price</td>
              <td className="dialogTextValue" data-testid="dlg-trade-limit-price">${trade.limitPrice}</td>
            </tr>
            <tr>
              <td className="dialogText">Stop Price</td>
              <td className="dialogTextValue" data-testid="dlg-trade-stop-price">${trade.stopPrice}</td>
            </tr>
            </>)}
          <tr>
            <td className="dialogText">TIF</td>
            <td className="dialogTextValue" data-testid="dlg-trade-tif">{trade.tif}</td>
          </tr>
        </tbody>
      </table>
      {(trade.takeProfit || trade.stopLossPrice) && (
        <>
          <div className='divider'></div>
          <table className='table'>
            <tbody>
              {trade.takeProfit && (
                <tr>
                  <td className="dialogText">Take Profit</td>
                  <td className="dialogTextValue">{trade.takeProfit}</td>
                </tr>
              )}
              {trade.stopLossPrice && (
                <tr>
                  <td className="dialogText">Stop Loss</td>
                  <td className="dialogTextValue">{trade.stopLossPrice}</td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}
    </DialogContent>
    {hasTradingTips() && <div className='warning'>
        <Warning sx={{ width: "22px", height: "17px"}} />
        {Object.entries(tradingTips).map(([key, value]) => (
            <span className='warningMessage' key={key}>{value}</span>
        ))}
    </div>}
    <DialogActions>
      <Button data-testid="btnTradeDialogCancel" className='confirmTradeDialog' onClick={cancel} style={{ color: "#081D37DE" }}>Cancel</Button>
      <Button data-testid="btnTradeDialogConfirm" className='confirmTradeDialogSuccess' style={{ color: '#006EB6' }} onClick={confirm}>Confirm Order</Button>
    </DialogActions>
  </Dialog>
  {openTradeLogDialog && 
    <EditTradeForm trade={orderData} open={openTradeLogDialog} onClose={onHandleClose} onSave={confirm} sideType={trade.side} title="Update Your Trade Log for Your Order" saveText='Ok'/>
  }  
  </>
  )
};

export default ConfirmTradeDialog;
