import React, { useEffect, useMemo, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Grid, useMediaQuery } from "@mui/material";
import "./Trade.css";
import ConfirmTradeDialog from "./ConfirmTradeDialog";
import SelectedContractInfo from "./SelectedContractInfo";
import { OrderState } from "../../cqg-api/models/OrderState";
import { makeOrder } from "../../cqg-api/controllers/tradingTicketController";
import { Instrument } from "../../cqg-api/models/Instrument";
import { useSelector } from "react-redux";
import { subscribeToContracts } from "../../utils/subscriptions";
import { useRealTimeMarketData } from "../../cqg-api/hooks/ServiceHooks";
import workspacesSelectors from "../../redux/workspaces/workspacesSelector";
import { createOrderStateObject } from "./utils";
import StrikePriceOptions from "./StrikePriceOption";
import PutCallSelector from "./TradeComponent/PutCallSelector";
import {
  gridItemStyles,
  StyledButton,
  StyledFormControl,
  StyledBlueBorderButton,
  StyledRedTextButton,
  ButtonNamedStyled,
  inputStyleBordered,
} from "./styles";
import SideController from "./components/SideController";
import { IFormInput } from "./types";
import { useAvailableProductsContext } from "../../AvailableProductsProvider";
import { StrikePriceOptionsController } from "../../cqg-api/controllers/StrikePriceOptionsController";
import { InstrumentExt, OrderType, TradeTicketTab, WidgetAction } from "../../types";
import OrderTypeSelect from "./components/OrderType";
import TakeProfitandLoss from "../widgets/priceLadder/OrderSettingsDialog/TakeProfitAndLoss/TakeProfitandLoss";
import TimeInForce from "./components/TimeInForce";
import useCreateOrder from "./hooks/useCreateOrder";
import useOrderTypeChange from "./hooks/useOrderTypeChange";
import useHandleFutureOrOptionTabChange from "./hooks/useHandleFutureOrOptionTabChange";
import useCustomizeTradeTicketUI from "./hooks/useCustomizeTradeTicketUI";
import useHandleStrikeAndOptionChange from "./hooks/useHandleStrikeAndOptionChange";
import { useTradeProps } from "./hooks/useTradeProps";
import useAmendOrder from "./hooks/useAmendOrder";
import useCreateDefaultFormValues from "./hooks/useCreateDefaultFormValues";
import ContractSelect from "./components/ContractSelect";
import FuturesOptionsTab from "./components/FuturesOptionsTab";
import FormErrors from "./components/FormErrors";
import { PriceInputByTick } from "./components/PriceInputByTick";
import { DisplayUtil } from "../../cqg-api/utils/DisplayUtil";
import mobile from "is-mobile";
import { useTheme } from "@mui/material/styles";
import { useOneClickTrade } from "../../hooks/api/workspace";

type TradeFormProps = {
  contracts?: Instrument[];
  workspaceId: number;
  externalData: any;
  expired?: boolean;
  isMobile?: boolean;
  instrument?: Instrument;
  closeTrade?: () => void;
};

const TradeForm: React.FC<TradeFormProps> = (props) => {
  const workspaces = useSelector(workspacesSelectors.getWorkspaces);
  const sourceContract = props.instrument ? props.instrument : workspaces[props.workspaceId]?.ticketInstrument;
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm")) || mobile();
  const { getOneClickTradeAsync } = useOneClickTrade();
  
  const { monthlyContractsByDisplayNameMap } = useAvailableProductsContext();
  
  const monthlyContracts = useMemo(() => {
    let contract: InstrumentExt;
    if (sourceContract && sourceContract.isOption()) {
      contract = monthlyContractsByDisplayNameMap[sourceContract?.cqgSymbol];
    } else {
      contract = monthlyContractsByDisplayNameMap[sourceContract?.displayName];
    }

    return contract?.monthlyContracts ?? [];
  }, [sourceContract?.displayName, monthlyContractsByDisplayNameMap]);

  const [disableFuturesTab, setDisableFuturesTab] = useState<boolean | undefined>(undefined);
  const [disableOptionsTab, setDisableOptionsTab] = useState<boolean | undefined>(undefined);

  const { amendOrder, showCancelButton, showCancelOrderButton, canChangeInstrument, orderData, widgetData } =
    useAmendOrder(props.externalData);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: useCreateDefaultFormValues(sourceContract, orderData, amendOrder, props.externalData, widgetData),
  });

  const contractValue = watch("contract");
  const quantity = watch("quantity");
  const limitPrice = watch("limitPrice");
  const orderSide = watch("side");
  const orderType = watch("orderType");
  const rawLimitPrice = watch("rawLimitPrice");
  const rawStopPrice = watch("rawStopPrice");
  const putCallValue = watch("putCall");

  const [activeTab, setActiveTab] = useState("futures");
  const [createOrderState, setCreateOrderState] = useState<OrderState>();
  const [hasLoadError, setHasLoadError] = useState(false);

  const selectedInstrument = useMemo(() => {
    if (!monthlyContracts || monthlyContracts?.length === 0) return;

    let contract = monthlyContracts?.find((contract: Instrument) => contract.displayName === contractValue);

    // If no contract returned, then it may be closed or no longer valid, then return the first contract.
    if (!contract) {
      contract = monthlyContracts[0];
      setValue("contract", contract.displayName!);
    }

    return contract;
  }, [monthlyContracts, contractValue]);

  const controller = useMemo(() => {
    const newController = new StrikePriceOptionsController({
      addOrUpdate: (contractMetadata) => { },
      instruments: selectedInstrument!,
    });

    return newController;
  }, [selectedInstrument]);

  const [selectedContract, setSelectedContract] = useState<Instrument | null>(selectedInstrument!);

  const { isEnabledLmtPrice, isEnabledStpPrice, getDefaultLimitPrice, getDefaultStopPrice } = useOrderTypeChange(
    selectedContract,
    amendOrder,
    orderType,
    orderSide,
    setValue,
    selectedContract?.lastPrice,
    sourceContract?.lastPrice,
    props.externalData,
    widgetData,
  );
  
  const { showConfirmTradeDialog, setShowConfirmTradeDialog, createOrder, cancelOrder, hideTradeTicket } =
    useCreateOrder(props.workspaceId, props.closeTrade, props.isMobile);
  
    const { selectedStrike, setSelectedStrike, onStrikeChange, handleOptionChange } = useHandleStrikeAndOptionChange(
    putCallValue,
    setSelectedContract,
    setValue,
    controller,
    contractValue,
    widgetData
  );

  useHandleFutureOrOptionTabChange(
    selectedInstrument,
    selectedContract,
    limitPrice,
    activeTab,
    setValue,
    getDefaultLimitPrice,
    getDefaultStopPrice,
    putCallValue,
    selectedStrike,
    contractValue,
    reset,
  );

  useCustomizeTradeTicketUI(props.isMobile);

  useEffect(() => {
    if (sourceContract && sourceContract?.isOption()) {
      const contract = monthlyContracts.find((cont: Instrument) => cont.month === sourceContract.month)
      setValue("contract", contract?.displayName!);
      setActiveTab("options");
      setValue('putCall', sourceContract.putCallAbbrev());
      setSelectedStrike(sourceContract.strikePrice);
    } else {
      setValue("contract", sourceContract?.displayName);
    }
  }, [sourceContract, setValue, setActiveTab, setSelectedStrike]);

  useRealTimeMarketData();

  useEffect(() => {
    if (selectedInstrument && activeTab === TradeTicketTab.Futures) {
      subscribeToContracts([selectedInstrument.contractId] as number[]);
      setSelectedContract(selectedInstrument);
      setValue("limitPrice", 0);
    }
    if (activeTab === TradeTicketTab.Options) {
      setValue("limitPrice", 0);
    }
  }, [activeTab, selectedInstrument, setValue]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setActiveTab(newValue);
    if (newValue === "futures") {
      setSelectedStrike(null);
    }
    if (newValue === "options") {
      setSelectedContract(null);
    }
  };

  const setDefaultValues = () => {
    setValue('putCall', undefined);
    setValue('quantity', 0);
    setValue('side', undefined);
    setSelectedContract(null);
  }

  useEffect(() => {
    if (controller && selectedInstrument) {
      controller.onShowOptions({ show: true, instrument: selectedInstrument });
    }
  }, [controller, selectedInstrument]);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const instrument = activeTab === TradeTicketTab.Options ? selectedContract : selectedInstrument;
    const orderState = createOrderStateObject(
      data,
      selectedStrike,
      instrument,
      orderSide,
      selectedInstrument?.correctPriceScale,
    );
    
    const isOneClickEnabled = await getOneClickTradeAsync();
    
    if (isOneClickEnabled && !isMobileView) {
      const newOrder = makeOrder(orderState);
      createOrder(amendOrder, newOrder);
    } else {
      setShowConfirmTradeDialog(true);
      const newOrder = makeOrder(orderState);
      setCreateOrderState(newOrder);
    }
  };

  useEffect(() => {
    // Its Options Order
    if (widgetData?.data?.putCall && widgetData?.action === WidgetAction.AmendOrder) {
      setDisableFuturesTab(true);
    }
    
    // Its Futures Order
    if (widgetData?.data?.putCall === null && widgetData?.action === WidgetAction.AmendOrder) {
      setDisableOptionsTab(true);
    }
    
  }, [widgetData?.action, widgetData?.data?.putCall]);

  useEffect(() => {
    if (widgetData?.data?.putCall) {
      const { putCall, instrument, strikePrice, displayContract, limitPrice } = widgetData.data;
      setDisableFuturesTab(true);
      setActiveTab("options");
      setSelectedContract(instrument);
      setSelectedStrike(strikePrice);
      setValue('putCall', putCall);
      setValue('contract', displayContract);
      setValue('limitPrice', limitPrice);
    }
  }, [setSelectedStrike, setValue, widgetData?.data]);

  useEffect(() => {
    if(widgetData?.action === WidgetAction.Options) {
      setDisableFuturesTab(true);
      setValue('putCall', widgetData?.data?.putCallValue);
      setSelectedContract(null);
    }
  },[setValue, widgetData])

  const limitPriceRules = useMemo(() => {
    return ({
      validate: (value: string) => {
        if (!isEnabledLmtPrice) return true;

        if ((orderType === OrderType.Mkt || orderType === OrderType.Stp) && Number(value) === 0)  return "Limit Price is invalid";

        if ((orderType === OrderType.Lmt || orderType === OrderType.Stl) && Number(value) === 0) return "Limit Price is required";

        if (Number(value) > 0) return true;
      },
      required: ["STL", "LMT"].includes(orderType) ? "Limit Price is required" : false,
    })
  }, [orderType, isEnabledLmtPrice]);

  const stopPriceRules = useMemo(() => {
    return ({
      validate: (value: string) => {
        if (!isEnabledStpPrice) return true;
        
        if ((orderType === OrderType.Mkt || orderType === OrderType.Lmt) && Number(value) === 0) return "Stop Price is invalid";

        if ((orderType === OrderType.Stp || orderType === OrderType.Stl) && Number(value) === 0) return "Stop Price is required";

        if (Number(value) > 0) return true;
      },
      required: ["STP", "STL"].includes(orderType) ? "Stop Price is required" : false,
    })
  }, [orderType, isEnabledStpPrice]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ padding: "10px", maxWidth: !props.isMobile ? "500px" : undefined, color: "#222", overflowX: "hidden" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {props?.expired ? (
            <div style={inputStyleBordered}><span style={{ color: "red" }}>Invalid contract: {contractValue}</span></div>
          ) : (
            <ContractSelect control={control} contracts={monthlyContracts} canChangeInstrument={canChangeInstrument} widgetData={widgetData} onContractChanged={setDefaultValues} />
          )}
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "0 !important" }}>
          <SelectedContractInfo selectedContract={selectedContract} activeTab={activeTab} putCallValue={putCallValue} />
        </Grid>
        <Grid item xs={12}>
          <FormErrors errors={errors} />
        </Grid>
        <FuturesOptionsTab
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          amendOrder={amendOrder}
          controller={controller}
          disableFuturesTab={disableFuturesTab}
          disableOptionsTab={disableOptionsTab}
        />
        <Grid item xs={6} sx={gridItemStyles}>
          <SideController control={control} side={errors.side} setValue={setValue} disabled={!!amendOrder} />
        </Grid>

        <PriceInputByTick
          name="quantity"
          label="Quantity (1-10)"
          control={control}
          errors={errors}
          value={quantity}
          tickSize={1}
          onTickChange={(step: number) => {
            const nextVal = quantity + step;
            if (nextVal > 0 && nextVal <= 10) {
              setValue("quantity", nextVal);
            }

            if (isNaN(nextVal)) {
              setValue("quantity", 1);
            }
          }}

          onInputChange={(inputValue: string) => {
            setValue("quantity", Number(inputValue));
          }}

          rules={{
            required: "Quantity is required",
            min: { value: 1, message: "Minimum quantity is 1" },
            max: { value: 10, message: "Maximum quantity is 10" },
          }}
        />

        <Grid item xs={6}>
          <StyledFormControl fullWidth>
            <TimeInForce control={control} isLabel={true} />
          </StyledFormControl>
        </Grid>

        <OrderTypeSelect control={control} activeTab={activeTab} amendOrder={amendOrder} />

        <PriceInputByTick
          name="limitPrice"
          label="Limit Price"
          control={control}
          errors={errors}
          isEnabled={isEnabledLmtPrice}
          value={orderType === OrderType.Lmt || orderType === OrderType.Stl ? limitPrice || '' : ''}
          tickSize={1}
          onTickChange={(step: number) => {
            const selectedInst = activeTab === TradeTicketTab.Options ? selectedContract : selectedInstrument;
            
            if (!selectedInst) return;

            const lprice = DisplayUtil.rawStepPrice(Number(rawLimitPrice), selectedInst, step);
            const newLimitPrice = parseFloat(DisplayUtil.toDisplayPrice(lprice, selectedInst)?.toString() || "") || 0;

            if (newLimitPrice >= 0) {
              setValue("rawLimitPrice", lprice);
              setValue("limitPrice", newLimitPrice);
            }
          }}

          onInputChange={(inputValue: string) => {
            setValue("rawLimitPrice", Number(inputValue));
            setValue("limitPrice", Number(inputValue));
          }}

          rules={limitPriceRules}
        />

        <PriceInputByTick
          name="stopPrice"
          label="Stop Price"
          control={control}
          errors={errors}
          isEnabled={isEnabledStpPrice}
          value={orderType === OrderType.Stp || orderType === OrderType.Stl ? rawStopPrice || '' : ''}
          tickSize={1}
          onTickChange={(step: number) => {
            if (!selectedInstrument) return;

            const sPrice = DisplayUtil.rawStepPrice(Number(rawStopPrice), selectedInstrument, step);
            const stopPrice = parseFloat(DisplayUtil.toDisplayPrice(sPrice, selectedInstrument)?.toString() || "") || 0;

            if (stopPrice >= 0) {
              setValue("rawStopPrice", sPrice);
              setValue("stopPrice", stopPrice);
            }
          }}

          onInputChange={(inputValue: string) => {
            setValue("rawStopPrice", Number(inputValue));
            setValue("stopPrice", Number(inputValue));
          }}

          rules={stopPriceRules}
        />

        {activeTab === TradeTicketTab.Futures && (
          <TakeProfitandLoss
            control={control}
            setValue={setValue}
            watch={watch}
            selectedContract={selectedInstrument}
            limitPrice={Number(limitPrice)}
            errors={errors}
          />
        )}
        {activeTab === TradeTicketTab.Options && (
          <>
            <Grid item xs={6}>
              <StyledFormControl fullWidth>
                <label className="label">Put Call</label>
                <PutCallSelector
                  control={control}
                  putCallValue={putCallValue}
                  onOptionChange={handleOptionChange}
                  disabled={widgetData?.data?.putCall ? true : hasLoadError}
                />
              </StyledFormControl>
            </Grid>
            <Grid item xs={6}>
              <label className="label">Strike Price</label>
              <StrikePriceOptions
                putCall={putCallValue}
                selectedStrike={selectedStrike}
                onStrikeChange={onStrikeChange}
                onError={(hasError) => setHasLoadError(hasError)}
                widgetData={widgetData}
                controller={controller}
                name="strike-price-options"
              />
            </Grid>
          </>
        )}
        {showCancelButton && (
          <Grid item xs={6} sx={gridItemStyles}>
            <StyledBlueBorderButton
              fullWidth
              onClick={() => {
                hideTradeTicket();
              }}
            >
              CANCEL
            </StyledBlueBorderButton>
          </Grid>
        )}
        <Grid item xs={!!showCancelButton ? 6 : 12} sx={gridItemStyles}>
          {!amendOrder && (
            <StyledButton data-testid="tradeSubmit" fullWidth disabled={!selectedInstrument} type={!!selectedInstrument ? "submit" : "button"} orderSide={orderSide}>
              {orderSide ? orderSide.toUpperCase() : "SUBMIT"}
            </StyledButton>
          )}

          {!!amendOrder && (
            <ButtonNamedStyled data-testid="tradeSubmit" fullWidth type="submit" styleName="AMEND">
              AMEND
            </ButtonNamedStyled>
          )}
        </Grid>

        {showCancelOrderButton && (
          <Grid item xs={12} sx={gridItemStyles}>
            <StyledRedTextButton data-testid="tradeCancelOrder"
              fullWidth
              onClick={() => {
                cancelOrder(amendOrder);
              }}
            >
              CANCEL ORDER
            </StyledRedTextButton>
          </Grid>
        )}
      </Grid>
      <ConfirmTradeDialog
        open={showConfirmTradeDialog}
        onClose={() => setShowConfirmTradeDialog(false)}
        cancel={() => setShowConfirmTradeDialog(false)}
        confirm={() => createOrder(amendOrder, createOrderState)}
        title={!!amendOrder ? "Confirm Order Amend" : "Confirm Order"}
        trade={useTradeProps({
          watch,
          activeTab,
          selectedStrike,
          selectedContract,
          selectedInstrument,
        })}
      />
    </form>
  );
};

export default TradeForm;
