import { StyledFormControl, StyledSelect } from "./PriceLadderStyles";
import { Controller } from "react-hook-form";
import { Instrument } from "../../../cqg-api/models/Instrument";
import MenuItem from "@mui/material/MenuItem";
import "./PriceLadder.css";

const SelectContract = (props: { control: any; name: string; contracts: Instrument[] }) => {
  const { control, name, contracts } = props;
  return (
    <div className="price-ladder-contract-select">
      <StyledFormControl fullWidth>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledSelect onChange={onChange} value={value}>
              {contracts.map((contract: Instrument) => (
                <MenuItem key={contract.displayName} value={contract.displayName}>
                  {contract.displayName}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
        />
      </StyledFormControl>
    </div>
  );
};

export default SelectContract;
