import { Box, IconButton } from "@mui/material";
import { OrderSide } from "../../../types";
import useStyles from "../styles";
import { GridCloseIcon } from "@mui/x-data-grid";

interface ModalHeaderProps {
  data: any;
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const ModalHeader = ({ data, toggleDrawer }: ModalHeaderProps) => {
  const classes = useStyles();
  const getSide = (side: string) => {
    if (side === OrderSide.Buy) {
      return <span className="side buy">B</span>;
    }
    return <span className="side sell">S</span>;
  };
  return (
    <Box justifyContent={"space-between"} sx={{ padding: "6px", display: "flex" }} alignItems="center">
      <div className={classes.contractTitle}>
        <div className="text-sm contract">{getSide(data.side)}</div>
        <div>{data.contract}</div>
      </div>
      <IconButton onClick={toggleDrawer(false)} color="inherit">
        <GridCloseIcon sx={{ color: "#5A6874", height: "12px", width: "12px" }} />
      </IconButton>
    </Box>
  );
};

export default ModalHeader;
