import { Tabs, Tab } from "@mui/material";
import { TradeTicketTab } from "../../../types";
import { tabStyles } from "../styles";
import OptionsTab from "./OptionsTab";
import { useMemo } from "react";
import { OrderState } from "../../../cqg-api/models/OrderState";
import { StrikePriceOptionsController } from "../../../cqg-api/controllers/StrikePriceOptionsController";

interface FuturesOptionsTabProps {
  activeTab: string;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  amendOrder: OrderState | undefined;
  controller: StrikePriceOptionsController | null;
  disableFuturesTab?: boolean;
  disableOptionsTab?: boolean;
}

const FuturesOptionsTab = ({ activeTab, handleTabChange, amendOrder, controller, disableFuturesTab, disableOptionsTab }: FuturesOptionsTabProps) => {
  const hasOptions = useMemo(
    () => (controller?.strikeOptions?.length && controller?.strikeOptions?.length > 0) || !!amendOrder,
    [amendOrder, controller?.strikeOptions?.length],
  );

  return (
    <Tabs sx={tabStyles} value={activeTab} onChange={handleTabChange} className="tabs">
      <Tab label="Futures" value="futures" disabled={disableFuturesTab} data-testid='tab-futures' />
      {hasOptions ? (
        <Tab label="Options" value="options" disabled={disableOptionsTab} data-testid='tab-options' />
      ) : activeTab === TradeTicketTab.Futures ? (
        <OptionsTab isLoading={!!amendOrder ? "" : controller?.loadStatus!} />
      ) : (
        <Tab label="Options" value="options" disabled={disableOptionsTab} data-testid='tab-options' />
      )}
    </Tabs>
  );
};

export default FuturesOptionsTab;
