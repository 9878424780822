import { Box, IconButton, Typography } from "@mui/material";
import { modalBoxHeadingStyles, modalTypographyHeadingStyles } from "../../marketStyles";
import { GridCloseIcon } from "@mui/x-data-grid";

interface ModalHeaderProps {
  instrumentName: string | undefined;
  onClose: () => void;
}

const ModalHeader = ({ instrumentName, onClose }: ModalHeaderProps) => {
  return (
    <Box sx={modalBoxHeadingStyles}>
      <Typography variant="h6" sx={modalTypographyHeadingStyles}>
        {instrumentName}
      </Typography>
      <IconButton onClick={onClose} edge="end" color="inherit">
        <GridCloseIcon style={{ color: "#5A6874", height: "12px", width: "12px" }} />
      </IconButton>
    </Box>
  );
};

export default ModalHeader;
