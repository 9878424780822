import { numberNullable } from "../../../cqg-api/types/Types";
import { PositionDisplayEnum } from "../../../types";

export const getPosition = (long: numberNullable, short: numberNullable, prefix: PositionDisplayEnum) => {
  if (long) {
    return `${prefix === PositionDisplayEnum.LongShort ? "Long" : "+"} ${long}`;
  } else if (short) {
    return `${prefix === PositionDisplayEnum.LongShort ? "Short" : "-"} ${short}`;
  }
};
