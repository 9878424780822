import { OrderState } from "../../../cqg-api/models/OrderState";
import { CQGService } from "../../../cqg-api/services/CQGService";
import { numberNullable, stringNullable } from "../../../cqg-api/types/Types";

export const capitalizeFirstLetter = (text: stringNullable) => {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const getOrderStatusLabel = (obj: any, value: numberNullable) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const cancelOrders = (orders: OrderState[]) => {
  CQGService.cancelOrders(orders);
};
