import { Depth } from "./types";

export const onlyNumbersRules = {
  pattern: {
    value: /^[0-9]*$/,
    message: "Please enter a valid number",
  },
};

const generateNewDepth = (price: number) => ({
  price,
  displayPrice: price,
  volume: null,
});

export function getDecimalPlaces(number: number): number {
  const decimalPart = number?.toString().split(".")[1];
  return decimalPart ? decimalPart.length : 0;
}

export const generateDepthList = (depthList: any[], tickSize: number, count = 50, isBids?: boolean): Depth[] => {
  if (!Array.isArray(depthList) || depthList.length === 0) return [];

  const decimals = getDecimalPlaces(tickSize) ?? 0;
  const lastPrice = depthList[depthList.length - 1]?.displayPrice ?? 0;
  const result = [...depthList];

  for (let i = depthList.length; i < count; i++) {
    const updatedPrice = lastPrice + (isBids ? -tickSize : tickSize) * (i - depthList.length + 1);
    result.push(generateNewDepth(Number(updatedPrice.toFixed(decimals))));
  }

  return result;
};

export function removeLeadingNulls(array: any[]) {
  let firstNonNullIndex = 0;

  // Find the index of the first non-null element
  while (firstNonNullIndex < array?.length && array[firstNonNullIndex]?.volume === null) {
    firstNonNullIndex++;
  }

  // Return the array starting from the first non-null index
  return array?.slice(firstNonNullIndex);
}

export function convertToKFormat(value: number | null) {
  if (!value) {
    return;
  }
  if (typeof value !== "number") return value; // Return the value as-is if it's not a number

  if (value >= 1000) {
    return (value / 1000).toFixed(3).replace(/\.0$/, "") + "K";
  }

  return value.toString(); // Return the number as a string if less than 1000
}


export function createPriceSeries(
  bestBid: number | null,
  incremental: number,
  length = 50,
  isBids?: boolean
) {
  if (!bestBid) return;

  const decimals = getDecimalPlaces(incremental) ?? 0;
  const direction = isBids ? -1 : 1;

  return Array.from({ length }, (_, i) => {
    const price = bestBid + i * incremental * direction;
    const formattedPrice = price.toFixed(decimals);
    return { displayPrice: formattedPrice, price: formattedPrice };
  });
}


export const createPriceLookup = (items: any, decimals: any) =>
  items?.reduce((map: any, item: any) => {
    map[item.price.toFixed(decimals)] = { volume: item.volume, displayPrice: item.displayPrice };
    return map;
  }, {});

export const updatePriceSeries = (priceSeries: any, priceLookup: any, decimals: any) =>
  priceSeries?.map((item: any) => {
    const key = Number(item.price).toFixed(decimals);
    if (priceLookup[key]) {
      return {
        ...item,
        volume: priceLookup[key].volume,
        displayPrice: priceLookup[key].displayPrice.toFixed(decimals),
      };
    }
    return { ...item, volume: null };
  });

export const generateDepth = (depthList: Depth[], tickSize: number, count: number, reverse = false) => {
  const filteredList = depthList && removeLeadingNulls(depthList);
  const sortedList = reverse
    ? filteredList?.sort((a, b) => b.price - a.price)
    : filteredList?.sort((a, b) => a.price - b.price);

  if (sortedList?.length >= count) {
    return sortedList.slice(0, count - 1);
  }
  return sortedList && generateDepthList(sortedList, tickSize, count, reverse);
};