export const getRemovedProduct = (state) => state.watchlist.removeProduct;
export const getAddProduct = (state) => state.watchlist.addProduct;
export const getLoading = (state) => state.watchlist.loading;
export const getWidget = (state) => state.watchlist.widget;
export const getSelectedTreeItems = (state) => state.watchlist.selectedTreeItems;
export const getUpdateLoading = (state) => state.watchlist.updateLoading;
export const getIsEdit = (state) => state.watchlist.isEdit;
export const getWatchlistDuplicateRequest = (state) => state.watchlist.duplicateState;
const watchlistSelectors = {
  getRemovedProduct,
  getAddProduct,
  getLoading,
  getWidget,
  getSelectedTreeItems,
  getUpdateLoading,
  getIsEdit,
  getWatchlistDuplicateRequest,
};

export default watchlistSelectors;
