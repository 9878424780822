import React, { useCallback, useMemo, useState } from "react";
import { Workspace } from "../../../../types";
import AddNewWorkspace from "./workspace/AddNewWorkSpace";
import RenameDialog from "./RenameDialog";
import WorkspaceTab from "./WorkspaceTab";
import WorkspaceMenu from "./WorkspaceMenu";
import { Box, IconButton, Tabs } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import Notification from "../../../shared/notification/Notification";
import ConfirmCancelDialog from "../../../shared/ConfirmCancelDialog";

type WorkspaceTabsProps = {
  activeWorkspace?: Workspace;
  workSpaces: Workspace[];
  onActiveWorkSpaceChange: (workspace: Workspace) => void;
  onDeleteWorkspace: (workspace: Workspace) => Promise<void>;
  deleteWorkspaceLoading: boolean;
  handleNewWorkspaceCreated: (workspace: Workspace) => void;
  handleRenameWorkspace: (workspace: Workspace, newTitle: string) => Promise<void>;
  updateWorkspaceLoaded: boolean;
  onSetDefaultWorkspace: (workspace: Workspace) => void;
  onDuplicateWorkspace: (workspace: Workspace) => void;
  isSidebarOpen: boolean;
  onSidebarToggle: () => void; 
  workspaceTabsLimit: number;
};

const WorkspaceTabs = ({
  activeWorkspace,
  workSpaces,
  onActiveWorkSpaceChange,
  onDeleteWorkspace,
  deleteWorkspaceLoading,
  handleNewWorkspaceCreated,
  handleRenameWorkspace,
  updateWorkspaceLoaded,
  onSetDefaultWorkspace,
  onDuplicateWorkspace,
  isSidebarOpen,
  onSidebarToggle,
  workspaceTabsLimit
}: WorkspaceTabsProps) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [renameWorkspace, setRenameWorkspace] = useState<Workspace | null>(null);
  const [renameTitle, setRenameTitle] = useState<string>('');
  const [duplicateConfirmDialog, setDuplicateConfirmDialog] = useState(false)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, workspace: Workspace) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedWorkspace(workspace);
  };

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenDialog = () => {
    setOpenConfirmDialog(true);
    handleMenuClose();
  };

  const handleCloseDialog = useCallback(() => {
    setOpenConfirmDialog(false);
    setDuplicateConfirmDialog(false);
  },[]);

  const onHandleDeleteWorkspace = useCallback(async () => {
    if (selectedWorkspace) {
      await onDeleteWorkspace(selectedWorkspace);
      setSelectedWorkspace(null);
      handleCloseDialog();
    }
  }, [selectedWorkspace, onDeleteWorkspace, handleCloseDialog]);

  const handleRename = useCallback(
    (workspace: Workspace) => {
      setRenameTitle(workspace.title || "");
      setRenameWorkspace(workspace);
      setOpenRenameDialog(true);
      handleMenuClose();
    },
    [handleMenuClose],
  );

  const handleRenameSubmit = useCallback(
    async (newTitle: string) => {
      if (renameWorkspace) {
        await handleRenameWorkspace(renameWorkspace, newTitle);
        setOpenRenameDialog(false);
      }
    },
    [handleRenameWorkspace, renameWorkspace],
  );

  const handleRenameDialogClose = useCallback(() => {
    setOpenRenameDialog(false);
  }, []);

  const handleRenameClick = useCallback(() => {
    if (selectedWorkspace) {
      handleRename(selectedWorkspace);
    }
  }, [selectedWorkspace, handleRename]);

  const handleDuplicateClick = useCallback(() => {
    if (workSpaces?.length < workspaceTabsLimit) {
      setDuplicateConfirmDialog(true);
    } else {
      Notification.error(`You've reached your limit of ${workspaceTabsLimit} workspaces. To add more, please remove an existing one.`);
    }
    handleMenuClose();
  }, [workSpaces?.length, handleMenuClose, workspaceTabsLimit]);
  
  const onHandleDuplicateWorkspace = useCallback(() => {
    if (selectedWorkspace) {
      onDuplicateWorkspace(selectedWorkspace);
      setDuplicateConfirmDialog(false);
    }
  },[selectedWorkspace, onDuplicateWorkspace]);

  const handleChangeDefault = () => {
    if(selectedWorkspace) {
      onSetDefaultWorkspace(selectedWorkspace);
      handleMenuClose();
    }
  }

  const sortedWorkspacesByDefault = useMemo(() => {return [...workSpaces].sort((a, b) => {
    if (a.isDefault === b.isDefault) {return 0;} return a.isDefault ? -1 : 1;});
  }, [workSpaces]);

  return (
    <>
      <div className="Tabs">
      {!isSidebarOpen && (
        <>
          <div style={{ backgroundColor: "#fff", borderRight: "1px solid var(--divider, rgba(0, 0, 0, 0.12))" }}>
            <div style={{ display: "flex", justifyContent: "space-between"
            }}>
              <IconButton className="userMoreInfo" onClick={onSidebarToggle} sx={{padding: '6px'}} >
                <ChevronRight />
              </IconButton>
            </div>
          </div>
        </>
      )}
        <Box sx={{ width: `calc(100vw - ${!isSidebarOpen ? '40px': '183px'})`,
          '& .MuiTabScrollButton-root.Mui-disabled': {display: 'none'},
          '& .MuiTab-root': { '&.Mui-selected': { borderBottom: 'none'} },
          '& .MuiTabScrollButton-root': { borderInline: '1px solid var(--divider, #0000001F)' },
          '& .css-1aquho2-MuiTabs-indicator': {position: 'static'}
        }}>
        <Tabs
          value={false}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable custom tabs"
          sx={{
            minHeight: 0, '& .MuiTab-root': { '&.Mui-selected': { borderBottom: 'none' }},
          }}
        >
          {sortedWorkspacesByDefault.map((workspace) => (
            <WorkspaceTab
              key={workspace.id}
              workspace={workspace}
              active={activeWorkspace?.id === workspace.id}
              onClick={() => {
                onActiveWorkSpaceChange(workspace);
              }}
              onMenuOpen={(e) => handleMenuOpen(e, workspace)}
            />
          ))}
          <AddNewWorkspace
            onNewWorkspaceCreated={handleNewWorkspaceCreated}
            workspaceTabsLimit={workspaceTabsLimit}
            workSpaces={workSpaces}
          />
        </Tabs>
        </Box>
      </div>
      {openConfirmDialog && 
        <ConfirmCancelDialog
          open={openConfirmDialog}
          onClose={handleCloseDialog}
          onConfirm={onHandleDeleteWorkspace}
          loading={deleteWorkspaceLoading}
          title="Delete workspace"
          message={
            <span>
              Are you sure you want to remove 
              <span style={{ color: 'blue' }}> {selectedWorkspace?.title}</span>?
            </span>
          }
          confirmText="Remove"
          cancelText="Cancel"
        />
      }

      {duplicateConfirmDialog && 
        <ConfirmCancelDialog
          open={duplicateConfirmDialog}
          onClose={handleCloseDialog}
          onConfirm={onHandleDuplicateWorkspace}
          loading={deleteWorkspaceLoading}
          title="Duplicate workspace"
          message={
            <span>
              Are you sure you want to duplicate 
              <span style={{ color: 'blue' }}> {selectedWorkspace?.title}</span>?
            </span>
          }
          confirmText="Duplicate"
          cancelText="Cancel"
        />
      }

      {openRenameDialog && (
        <RenameDialog
          open={openRenameDialog}
          onClose={handleRenameDialogClose}
          onRename={handleRenameSubmit}
          currentTitle={renameTitle}
          loading={!updateWorkspaceLoaded}
        />
      )}

      <WorkspaceMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onRenameClick={handleRenameClick}
        onDeleteClick={handleOpenDialog}
        onChangeDefaultClick={handleChangeDefault}
        onDuplicateClick={handleDuplicateClick}
      />
    </>
  );
};

export default WorkspaceTabs;