import { useEffect, useRef, useId, useState } from "react";
import { cqgUDFDataFeed } from '../../../cqg-api/services/CQGUDFDataFeed';
import { chartConfig, generateMultiChartLayout, chartLayout } from "./chartConfig";

const TradingChart = (props) => {
  const chartContainerRef = useRef();
  const [tvWidget, setTvWidget] = useState(null);

  const containerId = useId();

  useEffect(() => {
    if (!window.Trading && !tvWidget) {
      const script = document.createElement('script');
      script.src = "charting_library/charting_library.min.js"; // path to the Trading library
      script.onload = () => {
        const widget = new window.TradingView.widget(chartConfig(cqgUDFDataFeed, props.symbols[0], containerId))
        setTvWidget(widget)
      };
      document.body.appendChild(script);
    } else {
      const widget = new window.TradingView.widget(chartConfig(cqgUDFDataFeed, props.symbols[0], containerId))
      setTvWidget(widget)
    }
  }, []);

  useEffect(() => {
    if (tvWidget) {
      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          const button = tvWidget.createButton();
          button.setAttribute("title", "Click to show a notification popup");
          button.classList.add("apply-common-tooltip");
          const size = props.symbols.length;
  
          if (size === 7 || size > 8) {
            console.log(`Trading chart does not support ${size} layout.`);
          } else {
            tvWidget.setLayout(chartLayout(size));
            generateMultiChartLayout(tvWidget, size, props.symbols)
          }
            
          button.addEventListener("click", () =>
            tvWidget.showNoticeDialog({
              title: "Notification",
              body: "TradingView Charting Library API works correctly",
              callback: () => {
                console.log("Noticed!");
              },
            }),
          );
          button.innerHTML = "Check API";
        });
      });
    }

    return () => {
      if (tvWidget)
        tvWidget.remove();
    }
  }, [tvWidget])

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      id={"TVChartContainer" + containerId}
      ref={chartContainerRef}
      className={"TVChartContainer"}
    />
  );
};

export default TradingChart;
