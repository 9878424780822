import { InformationSubscriptions, SubscriptionService } from "./InformationSubscriptions";
import * as WebAPI from "../proto/webapi_2";

export class AtTheMoneyStrikeSubscription {
  public parentSymbolId: string;
  public requestId: string;
  public statusCode: number | null = null;
  public textMessage: string | null | undefined = null;
  public atmStrike: number | null = null;

  private resolvePromise!: Promise<number | null>;
  private resolveDeferred!: (value: number | null) => void;
  private rejectDeferred!: (reason: any) => void;

  constructor(parentSymbolId: string, requestId: string) {
    this.parentSymbolId = parentSymbolId;
    this.requestId = requestId;

    this.resolvePromise = new Promise((resolve, reject) => {
      this.resolveDeferred = resolve;
      this.rejectDeferred = reject;
    });
  }

  public async update(informationReport: WebAPI.InformationReport): Promise<number | null> {
    this.statusCode = informationReport.statusCode;
    this.textMessage = informationReport.textMessage;
    const strike = informationReport.atTheMoneyStrikeReport && informationReport.atTheMoneyStrikeReport?.strike;

    if (strike) {
      this.atmStrike = strike;
    }

    if (this.statusCode >= WebAPI.InformationReport_StatusCode.STATUS_CODE_FAILURE) {
      this.rejectDeferred({
        parentSymbolId: this.parentSymbolId,
        reason: this.textMessage,
      });
      throw new Error(`Error for parentSymbolId ${this.parentSymbolId}: ${this.textMessage}`);
    } else if (this.statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_SUCCESS) {
      this.resolveDeferred(this.atmStrike);
    }

    return this.atmStrike;
  }

  public get promise(): Promise<number | null> {
    return this.resolvePromise;
  }
}

export class AtTheMoneyStrikeSubscriptions {
  private static instance: AtTheMoneyStrikeSubscriptions;
  private service!: SubscriptionService;

  static get Instance() {
    return AtTheMoneyStrikeSubscriptions.instance || new AtTheMoneyStrikeSubscriptions();
  }

  constructor() {
    if (AtTheMoneyStrikeSubscriptions.instance) {
      return AtTheMoneyStrikeSubscriptions.instance;
    }

    const informationSubscriptions = new InformationSubscriptions();
    this.service = informationSubscriptions.SubscriptionsManager;

    this.service.createSubscription = (key: string, requestId: string) => {
      return new AtTheMoneyStrikeSubscription(key, requestId);
    };
    AtTheMoneyStrikeSubscriptions.instance = this;
  }

  public get SubscriptionManager() {
    return this.service;
  }
}
