import { Select, MenuItem } from '@mui/material';

interface MarketAssetsProps {
  activeTab: string;
  assetProductsDictionary: Record<string, any>;
  selectMarket: (market: string) => void;
  isSmallPanel: boolean;
}

const MarketAssets = ({ activeTab, assetProductsDictionary, selectMarket, isSmallPanel }: MarketAssetsProps) => (
  <div className="market-type-wrapper">
    {isSmallPanel ? (
      <Select
        sx={{
          padding: 0,
          minWidth: "196px",
          fontSize: '12px',
          overflow: 'hidden',
          '& .MuiSelect-select': {
            padding: '5px',
            fontSize: '12px',
            background: 'white',
          },
        }}
        value={activeTab}
        onChange={(event) => selectMarket(event.target.value)}
      >
        {Object.keys(assetProductsDictionary).map((market) => (
          <MenuItem key={market} value={market}>
            {market}
          </MenuItem>
        ))}
      </Select>
    ) : (
      Object.keys(assetProductsDictionary).map((market) => {
        const isActive = activeTab === market ? "market-type-active" : "";
        return (
          <button onClick={() => selectMarket(market)} className={`market-type ${isActive}`} key={market}>
            {market}
          </button>
        );
      })
    )}
  </div>
);

export default MarketAssets;
