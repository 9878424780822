import React, { useState } from 'react';
import usePositions from '../widgets/positions/usePositions';
import './positions.css';
import FlattenPosition from './PositionModal/FlattenModal';
import { Row } from '../../types';
import { getPrice } from '../../utils/getPrice';
import { convertSymbolToYear, YearFormat } from '../tree-view/utils/convertSymbolToDate';
import { Box, Modal } from "@mui/material";
import TradeForm from "../TradeTicketDrawer/TradeTicketMobile";
import { mobileTradeTicketStyles } from '../widgets/availableProducts/mobile/mobileTradeTicketStyles';

type FixedLastColumnTableProps = {
  dataRows: Row[];
  workspaceId: number;
}

const FixedLastColumnTable = ({ dataRows , workspaceId}: FixedLastColumnTableProps) => {
  const { handleFlattenPosition,
    closeFlattenDialog,
    openSingleFlattenDialog,
    handleConfirmFlatten,
    selectedPosition,
    loading,
    handleTrade,
    openTradeTicket,
    handleCloseTradeTicket,
  } = usePositions();
  const [instrument, setInstrument] = useState();
  const setSelectedInstrument = (root: any) => {
    setInstrument(root.position.instrument);
    handleFlattenPosition(root);
  };
  return (
    <>
      {
        dataRows?.map((row: Row) => (
          <div
            key={row.id}
            className='flex w-full justify-between row py-2'
            onClick={() => setSelectedInstrument(row)}
          >
            <div className='tableData'>
            <span className='text-xs symbolName'>{row.contract}</span>
            <span className='text-xs justify-end symbolName'>{row.strike === 0 ? "" : row.strike}</span>
            <span className='text-xs justify-end symbolName'>{row.direction}</span>
            <span className='text-xs justify-end'>{getPrice(row.realizedPL, row.unrealizedPL)}</span>
            {row.symbol && (
                <span className='text-xs sm-info'>
                  {row.symbol} - {convertSymbolToYear(row.symbol, row.month, YearFormat.SHORT)}
                </span>
            )}
            <span className='text-xs justify-end symbolName'>{row.cp}</span>
            <span className='text-xs justify-end symbolName'>{row.size}</span>
            <span className='text-xs justify-end symbolName'>{row.averagePX}</span>

            </div>
          </div>))
      }
      <Modal
        open={openTradeTicket}
        onClose={handleCloseTradeTicket}
      >
        <Box sx={mobileTradeTicketStyles}>
      <TradeForm
        workspaceId={workspaceId}
        externalData={undefined}
        instrument={instrument}
        closeTrade={handleCloseTradeTicket}
      />
      </Box>
      </Modal>
      <FlattenPosition
        handleConfirmFlatten={handleConfirmFlatten}
        data={selectedPosition}
        open={openSingleFlattenDialog}
        onClose={closeFlattenDialog}
        openTradeTicket={handleTrade}
      />
      </>
  );
};

export default FixedLastColumnTable;