import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { Watchlist, Widget, WidgetType, Workspace } from "../../../../../types";
import { useState, useMemo } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import TreeViewContainer from "../../../../tree-view/TreeViewContainer";
import "../../../../tree-view/TreeViewContainer.css";
import TreeViewContainerButtonTitle from "../../../../tree-view/TreeViewContainerButtonTitle";
import WatchlistManager from "./WatchlistManager";
import { WatchListMode } from "./types";
import DrawerComponent from "@mui/material/Drawer";
import { useCreateWatchlist, useUpdateWatchlist } from "../../../../../hooks/api/watchlist";
import watchlistActions from "../../../../../redux/watchlist/watchlistActions";
import { useDispatch } from "react-redux";
import { AvailableProductsContext } from "../../../../../AvailableProductsProvider";
import { v4 as uuidv4 } from "uuid";

type MenuDrawerProps = {
  onClose: () => void;
  onAddToWorkspaceRequest: (widget: Widget) => void;
  widgetToAdd: Widget;
  open: boolean;
  activeWorkspace?: Workspace;
  selectedTreeItems?: string[];
};

const drawerStyle = {
  "& .MuiModal-modal": {
    zIndex: 10,
  },
  "& .MuiModal-backdrop": {
    left: 180,
    top: 83,
  },
  "& .MuiDrawer-paper": {
    width: 420,
    boxSizing: "border-box",
    backgroundColor: "#f4f4f4",
    left: 180,
    top: 83,
    boxShadow: "none",
    background: "#fff",
    border: '1px solid var(--divider, #0000001F)',
  },
};

const MenuItemDrawer: React.FC<MenuDrawerProps> = (props) => {
  const dispatch = useDispatch();
  const { widgetToAdd, onClose, onAddToWorkspaceRequest, open } = props;
  const widgetType = widgetToAdd.widgetType as WidgetType;
  const [selectedTreeItems, setSelectedTreeItems] = useState<string[]>(props.selectedTreeItems || []);
  const { productsMapById, monthlyContractsByDisplayNameMap } = React.useContext(AvailableProductsContext);
  const [watchlistMode, setWatchListMode] = useState<WatchListMode>(WatchListMode.Creation);
  const { createWatchlistAsync } = useCreateWatchlist();
  const { updateWatchlistAsync } = useUpdateWatchlist();

  React.useEffect(() => {
    setWatchListMode(WatchListMode.Creation);
    setSelectedTreeItems(props.selectedTreeItems ?? []);
  }, [props.selectedTreeItems, widgetToAdd]);

  const handleChangeTreeItems = (selectedItems: string[]) => {
    setSelectedTreeItems(selectedItems);
  };

  const selectedMonthlyContracts = useMemo(() => {
    const productIds = Object.keys(productsMapById);
    return selectedTreeItems.filter((item) => !productIds.includes(item.toString()));
  }, [productsMapById, selectedTreeItems]);

  const treeViewErrorMessage = useMemo(() => {
    const stringCount = selectedMonthlyContracts?.filter((item) => typeof item === "string").length;
    if (stringCount === 7 && widgetType === WidgetType.Chart) {
      return "Display of 7 charts is not supported";
    } else if (stringCount > 8 && widgetType === WidgetType.Chart) {
      return "You have selected maximum number of charts supported";
    }
    return null;
  }, [widgetType, selectedMonthlyContracts]);

  const handleActionButton = () => {
    if (!widgetToAdd) {
      throw new Error("Widget not found");
    }

    const widget = {
      ...widgetToAdd,
      contractIds: selectedMonthlyContracts || [],
      ...(widgetToAdd.widgetId && { widgetId: widgetToAdd.widgetId }),
      id: uuidv4(),
    };

    if (widget.widgetType === WidgetType.PriceLadder) {
      const selectedContractKey = selectedMonthlyContracts?.[0];
      const contract = monthlyContractsByDisplayNameMap?.[selectedContractKey];
      if (contract) {
        widget.widgetName = `${contract.cmeSymbolName} - ${contract.cmeSymbol}`;
      }
    }

    if (widget.widgetType === WidgetType.Options) {
      const selectedContractKey = selectedMonthlyContracts?.[0];
      const contract = monthlyContractsByDisplayNameMap?.[selectedContractKey];
      if (contract) {
        widget.widgetName = `${contract.cmeSymbolName} Options - Quotes`;
      }
    }

    if (widget.widgetType !== WidgetType.Watchlist) {
      onAddToWorkspaceRequest(widget);
      return;
    }

    if (widget.widgetId) {
      const contractIds = widget.contractIds.join(",");
      updateWatchlistAsync(widget.widgetId, contractIds, () => {
        dispatch(watchlistActions.dispatchUpdateWatchlistLoading(true));
        onDrawerCloseClick();
      });
    } else {
      createWatchlistAsync(
        widget.contractIds,
        (response: Watchlist) => {
          widget.widgetId = response.id;
          onAddToWorkspaceRequest(widget);
        },
        props.activeWorkspace && props.activeWorkspace.id,
      );
      return;
    }
  };

  // Determine if the action button should be disabled
  const isActionButtonDisabled = useMemo(() => {
    // disable if no tree items are selected
    return selectedTreeItems.length === 0;
  }, [selectedTreeItems.length]);

  const handleWatchListModeChange = React.useCallback((watchlistMode: WatchListMode) => {
    setWatchListMode(watchlistMode);
  }, []);

  const onDrawerCloseClick = () => {
    if (watchlistMode === WatchListMode.Edit) {
      dispatch(watchlistActions.dispatchEditWatchlist(false));
    }
    onClose();
  };

  return (
    <DrawerComponent sx={drawerStyle} open={open} onClose={onDrawerCloseClick}>
      <div className="widget-dialog-container">
        <div className="widget-dialog-header">
          <Header widgetType={widgetType} />
          <CloseIcon className="widget-dialog-remove" onClick={onDrawerCloseClick} />
        </div>

        {widgetType === WidgetType.Watchlist && (
          <WatchlistManager watchlistMode={watchlistMode} onWatchListModeChange={handleWatchListModeChange} />
        )}

        <TreeViewContainer
          widgetType={widgetType}
          selectedTreeItems={selectedTreeItems}
          onChangeTreeItems={handleChangeTreeItems}
          errorMessage={treeViewErrorMessage}
          data-testid="tree-view"
        />

        <div className="widget-dialog-actions">
          <Button
            sx={{
              width: "186px",
              color: "#006EB6",
              border: "1px solid #006EB6",
              fontFamily: "Averta-Regular",
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "26px",
              letterSpacing: "1.5px",
            }}
            onClick={onDrawerCloseClick}
            variant="outlined"
          >
            Cancel
          </Button>

          <Button
            onClick={handleActionButton}
            sx={{
              width: "186px",
              background: "#006EB6",
              fontFamily: "Averta-Regular",
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "26px",
              letterSpacing: "1.5px",
            }}
            variant="contained"
            disabled={isActionButtonDisabled || !!treeViewErrorMessage}
          >
            <TreeViewContainerButtonTitle
              widgetType={widgetType}
              isWatchlistEditMode={watchlistMode === WatchListMode.Edit}
            />
          </Button>
        </div>
      </div>
    </DrawerComponent>
  );
};

export default MenuItemDrawer;
