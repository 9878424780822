import { FormControlLabel, Grid, Switch } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { IFormInput } from "../types";
import { Action } from "./types";

interface SwitchFieldProps {
  label: string;
  name: any;
  control: Control<IFormInput, any>;
  checked: boolean;
  dispatch: React.Dispatch<Action>;
  dispatchType: any;
}

const SwitchField = ({ label, name, control, checked, dispatch, dispatchType }: SwitchFieldProps) => (
  <Grid item xs={12} sx={{ padding: "0px !important" }}>
    <FormControlLabel
      labelPlacement="start"
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              checked={checked}
              onChange={(e) => {
                field.onChange(e.target.checked);
                dispatch({ type: dispatchType, payload: e.target.checked ? 1 : 0 });
              }}
            />
          )}
        />
      }
      label={label}
      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "#081D37DE" }}
    />
  </Grid>
);

export default SwitchField;
