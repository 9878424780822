import { DialogContent, Grid } from "@mui/material";
import SwitchField from "./SwitchField";
import LabelledField from "./LabelledField";
import { Control } from "react-hook-form";
import { IFormInput } from "../types";
import { Action } from "./types";

interface OrderSettingsContentProps {
  profitState: any;
  control: Control<IFormInput, any>;
  profitDispatch: React.Dispatch<Action>;
  lossState: any;
  lossDispatch: React.Dispatch<Action>;
}

const OrderSettingsContent = ({
  profitState,
  control,
  profitDispatch,
  lossState,
  lossDispatch,
}: OrderSettingsContentProps) => {
  return (
    <>
      <DialogContent>
        <Grid container spacing={1}>
          {/* Take Profit Section */}
          <div className="dividerTrade"></div>
          <SwitchField
            label="Take Profit"
            name="takeProfit"
            control={control}
            checked={profitState.enabled}
            dispatch={profitDispatch}
            dispatchType="enabled"
          />
          <>
            <div className="dividerTradeTrade"></div>
            <LabelledField
              name="profitTicks"
              label="Ticks"
              value={profitState.ticks}
              dispatchType="ticks"
              control={control}
              dispatch={profitDispatch}
              xs={2}
            />
            <LabelledField
              name="profitPrice"
              label="Price"
              value={profitState.price}
              dispatchType="price"
              control={control}
              dispatch={profitDispatch}
              xs={4}
            />
            <LabelledField
              name="profit"
              label="Profit $"
              value={profitState.pnl}
              dispatchType="pnl"
              control={control}
              dispatch={profitDispatch}
              xs={4}
            />
            <LabelledField
              name="profitPercentage"
              label="%"
              value={profitState.percent}
              dispatchType="percent"
              control={control}
              dispatch={profitDispatch}
              xs={2}
            />
          </>

          {/* Stop Loss Section */}
          <div className="dividerTrade"></div>
          <SwitchField
            label="Stop Loss"
            name="stopLoss"
            control={control}
            checked={lossState.enabled}
            dispatch={lossDispatch}
            dispatchType="enabled"
          />
          <>
            <LabelledField
              name="lossTicks"
              label="Ticks"
              value={lossState.ticks}
              dispatchType="ticks"
              control={control}
              dispatch={lossDispatch}
              xs={2}
            />
            <LabelledField
              name="lossPrice"
              label="Price"
              value={lossState.price}
              dispatchType="price"
              control={control}
              dispatch={lossDispatch}
              xs={4}
            />
            <LabelledField
              name="loss"
              label="Loss $"
              value={lossState.pnl}
              dispatchType="pnl"
              control={control}
              dispatch={lossDispatch}
              xs={4}
            />
            <LabelledField
              name="lossPercentage"
              label="%"
              value={lossState.percent}
              dispatchType="percent"
              control={control}
              dispatch={lossDispatch}
              xs={2}
            />
          </>

          <div className="dividerTrade"></div>
        </Grid>
      </DialogContent>
    </>
  );
};

export default OrderSettingsContent;
