import { useCallback, useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { IFormInput } from "../types";
import { WidgetAction } from "../../../types";

const useHandleStrikeAndOptionChange = (
  putCallValue: "P" | "C" | undefined,
  setSelectedContract: any,
  setValue: UseFormSetValue<IFormInput>,
  controller: any,
  contractValue: string,
  widgetData: any
) => {
  const [selectedStrike, setSelectedStrike] = useState<number | null>(null);
  const [optionsLocalState, setOptionsLocalState] = useState(true);

  const onStrikeChange = useCallback(
    (strikeDetails: { strike: number | null; callOption: any; putOption: any }) => {
      const { callOption, putOption, strike } = strikeDetails;
      setSelectedStrike(strike);

      if (putCallValue === "P" && putOption) {
        setSelectedContract(putOption);
      } else if (putCallValue === "C" && callOption) {
        setSelectedContract(callOption);
      }
    },
    [putCallValue, setSelectedContract],
  );

  useEffect(() => {
    if (contractValue) {
      onStrikeChange({ strike: null, callOption: null, putOption: null });
    }

    if (widgetData?.action === WidgetAction.AmendOrder && widgetData?.data?.putCall) {
      const { instrument, strikePrice } = widgetData?.data;
      setSelectedContract(instrument);
      setSelectedStrike(strikePrice);
      return;
    }  

    const strikeToUse = selectedStrike ?? controller?.atmStrike;

    if (strikeToUse && controller?.strikeOptions) {
      const strikeAsNumber = typeof strikeToUse === "string" ? parseFloat(strikeToUse) : strikeToUse;
      const selectedOption = controller.strikeOptions.find((option: any) => option.strike === strikeAsNumber);

      if (selectedOption) {
        const { callOption, putOption } = selectedOption;
        onStrikeChange({
          strike: strikeAsNumber,
          callOption: putCallValue === "C" ? callOption : null,
          putOption: putCallValue === "P" ? putOption : null,
        });
      }
    }
  }, [contractValue, controller?.atmStrike, controller.strikeOptions, onStrikeChange, setSelectedContract, widgetData?.action, widgetData?.data]);

 useEffect(() => {
    if(
      widgetData?.action === WidgetAction.Options &&
      widgetData?.data && optionsLocalState &&
      controller?.strikeOptions?.length
    ) {
      setOptionsLocalState(false);
      const { strikePrice } = widgetData?.data;
      setSelectedStrike(strikePrice);

      const selectedOption = controller.strikeOptions?.find((option: any) => option.strike === strikePrice);

      if (selectedOption) {
        const { callOption, putOption } = selectedOption;
        onStrikeChange({
          strike: strikePrice,
          callOption: putCallValue === "C" ? callOption : null,
          putOption: putCallValue === "P" ? putOption : null,
        });
      }
      return;
    }
 },[optionsLocalState, controller.strikeOptions, onStrikeChange, putCallValue, widgetData?.action, widgetData?.data]) 

  const handleOptionChange = useCallback(
    (option: "P" | "C") => {
      setValue("putCall", option);
      const selectedOption = controller?.strikeOptions.find((opt: any) => opt.strike === selectedStrike);
  
      if (selectedOption) {
        const { callOption, putOption } = selectedOption;

        onStrikeChange({
          strike: selectedStrike,
          putOption: option === "P" ? putOption : null,
          callOption: option === "C" ? callOption : null,
        });
      }
    },
    [controller?.strikeOptions, onStrikeChange, selectedStrike, setValue],
  );
  return { selectedStrike, setSelectedStrike, onStrikeChange, handleOptionChange };
};

export default useHandleStrikeAndOptionChange;
