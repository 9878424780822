import ConfirmCancelDialog from "../shared/ConfirmCancelDialog";
import FixedLastColumnTable from "./Table";
import usePositions from "../widgets/positions/usePositions";
import clsx from "clsx";
import FilterProducts from "./Options";
import "../widgets/positions/Positions.css";

type PositionsWidgetProps = {
  workspaceId: number;
};

const PositionsWidget = ({ workspaceId }: PositionsWidgetProps) => {
  const {
    hasPositionsWithDirection,
    setOpenAllFlattenDialog,
    openAllFlattenDialog,
    handleAllFlattenPosition,
    loading,
    activeFilter,
    setFilter,
    dataRows,
   } = usePositions();
  return (
    <div style={{ height: '100vh' }}>
      <div className="positions-header">
        <FilterProducts value={activeFilter} selectFilter={(val: string) => setFilter(val)} />
        <div
          className={clsx(
            "mobile-button cancel-all-orders",
            !hasPositionsWithDirection && "disabled-position"
          )}
          onClick={hasPositionsWithDirection ? () => setOpenAllFlattenDialog(true) : undefined}
        >
          FLATTEN ALL
        </div>
      </div>
    <FixedLastColumnTable dataRows={dataRows} workspaceId={workspaceId} />
    {openAllFlattenDialog && 
        <ConfirmCancelDialog
          open={openAllFlattenDialog}
          onClose={() => setOpenAllFlattenDialog(false)}
          onConfirm={handleAllFlattenPosition}
          loading={loading}
          title="Flatten All Positions"
          message="Are you sure you want to flatten all positions?"
          cancelText="Cancel"
          confirmText="Yes, Flatten"
        />
      }
  </div>
  )
}
export default PositionsWidget;
