import React, { useCallback } from "react";
import { Button } from "@mui/material";
import { flattenButtonStyles } from "./PriceLadderStyles";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { usePositionsList } from "../../../cqg-api/hooks/ServiceHooks";
import { PositionsController } from "../../../cqg-api/controllers/PositionsController";

const FlattenAllPositions = (props: { contract: Instrument }) => {
  const { contract } = props;
  const { positionsList } = usePositionsList();

  const handleOnClickFlattenPostions = useCallback(() => {
    PositionsController.flattenAllPositions(
      positionsList.filter((position) => position.contractId === contract?.contractId),
    );
  }, [contract?.contractId, positionsList]);

  return (
    <Button variant="outlined" sx={flattenButtonStyles} onClick={handleOnClickFlattenPostions}>
      FLATTEN
    </Button>
  );
};

export default FlattenAllPositions;
