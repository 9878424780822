import { useCallback, useState } from "react";
import { Watchlist } from "../../types";
import Notification from "../../components/shared/notification/Notification";
import axios, { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import watchlistActions from "../../redux/watchlist/watchlistActions";

export const useFetchWorkspaceWatchlist = () => {
  const [watchlist, setWatchlist] = useState<Watchlist | null>(null);
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const loadWorkspaceWatchlist = useCallback(async (watchlistId: Number) => {
    try {
      const response = await axios.get<Watchlist>(`watchlists/${watchlistId}`);
      setWatchlist(response.data);
      dispatch(watchlistActions.dispatchSetWatchlist(response.data))
    } catch (error: any) {
      setError(error);
      Notification.error(`Could not load workspace watchlist, ${error?.message}`);
    } finally {
      setLoaded(true);
    }
  }, []);

  return { watchlist, error, loaded, loadWorkspaceWatchlist, setWatchlist };
};

export const useFetchWorkspaceWatchlists = () => {
  const [watchlists, setWatchlists] = useState<Watchlist[]>([]);
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState(false);

  const loadWorkspaceWatchlists = useCallback(async (workspaceId: Number) => {
    try {
      const response = await axios.get<Watchlist[]>(`get_workspace_watchlists/${workspaceId}`);
      setWatchlists(response.data);
    } catch (error: any) {
      setError(error);
      Notification.error(`Could not load workspace watchlists, ${error?.message}`);
    } finally {
      setLoaded(true);
    }
  }, []);

  return { watchlists, error, loaded, loadWorkspaceWatchlists, setWatchlists };
};

export const useCreateWatchlist = () => {
  const [error, setError] = useState<Error>();
  const [createWatchlistdLoaded, setCreateWatchlistLoaded] = useState(true);

  const createWatchlistAsync = useCallback(
    async (workspaceId: Number, onSuccess?: (response: Watchlist) => void, onFail?: () => void) => {
      try {
        setCreateWatchlistLoaded(false);
        const response = await axios.post(`watchlists?workspace_id=${workspaceId}`);
        // Notification.success("Watchlist created successfully");
        onSuccess?.(response.data as Watchlist);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not create watchlist, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setCreateWatchlistLoaded(true);
      }
    },
    [],
  );

  return { createWatchlistAsync, error, createWatchlistdLoaded };
};

export const useUpdateWatchlist = () => {
  const [error, setError] = useState<Error>();
  const [updateWatchlistLoaded, setUpdateWatchlistLoaded] = useState(true);

  const updateWatchlistAsync = useCallback(
    async (
      watchlistId: Number,
      associatedProducts: string,
      onSuccess?: (response: Watchlist) => void,
      onFail?: () => void,
    ) => {
      try {
        setUpdateWatchlistLoaded(false);
        const response: Watchlist = await axios.put(`${"watchlists"}/${watchlistId}`, {
          associatedProducts,
        });
        // Notification.success("Watchlist updated successfully");
        onSuccess?.(response);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not update watchlist, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setUpdateWatchlistLoaded(true);
      }
    },
    [],
  );

  return { updateWatchlistAsync, error, updateWatchlistLoaded };
};
