import { useState } from "react";

const useOrderCancel = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState<any>(null);

  const requestCancel = (confirmCallback: any) => {
    setOnConfirm(() => confirmCallback);
    setIsDialogOpen(true);
  };

  const confirmCancel = () => {
    if (onConfirm) {
      onConfirm();
    }
    setIsDialogOpen(false);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return { isDialogOpen, requestCancel, confirmCancel, closeDialog };
};

export default useOrderCancel;
