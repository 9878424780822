import { OptionMaturityMetadata } from "../proto/metadata_2";
import * as WebAPI from "../proto/webapi_2";
import { InformationSubscriptions, SubscriptionService } from "./InformationSubscriptions";
import { Deferred } from "../utils/Deferred";
export class UnderlyingSubscription {
  public contractId: string;
  public requestId: string;
  public statusCode: number | null = null;
  public textMessage: string | undefined | null = null;
  public underlyingSymbols: OptionMaturityMetadata[] | null = null;

  resolveDeferred = new Deferred<OptionMaturityMetadata[] | null>();
  resolvePromise = this.resolveDeferred.promise;

  constructor(contractId: string, requestId: string) {
    this.contractId = contractId;
    this.requestId = requestId;
  }

  public async getPromise(): Promise<OptionMaturityMetadata[] | null> {
    return this.resolvePromise;
  }

  public update(informationReport: WebAPI.InformationReport): void {
    this.statusCode = informationReport.statusCode;
    this.textMessage = informationReport.textMessage;
    const underlyings =
      informationReport.optionMaturityListReport && informationReport.optionMaturityListReport?.optionMaturities;

    if (underlyings) {
      this.underlyingSymbols = underlyings;
    }

    if (this.statusCode >= WebAPI.InformationReport_StatusCode.STATUS_CODE_FAILURE) {
      this.resolveDeferred.reject({ contractId: this.contractId, reason: this.textMessage });
    } else if (this.statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_SUCCESS) {
      this.resolveDeferred.resolve(this.underlyingSymbols)
    }
  }
}

export class SymbolsByUnderlyingSubscriptions {
  private static instance: SymbolsByUnderlyingSubscriptions;
  private service!: SubscriptionService;

  static get Instance() {
    return SymbolsByUnderlyingSubscriptions.instance || new SymbolsByUnderlyingSubscriptions();
  }

  constructor() {
    if (!SymbolsByUnderlyingSubscriptions.instance) {
      const informationSubscriptions = new InformationSubscriptions();
      this.service = informationSubscriptions.SubscriptionsManager;
      this.service.createSubscription = (key: string, requestId: string) => {
        return new UnderlyingSubscription(key, requestId);
      };

      SymbolsByUnderlyingSubscriptions.instance = this;
    }
  }

  public get SubscriptionManager() {
    return this.service;
  }
}
