import { useEffect, useRef, useState } from 'react';

const useResponsivePanel = (threshold: number) => {
  const panelRef = useRef<HTMLDivElement | null>(null);
  const [panelSize, setPanelSize] = useState<{ width: number }>({ width: 0 });

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setPanelSize({ width: entry.contentRect.width });
      }
    });

    const currentPanel = panelRef.current;
    if (currentPanel) {
      observer.observe(currentPanel);
    }

    return () => {
      if (currentPanel) {
        observer.unobserve(currentPanel);
      }
    };
  }, []);

  const isSmallPanel = panelSize.width < threshold;

  return { panelRef, isSmallPanel };
};

export default useResponsivePanel;
