import React, { useEffect } from 'react';
import ProgressBar from './ProgressBar';
import './LoadingComponent.css';
export type LoadingComponentProps = {
  withOverlay?: boolean;
};
export default function LoadingComponent({ withOverlay = false }: LoadingComponentProps) {
  useEffect(() => {
    ProgressBar.start();
    return () => {
      ProgressBar.done();
    };
  }, []);

  return <div>
    {withOverlay && <div className='overlay'></div>}
  </div>;
}
