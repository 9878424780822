// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  width: 100%;
  tr td:last-child {
    text-align: right;
  }
}
.divider {
  background-color: beige;
  height: 1px;
  width: 100%;
  margin: 20px 0;
}
.DialogContent {
  background-color: #fafafa;
  border-radius: 4px;
  padding-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/TradeTicketDrawer/ConfirmTradeDialog/confirmTradeDialog.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX;IACE,iBAAiB;EACnB;AACF;AACA;EACE,uBAAuB;EACvB,WAAW;EACX,WAAW;EACX,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".table {\n  width: 100%;\n  tr td:last-child {\n    text-align: right;\n  }\n}\n.divider {\n  background-color: beige;\n  height: 1px;\n  width: 100%;\n  margin: 20px 0;\n}\n.DialogContent {\n  background-color: #fafafa;\n  border-radius: 4px;\n  padding-top: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
