// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: common_1.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Text } from "./common/shared_1";
import Long from "long";

export const protobufPackage = "common_1";

/** Operation result enum. */
export enum OperationStatus {
  /** SUCCESS - Successful result. */
  SUCCESS = 1,
  /** FAILURE - Failed result. */
  FAILURE = 2,
  /** ACCEPTED - Request accepted by CMS API and will be scheduled for processing */
  ACCEPTED = 3,
  /** QUEUED - Request was defered in CMS API Waiting Queue */
  QUEUED = 4,
  /** IN_PROCESSING - Request processing started by CMS API */
  IN_PROCESSING = 5,
  /** CANCELED - Request was cancelled from Waiting Queue */
  CANCELED = 6,
  UNRECOGNIZED = -1,
}

export function operationStatusFromJSON(object: any): OperationStatus {
  switch (object) {
    case 1:
    case "SUCCESS":
      return OperationStatus.SUCCESS;
    case 2:
    case "FAILURE":
      return OperationStatus.FAILURE;
    case 3:
    case "ACCEPTED":
      return OperationStatus.ACCEPTED;
    case 4:
    case "QUEUED":
      return OperationStatus.QUEUED;
    case 5:
    case "IN_PROCESSING":
      return OperationStatus.IN_PROCESSING;
    case 6:
    case "CANCELED":
      return OperationStatus.CANCELED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OperationStatus.UNRECOGNIZED;
  }
}

export function operationStatusToJSON(object: OperationStatus): string {
  switch (object) {
    case OperationStatus.SUCCESS:
      return "SUCCESS";
    case OperationStatus.FAILURE:
      return "FAILURE";
    case OperationStatus.ACCEPTED:
      return "ACCEPTED";
    case OperationStatus.QUEUED:
      return "QUEUED";
    case OperationStatus.IN_PROCESSING:
      return "IN_PROCESSING";
    case OperationStatus.CANCELED:
      return "CANCELED";
    case OperationStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Domain of the login information */
export enum LoginDomain {
  /** CQG_TRADE_ROUTING - CQG Gateway login domain. */
  CQG_TRADE_ROUTING = 2,
  /** CQG_SYSTEM - CQG System (IC, QTrader, etc.) login domain. */
  CQG_SYSTEM = 3,
  /** CQG_ADMIN - CQG Admin (CMS, CAST, CAST2) login domain. */
  CQG_ADMIN = 4,
  UNRECOGNIZED = -1,
}

export function loginDomainFromJSON(object: any): LoginDomain {
  switch (object) {
    case 2:
    case "CQG_TRADE_ROUTING":
      return LoginDomain.CQG_TRADE_ROUTING;
    case 3:
    case "CQG_SYSTEM":
      return LoginDomain.CQG_SYSTEM;
    case 4:
    case "CQG_ADMIN":
      return LoginDomain.CQG_ADMIN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LoginDomain.UNRECOGNIZED;
  }
}

export function loginDomainToJSON(object: LoginDomain): string {
  switch (object) {
    case LoginDomain.CQG_TRADE_ROUTING:
      return "CQG_TRADE_ROUTING";
    case LoginDomain.CQG_SYSTEM:
      return "CQG_SYSTEM";
    case LoginDomain.CQG_ADMIN:
      return "CQG_ADMIN";
    case LoginDomain.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Types of common lookup properties. */
export enum CommonLookupPropertyType {
  AUTHENTICATION_SYSTEM = 201,
  CONNECTION_POINT = 202,
  EXCHANGE = 203,
  COUNTRY = 204,
  REGION = 205,
  LOG_EVENT_TYPE = 206,
  REGEX_VALIDATION_RULE = 207,
  UNRECOGNIZED = -1,
}

export function commonLookupPropertyTypeFromJSON(object: any): CommonLookupPropertyType {
  switch (object) {
    case 201:
    case "AUTHENTICATION_SYSTEM":
      return CommonLookupPropertyType.AUTHENTICATION_SYSTEM;
    case 202:
    case "CONNECTION_POINT":
      return CommonLookupPropertyType.CONNECTION_POINT;
    case 203:
    case "EXCHANGE":
      return CommonLookupPropertyType.EXCHANGE;
    case 204:
    case "COUNTRY":
      return CommonLookupPropertyType.COUNTRY;
    case 205:
    case "REGION":
      return CommonLookupPropertyType.REGION;
    case 206:
    case "LOG_EVENT_TYPE":
      return CommonLookupPropertyType.LOG_EVENT_TYPE;
    case 207:
    case "REGEX_VALIDATION_RULE":
      return CommonLookupPropertyType.REGEX_VALIDATION_RULE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CommonLookupPropertyType.UNRECOGNIZED;
  }
}

export function commonLookupPropertyTypeToJSON(object: CommonLookupPropertyType): string {
  switch (object) {
    case CommonLookupPropertyType.AUTHENTICATION_SYSTEM:
      return "AUTHENTICATION_SYSTEM";
    case CommonLookupPropertyType.CONNECTION_POINT:
      return "CONNECTION_POINT";
    case CommonLookupPropertyType.EXCHANGE:
      return "EXCHANGE";
    case CommonLookupPropertyType.COUNTRY:
      return "COUNTRY";
    case CommonLookupPropertyType.REGION:
      return "REGION";
    case CommonLookupPropertyType.LOG_EVENT_TYPE:
      return "LOG_EVENT_TYPE";
    case CommonLookupPropertyType.REGEX_VALIDATION_RULE:
      return "REGEX_VALIDATION_RULE";
    case CommonLookupPropertyType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** List of allowed AuthServer special service operations. */
export enum AuthServerSpecialOperation {
  /**
   * COMPLETE_LOGON - Complete regular logon that was previously failed due to required operations
   * exist for logon. This operation is enabled only for SSTs returned by AuthServer
   * in LogonResult when logon fails with one of following codes:
   * - PASSWORD_EXPIRED;
   * - UNSIGNED_AGREEMENT;
   * - OPERATIONS_REQUIRED;
   * - SECOND_FACTOR_INIT_REQUIRED.
   */
  COMPLETE_LOGON = 0,
  /** SET_PASSWORD - Set static password without providing old static password. */
  SET_PASSWORD = 1,
  /** SETUP_SECOND_FACTOR - Setup new second factor regardless if it is set already. */
  SETUP_SECOND_FACTOR = 2,
  /** CHANGE_EXPIRED_PASSWORD - Change expired static password. */
  CHANGE_EXPIRED_PASSWORD = 3,
  /** SIGN_AGREEMENT - Sign agreement(s). */
  SIGN_AGREEMENT = 4,
  /** VERIFY_PHONE - Verify user's phone number. */
  VERIFY_PHONE = 5,
  /** PASS_CERTIFICATION - Pass certification request. */
  PASS_CERTIFICATION = 6,
  UNRECOGNIZED = -1,
}

export function authServerSpecialOperationFromJSON(object: any): AuthServerSpecialOperation {
  switch (object) {
    case 0:
    case "COMPLETE_LOGON":
      return AuthServerSpecialOperation.COMPLETE_LOGON;
    case 1:
    case "SET_PASSWORD":
      return AuthServerSpecialOperation.SET_PASSWORD;
    case 2:
    case "SETUP_SECOND_FACTOR":
      return AuthServerSpecialOperation.SETUP_SECOND_FACTOR;
    case 3:
    case "CHANGE_EXPIRED_PASSWORD":
      return AuthServerSpecialOperation.CHANGE_EXPIRED_PASSWORD;
    case 4:
    case "SIGN_AGREEMENT":
      return AuthServerSpecialOperation.SIGN_AGREEMENT;
    case 5:
    case "VERIFY_PHONE":
      return AuthServerSpecialOperation.VERIFY_PHONE;
    case 6:
    case "PASS_CERTIFICATION":
      return AuthServerSpecialOperation.PASS_CERTIFICATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AuthServerSpecialOperation.UNRECOGNIZED;
  }
}

export function authServerSpecialOperationToJSON(object: AuthServerSpecialOperation): string {
  switch (object) {
    case AuthServerSpecialOperation.COMPLETE_LOGON:
      return "COMPLETE_LOGON";
    case AuthServerSpecialOperation.SET_PASSWORD:
      return "SET_PASSWORD";
    case AuthServerSpecialOperation.SETUP_SECOND_FACTOR:
      return "SETUP_SECOND_FACTOR";
    case AuthServerSpecialOperation.CHANGE_EXPIRED_PASSWORD:
      return "CHANGE_EXPIRED_PASSWORD";
    case AuthServerSpecialOperation.SIGN_AGREEMENT:
      return "SIGN_AGREEMENT";
    case AuthServerSpecialOperation.VERIFY_PHONE:
      return "VERIFY_PHONE";
    case AuthServerSpecialOperation.PASS_CERTIFICATION:
      return "PASS_CERTIFICATION";
    case AuthServerSpecialOperation.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** List of credential types. */
export enum CredentialType {
  STATIC_PASSWORD = 1,
  SECOND_FACTOR = 2,
  UNRECOGNIZED = -1,
}

export function credentialTypeFromJSON(object: any): CredentialType {
  switch (object) {
    case 1:
    case "STATIC_PASSWORD":
      return CredentialType.STATIC_PASSWORD;
    case 2:
    case "SECOND_FACTOR":
      return CredentialType.SECOND_FACTOR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CredentialType.UNRECOGNIZED;
  }
}

export function credentialTypeToJSON(object: CredentialType): string {
  switch (object) {
    case CredentialType.STATIC_PASSWORD:
      return "STATIC_PASSWORD";
    case CredentialType.SECOND_FACTOR:
      return "SECOND_FACTOR";
    case CredentialType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** List of possible entities. */
export enum EntityType {
  ENTITY_TYPE_ACCOUNT = 1,
  ENTITY_TYPE_ORDER = 2,
  ENTITY_TYPE_LOGIN = 3,
  ENTITY_TYPE_PROFILE = 4,
  ENTITY_TYPE_LEGAL_DOCUMENT = 5,
  UNRECOGNIZED = -1,
}

export function entityTypeFromJSON(object: any): EntityType {
  switch (object) {
    case 1:
    case "ENTITY_TYPE_ACCOUNT":
      return EntityType.ENTITY_TYPE_ACCOUNT;
    case 2:
    case "ENTITY_TYPE_ORDER":
      return EntityType.ENTITY_TYPE_ORDER;
    case 3:
    case "ENTITY_TYPE_LOGIN":
      return EntityType.ENTITY_TYPE_LOGIN;
    case 4:
    case "ENTITY_TYPE_PROFILE":
      return EntityType.ENTITY_TYPE_PROFILE;
    case 5:
    case "ENTITY_TYPE_LEGAL_DOCUMENT":
      return EntityType.ENTITY_TYPE_LEGAL_DOCUMENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EntityType.UNRECOGNIZED;
  }
}

export function entityTypeToJSON(object: EntityType): string {
  switch (object) {
    case EntityType.ENTITY_TYPE_ACCOUNT:
      return "ENTITY_TYPE_ACCOUNT";
    case EntityType.ENTITY_TYPE_ORDER:
      return "ENTITY_TYPE_ORDER";
    case EntityType.ENTITY_TYPE_LOGIN:
      return "ENTITY_TYPE_LOGIN";
    case EntityType.ENTITY_TYPE_PROFILE:
      return "ENTITY_TYPE_PROFILE";
    case EntityType.ENTITY_TYPE_LEGAL_DOCUMENT:
      return "ENTITY_TYPE_LEGAL_DOCUMENT";
    case EntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum LogonResultCode {
  /**
   * LOGON_SUCCESS - // success codes
   * User is logged in to the system.
   */
  LOGON_SUCCESS = 0,
  /**
   * LOGON_FAILURE - // failure codes (100+)
   * General failure.
   */
  LOGON_FAILURE = 101,
  /**
   * NO_ONETIME_PASSWORD - One-time password is required for this user but it was not sent, repeat logon
   * with one-time password.
   */
  NO_ONETIME_PASSWORD = 103,
  /** PASSWORD_EXPIRED - User password is expired, only change password operation is allowed. */
  PASSWORD_EXPIRED = 104,
  /** ROUTINE_ERROR - The negotiation rules for LogonRoutineClient have been violated, e.g. user has specified several fields at once in one message. */
  ROUTINE_ERROR = 105,
  /** WRONG_PARAMETERS - Some fields in LogonInit have wrong values. */
  WRONG_PARAMETERS = 106,
  /** USER_AUTO_LOCKOUT - User has been locked out by the system. */
  USER_AUTO_LOCKOUT = 107,
  /** USER_MANUAL_LOCKOUT - User has been locked out by admins. */
  USER_MANUAL_LOCKOUT = 108,
  /** SECOND_FACTOR_INIT_REQUIRED - Second factor authentication is required from this user, but it is not initialised, initialize and repeat logon */
  SECOND_FACTOR_INIT_REQUIRED = 109,
  /** INCOMPATIBLE_CLIENT - Client application version isn't supported, the client application must be upgraded. */
  INCOMPATIBLE_CLIENT = 110,
  /** UNSIGNED_AGREEMENT - There is unsigned/rejected agreement(s) that denies logon. */
  UNSIGNED_AGREEMENT = 111,
  /** TOO_MANY_LOGONS - User exceeded allowed number of logons. */
  TOO_MANY_LOGONS = 112,
  /** PHONE_VERIFICATION_REQUIRED - Verification of user's phone number is required. */
  PHONE_VERIFICATION_REQUIRED = 113,
  /** ACCESS_DENIED - No resources granted to the user. */
  ACCESS_DENIED = 114,
  UNRECOGNIZED = -1,
}

export function logonResultCodeFromJSON(object: any): LogonResultCode {
  switch (object) {
    case 0:
    case "LOGON_SUCCESS":
      return LogonResultCode.LOGON_SUCCESS;
    case 101:
    case "LOGON_FAILURE":
      return LogonResultCode.LOGON_FAILURE;
    case 103:
    case "NO_ONETIME_PASSWORD":
      return LogonResultCode.NO_ONETIME_PASSWORD;
    case 104:
    case "PASSWORD_EXPIRED":
      return LogonResultCode.PASSWORD_EXPIRED;
    case 105:
    case "ROUTINE_ERROR":
      return LogonResultCode.ROUTINE_ERROR;
    case 106:
    case "WRONG_PARAMETERS":
      return LogonResultCode.WRONG_PARAMETERS;
    case 107:
    case "USER_AUTO_LOCKOUT":
      return LogonResultCode.USER_AUTO_LOCKOUT;
    case 108:
    case "USER_MANUAL_LOCKOUT":
      return LogonResultCode.USER_MANUAL_LOCKOUT;
    case 109:
    case "SECOND_FACTOR_INIT_REQUIRED":
      return LogonResultCode.SECOND_FACTOR_INIT_REQUIRED;
    case 110:
    case "INCOMPATIBLE_CLIENT":
      return LogonResultCode.INCOMPATIBLE_CLIENT;
    case 111:
    case "UNSIGNED_AGREEMENT":
      return LogonResultCode.UNSIGNED_AGREEMENT;
    case 112:
    case "TOO_MANY_LOGONS":
      return LogonResultCode.TOO_MANY_LOGONS;
    case 113:
    case "PHONE_VERIFICATION_REQUIRED":
      return LogonResultCode.PHONE_VERIFICATION_REQUIRED;
    case 114:
    case "ACCESS_DENIED":
      return LogonResultCode.ACCESS_DENIED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LogonResultCode.UNRECOGNIZED;
  }
}

export function logonResultCodeToJSON(object: LogonResultCode): string {
  switch (object) {
    case LogonResultCode.LOGON_SUCCESS:
      return "LOGON_SUCCESS";
    case LogonResultCode.LOGON_FAILURE:
      return "LOGON_FAILURE";
    case LogonResultCode.NO_ONETIME_PASSWORD:
      return "NO_ONETIME_PASSWORD";
    case LogonResultCode.PASSWORD_EXPIRED:
      return "PASSWORD_EXPIRED";
    case LogonResultCode.ROUTINE_ERROR:
      return "ROUTINE_ERROR";
    case LogonResultCode.WRONG_PARAMETERS:
      return "WRONG_PARAMETERS";
    case LogonResultCode.USER_AUTO_LOCKOUT:
      return "USER_AUTO_LOCKOUT";
    case LogonResultCode.USER_MANUAL_LOCKOUT:
      return "USER_MANUAL_LOCKOUT";
    case LogonResultCode.SECOND_FACTOR_INIT_REQUIRED:
      return "SECOND_FACTOR_INIT_REQUIRED";
    case LogonResultCode.INCOMPATIBLE_CLIENT:
      return "INCOMPATIBLE_CLIENT";
    case LogonResultCode.UNSIGNED_AGREEMENT:
      return "UNSIGNED_AGREEMENT";
    case LogonResultCode.TOO_MANY_LOGONS:
      return "TOO_MANY_LOGONS";
    case LogonResultCode.PHONE_VERIFICATION_REQUIRED:
      return "PHONE_VERIFICATION_REQUIRED";
    case LogonResultCode.ACCESS_DENIED:
      return "ACCESS_DENIED";
    case LogonResultCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Represents admin login scope. */
export enum AdminLoginScope {
  CQG = 1,
  BROKERAGE = 2,
  SALES_REP = 3,
  TRADER = 4,
  UNRECOGNIZED = -1,
}

export function adminLoginScopeFromJSON(object: any): AdminLoginScope {
  switch (object) {
    case 1:
    case "CQG":
      return AdminLoginScope.CQG;
    case 2:
    case "BROKERAGE":
      return AdminLoginScope.BROKERAGE;
    case 3:
    case "SALES_REP":
      return AdminLoginScope.SALES_REP;
    case 4:
    case "TRADER":
      return AdminLoginScope.TRADER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AdminLoginScope.UNRECOGNIZED;
  }
}

export function adminLoginScopeToJSON(object: AdminLoginScope): string {
  switch (object) {
    case AdminLoginScope.CQG:
      return "CQG";
    case AdminLoginScope.BROKERAGE:
      return "BROKERAGE";
    case AdminLoginScope.SALES_REP:
      return "SALES_REP";
    case AdminLoginScope.TRADER:
      return "TRADER";
    case AdminLoginScope.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Authentication activity types. */
export enum AuthActivityType {
  /** LOGIN - Login. */
  LOGIN = 1,
  /** PASSWORD_CHANGE - Password change. */
  PASSWORD_CHANGE = 2,
  /** CLEAR_LOCKOUT - Clear lockout (from CAST). */
  CLEAR_LOCKOUT = 3,
  /** CHANGE_2FA - Change 2FA */
  CHANGE_2FA = 4,
  /** ERASE_2FA - Erase 2FA */
  ERASE_2FA = 5,
  /** ERASE_PASSWORD - Erase password */
  ERASE_PASSWORD = 6,
  UNRECOGNIZED = -1,
}

export function authActivityTypeFromJSON(object: any): AuthActivityType {
  switch (object) {
    case 1:
    case "LOGIN":
      return AuthActivityType.LOGIN;
    case 2:
    case "PASSWORD_CHANGE":
      return AuthActivityType.PASSWORD_CHANGE;
    case 3:
    case "CLEAR_LOCKOUT":
      return AuthActivityType.CLEAR_LOCKOUT;
    case 4:
    case "CHANGE_2FA":
      return AuthActivityType.CHANGE_2FA;
    case 5:
    case "ERASE_2FA":
      return AuthActivityType.ERASE_2FA;
    case 6:
    case "ERASE_PASSWORD":
      return AuthActivityType.ERASE_PASSWORD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AuthActivityType.UNRECOGNIZED;
  }
}

export function authActivityTypeToJSON(object: AuthActivityType): string {
  switch (object) {
    case AuthActivityType.LOGIN:
      return "LOGIN";
    case AuthActivityType.PASSWORD_CHANGE:
      return "PASSWORD_CHANGE";
    case AuthActivityType.CLEAR_LOCKOUT:
      return "CLEAR_LOCKOUT";
    case AuthActivityType.CHANGE_2FA:
      return "CHANGE_2FA";
    case AuthActivityType.ERASE_2FA:
      return "ERASE_2FA";
    case AuthActivityType.ERASE_PASSWORD:
      return "ERASE_PASSWORD";
    case AuthActivityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** List of possible profile legal types. */
export enum LegalType {
  /** INDIVIDUAL - Individual. */
  INDIVIDUAL = 1,
  /** JOINT - Joint-stock company. */
  JOINT = 2,
  /** LIMITED - Limited partnership. */
  LIMITED = 3,
  /** TRUST - Trust company. */
  TRUST = 4,
  /** CORPORATE - Corporate group. */
  CORPORATE = 5,
  /** PARTNERSHIP - General partnership. */
  PARTNERSHIP = 6,
  UNRECOGNIZED = -1,
}

export function legalTypeFromJSON(object: any): LegalType {
  switch (object) {
    case 1:
    case "INDIVIDUAL":
      return LegalType.INDIVIDUAL;
    case 2:
    case "JOINT":
      return LegalType.JOINT;
    case 3:
    case "LIMITED":
      return LegalType.LIMITED;
    case 4:
    case "TRUST":
      return LegalType.TRUST;
    case 5:
    case "CORPORATE":
      return LegalType.CORPORATE;
    case 6:
    case "PARTNERSHIP":
      return LegalType.PARTNERSHIP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LegalType.UNRECOGNIZED;
  }
}

export function legalTypeToJSON(object: LegalType): string {
  switch (object) {
    case LegalType.INDIVIDUAL:
      return "INDIVIDUAL";
    case LegalType.JOINT:
      return "JOINT";
    case LegalType.LIMITED:
      return "LIMITED";
    case LegalType.TRUST:
      return "TRUST";
    case LegalType.CORPORATE:
      return "CORPORATE";
    case LegalType.PARTNERSHIP:
      return "PARTNERSHIP";
    case LegalType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ProfileType {
  PROFILE_TYPE_CUSTOMER = 1,
  PROFILE_TYPE_ADMIN = 2,
  PROFILE_TYPE_BROKERAGE = 3,
  PROFILE_TYPE_SUBSCRIBER = 4,
  PROFILE_TYPE_SALES_SERIES = 5,
  UNRECOGNIZED = -1,
}

export function profileTypeFromJSON(object: any): ProfileType {
  switch (object) {
    case 1:
    case "PROFILE_TYPE_CUSTOMER":
      return ProfileType.PROFILE_TYPE_CUSTOMER;
    case 2:
    case "PROFILE_TYPE_ADMIN":
      return ProfileType.PROFILE_TYPE_ADMIN;
    case 3:
    case "PROFILE_TYPE_BROKERAGE":
      return ProfileType.PROFILE_TYPE_BROKERAGE;
    case 4:
    case "PROFILE_TYPE_SUBSCRIBER":
      return ProfileType.PROFILE_TYPE_SUBSCRIBER;
    case 5:
    case "PROFILE_TYPE_SALES_SERIES":
      return ProfileType.PROFILE_TYPE_SALES_SERIES;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProfileType.UNRECOGNIZED;
  }
}

export function profileTypeToJSON(object: ProfileType): string {
  switch (object) {
    case ProfileType.PROFILE_TYPE_CUSTOMER:
      return "PROFILE_TYPE_CUSTOMER";
    case ProfileType.PROFILE_TYPE_ADMIN:
      return "PROFILE_TYPE_ADMIN";
    case ProfileType.PROFILE_TYPE_BROKERAGE:
      return "PROFILE_TYPE_BROKERAGE";
    case ProfileType.PROFILE_TYPE_SUBSCRIBER:
      return "PROFILE_TYPE_SUBSCRIBER";
    case ProfileType.PROFILE_TYPE_SALES_SERIES:
      return "PROFILE_TYPE_SALES_SERIES";
    case ProfileType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum InternalEntityType {
  INTERNAL_ENTITY_TYPE_ACCOUNT = 1,
  INTERNAL_ENTITY_TYPE_ORDER = 2,
  INTERNAL_ENTITY_TYPE_TRADER = 3,
  INTERNAL_ENTITY_TYPE_CUSTOMER = 4,
  INTERNAL_ENTITY_TYPE_SYSTEM = 5,
  INTERNAL_ENTITY_TYPE_CAST_USER = 6,
  INTERNAL_ENTITY_TYPE_SALES_SERIES = 7,
  INTERNAL_ENTITY_TYPE_FCM = 8,
  INTERNAL_ENTITY_TYPE_ROUTE_GROUP = 9,
  INTERNAL_ENTITY_TYPE_EXCHANGE = 10,
  INTERNAL_ENTITY_TYPE_EXECUTION_SYSTEM_ROUTE = 11,
  UNRECOGNIZED = -1,
}

export function internalEntityTypeFromJSON(object: any): InternalEntityType {
  switch (object) {
    case 1:
    case "INTERNAL_ENTITY_TYPE_ACCOUNT":
      return InternalEntityType.INTERNAL_ENTITY_TYPE_ACCOUNT;
    case 2:
    case "INTERNAL_ENTITY_TYPE_ORDER":
      return InternalEntityType.INTERNAL_ENTITY_TYPE_ORDER;
    case 3:
    case "INTERNAL_ENTITY_TYPE_TRADER":
      return InternalEntityType.INTERNAL_ENTITY_TYPE_TRADER;
    case 4:
    case "INTERNAL_ENTITY_TYPE_CUSTOMER":
      return InternalEntityType.INTERNAL_ENTITY_TYPE_CUSTOMER;
    case 5:
    case "INTERNAL_ENTITY_TYPE_SYSTEM":
      return InternalEntityType.INTERNAL_ENTITY_TYPE_SYSTEM;
    case 6:
    case "INTERNAL_ENTITY_TYPE_CAST_USER":
      return InternalEntityType.INTERNAL_ENTITY_TYPE_CAST_USER;
    case 7:
    case "INTERNAL_ENTITY_TYPE_SALES_SERIES":
      return InternalEntityType.INTERNAL_ENTITY_TYPE_SALES_SERIES;
    case 8:
    case "INTERNAL_ENTITY_TYPE_FCM":
      return InternalEntityType.INTERNAL_ENTITY_TYPE_FCM;
    case 9:
    case "INTERNAL_ENTITY_TYPE_ROUTE_GROUP":
      return InternalEntityType.INTERNAL_ENTITY_TYPE_ROUTE_GROUP;
    case 10:
    case "INTERNAL_ENTITY_TYPE_EXCHANGE":
      return InternalEntityType.INTERNAL_ENTITY_TYPE_EXCHANGE;
    case 11:
    case "INTERNAL_ENTITY_TYPE_EXECUTION_SYSTEM_ROUTE":
      return InternalEntityType.INTERNAL_ENTITY_TYPE_EXECUTION_SYSTEM_ROUTE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return InternalEntityType.UNRECOGNIZED;
  }
}

export function internalEntityTypeToJSON(object: InternalEntityType): string {
  switch (object) {
    case InternalEntityType.INTERNAL_ENTITY_TYPE_ACCOUNT:
      return "INTERNAL_ENTITY_TYPE_ACCOUNT";
    case InternalEntityType.INTERNAL_ENTITY_TYPE_ORDER:
      return "INTERNAL_ENTITY_TYPE_ORDER";
    case InternalEntityType.INTERNAL_ENTITY_TYPE_TRADER:
      return "INTERNAL_ENTITY_TYPE_TRADER";
    case InternalEntityType.INTERNAL_ENTITY_TYPE_CUSTOMER:
      return "INTERNAL_ENTITY_TYPE_CUSTOMER";
    case InternalEntityType.INTERNAL_ENTITY_TYPE_SYSTEM:
      return "INTERNAL_ENTITY_TYPE_SYSTEM";
    case InternalEntityType.INTERNAL_ENTITY_TYPE_CAST_USER:
      return "INTERNAL_ENTITY_TYPE_CAST_USER";
    case InternalEntityType.INTERNAL_ENTITY_TYPE_SALES_SERIES:
      return "INTERNAL_ENTITY_TYPE_SALES_SERIES";
    case InternalEntityType.INTERNAL_ENTITY_TYPE_FCM:
      return "INTERNAL_ENTITY_TYPE_FCM";
    case InternalEntityType.INTERNAL_ENTITY_TYPE_ROUTE_GROUP:
      return "INTERNAL_ENTITY_TYPE_ROUTE_GROUP";
    case InternalEntityType.INTERNAL_ENTITY_TYPE_EXCHANGE:
      return "INTERNAL_ENTITY_TYPE_EXCHANGE";
    case InternalEntityType.INTERNAL_ENTITY_TYPE_EXECUTION_SYSTEM_ROUTE:
      return "INTERNAL_ENTITY_TYPE_EXECUTION_SYSTEM_ROUTE";
    case InternalEntityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Message from server. */
export interface UserMessage {
  /** This field is associated with MessageType enum type. */
  messageType: number;
  /** Information about source of the message. */
  source?:
    | string
    | undefined;
  /** Message subject. */
  subject?:
    | string
    | undefined;
  /** Message text. */
  text:
    | Text
    | undefined;
  /** Optional time when this message is expired, it should be hidden from user after this time (UTC). */
  expirationUtcTime?: number | undefined;
}

/** Type of the message. */
export enum UserMessage_MessageType {
  /** CRITICAL_ERROR - Critical error message. */
  CRITICAL_ERROR = 1,
  /** WARNING - Warning. */
  WARNING = 2,
  /** INFO - General information. */
  INFO = 3,
  /** LOG - This message shouldn't be visible for users, only dumped into a log file. */
  LOG = 4,
  UNRECOGNIZED = -1,
}

export function userMessage_MessageTypeFromJSON(object: any): UserMessage_MessageType {
  switch (object) {
    case 1:
    case "CRITICAL_ERROR":
      return UserMessage_MessageType.CRITICAL_ERROR;
    case 2:
    case "WARNING":
      return UserMessage_MessageType.WARNING;
    case 3:
    case "INFO":
      return UserMessage_MessageType.INFO;
    case 4:
    case "LOG":
      return UserMessage_MessageType.LOG;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserMessage_MessageType.UNRECOGNIZED;
  }
}

export function userMessage_MessageTypeToJSON(object: UserMessage_MessageType): string {
  switch (object) {
    case UserMessage_MessageType.CRITICAL_ERROR:
      return "CRITICAL_ERROR";
    case UserMessage_MessageType.WARNING:
      return "WARNING";
    case UserMessage_MessageType.INFO:
      return "INFO";
    case UserMessage_MessageType.LOG:
      return "LOG";
    case UserMessage_MessageType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Logon to the system and open a new session. */
export interface Logon {
  /** [required] Current protocol version that is used by a client. Client has to fill it with values from ProtocolVersion enum. */
  protocolVersionMinor?: number | undefined;
  protocolVersionMajor?:
    | number
    | undefined;
  /**
   * Username.
   * Ignored when access_token is provided.
   */
  userName?:
    | string
    | undefined;
  /**
   * User password.
   * Ignored when access_token is provided.
   */
  password?:
    | string
    | undefined;
  /**
   * Identifier of a client application as assigned by CQG.
   * Ignored when access_token is provided.
   */
  clientAppId?:
    | string
    | undefined;
  /** Version of a client application. */
  clientVersion?:
    | string
    | undefined;
  /**
   * List of settings for a new session.
   * This field is associated with SessionSetting enum type.
   */
  sessionSettings: number[];
  /**
   * Optional field that indicates if possible concurrent sessions for this user should be forcedly dropped.
   * If it is set to false then Logon may fail with corresponding error message.
   * If it is omitted or set to true then concurrent sessions for this user will be dropped.
   */
  dropConcurrentSession?:
    | boolean
    | undefined;
  /**
   * Private label name.
   * Ignored when access_token is provided.
   */
  privateLabel?:
    | string
    | undefined;
  /** Access token from CQG auth server. */
  accessToken?:
    | string
    | undefined;
  /**
   * Indicates that CMS API must notify client with additional requests statuses:
   * ACCEPTED, QUEUED, IN_PROCESSING, CANCELED
   */
  subscribeOnRequestStatusChange?: boolean | undefined;
}

/** Session settings for additional functionality. */
export enum Logon_SessionSetting {
  /**
   * ALLOW_SESSION_RESTORE - Allows restoring session in case of an accidental disconnect (see RestoreOrJoinSession message).
   * If the setting is not specified then termination of the last connection also terminates the session without ability to restore it.
   * For security reasons clients should not specify this setting without using the restore session functionality.
   */
  ALLOW_SESSION_RESTORE = 1,
  /**
   * ALLOW_SESSION_JOIN - Allows other new connections to join the session (see RestoreOrJoinSession message) and work concurrently.
   * If the setting is not specified then the session can have only one connection at each moment and a new connection pushes the old one out (if the session restore is allowed).
   * For security reasons clients should not specify this setting without using join session functionality.
   */
  ALLOW_SESSION_JOIN = 2,
  UNRECOGNIZED = -1,
}

export function logon_SessionSettingFromJSON(object: any): Logon_SessionSetting {
  switch (object) {
    case 1:
    case "ALLOW_SESSION_RESTORE":
      return Logon_SessionSetting.ALLOW_SESSION_RESTORE;
    case 2:
    case "ALLOW_SESSION_JOIN":
      return Logon_SessionSetting.ALLOW_SESSION_JOIN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Logon_SessionSetting.UNRECOGNIZED;
  }
}

export function logon_SessionSettingToJSON(object: Logon_SessionSetting): string {
  switch (object) {
    case Logon_SessionSetting.ALLOW_SESSION_RESTORE:
      return "ALLOW_SESSION_RESTORE";
    case Logon_SessionSetting.ALLOW_SESSION_JOIN:
      return "ALLOW_SESSION_JOIN";
    case Logon_SessionSetting.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Client request to restore session because of accidental disconnect or join the same session.
 * In non-trusted mode, this operation is possible only from the same IP address.
 * Permissions to use this functionality is regulated by ALLOW_SESSION_RESTORE/ALLOW_SESSION_JOIN session settings
 * which are specified in Logon message.
 */
export interface RestoreOrJoinSession {
  /** Token of the session to restore or join, it has limited lifetime after disconnect. */
  sessionToken: string;
  /** Identifier of the client application as assigned by CQG. */
  clientAppId: string;
  /** Can be used by trusted clients (components connected in trust mode) to specify real client IP they act on behalf of. */
  clientIpAddress?:
    | string
    | undefined;
  /**
   * Indicates that CMS API must notify client with additional requests statuses:
   * ACCEPTED, QUEUED, IN_PROCESSING, CANCELED
   */
  subscribeOnRequestStatusChange?:
    | boolean
    | undefined;
  /** This field is associated with OperationType enum. */
  operationType?: number | undefined;
}

/** Type of operation on user session. */
export enum RestoreOrJoinSession_OperationType {
  /** RESTORE - Restore session. */
  RESTORE = 1,
  /** JOIN - Join session. */
  JOIN = 2,
  UNRECOGNIZED = -1,
}

export function restoreOrJoinSession_OperationTypeFromJSON(object: any): RestoreOrJoinSession_OperationType {
  switch (object) {
    case 1:
    case "RESTORE":
      return RestoreOrJoinSession_OperationType.RESTORE;
    case 2:
    case "JOIN":
      return RestoreOrJoinSession_OperationType.JOIN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RestoreOrJoinSession_OperationType.UNRECOGNIZED;
  }
}

export function restoreOrJoinSession_OperationTypeToJSON(object: RestoreOrJoinSession_OperationType): string {
  switch (object) {
    case RestoreOrJoinSession_OperationType.RESTORE:
      return "RESTORE";
    case RestoreOrJoinSession_OperationType.JOIN:
      return "JOIN";
    case RestoreOrJoinSession_OperationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Logoff session. */
export interface Logoff {
}

export interface Credentials {
  /** User name to login. */
  userName: string;
  /** User password. */
  password?: string | undefined;
}

/** Result of the Logon operation. */
export interface LogonResult {
  /** Current protocol version of the server. For information only. */
  protocolVersionMinor: number;
  protocolVersionMajor: number;
  /**
   * Operation status.
   * This field is associated with OperationStatus enum.
   */
  operationStatus: number;
  /** Message in case of logon failure. */
  errorMessage?:
    | Text
    | undefined;
  /** Token of a new session if logon succeeded. */
  sessionToken?: string | undefined;
}

/** Result of session restore or join attempt. */
export interface RestoreOrJoinSessionResult {
  /** This field is associated with OperationStatus enum. */
  operationStatus: number;
  /** Message in case of failure. */
  errorMessage?: Text | undefined;
}

/**
 * Server notification about closing user's session,
 * server closes connection after this message.
 */
export interface LoggedOff {
  /** Logoff reason. */
  reason?: Text | undefined;
}

/** Request of entitlement categories. */
export interface EntitlementCategoryListRequest {
}

/** Request of entitlement service information. */
export interface EntitlementServiceRequest {
  /** Id of the requested service. */
  entitlementServiceId: number;
}

/**
 * Request of entitlement service list
 * available for the current session login.
 */
export interface EntitlementServiceListRequest {
  /** Optional list of brokerage IDs. If it is not specified all brokerages for current session will be used. */
  brokerageId: string[];
  /**
   * Indicates that CMS API must return retired services as well.
   * By default retired services are not returned.
   */
  includeRetired?: boolean | undefined;
}

/** Entitlement Service details. */
export interface EntitlementService {
  /** Identifier. */
  id: number;
  /** Name. */
  name:
    | Text
    | undefined;
  /** List of assigned entitlement category IDs. */
  categoryId: number[];
  /** Service prices. */
  price: Price[];
  /** Description. */
  description:
    | Text
    | undefined;
  /**
   * Authorization Type.
   * This field is associated with EntitlementService.AuthorizationType enum type.
   */
  authorizationType: number;
  /** List of this service constraints. */
  constraint: EntitlementConstraint[];
  /**
   * [obsolete] Indicates if a separate agreement is required according to
   * original service provider rules (False if omitted).
   *
   * @deprecated
   */
  obsoleteAgreementRequired?:
    | boolean
    | undefined;
  /**
   * [obsolete] Optional agreement text.
   *
   * @deprecated
   */
  obsoleteAgreement?:
    | Text
    | undefined;
  /**
   * Optional link of a service to a subscriber type.
   * The service is allowed for any subscriber type if omitted.
   * This field is associated with User.SubscriberType enum type.
   */
  subscriberType?:
    | number
    | undefined;
  /** Indicates whether billing brokerage is mandatory in order to enable the service for a login. */
  billingBrokerageRequired?:
    | boolean
    | undefined;
  /**
   * Login domains.
   * This field is associated with LoginDomain enum type.
   */
  loginDomains: number[];
  /**
   * Special options.
   * This field is associated with EntitlementService.ServiceOption enum type.
   */
  options: number[];
  /** Included items. */
  items: EntitlementServiceItem[];
  /** Indicates whether brokerage can be assigned for the services in for a login. */
  brokerageAssignable?:
    | boolean
    | undefined;
  /** Indicates whether service requires explicit brokerage authorization in order to be available for brokerage logins. */
  brokerageAuthorizationRequired?:
    | boolean
    | undefined;
  /** Service will be added to newly created login by default. */
  default?:
    | boolean
    | undefined;
  /** Service is visible by CQG admins only. */
  visibleByCqgOnly?:
    | boolean
    | undefined;
  /** Entitlement Service Contract requirement. This value is corresponding appropriate INFO value for entitlement contract type. */
  contractType?: string | undefined;
}

/** Service authorization type. */
export enum EntitlementService_AuthorizationType {
  DISABLE_ONLY = 1,
  VIEW_ONLY = 2,
  ENABLE_ONLY = 3,
  FULL_CONTROL = 4,
  UNRECOGNIZED = -1,
}

export function entitlementService_AuthorizationTypeFromJSON(object: any): EntitlementService_AuthorizationType {
  switch (object) {
    case 1:
    case "DISABLE_ONLY":
      return EntitlementService_AuthorizationType.DISABLE_ONLY;
    case 2:
    case "VIEW_ONLY":
      return EntitlementService_AuthorizationType.VIEW_ONLY;
    case 3:
    case "ENABLE_ONLY":
      return EntitlementService_AuthorizationType.ENABLE_ONLY;
    case 4:
    case "FULL_CONTROL":
      return EntitlementService_AuthorizationType.FULL_CONTROL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EntitlementService_AuthorizationType.UNRECOGNIZED;
  }
}

export function entitlementService_AuthorizationTypeToJSON(object: EntitlementService_AuthorizationType): string {
  switch (object) {
    case EntitlementService_AuthorizationType.DISABLE_ONLY:
      return "DISABLE_ONLY";
    case EntitlementService_AuthorizationType.VIEW_ONLY:
      return "VIEW_ONLY";
    case EntitlementService_AuthorizationType.ENABLE_ONLY:
      return "ENABLE_ONLY";
    case EntitlementService_AuthorizationType.FULL_CONTROL:
      return "FULL_CONTROL";
    case EntitlementService_AuthorizationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Special service options. */
export enum EntitlementService_ServiceOption {
  ONLY_FOR_TRADERS = 1,
  FREE_FOR_TRADERS = 2,
  UNRECOGNIZED = -1,
}

export function entitlementService_ServiceOptionFromJSON(object: any): EntitlementService_ServiceOption {
  switch (object) {
    case 1:
    case "ONLY_FOR_TRADERS":
      return EntitlementService_ServiceOption.ONLY_FOR_TRADERS;
    case 2:
    case "FREE_FOR_TRADERS":
      return EntitlementService_ServiceOption.FREE_FOR_TRADERS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EntitlementService_ServiceOption.UNRECOGNIZED;
  }
}

export function entitlementService_ServiceOptionToJSON(object: EntitlementService_ServiceOption): string {
  switch (object) {
    case EntitlementService_ServiceOption.ONLY_FOR_TRADERS:
      return "ONLY_FOR_TRADERS";
    case EntitlementService_ServiceOption.FREE_FOR_TRADERS:
      return "FREE_FOR_TRADERS";
    case EntitlementService_ServiceOption.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Entitlement item description. */
export interface EntitlementServiceItem {
  /** Entitlement Item ID. */
  id?:
    | string
    | undefined;
  /** Entitlement Item Type ID. */
  type?:
    | EntitlementServiceItemType
    | undefined;
  /** Item name. */
  name?: Text | undefined;
}

/** Entitlement item type. */
export interface EntitlementServiceItemType {
  /** Type ID in INFO DB (INFO Value). */
  id?:
    | string
    | undefined;
  /** Type name. */
  name?:
    | Text
    | undefined;
  /** Type description. */
  description?:
    | Text
    | undefined;
  /** Determines whether item type is exchange (market data). */
  isMarketData?: boolean | undefined;
}

/** Price as currency and value. */
export interface Price {
  /** Currency code (real currency code is ISO 4217 based. */
  currency: string;
  /** Price value in a specified currency. */
  value: number;
}

/** Service price override. */
export interface ServicePriceOverride {
  /** Service identifier. */
  serviceId?:
    | string
    | undefined;
  /** Price override in USD. */
  price?: Price | undefined;
}

/** Category of entitlement. */
export interface EntitlementCategory {
  /** Category ID. */
  id: number;
  /** Category name. */
  name:
    | Text
    | undefined;
  /** List of sub-categories. */
  subCategory: EntitlementCategory[];
  /** Indicates whether brokerage can be assigned for the services in the catogory for a login. */
  brokerageAssignable?: boolean | undefined;
}

/** Entitlement constraint */
export interface EntitlementConstraint {
  /** This field is associated with EntitlementConstraint.Type enum type. */
  constraintType: number;
  /** Referenced service id. */
  refServiceId?:
    | number
    | undefined;
  /** Constraint group name for some constraint types. */
  constraintGroup?: string | undefined;
}

/** Constraint type. */
export enum EntitlementConstraint_Type {
  /** EXCLUDE - The services in the constraint are mutually exclusive. */
  EXCLUDE = 1,
  /**
   * WARNING - Warning constraints are used where two services would normally not be allowed together
   * because one is a subset of the other, but we can't use the exclude constraint because
   * we need to allow the user to try out a version of the more extensive service.
   */
  WARNING = 2,
  /**
   * MANDATORY_ONLY_ONE - One of the services is mandatory within constraint group but only one service
   * from the group can be selected at the same time (if enforced for this user).
   */
  MANDATORY_ONLY_ONE = 3,
  /** PICK_ONE - Only one service from the group can be selected at the same time. */
  PICK_ONE = 4,
  /** PRODUCT_WHITELIST - Only service in whitelist works with particular product service. */
  PRODUCT_WHITELIST = 5,
  /** INCLUDE_ITEMS - Constraint to show that all service entitlement items are implicitly included in ref-service */
  INCLUDE_ITEMS = 6,
  UNRECOGNIZED = -1,
}

export function entitlementConstraint_TypeFromJSON(object: any): EntitlementConstraint_Type {
  switch (object) {
    case 1:
    case "EXCLUDE":
      return EntitlementConstraint_Type.EXCLUDE;
    case 2:
    case "WARNING":
      return EntitlementConstraint_Type.WARNING;
    case 3:
    case "MANDATORY_ONLY_ONE":
      return EntitlementConstraint_Type.MANDATORY_ONLY_ONE;
    case 4:
    case "PICK_ONE":
      return EntitlementConstraint_Type.PICK_ONE;
    case 5:
    case "PRODUCT_WHITELIST":
      return EntitlementConstraint_Type.PRODUCT_WHITELIST;
    case 6:
    case "INCLUDE_ITEMS":
      return EntitlementConstraint_Type.INCLUDE_ITEMS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EntitlementConstraint_Type.UNRECOGNIZED;
  }
}

export function entitlementConstraint_TypeToJSON(object: EntitlementConstraint_Type): string {
  switch (object) {
    case EntitlementConstraint_Type.EXCLUDE:
      return "EXCLUDE";
    case EntitlementConstraint_Type.WARNING:
      return "WARNING";
    case EntitlementConstraint_Type.MANDATORY_ONLY_ONE:
      return "MANDATORY_ONLY_ONE";
    case EntitlementConstraint_Type.PICK_ONE:
      return "PICK_ONE";
    case EntitlementConstraint_Type.PRODUCT_WHITELIST:
      return "PRODUCT_WHITELIST";
    case EntitlementConstraint_Type.INCLUDE_ITEMS:
      return "INCLUDE_ITEMS";
    case EntitlementConstraint_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Request of user information. */
export interface UserInfoRequest {
  /** User ID to request user information. */
  userId: string;
}

/** User information. */
export interface User {
  /** List of field ids to clear during update operation. */
  clearedFields: number[];
  /**
   * [required-update] User Identifier.
   * The value is ignored for CreateUser operation.
   */
  id?:
    | string
    | undefined;
  /** [required-create] User name (max length = 32). */
  userName?:
    | string
    | undefined;
  /**
   * [obsolete] User first name (max length = 20).
   *
   * @deprecated
   */
  obsoleteFirstName?:
    | string
    | undefined;
  /**
   * [obsolete] User last name (max length = 25).
   *
   * @deprecated
   */
  obsoleteLastName?:
    | string
    | undefined;
  /**
   * [obsolete] User e-mail (max length = 60).
   *
   * @deprecated
   */
  obsoleteEmail?:
    | string
    | undefined;
  /**
   * [immutable-update] Linked customer id.
   * Use profile_id instead.
   */
  customerId?:
    | string
    | undefined;
  /**
   * [required-create] Subscriber Type. PRO by default when created.
   * This field is associated with User.SubscriberType enum type.
   * Supported login domains: system, trade-routing.
   */
  subscriberType?:
    | number
    | undefined;
  /**
   * Date till the user is alive and then the user will be removed.
   * Datetime format is used but only date part is used, time part is ignored.
   * Supported login domains: system.
   */
  removalDate?:
    | number
    | undefined;
  /** [immutable] Identifier of CQG System user. */
  systemId?:
    | string
    | undefined;
  /** [immutable] Identifier of Trade Routing user. */
  traderId?:
    | string
    | undefined;
  /** [immutable] List of enforced mandatory constraint group names. */
  enforcedConstraintGroup: string[];
  /** [immutable] Billing currency code (ISO 4217) */
  currency?:
    | string
    | undefined;
  /**
   * [required-create][immutable-update] Login domain.
   * This field is associated with LoginDomain enum type.
   */
  domain?:
    | number
    | undefined;
  /** [immutable] Removed flag. */
  removed?:
    | boolean
    | undefined;
  /**
   * [immutable-update] Login class.
   * If not provided during create, default used - regular class.
   * This field is associated with User.LoginClass enum type.
   * Supported login domains: trade-routing.
   */
  class?:
    | number
    | undefined;
  /**
   * [immutable] Indicates whether login is used for demo access.
   * It cannot be used during login creation and cannot be changed for existing login.
   * Supported login domains: trade-routing.
   */
  isTemporary?:
    | boolean
    | undefined;
  /**
   * [immutable] Determines, whether login can be used for trading.
   * Supported login domains: trade-routing.
   */
  isActive?:
    | boolean
    | undefined;
  /**
   * [immutable] Date and Time, when login is deactivated and can't be used for trading.
   * Supported login domains: trade-routing.
   */
  expirationTime?:
    | number
    | undefined;
  /** [immutable] Identifier of CAST user. */
  castUserId?:
    | string
    | undefined;
  /**
   * Admin login's scope.
   * Supported login domains: admin.
   * This field is associated with AdminLoginScope enum.
   */
  scope?:
    | number
    | undefined;
  /**
   * Enforce IP whitelist check.
   * Supported login domains: admin, trade-routing.
   */
  enforceIpWhitelist?:
    | boolean
    | undefined;
  /**
   * [erasable] IP addresses whitelist in CIDR notation.
   * Supported login domains: admin, trade-routing.
   */
  ipWhitelist: string[];
  /**
   * [immutable-update][required-create] Owner profile id.
   * Has priority over customer_id.
   * Supported profile types: admin, customer.
   */
  profileId?: string | undefined;
}

/** Subscriber Type. */
export enum User_SubscriberType {
  /** PRO - Professional subscriber. */
  PRO = 0,
  /** NON_PRO - Non-professional subscriber. */
  NON_PRO = 1,
  UNRECOGNIZED = -1,
}

export function user_SubscriberTypeFromJSON(object: any): User_SubscriberType {
  switch (object) {
    case 0:
    case "PRO":
      return User_SubscriberType.PRO;
    case 1:
    case "NON_PRO":
      return User_SubscriberType.NON_PRO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return User_SubscriberType.UNRECOGNIZED;
  }
}

export function user_SubscriberTypeToJSON(object: User_SubscriberType): string {
  switch (object) {
    case User_SubscriberType.PRO:
      return "PRO";
    case User_SubscriberType.NON_PRO:
      return "NON_PRO";
    case User_SubscriberType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Defines classes of login. */
export enum User_LoginClass {
  /** REGULAR - Regular login for trading. */
  REGULAR = 1,
  /** FIX_DIRECT - Fix direct access login. */
  FIX_DIRECT = 2,
  /** ORDER_HANDLER - Order handler login. */
  ORDER_HANDLER = 3,
  /** TEMPLATE - Template login for cloning only. */
  TEMPLATE = 4,
  UNRECOGNIZED = -1,
}

export function user_LoginClassFromJSON(object: any): User_LoginClass {
  switch (object) {
    case 1:
    case "REGULAR":
      return User_LoginClass.REGULAR;
    case 2:
    case "FIX_DIRECT":
      return User_LoginClass.FIX_DIRECT;
    case 3:
    case "ORDER_HANDLER":
      return User_LoginClass.ORDER_HANDLER;
    case 4:
    case "TEMPLATE":
      return User_LoginClass.TEMPLATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return User_LoginClass.UNRECOGNIZED;
  }
}

export function user_LoginClassToJSON(object: User_LoginClass): string {
  switch (object) {
    case User_LoginClass.REGULAR:
      return "REGULAR";
    case User_LoginClass.FIX_DIRECT:
      return "FIX_DIRECT";
    case User_LoginClass.ORDER_HANDLER:
      return "ORDER_HANDLER";
    case User_LoginClass.TEMPLATE:
      return "TEMPLATE";
    case User_LoginClass.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Additional login settings. */
export interface LoginSettings {
  /** List of field ids to clear during update operation. */
  clearedFields: number[];
  /** [required] Login Identifier. */
  loginId?:
    | string
    | undefined;
  /**
   * Preferred connection point.
   * The values are associated with location_1.ConnectionPointListRequest.
   * In update operation if connection point requires brokerage authorization,
   * then brokerage of login's customer profile must be authorized on this connection point.
   */
  preferredConnectionPointId?:
    | string
    | undefined;
  /**
   * [erasable] Possible concurrent sessions number per one IP.
   * View and modify access to this field is limited for Admin users.
   */
  concurrentSessionsNumber?:
    | number
    | undefined;
  /** Determines whether password never expires. */
  passwordNeverExpires?:
    | boolean
    | undefined;
  /**
   * [obsolete] Strategies can be executed through this login.
   *
   * @deprecated
   */
  obsoleteIsStrategyRunner?:
    | boolean
    | undefined;
  /** [immutable] This can be used in case when preferred_connection_point_id is missing in connection points list. */
  preferredConnectionPointName?:
    | string
    | undefined;
  /**
   * [erasable] Linked login for operations on behave of.
   * In case of admin login, this linked login can be only order-handler trade-routing login,
   * which is used for order operations going to exchange.
   * In case of trade-routing login, this linked login can be only admin login,
   * which is used for operations in CMS API.
   */
  linkedLoginId?:
    | string
    | undefined;
  /**
   * Parameters of external authentication, if any.
   * Supported login domains: trade-routing.
   */
  externalAuth?:
    | ExternalAuth
    | undefined;
  /**
   * Per-user override of limit of allowed logons per day. Zero or absent field
   * mean no override.
   */
  dailyLogonLimit?:
    | number
    | undefined;
  /**
   * [immutable] Number of performed logons for current day. Absent field means zero.
   * Note: This property is intentionally mutable, allowing reset of the logon counter by FCMs/ops.
   * Note: day boundary is 00:00 UTC.
   */
  dailyLogonCount?:
    | number
    | undefined;
  /**
   * Authentication system identifier.
   * LookupPropertyListRequest { property_type = CommonLookupPropertyType.AUTHENTICATION_SYSTEM }
   * Supported login domains: trade-routing, admin.
   */
  authenticationSystem?:
    | string
    | undefined;
  /** [immutable] Determines whether login is allowed to exceed account authorizations limit. */
  allowExceedAccountLimit?:
    | boolean
    | undefined;
  /**
   * This field is associated with LoginSettings.DisconnectActions enum type.
   * Supported login domains: trade-routing.
   */
  onSessionDisconnectAction?:
    | number
    | undefined;
  /**
   * Timeout in seconds. If the login reconnects during this period, orders won't be canceled/suspended.
   * Applied only if on_session_disconnect_action field does not equal to NOTHING.
   * Supported login domains: trade-routing.
   */
  reconnectTimeoutAfterDisconnect?:
    | number
    | undefined;
  /**
   * Time zone in IANA (Olson) format: "Area/Location". See https://www.iana.org/time-zones
   * Sets preferred time zone for date/time in notification messages.
   */
  timeZone?: string | undefined;
}

/** Defines actions on login session disconnect. */
export enum LoginSettings_DisconnectAction {
  /** NOTHING - No specific action. */
  NOTHING = 1,
  /** CANCEL - Cancel orders. */
  CANCEL = 2,
  /** SUSPEND - Suspend orders. */
  SUSPEND = 3,
  UNRECOGNIZED = -1,
}

export function loginSettings_DisconnectActionFromJSON(object: any): LoginSettings_DisconnectAction {
  switch (object) {
    case 1:
    case "NOTHING":
      return LoginSettings_DisconnectAction.NOTHING;
    case 2:
    case "CANCEL":
      return LoginSettings_DisconnectAction.CANCEL;
    case 3:
    case "SUSPEND":
      return LoginSettings_DisconnectAction.SUSPEND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LoginSettings_DisconnectAction.UNRECOGNIZED;
  }
}

export function loginSettings_DisconnectActionToJSON(object: LoginSettings_DisconnectAction): string {
  switch (object) {
    case LoginSettings_DisconnectAction.NOTHING:
      return "NOTHING";
    case LoginSettings_DisconnectAction.CANCEL:
      return "CANCEL";
    case LoginSettings_DisconnectAction.SUSPEND:
      return "SUSPEND";
    case LoginSettings_DisconnectAction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Request to search for a user. */
export interface UserSearchRequest {
  /**
   * [obsolete] Text to search.
   *
   * @deprecated
   */
  obsoleteText?:
    | string
    | undefined;
  /**
   * [obsolete] List of search options.
   * This field is associated with SearchCriteria enum type.
   * Empty list means search by any possible options.
   *
   * @deprecated
   */
  obsoleteSearchCriteria: number[];
  /** List of search options. Each option has its own search text, criteria and matching rule. */
  searchOptions: SearchOption[];
  /**
   * Indicates, whether all criteria must match at once.
   * By default any criteria must match.
   */
  allMatchMode?: boolean | undefined;
}

/** List of possible search refine criteria. */
export enum UserSearchRequest_SearchCriteria {
  /**
   * USER_ID - Search by user_id.
   * With 'CONTAINS' rule, minimal query text length is limited with 3 symbols.
   */
  USER_ID = 1,
  /**
   * USER_NAME - Search by user name.
   * With 'CONTAINS' rule, minimal query text length is limited with 3 symbols.
   */
  USER_NAME = 4,
  /** PROFILE_SALES_SERIES_NUMBER - Search by profile sales series number. */
  PROFILE_SALES_SERIES_NUMBER = 5,
  /** PROFILE_SALES_SERIES_NAME - Search by profile sales series name. */
  PROFILE_SALES_SERIES_NAME = 6,
  /**
   * SALES_SERIES_ID - Search by sales series ID.
   * Use PROFILE_SALES_SERIES_ID instead.
   */
  SALES_SERIES_ID = 7,
  /**
   * CUSTOMER_ID - Search by customer ID for all linked users.
   * With 'CONTAINS' rule, minimal query text length is limited with 3 symbols.
   * In case of system logins, only EXACT_EQUALITY rule is used, regardless provided rule in request.
   * Use PROFILE_ID instead.
   */
  CUSTOMER_ID = 8,
  /**
   * BROKERAGE_ID - Search by authorized brokerage id.
   * Supported login domains: admin.
   */
  BROKERAGE_ID = 9,
  /**
   * BROKERAGE_NAME - Search by authorized brokerage name.
   * Supported login domains: admin.
   */
  BROKERAGE_NAME = 10,
  /**
   * CLASS - Login class.
   * Supported login domains: trade-routing.
   * This field is associated with User.LoginClass enum type.
   * LIMITED USE: Only explicitly, one per search option with EXACT_EQUALITY rule and in all match mode.
   */
  CLASS = 11,
  /**
   * LOGIN_DOMAIN - Login domain.
   * This field is associated with LoginDomain enum type.
   * LIMITED USE: Only explicitly, one per search option with EXACT_EQUALITY rule and in all match mode.
   */
  LOGIN_DOMAIN = 12,
  /**
   * PROFILE_ID - Search by profile (customer/admin) ID for all linked users.
   * With 'CONTAINS' rule, minimal query text length is limited with 3 symbols.
   * In case of system logins, only EXACT_EQUALITY rule is used, regardless provided rule in request.
   */
  PROFILE_ID = 13,
  /** PROFILE_SALES_SERIES_ID - Search by profile sales series ID. */
  PROFILE_SALES_SERIES_ID = 14,
  /**
   * AUTH_PARTNER_ID - Search by assigned authentication partner identifier
   * Supported login domain: trade-routing.
   */
  AUTH_PARTNER_ID = 15,
  /**
   * EXTERNAL_USER_ID - Search by id of user (username) registered for authentication partner
   * Supported login domain: trade-routing.
   */
  EXTERNAL_USER_ID = 16,
  /**
   * AUTH_PARTNER_NAME - Search by assigned authentication partner name
   * Supported login domain: trade-routing.
   */
  AUTH_PARTNER_NAME = 17,
  UNRECOGNIZED = -1,
}

export function userSearchRequest_SearchCriteriaFromJSON(object: any): UserSearchRequest_SearchCriteria {
  switch (object) {
    case 1:
    case "USER_ID":
      return UserSearchRequest_SearchCriteria.USER_ID;
    case 4:
    case "USER_NAME":
      return UserSearchRequest_SearchCriteria.USER_NAME;
    case 5:
    case "PROFILE_SALES_SERIES_NUMBER":
      return UserSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_NUMBER;
    case 6:
    case "PROFILE_SALES_SERIES_NAME":
      return UserSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_NAME;
    case 7:
    case "SALES_SERIES_ID":
      return UserSearchRequest_SearchCriteria.SALES_SERIES_ID;
    case 8:
    case "CUSTOMER_ID":
      return UserSearchRequest_SearchCriteria.CUSTOMER_ID;
    case 9:
    case "BROKERAGE_ID":
      return UserSearchRequest_SearchCriteria.BROKERAGE_ID;
    case 10:
    case "BROKERAGE_NAME":
      return UserSearchRequest_SearchCriteria.BROKERAGE_NAME;
    case 11:
    case "CLASS":
      return UserSearchRequest_SearchCriteria.CLASS;
    case 12:
    case "LOGIN_DOMAIN":
      return UserSearchRequest_SearchCriteria.LOGIN_DOMAIN;
    case 13:
    case "PROFILE_ID":
      return UserSearchRequest_SearchCriteria.PROFILE_ID;
    case 14:
    case "PROFILE_SALES_SERIES_ID":
      return UserSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_ID;
    case 15:
    case "AUTH_PARTNER_ID":
      return UserSearchRequest_SearchCriteria.AUTH_PARTNER_ID;
    case 16:
    case "EXTERNAL_USER_ID":
      return UserSearchRequest_SearchCriteria.EXTERNAL_USER_ID;
    case 17:
    case "AUTH_PARTNER_NAME":
      return UserSearchRequest_SearchCriteria.AUTH_PARTNER_NAME;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserSearchRequest_SearchCriteria.UNRECOGNIZED;
  }
}

export function userSearchRequest_SearchCriteriaToJSON(object: UserSearchRequest_SearchCriteria): string {
  switch (object) {
    case UserSearchRequest_SearchCriteria.USER_ID:
      return "USER_ID";
    case UserSearchRequest_SearchCriteria.USER_NAME:
      return "USER_NAME";
    case UserSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_NUMBER:
      return "PROFILE_SALES_SERIES_NUMBER";
    case UserSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_NAME:
      return "PROFILE_SALES_SERIES_NAME";
    case UserSearchRequest_SearchCriteria.SALES_SERIES_ID:
      return "SALES_SERIES_ID";
    case UserSearchRequest_SearchCriteria.CUSTOMER_ID:
      return "CUSTOMER_ID";
    case UserSearchRequest_SearchCriteria.BROKERAGE_ID:
      return "BROKERAGE_ID";
    case UserSearchRequest_SearchCriteria.BROKERAGE_NAME:
      return "BROKERAGE_NAME";
    case UserSearchRequest_SearchCriteria.CLASS:
      return "CLASS";
    case UserSearchRequest_SearchCriteria.LOGIN_DOMAIN:
      return "LOGIN_DOMAIN";
    case UserSearchRequest_SearchCriteria.PROFILE_ID:
      return "PROFILE_ID";
    case UserSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_ID:
      return "PROFILE_SALES_SERIES_ID";
    case UserSearchRequest_SearchCriteria.AUTH_PARTNER_ID:
      return "AUTH_PARTNER_ID";
    case UserSearchRequest_SearchCriteria.EXTERNAL_USER_ID:
      return "EXTERNAL_USER_ID";
    case UserSearchRequest_SearchCriteria.AUTH_PARTNER_NAME:
      return "AUTH_PARTNER_NAME";
    case UserSearchRequest_SearchCriteria.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** User search result record. */
export interface UserSearchResultRecord {
  /** User identifier. */
  userId: string;
  /** User name. */
  userName: string;
  /** Customer's first name. */
  firstName?:
    | string
    | undefined;
  /** Customer's last name. */
  lastName?:
    | string
    | undefined;
  /**
   * Customer id.
   * Use profile_id instead.
   */
  customerId?:
    | string
    | undefined;
  /** Customer name. */
  customerName?:
    | string
    | undefined;
  /** Customer's brokerage name. */
  customerBrokerageName?:
    | string
    | undefined;
  /** Removed flag. */
  removed?:
    | boolean
    | undefined;
  /**
   * User class.
   * This field is associated with User.LoginClass enum type.
   */
  class?:
    | number
    | undefined;
  /** Temporary user for demo access. */
  isTemporary?:
    | boolean
    | undefined;
  /** Owner profile id. */
  profileId?:
    | string
    | undefined;
  /** Parameters of external authentication, if any. */
  externalAuth?: ExternalAuth | undefined;
}

/**
 * Clone traderouting login.
 * When target_profile_id is provided, new user will be linked with it,
 * otherwise new customer profile will be created.
 */
export interface CloneUser {
  /** [required] Source user identifier. */
  sourceUserId?:
    | string
    | undefined;
  /** [required] New user username (max length = 32). */
  newUserUsername?:
    | string
    | undefined;
  /**
   * [obsolete] New user first name (max length = 20).
   *
   * @deprecated
   */
  obsoleteNewUserFirstName?:
    | string
    | undefined;
  /**
   * [obsolete] New user last name (max length = 25).
   *
   * @deprecated
   */
  obsoleteNewUserLastName?:
    | string
    | undefined;
  /**
   * [obsolete] New user address.
   *
   * @deprecated
   */
  obsoleteNewUserAddress?:
    | Address
    | undefined;
  /**
   * Contact information of new customer profile that will be linked with new login.
   * Mutually exclusive with target_profile_id.
   */
  newUserContactInformation?:
    | ContactInformation
    | undefined;
  /**
   * [obsolete] New user middle initial (max length = 1).
   *
   * @deprecated
   */
  obsoleteNewUserMiddleInitial?:
    | string
    | undefined;
  /**
   * Existing customer profile that new login will be linked with.
   * Mutually exclusive with new_user_contact_information.
   */
  targetProfileId?:
    | string
    | undefined;
  /** This flag if set to true prohibit to send welcome email for cloned trader. */
  noWelcomeEmail?:
    | boolean
    | undefined;
  /**
   * Brokerage_id for new customer profile. If not provided it is copied from customer profile of source user.
   * Mutually exclusive with target_profile_id.
   */
  newProfileBrokerageId?: string | undefined;
}

export interface CloneUserResult {
  /** New traderouting login identifier. */
  newUserId: string;
  /** New linked customer profile identifier. */
  newProfileId?: string | undefined;
}

/** Address information. */
export interface Address {
  /** List of field ids to clear. Erasable fields must be defined in a message where Address is used. */
  clearedFields: number[];
  /** Country code (ISO 3166 based) (max length = 2). */
  country?:
    | string
    | undefined;
  /** State code (ISO 3166 based) (max length = 2). */
  state?:
    | string
    | undefined;
  /** City (max length = 25). */
  city?:
    | string
    | undefined;
  /** ZIP/Postal code (max length = 9). */
  zip?:
    | string
    | undefined;
  /** Address line 1 (max length = 58). */
  address?:
    | string
    | undefined;
  /** Address line 2 (max length = 58). */
  address2?:
    | string
    | undefined;
  /** Name of address for its identification. */
  name?:
    | string
    | undefined;
  /**
   * Validation status of the address.
   * This field is associated with Address.AddressStatus enum type.
   */
  addressStatus?: number | undefined;
}

/** List of possible validation statuses of an address. */
export enum Address_AddressStatus {
  INVALID = 0,
  VALID = 1,
  NO_VALIDATION = 2,
  NEW = 3,
  UNRECOGNIZED = -1,
}

export function address_AddressStatusFromJSON(object: any): Address_AddressStatus {
  switch (object) {
    case 0:
    case "INVALID":
      return Address_AddressStatus.INVALID;
    case 1:
    case "VALID":
      return Address_AddressStatus.VALID;
    case 2:
    case "NO_VALIDATION":
      return Address_AddressStatus.NO_VALIDATION;
    case 3:
    case "NEW":
      return Address_AddressStatus.NEW;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Address_AddressStatus.UNRECOGNIZED;
  }
}

export function address_AddressStatusToJSON(object: Address_AddressStatus): string {
  switch (object) {
    case Address_AddressStatus.INVALID:
      return "INVALID";
    case Address_AddressStatus.VALID:
      return "VALID";
    case Address_AddressStatus.NO_VALIDATION:
      return "NO_VALIDATION";
    case Address_AddressStatus.NEW:
      return "NEW";
    case Address_AddressStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Contact information. */
export interface ContactInformation {
  /**
   * List of e-mail addresses.
   * Maximum allowed size is 2 elements.
   */
  email: Email[];
  /**
   * List of phones.
   * Maximum allowed size is 3 elements.
   */
  phone: Phone[];
  /**
   * List of faxes.
   * Maximum allowed size is 1 element.
   */
  fax: Phone[];
  /** Contact first name (max length = 20). */
  firstName?:
    | string
    | undefined;
  /** Contact last name (max length = 25). */
  lastName?:
    | string
    | undefined;
  /**
   * Mail address information.
   * Maximum allowed size is 1 element.
   */
  address: Address[];
}

/** e-mail address information. */
export interface Email {
  /**
   * e-mail (max length = 60).
   * It should be valid email address according to RFC 5322 standard or single period '.'.
   */
  email: string;
}

/** Phone information. */
export interface Phone {
  /** Phone number (max length = 20). */
  number: string;
}

/** Create user operation. */
export interface CreateUser {
  /** User to create. */
  user: User | undefined;
}

/** Update user operation. */
export interface UpdateUser {
  /**
   * [required] User to update.
   * Only fields for update must be provided.
   */
  user?:
    | User
    | undefined;
  /**
   * [obsolete] Use only user field instead.
   *
   * @deprecated
   */
  obsoleteOriginalUser?: User | undefined;
}

/** Remove user operation. */
export interface RemoveUser {
  /** User identifier to remove. */
  id: string;
}

/** Restore user operation. */
export interface RestoreUser {
  /** User identifier to restore. */
  id: string;
}

/** Result of create user operation. */
export interface CreateUserResult {
  /** Assigned user id, if succeeded. */
  id?: string | undefined;
}

/** Result of update user operation. */
export interface UpdateUserResult {
}

/** Result of restore user operation. */
export interface RestoreUserResult {
}

/** Result of remove user operation. */
export interface RemoveUserResult {
}

/** This entity is used as a common list of name - value pairs for different fields. */
export interface LookupProperty {
  /** The value is associated with Lookup Property type enum defined within the request scope. */
  type: number;
  /**
   * Property Value.
   * This value must be provided as corresponding field value (usually <entity>_id) in specific message.
   */
  value: string;
  /** Property Name. */
  name:
    | Text
    | undefined;
  /** Optional property description. */
  description?: Text | undefined;
}

/** Get a list of lookup properties. */
export interface LookupPropertyListRequest {
  /**
   * List of property types to request.
   * Empty list means all types are requested.
   * The values are associated with Lookup Property type enum defined within the request scope.
   */
  propertyType: number[];
}

/** Result of a list of lookup properties request. */
export interface LookupPropertyListResult {
  /** List of requested lookup properties. */
  lookupProperty: LookupProperty[];
}

/** Search for sales series. */
export interface SalesSeriesSearchRequest {
  /**
   * [obsolete] Text to search.
   *
   * @deprecated
   */
  obsoleteText?:
    | string
    | undefined;
  /**
   * [obsolete] List of search options.
   * This field is associated with SearchCriteria enum type.
   * Empty list means search by any possible options.
   *
   * @deprecated
   */
  obsoleteSearchCriteria: number[];
  /** List of search options. Each option has its own search text, criteria and matching rule. */
  searchOptions: SearchOption[];
  /**
   * Indicates, whether all criteria must match at once.
   * By default any criteria must match.
   */
  allMatchMode?: boolean | undefined;
}

/** List of possible search refine criteria. */
export enum SalesSeriesSearchRequest_SearchCriteria {
  /** ID - Search by sales series ID. */
  ID = 1,
  /** NUMBER - Search by sales series number. */
  NUMBER = 2,
  /** NAME - Search by sales series name. */
  NAME = 3,
  /** BROKERAGE_ID - Search by brokerage id. */
  BROKERAGE_ID = 4,
  FIRST_NAME = 5,
  LAST_NAME = 6,
  UNRECOGNIZED = -1,
}

export function salesSeriesSearchRequest_SearchCriteriaFromJSON(object: any): SalesSeriesSearchRequest_SearchCriteria {
  switch (object) {
    case 1:
    case "ID":
      return SalesSeriesSearchRequest_SearchCriteria.ID;
    case 2:
    case "NUMBER":
      return SalesSeriesSearchRequest_SearchCriteria.NUMBER;
    case 3:
    case "NAME":
      return SalesSeriesSearchRequest_SearchCriteria.NAME;
    case 4:
    case "BROKERAGE_ID":
      return SalesSeriesSearchRequest_SearchCriteria.BROKERAGE_ID;
    case 5:
    case "FIRST_NAME":
      return SalesSeriesSearchRequest_SearchCriteria.FIRST_NAME;
    case 6:
    case "LAST_NAME":
      return SalesSeriesSearchRequest_SearchCriteria.LAST_NAME;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SalesSeriesSearchRequest_SearchCriteria.UNRECOGNIZED;
  }
}

export function salesSeriesSearchRequest_SearchCriteriaToJSON(object: SalesSeriesSearchRequest_SearchCriteria): string {
  switch (object) {
    case SalesSeriesSearchRequest_SearchCriteria.ID:
      return "ID";
    case SalesSeriesSearchRequest_SearchCriteria.NUMBER:
      return "NUMBER";
    case SalesSeriesSearchRequest_SearchCriteria.NAME:
      return "NAME";
    case SalesSeriesSearchRequest_SearchCriteria.BROKERAGE_ID:
      return "BROKERAGE_ID";
    case SalesSeriesSearchRequest_SearchCriteria.FIRST_NAME:
      return "FIRST_NAME";
    case SalesSeriesSearchRequest_SearchCriteria.LAST_NAME:
      return "LAST_NAME";
    case SalesSeriesSearchRequest_SearchCriteria.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Represents single found sales series. */
export interface SalesSeriesSearchResultRecord {
  salesSeriesId?: string | undefined;
  salesSeriesName?: string | undefined;
  salesSeriesNumber?: string | undefined;
  brokerageId?: string | undefined;
  brokerageName?: string | undefined;
  removed?: boolean | undefined;
}

/** Common tuple message of string values. */
export interface Tuple {
  first?: string | undefined;
  second?: string | undefined;
  third?: string | undefined;
}

/**
 * Customer to sales series authorization list request message.
 * By default result contains at most DEFAULT_RESULT_RECORDS_NUMBER records.
 * Set parameter top to a larger number to receive more.
 * Either customer_id or sales_series_id or profile_id or profile_sales_series_id is required (mutually exclusive).
 */
export interface CustomerSalesSeriesAuthorizationListRequest {
  /**
   * Customer ID.
   * Use profile_id instead.
   */
  customerId?:
    | string
    | undefined;
  /**
   * Sales series ID.
   * Use profile_sales_series_id instead.
   */
  salesSeriesId?:
    | string
    | undefined;
  /** optionally restrict results by returning the first 'top' records. */
  top?:
    | number
    | undefined;
  /**
   * Profile ID.
   * Has priority over customer_id.
   * Supported types: customer, admin.
   */
  profileId?:
    | string
    | undefined;
  /**
   * Profile sales series ID.
   * Has priority over sales_series_id.
   */
  profileSalesSeriesId?: string | undefined;
}

/** Update customer to sales series authorization list operation. */
export interface UpdateCustomerSalesSeriesAuthorizationList {
  /**
   * Links to add.
   * Authorization is added if it does not exist with this customer and sales series id.
   */
  linksToSet: CustomerSalesSeriesLink[];
  /** Links to remove. First - customer id (profile id), second - sales series id (profile sales series id). */
  linksToRemove: Tuple[];
}

/** Customer to sales series authorization list result message. */
export interface CustomerSalesSeriesAuthorizationListResult {
  /** List of customer to sales series authorization links. */
  customerSalesSeriesLinks: CustomerSalesSeriesLink[];
  /**
   * This flag is set to true if all results are sent
   * and nothing was filtered out by 'top' parameter.
   */
  isComplete?: boolean | undefined;
}

/** Result of customer to sales series authorization list updation. */
export interface UpdateCustomerSalesSeriesAuthorizationListResult {
}

/** Customer to sales series authorization link. */
export interface CustomerSalesSeriesLink {
  /**
   * Customer ID.
   * Use profile_id instead.
   */
  customerId?:
    | string
    | undefined;
  /**
   * Sales series ID.
   * Use profile_sales_series_id instead.
   */
  salesSeriesId?:
    | string
    | undefined;
  /** [immutable] Sales series name. */
  salesSeriesName?:
    | string
    | undefined;
  /** [immutable] Sales series number. */
  salesSeriesNumber?:
    | string
    | undefined;
  /**
   * [required] Main profile id.
   * Has priority over customer_id.
   * Supported types: customer, admin.
   */
  profileId?:
    | string
    | undefined;
  /**
   * [required] Sales series profile id.
   * Has priority over sales_series_id.
   */
  profileSalesSeriesId?: string | undefined;
}

/** Login additional settings request. */
export interface LoginSettingsRequest {
  /** [required] Login identifier. */
  loginId?: string | undefined;
}

/** Result of LoginSettingsRequest. */
export interface LoginSettingsResult {
  /** Login settings. */
  loginSettings?: LoginSettings | undefined;
}

/** Update Login settings. */
export interface UpdateLoginSettings {
  /** [required] Login settings. */
  loginSettings?: LoginSettings | undefined;
}

/** Result of UpdateLoginSettings. */
export interface UpdateLoginSettingsResult {
}

/** Requests login's member ids. */
export interface LoginExchangeMemberIdListRequest {
  /** [required] Login identifier. */
  loginId?: string | undefined;
}

/** Requests login's member ids. */
export interface LoginExchangeMemberIdListResult {
  /** Member ids. */
  loginMemberIds: LoginExchangeMemberId[];
}

/** Login member id. */
export interface LoginExchangeMemberId {
  /** [required] Login identifier. */
  loginId?:
    | string
    | undefined;
  /**
   * [required] Exchange identifier.
   * LookupPropertyListRequest { property_type = CommonLookupPropertyType.EXCHANGE }
   */
  exchangeId?:
    | string
    | undefined;
  /** [required] Exchange member identifier. */
  memberId?: string | undefined;
}

/** Update login exchange member id list. */
export interface UpdateLoginExchangeMemberIdList {
  /** [required] Login id to update member ids. */
  loginId?:
    | string
    | undefined;
  /** Member ids to add or change. */
  memberIdsToSet: LoginExchangeMemberId[];
  /**
   * Member ids to remove from login.
   * First - login id [required], second - exchange id [required].
   */
  memberIdsToRemove: Tuple[];
}

/** Result of UpdateLoginExchangeMemberIdList. */
export interface UpdateLoginExchangeMemberIdListResult {
}

/** Activate login operation. */
export interface ActivateLogin {
  /** [required] Login identifier to activate. */
  loginId?: string | undefined;
}

/** Deactivate login operation. */
export interface DeactivateLogin {
  /** [required] Login identifier to deactivate. */
  loginId?:
    | string
    | undefined;
  /** Date and time when login will be deactivated. */
  expirationTime?:
    | number
    | undefined;
  /**
   * Flag to show that event subscription must be removed.
   * Could be set only on immediate deactivation (absent expiration_time).
   */
  cleanUpEventSubscriptions?: boolean | undefined;
}

/** Result of ActivateLogin */
export interface ActivateLoginResult {
}

/** Result of DeactivateLogin */
export interface DeactivateLoginResult {
}

/** Erases current credentials of specified types. */
export interface EraseCurrentCredentials {
  /** [required] Login identifier. */
  loginId?:
    | string
    | undefined;
  /**
   * List of credentials to erase.
   * This filed is associated with common_1.CredentialType enum.
   */
  credentialTypes: number[];
}

/** Result of EraseCurrentCredentials */
export interface EraseCurrentCredentialsResult {
}

/** Represents search option for search requests. */
export interface SearchOption {
  /**
   * [required] Text to search.
   * Empty text is prohibited.
   */
  text?:
    | string
    | undefined;
  /**
   * Search criteria.
   * For each type of search request corresponding enum values should be used.
   * Empty means search by any possible criterion.
   * Regardless 'all match mode' set on search request level, criteria given here match between each other with 'any' mode.
   */
  criteria: number[];
  /**
   * Comparing matching rule. Associated with MatchingRule enum.
   * By default all matching rules are used in the following order: EXACT_EQUALITY, STARTS_WITH, ENDS_WITH, CONTAINS.
   */
  matchingRule?: number | undefined;
}

export enum SearchOption_MatchingRule {
  CONTAINS = 0,
  STARTS_WITH = 1,
  ENDS_WITH = 2,
  EXACT_EQUALITY = 3,
  /**
   * IN_MATCHING_RULE - Only criteria marked as supported for this rule can be used with.
   * Format: value1,value2. Where each value matches by EXACT_EQUALITY.
   * Searches records that match any of these values.
   */
  IN_MATCHING_RULE = 4,
  UNRECOGNIZED = -1,
}

export function searchOption_MatchingRuleFromJSON(object: any): SearchOption_MatchingRule {
  switch (object) {
    case 0:
    case "CONTAINS":
      return SearchOption_MatchingRule.CONTAINS;
    case 1:
    case "STARTS_WITH":
      return SearchOption_MatchingRule.STARTS_WITH;
    case 2:
    case "ENDS_WITH":
      return SearchOption_MatchingRule.ENDS_WITH;
    case 3:
    case "EXACT_EQUALITY":
      return SearchOption_MatchingRule.EXACT_EQUALITY;
    case 4:
    case "IN_MATCHING_RULE":
      return SearchOption_MatchingRule.IN_MATCHING_RULE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SearchOption_MatchingRule.UNRECOGNIZED;
  }
}

export function searchOption_MatchingRuleToJSON(object: SearchOption_MatchingRule): string {
  switch (object) {
    case SearchOption_MatchingRule.CONTAINS:
      return "CONTAINS";
    case SearchOption_MatchingRule.STARTS_WITH:
      return "STARTS_WITH";
    case SearchOption_MatchingRule.ENDS_WITH:
      return "ENDS_WITH";
    case SearchOption_MatchingRule.EXACT_EQUALITY:
      return "EXACT_EQUALITY";
    case SearchOption_MatchingRule.IN_MATCHING_RULE:
      return "IN_MATCHING_RULE";
    case SearchOption_MatchingRule.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Represents common message of id and name. */
export interface NamedEntity {
  /** Identifier. */
  id?:
    | string
    | undefined;
  /** Name. */
  name?: Text | undefined;
}

/** Represents template message for adding/removing links between entities. */
export interface UpdateEntityLinks {
  /** Links to add. Tuple.First, Tuple.Second are [required]. */
  linksToSet: Tuple[];
  /** Links to remove. Tuple.First, Tuple.Second are [required]. */
  linksToRemove: Tuple[];
}

/** Result of update entity links operation. */
export interface UpdateEntityLinksResult {
}

/**
 * Represents template message for requesting linked entities for some entity.
 * By default result contains at most DEFAULT_RESULT_RECORDS_NUMBER records.
 * Set parameter top to a larger number to receive more.
 */
export interface LinkedEntityListRequest {
  /** Entity identifier. */
  id?:
    | string
    | undefined;
  /** optionally restrict results by returning the first 'top' records. */
  top?: number | undefined;
}

/** Result of linked entities request operation. */
export interface LinkedEntityListResult {
  entities: NamedEntity[];
  /**
   * This flag is set to true if all results are sent
   * and nothing was filtered out by 'top' parameter.
   */
  isComplete?: boolean | undefined;
}

/** Common message for sales series list result. */
export interface SalesSeriesListResult {
  salesSeries: SalesSeriesInfo[];
  /**
   * This flag is set to true if all results are sent
   * and nothing was filtered out by 'top' parameter.
   */
  isComplete?: boolean | undefined;
}

/** Represents sales series. */
export interface SalesSeriesInfo {
  /** Sales series ID. */
  id?:
    | string
    | undefined;
  /** Sales series name. */
  salesSeriesName?:
    | string
    | undefined;
  /** Sales series number. */
  salesSeriesNumber?:
    | string
    | undefined;
  /** Sales series profile id. */
  profileId?: string | undefined;
}

/** Parameters of external authentication. */
export interface ExternalAuth {
  /** [required] ID of external authentication partner, if any. */
  partnerId?:
    | string
    | undefined;
  /**
   * [required] Username as registered by authentication partner, if any (max length = 255).
   * UTF8 encoding string.
   */
  username?: string | undefined;
}

/** Request for update login billing custom data. */
export interface UpdateLoginBillingCustomData {
  /** [required] Login identifier. */
  loginId?:
    | string
    | undefined;
  /** [required] Login billing custom data. */
  loginBillingCustomData: LoginBillingCustomData[];
}

/** Result of UpdateLoginBillingCustomData. */
export interface UpdateLoginBillingCustomDataResult {
}

export interface LoginBillingCustomData {
  /** [required-update] Account identifier. */
  accountId?:
    | string
    | undefined;
  /**
   * Billing custom data.
   * Maximum count is 3 (max length = 256).
   */
  billingCustomData: string[];
}

/** Request of login billing custom data. */
export interface LoginBillingCustomDataRequest {
  /** [required] Login identifier. */
  loginId?: string | undefined;
}

/** Result of LoginBillingCustomDataRequest. */
export interface LoginBillingCustomDataResult {
  /** Login billing custom data. */
  loginBillingCustomData: LoginBillingCustomData[];
}

export interface TradingFeaturesRequest {
}

export interface TradingFeaturesResult {
  tradingInterfaceElements: TradingInterfaceElement[];
}

export interface TradingInterfaceElement {
  /** ID of the trading interface element. */
  id?:
    | string
    | undefined;
  /** Name of trading interface element. */
  name?:
    | Text
    | undefined;
  /**
   * Indicates if trading interface is not allowed to be enabled
   * and can be only disabled.
   */
  disableOnly?: boolean | undefined;
}

/** Profile information. */
export interface Profile {
  /** List of field ids to clear during update operation. */
  clearedFields: number[];
  /**
   * [immutable] Associated customer.
   * [required-update] for customer and admin profile type and profile_id is not provided.
   * Use profile_id instead.
   */
  customerId?:
    | string
    | undefined;
  /**
   * [required-create] Profile legal type.
   * Profile of admin profile type can be only Individual.
   * This field is associated with common_1.LegalType enum type.
   * Supported profile type: customer, admin.
   */
  legalType?:
    | number
    | undefined;
  /**
   * Profile name (max length = 32).
   * [required-create] for customer/admin and non-Individual legal types.
   * [immutable] for Individual legal type.
   */
  name?:
    | string
    | undefined;
  /** Profile contact information. */
  contactInformation?:
    | ContactInformation
    | undefined;
  /**
   * Brokerage ID.
   * [erasable] only for admin profile type.
   * [required-create] for customer and sales series profile type.
   */
  linkedBrokerageId?:
    | string
    | undefined;
  /** [immutable] Removed flag. */
  removed?:
    | boolean
    | undefined;
  /**
   * [obsolete] Authentication system of login (LoginSettings.authentication_system field) must be used instead.
   * Profile's authentication system.
   * LookupPropertyListRequest { property_type = CommonLookupPropertyType.AUTHENTICATION_SYSTEM }
   *
   * @deprecated
   */
  obsoleteAuthenticationSystem?:
    | string
    | undefined;
  /**
   * Profile type - represents main type of profile.
   * This field is associated with ProfileType enum type.
   * [immutable] for sales series profile type.
   */
  profileType?:
    | number
    | undefined;
  /**
   * [required-update] Profile identifier.
   * For backward compatibility if customer_id is given, it isn't required during update.
   * Has priority over customer_id
   */
  profileId?:
    | string
    | undefined;
  /** [immutable] Associated sales series id. */
  salesSeriesId?:
    | string
    | undefined;
  /**
   * Unique number (max length = 16).
   * [required-create] for sales series profile type.
   */
  number?:
    | string
    | undefined;
  /**
   * Determines, whether profile is simplified, used for cases of auto-generation.
   * Only name is required in this case during creation.
   */
  simplified?: boolean | undefined;
}

/** Generic message to retrieve data. */
export interface DataRequest {
}

/** Generic message to retrieve data associated with some entity. */
export interface EntityDataRequest {
  /** [required] Entity identifier. */
  entityId?: string | undefined;
}

export interface EntitiesDataRequest {
  /** [required] Entities identifiers. */
  entityId: string[];
}

function createBaseUserMessage(): UserMessage {
  return { messageType: 0, source: "", subject: "", text: undefined, expirationUtcTime: 0 };
}

export const UserMessage = {
  encode(message: UserMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.messageType !== 0) {
      writer.uint32(8).uint32(message.messageType);
    }
    if (message.source !== undefined && message.source !== "") {
      writer.uint32(18).string(message.source);
    }
    if (message.subject !== undefined && message.subject !== "") {
      writer.uint32(26).string(message.subject);
    }
    if (message.text !== undefined) {
      Text.encode(message.text, writer.uint32(34).fork()).ldelim();
    }
    if (message.expirationUtcTime !== undefined && message.expirationUtcTime !== 0) {
      writer.uint32(40).sint64(message.expirationUtcTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.messageType = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.source = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subject = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.text = Text.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.expirationUtcTime = longToNumber(reader.sint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserMessage {
    return {
      messageType: isSet(object.messageType) ? globalThis.Number(object.messageType) : 0,
      source: isSet(object.source) ? globalThis.String(object.source) : "",
      subject: isSet(object.subject) ? globalThis.String(object.subject) : "",
      text: isSet(object.text) ? Text.fromJSON(object.text) : undefined,
      expirationUtcTime: isSet(object.expirationUtcTime) ? globalThis.Number(object.expirationUtcTime) : 0,
    };
  },

  toJSON(message: UserMessage): unknown {
    const obj: any = {};
    if (message.messageType !== 0) {
      obj.messageType = Math.round(message.messageType);
    }
    if (message.source !== undefined && message.source !== "") {
      obj.source = message.source;
    }
    if (message.subject !== undefined && message.subject !== "") {
      obj.subject = message.subject;
    }
    if (message.text !== undefined) {
      obj.text = Text.toJSON(message.text);
    }
    if (message.expirationUtcTime !== undefined && message.expirationUtcTime !== 0) {
      obj.expirationUtcTime = Math.round(message.expirationUtcTime);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserMessage>, I>>(base?: I): UserMessage {
    return UserMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserMessage>, I>>(object: I): UserMessage {
    const message = createBaseUserMessage();
    message.messageType = object.messageType ?? 0;
    message.source = object.source ?? "";
    message.subject = object.subject ?? "";
    message.text = (object.text !== undefined && object.text !== null) ? Text.fromPartial(object.text) : undefined;
    message.expirationUtcTime = object.expirationUtcTime ?? 0;
    return message;
  },
};

function createBaseLogon(): Logon {
  return {
    protocolVersionMinor: 0,
    protocolVersionMajor: 0,
    userName: "",
    password: "",
    clientAppId: "",
    clientVersion: "",
    sessionSettings: [],
    dropConcurrentSession: false,
    privateLabel: "",
    accessToken: "",
    subscribeOnRequestStatusChange: false,
  };
}

export const Logon = {
  encode(message: Logon, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.protocolVersionMinor !== undefined && message.protocolVersionMinor !== 0) {
      writer.uint32(8).uint32(message.protocolVersionMinor);
    }
    if (message.protocolVersionMajor !== undefined && message.protocolVersionMajor !== 0) {
      writer.uint32(16).uint32(message.protocolVersionMajor);
    }
    if (message.userName !== undefined && message.userName !== "") {
      writer.uint32(26).string(message.userName);
    }
    if (message.password !== undefined && message.password !== "") {
      writer.uint32(34).string(message.password);
    }
    if (message.clientAppId !== undefined && message.clientAppId !== "") {
      writer.uint32(42).string(message.clientAppId);
    }
    if (message.clientVersion !== undefined && message.clientVersion !== "") {
      writer.uint32(50).string(message.clientVersion);
    }
    writer.uint32(58).fork();
    for (const v of message.sessionSettings) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.dropConcurrentSession !== undefined && message.dropConcurrentSession !== false) {
      writer.uint32(64).bool(message.dropConcurrentSession);
    }
    if (message.privateLabel !== undefined && message.privateLabel !== "") {
      writer.uint32(74).string(message.privateLabel);
    }
    if (message.accessToken !== undefined && message.accessToken !== "") {
      writer.uint32(82).string(message.accessToken);
    }
    if (message.subscribeOnRequestStatusChange !== undefined && message.subscribeOnRequestStatusChange !== false) {
      writer.uint32(88).bool(message.subscribeOnRequestStatusChange);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Logon {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.protocolVersionMinor = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.protocolVersionMajor = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.password = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.clientAppId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.clientVersion = reader.string();
          continue;
        case 7:
          if (tag === 56) {
            message.sessionSettings.push(reader.uint32());

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.sessionSettings.push(reader.uint32());
            }

            continue;
          }

          break;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.dropConcurrentSession = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.privateLabel = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.accessToken = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.subscribeOnRequestStatusChange = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Logon {
    return {
      protocolVersionMinor: isSet(object.protocolVersionMinor) ? globalThis.Number(object.protocolVersionMinor) : 0,
      protocolVersionMajor: isSet(object.protocolVersionMajor) ? globalThis.Number(object.protocolVersionMajor) : 0,
      userName: isSet(object.userName) ? globalThis.String(object.userName) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
      clientAppId: isSet(object.clientAppId) ? globalThis.String(object.clientAppId) : "",
      clientVersion: isSet(object.clientVersion) ? globalThis.String(object.clientVersion) : "",
      sessionSettings: globalThis.Array.isArray(object?.sessionSettings)
        ? object.sessionSettings.map((e: any) => globalThis.Number(e))
        : [],
      dropConcurrentSession: isSet(object.dropConcurrentSession)
        ? globalThis.Boolean(object.dropConcurrentSession)
        : false,
      privateLabel: isSet(object.privateLabel) ? globalThis.String(object.privateLabel) : "",
      accessToken: isSet(object.accessToken) ? globalThis.String(object.accessToken) : "",
      subscribeOnRequestStatusChange: isSet(object.subscribeOnRequestStatusChange)
        ? globalThis.Boolean(object.subscribeOnRequestStatusChange)
        : false,
    };
  },

  toJSON(message: Logon): unknown {
    const obj: any = {};
    if (message.protocolVersionMinor !== undefined && message.protocolVersionMinor !== 0) {
      obj.protocolVersionMinor = Math.round(message.protocolVersionMinor);
    }
    if (message.protocolVersionMajor !== undefined && message.protocolVersionMajor !== 0) {
      obj.protocolVersionMajor = Math.round(message.protocolVersionMajor);
    }
    if (message.userName !== undefined && message.userName !== "") {
      obj.userName = message.userName;
    }
    if (message.password !== undefined && message.password !== "") {
      obj.password = message.password;
    }
    if (message.clientAppId !== undefined && message.clientAppId !== "") {
      obj.clientAppId = message.clientAppId;
    }
    if (message.clientVersion !== undefined && message.clientVersion !== "") {
      obj.clientVersion = message.clientVersion;
    }
    if (message.sessionSettings?.length) {
      obj.sessionSettings = message.sessionSettings.map((e) => Math.round(e));
    }
    if (message.dropConcurrentSession !== undefined && message.dropConcurrentSession !== false) {
      obj.dropConcurrentSession = message.dropConcurrentSession;
    }
    if (message.privateLabel !== undefined && message.privateLabel !== "") {
      obj.privateLabel = message.privateLabel;
    }
    if (message.accessToken !== undefined && message.accessToken !== "") {
      obj.accessToken = message.accessToken;
    }
    if (message.subscribeOnRequestStatusChange !== undefined && message.subscribeOnRequestStatusChange !== false) {
      obj.subscribeOnRequestStatusChange = message.subscribeOnRequestStatusChange;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Logon>, I>>(base?: I): Logon {
    return Logon.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Logon>, I>>(object: I): Logon {
    const message = createBaseLogon();
    message.protocolVersionMinor = object.protocolVersionMinor ?? 0;
    message.protocolVersionMajor = object.protocolVersionMajor ?? 0;
    message.userName = object.userName ?? "";
    message.password = object.password ?? "";
    message.clientAppId = object.clientAppId ?? "";
    message.clientVersion = object.clientVersion ?? "";
    message.sessionSettings = object.sessionSettings?.map((e) => e) || [];
    message.dropConcurrentSession = object.dropConcurrentSession ?? false;
    message.privateLabel = object.privateLabel ?? "";
    message.accessToken = object.accessToken ?? "";
    message.subscribeOnRequestStatusChange = object.subscribeOnRequestStatusChange ?? false;
    return message;
  },
};

function createBaseRestoreOrJoinSession(): RestoreOrJoinSession {
  return {
    sessionToken: "",
    clientAppId: "",
    clientIpAddress: "",
    subscribeOnRequestStatusChange: false,
    operationType: 0,
  };
}

export const RestoreOrJoinSession = {
  encode(message: RestoreOrJoinSession, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionToken !== "") {
      writer.uint32(10).string(message.sessionToken);
    }
    if (message.clientAppId !== "") {
      writer.uint32(18).string(message.clientAppId);
    }
    if (message.clientIpAddress !== undefined && message.clientIpAddress !== "") {
      writer.uint32(26).string(message.clientIpAddress);
    }
    if (message.subscribeOnRequestStatusChange !== undefined && message.subscribeOnRequestStatusChange !== false) {
      writer.uint32(32).bool(message.subscribeOnRequestStatusChange);
    }
    if (message.operationType !== undefined && message.operationType !== 0) {
      writer.uint32(40).uint32(message.operationType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RestoreOrJoinSession {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRestoreOrJoinSession();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionToken = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientAppId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientIpAddress = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.subscribeOnRequestStatusChange = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.operationType = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RestoreOrJoinSession {
    return {
      sessionToken: isSet(object.sessionToken) ? globalThis.String(object.sessionToken) : "",
      clientAppId: isSet(object.clientAppId) ? globalThis.String(object.clientAppId) : "",
      clientIpAddress: isSet(object.clientIpAddress) ? globalThis.String(object.clientIpAddress) : "",
      subscribeOnRequestStatusChange: isSet(object.subscribeOnRequestStatusChange)
        ? globalThis.Boolean(object.subscribeOnRequestStatusChange)
        : false,
      operationType: isSet(object.operationType) ? globalThis.Number(object.operationType) : 0,
    };
  },

  toJSON(message: RestoreOrJoinSession): unknown {
    const obj: any = {};
    if (message.sessionToken !== "") {
      obj.sessionToken = message.sessionToken;
    }
    if (message.clientAppId !== "") {
      obj.clientAppId = message.clientAppId;
    }
    if (message.clientIpAddress !== undefined && message.clientIpAddress !== "") {
      obj.clientIpAddress = message.clientIpAddress;
    }
    if (message.subscribeOnRequestStatusChange !== undefined && message.subscribeOnRequestStatusChange !== false) {
      obj.subscribeOnRequestStatusChange = message.subscribeOnRequestStatusChange;
    }
    if (message.operationType !== undefined && message.operationType !== 0) {
      obj.operationType = Math.round(message.operationType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RestoreOrJoinSession>, I>>(base?: I): RestoreOrJoinSession {
    return RestoreOrJoinSession.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RestoreOrJoinSession>, I>>(object: I): RestoreOrJoinSession {
    const message = createBaseRestoreOrJoinSession();
    message.sessionToken = object.sessionToken ?? "";
    message.clientAppId = object.clientAppId ?? "";
    message.clientIpAddress = object.clientIpAddress ?? "";
    message.subscribeOnRequestStatusChange = object.subscribeOnRequestStatusChange ?? false;
    message.operationType = object.operationType ?? 0;
    return message;
  },
};

function createBaseLogoff(): Logoff {
  return {};
}

export const Logoff = {
  encode(_: Logoff, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Logoff {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogoff();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): Logoff {
    return {};
  },

  toJSON(_: Logoff): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<Logoff>, I>>(base?: I): Logoff {
    return Logoff.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Logoff>, I>>(_: I): Logoff {
    const message = createBaseLogoff();
    return message;
  },
};

function createBaseCredentials(): Credentials {
  return { userName: "", password: "" };
}

export const Credentials = {
  encode(message: Credentials, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userName !== "") {
      writer.uint32(10).string(message.userName);
    }
    if (message.password !== undefined && message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Credentials {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCredentials();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.password = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Credentials {
    return {
      userName: isSet(object.userName) ? globalThis.String(object.userName) : "",
      password: isSet(object.password) ? globalThis.String(object.password) : "",
    };
  },

  toJSON(message: Credentials): unknown {
    const obj: any = {};
    if (message.userName !== "") {
      obj.userName = message.userName;
    }
    if (message.password !== undefined && message.password !== "") {
      obj.password = message.password;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Credentials>, I>>(base?: I): Credentials {
    return Credentials.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Credentials>, I>>(object: I): Credentials {
    const message = createBaseCredentials();
    message.userName = object.userName ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseLogonResult(): LogonResult {
  return {
    protocolVersionMinor: 0,
    protocolVersionMajor: 0,
    operationStatus: 0,
    errorMessage: undefined,
    sessionToken: "",
  };
}

export const LogonResult = {
  encode(message: LogonResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.protocolVersionMinor !== 0) {
      writer.uint32(8).uint32(message.protocolVersionMinor);
    }
    if (message.protocolVersionMajor !== 0) {
      writer.uint32(16).uint32(message.protocolVersionMajor);
    }
    if (message.operationStatus !== 0) {
      writer.uint32(24).uint32(message.operationStatus);
    }
    if (message.errorMessage !== undefined) {
      Text.encode(message.errorMessage, writer.uint32(34).fork()).ldelim();
    }
    if (message.sessionToken !== undefined && message.sessionToken !== "") {
      writer.uint32(42).string(message.sessionToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogonResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogonResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.protocolVersionMinor = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.protocolVersionMajor = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.operationStatus = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.errorMessage = Text.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sessionToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LogonResult {
    return {
      protocolVersionMinor: isSet(object.protocolVersionMinor) ? globalThis.Number(object.protocolVersionMinor) : 0,
      protocolVersionMajor: isSet(object.protocolVersionMajor) ? globalThis.Number(object.protocolVersionMajor) : 0,
      operationStatus: isSet(object.operationStatus) ? globalThis.Number(object.operationStatus) : 0,
      errorMessage: isSet(object.errorMessage) ? Text.fromJSON(object.errorMessage) : undefined,
      sessionToken: isSet(object.sessionToken) ? globalThis.String(object.sessionToken) : "",
    };
  },

  toJSON(message: LogonResult): unknown {
    const obj: any = {};
    if (message.protocolVersionMinor !== 0) {
      obj.protocolVersionMinor = Math.round(message.protocolVersionMinor);
    }
    if (message.protocolVersionMajor !== 0) {
      obj.protocolVersionMajor = Math.round(message.protocolVersionMajor);
    }
    if (message.operationStatus !== 0) {
      obj.operationStatus = Math.round(message.operationStatus);
    }
    if (message.errorMessage !== undefined) {
      obj.errorMessage = Text.toJSON(message.errorMessage);
    }
    if (message.sessionToken !== undefined && message.sessionToken !== "") {
      obj.sessionToken = message.sessionToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LogonResult>, I>>(base?: I): LogonResult {
    return LogonResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LogonResult>, I>>(object: I): LogonResult {
    const message = createBaseLogonResult();
    message.protocolVersionMinor = object.protocolVersionMinor ?? 0;
    message.protocolVersionMajor = object.protocolVersionMajor ?? 0;
    message.operationStatus = object.operationStatus ?? 0;
    message.errorMessage = (object.errorMessage !== undefined && object.errorMessage !== null)
      ? Text.fromPartial(object.errorMessage)
      : undefined;
    message.sessionToken = object.sessionToken ?? "";
    return message;
  },
};

function createBaseRestoreOrJoinSessionResult(): RestoreOrJoinSessionResult {
  return { operationStatus: 0, errorMessage: undefined };
}

export const RestoreOrJoinSessionResult = {
  encode(message: RestoreOrJoinSessionResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.operationStatus !== 0) {
      writer.uint32(8).uint32(message.operationStatus);
    }
    if (message.errorMessage !== undefined) {
      Text.encode(message.errorMessage, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RestoreOrJoinSessionResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRestoreOrJoinSessionResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.operationStatus = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.errorMessage = Text.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RestoreOrJoinSessionResult {
    return {
      operationStatus: isSet(object.operationStatus) ? globalThis.Number(object.operationStatus) : 0,
      errorMessage: isSet(object.errorMessage) ? Text.fromJSON(object.errorMessage) : undefined,
    };
  },

  toJSON(message: RestoreOrJoinSessionResult): unknown {
    const obj: any = {};
    if (message.operationStatus !== 0) {
      obj.operationStatus = Math.round(message.operationStatus);
    }
    if (message.errorMessage !== undefined) {
      obj.errorMessage = Text.toJSON(message.errorMessage);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RestoreOrJoinSessionResult>, I>>(base?: I): RestoreOrJoinSessionResult {
    return RestoreOrJoinSessionResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RestoreOrJoinSessionResult>, I>>(object: I): RestoreOrJoinSessionResult {
    const message = createBaseRestoreOrJoinSessionResult();
    message.operationStatus = object.operationStatus ?? 0;
    message.errorMessage = (object.errorMessage !== undefined && object.errorMessage !== null)
      ? Text.fromPartial(object.errorMessage)
      : undefined;
    return message;
  },
};

function createBaseLoggedOff(): LoggedOff {
  return { reason: undefined };
}

export const LoggedOff = {
  encode(message: LoggedOff, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.reason !== undefined) {
      Text.encode(message.reason, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoggedOff {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoggedOff();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.reason = Text.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoggedOff {
    return { reason: isSet(object.reason) ? Text.fromJSON(object.reason) : undefined };
  },

  toJSON(message: LoggedOff): unknown {
    const obj: any = {};
    if (message.reason !== undefined) {
      obj.reason = Text.toJSON(message.reason);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoggedOff>, I>>(base?: I): LoggedOff {
    return LoggedOff.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoggedOff>, I>>(object: I): LoggedOff {
    const message = createBaseLoggedOff();
    message.reason = (object.reason !== undefined && object.reason !== null)
      ? Text.fromPartial(object.reason)
      : undefined;
    return message;
  },
};

function createBaseEntitlementCategoryListRequest(): EntitlementCategoryListRequest {
  return {};
}

export const EntitlementCategoryListRequest = {
  encode(_: EntitlementCategoryListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementCategoryListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementCategoryListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EntitlementCategoryListRequest {
    return {};
  },

  toJSON(_: EntitlementCategoryListRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementCategoryListRequest>, I>>(base?: I): EntitlementCategoryListRequest {
    return EntitlementCategoryListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementCategoryListRequest>, I>>(_: I): EntitlementCategoryListRequest {
    const message = createBaseEntitlementCategoryListRequest();
    return message;
  },
};

function createBaseEntitlementServiceRequest(): EntitlementServiceRequest {
  return { entitlementServiceId: 0 };
}

export const EntitlementServiceRequest = {
  encode(message: EntitlementServiceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entitlementServiceId !== 0) {
      writer.uint32(8).uint32(message.entitlementServiceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementServiceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementServiceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.entitlementServiceId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitlementServiceRequest {
    return {
      entitlementServiceId: isSet(object.entitlementServiceId) ? globalThis.Number(object.entitlementServiceId) : 0,
    };
  },

  toJSON(message: EntitlementServiceRequest): unknown {
    const obj: any = {};
    if (message.entitlementServiceId !== 0) {
      obj.entitlementServiceId = Math.round(message.entitlementServiceId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementServiceRequest>, I>>(base?: I): EntitlementServiceRequest {
    return EntitlementServiceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementServiceRequest>, I>>(object: I): EntitlementServiceRequest {
    const message = createBaseEntitlementServiceRequest();
    message.entitlementServiceId = object.entitlementServiceId ?? 0;
    return message;
  },
};

function createBaseEntitlementServiceListRequest(): EntitlementServiceListRequest {
  return { brokerageId: [], includeRetired: false };
}

export const EntitlementServiceListRequest = {
  encode(message: EntitlementServiceListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.brokerageId) {
      writer.uint32(10).string(v!);
    }
    if (message.includeRetired !== undefined && message.includeRetired !== false) {
      writer.uint32(16).bool(message.includeRetired);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementServiceListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementServiceListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerageId.push(reader.string());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.includeRetired = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitlementServiceListRequest {
    return {
      brokerageId: globalThis.Array.isArray(object?.brokerageId)
        ? object.brokerageId.map((e: any) => globalThis.String(e))
        : [],
      includeRetired: isSet(object.includeRetired) ? globalThis.Boolean(object.includeRetired) : false,
    };
  },

  toJSON(message: EntitlementServiceListRequest): unknown {
    const obj: any = {};
    if (message.brokerageId?.length) {
      obj.brokerageId = message.brokerageId;
    }
    if (message.includeRetired !== undefined && message.includeRetired !== false) {
      obj.includeRetired = message.includeRetired;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementServiceListRequest>, I>>(base?: I): EntitlementServiceListRequest {
    return EntitlementServiceListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementServiceListRequest>, I>>(
    object: I,
  ): EntitlementServiceListRequest {
    const message = createBaseEntitlementServiceListRequest();
    message.brokerageId = object.brokerageId?.map((e) => e) || [];
    message.includeRetired = object.includeRetired ?? false;
    return message;
  },
};

function createBaseEntitlementService(): EntitlementService {
  return {
    id: 0,
    name: undefined,
    categoryId: [],
    price: [],
    description: undefined,
    authorizationType: 0,
    constraint: [],
    obsoleteAgreementRequired: false,
    obsoleteAgreement: undefined,
    subscriberType: 0,
    billingBrokerageRequired: false,
    loginDomains: [],
    options: [],
    items: [],
    brokerageAssignable: false,
    brokerageAuthorizationRequired: false,
    default: false,
    visibleByCqgOnly: false,
    contractType: "",
  };
}

export const EntitlementService = {
  encode(message: EntitlementService, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.name !== undefined) {
      Text.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.categoryId) {
      writer.uint32(v);
    }
    writer.ldelim();
    for (const v of message.price) {
      Price.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.description !== undefined) {
      Text.encode(message.description, writer.uint32(42).fork()).ldelim();
    }
    if (message.authorizationType !== 0) {
      writer.uint32(48).uint32(message.authorizationType);
    }
    for (const v of message.constraint) {
      EntitlementConstraint.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.obsoleteAgreementRequired !== undefined && message.obsoleteAgreementRequired !== false) {
      writer.uint32(64).bool(message.obsoleteAgreementRequired);
    }
    if (message.obsoleteAgreement !== undefined) {
      Text.encode(message.obsoleteAgreement, writer.uint32(74).fork()).ldelim();
    }
    if (message.subscriberType !== undefined && message.subscriberType !== 0) {
      writer.uint32(80).uint32(message.subscriberType);
    }
    if (message.billingBrokerageRequired !== undefined && message.billingBrokerageRequired !== false) {
      writer.uint32(88).bool(message.billingBrokerageRequired);
    }
    writer.uint32(98).fork();
    for (const v of message.loginDomains) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(106).fork();
    for (const v of message.options) {
      writer.uint32(v);
    }
    writer.ldelim();
    for (const v of message.items) {
      EntitlementServiceItem.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    if (message.brokerageAssignable !== undefined && message.brokerageAssignable !== false) {
      writer.uint32(120).bool(message.brokerageAssignable);
    }
    if (message.brokerageAuthorizationRequired !== undefined && message.brokerageAuthorizationRequired !== false) {
      writer.uint32(128).bool(message.brokerageAuthorizationRequired);
    }
    if (message.default !== undefined && message.default !== false) {
      writer.uint32(136).bool(message.default);
    }
    if (message.visibleByCqgOnly !== undefined && message.visibleByCqgOnly !== false) {
      writer.uint32(144).bool(message.visibleByCqgOnly);
    }
    if (message.contractType !== undefined && message.contractType !== "") {
      writer.uint32(154).string(message.contractType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementService {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementService();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Text.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag === 24) {
            message.categoryId.push(reader.uint32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.categoryId.push(reader.uint32());
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.price.push(Price.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = Text.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.authorizationType = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.constraint.push(EntitlementConstraint.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.obsoleteAgreementRequired = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.obsoleteAgreement = Text.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.subscriberType = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.billingBrokerageRequired = reader.bool();
          continue;
        case 12:
          if (tag === 96) {
            message.loginDomains.push(reader.uint32());

            continue;
          }

          if (tag === 98) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.loginDomains.push(reader.uint32());
            }

            continue;
          }

          break;
        case 13:
          if (tag === 104) {
            message.options.push(reader.uint32());

            continue;
          }

          if (tag === 106) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.options.push(reader.uint32());
            }

            continue;
          }

          break;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.items.push(EntitlementServiceItem.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.brokerageAssignable = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.brokerageAuthorizationRequired = reader.bool();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.default = reader.bool();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.visibleByCqgOnly = reader.bool();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.contractType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitlementService {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? Text.fromJSON(object.name) : undefined,
      categoryId: globalThis.Array.isArray(object?.categoryId)
        ? object.categoryId.map((e: any) => globalThis.Number(e))
        : [],
      price: globalThis.Array.isArray(object?.price) ? object.price.map((e: any) => Price.fromJSON(e)) : [],
      description: isSet(object.description) ? Text.fromJSON(object.description) : undefined,
      authorizationType: isSet(object.authorizationType) ? globalThis.Number(object.authorizationType) : 0,
      constraint: globalThis.Array.isArray(object?.constraint)
        ? object.constraint.map((e: any) => EntitlementConstraint.fromJSON(e))
        : [],
      obsoleteAgreementRequired: isSet(object.obsoleteAgreementRequired)
        ? globalThis.Boolean(object.obsoleteAgreementRequired)
        : false,
      obsoleteAgreement: isSet(object.obsoleteAgreement) ? Text.fromJSON(object.obsoleteAgreement) : undefined,
      subscriberType: isSet(object.subscriberType) ? globalThis.Number(object.subscriberType) : 0,
      billingBrokerageRequired: isSet(object.billingBrokerageRequired)
        ? globalThis.Boolean(object.billingBrokerageRequired)
        : false,
      loginDomains: globalThis.Array.isArray(object?.loginDomains)
        ? object.loginDomains.map((e: any) => globalThis.Number(e))
        : [],
      options: globalThis.Array.isArray(object?.options)
        ? object.options.map((e: any) => globalThis.Number(e))
        : [],
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => EntitlementServiceItem.fromJSON(e))
        : [],
      brokerageAssignable: isSet(object.brokerageAssignable) ? globalThis.Boolean(object.brokerageAssignable) : false,
      brokerageAuthorizationRequired: isSet(object.brokerageAuthorizationRequired)
        ? globalThis.Boolean(object.brokerageAuthorizationRequired)
        : false,
      default: isSet(object.default) ? globalThis.Boolean(object.default) : false,
      visibleByCqgOnly: isSet(object.visibleByCqgOnly) ? globalThis.Boolean(object.visibleByCqgOnly) : false,
      contractType: isSet(object.contractType) ? globalThis.String(object.contractType) : "",
    };
  },

  toJSON(message: EntitlementService): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== undefined) {
      obj.name = Text.toJSON(message.name);
    }
    if (message.categoryId?.length) {
      obj.categoryId = message.categoryId.map((e) => Math.round(e));
    }
    if (message.price?.length) {
      obj.price = message.price.map((e) => Price.toJSON(e));
    }
    if (message.description !== undefined) {
      obj.description = Text.toJSON(message.description);
    }
    if (message.authorizationType !== 0) {
      obj.authorizationType = Math.round(message.authorizationType);
    }
    if (message.constraint?.length) {
      obj.constraint = message.constraint.map((e) => EntitlementConstraint.toJSON(e));
    }
    if (message.obsoleteAgreementRequired !== undefined && message.obsoleteAgreementRequired !== false) {
      obj.obsoleteAgreementRequired = message.obsoleteAgreementRequired;
    }
    if (message.obsoleteAgreement !== undefined) {
      obj.obsoleteAgreement = Text.toJSON(message.obsoleteAgreement);
    }
    if (message.subscriberType !== undefined && message.subscriberType !== 0) {
      obj.subscriberType = Math.round(message.subscriberType);
    }
    if (message.billingBrokerageRequired !== undefined && message.billingBrokerageRequired !== false) {
      obj.billingBrokerageRequired = message.billingBrokerageRequired;
    }
    if (message.loginDomains?.length) {
      obj.loginDomains = message.loginDomains.map((e) => Math.round(e));
    }
    if (message.options?.length) {
      obj.options = message.options.map((e) => Math.round(e));
    }
    if (message.items?.length) {
      obj.items = message.items.map((e) => EntitlementServiceItem.toJSON(e));
    }
    if (message.brokerageAssignable !== undefined && message.brokerageAssignable !== false) {
      obj.brokerageAssignable = message.brokerageAssignable;
    }
    if (message.brokerageAuthorizationRequired !== undefined && message.brokerageAuthorizationRequired !== false) {
      obj.brokerageAuthorizationRequired = message.brokerageAuthorizationRequired;
    }
    if (message.default !== undefined && message.default !== false) {
      obj.default = message.default;
    }
    if (message.visibleByCqgOnly !== undefined && message.visibleByCqgOnly !== false) {
      obj.visibleByCqgOnly = message.visibleByCqgOnly;
    }
    if (message.contractType !== undefined && message.contractType !== "") {
      obj.contractType = message.contractType;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementService>, I>>(base?: I): EntitlementService {
    return EntitlementService.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementService>, I>>(object: I): EntitlementService {
    const message = createBaseEntitlementService();
    message.id = object.id ?? 0;
    message.name = (object.name !== undefined && object.name !== null) ? Text.fromPartial(object.name) : undefined;
    message.categoryId = object.categoryId?.map((e) => e) || [];
    message.price = object.price?.map((e) => Price.fromPartial(e)) || [];
    message.description = (object.description !== undefined && object.description !== null)
      ? Text.fromPartial(object.description)
      : undefined;
    message.authorizationType = object.authorizationType ?? 0;
    message.constraint = object.constraint?.map((e) => EntitlementConstraint.fromPartial(e)) || [];
    message.obsoleteAgreementRequired = object.obsoleteAgreementRequired ?? false;
    message.obsoleteAgreement = (object.obsoleteAgreement !== undefined && object.obsoleteAgreement !== null)
      ? Text.fromPartial(object.obsoleteAgreement)
      : undefined;
    message.subscriberType = object.subscriberType ?? 0;
    message.billingBrokerageRequired = object.billingBrokerageRequired ?? false;
    message.loginDomains = object.loginDomains?.map((e) => e) || [];
    message.options = object.options?.map((e) => e) || [];
    message.items = object.items?.map((e) => EntitlementServiceItem.fromPartial(e)) || [];
    message.brokerageAssignable = object.brokerageAssignable ?? false;
    message.brokerageAuthorizationRequired = object.brokerageAuthorizationRequired ?? false;
    message.default = object.default ?? false;
    message.visibleByCqgOnly = object.visibleByCqgOnly ?? false;
    message.contractType = object.contractType ?? "";
    return message;
  },
};

function createBaseEntitlementServiceItem(): EntitlementServiceItem {
  return { id: "", type: undefined, name: undefined };
}

export const EntitlementServiceItem = {
  encode(message: EntitlementServiceItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.type !== undefined) {
      EntitlementServiceItemType.encode(message.type, writer.uint32(18).fork()).ldelim();
    }
    if (message.name !== undefined) {
      Text.encode(message.name, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementServiceItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementServiceItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = EntitlementServiceItemType.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = Text.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitlementServiceItem {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      type: isSet(object.type) ? EntitlementServiceItemType.fromJSON(object.type) : undefined,
      name: isSet(object.name) ? Text.fromJSON(object.name) : undefined,
    };
  },

  toJSON(message: EntitlementServiceItem): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.type !== undefined) {
      obj.type = EntitlementServiceItemType.toJSON(message.type);
    }
    if (message.name !== undefined) {
      obj.name = Text.toJSON(message.name);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementServiceItem>, I>>(base?: I): EntitlementServiceItem {
    return EntitlementServiceItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementServiceItem>, I>>(object: I): EntitlementServiceItem {
    const message = createBaseEntitlementServiceItem();
    message.id = object.id ?? "";
    message.type = (object.type !== undefined && object.type !== null)
      ? EntitlementServiceItemType.fromPartial(object.type)
      : undefined;
    message.name = (object.name !== undefined && object.name !== null) ? Text.fromPartial(object.name) : undefined;
    return message;
  },
};

function createBaseEntitlementServiceItemType(): EntitlementServiceItemType {
  return { id: "", name: undefined, description: undefined, isMarketData: false };
}

export const EntitlementServiceItemType = {
  encode(message: EntitlementServiceItemType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      Text.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    if (message.description !== undefined) {
      Text.encode(message.description, writer.uint32(26).fork()).ldelim();
    }
    if (message.isMarketData !== undefined && message.isMarketData !== false) {
      writer.uint32(32).bool(message.isMarketData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementServiceItemType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementServiceItemType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Text.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = Text.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isMarketData = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitlementServiceItemType {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? Text.fromJSON(object.name) : undefined,
      description: isSet(object.description) ? Text.fromJSON(object.description) : undefined,
      isMarketData: isSet(object.isMarketData) ? globalThis.Boolean(object.isMarketData) : false,
    };
  },

  toJSON(message: EntitlementServiceItemType): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== undefined) {
      obj.name = Text.toJSON(message.name);
    }
    if (message.description !== undefined) {
      obj.description = Text.toJSON(message.description);
    }
    if (message.isMarketData !== undefined && message.isMarketData !== false) {
      obj.isMarketData = message.isMarketData;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementServiceItemType>, I>>(base?: I): EntitlementServiceItemType {
    return EntitlementServiceItemType.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementServiceItemType>, I>>(object: I): EntitlementServiceItemType {
    const message = createBaseEntitlementServiceItemType();
    message.id = object.id ?? "";
    message.name = (object.name !== undefined && object.name !== null) ? Text.fromPartial(object.name) : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? Text.fromPartial(object.description)
      : undefined;
    message.isMarketData = object.isMarketData ?? false;
    return message;
  },
};

function createBasePrice(): Price {
  return { currency: "", value: 0 };
}

export const Price = {
  encode(message: Price, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currency !== "") {
      writer.uint32(10).string(message.currency);
    }
    if (message.value !== 0) {
      writer.uint32(17).double(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Price {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.value = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Price {
    return {
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: Price): unknown {
    const obj: any = {};
    if (message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Price>, I>>(base?: I): Price {
    return Price.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Price>, I>>(object: I): Price {
    const message = createBasePrice();
    message.currency = object.currency ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseServicePriceOverride(): ServicePriceOverride {
  return { serviceId: "", price: undefined };
}

export const ServicePriceOverride = {
  encode(message: ServicePriceOverride, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serviceId !== undefined && message.serviceId !== "") {
      writer.uint32(10).string(message.serviceId);
    }
    if (message.price !== undefined) {
      Price.encode(message.price, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServicePriceOverride {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServicePriceOverride();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serviceId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.price = Price.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServicePriceOverride {
    return {
      serviceId: isSet(object.serviceId) ? globalThis.String(object.serviceId) : "",
      price: isSet(object.price) ? Price.fromJSON(object.price) : undefined,
    };
  },

  toJSON(message: ServicePriceOverride): unknown {
    const obj: any = {};
    if (message.serviceId !== undefined && message.serviceId !== "") {
      obj.serviceId = message.serviceId;
    }
    if (message.price !== undefined) {
      obj.price = Price.toJSON(message.price);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServicePriceOverride>, I>>(base?: I): ServicePriceOverride {
    return ServicePriceOverride.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServicePriceOverride>, I>>(object: I): ServicePriceOverride {
    const message = createBaseServicePriceOverride();
    message.serviceId = object.serviceId ?? "";
    message.price = (object.price !== undefined && object.price !== null) ? Price.fromPartial(object.price) : undefined;
    return message;
  },
};

function createBaseEntitlementCategory(): EntitlementCategory {
  return { id: 0, name: undefined, subCategory: [], brokerageAssignable: false };
}

export const EntitlementCategory = {
  encode(message: EntitlementCategory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.name !== undefined) {
      Text.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.subCategory) {
      EntitlementCategory.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.brokerageAssignable !== undefined && message.brokerageAssignable !== false) {
      writer.uint32(32).bool(message.brokerageAssignable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementCategory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Text.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subCategory.push(EntitlementCategory.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.brokerageAssignable = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitlementCategory {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? Text.fromJSON(object.name) : undefined,
      subCategory: globalThis.Array.isArray(object?.subCategory)
        ? object.subCategory.map((e: any) => EntitlementCategory.fromJSON(e))
        : [],
      brokerageAssignable: isSet(object.brokerageAssignable) ? globalThis.Boolean(object.brokerageAssignable) : false,
    };
  },

  toJSON(message: EntitlementCategory): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== undefined) {
      obj.name = Text.toJSON(message.name);
    }
    if (message.subCategory?.length) {
      obj.subCategory = message.subCategory.map((e) => EntitlementCategory.toJSON(e));
    }
    if (message.brokerageAssignable !== undefined && message.brokerageAssignable !== false) {
      obj.brokerageAssignable = message.brokerageAssignable;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementCategory>, I>>(base?: I): EntitlementCategory {
    return EntitlementCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementCategory>, I>>(object: I): EntitlementCategory {
    const message = createBaseEntitlementCategory();
    message.id = object.id ?? 0;
    message.name = (object.name !== undefined && object.name !== null) ? Text.fromPartial(object.name) : undefined;
    message.subCategory = object.subCategory?.map((e) => EntitlementCategory.fromPartial(e)) || [];
    message.brokerageAssignable = object.brokerageAssignable ?? false;
    return message;
  },
};

function createBaseEntitlementConstraint(): EntitlementConstraint {
  return { constraintType: 0, refServiceId: 0, constraintGroup: "" };
}

export const EntitlementConstraint = {
  encode(message: EntitlementConstraint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.constraintType !== 0) {
      writer.uint32(8).uint32(message.constraintType);
    }
    if (message.refServiceId !== undefined && message.refServiceId !== 0) {
      writer.uint32(16).uint32(message.refServiceId);
    }
    if (message.constraintGroup !== undefined && message.constraintGroup !== "") {
      writer.uint32(26).string(message.constraintGroup);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitlementConstraint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementConstraint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.constraintType = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.refServiceId = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.constraintGroup = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitlementConstraint {
    return {
      constraintType: isSet(object.constraintType) ? globalThis.Number(object.constraintType) : 0,
      refServiceId: isSet(object.refServiceId) ? globalThis.Number(object.refServiceId) : 0,
      constraintGroup: isSet(object.constraintGroup) ? globalThis.String(object.constraintGroup) : "",
    };
  },

  toJSON(message: EntitlementConstraint): unknown {
    const obj: any = {};
    if (message.constraintType !== 0) {
      obj.constraintType = Math.round(message.constraintType);
    }
    if (message.refServiceId !== undefined && message.refServiceId !== 0) {
      obj.refServiceId = Math.round(message.refServiceId);
    }
    if (message.constraintGroup !== undefined && message.constraintGroup !== "") {
      obj.constraintGroup = message.constraintGroup;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitlementConstraint>, I>>(base?: I): EntitlementConstraint {
    return EntitlementConstraint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementConstraint>, I>>(object: I): EntitlementConstraint {
    const message = createBaseEntitlementConstraint();
    message.constraintType = object.constraintType ?? 0;
    message.refServiceId = object.refServiceId ?? 0;
    message.constraintGroup = object.constraintGroup ?? "";
    return message;
  },
};

function createBaseUserInfoRequest(): UserInfoRequest {
  return { userId: "" };
}

export const UserInfoRequest = {
  encode(message: UserInfoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(34).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserInfoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserInfoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserInfoRequest {
    return { userId: isSet(object.userId) ? globalThis.String(object.userId) : "" };
  },

  toJSON(message: UserInfoRequest): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserInfoRequest>, I>>(base?: I): UserInfoRequest {
    return UserInfoRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserInfoRequest>, I>>(object: I): UserInfoRequest {
    const message = createBaseUserInfoRequest();
    message.userId = object.userId ?? "";
    return message;
  },
};

function createBaseUser(): User {
  return {
    clearedFields: [],
    id: "",
    userName: "",
    obsoleteFirstName: "",
    obsoleteLastName: "",
    obsoleteEmail: "",
    customerId: "",
    subscriberType: 0,
    removalDate: 0,
    systemId: "",
    traderId: "",
    enforcedConstraintGroup: [],
    currency: "",
    domain: 0,
    removed: false,
    class: 0,
    isTemporary: false,
    isActive: false,
    expirationTime: 0,
    castUserId: "",
    scope: 0,
    enforceIpWhitelist: false,
    ipWhitelist: [],
    profileId: "",
  };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(186).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.userName !== undefined && message.userName !== "") {
      writer.uint32(18).string(message.userName);
    }
    if (message.obsoleteFirstName !== undefined && message.obsoleteFirstName !== "") {
      writer.uint32(26).string(message.obsoleteFirstName);
    }
    if (message.obsoleteLastName !== undefined && message.obsoleteLastName !== "") {
      writer.uint32(34).string(message.obsoleteLastName);
    }
    if (message.obsoleteEmail !== undefined && message.obsoleteEmail !== "") {
      writer.uint32(42).string(message.obsoleteEmail);
    }
    if (message.customerId !== undefined && message.customerId !== "") {
      writer.uint32(50).string(message.customerId);
    }
    if (message.subscriberType !== undefined && message.subscriberType !== 0) {
      writer.uint32(56).uint32(message.subscriberType);
    }
    if (message.removalDate !== undefined && message.removalDate !== 0) {
      writer.uint32(64).sint64(message.removalDate);
    }
    if (message.systemId !== undefined && message.systemId !== "") {
      writer.uint32(74).string(message.systemId);
    }
    if (message.traderId !== undefined && message.traderId !== "") {
      writer.uint32(82).string(message.traderId);
    }
    for (const v of message.enforcedConstraintGroup) {
      writer.uint32(90).string(v!);
    }
    if (message.currency !== undefined && message.currency !== "") {
      writer.uint32(98).string(message.currency);
    }
    if (message.domain !== undefined && message.domain !== 0) {
      writer.uint32(104).uint32(message.domain);
    }
    if (message.removed !== undefined && message.removed !== false) {
      writer.uint32(112).bool(message.removed);
    }
    if (message.class !== undefined && message.class !== 0) {
      writer.uint32(120).uint32(message.class);
    }
    if (message.isTemporary !== undefined && message.isTemporary !== false) {
      writer.uint32(128).bool(message.isTemporary);
    }
    if (message.isActive !== undefined && message.isActive !== false) {
      writer.uint32(136).bool(message.isActive);
    }
    if (message.expirationTime !== undefined && message.expirationTime !== 0) {
      writer.uint32(144).sint64(message.expirationTime);
    }
    if (message.castUserId !== undefined && message.castUserId !== "") {
      writer.uint32(154).string(message.castUserId);
    }
    if (message.scope !== undefined && message.scope !== 0) {
      writer.uint32(160).uint32(message.scope);
    }
    if (message.enforceIpWhitelist !== undefined && message.enforceIpWhitelist !== false) {
      writer.uint32(168).bool(message.enforceIpWhitelist);
    }
    for (const v of message.ipWhitelist) {
      writer.uint32(178).string(v!);
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      writer.uint32(194).string(message.profileId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 23:
          if (tag === 184) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 186) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.obsoleteFirstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.obsoleteLastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.obsoleteEmail = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.subscriberType = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.removalDate = longToNumber(reader.sint64() as Long);
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.systemId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.traderId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.enforcedConstraintGroup.push(reader.string());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.domain = reader.uint32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.removed = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.class = reader.uint32();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.isTemporary = reader.bool();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.isActive = reader.bool();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.expirationTime = longToNumber(reader.sint64() as Long);
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.castUserId = reader.string();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.scope = reader.uint32();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.enforceIpWhitelist = reader.bool();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.ipWhitelist.push(reader.string());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.profileId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      userName: isSet(object.userName) ? globalThis.String(object.userName) : "",
      obsoleteFirstName: isSet(object.obsoleteFirstName) ? globalThis.String(object.obsoleteFirstName) : "",
      obsoleteLastName: isSet(object.obsoleteLastName) ? globalThis.String(object.obsoleteLastName) : "",
      obsoleteEmail: isSet(object.obsoleteEmail) ? globalThis.String(object.obsoleteEmail) : "",
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "",
      subscriberType: isSet(object.subscriberType) ? globalThis.Number(object.subscriberType) : 0,
      removalDate: isSet(object.removalDate) ? globalThis.Number(object.removalDate) : 0,
      systemId: isSet(object.systemId) ? globalThis.String(object.systemId) : "",
      traderId: isSet(object.traderId) ? globalThis.String(object.traderId) : "",
      enforcedConstraintGroup: globalThis.Array.isArray(object?.enforcedConstraintGroup)
        ? object.enforcedConstraintGroup.map((e: any) => globalThis.String(e))
        : [],
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
      domain: isSet(object.domain) ? globalThis.Number(object.domain) : 0,
      removed: isSet(object.removed) ? globalThis.Boolean(object.removed) : false,
      class: isSet(object.class) ? globalThis.Number(object.class) : 0,
      isTemporary: isSet(object.isTemporary) ? globalThis.Boolean(object.isTemporary) : false,
      isActive: isSet(object.isActive) ? globalThis.Boolean(object.isActive) : false,
      expirationTime: isSet(object.expirationTime) ? globalThis.Number(object.expirationTime) : 0,
      castUserId: isSet(object.castUserId) ? globalThis.String(object.castUserId) : "",
      scope: isSet(object.scope) ? globalThis.Number(object.scope) : 0,
      enforceIpWhitelist: isSet(object.enforceIpWhitelist) ? globalThis.Boolean(object.enforceIpWhitelist) : false,
      ipWhitelist: globalThis.Array.isArray(object?.ipWhitelist)
        ? object.ipWhitelist.map((e: any) => globalThis.String(e))
        : [],
      profileId: isSet(object.profileId) ? globalThis.String(object.profileId) : "",
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.userName !== undefined && message.userName !== "") {
      obj.userName = message.userName;
    }
    if (message.obsoleteFirstName !== undefined && message.obsoleteFirstName !== "") {
      obj.obsoleteFirstName = message.obsoleteFirstName;
    }
    if (message.obsoleteLastName !== undefined && message.obsoleteLastName !== "") {
      obj.obsoleteLastName = message.obsoleteLastName;
    }
    if (message.obsoleteEmail !== undefined && message.obsoleteEmail !== "") {
      obj.obsoleteEmail = message.obsoleteEmail;
    }
    if (message.customerId !== undefined && message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    if (message.subscriberType !== undefined && message.subscriberType !== 0) {
      obj.subscriberType = Math.round(message.subscriberType);
    }
    if (message.removalDate !== undefined && message.removalDate !== 0) {
      obj.removalDate = Math.round(message.removalDate);
    }
    if (message.systemId !== undefined && message.systemId !== "") {
      obj.systemId = message.systemId;
    }
    if (message.traderId !== undefined && message.traderId !== "") {
      obj.traderId = message.traderId;
    }
    if (message.enforcedConstraintGroup?.length) {
      obj.enforcedConstraintGroup = message.enforcedConstraintGroup;
    }
    if (message.currency !== undefined && message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.domain !== undefined && message.domain !== 0) {
      obj.domain = Math.round(message.domain);
    }
    if (message.removed !== undefined && message.removed !== false) {
      obj.removed = message.removed;
    }
    if (message.class !== undefined && message.class !== 0) {
      obj.class = Math.round(message.class);
    }
    if (message.isTemporary !== undefined && message.isTemporary !== false) {
      obj.isTemporary = message.isTemporary;
    }
    if (message.isActive !== undefined && message.isActive !== false) {
      obj.isActive = message.isActive;
    }
    if (message.expirationTime !== undefined && message.expirationTime !== 0) {
      obj.expirationTime = Math.round(message.expirationTime);
    }
    if (message.castUserId !== undefined && message.castUserId !== "") {
      obj.castUserId = message.castUserId;
    }
    if (message.scope !== undefined && message.scope !== 0) {
      obj.scope = Math.round(message.scope);
    }
    if (message.enforceIpWhitelist !== undefined && message.enforceIpWhitelist !== false) {
      obj.enforceIpWhitelist = message.enforceIpWhitelist;
    }
    if (message.ipWhitelist?.length) {
      obj.ipWhitelist = message.ipWhitelist;
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      obj.profileId = message.profileId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.id = object.id ?? "";
    message.userName = object.userName ?? "";
    message.obsoleteFirstName = object.obsoleteFirstName ?? "";
    message.obsoleteLastName = object.obsoleteLastName ?? "";
    message.obsoleteEmail = object.obsoleteEmail ?? "";
    message.customerId = object.customerId ?? "";
    message.subscriberType = object.subscriberType ?? 0;
    message.removalDate = object.removalDate ?? 0;
    message.systemId = object.systemId ?? "";
    message.traderId = object.traderId ?? "";
    message.enforcedConstraintGroup = object.enforcedConstraintGroup?.map((e) => e) || [];
    message.currency = object.currency ?? "";
    message.domain = object.domain ?? 0;
    message.removed = object.removed ?? false;
    message.class = object.class ?? 0;
    message.isTemporary = object.isTemporary ?? false;
    message.isActive = object.isActive ?? false;
    message.expirationTime = object.expirationTime ?? 0;
    message.castUserId = object.castUserId ?? "";
    message.scope = object.scope ?? 0;
    message.enforceIpWhitelist = object.enforceIpWhitelist ?? false;
    message.ipWhitelist = object.ipWhitelist?.map((e) => e) || [];
    message.profileId = object.profileId ?? "";
    return message;
  },
};

function createBaseLoginSettings(): LoginSettings {
  return {
    clearedFields: [],
    loginId: "",
    preferredConnectionPointId: "",
    concurrentSessionsNumber: 0,
    passwordNeverExpires: false,
    obsoleteIsStrategyRunner: false,
    preferredConnectionPointName: "",
    linkedLoginId: "",
    externalAuth: undefined,
    dailyLogonLimit: 0,
    dailyLogonCount: 0,
    authenticationSystem: "",
    allowExceedAccountLimit: false,
    onSessionDisconnectAction: 0,
    reconnectTimeoutAfterDisconnect: 0,
    timeZone: "",
  };
}

export const LoginSettings = {
  encode(message: LoginSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(58).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.loginId !== undefined && message.loginId !== "") {
      writer.uint32(10).string(message.loginId);
    }
    if (message.preferredConnectionPointId !== undefined && message.preferredConnectionPointId !== "") {
      writer.uint32(18).string(message.preferredConnectionPointId);
    }
    if (message.concurrentSessionsNumber !== undefined && message.concurrentSessionsNumber !== 0) {
      writer.uint32(24).uint32(message.concurrentSessionsNumber);
    }
    if (message.passwordNeverExpires !== undefined && message.passwordNeverExpires !== false) {
      writer.uint32(32).bool(message.passwordNeverExpires);
    }
    if (message.obsoleteIsStrategyRunner !== undefined && message.obsoleteIsStrategyRunner !== false) {
      writer.uint32(40).bool(message.obsoleteIsStrategyRunner);
    }
    if (message.preferredConnectionPointName !== undefined && message.preferredConnectionPointName !== "") {
      writer.uint32(50).string(message.preferredConnectionPointName);
    }
    if (message.linkedLoginId !== undefined && message.linkedLoginId !== "") {
      writer.uint32(66).string(message.linkedLoginId);
    }
    if (message.externalAuth !== undefined) {
      ExternalAuth.encode(message.externalAuth, writer.uint32(74).fork()).ldelim();
    }
    if (message.dailyLogonLimit !== undefined && message.dailyLogonLimit !== 0) {
      writer.uint32(80).uint32(message.dailyLogonLimit);
    }
    if (message.dailyLogonCount !== undefined && message.dailyLogonCount !== 0) {
      writer.uint32(88).uint32(message.dailyLogonCount);
    }
    if (message.authenticationSystem !== undefined && message.authenticationSystem !== "") {
      writer.uint32(98).string(message.authenticationSystem);
    }
    if (message.allowExceedAccountLimit !== undefined && message.allowExceedAccountLimit !== false) {
      writer.uint32(104).bool(message.allowExceedAccountLimit);
    }
    if (message.onSessionDisconnectAction !== undefined && message.onSessionDisconnectAction !== 0) {
      writer.uint32(112).uint32(message.onSessionDisconnectAction);
    }
    if (message.reconnectTimeoutAfterDisconnect !== undefined && message.reconnectTimeoutAfterDisconnect !== 0) {
      writer.uint32(120).uint32(message.reconnectTimeoutAfterDisconnect);
    }
    if (message.timeZone !== undefined && message.timeZone !== "") {
      writer.uint32(130).string(message.timeZone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 7:
          if (tag === 56) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.preferredConnectionPointId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.concurrentSessionsNumber = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.passwordNeverExpires = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.obsoleteIsStrategyRunner = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.preferredConnectionPointName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.linkedLoginId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.externalAuth = ExternalAuth.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.dailyLogonLimit = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.dailyLogonCount = reader.uint32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.authenticationSystem = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.allowExceedAccountLimit = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.onSessionDisconnectAction = reader.uint32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.reconnectTimeoutAfterDisconnect = reader.uint32();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.timeZone = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoginSettings {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      loginId: isSet(object.loginId) ? globalThis.String(object.loginId) : "",
      preferredConnectionPointId: isSet(object.preferredConnectionPointId)
        ? globalThis.String(object.preferredConnectionPointId)
        : "",
      concurrentSessionsNumber: isSet(object.concurrentSessionsNumber)
        ? globalThis.Number(object.concurrentSessionsNumber)
        : 0,
      passwordNeverExpires: isSet(object.passwordNeverExpires)
        ? globalThis.Boolean(object.passwordNeverExpires)
        : false,
      obsoleteIsStrategyRunner: isSet(object.obsoleteIsStrategyRunner)
        ? globalThis.Boolean(object.obsoleteIsStrategyRunner)
        : false,
      preferredConnectionPointName: isSet(object.preferredConnectionPointName)
        ? globalThis.String(object.preferredConnectionPointName)
        : "",
      linkedLoginId: isSet(object.linkedLoginId) ? globalThis.String(object.linkedLoginId) : "",
      externalAuth: isSet(object.externalAuth) ? ExternalAuth.fromJSON(object.externalAuth) : undefined,
      dailyLogonLimit: isSet(object.dailyLogonLimit) ? globalThis.Number(object.dailyLogonLimit) : 0,
      dailyLogonCount: isSet(object.dailyLogonCount) ? globalThis.Number(object.dailyLogonCount) : 0,
      authenticationSystem: isSet(object.authenticationSystem) ? globalThis.String(object.authenticationSystem) : "",
      allowExceedAccountLimit: isSet(object.allowExceedAccountLimit)
        ? globalThis.Boolean(object.allowExceedAccountLimit)
        : false,
      onSessionDisconnectAction: isSet(object.onSessionDisconnectAction)
        ? globalThis.Number(object.onSessionDisconnectAction)
        : 0,
      reconnectTimeoutAfterDisconnect: isSet(object.reconnectTimeoutAfterDisconnect)
        ? globalThis.Number(object.reconnectTimeoutAfterDisconnect)
        : 0,
      timeZone: isSet(object.timeZone) ? globalThis.String(object.timeZone) : "",
    };
  },

  toJSON(message: LoginSettings): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.loginId !== undefined && message.loginId !== "") {
      obj.loginId = message.loginId;
    }
    if (message.preferredConnectionPointId !== undefined && message.preferredConnectionPointId !== "") {
      obj.preferredConnectionPointId = message.preferredConnectionPointId;
    }
    if (message.concurrentSessionsNumber !== undefined && message.concurrentSessionsNumber !== 0) {
      obj.concurrentSessionsNumber = Math.round(message.concurrentSessionsNumber);
    }
    if (message.passwordNeverExpires !== undefined && message.passwordNeverExpires !== false) {
      obj.passwordNeverExpires = message.passwordNeverExpires;
    }
    if (message.obsoleteIsStrategyRunner !== undefined && message.obsoleteIsStrategyRunner !== false) {
      obj.obsoleteIsStrategyRunner = message.obsoleteIsStrategyRunner;
    }
    if (message.preferredConnectionPointName !== undefined && message.preferredConnectionPointName !== "") {
      obj.preferredConnectionPointName = message.preferredConnectionPointName;
    }
    if (message.linkedLoginId !== undefined && message.linkedLoginId !== "") {
      obj.linkedLoginId = message.linkedLoginId;
    }
    if (message.externalAuth !== undefined) {
      obj.externalAuth = ExternalAuth.toJSON(message.externalAuth);
    }
    if (message.dailyLogonLimit !== undefined && message.dailyLogonLimit !== 0) {
      obj.dailyLogonLimit = Math.round(message.dailyLogonLimit);
    }
    if (message.dailyLogonCount !== undefined && message.dailyLogonCount !== 0) {
      obj.dailyLogonCount = Math.round(message.dailyLogonCount);
    }
    if (message.authenticationSystem !== undefined && message.authenticationSystem !== "") {
      obj.authenticationSystem = message.authenticationSystem;
    }
    if (message.allowExceedAccountLimit !== undefined && message.allowExceedAccountLimit !== false) {
      obj.allowExceedAccountLimit = message.allowExceedAccountLimit;
    }
    if (message.onSessionDisconnectAction !== undefined && message.onSessionDisconnectAction !== 0) {
      obj.onSessionDisconnectAction = Math.round(message.onSessionDisconnectAction);
    }
    if (message.reconnectTimeoutAfterDisconnect !== undefined && message.reconnectTimeoutAfterDisconnect !== 0) {
      obj.reconnectTimeoutAfterDisconnect = Math.round(message.reconnectTimeoutAfterDisconnect);
    }
    if (message.timeZone !== undefined && message.timeZone !== "") {
      obj.timeZone = message.timeZone;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoginSettings>, I>>(base?: I): LoginSettings {
    return LoginSettings.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoginSettings>, I>>(object: I): LoginSettings {
    const message = createBaseLoginSettings();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.loginId = object.loginId ?? "";
    message.preferredConnectionPointId = object.preferredConnectionPointId ?? "";
    message.concurrentSessionsNumber = object.concurrentSessionsNumber ?? 0;
    message.passwordNeverExpires = object.passwordNeverExpires ?? false;
    message.obsoleteIsStrategyRunner = object.obsoleteIsStrategyRunner ?? false;
    message.preferredConnectionPointName = object.preferredConnectionPointName ?? "";
    message.linkedLoginId = object.linkedLoginId ?? "";
    message.externalAuth = (object.externalAuth !== undefined && object.externalAuth !== null)
      ? ExternalAuth.fromPartial(object.externalAuth)
      : undefined;
    message.dailyLogonLimit = object.dailyLogonLimit ?? 0;
    message.dailyLogonCount = object.dailyLogonCount ?? 0;
    message.authenticationSystem = object.authenticationSystem ?? "";
    message.allowExceedAccountLimit = object.allowExceedAccountLimit ?? false;
    message.onSessionDisconnectAction = object.onSessionDisconnectAction ?? 0;
    message.reconnectTimeoutAfterDisconnect = object.reconnectTimeoutAfterDisconnect ?? 0;
    message.timeZone = object.timeZone ?? "";
    return message;
  },
};

function createBaseUserSearchRequest(): UserSearchRequest {
  return { obsoleteText: "", obsoleteSearchCriteria: [], searchOptions: [], allMatchMode: false };
}

export const UserSearchRequest = {
  encode(message: UserSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.obsoleteText !== undefined && message.obsoleteText !== "") {
      writer.uint32(10).string(message.obsoleteText);
    }
    writer.uint32(18).fork();
    for (const v of message.obsoleteSearchCriteria) {
      writer.uint32(v);
    }
    writer.ldelim();
    for (const v of message.searchOptions) {
      SearchOption.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.allMatchMode !== undefined && message.allMatchMode !== false) {
      writer.uint32(32).bool(message.allMatchMode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserSearchRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.obsoleteText = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.obsoleteSearchCriteria.push(reader.uint32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.obsoleteSearchCriteria.push(reader.uint32());
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.searchOptions.push(SearchOption.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.allMatchMode = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserSearchRequest {
    return {
      obsoleteText: isSet(object.obsoleteText) ? globalThis.String(object.obsoleteText) : "",
      obsoleteSearchCriteria: globalThis.Array.isArray(object?.obsoleteSearchCriteria)
        ? object.obsoleteSearchCriteria.map((e: any) => globalThis.Number(e))
        : [],
      searchOptions: globalThis.Array.isArray(object?.searchOptions)
        ? object.searchOptions.map((e: any) => SearchOption.fromJSON(e))
        : [],
      allMatchMode: isSet(object.allMatchMode) ? globalThis.Boolean(object.allMatchMode) : false,
    };
  },

  toJSON(message: UserSearchRequest): unknown {
    const obj: any = {};
    if (message.obsoleteText !== undefined && message.obsoleteText !== "") {
      obj.obsoleteText = message.obsoleteText;
    }
    if (message.obsoleteSearchCriteria?.length) {
      obj.obsoleteSearchCriteria = message.obsoleteSearchCriteria.map((e) => Math.round(e));
    }
    if (message.searchOptions?.length) {
      obj.searchOptions = message.searchOptions.map((e) => SearchOption.toJSON(e));
    }
    if (message.allMatchMode !== undefined && message.allMatchMode !== false) {
      obj.allMatchMode = message.allMatchMode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserSearchRequest>, I>>(base?: I): UserSearchRequest {
    return UserSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserSearchRequest>, I>>(object: I): UserSearchRequest {
    const message = createBaseUserSearchRequest();
    message.obsoleteText = object.obsoleteText ?? "";
    message.obsoleteSearchCriteria = object.obsoleteSearchCriteria?.map((e) => e) || [];
    message.searchOptions = object.searchOptions?.map((e) => SearchOption.fromPartial(e)) || [];
    message.allMatchMode = object.allMatchMode ?? false;
    return message;
  },
};

function createBaseUserSearchResultRecord(): UserSearchResultRecord {
  return {
    userId: "",
    userName: "",
    firstName: "",
    lastName: "",
    customerId: "",
    customerName: "",
    customerBrokerageName: "",
    removed: false,
    class: 0,
    isTemporary: false,
    profileId: "",
    externalAuth: undefined,
  };
}

export const UserSearchResultRecord = {
  encode(message: UserSearchResultRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.userName !== "") {
      writer.uint32(18).string(message.userName);
    }
    if (message.firstName !== undefined && message.firstName !== "") {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== undefined && message.lastName !== "") {
      writer.uint32(34).string(message.lastName);
    }
    if (message.customerId !== undefined && message.customerId !== "") {
      writer.uint32(42).string(message.customerId);
    }
    if (message.customerName !== undefined && message.customerName !== "") {
      writer.uint32(50).string(message.customerName);
    }
    if (message.customerBrokerageName !== undefined && message.customerBrokerageName !== "") {
      writer.uint32(58).string(message.customerBrokerageName);
    }
    if (message.removed !== undefined && message.removed !== false) {
      writer.uint32(64).bool(message.removed);
    }
    if (message.class !== undefined && message.class !== 0) {
      writer.uint32(72).uint32(message.class);
    }
    if (message.isTemporary !== undefined && message.isTemporary !== false) {
      writer.uint32(80).bool(message.isTemporary);
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      writer.uint32(90).string(message.profileId);
    }
    if (message.externalAuth !== undefined) {
      ExternalAuth.encode(message.externalAuth, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserSearchResultRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserSearchResultRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.customerName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.customerBrokerageName = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.removed = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.class = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isTemporary = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.profileId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.externalAuth = ExternalAuth.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserSearchResultRecord {
    return {
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      userName: isSet(object.userName) ? globalThis.String(object.userName) : "",
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "",
      customerName: isSet(object.customerName) ? globalThis.String(object.customerName) : "",
      customerBrokerageName: isSet(object.customerBrokerageName) ? globalThis.String(object.customerBrokerageName) : "",
      removed: isSet(object.removed) ? globalThis.Boolean(object.removed) : false,
      class: isSet(object.class) ? globalThis.Number(object.class) : 0,
      isTemporary: isSet(object.isTemporary) ? globalThis.Boolean(object.isTemporary) : false,
      profileId: isSet(object.profileId) ? globalThis.String(object.profileId) : "",
      externalAuth: isSet(object.externalAuth) ? ExternalAuth.fromJSON(object.externalAuth) : undefined,
    };
  },

  toJSON(message: UserSearchResultRecord): unknown {
    const obj: any = {};
    if (message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.userName !== "") {
      obj.userName = message.userName;
    }
    if (message.firstName !== undefined && message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== undefined && message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.customerId !== undefined && message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    if (message.customerName !== undefined && message.customerName !== "") {
      obj.customerName = message.customerName;
    }
    if (message.customerBrokerageName !== undefined && message.customerBrokerageName !== "") {
      obj.customerBrokerageName = message.customerBrokerageName;
    }
    if (message.removed !== undefined && message.removed !== false) {
      obj.removed = message.removed;
    }
    if (message.class !== undefined && message.class !== 0) {
      obj.class = Math.round(message.class);
    }
    if (message.isTemporary !== undefined && message.isTemporary !== false) {
      obj.isTemporary = message.isTemporary;
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      obj.profileId = message.profileId;
    }
    if (message.externalAuth !== undefined) {
      obj.externalAuth = ExternalAuth.toJSON(message.externalAuth);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserSearchResultRecord>, I>>(base?: I): UserSearchResultRecord {
    return UserSearchResultRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserSearchResultRecord>, I>>(object: I): UserSearchResultRecord {
    const message = createBaseUserSearchResultRecord();
    message.userId = object.userId ?? "";
    message.userName = object.userName ?? "";
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.customerId = object.customerId ?? "";
    message.customerName = object.customerName ?? "";
    message.customerBrokerageName = object.customerBrokerageName ?? "";
    message.removed = object.removed ?? false;
    message.class = object.class ?? 0;
    message.isTemporary = object.isTemporary ?? false;
    message.profileId = object.profileId ?? "";
    message.externalAuth = (object.externalAuth !== undefined && object.externalAuth !== null)
      ? ExternalAuth.fromPartial(object.externalAuth)
      : undefined;
    return message;
  },
};

function createBaseCloneUser(): CloneUser {
  return {
    sourceUserId: "",
    newUserUsername: "",
    obsoleteNewUserFirstName: "",
    obsoleteNewUserLastName: "",
    obsoleteNewUserAddress: undefined,
    newUserContactInformation: undefined,
    obsoleteNewUserMiddleInitial: "",
    targetProfileId: "",
    noWelcomeEmail: false,
    newProfileBrokerageId: "",
  };
}

export const CloneUser = {
  encode(message: CloneUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sourceUserId !== undefined && message.sourceUserId !== "") {
      writer.uint32(10).string(message.sourceUserId);
    }
    if (message.newUserUsername !== undefined && message.newUserUsername !== "") {
      writer.uint32(18).string(message.newUserUsername);
    }
    if (message.obsoleteNewUserFirstName !== undefined && message.obsoleteNewUserFirstName !== "") {
      writer.uint32(26).string(message.obsoleteNewUserFirstName);
    }
    if (message.obsoleteNewUserLastName !== undefined && message.obsoleteNewUserLastName !== "") {
      writer.uint32(34).string(message.obsoleteNewUserLastName);
    }
    if (message.obsoleteNewUserAddress !== undefined) {
      Address.encode(message.obsoleteNewUserAddress, writer.uint32(42).fork()).ldelim();
    }
    if (message.newUserContactInformation !== undefined) {
      ContactInformation.encode(message.newUserContactInformation, writer.uint32(50).fork()).ldelim();
    }
    if (message.obsoleteNewUserMiddleInitial !== undefined && message.obsoleteNewUserMiddleInitial !== "") {
      writer.uint32(58).string(message.obsoleteNewUserMiddleInitial);
    }
    if (message.targetProfileId !== undefined && message.targetProfileId !== "") {
      writer.uint32(66).string(message.targetProfileId);
    }
    if (message.noWelcomeEmail !== undefined && message.noWelcomeEmail !== false) {
      writer.uint32(72).bool(message.noWelcomeEmail);
    }
    if (message.newProfileBrokerageId !== undefined && message.newProfileBrokerageId !== "") {
      writer.uint32(82).string(message.newProfileBrokerageId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CloneUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCloneUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sourceUserId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newUserUsername = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.obsoleteNewUserFirstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.obsoleteNewUserLastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.obsoleteNewUserAddress = Address.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.newUserContactInformation = ContactInformation.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.obsoleteNewUserMiddleInitial = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.targetProfileId = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.noWelcomeEmail = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.newProfileBrokerageId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CloneUser {
    return {
      sourceUserId: isSet(object.sourceUserId) ? globalThis.String(object.sourceUserId) : "",
      newUserUsername: isSet(object.newUserUsername) ? globalThis.String(object.newUserUsername) : "",
      obsoleteNewUserFirstName: isSet(object.obsoleteNewUserFirstName)
        ? globalThis.String(object.obsoleteNewUserFirstName)
        : "",
      obsoleteNewUserLastName: isSet(object.obsoleteNewUserLastName)
        ? globalThis.String(object.obsoleteNewUserLastName)
        : "",
      obsoleteNewUserAddress: isSet(object.obsoleteNewUserAddress)
        ? Address.fromJSON(object.obsoleteNewUserAddress)
        : undefined,
      newUserContactInformation: isSet(object.newUserContactInformation)
        ? ContactInformation.fromJSON(object.newUserContactInformation)
        : undefined,
      obsoleteNewUserMiddleInitial: isSet(object.obsoleteNewUserMiddleInitial)
        ? globalThis.String(object.obsoleteNewUserMiddleInitial)
        : "",
      targetProfileId: isSet(object.targetProfileId) ? globalThis.String(object.targetProfileId) : "",
      noWelcomeEmail: isSet(object.noWelcomeEmail) ? globalThis.Boolean(object.noWelcomeEmail) : false,
      newProfileBrokerageId: isSet(object.newProfileBrokerageId) ? globalThis.String(object.newProfileBrokerageId) : "",
    };
  },

  toJSON(message: CloneUser): unknown {
    const obj: any = {};
    if (message.sourceUserId !== undefined && message.sourceUserId !== "") {
      obj.sourceUserId = message.sourceUserId;
    }
    if (message.newUserUsername !== undefined && message.newUserUsername !== "") {
      obj.newUserUsername = message.newUserUsername;
    }
    if (message.obsoleteNewUserFirstName !== undefined && message.obsoleteNewUserFirstName !== "") {
      obj.obsoleteNewUserFirstName = message.obsoleteNewUserFirstName;
    }
    if (message.obsoleteNewUserLastName !== undefined && message.obsoleteNewUserLastName !== "") {
      obj.obsoleteNewUserLastName = message.obsoleteNewUserLastName;
    }
    if (message.obsoleteNewUserAddress !== undefined) {
      obj.obsoleteNewUserAddress = Address.toJSON(message.obsoleteNewUserAddress);
    }
    if (message.newUserContactInformation !== undefined) {
      obj.newUserContactInformation = ContactInformation.toJSON(message.newUserContactInformation);
    }
    if (message.obsoleteNewUserMiddleInitial !== undefined && message.obsoleteNewUserMiddleInitial !== "") {
      obj.obsoleteNewUserMiddleInitial = message.obsoleteNewUserMiddleInitial;
    }
    if (message.targetProfileId !== undefined && message.targetProfileId !== "") {
      obj.targetProfileId = message.targetProfileId;
    }
    if (message.noWelcomeEmail !== undefined && message.noWelcomeEmail !== false) {
      obj.noWelcomeEmail = message.noWelcomeEmail;
    }
    if (message.newProfileBrokerageId !== undefined && message.newProfileBrokerageId !== "") {
      obj.newProfileBrokerageId = message.newProfileBrokerageId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CloneUser>, I>>(base?: I): CloneUser {
    return CloneUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CloneUser>, I>>(object: I): CloneUser {
    const message = createBaseCloneUser();
    message.sourceUserId = object.sourceUserId ?? "";
    message.newUserUsername = object.newUserUsername ?? "";
    message.obsoleteNewUserFirstName = object.obsoleteNewUserFirstName ?? "";
    message.obsoleteNewUserLastName = object.obsoleteNewUserLastName ?? "";
    message.obsoleteNewUserAddress =
      (object.obsoleteNewUserAddress !== undefined && object.obsoleteNewUserAddress !== null)
        ? Address.fromPartial(object.obsoleteNewUserAddress)
        : undefined;
    message.newUserContactInformation =
      (object.newUserContactInformation !== undefined && object.newUserContactInformation !== null)
        ? ContactInformation.fromPartial(object.newUserContactInformation)
        : undefined;
    message.obsoleteNewUserMiddleInitial = object.obsoleteNewUserMiddleInitial ?? "";
    message.targetProfileId = object.targetProfileId ?? "";
    message.noWelcomeEmail = object.noWelcomeEmail ?? false;
    message.newProfileBrokerageId = object.newProfileBrokerageId ?? "";
    return message;
  },
};

function createBaseCloneUserResult(): CloneUserResult {
  return { newUserId: "", newProfileId: "" };
}

export const CloneUserResult = {
  encode(message: CloneUserResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.newUserId !== "") {
      writer.uint32(10).string(message.newUserId);
    }
    if (message.newProfileId !== undefined && message.newProfileId !== "") {
      writer.uint32(18).string(message.newProfileId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CloneUserResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCloneUserResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.newUserId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newProfileId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CloneUserResult {
    return {
      newUserId: isSet(object.newUserId) ? globalThis.String(object.newUserId) : "",
      newProfileId: isSet(object.newProfileId) ? globalThis.String(object.newProfileId) : "",
    };
  },

  toJSON(message: CloneUserResult): unknown {
    const obj: any = {};
    if (message.newUserId !== "") {
      obj.newUserId = message.newUserId;
    }
    if (message.newProfileId !== undefined && message.newProfileId !== "") {
      obj.newProfileId = message.newProfileId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CloneUserResult>, I>>(base?: I): CloneUserResult {
    return CloneUserResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CloneUserResult>, I>>(object: I): CloneUserResult {
    const message = createBaseCloneUserResult();
    message.newUserId = object.newUserId ?? "";
    message.newProfileId = object.newProfileId ?? "";
    return message;
  },
};

function createBaseAddress(): Address {
  return {
    clearedFields: [],
    country: "",
    state: "",
    city: "",
    zip: "",
    address: "",
    address2: "",
    name: "",
    addressStatus: 0,
  };
}

export const Address = {
  encode(message: Address, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(74).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.country !== undefined && message.country !== "") {
      writer.uint32(10).string(message.country);
    }
    if (message.state !== undefined && message.state !== "") {
      writer.uint32(18).string(message.state);
    }
    if (message.city !== undefined && message.city !== "") {
      writer.uint32(26).string(message.city);
    }
    if (message.zip !== undefined && message.zip !== "") {
      writer.uint32(34).string(message.zip);
    }
    if (message.address !== undefined && message.address !== "") {
      writer.uint32(42).string(message.address);
    }
    if (message.address2 !== undefined && message.address2 !== "") {
      writer.uint32(50).string(message.address2);
    }
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(58).string(message.name);
    }
    if (message.addressStatus !== undefined && message.addressStatus !== 0) {
      writer.uint32(64).uint32(message.addressStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Address {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 9:
          if (tag === 72) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 74) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.country = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.state = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.city = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.zip = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.address = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.address2 = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.name = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.addressStatus = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Address {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      country: isSet(object.country) ? globalThis.String(object.country) : "",
      state: isSet(object.state) ? globalThis.String(object.state) : "",
      city: isSet(object.city) ? globalThis.String(object.city) : "",
      zip: isSet(object.zip) ? globalThis.String(object.zip) : "",
      address: isSet(object.address) ? globalThis.String(object.address) : "",
      address2: isSet(object.address2) ? globalThis.String(object.address2) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      addressStatus: isSet(object.addressStatus) ? globalThis.Number(object.addressStatus) : 0,
    };
  },

  toJSON(message: Address): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.country !== undefined && message.country !== "") {
      obj.country = message.country;
    }
    if (message.state !== undefined && message.state !== "") {
      obj.state = message.state;
    }
    if (message.city !== undefined && message.city !== "") {
      obj.city = message.city;
    }
    if (message.zip !== undefined && message.zip !== "") {
      obj.zip = message.zip;
    }
    if (message.address !== undefined && message.address !== "") {
      obj.address = message.address;
    }
    if (message.address2 !== undefined && message.address2 !== "") {
      obj.address2 = message.address2;
    }
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.addressStatus !== undefined && message.addressStatus !== 0) {
      obj.addressStatus = Math.round(message.addressStatus);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Address>, I>>(base?: I): Address {
    return Address.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Address>, I>>(object: I): Address {
    const message = createBaseAddress();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.country = object.country ?? "";
    message.state = object.state ?? "";
    message.city = object.city ?? "";
    message.zip = object.zip ?? "";
    message.address = object.address ?? "";
    message.address2 = object.address2 ?? "";
    message.name = object.name ?? "";
    message.addressStatus = object.addressStatus ?? 0;
    return message;
  },
};

function createBaseContactInformation(): ContactInformation {
  return { email: [], phone: [], fax: [], firstName: "", lastName: "", address: [] };
}

export const ContactInformation = {
  encode(message: ContactInformation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.email) {
      Email.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.phone) {
      Phone.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.fax) {
      Phone.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.firstName !== undefined && message.firstName !== "") {
      writer.uint32(34).string(message.firstName);
    }
    if (message.lastName !== undefined && message.lastName !== "") {
      writer.uint32(42).string(message.lastName);
    }
    for (const v of message.address) {
      Address.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContactInformation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContactInformation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email.push(Email.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phone.push(Phone.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fax.push(Phone.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.address.push(Address.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContactInformation {
    return {
      email: globalThis.Array.isArray(object?.email) ? object.email.map((e: any) => Email.fromJSON(e)) : [],
      phone: globalThis.Array.isArray(object?.phone) ? object.phone.map((e: any) => Phone.fromJSON(e)) : [],
      fax: globalThis.Array.isArray(object?.fax) ? object.fax.map((e: any) => Phone.fromJSON(e)) : [],
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      address: globalThis.Array.isArray(object?.address) ? object.address.map((e: any) => Address.fromJSON(e)) : [],
    };
  },

  toJSON(message: ContactInformation): unknown {
    const obj: any = {};
    if (message.email?.length) {
      obj.email = message.email.map((e) => Email.toJSON(e));
    }
    if (message.phone?.length) {
      obj.phone = message.phone.map((e) => Phone.toJSON(e));
    }
    if (message.fax?.length) {
      obj.fax = message.fax.map((e) => Phone.toJSON(e));
    }
    if (message.firstName !== undefined && message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== undefined && message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.address?.length) {
      obj.address = message.address.map((e) => Address.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContactInformation>, I>>(base?: I): ContactInformation {
    return ContactInformation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContactInformation>, I>>(object: I): ContactInformation {
    const message = createBaseContactInformation();
    message.email = object.email?.map((e) => Email.fromPartial(e)) || [];
    message.phone = object.phone?.map((e) => Phone.fromPartial(e)) || [];
    message.fax = object.fax?.map((e) => Phone.fromPartial(e)) || [];
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.address = object.address?.map((e) => Address.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEmail(): Email {
  return { email: "" };
}

export const Email = {
  encode(message: Email, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.email !== "") {
      writer.uint32(10).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Email {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Email {
    return { email: isSet(object.email) ? globalThis.String(object.email) : "" };
  },

  toJSON(message: Email): unknown {
    const obj: any = {};
    if (message.email !== "") {
      obj.email = message.email;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Email>, I>>(base?: I): Email {
    return Email.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Email>, I>>(object: I): Email {
    const message = createBaseEmail();
    message.email = object.email ?? "";
    return message;
  },
};

function createBasePhone(): Phone {
  return { number: "" };
}

export const Phone = {
  encode(message: Phone, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== "") {
      writer.uint32(10).string(message.number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Phone {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePhone();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.number = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Phone {
    return { number: isSet(object.number) ? globalThis.String(object.number) : "" };
  },

  toJSON(message: Phone): unknown {
    const obj: any = {};
    if (message.number !== "") {
      obj.number = message.number;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Phone>, I>>(base?: I): Phone {
    return Phone.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Phone>, I>>(object: I): Phone {
    const message = createBasePhone();
    message.number = object.number ?? "";
    return message;
  },
};

function createBaseCreateUser(): CreateUser {
  return { user: undefined };
}

export const CreateUser = {
  encode(message: CreateUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateUser {
    return { user: isSet(object.user) ? User.fromJSON(object.user) : undefined };
  },

  toJSON(message: CreateUser): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateUser>, I>>(base?: I): CreateUser {
    return CreateUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateUser>, I>>(object: I): CreateUser {
    const message = createBaseCreateUser();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    return message;
  },
};

function createBaseUpdateUser(): UpdateUser {
  return { user: undefined, obsoleteOriginalUser: undefined };
}

export const UpdateUser = {
  encode(message: UpdateUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    if (message.obsoleteOriginalUser !== undefined) {
      User.encode(message.obsoleteOriginalUser, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.obsoleteOriginalUser = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateUser {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      obsoleteOriginalUser: isSet(object.obsoleteOriginalUser) ? User.fromJSON(object.obsoleteOriginalUser) : undefined,
    };
  },

  toJSON(message: UpdateUser): unknown {
    const obj: any = {};
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.obsoleteOriginalUser !== undefined) {
      obj.obsoleteOriginalUser = User.toJSON(message.obsoleteOriginalUser);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateUser>, I>>(base?: I): UpdateUser {
    return UpdateUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUser>, I>>(object: I): UpdateUser {
    const message = createBaseUpdateUser();
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.obsoleteOriginalUser = (object.obsoleteOriginalUser !== undefined && object.obsoleteOriginalUser !== null)
      ? User.fromPartial(object.obsoleteOriginalUser)
      : undefined;
    return message;
  },
};

function createBaseRemoveUser(): RemoveUser {
  return { id: "" };
}

export const RemoveUser = {
  encode(message: RemoveUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveUser {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: RemoveUser): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveUser>, I>>(base?: I): RemoveUser {
    return RemoveUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveUser>, I>>(object: I): RemoveUser {
    const message = createBaseRemoveUser();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseRestoreUser(): RestoreUser {
  return { id: "" };
}

export const RestoreUser = {
  encode(message: RestoreUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RestoreUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRestoreUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RestoreUser {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: RestoreUser): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RestoreUser>, I>>(base?: I): RestoreUser {
    return RestoreUser.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RestoreUser>, I>>(object: I): RestoreUser {
    const message = createBaseRestoreUser();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseCreateUserResult(): CreateUserResult {
  return { id: "" };
}

export const CreateUserResult = {
  encode(message: CreateUserResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUserResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateUserResult {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: CreateUserResult): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateUserResult>, I>>(base?: I): CreateUserResult {
    return CreateUserResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateUserResult>, I>>(object: I): CreateUserResult {
    const message = createBaseCreateUserResult();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseUpdateUserResult(): UpdateUserResult {
  return {};
}

export const UpdateUserResult = {
  encode(_: UpdateUserResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateUserResult {
    return {};
  },

  toJSON(_: UpdateUserResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateUserResult>, I>>(base?: I): UpdateUserResult {
    return UpdateUserResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUserResult>, I>>(_: I): UpdateUserResult {
    const message = createBaseUpdateUserResult();
    return message;
  },
};

function createBaseRestoreUserResult(): RestoreUserResult {
  return {};
}

export const RestoreUserResult = {
  encode(_: RestoreUserResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RestoreUserResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRestoreUserResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RestoreUserResult {
    return {};
  },

  toJSON(_: RestoreUserResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RestoreUserResult>, I>>(base?: I): RestoreUserResult {
    return RestoreUserResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RestoreUserResult>, I>>(_: I): RestoreUserResult {
    const message = createBaseRestoreUserResult();
    return message;
  },
};

function createBaseRemoveUserResult(): RemoveUserResult {
  return {};
}

export const RemoveUserResult = {
  encode(_: RemoveUserResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveUserResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveUserResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RemoveUserResult {
    return {};
  },

  toJSON(_: RemoveUserResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveUserResult>, I>>(base?: I): RemoveUserResult {
    return RemoveUserResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveUserResult>, I>>(_: I): RemoveUserResult {
    const message = createBaseRemoveUserResult();
    return message;
  },
};

function createBaseLookupProperty(): LookupProperty {
  return { type: 0, value: "", name: undefined, description: undefined };
}

export const LookupProperty = {
  encode(message: LookupProperty, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).uint32(message.type);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    if (message.name !== undefined) {
      Text.encode(message.name, writer.uint32(26).fork()).ldelim();
    }
    if (message.description !== undefined) {
      Text.encode(message.description, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LookupProperty {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLookupProperty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = Text.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = Text.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LookupProperty {
    return {
      type: isSet(object.type) ? globalThis.Number(object.type) : 0,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      name: isSet(object.name) ? Text.fromJSON(object.name) : undefined,
      description: isSet(object.description) ? Text.fromJSON(object.description) : undefined,
    };
  },

  toJSON(message: LookupProperty): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = Math.round(message.type);
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.name !== undefined) {
      obj.name = Text.toJSON(message.name);
    }
    if (message.description !== undefined) {
      obj.description = Text.toJSON(message.description);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LookupProperty>, I>>(base?: I): LookupProperty {
    return LookupProperty.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LookupProperty>, I>>(object: I): LookupProperty {
    const message = createBaseLookupProperty();
    message.type = object.type ?? 0;
    message.value = object.value ?? "";
    message.name = (object.name !== undefined && object.name !== null) ? Text.fromPartial(object.name) : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? Text.fromPartial(object.description)
      : undefined;
    return message;
  },
};

function createBaseLookupPropertyListRequest(): LookupPropertyListRequest {
  return { propertyType: [] };
}

export const LookupPropertyListRequest = {
  encode(message: LookupPropertyListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.propertyType) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LookupPropertyListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLookupPropertyListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.propertyType.push(reader.uint32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.propertyType.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LookupPropertyListRequest {
    return {
      propertyType: globalThis.Array.isArray(object?.propertyType)
        ? object.propertyType.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: LookupPropertyListRequest): unknown {
    const obj: any = {};
    if (message.propertyType?.length) {
      obj.propertyType = message.propertyType.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LookupPropertyListRequest>, I>>(base?: I): LookupPropertyListRequest {
    return LookupPropertyListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LookupPropertyListRequest>, I>>(object: I): LookupPropertyListRequest {
    const message = createBaseLookupPropertyListRequest();
    message.propertyType = object.propertyType?.map((e) => e) || [];
    return message;
  },
};

function createBaseLookupPropertyListResult(): LookupPropertyListResult {
  return { lookupProperty: [] };
}

export const LookupPropertyListResult = {
  encode(message: LookupPropertyListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.lookupProperty) {
      LookupProperty.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LookupPropertyListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLookupPropertyListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lookupProperty.push(LookupProperty.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LookupPropertyListResult {
    return {
      lookupProperty: globalThis.Array.isArray(object?.lookupProperty)
        ? object.lookupProperty.map((e: any) => LookupProperty.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LookupPropertyListResult): unknown {
    const obj: any = {};
    if (message.lookupProperty?.length) {
      obj.lookupProperty = message.lookupProperty.map((e) => LookupProperty.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LookupPropertyListResult>, I>>(base?: I): LookupPropertyListResult {
    return LookupPropertyListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LookupPropertyListResult>, I>>(object: I): LookupPropertyListResult {
    const message = createBaseLookupPropertyListResult();
    message.lookupProperty = object.lookupProperty?.map((e) => LookupProperty.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSalesSeriesSearchRequest(): SalesSeriesSearchRequest {
  return { obsoleteText: "", obsoleteSearchCriteria: [], searchOptions: [], allMatchMode: false };
}

export const SalesSeriesSearchRequest = {
  encode(message: SalesSeriesSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.obsoleteText !== undefined && message.obsoleteText !== "") {
      writer.uint32(10).string(message.obsoleteText);
    }
    writer.uint32(18).fork();
    for (const v of message.obsoleteSearchCriteria) {
      writer.uint32(v);
    }
    writer.ldelim();
    for (const v of message.searchOptions) {
      SearchOption.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.allMatchMode !== undefined && message.allMatchMode !== false) {
      writer.uint32(32).bool(message.allMatchMode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SalesSeriesSearchRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSalesSeriesSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.obsoleteText = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.obsoleteSearchCriteria.push(reader.uint32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.obsoleteSearchCriteria.push(reader.uint32());
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.searchOptions.push(SearchOption.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.allMatchMode = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SalesSeriesSearchRequest {
    return {
      obsoleteText: isSet(object.obsoleteText) ? globalThis.String(object.obsoleteText) : "",
      obsoleteSearchCriteria: globalThis.Array.isArray(object?.obsoleteSearchCriteria)
        ? object.obsoleteSearchCriteria.map((e: any) => globalThis.Number(e))
        : [],
      searchOptions: globalThis.Array.isArray(object?.searchOptions)
        ? object.searchOptions.map((e: any) => SearchOption.fromJSON(e))
        : [],
      allMatchMode: isSet(object.allMatchMode) ? globalThis.Boolean(object.allMatchMode) : false,
    };
  },

  toJSON(message: SalesSeriesSearchRequest): unknown {
    const obj: any = {};
    if (message.obsoleteText !== undefined && message.obsoleteText !== "") {
      obj.obsoleteText = message.obsoleteText;
    }
    if (message.obsoleteSearchCriteria?.length) {
      obj.obsoleteSearchCriteria = message.obsoleteSearchCriteria.map((e) => Math.round(e));
    }
    if (message.searchOptions?.length) {
      obj.searchOptions = message.searchOptions.map((e) => SearchOption.toJSON(e));
    }
    if (message.allMatchMode !== undefined && message.allMatchMode !== false) {
      obj.allMatchMode = message.allMatchMode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SalesSeriesSearchRequest>, I>>(base?: I): SalesSeriesSearchRequest {
    return SalesSeriesSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SalesSeriesSearchRequest>, I>>(object: I): SalesSeriesSearchRequest {
    const message = createBaseSalesSeriesSearchRequest();
    message.obsoleteText = object.obsoleteText ?? "";
    message.obsoleteSearchCriteria = object.obsoleteSearchCriteria?.map((e) => e) || [];
    message.searchOptions = object.searchOptions?.map((e) => SearchOption.fromPartial(e)) || [];
    message.allMatchMode = object.allMatchMode ?? false;
    return message;
  },
};

function createBaseSalesSeriesSearchResultRecord(): SalesSeriesSearchResultRecord {
  return {
    salesSeriesId: "",
    salesSeriesName: "",
    salesSeriesNumber: "",
    brokerageId: "",
    brokerageName: "",
    removed: false,
  };
}

export const SalesSeriesSearchResultRecord = {
  encode(message: SalesSeriesSearchResultRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      writer.uint32(10).string(message.salesSeriesId);
    }
    if (message.salesSeriesName !== undefined && message.salesSeriesName !== "") {
      writer.uint32(18).string(message.salesSeriesName);
    }
    if (message.salesSeriesNumber !== undefined && message.salesSeriesNumber !== "") {
      writer.uint32(26).string(message.salesSeriesNumber);
    }
    if (message.brokerageId !== undefined && message.brokerageId !== "") {
      writer.uint32(34).string(message.brokerageId);
    }
    if (message.brokerageName !== undefined && message.brokerageName !== "") {
      writer.uint32(42).string(message.brokerageName);
    }
    if (message.removed !== undefined && message.removed !== false) {
      writer.uint32(48).bool(message.removed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SalesSeriesSearchResultRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSalesSeriesSearchResultRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.salesSeriesId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.salesSeriesName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.salesSeriesNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.brokerageId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.brokerageName = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.removed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SalesSeriesSearchResultRecord {
    return {
      salesSeriesId: isSet(object.salesSeriesId) ? globalThis.String(object.salesSeriesId) : "",
      salesSeriesName: isSet(object.salesSeriesName) ? globalThis.String(object.salesSeriesName) : "",
      salesSeriesNumber: isSet(object.salesSeriesNumber) ? globalThis.String(object.salesSeriesNumber) : "",
      brokerageId: isSet(object.brokerageId) ? globalThis.String(object.brokerageId) : "",
      brokerageName: isSet(object.brokerageName) ? globalThis.String(object.brokerageName) : "",
      removed: isSet(object.removed) ? globalThis.Boolean(object.removed) : false,
    };
  },

  toJSON(message: SalesSeriesSearchResultRecord): unknown {
    const obj: any = {};
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      obj.salesSeriesId = message.salesSeriesId;
    }
    if (message.salesSeriesName !== undefined && message.salesSeriesName !== "") {
      obj.salesSeriesName = message.salesSeriesName;
    }
    if (message.salesSeriesNumber !== undefined && message.salesSeriesNumber !== "") {
      obj.salesSeriesNumber = message.salesSeriesNumber;
    }
    if (message.brokerageId !== undefined && message.brokerageId !== "") {
      obj.brokerageId = message.brokerageId;
    }
    if (message.brokerageName !== undefined && message.brokerageName !== "") {
      obj.brokerageName = message.brokerageName;
    }
    if (message.removed !== undefined && message.removed !== false) {
      obj.removed = message.removed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SalesSeriesSearchResultRecord>, I>>(base?: I): SalesSeriesSearchResultRecord {
    return SalesSeriesSearchResultRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SalesSeriesSearchResultRecord>, I>>(
    object: I,
  ): SalesSeriesSearchResultRecord {
    const message = createBaseSalesSeriesSearchResultRecord();
    message.salesSeriesId = object.salesSeriesId ?? "";
    message.salesSeriesName = object.salesSeriesName ?? "";
    message.salesSeriesNumber = object.salesSeriesNumber ?? "";
    message.brokerageId = object.brokerageId ?? "";
    message.brokerageName = object.brokerageName ?? "";
    message.removed = object.removed ?? false;
    return message;
  },
};

function createBaseTuple(): Tuple {
  return { first: "", second: "", third: "" };
}

export const Tuple = {
  encode(message: Tuple, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.first !== undefined && message.first !== "") {
      writer.uint32(10).string(message.first);
    }
    if (message.second !== undefined && message.second !== "") {
      writer.uint32(18).string(message.second);
    }
    if (message.third !== undefined && message.third !== "") {
      writer.uint32(26).string(message.third);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tuple {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTuple();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.first = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.second = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.third = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Tuple {
    return {
      first: isSet(object.first) ? globalThis.String(object.first) : "",
      second: isSet(object.second) ? globalThis.String(object.second) : "",
      third: isSet(object.third) ? globalThis.String(object.third) : "",
    };
  },

  toJSON(message: Tuple): unknown {
    const obj: any = {};
    if (message.first !== undefined && message.first !== "") {
      obj.first = message.first;
    }
    if (message.second !== undefined && message.second !== "") {
      obj.second = message.second;
    }
    if (message.third !== undefined && message.third !== "") {
      obj.third = message.third;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Tuple>, I>>(base?: I): Tuple {
    return Tuple.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Tuple>, I>>(object: I): Tuple {
    const message = createBaseTuple();
    message.first = object.first ?? "";
    message.second = object.second ?? "";
    message.third = object.third ?? "";
    return message;
  },
};

function createBaseCustomerSalesSeriesAuthorizationListRequest(): CustomerSalesSeriesAuthorizationListRequest {
  return { customerId: "", salesSeriesId: "", top: 0, profileId: "", profileSalesSeriesId: "" };
}

export const CustomerSalesSeriesAuthorizationListRequest = {
  encode(message: CustomerSalesSeriesAuthorizationListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== undefined && message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      writer.uint32(18).string(message.salesSeriesId);
    }
    if (message.top !== undefined && message.top !== 0) {
      writer.uint32(24).uint32(message.top);
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      writer.uint32(34).string(message.profileId);
    }
    if (message.profileSalesSeriesId !== undefined && message.profileSalesSeriesId !== "") {
      writer.uint32(42).string(message.profileSalesSeriesId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomerSalesSeriesAuthorizationListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomerSalesSeriesAuthorizationListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.salesSeriesId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.top = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.profileId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.profileSalesSeriesId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomerSalesSeriesAuthorizationListRequest {
    return {
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "",
      salesSeriesId: isSet(object.salesSeriesId) ? globalThis.String(object.salesSeriesId) : "",
      top: isSet(object.top) ? globalThis.Number(object.top) : 0,
      profileId: isSet(object.profileId) ? globalThis.String(object.profileId) : "",
      profileSalesSeriesId: isSet(object.profileSalesSeriesId) ? globalThis.String(object.profileSalesSeriesId) : "",
    };
  },

  toJSON(message: CustomerSalesSeriesAuthorizationListRequest): unknown {
    const obj: any = {};
    if (message.customerId !== undefined && message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      obj.salesSeriesId = message.salesSeriesId;
    }
    if (message.top !== undefined && message.top !== 0) {
      obj.top = Math.round(message.top);
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      obj.profileId = message.profileId;
    }
    if (message.profileSalesSeriesId !== undefined && message.profileSalesSeriesId !== "") {
      obj.profileSalesSeriesId = message.profileSalesSeriesId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomerSalesSeriesAuthorizationListRequest>, I>>(
    base?: I,
  ): CustomerSalesSeriesAuthorizationListRequest {
    return CustomerSalesSeriesAuthorizationListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomerSalesSeriesAuthorizationListRequest>, I>>(
    object: I,
  ): CustomerSalesSeriesAuthorizationListRequest {
    const message = createBaseCustomerSalesSeriesAuthorizationListRequest();
    message.customerId = object.customerId ?? "";
    message.salesSeriesId = object.salesSeriesId ?? "";
    message.top = object.top ?? 0;
    message.profileId = object.profileId ?? "";
    message.profileSalesSeriesId = object.profileSalesSeriesId ?? "";
    return message;
  },
};

function createBaseUpdateCustomerSalesSeriesAuthorizationList(): UpdateCustomerSalesSeriesAuthorizationList {
  return { linksToSet: [], linksToRemove: [] };
}

export const UpdateCustomerSalesSeriesAuthorizationList = {
  encode(message: UpdateCustomerSalesSeriesAuthorizationList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.linksToSet) {
      CustomerSalesSeriesLink.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.linksToRemove) {
      Tuple.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCustomerSalesSeriesAuthorizationList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCustomerSalesSeriesAuthorizationList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linksToSet.push(CustomerSalesSeriesLink.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.linksToRemove.push(Tuple.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCustomerSalesSeriesAuthorizationList {
    return {
      linksToSet: globalThis.Array.isArray(object?.linksToSet)
        ? object.linksToSet.map((e: any) => CustomerSalesSeriesLink.fromJSON(e))
        : [],
      linksToRemove: globalThis.Array.isArray(object?.linksToRemove)
        ? object.linksToRemove.map((e: any) => Tuple.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateCustomerSalesSeriesAuthorizationList): unknown {
    const obj: any = {};
    if (message.linksToSet?.length) {
      obj.linksToSet = message.linksToSet.map((e) => CustomerSalesSeriesLink.toJSON(e));
    }
    if (message.linksToRemove?.length) {
      obj.linksToRemove = message.linksToRemove.map((e) => Tuple.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCustomerSalesSeriesAuthorizationList>, I>>(
    base?: I,
  ): UpdateCustomerSalesSeriesAuthorizationList {
    return UpdateCustomerSalesSeriesAuthorizationList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCustomerSalesSeriesAuthorizationList>, I>>(
    object: I,
  ): UpdateCustomerSalesSeriesAuthorizationList {
    const message = createBaseUpdateCustomerSalesSeriesAuthorizationList();
    message.linksToSet = object.linksToSet?.map((e) => CustomerSalesSeriesLink.fromPartial(e)) || [];
    message.linksToRemove = object.linksToRemove?.map((e) => Tuple.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCustomerSalesSeriesAuthorizationListResult(): CustomerSalesSeriesAuthorizationListResult {
  return { customerSalesSeriesLinks: [], isComplete: true };
}

export const CustomerSalesSeriesAuthorizationListResult = {
  encode(message: CustomerSalesSeriesAuthorizationListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.customerSalesSeriesLinks) {
      CustomerSalesSeriesLink.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      writer.uint32(16).bool(message.isComplete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomerSalesSeriesAuthorizationListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomerSalesSeriesAuthorizationListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerSalesSeriesLinks.push(CustomerSalesSeriesLink.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isComplete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomerSalesSeriesAuthorizationListResult {
    return {
      customerSalesSeriesLinks: globalThis.Array.isArray(object?.customerSalesSeriesLinks)
        ? object.customerSalesSeriesLinks.map((e: any) => CustomerSalesSeriesLink.fromJSON(e))
        : [],
      isComplete: isSet(object.isComplete) ? globalThis.Boolean(object.isComplete) : true,
    };
  },

  toJSON(message: CustomerSalesSeriesAuthorizationListResult): unknown {
    const obj: any = {};
    if (message.customerSalesSeriesLinks?.length) {
      obj.customerSalesSeriesLinks = message.customerSalesSeriesLinks.map((e) => CustomerSalesSeriesLink.toJSON(e));
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      obj.isComplete = message.isComplete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomerSalesSeriesAuthorizationListResult>, I>>(
    base?: I,
  ): CustomerSalesSeriesAuthorizationListResult {
    return CustomerSalesSeriesAuthorizationListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomerSalesSeriesAuthorizationListResult>, I>>(
    object: I,
  ): CustomerSalesSeriesAuthorizationListResult {
    const message = createBaseCustomerSalesSeriesAuthorizationListResult();
    message.customerSalesSeriesLinks =
      object.customerSalesSeriesLinks?.map((e) => CustomerSalesSeriesLink.fromPartial(e)) || [];
    message.isComplete = object.isComplete ?? true;
    return message;
  },
};

function createBaseUpdateCustomerSalesSeriesAuthorizationListResult(): UpdateCustomerSalesSeriesAuthorizationListResult {
  return {};
}

export const UpdateCustomerSalesSeriesAuthorizationListResult = {
  encode(_: UpdateCustomerSalesSeriesAuthorizationListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCustomerSalesSeriesAuthorizationListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCustomerSalesSeriesAuthorizationListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateCustomerSalesSeriesAuthorizationListResult {
    return {};
  },

  toJSON(_: UpdateCustomerSalesSeriesAuthorizationListResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCustomerSalesSeriesAuthorizationListResult>, I>>(
    base?: I,
  ): UpdateCustomerSalesSeriesAuthorizationListResult {
    return UpdateCustomerSalesSeriesAuthorizationListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCustomerSalesSeriesAuthorizationListResult>, I>>(
    _: I,
  ): UpdateCustomerSalesSeriesAuthorizationListResult {
    const message = createBaseUpdateCustomerSalesSeriesAuthorizationListResult();
    return message;
  },
};

function createBaseCustomerSalesSeriesLink(): CustomerSalesSeriesLink {
  return {
    customerId: "",
    salesSeriesId: "",
    salesSeriesName: "",
    salesSeriesNumber: "",
    profileId: "",
    profileSalesSeriesId: "",
  };
}

export const CustomerSalesSeriesLink = {
  encode(message: CustomerSalesSeriesLink, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== undefined && message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      writer.uint32(18).string(message.salesSeriesId);
    }
    if (message.salesSeriesName !== undefined && message.salesSeriesName !== "") {
      writer.uint32(26).string(message.salesSeriesName);
    }
    if (message.salesSeriesNumber !== undefined && message.salesSeriesNumber !== "") {
      writer.uint32(34).string(message.salesSeriesNumber);
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      writer.uint32(42).string(message.profileId);
    }
    if (message.profileSalesSeriesId !== undefined && message.profileSalesSeriesId !== "") {
      writer.uint32(50).string(message.profileSalesSeriesId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomerSalesSeriesLink {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomerSalesSeriesLink();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.salesSeriesId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.salesSeriesName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.salesSeriesNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.profileId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.profileSalesSeriesId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomerSalesSeriesLink {
    return {
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "",
      salesSeriesId: isSet(object.salesSeriesId) ? globalThis.String(object.salesSeriesId) : "",
      salesSeriesName: isSet(object.salesSeriesName) ? globalThis.String(object.salesSeriesName) : "",
      salesSeriesNumber: isSet(object.salesSeriesNumber) ? globalThis.String(object.salesSeriesNumber) : "",
      profileId: isSet(object.profileId) ? globalThis.String(object.profileId) : "",
      profileSalesSeriesId: isSet(object.profileSalesSeriesId) ? globalThis.String(object.profileSalesSeriesId) : "",
    };
  },

  toJSON(message: CustomerSalesSeriesLink): unknown {
    const obj: any = {};
    if (message.customerId !== undefined && message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      obj.salesSeriesId = message.salesSeriesId;
    }
    if (message.salesSeriesName !== undefined && message.salesSeriesName !== "") {
      obj.salesSeriesName = message.salesSeriesName;
    }
    if (message.salesSeriesNumber !== undefined && message.salesSeriesNumber !== "") {
      obj.salesSeriesNumber = message.salesSeriesNumber;
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      obj.profileId = message.profileId;
    }
    if (message.profileSalesSeriesId !== undefined && message.profileSalesSeriesId !== "") {
      obj.profileSalesSeriesId = message.profileSalesSeriesId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomerSalesSeriesLink>, I>>(base?: I): CustomerSalesSeriesLink {
    return CustomerSalesSeriesLink.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomerSalesSeriesLink>, I>>(object: I): CustomerSalesSeriesLink {
    const message = createBaseCustomerSalesSeriesLink();
    message.customerId = object.customerId ?? "";
    message.salesSeriesId = object.salesSeriesId ?? "";
    message.salesSeriesName = object.salesSeriesName ?? "";
    message.salesSeriesNumber = object.salesSeriesNumber ?? "";
    message.profileId = object.profileId ?? "";
    message.profileSalesSeriesId = object.profileSalesSeriesId ?? "";
    return message;
  },
};

function createBaseLoginSettingsRequest(): LoginSettingsRequest {
  return { loginId: "" };
}

export const LoginSettingsRequest = {
  encode(message: LoginSettingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.loginId !== undefined && message.loginId !== "") {
      writer.uint32(10).string(message.loginId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginSettingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginSettingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoginSettingsRequest {
    return { loginId: isSet(object.loginId) ? globalThis.String(object.loginId) : "" };
  },

  toJSON(message: LoginSettingsRequest): unknown {
    const obj: any = {};
    if (message.loginId !== undefined && message.loginId !== "") {
      obj.loginId = message.loginId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoginSettingsRequest>, I>>(base?: I): LoginSettingsRequest {
    return LoginSettingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoginSettingsRequest>, I>>(object: I): LoginSettingsRequest {
    const message = createBaseLoginSettingsRequest();
    message.loginId = object.loginId ?? "";
    return message;
  },
};

function createBaseLoginSettingsResult(): LoginSettingsResult {
  return { loginSettings: undefined };
}

export const LoginSettingsResult = {
  encode(message: LoginSettingsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.loginSettings !== undefined) {
      LoginSettings.encode(message.loginSettings, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginSettingsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginSettingsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginSettings = LoginSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoginSettingsResult {
    return { loginSettings: isSet(object.loginSettings) ? LoginSettings.fromJSON(object.loginSettings) : undefined };
  },

  toJSON(message: LoginSettingsResult): unknown {
    const obj: any = {};
    if (message.loginSettings !== undefined) {
      obj.loginSettings = LoginSettings.toJSON(message.loginSettings);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoginSettingsResult>, I>>(base?: I): LoginSettingsResult {
    return LoginSettingsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoginSettingsResult>, I>>(object: I): LoginSettingsResult {
    const message = createBaseLoginSettingsResult();
    message.loginSettings = (object.loginSettings !== undefined && object.loginSettings !== null)
      ? LoginSettings.fromPartial(object.loginSettings)
      : undefined;
    return message;
  },
};

function createBaseUpdateLoginSettings(): UpdateLoginSettings {
  return { loginSettings: undefined };
}

export const UpdateLoginSettings = {
  encode(message: UpdateLoginSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.loginSettings !== undefined) {
      LoginSettings.encode(message.loginSettings, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateLoginSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateLoginSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginSettings = LoginSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateLoginSettings {
    return { loginSettings: isSet(object.loginSettings) ? LoginSettings.fromJSON(object.loginSettings) : undefined };
  },

  toJSON(message: UpdateLoginSettings): unknown {
    const obj: any = {};
    if (message.loginSettings !== undefined) {
      obj.loginSettings = LoginSettings.toJSON(message.loginSettings);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateLoginSettings>, I>>(base?: I): UpdateLoginSettings {
    return UpdateLoginSettings.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateLoginSettings>, I>>(object: I): UpdateLoginSettings {
    const message = createBaseUpdateLoginSettings();
    message.loginSettings = (object.loginSettings !== undefined && object.loginSettings !== null)
      ? LoginSettings.fromPartial(object.loginSettings)
      : undefined;
    return message;
  },
};

function createBaseUpdateLoginSettingsResult(): UpdateLoginSettingsResult {
  return {};
}

export const UpdateLoginSettingsResult = {
  encode(_: UpdateLoginSettingsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateLoginSettingsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateLoginSettingsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateLoginSettingsResult {
    return {};
  },

  toJSON(_: UpdateLoginSettingsResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateLoginSettingsResult>, I>>(base?: I): UpdateLoginSettingsResult {
    return UpdateLoginSettingsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateLoginSettingsResult>, I>>(_: I): UpdateLoginSettingsResult {
    const message = createBaseUpdateLoginSettingsResult();
    return message;
  },
};

function createBaseLoginExchangeMemberIdListRequest(): LoginExchangeMemberIdListRequest {
  return { loginId: "" };
}

export const LoginExchangeMemberIdListRequest = {
  encode(message: LoginExchangeMemberIdListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.loginId !== undefined && message.loginId !== "") {
      writer.uint32(10).string(message.loginId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginExchangeMemberIdListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginExchangeMemberIdListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoginExchangeMemberIdListRequest {
    return { loginId: isSet(object.loginId) ? globalThis.String(object.loginId) : "" };
  },

  toJSON(message: LoginExchangeMemberIdListRequest): unknown {
    const obj: any = {};
    if (message.loginId !== undefined && message.loginId !== "") {
      obj.loginId = message.loginId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoginExchangeMemberIdListRequest>, I>>(
    base?: I,
  ): LoginExchangeMemberIdListRequest {
    return LoginExchangeMemberIdListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoginExchangeMemberIdListRequest>, I>>(
    object: I,
  ): LoginExchangeMemberIdListRequest {
    const message = createBaseLoginExchangeMemberIdListRequest();
    message.loginId = object.loginId ?? "";
    return message;
  },
};

function createBaseLoginExchangeMemberIdListResult(): LoginExchangeMemberIdListResult {
  return { loginMemberIds: [] };
}

export const LoginExchangeMemberIdListResult = {
  encode(message: LoginExchangeMemberIdListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.loginMemberIds) {
      LoginExchangeMemberId.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginExchangeMemberIdListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginExchangeMemberIdListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginMemberIds.push(LoginExchangeMemberId.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoginExchangeMemberIdListResult {
    return {
      loginMemberIds: globalThis.Array.isArray(object?.loginMemberIds)
        ? object.loginMemberIds.map((e: any) => LoginExchangeMemberId.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LoginExchangeMemberIdListResult): unknown {
    const obj: any = {};
    if (message.loginMemberIds?.length) {
      obj.loginMemberIds = message.loginMemberIds.map((e) => LoginExchangeMemberId.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoginExchangeMemberIdListResult>, I>>(base?: I): LoginExchangeMemberIdListResult {
    return LoginExchangeMemberIdListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoginExchangeMemberIdListResult>, I>>(
    object: I,
  ): LoginExchangeMemberIdListResult {
    const message = createBaseLoginExchangeMemberIdListResult();
    message.loginMemberIds = object.loginMemberIds?.map((e) => LoginExchangeMemberId.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLoginExchangeMemberId(): LoginExchangeMemberId {
  return { loginId: "", exchangeId: "", memberId: "" };
}

export const LoginExchangeMemberId = {
  encode(message: LoginExchangeMemberId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.loginId !== undefined && message.loginId !== "") {
      writer.uint32(10).string(message.loginId);
    }
    if (message.exchangeId !== undefined && message.exchangeId !== "") {
      writer.uint32(18).string(message.exchangeId);
    }
    if (message.memberId !== undefined && message.memberId !== "") {
      writer.uint32(26).string(message.memberId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginExchangeMemberId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginExchangeMemberId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.exchangeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.memberId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoginExchangeMemberId {
    return {
      loginId: isSet(object.loginId) ? globalThis.String(object.loginId) : "",
      exchangeId: isSet(object.exchangeId) ? globalThis.String(object.exchangeId) : "",
      memberId: isSet(object.memberId) ? globalThis.String(object.memberId) : "",
    };
  },

  toJSON(message: LoginExchangeMemberId): unknown {
    const obj: any = {};
    if (message.loginId !== undefined && message.loginId !== "") {
      obj.loginId = message.loginId;
    }
    if (message.exchangeId !== undefined && message.exchangeId !== "") {
      obj.exchangeId = message.exchangeId;
    }
    if (message.memberId !== undefined && message.memberId !== "") {
      obj.memberId = message.memberId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoginExchangeMemberId>, I>>(base?: I): LoginExchangeMemberId {
    return LoginExchangeMemberId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoginExchangeMemberId>, I>>(object: I): LoginExchangeMemberId {
    const message = createBaseLoginExchangeMemberId();
    message.loginId = object.loginId ?? "";
    message.exchangeId = object.exchangeId ?? "";
    message.memberId = object.memberId ?? "";
    return message;
  },
};

function createBaseUpdateLoginExchangeMemberIdList(): UpdateLoginExchangeMemberIdList {
  return { loginId: "", memberIdsToSet: [], memberIdsToRemove: [] };
}

export const UpdateLoginExchangeMemberIdList = {
  encode(message: UpdateLoginExchangeMemberIdList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.loginId !== undefined && message.loginId !== "") {
      writer.uint32(10).string(message.loginId);
    }
    for (const v of message.memberIdsToSet) {
      LoginExchangeMemberId.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.memberIdsToRemove) {
      Tuple.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateLoginExchangeMemberIdList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateLoginExchangeMemberIdList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.memberIdsToSet.push(LoginExchangeMemberId.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.memberIdsToRemove.push(Tuple.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateLoginExchangeMemberIdList {
    return {
      loginId: isSet(object.loginId) ? globalThis.String(object.loginId) : "",
      memberIdsToSet: globalThis.Array.isArray(object?.memberIdsToSet)
        ? object.memberIdsToSet.map((e: any) => LoginExchangeMemberId.fromJSON(e))
        : [],
      memberIdsToRemove: globalThis.Array.isArray(object?.memberIdsToRemove)
        ? object.memberIdsToRemove.map((e: any) => Tuple.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateLoginExchangeMemberIdList): unknown {
    const obj: any = {};
    if (message.loginId !== undefined && message.loginId !== "") {
      obj.loginId = message.loginId;
    }
    if (message.memberIdsToSet?.length) {
      obj.memberIdsToSet = message.memberIdsToSet.map((e) => LoginExchangeMemberId.toJSON(e));
    }
    if (message.memberIdsToRemove?.length) {
      obj.memberIdsToRemove = message.memberIdsToRemove.map((e) => Tuple.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateLoginExchangeMemberIdList>, I>>(base?: I): UpdateLoginExchangeMemberIdList {
    return UpdateLoginExchangeMemberIdList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateLoginExchangeMemberIdList>, I>>(
    object: I,
  ): UpdateLoginExchangeMemberIdList {
    const message = createBaseUpdateLoginExchangeMemberIdList();
    message.loginId = object.loginId ?? "";
    message.memberIdsToSet = object.memberIdsToSet?.map((e) => LoginExchangeMemberId.fromPartial(e)) || [];
    message.memberIdsToRemove = object.memberIdsToRemove?.map((e) => Tuple.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateLoginExchangeMemberIdListResult(): UpdateLoginExchangeMemberIdListResult {
  return {};
}

export const UpdateLoginExchangeMemberIdListResult = {
  encode(_: UpdateLoginExchangeMemberIdListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateLoginExchangeMemberIdListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateLoginExchangeMemberIdListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateLoginExchangeMemberIdListResult {
    return {};
  },

  toJSON(_: UpdateLoginExchangeMemberIdListResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateLoginExchangeMemberIdListResult>, I>>(
    base?: I,
  ): UpdateLoginExchangeMemberIdListResult {
    return UpdateLoginExchangeMemberIdListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateLoginExchangeMemberIdListResult>, I>>(
    _: I,
  ): UpdateLoginExchangeMemberIdListResult {
    const message = createBaseUpdateLoginExchangeMemberIdListResult();
    return message;
  },
};

function createBaseActivateLogin(): ActivateLogin {
  return { loginId: "" };
}

export const ActivateLogin = {
  encode(message: ActivateLogin, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.loginId !== undefined && message.loginId !== "") {
      writer.uint32(10).string(message.loginId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActivateLogin {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivateLogin();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActivateLogin {
    return { loginId: isSet(object.loginId) ? globalThis.String(object.loginId) : "" };
  },

  toJSON(message: ActivateLogin): unknown {
    const obj: any = {};
    if (message.loginId !== undefined && message.loginId !== "") {
      obj.loginId = message.loginId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivateLogin>, I>>(base?: I): ActivateLogin {
    return ActivateLogin.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivateLogin>, I>>(object: I): ActivateLogin {
    const message = createBaseActivateLogin();
    message.loginId = object.loginId ?? "";
    return message;
  },
};

function createBaseDeactivateLogin(): DeactivateLogin {
  return { loginId: "", expirationTime: 0, cleanUpEventSubscriptions: false };
}

export const DeactivateLogin = {
  encode(message: DeactivateLogin, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.loginId !== undefined && message.loginId !== "") {
      writer.uint32(10).string(message.loginId);
    }
    if (message.expirationTime !== undefined && message.expirationTime !== 0) {
      writer.uint32(16).sint64(message.expirationTime);
    }
    if (message.cleanUpEventSubscriptions !== undefined && message.cleanUpEventSubscriptions !== false) {
      writer.uint32(24).bool(message.cleanUpEventSubscriptions);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeactivateLogin {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeactivateLogin();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.expirationTime = longToNumber(reader.sint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.cleanUpEventSubscriptions = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeactivateLogin {
    return {
      loginId: isSet(object.loginId) ? globalThis.String(object.loginId) : "",
      expirationTime: isSet(object.expirationTime) ? globalThis.Number(object.expirationTime) : 0,
      cleanUpEventSubscriptions: isSet(object.cleanUpEventSubscriptions)
        ? globalThis.Boolean(object.cleanUpEventSubscriptions)
        : false,
    };
  },

  toJSON(message: DeactivateLogin): unknown {
    const obj: any = {};
    if (message.loginId !== undefined && message.loginId !== "") {
      obj.loginId = message.loginId;
    }
    if (message.expirationTime !== undefined && message.expirationTime !== 0) {
      obj.expirationTime = Math.round(message.expirationTime);
    }
    if (message.cleanUpEventSubscriptions !== undefined && message.cleanUpEventSubscriptions !== false) {
      obj.cleanUpEventSubscriptions = message.cleanUpEventSubscriptions;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeactivateLogin>, I>>(base?: I): DeactivateLogin {
    return DeactivateLogin.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeactivateLogin>, I>>(object: I): DeactivateLogin {
    const message = createBaseDeactivateLogin();
    message.loginId = object.loginId ?? "";
    message.expirationTime = object.expirationTime ?? 0;
    message.cleanUpEventSubscriptions = object.cleanUpEventSubscriptions ?? false;
    return message;
  },
};

function createBaseActivateLoginResult(): ActivateLoginResult {
  return {};
}

export const ActivateLoginResult = {
  encode(_: ActivateLoginResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActivateLoginResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivateLoginResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ActivateLoginResult {
    return {};
  },

  toJSON(_: ActivateLoginResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivateLoginResult>, I>>(base?: I): ActivateLoginResult {
    return ActivateLoginResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivateLoginResult>, I>>(_: I): ActivateLoginResult {
    const message = createBaseActivateLoginResult();
    return message;
  },
};

function createBaseDeactivateLoginResult(): DeactivateLoginResult {
  return {};
}

export const DeactivateLoginResult = {
  encode(_: DeactivateLoginResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeactivateLoginResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeactivateLoginResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeactivateLoginResult {
    return {};
  },

  toJSON(_: DeactivateLoginResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DeactivateLoginResult>, I>>(base?: I): DeactivateLoginResult {
    return DeactivateLoginResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeactivateLoginResult>, I>>(_: I): DeactivateLoginResult {
    const message = createBaseDeactivateLoginResult();
    return message;
  },
};

function createBaseEraseCurrentCredentials(): EraseCurrentCredentials {
  return { loginId: "", credentialTypes: [] };
}

export const EraseCurrentCredentials = {
  encode(message: EraseCurrentCredentials, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.loginId !== undefined && message.loginId !== "") {
      writer.uint32(10).string(message.loginId);
    }
    writer.uint32(18).fork();
    for (const v of message.credentialTypes) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EraseCurrentCredentials {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEraseCurrentCredentials();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginId = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.credentialTypes.push(reader.uint32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.credentialTypes.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EraseCurrentCredentials {
    return {
      loginId: isSet(object.loginId) ? globalThis.String(object.loginId) : "",
      credentialTypes: globalThis.Array.isArray(object?.credentialTypes)
        ? object.credentialTypes.map((e: any) => globalThis.Number(e))
        : [],
    };
  },

  toJSON(message: EraseCurrentCredentials): unknown {
    const obj: any = {};
    if (message.loginId !== undefined && message.loginId !== "") {
      obj.loginId = message.loginId;
    }
    if (message.credentialTypes?.length) {
      obj.credentialTypes = message.credentialTypes.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EraseCurrentCredentials>, I>>(base?: I): EraseCurrentCredentials {
    return EraseCurrentCredentials.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EraseCurrentCredentials>, I>>(object: I): EraseCurrentCredentials {
    const message = createBaseEraseCurrentCredentials();
    message.loginId = object.loginId ?? "";
    message.credentialTypes = object.credentialTypes?.map((e) => e) || [];
    return message;
  },
};

function createBaseEraseCurrentCredentialsResult(): EraseCurrentCredentialsResult {
  return {};
}

export const EraseCurrentCredentialsResult = {
  encode(_: EraseCurrentCredentialsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EraseCurrentCredentialsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEraseCurrentCredentialsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EraseCurrentCredentialsResult {
    return {};
  },

  toJSON(_: EraseCurrentCredentialsResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EraseCurrentCredentialsResult>, I>>(base?: I): EraseCurrentCredentialsResult {
    return EraseCurrentCredentialsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EraseCurrentCredentialsResult>, I>>(_: I): EraseCurrentCredentialsResult {
    const message = createBaseEraseCurrentCredentialsResult();
    return message;
  },
};

function createBaseSearchOption(): SearchOption {
  return { text: "", criteria: [], matchingRule: 0 };
}

export const SearchOption = {
  encode(message: SearchOption, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.text !== undefined && message.text !== "") {
      writer.uint32(10).string(message.text);
    }
    writer.uint32(18).fork();
    for (const v of message.criteria) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.matchingRule !== undefined && message.matchingRule !== 0) {
      writer.uint32(24).uint32(message.matchingRule);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchOption {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchOption();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.text = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.criteria.push(reader.uint32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.criteria.push(reader.uint32());
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.matchingRule = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SearchOption {
    return {
      text: isSet(object.text) ? globalThis.String(object.text) : "",
      criteria: globalThis.Array.isArray(object?.criteria) ? object.criteria.map((e: any) => globalThis.Number(e)) : [],
      matchingRule: isSet(object.matchingRule) ? globalThis.Number(object.matchingRule) : 0,
    };
  },

  toJSON(message: SearchOption): unknown {
    const obj: any = {};
    if (message.text !== undefined && message.text !== "") {
      obj.text = message.text;
    }
    if (message.criteria?.length) {
      obj.criteria = message.criteria.map((e) => Math.round(e));
    }
    if (message.matchingRule !== undefined && message.matchingRule !== 0) {
      obj.matchingRule = Math.round(message.matchingRule);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SearchOption>, I>>(base?: I): SearchOption {
    return SearchOption.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchOption>, I>>(object: I): SearchOption {
    const message = createBaseSearchOption();
    message.text = object.text ?? "";
    message.criteria = object.criteria?.map((e) => e) || [];
    message.matchingRule = object.matchingRule ?? 0;
    return message;
  },
};

function createBaseNamedEntity(): NamedEntity {
  return { id: "", name: undefined };
}

export const NamedEntity = {
  encode(message: NamedEntity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      Text.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NamedEntity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNamedEntity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Text.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NamedEntity {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? Text.fromJSON(object.name) : undefined,
    };
  },

  toJSON(message: NamedEntity): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== undefined) {
      obj.name = Text.toJSON(message.name);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NamedEntity>, I>>(base?: I): NamedEntity {
    return NamedEntity.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NamedEntity>, I>>(object: I): NamedEntity {
    const message = createBaseNamedEntity();
    message.id = object.id ?? "";
    message.name = (object.name !== undefined && object.name !== null) ? Text.fromPartial(object.name) : undefined;
    return message;
  },
};

function createBaseUpdateEntityLinks(): UpdateEntityLinks {
  return { linksToSet: [], linksToRemove: [] };
}

export const UpdateEntityLinks = {
  encode(message: UpdateEntityLinks, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.linksToSet) {
      Tuple.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.linksToRemove) {
      Tuple.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEntityLinks {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEntityLinks();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linksToSet.push(Tuple.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.linksToRemove.push(Tuple.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateEntityLinks {
    return {
      linksToSet: globalThis.Array.isArray(object?.linksToSet)
        ? object.linksToSet.map((e: any) => Tuple.fromJSON(e))
        : [],
      linksToRemove: globalThis.Array.isArray(object?.linksToRemove)
        ? object.linksToRemove.map((e: any) => Tuple.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateEntityLinks): unknown {
    const obj: any = {};
    if (message.linksToSet?.length) {
      obj.linksToSet = message.linksToSet.map((e) => Tuple.toJSON(e));
    }
    if (message.linksToRemove?.length) {
      obj.linksToRemove = message.linksToRemove.map((e) => Tuple.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEntityLinks>, I>>(base?: I): UpdateEntityLinks {
    return UpdateEntityLinks.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEntityLinks>, I>>(object: I): UpdateEntityLinks {
    const message = createBaseUpdateEntityLinks();
    message.linksToSet = object.linksToSet?.map((e) => Tuple.fromPartial(e)) || [];
    message.linksToRemove = object.linksToRemove?.map((e) => Tuple.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateEntityLinksResult(): UpdateEntityLinksResult {
  return {};
}

export const UpdateEntityLinksResult = {
  encode(_: UpdateEntityLinksResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateEntityLinksResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateEntityLinksResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateEntityLinksResult {
    return {};
  },

  toJSON(_: UpdateEntityLinksResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateEntityLinksResult>, I>>(base?: I): UpdateEntityLinksResult {
    return UpdateEntityLinksResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateEntityLinksResult>, I>>(_: I): UpdateEntityLinksResult {
    const message = createBaseUpdateEntityLinksResult();
    return message;
  },
};

function createBaseLinkedEntityListRequest(): LinkedEntityListRequest {
  return { id: "", top: 0 };
}

export const LinkedEntityListRequest = {
  encode(message: LinkedEntityListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.top !== undefined && message.top !== 0) {
      writer.uint32(16).uint32(message.top);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LinkedEntityListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLinkedEntityListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.top = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LinkedEntityListRequest {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      top: isSet(object.top) ? globalThis.Number(object.top) : 0,
    };
  },

  toJSON(message: LinkedEntityListRequest): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.top !== undefined && message.top !== 0) {
      obj.top = Math.round(message.top);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LinkedEntityListRequest>, I>>(base?: I): LinkedEntityListRequest {
    return LinkedEntityListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LinkedEntityListRequest>, I>>(object: I): LinkedEntityListRequest {
    const message = createBaseLinkedEntityListRequest();
    message.id = object.id ?? "";
    message.top = object.top ?? 0;
    return message;
  },
};

function createBaseLinkedEntityListResult(): LinkedEntityListResult {
  return { entities: [], isComplete: true };
}

export const LinkedEntityListResult = {
  encode(message: LinkedEntityListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.entities) {
      NamedEntity.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      writer.uint32(16).bool(message.isComplete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LinkedEntityListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLinkedEntityListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entities.push(NamedEntity.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isComplete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LinkedEntityListResult {
    return {
      entities: globalThis.Array.isArray(object?.entities)
        ? object.entities.map((e: any) => NamedEntity.fromJSON(e))
        : [],
      isComplete: isSet(object.isComplete) ? globalThis.Boolean(object.isComplete) : true,
    };
  },

  toJSON(message: LinkedEntityListResult): unknown {
    const obj: any = {};
    if (message.entities?.length) {
      obj.entities = message.entities.map((e) => NamedEntity.toJSON(e));
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      obj.isComplete = message.isComplete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LinkedEntityListResult>, I>>(base?: I): LinkedEntityListResult {
    return LinkedEntityListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LinkedEntityListResult>, I>>(object: I): LinkedEntityListResult {
    const message = createBaseLinkedEntityListResult();
    message.entities = object.entities?.map((e) => NamedEntity.fromPartial(e)) || [];
    message.isComplete = object.isComplete ?? true;
    return message;
  },
};

function createBaseSalesSeriesListResult(): SalesSeriesListResult {
  return { salesSeries: [], isComplete: true };
}

export const SalesSeriesListResult = {
  encode(message: SalesSeriesListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.salesSeries) {
      SalesSeriesInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      writer.uint32(16).bool(message.isComplete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SalesSeriesListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSalesSeriesListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.salesSeries.push(SalesSeriesInfo.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isComplete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SalesSeriesListResult {
    return {
      salesSeries: globalThis.Array.isArray(object?.salesSeries)
        ? object.salesSeries.map((e: any) => SalesSeriesInfo.fromJSON(e))
        : [],
      isComplete: isSet(object.isComplete) ? globalThis.Boolean(object.isComplete) : true,
    };
  },

  toJSON(message: SalesSeriesListResult): unknown {
    const obj: any = {};
    if (message.salesSeries?.length) {
      obj.salesSeries = message.salesSeries.map((e) => SalesSeriesInfo.toJSON(e));
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      obj.isComplete = message.isComplete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SalesSeriesListResult>, I>>(base?: I): SalesSeriesListResult {
    return SalesSeriesListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SalesSeriesListResult>, I>>(object: I): SalesSeriesListResult {
    const message = createBaseSalesSeriesListResult();
    message.salesSeries = object.salesSeries?.map((e) => SalesSeriesInfo.fromPartial(e)) || [];
    message.isComplete = object.isComplete ?? true;
    return message;
  },
};

function createBaseSalesSeriesInfo(): SalesSeriesInfo {
  return { id: "", salesSeriesName: "", salesSeriesNumber: "", profileId: "" };
}

export const SalesSeriesInfo = {
  encode(message: SalesSeriesInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.salesSeriesName !== undefined && message.salesSeriesName !== "") {
      writer.uint32(18).string(message.salesSeriesName);
    }
    if (message.salesSeriesNumber !== undefined && message.salesSeriesNumber !== "") {
      writer.uint32(26).string(message.salesSeriesNumber);
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      writer.uint32(34).string(message.profileId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SalesSeriesInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSalesSeriesInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.salesSeriesName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.salesSeriesNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.profileId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SalesSeriesInfo {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      salesSeriesName: isSet(object.salesSeriesName) ? globalThis.String(object.salesSeriesName) : "",
      salesSeriesNumber: isSet(object.salesSeriesNumber) ? globalThis.String(object.salesSeriesNumber) : "",
      profileId: isSet(object.profileId) ? globalThis.String(object.profileId) : "",
    };
  },

  toJSON(message: SalesSeriesInfo): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.salesSeriesName !== undefined && message.salesSeriesName !== "") {
      obj.salesSeriesName = message.salesSeriesName;
    }
    if (message.salesSeriesNumber !== undefined && message.salesSeriesNumber !== "") {
      obj.salesSeriesNumber = message.salesSeriesNumber;
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      obj.profileId = message.profileId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SalesSeriesInfo>, I>>(base?: I): SalesSeriesInfo {
    return SalesSeriesInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SalesSeriesInfo>, I>>(object: I): SalesSeriesInfo {
    const message = createBaseSalesSeriesInfo();
    message.id = object.id ?? "";
    message.salesSeriesName = object.salesSeriesName ?? "";
    message.salesSeriesNumber = object.salesSeriesNumber ?? "";
    message.profileId = object.profileId ?? "";
    return message;
  },
};

function createBaseExternalAuth(): ExternalAuth {
  return { partnerId: "", username: "" };
}

export const ExternalAuth = {
  encode(message: ExternalAuth, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.partnerId !== undefined && message.partnerId !== "") {
      writer.uint32(10).string(message.partnerId);
    }
    if (message.username !== undefined && message.username !== "") {
      writer.uint32(18).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExternalAuth {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExternalAuth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.partnerId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExternalAuth {
    return {
      partnerId: isSet(object.partnerId) ? globalThis.String(object.partnerId) : "",
      username: isSet(object.username) ? globalThis.String(object.username) : "",
    };
  },

  toJSON(message: ExternalAuth): unknown {
    const obj: any = {};
    if (message.partnerId !== undefined && message.partnerId !== "") {
      obj.partnerId = message.partnerId;
    }
    if (message.username !== undefined && message.username !== "") {
      obj.username = message.username;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExternalAuth>, I>>(base?: I): ExternalAuth {
    return ExternalAuth.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExternalAuth>, I>>(object: I): ExternalAuth {
    const message = createBaseExternalAuth();
    message.partnerId = object.partnerId ?? "";
    message.username = object.username ?? "";
    return message;
  },
};

function createBaseUpdateLoginBillingCustomData(): UpdateLoginBillingCustomData {
  return { loginId: "", loginBillingCustomData: [] };
}

export const UpdateLoginBillingCustomData = {
  encode(message: UpdateLoginBillingCustomData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.loginId !== undefined && message.loginId !== "") {
      writer.uint32(10).string(message.loginId);
    }
    for (const v of message.loginBillingCustomData) {
      LoginBillingCustomData.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateLoginBillingCustomData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateLoginBillingCustomData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.loginBillingCustomData.push(LoginBillingCustomData.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateLoginBillingCustomData {
    return {
      loginId: isSet(object.loginId) ? globalThis.String(object.loginId) : "",
      loginBillingCustomData: globalThis.Array.isArray(object?.loginBillingCustomData)
        ? object.loginBillingCustomData.map((e: any) => LoginBillingCustomData.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateLoginBillingCustomData): unknown {
    const obj: any = {};
    if (message.loginId !== undefined && message.loginId !== "") {
      obj.loginId = message.loginId;
    }
    if (message.loginBillingCustomData?.length) {
      obj.loginBillingCustomData = message.loginBillingCustomData.map((e) => LoginBillingCustomData.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateLoginBillingCustomData>, I>>(base?: I): UpdateLoginBillingCustomData {
    return UpdateLoginBillingCustomData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateLoginBillingCustomData>, I>>(object: I): UpdateLoginBillingCustomData {
    const message = createBaseUpdateLoginBillingCustomData();
    message.loginId = object.loginId ?? "";
    message.loginBillingCustomData = object.loginBillingCustomData?.map((e) => LoginBillingCustomData.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseUpdateLoginBillingCustomDataResult(): UpdateLoginBillingCustomDataResult {
  return {};
}

export const UpdateLoginBillingCustomDataResult = {
  encode(_: UpdateLoginBillingCustomDataResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateLoginBillingCustomDataResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateLoginBillingCustomDataResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateLoginBillingCustomDataResult {
    return {};
  },

  toJSON(_: UpdateLoginBillingCustomDataResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateLoginBillingCustomDataResult>, I>>(
    base?: I,
  ): UpdateLoginBillingCustomDataResult {
    return UpdateLoginBillingCustomDataResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateLoginBillingCustomDataResult>, I>>(
    _: I,
  ): UpdateLoginBillingCustomDataResult {
    const message = createBaseUpdateLoginBillingCustomDataResult();
    return message;
  },
};

function createBaseLoginBillingCustomData(): LoginBillingCustomData {
  return { accountId: "", billingCustomData: [] };
}

export const LoginBillingCustomData = {
  encode(message: LoginBillingCustomData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    for (const v of message.billingCustomData) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginBillingCustomData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginBillingCustomData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.billingCustomData.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoginBillingCustomData {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      billingCustomData: globalThis.Array.isArray(object?.billingCustomData)
        ? object.billingCustomData.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: LoginBillingCustomData): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.billingCustomData?.length) {
      obj.billingCustomData = message.billingCustomData;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoginBillingCustomData>, I>>(base?: I): LoginBillingCustomData {
    return LoginBillingCustomData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoginBillingCustomData>, I>>(object: I): LoginBillingCustomData {
    const message = createBaseLoginBillingCustomData();
    message.accountId = object.accountId ?? "";
    message.billingCustomData = object.billingCustomData?.map((e) => e) || [];
    return message;
  },
};

function createBaseLoginBillingCustomDataRequest(): LoginBillingCustomDataRequest {
  return { loginId: "" };
}

export const LoginBillingCustomDataRequest = {
  encode(message: LoginBillingCustomDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.loginId !== undefined && message.loginId !== "") {
      writer.uint32(10).string(message.loginId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginBillingCustomDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginBillingCustomDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoginBillingCustomDataRequest {
    return { loginId: isSet(object.loginId) ? globalThis.String(object.loginId) : "" };
  },

  toJSON(message: LoginBillingCustomDataRequest): unknown {
    const obj: any = {};
    if (message.loginId !== undefined && message.loginId !== "") {
      obj.loginId = message.loginId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoginBillingCustomDataRequest>, I>>(base?: I): LoginBillingCustomDataRequest {
    return LoginBillingCustomDataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoginBillingCustomDataRequest>, I>>(
    object: I,
  ): LoginBillingCustomDataRequest {
    const message = createBaseLoginBillingCustomDataRequest();
    message.loginId = object.loginId ?? "";
    return message;
  },
};

function createBaseLoginBillingCustomDataResult(): LoginBillingCustomDataResult {
  return { loginBillingCustomData: [] };
}

export const LoginBillingCustomDataResult = {
  encode(message: LoginBillingCustomDataResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.loginBillingCustomData) {
      LoginBillingCustomData.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoginBillingCustomDataResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoginBillingCustomDataResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginBillingCustomData.push(LoginBillingCustomData.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LoginBillingCustomDataResult {
    return {
      loginBillingCustomData: globalThis.Array.isArray(object?.loginBillingCustomData)
        ? object.loginBillingCustomData.map((e: any) => LoginBillingCustomData.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LoginBillingCustomDataResult): unknown {
    const obj: any = {};
    if (message.loginBillingCustomData?.length) {
      obj.loginBillingCustomData = message.loginBillingCustomData.map((e) => LoginBillingCustomData.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LoginBillingCustomDataResult>, I>>(base?: I): LoginBillingCustomDataResult {
    return LoginBillingCustomDataResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoginBillingCustomDataResult>, I>>(object: I): LoginBillingCustomDataResult {
    const message = createBaseLoginBillingCustomDataResult();
    message.loginBillingCustomData = object.loginBillingCustomData?.map((e) => LoginBillingCustomData.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseTradingFeaturesRequest(): TradingFeaturesRequest {
  return {};
}

export const TradingFeaturesRequest = {
  encode(_: TradingFeaturesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradingFeaturesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradingFeaturesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): TradingFeaturesRequest {
    return {};
  },

  toJSON(_: TradingFeaturesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<TradingFeaturesRequest>, I>>(base?: I): TradingFeaturesRequest {
    return TradingFeaturesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradingFeaturesRequest>, I>>(_: I): TradingFeaturesRequest {
    const message = createBaseTradingFeaturesRequest();
    return message;
  },
};

function createBaseTradingFeaturesResult(): TradingFeaturesResult {
  return { tradingInterfaceElements: [] };
}

export const TradingFeaturesResult = {
  encode(message: TradingFeaturesResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tradingInterfaceElements) {
      TradingInterfaceElement.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradingFeaturesResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradingFeaturesResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tradingInterfaceElements.push(TradingInterfaceElement.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradingFeaturesResult {
    return {
      tradingInterfaceElements: globalThis.Array.isArray(object?.tradingInterfaceElements)
        ? object.tradingInterfaceElements.map((e: any) => TradingInterfaceElement.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TradingFeaturesResult): unknown {
    const obj: any = {};
    if (message.tradingInterfaceElements?.length) {
      obj.tradingInterfaceElements = message.tradingInterfaceElements.map((e) => TradingInterfaceElement.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradingFeaturesResult>, I>>(base?: I): TradingFeaturesResult {
    return TradingFeaturesResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradingFeaturesResult>, I>>(object: I): TradingFeaturesResult {
    const message = createBaseTradingFeaturesResult();
    message.tradingInterfaceElements =
      object.tradingInterfaceElements?.map((e) => TradingInterfaceElement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTradingInterfaceElement(): TradingInterfaceElement {
  return { id: "", name: undefined, disableOnly: false };
}

export const TradingInterfaceElement = {
  encode(message: TradingInterfaceElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      Text.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    if (message.disableOnly !== undefined && message.disableOnly !== false) {
      writer.uint32(24).bool(message.disableOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradingInterfaceElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradingInterfaceElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Text.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.disableOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradingInterfaceElement {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? Text.fromJSON(object.name) : undefined,
      disableOnly: isSet(object.disableOnly) ? globalThis.Boolean(object.disableOnly) : false,
    };
  },

  toJSON(message: TradingInterfaceElement): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== undefined) {
      obj.name = Text.toJSON(message.name);
    }
    if (message.disableOnly !== undefined && message.disableOnly !== false) {
      obj.disableOnly = message.disableOnly;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradingInterfaceElement>, I>>(base?: I): TradingInterfaceElement {
    return TradingInterfaceElement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradingInterfaceElement>, I>>(object: I): TradingInterfaceElement {
    const message = createBaseTradingInterfaceElement();
    message.id = object.id ?? "";
    message.name = (object.name !== undefined && object.name !== null) ? Text.fromPartial(object.name) : undefined;
    message.disableOnly = object.disableOnly ?? false;
    return message;
  },
};

function createBaseProfile(): Profile {
  return {
    clearedFields: [],
    customerId: "",
    legalType: 0,
    name: "",
    contactInformation: undefined,
    linkedBrokerageId: "",
    removed: false,
    obsoleteAuthenticationSystem: "",
    profileType: 1,
    profileId: "",
    salesSeriesId: "",
    number: "",
    simplified: false,
  };
}

export const Profile = {
  encode(message: Profile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(74).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.customerId !== undefined && message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    if (message.legalType !== undefined && message.legalType !== 0) {
      writer.uint32(16).uint32(message.legalType);
    }
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.contactInformation !== undefined) {
      ContactInformation.encode(message.contactInformation, writer.uint32(34).fork()).ldelim();
    }
    if (message.linkedBrokerageId !== undefined && message.linkedBrokerageId !== "") {
      writer.uint32(42).string(message.linkedBrokerageId);
    }
    if (message.removed !== undefined && message.removed !== false) {
      writer.uint32(48).bool(message.removed);
    }
    if (message.obsoleteAuthenticationSystem !== undefined && message.obsoleteAuthenticationSystem !== "") {
      writer.uint32(58).string(message.obsoleteAuthenticationSystem);
    }
    if (message.profileType !== undefined && message.profileType !== 1) {
      writer.uint32(64).uint32(message.profileType);
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      writer.uint32(90).string(message.profileId);
    }
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      writer.uint32(98).string(message.salesSeriesId);
    }
    if (message.number !== undefined && message.number !== "") {
      writer.uint32(106).string(message.number);
    }
    if (message.simplified !== undefined && message.simplified !== false) {
      writer.uint32(112).bool(message.simplified);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Profile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 9:
          if (tag === 72) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 74) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.legalType = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.contactInformation = ContactInformation.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.linkedBrokerageId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.removed = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.obsoleteAuthenticationSystem = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.profileType = reader.uint32();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.profileId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.salesSeriesId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.number = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.simplified = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Profile {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "",
      legalType: isSet(object.legalType) ? globalThis.Number(object.legalType) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      contactInformation: isSet(object.contactInformation)
        ? ContactInformation.fromJSON(object.contactInformation)
        : undefined,
      linkedBrokerageId: isSet(object.linkedBrokerageId) ? globalThis.String(object.linkedBrokerageId) : "",
      removed: isSet(object.removed) ? globalThis.Boolean(object.removed) : false,
      obsoleteAuthenticationSystem: isSet(object.obsoleteAuthenticationSystem)
        ? globalThis.String(object.obsoleteAuthenticationSystem)
        : "",
      profileType: isSet(object.profileType) ? globalThis.Number(object.profileType) : 1,
      profileId: isSet(object.profileId) ? globalThis.String(object.profileId) : "",
      salesSeriesId: isSet(object.salesSeriesId) ? globalThis.String(object.salesSeriesId) : "",
      number: isSet(object.number) ? globalThis.String(object.number) : "",
      simplified: isSet(object.simplified) ? globalThis.Boolean(object.simplified) : false,
    };
  },

  toJSON(message: Profile): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.customerId !== undefined && message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    if (message.legalType !== undefined && message.legalType !== 0) {
      obj.legalType = Math.round(message.legalType);
    }
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.contactInformation !== undefined) {
      obj.contactInformation = ContactInformation.toJSON(message.contactInformation);
    }
    if (message.linkedBrokerageId !== undefined && message.linkedBrokerageId !== "") {
      obj.linkedBrokerageId = message.linkedBrokerageId;
    }
    if (message.removed !== undefined && message.removed !== false) {
      obj.removed = message.removed;
    }
    if (message.obsoleteAuthenticationSystem !== undefined && message.obsoleteAuthenticationSystem !== "") {
      obj.obsoleteAuthenticationSystem = message.obsoleteAuthenticationSystem;
    }
    if (message.profileType !== undefined && message.profileType !== 1) {
      obj.profileType = Math.round(message.profileType);
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      obj.profileId = message.profileId;
    }
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      obj.salesSeriesId = message.salesSeriesId;
    }
    if (message.number !== undefined && message.number !== "") {
      obj.number = message.number;
    }
    if (message.simplified !== undefined && message.simplified !== false) {
      obj.simplified = message.simplified;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Profile>, I>>(base?: I): Profile {
    return Profile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Profile>, I>>(object: I): Profile {
    const message = createBaseProfile();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.customerId = object.customerId ?? "";
    message.legalType = object.legalType ?? 0;
    message.name = object.name ?? "";
    message.contactInformation = (object.contactInformation !== undefined && object.contactInformation !== null)
      ? ContactInformation.fromPartial(object.contactInformation)
      : undefined;
    message.linkedBrokerageId = object.linkedBrokerageId ?? "";
    message.removed = object.removed ?? false;
    message.obsoleteAuthenticationSystem = object.obsoleteAuthenticationSystem ?? "";
    message.profileType = object.profileType ?? 1;
    message.profileId = object.profileId ?? "";
    message.salesSeriesId = object.salesSeriesId ?? "";
    message.number = object.number ?? "";
    message.simplified = object.simplified ?? false;
    return message;
  },
};

function createBaseDataRequest(): DataRequest {
  return {};
}

export const DataRequest = {
  encode(_: DataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DataRequest {
    return {};
  },

  toJSON(_: DataRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DataRequest>, I>>(base?: I): DataRequest {
    return DataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DataRequest>, I>>(_: I): DataRequest {
    const message = createBaseDataRequest();
    return message;
  },
};

function createBaseEntityDataRequest(): EntityDataRequest {
  return { entityId: "" };
}

export const EntityDataRequest = {
  encode(message: EntityDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entityId !== undefined && message.entityId !== "") {
      writer.uint32(10).string(message.entityId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntityDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntityDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entityId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntityDataRequest {
    return { entityId: isSet(object.entityId) ? globalThis.String(object.entityId) : "" };
  },

  toJSON(message: EntityDataRequest): unknown {
    const obj: any = {};
    if (message.entityId !== undefined && message.entityId !== "") {
      obj.entityId = message.entityId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntityDataRequest>, I>>(base?: I): EntityDataRequest {
    return EntityDataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntityDataRequest>, I>>(object: I): EntityDataRequest {
    const message = createBaseEntityDataRequest();
    message.entityId = object.entityId ?? "";
    return message;
  },
};

function createBaseEntitiesDataRequest(): EntitiesDataRequest {
  return { entityId: [] };
}

export const EntitiesDataRequest = {
  encode(message: EntitiesDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.entityId) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntitiesDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitiesDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entityId.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntitiesDataRequest {
    return {
      entityId: globalThis.Array.isArray(object?.entityId) ? object.entityId.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: EntitiesDataRequest): unknown {
    const obj: any = {};
    if (message.entityId?.length) {
      obj.entityId = message.entityId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntitiesDataRequest>, I>>(base?: I): EntitiesDataRequest {
    return EntitiesDataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitiesDataRequest>, I>>(object: I): EntitiesDataRequest {
    const message = createBaseEntitiesDataRequest();
    message.entityId = object.entityId?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
