
export interface Contract {
    id: string;
    name: string;
    month: string;
    title: string;
    lastPrice: number;
    labelPriceNetChange: number;
    labelPriceNetChangePercent: number;
    labelOpen: number;
    labelHigh: number;
    labelLow: number;
    labelTotalVolume: number;
    displayName: string;
    MONTH_CODES: {
      [key: string]: string;
    };
    contractId: number;
    yesterdaySettlement?:number;
  }

export const LABEL_TOTAL_VOLUME = "labelTotalVolume"
export const CHANGE = "change";
export const NAME = "name";
export const ASC_SORT = "asc";
export const DESC_SORT = "desc";


export const FILTER_MAPPER: any = {
  Volume: [{ field: LABEL_TOTAL_VOLUME, sort: DESC_SORT }],
  "Price Change": [{ field: CHANGE, sort: DESC_SORT }],
  "A-Z": [{ field: NAME, sort: ASC_SORT }],
  "Z-A": [{ field: NAME, sort: DESC_SORT }]
}