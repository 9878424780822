import { toast } from "react-toastify";
import { CircleChecked } from "../../../images/Icons";
import "./notification.css";
import { InstrumentsManager } from "../../../cqg-api/services/InstrumentsManager";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
export default class Notification {
  static success(message: string) {
    toast.success(`${message}`, {});
  }
  static error(message: string) {
    const toastId = "error-toast";
    if (!toast.isActive(toastId)) {
      toast.error(`${message}`, {
        toastId: toastId,
      });
    }
  }
  static warning(message: string) {
    toast.warn(message, {});
  }
  static info(message: string) {
    toast.info(message, {});
  }
  static orderInfo(createOrderState: any, orderUpdated?: boolean) {
    const instrument = InstrumentsManager.getInstrument(createOrderState.instrument.contractId);
    toast(
      <div>
        <div style={{ display: "flex", gap: "10px", fontFamily: "Averta-Regular" }}>
          <div>
            <CircleChecked />
          </div>
          <div className="notificationContent">
            <div className="notificationTitle">Order {orderUpdated ? "Updated" : "Filled"}</div>
            <div className="notificationText">
              {createOrderState?.displayContract} |{" "}
              <span style={{ color: createOrderState?.displaySide === "SELL" ? "#D32F2F" : "#4CAF50" }}>
                {createOrderState?.displaySide}
              </span>{" "}
              | {createOrderState?.filledSize ?? 0}/{createOrderState?.displaySize} Filled
            </div>
            <div className="notificationText">
              Avg. Price:{" "}
              {DisplayUtil.toDisplayPrice(createOrderState?.avgFillPrice, instrument) ?? createOrderState?.displayType}
            </div>
          </div>
        </div>
      </div>,
      {},
    );
  }
}
