import { SvgIconComponent } from "@mui/icons-material";
import { SerializedDockview } from "dockview";
import ReactGridLayout from "react-grid-layout";
import { Instrument } from "./cqg-api/models/Instrument";

export type DemoGridLayoutProps = {
  width?: number;
  defaultLayout: ReactGridLayout.Layout[];
  onLayoutChanged: (layout: ReactGridLayout.Layout[]) => void;
  selectedWorkspace?: Workspace;
};

export type WorkspaceData = {
  title?: string;
  description?: string;
  serializedDockview?: SerializedDockview;
  widgets: Widget[];
};

export type Workspace = {
  title: string;
  data?: SerializedDockview;
  description?: string;
  id?: number;
  memberId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  isDefault?: boolean;
};
export type DefaultWorkspaceData = {
  workspace: ReactGridLayout.Layout[];
  workspaceId: number;
};
export type DefaultWorkspace = DefaultWorkspaceData | null;

export type Widget = {
  title: string;
  key: number;
  icon?: SvgIconComponent | string;
  widgetType: WidgetType | string;
  widgetId?: number;
  workspaceId?: number;
  contractIds?: string[];
  widgetName?: string;
};

export type User = {
  accessToken?: string;
  name?: string;
  id?: string;
  accounts: Account[];
  firstName?: string;
  lastName?: string;
};

export enum AccountType {
  Challenge = "challenge",
  Simulator = "simulator",
}

export type Account = {
  accountId: number;
  accountType: AccountType;
  password: string;
  username: string;
};

export type Product = {
  id: string;
  cmeSymbol: string;
  description: string;
  name: string;
  tickSize: string;
  shortName: string;
  assetClass?: any;
  cqgSymbol: string;
};

export type ProductWithContracts = Product & {
  monthlyContracts?: Instrument[];
};

export type Watchlist = {
  id: number;
  workspaceId: number;
  associatedProducts: string;
  title: string;
};

export type InstrumentMarketData = Product & {
  askPrice?: number | string | null;
  askQty?: number | string | null;
  bidPrice?: number | string | null;
  bidQty?: number | string | null;
  lastPrice?: number | string | null;
  lastQty?: number | string | null;
  change?: number | null;
  changePercentage?: string | null;
  price?: number | string | null;
  title?: string | null;
  tradeVolume?: number | string | null;
};

export enum WidgetType {
  AccountInfo = "Account Info",
  Watchlist = "Watchlists",
  AvailableProducts = "Markets",
  Orders = "Orders",
  Positions = "Positions",
  EnergyChart = "Energy chart",
  BarChart = "Bar chart",
  LineChart = "Line chart",
  DoubleBarChart = "Donut chart",
  TradeLogs = "Performance",
  Chart = "Chart",
  Performance = "Performance",
  News = "News",
  PriceLadder = "Price Ladder",
  TradeTicket = "Trade Ticket",
}

export type DockerviewPanelProps = {
  title?: string;
  widget: Widget;
  workspace: Workspace;
};

export const WORKSPACES = "WORKSPACES";

export type AssetProductsDictionary = {
  [assetClassName: string]: ProductWithContracts[];
};

export enum TreeViewMode {
  SINGLE = "single",
  MULTIPLE = "multiple",
}

export const CONTRACT_ID_NAME = "displayName";

export enum positionsFilter {
  All = "All",
  Open = "Open",
  Closed = "Closed",
}

export enum OrderSide {
  Buy = "BUY",
  Sell = "SELL",
}

export enum OrderType {
  Mkt = "MKT",
  Lmt = "LMT",
  Stp = "STP",
  Stl = "STL",
  Cross = "CROSS",
  Unrecognized = "UNRECOGNIZED",
}

export enum PositionDisplayEnum {
  LongShort,
  PlusMinus,
}
