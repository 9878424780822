import { Menu, MenuItem } from "@mui/material";

const WorkspaceMenu: React.FC<{
    anchorEl: null | HTMLElement;
    open: boolean;
    onClose: () => void;
    onRenameClick: () => void;
    onDeleteClick: () => void;
    onChangeDefaultClick: () => void;
    onDuplicateClick: () => void;
  }> = ({ anchorEl, open, onClose, onRenameClick, onDeleteClick, onChangeDefaultClick, onDuplicateClick }) => (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ marginTop: "10px", padding: "5px" }}
    >
      <MenuItem onClick={onDuplicateClick}>
        <img src="/images/icons/ws/duplicate.svg" className="menuIcon" width={'16px'} height={'16px'} alt="duplicate workspace" />
        <span className="menuText">Duplicate</span>
      </MenuItem>
      <MenuItem onClick={onChangeDefaultClick}>
        <img src="/images/icons/ws/star.svg" className="menuIcon" width={'16px'} height={'16px'} alt="Set as Default" />
        <span className="menuText">Set as Default</span>
      </MenuItem>
      <MenuItem onClick={onRenameClick}>
        <img src="/images/icons/ws/rename.svg" className="menuIcon" width={'16px'} height={'16px'} alt="Rename Workspace" />
        <span className="menuText">Rename Workspace</span>
      </MenuItem>
      <hr className="divider-tab" />
      <MenuItem onClick={onDeleteClick}>
        <img src="/images/icons/ws/delete.svg" className="menuIcon" width={'16px'} height={'16px'} alt="Delete" />
        <span className="menuText">Delete</span>
      </MenuItem>
    </Menu>
  );

  export default WorkspaceMenu