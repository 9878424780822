import { FieldError } from "react-hook-form";
import { redErrorColor } from "../styles";

const blueColor = '#006EB680';

type SideButtonProps = {
  side?: FieldError;
  value?: string;
  onClick: (value: string) => void;
  cssClass: string;
  text: string;
  disabled: boolean;
}
const SideButton = ({ side, value, onClick, cssClass, text, disabled }: SideButtonProps) => {
  const styles = `instrumentAction ${cssClass} ${value === text && 'active'}`;
  return (
    <button
      style={{ borderColor: side ? redErrorColor : blueColor }}
      type="button"
      className={styles}
      disabled={disabled}
      onClick={() => onClick('BUY')} value="BUY">{text}</button>
  )
};

export default SideButton;
