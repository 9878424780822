import { useCallback, useEffect, useRef, useState } from "react";
import { DockviewApi, DockviewReadyEvent, SerializedDockview } from "dockview";
import { produce } from "immer";
import LoadingSpinner from "./LoadingSpinner";
import { Widget, Workspace } from "../../types";
import { addChartPanel, addPanel, addPanels, addTradingTicketPanel } from "./utils";
import { useUpdateWorkspace } from "../../hooks/api/workspace";

import { useDispatch, useSelector } from "react-redux";
import { clearChartWidgetValue } from "../../redux/products/chartWidget";
import DockviewContent from "./DockviewContent";
import EmptyWorkspace from "./EmptyWorkspace";
import workspacesSelectors from "../../redux/workspaces/workspacesSelector";
import { useOrderUpdates } from "../../cqg-api/hooks/ServiceHooks";

interface DockviewProps {
  activeWorkspace: Workspace;
  workspaceLoading: boolean;
  onWorkSpaceUpdated: (workspace: Workspace) => void;
  newWidgetToAddToPanel: Widget | null;
  onNewWidgetProcessed: () => void;
}

const Dockview = ({
  activeWorkspace,
  workspaceLoading,
  onWorkSpaceUpdated,
  newWidgetToAddToPanel,
  onNewWidgetProcessed,
}: DockviewProps) => {
  const { updateWorkspaceAsync } = useUpdateWorkspace();
  const [dockviewApi, setDockviewApi] = useState<DockviewApi | undefined>();
  const [showWidgetSelectionDialog, setShowWidgetSelectionDialog] = useState(false);
  useOrderUpdates();
  const [hasValidLayout, setHasValidLayout] = useState(false);
  const dispatch = useDispatch();
  const chartWidgetValue = useSelector((state: any) => state.chartWidget.chartWidgetValue);
  const tradeTicketWidgetValue = useSelector((state: any) => state.chartWidget.tradeTicketValue);

  const worspacesReduxData = useSelector(workspacesSelectors.getWorkspaces);
  const dockviewRef = useRef(null);
  
  const updateWorkspaceToServer = useCallback(async () => {
    if (!dockviewApi) return;

    const layout: SerializedDockview = dockviewApi.toJSON();
    const updatedWorkspace = produce(activeWorkspace, (draft) => {
      if (draft) draft.data = layout;
    });
    const isValidLayout = layout?.grid && Object.keys(layout.panels || {}).length === 0 ? true : false;
    setHasValidLayout(isValidLayout);

    await updateWorkspaceAsync(updatedWorkspace as Workspace);
  }, [dockviewApi, activeWorkspace, updateWorkspaceAsync]);

  const onDidLayOutChange = useCallback(() => {
    updateWorkspaceToServer();
  }, [updateWorkspaceToServer]);

  const onReady = useCallback((event: DockviewReadyEvent) => {
    setDockviewApi(event.api);
    event.api.onDidRemovePanel(async (panel) => {});
  }, []);

  useEffect(() => {
    if (!dockviewApi) return;
    dockviewApi.onWillDragPanel((event) => {
      if (event.panel?._title === "Trade") {
        event.nativeEvent.preventDefault();
      }
    });
    dockviewApi.clear();
    const { data } = activeWorkspace;

    if (data && data.grid) {
      try {
        dockviewApi.fromJSON(data);
        const panels = data.panels;
        const isValidLayout = Object.keys(panels).length === 0;
        setHasValidLayout(isValidLayout);
      } catch (e) {
        console.error("Failed to load workspace layout:", e);
      }
    } else {
      updateWorkspaceToServer();
    }

    const disposable = dockviewApi.onDidLayoutChange(onDidLayOutChange);
    return () => disposable.dispose();
  }, [dockviewApi, activeWorkspace, onDidLayOutChange, updateWorkspaceToServer]);

  const onWidgetsAdded = useCallback(() => {
    updateWorkspaceToServer();
  }, [updateWorkspaceToServer]);

  const addWidgetsToPanel = useCallback(
    async (widgets: Widget[]) => {
      if (!dockviewApi) return;
      dockviewApi.clear();
      try {
        addPanels(activeWorkspace, dockviewApi, widgets, undefined);
        try {
          const layout: SerializedDockview = dockviewApi.toJSON();
          const updatedWorkSpace = produce(activeWorkspace, (draft) => {
            if (draft) {
              draft.data = layout;
            }
          });

          onWorkSpaceUpdated(updatedWorkSpace);
          await updateWorkspaceAsync(updatedWorkSpace as Workspace);
        } catch (error) {
          console.error("Error updating workspace:", error);
        }
      } catch (error) {
        console.error("Failed to add widgets to panel:", error);
      }
    },
    [activeWorkspace, dockviewApi, onWorkSpaceUpdated, updateWorkspaceAsync],
  );

  const onClick = useCallback(
    (widgets: Widget[]) => {
      if (!dockviewApi) return;
      dockviewApi.clear();
      setShowWidgetSelectionDialog(false);
      widgets = JSON.parse(JSON.stringify(widgets));
      addWidgetsToPanel(widgets);
    },
    [addWidgetsToPanel, dockviewApi],
  );

  const onAddWidgetClick = useCallback(() => {
    setShowWidgetSelectionDialog(true);
  }, []);

  useEffect(() => {
    const updateWorkspace = async () => {
      if (!dockviewApi || !newWidgetToAddToPanel) return;

      const handleAddPanelAndUpdate = async () => {
        console.log("newWidgetToAddToPanel!!", newWidgetToAddToPanel);
        if (hasValidLayout) {
          dockviewApi.clear();
          try {
            addPanel(dockviewApi, newWidgetToAddToPanel, activeWorkspace);
            const layout: SerializedDockview = dockviewApi.toJSON();
            const updatedWorkSpace = produce(activeWorkspace, (draft) => {
              if (draft) {
                draft.data = layout;
              }
            });

            onWorkSpaceUpdated(updatedWorkSpace);
            await updateWorkspaceAsync(updatedWorkSpace as Workspace);
          } catch (error) {
            console.error("Error updating workspace:", error);
          }
        } else {
          addPanel(dockviewApi, newWidgetToAddToPanel, activeWorkspace);
          onWidgetsAdded();
        }
      };
      onNewWidgetProcessed();
      await handleAddPanelAndUpdate();
    };

    updateWorkspace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeWorkspace,
    dockviewApi,
    newWidgetToAddToPanel,
    onWidgetsAdded,
    hasValidLayout,
    onWorkSpaceUpdated,
    updateWorkspaceAsync,
    dispatch,
  ]);

  const openTradeTicket = (widgetValue: Widget) => {
    const tradeTicketPanel = dockviewApi?.panels?.find((panel) => panel?._title === "Trade");

    if (tradeTicketPanel) {
      tradeTicketPanel.api.close();
    }
    if (dockviewApi && widgetValue) {
      addTradingTicketPanel(dockviewApi, widgetValue, activeWorkspace, dockviewRef);
    }
  };

  useEffect(() => {
    const tradeTicketPanel = dockviewApi?.panels?.find((panel) => panel?._title === "Trade");
    const ticketInstrument =  activeWorkspace.id && worspacesReduxData[activeWorkspace.id]?.ticketInstrument;
    if(tradeTicketPanel && !ticketInstrument) {
      dockviewApi?.removePanel(tradeTicketPanel)
    }
  },[activeWorkspace.id, dockviewApi, worspacesReduxData])


  useEffect(() => {
    openTradeTicket(tradeTicketWidgetValue);
  }, [tradeTicketWidgetValue]);
  useEffect(() => {
    if (!dockviewApi || !chartWidgetValue) return;
    addChartPanel(dockviewApi, chartWidgetValue, activeWorkspace);
    dispatch(clearChartWidgetValue());
    onWidgetsAdded();
  }, [chartWidgetValue, activeWorkspace, dockviewApi, onWidgetsAdded, dispatch]);

  return (
    <>
      {workspaceLoading ? (
        <div className="dockview-workspace-loader">
          <LoadingSpinner />
        </div>
      ) : hasValidLayout ? (
        <EmptyWorkspace
          onAddWidgetClick={onAddWidgetClick}
          showWidgetSelectionDialog={showWidgetSelectionDialog}
          onWidgetSelection={onClick}
          setShowWidgetSelectionDialog={setShowWidgetSelectionDialog}
        />
      ) : (
        <DockviewContent onReady={onReady} dockviewRef={dockviewRef} />
      )}
    </>
  );
};

export default Dockview;
