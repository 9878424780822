const ChartIcon = () => {
  return (
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.66659 4.50016H0.333252V13.6668H3.66659V4.50016Z" fill="#006EB6"/>
<path d="M13.6666 7.8335H10.3333V13.6668H13.6666V7.8335Z" fill="#006EB6"/>
<path d="M8.66658 0.333496H5.33325V13.6668H8.66658V0.333496Z" fill="#006EB6"/>
</svg>

  )
};

export default ChartIcon;
