export const tradeTicketHeader = () => {
  const title = document.createElement('h4');
  title.textContent = 'Trade';
  title.style.margin = '0px';
  title.style.marginLeft = '5px';


  // Create the SVG element
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('width', '16');
  svg.setAttribute('height', '16');
  svg.setAttribute('viewBox', '0 0 16 16');
  svg.setAttribute('fill', 'none');
  svg.style.marginTop = '1px';

  // Create the path element inside the SVG
  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttribute('d', 'M4.66 7.3335L2 10.0002L4.66 12.6668V10.6668H9.33333V9.3335H4.66V7.3335ZM14 6.00016L11.34 3.3335V5.3335H6.66667V6.66683H11.34V8.66683L14 6.00016Z');
  path.setAttribute('fill', '#081D37');

  // Append the path to the SVG
  svg.appendChild(path);

  // Append the SVG to the div
  
  
  
  const imageContainer = document.createElement('div');
  imageContainer.appendChild(svg);
  imageContainer.style.display = 'flex';
  imageContainer.style.position = 'absolute';
  imageContainer.style.justifyContent = 'center';
  imageContainer.style.alignContent = 'center';
  imageContainer.style.left = '10px';
  imageContainer.style.top = '7px';

  imageContainer.appendChild(title);

  return imageContainer;
};

export const disableResize = (container: Element) => {
  const resizeHandle = container.parentElement?.parentElement;
  const topResizeHandler = resizeHandle?.querySelector('.dv-resize-handle-top');
  topResizeHandler.style.cssText = 'display: none !important';
  const rightResizeHandler = resizeHandle?.querySelector('.dv-resize-handle-right');
  rightResizeHandler.style.cssText = 'display: none !important';
  const bottomResizeHandler = resizeHandle?.querySelector('.dv-resize-handle-bottom');
  bottomResizeHandler.style.cssText = 'display: none !important';
  const leftResizeHandler = resizeHandle?.querySelector('.dv-resize-handle-left');
  leftResizeHandler.style.cssText = 'display: none !important';
  const topLeftResizeHandler = resizeHandle?.querySelector('.dv-resize-handle-topleft');
  topLeftResizeHandler.style.cssText = 'display: none !important';
  const topRightResizeHandler = resizeHandle?.querySelector('.dv-resize-handle-topright');
  topRightResizeHandler.style.cssText = 'display: none !important';
  const bottomRightResizeHandler = resizeHandle?.querySelector('.dv-resize-handle-bottomright');
  bottomRightResizeHandler.style.cssText = 'display: none !important';
  const bottomLeftResizeHandler = resizeHandle?.querySelector('.dv-resize-handle-bottomleft');
  bottomLeftResizeHandler.style.cssText = 'display: none !important';
}