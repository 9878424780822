import { CQGConstants } from "../constants/CQGConstants";
import * as Order2 from "../proto/order_2";
import { CQGEnvironment } from "../services/CQGEnvironment";

export const makeOrder = (position: any) => {
  console.log('position.side', position.side);

  let orderData = {
    type: CQGConstants.getOrderTypeByString(position.orderType),
    displayOrderLimitPrice: position.rawLimitPrice,
    displayOrderStopPrice: position.rawStopPrice,
    duration: position.timeInForce,
    side: position.side === "BUY" ? 1 : 2,
    size: Math.abs( position.size ),
    goodThruDate: new Date(),
    profitLossStrategy: position.profitLossStrategy,
    putCall: position.putCall,
    strikePrice: position.strikePrice,
  };

  var order = CQGEnvironment.Instance.ordersManager.createOrder( 
    position.account,
    position.instrument,
    orderData
  );

  return order;
}