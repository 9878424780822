import { Instrument } from "../../../cqg-api/models/Instrument";

type SelectedContractInfoProps = {
  selectedContract?: Instrument;
}
const SelectedContractInfo = ({ selectedContract }: SelectedContractInfoProps) => {
  return (
    <table cellPadding={0} cellSpacing={0} className='contractDataTable'>
      <thead>
        <tr>
          <th>Bid</th>
          <th>Ask</th>
          <th>Los</th>
          <th>Net Chg</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{selectedContract?.labelBid?.toFixed(2)}</td>
          <td>{selectedContract?.labelAsk?.toFixed(2)}</td>
          <td>{selectedContract?.labelLow?.toFixed(2)}</td>
          <td>{selectedContract?.labelPriceNetChange?.toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default SelectedContractInfo;
