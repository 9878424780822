import { WidgetType } from "../../types";

const TreeViewContainerButtonTitle = ({
  widgetType,
  isWatchlistEditMode,
}: {
  widgetType: WidgetType | undefined;
  isWatchlistEditMode?: boolean;
}) => {
  switch (widgetType) {
    case WidgetType.Watchlist:
      if (isWatchlistEditMode) {
        return "UPDATE WATCHLIST";
      }
      return "ADD WATCHLIST";
    case WidgetType.Chart:
      return "ADD CHART";
    case WidgetType.PriceLadder:
      return "ADD PRICE LADDER";
    case WidgetType.Options:
      return "ADD CONTRACT";
    default:
      return "";
  }
};

export default TreeViewContainerButtonTitle;
