import { useMediaQuery } from "@mui/material";
import { CMELogoIcon } from "../../images/Icons";
import { useTheme } from "@mui/material/styles";
import mobile from "is-mobile";

const Logo = () => {
    const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm")) || mobile();

  return (
    <div className={ isMobileView ? "logo-mobile" : "logo"}>
      {!isMobileView ? (
        <img src="/images/icons/cmeLogo.png" alt="Logo" width={"180px"} height={"65px"} />
      ) : (
        <CMELogoIcon />
      )}
    </div>
  );
};

export default Logo;
