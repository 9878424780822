import { useEffect, useMemo, useRef, useState } from "react";
import * as TradeRouting from "../proto/trade_routing_2";
import * as Order2 from "../proto/order_2";

import { CQGEnvironment } from "../services/CQGEnvironment";
import { Instrument } from "../models/Instrument";
import { OrderState } from "../models/OrderState";
import { CQGServiceMessageManager } from "../message-managers/ServiceMessageManager";
import { PositionsController } from "../controllers/PositionsController";
import { AggregatedPosition } from "../models/AggregatedPosition";
import { isArrayWithValues } from "../utils/lib";
import { AppMessageInfo, AppMessages, AppMessageSubTypes, AppMessageTypes } from "../utils/AppMessages";
import { OrderStatus_Status } from "../proto/common/shared_1";
import * as _ from "../../vendors/underscore-esm";
import * as MarketData from "../proto/market_data_2";
import { CQGService } from "../services/CQGService";


export const useCollateralStatus = () => {
  const [cqgEnv] = useState(CQGEnvironment.Instance);
  const [collateralStatus, setCollateralStatus] = useState<TradeRouting.CollateralStatus[]>([]);

  useEffect(() => {
    const collateralStatusesDataReceived = (collateralStatus: TradeRouting.CollateralStatus[]) => {
      setCollateralStatus(collateralStatus);
    };

    cqgEnv.accountSummariesManager?.onCollateralStatusesDataReceived(collateralStatusesDataReceived);

    return () => {
      cqgEnv.accountSummariesManager?.offCollateralStatusesDataReceived(collateralStatusesDataReceived);
    };
  }, [cqgEnv]);

  return { collateralStatus };
};

export const useRealTimeMarketData = () => {
  const [cqgEnv] = useState(CQGEnvironment.Instance);
  const [realTimeMarketData, setRealTimeMarketData] = useState<Instrument[]>([]);

  useEffect(() => {
    let cache: Instrument[] = [];

    // Market updates are too frequent, we need to throttle them
    const timer = setInterval(() => {
      setRealTimeMarketData(cache);
    }, 750);

    const realTimeMarketDataReceived = (instruments: Instrument[]) => {
      let map = new Map<number, Instrument>();

      cache.forEach((inst) => inst.contractId && map.set(inst.contractId, inst));
      instruments.forEach((inst) => inst.contractId && map.set(inst.contractId, inst));
      cache = Array.from(map.values());

      // setRealTimeMarketData(instruments);
    };

    cqgEnv.serviceMessageEventManager.onRealTimeMarketData(realTimeMarketDataReceived);

    return () => {
      clearInterval(timer);
      cqgEnv.serviceMessageEventManager.offRealTimeMarketData(realTimeMarketDataReceived);
    };
  }, [cqgEnv]);

  return { realTimeMarketData };
};

export const useOptionsData = (optionMaturityId: string) => {
  const [cqgEnv] = useState(CQGEnvironment.Instance);
  const [realTimeOptionsData, setRealTimeOptionsData] = useState<MarketData.OptionCalculationReport[]>([]);

  useEffect(() => {
    if(optionMaturityId) {
      CQGService.optionCalculationRequest(optionMaturityId);
    }
  },[optionMaturityId])

  useEffect(() => {
    let cache: MarketData.OptionCalculationReport[] = [];

    // Market updates are too frequent, we need to throttle them
    const timer = setInterval(() => {
      setRealTimeOptionsData(cache);
    }, 500);

    const realTimeOptionsDataReceived = (options: MarketData.OptionCalculationReport[]) => {
      cache=options;
    };

    cqgEnv.serviceMessageEventManager.onOptionCalculationReports(realTimeOptionsDataReceived);

    return () => {
      clearInterval(timer);
      cqgEnv.serviceMessageEventManager.offOptionCalculationReports(realTimeOptionsDataReceived);
    };
  }, [cqgEnv]);

  return { realTimeOptionsData };
}

export const useMessageSender = () => {
  const [cqgEnv] = useState(CQGEnvironment.Instance);
  const [messageManager] = useState(cqgEnv.serviceMessageManager);

  const nextRequestId = () => {
    return CQGServiceMessageManager.nextRequestId();
  };

  return { nextRequestId, messageManager };
};

export const useOrdersList = () => {
  const [cqgEnv] = useState(CQGEnvironment.Instance);
  const [ordersList, setOrdersList] = useState<OrderState[]>([]);
  const ordersRef = useRef(ordersList);

  useEffect(() => {
    let cacheMap = new Map<string, OrderState>();

    const ordersReceived = (update: Order2.OrderStatus | null, orderToUpdate: OrderState) => {
      cacheMap.set(orderToUpdate.chainOrderId!, orderToUpdate);
    };

    const updateOrdersReceived = () => {
      if (cacheMap.size > 0) {
        const ordersMap = new Map<string, OrderState>();

        ordersRef.current.forEach((order) => {
          ordersMap.set(order.chainOrderId!, order);
        });

        cacheMap.forEach((order, key) => {
          ordersMap.set(key, order);
        });

        const orders = Array.from(ordersMap.values());

        setOrdersList(_.sortBy(orders, (order: OrderState) => 
          -(order?.statusTime?.getTime() ?? 0)
        ));
        ordersRef.current = orders;
        cacheMap.clear();
        ordersMap.clear();
      }
    };

    let cacheAllOrders = () => {
      const userOrders = cqgEnv.ordersManager?.getOrders();
      let allOrders = [];

      for (let ordId in userOrders) {
        let order = userOrders[ordId];
        allOrders.push(order);
      }

      setOrdersList(_.sortBy(allOrders, (order: OrderState) => 
        -(order?.statusTime?.getTime() ?? 0)
      ));
      ordersRef.current = allOrders;
    };
    
    const timer = setInterval(() => {
      if (cacheMap.size === 0) {
        return;
      }

      cacheAllOrders();
      updateOrdersReceived();
    }, 700);

    cqgEnv.ordersManager?.onOrderUpdated(ordersReceived);
    cacheAllOrders();

    return () => {
      clearInterval(timer);
      cqgEnv.ordersManager?.offOrderUpdated(ordersReceived);
    };
  }, [cqgEnv]);

  return { ordersList };
};

export const usePositionsList = () => {
  const [cqgEnv] = useState(CQGEnvironment.Instance);
  const [positionsList, setPositionList] = useState<AggregatedPosition[]>([]);

  useEffect(() => {
    const onAccountPositions = () => {
      if (!cqgEnv.accountsManager) {
        return;
      }
      const account = cqgEnv.accountsManager?.getAccount(CQGEnvironment.cqgAccountAuthInfo?.accountId!);
      const accountPositions = cqgEnv.accountsManager
        ?.getAccount(CQGEnvironment.cqgAccountAuthInfo?.accountId!)
        ?.getPositions();

      if (!isArrayWithValues(accountPositions)) return;

      cqgEnv.accountSummariesManager.calcAccountSummary(account);

      const aggregatedPositions: AggregatedPosition[] = PositionsController.getAggregatedPositions(
        account,
        accountPositions,
      );

      // console.log("Aggregated Positions: ", aggregatedPositions);

      setPositionList(aggregatedPositions);
    };

    const timer = setInterval(onAccountPositions, 1000);

    cqgEnv.appMessageManager.onAccountPositionsChange(onAccountPositions);

    return () => {
      clearInterval(timer);
      cqgEnv.appMessageManager.offAccountPositionsChange(onAccountPositions);
    };
  }, [cqgEnv]);

  return { positionsList };
};

export const useOrderUpdates = () => {
  const [cqgEnv] = useState(CQGEnvironment.Instance);

  useEffect(() => {
    const ordersReceived = (update: Order2.OrderStatus | null, orderToUpdate: OrderState) => {
      if (!update?.isSnapshot) {
        if (update?.status === OrderStatus_Status.FILLED) {
          AppMessages.publish(
            new AppMessageInfo(AppMessageTypes.ORDER_FILLED, AppMessageSubTypes.UNDEFINED, orderToUpdate),
          );
        }

        if (update?.status === OrderStatus_Status.CANCELLED) {
          AppMessages.publish(
            new AppMessageInfo(AppMessageTypes.ORDER_CANCELLED, AppMessageSubTypes.UNDEFINED, orderToUpdate),
          );
        }

        if (update?.status === OrderStatus_Status.IN_MODIFY) {
          const order = AppMessages.workingOrders.getWorkingOrder(orderToUpdate.orderId!);
          AppMessages.publish(new AppMessageInfo(AppMessageTypes.ORDER_AMENDED, AppMessageSubTypes.UNDEFINED, order || orderToUpdate));
        }

        if (!!update?.rejectMessage && update?.rejectMessage?.length > 0) {
          AppMessages.publish(
            new AppMessageInfo(AppMessageTypes.ORDER_REJECTED, AppMessageSubTypes.UNDEFINED, orderToUpdate),
          );
        }
      }
    };

    cqgEnv.ordersManager?.onOrderUpdated(ordersReceived);

    return () => {
      cqgEnv.ordersManager?.offOrderUpdated(ordersReceived);
    };
  }, [cqgEnv]);
};

export const useAppMessages = () => {
  const [appMessage, setAppMessage] = useState<AppMessageInfo>();
  const setMessageHandled = useMemo(() => AppMessages.setMessageHandled, []);
  useEffect(() => {
    const onMessage = (message: AppMessageInfo) => {
      setAppMessage(message);
    };

    AppMessages.subscribe(onMessage, onMessage);

    return () => {
      AppMessages.unsubscribe(onMessage);
    };
  }, []);

  return { appMessage, setMessageHandled };
};
