import * as Order2 from "../proto/order_2";
import * as TradeRouting from "../proto/trade_routing_2";
import { Account } from "../models/Account";
import { AccountsManager } from "./AccountsManager";
import { TradeSubscriptionsManager } from "./TradeSubscriptionsManager";
import { OrderState } from "../models/OrderState";
import { Instrument } from "../models/Instrument";
import { InstrumentsManager } from "./InstrumentsManager";
import { ContractMetadata } from "../proto/metadata_2";
import { arrUniq } from "../utils/ArrayUtil";
import { CQGConstants } from "../constants/CQGConstants";
import { UserAlerts } from "./UserAlerts";
import { CQGEnvironment } from "./CQGEnvironment";
import EventEmitter from "events";
import { TradeSubscriptionParams } from "../models/TradeSubscriptionParams";
import { CQGServiceMessageEventManager } from "../message-managers/ServiceMessageEventManager";

export class OrdersManager {
  private orders: { [key: string]: OrderState } = {}; //dictionary<chainOrderId,orderState>
  private inClientOrders: { [key: string]: OrderState } = {}; //dictionary<clOrderId,orderState>
  private tempSnapshotData: { [key: number]: Order2.OrderStatus[] } = {}; //dictionary<subscriptionId,orderStatus[]>
  private pendingOrderRequests: { [key: number]: OrderState } = {}; //dictionary<requestId,orderState>
  private profitLossOrders = [];
  private orderUpdated = new EventEmitter();
  private accountsManager: AccountsManager;
  private tradeSubscriptionsManager: TradeSubscriptionsManager;
  private serviceMessageEventManager: CQGServiceMessageEventManager;

  constructor(
    accountsManager: AccountsManager,
    tradeSubscriptionsManager: TradeSubscriptionsManager,
    serviceMessageEventManager: CQGServiceMessageEventManager,
  ) {
    this.accountsManager = accountsManager;
    this.tradeSubscriptionsManager = tradeSubscriptionsManager;
    this.serviceMessageEventManager = serviceMessageEventManager;
    
    this.serviceMessageEventManager.onOrderStatuses(this.processOrderStatusUpdates);
    this.serviceMessageEventManager.onTradeSnapshotCompletions(this.processTradeSnapshotCompletion);
  }

  static subscribeToAccountOrders = (accountId: number, requestId: number) => {
    console.log("Subscribing to orders ... Account: ", accountId);
    let params = new TradeSubscriptionParams(requestId, accountId);
    params.accountId = accountId;
    params.publicationType = TradeRouting.TradeSubscription_PublicationType.PUBLICATION_TYPE_ACCOUNTS;
    params.skipOrdersSnapshot = false;

    CQGEnvironment.Instance.ordersManager?.subscribe(params);
  };

  public onOrderUpdated(listnerFn: (update: Order2.OrderStatus | null, orderToUpdate: OrderState) => void) {
    this.orderUpdated.on("orderUpdated", listnerFn);
    console.log("ON - OrderUpdated Listeners Count: " , this.orderUpdated.listenerCount("orderUpdated"));
  }

  public offOrderUpdated(listnerFn: (update: Order2.OrderStatus | null, orderToUpdate: OrderState) => void) {
    this.orderUpdated.off("orderUpdated", listnerFn);
    console.debug("OFF - OrderUpdated Listeners Count: " , this.orderUpdated.listenerCount("orderUpdated"));
  }

  public onOrderUpdatedEmit = (update: Order2.OrderStatus | null, orderToUpdate: OrderState) => {
    this.orderUpdated.emit("orderUpdated", update, orderToUpdate);
  };

  // public onTradeSnapshotCompleted = (listnerFn: any) => {
  //   this.tradeSnapshotCompletedEvent.on("tradeSnapshotCompleted", listnerFn);
  // };

  // private onTradeSnapshotCompletedEmit = (orders: any) => {
  //   this.tradeSnapshotCompletedEvent.emit("tradeSnapshotCompleted", orders);
  // };

  subscribe = (params: TradeSubscriptionParams) => {
    let account: Account | null = null;

    if (params.accountId != null) {
      account = this.accountsManager.getAccount(params.accountId);
      if (!account || account.orderDataRequested) {
        return;
      }
    }

    let subscriptionParams = new TradeSubscriptionParams(params.id, params.accountId);
    subscriptionParams.subscriptionScope = [TradeRouting.TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_ORDERS];
    subscriptionParams.publicationType = params.publicationType;
    subscriptionParams.skipOrdersSnapshot = params.skipOrdersSnapshot;

    this.tradeSubscriptionsManager.subscribe(subscriptionParams);

    if (account) {
      account.orderDataRequested = true;
    }
  };

  getOrders = () => {
    return this.orders;
  };

  newOrder = (order: OrderState, account: Account, requestId: number) => {
    this.pendingOrderRequests[requestId] = order;
    this.inClientOrders[order.clientOrderId!] = order;
    account.getOrders().push(order);
  };

  static createOrder = (account: Account, instrument: Instrument, orderData: OrderState) => {
    var order = new OrderState(account, instrument);
    order.assign(orderData);

    return order;
  };

  processOrderStatusUpdates = (updates: Order2.OrderStatus[]) => {
    updates.forEach((update) => {
      this.processOrderStatus(update);
    });
  };

  processTradeSnapshotCompletion = (tradeSnapshotCompletion: TradeRouting.TradeSnapshotCompletion[]) => {
    tradeSnapshotCompletion.forEach((item) => {
      this.processTradeSnapshotCompletionItem(item);
    });
  };

  processOrderRequestReject = (orderRequestReject: Order2.OrderRequestReject[]) => {
    orderRequestReject.forEach((item) => {
      this.processOrderRequestRejectItem(item);
    });
  };

  processOrderStatus = (update: Order2.OrderStatus) => {
    update.contractMetadata.forEach((contractMetadata: ContractMetadata) => {
      InstrumentsManager.addOrUpdate(contractMetadata);
    });
    if (update.isSnapshot) {
      this.processOrderStatusSnapshot(update);
    } else {
      this.applyOrdersStatusUpdates([update]);
    }
  };

  processOrderStatusSnapshot = (orderStatus: Order2.OrderStatus) => {
    orderStatus.subscriptionIds.forEach((subscriptionId) => {
      var pendingOrdersBySubscription = this.tempSnapshotData[subscriptionId];
      if (!pendingOrdersBySubscription) {
        pendingOrdersBySubscription = [];
        this.tempSnapshotData[subscriptionId] = pendingOrdersBySubscription;
      }
      pendingOrdersBySubscription.push(orderStatus);
    });

    // console.log("OrdersManager:processOrderStatusSnapshot Pending Orders", this.tempSnapshotData);
  };

  clearOrders = (updates: Order2.OrderStatus[]) => {
    let uniqUpdates = arrUniq(updates, (orderStatus) => {
      return orderStatus.order?.accountId;
    });

    var updateAccounts = uniqUpdates.map((orderStatus) => {
      return this.accountsManager.getAccount(orderStatus.order?.accountId!);
    });

    // TODO: To be reviewed
    // this.orders = arrOmit(this.orders, (order) => {
    //   return updateAccounts.indexOf(order.getAccount()) >= 0;
    // });

    updateAccounts.forEach((account) => {
      account.clearOrders();
    });
  };

  applyOrdersStatusUpdates = (updates: Order2.OrderStatus[]) => {
    if (!Array.isArray(updates)) {
      updates = [updates];
    }

    var newOrders: { [key: number]: OrderState[] } = {};
    updates.forEach((update) => {
      var orderToUpdate = this.orders[update.chainOrderId];
      if (!orderToUpdate) {
        //initial order update must contain order info
        if (!update.order) {
          return;
        }

        orderToUpdate = this.inClientOrders[update.order.clOrderId];
        if (orderToUpdate) {
          delete this.inClientOrders[update.order.clOrderId];

          for (let key in this.pendingOrderRequests) {
            if (this.pendingOrderRequests[key] === orderToUpdate) {
              delete this.pendingOrderRequests[key];
              break;
            }
          }

          this.orders[update.chainOrderId] = orderToUpdate;
        } else {
          var account = this.accountsManager.getAccount(update.order.accountId);
          if (!account) {
            return;
          }
          var instrument = InstrumentsManager.getInstrument(update.order.contractId);
          if (!instrument) {
            return;
          }

          orderToUpdate = new OrderState(account, instrument);
          this.orders[update.chainOrderId] = orderToUpdate;

          var accountNewOrders = newOrders[account.id];
          if (!accountNewOrders) {
            accountNewOrders = [];
            newOrders[account.id] = accountNewOrders;
          }
          accountNewOrders.push(orderToUpdate);
        }
      }

      // console.log("OrdersManager:processOrderStatusSnapshot Orders", this.orders);

      orderToUpdate.update(update);

      // TODO: fillReport.processOrder(orderToUpdate);
      this.processOrderReject(orderToUpdate);

      //TODO: implement chunked updates

      // Notify listeners about order
      this.onOrderUpdatedEmit(update, orderToUpdate);
    });

    for (let key in newOrders) {
      var account = this.accountsManager.getAccount(key);
      account.addOrders(newOrders[key]);
    }
  };

  processTradeSnapshotCompletionItem = (tradeSnapshotCompletion: TradeRouting.TradeSnapshotCompletion) => {
    if (
      tradeSnapshotCompletion.subscriptionScopes.indexOf(
        TradeRouting.TradeSubscription_SubscriptionScope.SUBSCRIPTION_SCOPE_ORDERS,
      ) < 0
    ) {
      return;
    }

    var pendingOrdersBySubscription = this.tempSnapshotData[tradeSnapshotCompletion.subscriptionId];

    if (pendingOrdersBySubscription) {
      this.clearOrders(pendingOrdersBySubscription);
      this.applyOrdersStatusUpdates(pendingOrdersBySubscription);
      delete this.tempSnapshotData[tradeSnapshotCompletion.subscriptionId];
    }

    // This call must be signaled after the snapshot has been consumed.
    // cmeProfitLossOrdersManager subscribes to orders once the snapshot is complete
    // this.onTradeSnapshotCompletedEmit(orders);
  };

  formatRejectMessage = (msg: string) => {
    return (
      "Your order was rejected.<br/>" +
      "<strong>Reject reason:</strong><br/>" +
      '<span class="pre-wrap" style="white-space: pre-wrap;">' +
      msg +
      "</span>"
    );
  };

  processOrderRequestRejectItem = (orderRequestRejectItem: Order2.OrderRequestReject) => {
    var order = this.pendingOrderRequests[orderRequestRejectItem.requestId];
    if (order) {
      delete this.pendingOrderRequests[orderRequestRejectItem.requestId];
      delete this.inClientOrders[order.clientOrderId!];
      order.getAccount().removeOrder(order);
    }
    var msg = this.formatRejectMessage(order.displayRejectMessage!);
    UserAlerts.addAlert("Order Rejected", msg, CQGConstants.DialogType.ORDER_ALERT, {}, null);
  };

  orderRejectLastShowTime = new Date();

  processOrderReject = (order: OrderState) => {
    if (order.rejectMessage && order.rejectTime! > this.orderRejectLastShowTime) {
      var msg = this.formatRejectMessage(order.displayRejectMessage!);
      UserAlerts.addAlert("Order Rejected", msg, CQGConstants.DialogType.ORDER_ALERT, {}, null);
      this.orderRejectLastShowTime = order.rejectTime!;
    }
  };

  clear = () => {
    this.orders = {};
    this.inClientOrders = {};
    this.tempSnapshotData = {};
    this.pendingOrderRequests = {};
  };
}
