import { InstrumentMarketData, Product, Widget, WidgetType, Workspace } from "../../../types";
import "./AvailableProducts.css";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DataGridTable from "../../shared/DataGrid";
import { MapInstrumentMarketData2 } from "../../../utils/utils";
import { useRealTimeMarketData } from "../../../cqg-api/hooks/ServiceHooks";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { subscribeToContracts, unsubscribeContracts } from "../../../utils/subscriptions";
import { useDispatch } from "react-redux";
import PriceIcon from "./Price";
import ChartIcon from "./ChartIcon";
import SelectContract from "./SelectContract";
// import { Contract } from "./types";
import RenderColorCell from "./RenderColorCell";
import MarketAssets from "./MarketAssets";
import FilterProducts from "./FilterProducts";
import { setChartWidgetValue, setSelectedInstrument, setTradeTicketValue } from "../../../redux/products/chartWidget";
import { IconButton } from "@mui/material";
import useResponsivePanel from "../../../hooks/useResponsivePanel";
import workspacesActions from "../../../redux/workspaces/workspacesActions";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
import { AvailableProductsContext } from "../../../App";
import { ProductWithContracts } from "../../../hooks/api/types";

// need contract type
interface RowState {
  [key: string]: any;
}

function changeCell(rowState: RowState) {
  return (params: GridRenderCellParams) => {
    const stateOfRow = rowState[params.row.id] ?? params.row.selectedContract;
    const color = stateOfRow?.labelPriceNetChange < 0 ? "#C62828" : "#00695C";
    if (stateOfRow?.labelPriceNetChange) {
      return (
        <span
          style={{ color }}
        >{`${stateOfRow?.labelPriceNetChange?.toFixed(2)} (${stateOfRow?.labelPriceNetChangePercent?.toFixed(2)}%)`}</span>
      );
    }
    return <span></span>;
  };
}

const AvailableProducts = (props: { selectedWorkspace?: Workspace | null; workspaceClassName?: string }) => {
  const { realTimeMarketData } = useRealTimeMarketData();
  const [activeTab, setActiveTab] = useState("");
  const [rowState, setRowState] = useState<RowState>({});
  const { assetProductsDictionary, productsMapById } = useContext(AvailableProductsContext);
  const dispatch = useDispatch();
  const { panelRef, isSmallPanel } = useResponsivePanel(900);
  const { panelRef: filterPanelRef, isSmallPanel: isSmallPanelFilter } = useResponsivePanel(600);

  const renderChangeCell = changeCell(rowState);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedContract, setSelectedContract] = useState<any>();

  const handleRowStateChange = (id: string, contract: any) => {
    setRowState((prevState) => ({
      ...prevState,
      [id]: contract,
    }));
  };

  const openChartForInstrument = useCallback(
    (params: GridRenderCellParams) => {
      const { row } = params;
      const rowId = row.id;
      const stateOfRow = rowState[rowId];

      const selectedInstrument = stateOfRow
        ? row.monthlyContracts.find((contract: any) => contract.displayName === stateOfRow.displayName)
        : row.monthlyContracts[0];

      if (selectedInstrument) {
        const chartWidgetValue: Widget = {
          key: 3,
          title: WidgetType.Chart,
          widgetType: `${selectedInstrument.displayName} Chart`,
          contractIds: [selectedInstrument.displayName],
        };
        dispatch(setChartWidgetValue(chartWidgetValue));
      } else {
        console.log("Instrument not found");
      }
    },
    [rowState, dispatch],
  );

  const isMarketDataAvailable = (contract: Instrument) => {
    return !!contract.labelPrice;
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 140,
        sortable: true,
        headerClassName: "market-table--header",
      },
      {
        field: "monthlyContracts",
        headerName: "Contract",
        flex: 1,
        minWidth: 100,
        sortable: true,
        headerClassName: "market-table--header",
        renderCell: (params: GridRenderCellParams) => {
          const rowId = params.row.id;
          const stateValue = rowState[rowId as string] || params.value[0];
          return (
            <SelectContract
              selectedState={stateValue}
              id={rowId}
              contracts={params.value}
              onSelect={handleRowStateChange}
            />
          );
        },
      },
      {
        field: "lastPrice",
        headerName: "Last Price",
        flex: 1,
        minWidth: 75,
        sortable: true,
        renderCell: (params: GridRenderCellParams) => {
          const stateOfRow = rowState[params.row.id] ?? params.row.selectedContract;
          return <RenderColorCell value={DisplayUtil.toDisplayPrice(stateOfRow?.lastPrice, stateOfRow)} />;
        },
        headerClassName: "market-table--header",
      },
      {
        field: "change",
        headerName: "Change",
        flex: 1,
        minWidth: 75,
        sortable: true,
        renderCell: renderChangeCell,
        headerClassName: "market-table--header",
      },
      {
        field: "askQty",
        headerName: "Open",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "market-table--header",
        renderCell: (params: GridRenderCellParams) => {
          const stateOfRow = rowState[params.row.id] ?? params.row.selectedContract;
          return <span>{stateOfRow?.labelOpen}</span>;
        },
      },
      {
        field: "bidPrice",
        headerName: "High",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "market-table--header",
        renderCell: (params: GridRenderCellParams) => {
          const stateOfRow = rowState[params.row.id] ?? params.row.selectedContract;
          return <span>{stateOfRow?.labelHigh}</span>;
        },
      },
      {
        field: "bidQty",
        headerName: "Low",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "market-table--header",
        renderCell: (params: GridRenderCellParams) => {
          const stateOfRow = rowState[params.row.id] ?? params.row.selectedContract;
          return <span>{stateOfRow?.labelLow}</span>;
        },
      },
      {
        field: "labelTotalVolume",
        headerName: "Volume",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "market-table--header",
        renderCell: (params: GridRenderCellParams) => {
          const stateOfRow = rowState[params.row.id] ?? params.row.selectedContract;
          return <span>{stateOfRow?.labelTotalVolume}</span>;
        },
      },
      {
        field: "selected",
        headerName: "Actions",
        headerClassName: "market-table--header",
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const stateOfRow = rowState[params.row.id] ?? params.row.selectedContract;
          const enabled = stateOfRow ? isMarketDataAvailable(stateOfRow) : false;
          return (
            <span className="starIcon">
              <IconButton
                disabled={!enabled}
                sx={{
                  "&.Mui-disabled": {
                    opacity: 0.5,
                  },
                }}
              >
                <span
                  className="icon"
                  onClick={() => {
                    setSelectedContract(params.row.monthlyContracts);
                    const chartWidgetValue: Widget = {
                      key: 3,
                      title: WidgetType.TradeTicket,
                      widgetType: "Sarab Chart",
                    };
                    dispatch(
                      workspacesActions.dispatchSetWorkspace({
                        workspaceId: props?.selectedWorkspace?.id,
                        instrument: rowState[params.row.id] ?? params.row.monthlyContracts[0],
                      }),
                    );
                    dispatch(setTradeTicketValue(chartWidgetValue));
                    dispatch(
                      setSelectedInstrument({
                        instrument: params.row.monthlyContracts,
                        selected: rowState[params.row.id] ?? params.row.monthlyContracts[0],
                      }),
                    );
                    setOpenDrawer(true);
                  }}
                >
                  <PriceIcon />
                </span>
              </IconButton>
              <IconButton onClick={() => openChartForInstrument(params)}>
                <ChartIcon />
              </IconButton>
              <IconButton></IconButton>
            </span>
          );
        },
      },
    ],
    [rowState, renderChangeCell, openChartForInstrument],
  );

  const productsAsPerChoosenAsset = useMemo(() => {
    const products = activeTab ? assetProductsDictionary?.[activeTab] : Object.values(productsMapById);
    return products.map((product: ProductWithContracts) => ({
      ...product,
      selectedContract: product.monthlyContracts?.[0],
    }));
  }, [activeTab, productsMapById, assetProductsDictionary]);

  const [gridData, setGridData] = useState<InstrumentMarketData[]>([]);

  useEffect(() => {
    if (assetProductsDictionary && Object.keys(assetProductsDictionary).length > 0) {
      setActiveTab(Object.keys(assetProductsDictionary)[0]);
    }
  }, [assetProductsDictionary]);

  useEffect(() => {
    const newWatchlistData: InstrumentMarketData[] = [];
    productsAsPerChoosenAsset.forEach(function (data: Product) {
      let index = gridData?.findIndex((product: Product) => product.id === data.id);
      if (index !== -1) {
        newWatchlistData.push(gridData[index]);
      } else {
        newWatchlistData.push({ ...data });
      }
    });
    setGridData(newWatchlistData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAsPerChoosenAsset]);

  useEffect(() => {
    if (gridData?.length) {
      setGridData(MapInstrumentMarketData2(gridData, realTimeMarketData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData]);

  useEffect(() => {
    const contracts = productsAsPerChoosenAsset
      .map((product: ProductWithContracts) => product.monthlyContracts?.[0])
      .flat()
      .filter((instrument) => !!instrument)
      .map((instrument: any) => instrument.contractId);

    subscribeToContracts(contracts);
    /*  subscribeToInstruments(productsAsPerChoosenAsset).then((symbols) => {
      resolvedInstruments = symbols;
    }); */
    return () => {
      unsubscribeContracts(contracts);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAsPerChoosenAsset]);

  const selectMarket = (id: string) => {
    setActiveTab(id);
  };
  const [selectedFilter, setSelectedFilter] = useState(1);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getNoDataMessage = useMemo(() => {
    return "No data available";
  }, []);

  return (
    <div ref={panelRef} className="widget-parent-container">
      <div className="available-product-container draggableCancelSelector widget-grid-container">
        <div className="available-product-left-panel" ref={filterPanelRef}>
          <MarketAssets
            activeTab={activeTab}
            assetProductsDictionary={assetProductsDictionary}
            selectMarket={selectMarket}
            isSmallPanel={isSmallPanel}
          />
          <FilterProducts
            selectedAssetType={selectedFilter.toString()}
            handleFilterIconClick={handleFilterIconClick}
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            setSelectedFilter={setSelectedFilter}
            isSmallPanelFilter={isSmallPanelFilter}
          />
        </div>
        <div className="available-product-right-panel">
          <DataGridTable columns={columns} rows={gridData} noDataMessage={getNoDataMessage} />
        </div>
      </div>
      {/* {true && <TradeTicketDrawer open={openDrawer} setOpen={setOpenDrawer} contracts={selectedContract} />} */}
    </div>
  );
};

export default AvailableProducts;
