import { Instrument } from "../../../../cqg-api/models/Instrument";
import { OrderSide, OrderType } from "../../../../types";
import { askOrBid, Depth, StopLoss, TakeProfit } from "../types";
import clsx from "clsx";
import OrderCell from "./OrderCell";
import { OrderState } from "../../../../cqg-api/models/OrderState";
import { Fragment, useEffect, useMemo, useState } from "react";
import {
  calculateVolumePercentage,
  convertToKFormat,
  createPriceLookup,
  createPriceSeries,
  findMax,
  generateDepth,
  getDecimalPlaces,
  updatePriceSeries,
} from "../utils";
import useVisibleRows from "../hooks/useVisibleRows";

interface LadderTableProps {
  priceLadderBookRef: React.MutableRefObject<any>;
  instrumentWithMarketData: Instrument;
  centerDivRef: React.MutableRefObject<any>;
  quantityValue: number;
  onDragStart: (depth: Depth, index: number) => void;
  onDrop: (depth: Depth, orderSide: OrderSide) => void;
  createOrderHandlerClick: (orderSide: OrderSide, orderType: OrderType, price: number | null) => void;
  onCancelClick: (orders: OrderState[]) => void;
  oco: {
    BUY: {
      takeProfit: TakeProfit;
      stopLoss: StopLoss;
    };
    SELL: {
      takeProfit: TakeProfit;
      stopLoss: StopLoss;
    };
  };
  contract: Instrument;
}

const LadderTable = ({
  priceLadderBookRef,
  instrumentWithMarketData,
  centerDivRef,
  quantityValue,
  onDragStart,
  onDrop,
  createOrderHandlerClick,
  onCancelClick,
  oco,
  contract,
}: LadderTableProps) => {
  const [hoveredMouseDepth, setHoveredMouseDepth] = useState<(Depth & { orderSide: OrderSide }) | null>(null);
  const tickSize = useMemo(() => instrumentWithMarketData?.tickSize, [instrumentWithMarketData?.tickSize]);
  const ladderSeriesTotalCount = 300;
  const decimals = getDecimalPlaces(tickSize);

  const [lastPrice, setLastPrice] = useState<number | null>(null);

  useEffect(() => {
    setLastPrice(null);
  }, [instrumentWithMarketData?.displayName]);

  useEffect(() => {
    if (!lastPrice)
      setLastPrice(
        instrumentWithMarketData?.labelPrice || instrumentWithMarketData?.bestBid || instrumentWithMarketData?.bestAsk,
      );
  }, [
    lastPrice,
    instrumentWithMarketData?.bestAsk,
    instrumentWithMarketData?.bestBid,
    instrumentWithMarketData?.labelPrice,
    instrumentWithMarketData?.displayName,
  ]);

  // Update bids
  const priceSeries = useMemo(
    () => createPriceSeries(lastPrice, tickSize, ladderSeriesTotalCount),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastPrice, tickSize, instrumentWithMarketData?.displayName],
  );

  const isMinLengthGreaterThanZero =
    Math.min(instrumentWithMarketData?.asks?.length, instrumentWithMarketData?.bids?.length) > 0;

  useEffect(() => {
    if (centerDivRef.current && isMinLengthGreaterThanZero) {
      setTimeout(() => {
        centerDivRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }, 200);
    }
    if (centerDivRef.current && instrumentWithMarketData.contractId && instrumentWithMarketData.displayName) {
      centerDivRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [
    centerDivRef,
    isMinLengthGreaterThanZero,
    priceSeries,
    instrumentWithMarketData.contractId,
    instrumentWithMarketData.displayName,
  ]);

  const bidPriceLookup = useMemo(
    () => createPriceLookup(instrumentWithMarketData?.bids, decimals),
    [decimals, instrumentWithMarketData?.bids],
  );
  const updatedBidsArray = useMemo(
    () => updatePriceSeries(priceSeries, bidPriceLookup, decimals, askOrBid.bid),
    [priceSeries, bidPriceLookup, decimals],
  );

  // Update asks
  const askPriceLookup = useMemo(
    () => createPriceLookup(instrumentWithMarketData?.asks, decimals),
    [decimals, instrumentWithMarketData?.asks],
  );
  const updatedPrices = useMemo(
    () => updatePriceSeries(updatedBidsArray, askPriceLookup, decimals, askOrBid.ask),
    [updatedBidsArray, askPriceLookup, decimals],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const prices: Depth[] = useMemo(
    () => generateDepth(updatedPrices, instrumentWithMarketData.displayName),
    [updatedPrices, instrumentWithMarketData.displayName],
  );

  const firstBid = useMemo(() => prices?.find((item) => item.askBid === askOrBid.bid), [prices]);

  const instrumentLastPrice = instrumentWithMarketData?.labelPrice?.toFixed(decimals);

  useEffect(() => {
    const count = 100;
    const firstViewDisplayPrice = Number(priceSeries?.[0]?.displayPrice);
    const lastViewDisplayPrice = Number(priceSeries?.[priceSeries?.length - 1]?.displayPrice);

    const priceDifferenceFromFirst = Math.abs((Number(instrumentLastPrice) - firstViewDisplayPrice) / tickSize);
    const priceDifferenceFromLast = Math.abs((Number(instrumentLastPrice) - lastViewDisplayPrice) / tickSize);
    if (priceDifferenceFromLast < count || priceDifferenceFromFirst < count) {
      setLastPrice(null);
    }
  }, [instrumentLastPrice, priceSeries, tickSize]);

  const { visibleRows } = useVisibleRows(priceLadderBookRef);

  const onMouseOver = (depth: Depth, orderSide: OrderSide) => {
    setHoveredMouseDepth({ ...depth, orderSide });
  };

  const onMouseLeave = () => {
    setHoveredMouseDepth(null);
  };

  const maxAskVolume = useMemo(
    () => findMax(instrumentWithMarketData?.asks, "volume")?.volume,
    [instrumentWithMarketData?.asks],
  );
  const maxBidVolume = useMemo(
    () => findMax(instrumentWithMarketData?.bids, "volume")?.volume,
    [instrumentWithMarketData?.bids],
  );

  return (
    <div className="price-ladder-book-container" ref={priceLadderBookRef}>
      <table cellPadding={0} cellSpacing={0} className="price-ladder-book-table">
        <thead className="price-ladder-book-header">
          <tr>
            <th>Qty</th>
            <th className="align-right">Buy</th>
            <th className="price-width">Price</th>
            <th className="align-left">Sell</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>
          {prices &&
            [...prices].map((depth, index, depthList) => (
              <Fragment key={depth.price} >
                {firstBid?.displayPrice === depth.price && <tr className="price-ladder-ask-body"></tr>}
                <tr key={depth.price} data-key={depth.displayPrice}>
                  <OrderCell
                    depth={depth}
                    orderSide={OrderSide.Buy}
                    index={index}
                    quantityValue={quantityValue}
                    onDragStart={onDragStart}
                    onDrop={onDrop}
                    onClick={createOrderHandlerClick}
                    onCancelClick={onCancelClick}
                    hoveredMouseDepth={hoveredMouseDepth}
                    onMouseOver={onMouseOver}
                    onMouseLeave={onMouseLeave}
                    decimals={decimals}
                    ocoSettings={oco}
                    contract={contract}
                    visibleRows={visibleRows}
                    key={`${depth?.displayPrice}-Buy`}
                  />
                  <td className="price-ladder-book-buy">
                    {depth.askBid === askOrBid.bid && (
                      <>
                        <div
                          className={clsx({
                            "price-ladder-book--bg": true,
                            "price-ladder-book--bg-buy": firstBid?.displayPrice === depth.price,
                          })}
                          style={{
                            background: depth?.volume ? "#d8f0fa" : undefined,
                            width: calculateVolumePercentage(Number(depth?.volume), maxBidVolume),
                          }}
                        ></div>
                        <span className="price-ladder-book--volume">{convertToKFormat(depth?.volume)}</span>
                      </>
                    )}
                  </td>

                  <td
                    ref={depth?.displayPrice?.toString() === instrumentLastPrice ? centerDivRef : null}
                    className={clsx({
                      "price-ladder-book--last-price": depth?.displayPrice?.toString() === instrumentLastPrice,
                      "price-ladder-book--high-price":
                        depth?.displayPrice?.toString() === instrumentWithMarketData?.labelHigh?.toFixed(decimals),
                      "price-ladder-book--low-price":
                        depth?.displayPrice?.toString() === instrumentWithMarketData?.labelLow?.toFixed(decimals),
                    })}
                  >
                    {depth?.displayPrice}
                  </td>

                  <td className="price-ladder-book-sell">
                    {depth.askBid === askOrBid.ask && (
                      <>
                        <div
                          className="price-ladder-book--bg price-ladder-book--bg-sell"
                          style={{
                            background: depth?.volume ? "#ffdbe1" : undefined,
                            width: calculateVolumePercentage(Number(depth?.volume), maxAskVolume),
                          }}
                        ></div>
                        <span className="price-ladder-book--volume">{convertToKFormat(depth?.volume)}</span>
                      </>
                    )}
                  </td>
                  <OrderCell
                    depth={depth}
                    orderSide={OrderSide.Sell}
                    index={index}
                    quantityValue={quantityValue}
                    onDragStart={onDragStart}
                    onDrop={onDrop}
                    onClick={createOrderHandlerClick}
                    onCancelClick={onCancelClick}
                    hoveredMouseDepth={hoveredMouseDepth}
                    onMouseOver={onMouseOver}
                    onMouseLeave={onMouseLeave}
                    decimals={decimals}
                    ocoSettings={oco}
                    contract={contract}
                    visibleRows={visibleRows}
                    key={`${depth?.displayPrice}-Sell`}
                  />
                </tr>
              </Fragment >
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default LadderTable;
