import { MarketDataSubscription_Level } from "../proto/market_data_2";
import * as Historical2 from "../proto/historical_2";

import * as _ from "../../vendors/underscore-esm";
import { TimeUtil } from "../utils/TimeUtil";
import { BarReportStatusCode, TimeBarParameters_BarUnit } from "../proto/historical_2";
import { CQGEnvironment } from "./CQGEnvironment";
import { CMESymbolService } from "./CMESymbolService";
import { CQGService } from "./CQGService";
import { Instrument } from "../models/Instrument";
import { CMEInstrument } from "../models/CMEInstrument";
import { SymbolResolutionSubscription } from "./SymbolResolutionSubscriptions";
import { Decimal } from "../proto/common/decimal";

interface QuoteSubscription {
  fullSymbol: string;
  symbol: string;
  contract: Instrument;
  callback: any;
  listenerGUID: string;
}

interface TimeBarExtended extends Historical2.TimeBar {
  time: number;
  open: number;
  high: number;
  low: number;
  close: number;
  cqg_volume: number;
}

interface symbolInfo {
  name: string;
  timezone: string;
  pricescale: number;
  minmov: number;
  minmove2: number;
  has_intraday: boolean;
  has_no_volume: boolean;
  ticker: string;
  description: string;
  session: string;
  type: string;
  data_status: string;
  fractional: boolean;
  base_name: string[];
  legs: string[];
  full_name: string;
  pro_name: string;
}

class CQGUDFDataFeed {
  private dataFeed = {};
  private _contractIdToInstrument: { [key: number]: Instrument } = {};
  private _level = MarketDataSubscription_Level.LEVEL_TRADES_BBA_DOM;

  private _enableLogging: any = false;
  private _callbacks: any = {};
  private _barSubscriptions: any = {};
  private _keySubscriptions: any = {};
  private _getBarsCallbacks: any = {};
  private _collectedBars: any = {};
  private _realtimeCallbacks: any = {};
  private _depthSubscriptions: any = {};
  private _configuration: any = {};
  private onSymbolSearchResultReadyCallback: any = {};
  private onSymbolResolvedCallback: any = {};
  private onResolveErrorCallback: any = {};
  private _quoteSubscriptions: { [key: string]: QuoteSubscription | undefined } = {};

  constructor() {
    CQGEnvironment.Instance.cqgService.registerTimeBarWatcher(this.onTimeBar); // register a time bar watcher
    CQGEnvironment.Instance.cqgService.registerMarketWatcher(this.onMarketUpdate); // register a market data watcher

    this._initialize();
  }

  onMarketUpdate = (updates: Instrument[]) => {
    var update = _.find(updates, (update: Instrument) => {
      return this._quoteSubscriptions[update.displayName!] !== undefined;
    });

    if (update) {
      const instrument = update;
      const subscription = this._quoteSubscriptions[update.displayName];
      const data = [];
      if (subscription) {
        data.push({
          s: "ok",
          n: subscription.fullSymbol,
          v: {
            ch: instrument.netChange,
            chp: instrument.labelPriceNetChangePercent,
            short_name: instrument.displayName,
            exchange: "CME",
            description: instrument.description,
            lp: instrument.lastPrice,
            ask: instrument.bestAsk,
            bid: instrument.bestBid,
            open_price: instrument.openPrice,
            high_price: instrument.highPrice,
            low_price: instrument.lowPrice,
            close_price: instrument.closePrice,
            volume: instrument.labelTotalVolume,
          },
        });

        subscription.callback(data);
      }
    }

    update = _.find(updates, (update: Instrument) => {
      return this._depthSubscriptions[update.displayName!] !== undefined;
    });

    if (update) {
      const subscription = this._depthSubscriptions[update.displayName];

      if (subscription) {
        const depth = {
          snapshot: true,
          asks: update.asks,
          bids: update.bids,
        };

        subscription.callback(depth);
      }
    }
  };

  onTimeBar = (
    report: Historical2.TimeBarReport,
    contractId: number | undefined,
    barUnits: number | undefined,
    unitsNumber: number | undefined,
  ) => {
    const instrument = this.getInstrumentFromContractId(contractId!);

    if (_.isUndefined(instrument)) {
      this._logMessage("Instrument not found for contractId: " + contractId);
      return;
    }

    const key = this.createKey(contractId!, barUnits!, unitsNumber!);
    const validBars: Historical2.TimeBar[] = _.filter(report.timeBars, (bar: Historical2.TimeBar) => {
      return (
        bar.scaledClosePrice !== 0 &&
        bar.scaledHighPrice !== 0 &&
        bar.scaledLowPrice !== 0 &&
        bar.scaledOpenPrice !== 0
      );
    });
    const bars = _.map(validBars, (bar: TimeBarExtended) => {
      bar.time = Date.parse(TimeUtil.toUtcDate(bar.barUtcTime).toString());
      bar.open = bar.scaledOpenPrice! * instrument.correctPriceScale!;
      bar.high = bar.scaledHighPrice! * instrument.correctPriceScale!;
      bar.low = bar.scaledLowPrice! * instrument.correctPriceScale!;
      bar.close = bar.scaledClosePrice! * instrument.correctPriceScale!;
      bar.cqg_volume = bar.scaledVolume!;

      // TODO: bar.volume already exists and mat contain value. Revalidation required.
      bar.volume = (bar.scaledVolume ? bar.scaledVolume : 0) as Decimal;

      // TODO: proto contains `bar.scaledVolume` as number not as object. Revalidation required.
      // bar.volume = bar.scaledVolume ? bar.scaledVolume.low : 0;

      return bar;
    });

    if (report.statusCode === BarReportStatusCode.BAR_REPORT_STATUS_CODE_UPDATE) {
      var callback = this._realtimeCallbacks[key];

      if (!_.isUndefined(callback)) {
        _.forEach(bars, (bar: TimeBarExtended) => {
          callback(bar);
        });
      }
    } else {
      this._collectedBars[key] = this._collectedBars[key].concat(bars);

      if (report.isReportComplete) {
        this._logMessage(
          "TimeBarReport: Status[ " +
            report.statusCode +
            " ] Text[ " +
            report.textMessage +
            " ]  Total[ " +
            this._collectedBars[key].length +
            " ] Key[ " +
            key +
            " ]",
        );

        var totalBars = this._collectedBars[key];

        this._collectedBars[key] = [];

        if (totalBars.length > 0) {
          totalBars.reverse();
          this.logRecievedTimeBars(totalBars)

          this._getBarsCallbacks[key].success(totalBars);
        } else {
          this._getBarsCallbacks[key].error("No bars received");
        }
      }
    }
  };

  logRecievedTimeBars = (bars: any) => {
    console.info("time,open,high,low,close,volume");

    // _.forEach(bars, (bar: any) => {
    //   console.info(bar.time + "," + bar.open + "," + bar.high + "," + bar.low + "," + bar.close + "," + bar.volume);
    // });
  };

  on = (event: any, callback: any) => {
    if (!this._callbacks.hasOwnProperty(event)) {
      this._callbacks[event] = [];
    }

    this._callbacks[event].push(callback);
    return this;
  };

  _fireEvent = (event: string, argument?: any) => {
    if (this._callbacks.hasOwnProperty(event)) {
      var callbacksChain = this._callbacks[event];
      for (var i = 0; i < callbacksChain.length; ++i) {
        callbacksChain[i](argument);
      }
      this._callbacks[event] = [];
    }
  };

  _logMessage = (message: any) => {
    if (this._enableLogging) {
      console.info(message);
    }
  };

  _initializeChartConfig = () => {
    const configurationData = {
      supports_search: true,
      exchanges: ["CME"],
      symbols_types: ["EP"],
      supported_resolutions: [1, 3, 5, 10, 15, 30, 60, 120, 240, "D", "1W", "1M"],
      supports_marks: false,
    };

    this._setupWithConfiguration(configurationData);
  };

  _initialize = () => {
    this._initializeChartConfig();
  };

  _setupWithConfiguration = (configurationData: any) => {
    this._configuration = configurationData;

    if (configurationData.supports_search === false && configurationData.supports_group_request === false) {
      throw new Error("Unsupported datafeed configuration. Must either support search, or support group request");
    }

    if (!configurationData.exchanges) {
      configurationData.exchanges = [];
    }

    if (!configurationData.symbolsTypes) {
      configurationData.symbolsTypes = [];
    }

    this._fireEvent("configuration_ready");
    this._logMessage("Initialized with " + JSON.stringify(configurationData));
  };


  onReady = (callback: any) => {
    if (this._configuration) {
      callback(this._configuration);
    } else {
      this.on("configuration_ready", () => {
        callback(this._configuration);
      });
    }
  };

  searchSymbolsByName = (ticker: string, exchange: string, type: string, onSymbolSearchResultReadyCallback: any) => {
    this._logMessage("searchSymbolsByName: " + ticker);
    if (ticker === "") {
      this._logMessage("searchSymbolsByName: Ticker is blank. Exiting!");
      return;
    }

    this.onSymbolSearchResultReadyCallback = onSymbolSearchResultReadyCallback;
  };

  //	BEWARE: this function does not consider symbol's exchange
  resolveSymbol = (
    symbolName: string,
    onSymbolResolvedCallback: (o: any) => void,
    onResolveErrorCallback: (o: any) => void,
  ) => {
    this._logMessage("resolveSymbol: symbolName: " + symbolName);

    this.onSymbolResolvedCallback = onSymbolResolvedCallback;
    this.onResolveErrorCallback = onResolveErrorCallback;

    var fixedSymbol = this.stripExchangeFromSymbol(symbolName);
    console.log("RESOLVE SYMBOL", symbolName, fixedSymbol);

    CMESymbolService.getInstrumentFromCmeSymbol(fixedSymbol).then(
      (symbol: CMEInstrument) => {
        var data = {
          name: symbolName,
          timezone: "America/Chicago",
          pricescale: symbol.pricescale,
          minmov: symbol.minmove,
          minmove2: symbol.minmove2,
          has_intraday: true,
          has_no_volume: false,
          ticker: symbolName,
          description: symbolName,
          session: symbol.session || "24x7",
          type: "futures",
          data_status: "streaming",
          tickSize: symbol.tick_size,
          fractional: symbol.fractional,
        };

        this._logMessage("resolveSymbol: Resolved Symbol (below)");
        this._logMessage(symbol);

        this.onSymbolResolvedCallback(data);
      },
      (reason) => {
        this._logMessage("resolveSymbol: Error resolving symbol! " + reason);
        this.onResolveErrorCallback(reason);
      },
    );
  };

  getBars = (
    symbolInfo: symbolInfo,
    resolution: string,
    startDate: number,
    endDate: number,
    onBarDataCallback: (t: any, e: any) => void,
    onErrorCallback: (e: any) => void,
  ) => {
    this._logMessage("getBars: symbolnfo (below):");
    this._logMessage(symbolInfo);

    CMESymbolService.getInstrumentFromCmeSymbol(symbolInfo.name).then((cqgContract) => {
      let syms: SymbolResolutionSubscription[] = CQGService.resolveSymbols(
        this.getCQGSymbol(symbolInfo.name, cqgContract.cqg_symbol),
      );

      _.forEach(syms, (sym: SymbolResolutionSubscription) => {
        sym.resolvePromise.then((instrument: Instrument) => {
          instrument.fractional = symbolInfo.fractional;
          this.updateInstrumentInfo(instrument);

          var cqgResolution = this.determineCqgResolution(resolution);
          var key = this.createKey(instrument.contractId!, cqgResolution.barUnit, cqgResolution.unitsNumber);

          startDate = startDate * 1000;
          this._collectedBars[key] = [];
          this._getBarsCallbacks[key] = { success: onBarDataCallback, error: onErrorCallback };
          this._keySubscriptions[key] = {
            contractId: instrument.contractId,
            barUnit: cqgResolution.barUnit,
            unitsNumber: cqgResolution.unitsNumber,
            startDate: startDate,
          };

          this._logMessage("getBars => Requested Key: " + key + ", startDate: " + startDate);

          CQGEnvironment.Instance.cqgService.subscribeTimeBars(
            instrument.contractId!,
            cqgResolution.barUnit,
            cqgResolution.unitsNumber,
            startDate,
          );
        });
      });
    });
  };

  customSubscribeBars = (
    symbolInfo: symbolInfo,
    resolution: string,
    startDate: number,
    endDate: number,
    onBarDataCallback: (t: any, e: any) => void,
    onErrorCallback: (e: any) => void,
    onRealtimeCallback: (o: any) => void,
    listenerGUID: string,
  ) => {
    CMESymbolService.getInstrumentFromCmeSymbol(symbolInfo.name).then((cqgContract) => {
      var syms: SymbolResolutionSubscription[] = CQGService.resolveSymbols(cqgContract.cqg_symbol);

      _.forEach(syms, (sym: SymbolResolutionSubscription) => {
        sym.resolvePromise.then((instrument: Instrument) => {
          var cqgResolution = this.determineCqgResolution(resolution);
          var key = this.createKey(instrument.contractId!, cqgResolution.barUnit, cqgResolution.unitsNumber);

          this._barSubscriptions[listenerGUID] = { callbackKey: key };
          this._realtimeCallbacks[key] = onRealtimeCallback;

          this.getBars(symbolInfo, resolution, startDate, endDate, onBarDataCallback, onErrorCallback);
        });
      });
    });
  };

  subscribeBars = (
    symbolInfo: symbolInfo,
    resolution: string,
    onRealtimeCallback: (o: any) => void,
    listenerGUID: string,
  ) => {
    CMESymbolService.getInstrumentFromCmeSymbol(symbolInfo.name).then((cqgContract) => {
      const syms: SymbolResolutionSubscription[] = CQGService.resolveSymbols(cqgContract.cqg_symbol);

      _.forEach(syms, (sym: SymbolResolutionSubscription) => {
        sym.resolvePromise.then((instrument) => {
          var cqgResolution = this.determineCqgResolution(resolution);
          var key = this.createKey(instrument.contractId!, cqgResolution.barUnit, cqgResolution.unitsNumber);

          this._barSubscriptions[listenerGUID] = { callbackKey: key };
          this._realtimeCallbacks[key] = onRealtimeCallback;
        });
      });
    });
  };

  unsubscribeBars = (listenerGUID: string) => {
    if (!_.isUndefined(this._barSubscriptions[listenerGUID])) {
      this._logMessage(["unsubscribe", listenerGUID]);

      var key = this._barSubscriptions[listenerGUID].callbackKey;
      var subscription = this._keySubscriptions[key];

      if (!_.isUndefined(subscription)) {
        delete this._keySubscriptions[key];
        CQGEnvironment.Instance.cqgService.unsubscribeTimeBars(
          subscription.contractId,
          subscription.barUnit,
          subscription.unitsNumber,
          subscription.startDate,
        );
      }

      delete this._realtimeCallbacks[this._barSubscriptions[listenerGUID].callbackKey];
      delete this._barSubscriptions[listenerGUID];
    }
  };

  customUnsubscribeBars = (listenerGUID: string) => {
    if (!_.isUndefined(this._barSubscriptions[listenerGUID])) {
      this._logMessage(["unsubscribe", listenerGUID]);

      var key = this._barSubscriptions[listenerGUID].callbackKey;
      var subscription = this._keySubscriptions[key];

      if (!_.isUndefined(subscription)) {
        delete this._keySubscriptions[key];
        CQGEnvironment.Instance.cqgService.unsubscribeTimeBars(
          subscription.contractId,
          subscription.barUnit,
          subscription.unitsNumber,
          subscription.startDate,
        );
      }

      delete this._realtimeCallbacks[this._barSubscriptions[listenerGUID].callbackKey];
      delete this._barSubscriptions[listenerGUID];
    }
  };

  static periodResolutions = {
    "1": { resolutionBack: "D", intervalBack: 2 },
    "3": { resolutionBack: "D", intervalBack: 7 },
    "5": { resolutionBack: "D", intervalBack: 14 },
    "10": { resolutionBack: "D", intervalBack: 28 },
    "15": { resolutionBack: "D", intervalBack: 42 },
    "30": { resolutionBack: "D", intervalBack: 84 },
    "60": { resolutionBack: "D", intervalBack: 168 },
    "120": { resolutionBack: "D", intervalBack: 336 },
    "240": { resolutionBack: "D", intervalBack: 672 },
    "1D": { resolutionBack: "D", intervalBack: 730 },
    "1W": { resolutionBack: "W", intervalBack: 730 },
    "1M": { resolutionBack: "M", intervalBack: 730 },
  };

  getQuotes = (symbols: string[], onDataCallback: (o: any) => void, onErrorCallback: (e: any) => void) => {
    console.log("getQuotes", symbols);

    const aaIndex = symbols.indexOf("AA");
    // const count = symbols.count;
    const data: any[] = [];

    if (aaIndex !== -1) {
      symbols.splice(aaIndex, 1);
      console.log("Removed invalid symbols. valid:", symbols);
    }

    if (symbols.length === 0) {
      onDataCallback(data);
    }

    symbols.forEach((symbol) => {
      var fixedSymbol = this.stripExchangeFromSymbol(symbol);

      CMESymbolService.getInstrumentFromCmeSymbol(fixedSymbol).then((cqgContract) => {
        var syms: SymbolResolutionSubscription[] = CQGService.resolveSymbols(cqgContract.cqg_symbol);

        _.forEach(syms, (sym: SymbolResolutionSubscription) => {
          sym.resolvePromise.then((instrument) => {
            data.push({
              s: "ok",
              n: symbol,
              v: {
                ch: instrument.netChange,
                chp: instrument.labelPriceNetChangePercent,
                short_name: instrument.displayName,
                exchange: "CME",
                description: instrument.description,
                lp: instrument.labelPrice,
                ask: instrument.labelAsk,
                bid: instrument.labelBid,
                open_price: instrument.labelOpen,
                high_price: instrument.labelHigh,
                low_price: instrument.labelLow,
                close_price: instrument.labelClose,
                volume: instrument.labelTotalVolume,
              },
            });

            if (data.length === symbols.length) {
              onDataCallback(data);
            }
          });
        });
      });
    });
  };

  createQuoteData = (instrument: any) => {};

  subscribeQuotes = (
    symbols: string[],
    fastSymbols: string[],
    onRealtimeCallback: (o: any) => void,
    listenerGUID: string,
  ) => {
    console.log("subscribeQuotes:", symbols, fastSymbols, listenerGUID);

    symbols.forEach((symbol) => {
      let fixedSymbol = this.stripExchangeFromSymbol(symbol);

      CMESymbolService.getInstrumentFromCmeSymbol(fixedSymbol).then((cqgContract) => { console.log("cqg contract", cqgContract.cqg_symbol)
        let syms: SymbolResolutionSubscription[] = CQGService.resolveSymbols(cqgContract.cqg_symbol);
        console.log("sym contract", syms)
        _.forEach(syms, (sym: SymbolResolutionSubscription) => {
          sym.resolvePromise.then((instrument: Instrument) => {
            this._quoteSubscriptions[instrument.displayName!] = {
              fullSymbol: symbol,
              symbol: fixedSymbol,
              contract: instrument,
              callback: onRealtimeCallback,
              listenerGUID: listenerGUID,
            };
          });
        });
      });
    });
  };

  unsubscribeQuotes = (listenerGUID: string) => {
    console.log("unsubscribeQuotes:", listenerGUID);
    for (var key in this._quoteSubscriptions) {
      let subscription = this._quoteSubscriptions[key];
      if (subscription && subscription.listenerGUID === listenerGUID) {
        delete this._quoteSubscriptions[key];
        return;
      }
    }
  };

  subscribeDepth = (symbolWithExchange: string, callback: (o: any) => void) => {
    console.log("subscribeDepth:", symbolWithExchange);
    var symbol = this.stripExchangeFromSymbol(symbolWithExchange);
    var subscriberUID = symbol;

    if (this._depthSubscriptions[subscriberUID]) {
      return subscriberUID;
    }

    this.resolveSymbolFromCqg(symbol).then((contract) => {
      this._depthSubscriptions[subscriberUID] = {
        fullSymbol: symbolWithExchange,
        symbol: symbol,
        contract: contract,
        callback: callback,
      };
      CQGEnvironment.Instance.cqgService.subscribeToInstruments(contract.contractId!, this._level);
    });

    console.log("subscribeDepth: subscriberUID:", subscriberUID);
    return subscriberUID;
  };

  unsubscribeDepth = (subscriberUID: string) => {
    console.log("unsubscribeDepth:", subscriberUID);

    if (this._depthSubscriptions[subscriberUID]) {
      var subscription = this._depthSubscriptions[subscriberUID];
      CQGEnvironment.Instance.cqgService.unsubscribeFromInstruments(subscription.contract.contractId, this._level);
      delete this._depthSubscriptions[subscriberUID];
    }
  };

  //
  // HELPER METHODS BELOW
  //
  resolveSymbolFromCqg = (symbol: string) => {
    var promise = new Promise<Instrument>((resolve, reject) => {
      this.resolveSymbolFromCme(symbol).then((cmeInstrument: CMEInstrument) => {
        let syms: SymbolResolutionSubscription[] = CQGService.resolveSymbols(
          this.getCQGSymbol(symbol, cmeInstrument.cqg_symbol),
        );

        _.forEach(syms, (sym: SymbolResolutionSubscription) => {
          sym.resolvePromise.then(
            (instrument: Instrument) => {
              resolve(instrument);
            },
            (rejectData) => {
              reject(rejectData);
            },
          );
        });
      });
    });

    return promise;
  };

  resolveSymbolFromCme = (symbol: string) => {
    var promise = new Promise<CMEInstrument>((resolve, reject) => {
      CMESymbolService.getInstrumentFromCmeSymbol(symbol).then(
        (instrument: CMEInstrument) => {
          resolve(instrument);
        },
        (rejected) => {
          reject(rejected);
        },
      );
    });

    return promise;
  };

  stripExchangeFromSymbol = (symbolName: string) => {
    // Charting library is sending DOM symbol as Exchange:Symbol (example: CME:ESH0)
    // Resolve it without its exchange

    var splitted = symbolName.split(":");
    return splitted.length > 1 ? splitted[1] : symbolName;
  };

  createKey = (contractId: number, barUnit: number, unitsNumber: number) => {
    if (_.isNull(unitsNumber) || _.isUndefined(unitsNumber)) {
      unitsNumber = 1;
    }
    return contractId + "|" + barUnit + "|" + unitsNumber;
  };

  determineCqgResolution = (supportedResolution: string) => {
    var barUnit = TimeBarParameters_BarUnit.BAR_UNIT_MIN;
    var unitsNumber = 1;

    switch (supportedResolution) {
      case "1":
        // already initialized
        break;
      case "3":
        unitsNumber = 3;
        break;
      case "5":
        unitsNumber = 5;
        break;
      case "10":
        unitsNumber = 10;
        break;
      case "15":
        unitsNumber = 15;
        break;
      case "30":
        unitsNumber = 30;
        break;
      case "60":
        barUnit = TimeBarParameters_BarUnit.BAR_UNIT_HOUR;
        break;
      case "120":
        barUnit = TimeBarParameters_BarUnit.BAR_UNIT_HOUR;
        unitsNumber = 2;
        break;
      case "240":
        barUnit = TimeBarParameters_BarUnit.BAR_UNIT_HOUR;
        unitsNumber = 4;
        break;
      case "1D":
        barUnit = TimeBarParameters_BarUnit.BAR_UNIT_DAY;
        break;
      case "1W":
        barUnit = TimeBarParameters_BarUnit.BAR_UNIT_WEEK;
        break;
      case "1M":
        barUnit = TimeBarParameters_BarUnit.BAR_UNIT_MONTH;
        break;
    }

    return { barUnit: barUnit, unitsNumber: unitsNumber };
  };

  updateInstrumentInfo = (instrument: Instrument) => {
    var stored = this.getInstrumentFromContractId(instrument.contractId!);

    if (_.isUndefined(stored)) {
      this._contractIdToInstrument[instrument.contractId!] = instrument;
    }
  };

  getInstrumentFromContractId = (contractId: number) => {
    return this._contractIdToInstrument[contractId];
  };

  getCQGSymbol = (symbolName: string, cqgSymbol: string) => {
    return symbolName.length <= 3 ? cqgSymbol : cqgSymbol + symbolName.slice(-2);
  };
}

export const cqgUDFDataFeed = new CQGUDFDataFeed(); 