// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
  border-bottom: 1px solid #0000001F;
  padding-left: 9px;
  padding-right: 9px;
  width: calc(100% - 18px);
  &:hover {
    background-color: #0000001F;
  }
}
.sm-info {
  color: #081D3799;
  text-transform: capitalize;
}
.tableData {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  row-gap: 5px;
}

.tableData-modal {
  display: grid;
  margin-bottom: 8px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0 12px 8px;
}
.threeCol {
  grid-template-columns: 1fr 1fr 1fr;

}
.tableColRightAlign{
  align-self: end;
  justify-self: end;
}
.cancel-all-orders {
  background-color: transparent;
}
.justify-end {
  justify-self: end;
}
.symbolName {
  color: #081D37DE;
}`, "",{"version":3,"sources":["webpack://./src/components/PositionsWidget/positions.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,iBAAiB;EACjB,kBAAkB;EAClB,wBAAwB;EACxB;IACE,2BAA2B;EAC7B;AACF;AACA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,WAAW;EACX,sCAAsC;EACtC,YAAY;AACd;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,sCAAsC;EACtC,kBAAkB;AACpB;AACA;EACE,kCAAkC;;AAEpC;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".row {\n  border-bottom: 1px solid #0000001F;\n  padding-left: 9px;\n  padding-right: 9px;\n  width: calc(100% - 18px);\n  &:hover {\n    background-color: #0000001F;\n  }\n}\n.sm-info {\n  color: #081D3799;\n  text-transform: capitalize;\n}\n.tableData {\n  display: grid;\n  width: 100%;\n  grid-template-columns: 2fr 1fr 1fr 1fr;\n  row-gap: 5px;\n}\n\n.tableData-modal {\n  display: grid;\n  margin-bottom: 8px;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  margin: 0 12px 8px;\n}\n.threeCol {\n  grid-template-columns: 1fr 1fr 1fr;\n\n}\n.tableColRightAlign{\n  align-self: end;\n  justify-self: end;\n}\n.cancel-all-orders {\n  background-color: transparent;\n}\n.justify-end {\n  justify-self: end;\n}\n.symbolName {\n  color: #081D37DE;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
