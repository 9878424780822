
import { styled } from '@mui/material/styles';

const TextWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});


export default TextWrapper;
