import { Instrument } from "../../../cqg-api/models/Instrument";
import { CONTRACT_ID_NAME, ProductWithContracts, TreeViewMode, WidgetType } from "../../../types";

const updateErrorMessage = (
  newSelectedItems: string[],
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
  widgetType: WidgetType
) => {
  const stringCount = newSelectedItems.filter(item => typeof item === 'string').length;
  if (stringCount > 8 && widgetType === WidgetType.Chart) {
    setErrorMessage("You have selected maximum number of charts supported");
    return true;
  }
  setErrorMessage(null);
  return false;
};

export const handleProductCheckboxToggle = (
  product: ProductWithContracts,
  selectedItems: string[],
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
  mode: TreeViewMode,
  widgetType: WidgetType
) => {
  const allContractIds = product.monthlyContracts?.map((contract) => String(contract[CONTRACT_ID_NAME])) || [];
  const selectedContractIds = selectedItems.filter((id) => allContractIds.includes(id));
  let newSelectedItems = [...selectedItems];

  switch (mode) {
    case TreeViewMode.MULTIPLE:
      if (selectedContractIds.length === 0 && product.monthlyContracts?.length) {
        // First click: Select the first monthly contract
        newSelectedItems.push(String(product.monthlyContracts[0][CONTRACT_ID_NAME]));
      } else if (selectedContractIds.length < allContractIds.length) {
        // Second click: Select all monthly contracts
        allContractIds.forEach((id) => {
          if (!newSelectedItems.includes(id)) {
            newSelectedItems.push(id);
          }
        });
      } else {
        // Third click: Unselect all monthly contracts
        newSelectedItems = newSelectedItems.filter((id) => !allContractIds.includes(id));
      }

      // Update product selection based on all contracts being selected or not
      const allContractsSelected =
        newSelectedItems.filter((id) => allContractIds.includes(id)).length === allContractIds.length;

      if (allContractsSelected) {
        if (!newSelectedItems.includes(product.id)) {
          newSelectedItems.push(product.id);
        }
      } else {
        newSelectedItems = newSelectedItems.filter((id) => id !== product.id);
      }
      break;

    case TreeViewMode.SINGLE:
      // Single select behavior: unselect others and select the current one
      newSelectedItems = selectedItems.includes(product.id) ? [] : [product.id];
      break;

    default:
      throw new Error("Unsupported TreeView mode"); // Optional: Handle unsupported modes
  }
  if (!updateErrorMessage(newSelectedItems, setErrorMessage, widgetType)) {
    setSelectedItems(newSelectedItems);
  }
};

export const handleContractCheckboxToggle = (
  contract: Instrument,
  product: ProductWithContracts,
  selectedItems: string[],
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
  mode: TreeViewMode,
  widgetType: WidgetType
) => {
  const displayName = String(contract[CONTRACT_ID_NAME]);
  const isSelected = selectedItems.includes(displayName);
  let newSelectedItems = [...selectedItems];

  switch (mode) {
    case TreeViewMode.MULTIPLE:
      if (isSelected) {
        // Unselect the contract
        newSelectedItems = newSelectedItems.filter((id) => id !== displayName);
      } else {
        // Select the contract
        newSelectedItems.push(displayName);
      }

      const allContractIds = product.monthlyContracts?.map((contract) => String(contract.displayName)) || [];
      const selectedContractIds = newSelectedItems.filter((id) => allContractIds.includes(id));

      if (selectedContractIds.length === allContractIds.length) {
        // If all contracts are selected, select the product (ensure it's not duplicated)
        if (!newSelectedItems.includes(product.id)) {
          newSelectedItems.push(product.id);
        }
      } else if (selectedContractIds.length === 0) {
        // If no contracts are selected, unselect the product
        newSelectedItems = newSelectedItems.filter((id) => id !== product.id);
      } else {
        // If some contracts are selected, ensure the product is not fully selected
        newSelectedItems = newSelectedItems.filter((id) => id !== product.id);
      }
      break;

    case TreeViewMode.SINGLE:
      // Single select behavior for contracts: toggle only the current contract
      //newSelectedItems = isSelected ? newSelectedItems.filter((id) => id !== contractId) : [contractId];
      newSelectedItems = [displayName]; // Logic to ensure the product is selected if a contract is selected
      newSelectedItems.push(product.id);

      break;

    default:
      throw new Error("Unsupported TreeView mode"); // Optional: Handle unsupported modes
  }
  if (!updateErrorMessage(newSelectedItems, setErrorMessage, widgetType)) {
    setSelectedItems(newSelectedItems);
  }
};
