import "./widgets.css";
import MenuItem from "../../../../common/userinfo/Menutem";
import { widgets } from "../../../../workspace/mockData";
import { Widget, WidgetType } from "../../../../../types";

const WidgetList = ({ onAddWidgetClick }: WidgetsNavProps) => {
  const handleonAddWidgetClick = (widget: Widget) => {
    if (widget.title === WidgetType.PriceLadder) return;
    onAddWidgetClick(widget);
  };

  return (
    <ul>
      {widgets.map((item, index) => {
        return (
          <li key={index} style={{ cursor: "pointer" }} onClick={() => handleonAddWidgetClick(item)}>
            <MenuItem text={item.title} icon={item.icon} />
          </li>
        );
      })}
    </ul>
  );
};

type WidgetsNavProps = { onAddWidgetClick: (widget: Widget) => void };
const WidgetsNav = ({ onAddWidgetClick }: WidgetsNavProps) => (
  <div className="productsNav">
    <h3 className="heading">ADD WIDGETS</h3>
    <WidgetList onAddWidgetClick={onAddWidgetClick} />
  </div>
);

export default WidgetsNav;
