import { useEffect, useState } from "react";
import { InstrumentMarketData } from "../../../../types";
import { ASC_SORT, CHANGE, LABEL_TOTAL_VOLUME, NAME } from "../types";
import { Instrument } from "../../../../cqg-api/models/Instrument";

const useSortTable = (rows: InstrumentMarketData[], sortModelValue: any) => {
  const [gridRows, setGridRows] = useState<InstrumentMarketData[]>([]);

  useEffect(() => {
    const sortRows = (field: string, sort: string) => {
      const getState = (row: InstrumentMarketData & { monthlyContracts?: Instrument }) => {
        return Array.isArray(row?.monthlyContracts)
          ? (row?.monthlyContracts[0] ?? null)
          : (row?.monthlyContracts ?? null);
      };

      return [...rows].sort((a, b) => {
        const stateOfRowA = getState(a);
        const stateOfRowB = getState(b);

        if (field === LABEL_TOTAL_VOLUME) {
          return stateOfRowB?.[field] - stateOfRowA?.[field];
        } else if (field === CHANGE) {
          return stateOfRowB?.labelPriceNetChange - stateOfRowA?.labelPriceNetChange;
        } else if (field === NAME) {
          return sort === ASC_SORT ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        }

        return 0;
      });
    };
    if (sortModelValue?.length > 0) {
      const { field, sort } = sortModelValue[0];
      setGridRows(sortRows(field, sort));
    }
  }, [rows, sortModelValue]);

  return { gridRows };
};

export default useSortTable;
