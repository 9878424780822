import { Box, Link, Stack, Typography } from "@mui/material";
import ControlledRadioGroup from "../RadioGroup";
import { Control } from "react-hook-form";
import { TradeLogProps } from "../../../../../types";

const UseStops = ({ control }: { control: Control<TradeLogProps, any> }) => {
  return (
    <Box component="section" className="section-outer">
      <Stack
        direction={{ md: "column", lg: "row" }}
        spacing={2}
        sx={{
          justifyContent: { xs: "center", md: "space-between" },
          alignItems: { xs: "flex-start", md: "flex-start" },
          gap: { xs: 1, md: 2, lg: 10 },
        }}
      >
        <Box className="section-left" sx={{ width: { xs: "100%", md: "auto" } }}>
          <Box className="section-sub-left">
            <Typography className="section-title">Section E:</Typography>
            <Typography className="section-sub-title">Use Stops</Typography>
          </Box>
          <Box className="section-sub-right">
            <Typography className="section-middle" fontSize="12px">
              Your trade exit strategy can be just as important as your trade entry decision—but can be an emotional one
              once your money is on the line. That’s why many successful traders place a stop order—tied to pre-defined
              risk parameters—that gets them out of a trade if it is going against them.
            </Typography>
            <Link
              href="https://www.cmegroup.com/education/courses/master-the-trade-futures/take-your-trade-plan-to-the-next-level/master-the-trade-managing-futures-risk.html"
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more in Trading and Risk Management
            </Link>
          </Box>
        </Box>
        <Box>
          <ControlledRadioGroup control={control} title="Please select " options={["Yes", "No"]} name="useStopLoss" />
        </Box>
      </Stack>
    </Box>
  );
};

export default UseStops;
