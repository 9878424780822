import { useCallback, useMemo } from "react";
import { DisplayUtil } from "../../../../../../cqg-api/utils/DisplayUtil";
import { UseFormClearErrors } from "react-hook-form";
import { IFormInput } from "../../../../../TradeTicketDrawer/types";
import { OrderSide } from "../../../../../../types";
import { SectionKey, SectionKeyType } from "../../../types";

interface Strategy {
  price: number;
  displayPrice: number;
  pnl: number;
  percent: number;
}

const useTickChange = (
  limitPrice: number,
  quantity: number,
  side: OrderSide,
  sectionKey: SectionKeyType,
  selectedContract: any,
  setValue: (key: string, value: any) => void,
  getSign: (isProfit: boolean) => number,
  clearErrors?: UseFormClearErrors<IFormInput>,
) => {
  const calculateStrategy = useMemo(
    () =>
      (tick: number, limitPrice: number, quantity: number, selectedContract: any, sectionKey: string): Strategy => {
        const sign = getSign(sectionKey === "takeProfit");
        let price = selectedContract ? DisplayUtil.rawStepPrice(limitPrice, selectedContract, sign * tick) : 0;

        const displayPrice = parseFloat(DisplayUtil.toDisplayPrice(price, selectedContract)?.toString() || "0") || 0;

        const pnl =
          quantity && selectedContract?.multiplier
            ? Math.round(selectedContract.multiplier * quantity * Math.abs(limitPrice - price) * 100) / 100
            : 0;

        const percent = limitPrice ? Math.round((Math.abs(limitPrice - price) / limitPrice) * 100 * 100) / 100 : 0;

        return { price, displayPrice, pnl, percent };
      },
    [getSign],
  );

  const onTickChange = useCallback(
    (tick: number) => {
      const strategy = calculateStrategy(tick, limitPrice, quantity, selectedContract, sectionKey);

      const pnlKey = sectionKey === SectionKey.TakeProfit ? "profit" : "loss";
      const percentKey = sectionKey === SectionKey.TakeProfit ? "profitPercentage" : "lossPercentage";

      setValue(`oco.${side}.${sectionKey}.price`, strategy.displayPrice);
      setValue(`oco.${side}.${sectionKey}.${pnlKey}`, strategy.pnl);
      setValue(`oco.${side}.${sectionKey}.${percentKey}`, strategy.percent);
      if (clearErrors) {
        clearErrors(`oco.${side}.${sectionKey}.price`);
        clearErrors(`oco.${side}.${sectionKey}.${pnlKey}` as any);
        clearErrors(`oco.${side}.${sectionKey}.${percentKey}` as any);
      }
    },
    [calculateStrategy, clearErrors, limitPrice, quantity, sectionKey, selectedContract, setValue, side],
  );

  return onTickChange;
};

export default useTickChange;
