import { Select, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { checkIconStyles, menuStyles, selectStyles } from "./marketStyles";
interface MarketAssetsProps {
  activeTab: string;
  assetProductsDictionary: Record<string, any>;
  selectMarket: (market: string) => void;
  isSmallPanel: boolean;
  isMobile?: boolean;
}

const MarketAssets = ({
  activeTab,
  assetProductsDictionary,
  selectMarket,
  isSmallPanel,
  isMobile,
}: MarketAssetsProps) => (
  <div className="market-type-wrapper" style={{ flex: isMobile ? 1 : undefined }}>
    {isSmallPanel || isMobile ? (
      <Select
        sx={{
          ...selectStyles,
          minWidth: isMobile ? "100%" : "196px",
          height: isMobile ? "36px" : isSmallPanel ? '30.3px': undefined,
          '& .MuiSelect-select': {
            paddingLeft: isMobile ? '14px' : undefined,
          }
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              width: isMobile ? "100%" : undefined,
            },
          },
        }}
        renderValue={(selected) => selected}
        value={activeTab}
        onChange={(event) => selectMarket(event.target.value)}
      >
        {Object.keys(assetProductsDictionary).map((market) => (
          <MenuItem key={market} value={market} sx={menuStyles}>
            <span>{market}</span>
            {isMobile && market === activeTab && <CheckIcon style={checkIconStyles} />}
          </MenuItem>
        ))}
      </Select>
    ) : (
      Object.keys(assetProductsDictionary).map((market) => {
        const isActive = activeTab === market ? "market-type-active" : "";
        return (
          <button onClick={() => selectMarket(market)} className={`market-type ${isActive}`} key={market}>
            {market}
          </button>
        );
      })
    )}
  </div>
);

export default MarketAssets;
