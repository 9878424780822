import { IconButton } from "@mui/material";
import { settingsIconStyles } from "./PriceLadderStyles";
import SettingsIcon from "@mui/icons-material/Settings";

const OrderSettings = ({ handleOpenOrderSettings }: { handleOpenOrderSettings: () => void }) => {
  const handleOpenOrderSettingsClick = () => {
    handleOpenOrderSettings();
  };

  return (
    <>
      <IconButton onClick={() => handleOpenOrderSettingsClick()}>
        <SettingsIcon style={settingsIconStyles} />
      </IconButton>
    </>
  );
};

export default OrderSettings;
