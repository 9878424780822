const PriceIcon = () => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.825 5.1665L0.5 8.49984L3.825 11.8332V9.33317H9.66667V7.6665H3.825V5.1665ZM15.5 3.49984L12.175 0.166504V2.6665H6.33333V4.33317H12.175V6.83317L15.5 3.49984Z" fill="#006EB6"/>
</svg>

  )
};

export default PriceIcon;
