import React, { useCallback, useMemo, useState } from "react";
import Logo from "../../Logo";
import "./header.css";
import { removeCurrentUserFromLS } from "../../../../storage";
import { useDispatch } from "react-redux";
import authActions from "../../../../redux/auth/authActions";
import { useGetCurrentUser } from "../../../../hooks/api/authentication";
import ProfileSection from './ProfileSection';
import FundsInfo from './FundsInfo';

const Header = () => {
  const dispatch = useDispatch();
  const currentUser = useGetCurrentUser();

  const firstName = currentUser?.firstName || "";
  const lastName = currentUser?.lastName || "";
  const userName = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName]);
  const profileInitial = useMemo(() => `${firstName.charAt(0).toUpperCase()}`, [firstName]);

  const [anchorShow, setAnchorShow] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorShow);

  const handleAccountClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorShow(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorShow(null);
  }, []);

  const handleLogoutClick = useCallback(() => {
    removeCurrentUserFromLS();
    dispatch(authActions.doSignout());
    handleClose();
  }, [dispatch, handleClose]);

  const handleAccountSettingsClick = useCallback(() => {
    console.log(`clicked`);
  }, []);
  
  return (
    <header>
      <div className="header-left">
        <Logo />
        <FundsInfo />
      </div>
      <div className="header-controls">
        <ProfileSection
          userName={userName}
          profileInitial={profileInitial}
          open={open}
          anchorShow={anchorShow}
          handleAccountClick={handleAccountClick}
          handleClose={handleClose}
          handleLogoutClick={handleLogoutClick}
          handleAccountSettingsClick={handleAccountSettingsClick}
        />
      </div>
    </header>
  );
};

export default Header;
