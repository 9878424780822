import React, { useCallback, useMemo, useRef, useState } from "react";
import { OrderState } from "../../../../cqg-api/models/OrderState";
import { Depth, StopLoss, TakeProfit } from "../types";
import { OrderSide, OrderType } from "../../../../types";
import { cancelRoundedBuyStyles, cancelRoundedSellStyles } from "../PriceLadderStyles";
import OrderChip from "./OrderChip";
import { Instrument } from "../../../../cqg-api/models/Instrument";
import { getSign, getTakeProfitAndStopPrice, ordersPlacedOnDepth } from "../utils";
import DottedOrderChip from "./DottedOrderChip";
import { useOrdersData } from "../../../../OrdersDataProvider";

interface OrderCellProps {
  depth: Depth;
  orderSide: OrderSide;
  index: number;
  quantityValue: number;
  onDragStart: (onDragStarts: Depth, index: number) => void;
  onDrop: (depth: Depth, orderSide: OrderSide) => void;
  onClick: (orderSide: OrderSide, orderType: OrderType, price: number | null) => void;
  onCancelClick: (orders: OrderState[]) => void;
  hoveredMouseDepth:
    | (Depth & {
        orderSide: OrderSide;
      })
    | null;
  decimals: number;
  ocoSettings: {
    BUY: {
      takeProfit: TakeProfit;
      stopLoss: StopLoss;
    };
    SELL: {
      takeProfit: TakeProfit;
      stopLoss: StopLoss;
    };
  };
  contract: Instrument;
  visibleRows: { first: HTMLElement | null; last: HTMLElement | null };
  onMouseOver: (depth: Depth, orderSide: OrderSide) => void;
  onMouseLeave: () => void;
}

const OrderCell = ({
  depth,
  orderSide,
  index,
  quantityValue,
  onDragStart,
  onDrop,
  onClick,
  onCancelClick,
  hoveredMouseDepth,
  decimals,
  ocoSettings,
  contract,
  visibleRows,
  onMouseOver,
  onMouseLeave,
}: OrderCellProps) => {
  const { ordersList } = useOrdersData();
  const [isDragging, setIsDragging] = useState(false);
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const profitRef = useRef(null);
  const lossRef = useRef(null);

  const placedOrders: OrderState[] | undefined = useMemo(
    () => ordersPlacedOnDepth(ordersList, depth, orderSide),
    [depth, orderSide, ordersList],
  );

  const isAnyOrderPlacedOnDepth = !!placedOrders?.length;

  const ocoOrderSide = hoveredMouseDepth?.orderSide;

  const calculateTakeProfitOrStopPrice = (
    price: number | null | undefined,
    ocoOrderSide: OrderSide | undefined,
    isTakeProfit: boolean,
  ) => {
    if (!price || !ocoOrderSide) return null;

    const tick = Number(ocoSettings[ocoOrderSide]?.[isTakeProfit ? "takeProfit" : "stopLoss"]?.tick);
    const sign = getSign(isTakeProfit, ocoOrderSide);

    return getTakeProfitAndStopPrice(Number(price), contract, sign, tick).toFixed(decimals);
  };

  const profitPrice = calculateTakeProfitOrStopPrice(hoveredMouseDepth?.displayPrice, ocoOrderSide, true);
  const lossPrice = calculateTakeProfitOrStopPrice(hoveredMouseDepth?.displayPrice, ocoOrderSide, false);

  const isMatchingDepth = (priceType: string | null, isSelected: boolean) => {
    return (
      ocoOrderSide &&
      isSelected &&
      hoveredMouseDepth &&
      ocoOrderSide !== orderSide &&
      depth.displayPrice?.toString() === priceType
    );
  };

  const isTakeProfit = isMatchingDepth(
    profitPrice,
    ocoOrderSide ? ocoSettings[ocoOrderSide]?.takeProfit?.isSelected : false,
  );
  const isStopLoss = isMatchingDepth(lossPrice, ocoOrderSide ? ocoSettings[ocoOrderSide]?.stopLoss?.isSelected : false);

  const isSelected =
    (hoveredMouseDepth?.displayPrice === depth.displayPrice && hoveredMouseDepth.orderSide === orderSide) ||
    isTakeProfit ||
    isStopLoss;

  const orderClass = isAnyOrderPlacedOnDepth ? `price-ladder-order-${orderSide}` : "";
  const cancelStyles = orderSide === OrderSide.Buy ? cancelRoundedBuyStyles : cancelRoundedSellStyles;

  const handleDragEvents = {
    onDragOver: (e: any) => {
      e.preventDefault();
    },
    onDragStart: () => {
      setIsDragging(true);
      onDragStart(depth, index);
    },
    onDrop: () => {
      onDrop(depth, orderSide);
      setIsDragging(false);
    },
  };

  const handleMouseEvents = {
    onMouseOver: useCallback(() => onMouseOver(depth, orderSide), [depth, onMouseOver, orderSide]),
    onMouseLeave: useCallback(() => onMouseLeave(), [onMouseLeave]),
    onClick: useCallback(() => {
      if (!isAnyOrderPlacedOnDepth && !isPlacingOrder) {
        setIsPlacingOrder(true);
        onClick(orderSide, OrderType.Lmt, depth.displayPrice);
        const timeoutId = setTimeout(() => setIsPlacingOrder(false), 500);

        return () => clearTimeout(timeoutId);
      }
    }, [onClick, depth.displayPrice, isPlacingOrder, orderSide, isAnyOrderPlacedOnDepth]),
  };

  return (
    <td
      style={{ cursor: "pointer" }}
      className="price-ladder-book-qty"
      {...handleMouseEvents}
      {...handleDragEvents}
      draggable={isAnyOrderPlacedOnDepth}
      ref={isTakeProfit ? profitRef : isStopLoss ? lossRef : null}
    >
      {isAnyOrderPlacedOnDepth ? (
        <OrderChip
          orderClass={orderClass}
          cancelStyles={cancelStyles}
          placedOrders={placedOrders}
          onCancelClick={onCancelClick}
          isDragging={isDragging}
        />
      ) : (
        <DottedOrderChip
          orderSide={orderSide}
          isSelected={isSelected}
          quantityValue={quantityValue}
          ocoOrderSide={ocoOrderSide}
          ocoSettings={ocoSettings}
          depth={depth}
          profitPrice={profitPrice}
          lossPrice={lossPrice}
          hoveredMouseDepth={hoveredMouseDepth}
          visibleRows={visibleRows}
        />
      )}
    </td>
  );
};

export default OrderCell;
