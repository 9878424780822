import { WidgetType } from "../../types";

const TreeViewContainerDescription = ({ widgetType }: { widgetType: WidgetType | undefined }) => {
  switch (widgetType) {
    case WidgetType.Watchlist:
      return "Add product(s) to this watchlist";
    case WidgetType.Chart:
      return "Please select a product in order to add a chart.";
    case WidgetType.PriceLadder:
      return "Please select a product in order to add a price ladder.";
    case WidgetType.Options:
      return "Please select a product in order to add an options grid.";
    default:
      return "";
  }
};

export default TreeViewContainerDescription;
