import { Box, Chip } from "@mui/material";
import { WidgetType } from "../../types";
import CloseIcon from "@mui/icons-material/Close";

const TreeViewChip = ({
  selectedTreeItems,
  handleDelete,
  widgetType,
  isEnabled,
}: {
  selectedTreeItems: string[];
  handleDelete: (item: string) => void;
  widgetType?: WidgetType;
  isEnabled?: boolean;
}) => {
  return (
    <>
      {selectedTreeItems
        .filter((item) => typeof item === "string")
        .map((item) => (
          <Chip
            key={item}
            label={item}
            onDelete={() => handleDelete(item)}
            disabled={!isEnabled}
            deleteIcon={
              <Box
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor:
                    widgetType === WidgetType.PriceLadder ? "white" : "var(--primary-main-blue, #006EB6)",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CloseIcon
                  sx={{
                    color: widgetType === WidgetType.PriceLadder ? "#5A6874" : "white",
                    fontSize: "10px",
                  }}
                />
              </Box>
            }
            sx={{
              margin: "4px",
              minHeight: "24px",
              maxHeight: "24px",
              padding: "0px",
              gap: "0px",
              border:
                widgetType === WidgetType.PriceLadder
                  ? "1px solid transparent"
                  : "1px solid var(--primary-main-blue, #006EB6)",
              backgroundColor: widgetType === WidgetType.PriceLadder ? "var(--primary-Gray3, #5A6874)" : "white",
              color: widgetType === WidgetType.PriceLadder ? "white" : "var(--primary-main-blue, #006EB6)",
              fontFamily: "Averta-Regular",
              fontSize: "12px",
            }}
          />
        ))}
    </>
  );
};

export default TreeViewChip;
