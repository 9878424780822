import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import './confirmTradeDialog.css';
import { Instrument } from '../../../cqg-api/models/Instrument';

type ConfirmTradeDialogProps = {
  open: boolean;
  onClose: () => void;
  cancel: () => void;
  confirm: () => void;
  trade: {
    contractSize: number;
    contract: string;
    tickSize: number;
    tickValue: number;  
    lastPrice: number;
    nationalValue: number;
    marginCommited: number;
    side: string;
    quantity: number;
    orderType: string;
    limitPrice: number;
    tif: string;
    stopPrice: number;
    strikePrice?: number;
    putCall?: string;
    instrument: Instrument;
  },
};
function formatNumber(num: number): string {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(num));
}

function hasNotionalValue( instrument: Instrument ): boolean {
  return instrument !== null && instrument.isFuture() && ['Interest Rates', 'FX'].indexOf(instrument.assetClassName) == -1
}

const getOrderType = (orderType: string) => {
  if (orderType === 'MKT') {
    return 'Market';
  }
  if (orderType === 'LMT') {
    return 'Limit';
  }
  if (orderType === 'STP') {
    return 'Stop';
  }
  if (orderType === 'STL') {
    return 'Stop Limit';
  }
}
const getPutCallText = (text: string) => {
  if (text === 'C') {
    return 'CALL';
  }
  if (text === 'P') {
    return 'PUT';
  }
}

const ConfirmTradeDialog = ({ open, onClose, cancel, confirm, trade }: ConfirmTradeDialogProps) => {
  return (

    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '444px',
          padding: '6px',
          borderRadius: '8px',
        },
        '& .MuiDialogContent-root': {
          width: '80%',
          alignSelf: 'center',
        }
      }}
    >
    <DialogTitle className="dialogTitle">Confirm Order</DialogTitle>
    <DialogContent className='DialogContent'>
      <table className='table'>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="dialogTextValue highlight">{trade.contract}</td>
            <td className="dialogTextValue highlight">{getPutCallText(trade?.putCall)}</td>
          </tr>
          <tr>
            <td className="dialogText">Contract Size</td>
            <td className="dialogTextValue">{trade.contractSize}</td>
          </tr>
          <tr>
            <td className="dialogText">Tick Size</td>
            <td className="dialogTextValue">{trade.tickSize}</td>
          </tr>
          <tr>
            <td className="dialogText">Tick Value</td>
            <td className="dialogTextValue">${trade.tickValue}</td>
          </tr>
          <tr>
            <td className="dialogText">Last Price</td>
            <td className="dialogTextValue">${trade.lastPrice}</td>
          </tr>
          {trade.instrument && hasNotionalValue(trade.instrument) && !trade.putCall &&
          <tr>
            <td className="dialogText">Notional Value of Order</td>
            <td className="dialogTextValue">${formatNumber(trade.instrument?.multiplier * trade.quantity * trade.instrument?.lastPrice)}</td>
          </tr>}
          {trade.instrument && !trade.putCall &&
          <tr>
            <td className="dialogText">Margin committed to trade</td>
            <td className="dialogTextValue">${formatNumber(trade.instrument.marginInitialRate * trade.quantity)}</td>
          </tr>}
          {trade.strikePrice &&
          <tr>
            <td className="dialogText">Strike Price</td>
            <td className="dialogTextValue">{trade.strikePrice}</td>
          </tr>}
        </tbody>
      </table>
      <div className='divider'></div>
      <table className='table'>
        <tbody>
          <tr>
            <td className="dialogText">Side</td>
            <td className="dialogTextValue" style={{ color: trade.side === 'BUY' ? '#00695C' : '#C62828' }}>{trade.side}</td>
          </tr>
          <tr>
            <td className="dialogText">Quantity</td>
            <td className="dialogTextValue">{trade.quantity}</td>
          </tr>
          <tr>
            <td className="dialogText">Type</td>
            <td className="dialogTextValue">{getOrderType(trade.orderType)}</td>
          </tr>
            {trade.orderType === 'LMT' && (<tr>
              <td className="dialogText">Limit Price</td>
              <td className="dialogTextValue">${trade.limitPrice}</td>
            </tr>)}
            {trade.orderType === 'STP' && (<tr>
              <td className="dialogText">Stop Price</td>
              <td className="dialogTextValue">${trade.stopPrice}</td>
            </tr>)}
            {trade.orderType === 'STL' && (<><tr>
              <td className="dialogText">Limit Price</td>
              <td className="dialogTextValue">${trade.limitPrice}</td>
            </tr>
            <tr>
              <td className="dialogText">Stop Price</td>
              <td className="dialogTextValue">${trade.stopPrice}</td>
            </tr>
            </>)}
          <tr>
            <td className="dialogText">TIF</td>
            <td className="dialogTextValue">{trade.tif}</td>
          </tr>
        </tbody>
      </table>
    </DialogContent>
    <DialogActions>
      <Button className='confirmTradeDialog' onClick={cancel} style={{ color: "#081D37DE" }}>Cancel</Button>
      <Button className='confirmTradeDialogSuccess' style={{ color: '#006EB6' }} onClick={confirm}>Confirm Order</Button>
    </DialogActions>
  </Dialog>
  )
};

export default ConfirmTradeDialog;
