import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { useOrdersList, useRealTimeMarketData } from './cqg-api/hooks/ServiceHooks';
import { OrderState } from './cqg-api/models/OrderState';
import { Instrument } from './cqg-api/models/Instrument';


interface ContextProps {
  ordersList: OrderState[];
}

const defautContextProps: ContextProps = {
  ordersList: [],
};

const OrdersDataContext = createContext<ContextProps>(defautContextProps);

export const OrdersDataProvider = ({ children }: { children: ReactNode }) => {
  const { ordersList } = useOrdersList();

  const contextValue = useMemo(() => ({
    ordersList,
  }), [ordersList]);

  return (
    <OrdersDataContext.Provider value={contextValue}>
      {children}
    </OrdersDataContext.Provider>
  );
};

export const useOrdersData = () => {
  const context = useContext(OrdersDataContext);
  return context;
};
