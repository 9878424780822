// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  width: 100%;
  tr td:last-child {
    text-align: right;
  }
}
.divider {
  background-color: #dcdcdc;
  height: 1px;
  width: 100%;
  margin: 20px 0;
}
.DialogContent {
  background-color: #fafafa;
  border-radius: 4px;
  padding-top: 8px;
}
.dialogTitle {
  font-size: 1.5rem;
  font-family: Averta-Regular !important;
  color: #081D37DE;
}
.dialogText {
  font-size: 14px;
  font-family: Averta-Regular;
  color: #5A6874;
}
.dialogTextValue {
  color: #25323C;
  font-family: Averta-Regular;
  font-size: 14px;
}
.highlight {
  font-weight: 600;
}
.confirmTradeDialog {
  font-family: Averta-Regular !important;
  font-weight: 600 !important;
  letter-spacing: 1.5px !important;
  &:hover {
    background-color: #f5f6f7 !important;
  }
}
.confirmTradeDialogSuccess {
  font-family: Averta-Regular !important;
  font-weight: 600 !important;
  letter-spacing: 1.5px !important;
  &:hover {
    background-color: #f5f9fc !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/TradeTicketDrawer/ConfirmTradeDialog/confirmTradeDialog.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX;IACE,iBAAiB;EACnB;AACF;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,WAAW;EACX,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,sCAAsC;EACtC,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,2BAA2B;EAC3B,cAAc;AAChB;AACA;EACE,cAAc;EACd,2BAA2B;EAC3B,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,sCAAsC;EACtC,2BAA2B;EAC3B,gCAAgC;EAChC;IACE,oCAAoC;EACtC;AACF;AACA;EACE,sCAAsC;EACtC,2BAA2B;EAC3B,gCAAgC;EAChC;IACE,oCAAoC;EACtC;AACF","sourcesContent":[".table {\n  width: 100%;\n  tr td:last-child {\n    text-align: right;\n  }\n}\n.divider {\n  background-color: #dcdcdc;\n  height: 1px;\n  width: 100%;\n  margin: 20px 0;\n}\n.DialogContent {\n  background-color: #fafafa;\n  border-radius: 4px;\n  padding-top: 8px;\n}\n.dialogTitle {\n  font-size: 1.5rem;\n  font-family: Averta-Regular !important;\n  color: #081D37DE;\n}\n.dialogText {\n  font-size: 14px;\n  font-family: Averta-Regular;\n  color: #5A6874;\n}\n.dialogTextValue {\n  color: #25323C;\n  font-family: Averta-Regular;\n  font-size: 14px;\n}\n.highlight {\n  font-weight: 600;\n}\n.confirmTradeDialog {\n  font-family: Averta-Regular !important;\n  font-weight: 600 !important;\n  letter-spacing: 1.5px !important;\n  &:hover {\n    background-color: #f5f6f7 !important;\n  }\n}\n.confirmTradeDialogSuccess {\n  font-family: Averta-Regular !important;\n  font-weight: 600 !important;\n  letter-spacing: 1.5px !important;\n  &:hover {\n    background-color: #f5f9fc !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
