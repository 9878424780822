import { Grid, TextField } from "@mui/material";
import { defaultBorderColor, gridItemStyles, inputStyles, redErrorColor } from "../styles";
import { Control, Controller, FieldErrors, RegisterOptions, UseFormSetValue, UseFormWatch } from "react-hook-form";
import MinusIcon from "../MinusIcon";
import { insertNumberInput } from "../utils";
import PlusIcon from "../PlusIcon";
import { OrderType, TradeTicketTab } from "../../../types";
import { IFormInput } from "../types";

interface PriceInputByTickProps {
  name: keyof IFormInput;
  label: string;
  control: Control<IFormInput, any>;
  errors?: FieldErrors<IFormInput>;
  isEnabled?: boolean;
  value: number | string;
  tickSize: number;
  rules?: RegisterOptions;
  onTickChange: (step: number) => void;
  onInputChange: (inputValue: string) => void;
}

const fieldRules: RegisterOptions = {
  pattern: undefined,
  valueAsNumber: true,
  deps: [],
};

export const PriceInputByTick = ({
  name,
  label,
  control,
  errors,
  isEnabled = true,
  value,
  tickSize,
  rules,
  onTickChange,
  onInputChange,
}: PriceInputByTickProps) => {

  const handleInputChange = (e: any) => {
    insertNumberInput(e, true);

    if (e.target.value !== "" && (rules?.min || rules?.max)) {
      const ruleMinVal = rules?.min?.value;
      const ruleMaxVal = rules?.max?.value;

      if (!!ruleMinVal && e.target.value < ruleMinVal) {
        e.target.value = value;
      }

      if (!!ruleMaxVal && e.target.value > ruleMaxVal) {
        e.target.value = value;
      }
    }

    onInputChange(e.target.value);
  };

  const getFieldValue = <T,>(obj: Record<string, any>, key: string, elseValue: T): T => {
    return (key in obj ? obj[key] : elseValue);
  }

  return (
    <Grid item xs={6} sx={gridItemStyles}>
      <label className="label">{label}</label>
      <div className="control" style={{ borderColor: errors && getFieldValue(errors, name, false) ? redErrorColor : defaultBorderColor }}>

        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (<button
              type="button"
              className="minusButton"
              disabled={!isEnabled}
              onClick={() => onTickChange(-tickSize)}
            >
              <MinusIcon />
            </button>
            )
          }}
        />

        <Controller
          name={name}
          control={control}
          rules={{ ...fieldRules, ...rules }}
          render={({ field }) => {
            return (
              <TextField
                {...field}
                value={value}
                onInput={handleInputChange}
                disabled={!isEnabled}
                fullWidth
                variant="outlined"
                data-testid={name}
                sx={inputStyles}
              />
            )
          }}
        />

        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <button
              type="button"
              className="minusButton"
              disabled={!isEnabled}
              onClick={() => onTickChange(tickSize)}
            >
              <PlusIcon />
            </button>
          )}
        />

      </div>
    </Grid>
  );
};


