import React from 'react';
import { IconButton, Popover, MenuItem, Select } from '@mui/material';
import FilterIcon from './FilterIcon';

interface FilterProductsProps {
  selectedAssetType: string;
  handleFilterIconClick: (event: React.MouseEvent<HTMLElement>) => void;
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  setSelectedFilter: (value: number) => void;
  isSmallPanelFilter: boolean;
}

const FilterProducts = ({
  selectedAssetType,
  handleFilterIconClick,
  anchorEl,
  open,
  handleClose,
  setSelectedFilter,
  isSmallPanelFilter,
}: FilterProductsProps) => (
  <div className="asset-type-container">
    {isSmallPanelFilter ? (
      <div className={selectedAssetType === "" ? "asset-type" : ""}>
        <IconButton onClick={handleFilterIconClick}>
          <FilterIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{
            padding: 0,
            fontSize: '12px',
            '& .MuiSelect-select': {
              width: "196px",
              padding: '5px',
              fontSize: '12px',
              background: 'white',
            },
          }}
        >
            {[1, 2, 3, 4].map(value => (
                <MenuItem key={value} sx={{ width: "150px", fontSize: "14px", fontFamily: "Averta-Regular" }} onClick={() => { setSelectedFilter(value); handleClose(); }}>
                {value === 1 ? "Most Popular" : value === 2 ? "Volume" : value === 3 ? "A-Z" : "Z-A"}
                </MenuItem>
            ))}
        </Popover>
      </div>
    ) : (
      <Select
        sx={{
          padding: 0,
          minWidth: "196px",
          fontSize: '12px',
          '& .MuiSelect-select': {
            padding: '5px',
            fontSize: '12px',
            background: 'white',
          },
        }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedAssetType}
        onChange={(event) => setSelectedFilter(parseInt(event.target.value as string))}
      >
        {[1, 2, 3, 4].map(value => (
          <MenuItem key={value} value={value} sx={{ fontSize: "14px", fontFamily: "Averta-Regular" }}>
            {value === 1 ? "Most Popular" : value === 2 ? "Biggest Gainers" : value === 3 ? "Biggest Losers" : "A - Z"}
          </MenuItem>
        ))}
      </Select>
    )}
  </div>
);

export default FilterProducts;
