import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function MenuIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6673 7.5H3.33398V9.16667H16.6673V7.5ZM3.33398 12.5H16.6673V10.8333H3.33398V12.5Z" fill="#5A6874" />
      </svg>
    </SvgIcon>
  );
}

export function MoreIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.0007 13.3335C10.4427 13.3335 10.8666 13.5091 11.1792 13.8217C11.4917 14.1342 11.6673 14.5581 11.6673 15.0002C11.6673 15.4422 11.4917 15.8661 11.1792 16.1787C10.8666 16.4912 10.4427 16.6668 10.0007 16.6668C9.55862 16.6668 9.1347 16.4912 8.82214 16.1787C8.50958 15.8661 8.33398 15.4422 8.33398 15.0002C8.33398 14.5581 8.50958 14.1342 8.82214 13.8217C9.1347 13.5091 9.55862 13.3335 10.0007 13.3335ZM10.0007 8.3335C10.4427 8.3335 10.8666 8.50909 11.1792 8.82165C11.4917 9.13421 11.6673 9.55814 11.6673 10.0002C11.6673 10.4422 11.4917 10.8661 11.1792 11.1787C10.8666 11.4912 10.4427 11.6668 10.0007 11.6668C9.55862 11.6668 9.1347 11.4912 8.82214 11.1787C8.50958 10.8661 8.33398 10.4422 8.33398 10.0002C8.33398 9.55814 8.50958 9.13421 8.82214 8.82165C9.1347 8.50909 9.55862 8.3335 10.0007 8.3335ZM10.0007 3.3335C10.4427 3.3335 10.8666 3.50909 11.1792 3.82165C11.4917 4.13421 11.6673 4.55814 11.6673 5.00016C11.6673 5.44219 11.4917 5.86611 11.1792 6.17867C10.8666 6.49123 10.4427 6.66683 10.0007 6.66683C9.55862 6.66683 9.1347 6.49123 8.82214 6.17867C8.50958 5.86611 8.33398 5.44219 8.33398 5.00016C8.33398 4.55814 8.50958 4.13421 8.82214 3.82165C9.1347 3.50909 9.55862 3.3335 10.0007 3.3335Z"
          fill="#5A6874"
        />
      </svg>
    </SvgIcon>
  );
}

export function DoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3581 1.5C8.53618 3.3333 6.43975 5.43271 4.54526 7.33636L1.49698 4.73031L0 6.50607L3.86318 9.80304L4.67808 10.5L5.4326 9.73636C7.49109 7.66511 9.93778 5.21747 12 3.14243L10.3581 1.5Z"
          fill="#006EB6"
        />
      </svg>
    </SvgIcon>
  );
}
