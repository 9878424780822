import { v4 } from "uuid";

export enum AppMessageTypes {
  INFO_MESSAGE = "INFO_MESSAGE",
  ORDER_FILLED = "ORDER_FILLED",
  ORDER_REJECTED = "ORDER_REJECTED",
}

export enum AppMessageSubTypes {
  UNDEFINED = "-",
  NEW_MSG_RECEIVED = "NEW_MSG_RECEIVED",
}

export class AppMessageInfo {
  msgId: string;
  msgType: AppMessageTypes;
  msgSubType: AppMessageSubTypes;
  data: any;

  constructor(msgType: AppMessageTypes, msgSubType: AppMessageSubTypes, data: any) {
    this.msgId = v4();
    this.msgType = msgType;
    this.msgSubType = msgSubType;
    this.data = data;
  }
}

class WeakPublisher {
  private subscribers: WeakMap<any, (messagae: AppMessageInfo) => void>;
  private keys: Set<WeakRef<any>>;
  private messages: Map<string, AppMessageInfo> = new Map();

  constructor() {
    this.subscribers = new WeakMap<any, (message: AppMessageInfo) => void>();
    this.keys = new Set();
  }

  subscribe = (subscriber: any, callback: (messagae: AppMessageInfo) => void): void => {
    this.subscribers.set(subscriber, callback);
    this.keys.add(new WeakRef(subscriber));
  };

  unsubscribe = (subscriber: any): void => {
    this.keys.forEach((key) => {
      key.deref() === subscriber && this.keys.delete(key);
    });

    this.subscribers.delete(subscriber);
  };

  publish = (message: AppMessageInfo): void => {
    this.messages.set(message.msgId, message);
    this.relayMessage(new AppMessageInfo(AppMessageTypes.INFO_MESSAGE, AppMessageSubTypes.NEW_MSG_RECEIVED, null));
  };

  relayNextMessage = (): void => {
    const nextMessage = this.messages.values().next().value;
    this.relayMessage(nextMessage);
  };

  relayMessage = (message?: AppMessageInfo): void => {
    if (!message) {
      return;
    }

    this.keys.forEach((key) => {
      const keyObj = key.deref();

      if (keyObj) {
        const callback = this.subscribers.get(keyObj);

        if (callback) {
          callback(message);
        }
      }
    });
  };

  setMessageHandled = (msgId: string): void => {
    this.messages.has(msgId) && this.messages.delete(msgId);
    this.relayNextMessage();
  };
}

export const AppMessages = new WeakPublisher();
