import { GridColDef, GridColumnGroupingModel, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import RenderColorCell from "../../availableProducts/RenderColorCell";
import { DisplayUtil } from "../../../../cqg-api/utils/DisplayUtil";
import { InstrumentsManager } from "../../../../cqg-api/services/InstrumentsManager";
import { Widget, WidgetAction, WidgetType } from "../../../../types";
import workspacesActions from "../../../../redux/workspaces/workspacesActions";
import { setTradeTicketValue } from "../../../../redux/products/chartWidget";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { StrikePriceOptionsController } from "../../../../cqg-api/controllers/StrikePriceOptionsController";
import * as Order from "../../../../cqg-api/proto/order_2";

function renderChangeCell(params: GridRenderCellParams) {
  const stateOfRow = params.row?.[params?.field];
  const color = stateOfRow < 0 ? "#FF003D" : "#187E02";
  const sign = stateOfRow > 0 ? "+" : "";
  if (stateOfRow) {
    return <span style={{ color }}>{`${sign}${stateOfRow}`}</span>;
  }
  return <span></span>;
}

const useColumns = (selectedInstrument: any, workspaceId: number, controller: StrikePriceOptionsController | null) => {
  const dispatch = useDispatch();
  const columnGroupingModel: GridColumnGroupingModel = useMemo(
    () => [
      {
        groupId: "calls",
        headerName: "CALLS",
        description: "",
        headerClassName: "options-calls-section",
        children: [
          { field: "callsLow" },
          { field: "callsHigh" },
          { field: "callsPrior" },
          { field: "callsTheo" },
          { field: "callsVol" },
          { field: "callsTheta" },
          { field: "callsVega" },
          { field: "callsGamma" },
          { field: "callsDelta" },
          { field: "callsChange" },
          { field: "callsLast" },
          { field: "callsImpVol" },
          { field: "callsQty" },
          { field: "callsBid" },
          { field: "callsOffer" },
        ],
      },
      {
        groupId: "strikePrice",
        headerName: "",
        description: "",
        headerClassName: "strike-price-section",
        children: [{ field: "strikePrice" }],
      },
      {
        groupId: "puts",
        headerName: "PUTS",
        headerClassName: "options-puts-section",
        children: [
          { field: "putsBid" },
          { field: "putsOffer" },
          { field: "putsQty" },
          { field: "putsImpVol" },
          { field: "putsLast" },
          { field: "putsChange" },
          { field: "putsDelta" },
          { field: "putsGamma" },
          { field: "putsVega" },
          { field: "putsTheta" },
          { field: "putsVol" },
          { field: "putsTheo" },
          { field: "putsPrior" },
          { field: "putsLow" },
          { field: "putsHigh" },
        ],
      },
    ],
    [],
  );
  const openTradeTicketHandler = useCallback(
    (params: any, putCall?: "P" | "C") => {
      const instrument = {
        // TODO: need to verify options getInstrument
        ...InstrumentsManager.getInstrument(params.row.id),
        cqgSymbol: selectedInstrument?.cqgSymbol,
        displayName: selectedInstrument?.displayName,
        strikePrice: params.row.strikePrice,
      };
      const tradeTicketWidgetValue: Widget = {
        key: 3,
        title: WidgetType.TradeTicket,
        widgetType: WidgetType.TradeTicket,
        expired: instrument?.lastPrice || instrument?.strikePrice ? false : true,
        widgetData: {
          action: WidgetAction.Options,
          data: {
            ...params.row,
            putCallValue: putCall,
            orderType: Order.Order_OrderType.ORDER_TYPE_LMT,
          },
        },
      };
      dispatch(
        workspacesActions.dispatchSetWorkspace({
          workspaceId: workspaceId,
          instrument: instrument,
        }),
      );
      dispatch(setTradeTicketValue(tradeTicketWidgetValue));
    },
    [dispatch, selectedInstrument?.cqgSymbol, selectedInstrument?.displayName, workspaceId],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "callsLow",
        headerName: "LOW",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.callsLow}>{params.row.callsLow}</span>,
      },
      {
        field: "callsHigh",
        headerName: "HIGH",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.callsHigh}>{params.row.callsHigh}</span>,
      },
      {
        field: "callsPrior",
        headerName: "PRIOR",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.callsPrior}>{params.row.callsPrior}</span>,
      },
      {
        field: "callsTheo",
        headerName: "THEO",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.callsTheo}>{params.row.callsTheo}</span>,
      },
      {
        field: "callsVol",
        headerName: "VOL",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.callsVol}>{params.row.callsVol}</span>,
      },
      {
        field: "callsTheta",
        headerName: "θ",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.callsTheta}>{params.row.callsTheta}</span>,
      },
      {
        field: "callsVega",
        headerName: "V",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.callsVega}>{params.row.callsVega}</span>,
      },
      {
        field: "callsGamma",
        headerName: "Γ",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.callsGamma}>{params.row.callsGamma}</span>,
      },
      {
        field: "callsDelta",
        headerName: "Δ",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.callsDelta}>{params.row.callsDelta}</span>,
      },
      {
        field: "callsChange",
        headerName: "CHNGE",
        flex: 1,
        width: 42,
        renderCell: renderChangeCell,
        headerAlign: "center",
        type: "number",
      },
      {
        field: "callsLast",
        headerName: "LAST",
        flex: 1,
        width: 42,
        renderCell: (params: GridRenderCellParams) => {
          return <RenderColorCell value={DisplayUtil.toDisplayPrice(params.row.callsLast, params.row.callOption)} />;
        },
        headerAlign: "center",
        type: "number",
      },
      { field: "callsImpVol", headerName: "IMP VOL", flex: 1, width: 42, headerAlign: "center", type: "number" },
      {
        field: "callsQty",
        headerName: "QTY",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.callsQty}>{params.row.callsQty}</span>,
      },
      {
        field: "callsBid",
        headerName: "BID",
        flex: 1,
        minWidth: 72,
        renderCell: (params) => {
          return (
            <div
              className={clsx({
                "options-bid": params.row.callsBid,
              })}
              onClick={() => openTradeTicketHandler(params, "C")}
            >
              <span>{params.row.callsBid}</span>
            </div>
          );
        },
        headerAlign: "center",
        type: "number",
      },
      {
        field: "callsOffer",
        headerName: "OFFER ",
        flex: 1,
        minWidth: 72,
        renderCell: (params) => {
          return (
            <div
              onClick={() => openTradeTicketHandler(params, "C")}
              className={clsx({
                "options-offer": params.row.callsOffer,
              })}
            >
              <span>{params.row.callsOffer}</span>
            </div>
          );
        },
        headerAlign: "center",
        type: "number",
      },

      {
        field: "strikePrice",
        headerName: "",
        cellClassName: "options-strike-price--cell",
        type: "number",
        renderHeader: () => {
          return (
            <span
              style={{
                position: "absolute",
                top: "-12px",
                left: "50%",
                color: "#25323C",
                transform: "translate(-50%)",
              }}
            >
              STRIKE <br />
              &nbsp;PRICE
            </span>
          );
        },
        flex: 1,
        minWidth: 60,
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "options-strikePrice-main",
        renderCell: (params) => {
          return (
            <div
              onClick={() => openTradeTicketHandler(params)}
              className={clsx({
                "options-strike-price": true,
                "options-strike-price--atmStrike": params.row.strikePrice === controller?.atmStrike,
              })}
            >
              {params.row.strikePrice}
            </div>
          );
        },
        headerAlign: "center",
      },

      {
        field: "putsBid",
        headerName: "BID",
        flex: 1,
        minWidth: 72,
        renderCell: (params) => {
          return (
            <div
              className={clsx({
                "options-bid": params.row.putsBid,
              })}
              onClick={() => openTradeTicketHandler(params, "P")}
            >
              <span>{params.row.putsBid}</span>
            </div>
          );
        },
        headerAlign: "center",
        type: "number",
      },
      {
        field: "putsOffer",
        headerName: "OFFER ",
        flex: 1,
        minWidth: 72,
        renderCell: (params) => {
          return (
            <div
              onClick={() => openTradeTicketHandler(params, "P")}
              className={clsx({
                "options-offer": params.row.putsOffer,
              })}
            >
              <span>{params.row.putsOffer}</span>
            </div>
          );
        },
        headerAlign: "center",
        type: "number",
      },
      {
        field: "putsQty",
        headerName: "QTY",
        flex: 1,
        width: 42,
        type: "number",
        renderCell: (params) => <span title={params.row.putsQty}>{params.row.putsQty}</span>,
      },
      { field: "putsImpVol", headerName: "IMP VOL", flex: 1, width: 42, type: "number" },
      {
        field: "putsLast",
        headerName: "LAST",
        flex: 1,
        width: 42,
        renderCell: (params: GridRenderCellParams) => {
          return <RenderColorCell value={DisplayUtil.toDisplayPrice(params.row.putsLast, params.row.putOption)} />;
        },
        headerAlign: "center",
        type: "number",
      },
      {
        field: "putsChange",
        headerName: "CHNGE",
        flex: 1,
        width: 42,
        renderCell: renderChangeCell,
        headerAlign: "center",
        type: "number",
      },
      {
        field: "putsDelta",
        headerName: "Δ",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.putsDelta}>{params.row.putsDelta}</span>,
      },
      {
        field: "putsGamma",
        headerName: "Γ",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.putsGamma}>{params.row.putsGamma}</span>,
      },
      {
        field: "putsVega",
        headerName: "V",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.putsVega}>{params.row.putsVega}</span>,
      },
      {
        field: "putsTheta",
        headerName: "θ",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.putsTheta}>{params.row.putsTheta}</span>,
      },
      {
        field: "putsVol",
        headerName: "VOL",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.putsVol}>{params.row.putsVol}</span>,
      },
      {
        field: "putsTheo",
        headerName: "THEO",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.putsTheo}>{params.row.putsTheo}</span>,
      },
      {
        field: "putsPrior",
        headerName: "PRIOR",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.putsPrior}>{params.row.putsPrior}</span>,
      },
      {
        field: "putsLow",
        headerName: "LOW",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.putsLow}>{params.row.putsLow}</span>,
      },
      {
        field: "putsHigh",
        headerName: "HIGH",
        flex: 1,
        width: 42,
        headerAlign: "center",
        type: "number",
        renderCell: (params) => <span title={params.row.putsHigh}>{params.row.putsHigh}</span>,
      },
    ],
    [controller?.atmStrike, openTradeTicketHandler],
  );
  return { columnGroupingModel, columns };
};

export default useColumns;
