import { DockviewApi, IDockviewPanelProps } from "dockview";
import { DockerviewPanelProps, Widget, WidgetType, Workspace } from "../../types";
import AccountValue from "../widgets/accountValue/AccountValue";
import AvailableProducts from "../widgets/availableProducts/AvailableProducts";
import Orders from "../widgets/orders/Orders";
import Positions from "../widgets/positions/Positions";
import Watchlist from "../widgets/watchlist/Watchlist";
import EnergyChart from "../chart/EnergyChart";
import TradeLog from "../widgets/TradeLog/index";
import Trade from "../TradeTicketDrawer/Trade";
import BarChart from "../chart/BarChart";
import TradingChart from "../widgets/tradingChart/TradingChart";
import React from "react";
import PriceLadder from "../widgets/priceLadder/PriceLadder";

let panelCount = 0;

const TRADE_TICKET_HEIGHT = 700;
const TRADE_TICKET_WIDTH = 384;

const generateUniqueId = () => {
  return `id${Date.now().toString()}${(panelCount++).toString()}`;
};

export const addPanels = (workspace: Workspace, dockviewApi: DockviewApi, widgets: Widget[], referenceGroup: any) => {
  let referencePanel: any = undefined;
  let referencePanel2: any = undefined;
  widgets?.forEach((widget, index) => {
    const uniqueId = generateUniqueId();
    const panel = dockviewApi.addPanel<DockerviewPanelProps>({
      id: uniqueId,
      component: "default",
      renderer: "always",
      title: widget.widgetType,
      params: {
        widget,
        workspace,
      },
      position: {
        referencePanel: index % 2 === 0 && !referenceGroup ? referencePanel2 : referencePanel,
        referenceGroup: referenceGroup,
        direction: !referenceGroup
          ? index > 0
            ? index % 2 === 0
              ? "below"
              : index < 2
                ? "right"
                : "below"
            : "within"
          : undefined,
      },
    });

    if (!referenceGroup) {
      if (index % 2 === 0) {
        referencePanel2 = panel;
      } else {
        referencePanel = panel;
      }
    } else {
      if (index % 2 === 0) {
        referencePanel = panel;
      }
    }
  });
};

export const addPanel = (dockviewApi: DockviewApi, widget: Widget, workspace: Workspace) => {
  const uniqueId = generateUniqueId();
  /*
    Set the first panel active
    A fix for not adding widgets inside trading ticket and always in the main panel
  */
  if (dockviewApi.panels.length > 0 && dockviewApi.panels[0]) {
    dockviewApi.panels[0].api.setActive();
  }
  dockviewApi.addPanel<DockerviewPanelProps>({
    id: uniqueId,
    component: "default",
    renderer: "always",
    title: widget.widgetType === WidgetType.PriceLadder ? widget.widgetName : widget.widgetType,
    params: {
      widget,
      workspace,
    },
  });
};
export const addTradingTicketPanel = (
  dockviewApi: DockviewApi,
  widget: Widget,
  workspace: Workspace,
  dockviewRef: React.MutableRefObject<null | any>,
) => {
  const uniqueId = generateUniqueId();
  const left = dockviewRef?.current?.clientWidth / 2 - TRADE_TICKET_WIDTH / 2;
  const top = dockviewRef?.current?.clientHeight / 2 - TRADE_TICKET_HEIGHT / 2;

  const panel = dockviewApi.addPanel<DockerviewPanelProps>({
    id: uniqueId,
    component: "default",
    renderer: "always",
    title: "Trade",
    floating: {
      width: TRADE_TICKET_WIDTH,
      height: TRADE_TICKET_HEIGHT,
      position: {
        left,
        top,
      },
    },
    params: {
      widget,
      workspace,
    },
  });
  panel.group.locked = "no-drop-target";
};

export const addChartPanel = (dockviewApi: DockviewApi, widget: Widget, workspace: Workspace) => {
  const uniqueId = generateUniqueId();
  dockviewApi.addPanel<DockerviewPanelProps>({
    id: uniqueId,
    component: "default",
    renderer: "always",
    title: widget.widgetType,
    params: {
      widget,
      workspace,
    },
    position: { direction: "right" },
  });
};

export const componentFactory = (widget: Widget, workspace: Workspace) => {
  if (!workspace?.id) return <>No workspace found</>;

  const workspaceClassName = "grid-layout-workspace";

  // Helper function for chart components with common props
  const renderChart = (ChartComponent: React.ElementType) => (
    <ChartComponent selectedWorkspace={workspace} workspaceClassName={workspaceClassName} />
  );

  // Consolidated conditions for TradingChart widgets
  const isTradingChartWidget = widget.title === WidgetType.Chart || widget.title === WidgetType.PriceLadder;

  // Switch logic for widget rendering
  switch (widget.title) {
    case WidgetType.Watchlist:
      return <Watchlist widget={widget} workspaceId={workspace.id} workspaceClassName={workspaceClassName} />;
    case WidgetType.AccountInfo:
      return <AccountValue selectedWorkspace={workspace} workspaceClassName={workspaceClassName} />;
    case WidgetType.AvailableProducts:
      return <AvailableProducts selectedWorkspace={workspace} workspaceClassName={workspaceClassName} />;
    case WidgetType.Orders:
      return <Orders workspaceId={workspace.id} workspaceClassName={workspaceClassName} />;
    case WidgetType.Positions:
      return <Positions workspaceId={workspace.id} workspaceClassName={workspaceClassName} />;
    case WidgetType.TradeLogs:
      return <TradeLog />;
    case WidgetType.EnergyChart:
      return (
        <EnergyChart selectedWorkspace={workspace} workspaceClassName={workspaceClassName} isPopupWindow={undefined} />
      );
    case WidgetType.BarChart:
      return (
        <BarChart selectedWorkspace={workspace} workspaceClassName={workspaceClassName} isPopupWindow={undefined} />
      );
    case WidgetType.TradeTicket:
      return <Trade workspaceId={workspace.id} />;
    case WidgetType.PriceLadder:
      return <PriceLadder workspace={workspace} widget={widget} />;
    // case WidgetType.LineChart:
    //   return (
    //     <LineChartDemo
    //       selectedWorkspace={workspace}
    //       workspaceClassName={workspaceClassName}
    //       isPopupWindow={undefined}
    //     />
    //   );
    // case WidgetType.DoubleBarChart:
    //   return (
    //     <BarChartDemo selectedWorkspace={workspace} workspaceClassName={workspaceClassName} isPopupWindow={undefined} />
    //   );

    default:
      if (isTradingChartWidget) {
        return <TradingChart symbols={widget.contractIds} />;
      }
      return <div>No widget found</div>;
  }
};

function RenderWhenVisible(component: React.FunctionComponent<IDockviewPanelProps>) {
  const HigherOrderComponent = (props: IDockviewPanelProps) => {
    const [visible, setVisible] = React.useState<boolean>(props.api.isVisible);

    React.useEffect(() => {
      const disposable = props.api.onDidVisibilityChange((event) => setVisible(event.isVisible));

      return () => {
        disposable.dispose();
      };
    }, [props.api]);

    if (!visible) {
      return null;
    }

    return React.createElement(component, props);
  };
  return HigherOrderComponent;
}

export const components = {
  default: RenderWhenVisible((props: IDockviewPanelProps<DockerviewPanelProps>) => {
    return (
      <div
        style={{
          height: "100%",
          overflow: "auto",
          color: "white",
          position: "relative",
          width: "100%",
        }}
      >
        {componentFactory(props.params.widget, props.params.workspace)}
      </div>
    );
  }),
};
