import { useCallback, useEffect, useMemo, useState } from "react";
import { useFetchProducts } from "../../../hooks/api/product";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DataGridTable from "../../shared/DataGrid";
import { usePositionsList } from "../../../cqg-api/hooks/ServiceHooks";
import PositionsFilter from "./PositionsFilter";
import "./Positions.css";
import { PositionsController } from "../../../cqg-api/controllers/PositionsController";
import { AggregatedPosition } from "../../../cqg-api/models/AggregatedPosition";
import { PositionDisplayEnum, positionsFilter, Widget, WidgetType, } from "../../../types";
import useResponsivePanel from "../../../hooks/useResponsivePanel";
import { Box } from "@mui/material";
import ConfirmCancelDialog from "../../shared/ConfirmCancelDialog";
import { getPosition } from "./utils";
import workspacesActions from "../../../redux/workspaces/workspacesActions";
import { useDispatch } from "react-redux";
import { InstrumentsManager } from "../../../cqg-api/services/InstrumentsManager";
import { setTradeTicketValue } from "../../../redux/products/chartWidget";
import TradeButton from "../../shared/TradeButton";

const Positions = (props: {workspaceClassName: string; workspaceId: number }) => {
  const [activeFilter, setActiveFilter] = useState("All");
  const { loadProducts } = useFetchProducts();
  const { positionsList } = usePositionsList();
  const { panelRef, isSmallPanel } = useResponsivePanel(400);
  const [openAllFlattenDialog, setOpenAllFlattenDialog] = useState(false);
  const [openSingleFlattenDialog, setOpenSingleFlattenDialog] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState<AggregatedPosition | null>(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const handleFlattenPosition = useCallback((position: AggregatedPosition) => {
    setSelectedPosition(position); 
    setOpenSingleFlattenDialog(true); 
  }, []);

  const handleConfirmFlatten = useCallback(() => {
    if (selectedPosition) {
      setLoading(true);
      PositionsController.flattenPosition(selectedPosition); 
    }
    setLoading(false);
    setOpenSingleFlattenDialog(false); 
  }, [selectedPosition]);

  const handleAllFlattenPosition = useCallback(() => {
    setLoading(true);
    try {
      PositionsController.flattenAllPositions(positionsList);
    } finally {
      setLoading(false);
      setOpenAllFlattenDialog(false);
    }
  }, [positionsList]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "symbol",
        headerName: "Symbol",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "positions-table--header",
      },
      {
        field: "contract",
        headerName: "Contract",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "positions-table--header",
      },
      {
        field: "month",
        headerName: "Mo",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "positions-table--header",
      },
      {
        field: "strike",
        headerName: "Strike",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "positions-table--header",
        renderCell: (params) => <span>{params.value === 0 ? "" : params.value}</span>,
      },
      {
        field: "cp",
        headerName: "C/P",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "positions-table--header",
      },
      {
        field: "direction",
        headerName: "Position",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "positions-table--header",
      },
      {
        field: "buys",
        headerName: "Buys",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "positions-table--header",
      },
      {
        field: "sells",
        headerName: "Sells",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "positions-table--header",
      },
      {
        field: "averagePX",
        headerName: "Average PX",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "positions-table--header",
      },
      {
        field: "unrealizedPL",
        headerName: "Unrealized P/L",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "positions-table--header",
      },
      {
        field: "realizedPL",
        headerName: "Realized P/L",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "positions-table--header",
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        minWidth: 150,
        sortable: false,
        headerClassName: "order-table--header",
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span className="starIcon">
              <TradeButton
                data-testid={`trade-button-${params.row.id}`}
                enabled={true}
                handleClick={() => {
                  const instrument = InstrumentsManager.getInstrument(params.row.id);
                  const tradeTicketWidgetValue: Widget = {
                    key: 3,
                    title: WidgetType.TradeTicket,
                    widgetType: WidgetType.TradeTicket,
                    expired: (instrument?.lastPrice || instrument?.strikePrice) ? false : true,
                  };
                  dispatch(
                    workspacesActions.dispatchSetWorkspace({
                      workspaceId: props?.workspaceId,
                      instrument: instrument,
                    }),
                  );
                  dispatch(setTradeTicketValue(tradeTicketWidgetValue));
                }}
              />
              <button className="starIcon">
                {params.row.direction && (
                  <>
                    <Box
                      sx={{
                        width: '2px',
                        height: '18px',
                        background: 'rgba(0, 0, 0, 0.12)',
                      }}
                    />
                    <div className="icon" onClick={() => handleFlattenPosition(params.row.position)}>
                      FLATTEN
                    </div>
                  </>
                )}
              </button>
            </span>

          );
        },
      },
    ],
    [dispatch, handleFlattenPosition, props?.workspaceId],
  );

  const dataRows: any = positionsList
    .map((item) => ({
      id: item.contractId,
      trade: item?.instrument?.displayName,
      symbol: item.displaySymbol,
      contract: item?.instrument?.cmeSymbolName,
      month: item.instrument?.month,
      strike: item.instrument?.strikePrice,
      cp: item.instrument?.putCallAbbrev ? item.instrument.putCallAbbrev() : "-",
      direction: getPosition(item.displayLong, item.displayShort, PositionDisplayEnum.LongShort),
      buys: item.buys,
      sells: item.sells,
      averagePX: item.displayPrice,
      unrealizedPL: item.displayOteMvo.toFixed(2),
      realizedPL: item.displayRpl.toFixed(2),
      flatten: "",
      size: item.size,
      position: item,
    }))
    .filter((position) => {
      if (activeFilter === positionsFilter.Open) {
        return position.direction !== undefined && position.direction !== "";
      }
      if (activeFilter === positionsFilter.Closed) {
        return position.direction === undefined || position.direction === "";
      }
      return true;
    });

    const hasPositionsWithDirection = useMemo(() => {
      return dataRows.some((position:any) => position.direction);
    }, [dataRows]);


    const getNoDataMessage = useMemo(() => {
      switch (activeFilter) {
        case positionsFilter.Open:
          return "No open positions available";
        case positionsFilter.Closed:
          return "No closed positions available";
        default:
          return "You currently have no open positions";
      }
    },[activeFilter]);

  return (
    <>
      <div ref={panelRef} className="widget-parent-container">
        <div className="draggableCancelSelector widget-grid-container positions-container">
          <div className="positions-header">
            <PositionsFilter activeFilter={activeFilter} setActiveFilter={setActiveFilter} isSmallPanel={isSmallPanel} />
            <div className={`cancel-all-orders ${!hasPositionsWithDirection ? "disabled-position" : ""}`} 
              onClick={hasPositionsWithDirection ? () => setOpenAllFlattenDialog(true) : undefined}
            >
              FLATTEN ALL POSITIONS
            </div>
          </div>
          <div className="positions-grid-panel">
            <DataGridTable columns={columns} rows={dataRows} noDataMessage={getNoDataMessage} />
          </div>
        </div>
      </div>
      {openAllFlattenDialog && 
        <ConfirmCancelDialog
          open={openAllFlattenDialog}
          onClose={() => setOpenAllFlattenDialog(false)}
          onConfirm={handleAllFlattenPosition}
          loading={loading}
          title="Flatten All Positions"
          message="Are you sure you want to flatten all positions?"
          cancelText="Cancel"
          confirmText="Yes, Flatten"
        />
      }
      {openSingleFlattenDialog && 
        <ConfirmCancelDialog
          open={openSingleFlattenDialog}
          onClose={() => setOpenSingleFlattenDialog(false)}
          onConfirm={handleConfirmFlatten}
          loading={loading}
          title="Flatten Position"
          message={
            <span>
              Are you sure you want to flatten 
              <span style={{ color: 'blue' }}> {selectedPosition?.instrument?.displayName}</span> position?
            </span>
          }
          cancelText="Cancel"
          confirmText="Yes, Flatten"
        />
      }
    </>
  );
};

export default Positions;
