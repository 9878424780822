import * as WebAPI from "../proto/webapi_2";
import { UnderlyingSubscription } from "./SymbolsUnderlyingSubscriptions";

export class SubscriptionService {
  private subscriptions: Record<string, UnderlyingSubscription> = {};

  public add(searchItem: string, requestId: string): UnderlyingSubscription {
    const subscription = this.createSubscription(searchItem, requestId);
    this.subscriptions[searchItem] = subscription;
    return subscription;
  }

  public addSubscription(searchItem: string, subscription: UnderlyingSubscription) {
    this.subscriptions[searchItem] = subscription;
  }

  public createSubscription(searchItem: string, requestId: string): UnderlyingSubscription {
    throw new Error("not implemented");
  }

  public async processInformationReport(informationReport: WebAPI.InformationReport[]): Promise<void> {
    for (const item of informationReport) {
      if (item.statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_DISCONNECTED) {
        console.warn("Disconnected status code was received for symbol resolution subscription.");
        continue;
      }

      await this.processInformationReportItem(item);
    }
  }

  public clear(): void {
    this.subscriptions = {};
  }

  public getSubscription(key: string): UnderlyingSubscription | undefined {
    return this.subscriptions[key];
  }

  private findByRequestId(id: string): UnderlyingSubscription | null {
    for (const subscription of Object.values(this.subscriptions)) {
      if (subscription.requestId === id) {
        return subscription;
      }
    }
    return null;
  }

  private async processInformationReportItem(informationReport: WebAPI.InformationReport): Promise<UnderlyingSubscription | null> {
    const subscription = this.findByRequestId(informationReport.id.toString());
    if (subscription) {
      await subscription.update(informationReport); 
    }
    return subscription;
  }
}

export class InformationSubscriptions {
  public SubscriptionsManager;

  constructor() {
    this.SubscriptionsManager = new SubscriptionService();
  }
}
