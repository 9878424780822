import { Box, Link, Stack, Typography } from "@mui/material";
import InputBox from "../InputBox";
import { Control } from "react-hook-form";
import { TradeLogProps } from "../../../../../types";

const RiskParameter = ({ control }: { control: Control<TradeLogProps, any> }) => {
  return (
    <Box component="section" className="section-outer">
      <Stack
        direction={{ md: "column", lg: "row" }}
        spacing={2}
        sx={{
          justifyContent: { xs: "center", md: "space-between" },
          alignItems: { xs: "center", md: "flex-start" },
          gap: { xs: 1, md: 2, lg: 10 },
        }}
      >
        <Box className="section-left" sx={{ width: { xs: "100%", md: "auto" } }}>
          <Box className="section-sub-left">
            <Typography className="section-title">Section A:</Typography>
            <Typography className="section-sub-title">Risk Parameters</Typography>
          </Box>

          <Box className="section-sub-right">
            <Typography className="section-middle" fontSize="12px">
              Establishing your monetary boundaries is essential to maintaining perspective and trading longevity.
              Decide now—before the heat of battle begins—on your money management strategy and how much money you're
              willing to risk per idea and trade. Your answers will help determine number of positions you hold,
              position size and stop levels.
            </Typography>
            <Link
              href="https://www.cmegroup.com/education/courses/building-a-trade-plan.html"
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more in Building A Trade Plan
            </Link>
          </Box>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "auto" } }}>
          <Box marginBottom="32px">
            <InputBox
              control={control}
              title="Dollars to risk per trade"
              name="capitalRiskPerTrade"
              tooltipText="How many dollars am I willing to lose (difference between entry and exit prices) on this trade?"
            />
          </Box>
          <Box marginBottom="32px">
            <InputBox
              control={control}
              title="% willing to risk per trade"
              name="pctCapitalRiskPerTrade"
              tooltipText="What percent of my total account am I willing to lose (difference between entry and exit prices) on this trade?"
            />
          </Box>
          <Box>
            <InputBox
              control={control}
              title="% buying power willing to commit per trade"
              name="pctCapitalToMargin"
              tooltipText="What percent of my total capital am I willing to commit as initial margin to a single trade idea?"
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default RiskParameter;
