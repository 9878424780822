const monthMap: { [key: string]: string } = {
  F: "JAN",
  G: "FEB",
  H: "MAR",
  J: "APR",
  K: "MAY",
  M: "JUN",
  N: "JUL",
  Q: "AUG",
  U: "SEP",
  V: "OCT",
  X: "NOV",
  Z: "DEC",
};
export const monthMapCapitalize: { [key: string]: string } = {
  F: "Jan",
  G: "Feb",
  H: "Mar",
  J: "Apr",
  K: "May",
  M: "Jun",
  N: "Jul",
  Q: "Aul",
  U: "Sep",
  V: "Oct",
  X: "Nov",
  Z: "Dec",
};

export const convertSymbolToDate = (symbol: any) => {
  const len = symbol.length;

  const monthChar: any = symbol[len - 2];
  const yearChar = symbol[len - 1];

  // Get the month name
  const monthName = monthMap[monthChar];

  // Construct the year
  const year = "202" + yearChar; // Assuming the year is in 2000s

  // Return the formatted string
  return `${monthChar} - ${monthName} ${year}`;
};
export const convertSymbolToDateOnlyFullYear = (symbol: any, month: string) => {
  const len = symbol.length;

  const monthChar: any = symbol[len - 2];
  const yearChar = symbol[len - 1];
  const monthName = monthMapCapitalize[monthChar] ?? month;

  return { monthName, yearChar };
};
export enum YearFormat {
  FULL = "FULL",
  SHORT = "SHORT",
}
export const convertSymbolToYear = (symbol: any, month: string, format: YearFormat = YearFormat.FULL) => {
  const converted = convertSymbolToDateOnlyFullYear(symbol, month);
  if (format === YearFormat.FULL) {
    return `${converted.monthName} 202${converted.yearChar}`;
  }
  return `${converted.monthName} 2${converted.yearChar}`;

};