
import React from 'react';
import Trade from './Trade';
import { Instrument } from '../../cqg-api/models/Instrument';
import Dialog from '@mui/material/Dialog';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import './TradeTicketDrawer.css';
import PriceIcon from '../widgets/availableProducts/Price';

// Define the prop types
interface TradeTicketDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  contracts: Instrument[];
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}


// Create the functional component
const TradeTicketDrawer: React.FC<TradeTicketDrawerProps> = ({ open, setOpen, contracts }) => {
  console.log('selectedContract', contracts);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const handleClose = () => {
    setOpen(false);
  }
  return (

    <Dialog
    open={open}
    onClose={handleClose}
    disableEscapeKeyDown
    hideBackdrop
    PaperComponent={PaperComponent}
    aria-labelledby="draggable-dialog-title"
  >
    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className="dialogTitle">
    <div>
    <PriceIcon /> Trade
    </div>
      <CloseIcon />
    </DialogTitle>
    <DialogContent
    
    sx={{
      padding: '0px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      color: '#333', // text color
      maxWidth: '600px', // width of the content
    }}
    >
    <Trade contracts={contracts} />
    </DialogContent>
  </Dialog>
  );
};

// Export the component as the default export
export default TradeTicketDrawer;
