import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "../styles";

interface ModalHeaderProps {
  data: any;
  toggleDrawer: () => () => void;
}

const ModalHeader = ({ data, toggleDrawer }: ModalHeaderProps) => {
  const classes = useStyles();
  return (
    <Box justifyContent={"space-between"} sx={{ padding: "6px 12px", display: "flex" }} alignItems="center">
      <div className={classes.contractTitle}>{data.contract}</div>
      <IconButton onClick={toggleDrawer()} color="inherit">
        <CloseIcon sx={{ color: "#5A6874", height: "12px", width: "12px" }} />
      </IconButton>
    </Box>
  );
};

export default ModalHeader;
