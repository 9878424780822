import * as _ from "../../vendors/underscore-esm";
import { CQGService } from "../services/CQGService";
import { CQGEnvironment } from "../services/CQGEnvironment";
import * as MarketData from "../proto/market_data_2";
import { Instrument } from "../models/Instrument";
import { InstrumentsManager } from "../services/InstrumentsManager";
import { OptionMaturityMetadata } from "../proto/metadata_2";
import { ChildSymbolsSubscription } from "../services/ChildSymbolsSubscriptions";

class StrikeOptions {
  constructor(
    public strike: number,
    public putOption?: any,
    public callOption?: any,
  ) {}
}

interface StrikePriceOptionsControllerProps {
  addOrUpdate: (contractMetadata: any) => any;
  instruments: Instrument;
}

export class StrikePriceOptionsController {
  public strikeOptions: StrikeOptions[] | null = null;
  public loadStatus: string | null = null;
  public loadError: boolean = false;
  public atmStrike: null = null;
  public subscribeOptions: boolean = true;
  public isMarketDataNotAvailable: boolean = false;
  public childSymbolsValue: any[] = [];

  private level: number;
  public maxStrikesScrollbar: number = 6;

  constructor(private instruments: StrikePriceOptionsControllerProps) {
    this.level = MarketData.MarketDataSubscription_Level.LEVEL_TRADES_BBA_VOLUMES;
  }

  public onShowOptions(value: { show: boolean; instrument: any }) {
    if (value.show) {
      this.reset();
      this.processOptions(value.instrument);
    } else {
      this.reset();
    }
  }

  public onMarketData(value: boolean) {
    this.isMarketDataNotAvailable = value;
  }

  public getNetChangeClass(instrument: any): string {
    return instrument.labelPriceNetChange &&
      instrument.labelPriceNetChange.length > 0 &&
      instrument.labelPriceNetChange[0] === "-"
      ? "negative"
      : "positive";
  }

  public scrollbarMarginClass(): string {
    return this.strikeOptions && this.strikeOptions.length > this.maxStrikesScrollbar ? "scrollbar-margin" : "";
  }

  private reset() {
    if (this.subscribeOptions) {
      this.unsubscribeOptions();
    }
    this.strikeOptions = null;
    this.atmStrike = null;
  }

  public async processOptions(symbol: any) {
    if (!symbol) return;

    this.setStatus(`Loading Options for ${symbol.displayName}. Please wait ...`);

    const active = symbol;

    CQGService.requestSymbolsByUnderlying(active.contractId).then((symbols: OptionMaturityMetadata[]) => {
      // console.log(`Received symbols: ${JSON.stringify(symbols)}`);

      if (symbols.length == 0) {
        this.setStatus("This product does not have an options contract", true);
        return;
      }

      let underlying = this.getUnderlyingOfInterest(active, symbols);
      console.log(`Underlying of interest: ${underlying ? underlying.name : "none"}`);

      if (_.isUndefined(underlying) || _.isNull(underlying)) {
        this.setStatus("This product does not have an options contract", true);
      } else {
        CQGService.requestChildSymbols(underlying.id).then(
          (childSymbols: ChildSymbolsSubscription) => {
            // console.log(`Received child symbols: ${JSON.stringify(childSymbols)}`);

            if (childSymbols && childSymbols.childSymbols && childSymbols.childSymbols.length > 0) {
              CQGService.requestAtTheMoneyStrike(underlying.id).then(
                (strike: any) => {
                  console.log(`ATM Strike retrieved: ${strike}`, strike);
                  if (this.createOptions(childSymbols.childSymbols, strike, active)) {
                    if (this.subscribeOptions) {
                        this.subscribeOptionsHandler();
                    }
                    this.resetStatus();

                  // setTimeout(() => {
                  //   var x = $("#options-data .options-body")[0];
                  //   var scrollHeight = (x.scrollHeight - x.offsetTop) / 2;
                  //   x.scrollTo(0, scrollHeight);
                  // }, 200);
                  }
                },
                (error: any) => {
                    this.setStatus("Unable to determine ATM Strike for underlying: " + underlying.name, true);
                },
              );
            } else {
              this.setStatus("No child symbols available for underlying: " + underlying.name, true);
            }
          },
          (error: any) => {
            this.setStatus("Unable to fetch options for underlying: " + underlying.name, true);
          },
        );
      }
    });
  }

  private getUnderlyingOfInterest(instrument: any, symbols: any[]): any | null {
    const root = instrument.cqgOptionSymbol ? instrument.cqgOptionSymbol : instrument.getCQGRootSymbol();

    if (_.isNull(root)) {
      //   this.$log.warn(`Unable to determine format of underlying symbol of interest for instrument '${instrument.title}'`);
      return null;
    }

    const format = `${root}/${instrument.contractSymbol.substr(-3, 1)}${instrument.contractSymbol.substr(-2, 2)}`;
    return _.find(symbols, function (symbol: any) {
      return symbol.name.indexOf(format) == 0;
    });
  }

  private createOptions(childSymbols: any[], strike: any, active: any): boolean {
    let uniqueStrikes: number[] = [];
    let strikeOptions: { [key: number]: StrikeOptions } = {};
    let maxStrikesUpDownAtm = active.numStrikeRows;

    _.forEach(childSymbols, (child: any) => {
      if (uniqueStrikes.indexOf(child.contractMetadata.strike) === -1) {
        uniqueStrikes.push(child.contractMetadata.strike);
      }
    });
    // this.childSymbolsValue = uniqueStrikes;

    // console.log(
    //   `Unique Strikes:::::::::::::::::::::::::::::::::::: ${JSON.stringify(uniqueStrikes)}`,
    //   childSymbols,
    //   strike,
    //   active,
    // ); 
    let atmIndex = uniqueStrikes.indexOf(strike);

    if (atmIndex === -1) {
      this.setStatus("Could not find AtTheMoney strike price from the fetched strike prices", true);
      return false;
    }

    let minIndex = atmIndex - maxStrikesUpDownAtm;
    let maxIndex = atmIndex + maxStrikesUpDownAtm;

    if (minIndex < 0) {
      minIndex = 0;
    }
    if (maxIndex > uniqueStrikes.length - 1) {
      maxIndex = uniqueStrikes.length - 1;
    }

    uniqueStrikes = uniqueStrikes.slice(minIndex, maxIndex + 1);
    console.log("Found " + uniqueStrikes.length + " unique strikes");

    _.forEach(childSymbols, (child: any) => {
      const meta = child.contractMetadata;
      const strikeValue = meta.strike;

      if (uniqueStrikes.indexOf(strikeValue) !== -1) {
        let strikeOption = strikeOptions[strikeValue];

        if (_.isUndefined(strikeOption)) {
          strikeOption = strikeOptions[strikeValue] = new StrikeOptions(strikeValue);
        }

        const isCallOption = meta.contractSymbol[0] === "C";
        if (isCallOption) {
          strikeOption.callOption = this.addOrUpdateOption(meta, active);
        } else {
          strikeOption.putOption = this.addOrUpdateOption(meta, active);
        }
      }
    });

    this.strikeOptions = _.values(strikeOptions);
    this.atmStrike = strike;
    return true;
  }

  private addOrUpdateOption(contractMetadata: any, active: any): any {
    var option = InstrumentsManager.addOrUpdate(contractMetadata);
    // option.futureInstrument = active;
    return option;
  }

  private subscribeOptionsHandler() {
    this.strikeOptions?.forEach((strike: any) => {
      strike.callOption && this.subscribe(strike.callOption);
      strike.putOption && this.subscribe(strike.putOption);
    });
  }

  private unsubscribeOptions() {
    this.strikeOptions?.forEach((strike: any) => {
      strike.callOption && this.unsubscribe(strike.callOption);
      strike.putOption && this.unsubscribe(strike.putOption);
    });
  }

  private subscribe(instrument: any) {
    CQGEnvironment.Instance.cqgService.subscribeToInstruments(instrument.contractId, this.level);
  }

  private unsubscribe(instrument: any) {
    CQGEnvironment.Instance.cqgService.unsubscribeFromInstruments(instrument.contractId, this.level);
  }

  private setStatus(text: string, error: boolean = false) {
    this.loadStatus = text;
    this.loadError = error;

    if (this.loadError) {
    } else {
    }
  }

  private resetStatus() {
    this.loadStatus = null;
    this.loadError = false;
  }
}
