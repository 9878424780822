import { WidgetType } from "../../../../../types";

const Header = ({ widgetType }: { widgetType: WidgetType | undefined }) => {
  switch (widgetType) {
    case WidgetType.Watchlist:
      return <div>WATCHLIST</div>;
    case WidgetType.Chart:
      return <div>NEW CHART</div>;
    case WidgetType.PriceLadder:
      return <div>NEW PRICE LADDER</div>;
    default:
      return <></>;
  }
};

export default Header;
