import { WidgetType } from "../../types";

const TreeViewContainerButtonTitle = ({ widgetType }: { widgetType: WidgetType | undefined }) => {
  switch (widgetType) {
    case WidgetType.Watchlist:
      return "ADD WATCHLIST";
    case WidgetType.Chart:
      return "ADD CHART";
    case WidgetType.PriceLadder:
      return "ADD PRICE LADDER";
    default:
      return "";
  }
};

export default TreeViewContainerButtonTitle;
