import React, { useMemo } from "react";
import { TreeItem2 } from "@mui/x-tree-view";
import { Instrument } from "../../cqg-api/models/Instrument";
import { CONTRACT_ID_NAME, ProductWithContracts, TreeViewMode } from "../../types";
import Checkbox from "@mui/material/Checkbox";
import { DoneIcon } from "../../images/Icons";
import clsx from "clsx";
import { convertSymbolToDate } from "./utils/convertSymbolToDate";
import './TreeView.css';

const MonthlyContractTreeItem = (props: {
  product: ProductWithContracts;
  contract: Instrument;
  isSelected: boolean;
  handleCheckboxToggle: () => void;
  treeViewData: Instrument[];
  mode: TreeViewMode;
  isFirstContract: boolean;
}) => {
  const { contract, isSelected, treeViewData, mode, isFirstContract } = props;

  function changeCell(labelPriceNetChange: number | null, labelPriceNetChangePercent: number | null) {
    if (labelPriceNetChange) {
        const color = labelPriceNetChange < 0 ? '#C62828' : '#00695C';
        return <span style={{ color }}>{`${labelPriceNetChange?.toFixed(2)} (${labelPriceNetChangePercent?.toFixed(2)}%)`}</span>;
      }
      return <span></span>;
  }
  const volume = useMemo(() => {
    const index = treeViewData.findIndex((item) => item.contractId === contract.contractId);
    if (index > -1) return treeViewData[index].labelTotalVolume;
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeViewData]);
  const change = useMemo(() => {
    const index = treeViewData.findIndex((item) => item.contractId === contract.contractId);
    const { labelPriceNetChange = null, labelPriceNetChangePercent = null } = treeViewData[index] ?? {};
    return changeCell(labelPriceNetChange, labelPriceNetChangePercent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeViewData]);

  const handleCheckboxToggle = () => {
    props.handleCheckboxToggle();
  };
  return (
    <TreeItem2
      itemId={String(contract[CONTRACT_ID_NAME])}
      className="monthly-contract-tree-item-container"
      onClick={(e) => {
        e.stopPropagation();
        handleCheckboxToggle();
      }}
      label={
        mode === TreeViewMode.MULTIPLE ? (
          <div
            className={clsx({
              "tree-view-item-row": true,
              "tree-view-item-row--selected": isSelected,
            })}
          >
            <Checkbox style={{ position: "absolute" }} checked={isSelected} />
            <div className="label1Value">
              <div>{convertSymbolToDate(contract.displayName)}</div>
              {isFirstContract && (
                <div style={{ fontFamily: "Averta-Regular",
                  fontSize: "11px",
                  fontWeight: 400,
                  color: "#081D3799"
                   }}>
                  Rolling Front Month
                </div>
              )}
            </div>
            <div className='label2'>{change}</div>
            <div className='label3'>{volume}</div>
          </div>
        ) : (
          <div
            className={clsx({
              "tree-view-item-row": true,
              "tree-view-item-row--selected": isSelected,
            })}
          >
            {isSelected && <DoneIcon style={{ height: "12px", width: "12px", left: "4px", position: "absolute" }} />}
            <div className="label1Value1Select">
              <div>{convertSymbolToDate(contract.displayName)}</div>
              {isFirstContract && (
                <div style={{ fontFamily: "Averta-Regular",
                  fontSize: "11px",
                  fontWeight: 400,
                  color: "#081D3799"
                   }}>
                  Rolling Front Month
                </div>
              )}
            </div>
            <div className="label2">{change}</div>
            <div className="label3">{volume}</div>
          </div>
        )
      }
    />
  );
};

export default MonthlyContractTreeItem;
