import { OrderState } from "../../cqg-api/models/OrderState";
import { CQGEnvironment } from "../../cqg-api/services/CQGEnvironment";
import * as Order2 from "../../../src/cqg-api/proto/order_2";
import { OrderSide } from "../../types";
import { OrderChain_Side } from "../../cqg-api/proto/traderouting_1";
import { IFormInput } from "./types";
import { getSign, getTakeProfitAndStopPrice } from "../widgets/priceLadder/utils";
import { StopLoss, TakeProfit } from "../widgets/priceLadder/types";

export const tradeTicketHeader = () => {
  const title = document.createElement("h4");
  title.textContent = "Trade";
  title.style.margin = "0px";
  title.style.marginLeft = "5px";

  // Create the SVG element
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("width", "16");
  svg.setAttribute("height", "16");
  svg.setAttribute("viewBox", "0 0 16 16");
  svg.setAttribute("fill", "none");
  svg.style.marginTop = "1px";

  // Create the path element inside the SVG
  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute(
    "d",
    "M4.66 7.3335L2 10.0002L4.66 12.6668V10.6668H9.33333V9.3335H4.66V7.3335ZM14 6.00016L11.34 3.3335V5.3335H6.66667V6.66683H11.34V8.66683L14 6.00016Z",
  );
  path.setAttribute("fill", "#081D37");

  // Append the path to the SVG
  svg.appendChild(path);

  // Append the SVG to the div

  const imageContainer = document.createElement("div");
  imageContainer.appendChild(svg);
  imageContainer.style.display = "flex";
  imageContainer.style.position = "absolute";
  imageContainer.style.justifyContent = "center";
  imageContainer.style.alignContent = "center";
  imageContainer.style.left = "10px";
  imageContainer.style.top = "7px";

  imageContainer.appendChild(title);

  return imageContainer;
};

export const disableResize = (container: HTMLElement) => {
  const resizeHandle = container;
  const topResizeHandler = resizeHandle?.querySelector(".dv-resize-handle-top");
  topResizeHandler.style.cssText = "display: none !important";
  const rightResizeHandler = resizeHandle?.querySelector(".dv-resize-handle-right");
  rightResizeHandler.style.cssText = "display: none !important";
  const bottomResizeHandler = resizeHandle?.querySelector(".dv-resize-handle-bottom");
  bottomResizeHandler.style.cssText = "display: none !important";
  const leftResizeHandler = resizeHandle?.querySelector(".dv-resize-handle-left");
  leftResizeHandler.style.cssText = "display: none !important";
  const topLeftResizeHandler = resizeHandle?.querySelector(".dv-resize-handle-topleft");
  topLeftResizeHandler.style.cssText = "display: none !important";
  const topRightResizeHandler = resizeHandle?.querySelector(".dv-resize-handle-topright");
  topRightResizeHandler.style.cssText = "display: none !important";
  const bottomRightResizeHandler = resizeHandle?.querySelector(".dv-resize-handle-bottomright");
  bottomRightResizeHandler.style.cssText = "display: none !important";
  const bottomLeftResizeHandler = resizeHandle?.querySelector(".dv-resize-handle-bottomleft");
  bottomLeftResizeHandler.style.cssText = "display: none !important";
};

export const insertNumberInput = (e: React.FormEvent<HTMLInputElement>, decimal?: boolean) => {
  const target = e.target as HTMLInputElement;
  const value = target.value;
  const isValid = decimal ? /^[0-9]*\.?[0-9]*$/.test(value) : /^[0-9]*$/.test(value);
  if (!isValid) {
    target.value = value.slice(0, value.length - 1);
  }
};

export const numbersWithDecimalRules = {
  pattern: { value: /^[0-9]*\.?[0-9]*$/, message: "Please enter a valid number" },
};

export const placeOrder = (order: OrderState) => {
  CQGEnvironment.Instance.cqgService.placeOrder(order, order.account);
};

export const placeAmendOrder = (order: OrderState) => {
  CQGEnvironment.Instance.cqgService.modifyOrder(order);
};

export const getDefaultQty = (amendOrder: OrderState | undefined, orderData: Record<string, any> | undefined) => {
  if (!!amendOrder) {
    return amendOrder.size!;
  } else if (!!orderData) {
    return orderData.size!;
  } else {
    return 1;
  }
};

export const getDefaultSide = (amendOrder: OrderState | undefined, orderData: Record<string, any> | undefined) => {
  if (!!amendOrder) {
    return amendOrder.side! === OrderChain_Side.BUY ? OrderSide.Buy : OrderSide.Sell;
  } else if (!!orderData && orderData.side) {
    return orderData.side! === OrderChain_Side.BUY ? OrderSide.Buy : OrderSide.Sell;
  }

  return undefined;
};

export const getDefaultTIF = (amendOrder: OrderState | undefined) => {
  if (!!amendOrder) {
    return amendOrder.duration!;
  }

  return Order2.Order_Duration.DURATION_DAY;
};

// Helper function to get account details
const getAccountDetails = () => {
  return CQGEnvironment.Instance.accountsManager.getAccount(CQGEnvironment.cqgAccountAuthInfo?.accountId as number);
};

// Helper function to calculate price with correct scale
export const calculateProfitLossPrice = (
  orderSide: OrderSide,
  correctPriceScale: any,
  price: number | null,
): string | false => {
  if (!price || !orderSide) return false;
  return (price / correctPriceScale).toFixed();
};

// Helper function to create order state
export const createOrderStateObject = (
  data: IFormInput,
  selectedStrike: number | null,
  instrument: any,
  orderSide: OrderSide | undefined,
  correctPriceScale: any,
) => {
  const profitPrice = orderSide
    ? calculateProfitLossPrice(orderSide, correctPriceScale, orderSide && data.oco?.[orderSide]?.takeProfit?.price)
    : false;
  const lossPrice = orderSide
    ? calculateProfitLossPrice(orderSide, correctPriceScale, data.oco?.[orderSide]?.stopLoss?.price)
    : false;
  return {
    account: getAccountDetails(),
    instrument,
    when: new Date(),
    clientOrderId: "defaultClientOrderId", // Replace with actual client order ID
    size: data.quantity,
    putCall: data.putCall,
    ...data,
    strikePrice: selectedStrike,
    profitLossStrategy: getProfitLossStrategy(profitPrice, lossPrice, data, orderSide),
  };
};

// Helper function to build profit/loss strategy
const getProfitLossStrategy = (
  profitPrice: string | false,
  lossPrice: string | false,
  data: IFormInput,
  orderSide: OrderSide | undefined,
) => {
  return {
    profit: {
      price:
        orderSide && profitPrice
          ? shouldSetProfitLossPrice(data.oco?.[orderSide]?.takeProfit?.isSelected, profitPrice)
          : false,
    },
    loss: {
      price:
        orderSide && lossPrice
          ? shouldSetProfitLossPrice(data.oco?.[orderSide]?.stopLoss?.isSelected, lossPrice)
          : false,
    },
  };
};

// Helper function to check if profit/loss price should be set
const shouldSetProfitLossPrice = (isSelected: boolean, price: string | false) => {
  return isSelected ? price : false;
};

export const getPrice = (
  condition: TakeProfit | StopLoss,
  isProfit: boolean,
  price: number | null,
  selectedContract: any,
  orderSide: OrderSide,
) => {
  if (!condition?.isSelected) {
    return false;
  }

  const tick = Number(condition?.tick);
  const calculatedPrice =
    getTakeProfitAndStopPrice(Number(price), selectedContract, getSign(isProfit, orderSide), tick) /
    selectedContract?.correctPriceScale;

  return calculatedPrice.toFixed();
};
