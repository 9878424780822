import { useCallback, useEffect, useState } from "react";
import { Instrument } from "../../../../cqg-api/models/Instrument";
import { useOrdersList } from "../../../../cqg-api/hooks/ServiceHooks";
import { OrderStatus_Status } from "../../../../cqg-api/proto/common/shared_1";
import useOrderCancel from "../hooks/useOrderCancel";
import { cancelOrders } from "../../orders/actions";
import Notification from "../../../shared/notification/Notification";
import LadderTable from "./LadderTable";
import { OrderState } from "../../../../cqg-api/models/OrderState";
import { Depth } from "../types";
import { OrderSide, OrderType } from "../../../../types";
import ConfirmCancelDialog from "../../../shared/ConfirmCancelDialog";
import { CQGEnvironment } from "../../../../cqg-api/services/CQGEnvironment";

interface PriceLadderBookProps {
  centerDivRef: React.MutableRefObject<any>;
  priceLadderBookRef: React.MutableRefObject<any>;
  quantityValue: number;
  selectedContract: Instrument;
  createOrderHandler: (dorderSide: OrderSide, orderType: OrderType, price: number | null) => Promise<void>;
}

const PriceLadderBook = ({
  centerDivRef,
  priceLadderBookRef,
  quantityValue,
  selectedContract,
  createOrderHandler,
}: PriceLadderBookProps) => {
  const { ordersList } = useOrdersList();
  const [draggedRowIndex, setDraggedRowIndex] = useState<number | null>(null);
  const { isDialogOpen, requestCancel, confirmCancel, closeDialog } = useOrderCancel();

  const handleCancelOrder = (order: OrderState) => {
    cancelOrders([order]);
  };

  const handleCancelClick = (order: OrderState) => {
    requestCancel(() => handleCancelOrder(order));
  };

  const createOrderHandlerClick = (orderSide: OrderSide, orderType: OrderType, price: number | null) => {
    createOrderHandler(orderSide, orderType, price);
  };

  const isMinLengthGreaterThanZero = Math.min(selectedContract?.asks?.length, selectedContract?.bids?.length) > 0;

  useEffect(() => {
    if (centerDivRef.current && isMinLengthGreaterThanZero) {
      setTimeout(() => {
        centerDivRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }, 200);
    }
  }, [selectedContract.contractId, isMinLengthGreaterThanZero, centerDivRef]);

  const handleDragStart = useCallback((index: number) => {
    setDraggedRowIndex(index);
  }, []);

  const handleDrop = useCallback(
    (depth: Depth, dropIndex: number, depthList: Depth[]) => {
      // Skip processing if the drag and drop indices are the same or invalid
      if (draggedRowIndex === null || draggedRowIndex === dropIndex) {
        return;
      }

      // Clone the depth list and remove the dragged item
      const updatedList = [...depthList];
      const [draggedOrder] = updatedList.splice(draggedRowIndex, 1);

      // Find the matching order
      const matchingOrder = ordersList.find(
        (order) => order.displayLimitPrice === Number(draggedOrder.displayPrice) && order.status === OrderStatus_Status.WORKING,
      );

      if (!matchingOrder) {
        console.warn("No matching order found for modification.");
        setDraggedRowIndex(null);
        return;
      }

      // Update order properties
      matchingOrder.displayLimitPrice = Number(depth.displayPrice);
      matchingOrder.limitPrice = Number(depth.displayPrice);

      // Attempt to modify the order
      try {
        CQGEnvironment.Instance.cqgService.modifyOrder(matchingOrder);
        Notification.orderInfo(matchingOrder, true);
      } catch (error) {
        console.error("Failed to modify order:", { error, matchingOrder });
      }

      // Reset the dragged row index
      setDraggedRowIndex(null);
    },
    [draggedRowIndex, ordersList],
  );

  return (
    <>
      <LadderTable
        priceLadderBookRef={priceLadderBookRef}
        selectedContract={selectedContract}
        centerDivRef={centerDivRef}
        quantityValue={quantityValue}
        handleDragStart={handleDragStart}
        handleDrop={handleDrop}
        createOrderHandlerClick={createOrderHandlerClick}
        handleCancelClick={handleCancelClick}
      />

      <ConfirmCancelDialog
        open={isDialogOpen}
        onClose={() => closeDialog()}
        onConfirm={confirmCancel}
        loading={false}
        title="Confirm Order Cancellation"
        message="Are you sure you want to cancel this orders?"
      />
    </>
  );
};

export default PriceLadderBook;
