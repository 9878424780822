import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function MenuIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6673 7.5H3.33398V9.16667H16.6673V7.5ZM3.33398 12.5H16.6673V10.8333H3.33398V12.5Z" fill="#5A6874" />
      </svg>
    </SvgIcon>
  );
}

export function MoreIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.0007 13.3335C10.4427 13.3335 10.8666 13.5091 11.1792 13.8217C11.4917 14.1342 11.6673 14.5581 11.6673 15.0002C11.6673 15.4422 11.4917 15.8661 11.1792 16.1787C10.8666 16.4912 10.4427 16.6668 10.0007 16.6668C9.55862 16.6668 9.1347 16.4912 8.82214 16.1787C8.50958 15.8661 8.33398 15.4422 8.33398 15.0002C8.33398 14.5581 8.50958 14.1342 8.82214 13.8217C9.1347 13.5091 9.55862 13.3335 10.0007 13.3335ZM10.0007 8.3335C10.4427 8.3335 10.8666 8.50909 11.1792 8.82165C11.4917 9.13421 11.6673 9.55814 11.6673 10.0002C11.6673 10.4422 11.4917 10.8661 11.1792 11.1787C10.8666 11.4912 10.4427 11.6668 10.0007 11.6668C9.55862 11.6668 9.1347 11.4912 8.82214 11.1787C8.50958 10.8661 8.33398 10.4422 8.33398 10.0002C8.33398 9.55814 8.50958 9.13421 8.82214 8.82165C9.1347 8.50909 9.55862 8.3335 10.0007 8.3335ZM10.0007 3.3335C10.4427 3.3335 10.8666 3.50909 11.1792 3.82165C11.4917 4.13421 11.6673 4.55814 11.6673 5.00016C11.6673 5.44219 11.4917 5.86611 11.1792 6.17867C10.8666 6.49123 10.4427 6.66683 10.0007 6.66683C9.55862 6.66683 9.1347 6.49123 8.82214 6.17867C8.50958 5.86611 8.33398 5.44219 8.33398 5.00016C8.33398 4.55814 8.50958 4.13421 8.82214 3.82165C9.1347 3.50909 9.55862 3.3335 10.0007 3.3335Z"
          fill="#5A6874"
        />
      </svg>
    </SvgIcon>
  );
}

export function DoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3581 1.5C8.53618 3.3333 6.43975 5.43271 4.54526 7.33636L1.49698 4.73031L0 6.50607L3.86318 9.80304L4.67808 10.5L5.4326 9.73636C7.49109 7.66511 9.93778 5.21747 12 3.14243L10.3581 1.5Z"
          fill="#006EB6"
        />
      </svg>
    </SvgIcon>
  );
}

export function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="8.17" height="10.5" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.49935 10.0833C1.49935 10.725 2.02435 11.25 2.66602 11.25H7.33268C7.97435 11.25 8.49935 10.725 8.49935 10.0833V3.08333H1.49935V10.0833ZM9.08268 1.33333H7.04102L6.45768 0.75H3.54102L2.95768 1.33333H0.916016V2.5H9.08268V1.33333Z"
          fill="#006EB6"
        />
      </svg>
    </SvgIcon>
  );
}

export function ArrowDownIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="8" height="8">
      <svg fill="none" viewBox="0 0 9 8" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 4L7.795 3.295L5 6.085V0H4V6.085L1.21 3.29L0.5 4L4.5 8L8.5 4Z" fill="#D32F2F" />
      </svg>
    </SvgIcon>
  );
}

export function CircleChecked(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="16" fill="#E5F6FD" />
        <path
          d="M11.3333 16.9997L14.6667 20.333L22 12.9997"
          stroke="#006EB6"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

export function ArrowUpIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="8" height="8">
      <svg fill="#00695d" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" stroke="#00695d">
        <path d="M256,34,432,210l-21.2,21.21L271,91.4V478H241V91.4L101.16,231.25,80,210Z"></path>
      </svg>
    </SvgIcon>
  );
}

export function CancelRoundedGreen(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path
          d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H22C23.933 0.5 25.5 2.067 25.5 4V22C25.5 23.933 23.933 25.5 22 25.5H4C2.067 25.5 0.5 23.933 0.5 22V4Z"
          stroke="#2E7D32"
          strokeOpacity="0.5"
        />
        <path
          d="M18.25 8.8075L17.1925 7.75L13 11.9425L8.8075 7.75L7.75 8.8075L11.9425 13L7.75 17.1925L8.8075 18.25L13 14.0575L17.1925 18.25L18.25 17.1925L14.0575 13L18.25 8.8075Z"
          fill="#2E7D32"
        />
      </svg>
    </SvgIcon>
  );
}

export function CancelRoundedRed(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path
          d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H22C23.933 0.5 25.5 2.067 25.5 4V22C25.5 23.933 23.933 25.5 22 25.5H4C2.067 25.5 0.5 23.933 0.5 22V4Z"
          stroke="#D32F2F"
          strokeOpacity="0.5"
        />
        <path
          d="M18.25 8.8075L17.1925 7.75L13 11.9425L8.8075 7.75L7.75 8.8075L11.9425 13L7.75 17.1925L8.8075 18.25L13 14.0575L17.1925 18.25L18.25 17.1925L14.0575 13L18.25 8.8075Z"
          fill="#D32F2F"
        />
      </svg>
    </SvgIcon>
  );
}

export function LocationSearchingOutlined(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="8" height="8">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path
          d="M14.4601 7.33366C14.1534 4.55366 11.9467 2.34699 9.16675 2.04033V0.666992H7.83341V2.04033C5.05341 2.34699 2.84675 4.55366 2.54008 7.33366H1.16675V8.66699H2.54008C2.84675 11.447 5.05341 13.6537 7.83341 13.9603V15.3337H9.16675V13.9603C11.9467 13.6537 14.1534 11.447 14.4601 8.66699H15.8334V7.33366H14.4601ZM8.50008 12.667C5.92008 12.667 3.83341 10.5803 3.83341 8.00033C3.83341 5.42033 5.92008 3.33366 8.50008 3.33366C11.0801 3.33366 13.1667 5.42033 13.1667 8.00033C13.1667 10.5803 11.0801 12.667 8.50008 12.667Z"
          fill="#081D37"
        />
      </svg>
    </SvgIcon>
  );
}

export function TradeLogIcon({ htmlColor = "#081D37", ...props }: SvgIconProps & { htmlColor?: string }) {
  return (
    <SvgIcon {...props} htmlColor={htmlColor} width="8" height="8">
      <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.200012 1.7001C0.200012 0.816443 0.916356 0.100098 1.80001 0.100098H5.46864C5.89299 0.100098 6.29995 0.268669 6.60001 0.568727L9.33138 3.3001C9.63144 3.60016 9.80001 4.00712 9.80001 4.43147V11.3001C9.80001 12.1838 9.08367 12.9001 8.20001 12.9001H1.80001C0.916356 12.9001 0.200012 12.1838 0.200012 11.3001V1.7001Z"
          fill={htmlColor}
          fillOpacity="1"
        />
      </svg>
    </SvgIcon>
  );
}

export function Warning(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.9998 5.94949L17.9023 17.8753H4.09734L10.9998 5.94949ZM10.9998 2.29199L0.916504 19.7087H21.0832L10.9998 2.29199Z"
          fill="#EF6C00"
        />
        <path d="M11.9165 15.1253H10.0832V16.9587H11.9165V15.1253Z" fill="#EF6C00" />
        <path d="M11.9165 9.62533H10.0832V14.2087H11.9165V9.62533Z" fill="#EF6C00" />
      </svg>
    </SvgIcon>
  );
}

export function EducationIcon({ htmlColor = "#081D37", ...props }: SvgIconProps & { htmlColor?: string }) {
  return (
    <SvgIcon {...props} htmlColor={htmlColor} width="8" height="8">
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.50713 7.28919L11.6706 4.89948C12.0968 4.7094 12.0989 3.79845 11.6706 3.60747L6.50712 1.21777C6.1763 1.07194 5.8245 1.07194 5.49369 1.21777L0.330186 3.60747C-0.0960108 3.79755 -0.0980701 4.7085 0.330186 4.89948L1.14458 5.27639C0.991477 5.6798 0.93481 6.10888 0.917081 6.61297C0.733262 6.76643 0.608844 7.06063 0.608844 7.39856C0.608844 7.73419 0.731596 8.02663 0.913337 8.1809L0.610117 11.6376C0.598323 11.7721 0.668226 11.8915 0.75861 11.8915H1.65721C1.74766 11.8915 1.81749 11.772 1.8057 11.6376L1.50248 8.18093C1.68422 8.02663 1.80697 7.73419 1.80697 7.39856C1.80697 7.07156 1.69049 6.78541 1.51643 6.62821C1.53327 6.19899 1.57922 5.87292 1.73954 5.55172L5.49371 7.28919C5.82452 7.43501 6.17632 7.43501 6.50713 7.28919ZM6.67991 8.14962L6.68378 8.14782L9.50901 6.84009L9.74457 9.19576C9.74457 10.1883 8.06826 10.9929 6.00042 10.9929C3.93258 10.9929 2.25627 10.1883 2.25627 9.19576L2.49185 6.84009L5.31707 8.14782L5.32095 8.14962L5.32483 8.15133C5.7659 8.34576 6.23496 8.34576 6.67603 8.15133L6.67991 8.14962Z"
          fill={htmlColor}
        />
        <mask id="mask0_5535_6764" maskUnits="userSpaceOnUse" x="0" y="1" width="12" height="11">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.50713 7.28919L11.6706 4.89948C12.0968 4.7094 12.0989 3.79845 11.6706 3.60747L6.50712 1.21777C6.1763 1.07194 5.8245 1.07194 5.49369 1.21777L0.330186 3.60747C-0.0960108 3.79755 -0.0980701 4.7085 0.330186 4.89948L1.14458 5.27639C0.991477 5.6798 0.93481 6.10888 0.917081 6.61297C0.733262 6.76643 0.608844 7.06063 0.608844 7.39856C0.608844 7.73419 0.731596 8.02663 0.913337 8.1809L0.610117 11.6376C0.598323 11.7721 0.668226 11.8915 0.75861 11.8915H1.65721C1.74766 11.8915 1.81749 11.772 1.8057 11.6376L1.50248 8.18093C1.68422 8.02663 1.80697 7.73419 1.80697 7.39856C1.80697 7.07156 1.69049 6.78541 1.51643 6.62821C1.53327 6.19899 1.57922 5.87292 1.73954 5.55172L5.49371 7.28919C5.82452 7.43501 6.17632 7.43501 6.50713 7.28919ZM6.67991 8.14962L6.68378 8.14782L9.50901 6.84009L9.74457 9.19576C9.74457 10.1883 8.06826 10.9929 6.00042 10.9929C3.93258 10.9929 2.25627 10.1883 2.25627 9.19576L2.49185 6.84009L5.31707 8.14782L5.32095 8.14962L5.32483 8.15133C5.7659 8.34576 6.23496 8.34576 6.67603 8.15133L6.67991 8.14962Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_5535_6764)"></g>
      </svg>
    </SvgIcon>
  );
}

export function PriceLadderIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.5 7.75H5.5V6.25H0.5V7.75ZM0.5 4.75H5.5V3.25H0.5V4.75ZM0.5 1.75H5.5V0.25H0.5V1.75Z"
          fill="#081D37"
          fillOpacity="0.56"
        />
      </svg>
    </SvgIcon>
  );
}

export function MobileSearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
        <path
          d="M16.5666 13.975C16.9333 13.3917 17.15 12.7167 17.15 11.9833C17.15 9.90833 15.475 8.23333 13.4 8.23333C11.325 8.23333 9.64998 9.90833 9.64998 11.9833C9.64998 14.0583 11.325 15.7333 13.3916 15.7333C14.125 15.7333 14.8083 15.5167 15.3833 15.15L17.9833 17.75L19.1666 16.5667L16.5666 13.975ZM13.4 14.0667C12.25 14.0667 11.3166 13.1333 11.3166 11.9833C11.3166 10.8333 12.25 9.9 13.4 9.9C14.55 9.9 15.4833 10.8333 15.4833 11.9833C15.4833 13.1333 14.55 14.0667 13.4 14.0667ZM13.1 6.98333C12.4833 7 11.8916 7.13333 11.35 7.35833L10.8916 6.66667L7.72498 11.8167L5.21665 8.88333L2.19165 13.725L0.833313 12.75L4.99998 6.08333L7.49998 9L10.8333 3.58333L13.1 6.98333ZM15.2583 7.4C14.725 7.16667 14.15 7.025 13.55 6.99167L17.8166 0.25L19.1666 1.23333L15.2583 7.4Z"
          fill="#081D37"
          fillOpacity="0.56"
        />
      </svg>
    </SvgIcon>
  );
}

export const PriceLadderGreyIcon = () => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.67 0V14H6.33V0H11.67ZM12.67 14H18V0H12.67V14ZM5.33 14V0H0V14H5.33Z"
        fill="#081D37"
        fillOpacity="0.56"
      />
    </svg>
  );
};

export const MenuMoreVertIcon = () => {
  return (
    <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.00016 3.66683C2.91683 3.66683 3.66683 2.91683 3.66683 2.00016C3.66683 1.0835 2.91683 0.333496 2.00016 0.333496C1.0835 0.333496 0.333496 1.0835 0.333496 2.00016C0.333496 2.91683 1.0835 3.66683 2.00016 3.66683ZM2.00016 5.3335C1.0835 5.3335 0.333496 6.0835 0.333496 7.00016C0.333496 7.91683 1.0835 8.66683 2.00016 8.66683C2.91683 8.66683 3.66683 7.91683 3.66683 7.00016C3.66683 6.0835 2.91683 5.3335 2.00016 5.3335ZM2.00016 10.3335C1.0835 10.3335 0.333496 11.0835 0.333496 12.0002C0.333496 12.9168 1.0835 13.6668 2.00016 13.6668C2.91683 13.6668 3.66683 12.9168 3.66683 12.0002C3.66683 11.0835 2.91683 10.3335 2.00016 10.3335Z"
        fill="#006EB6"
      />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3333 1.3415L11.1583 0.166504L6.49996 4.82484L1.84163 0.166504L0.666626 1.3415L5.32496 5.99984L0.666626 10.6582L1.84163 11.8332L6.49996 7.17484L11.1583 11.8332L12.3333 10.6582L7.67496 5.99984L12.3333 1.3415Z"
        fill="#081D37"
        fillOpacity="0.56"
      />
    </svg>
  );
};

export const ChartIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.66659 4.50016H0.333252V13.6668H3.66659V4.50016Z" fill="#006EB6" />
      <path d="M13.6666 7.8335H10.3333V13.6668H13.6666V7.8335Z" fill="#006EB6" />
      <path d="M8.66658 0.333496H5.33325V13.6668H8.66658V0.333496Z" fill="#006EB6" />
    </svg>
  );
};

export const FilterIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.99993 2H12.9999L7.98993 8.3L2.99993 2ZM0.249934 1.61C2.26993 4.2 5.99993 9 5.99993 9V15C5.99993 15.55 6.44993 16 6.99993 16H8.99993C9.54993 16 9.99993 15.55 9.99993 15V9C9.99993 9 13.7199 4.2 15.7399 1.61C16.2499 0.95 15.7799 0 14.9499 0H1.03993C0.209934 0 -0.260066 0.95 0.249934 1.61Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
};

export const PriceIcon = () => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.825 5.1665L0.5 8.49984L3.825 11.8332V9.33317H9.66667V7.6665H3.825V5.1665ZM15.5 3.49984L12.175 0.166504V2.6665H6.33333V4.33317H12.175V6.83317L15.5 3.49984Z"
        fill="#006EB6"
      />
    </svg>
  );
};

export const DatePickerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
      <path
        d="M6 9H4V11H6V9ZM10 9H8V11H10V9ZM14 9H12V11H14V9ZM16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18Z"
        fill="#081D37"
        fillOpacity="0.56"
      />
    </svg>
  );
};

export const StrikePriceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
    <path d="M5.00008 3.83341C4.07925 3.83341 3.33341 4.57925 3.33341 5.50008C3.33341 6.42091 4.07925 7.16675 5.00008 7.16675C5.92091 7.16675 6.66675 6.42091 6.66675 5.50008C6.66675 4.57925 5.92091 3.83341 5.00008 3.83341ZM8.72508 5.08341C8.53341 3.34591 7.15425 1.96675 5.41675 1.77508V0.916748H4.58341V1.77508C2.84591 1.96675 1.46675 3.34591 1.27508 5.08341H0.416748V5.91675H1.27508C1.46675 7.65425 2.84591 9.03341 4.58341 9.22508V10.0834H5.41675V9.22508C7.15425 9.03341 8.53341 7.65425 8.72508 5.91675H9.58341V5.08341H8.72508ZM5.00008 8.41675C3.38758 8.41675 2.08341 7.11258 2.08341 5.50008C2.08341 3.88758 3.38758 2.58341 5.00008 2.58341C6.61258 2.58341 7.91675 3.88758 7.91675 5.50008C7.91675 7.11258 6.61258 8.41675 5.00008 8.41675Z" fill="#16202A"/>
    </svg>
  );
};

export const CMELogoIcon = () => {
  return (
    <svg width="91" height="16" viewBox="0 0 91 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5574 7.0569V7.02832C14.5574 4.17001 16.7012 1.83026 19.7513 1.83026C21.6078 1.83026 22.7298 2.47604 23.6948 3.3947L22.558 4.7024C21.751 3.95475 20.8886 3.45232 19.7374 3.45232C17.8094 3.45232 16.4129 5.03152 16.4129 6.99928V7.02832C16.4129 8.99425 17.8094 10.6025 19.7374 10.6025C20.975 10.6025 21.7662 10.1005 22.6296 9.29663L23.7659 10.4444C22.716 11.5364 21.5639 12.2245 19.6797 12.2245C16.7441 12.2245 14.5574 9.94241 14.5574 7.0569Z"
        fill="#3A464F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.3162 2.00275H27.2008L30.2662 6.75554L33.3316 2.00275H35.2158V12.0532H33.4466V4.84493L30.2662 9.58297H30.208L27.0581 4.8735V12.0532H25.3162V2.00275Z"
        fill="#3A464F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.1163 2.00275H44.5853V3.58102H38.8864V6.19503H43.9372V7.77422H38.8864V10.4735H44.6559V12.0532H37.1163V2.00275Z"
        fill="#3A464F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.3222 7.0569V7.02832C48.3222 4.21426 50.4822 1.83026 53.518 1.83026C55.2738 1.83026 56.3519 2.3184 57.3876 3.19373L56.2651 4.52909C55.4895 3.86855 54.7259 3.45232 53.445 3.45232C51.589 3.45232 50.1796 5.07484 50.1796 6.99928V7.02832C50.1796 9.0952 51.546 10.6177 53.603 10.6177C54.5527 10.6177 55.417 10.3153 56.036 9.85621V7.97556H53.4612V6.43878H57.7474V10.6596C56.7543 11.5073 55.3315 12.2245 53.5462 12.2245C50.3953 12.2245 48.3222 9.98574 48.3222 7.0569Z"
        fill="#3A464F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M59.5591 4.47133H61.3006V6.18005C61.7759 5.04658 62.6527 4.27082 63.99 4.32797V6.16668H63.8897C62.3649 6.16668 61.3006 7.15679 61.3006 9.16696V12.0529H59.5591V4.47133Z"
        fill="#3A464F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.0622 8.30554V8.27696C71.0622 6.94161 70.0986 5.8358 68.7309 5.8358C67.3363 5.8358 66.4438 6.92732 66.4438 8.24792V8.27696C66.4438 9.59757 67.4074 10.7167 68.7609 10.7167C70.1698 10.7167 71.0622 9.61186 71.0622 8.30554ZM64.7016 8.30555V8.27698C64.7016 6.1087 66.4297 4.31378 68.761 4.31378C71.091 4.31378 72.8029 6.07966 72.8029 8.24794V8.27698C72.8029 10.43 71.0758 12.2245 68.731 12.2245C66.4149 12.2245 64.7016 10.46 64.7016 8.30555Z"
        fill="#3A464F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.1965 9.29675V4.47113H75.938V8.77911C75.938 9.95682 76.5279 10.6312 77.5635 10.6312C78.5715 10.6312 79.2768 9.92778 79.2768 8.75053V4.47113H81.0174V12.0527H79.2768V10.8755C78.7872 11.5932 78.0823 12.2108 76.902 12.2108C75.1888 12.2108 74.1965 11.0622 74.1965 9.29675Z"
        fill="#3A464F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M88.8821 8.2768V8.24822C88.8821 6.7838 87.889 5.82135 86.7097 5.82135C85.5294 5.82135 84.4938 6.79763 84.4938 8.24822V8.2768C84.4938 9.74076 85.5294 10.7041 86.7097 10.7041C87.9033 10.7041 88.8821 9.76934 88.8821 8.2768ZM82.7949 4.47124H84.5363V5.73515C85.0967 4.94601 85.8889 4.3136 87.112 4.3136C88.9108 4.3136 90.6513 5.73515 90.6513 8.24822V8.27679C90.6513 10.7894 88.9247 12.2109 87.112 12.2109C85.8598 12.2109 85.0676 11.579 84.5363 10.8756V14.8121H82.7949V4.47124Z"
        fill="#3A464F"
      />
      <mask
        id="mask0_3713_11267"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="82"
        y="4"
        width="9"
        height="11"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M88.8821 8.2768V8.24822C88.8821 6.7838 87.889 5.82135 86.7097 5.82135C85.5294 5.82135 84.4938 6.79763 84.4938 8.24822V8.2768C84.4938 9.74076 85.5294 10.7041 86.7097 10.7041C87.9033 10.7041 88.8821 9.76934 88.8821 8.2768ZM82.7949 4.47124H84.5363V5.73515C85.0967 4.94601 85.8889 4.3136 87.112 4.3136C88.9108 4.3136 90.6513 5.73515 90.6513 8.24822V8.27679C90.6513 10.7894 88.9247 12.2109 87.112 12.2109C85.8598 12.2109 85.0676 11.579 84.5363 10.8756V14.8121H82.7949V4.47124Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3713_11267)"></g>
      <mask
        id="mask1_3713_11267"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="1"
        width="12"
        height="13"
      >
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.31647H11.7459V13.0513H0V1.31647Z" fill="white" />
      </mask>
      <g mask="url(#mask1_3713_11267)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.67375 6.95207H5.66059V4.1712H3.16016C2.86361 5.0115 2.69546 5.95136 2.67375 6.95207ZM7.18126 13.0513H8.32269L10.7765 10.6032H8.82988C8.43909 11.573 7.88062 12.4083 7.18126 13.0513ZM5.16293 13.0513H5.66043V10.6032H3.32861C3.75728 11.6104 4.38227 12.4507 5.16293 13.0513ZM0.954876 10.6032L3.40864 13.0513H4.54314C3.84517 12.4087 3.28808 11.573 2.89867 10.6032H0.954876ZM6.61564 1.3161H6.08534V3.74666H8.41578C7.9945 2.74595 7.38105 1.91164 6.61564 1.3161ZM3.32537 3.74666H5.66042V1.3161H5.12967C4.36286 1.91164 3.74804 2.74595 3.32537 3.74666ZM3.16294 10.1787H5.66059V7.37659H2.67375C2.69362 8.38375 2.86176 9.33191 3.16294 10.1787ZM11.1934 4.1712H9.00155C9.28426 5.01934 9.44454 5.95736 9.46348 6.95207H11.7459V4.72203L11.1934 4.1712ZM9.06063 7.37659H6.08534V10.1787H8.5659C8.86893 9.33191 9.03799 8.38375 9.06063 7.37659ZM10.7679 3.74666L8.33219 1.3161H7.23234C7.91738 1.95359 8.46569 2.78236 8.84725 3.74666H10.7679ZM8.98735 10.1787H11.2014L11.746 9.63521V7.37659H9.46221C9.44096 8.37822 9.27698 9.32361 8.98735 10.1787ZM4.51271 1.3161H3.36943L0.965093 3.74666H2.89365C3.27751 2.78236 3.82674 1.95359 4.51271 1.3161ZM6.08534 10.6032V13.0513H6.56113C7.34271 12.4507 7.96909 11.6104 8.39961 10.6032H6.08534ZM2.27177 7.37659H0V9.65042L0.529832 10.1787H2.74201C2.45377 9.32361 2.29163 8.37822 2.27177 7.37659ZM2.73878 4.1712H0.545076L0 4.72203V6.95207H2.27177C2.29255 5.95736 2.45423 5.01934 2.73878 4.1712ZM9.06201 6.95207H6.08534V4.1712H8.57976C8.87493 5.0115 9.04215 5.95136 9.06201 6.95207Z"
          fill="#25A9E0"
        />
      </g>
    </svg>
  );
};
