import { Grid, MenuItem } from "@mui/material";
import { StyledFormControl, StyledSelect } from "../styles";
import { Control, Controller } from "react-hook-form";
import { TradeTicketTab, OrderType } from "../../../types";
import { OrderState } from "../../../cqg-api/models/OrderState";
import { IFormInput } from "../types";

interface OrderTypeProps {
  control: Control<IFormInput, any>;
  activeTab: string;
  amendOrder?: OrderState;
}

const OrderTypeSelect = ({ control, activeTab, amendOrder }: OrderTypeProps) => {
  const renderMenuItems = () => {
    const items = [
      <MenuItem key="mkt" value={OrderType.Mkt}>
        Market
      </MenuItem>,
      <MenuItem key="lmt" value={OrderType.Lmt}>
        Limit
      </MenuItem>,
    ];

    if (activeTab === TradeTicketTab.Futures) {
      items.push(
        <MenuItem key="stp" value={OrderType.Stp}>
          Stop
        </MenuItem>,
        <MenuItem key="stl" value={OrderType.Stl}>
          Stop Limit
        </MenuItem>,
      );
    }

    return items;
  };

  return (
    <Grid item xs={6}>
      <label className="label">Order Type</label>
      <StyledFormControl fullWidth>
        <Controller
          name="orderType"
          control={control}
          render={({ field }) => (
            <StyledSelect data-testid="ddlOrderType" {...field} disabled={Boolean(amendOrder)}>
              {renderMenuItems()}
            </StyledSelect>
          )}
        />
      </StyledFormControl>
    </Grid>
  );
};

export default OrderTypeSelect;
