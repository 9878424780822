import { useCallback, useMemo, useState } from "react";
import { usePositionsList } from "../../../cqg-api/hooks/ServiceHooks";
import { OrderOption, PositionDisplay, PositionDisplayEnum, positionsFilter, Row, Widget, WidgetType } from "../../../types";
import { InstrumentsManager } from "../../../cqg-api/services/InstrumentsManager";
import { useDispatch } from "react-redux";
import { setTradeTicketValue } from "../../../redux/products/chartWidget";
import workspacesActions from "../../../redux/workspaces/workspacesActions";
import { PositionsController } from "../../../cqg-api/controllers/PositionsController";
// getPosition(item.displayLong, item.displayShort, PositionDisplayEnum.LongShort)
const getPositionValue = (long: number | null, short: number | null, prefix: PositionDisplayEnum) => {
  if (long) {
    return PositionDisplay.Long;
  }
  if (short) {
    return PositionDisplay.Short;
  }
  return "";
}
const getPosition = (long: number | null, short: number | null, prefix: PositionDisplayEnum) => {
  if (long) {
    return long;
  }
  if (short) {
    return short;
  }
  return "";
}
const usePositions = () => {
  const [activeFilter, setActiveFilter] = useState("All Positions");
  const { positionsList } = usePositionsList();
  const [openAllFlattenDialog, setOpenAllFlattenDialog] = useState(false);
  const dispatch = useDispatch();
  const [selectedPosition, setSelectedPosition] = useState<any>(null);
  const [openSingleFlattenDialog, setOpenSingleFlattenDialog] = useState(false);
  const [openTradeTicket, setOpenTradeTicket] = useState(false);
  const [loading, setLoading] = useState(false);
  const dataRows: any = positionsList
    .map((item) => ({
      id: item.contractId,
      trade: item?.instrument?.displayName,
      symbol: item.displaySymbol,
      contract: item?.instrument?.cmeSymbolName,
      month: item.instrument?.month,
      strike: item.instrument?.strikePrice,
      cp: item.instrument?.putCallAbbrev() === "P" ? OrderOption.Put : item.instrument?.putCallAbbrev() === "C" ? OrderOption.Call : "",
      direction: getPositionValue(item.displayLong, item.displayShort, PositionDisplayEnum.LongShort),
      buys: item.buys,
      sells: item.sells,
      averagePX: item.displayPrice,
      unrealizedPL: item.displayOteMvo.toFixed(2),
      realizedPL: item.displayRpl.toFixed(2),
      flatten: "",
      size: getPosition(item.displayLong, item.displayShort, PositionDisplayEnum.LongShort),
      position: item,
    }))
    .filter((position) => {
      if (activeFilter === positionsFilter.Open) {
        return position.direction !== undefined && position.direction !== "";
      }
      if (activeFilter === positionsFilter.Closed) {
        return position.direction === undefined || position.direction === "";
      }
      return true;
    });

    const hasPositionsWithDirection = useMemo(() => {
      return dataRows.some((position:any) => position.direction);
    }, [dataRows]);

    const setTradeTicket = (id: number, workspaceId: any) => {
      const instrument = InstrumentsManager.getInstrument(id);
      const chartWidgetValue: Widget = {
        key: 3,
        title: WidgetType.TradeTicket,
        widgetType: WidgetType.TradeTicket,
      };
      dispatch(
        workspacesActions.dispatchSetWorkspace({
          workspaceId: workspaceId,
          instrument: instrument,
        }),
      );
      dispatch(setTradeTicketValue(chartWidgetValue));
    }

    const handleFlattenPosition = useCallback((position: Row) => {
      setSelectedPosition(position); 
      setOpenSingleFlattenDialog(true); 
    }, []);

    const closeFlattenDialog = () => {
      setOpenSingleFlattenDialog(false);
    }

  const handleConfirmFlatten = useCallback(() => {
    if (selectedPosition) {
      setLoading(true);
      PositionsController.flattenPosition(selectedPosition.position); 
    }
    setLoading(false);
    setOpenSingleFlattenDialog(false); 
  }, [selectedPosition]);
  const handleCloseTradeTicket = () => {
    setOpenTradeTicket(false);
  }
  const setFilter = (filter: string) => {
    setActiveFilter(filter);
  };

  const getNoDataMessage = useMemo(() => {
    switch (activeFilter) {
      case positionsFilter.Open:
        return "No open positions available";
      case positionsFilter.Closed:
        return "No closed positions available";
      default:
        return "You currently have no open positions";
    }
  },[activeFilter]);
  const handleTrade = () => {
    setOpenTradeTicket(true);
    const instrument = InstrumentsManager.getInstrument(selectedPosition.position.instrument.id);
      const chartWidgetValue: Widget = {
        key: 3,
        title: WidgetType.TradeTicket,
        widgetType: WidgetType.TradeTicket,
        expired: (instrument?.lastPrice || instrument?.strikePrice) ? false : true,
      };
      dispatch(
        workspacesActions.dispatchSetWorkspace({
          workspaceId: 2,
          instrument: instrument,
        }),
      );
      dispatch(setTradeTicketValue(chartWidgetValue));
  };

  const handleAllFlattenPosition = useCallback(() => {
    setLoading(true);
    try {
      PositionsController.flattenAllPositions(positionsList);
    } finally {
      setLoading(false);
      setOpenAllFlattenDialog(false);
    }
  }, [positionsList]);
    return {
      hasPositionsWithDirection,
      dataRows,
      setTradeTicket,
      handleFlattenPosition,
      openSingleFlattenDialog,
      closeFlattenDialog,
      handleConfirmFlatten,
      loading,
      selectedPosition,
      activeFilter,
      setFilter,
      getNoDataMessage,
      openAllFlattenDialog,
      setOpenAllFlattenDialog,
      handleAllFlattenPosition,
      handleTrade,
      openTradeTicket,
      handleCloseTradeTicket,
    };
};


export default usePositions;
