import React from "react";
import { OrderState } from "../../../../cqg-api/models/OrderState";
import { useOrdersList } from "../../../../cqg-api/hooks/ServiceHooks";
import { OrderStatus_Status } from "../../../../cqg-api/proto/common/shared_1";
import { Depth } from "../types";
import { OrderSide, OrderType } from "../../../../types";
import { cancelRoundedBuyStyles, cancelRoundedSellStyles } from "../PriceLadderStyles";
import OrderContent from "./OrderContent";

interface OrderCellProps {
  depth: Depth;
  orderSide: OrderSide;
  index: number;
  depthList: Depth[];
  quantityValue: number;
  handleDragStart: (index: number) => void;
  handleDrop: (depth: Depth, index: number, depthList: Depth[]) => void;
  createOrderHandlerClick: (orderSide: OrderSide, orderType: OrderType, price: number | null) => void;
  handleCancelClick: (order: OrderState) => void;
  selectedDepth:
    | (Depth & {
        orderSide: OrderSide;
      })
    | null;
  setSelectedDepth: React.Dispatch<
    React.SetStateAction<
      | (Depth & {
          orderSide: OrderSide;
        })
      | null
    >
  >;
  decimals: number;
}

const OrderCell = ({
  depth,
  orderSide,
  index,
  depthList,
  quantityValue,
  handleDragStart,
  handleDrop,
  createOrderHandlerClick,
  handleCancelClick,
  selectedDepth,
  setSelectedDepth,
  decimals,
}: OrderCellProps) => {
  const { ordersList } = useOrdersList();

  const placedOrder: OrderState | undefined = ordersList.find(
    (i) => i.limitPrice?.toFixed(decimals) === depth.displayPrice?.toString() && i?.status === OrderStatus_Status.WORKING,
  );
  const isWorkingOrder = placedOrder?.displaySide === orderSide && placedOrder?.status === OrderStatus_Status.WORKING;
  const isSelected = selectedDepth?.displayPrice === depth.displayPrice && selectedDepth.orderSide === orderSide;
  const orderClass = isWorkingOrder ? `price-ladder-order-${orderSide}` : "";
  const preOrderClass = isSelected ? `price-ladder-pre-order-${orderSide}` : "";
  const cancelStyles = orderSide === OrderSide.Buy ? cancelRoundedBuyStyles : cancelRoundedSellStyles;

  const handleDragEvents = {
    onDragOver: (e: any) => {
      e.preventDefault();
    },
    onDragStart: () => handleDragStart(index),
    onDrop: () => {
      handleDrop(depth, index, depthList);
    },
  };

  const handleMouseEvents = {
    onMouseOver: () => setSelectedDepth({ ...depth, orderSide }),
    onMouseLeave: () => setSelectedDepth(null),
    onClick: () =>
      !isWorkingOrder ? createOrderHandlerClick(orderSide, OrderType.Lmt, depth.displayPrice) : undefined,
  };

  const renderPreOrderContent = () => isSelected && <span className={preOrderClass}>{quantityValue}</span>;

  return (
    <td
      style={{ cursor: "pointer" }}
      className="price-ladder-book-qty"
      {...handleMouseEvents}
      {...handleDragEvents}
      draggable={isWorkingOrder}
    >
      {isWorkingOrder ? (
        <OrderContent
          orderClass={orderClass}
          cancelStyles={cancelStyles}
          placedOrder={placedOrder}
          handleCancelClick={handleCancelClick}
        />
      ) : (
        renderPreOrderContent()
      )}
    </td>
  );
};

export default OrderCell;
