import { InstrumentMarketData, Product, Widget, WidgetType } from "../../../types";
import "./AvailableProducts.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DataGridTable from "../../shared/DataGrid";
import { MapInstrumentMarketData2 } from "../../../utils/utils";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { useDispatch } from "react-redux";
import SelectContract from "./SelectContract";
import RenderColorCell from "./RenderColorCell";
import MarketAssets from "./MarketAssets";
import FilterProducts from "./FilterProducts";
import { setChartWidgetValue, setSelectedInstrument, setTradeTicketValue } from "../../../redux/products/chartWidget";
import { IconButton } from "@mui/material";
import useResponsivePanel from "../../../hooks/useResponsivePanel";
import workspacesActions from "../../../redux/workspaces/workspacesActions";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
import { useAvailableProductsContext } from "../../../AvailableProductsProvider";
import { ProductWithContracts } from "../../../hooks/api/types";
import TradeButton from "../../shared/TradeButton";
import MarketMobileWidget from "./mobile/MarketMobileWidget";
import CustomMenu from "../../shared/CustomMenu";
import { ChartIcon, MenuMoreVertIcon } from "../../../images/Icons";
import { FILTER_MAPPER } from "./types";
import clsx from "clsx";
import { useMarketData } from "../../../MarketDataProvider";

function changeCell() {
  return (params: GridRenderCellParams) => {
    const stateOfRow = params.row.monthlyContract;
    const color = stateOfRow?.labelPriceNetChange < 0 ? "#C62828" : "#00695C";
    if (stateOfRow?.labelPriceNetChange) {
      return (
        <span
          style={{ color }}
        >{`${stateOfRow?.labelPriceNetChange?.toFixed(2)} (${stateOfRow?.labelPriceNetChangePercent?.toFixed(2)}%)`}</span>
      );
    }
    return <span></span>;
  };
}

const AvailableProducts = (props: { workspaceId: number; workspaceClassName?: string; isMobile?: boolean }) => {
  const { realTimeMarketData } = useMarketData();
  const [activeTab, setActiveTab] = useState("");
  const { assetProductsDictionary, productsMapById } = useAvailableProductsContext();
  const dispatch = useDispatch();
  const { panelRef, isSmallPanel } = useResponsivePanel(900);
  const { panelRef: filterPanelRef, isSmallPanel: isSmallPanelFilter } = useResponsivePanel(400);

  const renderChangeCell = changeCell();

  const openChartForInstrument = useCallback(
    (params: GridRenderCellParams) => {
      const { row } = params;
      const stateOfRow = row.monthlyContract;

      const selectedInstrument = stateOfRow
        ? row.monthlyContracts.find((contract: any) => contract.displayName === stateOfRow.displayName)
        : row.monthlyContracts[0];

      if (selectedInstrument) {
        const chartWidgetValue: Widget = {
          key: 3,
          title: WidgetType.Chart,
          widgetType: `${selectedInstrument.displayName} Chart`,
          contractIds: [selectedInstrument.displayName],
        };
        dispatch(setChartWidgetValue(chartWidgetValue));
      } else {
        console.log("Instrument not found");
      }
    },
    [dispatch],
  );

  const isMarketDataAvailable = (contract: Instrument) => {
    return !!contract.labelPrice;
  };

  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const [paramRowData, setParamRowData] = useState<null | any>(null);

  const openMarketMenu = useCallback((event: React.MouseEvent<HTMLElement>, params: any) => {
    setAnchorMenu(event.currentTarget);
    setParamRowData(params?.row?.monthlyContract);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorMenu(null);
  }, []);

  const [gridData, setGridData] = useState<InstrumentMarketData[]>([]);

  const handleRowStateChange = useCallback((id: string, contract: any) => {
    setGridData((prevData) =>
      prevData.map((data) => (data.cqgSymbol === contract.cqgSymbol ? { ...data, monthlyContract: contract } : data)),
    );
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 140,
        sortable: true,
        headerClassName: "market-table--header",
      },
      {
        field: "monthlyContracts",
        headerName: "Contract",
        flex: 1,
        minWidth: 100,
        sortable: true,
        headerClassName: "market-table--header",
        renderCell: (params: GridRenderCellParams) => {
          return (
            <SelectContract
              selectedState={params.row.monthlyContract || params.value[0]}
              id={params.row.id}
              contracts={params.value}
              onSelect={handleRowStateChange}
            />
          );
        },
      },
      {
        field: "lastPrice",
        headerName: "Last Price",
        flex: 1,
        minWidth: 75,
        sortable: true,
        renderCell: (params: GridRenderCellParams) => {
          const stateOfRow = params.row.monthlyContract;
          return <RenderColorCell value={DisplayUtil.toDisplayPrice(stateOfRow?.lastPrice, stateOfRow)} />;
        },
        headerClassName: "market-table--header",
      },
      {
        field: "change",
        headerName: "Change",
        flex: 1,
        minWidth: 75,
        sortable: true,
        renderCell: renderChangeCell,
        headerClassName: "market-table--header",
      },
      {
        field: "askQty",
        headerName: "Open",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "market-table--header",
        renderCell: (params: GridRenderCellParams) => {
          return <span>{params.row.monthlyContract?.labelOpen}</span>;
        },
      },
      {
        field: "yesterdaySettlement",
        headerName: "Prior Settle",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "market-table--header",
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span>
              {parseFloat(
                DisplayUtil.toDisplayPrice(
                  params.row.monthlyContract?.yesterdaySettlement ?? 0,
                  params.row.monthlyContract as any,
                )?.toString() || "",
              ) || null}
            </span>
          );
        },
      },
      {
        field: "bidPrice",
        headerName: "High",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "market-table--header",
        renderCell: (params: GridRenderCellParams) => {
          return <span>{params.row.monthlyContract?.labelHigh}</span>;
        },
      },
      {
        field: "bidQty",
        headerName: "Low",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "market-table--header",
        renderCell: (params: GridRenderCellParams) => {
          return <span>{params.row.monthlyContract?.labelLow}</span>;
        },
      },
      {
        field: "labelTotalVolume",
        headerName: "Volume",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: "market-table--header",
        renderCell: (params: GridRenderCellParams) => {
          return <span>{params.row.monthlyContract?.labelTotalVolume}</span>;
        },
      },
      {
        field: "selected",
        headerName: "Actions",
        headerClassName: "market-table--header",
        flex: 1,
        minWidth: 130,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const enabled = params.row.monthlyContract ? isMarketDataAvailable(params.row.monthlyContract) : false;

          const handlePrimaryClick = () => {
            const tradeTicketWidgetValue: Widget = {
              key: 3,
              title: WidgetType.TradeTicket,
              widgetType: "Trade Ticket",
            };
            dispatch(
              workspacesActions.dispatchSetWorkspace({
                workspaceId: props?.workspaceId,
                instrument: params.row.monthlyContract,
              }),
            );
            dispatch(setTradeTicketValue(tradeTicketWidgetValue));
            dispatch(
              setSelectedInstrument({
                instrument: params.row.monthlyContracts,
                selected: params.row.monthlyContract,
              }),
            );
          };

          return (
            <span className="starIcon">
              <TradeButton enabled={enabled} handleClick={handlePrimaryClick} />
              <IconButton data-testid={`action-button-2-${params.row.id}`} onClick={() => openChartForInstrument(params)}>
                <ChartIcon />
              </IconButton>
              <IconButton data-testid={`action-button-3-${params.row.id}`} onClick={(event) => openMarketMenu(event, params)} sx={{ padding: "5px 10px" }}>
                <MenuMoreVertIcon />
              </IconButton>
              <CustomMenu anchorMenu={anchorMenu} handleCloseMenu={handleCloseMenu} paramRowData={paramRowData} />
            </span>
          );
        },
      },
    ],
    [
      renderChangeCell,
      handleRowStateChange,
      anchorMenu,
      handleCloseMenu,
      paramRowData,
      dispatch,
      props?.workspaceId,
      openChartForInstrument,
      openMarketMenu,
    ],
  );

  const productsAsPerChoosenAsset: ProductWithContracts[] = useMemo(() => {
    const products = activeTab ? assetProductsDictionary?.[activeTab] : Object.values(productsMapById);
    return products.map((product: ProductWithContracts) => ({
      ...product,
    }));
  }, [activeTab, productsMapById, assetProductsDictionary]);

  useEffect(() => {
    if (assetProductsDictionary && Object.keys(assetProductsDictionary).length > 0) {
      setActiveTab(Object.keys(assetProductsDictionary)[0]);
    }
  }, [assetProductsDictionary]);

  useEffect(() => {
    const newWatchlistData: InstrumentMarketData[] = [];
    productsAsPerChoosenAsset.forEach(function (product: ProductWithContracts) {
      let index = gridData?.findIndex((data: Product) => product.id === data.id);
      if (index !== -1) {
        newWatchlistData.push(gridData[index]);
      } else {
        newWatchlistData.push({ ...product, monthlyContract: product.monthlyContracts?.[0] as Instrument });
      }
    });
    setGridData(newWatchlistData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAsPerChoosenAsset]);

  useEffect(() => {
    if (gridData?.length) {
      setGridData(MapInstrumentMarketData2(gridData, realTimeMarketData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData]);

  const selectMarket = (id: string) => {
    setActiveTab(id);
  };
  const [selectedFilter, setSelectedFilter] = useState("Volume");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getNoDataMessage = useMemo(() => {
    return "No data available";
  }, []);

  return (
    <div ref={panelRef} className="widget-parent-container">
      <div
        className={clsx({
          "available-product-container": true,
          draggableCancelSelector: true,
          "widget-grid-container": true,
          "available-product-container--mobile": props?.isMobile,
        })}
      >
        <div className="available-product-left-panel" ref={filterPanelRef}>
          <MarketAssets
            activeTab={activeTab}
            assetProductsDictionary={assetProductsDictionary}
            selectMarket={selectMarket}
            isSmallPanel={isSmallPanel}
            isMobile={props?.isMobile}
          />
          <FilterProducts
            selectedAssetType={selectedFilter}
            handleFilterIconClick={handleFilterIconClick}
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            setSelectedFilter={setSelectedFilter}
            isSmallPanelFilter={isSmallPanelFilter}
            isMobile={props?.isMobile}
          />
        </div>
        <div className="available-product-right-panel">
          {props?.isMobile ? (
            <MarketMobileWidget
              rows={gridData}
              sortModelValue={FILTER_MAPPER[selectedFilter]}
              productsAsPerChoosenAsset={productsAsPerChoosenAsset}
            />
          ) : (
            <DataGridTable
              columns={columns}
              rows={gridData}
              noDataMessage={getNoDataMessage}
              sortModelValue={FILTER_MAPPER[selectedFilter]}
            />
          )}
        </div>
      </div>
      {/* {true && <TradeTicketDrawer open={openDrawer} setOpen={setOpenDrawer} contracts={firstMonthlyContract} />} */}
    </div>
  );
};

export default AvailableProducts;
