import { IconButton, MenuItem, Popover } from "@mui/material";
import { useCallback, useState } from "react";
import clsx from "clsx";
import { FilterIcon } from "../../../../../images/Icons";

const popoverStyles = {
  padding: 0,
  fontSize: "12px",
  "& .MuiPaper-root": {
    width: "150px",
    padding: "5px 0",
    fontSize: "12px",
    background: "white",
    maxHeight: "200px",
  },
};

interface MobileProductFilterProps {
  categories: string[];
  filters: {
    product: string;
    month: string;
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      product: string;
      month: string;
    }>
  >;
  setLoading: (loading: boolean) => void;
}

const MobileProductFilter = ({ categories, filters, setFilters, setLoading }: MobileProductFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleFilterChange = useCallback(
    (key: keyof typeof filters, value: string) => {
      setFilters((prev) => ({ ...prev, [key]: value }));
      setLoading(true);
      handleClose();
    },
    [setFilters, setLoading],
  );

  return (
    <>
      <IconButton onClick={handleFilterIconClick}>
        <FilterIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={popoverStyles}
      >
        <MenuItem
          className={clsx({
            "trade-log-product-filter--active": filters.month === "",
          })}
          value=""
          onClick={() => handleFilterChange("month", "")}
        >
          All months
        </MenuItem>
        {categories.map((option, index) =>
          option ? (
            <MenuItem
              className={clsx({
                "trade-log-product-filter--active": filters.month === option,
              })}
              key={index}
              value={option}
              onClick={() => handleFilterChange("month", option)}
            >
              {option}
            </MenuItem>
          ) : null,
        )}
      </Popover>
    </>
  );
};

export default MobileProductFilter;
