// DockviewContent.tsx
import React from "react";
import { DockviewReact } from "dockview";
import { components } from "./utils";
import RightHeaderControls from "./RightHeaderControls";

interface DockviewContentProps {
  onReady: (event: any) => void;
  dockviewRef: React.MutableRefObject<null>
}

const DockviewContent: React.FC<DockviewContentProps> = ({ onReady, dockviewRef }) => {
  return (
    <DockviewReact
      components={components}
      rightHeaderActionsComponent={RightHeaderControls}
      onReady={onReady}
      className="dockview-theme-light"
      gap={8}
      defaultRenderer="always"
      floatingGroupBounds="boundedWithinViewport"
      ref={dockviewRef}
    />
  );
};

export default DockviewContent;
