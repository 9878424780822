import { useMemo } from "react";
import { AssetProductsDictionary, ProductWithContracts } from "../../../types";

const useFilteredAssets = (
  assetProductsDictionary: AssetProductsDictionary,
  searchQuery: string,
): AssetProductsDictionary => {
  return useMemo(() => {
    // Return the full object if the search query is empty
    if (searchQuery.trim() === "") {
      return assetProductsDictionary;
    }

    const inputSearchTextLower = searchQuery.toLowerCase();
    const filteredAssets: AssetProductsDictionary = {};

    for (const asset in assetProductsDictionary) {
      const products = assetProductsDictionary[asset].filter((item: ProductWithContracts) => {
        const nameInLowerCase = item.name?.toLowerCase() ?? "";

        // Check if the item matches the search query
        const matchesItem = nameInLowerCase.includes(inputSearchTextLower);

        // Check if any monthly contracts match the search query
        const matchesContracts = item.monthlyContracts?.some((contract) =>
          contract.displayName?.toLowerCase().includes(inputSearchTextLower),
        );

        return matchesItem || matchesContracts;
      });

      // Only keep assets that have matching products
      if (products.length > 0) {
        filteredAssets[asset] = products;
      }
    }

    return filteredAssets;
  }, [assetProductsDictionary, searchQuery]);
};

export default useFilteredAssets;
