import React from "react";
import { TreeItem2 } from "@mui/x-tree-view";
import { CONTRACT_ID_NAME, ProductWithContracts, TreeViewMode } from "../../types";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Checkbox from "@mui/material/Checkbox";
import { DoneIcon } from "../../images/Icons";

const ProductTreeItem = (props: {
  product: ProductWithContracts;
  selectedItems: string[];
  isSelected: boolean;
  handleCheckboxToggle: (product: ProductWithContracts) => void;
  children: React.ReactNode;
  mode: TreeViewMode;
}) => {
  const { product, selectedItems, isSelected, children, mode } = props;

  const allContractIds = product.monthlyContracts?.map((contract) => String(contract[CONTRACT_ID_NAME]));

  // `selectedContractIds` filters the `selectedItems` array to include only the IDs that match any in `allContractIds`.
  const selectedContractIds = selectedItems.filter((id) => allContractIds?.includes(id));

  // `isProductSelected` checks if all monthly contracts of the product are selected by comparing the lengths of `selectedContractIds` and `allContractIds`.
  const isProductSelected = selectedContractIds.length === allContractIds?.length;

  // `isIndeterminate` becomes true if some but not all monthly contracts are selected, indicating a mixed (indeterminate) state for the product's checkbox.
  const isIndeterminate = selectedContractIds.length > 0 && !isProductSelected;

  const handleCheckboxToggle = () => {
    props.handleCheckboxToggle(product);
  };

  return (
    <TreeItem2
      slots={{
        expandIcon: AddIcon,
        collapseIcon: RemoveIcon,
      }}
      className="product-tree-item-container"
      itemId={product.id.toString()}
      label={
        mode === TreeViewMode.MULTIPLE ? (
          <>
            <div className="tree-view-item-row">
              <Checkbox
                style={{ position: "absolute" }}
                checked={isSelected}
                indeterminate={isIndeterminate}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCheckboxToggle();
                }}
              />
              <div style={{ marginLeft: "36px" }}>{product.name}</div>
            </div>
          </>
        ) : (
          <div className="tree-view-item-row">
            {isSelected && <DoneIcon style={{ height: "12px", width: "12px", left: "4px", position: "absolute" }} />}
            <span style={{ marginLeft: "24px" }}>{product.name}</span>
          </div>
        )
      }
    >
      {children}
    </TreeItem2>
  );
};

export default ProductTreeItem;
