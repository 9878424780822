import "./sidebar.css";
import { Widget, WidgetType, Workspace } from "../../../../types";
import { useCallback, useContext, useEffect, useState } from "react";
import MenuItemDrawer from "./menu-item-drawer/MenuItemDrawer";
import Footer from "./Footer";
import MenuItems from "./SideMenuItems";
import { useSelector } from "react-redux";
import watchlistSelectors from "../../../../redux/watchlist/watchlistSelector";
import { AvailableProductsContext } from "../../../../AvailableProductsProvider";

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
  onAddNewWidgetToWorkSpaceRequest: (widget: Widget) => void;
  activeWorkspace?: Workspace;
  onMenuItemClick: (component: JSX.Element | null) => void
  loadWorkspaceOnToolsSelection: (value: boolean) => void;
}

const SideMenu = ({ isOpen, onToggle, onAddNewWidgetToWorkSpaceRequest, activeWorkspace, onMenuItemClick, loadWorkspaceOnToolsSelection }: SidebarProps) => {
  const [open, setOpen] = useState(false);
  const [widgetToAdd, setWidgetToAdd] = useState<Widget | null>(null);
  const productSelectionList = [WidgetType.Chart, WidgetType.PriceLadder, WidgetType.Watchlist, WidgetType.Options];
  const watchlistWidget = useSelector(watchlistSelectors.getWidget);
  const watchlistItems = useSelector(watchlistSelectors.getSelectedTreeItems);
  const [treeViewPreSelectedItems, setTreeViewPreSelectedItems] = useState<string[]>([]);
  const { monthlyContractsByDisplayNameMap } = useContext(AvailableProductsContext);

  const handleToggle = () => (open ? (showMenuItemDrawer(false), setTimeout(onToggle, 200)) : onToggle());
  const handleDrawer = () => {showMenuItemDrawer(false)};
  const onAddWidgetClick = (widget: Widget) => {
    if (productSelectionList.includes(widget.widgetType as WidgetType)) {
      setWidgetToAdd(widget);
      showMenuItemDrawer(true);
      setTreeViewPreSelectedItems([]);
    } else {
      handleOnAddToWorkspaceRequest(widget);
    }
  };

  useEffect(() => {
    if (!watchlistWidget) return;

    showMenuItemDrawer(true);
    setWidgetToAdd(watchlistWidget);

    const uniqueProductIds: string[] = watchlistItems
      .map((contractId: string) => monthlyContractsByDisplayNameMap[contractId]?.productId)
      .filter((productId: string, index: number, self: string[]) => productId && self.indexOf(productId) === index);

    setTreeViewPreSelectedItems([...watchlistItems, ...uniqueProductIds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchlistItems, watchlistWidget, monthlyContractsByDisplayNameMap]);

  const showMenuItemDrawer = useCallback((newOpen: boolean) => {
    if (newOpen === false) {
      setWidgetToAdd(null);
    }
    setOpen(newOpen);
  }, []);

  const handleOnAddToWorkspaceRequest = (widget: Widget) => {
    onAddNewWidgetToWorkSpaceRequest(widget);
    showMenuItemDrawer(false);
  };

  return (
    <>
      <nav>
        <MenuItems handleDrawer={handleDrawer} isOpen={isOpen} drawerOpen={open} onToggle={handleToggle} onAddWidgetClick={onAddWidgetClick} onMenuItemClick={onMenuItemClick} loadWorkspaceOnToolsSelection={loadWorkspaceOnToolsSelection}></MenuItems>
        <Footer isOpen={isOpen}/>
      </nav>
      {widgetToAdd && (
        <MenuItemDrawer
          onClose={() => showMenuItemDrawer(false)}
          onAddToWorkspaceRequest={handleOnAddToWorkspaceRequest}
          widgetToAdd={widgetToAdd}
          open={open}
          activeWorkspace={activeWorkspace}
          selectedTreeItems={treeViewPreSelectedItems ?? []}
        ></MenuItemDrawer>
      )}
    </>
  );
};

export default SideMenu;
