import { InstrumentMarketData, Product, Widget, WidgetType, Workspace } from "../../../types";
import "./AvailableProducts.css";
import { useCallback, useEffect, useMemo, useState } from "react";

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import DataGridTable from "../../shared/DataGrid";
import { MapInstrumentMarketData2 } from "../../../utils/utils";
import { useRealTimeMarketData } from "../../../cqg-api/hooks/ServiceHooks";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { subscribeToInstruments, unsubscribeInstuments } from "../../../utils/subscriptions";
import { useDispatch } from "react-redux";
import PriceIcon from "./Price";
import ChartIcon from "./ChartIcon";
import MenuIcon from "./MenuIcon";
import SelectContract from "./SelectContract";
import { Contract } from "./types";
import RenderColorCell from "./RenderColorCell";
import useAvailableProducts from "./useAvailableProducts";
import MarketAssets from "./MarketAssets";
import FilterProducts from "./FilterProducts";
import { setChartWidgetValue } from '../../../redux/products/chartWidget'
import ActionMenu from "./AvtionMenu";
import { IconButton } from "@mui/material";
import TradeTicketDrawer from '../../TradeTicketDrawer';
import useResponsivePanel from "../../../hooks/useResponsivePanel";

interface RowState {
  [key: string]: Contract;
}

function changeCell(rowState: RowState) {
  return (params: GridRenderCellParams) => {
    const stateOfRow = rowState[params.row.id] ?? params.row.selectedContract;
    const color = stateOfRow?.labelPriceNetChange < 0 ? 'red' : 'green';
    if (stateOfRow?.labelPriceNetChange) {
      return <span style={{ color }}>{`${stateOfRow?.labelPriceNetChange?.toFixed(2)} (${stateOfRow?.labelPriceNetChangePercent?.toFixed(2)}%)`}</span>;
    }
    return <span></span>;
  };
}

const AvailableProducts = (props: { selectedWorkspace?: Workspace | null; workspaceClassName?: string }) => {
  const { realTimeMarketData } = useRealTimeMarketData();
  const [activeTab, setActiveTab] = useState("");
  const [rowState, setRowState] = useState<RowState>({});
  const { assetProductsDictionary, productsMapById } = useAvailableProducts();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const { panelRef, isSmallPanel } = useResponsivePanel(900);
  const { panelRef: filterPanelRef, isSmallPanel: isSmallPanelFilter } = useResponsivePanel(600);

  const renderChangeCell = changeCell(rowState);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedContract, setSelectedContract] = useState();

  const handleRowStateChange = (id: string, contract: Contract) => {
    setRowState((prevState) => ({
      ...prevState,
      [id]: contract,
    }));
  };

  const openChartForInstrument = useCallback((params: GridRenderCellParams) => {    
    const { row } = params;
    const rowId = row.id;
    const stateOfRow = rowState[rowId];
      
    const selectedInstrument = stateOfRow 
      ? row.monthlyContracts.find((contract: Contract) => contract.displayName === stateOfRow.displayName) 
      : row.monthlyContracts[0];
  
    if (selectedInstrument) {
      const chartWidgetValue: Widget = {
        key: 3,
        title: WidgetType.Chart,
        WidgetType: `${selectedInstrument.displayName} Chart`,
        contractIds: [selectedInstrument.displayName]
      };
      dispatch(setChartWidgetValue(chartWidgetValue));
    } else {
      console.log('Instrument not found');
    }
  }, [rowState, dispatch]);
    
  const handleMenuIconClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  },[]);
  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  },[]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: 'market-table--header',
      },
      {
        field: "monthlyContracts",
        headerName: "Contract",
        flex: 1,
        minWidth: 100,
        sortable: true,
        headerClassName: 'market-table--header',
        renderCell: (params: GridRenderCellParams) => {
          const rowId = params.row.id;
          const stateValue = rowState[rowId as string] || params.value[0];
          return <SelectContract selectedState={stateValue} id={rowId} contracts={params.value} onSelect={handleRowStateChange} />
        }
      },
      {
        field: "lastPrice",
        headerName: "Last Price",
        flex: 1,
        minWidth: 75,
        sortable: true,
        renderCell: (params: GridRenderCellParams) => {
          const stateOfRow = rowState[params.row.id] ?? params.row.selectedContract;
          return <RenderColorCell value={stateOfRow?.lastPrice} />;
        },
        headerClassName: 'market-table--header',
      },
      {
        field: "change",
        headerName: "Change",
        flex: 1,
        minWidth: 75,
        sortable: true,
        renderCell: renderChangeCell,
        headerClassName: 'market-table--header',
      },
      {
        field: "askQty",
        headerName: "Open",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: 'market-table--header',
        renderCell: (params: GridRenderCellParams) => {
          const stateOfRow = rowState[params.row.id] ?? params.row.selectedContract;
          return <span>{stateOfRow?.labelOpen}</span>;
        },
      },
      {
        field: "bidPrice",
        headerName: "High",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: 'market-table--header',
        renderCell: (params: GridRenderCellParams) => {
          const stateOfRow = rowState[params.row.id] ?? params.row.selectedContract;
          return <span>{stateOfRow?.labelHigh}</span>;
        },
      },
      {
        field: "bidQty",
        headerName: "Low",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: 'market-table--header',
        renderCell: (params: GridRenderCellParams) => {
          const stateOfRow = rowState[params.row.id] ?? params.row.selectedContract;
          return <span>{stateOfRow?.labelLow}</span>;
        },
      },
      {
        field: "labelTotalVolume",
        headerName: "Volume",
        flex: 1,
        minWidth: 75,
        sortable: true,
        headerClassName: 'market-table--header',
        renderCell: (params: GridRenderCellParams) => {
          const stateOfRow = rowState[params.row.id] ?? params.row.selectedContract;
          return <span>{stateOfRow?.labelTotalVolume}</span>;
        },
      },
      {
        field: "selected",
        headerName: "Actions",
        headerClassName: 'market-table--header',
        flex: 1,
        minWidth: 100,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <span className="starIcon">
              <div className="icon" onClick={() =>{
                setSelectedContract(params.row.monthlyContracts);
                console.log('contract!!!', params.row);
                setOpenDrawer(true);
              }}><PriceIcon /></div>
              <IconButton onClick={() => openChartForInstrument(params)}><ChartIcon /></IconButton>
              <IconButton onClick={handleMenuIconClick}><MenuIcon /></IconButton>
            </span>
          );
        },
      },
    ],
    [ rowState, renderChangeCell, openChartForInstrument, handleMenuIconClick],
  );
  const productsAsPerChoosenAsset = useMemo(
    () => {
      const products = (activeTab
        ? assetProductsDictionary?.[activeTab]
        : Object.values(productsMapById));
        return products.map((k: any) => ({
          ...k,
          selectedContract: k.monthlyContracts[0],
        }));
      },
    [activeTab, productsMapById, assetProductsDictionary],
  );
  const [watchlistData, setWatchlistData] = useState<InstrumentMarketData[]>([]);

  useEffect(() => {
    if (assetProductsDictionary && Object.keys(assetProductsDictionary).length > 0) {
      setActiveTab(Object.keys(assetProductsDictionary)[0]);
    }
  }, [assetProductsDictionary]);

  useEffect(() => {
    const newWatchlistData: InstrumentMarketData[] = [];
    productsAsPerChoosenAsset.forEach(function (data: Product) {
      let index = watchlistData?.findIndex((product: Product) => product.id === data.id);
      if (index !== -1) {
        newWatchlistData.push(watchlistData[index]);
      } else {
        newWatchlistData.push({ ...data });
      }
    });
    setWatchlistData(newWatchlistData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAsPerChoosenAsset]);
  useEffect(() => {
    if (watchlistData?.length) {
      setWatchlistData(MapInstrumentMarketData2(watchlistData, realTimeMarketData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData]);

  useEffect(() => {
    let resolvedInstruments: Instrument[] = [];
    subscribeToInstruments(productsAsPerChoosenAsset).then((symbols) => {
      resolvedInstruments = symbols;
    });
    return () => {
      unsubscribeInstuments(resolvedInstruments);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAsPerChoosenAsset]);

  const selectMarket = (id: string) => {
    setActiveTab(id);
  };
  const [selectedFilter, setSelectedFilter] = useState(1);
 
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => { setAnchorEl(null); };

  const open = Boolean(anchorEl);

  const getNoDataMessage = useMemo(() => {
    return "No data available";
  },[]);

  return (
    <div ref={panelRef} className="widget-parent-container">
      <div className="available-product-container draggableCancelSelector widget-grid-container">
        <div className="available-product-left-panel" ref={filterPanelRef}>
        <MarketAssets 
          activeTab={activeTab} 
          assetProductsDictionary={assetProductsDictionary} 
          selectMarket={selectMarket} 
          isSmallPanel={isSmallPanel} 
        />
        <FilterProducts
          selectedAssetType={selectedFilter.toString()} 
          handleFilterIconClick={handleFilterIconClick} 
          anchorEl={anchorEl} 
          open={open} 
          handleClose={handleClose} 
          setSelectedFilter={setSelectedFilter} 
          isSmallPanelFilter={isSmallPanelFilter} 
          />
          {menuAnchorEl && (
            <ActionMenu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} handleClose={handleMenuClose} />
          )}
        </div>
        <div className="available-product-right-panel">
          <DataGridTable columns={columns} rows={watchlistData} noDataMessage={getNoDataMessage}/>
        </div>
      </div>
      {true && <TradeTicketDrawer open={openDrawer} setOpen={setOpenDrawer} contracts={selectedContract} />}
    </div>
  );
};

export default AvailableProducts;

