import { Box, Button, Chip, TextField, Typography } from "@mui/material";
import "./TreeViewContainer.css";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import TreeView from "./TreeView";
import { TreeViewMode, WidgetType } from "../../types";
import { useState } from "react";
import useAvailableProducts from "../widgets/availableProducts/useAvailableProducts";
import TreeViewContainerHeader from "./TreeViewContainerHeader";
import TreeViewContainerButtonTitle from "./TreeViewContainerButtonTitle";
import TreeViewContainerDescription from "./TreeViewContainerDescription";

const TreeViewContainer = (props: {
  onClose: () => void;
  onContractsSelection: (contractIds: string[]) => void;
  widgetType?: WidgetType;
  selectedTreeItems?: string[];
}) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [selectedTreeItems, setSelectedTreeItems] = useState<string[]>(props.selectedTreeItems || []);
  const { assetProductsDictionary, productsMapById } = useAvailableProducts();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  React.useEffect(() => {
    setSelectedTreeItems([]);
    setErrorMessage("")
  }, [props.widgetType]);

  const getSelectedMonthlyContracts = (selectedItems: string[]) => {
    const productIds = Object.keys(productsMapById);
    return selectedTreeItems.filter((item) => !productIds.includes(item.toString()));
  };

  const onContractsSelection = () => {

    const stringCount = selectedTreeItems.filter(item => typeof item === 'string').length;
    if (stringCount === 7 && props.widgetType === WidgetType.Chart) {
      setErrorMessage("Display of 7 charts is not supported");
      return;
    } else if (stringCount > 8 && props.widgetType === WidgetType.Chart) {
      setErrorMessage("You have selected maximum number of charts supported");
      return;
    }
    setErrorMessage("");
    const selectedContractIds = getSelectedMonthlyContracts(selectedTreeItems);
    props.onContractsSelection(selectedContractIds);
  };

  const handleDelete = React.useCallback(
    (item: string) => {
      setErrorMessage("");
      setSelectedTreeItems((prev) =>
        props.widgetType === WidgetType.PriceLadder ? [] : prev.filter((i) => i !== item),
      );
    },
    [props.widgetType],
  );

  return (
    <div className="widget-dialog-container">
      <div className="widget-dialog-header">
        <TreeViewContainerHeader widgetType={props.widgetType} />
        <CloseIcon
          className="widget-dialog-remove"
          onClick={() => {
            props.onClose();
          }}
        />
      </div>
      <div className="widget-dialog-description">
        <TreeViewContainerDescription widgetType={props.widgetType} />
      </div>
      <div className="widget-dialog-search">
        <TextField
          id="title"
          label={"Search"}
          size="small"
          sx={{ width: "100%" }}
          className="basic-info-title-field"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="selected-items-container">
        {selectedTreeItems
          .filter((item) => typeof item === "string")
          .map((item) => (
            <Chip
              key={item}
              label={item}
              onDelete={() => handleDelete(item)}
              deleteIcon={
                <Box
                  sx={{
                    width: "12px",
                    height: "12px",
                    backgroundColor:
                      props.widgetType === WidgetType.PriceLadder ? "white" : "var(--primary-main-blue, #006EB6)",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CloseIcon
                    sx={{ color: props.widgetType === WidgetType.PriceLadder ? "#5A6874" : "white", fontSize: "10px" }}
                  />
                </Box>
              }
              sx={{
                margin: "4px",
                minHeight: "24px",
                maxHeight: "24px",
                padding: "0px",
                gap: "0px",
                border:
                  props.widgetType === WidgetType.PriceLadder
                    ? "1px solid transparent"
                    : "1px solid var(--primary-main-blue, #006EB6)",
                backgroundColor:
                  props.widgetType === WidgetType.PriceLadder ? "var(--primary-Gray3, #5A6874)" : "white",
                color: props.widgetType === WidgetType.PriceLadder ? "white" : "var(--primary-main-blue, #006EB6)",
                fontFamily: "Averta-Regular",
                fontSize: "12px",
              }}
            />
          ))}
      </div>
      {errorMessage && (
        <Typography color="error" variant="caption" sx={{ marginBottom: 1, marginLeft: '24px' }}>
          {errorMessage}
        </Typography>
      )}
      <div className="widget-dialog-menu">
        <TreeView
          mode={props.widgetType === WidgetType.PriceLadder ? TreeViewMode.SINGLE : TreeViewMode.MULTIPLE}
          searchQuery={searchQuery}
          selectedItems={selectedTreeItems}
          setSelectedItems={setSelectedTreeItems}
          assetProductsDictionary={assetProductsDictionary}
          productsMapById={productsMapById}
          setErrorMessage={setErrorMessage}
          widgetType={props.widgetType}
        />
      </div>
      <div className="widget-dialog-actions">
        <Button
          sx={{
            width: "186px",
            color: "#006EB6",
            border: "1px solid #006EB6",
            fontFamily: "Averta-Regular",
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: "26px",
            letterSpacing: "1.5px",
          }}
          onClick={() => {
            props.onClose();
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={() => onContractsSelection()}
          sx={{
            width: "186px",
            background: "#006EB6",
            fontFamily: "Averta-Regular",
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: "26px",
            letterSpacing: "1.5px",
          }}
          variant="contained"
          disabled={!selectedTreeItems.length}
        >
          <TreeViewContainerButtonTitle widgetType={props.widgetType} />
        </Button>
      </div>
    </div>
  );
};

export default TreeViewContainer;
