import * as Order2 from "../proto/order_2";
import { CQGEnvironment } from "../services/CQGEnvironment";

export const makeOrder = (position: any) => {
  console.log('position.side', position.side);
  let orderData = {
    type: parseInt( Order2.Order_OrderType.ORDER_TYPE_MKT.toString() ),
    duration: parseInt( Order2.Order_Duration.DURATION_DAY.toString() ),
    side: position.side === "BUY" ? 1 : 2,
    size: Math.abs( position.size ),
    goodThruDate: new Date()
  };
  console.log('position', position);
  var order = CQGEnvironment.Instance.ordersManager.createOrder( 
    position.account,
    position.instrument,
    orderData
  );

  return order;
}