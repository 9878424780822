import { Fragment, useState } from "react";
import { Box, IconButton, Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TradeLog as TradeLogType } from "../../../../hooks/api/types";
import { StyledTableCell, StyledTableRow, StyledTableRowTitle } from "../../../widgets/TradeLog/styles";
import "./TradeLog.css";
import { getOrderDuration, getOrderType } from "../../../../utils/utils";
import { Edit, KeyboardArrowRight } from "@mui/icons-material";
import * as Order2 from "../../../../cqg-api/proto/order_2";
import { OrderSide } from "../../../../types";

export default function Row(props: { row: TradeLogType; onEdit: (trade: TradeLogType, sideType: string) => void }) {
  const { row, onEdit } = props;
  const [openBuyOrder, setOpenBuyOrder] = useState(false);
  const [openSellOrder, setOpenSellOrder] = useState(false);

  const buyOrderDate = row.buyOrder?.statusUtcTime ? new Date(row.buyOrder.statusUtcTime) : null;
  const sellOrderDate = row.sellOrder?.statusUtcTime ? new Date(row.sellOrder.statusUtcTime) : null;

  const formatDate = (date: Date | null): string => {
    if (!date) return "-";
    const options: Intl.DateTimeFormatOptions = {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
  
    return new Intl.DateTimeFormat("en-US", options).format(date).replace(", ", " | ");
  };
  const tryParseJson = (str: any) => {
    try {
      return Array.isArray(JSON.parse(str)) ? JSON.parse(str).join(", ") : str;
    } catch (e) {
      return str;
    }
  };
  
  return (
    <Fragment>
      <StyledTableRowTitle sx={{ "& > *": { borderBottom: "unset" }, paddingLeft: "10px" }}>
        <StyledTableCell sx={{whiteSpace: 'nowrap', fontFamily: 'Averta-Regular', fontWeight: 600, fontSize: "11px", letterSpacing: "1px", textTransform: "uppercase", color: "rgba(8, 29, 55, 0.87)"}} colSpan={14}>1 {row?.cmeSymbol?.cmeSymbol} {row?.instrument?.putCall ? "Option" : "Future"} <span style={{paddingLeft: "20px"}}>P&L: ${row.realizedProfitLoss}</span></StyledTableCell>
      </StyledTableRowTitle>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row buy"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpenBuyOrder(!openBuyOrder);
            }}
          >
            {openBuyOrder ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {formatDate(buyOrderDate)}
        </StyledTableCell>
        <StyledTableCell align="left">Open</StyledTableCell>
        <StyledTableCell align="left">
          {row.buyOrder?.side ? (row.buyOrder.side === Order2.Order_Side.SIDE_BUY ? "BUY" : "SELL") : "-"}
        </StyledTableCell>
        <StyledTableCell align="left">{row.instrument.putCall || "-"}</StyledTableCell>
        <StyledTableCell align="left">{row.instrument.strikePrice || "-"}</StyledTableCell>
        <StyledTableCell align="left">{row.qty || "-"}</StyledTableCell>
        <StyledTableCell align="left">
          {row.buyOrder?.orderType !== null && row.buyOrder?.orderType !== undefined
            ? getOrderType(row.buyOrder.orderType)
            : "-"}
        </StyledTableCell>
        <StyledTableCell align="left">{row.buyOrder?.limitPrice || "-"}</StyledTableCell>
        <StyledTableCell align="left">
          {row.buyOrder?.duration !== null && row.buyOrder?.duration !== undefined
            ? getOrderDuration(row.buyOrder.duration)
            : "-"}
        </StyledTableCell>
        <StyledTableCell align="left">{row.buyOrder?.avgFillPrice || "-"}</StyledTableCell>
        <StyledTableCell align="left">{row.buyOrder?.tradeLogStopLoss || "-"}</StyledTableCell>
        <StyledTableCell align="left">{row.buyOrder?.stopPrice ? `$${row.buyOrder.stopPrice}` : "-"}</StyledTableCell>
        <StyledTableCell align="left" style={{position:'sticky', right:0, background:'#fff', zIndex:10}}>
          <IconButton
            aria-label="edit-buy"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(row, OrderSide.Buy);
            }}
          >
            <Edit sx={{ fontSize: "1rem", color: "#006EB6" }} />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={14}>
          <Collapse in={openBuyOrder} timeout="auto" unmountOnExit>
            <Box className="responsive-row">
              <span className="approach">
                Approach:{" "}
                <span className="approach-text">
                  {row.buyOrder?.tradeLogApproach === "Fundamental"
                    ? [
                        row.buyOrder.tradeLogApproach || "-",
                        Array.isArray(row.buyOrder.tradePlanApproachFundamentalAnalysis)
                          ? row.buyOrder.tradePlanApproachFundamentalAnalysis.join(" | ")
                          : tryParseJson(row.buyOrder.tradePlanApproachFundamentalAnalysis),
                      ]
                        .filter(Boolean)
                        .join(" | ")
                    : row.buyOrder?.tradeLogApproach === "Technical"
                    ? [
                        row.buyOrder.tradeLogApproach,
                        row.buyOrder.tradePlanTechnicalType,
                        Array.isArray(row.buyOrder?.tradePlanTechnicalIndicator)
                          ? row.buyOrder.tradePlanTechnicalIndicator.join(", ")
                          : tryParseJson(row.buyOrder?.tradePlanTechnicalIndicator),
                        row.buyOrder.tradePlanTechnicalChartType,
                      ]
                        .filter(Boolean)
                        .join(" | ") || "-"
                    : "-"}
                </span>
              </span>
              <span className=" mkt-trend">
                Market Trend: <span className="trend-text">{row.buyOrder?.tradeLogTrend}</span>
              </span>
              <span className=" r-to-r">
                Risk Reward Ratio: <span className="rrr-text">{row.buyOrder?.tradeLogRatio}</span>
              </span>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row sell"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpenSellOrder(!openSellOrder);
            }}
          >
            {openSellOrder ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {formatDate(sellOrderDate)}
        </StyledTableCell>
        <StyledTableCell align="left">Close</StyledTableCell>
        <StyledTableCell align="left">
          {row.sellOrder?.side ? (row.sellOrder.side === Order2.Order_Side.SIDE_BUY ? "BUY" : "SELL") : "-"}
        </StyledTableCell>
        <StyledTableCell align="left">{row.instrument.putCall || "-"}</StyledTableCell>
        <StyledTableCell align="left">{row.instrument.strikePrice || "-"}</StyledTableCell>
        <StyledTableCell align="left">{row.qty || "-"}</StyledTableCell>
        <StyledTableCell align="left">
          {row.sellOrder?.orderType !== null && row.sellOrder?.orderType !== undefined
            ? getOrderType(row.sellOrder.orderType)
            : "-"}
        </StyledTableCell>
        <StyledTableCell align="left">{row.sellOrder?.limitPrice || "-"}</StyledTableCell>
        <StyledTableCell align="left">
          {row.sellOrder?.duration !== null && row.sellOrder?.duration !== undefined
            ? getOrderDuration(row.sellOrder.duration)
            : "-"}
        </StyledTableCell>
        <StyledTableCell align="left">{row.sellOrder?.avgFillPrice || "-"}</StyledTableCell>
        <StyledTableCell align="left">{row.sellOrder?.tradeLogStopLoss || "-"}</StyledTableCell>
        <StyledTableCell align="left">{row.sellOrder?.stopPrice ? `$${row.sellOrder.stopPrice}` : "-"}</StyledTableCell>
        <StyledTableCell align="left" style={{position:'sticky', right:0, background:'#fff', zIndex:10}}>
          <IconButton
            aria-label="edit-sell"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(row, OrderSide.Sell);
            }}
          >
            <Edit sx={{ fontSize: "1rem", color: "#006EB6" }} />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={14}>
          <Collapse in={openSellOrder} timeout="auto" unmountOnExit>
            <Box className="responsive-row">
              <span className="approach">
                Approach:{" "}
                <span className="approach-text">
                  {row.sellOrder?.tradeLogApproach === "Fundamental"
                    ? [
                        row.sellOrder.tradeLogApproach || "-",
                        Array.isArray(row.sellOrder.tradePlanApproachFundamentalAnalysis)
                          ? row.sellOrder.tradePlanApproachFundamentalAnalysis.join(" | ")
                          : tryParseJson(row.sellOrder?.tradePlanApproachFundamentalAnalysis),
                      ]
                        .filter(Boolean)
                        .join(" | ")
                    : row.sellOrder?.tradeLogApproach === "Technical"
                    ? [
                        row.sellOrder.tradeLogApproach,
                        row.sellOrder.tradePlanTechnicalType,
                        Array.isArray(row.buyOrder?.tradePlanTechnicalIndicator)
                          ? row.buyOrder.tradePlanTechnicalIndicator.join(", ")
                          : tryParseJson(row.buyOrder?.tradePlanTechnicalIndicator),
                        row.sellOrder.tradePlanTechnicalChartType,
                      ]
                        .filter(Boolean)
                        .join(" | ") || "-"
                    : "-"}
                </span>
              </span>
              <span className=" mkt-trend">
                Market Trend: <span className="trend-text">{row.sellOrder?.tradeLogTrend}</span>
              </span>
              <span className=" r-to-r">
                Risk Reward Ratio: <span className="rrr-text">{row.sellOrder?.tradeLogRatio}</span>
              </span>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </Fragment>
  );
}
