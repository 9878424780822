import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import "./ordersModal.css";
import ModalHeader from "./ModalHeader";
import ModalTable from "./ModalTable";
import ModalFooter from "./ModalFooter";

type Anchor = "top" | "left" | "bottom" | "right";

type OrderDetailsProps = {
  open: boolean;
  onClose: () => void;
  handleAmmendOrder: () => void;
  data: any;
};

export default function OrderDetails({ open, onClose, data, handleAmmendOrder }: OrderDetailsProps) {
  if (!data) return null;
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    onClose();
  };
  const isOption = data.strike !== 0;
  const list = (anchor: Anchor) => (
    <Box role="presentation" onKeyDown={toggleDrawer(false)}>
      <ModalHeader data={data} toggleDrawer={toggleDrawer} />
      <ModalTable data={data} isOption={isOption} />
      <Divider />
      <ModalFooter data={data} onClose={onClose} />
    </Box>
  );

  return (
    <Drawer anchor={"bottom"} open={open} onClose={onClose}>
      {list("bottom")}
    </Drawer>
  );
}
