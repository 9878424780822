import * as WebAPI from "../proto/webapi_2";
import * as TradeRouting from "../proto/trade_routing_2";
import { CQGEnvironment } from "../services/CQGEnvironment";
import { CQGService } from "../services/CQGService";
import { CQGServiceMessageEventManager } from "./ServiceMessageEventManager";
import { LogonResult, LogonResult_ResultCode } from "../proto/user_session_2";

export class CQGServiceMessageManager {
  private messageQueue: WebAPI.ClientMsg[] = [];
  private static reqCount = 0;
  private static instance: CQGServiceMessageManager;
  private env!: CQGEnvironment;
  private cqgService!: CQGService;
  // private serviceMessageEventManager: CQGServiceMessageEventManager;

  constructor(env: CQGEnvironment, cqgService: CQGService, serviceMessageEventManager: CQGServiceMessageEventManager) {
    if (CQGServiceMessageManager.instance) {
      return CQGServiceMessageManager.instance;
    }

    CQGServiceMessageManager.instance = this;
    this.env = env;
    this.cqgService = cqgService;

    serviceMessageEventManager.onServiceReady((logonResult: LogonResult) => {
      if (logonResult.resultCode === LogonResult_ResultCode.RESULT_CODE_SUCCESS) {
        this.scheduleMessage();
      }
    });
  }

  static get Instance() {
    return CQGServiceMessageManager.instance;
  }

  public static nextRequestId() {
    return (this.reqCount += 1);
  }

  public nextRequestId() {
    return CQGServiceMessageManager.nextRequestId();
  }

  sendMessage(msg: WebAPI.ClientMsg) {
    if (!this.cqgService.isReady) {
      // console.log("Sending message: in-queue:", msg);
      this.messageQueue.push(msg);
    } else {
      try {
        this.cqgService.send(msg);
        // console.log("Sending message: Sent!");
      } catch (error) {
        // console.error("Error sending message:", error);
      }
    }
  }

  scheduleMessage = () => {
    if (this.cqgService.isReady && this.messageQueue.length > 0) {
      while (this.messageQueue.length > 0) {
        const msg = this.messageQueue.shift();
        // console.log("Sending message de-queue:", msg);
        if (msg) {
          this.sendMessage(msg);
        }
      }
    }
  };

  sendInformationRequest = (requests: WebAPI.InformationRequest) => {
    let clMsg = WebAPI.ClientMsg.create();
    clMsg.informationRequests = [requests];
    this.sendMessage(clMsg);
  };

  sendTradeSubscription = (tradeSubscriptions: TradeRouting.TradeSubscription[]) => {
    var clMsg = WebAPI.ClientMsg.create();
    clMsg.tradeSubscriptions = [...tradeSubscriptions];
    this.sendMessage(clMsg);
  };
}
