import UserInfo from "../../../common/userinfo";
import ProductsNav from "../../../common/userinfo/ProductsNav";
import ToolsNav from "../../../common/userinfo/Tools";
import { Drawer, Switch } from "@mui/material";
import DrawerComponent from "@mui/material/Drawer";
import { ChevronLeft } from "@mui/icons-material";
import "./sidebar.css";
import { Widget, WidgetType } from "../../../../types";
import { useState } from "react";
import TreeViewContainer from "../../../tree-view/TreeViewContainer";

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
  onRequestToAddNewWidget: (widget: Widget) => void;
}

const Sidebar = ({ isOpen, onToggle, onRequestToAddNewWidget }: SidebarProps) => {
  const [open, setOpen] = useState(false);
  const [widgetToAdd, setWidgetToAdd] = useState<Widget | null>(null);

  const productSelectionList = [WidgetType.Chart, WidgetType.PriceLadder, WidgetType.Watchlist];

  const handleToggle = () => (open ? (showProductDrawer(false), setTimeout(onToggle, 200)) : onToggle());
  const onContractsSelection = (contractIds: string[]) => {
    if (widgetToAdd) {
      let widget = {...widgetToAdd, contractIds: contractIds || []}
      onRequestToAddNewWidget(widget);
      showProductDrawer(false);
    } else {
      throw new Error("Widget not found");
    }
  };

  const onWidgetAdd = (widget: Widget) => {
    setWidgetToAdd(widget);
    if (productSelectionList.includes(widget.WidgetType as WidgetType)) {
      showProductDrawer(true);
    } else {
      showProductDrawer(false);
      onRequestToAddNewWidget(widget);
    }
  };

  const showProductDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const drawerStyle = {
    "& .MuiModal-modal": {
      zIndex: 10,
    },
    "& .MuiModal-backdrop": {
      left: 180,
      top: 83,
    },
    "& .MuiDrawer-paper": {
      width: 420,
      boxSizing: "border-box",
      backgroundColor: "#f4f4f4",
      left: 180,
      top: 83,
      boxShadow: "none",
    },
  };

  return (
    <>
      <nav>
        <Drawer
          anchor="left"
          open={isOpen}
          onClose={handleToggle}
          variant="persistent"
          sx={{
            width: isOpen ? 180 : 0,
            height: "calc(100vh - 115px)",
            transition: "width 0.1s ease",
            "& .MuiDrawer-paper": {
              width: isOpen ? 180 : 0,
              top: 2,
              position: "relative",
              overflow: "hidden",
            },
          }}
        >
          <div className="sidebar-content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 8px 6px 16px",
                borderBottom: "1px solid var(--divider, #0000001F)",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleToggle}
            >
              <UserInfo />
              <ChevronLeft />
            </div>
            <ProductsNav onWidgetAdd={onWidgetAdd} />
            <ToolsNav />
          </div>
        </Drawer>
        <DrawerComponent style={{ zIndex: 10 }} sx={drawerStyle} open={open} onClose={() => showProductDrawer(false)}>
          {/* {DrawerList} */}
          <TreeViewContainer
            onClose={() => showProductDrawer(false)}
            onContractsSelection={onContractsSelection}
            widgetType={widgetToAdd?.WidgetType as WidgetType}
            selectedTreeItems={[]}
          />
        </DrawerComponent>
        <div className="switch-container">
          <Switch className="toggle-switch" />
          <span className="toggle-label">1-Click</span>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
