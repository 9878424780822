import { Widget } from "../../../types";
import "./Options.css";
import Table from "./Table";
import HeaderDatagrid from "./HeaderDatagrid";
import { useState } from "react";

interface OptionsProps {
  workspaceId: number;
  widget: Widget;
  isMobile?: boolean;
}

const Options = ({ workspaceId, widget, isMobile }: OptionsProps) => {
  const [selectedInstrument, setSelectedInstrument] = useState();

  return (
    <>
      <div className="options-grid-container" style={{ height: "100px" }}>
        <HeaderDatagrid
          widget={widget}
          setSelectedInstrument={setSelectedInstrument}
          selectedInstrument={selectedInstrument}
          isMobile={isMobile}
        />
      </div>
      <Table selectedInstrument={selectedInstrument} workspaceId={workspaceId} isMobile={isMobile} />
    </>
  );
};

export default Options;
