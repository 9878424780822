export const convertSymbolToDate = (symbol: any) => {
  const monthMap: any = {
    F: "JAN",
    G: "FEB",
    H: "MAR",
    J: "APR",
    K: "MAY",
    M: "JUN",
    N: "JUL",
    Q: "AUG",
    U: "SEP",
    V: "OCT",
    X: "NOV",
    Z: "DEC",
  };

  // Extract month and year from the symbol

  const len = symbol.length;

  const monthChar: any = symbol[len - 2];
  const yearChar = symbol[len - 1];

  // Get the month name
  const monthName = monthMap[monthChar];

  // Construct the year
  const year = "202" + yearChar; // Assuming the year is in 2000s

  // Return the formatted string
  return `${monthChar} - ${monthName} ${year}`;
};
