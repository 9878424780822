import { Box, Link, Stack, Typography } from "@mui/material";
import ControlledRadioGroup from "../RadioGroup";
import { Control } from "react-hook-form";
import { TradeLogProps } from "../../../../../types";
import { RISK_REWARD_RATIO_OPTIONS } from "../types";

const RiskReward = ({ control }: { control: Control<TradeLogProps, any> }) => {
  return (
    <Box component="section" className="section-outer">
      <Stack
        direction={{ md: "column", lg: "row" }}
        spacing={2}
        sx={{
          justifyContent: { xs: "center", md: "space-between" },
          alignItems: { xs: "center", md: "flex-start" },
          gap: { xs: 1, md: 2, lg: 10 },
        }}
      >
        <Box className="section-left" sx={{ width: { xs: "100%", md: "auto" } }}>
          <Box className="section-sub-left">
            <Typography className="section-title">Section D:</Typography>
            <Typography className="section-sub-title">Risk Reward Ratio</Typography>
          </Box>
          <Box className="section-sub-right">
            <Typography className="section-middle" fontSize="12px">
              Setting a base risk/reward ratio for your trading defines your preference for how much reward you believe
              is available in the trade vs. how much money you put at risk. At 1:1, you’d be willing to risk $10 to make
              $10. At 5:1, you’d be willing to risk $10 to make $50.
            </Typography>
            <Link
              href="https://www.cmegroup.com/education/courses/trade-and-risk-management.html"
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more in Trading and Risk Management
            </Link>
          </Box>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "auto" } }}>
          <ControlledRadioGroup
            control={control}
            title="Please select ratio"
            options={RISK_REWARD_RATIO_OPTIONS}
            name="ratio"
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default RiskReward;
