import { useCallback } from "react";
import "dockview/dist/styles/dockview.css";
import Dockview from "../../../dockview/Dockview";
import { Widget, Workspace } from "../../../../types";
import "./Dashboard.css";
import WorkspaceTabs from "./WorkspaceTabs";
import AppMessagesHost from "../../../shared/AppMessages";

export type DashboardProps = {
  activeWorkspace?: Workspace;
  workspaceLoading: boolean;
  workSpaces: Workspace[];
  onActiveWorkSpaceChange: (workspace: Workspace) => void;
  onNewWorkSpaceCreated: (workspace: Workspace) => void;
  onDeleteWorkspace: (workspace: Workspace) => Promise<void>;
  deleteWorkspaceLoading: boolean;
  onSetDefaultWorkspace: (workspace: Workspace) => void;
  handleRenameWorkspace: (workspace: Workspace, newTitle: string) => Promise<void>;
  updateWorkspaceLoaded: boolean;
  onWorkSpaceUpdated: (workspace: Workspace) => void;
  onDuplicateWorkspace: (workspace: Workspace) => void;
  isSidebarOpen: boolean;
  onSidebarToggle: () => void;
  workspaceTabsLimit: number;
  newWidgetToAddToPanel: Widget | null;
  onNewWidgetProcessed: () => void;
};

const Dashboard = ({
  activeWorkspace,
  workspaceLoading,
  workSpaces,
  onActiveWorkSpaceChange,
  onNewWorkSpaceCreated,
  onDeleteWorkspace,
  deleteWorkspaceLoading,
  onSetDefaultWorkspace,
  handleRenameWorkspace,
  updateWorkspaceLoaded,
  onWorkSpaceUpdated,
  onDuplicateWorkspace,
  isSidebarOpen,
  onSidebarToggle,
  workspaceTabsLimit,
  newWidgetToAddToPanel,
  onNewWidgetProcessed
}: DashboardProps) => {

  const handleNewWorkspaceCreated = useCallback(
    (workspace: Workspace) => {
      onNewWorkSpaceCreated(workspace);
    },
    [onNewWorkSpaceCreated],
  );

  return (
    <>
      <div className="TabsLayout">
        <WorkspaceTabs
          workSpaces={workSpaces}
          activeWorkspace={activeWorkspace}
          onActiveWorkSpaceChange={onActiveWorkSpaceChange}
          onDeleteWorkspace={onDeleteWorkspace}
          deleteWorkspaceLoading={deleteWorkspaceLoading}
          handleNewWorkspaceCreated={handleNewWorkspaceCreated}
          onSetDefaultWorkspace={onSetDefaultWorkspace}
          handleRenameWorkspace={handleRenameWorkspace}
          updateWorkspaceLoaded={updateWorkspaceLoaded}
          onDuplicateWorkspace={onDuplicateWorkspace}
          onSidebarToggle={onSidebarToggle}
          isSidebarOpen={isSidebarOpen}
          workspaceTabsLimit={workspaceTabsLimit}
        />
        <div className={`TabsContent ${isSidebarOpen ? "showMenuTabsContent" : "hideMenuTabsContent"}`}>
          {activeWorkspace ? (
            <Dockview
              activeWorkspace={activeWorkspace}
              workspaceLoading={workspaceLoading}
              onWorkSpaceUpdated={onWorkSpaceUpdated}
              newWidgetToAddToPanel={newWidgetToAddToPanel}
              onNewWidgetProcessed={onNewWidgetProcessed}
            />
          ) : workSpaces?.length < workspaceTabsLimit && (
            <div className="emptyWorkspace">
              <img
                src="/images/icons/workspace.svg"
                alt="Workspace"
                height="48"
                width="46"
                style={{ color: "#5A6874" }}
              />
              <h3>New Workspace</h3>
              <p>Welcome to your new workspace</p>
              <p>Click '+' to create your workspace</p>
            </div>
          )}
        </div>
      </div>
      <AppMessagesHost />
    </>
  );
};

export default Dashboard;
