import { MenuItem, Select } from "@mui/material";
import { OrderStatus_Status } from "../../../cqg-api/proto/common/shared_1";

const orderFilters = [
  { label: "All orders", orderStatusId: 0 },
  { label: "Working orders", orderStatusId: OrderStatus_Status.WORKING },
  { label: "Filled orders", orderStatusId: OrderStatus_Status.FILLED },
  { label: "Cancelled orders", orderStatusId: OrderStatus_Status.CANCELLED },
];
const OrdersFilter = ({
  activeFilter,
  setActiveFilter,
  isSmallPanel
}: {
  activeFilter: number;
  setActiveFilter: React.Dispatch<React.SetStateAction<number>>;
  isSmallPanel: boolean;
}) => {
  const selectOrderType = (id: number) => {
    setActiveFilter(id);
  };
  return (
    <div className="order-type-wrapper">
      {isSmallPanel ? (
        <Select
          value={activeFilter}
          onChange={(e) => selectOrderType(e.target.value as number)}
          displayEmpty
          sx={{
            padding: 0,
            minWidth: "196px",
            fontSize: '12px',
            overflow: 'hidden',
            '& .MuiSelect-select': {
              padding: '5px',
              fontSize: '12px',
              background: 'white',
            },
          }}
        >
          {orderFilters.map((filter) => (
            <MenuItem value={filter.orderStatusId} key={filter.orderStatusId}>
              {filter.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        orderFilters.map((filter) => {
          const isActive = activeFilter === filter.orderStatusId ? "order-type-active" : "";
          return (
            <button
              onClick={() => selectOrderType(filter.orderStatusId)}
              className={`order-type ${isActive}`}
              key={filter.orderStatusId}
            >
              {filter.label}
            </button>
          );
        }))}
    </div>
  );
};

export default OrdersFilter;
