import React from "react";
import { Controller, Control, FieldValues } from "react-hook-form";

interface PutCallSelectorProps {
  control: Control<FieldValues>;
  putCallValue?: "P" | "C";
  onOptionChange: (option: "P" | "C") => void;
  disabled?: boolean;
}

const PutCallSelector: React.FC<PutCallSelectorProps> = ({
  control,
  putCallValue,
  onOptionChange,
  disabled,
}) => {

  return (
    <Controller
      name="putCall"
      control={control}
      rules={{
        required: 'Put/Call or Strike is invalid',
      }}
      render={({ field }) => (
        <div className="timeInForce">
          <button
            type="button"
            onClick={() => onOptionChange("P")}
            className={`timeInDay ${putCallValue === "P" ? "active" : ""}`}
            disabled={disabled}
          >
            PUT
          </button>
          <button
            type="button"
            onClick={() => onOptionChange("C")}
            className={`timeInDay ${putCallValue === "C" ? "active" : ""}`}
            disabled={disabled}
          >
            CALL
          </button>
        </div>
      )}
    />
  );
};

export default PutCallSelector;
