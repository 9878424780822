import { useCallback, useEffect, useMemo, useState } from "react";
import { useFetchProducts } from "../../hooks/api/product";
import { OrderStatus_Status } from "../../cqg-api/proto/common/shared_1";
import { OrderOption, OrderRow } from "../../types";
import { cancelOrders } from "../widgets/orders/actions";
import { useOrdersData } from "../../OrdersDataProvider";

const useOrders = () => {
  const [activeFilter, setActiveFilter] = useState(0);
  const { loadProducts } = useFetchProducts();
  const { ordersList } = useOrdersData();
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSelectedDialog, setOpenSelectedDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);

  const handleCancelAllOrders = useCallback(() => {
    setLoading(true);
    try {
      cancelOrders(ordersList);
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  }, [ordersList]);

  const setFilter = (filter: number) => {
    setActiveFilter(filter);
  };
  useEffect(() => {
    loadProducts();
  }, [loadProducts]);
  const dataRows = useMemo(() => {
    return ordersList
      .map((order, i) => ({
        id: i,
        side: order.displaySide,
        symbol: order.displayContract,
        month: order.month,
        strike: order.strikePrice,
        cp: order.putCall === "C" ? OrderOption.Call : order.putCall === "P" ? OrderOption.Put : "",
        qty: order.displaySize,
        leaves: order.displayUnfilledSize,
        type: order.displayType,
        fillPX: order.displayAvgFillPrice,
        limitPX: order.displayLimitPrice,
        stopPX: order.displayStopPrice,
        status: order.status,
        order: order,
        contract: order.instrument?.cmeSymbolName,
      }))
      .filter((order) => activeFilter === 0 || Number(order.status) === activeFilter);
  }, [ordersList, activeFilter]);

  const hasWorkingOrders = useMemo(() => {
    return ordersList.some(
      (order) =>
        order.status === OrderStatus_Status.WORKING && (activeFilter === 0 || Number(order.status) === activeFilter),
    );
  }, [ordersList, activeFilter]);
  const showOrderDetails = (order: OrderRow) => {
    setSelectedOrder(order);
    setOpenSelectedDialog(true);
  };

  const closeOrderDetails = () => {
    setOpenSelectedDialog(false);
  }
  const handleAmmendOrder = () => {
    // to be implemented
  }
  return {
    activeFilter,
    dataRows,
    setFilter,
    hasWorkingOrders,
    loading,
    openDialog,
    setOpenDialog,
    handleCancelAllOrders,
    showOrderDetails,
    closeOrderDetails,
    openSelectedDialog,
    selectedOrder,
    handleAmmendOrder,
  };
};

export default useOrders;
