import { DockviewApi, IDockviewPanelProps } from "dockview";
import { DockerviewPanelProps, Widget, WidgetType, Workspace } from "../../types";
import AccountValue from "../widgets/accountValue/AccountValue";
import AvailableProducts from "../widgets/availableProducts/AvailableProducts";
import Orders from "../widgets/orders/Orders";
import Positions from "../widgets/positions/Positions";
import Watchlist from "../widgets/watchlist/Watchlist";
import EnergyChart from "../chart/EnergyChart";
import BarChartDemo from "../chart/BarChartDemo";
import LineChartDemo from "../chart/LineChartDemo";
import TradeLog from "../widgets/TradeLog/index";
import BarChart from "../chart/BarChart";
import TradingChart from "../widgets/tradingChart/TradingChart";
import React from "react";

let panelCount = 0;

const generateUniqueId = () => {
  return `id${Date.now().toString()}${(panelCount++).toString()}`;
};

export const addPanels = (workspace: Workspace, dockviewApi: DockviewApi, widgets: Widget[], referenceGroup: any) => {
  let referencePanel: any = undefined;
  let referencePanel2: any = undefined;
  widgets?.forEach((widget, index) => {
    const uniqueId = generateUniqueId();
    const panel = dockviewApi.addPanel<DockerviewPanelProps>({
      id: uniqueId,
      component: "default",
      renderer: "always",
      title: widget.WidgetType,
      params: {
        widget,
        workspace,
      },
      position: {
        referencePanel: index % 2 === 0 && !referenceGroup ? referencePanel2 : referencePanel,
        referenceGroup: referenceGroup,
        direction: !referenceGroup
          ? index > 0
            ? index % 2 === 0
              ? "below"
              : index < 2
                ? "right"
                : "below"
            : "within"
          : undefined,
      },
    });

    if (!referenceGroup) {
      if (index % 2 === 0) {
        referencePanel2 = panel;
      } else {
        referencePanel = panel;
      }
    } else {
      if (index % 2 === 0) {
        referencePanel = panel;
      }
    }
  });
};

export const addPanel = (dockviewApi: DockviewApi, widget: Widget, workspace: Workspace) => {
  const uniqueId = generateUniqueId();
  dockviewApi.addPanel<DockerviewPanelProps>({
    id: uniqueId,
    component: "default",
    renderer: "always",
    title: widget.WidgetType,
    params: {
      widget,
      workspace,
    },
  });
};

export const addChartPanel = (dockviewApi: DockviewApi, widget: Widget, workspace: Workspace) => {
  const uniqueId = generateUniqueId();
  dockviewApi.addPanel<DockerviewPanelProps>({
    id: uniqueId,
    component: "default",
    renderer: "always",
    title: widget.WidgetType,
    params: {
      widget,
      workspace,
    },
    position: { direction: 'right' }
  });
};

export const componentFactory = (widget: Widget, workspace: Workspace) => {
  const workspaceClassName = "grid-layout-workspace";
  const componentName = widget.title;
  if (!workspace?.id) return <>No workspace found</>;
  switch (componentName) {
    case WidgetType.Watchlist:
      return <Watchlist widget={widget} workspaceId={workspace.id} workspaceClassName={workspaceClassName} />;
    case WidgetType.AccountInfo:
      return <AccountValue selectedWorkspace={workspace} workspaceClassName={workspaceClassName} />;
    case WidgetType.AvailableProducts:
      return <AvailableProducts selectedWorkspace={workspace} workspaceClassName={workspaceClassName} />;
    case WidgetType.Orders:
      return <Orders workspaceId={workspace.id} workspaceClassName={workspaceClassName} />;
    case WidgetType.Positions:
      return <Positions workspaceId={workspace.id} workspaceClassName={workspaceClassName} />;
    case WidgetType.TradeLogs:
      return <TradeLog />;
    case WidgetType.Chart:
      return <TradingChart symbols={widget.contractIds} />;
    case WidgetType.PriceLadder:
      return <TradingChart symbols={widget.contractIds} />;
    case WidgetType.EnergyChart:
      return (
        <EnergyChart selectedWorkspace={workspace} workspaceClassName={workspaceClassName} isPopupWindow={undefined} />
      );
    case WidgetType.BarChart:
      return (
        <BarChart selectedWorkspace={workspace} workspaceClassName={workspaceClassName} isPopupWindow={undefined} />
      );
    case WidgetType.LineChart:
      return (
        <LineChartDemo
          selectedWorkspace={workspace}
          workspaceClassName={workspaceClassName}
          isPopupWindow={undefined}
        />
      );
    case WidgetType.DoubleBarChart:
      return (
        <BarChartDemo selectedWorkspace={workspace} workspaceClassName={workspaceClassName} isPopupWindow={undefined} />
      );

    default:
      return <div>No widget found</div>;
  }
};

function RenderWhenVisible(
  component: React.FunctionComponent<IDockviewPanelProps>
) {
  const HigherOrderComponent = (props: IDockviewPanelProps) => {
      const [visible, setVisible] = React.useState<boolean>(
          props.api.isVisible
      );

      React.useEffect(() => {
          const disposable = props.api.onDidVisibilityChange((event) =>
              setVisible(event.isVisible)
          );

          return () => {
              disposable.dispose();
          };
      }, [props.api]);

      if (!visible) {
          return null;
      }

      return React.createElement(component, props);
  };
  return HigherOrderComponent;
}

export const components = {
  default: RenderWhenVisible((props: IDockviewPanelProps<DockerviewPanelProps>) => {
    return (
      <div
        style={{
          height: "100%",
          overflow: "auto",
          color: "white",
          position: "relative",
          width: "100%",
        }}
      >
        {componentFactory(props.params.widget, props.params.workspace)}
      </div>
    );
  })
};
