import { Action, State } from './reducerTypes';
import { DisplayUtil } from '../../cqg-api/utils/DisplayUtil';

export function createStrategyReducer(
    calculateChangeByField: (strategy: State, field?: string, updateByTicks?: boolean) => State,
    setValue: any,
    watch: any,
    selectedInstrument: any
  ) {
    return (state: State, action: Action) => {
        const {type, payload, data, prices, isProfit, isLoss, isProfitPrice, isLossPrice, isProfitPercent, isLossPercent} = action;

        switch(type) {
            case 'ticks': {      
                const updatedState = { ...state, ticks: payload || 0 };
                const strategy = calculateChangeByField(updatedState, 'ticks');
                handleFieldUpdate('ticks', strategy, setValue)
                return {...state, ...strategy};
            }
            case 'price': {
                let tickPrice = Number(payload);
                let p = prices;
                if(isProfitPrice) {
                tickPrice = Number(watch('profitPrice'));
                }

                if(isLossPrice) {
                tickPrice = Number(watch('lossPrice'));
                }
            
                if(isProfitPrice || isLossPrice) {
                const rawPrice = DisplayUtil.fromDisplayPrice(tickPrice, selectedInstrument)
                const displayPrice = DisplayUtil.toDisplayPrice(Number(rawPrice), selectedInstrument)
                p = { price: Number(rawPrice), displayPrice: Number(displayPrice)}
                }

                const updatedState = { ...state, ...p };
                const strategy = calculateChangeByField(updatedState, 'price');
                handleFieldUpdate('price', strategy, setValue)

                return {...state, ...strategy};
            }
            case 'pnl': {
                let pnl = Number(payload);
                if (isProfit) {
                pnl = Number(watch('profit'));
                }
                if(isLoss) {
                pnl = Number(watch('loss'));
                }
                const updatedState = { ...state, pnl: pnl };
                const strategy = calculateChangeByField(updatedState, 'pnl');

                handleFieldUpdate('pnl', strategy, setValue)


                return {...state, ...strategy};
            }
            case 'percent': {
                let percent = Number(payload);
                if (isProfitPercent) {
                percent = Number(watch('profitPercentage'));
                }
                if(isLossPercent) {
                percent = Number(watch('lossPercentage'));
                }
                const updatedState = { ...state, percent: percent };
                const strategy = calculateChangeByField(updatedState, 'percent');
                handleFieldUpdate('percent', strategy, setValue)

                return {...state, ...strategy};
            }
            case 'canTakeProfit': {
                const updatedState = { ...state, canTakeProfit: !!payload };
                return {...state, ...updatedState};
            }
            case 'canStopLoss': {
                const updatedState = { ...state, canStopLoss: !!payload };
                return {...state, ...updatedState};
            }
            case 'enabled': {
                const updatedState = { ...state, enabled: !!payload };
                return {...state, ...updatedState};
            }
            case 'set_data': {
                const updatedState = { ...state, ...data };
                handleFieldUpdate('', updatedState, setValue);
                return {...state, ...updatedState};
            }
            default:
                return state;
            } 
}}

const handleFieldUpdate = (skipField: string, strategy: State, setValue: any) => {
    const fields = ['ticks', 'pnl', 'price', 'percent']

    const f = fields.filter((f) => f !== skipField);

    f.map((field) => {
      switch (field) {
        case 'ticks':
          if(strategy.canTakeProfit) {
            setValue('profitTicks', strategy.ticks);
          } else if(strategy.canStopLoss) {
            setValue('lossTicks', strategy.ticks);
          }
          break;
        case 'pnl':
          if(strategy.canTakeProfit) {
            setValue('profit', strategy.pnl);
          } else if(strategy.canStopLoss) {
            setValue('loss', strategy.pnl);
          }
          break;
        case 'percent':
          if(strategy.canTakeProfit) {
            setValue('profitPercentage', strategy.percent);
          } else if(strategy.canStopLoss) {
            setValue('lossPercentage', strategy.percent);
          }
          break;
        case 'price':
          if(strategy.canTakeProfit) {
            setValue('profitPrice', strategy.displayPrice);
          } else if(strategy.canStopLoss) {
            setValue('lossPrice', strategy.displayPrice);
          }
          break;
      
        default:
          break;
      }
    })
}
