import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Paper, CircularProgress, DialogActions, Button } from '@mui/material';

interface ConfirmCancelDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  message?: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  loading?: boolean;
}

const ConfirmCancelDialog = ({
  open,
  onClose,
  onConfirm,
  title = 'Confirm Order Cancellation',
  message = 'Are you sure you want to cancel this order?',
  cancelText = 'No, Go Back',
  confirmText = 'Yes, Cancel',
  loading = false,
}: ConfirmCancelDialogProps) => {

  const [confirmOnceClicked, setConfirmOnceClicked] = useState(false);

  useEffect(() => {
    !!open && setConfirmOnceClicked(false);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} PaperComponent={(props) => <Paper {...props} sx={{ width: 444 }} />}>
      <DialogTitle sx={{
        padding: '16px 24px',
        fontSize: '24px',
        fontWeight: 400,
        color: 'rgba(8, 29, 55, 0.87)',
        fontFamily: 'Averta-regular',
        letterSpacing: '0.15px'
      }}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ padding: '0px 24px', minHeight: 64, alignContent: 'center' }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: '16px',
            color: 'rgba(8, 29, 55, 0.87)',
            lineHeight: '150%',
            overflow: 'hidden',
            fontFamily: 'Averta-regular',
            letterSpacing: '0.15px',
          }}
        >
          {message}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '8px',
          gap: '8px',
        }}
      >
        <Button
          disabled={!cancelText}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 11px',
            width: '130px',
            height: '42px',
            borderRadius: '4px',
            fontFamily: 'Averta-Regular',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '1.5px',
            textTransform: 'uppercase',
            color: !cancelText ? 'rgba(8, 29, 55, 0.4)' : 'rgba(8, 29, 55, 0.87)',
            '&:hover': {
              color: !cancelText ? 'rgba(8, 29, 55, 0.4)' : '#555',
            },
          }}
          onClick={onClose}
        >
          {cancelText}
        </Button>
        <Button
          disabled={confirmOnceClicked}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 11px',
            width: '127px',
            height: '42px',
            borderRadius: '4px',
            fontFamily: 'Averta-Regular',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '1.5px',
            textTransform: 'uppercase',
            color: confirmText === 'ok'
              ? (loading ? '#1565C080' : '#1565C0')
              : (loading ? '#D32F2F80' : '#D32F2F'),
            cursor: loading ? 'default' : 'pointer',
            '&:hover': {
              color: confirmText === 'ok'
                ? (loading ? '#1565C080' : '#0d47a1')
                : (loading ? '#D32F2F80' : '#9c2d2d'),
            },
            whiteSpace: 'nowrap',
            marginLeft: 0
          }}
          onClick={() => {
            setConfirmOnceClicked(true);
            !!onConfirm && onConfirm();
          }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmCancelDialog;
