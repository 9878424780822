import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Input } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { widgets } from "../workspace/mockData";
import { Widget } from "../../types";
import React, { useState } from "react";

interface WidgetSelectionDialogProps {
  title: string;
  show: boolean;
  onOkay: (widgets: Widget[], title?: string) => void;
  onCancel: () => void;
  isShowTitle: boolean;
}

const WidgetSelectionDialog = (props: WidgetSelectionDialogProps) => {
  const { show, title, onCancel, onOkay, isShowTitle } = props;
  const [titleValue, setTitleValue] = useState("");
  const [selectedWidgets, setSelectedWidgets] = useState<Widget[]>([]);
  const onWidgetSelect = (widget: Widget) => {
    setSelectedWidgets((prevSelectedWidgets) => {
      // Check if the widget is already in the array
      const isSelected = prevSelectedWidgets.some((wid) => wid.key === widget.key);

      if (isSelected) {
        // Remove the widget from the array
        return prevSelectedWidgets.filter((wid) => wid.key !== widget.key);
      } else {
        // Add the widget to the array
        return [...prevSelectedWidgets, widget];
      }
    });
  };

  const isSelectedWidget = (key: number) => {
    return selectedWidgets?.find((k) => k.key === key);
  };
  const handleCreateWidgets = async () => {
    onOkay(selectedWidgets, titleValue);
  };

  return (
    <Dialog
      open={show}
      onClose={onCancel}
      PaperProps={{
        style: {
          background: "#fff",
          color: "hsl(240, 7%, 14%)",
          padding: "4px",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle>
        {title}
        <IconButton
          edge="end"
          color="inherit"
          onClick={onCancel}
          aria-label="close"
          sx={{ position: "absolute", right: 12, top: 4 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isShowTitle && (
          <Box style={{ marginBottom: 20, width: "100%" }}>
            <Input placeholder="Title" value={titleValue} onChange={(val) => setTitleValue(val.target.value)} />
          </Box>
        )}
        <div className="dialog-widget-selection">
          {widgets.map((widget: Widget) => {
            if (['Price Ladder', 'News'].includes(widget.widgetType)) {
              return <span style={{ display: "none" }}></span>;
            }

            return (
              <div key={widget?.key}>
                <Button
                  className={`widget-button ${isSelectedWidget(widget.key) ? "active" : ""}`}
                  onClick={() => {
                    onWidgetSelect(widget);
                  }}
                >
                  {typeof widget.icon === "string" ? (
                    <img src={widget.icon} alt={`${widget.icon} icon`} style={{ width: "15px", height: "15px", color: "#5A6874" }} />
                  ) : (
                    widget.icon && React.createElement(widget.icon, { sx: { fontSize: "15px", color: "#5A6874" } })
                  )}
                  {/* <img className="button-icon" src={widget.icon} alt={widget.title} /> */}
                  <div className="button-text">{widget.title}</div>
                </Button>
              </div>
            );
          })}
        </div>
        <Box style={{ display: "flex" }} justifyContent="flex-end">
          <Button
            disabled={(isShowTitle && titleValue.length < 1) || (selectedWidgets && selectedWidgets?.length < 1)}
            onClick={handleCreateWidgets}
            variant="contained"
          >
            Create
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WidgetSelectionDialog;
