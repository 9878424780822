import { Grid, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import MinusIcon from "../../../../TradeTicketDrawer/MinusIcon";
import PlusIcon from "../../../../TradeTicketDrawer/PlusIcon";
import { onlyNumbersRules } from "../../utils";
import { defaultBorderColor, inputStyles, redErrorColor } from "../../../../TradeTicketDrawer/styles";
import { IFormInput } from "../../../../TradeTicketDrawer/types";
import { OrderSide } from "../../../../../types";
import { SectionKey, SectionKeyType } from "../../types";
import { insertNumberInput } from "../../../../TradeTicketDrawer/utils";

interface PercentageProps {
  control: Control<IFormInput, any>;
  setValue: (name: string, value: number) => void;
  name: any;
  onPercentageChange: (percent: number) => void;
  errors?: any;
  orderSide: OrderSide;
  sectionKey: SectionKeyType;
}

const Percentage = ({
  control,
  setValue,
  name,
  onPercentageChange,
  errors,
  orderSide,
  sectionKey,
}: PercentageProps) => {
  const handleValueChange = (adjustment: number, currentValue: number, onChange: (...event: any[]) => void) => {
    const newValue = Number((Number(currentValue) + adjustment).toFixed(2));
    if (newValue >= 0) {
      onChange(newValue);
      onPercentageChange(newValue);
    }
  };

  const handleInputChange = (e: any) => {
    insertNumberInput(e, true);
    if (e.target.value === "") {
      onPercentageChange(Number(e.target.value));
      setValue(name, Number(e.target.value));
    } else {
      onPercentageChange(Number(e.target.value));
      setValue(name, Number(e.target.value));
    }
  };

  return (
    <Grid item xs={6} sx={{ paddingTop: "6px !important" }}>
      <label className="label">Percentage %</label>
      <div
        className="control"
        style={{
          borderColor: errors?.["oco"]?.[orderSide]?.[sectionKey]?.[
            sectionKey === SectionKey.TakeProfit ? "profitPercentage" : "lossPercentage"
          ]
            ? redErrorColor
            : defaultBorderColor,
        }}
      >
        <Controller
          name={name}
          control={control}
          rules={{
            ...onlyNumbersRules,
            required: `${sectionKey === SectionKey.TakeProfit ? "Profit" : "Loss"} % is required`,
          }}
          render={({ field }) => (
            <>
              <button
                type="button"
                className="minusButton"
                onClick={() => handleValueChange(-0.01, field.value, field.onChange)}
              >
                <MinusIcon />
              </button>

              <TextField
                {...field}
                type="number"
                fullWidth
                value={field.value}
                onInput={handleInputChange}
                variant="outlined"
                sx={inputStyles}
                data-testid={name}
              />

              <button
                type="button"
                className="minusButton"
                onClick={() => handleValueChange(0.01, field.value, field.onChange)}
              >
                <PlusIcon />
              </button>
            </>
          )}
        />
      </div>
    </Grid>
  );
};

export default Percentage;
