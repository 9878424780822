import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";
import { checkIconStyles } from '../widgets/availableProducts/marketStyles';

export const selectStyle = {
  minWidth: "fit-content",
  width: '50%',
  fontSize: '14px',
  padding: '5px',
  fontFamily: 'Averta-Regular',
  '& .MuiSelect-select': {
    padding: '5px',
    fontSize: '14px',
    background: 'white',
  },
};
export type FilterProductsProps = {
  value: string;
  selectFilter: (event: string) => void;
};
const FilterProducts = ({ value, selectFilter }: FilterProductsProps) => {
  const handleFilterSelection = (event: SelectChangeEvent<string>) => {
    selectFilter(event.target.value);
  }
  return (
    <Select
      sx={selectStyle}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      MenuProps={{
        PaperProps: {
          sx: {
            width: "100%",
          },
        },
      }}
      renderValue={(selected) => selected}
      value={value}
      onChange={handleFilterSelection}
    >
      {["All Positions", "Open", "Closed"].map(option => (
        <MenuItem key={option} value={option} sx={{ fontSize: "14px", fontFamily: "Averta-Regular", display:'flex', justifyContent:'space-between',  minHeight: "32px", }}>
          <span>{option}</span>
          {option === value && <CheckIcon style={checkIconStyles} /> }
        </MenuItem>
      ))}
    </Select>
)};

export default FilterProducts;
