import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function MenuIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6673 7.5H3.33398V9.16667H16.6673V7.5ZM3.33398 12.5H16.6673V10.8333H3.33398V12.5Z" fill="#5A6874" />
      </svg>
    </SvgIcon>
  );
}

export function MoreIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.0007 13.3335C10.4427 13.3335 10.8666 13.5091 11.1792 13.8217C11.4917 14.1342 11.6673 14.5581 11.6673 15.0002C11.6673 15.4422 11.4917 15.8661 11.1792 16.1787C10.8666 16.4912 10.4427 16.6668 10.0007 16.6668C9.55862 16.6668 9.1347 16.4912 8.82214 16.1787C8.50958 15.8661 8.33398 15.4422 8.33398 15.0002C8.33398 14.5581 8.50958 14.1342 8.82214 13.8217C9.1347 13.5091 9.55862 13.3335 10.0007 13.3335ZM10.0007 8.3335C10.4427 8.3335 10.8666 8.50909 11.1792 8.82165C11.4917 9.13421 11.6673 9.55814 11.6673 10.0002C11.6673 10.4422 11.4917 10.8661 11.1792 11.1787C10.8666 11.4912 10.4427 11.6668 10.0007 11.6668C9.55862 11.6668 9.1347 11.4912 8.82214 11.1787C8.50958 10.8661 8.33398 10.4422 8.33398 10.0002C8.33398 9.55814 8.50958 9.13421 8.82214 8.82165C9.1347 8.50909 9.55862 8.3335 10.0007 8.3335ZM10.0007 3.3335C10.4427 3.3335 10.8666 3.50909 11.1792 3.82165C11.4917 4.13421 11.6673 4.55814 11.6673 5.00016C11.6673 5.44219 11.4917 5.86611 11.1792 6.17867C10.8666 6.49123 10.4427 6.66683 10.0007 6.66683C9.55862 6.66683 9.1347 6.49123 8.82214 6.17867C8.50958 5.86611 8.33398 5.44219 8.33398 5.00016C8.33398 4.55814 8.50958 4.13421 8.82214 3.82165C9.1347 3.50909 9.55862 3.3335 10.0007 3.3335Z"
          fill="#5A6874"
        />
      </svg>
    </SvgIcon>
  );
}

export function DoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3581 1.5C8.53618 3.3333 6.43975 5.43271 4.54526 7.33636L1.49698 4.73031L0 6.50607L3.86318 9.80304L4.67808 10.5L5.4326 9.73636C7.49109 7.66511 9.93778 5.21747 12 3.14243L10.3581 1.5Z"
          fill="#006EB6"
        />
      </svg>
    </SvgIcon>
  );
}

export function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="8.17" height="10.5" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.49935 10.0833C1.49935 10.725 2.02435 11.25 2.66602 11.25H7.33268C7.97435 11.25 8.49935 10.725 8.49935 10.0833V3.08333H1.49935V10.0833ZM9.08268 1.33333H7.04102L6.45768 0.75H3.54102L2.95768 1.33333H0.916016V2.5H9.08268V1.33333Z"
          fill="#006EB6"
        />
      </svg>
    </SvgIcon>
  );
}

export function ArrowDownIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="8" height="8">
      <svg fill="none" viewBox="0 0 9 8" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 4L7.795 3.295L5 6.085V0H4V6.085L1.21 3.29L0.5 4L4.5 8L8.5 4Z" fill="#D32F2F" />
      </svg>
    </SvgIcon>
  );
}

export function CircleChecked(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="16" fill="#E5F6FD" />
        <path
          d="M11.3333 16.9997L14.6667 20.333L22 12.9997"
          stroke="#006EB6"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

export function ArrowUpIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="8" height="8">
      <svg fill="#00695d" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" stroke="#00695d">
        <path d="M256,34,432,210l-21.2,21.21L271,91.4V478H241V91.4L101.16,231.25,80,210Z"></path>
      </svg>
    </SvgIcon>
  );
}

export function CancelRoundedGreen(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path
          d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H22C23.933 0.5 25.5 2.067 25.5 4V22C25.5 23.933 23.933 25.5 22 25.5H4C2.067 25.5 0.5 23.933 0.5 22V4Z"
          stroke="#2E7D32"
          stroke-opacity="0.5"
        />
        <path
          d="M18.25 8.8075L17.1925 7.75L13 11.9425L8.8075 7.75L7.75 8.8075L11.9425 13L7.75 17.1925L8.8075 18.25L13 14.0575L17.1925 18.25L18.25 17.1925L14.0575 13L18.25 8.8075Z"
          fill="#2E7D32"
        />
      </svg>
    </SvgIcon>
  );
}

export function CancelRoundedRed(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path
          d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H22C23.933 0.5 25.5 2.067 25.5 4V22C25.5 23.933 23.933 25.5 22 25.5H4C2.067 25.5 0.5 23.933 0.5 22V4Z"
          stroke="#D32F2F"
          stroke-opacity="0.5"
        />
        <path
          d="M18.25 8.8075L17.1925 7.75L13 11.9425L8.8075 7.75L7.75 8.8075L11.9425 13L7.75 17.1925L8.8075 18.25L13 14.0575L17.1925 18.25L18.25 17.1925L14.0575 13L18.25 8.8075Z"
          fill="#D32F2F"
        />
      </svg>
    </SvgIcon>
  );
}

export function LocationSearchingOutlined(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="8" height="8">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path
          d="M14.4601 7.33366C14.1534 4.55366 11.9467 2.34699 9.16675 2.04033V0.666992H7.83341V2.04033C5.05341 2.34699 2.84675 4.55366 2.54008 7.33366H1.16675V8.66699H2.54008C2.84675 11.447 5.05341 13.6537 7.83341 13.9603V15.3337H9.16675V13.9603C11.9467 13.6537 14.1534 11.447 14.4601 8.66699H15.8334V7.33366H14.4601ZM8.50008 12.667C5.92008 12.667 3.83341 10.5803 3.83341 8.00033C3.83341 5.42033 5.92008 3.33366 8.50008 3.33366C11.0801 3.33366 13.1667 5.42033 13.1667 8.00033C13.1667 10.5803 11.0801 12.667 8.50008 12.667Z"
          fill="#081D37"
        />
      </svg>
    </SvgIcon>
  );
}

export function TradeLogIcon({ htmlColor = "#081D37", ...props }: SvgIconProps & { htmlColor?: string }) {
  return (
    <SvgIcon {...props} htmlColor={htmlColor} width="8" height="8">
      <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.200012 1.7001C0.200012 0.816443 0.916356 0.100098 1.80001 0.100098H5.46864C5.89299 0.100098 6.29995 0.268669 6.60001 0.568727L9.33138 3.3001C9.63144 3.60016 9.80001 4.00712 9.80001 4.43147V11.3001C9.80001 12.1838 9.08367 12.9001 8.20001 12.9001H1.80001C0.916356 12.9001 0.200012 12.1838 0.200012 11.3001V1.7001Z"
          fill={htmlColor}
          fillOpacity="1"
        />
      </svg>
    </SvgIcon>
  );
}

export function EducationIcon({ htmlColor = "#081D37", ...props }: SvgIconProps & { htmlColor?: string }) {
  return (
    <SvgIcon {...props} htmlColor={htmlColor} width="8" height="8">
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.50713 7.28919L11.6706 4.89948C12.0968 4.7094 12.0989 3.79845 11.6706 3.60747L6.50712 1.21777C6.1763 1.07194 5.8245 1.07194 5.49369 1.21777L0.330186 3.60747C-0.0960108 3.79755 -0.0980701 4.7085 0.330186 4.89948L1.14458 5.27639C0.991477 5.6798 0.93481 6.10888 0.917081 6.61297C0.733262 6.76643 0.608844 7.06063 0.608844 7.39856C0.608844 7.73419 0.731596 8.02663 0.913337 8.1809L0.610117 11.6376C0.598323 11.7721 0.668226 11.8915 0.75861 11.8915H1.65721C1.74766 11.8915 1.81749 11.772 1.8057 11.6376L1.50248 8.18093C1.68422 8.02663 1.80697 7.73419 1.80697 7.39856C1.80697 7.07156 1.69049 6.78541 1.51643 6.62821C1.53327 6.19899 1.57922 5.87292 1.73954 5.55172L5.49371 7.28919C5.82452 7.43501 6.17632 7.43501 6.50713 7.28919ZM6.67991 8.14962L6.68378 8.14782L9.50901 6.84009L9.74457 9.19576C9.74457 10.1883 8.06826 10.9929 6.00042 10.9929C3.93258 10.9929 2.25627 10.1883 2.25627 9.19576L2.49185 6.84009L5.31707 8.14782L5.32095 8.14962L5.32483 8.15133C5.7659 8.34576 6.23496 8.34576 6.67603 8.15133L6.67991 8.14962Z"
          fill={htmlColor}
        />
        <mask
          id="mask0_5535_6764"
          maskUnits="userSpaceOnUse"
          x="0"
          y="1"
          width="12"
          height="11"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.50713 7.28919L11.6706 4.89948C12.0968 4.7094 12.0989 3.79845 11.6706 3.60747L6.50712 1.21777C6.1763 1.07194 5.8245 1.07194 5.49369 1.21777L0.330186 3.60747C-0.0960108 3.79755 -0.0980701 4.7085 0.330186 4.89948L1.14458 5.27639C0.991477 5.6798 0.93481 6.10888 0.917081 6.61297C0.733262 6.76643 0.608844 7.06063 0.608844 7.39856C0.608844 7.73419 0.731596 8.02663 0.913337 8.1809L0.610117 11.6376C0.598323 11.7721 0.668226 11.8915 0.75861 11.8915H1.65721C1.74766 11.8915 1.81749 11.772 1.8057 11.6376L1.50248 8.18093C1.68422 8.02663 1.80697 7.73419 1.80697 7.39856C1.80697 7.07156 1.69049 6.78541 1.51643 6.62821C1.53327 6.19899 1.57922 5.87292 1.73954 5.55172L5.49371 7.28919C5.82452 7.43501 6.17632 7.43501 6.50713 7.28919ZM6.67991 8.14962L6.68378 8.14782L9.50901 6.84009L9.74457 9.19576C9.74457 10.1883 8.06826 10.9929 6.00042 10.9929C3.93258 10.9929 2.25627 10.1883 2.25627 9.19576L2.49185 6.84009L5.31707 8.14782L5.32095 8.14962L5.32483 8.15133C5.7659 8.34576 6.23496 8.34576 6.67603 8.15133L6.67991 8.14962Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_5535_6764)"></g>
      </svg>
    </SvgIcon>
  );
}
