import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Averta-Regular, sans-serif',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '17.16px',
  letterSpacing: '0.17px',
  textAlign: 'left',
  color: "#081D37DE"
}));

export const typographyStyles = {
  fontFamily: 'Averta-Regular',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '16px',
  letterSpacing: '1.5px',
  textAlign: 'left',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
  color: 'var(--primary-main-blue, #006EB6)',
};

export const headingTypographyStyles = {
  fontFamily: 'Averta-Regular', 
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24.5px',
  textAlign: 'left',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
  color: 'var(--text-primary, #081D37)',
};
