import { InstrumentMarketData, ProductWithContracts, Widget, WidgetType } from "../../../../types";
import InstrumentModal from "./marketModal/MarketMobileModal";
import { useEffect, useState } from "react";
import "./simpleMobileWidget.css";
import { subscribeToContracts, unsubscribeContracts } from "../../../../utils/subscriptions";
import { Contract } from "../types";
import TableView from "./TableView";
import useSortTable from "./useSortTable";
import { Instrument } from "../../../../cqg-api/models/Instrument";
import TradeTicketMobile from "../../../TradeTicketDrawer/TradeTicketMobile";
import { Box, Modal } from "@mui/material";
import { mobileTradeTicketStyles } from "./mobileTradeTicketStyles";
interface SimpleMobileWidgetProps {
  rows: InstrumentMarketData[];
  sortModelValue: any;
  productsAsPerChoosenAsset: any;
}

const SimpleMobileWidget = ({ rows, sortModelValue, productsAsPerChoosenAsset }: SimpleMobileWidgetProps) => {
  const [selectedInstrument, setSelectedInstrument] = useState<
    (InstrumentMarketData & { monthlyContracts?: Instrument }) | null
  >(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [viewTradeTicket, setViewTradeTicket] = useState(false);

  const handleOpenModal = (instrument: InstrumentMarketData & { monthlyContracts?: Instrument }) => {
    const contractIds = Array.isArray(instrument?.monthlyContracts)
      ? instrument?.monthlyContracts.map((contract: Contract) => contract.contractId)
      : [];

    subscribeToContracts(contractIds);
    setSelectedInstrument(instrument);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const contracts = productsAsPerChoosenAsset
      .map((product: ProductWithContracts) => product.monthlyContracts?.[0])
      .flat()
      .filter((instrument: Instrument) => !!instrument)
      .map((instrument: any) => instrument.contractId);

    subscribeToContracts(contracts);
    return () => {
      unsubscribeContracts(contracts);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAsPerChoosenAsset]);

  const { gridRows } = useSortTable(rows, sortModelValue);

  const openTradeWindow = (contract: Contract) => {
    setViewTradeTicket(true);
  };
  const closeTrade = () => setViewTradeTicket(false);
  return (
    <>
      <hr style={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />
      <TableView rows={gridRows} handleOpenModal={handleOpenModal} />
      {selectedInstrument && (
        <InstrumentModal
          open={modalOpen}
          onClose={handleCloseModal}
          openTradeWindow={openTradeWindow}
          instrument={selectedInstrument}
          contract={Array.isArray(selectedInstrument?.monthlyContracts) ? selectedInstrument?.monthlyContracts : []}
        />
      )}
      {viewTradeTicket && (
        <Modal
          open={viewTradeTicket}
          onClose={closeTrade}
        >
          <Box sx={mobileTradeTicketStyles}>
            <TradeTicketMobile
              workspaceId={0}
              externalData={undefined}
              instrument={selectedInstrument?.monthlyContract}
              closeTrade={closeTrade}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default SimpleMobileWidget;
