import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import './confirmTradeDialog.css';

type ConfirmTradeDialogProps = {
  open: boolean;
  onClose: () => void;
  cancel: () => void;
  confirm: () => void;
};

const ConfirmTradeDialog = ({ open, onClose, cancel, confirm }: ConfirmTradeDialogProps) => {
  return (

    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '444px',
          padding: '24px',
          borderRadius: '8px',
        }
      }}
    >
    <DialogTitle>Confirm Order</DialogTitle>
    <DialogContent className='DialogContent'>
      <table className='table'>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Contract Size:</td>
            <td>50</td>
          </tr>
          <tr>
            <td>Tick Size:</td>
            <td>0.25</td>
          </tr>
          <tr>
            <td>Last Price:</td>
            <td>$5755.00</td>
          </tr>
          <tr>
            <td>National Value or Order</td>
            <td>$863,250.00</td>
          </tr>
          <tr>
            <td>Margin Comitted to Trade</td>
            <td>$45,540.00</td>
          </tr>
        </tbody>
      </table>
      <div className='divider'></div>
      <table className='table'>
        <tbody>
          <tr>
            <td>Side</td>
            <td>Buy</td>
          </tr>
          <tr>
            <td>Quantity</td>
            <td>1</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>Limit</td>
          </tr>
          <tr>
            <td>Limit Price</td>
            <td>$5755.00</td>
          </tr>
          <tr>
            <td>TIF</td>
            <td>Day</td>
          </tr>
        </tbody>
      </table>
    </DialogContent>
    <DialogActions>
      <Button onClick={cancel}>Cancel</Button>
      <Button onClick={confirm}>Confirm Order</Button>
    </DialogActions>
  </Dialog>
  )
};

export default ConfirmTradeDialog;
