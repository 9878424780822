// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-tree-view-contract-select-container {
  padding: 7px 10px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}
.mobile-tree-view-modal-content {
  height: 97vh;
  padding-top: 3vh;
}
.mobile-tree-view-buttons {
  display: flex;
  padding: 24px;
}
.mobile-tree-view-button {
  background: none;
  font-weight: 600;
  border: none;
  font-size: 14px;
  letter-spacing: 1.5px;
  font-family: "Averta-Regular";
  margin-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/tree-view/mobile/MobileTreeViewModal.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,qBAAqB;EACrB,6BAA6B;EAC7B,iBAAiB;AACnB","sourcesContent":[".mobile-tree-view-contract-select-container {\n  padding: 7px 10px;\n  border: 1px solid #d1d1d1;\n  border-radius: 4px;\n}\n.mobile-tree-view-modal-content {\n  height: 97vh;\n  padding-top: 3vh;\n}\n.mobile-tree-view-buttons {\n  display: flex;\n  padding: 24px;\n}\n.mobile-tree-view-button {\n  background: none;\n  font-weight: 600;\n  border: none;\n  font-size: 14px;\n  letter-spacing: 1.5px;\n  font-family: \"Averta-Regular\";\n  margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
