import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import QuantityButton from "./QuantityButton";
import { inputStyles } from "./PriceLadderStyles";

interface QuantityProps {
  name: string;
  control: any;
}

const Quantity = ({ name, control }: QuantityProps) => {
  const isValueValid = (value: number) => value >= 1 && value <= 10; // Validates range

  const adjustQuantity = (currentValue: number, increment: boolean, onChange: any) => {
    const newValue = increment ? currentValue + 1 : currentValue - 1;
    if (isValueValid(newValue)) onChange(newValue);
  };

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, onChange: any) => {
    const newValue = e.target.value;

    // Allow empty value to clear the input
    if (newValue === "") {
      onChange("");
    } else if (/^\d+$/.test(newValue)) {
      const numericValue = parseInt(newValue);
      if (isValueValid(numericValue)) {
        onChange(numericValue);
      }
    }
  };

  return (
    <div className="control">
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            <QuantityButton onClick={() => adjustQuantity(value, false, onChange)} increment={false} />
            <TextField
              value={value || ""} // Ensure value is a string, allowing empty input
              onChange={(e) => handleInputChange(e, onChange)} // Using the extracted function here
              fullWidth
              variant="outlined"
              sx={inputStyles}
            />
            <QuantityButton onClick={() => adjustQuantity(value, true, onChange)} increment={true} />
          </>
        )}
      />
    </div>
  );
};

export default Quantity;
