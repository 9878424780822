import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import "../positions.css";
import Divider from "@mui/material/Divider";
import "./flattenModal.css";
import ModalHeader from "./ModalHeader";
import ModalTable from "./ModalTable";
import ModalFooter from "./ModalFooter";

type FlattenPositionProps = {
  open: boolean;
  onClose: () => void;
  handleConfirmFlatten: () => void;
  data: any;
  openTradeTicket: () => void;
};
export default function FlattenPosition({ open, onClose, data, handleConfirmFlatten, openTradeTicket }: FlattenPositionProps) {
  if (!data) return null;
  const toggleDrawer = () => () => {
    onClose();
  };
  const isOption = data.strike !== 0;
  const list = () => (
    <Box role="presentation" onKeyDown={toggleDrawer()}>
      <ModalHeader data={data} toggleDrawer={toggleDrawer} />
      <ModalTable data={data} isOption={isOption} />
      <Divider />
      <ModalFooter openTradeTicket={openTradeTicket} data={data} handleConfirmFlatten={handleConfirmFlatten} />
    </Box>
  );

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      {list()}
    </Drawer>
  );
}
