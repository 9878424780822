export var chartConfig = (cqgUDFDataFeed, symbol, containerId) =>  {
   return {
        disabled_features: ["link_to_tradingview", "header_screenshot", "study_templates","order_panel", "header_symbol_search", "symbol_search_hot_key"],
        // enabled_features: ['dome_widget'],
        width: '100%',
        height: '100%',
        symbol: symbol || 'AA',
        interval: 1,
        toolbar_bg: '#f4f7f9',
        container_id:  "TVChartContainer" + containerId,
        datafeed: cqgUDFDataFeed,
        library_path: '/charting_library/',
        locale: 'en',
        timezone: 'America/Chicago',
        custom_css_url: '/charting_library/static/css/custom.css',
        time_frames: [
        { text: "5y", resolution: "1825" },
        { text: "1y", resolution: "365" },
        { text: "6m", resolution: "180" },
        { text: "3m", resolution: "90" },
        { text: "1m", resolution: "30" },
        { text: "5d", resolution: "5" },
        { text: "1d", resolution: "1" },
        ],
        overrides: {
        'symbolWatermarkProperties.transparency': 100
        },
        logo: {
            image: 'images/CMEGroup_2c_RGB-01.svg',
            link: 'https://www.cmegroup.com'
        },
    }
}

export const chartLayout = (symbolSize) => {
    let layout = 's'
    if (symbolSize === 7) {
        return layout;
    }
    switch (symbolSize) {
        case 2:
        case 3:
          layout = `${symbolSize}h`
          break;
        case 4:
        case 6:
        case 8:
          layout = symbolSize.toString();
          break;
        case 5:
          layout = `${symbolSize}s`;
          break;
        default:
          break;
    }

    return layout;
}

export const generateMultiChartLayout = (tvWidget, layoutSize, symbols) => {
    setTimeout(() => {
        if(layoutSize > 1) {
          for(let i = 1; i <= layoutSize -1; i++) {
            tvWidget.chart(i).setSymbol(symbols[i]);
          }
        }
      })
}