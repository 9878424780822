import { Newspaper, SwapHoriz, ViewColumn, Watch } from "@mui/icons-material";
import { Widget, WidgetType } from "../../types";

export const widgets: Widget[] = [
  {
    key: 1,
    title: WidgetType.AvailableProducts,
    widgetType: WidgetType.AvailableProducts,
    icon: '/images/icons/markets.svg',
    
  },
  {
    key: 2,
    title: WidgetType.Watchlist,
    widgetType: WidgetType.Watchlist,
    icon: Watch,
  },
  {
    key: 3,
    title: WidgetType.Chart,
    widgetType: WidgetType.Chart,
    icon: '/images/icons/Chart.svg',
  },
  {
    key: 4,
    title: WidgetType.PriceLadder,
    widgetType: WidgetType.PriceLadder,
    icon: ViewColumn,
  },
  {
    key: 10,
    title: WidgetType.Options,
    widgetType: WidgetType.Options,
    icon: '/images/icons/Options.svg',
  },
  {
    key: 5,
    title: WidgetType.Positions,
    widgetType: WidgetType.Positions,
    icon: '/images/icons/Icon.svg',
  },
  {
    key: 6,
    title: WidgetType.Orders,
    widgetType: WidgetType.Orders,
    icon: SwapHoriz,
  },
  {
    key: 7,
    title: WidgetType.Performance,
    widgetType: WidgetType.Performance,
    icon: "/images/icons/LeftContent.svg",
  },
  {
    key: 8,
    title: WidgetType.News,
    widgetType: WidgetType.News,
    icon: Newspaper,
  },
  {
    key: 9,
    title: WidgetType.EconodayCalendar,
    widgetType: WidgetType.EconodayCalendar,
    icon: "/images/icons/DatePickerIcon.svg",
  },
];
