// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: traderouting_1.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Decimal } from "./common/decimal";
import { Text } from "./common/shared_1";
import { LookupPropertyListRequest, LookupPropertyListResult, SearchOption, Tuple } from "./common_1";
import { BatchOrdersOperation, BatchOrdersOperationResult } from "./order_1";
import Long from "long";

export const protobufPackage = "traderouting_1";

/** Types of trade routing related lookup properties. */
export enum TradeRoutingLookupPropertyType {
  /** ACCOUNT_TYPE - Type of the account (e.g. 'Customer Account', 'House Account', etc.) */
  ACCOUNT_TYPE = 101,
  /** MODES_ACCOUNT_GROUP - MODES Account groups type. */
  MODES_ACCOUNT_GROUP = 102,
  /** TIME_ZONE - Time zones. */
  TIME_ZONE = 103,
  /** RISK_SERVER_INSTANCE - Risk server instances. */
  RISK_SERVER_INSTANCE = 104,
  /** TRADE_LOCATION - Trade locations. */
  TRADE_LOCATION = 105,
  /** ORDER_CLASS - Order classes. */
  ORDER_CLASS = 106,
  /** ACCOUNT_ORIGIN - Account origin ('Customer', 'firm', etc. ) */
  ACCOUNT_ORIGIN = 107,
  /** EXECUTION_SOURCE_CODE - Execution source codes that can be used for Account-Login link. */
  EXECUTION_SOURCE_CODE = 108,
  /** CQG_MIFID_ALGORITHM - CQG MiFID Algorithms. */
  CQG_MIFID_ALGORITHM = 109,
  /** FLEXIBLE_IDENTIFIER - MiFID */
  FLEXIBLE_IDENTIFIER = 110,
  UNRECOGNIZED = -1,
}

export function tradeRoutingLookupPropertyTypeFromJSON(object: any): TradeRoutingLookupPropertyType {
  switch (object) {
    case 101:
    case "ACCOUNT_TYPE":
      return TradeRoutingLookupPropertyType.ACCOUNT_TYPE;
    case 102:
    case "MODES_ACCOUNT_GROUP":
      return TradeRoutingLookupPropertyType.MODES_ACCOUNT_GROUP;
    case 103:
    case "TIME_ZONE":
      return TradeRoutingLookupPropertyType.TIME_ZONE;
    case 104:
    case "RISK_SERVER_INSTANCE":
      return TradeRoutingLookupPropertyType.RISK_SERVER_INSTANCE;
    case 105:
    case "TRADE_LOCATION":
      return TradeRoutingLookupPropertyType.TRADE_LOCATION;
    case 106:
    case "ORDER_CLASS":
      return TradeRoutingLookupPropertyType.ORDER_CLASS;
    case 107:
    case "ACCOUNT_ORIGIN":
      return TradeRoutingLookupPropertyType.ACCOUNT_ORIGIN;
    case 108:
    case "EXECUTION_SOURCE_CODE":
      return TradeRoutingLookupPropertyType.EXECUTION_SOURCE_CODE;
    case 109:
    case "CQG_MIFID_ALGORITHM":
      return TradeRoutingLookupPropertyType.CQG_MIFID_ALGORITHM;
    case 110:
    case "FLEXIBLE_IDENTIFIER":
      return TradeRoutingLookupPropertyType.FLEXIBLE_IDENTIFIER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TradeRoutingLookupPropertyType.UNRECOGNIZED;
  }
}

export function tradeRoutingLookupPropertyTypeToJSON(object: TradeRoutingLookupPropertyType): string {
  switch (object) {
    case TradeRoutingLookupPropertyType.ACCOUNT_TYPE:
      return "ACCOUNT_TYPE";
    case TradeRoutingLookupPropertyType.MODES_ACCOUNT_GROUP:
      return "MODES_ACCOUNT_GROUP";
    case TradeRoutingLookupPropertyType.TIME_ZONE:
      return "TIME_ZONE";
    case TradeRoutingLookupPropertyType.RISK_SERVER_INSTANCE:
      return "RISK_SERVER_INSTANCE";
    case TradeRoutingLookupPropertyType.TRADE_LOCATION:
      return "TRADE_LOCATION";
    case TradeRoutingLookupPropertyType.ORDER_CLASS:
      return "ORDER_CLASS";
    case TradeRoutingLookupPropertyType.ACCOUNT_ORIGIN:
      return "ACCOUNT_ORIGIN";
    case TradeRoutingLookupPropertyType.EXECUTION_SOURCE_CODE:
      return "EXECUTION_SOURCE_CODE";
    case TradeRoutingLookupPropertyType.CQG_MIFID_ALGORITHM:
      return "CQG_MIFID_ALGORITHM";
    case TradeRoutingLookupPropertyType.FLEXIBLE_IDENTIFIER:
      return "FLEXIBLE_IDENTIFIER";
    case TradeRoutingLookupPropertyType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Price limit mode enumeration. */
export enum PriceLimitMode {
  /** AGGRESSIVE_LMT - Trade price limit check is enforced only for orders subject to immediate execution. Check LMT only. */
  AGGRESSIVE_LMT = 1,
  /** AGGRESSIVE_LMT_STP - Trade price limit check is enforced only for orders subject to immediate execution. Check LMT and STP. */
  AGGRESSIVE_LMT_STP = 2,
  /** ALL_LMT - Trade price limit check is enforced for all orders. Check LMT only. */
  ALL_LMT = 3,
  /** ALL_LMT_STP - Trade price limit check is enforced for all orders. Check LMT and STP. */
  ALL_LMT_STP = 4,
  UNRECOGNIZED = -1,
}

export function priceLimitModeFromJSON(object: any): PriceLimitMode {
  switch (object) {
    case 1:
    case "AGGRESSIVE_LMT":
      return PriceLimitMode.AGGRESSIVE_LMT;
    case 2:
    case "AGGRESSIVE_LMT_STP":
      return PriceLimitMode.AGGRESSIVE_LMT_STP;
    case 3:
    case "ALL_LMT":
      return PriceLimitMode.ALL_LMT;
    case 4:
    case "ALL_LMT_STP":
      return PriceLimitMode.ALL_LMT_STP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PriceLimitMode.UNRECOGNIZED;
  }
}

export function priceLimitModeToJSON(object: PriceLimitMode): string {
  switch (object) {
    case PriceLimitMode.AGGRESSIVE_LMT:
      return "AGGRESSIVE_LMT";
    case PriceLimitMode.AGGRESSIVE_LMT_STP:
      return "AGGRESSIVE_LMT_STP";
    case PriceLimitMode.ALL_LMT:
      return "ALL_LMT";
    case PriceLimitMode.ALL_LMT_STP:
      return "ALL_LMT_STP";
    case PriceLimitMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Limit mode. */
export enum LimitMode {
  /** DEFAULT - Limit is inherited from a parent entity. */
  DEFAULT = 1,
  /** UNLIMITED - Limit is not enforced. */
  UNLIMITED = 2,
  /** LIMITED - Limit is enforced. */
  LIMITED = 3,
  UNRECOGNIZED = -1,
}

export function limitModeFromJSON(object: any): LimitMode {
  switch (object) {
    case 1:
    case "DEFAULT":
      return LimitMode.DEFAULT;
    case 2:
    case "UNLIMITED":
      return LimitMode.UNLIMITED;
    case 3:
    case "LIMITED":
      return LimitMode.LIMITED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LimitMode.UNRECOGNIZED;
  }
}

export function limitModeToJSON(object: LimitMode): string {
  switch (object) {
    case LimitMode.DEFAULT:
      return "DEFAULT";
    case LimitMode.UNLIMITED:
      return "UNLIMITED";
    case LimitMode.LIMITED:
      return "LIMITED";
    case LimitMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ServiceGroupAuthorizationLevel {
  /** NONE - No additional authorization is required. Product enablement enables linked Service Groups. */
  NONE = 0,
  /**
   * ONE_REQUIRED - Only one Service Group for the exchange is possible to enable at the same time.
   * If nothing is enabled then there is no authorization to see/trade linked products.
   */
  ONE_REQUIRED = 1,
  /**
   * ANY_REQUIRED - One or more Service Group can be enabled at the same time.
   * If nothing is enabled then there is no authorization to see/trade linked products.
   */
  ANY_REQUIRED = 2,
  UNRECOGNIZED = -1,
}

export function serviceGroupAuthorizationLevelFromJSON(object: any): ServiceGroupAuthorizationLevel {
  switch (object) {
    case 0:
    case "NONE":
      return ServiceGroupAuthorizationLevel.NONE;
    case 1:
    case "ONE_REQUIRED":
      return ServiceGroupAuthorizationLevel.ONE_REQUIRED;
    case 2:
    case "ANY_REQUIRED":
      return ServiceGroupAuthorizationLevel.ANY_REQUIRED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ServiceGroupAuthorizationLevel.UNRECOGNIZED;
  }
}

export function serviceGroupAuthorizationLevelToJSON(object: ServiceGroupAuthorizationLevel): string {
  switch (object) {
    case ServiceGroupAuthorizationLevel.NONE:
      return "NONE";
    case ServiceGroupAuthorizationLevel.ONE_REQUIRED:
      return "ONE_REQUIRED";
    case ServiceGroupAuthorizationLevel.ANY_REQUIRED:
      return "ANY_REQUIRED";
    case ServiceGroupAuthorizationLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** List of possible extended sides. */
export enum ExtendedSide {
  EXTENDED_SIDE_BUY = 1,
  EXTENDED_SIDE_SELL = 2,
  EXTENDED_SIDE_SELL_SHORT = 5,
  EXTENDED_SIDE_SELL_SHORT_EXEMPT = 6,
  UNRECOGNIZED = -1,
}

export function extendedSideFromJSON(object: any): ExtendedSide {
  switch (object) {
    case 1:
    case "EXTENDED_SIDE_BUY":
      return ExtendedSide.EXTENDED_SIDE_BUY;
    case 2:
    case "EXTENDED_SIDE_SELL":
      return ExtendedSide.EXTENDED_SIDE_SELL;
    case 5:
    case "EXTENDED_SIDE_SELL_SHORT":
      return ExtendedSide.EXTENDED_SIDE_SELL_SHORT;
    case 6:
    case "EXTENDED_SIDE_SELL_SHORT_EXEMPT":
      return ExtendedSide.EXTENDED_SIDE_SELL_SHORT_EXEMPT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ExtendedSide.UNRECOGNIZED;
  }
}

export function extendedSideToJSON(object: ExtendedSide): string {
  switch (object) {
    case ExtendedSide.EXTENDED_SIDE_BUY:
      return "EXTENDED_SIDE_BUY";
    case ExtendedSide.EXTENDED_SIDE_SELL:
      return "EXTENDED_SIDE_SELL";
    case ExtendedSide.EXTENDED_SIDE_SELL_SHORT:
      return "EXTENDED_SIDE_SELL_SHORT";
    case ExtendedSide.EXTENDED_SIDE_SELL_SHORT_EXEMPT:
      return "EXTENDED_SIDE_SELL_SHORT_EXEMPT";
    case ExtendedSide.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Request related to trade routing.
 * Only one optional field is expected.
 */
export interface TradeRoutingRequest {
  /** ID of a request. */
  id: number;
  /** Request related to trade routing accounts. */
  accountScopeRequest?:
    | AccountScopeRequest
    | undefined;
  /** Request related to trade routing orders. */
  orderScopeRequest?:
    | OrderScopeRequest
    | undefined;
  /**
   * Get a list of related to trade routing lookup properties.
   * Lookup property types are defined in TradeRoutingLookupPropertyType enum.
   */
  lookupPropertyListRequest?: LookupPropertyListRequest | undefined;
}

/**
 * Request related to trade routing accounts.
 * Only one optional field is expected.
 */
export interface AccountScopeRequest {
  /** Get information about an account. */
  accountInfoRequest?:
    | AccountInfoRequest
    | undefined;
  /** Get balance records for an account. */
  balanceRecordsRequest?:
    | BalanceRecordsRequest
    | undefined;
  /** Create new balance record. */
  createBalanceRecord?:
    | CreateBalanceRecord
    | undefined;
  /** Update existing balance record. */
  updateBalanceRecord?:
    | UpdateBalanceRecord
    | undefined;
  /** Get account risk parameters. */
  accountRiskParametersRequest?:
    | AccountRiskParametersRequest
    | undefined;
  /**
   * Update account's risk parameters.
   * [obsolete] Use UpdateAccountRiskParameters instead.
   *
   * @deprecated
   */
  obsoleteUpdateRiskParameters?:
    | UpdateRiskParameters
    | undefined;
  /** Search account information. */
  accountSearchRequest?:
    | AccountSearchRequest
    | undefined;
  /** Get enabled account's routes. */
  accountRouteListRequest?:
    | AccountRouteListRequest
    | undefined;
  /** Get account to user authorization list. */
  accountUserAuthorizationListRequest?:
    | AccountUserAuthorizationListRequest
    | undefined;
  /** Get account settings. */
  accountSettingsRequest?:
    | AccountSettingsRequest
    | undefined;
  /**
   * Get list of exchange groups and commodities that are potentially available for the account.
   * List of enabled exchange groups and commodities along with other settings is requested by account_market_limits_request.
   */
  accountAvailableExchangeGroupsRequest?:
    | AccountAvailableExchangeGroupsRequest
    | undefined;
  /**
   * Get account market limits.
   * Account risk parameters with limits enforcement settings is requested by account_risk_parameters_request.
   */
  accountMarketLimitsRequest?:
    | AccountMarketLimitsRequest
    | undefined;
  /**
   * Gets account positions data without trades matching.
   * It includes 'start of day' positions (statement already run).
   * And current day fills (orders filled during current trading period and statement hasn't been run yet).
   * Currency fills are included in current day fills.
   */
  accountPositionsRequest?:
    | AccountPositionsRequest
    | undefined;
  /** Get account equity based on most recent statement data. */
  accountEquityRequest?:
    | AccountEquityRequest
    | undefined;
  /** Get current account collateral (margin details). */
  accountCollateralRequest?:
    | AccountCollateralRequest
    | undefined;
  /** Create new account and clone configuration from the existing one. */
  cloneAccount?:
    | CloneAccount
    | undefined;
  /** Update general information of account. */
  updateAccount?:
    | UpdateAccount
    | undefined;
  /** Update specific account settings. */
  updateAccountSettings?:
    | UpdateAccountSettings
    | undefined;
  /** Get account group information. */
  accountGroupRequest?:
    | AccountGroupRequest
    | undefined;
  /** Update account risk parameters. */
  updateAccountRiskParameters?:
    | UpdateAccountRiskParameters
    | undefined;
  /** Request of all routes available for the account. */
  accountAvailableRouteListRequest?:
    | AccountAvailableRouteListRequest
    | undefined;
  /** Update account routes list. */
  updateAccountRouteList?:
    | UpdateAccountRouteList
    | undefined;
  /** Update account market limits. */
  updateAccountMarketLimits?:
    | UpdateAccountMarketLimits
    | undefined;
  /** Create account. */
  createAccount?:
    | CreateAccount
    | undefined;
  /**
   * [obsolete] This operation is no longer supported.
   *
   * @deprecated
   */
  obsoleteAccountClusterRequest?:
    | AccountClusterRequest
    | undefined;
  /**
   * [obsolete] This operation is no longer supported.
   *
   * @deprecated
   */
  obsoleteCreateAccountCluster?:
    | CreateAccountCluster
    | undefined;
  /**
   * [obsolete] This operation is no longer supported.
   *
   * @deprecated
   */
  obsoleteUpdateAccountCluster?:
    | UpdateAccountCluster
    | undefined;
  /**
   * [obsolete] This operation is no longer supported.
   *
   * @deprecated
   */
  obsoleteUpdateAccountClusterPriceOffsets?:
    | UpdateAccountClusterPriceOffsets
    | undefined;
  /** Restore account. */
  restoreAccount?:
    | RestoreAccount
    | undefined;
  /** Account type overrides per exchange list. */
  accountTypeOverrideListRequest?:
    | AccountTypeOverrideListRequest
    | undefined;
  /** Update account type overrides list. */
  updateAccountTypeOverrideList?:
    | UpdateAccountTypeOverrideList
    | undefined;
  /** Update account to user authorization list. */
  updateAccountUserAuthorizationList?:
    | UpdateAccountUserAuthorizationList
    | undefined;
  /** Remove account. */
  removeAccount?:
    | RemoveAccount
    | undefined;
  /** Gets available to account list of Service Groups according to enabled routes. */
  accountAvailableServiceGroupListRequest?:
    | AccountAvailableServiceGroupListRequest
    | undefined;
  /** Gets account's enabled Service Groups. */
  accountServiceGroupListRequest?:
    | AccountServiceGroupListRequest
    | undefined;
  /** Updates list of account's enabled Service Groups. */
  updateAccountServiceGroupList?:
    | UpdateAccountServiceGroupList
    | undefined;
  /** Gets billable exchanges available to the current admin login. */
  billableExchangeListRequest?:
    | BillableExchangeListRequest
    | undefined;
  /** Creates new account group with master and sub accounts. */
  createAccountGroup?:
    | CreateAccountGroup
    | undefined;
  /** Updates existing account group. */
  updateAccountGroup?:
    | UpdateAccountGroup
    | undefined;
  /** Removes existing account group. */
  removeAccountGroup?:
    | RemoveAccountGroup
    | undefined;
  /**
   * Deletes specific positions data or all positions data on specific account.
   * It only deletes data of statement positions, does not do real positions liquidation on exchange.
   */
  deleteAccountPositions?:
    | DeleteAccountPositions
    | undefined;
  /** Requests trading features for the account. */
  accountTradingFeaturesRequest?:
    | AccountTradingFeaturesRequest
    | undefined;
  /** Updates account's trading features. */
  updateAccountTradingFeatures?: UpdateAccountTradingFeatures | undefined;
}

/**
 * Request related to trade routing orders.
 * Only one optional field is expected.
 */
export interface OrderScopeRequest {
  /** Get order details. */
  orderDetailsRequest?:
    | OrderDetailsRequest
    | undefined;
  /** Add fill to the existing order. */
  addFill?:
    | AddFill
    | undefined;
  /** Create new external order. */
  createExternalOrder?:
    | CreateExternalOrder
    | undefined;
  /** Search order requests information. */
  orderSearchRequest?:
    | OrderSearchRequest
    | undefined;
  /** Requests tree with related order chains for synthetic or synthetic strategy orders. */
  relatedOrderTreeRequest?:
    | RelatedOrderTreeRequest
    | undefined;
  /** Compound orders tree. */
  compoundOrderTreeRequest?:
    | CompoundOrderTreeRequest
    | undefined;
  /** Order strategy tree, describing synthetic strategy. */
  syntheticStrategyTreeRequest?:
    | SyntheticStrategyTreeRequest
    | undefined;
  /** Cancel order operation. */
  cancelOrder?:
    | CancelOrder
    | undefined;
  /** Reflect order as canceled without sending real cancel request to exchange. */
  reflectAsCanceledOrder?:
    | ReflectAsCanceledOrder
    | undefined;
  /** Cancel whole tree of compound orders. */
  cancelCompoundOrderTree?: CancelCompoundOrderTree | undefined;
  confirmOrderSentToExchange?: ConfirmOrderSentToExchange | undefined;
  activateOrder?: ActivateOrder | undefined;
  correctFill?: CorrectFill | undefined;
  cancelFill?:
    | CancelFill
    | undefined;
  /** Exclude order from (or include order to) risk calculation. */
  ignoreOrder?:
    | IgnoreOrder
    | undefined;
  /** Cancels all orders satisfying the given criteria. */
  batchOrdersOperation?: BatchOrdersOperation | undefined;
}

/**
 * Order details request message.
 * Get information about an order chain.
 * By default result contains at most DEFAULT_RESULT_RECORDS_NUMBER records.
 * Set parameter order_requests_top to a larger number to receive more.
 */
export interface OrderDetailsRequest {
  /**
   * ID of the account that was used to place the order.
   * Has priority over trade_location_id.
   */
  accountId?:
    | number
    | undefined;
  /** [required] Server order identifier (one of order ID within order chain). */
  orderId?:
    | string
    | undefined;
  /** Trade location identifier. */
  tradeLocationId?:
    | string
    | undefined;
  /** Optionally restrict order requests in the chain by returning 'top' ones of the last submitted. */
  orderRequestsTop?: number | undefined;
}

/**
 * Add fill message.
 * Fills may only be added to single leg (outright) orders.
 * Server automatically creates an order and matching leg fill.
 * Either account_id or trade_location_id is required.
 * Either price or display_price is required.
 */
export interface AddFill {
  /**
   * Identifier of a trade routing account that is used to add a fill.
   * Has priority over trade_location_id.
   */
  accountId?:
    | number
    | undefined;
  /** [required] Order identifier assigned by server after last modification. */
  orderId?:
    | string
    | undefined;
  /**
   * [obsolete] Use quantity field instead.
   *
   * @deprecated
   */
  obsoleteQuantity?:
    | number
    | undefined;
  /**
   * Price at which the lots were bought or sold.
   * Correct price format.
   * Has priority over display_price.
   */
  price?:
    | number
    | undefined;
  /** UTC time of the fill (offset in milliseconds from 01.01.1970). */
  fillUtcTime?:
    | number
    | undefined;
  /** Flag that trade was aggressive (Fixed Income specific order modifier; true by default). */
  isAggressive?:
    | boolean
    | undefined;
  /**
   * Price of the single fill to apply to the order.
   * Display price format for the order's contract.
   */
  displayPrice?:
    | number
    | undefined;
  /**
   * Order fill open/close type.
   * This field is associated with OrderChain.OpenCloseType enum type.
   */
  openCloseType?:
    | number
    | undefined;
  /**
   * Order fill speculation type.
   * This field is associated with OrderChain.SpeculationType enum type.
   */
  speculationType?:
    | number
    | undefined;
  /** Trade location. */
  tradeLocationId?:
    | string
    | undefined;
  /** [required] Quantity of bought or sold lots depending on order side. */
  quantity?:
    | Decimal
    | undefined;
  /** Flag for wait replication from local to central. */
  sync?: boolean | undefined;
}

/**
 * Create external order and fill message.
 * Only single leg (outright) orders are supported.
 * Server automatically creates an order and matching leg fill.
 * Either fill_price or display_fill_price is required.
 * Either limit_price or display_limit_price is required for limit and stop-limit orders.
 * Either stop_price or display_stop_price is required for stop and stop-limit orders.
 */
export interface CreateExternalOrder {
  /** [required] Account ID. */
  accountId?:
    | number
    | undefined;
  /** [required] User ID. */
  userId?:
    | string
    | undefined;
  /** [required] Full contract symbol. */
  contractSymbol?:
    | string
    | undefined;
  /**
   * Order side.
   * [required] This field is associated with OrderChain.Side enum type.
   */
  side?:
    | number
    | undefined;
  /**
   * Order type.
   * [required] This field is associated with OrderChain.OrderType enum type.
   */
  orderType?:
    | number
    | undefined;
  /**
   * Limit price of the order.
   * Correct price format.
   * Has priority over display_limit_price.
   */
  limitPrice?:
    | number
    | undefined;
  /**
   * Stop price of the order.
   * Correct price format.
   * Has priority over display_stop_price.
   */
  stopPrice?:
    | number
    | undefined;
  /**
   * [obsolete] Use fill_quantity field instead.
   *
   * @deprecated
   */
  obsoleteFillQuantity?:
    | number
    | undefined;
  /**
   * Price of the single fill to apply to the order.
   * CQG correct price format for the order's contract.
   * Has priority over display_fill_price.
   */
  fillPrice?:
    | number
    | undefined;
  /** UTC time of the fill. */
  fillUtcTime?:
    | number
    | undefined;
  /**
   * Limit price of the order (required for limit and stop-limit orders).
   * Display price format.
   */
  displayLimitPrice?:
    | number
    | undefined;
  /**
   * Stop price of the order (required for stop and stop-limit orders).
   * Display price format.
   */
  displayStopPrice?:
    | number
    | undefined;
  /**
   * Price of the single fill to apply to the order.
   * Display price format for the order's contract.
   */
  displayFillPrice?:
    | number
    | undefined;
  /**
   * Order open/close type.
   * This field is associated with OrderChain.OpenCloseType enum type.
   */
  openCloseType?:
    | number
    | undefined;
  /**
   * Order speculation type.
   * This field is associated with OrderChain.SpeculationType enum type.
   */
  speculationType?:
    | number
    | undefined;
  /** Flag that trade was aggressive (null by default). */
  fillIsAggressive?:
    | boolean
    | undefined;
  /** [required] Quantity of the single fill to apply to the order. */
  fillQuantity?:
    | Decimal
    | undefined;
  /** Flag for wait replication from local to central. */
  sync?: boolean | undefined;
}

/**
 * Order search request message.
 * Server returns a list of found order request records.
 * By default result contains at most DEFAULT_RESULT_RECORDS_NUMBER records.
 * Set parameter top to a larger number to receive more.
 */
export interface OrderSearchRequest {
  /**
   * [obsolete] Text to search.
   *
   * @deprecated
   */
  obsoleteText?:
    | string
    | undefined;
  /**
   * [obsolete] List of search options.
   * This field is associated with SearchCriteria enum type.
   * Empty list means search by any possible options.
   *
   * @deprecated
   */
  obsoleteSearchCriteria: number[];
  /** optionally restrict matched results by returning the first 'top' records. */
  top?:
    | number
    | undefined;
  /** Trade location. */
  tradeLocationId?:
    | string
    | undefined;
  /** Archived orders only. */
  archived?:
    | boolean
    | undefined;
  /** List of search options. Each option has its own search text, criteria and matching rule. */
  searchOptions: SearchOption[];
  /**
   * Indicates, whether all criteria must match at once.
   * By default any criteria must match.
   */
  allMatchMode?: boolean | undefined;
}

/** List of possible search refine criteria. */
export enum OrderSearchRequest_SearchCriteria {
  /** ORDER_ID - Search by order request id. */
  ORDER_ID = 1,
  /** CLIENT_ORDER_ID - Search by client order ID. */
  CLIENT_ORDER_ID = 2,
  /**
   * TICKET_NUMBER - Search by order ticket number
   * (order identifier assigned by execution system).
   */
  TICKET_NUMBER = 3,
  /** CONTRACT - Search by order contract. */
  CONTRACT = 4,
  /** ACCOUNT_ID - Search by account id. */
  ACCOUNT_ID = 5,
  /** ACCOUNT_NAME - Search by account name. */
  ACCOUNT_NAME = 6,
  /** BROKERAGE_NUMBER - Search by brokerage account number. */
  BROKERAGE_NUMBER = 7,
  /** USER_NAME - Search by user name. */
  USER_NAME = 8,
  /** CUSTOMER_LAST_NAME - Search by customer last name. */
  CUSTOMER_LAST_NAME = 9,
  /** CUSTOMER_FIRST_NAME - Search by customer first name. */
  CUSTOMER_FIRST_NAME = 10,
  /** USER_ID - Search by user id. */
  USER_ID = 11,
  /**
   * ROUTE_ORDER_ID - Search by route order id
   * (numeric id assigned withing order route).
   */
  ROUTE_ORDER_ID = 12,
  /**
   * ROUTE_CLIENT_ORDER_ID - Search by route client order id
   * (route order id transformed to meet execution system format rules).
   */
  ROUTE_CLIENT_ORDER_ID = 13,
  /** ORDER_GUID - Search by order GUID. */
  ORDER_GUID = 14,
  /** FILL_CARE_ORDER_ID - Fill care order id. */
  FILL_CARE_ORDER_ID = 15,
  /** CHAIN_ORDER_ID - Chain order id. */
  CHAIN_ORDER_ID = 16,
  /** SALES_SERIES_NUMBER - Search by sales series number. */
  SALES_SERIES_NUMBER = 17,
  /** SALES_SERIES_NAME - Search by sales series name. */
  SALES_SERIES_NAME = 18,
  /**
   * SALES_SERIES_ID - Search by sales series ID.
   * Use PROFILE_SALES_SERIES_ID instead.
   */
  SALES_SERIES_ID = 19,
  /** EXCHANGE_NAME - Search by exchange name. */
  EXCHANGE_NAME = 20,
  /** ROUTE_CODE - Search by route code. */
  ROUTE_CODE = 21,
  /** ROUTE_NAME - Search by route name. */
  ROUTE_NAME = 22,
  /** PROFILE_SALES_SERIES_ID - Search by profile sales series ID. */
  PROFILE_SALES_SERIES_ID = 23,
  /** BATCH_REQUEST_GUID - Search by batch request GUID. */
  BATCH_REQUEST_GUID = 24,
  /**
   * CHAIN_ORDER_STATUS_ID - Order Chain status.
   * This criterion is associated with OrderChain.Status enum type.
   */
  CHAIN_ORDER_STATUS_ID = 25,
  /**
   * ORDER_STATE_ID - Order request state.
   * This criterion is associated with OrderRequest.State enum type.
   */
  ORDER_STATE_ID = 26,
  UNRECOGNIZED = -1,
}

export function orderSearchRequest_SearchCriteriaFromJSON(object: any): OrderSearchRequest_SearchCriteria {
  switch (object) {
    case 1:
    case "ORDER_ID":
      return OrderSearchRequest_SearchCriteria.ORDER_ID;
    case 2:
    case "CLIENT_ORDER_ID":
      return OrderSearchRequest_SearchCriteria.CLIENT_ORDER_ID;
    case 3:
    case "TICKET_NUMBER":
      return OrderSearchRequest_SearchCriteria.TICKET_NUMBER;
    case 4:
    case "CONTRACT":
      return OrderSearchRequest_SearchCriteria.CONTRACT;
    case 5:
    case "ACCOUNT_ID":
      return OrderSearchRequest_SearchCriteria.ACCOUNT_ID;
    case 6:
    case "ACCOUNT_NAME":
      return OrderSearchRequest_SearchCriteria.ACCOUNT_NAME;
    case 7:
    case "BROKERAGE_NUMBER":
      return OrderSearchRequest_SearchCriteria.BROKERAGE_NUMBER;
    case 8:
    case "USER_NAME":
      return OrderSearchRequest_SearchCriteria.USER_NAME;
    case 9:
    case "CUSTOMER_LAST_NAME":
      return OrderSearchRequest_SearchCriteria.CUSTOMER_LAST_NAME;
    case 10:
    case "CUSTOMER_FIRST_NAME":
      return OrderSearchRequest_SearchCriteria.CUSTOMER_FIRST_NAME;
    case 11:
    case "USER_ID":
      return OrderSearchRequest_SearchCriteria.USER_ID;
    case 12:
    case "ROUTE_ORDER_ID":
      return OrderSearchRequest_SearchCriteria.ROUTE_ORDER_ID;
    case 13:
    case "ROUTE_CLIENT_ORDER_ID":
      return OrderSearchRequest_SearchCriteria.ROUTE_CLIENT_ORDER_ID;
    case 14:
    case "ORDER_GUID":
      return OrderSearchRequest_SearchCriteria.ORDER_GUID;
    case 15:
    case "FILL_CARE_ORDER_ID":
      return OrderSearchRequest_SearchCriteria.FILL_CARE_ORDER_ID;
    case 16:
    case "CHAIN_ORDER_ID":
      return OrderSearchRequest_SearchCriteria.CHAIN_ORDER_ID;
    case 17:
    case "SALES_SERIES_NUMBER":
      return OrderSearchRequest_SearchCriteria.SALES_SERIES_NUMBER;
    case 18:
    case "SALES_SERIES_NAME":
      return OrderSearchRequest_SearchCriteria.SALES_SERIES_NAME;
    case 19:
    case "SALES_SERIES_ID":
      return OrderSearchRequest_SearchCriteria.SALES_SERIES_ID;
    case 20:
    case "EXCHANGE_NAME":
      return OrderSearchRequest_SearchCriteria.EXCHANGE_NAME;
    case 21:
    case "ROUTE_CODE":
      return OrderSearchRequest_SearchCriteria.ROUTE_CODE;
    case 22:
    case "ROUTE_NAME":
      return OrderSearchRequest_SearchCriteria.ROUTE_NAME;
    case 23:
    case "PROFILE_SALES_SERIES_ID":
      return OrderSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_ID;
    case 24:
    case "BATCH_REQUEST_GUID":
      return OrderSearchRequest_SearchCriteria.BATCH_REQUEST_GUID;
    case 25:
    case "CHAIN_ORDER_STATUS_ID":
      return OrderSearchRequest_SearchCriteria.CHAIN_ORDER_STATUS_ID;
    case 26:
    case "ORDER_STATE_ID":
      return OrderSearchRequest_SearchCriteria.ORDER_STATE_ID;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderSearchRequest_SearchCriteria.UNRECOGNIZED;
  }
}

export function orderSearchRequest_SearchCriteriaToJSON(object: OrderSearchRequest_SearchCriteria): string {
  switch (object) {
    case OrderSearchRequest_SearchCriteria.ORDER_ID:
      return "ORDER_ID";
    case OrderSearchRequest_SearchCriteria.CLIENT_ORDER_ID:
      return "CLIENT_ORDER_ID";
    case OrderSearchRequest_SearchCriteria.TICKET_NUMBER:
      return "TICKET_NUMBER";
    case OrderSearchRequest_SearchCriteria.CONTRACT:
      return "CONTRACT";
    case OrderSearchRequest_SearchCriteria.ACCOUNT_ID:
      return "ACCOUNT_ID";
    case OrderSearchRequest_SearchCriteria.ACCOUNT_NAME:
      return "ACCOUNT_NAME";
    case OrderSearchRequest_SearchCriteria.BROKERAGE_NUMBER:
      return "BROKERAGE_NUMBER";
    case OrderSearchRequest_SearchCriteria.USER_NAME:
      return "USER_NAME";
    case OrderSearchRequest_SearchCriteria.CUSTOMER_LAST_NAME:
      return "CUSTOMER_LAST_NAME";
    case OrderSearchRequest_SearchCriteria.CUSTOMER_FIRST_NAME:
      return "CUSTOMER_FIRST_NAME";
    case OrderSearchRequest_SearchCriteria.USER_ID:
      return "USER_ID";
    case OrderSearchRequest_SearchCriteria.ROUTE_ORDER_ID:
      return "ROUTE_ORDER_ID";
    case OrderSearchRequest_SearchCriteria.ROUTE_CLIENT_ORDER_ID:
      return "ROUTE_CLIENT_ORDER_ID";
    case OrderSearchRequest_SearchCriteria.ORDER_GUID:
      return "ORDER_GUID";
    case OrderSearchRequest_SearchCriteria.FILL_CARE_ORDER_ID:
      return "FILL_CARE_ORDER_ID";
    case OrderSearchRequest_SearchCriteria.CHAIN_ORDER_ID:
      return "CHAIN_ORDER_ID";
    case OrderSearchRequest_SearchCriteria.SALES_SERIES_NUMBER:
      return "SALES_SERIES_NUMBER";
    case OrderSearchRequest_SearchCriteria.SALES_SERIES_NAME:
      return "SALES_SERIES_NAME";
    case OrderSearchRequest_SearchCriteria.SALES_SERIES_ID:
      return "SALES_SERIES_ID";
    case OrderSearchRequest_SearchCriteria.EXCHANGE_NAME:
      return "EXCHANGE_NAME";
    case OrderSearchRequest_SearchCriteria.ROUTE_CODE:
      return "ROUTE_CODE";
    case OrderSearchRequest_SearchCriteria.ROUTE_NAME:
      return "ROUTE_NAME";
    case OrderSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_ID:
      return "PROFILE_SALES_SERIES_ID";
    case OrderSearchRequest_SearchCriteria.BATCH_REQUEST_GUID:
      return "BATCH_REQUEST_GUID";
    case OrderSearchRequest_SearchCriteria.CHAIN_ORDER_STATUS_ID:
      return "CHAIN_ORDER_STATUS_ID";
    case OrderSearchRequest_SearchCriteria.ORDER_STATE_ID:
      return "ORDER_STATE_ID";
    case OrderSearchRequest_SearchCriteria.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Requests tree with related order chains for synthetic or synthetic strategy orders.
 * By default result contains at most DEFAULT_RESULT_RECORDS_NUMBER records.
 * Set parameter order_requests_top to a larger number to receive more.
 */
export interface RelatedOrderTreeRequest {
  /** [required] Identifier of any order chain in a tree. */
  chainOrderId?:
    | string
    | undefined;
  /** Trade location. */
  tradeLocationId?:
    | string
    | undefined;
  /** Optionally restrict order requests in the chains by returning 'top' ones of the last submitted. */
  orderRequestsTop?:
    | number
    | undefined;
  /** Optionally restrict matched results by returning the first 'top' records (sorted by leg path). */
  top?: number | undefined;
}

/**
 * Compound orders tree.
 * By default result contains at most DEFAULT_RESULT_RECORDS_NUMBER records.
 * Set parameter order_requests_top/top to a larger number to receive more.
 */
export interface CompoundOrderTreeRequest {
  /** [required] Identifier of compound tree. */
  compoundTreeId?:
    | string
    | undefined;
  /** Trade location. */
  tradeLocationId?:
    | string
    | undefined;
  /** Optionally restrict order requests in the chains by returning 'top' ones of the last submitted. */
  orderRequestsTop?:
    | number
    | undefined;
  /** optionally restrict matched results by returning the first 'top' records. */
  top?: number | undefined;
}

/** Order strategy tree, describing synthetic strategy. */
export interface SyntheticStrategyTreeRequest {
  /** [required] Identifier of root synthetic strategy order chain. */
  rootChainOrderId?:
    | string
    | undefined;
  /** Trade location. */
  tradeLocationId?: string | undefined;
}

/**
 * Cancel order operation.
 * Either account_id or trade_location_id is required.
 */
export interface CancelOrder {
  /** [required] Chain order identifier. */
  chainOrderId?:
    | string
    | undefined;
  /**
   * ID of the account that was used to place the order.
   * Has priority over trade_location_id.
   */
  accountId?:
    | number
    | undefined;
  /** Trade location. */
  tradeLocationId?:
    | string
    | undefined;
  /** Flag for wait replication from local to central. */
  sync?: boolean | undefined;
}

/**
 * Reflect order as canceled without sending real cancel request to exchange.
 * Either account_id or trade_location_id is required.
 */
export interface ReflectAsCanceledOrder {
  /** [required] Chain order identifier. */
  chainOrderId?:
    | string
    | undefined;
  /**
   * ID of the account that was used to place the order.
   * Has priority over trade_location_id.
   */
  accountId?:
    | number
    | undefined;
  /** Trade location. */
  tradeLocationId?:
    | string
    | undefined;
  /** Flag for wait replication from local to central. */
  sync?: boolean | undefined;
}

/**
 * Cancel whole tree of compound orders.
 * Either account_id or trade_location_id is required.
 */
export interface CancelCompoundOrderTree {
  /** [required] Compound tree identifier. */
  compoundTreeId?:
    | string
    | undefined;
  /**
   * ID of the account that was used to place the tree.
   * Has priority over trade_location_id.
   */
  accountId?:
    | number
    | undefined;
  /** Trade location. */
  tradeLocationId?:
    | string
    | undefined;
  /** Flag for wait replication from local to central. */
  sync?: boolean | undefined;
}

/** Either account_id or trade_location_id is required. */
export interface ConfirmOrderSentToExchange {
  /** [required] Chain order identifier. */
  chainOrderId?:
    | string
    | undefined;
  /**
   * ID of the account that was used to place the order.
   * Has priority over trade_location_id.
   */
  accountId?:
    | string
    | undefined;
  /** Trade location. */
  tradeLocationId?:
    | string
    | undefined;
  /** [required] New route order id. */
  newRouteOrderId?:
    | string
    | undefined;
  /** Flag for wait replication from local to central. */
  sync?: boolean | undefined;
}

/** Either account_id or trade_location_id is required. */
export interface ActivateOrder {
  /** [required] Chain order identifier. */
  chainOrderId?:
    | string
    | undefined;
  /**
   * ID of the account that was used to place the order.
   * Has priority over trade_location_id.
   */
  accountId?:
    | string
    | undefined;
  /** Trade location. */
  tradeLocationId?:
    | string
    | undefined;
  /** Flag for wait replication from local to central. */
  sync?: boolean | undefined;
}

/** Either account_id or trade_location_id is required. */
export interface CorrectFill {
  /** [required] Trade routing order fill/leg fill unique identifier. */
  id?:
    | string
    | undefined;
  /**
   * Associated with CorrectFill.FillType enum.
   * If ommited, FILL_TYPE_ORDER_FILL type is used.
   */
  fillType?:
    | number
    | undefined;
  /**
   * ID of the account that was used to place the order.
   * Has priority over trade_location_id.
   */
  accountId?:
    | string
    | undefined;
  /** Trade location identifier. */
  tradeLocationId?:
    | string
    | undefined;
  /** [required] Quantity of bought or sold lots depending on order side. */
  quantity?:
    | Decimal
    | undefined;
  /**
   * [required] Price of the single fill to apply to the order.
   * Display price format for the order's contract.
   */
  displayPrice?:
    | number
    | undefined;
  /** Flag for wait replication from local to central. */
  sync?: boolean | undefined;
}

export enum CorrectFill_FillType {
  FILL_TYPE_ORDER_FILL = 1,
  FILL_TYPE_LEG_FILL = 2,
  UNRECOGNIZED = -1,
}

export function correctFill_FillTypeFromJSON(object: any): CorrectFill_FillType {
  switch (object) {
    case 1:
    case "FILL_TYPE_ORDER_FILL":
      return CorrectFill_FillType.FILL_TYPE_ORDER_FILL;
    case 2:
    case "FILL_TYPE_LEG_FILL":
      return CorrectFill_FillType.FILL_TYPE_LEG_FILL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CorrectFill_FillType.UNRECOGNIZED;
  }
}

export function correctFill_FillTypeToJSON(object: CorrectFill_FillType): string {
  switch (object) {
    case CorrectFill_FillType.FILL_TYPE_ORDER_FILL:
      return "FILL_TYPE_ORDER_FILL";
    case CorrectFill_FillType.FILL_TYPE_LEG_FILL:
      return "FILL_TYPE_LEG_FILL";
    case CorrectFill_FillType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Either account_id or trade_location_id is required. */
export interface CancelFill {
  /** [required] Trade routing order fill/leg fill unique identifier. */
  id?:
    | string
    | undefined;
  /**
   * Associated with CorrectFill.FillType enum.
   * If ommited, FILL_TYPE_ORDER_FILL type is used.
   */
  fillType?:
    | number
    | undefined;
  /**
   * ID of the account that was used to place the order.
   * Has priority over trade_location_id.
   */
  accountId?:
    | string
    | undefined;
  /** Trade location. */
  tradeLocationId?:
    | string
    | undefined;
  /**
   * Busting a fill means that the quantity remaining on the order will not be adjusted.
   * For example, on an order for 5 with 2 fills busting the fills keeps the quantity remaining at 3 instead of restoring it to 5.
   * In case of fill_type = FILL_TYPE_FILL_LEG, bust mode is always used.
   */
  bust?:
    | boolean
    | undefined;
  /** Flag for wait replication from local to central. */
  sync?: boolean | undefined;
}

/** Exclude order from (or include order to) risk calculation. */
export interface IgnoreOrder {
  /** [required] Chain order identifier. */
  chainOrderId?:
    | string
    | undefined;
  /**
   * ID of the account that was used to place the order.
   * Has priority over trade_location_id.
   */
  accountId?:
    | string
    | undefined;
  /** Trade location. */
  tradeLocationId?:
    | string
    | undefined;
  /** [required] true = ignore order, false = stop ignoring order. */
  action?: boolean | undefined;
}

/**
 * Account information request message.
 * Server returns matching account if exactly one account matches supplied criteria.
 * Either account_id or brokerage_account_number is required.
 */
export interface AccountInfoRequest {
  /**
   * Trade routing account identifier.
   * Has priority over brokerage_account_number.
   */
  accountId?:
    | number
    | undefined;
  /** Brokerage account number. */
  brokerageAccountNumber?: string | undefined;
}

/**
 * Balance records request message.
 * Server returns balance records that matches supplied criteria.
 */
export interface BalanceRecordsRequest {
  /**
   * [required] Gateway account identifier.
   * If supplied, balance_id should not be supplied.
   */
  accountId?:
    | number
    | undefined;
  /**
   * Currency code (real currency code is ISO 4217 based.
   * If supplied, only balance records of this currency are returned.
   * Can be supplied only with account_id.
   */
  currency?:
    | string
    | undefined;
  /**
   * Trade routing balance identifier.
   * If supplied, only the balance record with this ID is returned.
   * If supplied, account_id and currency should not be supplied.
   */
  balanceId?: number | undefined;
}

/**
 * Create balance record message.
 * Add a new balance record for the specified account.
 */
export interface CreateBalanceRecord {
  /** [required] Trade routing account identifier. */
  accountId?:
    | number
    | undefined;
  /** [required] Currency code (real currency code is ISO 4217 based. */
  currency?:
    | string
    | undefined;
  /** [required] End cash balance. */
  endCashBalance?:
    | number
    | undefined;
  /** Cash value of collateral. */
  collateral?:
    | number
    | undefined;
  /** Regulated. */
  regulated?:
    | boolean
    | undefined;
  /**
   * Statement date, could be present or future (UTC).
   * Datetime format is used but only date part is used, time part is ignored.
   */
  asOfDate?: number | undefined;
}

/** Update balance record message. */
export interface UpdateBalanceRecord {
  /** [required] Trade routing balance record identifier. */
  balanceId: number;
  /** End cash balance. */
  endCashBalance?:
    | number
    | undefined;
  /**
   * Cash value of collateral.
   * If not supplied, any existing collateral value is not changed.
   */
  collateral?: number | undefined;
}

/**
 * Account search request message.
 * Server returns a list of found account records.
 * By default result contains at most DEFAULT_RESULT_RECORDS_NUMBER records.
 */
export interface AccountSearchRequest {
  /**
   * [obsolete] Text to search.
   *
   * @deprecated
   */
  obsoleteText?:
    | string
    | undefined;
  /**
   * [obsolete] List of search options.
   * This field is associated with SearchCriteria enum type.
   * Empty list means search by any possible options.
   *
   * @deprecated
   */
  obsoleteSearchCriteria: number[];
  /** Optionally restrict matched results by returning the first 'top' records. */
  top?:
    | number
    | undefined;
  /**
   * Determines whether removed entities are included in search result.
   * For clients that use protocol version under 1.19 it is 'true' by default.
   */
  includeRemoved?:
    | boolean
    | undefined;
  /** List of search options. Each option has its own search text, criteria and matching rule. */
  searchOptions: SearchOption[];
  /**
   * Indicates, whether all criteria must match at once.
   * By default any criteria must match.
   */
  allMatchMode?:
    | boolean
    | undefined;
  /**
   * Determines whether accounts from mapped sales series profiles are included in search result.
   * Available only for Brokerage scope admin logins with appropriate permissions.
   */
  includeAccountsFromMappedSalesSeries?: boolean | undefined;
}

export enum AccountSearchRequest_AccountGroupRole {
  MASTER = 2,
  SUB = 3,
  UNRECOGNIZED = -1,
}

export function accountSearchRequest_AccountGroupRoleFromJSON(object: any): AccountSearchRequest_AccountGroupRole {
  switch (object) {
    case 2:
    case "MASTER":
      return AccountSearchRequest_AccountGroupRole.MASTER;
    case 3:
    case "SUB":
      return AccountSearchRequest_AccountGroupRole.SUB;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AccountSearchRequest_AccountGroupRole.UNRECOGNIZED;
  }
}

export function accountSearchRequest_AccountGroupRoleToJSON(object: AccountSearchRequest_AccountGroupRole): string {
  switch (object) {
    case AccountSearchRequest_AccountGroupRole.MASTER:
      return "MASTER";
    case AccountSearchRequest_AccountGroupRole.SUB:
      return "SUB";
    case AccountSearchRequest_AccountGroupRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** List of possible search refine criteria. */
export enum AccountSearchRequest_SearchCriteria {
  /** ACCOUNT_ID - Search by account id. */
  ACCOUNT_ID = 1,
  /** ACCOUNT_NAME - Search by account name. */
  ACCOUNT_NAME = 2,
  /** BROKERAGE_NUMBER - Search by brokerage account number. */
  BROKERAGE_NUMBER = 3,
  /** PROFILE_SALES_SERIES_NUMBER - Search by profile sales series number. */
  PROFILE_SALES_SERIES_NUMBER = 4,
  /** PROFILE_SALES_SERIES_NAME - Search by profile sales series name. */
  PROFILE_SALES_SERIES_NAME = 5,
  /**
   * SALES_SERIES_ID - Search by sales series ID.
   * Use PROFILE_SALES_SERIES_ID instead.
   */
  SALES_SERIES_ID = 6,
  /**
   * CUSTOMER_ID - Search by customer ID.
   * Use PROFILE_ID instead.
   */
  CUSTOMER_ID = 7,
  /** PROFILE_NAME - Search by profile name. */
  PROFILE_NAME = 8,
  /**
   * OBSOLETE_ACCOUNT_CLUSTER_ID - [obsolete] Search by account cluster.
   * This criteria is no longer supported.
   */
  OBSOLETE_ACCOUNT_CLUSTER_ID = 9,
  /** RISK_SERVER_INSTANCE_ID - LIMITED USE: Only explicitly, one per search option with EXACT_EQUALITY rule and in all match mode. */
  RISK_SERVER_INSTANCE_ID = 10,
  ACCOUNT_GROUP_ID = 11,
  /**
   * ACCOUNT_GROUP_ROLE - This field is associated with AccountSearchRequest.AccountGroupRole enum type.
   * LIMITED USE: Only explicitly, one per search option with EXACT_EQUALITY rule and in all match mode.
   */
  ACCOUNT_GROUP_ROLE = 12,
  BROKERAGE_ID = 13,
  /**
   * ACCOUNT_CLASS - This field is associated with Account.Class enum type.
   * LIMITED USE: Only explicitly, one per search option with EXACT_EQUALITY rule and in all match mode.
   */
  ACCOUNT_CLASS = 14,
  /**
   * IS_COLLECTION - Can be 0 (false) or 1 (true).
   * LIMITED USE: Only explicitly, one per search option with EXACT_EQUALITY rule and in all match mode.
   */
  IS_COLLECTION = 15,
  /**
   * IS_IN_ACCOUNT_GROUP - Can be 0 (false) or 1 (true).
   * LIMITED USE: Only explicitly, one per search option with EXACT_EQUALITY rule and in all match mode.
   */
  IS_IN_ACCOUNT_GROUP = 16,
  /** PROFILE_ID - Search by profile (customer/admin) ID. */
  PROFILE_ID = 17,
  /** PROFILE_SALES_SERIES_ID - Search by profile sales series ID. */
  PROFILE_SALES_SERIES_ID = 18,
  UNRECOGNIZED = -1,
}

export function accountSearchRequest_SearchCriteriaFromJSON(object: any): AccountSearchRequest_SearchCriteria {
  switch (object) {
    case 1:
    case "ACCOUNT_ID":
      return AccountSearchRequest_SearchCriteria.ACCOUNT_ID;
    case 2:
    case "ACCOUNT_NAME":
      return AccountSearchRequest_SearchCriteria.ACCOUNT_NAME;
    case 3:
    case "BROKERAGE_NUMBER":
      return AccountSearchRequest_SearchCriteria.BROKERAGE_NUMBER;
    case 4:
    case "PROFILE_SALES_SERIES_NUMBER":
      return AccountSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_NUMBER;
    case 5:
    case "PROFILE_SALES_SERIES_NAME":
      return AccountSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_NAME;
    case 6:
    case "SALES_SERIES_ID":
      return AccountSearchRequest_SearchCriteria.SALES_SERIES_ID;
    case 7:
    case "CUSTOMER_ID":
      return AccountSearchRequest_SearchCriteria.CUSTOMER_ID;
    case 8:
    case "PROFILE_NAME":
      return AccountSearchRequest_SearchCriteria.PROFILE_NAME;
    case 9:
    case "OBSOLETE_ACCOUNT_CLUSTER_ID":
      return AccountSearchRequest_SearchCriteria.OBSOLETE_ACCOUNT_CLUSTER_ID;
    case 10:
    case "RISK_SERVER_INSTANCE_ID":
      return AccountSearchRequest_SearchCriteria.RISK_SERVER_INSTANCE_ID;
    case 11:
    case "ACCOUNT_GROUP_ID":
      return AccountSearchRequest_SearchCriteria.ACCOUNT_GROUP_ID;
    case 12:
    case "ACCOUNT_GROUP_ROLE":
      return AccountSearchRequest_SearchCriteria.ACCOUNT_GROUP_ROLE;
    case 13:
    case "BROKERAGE_ID":
      return AccountSearchRequest_SearchCriteria.BROKERAGE_ID;
    case 14:
    case "ACCOUNT_CLASS":
      return AccountSearchRequest_SearchCriteria.ACCOUNT_CLASS;
    case 15:
    case "IS_COLLECTION":
      return AccountSearchRequest_SearchCriteria.IS_COLLECTION;
    case 16:
    case "IS_IN_ACCOUNT_GROUP":
      return AccountSearchRequest_SearchCriteria.IS_IN_ACCOUNT_GROUP;
    case 17:
    case "PROFILE_ID":
      return AccountSearchRequest_SearchCriteria.PROFILE_ID;
    case 18:
    case "PROFILE_SALES_SERIES_ID":
      return AccountSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_ID;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AccountSearchRequest_SearchCriteria.UNRECOGNIZED;
  }
}

export function accountSearchRequest_SearchCriteriaToJSON(object: AccountSearchRequest_SearchCriteria): string {
  switch (object) {
    case AccountSearchRequest_SearchCriteria.ACCOUNT_ID:
      return "ACCOUNT_ID";
    case AccountSearchRequest_SearchCriteria.ACCOUNT_NAME:
      return "ACCOUNT_NAME";
    case AccountSearchRequest_SearchCriteria.BROKERAGE_NUMBER:
      return "BROKERAGE_NUMBER";
    case AccountSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_NUMBER:
      return "PROFILE_SALES_SERIES_NUMBER";
    case AccountSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_NAME:
      return "PROFILE_SALES_SERIES_NAME";
    case AccountSearchRequest_SearchCriteria.SALES_SERIES_ID:
      return "SALES_SERIES_ID";
    case AccountSearchRequest_SearchCriteria.CUSTOMER_ID:
      return "CUSTOMER_ID";
    case AccountSearchRequest_SearchCriteria.PROFILE_NAME:
      return "PROFILE_NAME";
    case AccountSearchRequest_SearchCriteria.OBSOLETE_ACCOUNT_CLUSTER_ID:
      return "OBSOLETE_ACCOUNT_CLUSTER_ID";
    case AccountSearchRequest_SearchCriteria.RISK_SERVER_INSTANCE_ID:
      return "RISK_SERVER_INSTANCE_ID";
    case AccountSearchRequest_SearchCriteria.ACCOUNT_GROUP_ID:
      return "ACCOUNT_GROUP_ID";
    case AccountSearchRequest_SearchCriteria.ACCOUNT_GROUP_ROLE:
      return "ACCOUNT_GROUP_ROLE";
    case AccountSearchRequest_SearchCriteria.BROKERAGE_ID:
      return "BROKERAGE_ID";
    case AccountSearchRequest_SearchCriteria.ACCOUNT_CLASS:
      return "ACCOUNT_CLASS";
    case AccountSearchRequest_SearchCriteria.IS_COLLECTION:
      return "IS_COLLECTION";
    case AccountSearchRequest_SearchCriteria.IS_IN_ACCOUNT_GROUP:
      return "IS_IN_ACCOUNT_GROUP";
    case AccountSearchRequest_SearchCriteria.PROFILE_ID:
      return "PROFILE_ID";
    case AccountSearchRequest_SearchCriteria.PROFILE_SALES_SERIES_ID:
      return "PROFILE_SALES_SERIES_ID";
    case AccountSearchRequest_SearchCriteria.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Get list of routes enabled for the account. */
export interface AccountRouteListRequest {
  /** Account ID to get list of routes. */
  accountId: number;
}

/**
 * Account to user authorization list request message.
 * Either account_id (to request a list of users for an account)
 * or user_id (to request a list or accounts for a user) or both is required.
 */
export interface AccountUserAuthorizationListRequest {
  /** Account ID. */
  accountId?:
    | number
    | undefined;
  /** User ID. */
  userId?:
    | string
    | undefined;
  /** optionally restrict results by returning the first 'top' records. */
  top?:
    | number
    | undefined;
  /** Includes also implicit authorization (via brokerage or sales series direct authority). */
  includeImplicitAuthorization?: boolean | undefined;
}

/** Account settings request message. */
export interface AccountSettingsRequest {
  /** Account ID to get account settings. */
  accountId: number;
}

/** Account risk parameters request message. */
export interface AccountRiskParametersRequest {
  /** Account ID to get risk parameters. */
  accountId: number;
}

/**
 * Update account risk parameters message.
 * [obsolete] Use UpdateAccountRiskParameters instead.
 */
export interface UpdateRiskParameters {
  /** Trade routing account identifier. */
  accountId: number;
  /** Allow positions to be closed only. */
  liquidationOnly?:
    | boolean
    | undefined;
  /** Allow futures trading. */
  allowFutures?:
    | boolean
    | undefined;
  /**
   * Allow options trading.
   * The value is associated with AccountRiskParameters.OptionsTrading enum.
   */
  allowOptions?:
    | number
    | undefined;
  /**
   * Additional credit available to satisfy margin requirements.
   * Can be set only if Margin Subsystem is enabled for the account.
   */
  allowableMarginCredit?: number | undefined;
}

/** Account potentially available exchange groups and commodities request message. */
export interface AccountAvailableExchangeGroupsRequest {
  /** Account ID to get exchange groups. */
  accountId: number;
}

/** Account market limits request message. */
export interface AccountMarketLimitsRequest {
  /** Account ID to get limits. */
  accountId: number;
}

/** Account positions request message. */
export interface AccountPositionsRequest {
  /** [required] Account ID to get positions. */
  accountId?:
    | number
    | undefined;
  /**
   * Group positions by contract or fungible contract.
   * By default grouping is by fungible contract.
   */
  groupByContract?: boolean | undefined;
}

/** Account equity request message. */
export interface AccountEquityRequest {
  /** Account ID. */
  accountId: number;
  /**
   * Currency code of requested equity values (ISO 4217 based).
   * Defaults to account’s currency if omitted.
   */
  currency?: string | undefined;
}

/** Account collateral request message. */
export interface AccountCollateralRequest {
  /** Account ID. */
  accountId: number;
}

/**
 * Clone account message.
 * Information that is cloned:
 * - account customer (if new_account_user_id is not provided);
 * - account settings;
 * - account routing options;
 * - account risk parameters;
 * - account market limits and allowable markets;
 * - list of authorized traders.
 */
export interface CloneAccount {
  /** Account which configuration will be copied. */
  sourceAccountId: number;
  /** Name of the new account (max length = 64). */
  newAccountName: string;
  /** Brokerage number of the new account (max length = 256). */
  newAccountBrokerageNumber: string;
  /**
   * ID of a user (trader) which customer is used for the new account.
   * This user will be authorized on the new account.
   * If not specified then the source account customer will be used.
   */
  newAccountUserId?:
    | string
    | undefined;
  /** This field is associated with Account.Class enum. */
  newAccountClass?: number | undefined;
}

/** Updates general information of account. */
export interface UpdateAccount {
  /**
   * [required] Account information to update.
   * Only fields for update must be provided.
   * Fields that cannot be changed with this update message (see fields comments) are ignored.
   */
  account?:
    | Account
    | undefined;
  /**
   * [obsolete] Use only account field instead.
   *
   * @deprecated
   */
  obsoleteOriginalAccount?: Account | undefined;
}

/** [obsolete] Requests account cluster by its identifier. */
export interface AccountClusterRequest {
  /** [required] Account cluster id. */
  accountClusterId?: string | undefined;
}

/** [obsolete] Creates account cluster. */
export interface CreateAccountCluster {
  /**
   * [required] Account cluster to create.
   *
   * @deprecated
   */
  obsoleteAccountCluster?: AccountCluster | undefined;
}

/** [obsolete] Updates account cluster. */
export interface UpdateAccountCluster {
  /**
   * [required] Account cluster to update.
   *
   * @deprecated
   */
  obsoleteAccountCluster?:
    | AccountCluster
    | undefined;
  /**
   * Original account cluster information.
   * When provided, only fields that are different from the original account cluster are changed;
   * otherwise account cluster is applied as provided in account cluster fields.
   *
   * @deprecated
   */
  obsoleteOriginalAccountCluster?: AccountCluster | undefined;
}

/** [obsolete] Updates account cluster price offsets. */
export interface UpdateAccountClusterPriceOffsets {
  /** [required] Account cluster id. */
  accountClusterId?:
    | string
    | undefined;
  /**
   * Price offsets to create or update.
   * Price offsets will be created if they do not exist or updated otherwise.
   */
  offsetsToSet: AccountClusterPriceOffset[];
  /** Price offsets to remove. */
  offsetsToRemove: AccountClusterPriceOffset[];
}

export interface UpdateAccountSettings {
  /**
   * Account settings to update.
   * Only fields for update must be provided.
   */
  settings?:
    | AccountSettings
    | undefined;
  /**
   * [obsolete] Use only settings field instead.
   *
   * @deprecated
   */
  obsoleteOriginalSettings?: AccountSettings | undefined;
}

/** Either account_id or account_group_id is required (mutually exclusive). */
export interface AccountGroupRequest {
  /** Account id to get group information for, if it is related to any. */
  accountId?:
    | string
    | undefined;
  /** Account group id. */
  accountGroupId?: string | undefined;
}

/** Update risk parameters. */
export interface UpdateAccountRiskParameters {
  /**
   * [required] Account id.
   * Must be provided instead of risk_parameters.obsolete_account_id.
   */
  accountId?:
    | string
    | undefined;
  /**
   * [required] Risk parameters to update.
   * Only fields for update must be provided.
   */
  riskParameters?:
    | RiskParameters
    | undefined;
  /**
   * [obsolete] Use only risk_parameters field instead.
   *
   * @deprecated
   */
  obsoleteOriginalRiskParameters?: RiskParameters | undefined;
}

/** Update account route list. */
export interface UpdateAccountRouteList {
  /** [required] Account id to update routes. */
  accountId?:
    | string
    | undefined;
  /** Routes to add or change. */
  routesToSet: AccountRouteRecord[];
  /** Route codes to remove from account. */
  routeCodesToRemove: string[];
}

/**
 * Update account market limits.
 * How to use this message:
 * - only provided data is updated - records/levels (like exchange, commodity..)
 *   and limits inside of them are not changed if not provided explicitly;
 * - if you want to clear (set to default) group of limits (entire level, exchange, commodity..),
 *   including trading authority (allowed_trade) you can use cleared_fields of corresponding message.
 */
export interface UpdateAccountMarketLimits {
  /** [required] account id to update market limits. */
  accountId?:
    | string
    | undefined;
  /**
   * [required] market limits to update.
   * Only fields for update must be provided.
   */
  marketLimits?:
    | MarketLimits
    | undefined;
  /**
   * [obsolete] Use only market_limits field instead.
   *
   * @deprecated
   */
  obsoleteOriginalMarketLimits?: MarketLimits | undefined;
}

/** Request of all routes available for the account. */
export interface AccountAvailableRouteListRequest {
  /** [required] Account identifier. */
  accountId?: string | undefined;
}

/** Creates a new GW Account. */
export interface CreateAccount {
  /** [required] Account to create. */
  account?: Account | undefined;
}

/** Result of TradeRoutingRequest. */
export interface TradeRoutingResult {
  /** ID of a corresponding request. */
  requestId: number;
  /**
   * Operation status.
   * This field is associated with OperationStatus enum.
   */
  operationStatus: number;
  /** Error details if operation failed. */
  errorMessage?:
    | Text
    | undefined;
  /** Result of accounts related request. */
  accountScopeResult?:
    | AccountScopeResult
    | undefined;
  /** Result of orders related request. */
  orderScopeResult?:
    | OrderScopeResult
    | undefined;
  /** Result of lookup_property_list_request. */
  lookupPropertyListResult?:
    | LookupPropertyListResult
    | undefined;
  /**
   * Request tracking number generated by CMS API
   * Used by client to cancel deferred request
   */
  trackingNumber?: number | undefined;
}

/** Result of accounts related request. */
export interface AccountScopeResult {
  /** Result of account information request. */
  accountInfoResult?:
    | AccountInfoResult
    | undefined;
  /** Result of balance records request. */
  balanceRecordsResult?:
    | BalanceRecordsResult
    | undefined;
  /** Result of balance record creation. */
  createBalanceRecordResult?:
    | CreateBalanceRecordResult
    | undefined;
  /** Result of balance record update. */
  updateBalanceRecordResult?:
    | UpdateBalanceRecordResult
    | undefined;
  /** Result of account risk parameters request. */
  accountRiskParametersResult?:
    | AccountRiskParametersResult
    | undefined;
  /** Result of account's risk parameters update. */
  updateRiskParametersResult?:
    | UpdateRiskParametersResult
    | undefined;
  /** Result of account search request. */
  accountSearchResult?:
    | AccountSearchResult
    | undefined;
  /** Result of account routes list request. */
  accountRouteListResult?:
    | AccountRouteListResult
    | undefined;
  /** Result of account to user authorization list request. */
  accountUserAuthorizationListResult?:
    | AccountUserAuthorizationListResult
    | undefined;
  /** Result of account settings request. */
  accountSettingsResult?:
    | AccountSettingsResult
    | undefined;
  /** Result of AccountAvailableExchangeGroupsRequest. */
  accountExchangeGroupsResult?:
    | AccountExchangeGroupsResult
    | undefined;
  /** Result of account market limits request. */
  accountMarketLimitsResult?:
    | AccountMarketLimitsResult
    | undefined;
  /** Result of positions request. */
  accountPositionsResult?:
    | AccountPositionsResult
    | undefined;
  /** Result of equity request. */
  accountEquityResult?:
    | AccountEquityResult
    | undefined;
  /** Result of collateral request. */
  accountCollateralResult?:
    | AccountCollateralResult
    | undefined;
  /** Result of clone account request. */
  cloneAccountResult?:
    | CloneAccountResult
    | undefined;
  /** Result of UpdateAccount. */
  updateAccountResult?:
    | UpdateAccountResult
    | undefined;
  /** Result of UpdateAccountSettings. */
  updateAccountSettingsResult?:
    | UpdateAccountSettingsResult
    | undefined;
  /** Result of AccountGroupRequest. */
  accountGroupResult?:
    | AccountGroupResult
    | undefined;
  /** Result of UpdateAccountRiskParameters. */
  updateAccountRiskParametersResult?:
    | UpdateAccountRiskParametersResult
    | undefined;
  /** Result of AccountAvailableRouteListRequest. */
  accountAvailableRouteListResult?:
    | AccountAvailableRouteListResult
    | undefined;
  /** Result of UpdateAccountRouteList. */
  updateAccountRouteListResult?:
    | UpdateAccountRouteListResult
    | undefined;
  /** Result of UpdateAccountMarketLimits. */
  updateAccountMarketLimitsResult?:
    | UpdateAccountMarketLimitsResult
    | undefined;
  /** Result of CreateAccount. */
  createAccountResult?:
    | CreateAccountResult
    | undefined;
  /**
   * [obsolete] This operation is no longer supported.
   *
   * @deprecated
   */
  obsoleteAccountClusterResult?:
    | AccountClusterResult
    | undefined;
  /**
   * [obsolete] This operation is no longer supported.
   *
   * @deprecated
   */
  obsoleteCreateAccountClusterResult?:
    | CreateAccountClusterResult
    | undefined;
  /**
   * [obsolete] This operation is no longer supported.
   *
   * @deprecated
   */
  obsoleteUpdateAccountClusterResult?:
    | UpdateAccountClusterResult
    | undefined;
  /**
   * [obsolete] This operation is no longer supported.
   *
   * @deprecated
   */
  obsoleteUpdateAccountClusterPriceOffsetsResult?:
    | UpdateAccountClusterPriceOffsetsResult
    | undefined;
  /** Result of RestoreAccount. */
  restoreAccountResult?:
    | RestoreAccountResult
    | undefined;
  /** Result of AccountTypeOverrideListRequest. */
  accountTypeOverrideListResult?:
    | AccountTypeOverrideListResult
    | undefined;
  /** Result of UpdateAccountTypeOverrideList. */
  updateAccountTypeOverrideListResult?:
    | UpdateAccountTypeOverrideListResult
    | undefined;
  /** Result of UpdateAccountUserAuthorizationList. */
  updateAccountUserAuthorizationListResult?:
    | UpdateAccountUserAuthorizationListResult
    | undefined;
  /** Result of RemoveAccount. */
  removeAccountResult?:
    | RemoveAccountResult
    | undefined;
  /** Result of AccountAvailableServiceGroupListRequest. */
  accountAvailableServiceGroupListResult?:
    | AccountAvailableServiceGroupListResult
    | undefined;
  /** Result of AccountServiceGroupListRequest. */
  accountServiceGroupListResult?:
    | AccountServiceGroupListResult
    | undefined;
  /** Result of UpdateAccountServiceGroupList. */
  updateAccountServiceGroupListResult?:
    | UpdateAccountServiceGroupListResult
    | undefined;
  /** Result of BillableExchangeListRequest. */
  billableExchangeListResult?:
    | BillableExchangeListResult
    | undefined;
  /** Result of CreateAccountGroup. */
  createAccountGroupResult?:
    | CreateAccountGroupResult
    | undefined;
  /** Result of AccountTradingFeaturesRequest. */
  accountTradingFeaturesResult?: AccountTradingFeaturesResult | undefined;
}

/** Result of orders related request. */
export interface OrderScopeResult {
  /** Result of order details request. */
  orderDetailsResult?:
    | OrderDetailsResult
    | undefined;
  /** Result of fill adding. */
  addFillResult?:
    | AddFillResult
    | undefined;
  /** Result of external order creation. */
  createExternalOrderResult?:
    | CreateExternalOrderResult
    | undefined;
  /** Result of order search request. */
  orderSearchResult?:
    | OrderSearchResult
    | undefined;
  /** Result of RelatedOrderTreeRequest. */
  relatedOrderTreeResult?:
    | RelatedOrderTreeResult
    | undefined;
  /** Result of CompoundOrderTreeRequest. */
  compoundOrderTreeResult?:
    | CompoundOrderTreeResult
    | undefined;
  /** Result of SyntheticStrategyTreeRequest. */
  syntheticStrategyTreeResult?:
    | SyntheticStrategyTreeResult
    | undefined;
  /** Result of CancelOrder. */
  cancelOrderResult?:
    | CancelOrderResult
    | undefined;
  /** Result of ReflectAsCanceledOrder. */
  reflectAsCanceledOrderResult?:
    | ReflectAsCanceledOrderResult
    | undefined;
  /** Result of CancelCompoundOrderTreeRequest. */
  cancelCompoundOrderTreeResult?:
    | CancelCompoundOrderTreeResult
    | undefined;
  /** Result of a BatchOrdersOperation operation. */
  batchOrdersOperationResult?: BatchOrdersOperationResult | undefined;
}

/** Order details request result message. */
export interface OrderDetailsResult {
  /** Trade routing order chain. */
  orderChain:
    | OrderChain
    | undefined;
  /** This flag is set to true if all order requests in the chain are sent. */
  isComplete?: boolean | undefined;
}

/** Add fill result message. */
export interface AddFillResult {
}

/** Create order result message. */
export interface CreateExternalOrderResult {
  /** Chain order ID of created order. */
  orderId?:
    | string
    | undefined;
  /** Trade location of newly created order. */
  tradeLocationId?: string | undefined;
}

/** Order search request message. */
export interface OrderSearchResult {
  /** List of found orders. */
  orderRecord: OrderSearchResultRecord[];
  /**
   * This flag is set to true if all search results are sent
   * and nothing was filtered out by 'top' parameter.
   */
  isComplete?: boolean | undefined;
}

/** Result of RelatedOrderTreeRequest. */
export interface RelatedOrderTreeResult {
  /** Root order with children. */
  relatedOrder?:
    | RelatedOrder
    | undefined;
  /** This flag is set to true if all orders in the tree are sent. */
  isComplete?: boolean | undefined;
}

/** Result of CompoundOrderTreeRequest. */
export interface CompoundOrderTreeResult {
  /** Root compound order with children. */
  compoundOrder?:
    | CompoundOrder
    | undefined;
  /** This flag is set to true if all orders in the tree are sent. */
  isComplete?: boolean | undefined;
}

/** Result of SyntheticStrategyTreeRequest. */
export interface SyntheticStrategyTreeResult {
  /** First level strategy order legs. */
  legs: StrategyLeg[];
  /** Strategy contract name. */
  strategyName?: string | undefined;
}

/** Result of CancelOrder. */
export interface CancelOrderResult {
}

/** Result of ReflectAsCanceledOrder */
export interface ReflectAsCanceledOrderResult {
}

/** Result of CancelCompoundOrderTreeRequest. */
export interface CancelCompoundOrderTreeResult {
}

/** Account information request result message. */
export interface AccountInfoResult {
  /** Trade routing account. */
  account: Account | undefined;
}

/** Balance records request result message. */
export interface BalanceRecordsResult {
  /** Balance records. */
  balanceRecord: BalanceRecord[];
}

/** Create balance record result message. */
export interface CreateBalanceRecordResult {
  /** Created account balance record id. */
  balanceRecordId: number;
}

/** Update balance record result message. */
export interface UpdateBalanceRecordResult {
}

/** Account search result message. */
export interface AccountSearchResult {
  /** List of found accounts. */
  accountRecord: AccountSearchResultRecord[];
  /**
   * This flag is set to true if all results are sent
   * and nothing was filtered out by 'top' parameter.
   */
  isComplete?: boolean | undefined;
}

/** Account routes result message. */
export interface AccountRouteListResult {
  /** List of enabled account routes. */
  accountRouteRecord: AccountRouteRecord[];
}

/** Account to user authorization list result message. */
export interface AccountUserAuthorizationListResult {
  /** list of account to user authorization links. */
  accountUserLink: AccountUserLink[];
  /**
   * This flag is set to true if all results are sent
   * and nothing was filtered out by 'top' parameter.
   */
  isComplete?: boolean | undefined;
}

/** Account settings result message. */
export interface AccountSettingsResult {
  /** Account settings. */
  accountSettings: AccountSettings | undefined;
}

/** Account risk parameters result message. */
export interface AccountRiskParametersResult {
  /** Account risk parameters. */
  accountRiskParameters?: RiskParameters | undefined;
}

/** Update account's risk parameters result message. */
export interface UpdateRiskParametersResult {
}

/** Account exchange groups result message. */
export interface AccountExchangeGroupsResult {
  /** List of exchange groups available for the account. */
  exchangeGroup: ExchangeGroup[];
  /** Dictionary of instrument types that are used in the exchange group's commodities above. */
  instrumentType: InstrumentType[];
}

/** Account market limits result message. */
export interface AccountMarketLimitsResult {
  accountMarketLimits: MarketLimits | undefined;
}

/** Account positions result message. */
export interface AccountPositionsResult {
  /**
   * Account per contract positions.
   * If some contract has both long and short positions then there are two separate records for
   * this contract.
   */
  contractPosition: ContractPosition[];
}

/** Account equity result message. */
export interface AccountEquityResult {
  /** Account equity. */
  equity: Equity | undefined;
}

/** Account collateral result message. */
export interface AccountCollateralResult {
  /** Account collateral. */
  collateral: Collateral | undefined;
}

/** Clone account result message. */
export interface CloneAccountResult {
  /** ID of the newly created account. */
  newAccountId: number;
}

/** Result of account general information update. */
export interface UpdateAccountResult {
}

/** Result of account settings update. */
export interface UpdateAccountSettingsResult {
}

/** Result of AccountGroupRequest. */
export interface AccountGroupResult {
  /** If provided account is not related to any group then empty. */
  acountGroup?: AccountGroup | undefined;
}

/** Result of UpdateAccountRiskParameters. */
export interface UpdateAccountRiskParametersResult {
}

/** Result of AccountAvailableRouteListRequest. */
export interface AccountAvailableRouteListResult {
  /** List of available routes. */
  routes: AvailableRoute[];
}

/** Result of UpdateAccountRouteList. */
export interface UpdateAccountRouteListResult {
}

/** Result of UpdateAccountMarketLimits. */
export interface UpdateAccountMarketLimitsResult {
}

/** Result of CreateAccount. */
export interface CreateAccountResult {
  /** Id of newly created account, if succeeded. */
  id?: string | undefined;
}

/** [obsolete] Result of account cluster request. */
export interface AccountClusterResult {
  /**
   * Account cluster.
   *
   * @deprecated
   */
  obsoleteAccountCluster?:
    | AccountCluster
    | undefined;
  /**
   * Account cluster price offsets.
   *
   * @deprecated
   */
  obsoleteAccountClusterPriceOffsets: AccountClusterPriceOffset[];
}

/** [obsolete] Result of account cluster creation. */
export interface CreateAccountClusterResult {
  /** Id of newly created account cluster if succeeded. */
  id?: string | undefined;
}

/** [obsolete] Result of account cluster update. */
export interface UpdateAccountClusterResult {
}

/** [obsolete] Result of account cluster price offsets update. */
export interface UpdateAccountClusterPriceOffsetsResult {
}

/**
 * Trade routing order chain.
 * Order chain attributes reflect current order chain status.
 */
export interface OrderChain {
  /** Identifier of an order chain (it is equal to the order_id of the first request in the chain). */
  chainOrderId: string;
  /**
   * Order identifier of the last not rejected order request (except rejected initial place request)
   * which defines the current status of the chain.
   */
  orderId: string;
  /** Account ID that was used to create the chain. */
  chainOrigAccountId: number;
  /** Time when order was submitted to execution system (UTC). */
  submittedUtcTime: number;
  /** Full contract symbol. */
  contractSymbol: string;
  /**
   * Order side.
   * This field is associated with OrderChain.Side enum type.
   */
  side: number;
  /**
   * [obsolete] Use size field instead.
   *
   * @deprecated
   */
  obsoleteSize?:
    | number
    | undefined;
  /**
   * Order limit price.
   * Correct price format.
   */
  limitPrice?:
    | number
    | undefined;
  /**
   * Order stop price.
   * Correct price format.
   */
  stopPrice?:
    | number
    | undefined;
  /**
   * List of execution instructions.
   * This field is associated with OrderChain.ExecInstruction enum type.
   */
  execInstruction: number[];
  /**
   * Order duration.
   * This field is associated with OrderChain.Duration enum type.
   */
  duration: number;
  /** Date for GTD (local to exchange, date only value in time format) when the order should expire. */
  goodThruDate?:
    | number
    | undefined;
  /** Date and time for GTT order (UTC) when the order should expire. */
  goodThruUtcTime?:
    | number
    | undefined;
  /**
   * Order status.
   * This field is associated with OrderChain.Status enum type.
   */
  status: number;
  /**
   * Order type.
   * This field is associated with OrderChain.OrderType enum type.
   */
  orderType: number;
  /** List of order requests in the chain. */
  orderRequest: OrderRequest[];
  /** Order fills. */
  orderFill: OrderFill[];
  /** The values are associated with a lookup property with ORDER_CLASS type. */
  orderClasses: string[];
  /**
   * Order limit price.
   * Display price format.
   */
  displayLimitPrice?:
    | number
    | undefined;
  /**
   * Order stop price.
   * Display price format.
   */
  displayStopPrice?:
    | number
    | undefined;
  /** Account name provided to exchange. */
  executionAccountName?:
    | string
    | undefined;
  /** Trailing specific information. */
  trailing?:
    | OrderTrailing
    | undefined;
  /** Specific order sizes. */
  sizes?:
    | OrderSizes
    | undefined;
  /** Full list of accounts towards exchange. */
  accounts: ChainAccount[];
  /** Associated compound orders tree identifier. */
  compoundTreeId?:
    | string
    | undefined;
  /** In case of some synthetics. */
  rootChainOrderId?:
    | string
    | undefined;
  /** Order can be checked for any reason. */
  checked?:
    | boolean
    | undefined;
  /** Strategy trading expression. */
  tradingExpression?:
    | string
    | undefined;
  /** Order's contract legs */
  legs: OrderContractLeg[];
  /**
   * Full contract description.
   * Formula in case of strategy.
   */
  contractDescription?:
    | string
    | undefined;
  /**
   * Additional user defined attributes.
   * First - name, second - value.
   */
  userAttributes: Tuple[];
  /** Client-side determined algo strategy. */
  clientAlgoStrategy?:
    | string
    | undefined;
  /** Gateway-side algo strategy. */
  algoStrategy?:
    | string
    | undefined;
  /** Actual order route. */
  route?:
    | OrderRoute
    | undefined;
  /** Order chain expiration time. */
  expirationTime?:
    | number
    | undefined;
  /**
   * Order open/close type.
   * This field is associated with OrderChain.OpenCloseType enum type.
   */
  openCloseType?:
    | number
    | undefined;
  /**
   * Order speculation type.
   * This field is associated with OrderChain.SpeculationType enum type.
   */
  speculationType?:
    | number
    | undefined;
  /** Archived order. */
  archived?:
    | boolean
    | undefined;
  /** Current order request at exchange. */
  orderIdAtExchange?:
    | string
    | undefined;
  /**
   * [obsolete] This operation is no longer supported
   *
   * @deprecated
   */
  obsoleteDiscretionaryOffset?:
    | number
    | undefined;
  /** Total positive size of the order. */
  size?:
    | Decimal
    | undefined;
  /**
   * Order extended side.
   * This field is associated with ExtendedSide enum type.
   */
  extendedSide?:
    | number
    | undefined;
  /**
   * Identifies a brokerage that lended an equity being sold short.
   * (max length = 64).
   */
  locateId?:
    | string
    | undefined;
  /**
   * Exchange extra values.
   * First - name, second - value.
   */
  exchangeExtraValues: Tuple[];
  /** Flag for ignore order chain risk calculation */
  isExcludedFromRiskCalculation?: boolean | undefined;
}

/** Order side enum. */
export enum OrderChain_Side {
  /** BUY - Describes purchasing side of a trade. */
  BUY = 1,
  /** SELL - Describes selling side of a trade. */
  SELL = 2,
  UNRECOGNIZED = -1,
}

export function orderChain_SideFromJSON(object: any): OrderChain_Side {
  switch (object) {
    case 1:
    case "BUY":
      return OrderChain_Side.BUY;
    case 2:
    case "SELL":
      return OrderChain_Side.SELL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderChain_Side.UNRECOGNIZED;
  }
}

export function orderChain_SideToJSON(object: OrderChain_Side): string {
  switch (object) {
    case OrderChain_Side.BUY:
      return "BUY";
    case OrderChain_Side.SELL:
      return "SELL";
    case OrderChain_Side.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Order type enum. */
export enum OrderChain_OrderType {
  /** MKT - Market order, buy or sell at the best available opposite price. */
  MKT = 1,
  /** LMT - Limit order, buy or sell at price that is the same or better than specified limit price. */
  LMT = 2,
  /**
   * STP - Stop order, order becomes a market order when market reaches order's stop price.
   * (which is on opposite side of market).
   */
  STP = 3,
  /** STL - Stop-limit order, order becomes a limit order when market reaches order's stop price. */
  STL = 4,
  /**
   * CROSS - Cross order, prearranged trades with either a counterparty within the trading firm itself
   * or with a specified counterparty outside the firm. These orders are executed on exchange.
   */
  CROSS = 5,
  UNRECOGNIZED = -1,
}

export function orderChain_OrderTypeFromJSON(object: any): OrderChain_OrderType {
  switch (object) {
    case 1:
    case "MKT":
      return OrderChain_OrderType.MKT;
    case 2:
    case "LMT":
      return OrderChain_OrderType.LMT;
    case 3:
    case "STP":
      return OrderChain_OrderType.STP;
    case 4:
    case "STL":
      return OrderChain_OrderType.STL;
    case 5:
    case "CROSS":
      return OrderChain_OrderType.CROSS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderChain_OrderType.UNRECOGNIZED;
  }
}

export function orderChain_OrderTypeToJSON(object: OrderChain_OrderType): string {
  switch (object) {
    case OrderChain_OrderType.MKT:
      return "MKT";
    case OrderChain_OrderType.LMT:
      return "LMT";
    case OrderChain_OrderType.STP:
      return "STP";
    case OrderChain_OrderType.STL:
      return "STL";
    case OrderChain_OrderType.CROSS:
      return "CROSS";
    case OrderChain_OrderType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Order execution instruction. */
export enum OrderChain_ExecInstruction {
  /** AON - All or None (fill order only completely). */
  AON = 1,
  /** ICEBERG - Iceberg (show only part of order size). */
  ICEBERG = 2,
  /** QT - Quantity triggered (aka DOM Triggered, honor additional quantity threshold for triggering). */
  QT = 3,
  /** TRAIL - Trailing order (price of the order is following market one direction by specific offset). */
  TRAIL = 4,
  /** FUNARI - Funari (Limit order becomes a Market on Close). */
  FUNARI = 5,
  /** MIT - Market if Touched (Limit order becomes a Market when market reaches order's limit price). */
  MIT = 6,
  /**
   * MLM - Market Limit Market is a limit order that is used to place a buy order above the best offer
   * to fill by the best offer or a sell order below the best bid to fill by the best bid.
   */
  MLM = 7,
  /**
   * MTL - Market with Leftover as Limit
   * (market order then unexecuted quantity becomes limit order at last price).
   */
  MTL = 8,
  /**
   * AUCTION - An auction order is an order to buy or sell in the market at the Calculated Opening Price (COP)
   * (Unmatched auction orders are converted to limit orders on the market open).
   */
  AUCTION = 9,
  /** POSTONLY - Post Only Orders are only accepted as passive limit orders. */
  POSTONLY = 10,
  /** OBSOLETE_DISCRETION - [obsolete] This execution instruction is no longer supported. */
  OBSOLETE_DISCRETION = 11,
  /** AT_ANY_PRICE - At Any Price Orders are US-Style Market Orders. */
  AT_ANY_PRICE = 12,
  /** LIMIT_PREARRANGED_TRANSACTION - Prearranged Limit Order. */
  LIMIT_PREARRANGED_TRANSACTION = 13,
  /** NNE_CROSS - Nasdaq Nordic Equities On-Exchange Trade Reporting. */
  NNE_CROSS = 14,
  /** NNE_APA - Nasdaq Nordic Equities Off-Exchange (APA) Trade Reporting. */
  NNE_APA = 15,
  /** NND_CROSSO - Nasdaq Nordic Derivatives On-Exchange Outright Trade Reporting. */
  NND_CROSSO = 16,
  /** NND_CROSSC - Nasdaq Nordic Derivatives On-Exchange Combination Trade Reporting. */
  NND_CROSSC = 17,
  /** ICO - Internal Cross Only (This order type is used by OTC to pull up to order size quantity from the exchange on the opposite side). */
  ICO = 18,
  UNRECOGNIZED = -1,
}

export function orderChain_ExecInstructionFromJSON(object: any): OrderChain_ExecInstruction {
  switch (object) {
    case 1:
    case "AON":
      return OrderChain_ExecInstruction.AON;
    case 2:
    case "ICEBERG":
      return OrderChain_ExecInstruction.ICEBERG;
    case 3:
    case "QT":
      return OrderChain_ExecInstruction.QT;
    case 4:
    case "TRAIL":
      return OrderChain_ExecInstruction.TRAIL;
    case 5:
    case "FUNARI":
      return OrderChain_ExecInstruction.FUNARI;
    case 6:
    case "MIT":
      return OrderChain_ExecInstruction.MIT;
    case 7:
    case "MLM":
      return OrderChain_ExecInstruction.MLM;
    case 8:
    case "MTL":
      return OrderChain_ExecInstruction.MTL;
    case 9:
    case "AUCTION":
      return OrderChain_ExecInstruction.AUCTION;
    case 10:
    case "POSTONLY":
      return OrderChain_ExecInstruction.POSTONLY;
    case 11:
    case "OBSOLETE_DISCRETION":
      return OrderChain_ExecInstruction.OBSOLETE_DISCRETION;
    case 12:
    case "AT_ANY_PRICE":
      return OrderChain_ExecInstruction.AT_ANY_PRICE;
    case 13:
    case "LIMIT_PREARRANGED_TRANSACTION":
      return OrderChain_ExecInstruction.LIMIT_PREARRANGED_TRANSACTION;
    case 14:
    case "NNE_CROSS":
      return OrderChain_ExecInstruction.NNE_CROSS;
    case 15:
    case "NNE_APA":
      return OrderChain_ExecInstruction.NNE_APA;
    case 16:
    case "NND_CROSSO":
      return OrderChain_ExecInstruction.NND_CROSSO;
    case 17:
    case "NND_CROSSC":
      return OrderChain_ExecInstruction.NND_CROSSC;
    case 18:
    case "ICO":
      return OrderChain_ExecInstruction.ICO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderChain_ExecInstruction.UNRECOGNIZED;
  }
}

export function orderChain_ExecInstructionToJSON(object: OrderChain_ExecInstruction): string {
  switch (object) {
    case OrderChain_ExecInstruction.AON:
      return "AON";
    case OrderChain_ExecInstruction.ICEBERG:
      return "ICEBERG";
    case OrderChain_ExecInstruction.QT:
      return "QT";
    case OrderChain_ExecInstruction.TRAIL:
      return "TRAIL";
    case OrderChain_ExecInstruction.FUNARI:
      return "FUNARI";
    case OrderChain_ExecInstruction.MIT:
      return "MIT";
    case OrderChain_ExecInstruction.MLM:
      return "MLM";
    case OrderChain_ExecInstruction.MTL:
      return "MTL";
    case OrderChain_ExecInstruction.AUCTION:
      return "AUCTION";
    case OrderChain_ExecInstruction.POSTONLY:
      return "POSTONLY";
    case OrderChain_ExecInstruction.OBSOLETE_DISCRETION:
      return "OBSOLETE_DISCRETION";
    case OrderChain_ExecInstruction.AT_ANY_PRICE:
      return "AT_ANY_PRICE";
    case OrderChain_ExecInstruction.LIMIT_PREARRANGED_TRANSACTION:
      return "LIMIT_PREARRANGED_TRANSACTION";
    case OrderChain_ExecInstruction.NNE_CROSS:
      return "NNE_CROSS";
    case OrderChain_ExecInstruction.NNE_APA:
      return "NNE_APA";
    case OrderChain_ExecInstruction.NND_CROSSO:
      return "NND_CROSSO";
    case OrderChain_ExecInstruction.NND_CROSSC:
      return "NND_CROSSC";
    case OrderChain_ExecInstruction.ICO:
      return "ICO";
    case OrderChain_ExecInstruction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Order duration. */
export enum OrderChain_Duration {
  /** DAY - Day order. Order is working through the current trading day only. */
  DAY = 1,
  /** GTC - Good Til Canceled. Order is working until canceled or until the contract is no longer available for trading. */
  GTC = 2,
  /**
   * GTD - Good Til Date. Order is working until the end of the nearest trading day for the contract on
   * or before the date specified in the order.
   */
  GTD = 3,
  /** GTT - Good Til Time. Order is working until the specified time. */
  GTT = 4,
  /** FAK - Fill and Kill. Immediately fill as many as possible and cancel the rest. */
  FAK = 5,
  /** FOK - Fill Or Kill. Immediately fill this order completely or cancel. */
  FOK = 6,
  /** ATO - At The Open. Buy or sell at the very beginning of the trading day. */
  ATO = 7,
  /** ATC - At The Close. Buy or sell at the close of the market, or as near to the closing price as possible. */
  ATC = 8,
  UNRECOGNIZED = -1,
}

export function orderChain_DurationFromJSON(object: any): OrderChain_Duration {
  switch (object) {
    case 1:
    case "DAY":
      return OrderChain_Duration.DAY;
    case 2:
    case "GTC":
      return OrderChain_Duration.GTC;
    case 3:
    case "GTD":
      return OrderChain_Duration.GTD;
    case 4:
    case "GTT":
      return OrderChain_Duration.GTT;
    case 5:
    case "FAK":
      return OrderChain_Duration.FAK;
    case 6:
    case "FOK":
      return OrderChain_Duration.FOK;
    case 7:
    case "ATO":
      return OrderChain_Duration.ATO;
    case 8:
    case "ATC":
      return OrderChain_Duration.ATC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderChain_Duration.UNRECOGNIZED;
  }
}

export function orderChain_DurationToJSON(object: OrderChain_Duration): string {
  switch (object) {
    case OrderChain_Duration.DAY:
      return "DAY";
    case OrderChain_Duration.GTC:
      return "GTC";
    case OrderChain_Duration.GTD:
      return "GTD";
    case OrderChain_Duration.GTT:
      return "GTT";
    case OrderChain_Duration.FAK:
      return "FAK";
    case OrderChain_Duration.FOK:
      return "FOK";
    case OrderChain_Duration.ATO:
      return "ATO";
    case OrderChain_Duration.ATC:
      return "ATC";
    case OrderChain_Duration.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Current order chain status. */
export enum OrderChain_Status {
  /** UNDEFINED - This status is used for internal Gateway transactions. */
  UNDEFINED = 0,
  /** IN_TRANSIT - Original order is sent to execution system. */
  IN_TRANSIT = 1,
  /** REJECTED - Order is rejected. */
  REJECTED = 2,
  /** WORKING - Order is acknowledged by execution and perhaps partially filled. */
  WORKING = 3,
  /** EXPIRED - Order is expired. */
  EXPIRED = 4,
  /** IN_CANCEL - Cancel request is sent to execution system. */
  IN_CANCEL = 5,
  /** IN_MODIFY - Modify request is sent to execution system. */
  IN_MODIFY = 6,
  /** CANCELLED - Order is canceled. */
  CANCELLED = 7,
  /** FILLED - Order is completely filled by execution system. */
  FILLED = 8,
  /** SUSPENDED - Order is waiting submission to execution system. */
  SUSPENDED = 9,
  /** DISCONNECTED - Order may be canceled because a disconnection occurred. */
  DISCONNECTED = 10,
  /** ACTIVE_AT - Order will be placed at a specified time (waiting for execution system to start accepting orders). */
  ACTIVE_AT = 11,
  /** APPROVE_REQUIRED - Cross order sent to exchange and waiting approval from exchange and/or counterparties. */
  APPROVE_REQUIRED = 12,
  /** APPROVED - Cross order is approved by exchange and counterparties. */
  APPROVED = 13,
  /** APPROVE_REJECTED - Cross order is rejected by one of counterparties. */
  APPROVE_REJECTED = 14,
  /** MATCHED - Cross order is completely executed on exchange. It is the final state of cross order. */
  MATCHED = 15,
  /**
   * PART_MATCHED - Cross order/leg is executed on exchange,
   * this is transit state until all transactions for trades/breaks are received.
   */
  PART_MATCHED = 16,
  /**
   * TRADE_BROKEN - Cross order was executed on exchange, but now
   * all its trades/legs have been broken by the exchange.
   */
  TRADE_BROKEN = 17,
  UNRECOGNIZED = -1,
}

export function orderChain_StatusFromJSON(object: any): OrderChain_Status {
  switch (object) {
    case 0:
    case "UNDEFINED":
      return OrderChain_Status.UNDEFINED;
    case 1:
    case "IN_TRANSIT":
      return OrderChain_Status.IN_TRANSIT;
    case 2:
    case "REJECTED":
      return OrderChain_Status.REJECTED;
    case 3:
    case "WORKING":
      return OrderChain_Status.WORKING;
    case 4:
    case "EXPIRED":
      return OrderChain_Status.EXPIRED;
    case 5:
    case "IN_CANCEL":
      return OrderChain_Status.IN_CANCEL;
    case 6:
    case "IN_MODIFY":
      return OrderChain_Status.IN_MODIFY;
    case 7:
    case "CANCELLED":
      return OrderChain_Status.CANCELLED;
    case 8:
    case "FILLED":
      return OrderChain_Status.FILLED;
    case 9:
    case "SUSPENDED":
      return OrderChain_Status.SUSPENDED;
    case 10:
    case "DISCONNECTED":
      return OrderChain_Status.DISCONNECTED;
    case 11:
    case "ACTIVE_AT":
      return OrderChain_Status.ACTIVE_AT;
    case 12:
    case "APPROVE_REQUIRED":
      return OrderChain_Status.APPROVE_REQUIRED;
    case 13:
    case "APPROVED":
      return OrderChain_Status.APPROVED;
    case 14:
    case "APPROVE_REJECTED":
      return OrderChain_Status.APPROVE_REJECTED;
    case 15:
    case "MATCHED":
      return OrderChain_Status.MATCHED;
    case 16:
    case "PART_MATCHED":
      return OrderChain_Status.PART_MATCHED;
    case 17:
    case "TRADE_BROKEN":
      return OrderChain_Status.TRADE_BROKEN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderChain_Status.UNRECOGNIZED;
  }
}

export function orderChain_StatusToJSON(object: OrderChain_Status): string {
  switch (object) {
    case OrderChain_Status.UNDEFINED:
      return "UNDEFINED";
    case OrderChain_Status.IN_TRANSIT:
      return "IN_TRANSIT";
    case OrderChain_Status.REJECTED:
      return "REJECTED";
    case OrderChain_Status.WORKING:
      return "WORKING";
    case OrderChain_Status.EXPIRED:
      return "EXPIRED";
    case OrderChain_Status.IN_CANCEL:
      return "IN_CANCEL";
    case OrderChain_Status.IN_MODIFY:
      return "IN_MODIFY";
    case OrderChain_Status.CANCELLED:
      return "CANCELLED";
    case OrderChain_Status.FILLED:
      return "FILLED";
    case OrderChain_Status.SUSPENDED:
      return "SUSPENDED";
    case OrderChain_Status.DISCONNECTED:
      return "DISCONNECTED";
    case OrderChain_Status.ACTIVE_AT:
      return "ACTIVE_AT";
    case OrderChain_Status.APPROVE_REQUIRED:
      return "APPROVE_REQUIRED";
    case OrderChain_Status.APPROVED:
      return "APPROVED";
    case OrderChain_Status.APPROVE_REJECTED:
      return "APPROVE_REJECTED";
    case OrderChain_Status.MATCHED:
      return "MATCHED";
    case OrderChain_Status.PART_MATCHED:
      return "PART_MATCHED";
    case OrderChain_Status.TRADE_BROKEN:
      return "TRADE_BROKEN";
    case OrderChain_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Order or fill open/close type enum. */
export enum OrderChain_OpenCloseType {
  /** OPEN - Opening new current day position. */
  OPEN = 1,
  /** CLOSE - Closing (current day only if instrument uses explicit close). */
  CLOSE = 2,
  /** CLOSE_PREVIOUS - Closing previous day (if instrument uses explicit close). */
  CLOSE_PREVIOUS = 3,
  UNRECOGNIZED = -1,
}

export function orderChain_OpenCloseTypeFromJSON(object: any): OrderChain_OpenCloseType {
  switch (object) {
    case 1:
    case "OPEN":
      return OrderChain_OpenCloseType.OPEN;
    case 2:
    case "CLOSE":
      return OrderChain_OpenCloseType.CLOSE;
    case 3:
    case "CLOSE_PREVIOUS":
      return OrderChain_OpenCloseType.CLOSE_PREVIOUS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderChain_OpenCloseType.UNRECOGNIZED;
  }
}

export function orderChain_OpenCloseTypeToJSON(object: OrderChain_OpenCloseType): string {
  switch (object) {
    case OrderChain_OpenCloseType.OPEN:
      return "OPEN";
    case OrderChain_OpenCloseType.CLOSE:
      return "CLOSE";
    case OrderChain_OpenCloseType.CLOSE_PREVIOUS:
      return "CLOSE_PREVIOUS";
    case OrderChain_OpenCloseType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Order or fill speculation type enum. */
export enum OrderChain_SpeculationType {
  /** SPECULATION - Speculation. */
  SPECULATION = 1,
  /** HEDGE - Hedge. */
  HEDGE = 2,
  /** ARBITRAGE - Arbitrage. */
  ARBITRAGE = 3,
  UNRECOGNIZED = -1,
}

export function orderChain_SpeculationTypeFromJSON(object: any): OrderChain_SpeculationType {
  switch (object) {
    case 1:
    case "SPECULATION":
      return OrderChain_SpeculationType.SPECULATION;
    case 2:
    case "HEDGE":
      return OrderChain_SpeculationType.HEDGE;
    case 3:
    case "ARBITRAGE":
      return OrderChain_SpeculationType.ARBITRAGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderChain_SpeculationType.UNRECOGNIZED;
  }
}

export function orderChain_SpeculationTypeToJSON(object: OrderChain_SpeculationType): string {
  switch (object) {
    case OrderChain_SpeculationType.SPECULATION:
      return "SPECULATION";
    case OrderChain_SpeculationType.HEDGE:
      return "HEDGE";
    case OrderChain_SpeculationType.ARBITRAGE:
      return "ARBITRAGE";
    case OrderChain_SpeculationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Represents account in the chain of accounts. */
export interface ChainAccount {
  /** Account identifier. */
  accountId?:
    | string
    | undefined;
  /** Account name. */
  accountName?:
    | string
    | undefined;
  /** Brokerage account number. */
  brokerageAccountNumber?: string | undefined;
}

/** Represents route through which order is executed. */
export interface OrderRoute {
  /** Route unique code. */
  code?:
    | string
    | undefined;
  /** Route name. */
  name?:
    | string
    | undefined;
  /** Route order id. */
  orderId?: string | undefined;
}

/** Represents order's contract leg. */
export interface OrderContractLeg {
  /** Leg number. */
  number?:
    | number
    | undefined;
  /**
   * [obsolete] Use size field instead.
   *
   * @deprecated
   */
  obsoleteSize?:
    | number
    | undefined;
  /**
   * Order side.
   * This field is associated with OrderChain.Side enum type.
   */
  side?:
    | number
    | undefined;
  /** Full contract symbol. */
  contractSymbol?:
    | string
    | undefined;
  /** Total positive size of the leg. */
  size?:
    | Decimal
    | undefined;
  /**
   * Order extended side.
   * This field is associated with ExtendedSide enum type.
   */
  extendedSide?:
    | number
    | undefined;
  /**
   * Identifies a brokerage that lended an equity being sold short.
   * (max length = 64)
   */
  locateId?: string | undefined;
}

/** Account and user information associated with an order request. */
export interface AccountAndUserInfo {
  /** Trade routing account identifier. */
  accountId: number;
  /**
   * Associated user information. Contains either user_name that initiated the order request on
   * this account or Brokerage name of the next omnibus account in the chain.
   */
  userInfo: string;
  /** Reject details for rejected order request. */
  rejectDetails?:
    | string
    | undefined;
  /** Additional internal reject details for rejected order request. */
  rejectDetailsInternal?:
    | string
    | undefined;
  /** ID of a user who placed the order. */
  userId?:
    | string
    | undefined;
  /** User name. */
  userName?:
    | string
    | undefined;
  /** Account name. */
  accountName?:
    | string
    | undefined;
  /** Brokerage account number. */
  brokerageAccountNumber?: string | undefined;
}

/**
 * Trade routing order request.
 * Attributes of the order request (place, replace or cancel) allow to track order changes during the its lifetime.
 */
export interface OrderRequest {
  /** Order request identifier assigned by server. */
  orderId: string;
  /** Time when order request was submitted to execution system (UTC). */
  submittedUtcTime: number;
  /**
   * Type of the request.
   * This field is associated with OrderRequest.RequestType enum type.
   */
  requestType: number;
  /**
   * Order type.
   * This field is associated with OrderChain.OrderType enum type.
   */
  orderType?:
    | number
    | undefined;
  /**
   * [obsolete] Use size field instead.
   *
   * @deprecated
   */
  obsoleteSize?:
    | number
    | undefined;
  /**
   * Order limit price.
   * Correct price format.
   */
  limitPrice?:
    | number
    | undefined;
  /**
   * Order stop price.
   * Correct price format.
   */
  stopPrice?:
    | number
    | undefined;
  /**
   * Order duration.
   * This field is associated with OrderChain.Duration enum type.
   */
  duration?:
    | number
    | undefined;
  /** Date for GTD (local to exchange, date only value in time format) when the order should expire. */
  goodThruDate?:
    | number
    | undefined;
  /** Date and time for GTT order (UTC) when the order should expire. */
  goodThruUtcTime?:
    | number
    | undefined;
  /**
   * Current order request state.
   * This field is associated with OrderRequest.State enum type.
   */
  state: number;
  /** ID of order request that this request was applied to (for cancel and replace requests only). */
  linkOrderId?:
    | string
    | undefined;
  /**
   * List of [last] transactions.
   * All available transactions are sent.
   */
  transactionStatus: OrderTransactionStatus[];
  /** List of accounts associated with an order request with user information. */
  accountAndUserInfo: AccountAndUserInfo[];
  /**
   * Order limit price.
   * Display price format.
   */
  displayLimitPrice?:
    | number
    | undefined;
  /**
   * Order stop price.
   * Display price format.
   */
  displayStopPrice?:
    | number
    | undefined;
  /** Trailing specific information. */
  trailing?:
    | OrderTrailing
    | undefined;
  /** Specific order sizes. */
  sizes?:
    | OrderSizes
    | undefined;
  /** Ticket number. */
  ticketNumber?:
    | string
    | undefined;
  /** Request comments. */
  comments?:
    | string
    | undefined;
  /** The values are associated with a lookup property with ORDER_CLASS type. */
  orderClasses: string[];
  /** Native order type. */
  nativeOrderType?:
    | number
    | undefined;
  /** Activation time. */
  activationTime?:
    | number
    | undefined;
  /** Park time. */
  suspensionTime?:
    | number
    | undefined;
  /** Expiration time. */
  expirationTime?:
    | number
    | undefined;
  /** Routes. */
  routes: OrderRoute[];
  /** Client regulation algorithm. */
  clientRegulatoryAlgorithmId?:
    | string
    | undefined;
  /** Effective regulation algorithm. */
  effectiveRegulatoryAlgorithmId?:
    | string
    | undefined;
  /** Operational details. */
  operationalDetails?:
    | OperationalDetails
    | undefined;
  /** Specific instructions. */
  instructions?:
    | string
    | undefined;
  /**
   * Client extra values.
   * First - name, second - value.
   */
  clientExtraValues: Tuple[];
  /** Route client order ID. */
  routeClientOrderId?:
    | string
    | undefined;
  /**
   * [obsolete] This operation is no longer supported.
   *
   * @deprecated
   */
  obsoleteDiscretionaryOffset?:
    | number
    | undefined;
  /**
   * List of execution instructions.
   * This field is associated with OrderChain.ExecInstruction enum type.
   */
  execInstruction: number[];
  /** Positive size of the order request. */
  size?:
    | Decimal
    | undefined;
  /** Actual execution source code that is sent to an exchange. */
  executionSourceCode?:
    | string
    | undefined;
  /**
   * Order extended side.
   * This field is associated with ExtendedSide enum type.
   */
  extendedSide?:
    | number
    | undefined;
  /**
   * Identifies a brokerage that lended an equity being sold short.
   * (max length = 64)
   */
  locateId?: string | undefined;
}

/** Type of order request in order chain. */
export enum OrderRequest_RequestType {
  /** PLACE - New order place request. */
  PLACE = 1,
  /** REPLACE - Replace order request. */
  REPLACE = 2,
  /** CANCEL - Cancel order request. */
  CANCEL = 3,
  UNRECOGNIZED = -1,
}

export function orderRequest_RequestTypeFromJSON(object: any): OrderRequest_RequestType {
  switch (object) {
    case 1:
    case "PLACE":
      return OrderRequest_RequestType.PLACE;
    case 2:
    case "REPLACE":
      return OrderRequest_RequestType.REPLACE;
    case 3:
    case "CANCEL":
      return OrderRequest_RequestType.CANCEL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderRequest_RequestType.UNRECOGNIZED;
  }
}

export function orderRequest_RequestTypeToJSON(object: OrderRequest_RequestType): string {
  switch (object) {
    case OrderRequest_RequestType.PLACE:
      return "PLACE";
    case OrderRequest_RequestType.REPLACE:
      return "REPLACE";
    case OrderRequest_RequestType.CANCEL:
      return "CANCEL";
    case OrderRequest_RequestType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** State of order request. */
export enum OrderRequest_State {
  /** ACTIVE_AT - Order is waiting for trading session start time for activation. */
  ACTIVE_AT = 1,
  /** SUSPENDED - Order request is suspended. */
  SUSPENDED = 2,
  /** PENDING - Order request is sent to an execution system and is waiting for acknowledgment. */
  PENDING = 3,
  /** WORKING - Order request is working at an execution system. */
  WORKING = 4,
  /** REPLACED - Order request is replaced with a new one. */
  REPLACED = 5,
  /** CANCELLED - Order request is cancelled. */
  CANCELLED = 6,
  /** REJECTED - Order request is rejected. */
  REJECTED = 7,
  /** FILLED - Order request is filled. */
  FILLED = 8,
  /** EXPIRED - Order request is expired. */
  EXPIRED = 9,
  /** MATCHED - Order request is matched. */
  MATCHED = 10,
  UNRECOGNIZED = -1,
}

export function orderRequest_StateFromJSON(object: any): OrderRequest_State {
  switch (object) {
    case 1:
    case "ACTIVE_AT":
      return OrderRequest_State.ACTIVE_AT;
    case 2:
    case "SUSPENDED":
      return OrderRequest_State.SUSPENDED;
    case 3:
    case "PENDING":
      return OrderRequest_State.PENDING;
    case 4:
    case "WORKING":
      return OrderRequest_State.WORKING;
    case 5:
    case "REPLACED":
      return OrderRequest_State.REPLACED;
    case 6:
    case "CANCELLED":
      return OrderRequest_State.CANCELLED;
    case 7:
    case "REJECTED":
      return OrderRequest_State.REJECTED;
    case 8:
    case "FILLED":
      return OrderRequest_State.FILLED;
    case 9:
    case "EXPIRED":
      return OrderRequest_State.EXPIRED;
    case 10:
    case "MATCHED":
      return OrderRequest_State.MATCHED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderRequest_State.UNRECOGNIZED;
  }
}

export function orderRequest_StateToJSON(object: OrderRequest_State): string {
  switch (object) {
    case OrderRequest_State.ACTIVE_AT:
      return "ACTIVE_AT";
    case OrderRequest_State.SUSPENDED:
      return "SUSPENDED";
    case OrderRequest_State.PENDING:
      return "PENDING";
    case OrderRequest_State.WORKING:
      return "WORKING";
    case OrderRequest_State.REPLACED:
      return "REPLACED";
    case OrderRequest_State.CANCELLED:
      return "CANCELLED";
    case OrderRequest_State.REJECTED:
      return "REJECTED";
    case OrderRequest_State.FILLED:
      return "FILLED";
    case OrderRequest_State.EXPIRED:
      return "EXPIRED";
    case OrderRequest_State.MATCHED:
      return "MATCHED";
    case OrderRequest_State.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Represents order sizes. */
export interface OrderSizes {
  /**
   * [obsolete] Use trigger_size field instead.
   *
   * @deprecated
   */
  obsoleteTriggerSize?:
    | number
    | undefined;
  /**
   * [obsolete] Use visible_size field instead.
   *
   * @deprecated
   */
  obsoleteVisibleSize?:
    | number
    | undefined;
  /**
   * [obsolete] Use min_visible_size field instead.
   *
   * @deprecated
   */
  obsoleteMinVisibleSize?:
    | number
    | undefined;
  /**
   * [obsolete] Use remaining_size field instead.
   *
   * @deprecated
   */
  obsoleteRemainingSize?:
    | number
    | undefined;
  /** Trigger size. (DOM Threshold). */
  triggerSize?:
    | Decimal
    | undefined;
  /** Visible size. Iceberg. */
  visibleSize?:
    | Decimal
    | undefined;
  /** Minimum visible size. Iceberg. */
  minVisibleSize?:
    | Decimal
    | undefined;
  /** Remaining size of chain (set only for chain). */
  remainingSize?: Decimal | undefined;
}

/** Represents order trailing information. */
export interface OrderTrailing {
  /** This field is associated with TrailType enum type. */
  trailType?:
    | number
    | undefined;
  /**
   * Trail offset.
   * Correct format.
   */
  offset?:
    | number
    | undefined;
  /**
   * Trail stop price.
   * Correct format.
   */
  stopPrice?:
    | number
    | undefined;
  /**
   * Trail limit price.
   * Correct format.
   */
  limitPrice?:
    | number
    | undefined;
  /**
   * Trail offset.
   * Display format.
   */
  displayOffset?:
    | number
    | undefined;
  /**
   * Trail stop price.
   * Display format.
   */
  displayStopPrice?:
    | number
    | undefined;
  /**
   * Trail limit price.
   * Display format.
   */
  displayLimitPrice?:
    | number
    | undefined;
  /** Prices update time. */
  updateTime?: number | undefined;
}

/** Trailing type. */
export enum OrderTrailing_TrailType {
  BEST_BID = 1,
  BEST_ASK = 2,
  LAST_TRADE = 3,
  UNRECOGNIZED = -1,
}

export function orderTrailing_TrailTypeFromJSON(object: any): OrderTrailing_TrailType {
  switch (object) {
    case 1:
    case "BEST_BID":
      return OrderTrailing_TrailType.BEST_BID;
    case 2:
    case "BEST_ASK":
      return OrderTrailing_TrailType.BEST_ASK;
    case 3:
    case "LAST_TRADE":
      return OrderTrailing_TrailType.LAST_TRADE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderTrailing_TrailType.UNRECOGNIZED;
  }
}

export function orderTrailing_TrailTypeToJSON(object: OrderTrailing_TrailType): string {
  switch (object) {
    case OrderTrailing_TrailType.BEST_BID:
      return "BEST_BID";
    case OrderTrailing_TrailType.BEST_ASK:
      return "BEST_ASK";
    case OrderTrailing_TrailType.LAST_TRADE:
      return "LAST_TRADE";
    case OrderTrailing_TrailType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Operational details. */
export interface OperationalDetails {
  /** Client-side order id. */
  clientOrderId?:
    | string
    | undefined;
  /** Unique client guid. */
  clientGuid?:
    | string
    | undefined;
  /**
   * Client unique system id.
   * Hidden for Sale Series scope.
   */
  clientSystemId?:
    | string
    | undefined;
  /** Client ip address. */
  clientIp?:
    | string
    | undefined;
  /** Client application version. */
  clientVersion?:
    | string
    | undefined;
  /** Trade location. */
  tradeLocationId?:
    | string
    | undefined;
  /**
   * Product to which client application belongs.
   * Can be used to obtain IDs of CAT, PL and Platform.
   */
  productId?:
    | string
    | undefined;
  /** Client application name. */
  clientAppTypeName?:
    | string
    | undefined;
  /** Name of the platform to which client application belongs. */
  platformName?:
    | string
    | undefined;
  /** Client application private label name. */
  privateLabelName?:
    | string
    | undefined;
  /**
   * Identifies effective operator, used to identify specific message originator (desk, trader, etc.).
   * (max length = 64)
   */
  operatorId?:
    | string
    | undefined;
  /**
   * Identifies client-supplied operator, used to identify specific message originator (desk, trader, etc.).
   * (max length = 64)
   */
  senderOperatorId?:
    | string
    | undefined;
  /**
   * Identifies client-supplied location, used to identify specific message originator's location (i.e. geographic location and/or desk, trader).
   * (max length = 12)
   */
  senderLocationId?:
    | string
    | undefined;
  /**
   * [required] Identifies effective originator location.
   * (max length = 12)
   */
  originatorLocationId?: string | undefined;
}

/**
 * Status of a specific transaction.
 * All attributes are at the transaction moment.
 */
export interface OrderTransactionStatus {
  /** This field is associated with Status enum type. */
  status: number;
  /** Order transaction identifier, unique within order chain. */
  transId: number;
  /** Transaction time assigned by server (UTC). */
  transUtcTime: number;
  /**
   * Order request state at the transaction moment.
   * This field is associated with OrderRequest.State enum type.
   */
  orderState: number;
  /**
   * Order chain status at the transaction moment.
   * This field is associated with OrderChain.Status enum type.
   */
  orderChainStatus: number;
  /** Additional transaction comments. */
  textMessage?:
    | string
    | undefined;
  /**
   * [obsolete] This operation is no longer supported.
   *
   * @deprecated
   */
  obsoleteNewDiscretionaryOffset?:
    | number
    | undefined;
  /**
   * [obsolete] This operation is no longer supported.
   *
   * @deprecated
   */
  obsoletePrevDiscretionaryOffset?: number | undefined;
}

/** Status of the transaction. */
export enum OrderTransactionStatus_Status {
  /** UNDEFINED - This status is used for internal Gateway transactions. */
  UNDEFINED = 0,
  /** IN_TRANSIT - Original order is sent to execution system. */
  IN_TRANSIT = 1,
  /** REJECTED - Original order is rejected (exception). */
  REJECTED = 2,
  /** ACK_PLACE - Order is acknowledged by execution system. */
  ACK_PLACE = 3,
  /** EXPIRED - Order is expired (exception). */
  EXPIRED = 4,
  /** IN_CANCEL - Cancel request is sent to execution system. */
  IN_CANCEL = 5,
  /** ACK_CANCEL - Cancel is acknowledged by execution system. */
  ACK_CANCEL = 6,
  /** REJECT_CANCEL - Cancel request is rejected (exception). */
  REJECT_CANCEL = 7,
  /** IN_MODIFY - Modify request is sent to execution system. */
  IN_MODIFY = 8,
  /** ACK_MODIFY - Modify is acknowledged by execution system. */
  ACK_MODIFY = 9,
  /** REJECT_MODIFY - Modify request is rejected (exception). */
  REJECT_MODIFY = 10,
  /** FILL - Fill event is received from execution system. */
  FILL = 11,
  /** SUSPEND - Order is suspended. */
  SUSPEND = 12,
  /** FILL_CORRECT - Referenced fill is corrected. */
  FILL_CORRECT = 13,
  /** FILL_CANCEL - Referenced fill is canceled (exception). */
  FILL_CANCEL = 14,
  /** FILL_BUST - Referenced fill is busted (exception). */
  FILL_BUST = 15,
  /** ACTIVEAT - Acknowledgment that order will activate at specified time. */
  ACTIVEAT = 16,
  /** DISCONNECT - Order may be canceled because a disconnect occurred (exception). */
  DISCONNECT = 17,
  /** SYNTHETIC_ACTIVATED - Synthetic order was sent to the actual execution system. */
  SYNTHETIC_ACTIVATED = 18,
  /** UPDATE - Order status and/ or attributes are updated. */
  UPDATE = 19,
  /** SYNTHETIC_FAILED - Synthetic strategy execution failed. */
  SYNTHETIC_FAILED = 20,
  /** SYNTHETIC_OVERFILL - Synthetic strategy order has at least one overfilled leg. */
  SYNTHETIC_OVERFILL = 21,
  /** SYNTHETIC_HANG - Hanging quantity update for the synthetic strategy order. */
  SYNTHETIC_HANG = 22,
  /** APPROVING - Cross order is acknowledged by exchange but waiting for approval from counter-parties. */
  APPROVING = 23,
  /** APPROVE_REQUESTED - Cross order is waiting for approval from the client (i.e. from the side/participant that received this transaction). */
  APPROVE_REQUESTED = 24,
  /** APPROVED_BY_EXCHANGE - The exchange approved the cross order. */
  APPROVED_BY_EXCHANGE = 25,
  /** REJECTED_BY_USER - Cross order is rejected by one of its participants. */
  REJECTED_BY_USER = 26,
  /** MATCHED - Cross order is completely executed on exchange, this is the final state of the cross order. */
  MATCHED = 27,
  /** TRADE_AMENDED - Previously matched cross order/leg have been amended by the exchange. */
  TRADE_AMENDED = 28,
  /**
   * TRADE_BROKEN - Cross order has a previously executed trade/leg broken by exchange;
   * this is analogous to bust on a regular order.
   */
  TRADE_BROKEN = 29,
  UNRECOGNIZED = -1,
}

export function orderTransactionStatus_StatusFromJSON(object: any): OrderTransactionStatus_Status {
  switch (object) {
    case 0:
    case "UNDEFINED":
      return OrderTransactionStatus_Status.UNDEFINED;
    case 1:
    case "IN_TRANSIT":
      return OrderTransactionStatus_Status.IN_TRANSIT;
    case 2:
    case "REJECTED":
      return OrderTransactionStatus_Status.REJECTED;
    case 3:
    case "ACK_PLACE":
      return OrderTransactionStatus_Status.ACK_PLACE;
    case 4:
    case "EXPIRED":
      return OrderTransactionStatus_Status.EXPIRED;
    case 5:
    case "IN_CANCEL":
      return OrderTransactionStatus_Status.IN_CANCEL;
    case 6:
    case "ACK_CANCEL":
      return OrderTransactionStatus_Status.ACK_CANCEL;
    case 7:
    case "REJECT_CANCEL":
      return OrderTransactionStatus_Status.REJECT_CANCEL;
    case 8:
    case "IN_MODIFY":
      return OrderTransactionStatus_Status.IN_MODIFY;
    case 9:
    case "ACK_MODIFY":
      return OrderTransactionStatus_Status.ACK_MODIFY;
    case 10:
    case "REJECT_MODIFY":
      return OrderTransactionStatus_Status.REJECT_MODIFY;
    case 11:
    case "FILL":
      return OrderTransactionStatus_Status.FILL;
    case 12:
    case "SUSPEND":
      return OrderTransactionStatus_Status.SUSPEND;
    case 13:
    case "FILL_CORRECT":
      return OrderTransactionStatus_Status.FILL_CORRECT;
    case 14:
    case "FILL_CANCEL":
      return OrderTransactionStatus_Status.FILL_CANCEL;
    case 15:
    case "FILL_BUST":
      return OrderTransactionStatus_Status.FILL_BUST;
    case 16:
    case "ACTIVEAT":
      return OrderTransactionStatus_Status.ACTIVEAT;
    case 17:
    case "DISCONNECT":
      return OrderTransactionStatus_Status.DISCONNECT;
    case 18:
    case "SYNTHETIC_ACTIVATED":
      return OrderTransactionStatus_Status.SYNTHETIC_ACTIVATED;
    case 19:
    case "UPDATE":
      return OrderTransactionStatus_Status.UPDATE;
    case 20:
    case "SYNTHETIC_FAILED":
      return OrderTransactionStatus_Status.SYNTHETIC_FAILED;
    case 21:
    case "SYNTHETIC_OVERFILL":
      return OrderTransactionStatus_Status.SYNTHETIC_OVERFILL;
    case 22:
    case "SYNTHETIC_HANG":
      return OrderTransactionStatus_Status.SYNTHETIC_HANG;
    case 23:
    case "APPROVING":
      return OrderTransactionStatus_Status.APPROVING;
    case 24:
    case "APPROVE_REQUESTED":
      return OrderTransactionStatus_Status.APPROVE_REQUESTED;
    case 25:
    case "APPROVED_BY_EXCHANGE":
      return OrderTransactionStatus_Status.APPROVED_BY_EXCHANGE;
    case 26:
    case "REJECTED_BY_USER":
      return OrderTransactionStatus_Status.REJECTED_BY_USER;
    case 27:
    case "MATCHED":
      return OrderTransactionStatus_Status.MATCHED;
    case 28:
    case "TRADE_AMENDED":
      return OrderTransactionStatus_Status.TRADE_AMENDED;
    case 29:
    case "TRADE_BROKEN":
      return OrderTransactionStatus_Status.TRADE_BROKEN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderTransactionStatus_Status.UNRECOGNIZED;
  }
}

export function orderTransactionStatus_StatusToJSON(object: OrderTransactionStatus_Status): string {
  switch (object) {
    case OrderTransactionStatus_Status.UNDEFINED:
      return "UNDEFINED";
    case OrderTransactionStatus_Status.IN_TRANSIT:
      return "IN_TRANSIT";
    case OrderTransactionStatus_Status.REJECTED:
      return "REJECTED";
    case OrderTransactionStatus_Status.ACK_PLACE:
      return "ACK_PLACE";
    case OrderTransactionStatus_Status.EXPIRED:
      return "EXPIRED";
    case OrderTransactionStatus_Status.IN_CANCEL:
      return "IN_CANCEL";
    case OrderTransactionStatus_Status.ACK_CANCEL:
      return "ACK_CANCEL";
    case OrderTransactionStatus_Status.REJECT_CANCEL:
      return "REJECT_CANCEL";
    case OrderTransactionStatus_Status.IN_MODIFY:
      return "IN_MODIFY";
    case OrderTransactionStatus_Status.ACK_MODIFY:
      return "ACK_MODIFY";
    case OrderTransactionStatus_Status.REJECT_MODIFY:
      return "REJECT_MODIFY";
    case OrderTransactionStatus_Status.FILL:
      return "FILL";
    case OrderTransactionStatus_Status.SUSPEND:
      return "SUSPEND";
    case OrderTransactionStatus_Status.FILL_CORRECT:
      return "FILL_CORRECT";
    case OrderTransactionStatus_Status.FILL_CANCEL:
      return "FILL_CANCEL";
    case OrderTransactionStatus_Status.FILL_BUST:
      return "FILL_BUST";
    case OrderTransactionStatus_Status.ACTIVEAT:
      return "ACTIVEAT";
    case OrderTransactionStatus_Status.DISCONNECT:
      return "DISCONNECT";
    case OrderTransactionStatus_Status.SYNTHETIC_ACTIVATED:
      return "SYNTHETIC_ACTIVATED";
    case OrderTransactionStatus_Status.UPDATE:
      return "UPDATE";
    case OrderTransactionStatus_Status.SYNTHETIC_FAILED:
      return "SYNTHETIC_FAILED";
    case OrderTransactionStatus_Status.SYNTHETIC_OVERFILL:
      return "SYNTHETIC_OVERFILL";
    case OrderTransactionStatus_Status.SYNTHETIC_HANG:
      return "SYNTHETIC_HANG";
    case OrderTransactionStatus_Status.APPROVING:
      return "APPROVING";
    case OrderTransactionStatus_Status.APPROVE_REQUESTED:
      return "APPROVE_REQUESTED";
    case OrderTransactionStatus_Status.APPROVED_BY_EXCHANGE:
      return "APPROVED_BY_EXCHANGE";
    case OrderTransactionStatus_Status.REJECTED_BY_USER:
      return "REJECTED_BY_USER";
    case OrderTransactionStatus_Status.MATCHED:
      return "MATCHED";
    case OrderTransactionStatus_Status.TRADE_AMENDED:
      return "TRADE_AMENDED";
    case OrderTransactionStatus_Status.TRADE_BROKEN:
      return "TRADE_BROKEN";
    case OrderTransactionStatus_Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Gateway order fill. */
export interface OrderFill {
  /** Trade routing order fill unique identifier. */
  id?:
    | number
    | undefined;
  /** Trade routing order fill execution identifier. */
  execId?:
    | string
    | undefined;
  /**
   * [obsolete] Use quantity field instead.
   *
   * @deprecated
   */
  obsoleteQuantity?:
    | number
    | undefined;
  /**
   * Price at which the lots were bought or sold.
   * Correct price.
   */
  price?:
    | number
    | undefined;
  /** UTC time the fill happened (offset in milliseconds from 01.01.1970). */
  fillUtcTime?:
    | number
    | undefined;
  /** Flag that trade was aggressive (Fixed Income specific order modifier). */
  isAggressive?:
    | boolean
    | undefined;
  /** Leg fills associated with this order fill. */
  legFills: LegFill[];
  /**
   * Interesting to show.
   * Order request ID associated with the fill.
   */
  orderId?:
    | string
    | undefined;
  /**
   * Status of the fill.
   * The value is associated with FillStatus enum.
   */
  fillStatus?:
    | number
    | undefined;
  /** Associated fill care order request id. */
  fillCareOrderRequestId?:
    | string
    | undefined;
  /**
   * Price at which the lots were bought or sold.
   * Display price.
   */
  displayPrice?:
    | number
    | undefined;
  /**
   * Fill open/close type.
   * This field is associated with OrderChain.OpenCloseType enum type.
   */
  openCloseType?:
    | number
    | undefined;
  /**
   * Fill speculation type.
   * This field is associated with OrderChain.SpeculationType enum type.
   */
  speculationType?:
    | number
    | undefined;
  /** Commission value of the fill. */
  commission?:
    | number
    | undefined;
  /** Commission currency code (ISO 4217 based) of the fill. */
  commissionCurrency?:
    | string
    | undefined;
  /** Quantity of bought or sold lots depending on order side. */
  quantity?: Decimal | undefined;
}

/** Possible fill status. */
export enum OrderFill_FillStatus {
  /** VALID - Regular valid fill. */
  VALID = 1,
  /** BUSTED - Fill was busted. */
  BUSTED = 2,
  /** CORRECTED - Fill was corrected. */
  CORRECTED = 3,
  /** CANCELLED - Fill was cancelled. */
  CANCELLED = 4,
  UNRECOGNIZED = -1,
}

export function orderFill_FillStatusFromJSON(object: any): OrderFill_FillStatus {
  switch (object) {
    case 1:
    case "VALID":
      return OrderFill_FillStatus.VALID;
    case 2:
    case "BUSTED":
      return OrderFill_FillStatus.BUSTED;
    case 3:
    case "CORRECTED":
      return OrderFill_FillStatus.CORRECTED;
    case 4:
    case "CANCELLED":
      return OrderFill_FillStatus.CANCELLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderFill_FillStatus.UNRECOGNIZED;
  }
}

export function orderFill_FillStatusToJSON(object: OrderFill_FillStatus): string {
  switch (object) {
    case OrderFill_FillStatus.VALID:
      return "VALID";
    case OrderFill_FillStatus.BUSTED:
      return "BUSTED";
    case OrderFill_FillStatus.CORRECTED:
      return "CORRECTED";
    case OrderFill_FillStatus.CANCELLED:
      return "CANCELLED";
    case OrderFill_FillStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Trade routing fill leg. */
export interface LegFill {
  /** Trade routing leg fill unique identifier. */
  id?:
    | number
    | undefined;
  /** Trade routing leg fill execution identifier. */
  execId?:
    | string
    | undefined;
  /** Full contract symbol for this leg. */
  contractSymbol?:
    | string
    | undefined;
  /**
   * [obsolete] Use quantity field instead.
   *
   * @deprecated
   */
  obsoleteQuantity?:
    | number
    | undefined;
  /**
   * Leg side.
   * This field is associated with OrderChain.Side enum type.
   */
  side?:
    | number
    | undefined;
  /**
   * Price at which the lots were bought or sold.
   * Correct price.
   */
  price?:
    | number
    | undefined;
  /** UTC time the fill happened (offset in milliseconds from 01.01.1970). */
  fillUtcTime?:
    | number
    | undefined;
  /**
   * Status of the leg fill.
   * The value is associated with OrderFill.FillStatus enum.
   */
  fillStatus?:
    | number
    | undefined;
  /**
   * Price at which the lots were bought or sold.
   * Display price.
   */
  displayPrice?:
    | number
    | undefined;
  /**
   * Leg fill open/close type.
   * This field is associated with OrderChain.OpenCloseType enum type.
   */
  openCloseType?:
    | number
    | undefined;
  /**
   * Leg fill speculation type.
   * This field is associated with OrderChain.SpeculationType enum type.
   */
  speculationType?:
    | number
    | undefined;
  /** Flag that trade was aggressive (Fixed Income specific order modifier). */
  isAggressive?:
    | boolean
    | undefined;
  /** Quantity of bought or sold lots depending on leg side. */
  quantity?:
    | Decimal
    | undefined;
  /**
   * Order extended side.
   * This field is associated with ExtendedSide enum type.
   */
  extendedSide?:
    | number
    | undefined;
  /**
   * Identifies a brokerage that lended an equity being sold short.
   * (max length = 64)
   */
  locateId?:
    | string
    | undefined;
  /**
   * Fill extra values.
   * First - name, second - value.
   */
  fillExtraValues: Tuple[];
  /** Leg's number. */
  legNumber?: string | undefined;
}

/** Represents order with related child orders. */
export interface RelatedOrder {
  /** Associated Order chain. */
  orderChain?:
    | OrderChain
    | undefined;
  /** Child order chains. */
  children: RelatedOrder[];
  /**
   * Leg path.
   * Example: '1/2/'.
   */
  legPath?: string | undefined;
}

/** Represents compound order with child orders. */
export interface CompoundOrder {
  /** Compound order id. */
  id?:
    | string
    | undefined;
  /**
   * Compound role.
   * This field is associated with CompoundOrder.CompoundOrderNodeRole enum type.
   */
  nodeRole?:
    | number
    | undefined;
  /**
   * Compound type.
   * This field is associated with CompoundOrder.CompoundOrderType enum type.
   */
  type?:
    | number
    | undefined;
  /** Unique compound client guid. */
  clientGuid?:
    | string
    | undefined;
  /**
   * Trigger on market type.
   * This field is associated with CompoundOrder.CompoundOrderTriggerType enum type.
   */
  triggerType?:
    | number
    | undefined;
  /**
   * Associated order chain.
   * Required when compound order type is ORDER.
   */
  orderChain?:
    | OrderChain
    | undefined;
  /** Child compounds. */
  children: CompoundOrder[];
}

export enum CompoundOrder_CompoundOrderNodeRole {
  /** ROOT - Root of tree. */
  ROOT = 1,
  /** OPO_PRIMARY - Order place order primary. */
  OPO_PRIMARY = 2,
  /** OPO_SECONDARY - Order place order secondary. */
  OPO_SECONDARY = 3,
  /** OCO_ROLE - Order cancel order. */
  OCO_ROLE = 4,
  /** INDEPENDENT_ROLE - Independent compound. */
  INDEPENDENT_ROLE = 5,
  UNRECOGNIZED = -1,
}

export function compoundOrder_CompoundOrderNodeRoleFromJSON(object: any): CompoundOrder_CompoundOrderNodeRole {
  switch (object) {
    case 1:
    case "ROOT":
      return CompoundOrder_CompoundOrderNodeRole.ROOT;
    case 2:
    case "OPO_PRIMARY":
      return CompoundOrder_CompoundOrderNodeRole.OPO_PRIMARY;
    case 3:
    case "OPO_SECONDARY":
      return CompoundOrder_CompoundOrderNodeRole.OPO_SECONDARY;
    case 4:
    case "OCO_ROLE":
      return CompoundOrder_CompoundOrderNodeRole.OCO_ROLE;
    case 5:
    case "INDEPENDENT_ROLE":
      return CompoundOrder_CompoundOrderNodeRole.INDEPENDENT_ROLE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CompoundOrder_CompoundOrderNodeRole.UNRECOGNIZED;
  }
}

export function compoundOrder_CompoundOrderNodeRoleToJSON(object: CompoundOrder_CompoundOrderNodeRole): string {
  switch (object) {
    case CompoundOrder_CompoundOrderNodeRole.ROOT:
      return "ROOT";
    case CompoundOrder_CompoundOrderNodeRole.OPO_PRIMARY:
      return "OPO_PRIMARY";
    case CompoundOrder_CompoundOrderNodeRole.OPO_SECONDARY:
      return "OPO_SECONDARY";
    case CompoundOrder_CompoundOrderNodeRole.OCO_ROLE:
      return "OCO_ROLE";
    case CompoundOrder_CompoundOrderNodeRole.INDEPENDENT_ROLE:
      return "INDEPENDENT_ROLE";
    case CompoundOrder_CompoundOrderNodeRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum CompoundOrder_CompoundOrderType {
  /** OCO - Order cancel order. */
  OCO = 1,
  /** OPO - Order place order. */
  OPO = 2,
  /** ORDER - Order type. Compound order with ORDER type is associated with order chain. */
  ORDER = 3,
  /** INDEPENDENT - Independent compound relation as set of two or more of otherwise unrelated orders being placed in one action. */
  INDEPENDENT = 4,
  UNRECOGNIZED = -1,
}

export function compoundOrder_CompoundOrderTypeFromJSON(object: any): CompoundOrder_CompoundOrderType {
  switch (object) {
    case 1:
    case "OCO":
      return CompoundOrder_CompoundOrderType.OCO;
    case 2:
    case "OPO":
      return CompoundOrder_CompoundOrderType.OPO;
    case 3:
    case "ORDER":
      return CompoundOrder_CompoundOrderType.ORDER;
    case 4:
    case "INDEPENDENT":
      return CompoundOrder_CompoundOrderType.INDEPENDENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CompoundOrder_CompoundOrderType.UNRECOGNIZED;
  }
}

export function compoundOrder_CompoundOrderTypeToJSON(object: CompoundOrder_CompoundOrderType): string {
  switch (object) {
    case CompoundOrder_CompoundOrderType.OCO:
      return "OCO";
    case CompoundOrder_CompoundOrderType.OPO:
      return "OPO";
    case CompoundOrder_CompoundOrderType.ORDER:
      return "ORDER";
    case CompoundOrder_CompoundOrderType.INDEPENDENT:
      return "INDEPENDENT";
    case CompoundOrder_CompoundOrderType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum CompoundOrder_CompoundOrderTriggerType {
  /** Working - Default value for compound order. Indicates whether сompound order is working. */
  Working = 1,
  /** Filled - Indicates whether сompound order has been filled. */
  Filled = 2,
  /** Cancelled - Indicates whether сompound order has been cancelled. */
  Cancelled = 3,
  UNRECOGNIZED = -1,
}

export function compoundOrder_CompoundOrderTriggerTypeFromJSON(object: any): CompoundOrder_CompoundOrderTriggerType {
  switch (object) {
    case 1:
    case "Working":
      return CompoundOrder_CompoundOrderTriggerType.Working;
    case 2:
    case "Filled":
      return CompoundOrder_CompoundOrderTriggerType.Filled;
    case 3:
    case "Cancelled":
      return CompoundOrder_CompoundOrderTriggerType.Cancelled;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CompoundOrder_CompoundOrderTriggerType.UNRECOGNIZED;
  }
}

export function compoundOrder_CompoundOrderTriggerTypeToJSON(object: CompoundOrder_CompoundOrderTriggerType): string {
  switch (object) {
    case CompoundOrder_CompoundOrderTriggerType.Working:
      return "Working";
    case CompoundOrder_CompoundOrderTriggerType.Filled:
      return "Filled";
    case CompoundOrder_CompoundOrderTriggerType.Cancelled:
      return "Cancelled";
    case CompoundOrder_CompoundOrderTriggerType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Represents strategy leg with child legs. */
export interface StrategyLeg {
  /** Leg path. */
  legPath?:
    | string
    | undefined;
  /** Strategy contract. */
  contractSymbol?:
    | string
    | undefined;
  /**
   * [obsolete] Use quantity_ratio field instead.
   *
   * @deprecated
   */
  obsoleteQuantityRatio?:
    | number
    | undefined;
  /** Price ratio. */
  priceRatio?:
    | number
    | undefined;
  /** Associated with StrategyMode enum. */
  mode?:
    | number
    | undefined;
  /** Account. */
  account?:
    | ChainAccount
    | undefined;
  /**
   * Yield model.
   * First - key, second - value.
   */
  yieldModel: Tuple[];
  /** Child legs. */
  legs: StrategyLeg[];
  /**
   * Strategy leg open/close type.
   * This field is associated with OrderChain.OpenCloseType enum type.
   */
  openCloseType?:
    | number
    | undefined;
  /**
   * Strategy leg speculation type.
   * This field is associated with OrderChain.SpeculationType enum type.
   */
  speculationType?:
    | number
    | undefined;
  /** Quantity ratio. */
  quantityRatio?: Decimal | undefined;
}

export enum StrategyLeg_StrategyMode {
  /** ALL - All legs executed. */
  ALL = 1,
  /** AGGR - Aggregate legs. */
  AGGR = 2,
  /** MUL - Multiplicative strategy. */
  MUL = 3,
  UNRECOGNIZED = -1,
}

export function strategyLeg_StrategyModeFromJSON(object: any): StrategyLeg_StrategyMode {
  switch (object) {
    case 1:
    case "ALL":
      return StrategyLeg_StrategyMode.ALL;
    case 2:
    case "AGGR":
      return StrategyLeg_StrategyMode.AGGR;
    case 3:
    case "MUL":
      return StrategyLeg_StrategyMode.MUL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return StrategyLeg_StrategyMode.UNRECOGNIZED;
  }
}

export function strategyLeg_StrategyModeToJSON(object: StrategyLeg_StrategyMode): string {
  switch (object) {
    case StrategyLeg_StrategyMode.ALL:
      return "ALL";
    case StrategyLeg_StrategyMode.AGGR:
      return "AGGR";
    case StrategyLeg_StrategyMode.MUL:
      return "MUL";
    case StrategyLeg_StrategyMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Represents general information about CQG Gateway account. */
export interface Account {
  /** [required-update] Trade routing account identifier. */
  id?:
    | number
    | undefined;
  /**
   * [required-create] Account name (max length = 64).
   * Can contain prefix (like SIM for simalation account) which cannot be changed.
   */
  name?:
    | string
    | undefined;
  /**
   * [required-create] Brokerage account number (max length = 256).
   * Can contain prefix (like PS for permanent simulation) which cannot be changed.
   */
  brokerageAccountNumber?:
    | string
    | undefined;
  /**
   * [immutable][obsolete] Use sales_series_id field instead.
   * Sales series number.
   * Cannot be changed directly with UpdateAccount message, but can be changed by changing sales_series_id.
   *
   * @deprecated
   */
  obsoleteSalesSeriesNumber?:
    | string
    | undefined;
  /**
   * [immutable][obsolete] Use sales_series_id field instead.
   * Sales series name.
   * Cannot be changed directly with UpdateAccount message, but can be changed by changing sales_series_id.
   *
   * @deprecated
   */
  obsoleteSalesSeriesName?:
    | string
    | undefined;
  /**
   * [immutable][obsolete] Use brokerage_id field instead.
   * Brokerage name.
   *
   * @deprecated
   */
  obsoleteBrokerageName?:
    | string
    | undefined;
  /** [required-create][immutable-update] This field is associated with Class enum. */
  class?:
    | number
    | undefined;
  /**
   * Id of customer - owner of the account.
   * If changed then new customer must belong to the same brokerage.
   * Use profile_id instead.
   */
  customerId?:
    | string
    | undefined;
  /** [immutable] Risk server instance which calculates risks for this account (includes related Gateway location). */
  riskServerInstance?:
    | string
    | undefined;
  /**
   * [required-create] Type of the account (e.g. 'Customer Account', 'House Account', etc.)
   * List of available account types can be obtained with:
   * LookupPropertyListRequest { property_type = TradeRoutingLookupPropertyType.ACCOUNT_TYPE }
   */
  accountTypeId?:
    | string
    | undefined;
  /**
   * ID of related sales series.
   * If changed then new sales series must belong to the same brokerage.
   * Use profile_sales_series_id instead.
   */
  salesSeriesId?:
    | string
    | undefined;
  /** [immutable] ID of related brokerage. */
  brokerageId?:
    | string
    | undefined;
  /** [immutable] Default account currency code (ISO 4217). */
  currency?:
    | string
    | undefined;
  /**
   * This field is associated with SubClass enum.
   * Can be set/changed only for account with class = 3 (omnibus).
   */
  subClass?:
    | number
    | undefined;
  /**
   * [immutable-update] Risk server instance id.
   * If not set during CreateAccount message, default brokerage one is used.
   */
  riskServerInstanceId?:
    | string
    | undefined;
  /**
   * [obsolete] ID of account cluster the account points to.
   *
   * @deprecated
   */
  obsoleteAccountClusterId?:
    | string
    | undefined;
  /** [immutable] Determines, whether account is removed. */
  removed?:
    | boolean
    | undefined;
  /**
   * [immutable] Determines group role (master/sub-account)
   * in case when account belongs to a group.
   */
  isMaster?:
    | boolean
    | undefined;
  /**
   * [immutable] This field is associated with AccountGroup.RelationType enum.
   * Specifies group relation type in case when account belongs to a group.
   */
  groupRelationType?:
    | number
    | undefined;
  /** [immutable] Determines if account is collection. */
  isCollection?:
    | boolean
    | undefined;
  /**
   * [required-create] Id of profile - owner of the account.
   * If changed then new profile must belong to the same brokerage.
   * Has priority over customer_id.
   * Supported profile types: admin, customer.
   */
  profileId?:
    | string
    | undefined;
  /**
   * [required-create] ID of linked sales series profile.
   * If changed then new sales series profile must belong to the same brokerage.
   * Has priority over sales_series_id.
   */
  profileSalesSeriesId?:
    | string
    | undefined;
  /** [immutable] Determines if account has migration request(s) not in final state. */
  isInMigration?: boolean | undefined;
}

/** Account class enumeration. */
export enum Account_Class {
  /** REGULAR - Regular account class. */
  REGULAR = 1,
  /**
   * TEMPLATE - Template account class.
   * This class is used for back office parsing not for clonning.
   */
  TEMPLATE = 2,
  /** OMNIBUS - Omnibus account class. */
  OMNIBUS = 3,
  UNRECOGNIZED = -1,
}

export function account_ClassFromJSON(object: any): Account_Class {
  switch (object) {
    case 1:
    case "REGULAR":
      return Account_Class.REGULAR;
    case 2:
    case "TEMPLATE":
      return Account_Class.TEMPLATE;
    case 3:
    case "OMNIBUS":
      return Account_Class.OMNIBUS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Account_Class.UNRECOGNIZED;
  }
}

export function account_ClassToJSON(object: Account_Class): string {
  switch (object) {
    case Account_Class.REGULAR:
      return "REGULAR";
    case Account_Class.TEMPLATE:
      return "TEMPLATE";
    case Account_Class.OMNIBUS:
      return "OMNIBUS";
    case Account_Class.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Account sub-class enumeration. */
export enum Account_SubClass {
  /** SUB_CLASS_REGULAR - Regular account sub-class. */
  SUB_CLASS_REGULAR = 1,
  /** SUB_CLASS_INTERNAL - Gateway internal account sub-class. */
  SUB_CLASS_INTERNAL = 2,
  UNRECOGNIZED = -1,
}

export function account_SubClassFromJSON(object: any): Account_SubClass {
  switch (object) {
    case 1:
    case "SUB_CLASS_REGULAR":
      return Account_SubClass.SUB_CLASS_REGULAR;
    case 2:
    case "SUB_CLASS_INTERNAL":
      return Account_SubClass.SUB_CLASS_INTERNAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Account_SubClass.UNRECOGNIZED;
  }
}

export function account_SubClassToJSON(object: Account_SubClass): string {
  switch (object) {
    case Account_SubClass.SUB_CLASS_REGULAR:
      return "SUB_CLASS_REGULAR";
    case Account_SubClass.SUB_CLASS_INTERNAL:
      return "SUB_CLASS_INTERNAL";
    case Account_SubClass.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Gateway balance record. */
export interface BalanceRecord {
  /** Trade routing account identifier. */
  accountId: number;
  /** Balance record identifier. */
  balanceRecordId: number;
  /** Currency code (real currency code is ISO 4217 based. */
  currency: string;
  /** End cash balance. */
  endCashBalance?:
    | number
    | undefined;
  /** Collateral. */
  collateral?:
    | number
    | undefined;
  /** Previous close date (date value only) (UTC). */
  asOfDate?:
    | number
    | undefined;
  /**
   * [immutable] Balance origin.
   * This field is associated with BalanceOrigin enum type.
   */
  origin?:
    | number
    | undefined;
  /** Regulated. */
  regulated?: boolean | undefined;
}

/** Represents balance origin enum. */
export enum BalanceRecord_BalanceOrigin {
  /** NA - Not-available. */
  NA = 1,
  /** LOCAL - Local balance. */
  LOCAL = 2,
  /** OVERSEAS - Abroad balance. */
  OVERSEAS = 3,
  UNRECOGNIZED = -1,
}

export function balanceRecord_BalanceOriginFromJSON(object: any): BalanceRecord_BalanceOrigin {
  switch (object) {
    case 1:
    case "NA":
      return BalanceRecord_BalanceOrigin.NA;
    case 2:
    case "LOCAL":
      return BalanceRecord_BalanceOrigin.LOCAL;
    case 3:
    case "OVERSEAS":
      return BalanceRecord_BalanceOrigin.OVERSEAS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BalanceRecord_BalanceOrigin.UNRECOGNIZED;
  }
}

export function balanceRecord_BalanceOriginToJSON(object: BalanceRecord_BalanceOrigin): string {
  switch (object) {
    case BalanceRecord_BalanceOrigin.NA:
      return "NA";
    case BalanceRecord_BalanceOrigin.LOCAL:
      return "LOCAL";
    case BalanceRecord_BalanceOrigin.OVERSEAS:
      return "OVERSEAS";
    case BalanceRecord_BalanceOrigin.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Information about one of the found accounts. */
export interface AccountSearchResultRecord {
  /** Trade routing account identifier. */
  accountId: number;
  /** Account name. */
  name: string;
  /** Brokerage account number. */
  brokerageAccountNumber: string;
  /** Sales series number. */
  salesSeriesNumber: string;
  /** Sales series name. */
  salesSeriesName: string;
  /** Brokerage name. */
  brokerageName: string;
  /**
   * Id of customer - owner of the account.
   * Use profile_id instead.
   */
  customerId?:
    | string
    | undefined;
  /** Name of customer - owner of the account. */
  customerName?:
    | string
    | undefined;
  /** Removed flag. */
  removed?:
    | boolean
    | undefined;
  /**
   * Id of sales series.
   * Use profile_sales_series_id instead.
   */
  salesSeriesId?: string | undefined;
  brokerageId?: string | undefined;
  riskServerInstanceId?: string | undefined;
  groupId?:
    | string
    | undefined;
  /** This field is associated with Class enum. */
  class?:
    | number
    | undefined;
  /** Owner profile id. */
  profileId?:
    | string
    | undefined;
  /** Linked sales series profile id. */
  profileSalesSeriesId?: string | undefined;
}

/** Information about enabled account route. */
export interface AccountRouteRecord {
  /** List of field ids to clear during update operation. */
  clearedFields: number[];
  /** [required] Code number of the route. */
  routeCode?:
    | number
    | undefined;
  /**
   * [obsolete] Route name.
   * See RouteInformation.name field.
   *
   * @deprecated
   */
  obsoleteRouteName?:
    | string
    | undefined;
  /**
   * [required-create] Account priority of the route.
   * Should be unique positive number.
   */
  priority?:
    | number
    | undefined;
  /**
   * [erasable] Omnibus account ID if it is used for this route.
   * NOTE: erasing using cleared_fields functionality is
   * implemented for protocol version 1.33 or higher.
   */
  omnibusAccountId?:
    | number
    | undefined;
  /**
   * [obsolete] Omnibus account name if it is used for this route.
   * See Route.omnibus_accounts field.
   *
   * @deprecated
   */
  obsoleteOmnibusAccountName?:
    | string
    | undefined;
  /** List of route attributes with values. */
  attributes: AccountRouteAttribute[];
  /** [immutable] If set, route is in use by some other account using this configured route. */
  isInUse?:
    | boolean
    | undefined;
  /**
   * Indicates whether synthetic orders are allowed on this route for this account.
   * Can be 'true' only if route allows synthetic orders (RouteInformation.allow_synthetic_order_types).
   */
  allowSyntheticOrderTypes?:
    | boolean
    | undefined;
  /**
   * Indicates whether the algo_strategies_whitelist contains information about allowed algorithmic strategies.
   * Otherwise all available algorithmic strategies on particular route are allowed for the account.
   */
  enableAlgoStrategiesWhitelist?:
    | boolean
    | undefined;
  /**
   * [erasable] List of route algorithmic strategy ids allowed to account on particular route.
   * Can contain specific algorithmic strategy enablement in case when enable_algo_strategies_whitelist flag is set.
   */
  algoStrategiesWhitelist: string[];
  /**
   * Flag indicating whether during doing order route calculation, Risk Server should enforce
   * that a route enabled as "CQG Algo orders only" is only used for the order when it has a CQG MiFID Algo ID.
   */
  cqgAlgoOrdersOnly?:
    | boolean
    | undefined;
  /** This field is associated with ForceVariableLatencyAlgo enum. */
  forceVariableLatencyAlgo?: number | undefined;
}

/** Values indicating whether CQG MiFID Algo "CQG Variable Latency" should be applyed. */
export enum AccountRouteRecord_ForceVariableLatencyAlgo {
  /** DISABLED - Never for any orders on this route. */
  DISABLED = 0,
  /** NON_CQG_ALGO_ORDERS - For any orders on this route if they do not already have a CQG Algo or Spreader orders. */
  NON_CQG_ALGO_ORDERS = 1,
  /** ALL_NON_SPREADER_ORDERS - Always for any orders on this route if they are not Spreader orders. */
  ALL_NON_SPREADER_ORDERS = 2,
  UNRECOGNIZED = -1,
}

export function accountRouteRecord_ForceVariableLatencyAlgoFromJSON(
  object: any,
): AccountRouteRecord_ForceVariableLatencyAlgo {
  switch (object) {
    case 0:
    case "DISABLED":
      return AccountRouteRecord_ForceVariableLatencyAlgo.DISABLED;
    case 1:
    case "NON_CQG_ALGO_ORDERS":
      return AccountRouteRecord_ForceVariableLatencyAlgo.NON_CQG_ALGO_ORDERS;
    case 2:
    case "ALL_NON_SPREADER_ORDERS":
      return AccountRouteRecord_ForceVariableLatencyAlgo.ALL_NON_SPREADER_ORDERS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AccountRouteRecord_ForceVariableLatencyAlgo.UNRECOGNIZED;
  }
}

export function accountRouteRecord_ForceVariableLatencyAlgoToJSON(
  object: AccountRouteRecord_ForceVariableLatencyAlgo,
): string {
  switch (object) {
    case AccountRouteRecord_ForceVariableLatencyAlgo.DISABLED:
      return "DISABLED";
    case AccountRouteRecord_ForceVariableLatencyAlgo.NON_CQG_ALGO_ORDERS:
      return "NON_CQG_ALGO_ORDERS";
    case AccountRouteRecord_ForceVariableLatencyAlgo.ALL_NON_SPREADER_ORDERS:
      return "ALL_NON_SPREADER_ORDERS";
    case AccountRouteRecord_ForceVariableLatencyAlgo.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Route attributes. */
export interface AccountRouteAttribute {
  /** [immutable] Route attribute name. */
  Name: string;
  /** Route attribute value. */
  Value?:
    | string
    | undefined;
  /** [required][immutable] attribute id. */
  id?: string | undefined;
}

/** Account to user authorization link. */
export interface AccountUserLink {
  /** [required] Account ID. */
  accountId?:
    | number
    | undefined;
  /** [required] User ID. */
  userId?:
    | string
    | undefined;
  /** True means the trader can only view account activity. */
  isViewOnly?:
    | boolean
    | undefined;
  /** Determines if Gateway will automatically park trader's orders as Care Orders. */
  isForceCare?:
    | boolean
    | undefined;
  /** [immutable] User name. */
  userName?:
    | string
    | undefined;
  /** [immutable] Account name. */
  accountName?:
    | string
    | undefined;
  /** [immutable] Brokerage account number. */
  brokerageAccountNumber?:
    | string
    | undefined;
  /** [immutable] Account's brokerage name. */
  brokerageName?:
    | string
    | undefined;
  /** Enables trader to place orders into external FCM account number. */
  allowExternalAccounts?:
    | boolean
    | undefined;
  /** [immutable] Authorization via brokerage or sales series. */
  implicitAuthorization?:
    | boolean
    | undefined;
  /**
   * Execution Source Code that is used for orders placed by user_id on account_id.
   * This is not an actual code but ID that can be obtained from lookup (value field).
   * LookupPropertyListRequest { property_type = TradeRoutingLookupPropertyType.EXECUTION_SOURCE_CODE }
   */
  executionSourceCodeId?:
    | string
    | undefined;
  /** [immutable] Account's brokerage identifier. */
  accountBrokerageId?:
    | string
    | undefined;
  /** [immutable] Account's sales series identifier. */
  accountSalesSeriesId?:
    | string
    | undefined;
  /**
   * Indicates whether orders on Brokerage/Sales series accounts
   * should be flagged as Direct Electronic Access under MiFID.
   */
  isMifidDea?:
    | boolean
    | undefined;
  /** Forces CQG MiFID algorithms to be treated as client algorithms rather than firm algorithms. */
  transformMifidAlgoCqgToClientAlgo?:
    | boolean
    | undefined;
  /**
   * Indicates whether trade-routing login is primary on account.
   * If account already has primary login then it will be
   * cleared from account and new one is set.
   */
  isPrimaryLogin?: boolean | undefined;
}

/** Account settings. */
export interface AccountSettings {
  /** List of field ids to clear during update operation. */
  clearedFields: number[];
  /** [required-update] Trade routing account identifier. */
  accountId?:
    | number
    | undefined;
  /**
   * [obsolete] Block account indicator. The field is obsolete and always has False value.
   *
   * @deprecated
   */
  obsoleteIsBlock?:
    | boolean
    | undefined;
  /**
   * [obsolete] Block size if this is a block account.
   *
   * @deprecated
   */
  obsoleteBlockSize?:
    | number
    | undefined;
  /** Give up account indicator. */
  isGiveup?:
    | boolean
    | undefined;
  /**
   * [obsolete] Use giveup_brokerage_id field instead.
   * Give up brokerage name if this is a give up account.
   *
   * @deprecated
   */
  obsoleteGiveupBrokerageName?:
    | string
    | undefined;
  /** This field is associated with StatementOption enum. */
  statementOption?:
    | number
    | undefined;
  /** If statement processing option is RESET this field indicates if balances are reset to zero along with positions. */
  zeroBalance?:
    | boolean
    | undefined;
  /** [erasable] Reconciliation indicator. Account follows brokerage reconciliation setting if the filed is omitted. */
  reconciliation?:
    | boolean
    | undefined;
  /** Give up brokerage id if this is a give up account. */
  giveupBrokerageId?:
    | string
    | undefined;
  /**
   * [erasable] Start of account trading time in ISO 8601 format.
   * Only time must be provided.
   */
  tradingTimeFrom?:
    | string
    | undefined;
  /**
   * [erasable] End of account trading time in ISO 8601 format.
   * Only time must be provided.
   * Must be provided with the same time zone if start time is set.
   */
  tradingTimeTo?:
    | string
    | undefined;
  /**
   * [erasable] Trading time zone.
   * The value are associated with a lookup property with TIME_ZONE type.
   */
  tradingTimeZone?:
    | string
    | undefined;
  /** Allow to liquidate orders outside trading time, if trading time is set. */
  liquidationOrdersOutsideTradingTime?:
    | boolean
    | undefined;
  /** Instruct account indicator. */
  isInstruct?:
    | boolean
    | undefined;
  /** Allow externally provided execution of account. */
  externallyProvidedExecution?:
    | boolean
    | undefined;
  /**
   * [erasable] Associated MODES account groups.
   * The values are associated with a lookup property with MODES_ACCOUNT_GROUP type.
   * Empty string will not be processed, use cleared_fields during update operation to clear this field.
   */
  modesAccountGroups: string[];
  /** Determines whether Pre-trade middle market price for an order is required. */
  requirePreTradeMidMarketMark?:
    | boolean
    | undefined;
  /** Allows using of extended sides. */
  allowExtendedSide?:
    | boolean
    | undefined;
  /** Indicates whether GoFlat request for the account is allowed (false by default). */
  allowGoFlat?:
    | boolean
    | undefined;
  /**
   * [erasable] This field is associated with OrderChain.SpeculationType enum.
   * Value should be used for orders initiated from a GoFlat request that does not include speculation type on contracts where speculation type is required.
   */
  goFlatDefaultSpeculationType?: number | undefined;
}

/** Account statement processing option enumeration (account clearing). */
export enum AccountSettings_StatementOption {
  /** GENERATE - Generate complete daily statement (simulated clearing). */
  GENERATE = 1,
  /** BROKERAGE - Process statements as received from a brokerage. */
  BROKERAGE = 2,
  /** RESET - Reset to zero positions at the end of the day. */
  RESET = 3,
  UNRECOGNIZED = -1,
}

export function accountSettings_StatementOptionFromJSON(object: any): AccountSettings_StatementOption {
  switch (object) {
    case 1:
    case "GENERATE":
      return AccountSettings_StatementOption.GENERATE;
    case 2:
    case "BROKERAGE":
      return AccountSettings_StatementOption.BROKERAGE;
    case 3:
    case "RESET":
      return AccountSettings_StatementOption.RESET;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AccountSettings_StatementOption.UNRECOGNIZED;
  }
}

export function accountSettings_StatementOptionToJSON(object: AccountSettings_StatementOption): string {
  switch (object) {
    case AccountSettings_StatementOption.GENERATE:
      return "GENERATE";
    case AccountSettings_StatementOption.BROKERAGE:
      return "BROKERAGE";
    case AccountSettings_StatementOption.RESET:
      return "RESET";
    case AccountSettings_StatementOption.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface RiskParameters {
  /**
   * Trade routing account identifier.
   * [obsolete] Use specific entity identifier on update message level.
   *
   * @deprecated
   */
  obsoleteAccountId?:
    | number
    | undefined;
  /** Allow positions to be closed only. */
  liquidationOnly?:
    | boolean
    | undefined;
  /** Allow futures trading. */
  allowFutures?:
    | boolean
    | undefined;
  /**
   * Allow options trading.
   * The value is associated with OptionsTrading enum.
   */
  allowOptions?:
    | number
    | undefined;
  /** True if trade size limit is enforced. */
  enforceTradeSizeLimit?:
    | boolean
    | undefined;
  /**
   * [obsolete] Use trade_size_limit field instead.
   *
   * @deprecated
   */
  obsoleteTradeSizeLimit?:
    | number
    | undefined;
  /** True if trade margin limit is enforced. */
  enforceTradeMarginLimit?:
    | boolean
    | undefined;
  /** Optional trade margin limit which is maximum margin requirement for any single trade. */
  tradeMarginLimit?:
    | number
    | undefined;
  /** True if trade price limit in ticks is enforced. */
  enforceTradePriceLimitTicks?:
    | boolean
    | undefined;
  /** Optional trade price limit in ticks for any single trade. */
  tradePriceLimitTicks?:
    | TradePriceLimit
    | undefined;
  /** True if commodity position limit is enforced. */
  enforceCommodityPositionLimit?:
    | boolean
    | undefined;
  /**
   * [obsolete] Use commodity_position_limit field instead.
   *
   * @deprecated
   */
  obsoleteCommodityPositionLimit?:
    | LimitValue
    | undefined;
  /** True if contract position limit is enforced. */
  enforceContractPositionLimit?:
    | boolean
    | undefined;
  /**
   * [obsolete] Use contract_position_limit field instead.
   *
   * @deprecated
   */
  obsoleteContractPositionLimit?:
    | LimitValue
    | undefined;
  /**
   * True if margin sybsystem parameters are enforced.
   * When enforced, any trade that could cause margin requirements to exceed account's available funds is denied.
   */
  enforceMarginSubsystemParameters?:
    | boolean
    | undefined;
  /** Purchasing power parameters. */
  purchasingPowerParameters?:
    | PurchasingPowerParameters
    | undefined;
  /** True if daily loss limit is enforced. */
  enforceDailyLossLimit?:
    | boolean
    | undefined;
  /**
   * Optional daily loss limit. Limits losses during a single day.
   * Account ending balance + collateral are considered as 'funds' for the limit.
   * Losses during a single day are considered as 'losses' for the limit.
   */
  dailyLossLimit?:
    | LossLimit
    | undefined;
  /** True if delta daily loss limit is enforced. */
  enforceDeltaDailyLossLimit?:
    | boolean
    | undefined;
  /**
   * Optional delta daily loss limit. Limits losses since yesterday.
   * Yesterday's purchasing power is considered as 'funds' for the limit.
   * Losses incurred since yesterday are considered as 'losses' for the limit.
   */
  deltaDailyLossLimit?:
    | LossLimit
    | undefined;
  /** Maximum number of order requests (new orders, modifications, cancellations) allowed per second. */
  maximumOrderRate?:
    | number
    | undefined;
  /** Reject BUY MKT orders if ask price is not available and SELL MKT orders if bid price is not available. */
  rejectRiskyMarketOrders?:
    | boolean
    | undefined;
  /** True if trade price limit in percent is enforced. */
  enforceTradePriceLimitPercent?:
    | boolean
    | undefined;
  /** Optional trade price limit in percent for any single trade. */
  tradePriceLimitPercent?:
    | TradePriceLimitPercent
    | undefined;
  /** Optional trade size limit for any single trade. */
  tradeSizeLimit?:
    | Decimal
    | undefined;
  /** Optional commodity position limit. */
  commodityPositionLimit?:
    | LimitValueDecimal
    | undefined;
  /** Optional contract position limit. */
  contractPositionLimit?:
    | LimitValueDecimal
    | undefined;
  /** Order placement date plus this number of days must be more than contract last trading date. */
  lastTradingDateLimit?:
    | LimitValue
    | undefined;
  /**
   * Trade size limit with unlimited/inherited mode.
   * Unlimited/inherited modes aren't supported for account now.
   */
  tradeSizeLimitWithModes?:
    | LimitValueDecimal
    | undefined;
  /** True if total gross open position limit is enforced. */
  enforceTotalGrossOpenPositionLimit?:
    | boolean
    | undefined;
  /** Optional total gross open position limit. */
  totalGrossOpenPositionLimit?:
    | LimitValueDecimal
    | undefined;
  /**
   * Long Option Premium check parameter.
   * True means that check is performed against Purchasing Power, False - against Cash Balance.
   */
  longOptionPremiumCheckAgainstPurchasingPower?:
    | boolean
    | undefined;
  /**
   * True means that real-time currency rates are used for intra-day currency conversions, where available.
   * Otherwise, Brokerage daily statement currency rates are used for all currency conversions.
   */
  useIntradayCurrencyRate?: boolean | undefined;
}

export enum RiskParameters_OptionsTrading {
  /** NO - Cannot buy or sell options. */
  NO = 1,
  /** BUY - Buy options or liquidate long option positions. */
  BUY = 2,
  /** ALL - Buy or Sell options. */
  ALL = 3,
  UNRECOGNIZED = -1,
}

export function riskParameters_OptionsTradingFromJSON(object: any): RiskParameters_OptionsTrading {
  switch (object) {
    case 1:
    case "NO":
      return RiskParameters_OptionsTrading.NO;
    case 2:
    case "BUY":
      return RiskParameters_OptionsTrading.BUY;
    case 3:
    case "ALL":
      return RiskParameters_OptionsTrading.ALL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RiskParameters_OptionsTrading.UNRECOGNIZED;
  }
}

export function riskParameters_OptionsTradingToJSON(object: RiskParameters_OptionsTrading): string {
  switch (object) {
    case RiskParameters_OptionsTrading.NO:
      return "NO";
    case RiskParameters_OptionsTrading.BUY:
      return "BUY";
    case RiskParameters_OptionsTrading.ALL:
      return "ALL";
    case RiskParameters_OptionsTrading.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Account group. */
export interface AccountGroup {
  /** [required-update] Group identifier. */
  groupId?:
    | string
    | undefined;
  /** [required-create][immutable-update] Master of the group. */
  masterAccountId?:
    | string
    | undefined;
  /**
   * [required-create] Relation type.
   * This field is associated with RelationType enum.
   */
  relationType?:
    | number
    | undefined;
  /** [immutable-update] Sub account of the group. */
  subAccounts: SubAccount[];
}

/** Group accounts relation enumeration. */
export enum AccountGroup_RelationType {
  /** MARGIN_INDEPENDENTLY_BORROW - Margin independently (sub accounts can borrow Purchasing Power from master up to configured %). */
  MARGIN_INDEPENDENTLY_BORROW = 1,
  /** MARGIN_INDEPENDENTLY_SUM - Margin independently, then sum margin requirements and Purchasing Powers. */
  MARGIN_INDEPENDENTLY_SUM = 2,
  /** MARGIN_TOGETHER - Margin master and sub-accounts together (combine funds, orders, and positions for margin calculations). */
  MARGIN_TOGETHER = 3,
  UNRECOGNIZED = -1,
}

export function accountGroup_RelationTypeFromJSON(object: any): AccountGroup_RelationType {
  switch (object) {
    case 1:
    case "MARGIN_INDEPENDENTLY_BORROW":
      return AccountGroup_RelationType.MARGIN_INDEPENDENTLY_BORROW;
    case 2:
    case "MARGIN_INDEPENDENTLY_SUM":
      return AccountGroup_RelationType.MARGIN_INDEPENDENTLY_SUM;
    case 3:
    case "MARGIN_TOGETHER":
      return AccountGroup_RelationType.MARGIN_TOGETHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AccountGroup_RelationType.UNRECOGNIZED;
  }
}

export function accountGroup_RelationTypeToJSON(object: AccountGroup_RelationType): string {
  switch (object) {
    case AccountGroup_RelationType.MARGIN_INDEPENDENTLY_BORROW:
      return "MARGIN_INDEPENDENTLY_BORROW";
    case AccountGroup_RelationType.MARGIN_INDEPENDENTLY_SUM:
      return "MARGIN_INDEPENDENTLY_SUM";
    case AccountGroup_RelationType.MARGIN_TOGETHER:
      return "MARGIN_TOGETHER";
    case AccountGroup_RelationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Sub account of group. */
export interface SubAccount {
  /** [required] Account identifier. */
  accountId?:
    | string
    | undefined;
  /** [immutable] Account name. */
  accountName?:
    | string
    | undefined;
  /** [immutable] Brokerage account number. */
  brokerageAccountNumber?:
    | string
    | undefined;
  /** [required] Purchasing power. Allowed values (0..1). */
  purchasingPowerFraction?: number | undefined;
}

/** Trade price limit parameters. */
export interface TradePriceLimit {
  /** This field is associated with PriceLimitMode enum. */
  mode?:
    | number
    | undefined;
  /**
   * [obsolete] Number of ticks away from the current market price to enforce the price limit.
   *
   * @deprecated
   */
  obsoleteTicks?:
    | number
    | undefined;
  /** Number of ticks away from the current market price to enforce the price limit. */
  value?: LimitValue | undefined;
}

/** Trade price limit parameters. */
export interface TradePriceLimitPercent {
  /** This field is associated with PriceLimitMode enum. */
  mode?:
    | number
    | undefined;
  /** Percent from the current market price to enforce the price limit. */
  value?: LimitValueDouble | undefined;
}

/** Limit value in ticks. */
export interface LimitValue {
  /** This field is associated with LimitMode enum. */
  mode?:
    | number
    | undefined;
  /** Limit value when mode is LIMITED. */
  value?:
    | number
    | undefined;
  /**
   * [obsolete] List of optional contract expiration based limits overrides.
   *
   * @deprecated
   */
  obsoleteExpirationLimit: ExpirationLimit[];
  /**
   * Enforce all brokerage accounts to use this setting.
   * Only applicable for Brokerage level configuration.
   * Supported by LTD Limit.
   */
  enforce?: boolean | undefined;
}

/** Limit value in double. */
export interface LimitValueDouble {
  /** This field is associated with LimitMode enum. */
  mode?:
    | number
    | undefined;
  /** Limit value in double when mode is LIMITED. */
  value?:
    | number
    | undefined;
  /**
   * Enforce all brokerage accounts to use this setting.
   * Only applicable for Brokerage level configuration.
   * Supported by next limits: margin_multiplier, last_trading_date_limit, long_positions_only.
   */
  enforce?: boolean | undefined;
}

/** Limit value in decimal (can be used for trade size of position limit). */
export interface LimitValueDecimal {
  /** List of field ids to clear during update operation. */
  clearedFields: number[];
  /** This field is associated with LimitMode enum. */
  mode?:
    | number
    | undefined;
  /** Limit value when mode is LIMITED. */
  value?:
    | Decimal
    | undefined;
  /**
   * [erasable] List of optional contract expiration based limits overrides.
   * This list is used only for contract level position limit at the moment.
   * Limits with lower number of days before expiration take over limits with bigger number of days before expiration
   * when contract expiration reaches corresponding limit period.
   */
  expirationLimit: ExpirationLimitDecimal[];
  /**
   * Enforce All brokerage accounts to use this setting.
   * Only applicable for Brokerage level configuration.
   * Supported by LTD Limit.
   */
  enforce?:
    | boolean
    | undefined;
  /**
   * Only applicable for Login level configuration.
   *  1- enforced(use login settins}, 0- not enforced. Supported by gross open position limit.
   */
  loginEnforce?: boolean | undefined;
}

/** Limit value that can be true/false/default. */
export interface LimitValueBoolean {
  /** This field is associated with LimitMode enum. UNLIMITED is not supported. */
  mode?:
    | number
    | undefined;
  /** Limit value when mode is LIMITED. */
  value?:
    | boolean
    | undefined;
  /**
   * Enforce All brokerage accounts to use this setting.
   * Only applicable for Brokerage level configuration.
   */
  enforce?: boolean | undefined;
}

/** [obsolete] Use ExpirationLimitDecimal message instead. */
export interface ExpirationLimit {
  /** Number of days before contract expiration this limit is in effect. */
  daysBeforeExpiration?:
    | number
    | undefined;
  /** Limit value when enforced. */
  value?: number | undefined;
}

/** Contract expiration based limit. */
export interface ExpirationLimitDecimal {
  /** Number of days before contract expiration this limit is in effect. */
  daysBeforeExpiration?:
    | number
    | undefined;
  /** Limit value when enforced. */
  value?: Decimal | undefined;
}

/** Margin subsystem parameters. */
export interface PurchasingPowerParameters {
  /** Additional credit available to satisfy margin requirements. */
  msAllowableMarginCredit?:
    | number
    | undefined;
  /** Cross margining indicator. If true then inter-month, inter-commodity and inter-exchange credits are considered in margin estimation. */
  msCrossMargining?:
    | boolean
    | undefined;
  /**
   * Include OTE (Open Trade Equity) in Purchasing Power (PP).
   * This field is associated with IncludeOption enum.
   */
  msIncludeOtePp?:
    | number
    | undefined;
  /**
   * Include NOV (Net Option Value) in Purchasing Power (PP).
   * This field is associated with IncludeOption enum.
   */
  msIncludeNovPp?:
    | number
    | undefined;
  /**
   * Include UPL (Unrealized Profit Loss) in DLL/DDLL (Daily Loss Limit and Delta Daily Loss Limit)
   * This field is associated with IncludeOption enum.
   */
  includeUplLl?:
    | number
    | undefined;
  /**
   * Include OTE (Open Trade Equity) in DLL/DDLL (Daily Loss Limit and Delta Daily Loss Limit)
   * This field is associated with IncludeOption enum.
   */
  includeOteLl?:
    | number
    | undefined;
  /** Check for the negative balance. */
  checkNegativeBalance?:
    | boolean
    | undefined;
  /** If true then OTE, NOV, and UPL can be calculated using theoretical values. */
  useTheoPrices?:
    | boolean
    | undefined;
  /** Slippage for option theoretical price in ticks. */
  theoTicks?:
    | number
    | undefined;
  /** If true then Best Bid and Ask can be used for OTE calculation, only trade and settlement are used otherwise. */
  useBbaOte?:
    | boolean
    | undefined;
  /** If true then Best Bid and Ask can be used for NOV and UPL calculation, only trade and settlement are used otherwise. */
  useBbaNovUpl?:
    | boolean
    | undefined;
  /** Calculate current price as a settlement adjusted by net price change of reference contract, LME style. */
  adjustPriceByNetchange?:
    | boolean
    | undefined;
  /**
   * Use brokerage's configured margins instead of gateway's default ones.
   * Using this option you are disabling cross-margining, even if it is enabled.
   * Can be set if it is allowed on brokerage level only.
   */
  useBrokerageMarginsOnly?:
    | boolean
    | undefined;
  /**
   * [obsolete] Use margin_multiplier instead.
   * Multiplier for calculated margin requirements. Allowed values (0.001..100).
   *
   * @deprecated
   */
  obsoleteMarginMultiplier?:
    | number
    | undefined;
  /** If true then margin is calculated independently for options using instrument_group_margin_multiplier. */
  msUseInstrumentGroupMarginMultiplier?:
    | boolean
    | undefined;
  /** Multiplier for calculated instrument group (options) margin requirements. Allowed values (0.001..100). */
  msInstrumentGroupMarginMultiplier?:
    | number
    | undefined;
  /**
   * Multiplier for calculated margin requirements. Allowed values (0.001..100).
   * In update request you should use LimitMode.DEFAULT to reset this field.
   */
  marginMultiplier?:
    | LimitValueDouble
    | undefined;
  /**
   * This parameter allows to ignore margin-based checks for liquidation orders.
   * Any other risk checks that are enabled for this account
   * will be conducted as usual even for liquidation orders.
   */
  alwaysAllowLiquidation?:
    | boolean
    | undefined;
  /** If true then max_purchasing_power parameter is used. */
  enforceMaxPurchasingPower?:
    | boolean
    | undefined;
  /**
   * Maximum amount of purchasing power available to satisfy margin requirements.
   * Applicable when enforce_max_purchasing_power is true. Allowed values (0.001..9999999999).
   */
  maxPurchasingPower?: number | undefined;
}

/** Possible options to include different values into purchasing power. */
export enum PurchasingPowerParameters_IncludeOption {
  /** YES - Value is included. */
  YES = 1,
  /** NEGATIVE - Only negative value is included. */
  NEGATIVE = 2,
  /** NO - Value is not included. */
  NO = 3,
  UNRECOGNIZED = -1,
}

export function purchasingPowerParameters_IncludeOptionFromJSON(object: any): PurchasingPowerParameters_IncludeOption {
  switch (object) {
    case 1:
    case "YES":
      return PurchasingPowerParameters_IncludeOption.YES;
    case 2:
    case "NEGATIVE":
      return PurchasingPowerParameters_IncludeOption.NEGATIVE;
    case 3:
    case "NO":
      return PurchasingPowerParameters_IncludeOption.NO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PurchasingPowerParameters_IncludeOption.UNRECOGNIZED;
  }
}

export function purchasingPowerParameters_IncludeOptionToJSON(object: PurchasingPowerParameters_IncludeOption): string {
  switch (object) {
    case PurchasingPowerParameters_IncludeOption.YES:
      return "YES";
    case PurchasingPowerParameters_IncludeOption.NEGATIVE:
      return "NEGATIVE";
    case PurchasingPowerParameters_IncludeOption.NO:
      return "NO";
    case PurchasingPowerParameters_IncludeOption.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Loss limit. */
export interface LossLimit {
  /** This field is associated with Type enumeration. */
  type?:
    | number
    | undefined;
  /** Percent of funds allowed in losses, entered as a fractional between 0 and 1. */
  percent?:
    | number
    | undefined;
  /** Absolute amount in US dollars allowed in losses. */
  amount?: number | undefined;
}

/**
 * Loss limit type enumeration.
 * It defines what is used to get the limit absolute value during calculation.
 */
export enum LossLimit_Type {
  /** PERCENT - Percentage of funds allowed in losses. */
  PERCENT = 1,
  /** AMOUNT - Absolute amount allowed in losses, limited by funds. */
  AMOUNT = 2,
  /** MIN_PERCENT_AMOUNT - Minimum from percent and absolute amount of funds allowed in losses. */
  MIN_PERCENT_AMOUNT = 3,
  /** MAX_PERCENT_AMOUNT - Maximum from percent and absolute amount of funds allowed in losses. */
  MAX_PERCENT_AMOUNT = 4,
  /** AMOUNT_NO_LIMIT - Absolute amount of funds allowed in losses, and not limited by funds. */
  AMOUNT_NO_LIMIT = 5,
  UNRECOGNIZED = -1,
}

export function lossLimit_TypeFromJSON(object: any): LossLimit_Type {
  switch (object) {
    case 1:
    case "PERCENT":
      return LossLimit_Type.PERCENT;
    case 2:
    case "AMOUNT":
      return LossLimit_Type.AMOUNT;
    case 3:
    case "MIN_PERCENT_AMOUNT":
      return LossLimit_Type.MIN_PERCENT_AMOUNT;
    case 4:
    case "MAX_PERCENT_AMOUNT":
      return LossLimit_Type.MAX_PERCENT_AMOUNT;
    case 5:
    case "AMOUNT_NO_LIMIT":
      return LossLimit_Type.AMOUNT_NO_LIMIT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LossLimit_Type.UNRECOGNIZED;
  }
}

export function lossLimit_TypeToJSON(object: LossLimit_Type): string {
  switch (object) {
    case LossLimit_Type.PERCENT:
      return "PERCENT";
    case LossLimit_Type.AMOUNT:
      return "AMOUNT";
    case LossLimit_Type.MIN_PERCENT_AMOUNT:
      return "MIN_PERCENT_AMOUNT";
    case LossLimit_Type.MAX_PERCENT_AMOUNT:
      return "MAX_PERCENT_AMOUNT";
    case LossLimit_Type.AMOUNT_NO_LIMIT:
      return "AMOUNT_NO_LIMIT";
    case LossLimit_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Exchange group with a list of commodities. */
export interface ExchangeGroup {
  /** Trade routing system identifier of an exchange group. */
  id: number;
  /** Name of the exchange group. */
  name:
    | Text
    | undefined;
  /** List of exchange group fungible commodities. */
  fungibleCommodity: FungibleCommodity[];
}

/** Fungible commodity. */
export interface FungibleCommodity {
  /** Trade routing system identifier of the fungible commodity. */
  id?:
    | number
    | undefined;
  /** Name of the fungible commodity. */
  name?:
    | Text
    | undefined;
  /** List of tradable commodities. */
  tradableCommodity: TradableCommodity[];
  /** US commodity indicator. */
  isUs?:
    | boolean
    | undefined;
  /** List of instrument types defined for this fungible commodity. */
  instrumentTypeId: number[];
  /** Synthetic ID of margin group. */
  marginGroupId?:
    | string
    | undefined;
  /** Prefix of the fungible commodity. */
  prefix?:
    | string
    | undefined;
  /** Fungible commodity's extended code. */
  extendedCode?: string | undefined;
}

/** Tradable commodity. */
export interface TradableCommodity {
  /** Commodity string id (aka Commodity XBit). */
  id: string;
  /** Commodity symbol. */
  symbol: string;
}

/** Instrument type. */
export interface InstrumentType {
  /** Trade routing system identifier of the instrument type. */
  id: number;
  /** Name of the instrument type. */
  name:
    | Text
    | undefined;
  /**
   * List of limit types that are acceptable for this instrument type.
   * This filed is associated with MarketLimitType enum.
   */
  allowedLimits: number[];
  /**
   * Instrument group type id.
   * This filed is associated with InstrumentGroupType enum.
   */
  instrumentGroup?: number | undefined;
}

/** Different market limit types. */
export enum InstrumentType_MarketLimitType {
  /** INSTRUMENT_POSITION_LIMIT - Instrument position limit type. */
  INSTRUMENT_POSITION_LIMIT = 1,
  /** CONTRACT_POSITION_LIMIT - Contract position limit type. */
  CONTRACT_POSITION_LIMIT = 2,
  /** TRADE_SIZE_LIMIT - Trade size limit type. */
  TRADE_SIZE_LIMIT = 3,
  /** TRADE_PRICE_LIMIT - Trade price limit type. */
  TRADE_PRICE_LIMIT = 4,
  /** LAST_TRADING_DATE_LIMIT - Trade price limit type. */
  LAST_TRADING_DATE_LIMIT = 5,
  /** LONG_POSITIONS_ONLY - Long positions only type. */
  LONG_POSITIONS_ONLY = 6,
  UNRECOGNIZED = -1,
}

export function instrumentType_MarketLimitTypeFromJSON(object: any): InstrumentType_MarketLimitType {
  switch (object) {
    case 1:
    case "INSTRUMENT_POSITION_LIMIT":
      return InstrumentType_MarketLimitType.INSTRUMENT_POSITION_LIMIT;
    case 2:
    case "CONTRACT_POSITION_LIMIT":
      return InstrumentType_MarketLimitType.CONTRACT_POSITION_LIMIT;
    case 3:
    case "TRADE_SIZE_LIMIT":
      return InstrumentType_MarketLimitType.TRADE_SIZE_LIMIT;
    case 4:
    case "TRADE_PRICE_LIMIT":
      return InstrumentType_MarketLimitType.TRADE_PRICE_LIMIT;
    case 5:
    case "LAST_TRADING_DATE_LIMIT":
      return InstrumentType_MarketLimitType.LAST_TRADING_DATE_LIMIT;
    case 6:
    case "LONG_POSITIONS_ONLY":
      return InstrumentType_MarketLimitType.LONG_POSITIONS_ONLY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return InstrumentType_MarketLimitType.UNRECOGNIZED;
  }
}

export function instrumentType_MarketLimitTypeToJSON(object: InstrumentType_MarketLimitType): string {
  switch (object) {
    case InstrumentType_MarketLimitType.INSTRUMENT_POSITION_LIMIT:
      return "INSTRUMENT_POSITION_LIMIT";
    case InstrumentType_MarketLimitType.CONTRACT_POSITION_LIMIT:
      return "CONTRACT_POSITION_LIMIT";
    case InstrumentType_MarketLimitType.TRADE_SIZE_LIMIT:
      return "TRADE_SIZE_LIMIT";
    case InstrumentType_MarketLimitType.TRADE_PRICE_LIMIT:
      return "TRADE_PRICE_LIMIT";
    case InstrumentType_MarketLimitType.LAST_TRADING_DATE_LIMIT:
      return "LAST_TRADING_DATE_LIMIT";
    case InstrumentType_MarketLimitType.LONG_POSITIONS_ONLY:
      return "LONG_POSITIONS_ONLY";
    case InstrumentType_MarketLimitType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Instrument group types. */
export enum InstrumentType_InstrumentGroupType {
  /** FUTURES - Futures. */
  FUTURES = 1,
  /** OPTIONS - Options call/put. */
  OPTIONS = 2,
  /** STRATEGIES - All strategy types (calendar spreads, reduced tick calendar spreads, packs, strips, etc.). */
  STRATEGIES = 3,
  /** INDICES - Indices. */
  INDICES = 4,
  /** FIXED_INCOME - Fixed Income. */
  FIXED_INCOME = 5,
  /** CASH_SPOT - Cash Spot (including FX). */
  CASH_SPOT = 6,
  /** DAILY_FUTURES - Daily Futures (relative/rolling prompts). */
  DAILY_FUTURES = 7,
  /** EQUITIES - Equities. */
  EQUITIES = 8,
  UNRECOGNIZED = -1,
}

export function instrumentType_InstrumentGroupTypeFromJSON(object: any): InstrumentType_InstrumentGroupType {
  switch (object) {
    case 1:
    case "FUTURES":
      return InstrumentType_InstrumentGroupType.FUTURES;
    case 2:
    case "OPTIONS":
      return InstrumentType_InstrumentGroupType.OPTIONS;
    case 3:
    case "STRATEGIES":
      return InstrumentType_InstrumentGroupType.STRATEGIES;
    case 4:
    case "INDICES":
      return InstrumentType_InstrumentGroupType.INDICES;
    case 5:
    case "FIXED_INCOME":
      return InstrumentType_InstrumentGroupType.FIXED_INCOME;
    case 6:
    case "CASH_SPOT":
      return InstrumentType_InstrumentGroupType.CASH_SPOT;
    case 7:
    case "DAILY_FUTURES":
      return InstrumentType_InstrumentGroupType.DAILY_FUTURES;
    case 8:
    case "EQUITIES":
      return InstrumentType_InstrumentGroupType.EQUITIES;
    case -1:
    case "UNRECOGNIZED":
    default:
      return InstrumentType_InstrumentGroupType.UNRECOGNIZED;
  }
}

export function instrumentType_InstrumentGroupTypeToJSON(object: InstrumentType_InstrumentGroupType): string {
  switch (object) {
    case InstrumentType_InstrumentGroupType.FUTURES:
      return "FUTURES";
    case InstrumentType_InstrumentGroupType.OPTIONS:
      return "OPTIONS";
    case InstrumentType_InstrumentGroupType.STRATEGIES:
      return "STRATEGIES";
    case InstrumentType_InstrumentGroupType.INDICES:
      return "INDICES";
    case InstrumentType_InstrumentGroupType.FIXED_INCOME:
      return "FIXED_INCOME";
    case InstrumentType_InstrumentGroupType.CASH_SPOT:
      return "CASH_SPOT";
    case InstrumentType_InstrumentGroupType.DAILY_FUTURES:
      return "DAILY_FUTURES";
    case InstrumentType_InstrumentGroupType.EQUITIES:
      return "EQUITIES";
    case InstrumentType_InstrumentGroupType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Market Limits set.
 * The general rules for using this result are:
 * - all fungible commodities with non-default limit(s) are returned (even those with no 'allowed to trade' tradable commodities);
 * - to determine if a tradable commodity is enabled (allowed to trade), first check tradable_commodity_id field;
 *   if the tradable commodity is not present there, use the next rule;
 * - if something is not specified on a lower level, check defaults at applicable higher levels
 *   (fungible commodity -> exchange group -> all US/non-US commodities -> all commodities).
 */
export interface MarketLimits {
  /** Allows to clear (set to default) all market limits (including trading authority) for provided level except all_market_limits. */
  clearedFields: number[];
  /** Default market limits for all commodities. */
  allMarketLimits?:
    | MarketLimitsRecord
    | undefined;
  /** [erasable] Default market limits for US commodities. */
  usMarketLimits?:
    | MarketLimitsRecord
    | undefined;
  /** [erasable] Default market limits for non-US commodities. */
  nonUsMarketLimits?:
    | MarketLimitsRecord
    | undefined;
  /** [erasable] Default market limits for exchange groups. */
  exchangeMarketLimits: ExchangeMarketLimits[];
}

/**
 * Record of market limit setting for exchange group level or higher.
 * Limit values are defaults for all included commodities and can be can be overridden on a lower level.
 */
export interface MarketLimitsRecord {
  /** Reserved for future use, not used now. */
  clearedFields: number[];
  /**
   * Indicates if level is allowed to trade.
   * If null (not set) - default, actual value inherited from upper level.
   */
  allowedToTrade?:
    | EntityAllowedToTrade
    | undefined;
  /**
   * [obsolete] Default margin multiplier. Allowed values (0.001..100).
   * Use margin_multiplier = 5 instead.
   *
   * @deprecated
   */
  obsoleteMarginMultiplier?:
    | number
    | undefined;
  /** Default position and Trade limits. */
  positionAndTradeLimits?:
    | PositionAndTradeLimits
    | undefined;
  /**
   * Margin multiplier for default and exchange levels. Allowed values (0.001..100).
   * Can be enforced (locked) on FCM level.
   */
  marginMultiplier?: LimitValueDouble | undefined;
}

/** Set of position and trade limits for all possible levels. */
export interface PositionAndTradeLimits {
  /** Reserved for future use, not used now. */
  clearedFields: number[];
  /**
   * [obsolete] Use commodity_position_limit field instead.
   *
   * @deprecated
   */
  obsoleteCommodityPositionLimit?:
    | LimitValue
    | undefined;
  /**
   * [obsolete] Use instrument_position_limit field instead.
   *
   * @deprecated
   */
  obsoleteInstrumentPositionLimit?:
    | LimitValue
    | undefined;
  /**
   * [obsolete] Use contract_position_limit field instead.
   *
   * @deprecated
   */
  obsoleteContractPositionLimit?:
    | LimitValue
    | undefined;
  /**
   * [obsolete] Use trade_size_limit field instead.
   *
   * @deprecated
   */
  obsoleteTradeSizeLimit?:
    | LimitValue
    | undefined;
  /** Trade price limit (all price sources) (in ticks). */
  tradePriceLimitTicks?:
    | LimitValue
    | undefined;
  /** Trade price limit (all price sources) (in percent). */
  tradePriceLimitPercent?:
    | LimitValueDouble
    | undefined;
  /** Trade price limits in ticks per price sources for continuous trading. */
  tradePriceLimitTicksContinuous?:
    | TradePriceLimitsTicks
    | undefined;
  /** Trade price limits in ticks per price sources for non-continuous trading. */
  tradePriceLimitTicksNonContinuous?:
    | TradePriceLimitsTicks
    | undefined;
  /** Trade price limits in percent per price sources for continuous trading. */
  tradePriceLimitPercentContinuous?:
    | TradePriceLimitsPercent
    | undefined;
  /** Trade price limits in percent per price sources for non-continuous trading. */
  tradePriceLimitPercentNonContinuous?:
    | TradePriceLimitsPercent
    | undefined;
  /** Order placement date plus this amount of days must be more than contract last trading date. */
  lastTradingDateLimit?:
    | LimitValue
    | undefined;
  /**
   * Optional commodity position limit.
   * It is not used (and does not make sense) on instrument type level of settings.
   */
  commodityPositionLimit?:
    | LimitValueDecimal
    | undefined;
  /**
   * Instrument position limit.
   * It is not applicable for some instrument types.
   */
  instrumentPositionLimit?:
    | LimitValueDecimal
    | undefined;
  /**
   * Contract position limit.
   * It is not applicable for some instrument types.
   */
  contractPositionLimit?:
    | LimitValueDecimal
    | undefined;
  /**
   * Trade size limit.
   * It is not applicable for some instrument types.
   */
  tradeSizeLimit?:
    | LimitValueDecimal
    | undefined;
  /**
   * Indicates if selling before buying is restricted on level.
   * If null (not set) - default, actual value inherited from upper level.
   */
  longPositionsOnly?:
    | LimitValueBoolean
    | undefined;
  /**
   * Commodity gross open position limit.
   * It is not applicable for instrument types.
   */
  commodityGrossOpenPositionLimit?: LimitValueDecimal | undefined;
}

/** Trade price limits in ticks per price sources. */
export interface TradePriceLimitsTicks {
  /** Bid/Ask price source. */
  bidAsk?:
    | LimitValue
    | undefined;
  /**
   * The last trade price source.
   * Not used for non-continuous trading.
   */
  last?:
    | LimitValue
    | undefined;
  /**
   * Open (for continuous) price source - the first trading price at the beginning of the trading day.
   * Indicative open (for non-continuous) price source - the proposed open price at the beginning of the next trading day.
   */
  open?:
    | LimitValue
    | undefined;
  /** Settlement price source - an official price established at the end of each trading day. */
  settlement?: LimitValue | undefined;
}

/** Trade price limits in percent per price sources. */
export interface TradePriceLimitsPercent {
  /** Bid/Ask price source. */
  bidAsk?:
    | LimitValueDouble
    | undefined;
  /**
   * The last trade price source.
   * Not used for non-continuous trading.
   */
  last?:
    | LimitValueDouble
    | undefined;
  /**
   * Open (for continuous) price source - the first trading price at the beginning of the trading day.
   * Indicative open (for non-continuous) price source - the proposed open price at the beginning of the next trading day.
   */
  open?:
    | LimitValueDouble
    | undefined;
  /** Settlement price source - an official price established at the end of each trading day. */
  settlement?: LimitValueDouble | undefined;
}

/** Exchange group level limits. */
export interface ExchangeMarketLimits {
  /** Allows to clear (set to default) all limits on exchange level (default_market_limits) and below (commodity_market_limits). */
  clearedFields: number[];
  /**
   * Trade routing system identifier of an exchange group.
   * Can be obtained via AccountAvailableExchangeGroupsRequest.
   * Corresponds to ExchangeGroup.id field.
   */
  exchangeGroupId: number;
  /** [erasable] Default exchange group level limits. */
  defaultMarketLimits?:
    | MarketLimitsRecord
    | undefined;
  /** [erasable] List of fungible commodity level limits. */
  commodityMarketLimits: CommodityMarketLimits[];
}

/** Fungible commodity level limits. */
export interface CommodityMarketLimits {
  /** Allows to clear (set to default) all limits of this commodity level and below (instrument_market_limits). */
  clearedFields: number[];
  /**
   * Fungible commodity ID.
   * Can be obtained via AccountAvailableExchangeGroupsRequest.
   * Corresponds to FungibleCommodity.id field.
   */
  fungibleCommodityId: number;
  /**
   * [obsolete] List of allowed to trade tradable commodity ids.
   *
   * @deprecated
   */
  obsoleteTradableCommodityId: string[];
  /** [erasable] Default commodity level position and Trade limits. */
  positionAndTradeLimits?:
    | PositionAndTradeLimits
    | undefined;
  /** [erasable] List of instrument type level limits. */
  instrumentMarketLimits: InstrumentMarketLimits[];
  /**
   * [erasable] List of non-default allowed to trade configuration of commodities.
   * EntityAllowedToTrade.id is commodity_id (aka XBit).
   */
  allowedToTradeCommodities: EntityAllowedToTrade[];
  /**
   * Commodity margin multiplier. Allowed values (0.001..100).
   * It is applied to all commodities with same margin_group_id even if specified only for one commodity from the group.
   * Can be enforced (locked) on FCM level.
   */
  marginMultiplier?: LimitValueDouble | undefined;
}

/** Instrument type level limits. */
export interface InstrumentMarketLimits {
  /** Allows to clear (set to default) all limits of this instrument. */
  clearedFields: number[];
  /**
   * ID of the instrument type.
   * Can be obtained via AccountAvailableExchangeGroupsRequest.
   * Corresponds to InstrumentType.id field.
   */
  instrumentTypeId: number;
  /** [erasable] Instrument type level position and Trade limits. */
  positionAndTradeLimits?:
    | PositionAndTradeLimits
    | undefined;
  /** [erasable] List of non-default allowed to trade configuration of commodities. */
  allowedToTradeCommodities: EntityAllowedToTrade[];
  /** [erasable] List of non-default allowed to trade configuration of contracts. */
  allowedToTradeContracts: EntityAllowedToTrade[];
}

/** Represents any entity that can be allowed/disallowed to trade. */
export interface EntityAllowedToTrade {
  /** Allows to clear allowed_to_trade field. */
  clearedFields: number[];
  /**
   * Entity identifier. Can be ID of commodity or contract.
   * Commodity ID can be obtained via AccountAvailableExchangeGroupsRequest
   * and corresponds to TradableCommodity.id field.
   */
  id?:
    | string
    | undefined;
  /**
   * [erasable] Indicates if entity is allowed to trade.
   * Values: null (not set) - default, actual value inherited from upper level.
   *         false - explicitly disallowed, no matter what value is on upper level.
   *         true - explicitly allowed, no matter what value is on upper level.
   */
  allowedToTrade?:
    | boolean
    | undefined;
  /**
   * Enforce All brokerage accounts to use this setting.
   * Only applicable for Brokerage level configuration.
   */
  enforce?: boolean | undefined;
}

/** Contract positions. */
export interface ContractPosition {
  /** Contract symbol (Full contract name). */
  symbol: string;
  /** True if open positions are short (result of sell operations), long otherwise. */
  isShortOpenPosition: boolean;
  /**
   * List of open positions.
   * There could be more than one position per contract separating contributed trades on different price levels.
   */
  openPosition: OpenPosition[];
  /** Contract id in case of grouping by contract. */
  contractId?: string | undefined;
}

/** Contract open position. */
export interface OpenPosition {
  /**
   * [obsolete] Use qty field instead.
   *
   * @deprecated
   */
  obsoleteQty?:
    | number
    | undefined;
  /**
   * Position average price.
   * NOTE: Since it could be an aggregated position price is sent in correct format directly.
   */
  price: number;
  /** Exchange specific trade date when the position was open or last changed (date only value). */
  tradeDate: number;
  /** Statement date (date value only). */
  statementDate: number;
  /** UTC trade time (including date) if available, it might not be available e.g. for the previous day or aggregated positions. */
  tradeUtcTime?:
    | number
    | undefined;
  /**
   * Position ID (status=POSITION) or order request ID (status=WORKING_ORDER)
   * associated with the position (if statement hasn't run yet).
   */
  positionId?:
    | string
    | undefined;
  /**
   * Indicates whether it is 'start of the day' or 'intraday' (working order) position.
   * The value is associated with OpenPosition.PositionStatus enum.
   */
  status?:
    | number
    | undefined;
  /** Position size, zero means that this position is deleted. */
  qty?:
    | Decimal
    | undefined;
  /**
   * [obsolete] Currency code (internal format).
   * Use currency_iso_code field instead.
   *
   * @deprecated
   */
  obsoleteCurrencyCode?:
    | string
    | undefined;
  /**
   * Position speculation type.
   * This field is associated with OrderChain.SpeculationType enum type.
   */
  speculationType?:
    | number
    | undefined;
  /** Associated order chain in case of status = WORKING_ORDER. */
  orderChainId?:
    | string
    | undefined;
  /** Position display price. */
  displayPrice?:
    | number
    | undefined;
  /** Currency code of position (ISO 4217 based). */
  currencyIsoCode?: string | undefined;
}

export enum OpenPosition_PositionStatus {
  /** POSITION - 'Start of the day' position. Statement was run. */
  POSITION = 1,
  /**
   * WORKING_ORDER - 'Intraday' position. Order request was filled in current trading day.
   * Until statement, currency fills also included here.
   */
  WORKING_ORDER = 2,
  UNRECOGNIZED = -1,
}

export function openPosition_PositionStatusFromJSON(object: any): OpenPosition_PositionStatus {
  switch (object) {
    case 1:
    case "POSITION":
      return OpenPosition_PositionStatus.POSITION;
    case 2:
    case "WORKING_ORDER":
      return OpenPosition_PositionStatus.WORKING_ORDER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OpenPosition_PositionStatus.UNRECOGNIZED;
  }
}

export function openPosition_PositionStatusToJSON(object: OpenPosition_PositionStatus): string {
  switch (object) {
    case OpenPosition_PositionStatus.POSITION:
      return "POSITION";
    case OpenPosition_PositionStatus.WORKING_ORDER:
      return "WORKING_ORDER";
    case OpenPosition_PositionStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Equity. */
export interface Equity {
  /** Currency code of equity values (ISO 4217 based). */
  currency: string;
  /** Securities on deposit. Margin security values net of haircut. */
  securities: number;
  /** Cash Balance excluding open futures profits/losses, option market values, and securities on deposit. */
  balance: number;
  /** Total open trade equity. Open profits/losses on all futures positions. */
  ote: number;
  /** Ending balance + total open trade equity. */
  totalEquity: number;
  /** Mark to market value of option positions. */
  mvo: number;
  /** Total equity + option market value + securities. */
  totalValue: number;
  /** Liquid funds available -- can be withdrawn from account. */
  cashExcess?:
    | number
    | undefined;
  /**
   * Implied initial margin requirement.
   * Equity required to establish current positions based on margin requirements.
   */
  initialMargin?:
    | number
    | undefined;
  /**
   * Implied maintenance margin requirement.
   * Minimum equity required to maintain current positions based on margin requirements.
   */
  maintMargin?:
    | number
    | undefined;
  /** The sum of total equity and securities on deposit. */
  postedMargin?: number | undefined;
}

/** Collateral (margin details). */
export interface Collateral {
  /** Currency code of margin and PP values (ISO 4217 based). */
  currency: string;
  /** Current total margin. */
  totalMargin: number;
  /**
   * Available account funds including balance, realized profit (or loss), collateral and credits.
   * NOTE: OTE and MVO are not included, regardless of account risk parameters.
   */
  purchasingPower: number;
}

/** Information about one of the found orders. */
export interface OrderSearchResultRecord {
  /** Brokerage account number. */
  brokerageAccountNumber: string;
  /** Order ID. */
  orderId: string;
  /** Ticket number. */
  ticketNumber?:
    | string
    | undefined;
  /**
   * Order side.
   * The value is associated with OrderChain.Side enum.
   */
  side: number;
  /**
   * Order type.
   * The value is associated with OrderChain.OrderType enum.
   */
  type: number;
  /**
   * Order limit price.
   * Correct price format.
   */
  limitPrice?:
    | number
    | undefined;
  /**
   * Order stop price.
   * Correct price format.
   */
  stopPrice?:
    | number
    | undefined;
  /** Contract name. */
  contractName: string;
  /** Time when order was submitted to execution system (UTC). */
  submittedUtcTime: number;
  /**
   * Order chain status.
   * This field is associated with OrderChain.Status enum type.
   */
  status: number;
  /** User name. */
  userName?:
    | string
    | undefined;
  /** Last name of customer to whom belongs login that placed the order. */
  customerLastName?:
    | string
    | undefined;
  /** Brokerage name. */
  brokerageName: string;
  /** Route name. */
  routeName: string;
  /** Trade routing account identifier. */
  accountId: number;
  /** Account name. */
  accountName: string;
  /** Native route name. */
  nativeRouteName?:
    | string
    | undefined;
  /** Route client order ID. */
  routeClientOrderId: string;
  /** Exchange name. */
  exchangeName: string;
  /** Chain order id. */
  chainOrderId?:
    | string
    | undefined;
  /**
   * Limit price of the order.
   * Display price format.
   */
  displayLimitPrice?:
    | number
    | undefined;
  /**
   * Stop price of the order.
   * Display price format.
   */
  displayStopPrice?:
    | number
    | undefined;
  /**
   * [obsolete] Use size field instead.
   *
   * @deprecated
   */
  obsoleteSize?:
    | number
    | undefined;
  /** Sales series number. */
  salesSeriesNumber?:
    | string
    | undefined;
  /** Sales series name. */
  salesSeriesName?:
    | string
    | undefined;
  /**
   * Id of sales series.
   * Use profile_sales_series_id instead.
   */
  salesSeriesId?:
    | string
    | undefined;
  /** Total positive size of the order. */
  size?:
    | Decimal
    | undefined;
  /** Profile id of sales series. */
  profileSalesSeriesId?:
    | string
    | undefined;
  /**
   * Order extended side.
   * This field is associated with ExtendedSide enum type.
   */
  extendedSide?:
    | number
    | undefined;
  /** Guid of batch request initiated this order request */
  batchRequestGuid?:
    | string
    | undefined;
  /**
   * Order request state.
   * This field is associated with OrderRequest.State enum type.
   */
  orderState?: number | undefined;
}

/** [obsolete] Account cluster. */
export interface AccountCluster {
  /**
   * Account cluster id.
   * Omit the value during account cluster creation.
   */
  id?:
    | string
    | undefined;
  /** Brokerage id the cluster points to. */
  brokerageId?:
    | string
    | undefined;
  /** Cluster name (max length = 256). */
  name?:
    | string
    | undefined;
  /**
   * True if the cluster is removed.
   * Set the value to true during update to remove this account cluster.
   */
  isRemoved?: boolean | undefined;
}

/** [obsolete] Account cluster price offsets per commodity and instrument type. */
export interface AccountClusterPriceOffset {
  /** List of field ids to clear during update operation. */
  clearedFields: number[];
  /** Commodity string id (aka Xbit). */
  commodityId?:
    | string
    | undefined;
  /** Trade routing system identifier of the instrument type. */
  instrumentTypeId?:
    | number
    | undefined;
  /** Price offset in ticks. */
  priceOffsetTicks?:
    | number
    | undefined;
  /** [erasable] Hedge order price offset in ticks. */
  hedgeOffsetTicks?:
    | number
    | undefined;
  /**
   * [erasable] List of execution instructions for hedge order.
   * Each field value is associated with OrderChain.ExecInstruction enum type.
   */
  hedgeExecInstruction: number[];
  /**
   * [erasable] Visible quantity of hedge iceberg order in percent.
   * The parameter is applied for hedge order with ICEBERG execution instruction.
   */
  icebergVisibleQtyPercent?: number | undefined;
}

/** Represents available route. */
export interface AvailableRoute {
  /** Route common information. */
  routeInformation?:
    | RouteInformation
    | undefined;
  /** Route priority. */
  priority?:
    | number
    | undefined;
  /** This filed is associated with OmnibusSupportType enum. */
  omnibusSupportType?:
    | number
    | undefined;
  /** Available omnibus accounts for the route. */
  omnibusAccounts: RouteOmnibusAccount[];
}

export enum AvailableRoute_OmnibusSupportType {
  PROHIBITED = 1,
  ALLOWED = 2,
  REQUIRED = 3,
  UNRECOGNIZED = -1,
}

export function availableRoute_OmnibusSupportTypeFromJSON(object: any): AvailableRoute_OmnibusSupportType {
  switch (object) {
    case 1:
    case "PROHIBITED":
      return AvailableRoute_OmnibusSupportType.PROHIBITED;
    case 2:
    case "ALLOWED":
      return AvailableRoute_OmnibusSupportType.ALLOWED;
    case 3:
    case "REQUIRED":
      return AvailableRoute_OmnibusSupportType.REQUIRED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AvailableRoute_OmnibusSupportType.UNRECOGNIZED;
  }
}

export function availableRoute_OmnibusSupportTypeToJSON(object: AvailableRoute_OmnibusSupportType): string {
  switch (object) {
    case AvailableRoute_OmnibusSupportType.PROHIBITED:
      return "PROHIBITED";
    case AvailableRoute_OmnibusSupportType.ALLOWED:
      return "ALLOWED";
    case AvailableRoute_OmnibusSupportType.REQUIRED:
      return "REQUIRED";
    case AvailableRoute_OmnibusSupportType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Represents omnibus account, configured on the route. */
export interface RouteOmnibusAccount {
  /** Omnibus account id. */
  accountId?:
    | string
    | undefined;
  /** Omnibus account name. */
  accountName?:
    | string
    | undefined;
  /** Omnibus brokerage account name. */
  brokerageAccountNumber?:
    | string
    | undefined;
  /** Omnibus account priority. */
  priority?:
    | number
    | undefined;
  /** Risk server instance id. */
  riskServerInstanceId?: string | undefined;
}

/** Represents route common information. */
export interface RouteInformation {
  /** Code number of the route. */
  routeCode?:
    | string
    | undefined;
  /** Route name. */
  routeName?:
    | string
    | undefined;
  /** If set, synthetic strategies are allowed. */
  syntheticStrategies?:
    | boolean
    | undefined;
  /** List of available route attributes. */
  attributes: AccountRouteAttribute[];
  /**
   * [obsolete] If set, route has overlapped instruments.
   *
   * @deprecated
   */
  obsoleteHasOverlappedInstruments?:
    | boolean
    | undefined;
  /** [immutable] If set, account requires authorization to use this route. */
  authorizationRequired?:
    | boolean
    | undefined;
  /** [immutable] Indicates whether this route supports synthetic orders. */
  allowSyntheticOrderTypes?:
    | boolean
    | undefined;
  /** List of available route algorithmic strategies allowed on particular route. */
  routeAlgoStrategies: RouteAlgoStrategy[];
  /**
   * [immutable] If set, account requires primary trade-routing login enabled to use this route.
   * Only one route with enabled this flag can be enabled on account.
   */
  primaryLoginRequired?: boolean | undefined;
}

/** Route algorithmic strategy. */
export interface RouteAlgoStrategy {
  /** Algorithmic strategy identifier allowed on particular route. */
  id?:
    | string
    | undefined;
  /** Algorithmic strategy name. */
  name?:
    | Text
    | undefined;
  /** Removed flag. */
  removed?: boolean | undefined;
}

/** Restore account. */
export interface RestoreAccount {
  /** [required] Account id to restore. */
  accountId?: string | undefined;
}

/** Account type overrides per exchange list. */
export interface AccountTypeOverrideListRequest {
  /** [required] Account id to get type overrides. */
  accountId?: string | undefined;
}

/** Update account type overrides list. */
export interface UpdateAccountTypeOverrideList {
  /** [required] Account id to update type overrides. */
  accountId?:
    | string
    | undefined;
  /** Overrides to add or change. */
  overridesToSet: AccountTypeOverride[];
  /**
   * Overrides to remove from account.
   * First - login id, second - exchange id.
   */
  overridesToRemove: Tuple[];
}

/** Result of RestoreAccount. */
export interface RestoreAccountResult {
}

/** Result of AccountTypeOverrideListRequest. */
export interface AccountTypeOverrideListResult {
  /** Account type overrides. */
  overrides: AccountTypeOverride[];
}

/**
 * Represents account type override per exchange.
 * Exchange ID + trade routing login ID pair is unique.
 */
export interface AccountTypeOverride {
  /**
   * [required] Exchange identifier.
   * LookupPropertyListRequest { property_type = common_1.CommonLookupPropertyType.EXCHANGE }
   */
  exchangeId?:
    | string
    | undefined;
  /**
   * Possible affected login.
   * If not provided, all logins are affected.
   */
  loginId?:
    | string
    | undefined;
  /**
   * [required] Account type override.
   * LookupPropertyListRequest { property_type = TradeRoutingLookupPropertyType.ACCOUNT_TYPE }
   */
  typeId?:
    | string
    | undefined;
  /**
   * [required] Account origin override.
   * LookupPropertyListRequest { property_type = TradeRoutingLookupPropertyType.ACCOUNT_ORIGIN }
   */
  originId?: string | undefined;
}

/** Result of UpdateAccountTypeOverrideList. */
export interface UpdateAccountTypeOverrideListResult {
}

/** Update account user authorization list operation. */
export interface UpdateAccountUserAuthorizationList {
  /**
   * Links to add or modify.
   * Authorization is added if it does not exist with this account and user id, it is updated otherwise.
   */
  linksToSet: AccountUserLink[];
  /** Links to remove. First - account id [required], second - user id [required]. */
  linksToRemove: Tuple[];
}

/** Result of account user authorization list update. */
export interface UpdateAccountUserAuthorizationListResult {
}

/** Remove account. */
export interface RemoveAccount {
  /** [required] Account id to remove. */
  accountId?: string | undefined;
}

/** Result of RemoveAccount. */
export interface RemoveAccountResult {
}

/** Gets available to account Service Groups according to enabled routes. */
export interface AccountAvailableServiceGroupListRequest {
  /** [required] Account id. */
  accountId?: string | undefined;
}

/** Gets account's enabled Service Groups. */
export interface AccountServiceGroupListRequest {
  /** [required] Account id. */
  accountId?: string | undefined;
}

/** Updates list of account's enabled Service Groups. */
export interface UpdateAccountServiceGroupList {
  /** [required] Account id. */
  accountId?:
    | string
    | undefined;
  /** Service Groups to enable */
  serviceGroupIdsToSet: string[];
  /** Service Groups to remove from account. */
  serviceGroupIdsToRemove: string[];
}

/** Result of AccountAvailableServiceGroupListRequest. */
export interface AccountAvailableServiceGroupListResult {
  /** Currently available to account Service Group list. */
  serviceGroups: ServiceGroup[];
}

/** Represents Service Group entity. */
export interface ServiceGroup {
  /** [immutable] Service Group's id. */
  id?:
    | string
    | undefined;
  /** [immutable] Name. */
  name?:
    | string
    | undefined;
  /** [immutable] Removed flag. */
  removed?: boolean | undefined;
}

/** Result of AccountServiceGroupListRequest. */
export interface AccountServiceGroupListResult {
  /** Enabled Service Groups. */
  serviceGroupIds: string[];
}

/** Result of UpdateAccountServiceGroupList. */
export interface UpdateAccountServiceGroupListResult {
}

/** Gets billable exchanges available to the current admin login. */
export interface BillableExchangeListRequest {
}

/** Result of BillableExchangeListRequest. */
export interface BillableExchangeListResult {
  billableExchanges: BillableExchange[];
}

export interface BillableExchange {
  /** Billable Exchange's id. */
  number?:
    | string
    | undefined;
  /** Name. */
  name?:
    | string
    | undefined;
  /** Description. */
  description?:
    | string
    | undefined;
  /**
   * Service Groups authorization level (Amount Allowed per Exchange).
   * This field is associated with ServiceGroupAuthorizationLevel enum.
   */
  serviceGroupAuthorizationLevel?:
    | number
    | undefined;
  /** Linked Service Groups. */
  serviceGroupIds: string[];
}

export interface CreateAccountGroup {
  newAccountGroup?: AccountGroup | undefined;
}

export interface CreateAccountGroupResult {
  /** Created account group id. */
  newAccountGroupId?: string | undefined;
}

export interface UpdateAccountGroup {
  /** [required] Account group to update. */
  accountGroup?:
    | AccountGroup
    | undefined;
  /** Sub-accounts to add/update. */
  subAccountsToSet: SubAccount[];
  /** Sub-accounts to remove. */
  subAccountIdsToRemove: string[];
}

export interface RemoveAccountGroup {
  /** [required] Account group id to remove. */
  accountGroupId?: string | undefined;
}

export interface DeleteAccountPositions {
  /**
   * ID of the account with positions data to delete.
   * Mutually exclusive with position_ids field.
   */
  accountId?:
    | string
    | undefined;
  /**
   * Specific position identifiers to delete.
   * Mutually exclusive with account_id field.
   */
  positionIds: string[];
}

export interface AccountTradingFeaturesRequest {
  /** [required] ID of the account. */
  accountId?: string | undefined;
}

export interface AccountTradingFeaturesResult {
  accountTradingInterfaceElements: AccountTradingInterfaceElement[];
}

export interface UpdateAccountTradingFeatures {
  /** [required] ID of the account. */
  accountId?: string | undefined;
  tradingInterfaceElementsToSet: AccountTradingInterfaceElement[];
  tradingInterfaceElementIdsToRemove: string[];
}

export interface AccountTradingInterfaceElement {
  /**
   * [required-update] ID of the trading interface element.
   * List of available Interface Elements with corresponding ID's
   * can be obtained with cmsapi_1.TradingFeaturesRequest.
   */
  id?:
    | string
    | undefined;
  /**
   * Indicates if trading interface element is enabled for the account.
   * Values: false - explicitly disabled.
   *         true - explicitly enabled.
   */
  enabled?: boolean | undefined;
}

function createBaseTradeRoutingRequest(): TradeRoutingRequest {
  return { id: 0, accountScopeRequest: undefined, orderScopeRequest: undefined, lookupPropertyListRequest: undefined };
}

export const TradeRoutingRequest = {
  encode(message: TradeRoutingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).uint32(message.id);
    }
    if (message.accountScopeRequest !== undefined) {
      AccountScopeRequest.encode(message.accountScopeRequest, writer.uint32(18).fork()).ldelim();
    }
    if (message.orderScopeRequest !== undefined) {
      OrderScopeRequest.encode(message.orderScopeRequest, writer.uint32(26).fork()).ldelim();
    }
    if (message.lookupPropertyListRequest !== undefined) {
      LookupPropertyListRequest.encode(message.lookupPropertyListRequest, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradeRoutingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradeRoutingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountScopeRequest = AccountScopeRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orderScopeRequest = OrderScopeRequest.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lookupPropertyListRequest = LookupPropertyListRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradeRoutingRequest {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      accountScopeRequest: isSet(object.accountScopeRequest)
        ? AccountScopeRequest.fromJSON(object.accountScopeRequest)
        : undefined,
      orderScopeRequest: isSet(object.orderScopeRequest)
        ? OrderScopeRequest.fromJSON(object.orderScopeRequest)
        : undefined,
      lookupPropertyListRequest: isSet(object.lookupPropertyListRequest)
        ? LookupPropertyListRequest.fromJSON(object.lookupPropertyListRequest)
        : undefined,
    };
  },

  toJSON(message: TradeRoutingRequest): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.accountScopeRequest !== undefined) {
      obj.accountScopeRequest = AccountScopeRequest.toJSON(message.accountScopeRequest);
    }
    if (message.orderScopeRequest !== undefined) {
      obj.orderScopeRequest = OrderScopeRequest.toJSON(message.orderScopeRequest);
    }
    if (message.lookupPropertyListRequest !== undefined) {
      obj.lookupPropertyListRequest = LookupPropertyListRequest.toJSON(message.lookupPropertyListRequest);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradeRoutingRequest>, I>>(base?: I): TradeRoutingRequest {
    return TradeRoutingRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradeRoutingRequest>, I>>(object: I): TradeRoutingRequest {
    const message = createBaseTradeRoutingRequest();
    message.id = object.id ?? 0;
    message.accountScopeRequest = (object.accountScopeRequest !== undefined && object.accountScopeRequest !== null)
      ? AccountScopeRequest.fromPartial(object.accountScopeRequest)
      : undefined;
    message.orderScopeRequest = (object.orderScopeRequest !== undefined && object.orderScopeRequest !== null)
      ? OrderScopeRequest.fromPartial(object.orderScopeRequest)
      : undefined;
    message.lookupPropertyListRequest =
      (object.lookupPropertyListRequest !== undefined && object.lookupPropertyListRequest !== null)
        ? LookupPropertyListRequest.fromPartial(object.lookupPropertyListRequest)
        : undefined;
    return message;
  },
};

function createBaseAccountScopeRequest(): AccountScopeRequest {
  return {
    accountInfoRequest: undefined,
    balanceRecordsRequest: undefined,
    createBalanceRecord: undefined,
    updateBalanceRecord: undefined,
    accountRiskParametersRequest: undefined,
    obsoleteUpdateRiskParameters: undefined,
    accountSearchRequest: undefined,
    accountRouteListRequest: undefined,
    accountUserAuthorizationListRequest: undefined,
    accountSettingsRequest: undefined,
    accountAvailableExchangeGroupsRequest: undefined,
    accountMarketLimitsRequest: undefined,
    accountPositionsRequest: undefined,
    accountEquityRequest: undefined,
    accountCollateralRequest: undefined,
    cloneAccount: undefined,
    updateAccount: undefined,
    updateAccountSettings: undefined,
    accountGroupRequest: undefined,
    updateAccountRiskParameters: undefined,
    accountAvailableRouteListRequest: undefined,
    updateAccountRouteList: undefined,
    updateAccountMarketLimits: undefined,
    createAccount: undefined,
    obsoleteAccountClusterRequest: undefined,
    obsoleteCreateAccountCluster: undefined,
    obsoleteUpdateAccountCluster: undefined,
    obsoleteUpdateAccountClusterPriceOffsets: undefined,
    restoreAccount: undefined,
    accountTypeOverrideListRequest: undefined,
    updateAccountTypeOverrideList: undefined,
    updateAccountUserAuthorizationList: undefined,
    removeAccount: undefined,
    accountAvailableServiceGroupListRequest: undefined,
    accountServiceGroupListRequest: undefined,
    updateAccountServiceGroupList: undefined,
    billableExchangeListRequest: undefined,
    createAccountGroup: undefined,
    updateAccountGroup: undefined,
    removeAccountGroup: undefined,
    deleteAccountPositions: undefined,
    accountTradingFeaturesRequest: undefined,
    updateAccountTradingFeatures: undefined,
  };
}

export const AccountScopeRequest = {
  encode(message: AccountScopeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountInfoRequest !== undefined) {
      AccountInfoRequest.encode(message.accountInfoRequest, writer.uint32(10).fork()).ldelim();
    }
    if (message.balanceRecordsRequest !== undefined) {
      BalanceRecordsRequest.encode(message.balanceRecordsRequest, writer.uint32(18).fork()).ldelim();
    }
    if (message.createBalanceRecord !== undefined) {
      CreateBalanceRecord.encode(message.createBalanceRecord, writer.uint32(26).fork()).ldelim();
    }
    if (message.updateBalanceRecord !== undefined) {
      UpdateBalanceRecord.encode(message.updateBalanceRecord, writer.uint32(34).fork()).ldelim();
    }
    if (message.accountRiskParametersRequest !== undefined) {
      AccountRiskParametersRequest.encode(message.accountRiskParametersRequest, writer.uint32(42).fork()).ldelim();
    }
    if (message.obsoleteUpdateRiskParameters !== undefined) {
      UpdateRiskParameters.encode(message.obsoleteUpdateRiskParameters, writer.uint32(50).fork()).ldelim();
    }
    if (message.accountSearchRequest !== undefined) {
      AccountSearchRequest.encode(message.accountSearchRequest, writer.uint32(58).fork()).ldelim();
    }
    if (message.accountRouteListRequest !== undefined) {
      AccountRouteListRequest.encode(message.accountRouteListRequest, writer.uint32(66).fork()).ldelim();
    }
    if (message.accountUserAuthorizationListRequest !== undefined) {
      AccountUserAuthorizationListRequest.encode(message.accountUserAuthorizationListRequest, writer.uint32(74).fork())
        .ldelim();
    }
    if (message.accountSettingsRequest !== undefined) {
      AccountSettingsRequest.encode(message.accountSettingsRequest, writer.uint32(82).fork()).ldelim();
    }
    if (message.accountAvailableExchangeGroupsRequest !== undefined) {
      AccountAvailableExchangeGroupsRequest.encode(
        message.accountAvailableExchangeGroupsRequest,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.accountMarketLimitsRequest !== undefined) {
      AccountMarketLimitsRequest.encode(message.accountMarketLimitsRequest, writer.uint32(98).fork()).ldelim();
    }
    if (message.accountPositionsRequest !== undefined) {
      AccountPositionsRequest.encode(message.accountPositionsRequest, writer.uint32(106).fork()).ldelim();
    }
    if (message.accountEquityRequest !== undefined) {
      AccountEquityRequest.encode(message.accountEquityRequest, writer.uint32(114).fork()).ldelim();
    }
    if (message.accountCollateralRequest !== undefined) {
      AccountCollateralRequest.encode(message.accountCollateralRequest, writer.uint32(122).fork()).ldelim();
    }
    if (message.cloneAccount !== undefined) {
      CloneAccount.encode(message.cloneAccount, writer.uint32(130).fork()).ldelim();
    }
    if (message.updateAccount !== undefined) {
      UpdateAccount.encode(message.updateAccount, writer.uint32(138).fork()).ldelim();
    }
    if (message.updateAccountSettings !== undefined) {
      UpdateAccountSettings.encode(message.updateAccountSettings, writer.uint32(146).fork()).ldelim();
    }
    if (message.accountGroupRequest !== undefined) {
      AccountGroupRequest.encode(message.accountGroupRequest, writer.uint32(154).fork()).ldelim();
    }
    if (message.updateAccountRiskParameters !== undefined) {
      UpdateAccountRiskParameters.encode(message.updateAccountRiskParameters, writer.uint32(162).fork()).ldelim();
    }
    if (message.accountAvailableRouteListRequest !== undefined) {
      AccountAvailableRouteListRequest.encode(message.accountAvailableRouteListRequest, writer.uint32(170).fork())
        .ldelim();
    }
    if (message.updateAccountRouteList !== undefined) {
      UpdateAccountRouteList.encode(message.updateAccountRouteList, writer.uint32(178).fork()).ldelim();
    }
    if (message.updateAccountMarketLimits !== undefined) {
      UpdateAccountMarketLimits.encode(message.updateAccountMarketLimits, writer.uint32(186).fork()).ldelim();
    }
    if (message.createAccount !== undefined) {
      CreateAccount.encode(message.createAccount, writer.uint32(194).fork()).ldelim();
    }
    if (message.obsoleteAccountClusterRequest !== undefined) {
      AccountClusterRequest.encode(message.obsoleteAccountClusterRequest, writer.uint32(202).fork()).ldelim();
    }
    if (message.obsoleteCreateAccountCluster !== undefined) {
      CreateAccountCluster.encode(message.obsoleteCreateAccountCluster, writer.uint32(210).fork()).ldelim();
    }
    if (message.obsoleteUpdateAccountCluster !== undefined) {
      UpdateAccountCluster.encode(message.obsoleteUpdateAccountCluster, writer.uint32(218).fork()).ldelim();
    }
    if (message.obsoleteUpdateAccountClusterPriceOffsets !== undefined) {
      UpdateAccountClusterPriceOffsets.encode(
        message.obsoleteUpdateAccountClusterPriceOffsets,
        writer.uint32(226).fork(),
      ).ldelim();
    }
    if (message.restoreAccount !== undefined) {
      RestoreAccount.encode(message.restoreAccount, writer.uint32(234).fork()).ldelim();
    }
    if (message.accountTypeOverrideListRequest !== undefined) {
      AccountTypeOverrideListRequest.encode(message.accountTypeOverrideListRequest, writer.uint32(242).fork()).ldelim();
    }
    if (message.updateAccountTypeOverrideList !== undefined) {
      UpdateAccountTypeOverrideList.encode(message.updateAccountTypeOverrideList, writer.uint32(250).fork()).ldelim();
    }
    if (message.updateAccountUserAuthorizationList !== undefined) {
      UpdateAccountUserAuthorizationList.encode(message.updateAccountUserAuthorizationList, writer.uint32(258).fork())
        .ldelim();
    }
    if (message.removeAccount !== undefined) {
      RemoveAccount.encode(message.removeAccount, writer.uint32(266).fork()).ldelim();
    }
    if (message.accountAvailableServiceGroupListRequest !== undefined) {
      AccountAvailableServiceGroupListRequest.encode(
        message.accountAvailableServiceGroupListRequest,
        writer.uint32(274).fork(),
      ).ldelim();
    }
    if (message.accountServiceGroupListRequest !== undefined) {
      AccountServiceGroupListRequest.encode(message.accountServiceGroupListRequest, writer.uint32(282).fork()).ldelim();
    }
    if (message.updateAccountServiceGroupList !== undefined) {
      UpdateAccountServiceGroupList.encode(message.updateAccountServiceGroupList, writer.uint32(290).fork()).ldelim();
    }
    if (message.billableExchangeListRequest !== undefined) {
      BillableExchangeListRequest.encode(message.billableExchangeListRequest, writer.uint32(298).fork()).ldelim();
    }
    if (message.createAccountGroup !== undefined) {
      CreateAccountGroup.encode(message.createAccountGroup, writer.uint32(306).fork()).ldelim();
    }
    if (message.updateAccountGroup !== undefined) {
      UpdateAccountGroup.encode(message.updateAccountGroup, writer.uint32(314).fork()).ldelim();
    }
    if (message.removeAccountGroup !== undefined) {
      RemoveAccountGroup.encode(message.removeAccountGroup, writer.uint32(322).fork()).ldelim();
    }
    if (message.deleteAccountPositions !== undefined) {
      DeleteAccountPositions.encode(message.deleteAccountPositions, writer.uint32(330).fork()).ldelim();
    }
    if (message.accountTradingFeaturesRequest !== undefined) {
      AccountTradingFeaturesRequest.encode(message.accountTradingFeaturesRequest, writer.uint32(338).fork()).ldelim();
    }
    if (message.updateAccountTradingFeatures !== undefined) {
      UpdateAccountTradingFeatures.encode(message.updateAccountTradingFeatures, writer.uint32(346).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountScopeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountScopeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountInfoRequest = AccountInfoRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.balanceRecordsRequest = BalanceRecordsRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createBalanceRecord = CreateBalanceRecord.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updateBalanceRecord = UpdateBalanceRecord.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.accountRiskParametersRequest = AccountRiskParametersRequest.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.obsoleteUpdateRiskParameters = UpdateRiskParameters.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.accountSearchRequest = AccountSearchRequest.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.accountRouteListRequest = AccountRouteListRequest.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.accountUserAuthorizationListRequest = AccountUserAuthorizationListRequest.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.accountSettingsRequest = AccountSettingsRequest.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.accountAvailableExchangeGroupsRequest = AccountAvailableExchangeGroupsRequest.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.accountMarketLimitsRequest = AccountMarketLimitsRequest.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.accountPositionsRequest = AccountPositionsRequest.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.accountEquityRequest = AccountEquityRequest.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.accountCollateralRequest = AccountCollateralRequest.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.cloneAccount = CloneAccount.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.updateAccount = UpdateAccount.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.updateAccountSettings = UpdateAccountSettings.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.accountGroupRequest = AccountGroupRequest.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.updateAccountRiskParameters = UpdateAccountRiskParameters.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.accountAvailableRouteListRequest = AccountAvailableRouteListRequest.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.updateAccountRouteList = UpdateAccountRouteList.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.updateAccountMarketLimits = UpdateAccountMarketLimits.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.createAccount = CreateAccount.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.obsoleteAccountClusterRequest = AccountClusterRequest.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.obsoleteCreateAccountCluster = CreateAccountCluster.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.obsoleteUpdateAccountCluster = UpdateAccountCluster.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.obsoleteUpdateAccountClusterPriceOffsets = UpdateAccountClusterPriceOffsets.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.restoreAccount = RestoreAccount.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.accountTypeOverrideListRequest = AccountTypeOverrideListRequest.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.updateAccountTypeOverrideList = UpdateAccountTypeOverrideList.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.updateAccountUserAuthorizationList = UpdateAccountUserAuthorizationList.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.removeAccount = RemoveAccount.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.accountAvailableServiceGroupListRequest = AccountAvailableServiceGroupListRequest.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.accountServiceGroupListRequest = AccountServiceGroupListRequest.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.updateAccountServiceGroupList = UpdateAccountServiceGroupList.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.billableExchangeListRequest = BillableExchangeListRequest.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.createAccountGroup = CreateAccountGroup.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.updateAccountGroup = UpdateAccountGroup.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.removeAccountGroup = RemoveAccountGroup.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.deleteAccountPositions = DeleteAccountPositions.decode(reader, reader.uint32());
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.accountTradingFeaturesRequest = AccountTradingFeaturesRequest.decode(reader, reader.uint32());
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.updateAccountTradingFeatures = UpdateAccountTradingFeatures.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountScopeRequest {
    return {
      accountInfoRequest: isSet(object.accountInfoRequest)
        ? AccountInfoRequest.fromJSON(object.accountInfoRequest)
        : undefined,
      balanceRecordsRequest: isSet(object.balanceRecordsRequest)
        ? BalanceRecordsRequest.fromJSON(object.balanceRecordsRequest)
        : undefined,
      createBalanceRecord: isSet(object.createBalanceRecord)
        ? CreateBalanceRecord.fromJSON(object.createBalanceRecord)
        : undefined,
      updateBalanceRecord: isSet(object.updateBalanceRecord)
        ? UpdateBalanceRecord.fromJSON(object.updateBalanceRecord)
        : undefined,
      accountRiskParametersRequest: isSet(object.accountRiskParametersRequest)
        ? AccountRiskParametersRequest.fromJSON(object.accountRiskParametersRequest)
        : undefined,
      obsoleteUpdateRiskParameters: isSet(object.obsoleteUpdateRiskParameters)
        ? UpdateRiskParameters.fromJSON(object.obsoleteUpdateRiskParameters)
        : undefined,
      accountSearchRequest: isSet(object.accountSearchRequest)
        ? AccountSearchRequest.fromJSON(object.accountSearchRequest)
        : undefined,
      accountRouteListRequest: isSet(object.accountRouteListRequest)
        ? AccountRouteListRequest.fromJSON(object.accountRouteListRequest)
        : undefined,
      accountUserAuthorizationListRequest: isSet(object.accountUserAuthorizationListRequest)
        ? AccountUserAuthorizationListRequest.fromJSON(object.accountUserAuthorizationListRequest)
        : undefined,
      accountSettingsRequest: isSet(object.accountSettingsRequest)
        ? AccountSettingsRequest.fromJSON(object.accountSettingsRequest)
        : undefined,
      accountAvailableExchangeGroupsRequest: isSet(object.accountAvailableExchangeGroupsRequest)
        ? AccountAvailableExchangeGroupsRequest.fromJSON(object.accountAvailableExchangeGroupsRequest)
        : undefined,
      accountMarketLimitsRequest: isSet(object.accountMarketLimitsRequest)
        ? AccountMarketLimitsRequest.fromJSON(object.accountMarketLimitsRequest)
        : undefined,
      accountPositionsRequest: isSet(object.accountPositionsRequest)
        ? AccountPositionsRequest.fromJSON(object.accountPositionsRequest)
        : undefined,
      accountEquityRequest: isSet(object.accountEquityRequest)
        ? AccountEquityRequest.fromJSON(object.accountEquityRequest)
        : undefined,
      accountCollateralRequest: isSet(object.accountCollateralRequest)
        ? AccountCollateralRequest.fromJSON(object.accountCollateralRequest)
        : undefined,
      cloneAccount: isSet(object.cloneAccount) ? CloneAccount.fromJSON(object.cloneAccount) : undefined,
      updateAccount: isSet(object.updateAccount) ? UpdateAccount.fromJSON(object.updateAccount) : undefined,
      updateAccountSettings: isSet(object.updateAccountSettings)
        ? UpdateAccountSettings.fromJSON(object.updateAccountSettings)
        : undefined,
      accountGroupRequest: isSet(object.accountGroupRequest)
        ? AccountGroupRequest.fromJSON(object.accountGroupRequest)
        : undefined,
      updateAccountRiskParameters: isSet(object.updateAccountRiskParameters)
        ? UpdateAccountRiskParameters.fromJSON(object.updateAccountRiskParameters)
        : undefined,
      accountAvailableRouteListRequest: isSet(object.accountAvailableRouteListRequest)
        ? AccountAvailableRouteListRequest.fromJSON(object.accountAvailableRouteListRequest)
        : undefined,
      updateAccountRouteList: isSet(object.updateAccountRouteList)
        ? UpdateAccountRouteList.fromJSON(object.updateAccountRouteList)
        : undefined,
      updateAccountMarketLimits: isSet(object.updateAccountMarketLimits)
        ? UpdateAccountMarketLimits.fromJSON(object.updateAccountMarketLimits)
        : undefined,
      createAccount: isSet(object.createAccount) ? CreateAccount.fromJSON(object.createAccount) : undefined,
      obsoleteAccountClusterRequest: isSet(object.obsoleteAccountClusterRequest)
        ? AccountClusterRequest.fromJSON(object.obsoleteAccountClusterRequest)
        : undefined,
      obsoleteCreateAccountCluster: isSet(object.obsoleteCreateAccountCluster)
        ? CreateAccountCluster.fromJSON(object.obsoleteCreateAccountCluster)
        : undefined,
      obsoleteUpdateAccountCluster: isSet(object.obsoleteUpdateAccountCluster)
        ? UpdateAccountCluster.fromJSON(object.obsoleteUpdateAccountCluster)
        : undefined,
      obsoleteUpdateAccountClusterPriceOffsets: isSet(object.obsoleteUpdateAccountClusterPriceOffsets)
        ? UpdateAccountClusterPriceOffsets.fromJSON(object.obsoleteUpdateAccountClusterPriceOffsets)
        : undefined,
      restoreAccount: isSet(object.restoreAccount) ? RestoreAccount.fromJSON(object.restoreAccount) : undefined,
      accountTypeOverrideListRequest: isSet(object.accountTypeOverrideListRequest)
        ? AccountTypeOverrideListRequest.fromJSON(object.accountTypeOverrideListRequest)
        : undefined,
      updateAccountTypeOverrideList: isSet(object.updateAccountTypeOverrideList)
        ? UpdateAccountTypeOverrideList.fromJSON(object.updateAccountTypeOverrideList)
        : undefined,
      updateAccountUserAuthorizationList: isSet(object.updateAccountUserAuthorizationList)
        ? UpdateAccountUserAuthorizationList.fromJSON(object.updateAccountUserAuthorizationList)
        : undefined,
      removeAccount: isSet(object.removeAccount) ? RemoveAccount.fromJSON(object.removeAccount) : undefined,
      accountAvailableServiceGroupListRequest: isSet(object.accountAvailableServiceGroupListRequest)
        ? AccountAvailableServiceGroupListRequest.fromJSON(object.accountAvailableServiceGroupListRequest)
        : undefined,
      accountServiceGroupListRequest: isSet(object.accountServiceGroupListRequest)
        ? AccountServiceGroupListRequest.fromJSON(object.accountServiceGroupListRequest)
        : undefined,
      updateAccountServiceGroupList: isSet(object.updateAccountServiceGroupList)
        ? UpdateAccountServiceGroupList.fromJSON(object.updateAccountServiceGroupList)
        : undefined,
      billableExchangeListRequest: isSet(object.billableExchangeListRequest)
        ? BillableExchangeListRequest.fromJSON(object.billableExchangeListRequest)
        : undefined,
      createAccountGroup: isSet(object.createAccountGroup)
        ? CreateAccountGroup.fromJSON(object.createAccountGroup)
        : undefined,
      updateAccountGroup: isSet(object.updateAccountGroup)
        ? UpdateAccountGroup.fromJSON(object.updateAccountGroup)
        : undefined,
      removeAccountGroup: isSet(object.removeAccountGroup)
        ? RemoveAccountGroup.fromJSON(object.removeAccountGroup)
        : undefined,
      deleteAccountPositions: isSet(object.deleteAccountPositions)
        ? DeleteAccountPositions.fromJSON(object.deleteAccountPositions)
        : undefined,
      accountTradingFeaturesRequest: isSet(object.accountTradingFeaturesRequest)
        ? AccountTradingFeaturesRequest.fromJSON(object.accountTradingFeaturesRequest)
        : undefined,
      updateAccountTradingFeatures: isSet(object.updateAccountTradingFeatures)
        ? UpdateAccountTradingFeatures.fromJSON(object.updateAccountTradingFeatures)
        : undefined,
    };
  },

  toJSON(message: AccountScopeRequest): unknown {
    const obj: any = {};
    if (message.accountInfoRequest !== undefined) {
      obj.accountInfoRequest = AccountInfoRequest.toJSON(message.accountInfoRequest);
    }
    if (message.balanceRecordsRequest !== undefined) {
      obj.balanceRecordsRequest = BalanceRecordsRequest.toJSON(message.balanceRecordsRequest);
    }
    if (message.createBalanceRecord !== undefined) {
      obj.createBalanceRecord = CreateBalanceRecord.toJSON(message.createBalanceRecord);
    }
    if (message.updateBalanceRecord !== undefined) {
      obj.updateBalanceRecord = UpdateBalanceRecord.toJSON(message.updateBalanceRecord);
    }
    if (message.accountRiskParametersRequest !== undefined) {
      obj.accountRiskParametersRequest = AccountRiskParametersRequest.toJSON(message.accountRiskParametersRequest);
    }
    if (message.obsoleteUpdateRiskParameters !== undefined) {
      obj.obsoleteUpdateRiskParameters = UpdateRiskParameters.toJSON(message.obsoleteUpdateRiskParameters);
    }
    if (message.accountSearchRequest !== undefined) {
      obj.accountSearchRequest = AccountSearchRequest.toJSON(message.accountSearchRequest);
    }
    if (message.accountRouteListRequest !== undefined) {
      obj.accountRouteListRequest = AccountRouteListRequest.toJSON(message.accountRouteListRequest);
    }
    if (message.accountUserAuthorizationListRequest !== undefined) {
      obj.accountUserAuthorizationListRequest = AccountUserAuthorizationListRequest.toJSON(
        message.accountUserAuthorizationListRequest,
      );
    }
    if (message.accountSettingsRequest !== undefined) {
      obj.accountSettingsRequest = AccountSettingsRequest.toJSON(message.accountSettingsRequest);
    }
    if (message.accountAvailableExchangeGroupsRequest !== undefined) {
      obj.accountAvailableExchangeGroupsRequest = AccountAvailableExchangeGroupsRequest.toJSON(
        message.accountAvailableExchangeGroupsRequest,
      );
    }
    if (message.accountMarketLimitsRequest !== undefined) {
      obj.accountMarketLimitsRequest = AccountMarketLimitsRequest.toJSON(message.accountMarketLimitsRequest);
    }
    if (message.accountPositionsRequest !== undefined) {
      obj.accountPositionsRequest = AccountPositionsRequest.toJSON(message.accountPositionsRequest);
    }
    if (message.accountEquityRequest !== undefined) {
      obj.accountEquityRequest = AccountEquityRequest.toJSON(message.accountEquityRequest);
    }
    if (message.accountCollateralRequest !== undefined) {
      obj.accountCollateralRequest = AccountCollateralRequest.toJSON(message.accountCollateralRequest);
    }
    if (message.cloneAccount !== undefined) {
      obj.cloneAccount = CloneAccount.toJSON(message.cloneAccount);
    }
    if (message.updateAccount !== undefined) {
      obj.updateAccount = UpdateAccount.toJSON(message.updateAccount);
    }
    if (message.updateAccountSettings !== undefined) {
      obj.updateAccountSettings = UpdateAccountSettings.toJSON(message.updateAccountSettings);
    }
    if (message.accountGroupRequest !== undefined) {
      obj.accountGroupRequest = AccountGroupRequest.toJSON(message.accountGroupRequest);
    }
    if (message.updateAccountRiskParameters !== undefined) {
      obj.updateAccountRiskParameters = UpdateAccountRiskParameters.toJSON(message.updateAccountRiskParameters);
    }
    if (message.accountAvailableRouteListRequest !== undefined) {
      obj.accountAvailableRouteListRequest = AccountAvailableRouteListRequest.toJSON(
        message.accountAvailableRouteListRequest,
      );
    }
    if (message.updateAccountRouteList !== undefined) {
      obj.updateAccountRouteList = UpdateAccountRouteList.toJSON(message.updateAccountRouteList);
    }
    if (message.updateAccountMarketLimits !== undefined) {
      obj.updateAccountMarketLimits = UpdateAccountMarketLimits.toJSON(message.updateAccountMarketLimits);
    }
    if (message.createAccount !== undefined) {
      obj.createAccount = CreateAccount.toJSON(message.createAccount);
    }
    if (message.obsoleteAccountClusterRequest !== undefined) {
      obj.obsoleteAccountClusterRequest = AccountClusterRequest.toJSON(message.obsoleteAccountClusterRequest);
    }
    if (message.obsoleteCreateAccountCluster !== undefined) {
      obj.obsoleteCreateAccountCluster = CreateAccountCluster.toJSON(message.obsoleteCreateAccountCluster);
    }
    if (message.obsoleteUpdateAccountCluster !== undefined) {
      obj.obsoleteUpdateAccountCluster = UpdateAccountCluster.toJSON(message.obsoleteUpdateAccountCluster);
    }
    if (message.obsoleteUpdateAccountClusterPriceOffsets !== undefined) {
      obj.obsoleteUpdateAccountClusterPriceOffsets = UpdateAccountClusterPriceOffsets.toJSON(
        message.obsoleteUpdateAccountClusterPriceOffsets,
      );
    }
    if (message.restoreAccount !== undefined) {
      obj.restoreAccount = RestoreAccount.toJSON(message.restoreAccount);
    }
    if (message.accountTypeOverrideListRequest !== undefined) {
      obj.accountTypeOverrideListRequest = AccountTypeOverrideListRequest.toJSON(
        message.accountTypeOverrideListRequest,
      );
    }
    if (message.updateAccountTypeOverrideList !== undefined) {
      obj.updateAccountTypeOverrideList = UpdateAccountTypeOverrideList.toJSON(message.updateAccountTypeOverrideList);
    }
    if (message.updateAccountUserAuthorizationList !== undefined) {
      obj.updateAccountUserAuthorizationList = UpdateAccountUserAuthorizationList.toJSON(
        message.updateAccountUserAuthorizationList,
      );
    }
    if (message.removeAccount !== undefined) {
      obj.removeAccount = RemoveAccount.toJSON(message.removeAccount);
    }
    if (message.accountAvailableServiceGroupListRequest !== undefined) {
      obj.accountAvailableServiceGroupListRequest = AccountAvailableServiceGroupListRequest.toJSON(
        message.accountAvailableServiceGroupListRequest,
      );
    }
    if (message.accountServiceGroupListRequest !== undefined) {
      obj.accountServiceGroupListRequest = AccountServiceGroupListRequest.toJSON(
        message.accountServiceGroupListRequest,
      );
    }
    if (message.updateAccountServiceGroupList !== undefined) {
      obj.updateAccountServiceGroupList = UpdateAccountServiceGroupList.toJSON(message.updateAccountServiceGroupList);
    }
    if (message.billableExchangeListRequest !== undefined) {
      obj.billableExchangeListRequest = BillableExchangeListRequest.toJSON(message.billableExchangeListRequest);
    }
    if (message.createAccountGroup !== undefined) {
      obj.createAccountGroup = CreateAccountGroup.toJSON(message.createAccountGroup);
    }
    if (message.updateAccountGroup !== undefined) {
      obj.updateAccountGroup = UpdateAccountGroup.toJSON(message.updateAccountGroup);
    }
    if (message.removeAccountGroup !== undefined) {
      obj.removeAccountGroup = RemoveAccountGroup.toJSON(message.removeAccountGroup);
    }
    if (message.deleteAccountPositions !== undefined) {
      obj.deleteAccountPositions = DeleteAccountPositions.toJSON(message.deleteAccountPositions);
    }
    if (message.accountTradingFeaturesRequest !== undefined) {
      obj.accountTradingFeaturesRequest = AccountTradingFeaturesRequest.toJSON(message.accountTradingFeaturesRequest);
    }
    if (message.updateAccountTradingFeatures !== undefined) {
      obj.updateAccountTradingFeatures = UpdateAccountTradingFeatures.toJSON(message.updateAccountTradingFeatures);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountScopeRequest>, I>>(base?: I): AccountScopeRequest {
    return AccountScopeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountScopeRequest>, I>>(object: I): AccountScopeRequest {
    const message = createBaseAccountScopeRequest();
    message.accountInfoRequest = (object.accountInfoRequest !== undefined && object.accountInfoRequest !== null)
      ? AccountInfoRequest.fromPartial(object.accountInfoRequest)
      : undefined;
    message.balanceRecordsRequest =
      (object.balanceRecordsRequest !== undefined && object.balanceRecordsRequest !== null)
        ? BalanceRecordsRequest.fromPartial(object.balanceRecordsRequest)
        : undefined;
    message.createBalanceRecord = (object.createBalanceRecord !== undefined && object.createBalanceRecord !== null)
      ? CreateBalanceRecord.fromPartial(object.createBalanceRecord)
      : undefined;
    message.updateBalanceRecord = (object.updateBalanceRecord !== undefined && object.updateBalanceRecord !== null)
      ? UpdateBalanceRecord.fromPartial(object.updateBalanceRecord)
      : undefined;
    message.accountRiskParametersRequest =
      (object.accountRiskParametersRequest !== undefined && object.accountRiskParametersRequest !== null)
        ? AccountRiskParametersRequest.fromPartial(object.accountRiskParametersRequest)
        : undefined;
    message.obsoleteUpdateRiskParameters =
      (object.obsoleteUpdateRiskParameters !== undefined && object.obsoleteUpdateRiskParameters !== null)
        ? UpdateRiskParameters.fromPartial(object.obsoleteUpdateRiskParameters)
        : undefined;
    message.accountSearchRequest = (object.accountSearchRequest !== undefined && object.accountSearchRequest !== null)
      ? AccountSearchRequest.fromPartial(object.accountSearchRequest)
      : undefined;
    message.accountRouteListRequest =
      (object.accountRouteListRequest !== undefined && object.accountRouteListRequest !== null)
        ? AccountRouteListRequest.fromPartial(object.accountRouteListRequest)
        : undefined;
    message.accountUserAuthorizationListRequest =
      (object.accountUserAuthorizationListRequest !== undefined && object.accountUserAuthorizationListRequest !== null)
        ? AccountUserAuthorizationListRequest.fromPartial(object.accountUserAuthorizationListRequest)
        : undefined;
    message.accountSettingsRequest =
      (object.accountSettingsRequest !== undefined && object.accountSettingsRequest !== null)
        ? AccountSettingsRequest.fromPartial(object.accountSettingsRequest)
        : undefined;
    message.accountAvailableExchangeGroupsRequest =
      (object.accountAvailableExchangeGroupsRequest !== undefined &&
          object.accountAvailableExchangeGroupsRequest !== null)
        ? AccountAvailableExchangeGroupsRequest.fromPartial(object.accountAvailableExchangeGroupsRequest)
        : undefined;
    message.accountMarketLimitsRequest =
      (object.accountMarketLimitsRequest !== undefined && object.accountMarketLimitsRequest !== null)
        ? AccountMarketLimitsRequest.fromPartial(object.accountMarketLimitsRequest)
        : undefined;
    message.accountPositionsRequest =
      (object.accountPositionsRequest !== undefined && object.accountPositionsRequest !== null)
        ? AccountPositionsRequest.fromPartial(object.accountPositionsRequest)
        : undefined;
    message.accountEquityRequest = (object.accountEquityRequest !== undefined && object.accountEquityRequest !== null)
      ? AccountEquityRequest.fromPartial(object.accountEquityRequest)
      : undefined;
    message.accountCollateralRequest =
      (object.accountCollateralRequest !== undefined && object.accountCollateralRequest !== null)
        ? AccountCollateralRequest.fromPartial(object.accountCollateralRequest)
        : undefined;
    message.cloneAccount = (object.cloneAccount !== undefined && object.cloneAccount !== null)
      ? CloneAccount.fromPartial(object.cloneAccount)
      : undefined;
    message.updateAccount = (object.updateAccount !== undefined && object.updateAccount !== null)
      ? UpdateAccount.fromPartial(object.updateAccount)
      : undefined;
    message.updateAccountSettings =
      (object.updateAccountSettings !== undefined && object.updateAccountSettings !== null)
        ? UpdateAccountSettings.fromPartial(object.updateAccountSettings)
        : undefined;
    message.accountGroupRequest = (object.accountGroupRequest !== undefined && object.accountGroupRequest !== null)
      ? AccountGroupRequest.fromPartial(object.accountGroupRequest)
      : undefined;
    message.updateAccountRiskParameters =
      (object.updateAccountRiskParameters !== undefined && object.updateAccountRiskParameters !== null)
        ? UpdateAccountRiskParameters.fromPartial(object.updateAccountRiskParameters)
        : undefined;
    message.accountAvailableRouteListRequest =
      (object.accountAvailableRouteListRequest !== undefined && object.accountAvailableRouteListRequest !== null)
        ? AccountAvailableRouteListRequest.fromPartial(object.accountAvailableRouteListRequest)
        : undefined;
    message.updateAccountRouteList =
      (object.updateAccountRouteList !== undefined && object.updateAccountRouteList !== null)
        ? UpdateAccountRouteList.fromPartial(object.updateAccountRouteList)
        : undefined;
    message.updateAccountMarketLimits =
      (object.updateAccountMarketLimits !== undefined && object.updateAccountMarketLimits !== null)
        ? UpdateAccountMarketLimits.fromPartial(object.updateAccountMarketLimits)
        : undefined;
    message.createAccount = (object.createAccount !== undefined && object.createAccount !== null)
      ? CreateAccount.fromPartial(object.createAccount)
      : undefined;
    message.obsoleteAccountClusterRequest =
      (object.obsoleteAccountClusterRequest !== undefined && object.obsoleteAccountClusterRequest !== null)
        ? AccountClusterRequest.fromPartial(object.obsoleteAccountClusterRequest)
        : undefined;
    message.obsoleteCreateAccountCluster =
      (object.obsoleteCreateAccountCluster !== undefined && object.obsoleteCreateAccountCluster !== null)
        ? CreateAccountCluster.fromPartial(object.obsoleteCreateAccountCluster)
        : undefined;
    message.obsoleteUpdateAccountCluster =
      (object.obsoleteUpdateAccountCluster !== undefined && object.obsoleteUpdateAccountCluster !== null)
        ? UpdateAccountCluster.fromPartial(object.obsoleteUpdateAccountCluster)
        : undefined;
    message.obsoleteUpdateAccountClusterPriceOffsets =
      (object.obsoleteUpdateAccountClusterPriceOffsets !== undefined &&
          object.obsoleteUpdateAccountClusterPriceOffsets !== null)
        ? UpdateAccountClusterPriceOffsets.fromPartial(object.obsoleteUpdateAccountClusterPriceOffsets)
        : undefined;
    message.restoreAccount = (object.restoreAccount !== undefined && object.restoreAccount !== null)
      ? RestoreAccount.fromPartial(object.restoreAccount)
      : undefined;
    message.accountTypeOverrideListRequest =
      (object.accountTypeOverrideListRequest !== undefined && object.accountTypeOverrideListRequest !== null)
        ? AccountTypeOverrideListRequest.fromPartial(object.accountTypeOverrideListRequest)
        : undefined;
    message.updateAccountTypeOverrideList =
      (object.updateAccountTypeOverrideList !== undefined && object.updateAccountTypeOverrideList !== null)
        ? UpdateAccountTypeOverrideList.fromPartial(object.updateAccountTypeOverrideList)
        : undefined;
    message.updateAccountUserAuthorizationList =
      (object.updateAccountUserAuthorizationList !== undefined && object.updateAccountUserAuthorizationList !== null)
        ? UpdateAccountUserAuthorizationList.fromPartial(object.updateAccountUserAuthorizationList)
        : undefined;
    message.removeAccount = (object.removeAccount !== undefined && object.removeAccount !== null)
      ? RemoveAccount.fromPartial(object.removeAccount)
      : undefined;
    message.accountAvailableServiceGroupListRequest =
      (object.accountAvailableServiceGroupListRequest !== undefined &&
          object.accountAvailableServiceGroupListRequest !== null)
        ? AccountAvailableServiceGroupListRequest.fromPartial(object.accountAvailableServiceGroupListRequest)
        : undefined;
    message.accountServiceGroupListRequest =
      (object.accountServiceGroupListRequest !== undefined && object.accountServiceGroupListRequest !== null)
        ? AccountServiceGroupListRequest.fromPartial(object.accountServiceGroupListRequest)
        : undefined;
    message.updateAccountServiceGroupList =
      (object.updateAccountServiceGroupList !== undefined && object.updateAccountServiceGroupList !== null)
        ? UpdateAccountServiceGroupList.fromPartial(object.updateAccountServiceGroupList)
        : undefined;
    message.billableExchangeListRequest =
      (object.billableExchangeListRequest !== undefined && object.billableExchangeListRequest !== null)
        ? BillableExchangeListRequest.fromPartial(object.billableExchangeListRequest)
        : undefined;
    message.createAccountGroup = (object.createAccountGroup !== undefined && object.createAccountGroup !== null)
      ? CreateAccountGroup.fromPartial(object.createAccountGroup)
      : undefined;
    message.updateAccountGroup = (object.updateAccountGroup !== undefined && object.updateAccountGroup !== null)
      ? UpdateAccountGroup.fromPartial(object.updateAccountGroup)
      : undefined;
    message.removeAccountGroup = (object.removeAccountGroup !== undefined && object.removeAccountGroup !== null)
      ? RemoveAccountGroup.fromPartial(object.removeAccountGroup)
      : undefined;
    message.deleteAccountPositions =
      (object.deleteAccountPositions !== undefined && object.deleteAccountPositions !== null)
        ? DeleteAccountPositions.fromPartial(object.deleteAccountPositions)
        : undefined;
    message.accountTradingFeaturesRequest =
      (object.accountTradingFeaturesRequest !== undefined && object.accountTradingFeaturesRequest !== null)
        ? AccountTradingFeaturesRequest.fromPartial(object.accountTradingFeaturesRequest)
        : undefined;
    message.updateAccountTradingFeatures =
      (object.updateAccountTradingFeatures !== undefined && object.updateAccountTradingFeatures !== null)
        ? UpdateAccountTradingFeatures.fromPartial(object.updateAccountTradingFeatures)
        : undefined;
    return message;
  },
};

function createBaseOrderScopeRequest(): OrderScopeRequest {
  return {
    orderDetailsRequest: undefined,
    addFill: undefined,
    createExternalOrder: undefined,
    orderSearchRequest: undefined,
    relatedOrderTreeRequest: undefined,
    compoundOrderTreeRequest: undefined,
    syntheticStrategyTreeRequest: undefined,
    cancelOrder: undefined,
    reflectAsCanceledOrder: undefined,
    cancelCompoundOrderTree: undefined,
    confirmOrderSentToExchange: undefined,
    activateOrder: undefined,
    correctFill: undefined,
    cancelFill: undefined,
    ignoreOrder: undefined,
    batchOrdersOperation: undefined,
  };
}

export const OrderScopeRequest = {
  encode(message: OrderScopeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderDetailsRequest !== undefined) {
      OrderDetailsRequest.encode(message.orderDetailsRequest, writer.uint32(10).fork()).ldelim();
    }
    if (message.addFill !== undefined) {
      AddFill.encode(message.addFill, writer.uint32(18).fork()).ldelim();
    }
    if (message.createExternalOrder !== undefined) {
      CreateExternalOrder.encode(message.createExternalOrder, writer.uint32(26).fork()).ldelim();
    }
    if (message.orderSearchRequest !== undefined) {
      OrderSearchRequest.encode(message.orderSearchRequest, writer.uint32(34).fork()).ldelim();
    }
    if (message.relatedOrderTreeRequest !== undefined) {
      RelatedOrderTreeRequest.encode(message.relatedOrderTreeRequest, writer.uint32(42).fork()).ldelim();
    }
    if (message.compoundOrderTreeRequest !== undefined) {
      CompoundOrderTreeRequest.encode(message.compoundOrderTreeRequest, writer.uint32(50).fork()).ldelim();
    }
    if (message.syntheticStrategyTreeRequest !== undefined) {
      SyntheticStrategyTreeRequest.encode(message.syntheticStrategyTreeRequest, writer.uint32(58).fork()).ldelim();
    }
    if (message.cancelOrder !== undefined) {
      CancelOrder.encode(message.cancelOrder, writer.uint32(66).fork()).ldelim();
    }
    if (message.reflectAsCanceledOrder !== undefined) {
      ReflectAsCanceledOrder.encode(message.reflectAsCanceledOrder, writer.uint32(74).fork()).ldelim();
    }
    if (message.cancelCompoundOrderTree !== undefined) {
      CancelCompoundOrderTree.encode(message.cancelCompoundOrderTree, writer.uint32(82).fork()).ldelim();
    }
    if (message.confirmOrderSentToExchange !== undefined) {
      ConfirmOrderSentToExchange.encode(message.confirmOrderSentToExchange, writer.uint32(90).fork()).ldelim();
    }
    if (message.activateOrder !== undefined) {
      ActivateOrder.encode(message.activateOrder, writer.uint32(98).fork()).ldelim();
    }
    if (message.correctFill !== undefined) {
      CorrectFill.encode(message.correctFill, writer.uint32(106).fork()).ldelim();
    }
    if (message.cancelFill !== undefined) {
      CancelFill.encode(message.cancelFill, writer.uint32(114).fork()).ldelim();
    }
    if (message.ignoreOrder !== undefined) {
      IgnoreOrder.encode(message.ignoreOrder, writer.uint32(122).fork()).ldelim();
    }
    if (message.batchOrdersOperation !== undefined) {
      BatchOrdersOperation.encode(message.batchOrdersOperation, writer.uint32(130).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderScopeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderScopeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderDetailsRequest = OrderDetailsRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.addFill = AddFill.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createExternalOrder = CreateExternalOrder.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.orderSearchRequest = OrderSearchRequest.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.relatedOrderTreeRequest = RelatedOrderTreeRequest.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.compoundOrderTreeRequest = CompoundOrderTreeRequest.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.syntheticStrategyTreeRequest = SyntheticStrategyTreeRequest.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.cancelOrder = CancelOrder.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.reflectAsCanceledOrder = ReflectAsCanceledOrder.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.cancelCompoundOrderTree = CancelCompoundOrderTree.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.confirmOrderSentToExchange = ConfirmOrderSentToExchange.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.activateOrder = ActivateOrder.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.correctFill = CorrectFill.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.cancelFill = CancelFill.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.ignoreOrder = IgnoreOrder.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.batchOrdersOperation = BatchOrdersOperation.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderScopeRequest {
    return {
      orderDetailsRequest: isSet(object.orderDetailsRequest)
        ? OrderDetailsRequest.fromJSON(object.orderDetailsRequest)
        : undefined,
      addFill: isSet(object.addFill) ? AddFill.fromJSON(object.addFill) : undefined,
      createExternalOrder: isSet(object.createExternalOrder)
        ? CreateExternalOrder.fromJSON(object.createExternalOrder)
        : undefined,
      orderSearchRequest: isSet(object.orderSearchRequest)
        ? OrderSearchRequest.fromJSON(object.orderSearchRequest)
        : undefined,
      relatedOrderTreeRequest: isSet(object.relatedOrderTreeRequest)
        ? RelatedOrderTreeRequest.fromJSON(object.relatedOrderTreeRequest)
        : undefined,
      compoundOrderTreeRequest: isSet(object.compoundOrderTreeRequest)
        ? CompoundOrderTreeRequest.fromJSON(object.compoundOrderTreeRequest)
        : undefined,
      syntheticStrategyTreeRequest: isSet(object.syntheticStrategyTreeRequest)
        ? SyntheticStrategyTreeRequest.fromJSON(object.syntheticStrategyTreeRequest)
        : undefined,
      cancelOrder: isSet(object.cancelOrder) ? CancelOrder.fromJSON(object.cancelOrder) : undefined,
      reflectAsCanceledOrder: isSet(object.reflectAsCanceledOrder)
        ? ReflectAsCanceledOrder.fromJSON(object.reflectAsCanceledOrder)
        : undefined,
      cancelCompoundOrderTree: isSet(object.cancelCompoundOrderTree)
        ? CancelCompoundOrderTree.fromJSON(object.cancelCompoundOrderTree)
        : undefined,
      confirmOrderSentToExchange: isSet(object.confirmOrderSentToExchange)
        ? ConfirmOrderSentToExchange.fromJSON(object.confirmOrderSentToExchange)
        : undefined,
      activateOrder: isSet(object.activateOrder) ? ActivateOrder.fromJSON(object.activateOrder) : undefined,
      correctFill: isSet(object.correctFill) ? CorrectFill.fromJSON(object.correctFill) : undefined,
      cancelFill: isSet(object.cancelFill) ? CancelFill.fromJSON(object.cancelFill) : undefined,
      ignoreOrder: isSet(object.ignoreOrder) ? IgnoreOrder.fromJSON(object.ignoreOrder) : undefined,
      batchOrdersOperation: isSet(object.batchOrdersOperation)
        ? BatchOrdersOperation.fromJSON(object.batchOrdersOperation)
        : undefined,
    };
  },

  toJSON(message: OrderScopeRequest): unknown {
    const obj: any = {};
    if (message.orderDetailsRequest !== undefined) {
      obj.orderDetailsRequest = OrderDetailsRequest.toJSON(message.orderDetailsRequest);
    }
    if (message.addFill !== undefined) {
      obj.addFill = AddFill.toJSON(message.addFill);
    }
    if (message.createExternalOrder !== undefined) {
      obj.createExternalOrder = CreateExternalOrder.toJSON(message.createExternalOrder);
    }
    if (message.orderSearchRequest !== undefined) {
      obj.orderSearchRequest = OrderSearchRequest.toJSON(message.orderSearchRequest);
    }
    if (message.relatedOrderTreeRequest !== undefined) {
      obj.relatedOrderTreeRequest = RelatedOrderTreeRequest.toJSON(message.relatedOrderTreeRequest);
    }
    if (message.compoundOrderTreeRequest !== undefined) {
      obj.compoundOrderTreeRequest = CompoundOrderTreeRequest.toJSON(message.compoundOrderTreeRequest);
    }
    if (message.syntheticStrategyTreeRequest !== undefined) {
      obj.syntheticStrategyTreeRequest = SyntheticStrategyTreeRequest.toJSON(message.syntheticStrategyTreeRequest);
    }
    if (message.cancelOrder !== undefined) {
      obj.cancelOrder = CancelOrder.toJSON(message.cancelOrder);
    }
    if (message.reflectAsCanceledOrder !== undefined) {
      obj.reflectAsCanceledOrder = ReflectAsCanceledOrder.toJSON(message.reflectAsCanceledOrder);
    }
    if (message.cancelCompoundOrderTree !== undefined) {
      obj.cancelCompoundOrderTree = CancelCompoundOrderTree.toJSON(message.cancelCompoundOrderTree);
    }
    if (message.confirmOrderSentToExchange !== undefined) {
      obj.confirmOrderSentToExchange = ConfirmOrderSentToExchange.toJSON(message.confirmOrderSentToExchange);
    }
    if (message.activateOrder !== undefined) {
      obj.activateOrder = ActivateOrder.toJSON(message.activateOrder);
    }
    if (message.correctFill !== undefined) {
      obj.correctFill = CorrectFill.toJSON(message.correctFill);
    }
    if (message.cancelFill !== undefined) {
      obj.cancelFill = CancelFill.toJSON(message.cancelFill);
    }
    if (message.ignoreOrder !== undefined) {
      obj.ignoreOrder = IgnoreOrder.toJSON(message.ignoreOrder);
    }
    if (message.batchOrdersOperation !== undefined) {
      obj.batchOrdersOperation = BatchOrdersOperation.toJSON(message.batchOrdersOperation);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderScopeRequest>, I>>(base?: I): OrderScopeRequest {
    return OrderScopeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderScopeRequest>, I>>(object: I): OrderScopeRequest {
    const message = createBaseOrderScopeRequest();
    message.orderDetailsRequest = (object.orderDetailsRequest !== undefined && object.orderDetailsRequest !== null)
      ? OrderDetailsRequest.fromPartial(object.orderDetailsRequest)
      : undefined;
    message.addFill = (object.addFill !== undefined && object.addFill !== null)
      ? AddFill.fromPartial(object.addFill)
      : undefined;
    message.createExternalOrder = (object.createExternalOrder !== undefined && object.createExternalOrder !== null)
      ? CreateExternalOrder.fromPartial(object.createExternalOrder)
      : undefined;
    message.orderSearchRequest = (object.orderSearchRequest !== undefined && object.orderSearchRequest !== null)
      ? OrderSearchRequest.fromPartial(object.orderSearchRequest)
      : undefined;
    message.relatedOrderTreeRequest =
      (object.relatedOrderTreeRequest !== undefined && object.relatedOrderTreeRequest !== null)
        ? RelatedOrderTreeRequest.fromPartial(object.relatedOrderTreeRequest)
        : undefined;
    message.compoundOrderTreeRequest =
      (object.compoundOrderTreeRequest !== undefined && object.compoundOrderTreeRequest !== null)
        ? CompoundOrderTreeRequest.fromPartial(object.compoundOrderTreeRequest)
        : undefined;
    message.syntheticStrategyTreeRequest =
      (object.syntheticStrategyTreeRequest !== undefined && object.syntheticStrategyTreeRequest !== null)
        ? SyntheticStrategyTreeRequest.fromPartial(object.syntheticStrategyTreeRequest)
        : undefined;
    message.cancelOrder = (object.cancelOrder !== undefined && object.cancelOrder !== null)
      ? CancelOrder.fromPartial(object.cancelOrder)
      : undefined;
    message.reflectAsCanceledOrder =
      (object.reflectAsCanceledOrder !== undefined && object.reflectAsCanceledOrder !== null)
        ? ReflectAsCanceledOrder.fromPartial(object.reflectAsCanceledOrder)
        : undefined;
    message.cancelCompoundOrderTree =
      (object.cancelCompoundOrderTree !== undefined && object.cancelCompoundOrderTree !== null)
        ? CancelCompoundOrderTree.fromPartial(object.cancelCompoundOrderTree)
        : undefined;
    message.confirmOrderSentToExchange =
      (object.confirmOrderSentToExchange !== undefined && object.confirmOrderSentToExchange !== null)
        ? ConfirmOrderSentToExchange.fromPartial(object.confirmOrderSentToExchange)
        : undefined;
    message.activateOrder = (object.activateOrder !== undefined && object.activateOrder !== null)
      ? ActivateOrder.fromPartial(object.activateOrder)
      : undefined;
    message.correctFill = (object.correctFill !== undefined && object.correctFill !== null)
      ? CorrectFill.fromPartial(object.correctFill)
      : undefined;
    message.cancelFill = (object.cancelFill !== undefined && object.cancelFill !== null)
      ? CancelFill.fromPartial(object.cancelFill)
      : undefined;
    message.ignoreOrder = (object.ignoreOrder !== undefined && object.ignoreOrder !== null)
      ? IgnoreOrder.fromPartial(object.ignoreOrder)
      : undefined;
    message.batchOrdersOperation = (object.batchOrdersOperation !== undefined && object.batchOrdersOperation !== null)
      ? BatchOrdersOperation.fromPartial(object.batchOrdersOperation)
      : undefined;
    return message;
  },
};

function createBaseOrderDetailsRequest(): OrderDetailsRequest {
  return { accountId: 0, orderId: "", tradeLocationId: "", orderRequestsTop: 0 };
}

export const OrderDetailsRequest = {
  encode(message: OrderDetailsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.orderId !== undefined && message.orderId !== "") {
      writer.uint32(18).string(message.orderId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(26).string(message.tradeLocationId);
    }
    if (message.orderRequestsTop !== undefined && message.orderRequestsTop !== 0) {
      writer.uint32(32).uint32(message.orderRequestsTop);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderDetailsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.orderRequestsTop = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderDetailsRequest {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      orderRequestsTop: isSet(object.orderRequestsTop) ? globalThis.Number(object.orderRequestsTop) : 0,
    };
  },

  toJSON(message: OrderDetailsRequest): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.orderId !== undefined && message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.orderRequestsTop !== undefined && message.orderRequestsTop !== 0) {
      obj.orderRequestsTop = Math.round(message.orderRequestsTop);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderDetailsRequest>, I>>(base?: I): OrderDetailsRequest {
    return OrderDetailsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderDetailsRequest>, I>>(object: I): OrderDetailsRequest {
    const message = createBaseOrderDetailsRequest();
    message.accountId = object.accountId ?? 0;
    message.orderId = object.orderId ?? "";
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.orderRequestsTop = object.orderRequestsTop ?? 0;
    return message;
  },
};

function createBaseAddFill(): AddFill {
  return {
    accountId: 0,
    orderId: "",
    obsoleteQuantity: 0,
    price: 0,
    fillUtcTime: 0,
    isAggressive: false,
    displayPrice: 0,
    openCloseType: 0,
    speculationType: 0,
    tradeLocationId: "",
    quantity: undefined,
    sync: false,
  };
}

export const AddFill = {
  encode(message: AddFill, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.orderId !== undefined && message.orderId !== "") {
      writer.uint32(18).string(message.orderId);
    }
    if (message.obsoleteQuantity !== undefined && message.obsoleteQuantity !== 0) {
      writer.uint32(24).uint32(message.obsoleteQuantity);
    }
    if (message.price !== undefined && message.price !== 0) {
      writer.uint32(33).double(message.price);
    }
    if (message.fillUtcTime !== undefined && message.fillUtcTime !== 0) {
      writer.uint32(40).sint64(message.fillUtcTime);
    }
    if (message.isAggressive !== undefined && message.isAggressive !== false) {
      writer.uint32(48).bool(message.isAggressive);
    }
    if (message.displayPrice !== undefined && message.displayPrice !== 0) {
      writer.uint32(56).sint64(message.displayPrice);
    }
    if (message.openCloseType !== undefined && message.openCloseType !== 0) {
      writer.uint32(64).uint32(message.openCloseType);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(72).uint32(message.speculationType);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(82).string(message.tradeLocationId);
    }
    if (message.quantity !== undefined) {
      Decimal.encode(message.quantity, writer.uint32(90).fork()).ldelim();
    }
    if (message.sync !== undefined && message.sync !== false) {
      writer.uint32(96).bool(message.sync);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFill {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFill();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.obsoleteQuantity = reader.uint32();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.price = reader.double();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.fillUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isAggressive = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.displayPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.openCloseType = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.quantity = Decimal.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.sync = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddFill {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      obsoleteQuantity: isSet(object.obsoleteQuantity) ? globalThis.Number(object.obsoleteQuantity) : 0,
      price: isSet(object.price) ? globalThis.Number(object.price) : 0,
      fillUtcTime: isSet(object.fillUtcTime) ? globalThis.Number(object.fillUtcTime) : 0,
      isAggressive: isSet(object.isAggressive) ? globalThis.Boolean(object.isAggressive) : false,
      displayPrice: isSet(object.displayPrice) ? globalThis.Number(object.displayPrice) : 0,
      openCloseType: isSet(object.openCloseType) ? globalThis.Number(object.openCloseType) : 0,
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      quantity: isSet(object.quantity) ? Decimal.fromJSON(object.quantity) : undefined,
      sync: isSet(object.sync) ? globalThis.Boolean(object.sync) : false,
    };
  },

  toJSON(message: AddFill): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.orderId !== undefined && message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.obsoleteQuantity !== undefined && message.obsoleteQuantity !== 0) {
      obj.obsoleteQuantity = Math.round(message.obsoleteQuantity);
    }
    if (message.price !== undefined && message.price !== 0) {
      obj.price = message.price;
    }
    if (message.fillUtcTime !== undefined && message.fillUtcTime !== 0) {
      obj.fillUtcTime = Math.round(message.fillUtcTime);
    }
    if (message.isAggressive !== undefined && message.isAggressive !== false) {
      obj.isAggressive = message.isAggressive;
    }
    if (message.displayPrice !== undefined && message.displayPrice !== 0) {
      obj.displayPrice = Math.round(message.displayPrice);
    }
    if (message.openCloseType !== undefined && message.openCloseType !== 0) {
      obj.openCloseType = Math.round(message.openCloseType);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.quantity !== undefined) {
      obj.quantity = Decimal.toJSON(message.quantity);
    }
    if (message.sync !== undefined && message.sync !== false) {
      obj.sync = message.sync;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddFill>, I>>(base?: I): AddFill {
    return AddFill.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddFill>, I>>(object: I): AddFill {
    const message = createBaseAddFill();
    message.accountId = object.accountId ?? 0;
    message.orderId = object.orderId ?? "";
    message.obsoleteQuantity = object.obsoleteQuantity ?? 0;
    message.price = object.price ?? 0;
    message.fillUtcTime = object.fillUtcTime ?? 0;
    message.isAggressive = object.isAggressive ?? false;
    message.displayPrice = object.displayPrice ?? 0;
    message.openCloseType = object.openCloseType ?? 0;
    message.speculationType = object.speculationType ?? 0;
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.quantity = (object.quantity !== undefined && object.quantity !== null)
      ? Decimal.fromPartial(object.quantity)
      : undefined;
    message.sync = object.sync ?? false;
    return message;
  },
};

function createBaseCreateExternalOrder(): CreateExternalOrder {
  return {
    accountId: 0,
    userId: "",
    contractSymbol: "",
    side: 0,
    orderType: 0,
    limitPrice: 0,
    stopPrice: 0,
    obsoleteFillQuantity: 0,
    fillPrice: 0,
    fillUtcTime: 0,
    displayLimitPrice: 0,
    displayStopPrice: 0,
    displayFillPrice: 0,
    openCloseType: 0,
    speculationType: 0,
    fillIsAggressive: false,
    fillQuantity: undefined,
    sync: false,
  };
}

export const CreateExternalOrder = {
  encode(message: CreateExternalOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.userId !== undefined && message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.contractSymbol !== undefined && message.contractSymbol !== "") {
      writer.uint32(26).string(message.contractSymbol);
    }
    if (message.side !== undefined && message.side !== 0) {
      writer.uint32(32).uint32(message.side);
    }
    if (message.orderType !== undefined && message.orderType !== 0) {
      writer.uint32(40).uint32(message.orderType);
    }
    if (message.limitPrice !== undefined && message.limitPrice !== 0) {
      writer.uint32(49).double(message.limitPrice);
    }
    if (message.stopPrice !== undefined && message.stopPrice !== 0) {
      writer.uint32(57).double(message.stopPrice);
    }
    if (message.obsoleteFillQuantity !== undefined && message.obsoleteFillQuantity !== 0) {
      writer.uint32(64).uint32(message.obsoleteFillQuantity);
    }
    if (message.fillPrice !== undefined && message.fillPrice !== 0) {
      writer.uint32(73).double(message.fillPrice);
    }
    if (message.fillUtcTime !== undefined && message.fillUtcTime !== 0) {
      writer.uint32(80).sint64(message.fillUtcTime);
    }
    if (message.displayLimitPrice !== undefined && message.displayLimitPrice !== 0) {
      writer.uint32(88).sint64(message.displayLimitPrice);
    }
    if (message.displayStopPrice !== undefined && message.displayStopPrice !== 0) {
      writer.uint32(96).sint64(message.displayStopPrice);
    }
    if (message.displayFillPrice !== undefined && message.displayFillPrice !== 0) {
      writer.uint32(104).sint64(message.displayFillPrice);
    }
    if (message.openCloseType !== undefined && message.openCloseType !== 0) {
      writer.uint32(112).uint32(message.openCloseType);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(120).uint32(message.speculationType);
    }
    if (message.fillIsAggressive !== undefined && message.fillIsAggressive !== false) {
      writer.uint32(128).bool(message.fillIsAggressive);
    }
    if (message.fillQuantity !== undefined) {
      Decimal.encode(message.fillQuantity, writer.uint32(138).fork()).ldelim();
    }
    if (message.sync !== undefined && message.sync !== false) {
      writer.uint32(144).bool(message.sync);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateExternalOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateExternalOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.contractSymbol = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.orderType = reader.uint32();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.limitPrice = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.stopPrice = reader.double();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.obsoleteFillQuantity = reader.uint32();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.fillPrice = reader.double();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.fillUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.displayLimitPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.displayStopPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.displayFillPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.openCloseType = reader.uint32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.fillIsAggressive = reader.bool();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.fillQuantity = Decimal.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.sync = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateExternalOrder {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      contractSymbol: isSet(object.contractSymbol) ? globalThis.String(object.contractSymbol) : "",
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      orderType: isSet(object.orderType) ? globalThis.Number(object.orderType) : 0,
      limitPrice: isSet(object.limitPrice) ? globalThis.Number(object.limitPrice) : 0,
      stopPrice: isSet(object.stopPrice) ? globalThis.Number(object.stopPrice) : 0,
      obsoleteFillQuantity: isSet(object.obsoleteFillQuantity) ? globalThis.Number(object.obsoleteFillQuantity) : 0,
      fillPrice: isSet(object.fillPrice) ? globalThis.Number(object.fillPrice) : 0,
      fillUtcTime: isSet(object.fillUtcTime) ? globalThis.Number(object.fillUtcTime) : 0,
      displayLimitPrice: isSet(object.displayLimitPrice) ? globalThis.Number(object.displayLimitPrice) : 0,
      displayStopPrice: isSet(object.displayStopPrice) ? globalThis.Number(object.displayStopPrice) : 0,
      displayFillPrice: isSet(object.displayFillPrice) ? globalThis.Number(object.displayFillPrice) : 0,
      openCloseType: isSet(object.openCloseType) ? globalThis.Number(object.openCloseType) : 0,
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
      fillIsAggressive: isSet(object.fillIsAggressive) ? globalThis.Boolean(object.fillIsAggressive) : false,
      fillQuantity: isSet(object.fillQuantity) ? Decimal.fromJSON(object.fillQuantity) : undefined,
      sync: isSet(object.sync) ? globalThis.Boolean(object.sync) : false,
    };
  },

  toJSON(message: CreateExternalOrder): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.userId !== undefined && message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.contractSymbol !== undefined && message.contractSymbol !== "") {
      obj.contractSymbol = message.contractSymbol;
    }
    if (message.side !== undefined && message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.orderType !== undefined && message.orderType !== 0) {
      obj.orderType = Math.round(message.orderType);
    }
    if (message.limitPrice !== undefined && message.limitPrice !== 0) {
      obj.limitPrice = message.limitPrice;
    }
    if (message.stopPrice !== undefined && message.stopPrice !== 0) {
      obj.stopPrice = message.stopPrice;
    }
    if (message.obsoleteFillQuantity !== undefined && message.obsoleteFillQuantity !== 0) {
      obj.obsoleteFillQuantity = Math.round(message.obsoleteFillQuantity);
    }
    if (message.fillPrice !== undefined && message.fillPrice !== 0) {
      obj.fillPrice = message.fillPrice;
    }
    if (message.fillUtcTime !== undefined && message.fillUtcTime !== 0) {
      obj.fillUtcTime = Math.round(message.fillUtcTime);
    }
    if (message.displayLimitPrice !== undefined && message.displayLimitPrice !== 0) {
      obj.displayLimitPrice = Math.round(message.displayLimitPrice);
    }
    if (message.displayStopPrice !== undefined && message.displayStopPrice !== 0) {
      obj.displayStopPrice = Math.round(message.displayStopPrice);
    }
    if (message.displayFillPrice !== undefined && message.displayFillPrice !== 0) {
      obj.displayFillPrice = Math.round(message.displayFillPrice);
    }
    if (message.openCloseType !== undefined && message.openCloseType !== 0) {
      obj.openCloseType = Math.round(message.openCloseType);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    if (message.fillIsAggressive !== undefined && message.fillIsAggressive !== false) {
      obj.fillIsAggressive = message.fillIsAggressive;
    }
    if (message.fillQuantity !== undefined) {
      obj.fillQuantity = Decimal.toJSON(message.fillQuantity);
    }
    if (message.sync !== undefined && message.sync !== false) {
      obj.sync = message.sync;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateExternalOrder>, I>>(base?: I): CreateExternalOrder {
    return CreateExternalOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateExternalOrder>, I>>(object: I): CreateExternalOrder {
    const message = createBaseCreateExternalOrder();
    message.accountId = object.accountId ?? 0;
    message.userId = object.userId ?? "";
    message.contractSymbol = object.contractSymbol ?? "";
    message.side = object.side ?? 0;
    message.orderType = object.orderType ?? 0;
    message.limitPrice = object.limitPrice ?? 0;
    message.stopPrice = object.stopPrice ?? 0;
    message.obsoleteFillQuantity = object.obsoleteFillQuantity ?? 0;
    message.fillPrice = object.fillPrice ?? 0;
    message.fillUtcTime = object.fillUtcTime ?? 0;
    message.displayLimitPrice = object.displayLimitPrice ?? 0;
    message.displayStopPrice = object.displayStopPrice ?? 0;
    message.displayFillPrice = object.displayFillPrice ?? 0;
    message.openCloseType = object.openCloseType ?? 0;
    message.speculationType = object.speculationType ?? 0;
    message.fillIsAggressive = object.fillIsAggressive ?? false;
    message.fillQuantity = (object.fillQuantity !== undefined && object.fillQuantity !== null)
      ? Decimal.fromPartial(object.fillQuantity)
      : undefined;
    message.sync = object.sync ?? false;
    return message;
  },
};

function createBaseOrderSearchRequest(): OrderSearchRequest {
  return {
    obsoleteText: "",
    obsoleteSearchCriteria: [],
    top: 0,
    tradeLocationId: "",
    archived: false,
    searchOptions: [],
    allMatchMode: false,
  };
}

export const OrderSearchRequest = {
  encode(message: OrderSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.obsoleteText !== undefined && message.obsoleteText !== "") {
      writer.uint32(10).string(message.obsoleteText);
    }
    writer.uint32(18).fork();
    for (const v of message.obsoleteSearchCriteria) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.top !== undefined && message.top !== 0) {
      writer.uint32(24).uint32(message.top);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(34).string(message.tradeLocationId);
    }
    if (message.archived !== undefined && message.archived !== false) {
      writer.uint32(40).bool(message.archived);
    }
    for (const v of message.searchOptions) {
      SearchOption.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.allMatchMode !== undefined && message.allMatchMode !== false) {
      writer.uint32(56).bool(message.allMatchMode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderSearchRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.obsoleteText = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.obsoleteSearchCriteria.push(reader.uint32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.obsoleteSearchCriteria.push(reader.uint32());
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.top = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.archived = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.searchOptions.push(SearchOption.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.allMatchMode = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderSearchRequest {
    return {
      obsoleteText: isSet(object.obsoleteText) ? globalThis.String(object.obsoleteText) : "",
      obsoleteSearchCriteria: globalThis.Array.isArray(object?.obsoleteSearchCriteria)
        ? object.obsoleteSearchCriteria.map((e: any) => globalThis.Number(e))
        : [],
      top: isSet(object.top) ? globalThis.Number(object.top) : 0,
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      archived: isSet(object.archived) ? globalThis.Boolean(object.archived) : false,
      searchOptions: globalThis.Array.isArray(object?.searchOptions)
        ? object.searchOptions.map((e: any) => SearchOption.fromJSON(e))
        : [],
      allMatchMode: isSet(object.allMatchMode) ? globalThis.Boolean(object.allMatchMode) : false,
    };
  },

  toJSON(message: OrderSearchRequest): unknown {
    const obj: any = {};
    if (message.obsoleteText !== undefined && message.obsoleteText !== "") {
      obj.obsoleteText = message.obsoleteText;
    }
    if (message.obsoleteSearchCriteria?.length) {
      obj.obsoleteSearchCriteria = message.obsoleteSearchCriteria.map((e) => Math.round(e));
    }
    if (message.top !== undefined && message.top !== 0) {
      obj.top = Math.round(message.top);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.archived !== undefined && message.archived !== false) {
      obj.archived = message.archived;
    }
    if (message.searchOptions?.length) {
      obj.searchOptions = message.searchOptions.map((e) => SearchOption.toJSON(e));
    }
    if (message.allMatchMode !== undefined && message.allMatchMode !== false) {
      obj.allMatchMode = message.allMatchMode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderSearchRequest>, I>>(base?: I): OrderSearchRequest {
    return OrderSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderSearchRequest>, I>>(object: I): OrderSearchRequest {
    const message = createBaseOrderSearchRequest();
    message.obsoleteText = object.obsoleteText ?? "";
    message.obsoleteSearchCriteria = object.obsoleteSearchCriteria?.map((e) => e) || [];
    message.top = object.top ?? 0;
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.archived = object.archived ?? false;
    message.searchOptions = object.searchOptions?.map((e) => SearchOption.fromPartial(e)) || [];
    message.allMatchMode = object.allMatchMode ?? false;
    return message;
  },
};

function createBaseRelatedOrderTreeRequest(): RelatedOrderTreeRequest {
  return { chainOrderId: "", tradeLocationId: "", orderRequestsTop: 0, top: 0 };
}

export const RelatedOrderTreeRequest = {
  encode(message: RelatedOrderTreeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(18).string(message.tradeLocationId);
    }
    if (message.orderRequestsTop !== undefined && message.orderRequestsTop !== 0) {
      writer.uint32(24).uint32(message.orderRequestsTop);
    }
    if (message.top !== undefined && message.top !== 0) {
      writer.uint32(32).uint32(message.top);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RelatedOrderTreeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRelatedOrderTreeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chainOrderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.orderRequestsTop = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.top = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RelatedOrderTreeRequest {
    return {
      chainOrderId: isSet(object.chainOrderId) ? globalThis.String(object.chainOrderId) : "",
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      orderRequestsTop: isSet(object.orderRequestsTop) ? globalThis.Number(object.orderRequestsTop) : 0,
      top: isSet(object.top) ? globalThis.Number(object.top) : 0,
    };
  },

  toJSON(message: RelatedOrderTreeRequest): unknown {
    const obj: any = {};
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      obj.chainOrderId = message.chainOrderId;
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.orderRequestsTop !== undefined && message.orderRequestsTop !== 0) {
      obj.orderRequestsTop = Math.round(message.orderRequestsTop);
    }
    if (message.top !== undefined && message.top !== 0) {
      obj.top = Math.round(message.top);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RelatedOrderTreeRequest>, I>>(base?: I): RelatedOrderTreeRequest {
    return RelatedOrderTreeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RelatedOrderTreeRequest>, I>>(object: I): RelatedOrderTreeRequest {
    const message = createBaseRelatedOrderTreeRequest();
    message.chainOrderId = object.chainOrderId ?? "";
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.orderRequestsTop = object.orderRequestsTop ?? 0;
    message.top = object.top ?? 0;
    return message;
  },
};

function createBaseCompoundOrderTreeRequest(): CompoundOrderTreeRequest {
  return { compoundTreeId: "", tradeLocationId: "", orderRequestsTop: 0, top: 0 };
}

export const CompoundOrderTreeRequest = {
  encode(message: CompoundOrderTreeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.compoundTreeId !== undefined && message.compoundTreeId !== "") {
      writer.uint32(10).string(message.compoundTreeId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(18).string(message.tradeLocationId);
    }
    if (message.orderRequestsTop !== undefined && message.orderRequestsTop !== 0) {
      writer.uint32(24).uint32(message.orderRequestsTop);
    }
    if (message.top !== undefined && message.top !== 0) {
      writer.uint32(32).uint32(message.top);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompoundOrderTreeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompoundOrderTreeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.compoundTreeId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.orderRequestsTop = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.top = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompoundOrderTreeRequest {
    return {
      compoundTreeId: isSet(object.compoundTreeId) ? globalThis.String(object.compoundTreeId) : "",
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      orderRequestsTop: isSet(object.orderRequestsTop) ? globalThis.Number(object.orderRequestsTop) : 0,
      top: isSet(object.top) ? globalThis.Number(object.top) : 0,
    };
  },

  toJSON(message: CompoundOrderTreeRequest): unknown {
    const obj: any = {};
    if (message.compoundTreeId !== undefined && message.compoundTreeId !== "") {
      obj.compoundTreeId = message.compoundTreeId;
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.orderRequestsTop !== undefined && message.orderRequestsTop !== 0) {
      obj.orderRequestsTop = Math.round(message.orderRequestsTop);
    }
    if (message.top !== undefined && message.top !== 0) {
      obj.top = Math.round(message.top);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompoundOrderTreeRequest>, I>>(base?: I): CompoundOrderTreeRequest {
    return CompoundOrderTreeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompoundOrderTreeRequest>, I>>(object: I): CompoundOrderTreeRequest {
    const message = createBaseCompoundOrderTreeRequest();
    message.compoundTreeId = object.compoundTreeId ?? "";
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.orderRequestsTop = object.orderRequestsTop ?? 0;
    message.top = object.top ?? 0;
    return message;
  },
};

function createBaseSyntheticStrategyTreeRequest(): SyntheticStrategyTreeRequest {
  return { rootChainOrderId: "", tradeLocationId: "" };
}

export const SyntheticStrategyTreeRequest = {
  encode(message: SyntheticStrategyTreeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rootChainOrderId !== undefined && message.rootChainOrderId !== "") {
      writer.uint32(10).string(message.rootChainOrderId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(18).string(message.tradeLocationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyntheticStrategyTreeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSyntheticStrategyTreeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rootChainOrderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SyntheticStrategyTreeRequest {
    return {
      rootChainOrderId: isSet(object.rootChainOrderId) ? globalThis.String(object.rootChainOrderId) : "",
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
    };
  },

  toJSON(message: SyntheticStrategyTreeRequest): unknown {
    const obj: any = {};
    if (message.rootChainOrderId !== undefined && message.rootChainOrderId !== "") {
      obj.rootChainOrderId = message.rootChainOrderId;
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SyntheticStrategyTreeRequest>, I>>(base?: I): SyntheticStrategyTreeRequest {
    return SyntheticStrategyTreeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SyntheticStrategyTreeRequest>, I>>(object: I): SyntheticStrategyTreeRequest {
    const message = createBaseSyntheticStrategyTreeRequest();
    message.rootChainOrderId = object.rootChainOrderId ?? "";
    message.tradeLocationId = object.tradeLocationId ?? "";
    return message;
  },
};

function createBaseCancelOrder(): CancelOrder {
  return { chainOrderId: "", accountId: 0, tradeLocationId: "", sync: false };
}

export const CancelOrder = {
  encode(message: CancelOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(26).string(message.tradeLocationId);
    }
    if (message.sync !== undefined && message.sync !== false) {
      writer.uint32(32).bool(message.sync);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chainOrderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sync = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CancelOrder {
    return {
      chainOrderId: isSet(object.chainOrderId) ? globalThis.String(object.chainOrderId) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      sync: isSet(object.sync) ? globalThis.Boolean(object.sync) : false,
    };
  },

  toJSON(message: CancelOrder): unknown {
    const obj: any = {};
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      obj.chainOrderId = message.chainOrderId;
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.sync !== undefined && message.sync !== false) {
      obj.sync = message.sync;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CancelOrder>, I>>(base?: I): CancelOrder {
    return CancelOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelOrder>, I>>(object: I): CancelOrder {
    const message = createBaseCancelOrder();
    message.chainOrderId = object.chainOrderId ?? "";
    message.accountId = object.accountId ?? 0;
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.sync = object.sync ?? false;
    return message;
  },
};

function createBaseReflectAsCanceledOrder(): ReflectAsCanceledOrder {
  return { chainOrderId: "", accountId: 0, tradeLocationId: "", sync: false };
}

export const ReflectAsCanceledOrder = {
  encode(message: ReflectAsCanceledOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(26).string(message.tradeLocationId);
    }
    if (message.sync !== undefined && message.sync !== false) {
      writer.uint32(32).bool(message.sync);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReflectAsCanceledOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReflectAsCanceledOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chainOrderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sync = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReflectAsCanceledOrder {
    return {
      chainOrderId: isSet(object.chainOrderId) ? globalThis.String(object.chainOrderId) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      sync: isSet(object.sync) ? globalThis.Boolean(object.sync) : false,
    };
  },

  toJSON(message: ReflectAsCanceledOrder): unknown {
    const obj: any = {};
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      obj.chainOrderId = message.chainOrderId;
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.sync !== undefined && message.sync !== false) {
      obj.sync = message.sync;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReflectAsCanceledOrder>, I>>(base?: I): ReflectAsCanceledOrder {
    return ReflectAsCanceledOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReflectAsCanceledOrder>, I>>(object: I): ReflectAsCanceledOrder {
    const message = createBaseReflectAsCanceledOrder();
    message.chainOrderId = object.chainOrderId ?? "";
    message.accountId = object.accountId ?? 0;
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.sync = object.sync ?? false;
    return message;
  },
};

function createBaseCancelCompoundOrderTree(): CancelCompoundOrderTree {
  return { compoundTreeId: "", accountId: 0, tradeLocationId: "", sync: false };
}

export const CancelCompoundOrderTree = {
  encode(message: CancelCompoundOrderTree, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.compoundTreeId !== undefined && message.compoundTreeId !== "") {
      writer.uint32(10).string(message.compoundTreeId);
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(16).sint32(message.accountId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(26).string(message.tradeLocationId);
    }
    if (message.sync !== undefined && message.sync !== false) {
      writer.uint32(32).bool(message.sync);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelCompoundOrderTree {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelCompoundOrderTree();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.compoundTreeId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sync = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CancelCompoundOrderTree {
    return {
      compoundTreeId: isSet(object.compoundTreeId) ? globalThis.String(object.compoundTreeId) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      sync: isSet(object.sync) ? globalThis.Boolean(object.sync) : false,
    };
  },

  toJSON(message: CancelCompoundOrderTree): unknown {
    const obj: any = {};
    if (message.compoundTreeId !== undefined && message.compoundTreeId !== "") {
      obj.compoundTreeId = message.compoundTreeId;
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.sync !== undefined && message.sync !== false) {
      obj.sync = message.sync;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CancelCompoundOrderTree>, I>>(base?: I): CancelCompoundOrderTree {
    return CancelCompoundOrderTree.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelCompoundOrderTree>, I>>(object: I): CancelCompoundOrderTree {
    const message = createBaseCancelCompoundOrderTree();
    message.compoundTreeId = object.compoundTreeId ?? "";
    message.accountId = object.accountId ?? 0;
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.sync = object.sync ?? false;
    return message;
  },
};

function createBaseConfirmOrderSentToExchange(): ConfirmOrderSentToExchange {
  return { chainOrderId: "", accountId: "", tradeLocationId: "", newRouteOrderId: "", sync: false };
}

export const ConfirmOrderSentToExchange = {
  encode(message: ConfirmOrderSentToExchange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(18).string(message.accountId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(26).string(message.tradeLocationId);
    }
    if (message.newRouteOrderId !== undefined && message.newRouteOrderId !== "") {
      writer.uint32(34).string(message.newRouteOrderId);
    }
    if (message.sync !== undefined && message.sync !== false) {
      writer.uint32(40).bool(message.sync);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConfirmOrderSentToExchange {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfirmOrderSentToExchange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chainOrderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.newRouteOrderId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.sync = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfirmOrderSentToExchange {
    return {
      chainOrderId: isSet(object.chainOrderId) ? globalThis.String(object.chainOrderId) : "",
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      newRouteOrderId: isSet(object.newRouteOrderId) ? globalThis.String(object.newRouteOrderId) : "",
      sync: isSet(object.sync) ? globalThis.Boolean(object.sync) : false,
    };
  },

  toJSON(message: ConfirmOrderSentToExchange): unknown {
    const obj: any = {};
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      obj.chainOrderId = message.chainOrderId;
    }
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.newRouteOrderId !== undefined && message.newRouteOrderId !== "") {
      obj.newRouteOrderId = message.newRouteOrderId;
    }
    if (message.sync !== undefined && message.sync !== false) {
      obj.sync = message.sync;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConfirmOrderSentToExchange>, I>>(base?: I): ConfirmOrderSentToExchange {
    return ConfirmOrderSentToExchange.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConfirmOrderSentToExchange>, I>>(object: I): ConfirmOrderSentToExchange {
    const message = createBaseConfirmOrderSentToExchange();
    message.chainOrderId = object.chainOrderId ?? "";
    message.accountId = object.accountId ?? "";
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.newRouteOrderId = object.newRouteOrderId ?? "";
    message.sync = object.sync ?? false;
    return message;
  },
};

function createBaseActivateOrder(): ActivateOrder {
  return { chainOrderId: "", accountId: "", tradeLocationId: "", sync: false };
}

export const ActivateOrder = {
  encode(message: ActivateOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(18).string(message.accountId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(26).string(message.tradeLocationId);
    }
    if (message.sync !== undefined && message.sync !== false) {
      writer.uint32(32).bool(message.sync);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActivateOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivateOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chainOrderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sync = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActivateOrder {
    return {
      chainOrderId: isSet(object.chainOrderId) ? globalThis.String(object.chainOrderId) : "",
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      sync: isSet(object.sync) ? globalThis.Boolean(object.sync) : false,
    };
  },

  toJSON(message: ActivateOrder): unknown {
    const obj: any = {};
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      obj.chainOrderId = message.chainOrderId;
    }
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.sync !== undefined && message.sync !== false) {
      obj.sync = message.sync;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivateOrder>, I>>(base?: I): ActivateOrder {
    return ActivateOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivateOrder>, I>>(object: I): ActivateOrder {
    const message = createBaseActivateOrder();
    message.chainOrderId = object.chainOrderId ?? "";
    message.accountId = object.accountId ?? "";
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.sync = object.sync ?? false;
    return message;
  },
};

function createBaseCorrectFill(): CorrectFill {
  return { id: "", fillType: 0, accountId: "", tradeLocationId: "", quantity: undefined, displayPrice: 0, sync: false };
}

export const CorrectFill = {
  encode(message: CorrectFill, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.fillType !== undefined && message.fillType !== 0) {
      writer.uint32(16).uint32(message.fillType);
    }
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(26).string(message.accountId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(34).string(message.tradeLocationId);
    }
    if (message.quantity !== undefined) {
      Decimal.encode(message.quantity, writer.uint32(42).fork()).ldelim();
    }
    if (message.displayPrice !== undefined && message.displayPrice !== 0) {
      writer.uint32(48).sint64(message.displayPrice);
    }
    if (message.sync !== undefined && message.sync !== false) {
      writer.uint32(56).bool(message.sync);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CorrectFill {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCorrectFill();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.fillType = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.quantity = Decimal.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.displayPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.sync = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CorrectFill {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      fillType: isSet(object.fillType) ? globalThis.Number(object.fillType) : 0,
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      quantity: isSet(object.quantity) ? Decimal.fromJSON(object.quantity) : undefined,
      displayPrice: isSet(object.displayPrice) ? globalThis.Number(object.displayPrice) : 0,
      sync: isSet(object.sync) ? globalThis.Boolean(object.sync) : false,
    };
  },

  toJSON(message: CorrectFill): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.fillType !== undefined && message.fillType !== 0) {
      obj.fillType = Math.round(message.fillType);
    }
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.quantity !== undefined) {
      obj.quantity = Decimal.toJSON(message.quantity);
    }
    if (message.displayPrice !== undefined && message.displayPrice !== 0) {
      obj.displayPrice = Math.round(message.displayPrice);
    }
    if (message.sync !== undefined && message.sync !== false) {
      obj.sync = message.sync;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CorrectFill>, I>>(base?: I): CorrectFill {
    return CorrectFill.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CorrectFill>, I>>(object: I): CorrectFill {
    const message = createBaseCorrectFill();
    message.id = object.id ?? "";
    message.fillType = object.fillType ?? 0;
    message.accountId = object.accountId ?? "";
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.quantity = (object.quantity !== undefined && object.quantity !== null)
      ? Decimal.fromPartial(object.quantity)
      : undefined;
    message.displayPrice = object.displayPrice ?? 0;
    message.sync = object.sync ?? false;
    return message;
  },
};

function createBaseCancelFill(): CancelFill {
  return { id: "", fillType: 0, accountId: "", tradeLocationId: "", bust: false, sync: false };
}

export const CancelFill = {
  encode(message: CancelFill, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.fillType !== undefined && message.fillType !== 0) {
      writer.uint32(16).uint32(message.fillType);
    }
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(26).string(message.accountId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(34).string(message.tradeLocationId);
    }
    if (message.bust !== undefined && message.bust !== false) {
      writer.uint32(40).bool(message.bust);
    }
    if (message.sync !== undefined && message.sync !== false) {
      writer.uint32(48).bool(message.sync);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelFill {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelFill();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.fillType = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.bust = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.sync = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CancelFill {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      fillType: isSet(object.fillType) ? globalThis.Number(object.fillType) : 0,
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      bust: isSet(object.bust) ? globalThis.Boolean(object.bust) : false,
      sync: isSet(object.sync) ? globalThis.Boolean(object.sync) : false,
    };
  },

  toJSON(message: CancelFill): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.fillType !== undefined && message.fillType !== 0) {
      obj.fillType = Math.round(message.fillType);
    }
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.bust !== undefined && message.bust !== false) {
      obj.bust = message.bust;
    }
    if (message.sync !== undefined && message.sync !== false) {
      obj.sync = message.sync;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CancelFill>, I>>(base?: I): CancelFill {
    return CancelFill.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelFill>, I>>(object: I): CancelFill {
    const message = createBaseCancelFill();
    message.id = object.id ?? "";
    message.fillType = object.fillType ?? 0;
    message.accountId = object.accountId ?? "";
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.bust = object.bust ?? false;
    message.sync = object.sync ?? false;
    return message;
  },
};

function createBaseIgnoreOrder(): IgnoreOrder {
  return { chainOrderId: "", accountId: "", tradeLocationId: "", action: false };
}

export const IgnoreOrder = {
  encode(message: IgnoreOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(18).string(message.accountId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(26).string(message.tradeLocationId);
    }
    if (message.action !== undefined && message.action !== false) {
      writer.uint32(32).bool(message.action);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IgnoreOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIgnoreOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chainOrderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.action = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IgnoreOrder {
    return {
      chainOrderId: isSet(object.chainOrderId) ? globalThis.String(object.chainOrderId) : "",
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      action: isSet(object.action) ? globalThis.Boolean(object.action) : false,
    };
  },

  toJSON(message: IgnoreOrder): unknown {
    const obj: any = {};
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      obj.chainOrderId = message.chainOrderId;
    }
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.action !== undefined && message.action !== false) {
      obj.action = message.action;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IgnoreOrder>, I>>(base?: I): IgnoreOrder {
    return IgnoreOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IgnoreOrder>, I>>(object: I): IgnoreOrder {
    const message = createBaseIgnoreOrder();
    message.chainOrderId = object.chainOrderId ?? "";
    message.accountId = object.accountId ?? "";
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.action = object.action ?? false;
    return message;
  },
};

function createBaseAccountInfoRequest(): AccountInfoRequest {
  return { accountId: 0, brokerageAccountNumber: "" };
}

export const AccountInfoRequest = {
  encode(message: AccountInfoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      writer.uint32(18).string(message.brokerageAccountNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountInfoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountInfoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.brokerageAccountNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountInfoRequest {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      brokerageAccountNumber: isSet(object.brokerageAccountNumber)
        ? globalThis.String(object.brokerageAccountNumber)
        : "",
    };
  },

  toJSON(message: AccountInfoRequest): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      obj.brokerageAccountNumber = message.brokerageAccountNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountInfoRequest>, I>>(base?: I): AccountInfoRequest {
    return AccountInfoRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountInfoRequest>, I>>(object: I): AccountInfoRequest {
    const message = createBaseAccountInfoRequest();
    message.accountId = object.accountId ?? 0;
    message.brokerageAccountNumber = object.brokerageAccountNumber ?? "";
    return message;
  },
};

function createBaseBalanceRecordsRequest(): BalanceRecordsRequest {
  return { accountId: 0, currency: "", balanceId: 0 };
}

export const BalanceRecordsRequest = {
  encode(message: BalanceRecordsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.currency !== undefined && message.currency !== "") {
      writer.uint32(18).string(message.currency);
    }
    if (message.balanceId !== undefined && message.balanceId !== 0) {
      writer.uint32(24).sint32(message.balanceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BalanceRecordsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalanceRecordsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.balanceId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BalanceRecordsRequest {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
      balanceId: isSet(object.balanceId) ? globalThis.Number(object.balanceId) : 0,
    };
  },

  toJSON(message: BalanceRecordsRequest): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.currency !== undefined && message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.balanceId !== undefined && message.balanceId !== 0) {
      obj.balanceId = Math.round(message.balanceId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BalanceRecordsRequest>, I>>(base?: I): BalanceRecordsRequest {
    return BalanceRecordsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BalanceRecordsRequest>, I>>(object: I): BalanceRecordsRequest {
    const message = createBaseBalanceRecordsRequest();
    message.accountId = object.accountId ?? 0;
    message.currency = object.currency ?? "";
    message.balanceId = object.balanceId ?? 0;
    return message;
  },
};

function createBaseCreateBalanceRecord(): CreateBalanceRecord {
  return { accountId: 0, currency: "", endCashBalance: 0, collateral: 0, regulated: true, asOfDate: 0 };
}

export const CreateBalanceRecord = {
  encode(message: CreateBalanceRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.currency !== undefined && message.currency !== "") {
      writer.uint32(18).string(message.currency);
    }
    if (message.endCashBalance !== undefined && message.endCashBalance !== 0) {
      writer.uint32(25).double(message.endCashBalance);
    }
    if (message.collateral !== undefined && message.collateral !== 0) {
      writer.uint32(33).double(message.collateral);
    }
    if (message.regulated !== undefined && message.regulated !== true) {
      writer.uint32(40).bool(message.regulated);
    }
    if (message.asOfDate !== undefined && message.asOfDate !== 0) {
      writer.uint32(48).sint64(message.asOfDate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBalanceRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBalanceRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.endCashBalance = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.collateral = reader.double();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.regulated = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.asOfDate = longToNumber(reader.sint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBalanceRecord {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
      endCashBalance: isSet(object.endCashBalance) ? globalThis.Number(object.endCashBalance) : 0,
      collateral: isSet(object.collateral) ? globalThis.Number(object.collateral) : 0,
      regulated: isSet(object.regulated) ? globalThis.Boolean(object.regulated) : true,
      asOfDate: isSet(object.asOfDate) ? globalThis.Number(object.asOfDate) : 0,
    };
  },

  toJSON(message: CreateBalanceRecord): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.currency !== undefined && message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.endCashBalance !== undefined && message.endCashBalance !== 0) {
      obj.endCashBalance = message.endCashBalance;
    }
    if (message.collateral !== undefined && message.collateral !== 0) {
      obj.collateral = message.collateral;
    }
    if (message.regulated !== undefined && message.regulated !== true) {
      obj.regulated = message.regulated;
    }
    if (message.asOfDate !== undefined && message.asOfDate !== 0) {
      obj.asOfDate = Math.round(message.asOfDate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateBalanceRecord>, I>>(base?: I): CreateBalanceRecord {
    return CreateBalanceRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateBalanceRecord>, I>>(object: I): CreateBalanceRecord {
    const message = createBaseCreateBalanceRecord();
    message.accountId = object.accountId ?? 0;
    message.currency = object.currency ?? "";
    message.endCashBalance = object.endCashBalance ?? 0;
    message.collateral = object.collateral ?? 0;
    message.regulated = object.regulated ?? true;
    message.asOfDate = object.asOfDate ?? 0;
    return message;
  },
};

function createBaseUpdateBalanceRecord(): UpdateBalanceRecord {
  return { balanceId: 0, endCashBalance: 0, collateral: 0 };
}

export const UpdateBalanceRecord = {
  encode(message: UpdateBalanceRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.balanceId !== 0) {
      writer.uint32(8).sint32(message.balanceId);
    }
    if (message.endCashBalance !== undefined && message.endCashBalance !== 0) {
      writer.uint32(17).double(message.endCashBalance);
    }
    if (message.collateral !== undefined && message.collateral !== 0) {
      writer.uint32(25).double(message.collateral);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateBalanceRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateBalanceRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.balanceId = reader.sint32();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.endCashBalance = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.collateral = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateBalanceRecord {
    return {
      balanceId: isSet(object.balanceId) ? globalThis.Number(object.balanceId) : 0,
      endCashBalance: isSet(object.endCashBalance) ? globalThis.Number(object.endCashBalance) : 0,
      collateral: isSet(object.collateral) ? globalThis.Number(object.collateral) : 0,
    };
  },

  toJSON(message: UpdateBalanceRecord): unknown {
    const obj: any = {};
    if (message.balanceId !== 0) {
      obj.balanceId = Math.round(message.balanceId);
    }
    if (message.endCashBalance !== undefined && message.endCashBalance !== 0) {
      obj.endCashBalance = message.endCashBalance;
    }
    if (message.collateral !== undefined && message.collateral !== 0) {
      obj.collateral = message.collateral;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateBalanceRecord>, I>>(base?: I): UpdateBalanceRecord {
    return UpdateBalanceRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateBalanceRecord>, I>>(object: I): UpdateBalanceRecord {
    const message = createBaseUpdateBalanceRecord();
    message.balanceId = object.balanceId ?? 0;
    message.endCashBalance = object.endCashBalance ?? 0;
    message.collateral = object.collateral ?? 0;
    return message;
  },
};

function createBaseAccountSearchRequest(): AccountSearchRequest {
  return {
    obsoleteText: "",
    obsoleteSearchCriteria: [],
    top: 0,
    includeRemoved: false,
    searchOptions: [],
    allMatchMode: false,
    includeAccountsFromMappedSalesSeries: false,
  };
}

export const AccountSearchRequest = {
  encode(message: AccountSearchRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.obsoleteText !== undefined && message.obsoleteText !== "") {
      writer.uint32(10).string(message.obsoleteText);
    }
    writer.uint32(18).fork();
    for (const v of message.obsoleteSearchCriteria) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.top !== undefined && message.top !== 0) {
      writer.uint32(24).uint32(message.top);
    }
    if (message.includeRemoved !== undefined && message.includeRemoved !== false) {
      writer.uint32(32).bool(message.includeRemoved);
    }
    for (const v of message.searchOptions) {
      SearchOption.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.allMatchMode !== undefined && message.allMatchMode !== false) {
      writer.uint32(48).bool(message.allMatchMode);
    }
    if (
      message.includeAccountsFromMappedSalesSeries !== undefined &&
      message.includeAccountsFromMappedSalesSeries !== false
    ) {
      writer.uint32(56).bool(message.includeAccountsFromMappedSalesSeries);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountSearchRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountSearchRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.obsoleteText = reader.string();
          continue;
        case 2:
          if (tag === 16) {
            message.obsoleteSearchCriteria.push(reader.uint32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.obsoleteSearchCriteria.push(reader.uint32());
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.top = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeRemoved = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.searchOptions.push(SearchOption.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.allMatchMode = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.includeAccountsFromMappedSalesSeries = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountSearchRequest {
    return {
      obsoleteText: isSet(object.obsoleteText) ? globalThis.String(object.obsoleteText) : "",
      obsoleteSearchCriteria: globalThis.Array.isArray(object?.obsoleteSearchCriteria)
        ? object.obsoleteSearchCriteria.map((e: any) => globalThis.Number(e))
        : [],
      top: isSet(object.top) ? globalThis.Number(object.top) : 0,
      includeRemoved: isSet(object.includeRemoved) ? globalThis.Boolean(object.includeRemoved) : false,
      searchOptions: globalThis.Array.isArray(object?.searchOptions)
        ? object.searchOptions.map((e: any) => SearchOption.fromJSON(e))
        : [],
      allMatchMode: isSet(object.allMatchMode) ? globalThis.Boolean(object.allMatchMode) : false,
      includeAccountsFromMappedSalesSeries: isSet(object.includeAccountsFromMappedSalesSeries)
        ? globalThis.Boolean(object.includeAccountsFromMappedSalesSeries)
        : false,
    };
  },

  toJSON(message: AccountSearchRequest): unknown {
    const obj: any = {};
    if (message.obsoleteText !== undefined && message.obsoleteText !== "") {
      obj.obsoleteText = message.obsoleteText;
    }
    if (message.obsoleteSearchCriteria?.length) {
      obj.obsoleteSearchCriteria = message.obsoleteSearchCriteria.map((e) => Math.round(e));
    }
    if (message.top !== undefined && message.top !== 0) {
      obj.top = Math.round(message.top);
    }
    if (message.includeRemoved !== undefined && message.includeRemoved !== false) {
      obj.includeRemoved = message.includeRemoved;
    }
    if (message.searchOptions?.length) {
      obj.searchOptions = message.searchOptions.map((e) => SearchOption.toJSON(e));
    }
    if (message.allMatchMode !== undefined && message.allMatchMode !== false) {
      obj.allMatchMode = message.allMatchMode;
    }
    if (
      message.includeAccountsFromMappedSalesSeries !== undefined &&
      message.includeAccountsFromMappedSalesSeries !== false
    ) {
      obj.includeAccountsFromMappedSalesSeries = message.includeAccountsFromMappedSalesSeries;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountSearchRequest>, I>>(base?: I): AccountSearchRequest {
    return AccountSearchRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountSearchRequest>, I>>(object: I): AccountSearchRequest {
    const message = createBaseAccountSearchRequest();
    message.obsoleteText = object.obsoleteText ?? "";
    message.obsoleteSearchCriteria = object.obsoleteSearchCriteria?.map((e) => e) || [];
    message.top = object.top ?? 0;
    message.includeRemoved = object.includeRemoved ?? false;
    message.searchOptions = object.searchOptions?.map((e) => SearchOption.fromPartial(e)) || [];
    message.allMatchMode = object.allMatchMode ?? false;
    message.includeAccountsFromMappedSalesSeries = object.includeAccountsFromMappedSalesSeries ?? false;
    return message;
  },
};

function createBaseAccountRouteListRequest(): AccountRouteListRequest {
  return { accountId: 0 };
}

export const AccountRouteListRequest = {
  encode(message: AccountRouteListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountRouteListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountRouteListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountRouteListRequest {
    return { accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0 };
  },

  toJSON(message: AccountRouteListRequest): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountRouteListRequest>, I>>(base?: I): AccountRouteListRequest {
    return AccountRouteListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountRouteListRequest>, I>>(object: I): AccountRouteListRequest {
    const message = createBaseAccountRouteListRequest();
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseAccountUserAuthorizationListRequest(): AccountUserAuthorizationListRequest {
  return { accountId: 0, userId: "", top: 0, includeImplicitAuthorization: false };
}

export const AccountUserAuthorizationListRequest = {
  encode(message: AccountUserAuthorizationListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.userId !== undefined && message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.top !== undefined && message.top !== 0) {
      writer.uint32(24).uint32(message.top);
    }
    if (message.includeImplicitAuthorization !== undefined && message.includeImplicitAuthorization !== false) {
      writer.uint32(32).bool(message.includeImplicitAuthorization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountUserAuthorizationListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountUserAuthorizationListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.top = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeImplicitAuthorization = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountUserAuthorizationListRequest {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      top: isSet(object.top) ? globalThis.Number(object.top) : 0,
      includeImplicitAuthorization: isSet(object.includeImplicitAuthorization)
        ? globalThis.Boolean(object.includeImplicitAuthorization)
        : false,
    };
  },

  toJSON(message: AccountUserAuthorizationListRequest): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.userId !== undefined && message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.top !== undefined && message.top !== 0) {
      obj.top = Math.round(message.top);
    }
    if (message.includeImplicitAuthorization !== undefined && message.includeImplicitAuthorization !== false) {
      obj.includeImplicitAuthorization = message.includeImplicitAuthorization;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountUserAuthorizationListRequest>, I>>(
    base?: I,
  ): AccountUserAuthorizationListRequest {
    return AccountUserAuthorizationListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountUserAuthorizationListRequest>, I>>(
    object: I,
  ): AccountUserAuthorizationListRequest {
    const message = createBaseAccountUserAuthorizationListRequest();
    message.accountId = object.accountId ?? 0;
    message.userId = object.userId ?? "";
    message.top = object.top ?? 0;
    message.includeImplicitAuthorization = object.includeImplicitAuthorization ?? false;
    return message;
  },
};

function createBaseAccountSettingsRequest(): AccountSettingsRequest {
  return { accountId: 0 };
}

export const AccountSettingsRequest = {
  encode(message: AccountSettingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountSettingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountSettingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountSettingsRequest {
    return { accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0 };
  },

  toJSON(message: AccountSettingsRequest): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountSettingsRequest>, I>>(base?: I): AccountSettingsRequest {
    return AccountSettingsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountSettingsRequest>, I>>(object: I): AccountSettingsRequest {
    const message = createBaseAccountSettingsRequest();
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseAccountRiskParametersRequest(): AccountRiskParametersRequest {
  return { accountId: 0 };
}

export const AccountRiskParametersRequest = {
  encode(message: AccountRiskParametersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountRiskParametersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountRiskParametersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountRiskParametersRequest {
    return { accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0 };
  },

  toJSON(message: AccountRiskParametersRequest): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountRiskParametersRequest>, I>>(base?: I): AccountRiskParametersRequest {
    return AccountRiskParametersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountRiskParametersRequest>, I>>(object: I): AccountRiskParametersRequest {
    const message = createBaseAccountRiskParametersRequest();
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseUpdateRiskParameters(): UpdateRiskParameters {
  return { accountId: 0, liquidationOnly: false, allowFutures: false, allowOptions: 0, allowableMarginCredit: 0 };
}

export const UpdateRiskParameters = {
  encode(message: UpdateRiskParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.liquidationOnly !== undefined && message.liquidationOnly !== false) {
      writer.uint32(16).bool(message.liquidationOnly);
    }
    if (message.allowFutures !== undefined && message.allowFutures !== false) {
      writer.uint32(24).bool(message.allowFutures);
    }
    if (message.allowOptions !== undefined && message.allowOptions !== 0) {
      writer.uint32(32).uint32(message.allowOptions);
    }
    if (message.allowableMarginCredit !== undefined && message.allowableMarginCredit !== 0) {
      writer.uint32(41).double(message.allowableMarginCredit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateRiskParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRiskParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.liquidationOnly = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.allowFutures = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.allowOptions = reader.uint32();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.allowableMarginCredit = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateRiskParameters {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      liquidationOnly: isSet(object.liquidationOnly) ? globalThis.Boolean(object.liquidationOnly) : false,
      allowFutures: isSet(object.allowFutures) ? globalThis.Boolean(object.allowFutures) : false,
      allowOptions: isSet(object.allowOptions) ? globalThis.Number(object.allowOptions) : 0,
      allowableMarginCredit: isSet(object.allowableMarginCredit) ? globalThis.Number(object.allowableMarginCredit) : 0,
    };
  },

  toJSON(message: UpdateRiskParameters): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.liquidationOnly !== undefined && message.liquidationOnly !== false) {
      obj.liquidationOnly = message.liquidationOnly;
    }
    if (message.allowFutures !== undefined && message.allowFutures !== false) {
      obj.allowFutures = message.allowFutures;
    }
    if (message.allowOptions !== undefined && message.allowOptions !== 0) {
      obj.allowOptions = Math.round(message.allowOptions);
    }
    if (message.allowableMarginCredit !== undefined && message.allowableMarginCredit !== 0) {
      obj.allowableMarginCredit = message.allowableMarginCredit;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateRiskParameters>, I>>(base?: I): UpdateRiskParameters {
    return UpdateRiskParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateRiskParameters>, I>>(object: I): UpdateRiskParameters {
    const message = createBaseUpdateRiskParameters();
    message.accountId = object.accountId ?? 0;
    message.liquidationOnly = object.liquidationOnly ?? false;
    message.allowFutures = object.allowFutures ?? false;
    message.allowOptions = object.allowOptions ?? 0;
    message.allowableMarginCredit = object.allowableMarginCredit ?? 0;
    return message;
  },
};

function createBaseAccountAvailableExchangeGroupsRequest(): AccountAvailableExchangeGroupsRequest {
  return { accountId: 0 };
}

export const AccountAvailableExchangeGroupsRequest = {
  encode(message: AccountAvailableExchangeGroupsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountAvailableExchangeGroupsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountAvailableExchangeGroupsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountAvailableExchangeGroupsRequest {
    return { accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0 };
  },

  toJSON(message: AccountAvailableExchangeGroupsRequest): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountAvailableExchangeGroupsRequest>, I>>(
    base?: I,
  ): AccountAvailableExchangeGroupsRequest {
    return AccountAvailableExchangeGroupsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountAvailableExchangeGroupsRequest>, I>>(
    object: I,
  ): AccountAvailableExchangeGroupsRequest {
    const message = createBaseAccountAvailableExchangeGroupsRequest();
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseAccountMarketLimitsRequest(): AccountMarketLimitsRequest {
  return { accountId: 0 };
}

export const AccountMarketLimitsRequest = {
  encode(message: AccountMarketLimitsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountMarketLimitsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountMarketLimitsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountMarketLimitsRequest {
    return { accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0 };
  },

  toJSON(message: AccountMarketLimitsRequest): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountMarketLimitsRequest>, I>>(base?: I): AccountMarketLimitsRequest {
    return AccountMarketLimitsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountMarketLimitsRequest>, I>>(object: I): AccountMarketLimitsRequest {
    const message = createBaseAccountMarketLimitsRequest();
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseAccountPositionsRequest(): AccountPositionsRequest {
  return { accountId: 0, groupByContract: false };
}

export const AccountPositionsRequest = {
  encode(message: AccountPositionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.groupByContract !== undefined && message.groupByContract !== false) {
      writer.uint32(16).bool(message.groupByContract);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountPositionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountPositionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.groupByContract = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountPositionsRequest {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      groupByContract: isSet(object.groupByContract) ? globalThis.Boolean(object.groupByContract) : false,
    };
  },

  toJSON(message: AccountPositionsRequest): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.groupByContract !== undefined && message.groupByContract !== false) {
      obj.groupByContract = message.groupByContract;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountPositionsRequest>, I>>(base?: I): AccountPositionsRequest {
    return AccountPositionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountPositionsRequest>, I>>(object: I): AccountPositionsRequest {
    const message = createBaseAccountPositionsRequest();
    message.accountId = object.accountId ?? 0;
    message.groupByContract = object.groupByContract ?? false;
    return message;
  },
};

function createBaseAccountEquityRequest(): AccountEquityRequest {
  return { accountId: 0, currency: "" };
}

export const AccountEquityRequest = {
  encode(message: AccountEquityRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.currency !== undefined && message.currency !== "") {
      writer.uint32(18).string(message.currency);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountEquityRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountEquityRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currency = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountEquityRequest {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
    };
  },

  toJSON(message: AccountEquityRequest): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.currency !== undefined && message.currency !== "") {
      obj.currency = message.currency;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountEquityRequest>, I>>(base?: I): AccountEquityRequest {
    return AccountEquityRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountEquityRequest>, I>>(object: I): AccountEquityRequest {
    const message = createBaseAccountEquityRequest();
    message.accountId = object.accountId ?? 0;
    message.currency = object.currency ?? "";
    return message;
  },
};

function createBaseAccountCollateralRequest(): AccountCollateralRequest {
  return { accountId: 0 };
}

export const AccountCollateralRequest = {
  encode(message: AccountCollateralRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountCollateralRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountCollateralRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountCollateralRequest {
    return { accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0 };
  },

  toJSON(message: AccountCollateralRequest): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountCollateralRequest>, I>>(base?: I): AccountCollateralRequest {
    return AccountCollateralRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountCollateralRequest>, I>>(object: I): AccountCollateralRequest {
    const message = createBaseAccountCollateralRequest();
    message.accountId = object.accountId ?? 0;
    return message;
  },
};

function createBaseCloneAccount(): CloneAccount {
  return {
    sourceAccountId: 0,
    newAccountName: "",
    newAccountBrokerageNumber: "",
    newAccountUserId: "",
    newAccountClass: 0,
  };
}

export const CloneAccount = {
  encode(message: CloneAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sourceAccountId !== 0) {
      writer.uint32(8).sint32(message.sourceAccountId);
    }
    if (message.newAccountName !== "") {
      writer.uint32(18).string(message.newAccountName);
    }
    if (message.newAccountBrokerageNumber !== "") {
      writer.uint32(26).string(message.newAccountBrokerageNumber);
    }
    if (message.newAccountUserId !== undefined && message.newAccountUserId !== "") {
      writer.uint32(34).string(message.newAccountUserId);
    }
    if (message.newAccountClass !== undefined && message.newAccountClass !== 0) {
      writer.uint32(40).uint32(message.newAccountClass);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CloneAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCloneAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sourceAccountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newAccountName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.newAccountBrokerageNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.newAccountUserId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.newAccountClass = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CloneAccount {
    return {
      sourceAccountId: isSet(object.sourceAccountId) ? globalThis.Number(object.sourceAccountId) : 0,
      newAccountName: isSet(object.newAccountName) ? globalThis.String(object.newAccountName) : "",
      newAccountBrokerageNumber: isSet(object.newAccountBrokerageNumber)
        ? globalThis.String(object.newAccountBrokerageNumber)
        : "",
      newAccountUserId: isSet(object.newAccountUserId) ? globalThis.String(object.newAccountUserId) : "",
      newAccountClass: isSet(object.newAccountClass) ? globalThis.Number(object.newAccountClass) : 0,
    };
  },

  toJSON(message: CloneAccount): unknown {
    const obj: any = {};
    if (message.sourceAccountId !== 0) {
      obj.sourceAccountId = Math.round(message.sourceAccountId);
    }
    if (message.newAccountName !== "") {
      obj.newAccountName = message.newAccountName;
    }
    if (message.newAccountBrokerageNumber !== "") {
      obj.newAccountBrokerageNumber = message.newAccountBrokerageNumber;
    }
    if (message.newAccountUserId !== undefined && message.newAccountUserId !== "") {
      obj.newAccountUserId = message.newAccountUserId;
    }
    if (message.newAccountClass !== undefined && message.newAccountClass !== 0) {
      obj.newAccountClass = Math.round(message.newAccountClass);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CloneAccount>, I>>(base?: I): CloneAccount {
    return CloneAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CloneAccount>, I>>(object: I): CloneAccount {
    const message = createBaseCloneAccount();
    message.sourceAccountId = object.sourceAccountId ?? 0;
    message.newAccountName = object.newAccountName ?? "";
    message.newAccountBrokerageNumber = object.newAccountBrokerageNumber ?? "";
    message.newAccountUserId = object.newAccountUserId ?? "";
    message.newAccountClass = object.newAccountClass ?? 0;
    return message;
  },
};

function createBaseUpdateAccount(): UpdateAccount {
  return { account: undefined, obsoleteOriginalAccount: undefined };
}

export const UpdateAccount = {
  encode(message: UpdateAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.account !== undefined) {
      Account.encode(message.account, writer.uint32(10).fork()).ldelim();
    }
    if (message.obsoleteOriginalAccount !== undefined) {
      Account.encode(message.obsoleteOriginalAccount, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.account = Account.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.obsoleteOriginalAccount = Account.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccount {
    return {
      account: isSet(object.account) ? Account.fromJSON(object.account) : undefined,
      obsoleteOriginalAccount: isSet(object.obsoleteOriginalAccount)
        ? Account.fromJSON(object.obsoleteOriginalAccount)
        : undefined,
    };
  },

  toJSON(message: UpdateAccount): unknown {
    const obj: any = {};
    if (message.account !== undefined) {
      obj.account = Account.toJSON(message.account);
    }
    if (message.obsoleteOriginalAccount !== undefined) {
      obj.obsoleteOriginalAccount = Account.toJSON(message.obsoleteOriginalAccount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccount>, I>>(base?: I): UpdateAccount {
    return UpdateAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccount>, I>>(object: I): UpdateAccount {
    const message = createBaseUpdateAccount();
    message.account = (object.account !== undefined && object.account !== null)
      ? Account.fromPartial(object.account)
      : undefined;
    message.obsoleteOriginalAccount =
      (object.obsoleteOriginalAccount !== undefined && object.obsoleteOriginalAccount !== null)
        ? Account.fromPartial(object.obsoleteOriginalAccount)
        : undefined;
    return message;
  },
};

function createBaseAccountClusterRequest(): AccountClusterRequest {
  return { accountClusterId: "" };
}

export const AccountClusterRequest = {
  encode(message: AccountClusterRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountClusterId !== undefined && message.accountClusterId !== "") {
      writer.uint32(10).string(message.accountClusterId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountClusterRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountClusterRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountClusterId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountClusterRequest {
    return { accountClusterId: isSet(object.accountClusterId) ? globalThis.String(object.accountClusterId) : "" };
  },

  toJSON(message: AccountClusterRequest): unknown {
    const obj: any = {};
    if (message.accountClusterId !== undefined && message.accountClusterId !== "") {
      obj.accountClusterId = message.accountClusterId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountClusterRequest>, I>>(base?: I): AccountClusterRequest {
    return AccountClusterRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountClusterRequest>, I>>(object: I): AccountClusterRequest {
    const message = createBaseAccountClusterRequest();
    message.accountClusterId = object.accountClusterId ?? "";
    return message;
  },
};

function createBaseCreateAccountCluster(): CreateAccountCluster {
  return { obsoleteAccountCluster: undefined };
}

export const CreateAccountCluster = {
  encode(message: CreateAccountCluster, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.obsoleteAccountCluster !== undefined) {
      AccountCluster.encode(message.obsoleteAccountCluster, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAccountCluster {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAccountCluster();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.obsoleteAccountCluster = AccountCluster.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAccountCluster {
    return {
      obsoleteAccountCluster: isSet(object.obsoleteAccountCluster)
        ? AccountCluster.fromJSON(object.obsoleteAccountCluster)
        : undefined,
    };
  },

  toJSON(message: CreateAccountCluster): unknown {
    const obj: any = {};
    if (message.obsoleteAccountCluster !== undefined) {
      obj.obsoleteAccountCluster = AccountCluster.toJSON(message.obsoleteAccountCluster);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAccountCluster>, I>>(base?: I): CreateAccountCluster {
    return CreateAccountCluster.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAccountCluster>, I>>(object: I): CreateAccountCluster {
    const message = createBaseCreateAccountCluster();
    message.obsoleteAccountCluster =
      (object.obsoleteAccountCluster !== undefined && object.obsoleteAccountCluster !== null)
        ? AccountCluster.fromPartial(object.obsoleteAccountCluster)
        : undefined;
    return message;
  },
};

function createBaseUpdateAccountCluster(): UpdateAccountCluster {
  return { obsoleteAccountCluster: undefined, obsoleteOriginalAccountCluster: undefined };
}

export const UpdateAccountCluster = {
  encode(message: UpdateAccountCluster, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.obsoleteAccountCluster !== undefined) {
      AccountCluster.encode(message.obsoleteAccountCluster, writer.uint32(10).fork()).ldelim();
    }
    if (message.obsoleteOriginalAccountCluster !== undefined) {
      AccountCluster.encode(message.obsoleteOriginalAccountCluster, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountCluster {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountCluster();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.obsoleteAccountCluster = AccountCluster.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.obsoleteOriginalAccountCluster = AccountCluster.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountCluster {
    return {
      obsoleteAccountCluster: isSet(object.obsoleteAccountCluster)
        ? AccountCluster.fromJSON(object.obsoleteAccountCluster)
        : undefined,
      obsoleteOriginalAccountCluster: isSet(object.obsoleteOriginalAccountCluster)
        ? AccountCluster.fromJSON(object.obsoleteOriginalAccountCluster)
        : undefined,
    };
  },

  toJSON(message: UpdateAccountCluster): unknown {
    const obj: any = {};
    if (message.obsoleteAccountCluster !== undefined) {
      obj.obsoleteAccountCluster = AccountCluster.toJSON(message.obsoleteAccountCluster);
    }
    if (message.obsoleteOriginalAccountCluster !== undefined) {
      obj.obsoleteOriginalAccountCluster = AccountCluster.toJSON(message.obsoleteOriginalAccountCluster);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountCluster>, I>>(base?: I): UpdateAccountCluster {
    return UpdateAccountCluster.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountCluster>, I>>(object: I): UpdateAccountCluster {
    const message = createBaseUpdateAccountCluster();
    message.obsoleteAccountCluster =
      (object.obsoleteAccountCluster !== undefined && object.obsoleteAccountCluster !== null)
        ? AccountCluster.fromPartial(object.obsoleteAccountCluster)
        : undefined;
    message.obsoleteOriginalAccountCluster =
      (object.obsoleteOriginalAccountCluster !== undefined && object.obsoleteOriginalAccountCluster !== null)
        ? AccountCluster.fromPartial(object.obsoleteOriginalAccountCluster)
        : undefined;
    return message;
  },
};

function createBaseUpdateAccountClusterPriceOffsets(): UpdateAccountClusterPriceOffsets {
  return { accountClusterId: "", offsetsToSet: [], offsetsToRemove: [] };
}

export const UpdateAccountClusterPriceOffsets = {
  encode(message: UpdateAccountClusterPriceOffsets, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountClusterId !== undefined && message.accountClusterId !== "") {
      writer.uint32(10).string(message.accountClusterId);
    }
    for (const v of message.offsetsToSet) {
      AccountClusterPriceOffset.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.offsetsToRemove) {
      AccountClusterPriceOffset.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountClusterPriceOffsets {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountClusterPriceOffsets();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountClusterId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.offsetsToSet.push(AccountClusterPriceOffset.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.offsetsToRemove.push(AccountClusterPriceOffset.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountClusterPriceOffsets {
    return {
      accountClusterId: isSet(object.accountClusterId) ? globalThis.String(object.accountClusterId) : "",
      offsetsToSet: globalThis.Array.isArray(object?.offsetsToSet)
        ? object.offsetsToSet.map((e: any) => AccountClusterPriceOffset.fromJSON(e))
        : [],
      offsetsToRemove: globalThis.Array.isArray(object?.offsetsToRemove)
        ? object.offsetsToRemove.map((e: any) => AccountClusterPriceOffset.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateAccountClusterPriceOffsets): unknown {
    const obj: any = {};
    if (message.accountClusterId !== undefined && message.accountClusterId !== "") {
      obj.accountClusterId = message.accountClusterId;
    }
    if (message.offsetsToSet?.length) {
      obj.offsetsToSet = message.offsetsToSet.map((e) => AccountClusterPriceOffset.toJSON(e));
    }
    if (message.offsetsToRemove?.length) {
      obj.offsetsToRemove = message.offsetsToRemove.map((e) => AccountClusterPriceOffset.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountClusterPriceOffsets>, I>>(
    base?: I,
  ): UpdateAccountClusterPriceOffsets {
    return UpdateAccountClusterPriceOffsets.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountClusterPriceOffsets>, I>>(
    object: I,
  ): UpdateAccountClusterPriceOffsets {
    const message = createBaseUpdateAccountClusterPriceOffsets();
    message.accountClusterId = object.accountClusterId ?? "";
    message.offsetsToSet = object.offsetsToSet?.map((e) => AccountClusterPriceOffset.fromPartial(e)) || [];
    message.offsetsToRemove = object.offsetsToRemove?.map((e) => AccountClusterPriceOffset.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateAccountSettings(): UpdateAccountSettings {
  return { settings: undefined, obsoleteOriginalSettings: undefined };
}

export const UpdateAccountSettings = {
  encode(message: UpdateAccountSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.settings !== undefined) {
      AccountSettings.encode(message.settings, writer.uint32(10).fork()).ldelim();
    }
    if (message.obsoleteOriginalSettings !== undefined) {
      AccountSettings.encode(message.obsoleteOriginalSettings, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.settings = AccountSettings.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.obsoleteOriginalSettings = AccountSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountSettings {
    return {
      settings: isSet(object.settings) ? AccountSettings.fromJSON(object.settings) : undefined,
      obsoleteOriginalSettings: isSet(object.obsoleteOriginalSettings)
        ? AccountSettings.fromJSON(object.obsoleteOriginalSettings)
        : undefined,
    };
  },

  toJSON(message: UpdateAccountSettings): unknown {
    const obj: any = {};
    if (message.settings !== undefined) {
      obj.settings = AccountSettings.toJSON(message.settings);
    }
    if (message.obsoleteOriginalSettings !== undefined) {
      obj.obsoleteOriginalSettings = AccountSettings.toJSON(message.obsoleteOriginalSettings);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountSettings>, I>>(base?: I): UpdateAccountSettings {
    return UpdateAccountSettings.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountSettings>, I>>(object: I): UpdateAccountSettings {
    const message = createBaseUpdateAccountSettings();
    message.settings = (object.settings !== undefined && object.settings !== null)
      ? AccountSettings.fromPartial(object.settings)
      : undefined;
    message.obsoleteOriginalSettings =
      (object.obsoleteOriginalSettings !== undefined && object.obsoleteOriginalSettings !== null)
        ? AccountSettings.fromPartial(object.obsoleteOriginalSettings)
        : undefined;
    return message;
  },
};

function createBaseAccountGroupRequest(): AccountGroupRequest {
  return { accountId: "", accountGroupId: "" };
}

export const AccountGroupRequest = {
  encode(message: AccountGroupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    if (message.accountGroupId !== undefined && message.accountGroupId !== "") {
      writer.uint32(18).string(message.accountGroupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountGroupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountGroupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountGroupId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountGroupRequest {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      accountGroupId: isSet(object.accountGroupId) ? globalThis.String(object.accountGroupId) : "",
    };
  },

  toJSON(message: AccountGroupRequest): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.accountGroupId !== undefined && message.accountGroupId !== "") {
      obj.accountGroupId = message.accountGroupId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountGroupRequest>, I>>(base?: I): AccountGroupRequest {
    return AccountGroupRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountGroupRequest>, I>>(object: I): AccountGroupRequest {
    const message = createBaseAccountGroupRequest();
    message.accountId = object.accountId ?? "";
    message.accountGroupId = object.accountGroupId ?? "";
    return message;
  },
};

function createBaseUpdateAccountRiskParameters(): UpdateAccountRiskParameters {
  return { accountId: "", riskParameters: undefined, obsoleteOriginalRiskParameters: undefined };
}

export const UpdateAccountRiskParameters = {
  encode(message: UpdateAccountRiskParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(26).string(message.accountId);
    }
    if (message.riskParameters !== undefined) {
      RiskParameters.encode(message.riskParameters, writer.uint32(10).fork()).ldelim();
    }
    if (message.obsoleteOriginalRiskParameters !== undefined) {
      RiskParameters.encode(message.obsoleteOriginalRiskParameters, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountRiskParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountRiskParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.riskParameters = RiskParameters.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.obsoleteOriginalRiskParameters = RiskParameters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountRiskParameters {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      riskParameters: isSet(object.riskParameters) ? RiskParameters.fromJSON(object.riskParameters) : undefined,
      obsoleteOriginalRiskParameters: isSet(object.obsoleteOriginalRiskParameters)
        ? RiskParameters.fromJSON(object.obsoleteOriginalRiskParameters)
        : undefined,
    };
  },

  toJSON(message: UpdateAccountRiskParameters): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.riskParameters !== undefined) {
      obj.riskParameters = RiskParameters.toJSON(message.riskParameters);
    }
    if (message.obsoleteOriginalRiskParameters !== undefined) {
      obj.obsoleteOriginalRiskParameters = RiskParameters.toJSON(message.obsoleteOriginalRiskParameters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountRiskParameters>, I>>(base?: I): UpdateAccountRiskParameters {
    return UpdateAccountRiskParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountRiskParameters>, I>>(object: I): UpdateAccountRiskParameters {
    const message = createBaseUpdateAccountRiskParameters();
    message.accountId = object.accountId ?? "";
    message.riskParameters = (object.riskParameters !== undefined && object.riskParameters !== null)
      ? RiskParameters.fromPartial(object.riskParameters)
      : undefined;
    message.obsoleteOriginalRiskParameters =
      (object.obsoleteOriginalRiskParameters !== undefined && object.obsoleteOriginalRiskParameters !== null)
        ? RiskParameters.fromPartial(object.obsoleteOriginalRiskParameters)
        : undefined;
    return message;
  },
};

function createBaseUpdateAccountRouteList(): UpdateAccountRouteList {
  return { accountId: "", routesToSet: [], routeCodesToRemove: [] };
}

export const UpdateAccountRouteList = {
  encode(message: UpdateAccountRouteList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    for (const v of message.routesToSet) {
      AccountRouteRecord.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.routeCodesToRemove) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountRouteList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountRouteList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.routesToSet.push(AccountRouteRecord.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.routeCodesToRemove.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountRouteList {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      routesToSet: globalThis.Array.isArray(object?.routesToSet)
        ? object.routesToSet.map((e: any) => AccountRouteRecord.fromJSON(e))
        : [],
      routeCodesToRemove: globalThis.Array.isArray(object?.routeCodesToRemove)
        ? object.routeCodesToRemove.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: UpdateAccountRouteList): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.routesToSet?.length) {
      obj.routesToSet = message.routesToSet.map((e) => AccountRouteRecord.toJSON(e));
    }
    if (message.routeCodesToRemove?.length) {
      obj.routeCodesToRemove = message.routeCodesToRemove;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountRouteList>, I>>(base?: I): UpdateAccountRouteList {
    return UpdateAccountRouteList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountRouteList>, I>>(object: I): UpdateAccountRouteList {
    const message = createBaseUpdateAccountRouteList();
    message.accountId = object.accountId ?? "";
    message.routesToSet = object.routesToSet?.map((e) => AccountRouteRecord.fromPartial(e)) || [];
    message.routeCodesToRemove = object.routeCodesToRemove?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateAccountMarketLimits(): UpdateAccountMarketLimits {
  return { accountId: "", marketLimits: undefined, obsoleteOriginalMarketLimits: undefined };
}

export const UpdateAccountMarketLimits = {
  encode(message: UpdateAccountMarketLimits, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    if (message.marketLimits !== undefined) {
      MarketLimits.encode(message.marketLimits, writer.uint32(18).fork()).ldelim();
    }
    if (message.obsoleteOriginalMarketLimits !== undefined) {
      MarketLimits.encode(message.obsoleteOriginalMarketLimits, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountMarketLimits {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountMarketLimits();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.marketLimits = MarketLimits.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.obsoleteOriginalMarketLimits = MarketLimits.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountMarketLimits {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      marketLimits: isSet(object.marketLimits) ? MarketLimits.fromJSON(object.marketLimits) : undefined,
      obsoleteOriginalMarketLimits: isSet(object.obsoleteOriginalMarketLimits)
        ? MarketLimits.fromJSON(object.obsoleteOriginalMarketLimits)
        : undefined,
    };
  },

  toJSON(message: UpdateAccountMarketLimits): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.marketLimits !== undefined) {
      obj.marketLimits = MarketLimits.toJSON(message.marketLimits);
    }
    if (message.obsoleteOriginalMarketLimits !== undefined) {
      obj.obsoleteOriginalMarketLimits = MarketLimits.toJSON(message.obsoleteOriginalMarketLimits);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountMarketLimits>, I>>(base?: I): UpdateAccountMarketLimits {
    return UpdateAccountMarketLimits.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountMarketLimits>, I>>(object: I): UpdateAccountMarketLimits {
    const message = createBaseUpdateAccountMarketLimits();
    message.accountId = object.accountId ?? "";
    message.marketLimits = (object.marketLimits !== undefined && object.marketLimits !== null)
      ? MarketLimits.fromPartial(object.marketLimits)
      : undefined;
    message.obsoleteOriginalMarketLimits =
      (object.obsoleteOriginalMarketLimits !== undefined && object.obsoleteOriginalMarketLimits !== null)
        ? MarketLimits.fromPartial(object.obsoleteOriginalMarketLimits)
        : undefined;
    return message;
  },
};

function createBaseAccountAvailableRouteListRequest(): AccountAvailableRouteListRequest {
  return { accountId: "" };
}

export const AccountAvailableRouteListRequest = {
  encode(message: AccountAvailableRouteListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountAvailableRouteListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountAvailableRouteListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountAvailableRouteListRequest {
    return { accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "" };
  },

  toJSON(message: AccountAvailableRouteListRequest): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountAvailableRouteListRequest>, I>>(
    base?: I,
  ): AccountAvailableRouteListRequest {
    return AccountAvailableRouteListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountAvailableRouteListRequest>, I>>(
    object: I,
  ): AccountAvailableRouteListRequest {
    const message = createBaseAccountAvailableRouteListRequest();
    message.accountId = object.accountId ?? "";
    return message;
  },
};

function createBaseCreateAccount(): CreateAccount {
  return { account: undefined };
}

export const CreateAccount = {
  encode(message: CreateAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.account !== undefined) {
      Account.encode(message.account, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.account = Account.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAccount {
    return { account: isSet(object.account) ? Account.fromJSON(object.account) : undefined };
  },

  toJSON(message: CreateAccount): unknown {
    const obj: any = {};
    if (message.account !== undefined) {
      obj.account = Account.toJSON(message.account);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAccount>, I>>(base?: I): CreateAccount {
    return CreateAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAccount>, I>>(object: I): CreateAccount {
    const message = createBaseCreateAccount();
    message.account = (object.account !== undefined && object.account !== null)
      ? Account.fromPartial(object.account)
      : undefined;
    return message;
  },
};

function createBaseTradeRoutingResult(): TradeRoutingResult {
  return {
    requestId: 0,
    operationStatus: 0,
    errorMessage: undefined,
    accountScopeResult: undefined,
    orderScopeResult: undefined,
    lookupPropertyListResult: undefined,
    trackingNumber: 0,
  };
}

export const TradeRoutingResult = {
  encode(message: TradeRoutingResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.operationStatus !== 0) {
      writer.uint32(16).uint32(message.operationStatus);
    }
    if (message.errorMessage !== undefined) {
      Text.encode(message.errorMessage, writer.uint32(26).fork()).ldelim();
    }
    if (message.accountScopeResult !== undefined) {
      AccountScopeResult.encode(message.accountScopeResult, writer.uint32(34).fork()).ldelim();
    }
    if (message.orderScopeResult !== undefined) {
      OrderScopeResult.encode(message.orderScopeResult, writer.uint32(42).fork()).ldelim();
    }
    if (message.lookupPropertyListResult !== undefined) {
      LookupPropertyListResult.encode(message.lookupPropertyListResult, writer.uint32(50).fork()).ldelim();
    }
    if (message.trackingNumber !== undefined && message.trackingNumber !== 0) {
      writer.uint32(56).uint64(message.trackingNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradeRoutingResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradeRoutingResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.operationStatus = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.errorMessage = Text.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.accountScopeResult = AccountScopeResult.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orderScopeResult = OrderScopeResult.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lookupPropertyListResult = LookupPropertyListResult.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.trackingNumber = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradeRoutingResult {
    return {
      requestId: isSet(object.requestId) ? globalThis.Number(object.requestId) : 0,
      operationStatus: isSet(object.operationStatus) ? globalThis.Number(object.operationStatus) : 0,
      errorMessage: isSet(object.errorMessage) ? Text.fromJSON(object.errorMessage) : undefined,
      accountScopeResult: isSet(object.accountScopeResult)
        ? AccountScopeResult.fromJSON(object.accountScopeResult)
        : undefined,
      orderScopeResult: isSet(object.orderScopeResult) ? OrderScopeResult.fromJSON(object.orderScopeResult) : undefined,
      lookupPropertyListResult: isSet(object.lookupPropertyListResult)
        ? LookupPropertyListResult.fromJSON(object.lookupPropertyListResult)
        : undefined,
      trackingNumber: isSet(object.trackingNumber) ? globalThis.Number(object.trackingNumber) : 0,
    };
  },

  toJSON(message: TradeRoutingResult): unknown {
    const obj: any = {};
    if (message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    if (message.operationStatus !== 0) {
      obj.operationStatus = Math.round(message.operationStatus);
    }
    if (message.errorMessage !== undefined) {
      obj.errorMessage = Text.toJSON(message.errorMessage);
    }
    if (message.accountScopeResult !== undefined) {
      obj.accountScopeResult = AccountScopeResult.toJSON(message.accountScopeResult);
    }
    if (message.orderScopeResult !== undefined) {
      obj.orderScopeResult = OrderScopeResult.toJSON(message.orderScopeResult);
    }
    if (message.lookupPropertyListResult !== undefined) {
      obj.lookupPropertyListResult = LookupPropertyListResult.toJSON(message.lookupPropertyListResult);
    }
    if (message.trackingNumber !== undefined && message.trackingNumber !== 0) {
      obj.trackingNumber = Math.round(message.trackingNumber);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradeRoutingResult>, I>>(base?: I): TradeRoutingResult {
    return TradeRoutingResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradeRoutingResult>, I>>(object: I): TradeRoutingResult {
    const message = createBaseTradeRoutingResult();
    message.requestId = object.requestId ?? 0;
    message.operationStatus = object.operationStatus ?? 0;
    message.errorMessage = (object.errorMessage !== undefined && object.errorMessage !== null)
      ? Text.fromPartial(object.errorMessage)
      : undefined;
    message.accountScopeResult = (object.accountScopeResult !== undefined && object.accountScopeResult !== null)
      ? AccountScopeResult.fromPartial(object.accountScopeResult)
      : undefined;
    message.orderScopeResult = (object.orderScopeResult !== undefined && object.orderScopeResult !== null)
      ? OrderScopeResult.fromPartial(object.orderScopeResult)
      : undefined;
    message.lookupPropertyListResult =
      (object.lookupPropertyListResult !== undefined && object.lookupPropertyListResult !== null)
        ? LookupPropertyListResult.fromPartial(object.lookupPropertyListResult)
        : undefined;
    message.trackingNumber = object.trackingNumber ?? 0;
    return message;
  },
};

function createBaseAccountScopeResult(): AccountScopeResult {
  return {
    accountInfoResult: undefined,
    balanceRecordsResult: undefined,
    createBalanceRecordResult: undefined,
    updateBalanceRecordResult: undefined,
    accountRiskParametersResult: undefined,
    updateRiskParametersResult: undefined,
    accountSearchResult: undefined,
    accountRouteListResult: undefined,
    accountUserAuthorizationListResult: undefined,
    accountSettingsResult: undefined,
    accountExchangeGroupsResult: undefined,
    accountMarketLimitsResult: undefined,
    accountPositionsResult: undefined,
    accountEquityResult: undefined,
    accountCollateralResult: undefined,
    cloneAccountResult: undefined,
    updateAccountResult: undefined,
    updateAccountSettingsResult: undefined,
    accountGroupResult: undefined,
    updateAccountRiskParametersResult: undefined,
    accountAvailableRouteListResult: undefined,
    updateAccountRouteListResult: undefined,
    updateAccountMarketLimitsResult: undefined,
    createAccountResult: undefined,
    obsoleteAccountClusterResult: undefined,
    obsoleteCreateAccountClusterResult: undefined,
    obsoleteUpdateAccountClusterResult: undefined,
    obsoleteUpdateAccountClusterPriceOffsetsResult: undefined,
    restoreAccountResult: undefined,
    accountTypeOverrideListResult: undefined,
    updateAccountTypeOverrideListResult: undefined,
    updateAccountUserAuthorizationListResult: undefined,
    removeAccountResult: undefined,
    accountAvailableServiceGroupListResult: undefined,
    accountServiceGroupListResult: undefined,
    updateAccountServiceGroupListResult: undefined,
    billableExchangeListResult: undefined,
    createAccountGroupResult: undefined,
    accountTradingFeaturesResult: undefined,
  };
}

export const AccountScopeResult = {
  encode(message: AccountScopeResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountInfoResult !== undefined) {
      AccountInfoResult.encode(message.accountInfoResult, writer.uint32(10).fork()).ldelim();
    }
    if (message.balanceRecordsResult !== undefined) {
      BalanceRecordsResult.encode(message.balanceRecordsResult, writer.uint32(18).fork()).ldelim();
    }
    if (message.createBalanceRecordResult !== undefined) {
      CreateBalanceRecordResult.encode(message.createBalanceRecordResult, writer.uint32(26).fork()).ldelim();
    }
    if (message.updateBalanceRecordResult !== undefined) {
      UpdateBalanceRecordResult.encode(message.updateBalanceRecordResult, writer.uint32(34).fork()).ldelim();
    }
    if (message.accountRiskParametersResult !== undefined) {
      AccountRiskParametersResult.encode(message.accountRiskParametersResult, writer.uint32(42).fork()).ldelim();
    }
    if (message.updateRiskParametersResult !== undefined) {
      UpdateRiskParametersResult.encode(message.updateRiskParametersResult, writer.uint32(50).fork()).ldelim();
    }
    if (message.accountSearchResult !== undefined) {
      AccountSearchResult.encode(message.accountSearchResult, writer.uint32(58).fork()).ldelim();
    }
    if (message.accountRouteListResult !== undefined) {
      AccountRouteListResult.encode(message.accountRouteListResult, writer.uint32(66).fork()).ldelim();
    }
    if (message.accountUserAuthorizationListResult !== undefined) {
      AccountUserAuthorizationListResult.encode(message.accountUserAuthorizationListResult, writer.uint32(74).fork())
        .ldelim();
    }
    if (message.accountSettingsResult !== undefined) {
      AccountSettingsResult.encode(message.accountSettingsResult, writer.uint32(82).fork()).ldelim();
    }
    if (message.accountExchangeGroupsResult !== undefined) {
      AccountExchangeGroupsResult.encode(message.accountExchangeGroupsResult, writer.uint32(90).fork()).ldelim();
    }
    if (message.accountMarketLimitsResult !== undefined) {
      AccountMarketLimitsResult.encode(message.accountMarketLimitsResult, writer.uint32(98).fork()).ldelim();
    }
    if (message.accountPositionsResult !== undefined) {
      AccountPositionsResult.encode(message.accountPositionsResult, writer.uint32(106).fork()).ldelim();
    }
    if (message.accountEquityResult !== undefined) {
      AccountEquityResult.encode(message.accountEquityResult, writer.uint32(114).fork()).ldelim();
    }
    if (message.accountCollateralResult !== undefined) {
      AccountCollateralResult.encode(message.accountCollateralResult, writer.uint32(122).fork()).ldelim();
    }
    if (message.cloneAccountResult !== undefined) {
      CloneAccountResult.encode(message.cloneAccountResult, writer.uint32(130).fork()).ldelim();
    }
    if (message.updateAccountResult !== undefined) {
      UpdateAccountResult.encode(message.updateAccountResult, writer.uint32(138).fork()).ldelim();
    }
    if (message.updateAccountSettingsResult !== undefined) {
      UpdateAccountSettingsResult.encode(message.updateAccountSettingsResult, writer.uint32(146).fork()).ldelim();
    }
    if (message.accountGroupResult !== undefined) {
      AccountGroupResult.encode(message.accountGroupResult, writer.uint32(154).fork()).ldelim();
    }
    if (message.updateAccountRiskParametersResult !== undefined) {
      UpdateAccountRiskParametersResult.encode(message.updateAccountRiskParametersResult, writer.uint32(162).fork())
        .ldelim();
    }
    if (message.accountAvailableRouteListResult !== undefined) {
      AccountAvailableRouteListResult.encode(message.accountAvailableRouteListResult, writer.uint32(170).fork())
        .ldelim();
    }
    if (message.updateAccountRouteListResult !== undefined) {
      UpdateAccountRouteListResult.encode(message.updateAccountRouteListResult, writer.uint32(178).fork()).ldelim();
    }
    if (message.updateAccountMarketLimitsResult !== undefined) {
      UpdateAccountMarketLimitsResult.encode(message.updateAccountMarketLimitsResult, writer.uint32(186).fork())
        .ldelim();
    }
    if (message.createAccountResult !== undefined) {
      CreateAccountResult.encode(message.createAccountResult, writer.uint32(194).fork()).ldelim();
    }
    if (message.obsoleteAccountClusterResult !== undefined) {
      AccountClusterResult.encode(message.obsoleteAccountClusterResult, writer.uint32(202).fork()).ldelim();
    }
    if (message.obsoleteCreateAccountClusterResult !== undefined) {
      CreateAccountClusterResult.encode(message.obsoleteCreateAccountClusterResult, writer.uint32(210).fork()).ldelim();
    }
    if (message.obsoleteUpdateAccountClusterResult !== undefined) {
      UpdateAccountClusterResult.encode(message.obsoleteUpdateAccountClusterResult, writer.uint32(218).fork()).ldelim();
    }
    if (message.obsoleteUpdateAccountClusterPriceOffsetsResult !== undefined) {
      UpdateAccountClusterPriceOffsetsResult.encode(
        message.obsoleteUpdateAccountClusterPriceOffsetsResult,
        writer.uint32(226).fork(),
      ).ldelim();
    }
    if (message.restoreAccountResult !== undefined) {
      RestoreAccountResult.encode(message.restoreAccountResult, writer.uint32(234).fork()).ldelim();
    }
    if (message.accountTypeOverrideListResult !== undefined) {
      AccountTypeOverrideListResult.encode(message.accountTypeOverrideListResult, writer.uint32(242).fork()).ldelim();
    }
    if (message.updateAccountTypeOverrideListResult !== undefined) {
      UpdateAccountTypeOverrideListResult.encode(message.updateAccountTypeOverrideListResult, writer.uint32(250).fork())
        .ldelim();
    }
    if (message.updateAccountUserAuthorizationListResult !== undefined) {
      UpdateAccountUserAuthorizationListResult.encode(
        message.updateAccountUserAuthorizationListResult,
        writer.uint32(258).fork(),
      ).ldelim();
    }
    if (message.removeAccountResult !== undefined) {
      RemoveAccountResult.encode(message.removeAccountResult, writer.uint32(266).fork()).ldelim();
    }
    if (message.accountAvailableServiceGroupListResult !== undefined) {
      AccountAvailableServiceGroupListResult.encode(
        message.accountAvailableServiceGroupListResult,
        writer.uint32(274).fork(),
      ).ldelim();
    }
    if (message.accountServiceGroupListResult !== undefined) {
      AccountServiceGroupListResult.encode(message.accountServiceGroupListResult, writer.uint32(282).fork()).ldelim();
    }
    if (message.updateAccountServiceGroupListResult !== undefined) {
      UpdateAccountServiceGroupListResult.encode(message.updateAccountServiceGroupListResult, writer.uint32(290).fork())
        .ldelim();
    }
    if (message.billableExchangeListResult !== undefined) {
      BillableExchangeListResult.encode(message.billableExchangeListResult, writer.uint32(298).fork()).ldelim();
    }
    if (message.createAccountGroupResult !== undefined) {
      CreateAccountGroupResult.encode(message.createAccountGroupResult, writer.uint32(306).fork()).ldelim();
    }
    if (message.accountTradingFeaturesResult !== undefined) {
      AccountTradingFeaturesResult.encode(message.accountTradingFeaturesResult, writer.uint32(314).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountScopeResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountScopeResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountInfoResult = AccountInfoResult.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.balanceRecordsResult = BalanceRecordsResult.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createBalanceRecordResult = CreateBalanceRecordResult.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updateBalanceRecordResult = UpdateBalanceRecordResult.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.accountRiskParametersResult = AccountRiskParametersResult.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updateRiskParametersResult = UpdateRiskParametersResult.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.accountSearchResult = AccountSearchResult.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.accountRouteListResult = AccountRouteListResult.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.accountUserAuthorizationListResult = AccountUserAuthorizationListResult.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.accountSettingsResult = AccountSettingsResult.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.accountExchangeGroupsResult = AccountExchangeGroupsResult.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.accountMarketLimitsResult = AccountMarketLimitsResult.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.accountPositionsResult = AccountPositionsResult.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.accountEquityResult = AccountEquityResult.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.accountCollateralResult = AccountCollateralResult.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.cloneAccountResult = CloneAccountResult.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.updateAccountResult = UpdateAccountResult.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.updateAccountSettingsResult = UpdateAccountSettingsResult.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.accountGroupResult = AccountGroupResult.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.updateAccountRiskParametersResult = UpdateAccountRiskParametersResult.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.accountAvailableRouteListResult = AccountAvailableRouteListResult.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.updateAccountRouteListResult = UpdateAccountRouteListResult.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.updateAccountMarketLimitsResult = UpdateAccountMarketLimitsResult.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.createAccountResult = CreateAccountResult.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.obsoleteAccountClusterResult = AccountClusterResult.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.obsoleteCreateAccountClusterResult = CreateAccountClusterResult.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.obsoleteUpdateAccountClusterResult = UpdateAccountClusterResult.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.obsoleteUpdateAccountClusterPriceOffsetsResult = UpdateAccountClusterPriceOffsetsResult.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.restoreAccountResult = RestoreAccountResult.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.accountTypeOverrideListResult = AccountTypeOverrideListResult.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.updateAccountTypeOverrideListResult = UpdateAccountTypeOverrideListResult.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.updateAccountUserAuthorizationListResult = UpdateAccountUserAuthorizationListResult.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.removeAccountResult = RemoveAccountResult.decode(reader, reader.uint32());
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.accountAvailableServiceGroupListResult = AccountAvailableServiceGroupListResult.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.accountServiceGroupListResult = AccountServiceGroupListResult.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.updateAccountServiceGroupListResult = UpdateAccountServiceGroupListResult.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.billableExchangeListResult = BillableExchangeListResult.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag !== 306) {
            break;
          }

          message.createAccountGroupResult = CreateAccountGroupResult.decode(reader, reader.uint32());
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.accountTradingFeaturesResult = AccountTradingFeaturesResult.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountScopeResult {
    return {
      accountInfoResult: isSet(object.accountInfoResult)
        ? AccountInfoResult.fromJSON(object.accountInfoResult)
        : undefined,
      balanceRecordsResult: isSet(object.balanceRecordsResult)
        ? BalanceRecordsResult.fromJSON(object.balanceRecordsResult)
        : undefined,
      createBalanceRecordResult: isSet(object.createBalanceRecordResult)
        ? CreateBalanceRecordResult.fromJSON(object.createBalanceRecordResult)
        : undefined,
      updateBalanceRecordResult: isSet(object.updateBalanceRecordResult)
        ? UpdateBalanceRecordResult.fromJSON(object.updateBalanceRecordResult)
        : undefined,
      accountRiskParametersResult: isSet(object.accountRiskParametersResult)
        ? AccountRiskParametersResult.fromJSON(object.accountRiskParametersResult)
        : undefined,
      updateRiskParametersResult: isSet(object.updateRiskParametersResult)
        ? UpdateRiskParametersResult.fromJSON(object.updateRiskParametersResult)
        : undefined,
      accountSearchResult: isSet(object.accountSearchResult)
        ? AccountSearchResult.fromJSON(object.accountSearchResult)
        : undefined,
      accountRouteListResult: isSet(object.accountRouteListResult)
        ? AccountRouteListResult.fromJSON(object.accountRouteListResult)
        : undefined,
      accountUserAuthorizationListResult: isSet(object.accountUserAuthorizationListResult)
        ? AccountUserAuthorizationListResult.fromJSON(object.accountUserAuthorizationListResult)
        : undefined,
      accountSettingsResult: isSet(object.accountSettingsResult)
        ? AccountSettingsResult.fromJSON(object.accountSettingsResult)
        : undefined,
      accountExchangeGroupsResult: isSet(object.accountExchangeGroupsResult)
        ? AccountExchangeGroupsResult.fromJSON(object.accountExchangeGroupsResult)
        : undefined,
      accountMarketLimitsResult: isSet(object.accountMarketLimitsResult)
        ? AccountMarketLimitsResult.fromJSON(object.accountMarketLimitsResult)
        : undefined,
      accountPositionsResult: isSet(object.accountPositionsResult)
        ? AccountPositionsResult.fromJSON(object.accountPositionsResult)
        : undefined,
      accountEquityResult: isSet(object.accountEquityResult)
        ? AccountEquityResult.fromJSON(object.accountEquityResult)
        : undefined,
      accountCollateralResult: isSet(object.accountCollateralResult)
        ? AccountCollateralResult.fromJSON(object.accountCollateralResult)
        : undefined,
      cloneAccountResult: isSet(object.cloneAccountResult)
        ? CloneAccountResult.fromJSON(object.cloneAccountResult)
        : undefined,
      updateAccountResult: isSet(object.updateAccountResult)
        ? UpdateAccountResult.fromJSON(object.updateAccountResult)
        : undefined,
      updateAccountSettingsResult: isSet(object.updateAccountSettingsResult)
        ? UpdateAccountSettingsResult.fromJSON(object.updateAccountSettingsResult)
        : undefined,
      accountGroupResult: isSet(object.accountGroupResult)
        ? AccountGroupResult.fromJSON(object.accountGroupResult)
        : undefined,
      updateAccountRiskParametersResult: isSet(object.updateAccountRiskParametersResult)
        ? UpdateAccountRiskParametersResult.fromJSON(object.updateAccountRiskParametersResult)
        : undefined,
      accountAvailableRouteListResult: isSet(object.accountAvailableRouteListResult)
        ? AccountAvailableRouteListResult.fromJSON(object.accountAvailableRouteListResult)
        : undefined,
      updateAccountRouteListResult: isSet(object.updateAccountRouteListResult)
        ? UpdateAccountRouteListResult.fromJSON(object.updateAccountRouteListResult)
        : undefined,
      updateAccountMarketLimitsResult: isSet(object.updateAccountMarketLimitsResult)
        ? UpdateAccountMarketLimitsResult.fromJSON(object.updateAccountMarketLimitsResult)
        : undefined,
      createAccountResult: isSet(object.createAccountResult)
        ? CreateAccountResult.fromJSON(object.createAccountResult)
        : undefined,
      obsoleteAccountClusterResult: isSet(object.obsoleteAccountClusterResult)
        ? AccountClusterResult.fromJSON(object.obsoleteAccountClusterResult)
        : undefined,
      obsoleteCreateAccountClusterResult: isSet(object.obsoleteCreateAccountClusterResult)
        ? CreateAccountClusterResult.fromJSON(object.obsoleteCreateAccountClusterResult)
        : undefined,
      obsoleteUpdateAccountClusterResult: isSet(object.obsoleteUpdateAccountClusterResult)
        ? UpdateAccountClusterResult.fromJSON(object.obsoleteUpdateAccountClusterResult)
        : undefined,
      obsoleteUpdateAccountClusterPriceOffsetsResult: isSet(object.obsoleteUpdateAccountClusterPriceOffsetsResult)
        ? UpdateAccountClusterPriceOffsetsResult.fromJSON(object.obsoleteUpdateAccountClusterPriceOffsetsResult)
        : undefined,
      restoreAccountResult: isSet(object.restoreAccountResult)
        ? RestoreAccountResult.fromJSON(object.restoreAccountResult)
        : undefined,
      accountTypeOverrideListResult: isSet(object.accountTypeOverrideListResult)
        ? AccountTypeOverrideListResult.fromJSON(object.accountTypeOverrideListResult)
        : undefined,
      updateAccountTypeOverrideListResult: isSet(object.updateAccountTypeOverrideListResult)
        ? UpdateAccountTypeOverrideListResult.fromJSON(object.updateAccountTypeOverrideListResult)
        : undefined,
      updateAccountUserAuthorizationListResult: isSet(object.updateAccountUserAuthorizationListResult)
        ? UpdateAccountUserAuthorizationListResult.fromJSON(object.updateAccountUserAuthorizationListResult)
        : undefined,
      removeAccountResult: isSet(object.removeAccountResult)
        ? RemoveAccountResult.fromJSON(object.removeAccountResult)
        : undefined,
      accountAvailableServiceGroupListResult: isSet(object.accountAvailableServiceGroupListResult)
        ? AccountAvailableServiceGroupListResult.fromJSON(object.accountAvailableServiceGroupListResult)
        : undefined,
      accountServiceGroupListResult: isSet(object.accountServiceGroupListResult)
        ? AccountServiceGroupListResult.fromJSON(object.accountServiceGroupListResult)
        : undefined,
      updateAccountServiceGroupListResult: isSet(object.updateAccountServiceGroupListResult)
        ? UpdateAccountServiceGroupListResult.fromJSON(object.updateAccountServiceGroupListResult)
        : undefined,
      billableExchangeListResult: isSet(object.billableExchangeListResult)
        ? BillableExchangeListResult.fromJSON(object.billableExchangeListResult)
        : undefined,
      createAccountGroupResult: isSet(object.createAccountGroupResult)
        ? CreateAccountGroupResult.fromJSON(object.createAccountGroupResult)
        : undefined,
      accountTradingFeaturesResult: isSet(object.accountTradingFeaturesResult)
        ? AccountTradingFeaturesResult.fromJSON(object.accountTradingFeaturesResult)
        : undefined,
    };
  },

  toJSON(message: AccountScopeResult): unknown {
    const obj: any = {};
    if (message.accountInfoResult !== undefined) {
      obj.accountInfoResult = AccountInfoResult.toJSON(message.accountInfoResult);
    }
    if (message.balanceRecordsResult !== undefined) {
      obj.balanceRecordsResult = BalanceRecordsResult.toJSON(message.balanceRecordsResult);
    }
    if (message.createBalanceRecordResult !== undefined) {
      obj.createBalanceRecordResult = CreateBalanceRecordResult.toJSON(message.createBalanceRecordResult);
    }
    if (message.updateBalanceRecordResult !== undefined) {
      obj.updateBalanceRecordResult = UpdateBalanceRecordResult.toJSON(message.updateBalanceRecordResult);
    }
    if (message.accountRiskParametersResult !== undefined) {
      obj.accountRiskParametersResult = AccountRiskParametersResult.toJSON(message.accountRiskParametersResult);
    }
    if (message.updateRiskParametersResult !== undefined) {
      obj.updateRiskParametersResult = UpdateRiskParametersResult.toJSON(message.updateRiskParametersResult);
    }
    if (message.accountSearchResult !== undefined) {
      obj.accountSearchResult = AccountSearchResult.toJSON(message.accountSearchResult);
    }
    if (message.accountRouteListResult !== undefined) {
      obj.accountRouteListResult = AccountRouteListResult.toJSON(message.accountRouteListResult);
    }
    if (message.accountUserAuthorizationListResult !== undefined) {
      obj.accountUserAuthorizationListResult = AccountUserAuthorizationListResult.toJSON(
        message.accountUserAuthorizationListResult,
      );
    }
    if (message.accountSettingsResult !== undefined) {
      obj.accountSettingsResult = AccountSettingsResult.toJSON(message.accountSettingsResult);
    }
    if (message.accountExchangeGroupsResult !== undefined) {
      obj.accountExchangeGroupsResult = AccountExchangeGroupsResult.toJSON(message.accountExchangeGroupsResult);
    }
    if (message.accountMarketLimitsResult !== undefined) {
      obj.accountMarketLimitsResult = AccountMarketLimitsResult.toJSON(message.accountMarketLimitsResult);
    }
    if (message.accountPositionsResult !== undefined) {
      obj.accountPositionsResult = AccountPositionsResult.toJSON(message.accountPositionsResult);
    }
    if (message.accountEquityResult !== undefined) {
      obj.accountEquityResult = AccountEquityResult.toJSON(message.accountEquityResult);
    }
    if (message.accountCollateralResult !== undefined) {
      obj.accountCollateralResult = AccountCollateralResult.toJSON(message.accountCollateralResult);
    }
    if (message.cloneAccountResult !== undefined) {
      obj.cloneAccountResult = CloneAccountResult.toJSON(message.cloneAccountResult);
    }
    if (message.updateAccountResult !== undefined) {
      obj.updateAccountResult = UpdateAccountResult.toJSON(message.updateAccountResult);
    }
    if (message.updateAccountSettingsResult !== undefined) {
      obj.updateAccountSettingsResult = UpdateAccountSettingsResult.toJSON(message.updateAccountSettingsResult);
    }
    if (message.accountGroupResult !== undefined) {
      obj.accountGroupResult = AccountGroupResult.toJSON(message.accountGroupResult);
    }
    if (message.updateAccountRiskParametersResult !== undefined) {
      obj.updateAccountRiskParametersResult = UpdateAccountRiskParametersResult.toJSON(
        message.updateAccountRiskParametersResult,
      );
    }
    if (message.accountAvailableRouteListResult !== undefined) {
      obj.accountAvailableRouteListResult = AccountAvailableRouteListResult.toJSON(
        message.accountAvailableRouteListResult,
      );
    }
    if (message.updateAccountRouteListResult !== undefined) {
      obj.updateAccountRouteListResult = UpdateAccountRouteListResult.toJSON(message.updateAccountRouteListResult);
    }
    if (message.updateAccountMarketLimitsResult !== undefined) {
      obj.updateAccountMarketLimitsResult = UpdateAccountMarketLimitsResult.toJSON(
        message.updateAccountMarketLimitsResult,
      );
    }
    if (message.createAccountResult !== undefined) {
      obj.createAccountResult = CreateAccountResult.toJSON(message.createAccountResult);
    }
    if (message.obsoleteAccountClusterResult !== undefined) {
      obj.obsoleteAccountClusterResult = AccountClusterResult.toJSON(message.obsoleteAccountClusterResult);
    }
    if (message.obsoleteCreateAccountClusterResult !== undefined) {
      obj.obsoleteCreateAccountClusterResult = CreateAccountClusterResult.toJSON(
        message.obsoleteCreateAccountClusterResult,
      );
    }
    if (message.obsoleteUpdateAccountClusterResult !== undefined) {
      obj.obsoleteUpdateAccountClusterResult = UpdateAccountClusterResult.toJSON(
        message.obsoleteUpdateAccountClusterResult,
      );
    }
    if (message.obsoleteUpdateAccountClusterPriceOffsetsResult !== undefined) {
      obj.obsoleteUpdateAccountClusterPriceOffsetsResult = UpdateAccountClusterPriceOffsetsResult.toJSON(
        message.obsoleteUpdateAccountClusterPriceOffsetsResult,
      );
    }
    if (message.restoreAccountResult !== undefined) {
      obj.restoreAccountResult = RestoreAccountResult.toJSON(message.restoreAccountResult);
    }
    if (message.accountTypeOverrideListResult !== undefined) {
      obj.accountTypeOverrideListResult = AccountTypeOverrideListResult.toJSON(message.accountTypeOverrideListResult);
    }
    if (message.updateAccountTypeOverrideListResult !== undefined) {
      obj.updateAccountTypeOverrideListResult = UpdateAccountTypeOverrideListResult.toJSON(
        message.updateAccountTypeOverrideListResult,
      );
    }
    if (message.updateAccountUserAuthorizationListResult !== undefined) {
      obj.updateAccountUserAuthorizationListResult = UpdateAccountUserAuthorizationListResult.toJSON(
        message.updateAccountUserAuthorizationListResult,
      );
    }
    if (message.removeAccountResult !== undefined) {
      obj.removeAccountResult = RemoveAccountResult.toJSON(message.removeAccountResult);
    }
    if (message.accountAvailableServiceGroupListResult !== undefined) {
      obj.accountAvailableServiceGroupListResult = AccountAvailableServiceGroupListResult.toJSON(
        message.accountAvailableServiceGroupListResult,
      );
    }
    if (message.accountServiceGroupListResult !== undefined) {
      obj.accountServiceGroupListResult = AccountServiceGroupListResult.toJSON(message.accountServiceGroupListResult);
    }
    if (message.updateAccountServiceGroupListResult !== undefined) {
      obj.updateAccountServiceGroupListResult = UpdateAccountServiceGroupListResult.toJSON(
        message.updateAccountServiceGroupListResult,
      );
    }
    if (message.billableExchangeListResult !== undefined) {
      obj.billableExchangeListResult = BillableExchangeListResult.toJSON(message.billableExchangeListResult);
    }
    if (message.createAccountGroupResult !== undefined) {
      obj.createAccountGroupResult = CreateAccountGroupResult.toJSON(message.createAccountGroupResult);
    }
    if (message.accountTradingFeaturesResult !== undefined) {
      obj.accountTradingFeaturesResult = AccountTradingFeaturesResult.toJSON(message.accountTradingFeaturesResult);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountScopeResult>, I>>(base?: I): AccountScopeResult {
    return AccountScopeResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountScopeResult>, I>>(object: I): AccountScopeResult {
    const message = createBaseAccountScopeResult();
    message.accountInfoResult = (object.accountInfoResult !== undefined && object.accountInfoResult !== null)
      ? AccountInfoResult.fromPartial(object.accountInfoResult)
      : undefined;
    message.balanceRecordsResult = (object.balanceRecordsResult !== undefined && object.balanceRecordsResult !== null)
      ? BalanceRecordsResult.fromPartial(object.balanceRecordsResult)
      : undefined;
    message.createBalanceRecordResult =
      (object.createBalanceRecordResult !== undefined && object.createBalanceRecordResult !== null)
        ? CreateBalanceRecordResult.fromPartial(object.createBalanceRecordResult)
        : undefined;
    message.updateBalanceRecordResult =
      (object.updateBalanceRecordResult !== undefined && object.updateBalanceRecordResult !== null)
        ? UpdateBalanceRecordResult.fromPartial(object.updateBalanceRecordResult)
        : undefined;
    message.accountRiskParametersResult =
      (object.accountRiskParametersResult !== undefined && object.accountRiskParametersResult !== null)
        ? AccountRiskParametersResult.fromPartial(object.accountRiskParametersResult)
        : undefined;
    message.updateRiskParametersResult =
      (object.updateRiskParametersResult !== undefined && object.updateRiskParametersResult !== null)
        ? UpdateRiskParametersResult.fromPartial(object.updateRiskParametersResult)
        : undefined;
    message.accountSearchResult = (object.accountSearchResult !== undefined && object.accountSearchResult !== null)
      ? AccountSearchResult.fromPartial(object.accountSearchResult)
      : undefined;
    message.accountRouteListResult =
      (object.accountRouteListResult !== undefined && object.accountRouteListResult !== null)
        ? AccountRouteListResult.fromPartial(object.accountRouteListResult)
        : undefined;
    message.accountUserAuthorizationListResult =
      (object.accountUserAuthorizationListResult !== undefined && object.accountUserAuthorizationListResult !== null)
        ? AccountUserAuthorizationListResult.fromPartial(object.accountUserAuthorizationListResult)
        : undefined;
    message.accountSettingsResult =
      (object.accountSettingsResult !== undefined && object.accountSettingsResult !== null)
        ? AccountSettingsResult.fromPartial(object.accountSettingsResult)
        : undefined;
    message.accountExchangeGroupsResult =
      (object.accountExchangeGroupsResult !== undefined && object.accountExchangeGroupsResult !== null)
        ? AccountExchangeGroupsResult.fromPartial(object.accountExchangeGroupsResult)
        : undefined;
    message.accountMarketLimitsResult =
      (object.accountMarketLimitsResult !== undefined && object.accountMarketLimitsResult !== null)
        ? AccountMarketLimitsResult.fromPartial(object.accountMarketLimitsResult)
        : undefined;
    message.accountPositionsResult =
      (object.accountPositionsResult !== undefined && object.accountPositionsResult !== null)
        ? AccountPositionsResult.fromPartial(object.accountPositionsResult)
        : undefined;
    message.accountEquityResult = (object.accountEquityResult !== undefined && object.accountEquityResult !== null)
      ? AccountEquityResult.fromPartial(object.accountEquityResult)
      : undefined;
    message.accountCollateralResult =
      (object.accountCollateralResult !== undefined && object.accountCollateralResult !== null)
        ? AccountCollateralResult.fromPartial(object.accountCollateralResult)
        : undefined;
    message.cloneAccountResult = (object.cloneAccountResult !== undefined && object.cloneAccountResult !== null)
      ? CloneAccountResult.fromPartial(object.cloneAccountResult)
      : undefined;
    message.updateAccountResult = (object.updateAccountResult !== undefined && object.updateAccountResult !== null)
      ? UpdateAccountResult.fromPartial(object.updateAccountResult)
      : undefined;
    message.updateAccountSettingsResult =
      (object.updateAccountSettingsResult !== undefined && object.updateAccountSettingsResult !== null)
        ? UpdateAccountSettingsResult.fromPartial(object.updateAccountSettingsResult)
        : undefined;
    message.accountGroupResult = (object.accountGroupResult !== undefined && object.accountGroupResult !== null)
      ? AccountGroupResult.fromPartial(object.accountGroupResult)
      : undefined;
    message.updateAccountRiskParametersResult =
      (object.updateAccountRiskParametersResult !== undefined && object.updateAccountRiskParametersResult !== null)
        ? UpdateAccountRiskParametersResult.fromPartial(object.updateAccountRiskParametersResult)
        : undefined;
    message.accountAvailableRouteListResult =
      (object.accountAvailableRouteListResult !== undefined && object.accountAvailableRouteListResult !== null)
        ? AccountAvailableRouteListResult.fromPartial(object.accountAvailableRouteListResult)
        : undefined;
    message.updateAccountRouteListResult =
      (object.updateAccountRouteListResult !== undefined && object.updateAccountRouteListResult !== null)
        ? UpdateAccountRouteListResult.fromPartial(object.updateAccountRouteListResult)
        : undefined;
    message.updateAccountMarketLimitsResult =
      (object.updateAccountMarketLimitsResult !== undefined && object.updateAccountMarketLimitsResult !== null)
        ? UpdateAccountMarketLimitsResult.fromPartial(object.updateAccountMarketLimitsResult)
        : undefined;
    message.createAccountResult = (object.createAccountResult !== undefined && object.createAccountResult !== null)
      ? CreateAccountResult.fromPartial(object.createAccountResult)
      : undefined;
    message.obsoleteAccountClusterResult =
      (object.obsoleteAccountClusterResult !== undefined && object.obsoleteAccountClusterResult !== null)
        ? AccountClusterResult.fromPartial(object.obsoleteAccountClusterResult)
        : undefined;
    message.obsoleteCreateAccountClusterResult =
      (object.obsoleteCreateAccountClusterResult !== undefined && object.obsoleteCreateAccountClusterResult !== null)
        ? CreateAccountClusterResult.fromPartial(object.obsoleteCreateAccountClusterResult)
        : undefined;
    message.obsoleteUpdateAccountClusterResult =
      (object.obsoleteUpdateAccountClusterResult !== undefined && object.obsoleteUpdateAccountClusterResult !== null)
        ? UpdateAccountClusterResult.fromPartial(object.obsoleteUpdateAccountClusterResult)
        : undefined;
    message.obsoleteUpdateAccountClusterPriceOffsetsResult =
      (object.obsoleteUpdateAccountClusterPriceOffsetsResult !== undefined &&
          object.obsoleteUpdateAccountClusterPriceOffsetsResult !== null)
        ? UpdateAccountClusterPriceOffsetsResult.fromPartial(object.obsoleteUpdateAccountClusterPriceOffsetsResult)
        : undefined;
    message.restoreAccountResult = (object.restoreAccountResult !== undefined && object.restoreAccountResult !== null)
      ? RestoreAccountResult.fromPartial(object.restoreAccountResult)
      : undefined;
    message.accountTypeOverrideListResult =
      (object.accountTypeOverrideListResult !== undefined && object.accountTypeOverrideListResult !== null)
        ? AccountTypeOverrideListResult.fromPartial(object.accountTypeOverrideListResult)
        : undefined;
    message.updateAccountTypeOverrideListResult =
      (object.updateAccountTypeOverrideListResult !== undefined && object.updateAccountTypeOverrideListResult !== null)
        ? UpdateAccountTypeOverrideListResult.fromPartial(object.updateAccountTypeOverrideListResult)
        : undefined;
    message.updateAccountUserAuthorizationListResult =
      (object.updateAccountUserAuthorizationListResult !== undefined &&
          object.updateAccountUserAuthorizationListResult !== null)
        ? UpdateAccountUserAuthorizationListResult.fromPartial(object.updateAccountUserAuthorizationListResult)
        : undefined;
    message.removeAccountResult = (object.removeAccountResult !== undefined && object.removeAccountResult !== null)
      ? RemoveAccountResult.fromPartial(object.removeAccountResult)
      : undefined;
    message.accountAvailableServiceGroupListResult =
      (object.accountAvailableServiceGroupListResult !== undefined &&
          object.accountAvailableServiceGroupListResult !== null)
        ? AccountAvailableServiceGroupListResult.fromPartial(object.accountAvailableServiceGroupListResult)
        : undefined;
    message.accountServiceGroupListResult =
      (object.accountServiceGroupListResult !== undefined && object.accountServiceGroupListResult !== null)
        ? AccountServiceGroupListResult.fromPartial(object.accountServiceGroupListResult)
        : undefined;
    message.updateAccountServiceGroupListResult =
      (object.updateAccountServiceGroupListResult !== undefined && object.updateAccountServiceGroupListResult !== null)
        ? UpdateAccountServiceGroupListResult.fromPartial(object.updateAccountServiceGroupListResult)
        : undefined;
    message.billableExchangeListResult =
      (object.billableExchangeListResult !== undefined && object.billableExchangeListResult !== null)
        ? BillableExchangeListResult.fromPartial(object.billableExchangeListResult)
        : undefined;
    message.createAccountGroupResult =
      (object.createAccountGroupResult !== undefined && object.createAccountGroupResult !== null)
        ? CreateAccountGroupResult.fromPartial(object.createAccountGroupResult)
        : undefined;
    message.accountTradingFeaturesResult =
      (object.accountTradingFeaturesResult !== undefined && object.accountTradingFeaturesResult !== null)
        ? AccountTradingFeaturesResult.fromPartial(object.accountTradingFeaturesResult)
        : undefined;
    return message;
  },
};

function createBaseOrderScopeResult(): OrderScopeResult {
  return {
    orderDetailsResult: undefined,
    addFillResult: undefined,
    createExternalOrderResult: undefined,
    orderSearchResult: undefined,
    relatedOrderTreeResult: undefined,
    compoundOrderTreeResult: undefined,
    syntheticStrategyTreeResult: undefined,
    cancelOrderResult: undefined,
    reflectAsCanceledOrderResult: undefined,
    cancelCompoundOrderTreeResult: undefined,
    batchOrdersOperationResult: undefined,
  };
}

export const OrderScopeResult = {
  encode(message: OrderScopeResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderDetailsResult !== undefined) {
      OrderDetailsResult.encode(message.orderDetailsResult, writer.uint32(10).fork()).ldelim();
    }
    if (message.addFillResult !== undefined) {
      AddFillResult.encode(message.addFillResult, writer.uint32(18).fork()).ldelim();
    }
    if (message.createExternalOrderResult !== undefined) {
      CreateExternalOrderResult.encode(message.createExternalOrderResult, writer.uint32(26).fork()).ldelim();
    }
    if (message.orderSearchResult !== undefined) {
      OrderSearchResult.encode(message.orderSearchResult, writer.uint32(34).fork()).ldelim();
    }
    if (message.relatedOrderTreeResult !== undefined) {
      RelatedOrderTreeResult.encode(message.relatedOrderTreeResult, writer.uint32(42).fork()).ldelim();
    }
    if (message.compoundOrderTreeResult !== undefined) {
      CompoundOrderTreeResult.encode(message.compoundOrderTreeResult, writer.uint32(50).fork()).ldelim();
    }
    if (message.syntheticStrategyTreeResult !== undefined) {
      SyntheticStrategyTreeResult.encode(message.syntheticStrategyTreeResult, writer.uint32(58).fork()).ldelim();
    }
    if (message.cancelOrderResult !== undefined) {
      CancelOrderResult.encode(message.cancelOrderResult, writer.uint32(66).fork()).ldelim();
    }
    if (message.reflectAsCanceledOrderResult !== undefined) {
      ReflectAsCanceledOrderResult.encode(message.reflectAsCanceledOrderResult, writer.uint32(74).fork()).ldelim();
    }
    if (message.cancelCompoundOrderTreeResult !== undefined) {
      CancelCompoundOrderTreeResult.encode(message.cancelCompoundOrderTreeResult, writer.uint32(82).fork()).ldelim();
    }
    if (message.batchOrdersOperationResult !== undefined) {
      BatchOrdersOperationResult.encode(message.batchOrdersOperationResult, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderScopeResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderScopeResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderDetailsResult = OrderDetailsResult.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.addFillResult = AddFillResult.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createExternalOrderResult = CreateExternalOrderResult.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.orderSearchResult = OrderSearchResult.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.relatedOrderTreeResult = RelatedOrderTreeResult.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.compoundOrderTreeResult = CompoundOrderTreeResult.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.syntheticStrategyTreeResult = SyntheticStrategyTreeResult.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.cancelOrderResult = CancelOrderResult.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.reflectAsCanceledOrderResult = ReflectAsCanceledOrderResult.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.cancelCompoundOrderTreeResult = CancelCompoundOrderTreeResult.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.batchOrdersOperationResult = BatchOrdersOperationResult.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderScopeResult {
    return {
      orderDetailsResult: isSet(object.orderDetailsResult)
        ? OrderDetailsResult.fromJSON(object.orderDetailsResult)
        : undefined,
      addFillResult: isSet(object.addFillResult) ? AddFillResult.fromJSON(object.addFillResult) : undefined,
      createExternalOrderResult: isSet(object.createExternalOrderResult)
        ? CreateExternalOrderResult.fromJSON(object.createExternalOrderResult)
        : undefined,
      orderSearchResult: isSet(object.orderSearchResult)
        ? OrderSearchResult.fromJSON(object.orderSearchResult)
        : undefined,
      relatedOrderTreeResult: isSet(object.relatedOrderTreeResult)
        ? RelatedOrderTreeResult.fromJSON(object.relatedOrderTreeResult)
        : undefined,
      compoundOrderTreeResult: isSet(object.compoundOrderTreeResult)
        ? CompoundOrderTreeResult.fromJSON(object.compoundOrderTreeResult)
        : undefined,
      syntheticStrategyTreeResult: isSet(object.syntheticStrategyTreeResult)
        ? SyntheticStrategyTreeResult.fromJSON(object.syntheticStrategyTreeResult)
        : undefined,
      cancelOrderResult: isSet(object.cancelOrderResult)
        ? CancelOrderResult.fromJSON(object.cancelOrderResult)
        : undefined,
      reflectAsCanceledOrderResult: isSet(object.reflectAsCanceledOrderResult)
        ? ReflectAsCanceledOrderResult.fromJSON(object.reflectAsCanceledOrderResult)
        : undefined,
      cancelCompoundOrderTreeResult: isSet(object.cancelCompoundOrderTreeResult)
        ? CancelCompoundOrderTreeResult.fromJSON(object.cancelCompoundOrderTreeResult)
        : undefined,
      batchOrdersOperationResult: isSet(object.batchOrdersOperationResult)
        ? BatchOrdersOperationResult.fromJSON(object.batchOrdersOperationResult)
        : undefined,
    };
  },

  toJSON(message: OrderScopeResult): unknown {
    const obj: any = {};
    if (message.orderDetailsResult !== undefined) {
      obj.orderDetailsResult = OrderDetailsResult.toJSON(message.orderDetailsResult);
    }
    if (message.addFillResult !== undefined) {
      obj.addFillResult = AddFillResult.toJSON(message.addFillResult);
    }
    if (message.createExternalOrderResult !== undefined) {
      obj.createExternalOrderResult = CreateExternalOrderResult.toJSON(message.createExternalOrderResult);
    }
    if (message.orderSearchResult !== undefined) {
      obj.orderSearchResult = OrderSearchResult.toJSON(message.orderSearchResult);
    }
    if (message.relatedOrderTreeResult !== undefined) {
      obj.relatedOrderTreeResult = RelatedOrderTreeResult.toJSON(message.relatedOrderTreeResult);
    }
    if (message.compoundOrderTreeResult !== undefined) {
      obj.compoundOrderTreeResult = CompoundOrderTreeResult.toJSON(message.compoundOrderTreeResult);
    }
    if (message.syntheticStrategyTreeResult !== undefined) {
      obj.syntheticStrategyTreeResult = SyntheticStrategyTreeResult.toJSON(message.syntheticStrategyTreeResult);
    }
    if (message.cancelOrderResult !== undefined) {
      obj.cancelOrderResult = CancelOrderResult.toJSON(message.cancelOrderResult);
    }
    if (message.reflectAsCanceledOrderResult !== undefined) {
      obj.reflectAsCanceledOrderResult = ReflectAsCanceledOrderResult.toJSON(message.reflectAsCanceledOrderResult);
    }
    if (message.cancelCompoundOrderTreeResult !== undefined) {
      obj.cancelCompoundOrderTreeResult = CancelCompoundOrderTreeResult.toJSON(message.cancelCompoundOrderTreeResult);
    }
    if (message.batchOrdersOperationResult !== undefined) {
      obj.batchOrdersOperationResult = BatchOrdersOperationResult.toJSON(message.batchOrdersOperationResult);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderScopeResult>, I>>(base?: I): OrderScopeResult {
    return OrderScopeResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderScopeResult>, I>>(object: I): OrderScopeResult {
    const message = createBaseOrderScopeResult();
    message.orderDetailsResult = (object.orderDetailsResult !== undefined && object.orderDetailsResult !== null)
      ? OrderDetailsResult.fromPartial(object.orderDetailsResult)
      : undefined;
    message.addFillResult = (object.addFillResult !== undefined && object.addFillResult !== null)
      ? AddFillResult.fromPartial(object.addFillResult)
      : undefined;
    message.createExternalOrderResult =
      (object.createExternalOrderResult !== undefined && object.createExternalOrderResult !== null)
        ? CreateExternalOrderResult.fromPartial(object.createExternalOrderResult)
        : undefined;
    message.orderSearchResult = (object.orderSearchResult !== undefined && object.orderSearchResult !== null)
      ? OrderSearchResult.fromPartial(object.orderSearchResult)
      : undefined;
    message.relatedOrderTreeResult =
      (object.relatedOrderTreeResult !== undefined && object.relatedOrderTreeResult !== null)
        ? RelatedOrderTreeResult.fromPartial(object.relatedOrderTreeResult)
        : undefined;
    message.compoundOrderTreeResult =
      (object.compoundOrderTreeResult !== undefined && object.compoundOrderTreeResult !== null)
        ? CompoundOrderTreeResult.fromPartial(object.compoundOrderTreeResult)
        : undefined;
    message.syntheticStrategyTreeResult =
      (object.syntheticStrategyTreeResult !== undefined && object.syntheticStrategyTreeResult !== null)
        ? SyntheticStrategyTreeResult.fromPartial(object.syntheticStrategyTreeResult)
        : undefined;
    message.cancelOrderResult = (object.cancelOrderResult !== undefined && object.cancelOrderResult !== null)
      ? CancelOrderResult.fromPartial(object.cancelOrderResult)
      : undefined;
    message.reflectAsCanceledOrderResult =
      (object.reflectAsCanceledOrderResult !== undefined && object.reflectAsCanceledOrderResult !== null)
        ? ReflectAsCanceledOrderResult.fromPartial(object.reflectAsCanceledOrderResult)
        : undefined;
    message.cancelCompoundOrderTreeResult =
      (object.cancelCompoundOrderTreeResult !== undefined && object.cancelCompoundOrderTreeResult !== null)
        ? CancelCompoundOrderTreeResult.fromPartial(object.cancelCompoundOrderTreeResult)
        : undefined;
    message.batchOrdersOperationResult =
      (object.batchOrdersOperationResult !== undefined && object.batchOrdersOperationResult !== null)
        ? BatchOrdersOperationResult.fromPartial(object.batchOrdersOperationResult)
        : undefined;
    return message;
  },
};

function createBaseOrderDetailsResult(): OrderDetailsResult {
  return { orderChain: undefined, isComplete: true };
}

export const OrderDetailsResult = {
  encode(message: OrderDetailsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderChain !== undefined) {
      OrderChain.encode(message.orderChain, writer.uint32(10).fork()).ldelim();
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      writer.uint32(16).bool(message.isComplete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderDetailsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderDetailsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderChain = OrderChain.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isComplete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderDetailsResult {
    return {
      orderChain: isSet(object.orderChain) ? OrderChain.fromJSON(object.orderChain) : undefined,
      isComplete: isSet(object.isComplete) ? globalThis.Boolean(object.isComplete) : true,
    };
  },

  toJSON(message: OrderDetailsResult): unknown {
    const obj: any = {};
    if (message.orderChain !== undefined) {
      obj.orderChain = OrderChain.toJSON(message.orderChain);
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      obj.isComplete = message.isComplete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderDetailsResult>, I>>(base?: I): OrderDetailsResult {
    return OrderDetailsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderDetailsResult>, I>>(object: I): OrderDetailsResult {
    const message = createBaseOrderDetailsResult();
    message.orderChain = (object.orderChain !== undefined && object.orderChain !== null)
      ? OrderChain.fromPartial(object.orderChain)
      : undefined;
    message.isComplete = object.isComplete ?? true;
    return message;
  },
};

function createBaseAddFillResult(): AddFillResult {
  return {};
}

export const AddFillResult = {
  encode(_: AddFillResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFillResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFillResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): AddFillResult {
    return {};
  },

  toJSON(_: AddFillResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<AddFillResult>, I>>(base?: I): AddFillResult {
    return AddFillResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddFillResult>, I>>(_: I): AddFillResult {
    const message = createBaseAddFillResult();
    return message;
  },
};

function createBaseCreateExternalOrderResult(): CreateExternalOrderResult {
  return { orderId: "", tradeLocationId: "" };
}

export const CreateExternalOrderResult = {
  encode(message: CreateExternalOrderResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== undefined && message.orderId !== "") {
      writer.uint32(26).string(message.orderId);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(34).string(message.tradeLocationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateExternalOrderResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateExternalOrderResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateExternalOrderResult {
    return {
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
    };
  },

  toJSON(message: CreateExternalOrderResult): unknown {
    const obj: any = {};
    if (message.orderId !== undefined && message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateExternalOrderResult>, I>>(base?: I): CreateExternalOrderResult {
    return CreateExternalOrderResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateExternalOrderResult>, I>>(object: I): CreateExternalOrderResult {
    const message = createBaseCreateExternalOrderResult();
    message.orderId = object.orderId ?? "";
    message.tradeLocationId = object.tradeLocationId ?? "";
    return message;
  },
};

function createBaseOrderSearchResult(): OrderSearchResult {
  return { orderRecord: [], isComplete: true };
}

export const OrderSearchResult = {
  encode(message: OrderSearchResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.orderRecord) {
      OrderSearchResultRecord.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      writer.uint32(16).bool(message.isComplete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderSearchResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderSearchResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderRecord.push(OrderSearchResultRecord.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isComplete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderSearchResult {
    return {
      orderRecord: globalThis.Array.isArray(object?.orderRecord)
        ? object.orderRecord.map((e: any) => OrderSearchResultRecord.fromJSON(e))
        : [],
      isComplete: isSet(object.isComplete) ? globalThis.Boolean(object.isComplete) : true,
    };
  },

  toJSON(message: OrderSearchResult): unknown {
    const obj: any = {};
    if (message.orderRecord?.length) {
      obj.orderRecord = message.orderRecord.map((e) => OrderSearchResultRecord.toJSON(e));
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      obj.isComplete = message.isComplete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderSearchResult>, I>>(base?: I): OrderSearchResult {
    return OrderSearchResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderSearchResult>, I>>(object: I): OrderSearchResult {
    const message = createBaseOrderSearchResult();
    message.orderRecord = object.orderRecord?.map((e) => OrderSearchResultRecord.fromPartial(e)) || [];
    message.isComplete = object.isComplete ?? true;
    return message;
  },
};

function createBaseRelatedOrderTreeResult(): RelatedOrderTreeResult {
  return { relatedOrder: undefined, isComplete: true };
}

export const RelatedOrderTreeResult = {
  encode(message: RelatedOrderTreeResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.relatedOrder !== undefined) {
      RelatedOrder.encode(message.relatedOrder, writer.uint32(10).fork()).ldelim();
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      writer.uint32(16).bool(message.isComplete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RelatedOrderTreeResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRelatedOrderTreeResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.relatedOrder = RelatedOrder.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isComplete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RelatedOrderTreeResult {
    return {
      relatedOrder: isSet(object.relatedOrder) ? RelatedOrder.fromJSON(object.relatedOrder) : undefined,
      isComplete: isSet(object.isComplete) ? globalThis.Boolean(object.isComplete) : true,
    };
  },

  toJSON(message: RelatedOrderTreeResult): unknown {
    const obj: any = {};
    if (message.relatedOrder !== undefined) {
      obj.relatedOrder = RelatedOrder.toJSON(message.relatedOrder);
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      obj.isComplete = message.isComplete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RelatedOrderTreeResult>, I>>(base?: I): RelatedOrderTreeResult {
    return RelatedOrderTreeResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RelatedOrderTreeResult>, I>>(object: I): RelatedOrderTreeResult {
    const message = createBaseRelatedOrderTreeResult();
    message.relatedOrder = (object.relatedOrder !== undefined && object.relatedOrder !== null)
      ? RelatedOrder.fromPartial(object.relatedOrder)
      : undefined;
    message.isComplete = object.isComplete ?? true;
    return message;
  },
};

function createBaseCompoundOrderTreeResult(): CompoundOrderTreeResult {
  return { compoundOrder: undefined, isComplete: true };
}

export const CompoundOrderTreeResult = {
  encode(message: CompoundOrderTreeResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.compoundOrder !== undefined) {
      CompoundOrder.encode(message.compoundOrder, writer.uint32(10).fork()).ldelim();
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      writer.uint32(16).bool(message.isComplete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompoundOrderTreeResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompoundOrderTreeResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.compoundOrder = CompoundOrder.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isComplete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompoundOrderTreeResult {
    return {
      compoundOrder: isSet(object.compoundOrder) ? CompoundOrder.fromJSON(object.compoundOrder) : undefined,
      isComplete: isSet(object.isComplete) ? globalThis.Boolean(object.isComplete) : true,
    };
  },

  toJSON(message: CompoundOrderTreeResult): unknown {
    const obj: any = {};
    if (message.compoundOrder !== undefined) {
      obj.compoundOrder = CompoundOrder.toJSON(message.compoundOrder);
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      obj.isComplete = message.isComplete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompoundOrderTreeResult>, I>>(base?: I): CompoundOrderTreeResult {
    return CompoundOrderTreeResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompoundOrderTreeResult>, I>>(object: I): CompoundOrderTreeResult {
    const message = createBaseCompoundOrderTreeResult();
    message.compoundOrder = (object.compoundOrder !== undefined && object.compoundOrder !== null)
      ? CompoundOrder.fromPartial(object.compoundOrder)
      : undefined;
    message.isComplete = object.isComplete ?? true;
    return message;
  },
};

function createBaseSyntheticStrategyTreeResult(): SyntheticStrategyTreeResult {
  return { legs: [], strategyName: "" };
}

export const SyntheticStrategyTreeResult = {
  encode(message: SyntheticStrategyTreeResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.legs) {
      StrategyLeg.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.strategyName !== undefined && message.strategyName !== "") {
      writer.uint32(18).string(message.strategyName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyntheticStrategyTreeResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSyntheticStrategyTreeResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.legs.push(StrategyLeg.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.strategyName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SyntheticStrategyTreeResult {
    return {
      legs: globalThis.Array.isArray(object?.legs) ? object.legs.map((e: any) => StrategyLeg.fromJSON(e)) : [],
      strategyName: isSet(object.strategyName) ? globalThis.String(object.strategyName) : "",
    };
  },

  toJSON(message: SyntheticStrategyTreeResult): unknown {
    const obj: any = {};
    if (message.legs?.length) {
      obj.legs = message.legs.map((e) => StrategyLeg.toJSON(e));
    }
    if (message.strategyName !== undefined && message.strategyName !== "") {
      obj.strategyName = message.strategyName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SyntheticStrategyTreeResult>, I>>(base?: I): SyntheticStrategyTreeResult {
    return SyntheticStrategyTreeResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SyntheticStrategyTreeResult>, I>>(object: I): SyntheticStrategyTreeResult {
    const message = createBaseSyntheticStrategyTreeResult();
    message.legs = object.legs?.map((e) => StrategyLeg.fromPartial(e)) || [];
    message.strategyName = object.strategyName ?? "";
    return message;
  },
};

function createBaseCancelOrderResult(): CancelOrderResult {
  return {};
}

export const CancelOrderResult = {
  encode(_: CancelOrderResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelOrderResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelOrderResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CancelOrderResult {
    return {};
  },

  toJSON(_: CancelOrderResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CancelOrderResult>, I>>(base?: I): CancelOrderResult {
    return CancelOrderResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelOrderResult>, I>>(_: I): CancelOrderResult {
    const message = createBaseCancelOrderResult();
    return message;
  },
};

function createBaseReflectAsCanceledOrderResult(): ReflectAsCanceledOrderResult {
  return {};
}

export const ReflectAsCanceledOrderResult = {
  encode(_: ReflectAsCanceledOrderResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReflectAsCanceledOrderResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReflectAsCanceledOrderResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ReflectAsCanceledOrderResult {
    return {};
  },

  toJSON(_: ReflectAsCanceledOrderResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ReflectAsCanceledOrderResult>, I>>(base?: I): ReflectAsCanceledOrderResult {
    return ReflectAsCanceledOrderResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReflectAsCanceledOrderResult>, I>>(_: I): ReflectAsCanceledOrderResult {
    const message = createBaseReflectAsCanceledOrderResult();
    return message;
  },
};

function createBaseCancelCompoundOrderTreeResult(): CancelCompoundOrderTreeResult {
  return {};
}

export const CancelCompoundOrderTreeResult = {
  encode(_: CancelCompoundOrderTreeResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelCompoundOrderTreeResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelCompoundOrderTreeResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CancelCompoundOrderTreeResult {
    return {};
  },

  toJSON(_: CancelCompoundOrderTreeResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<CancelCompoundOrderTreeResult>, I>>(base?: I): CancelCompoundOrderTreeResult {
    return CancelCompoundOrderTreeResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelCompoundOrderTreeResult>, I>>(_: I): CancelCompoundOrderTreeResult {
    const message = createBaseCancelCompoundOrderTreeResult();
    return message;
  },
};

function createBaseAccountInfoResult(): AccountInfoResult {
  return { account: undefined };
}

export const AccountInfoResult = {
  encode(message: AccountInfoResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.account !== undefined) {
      Account.encode(message.account, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountInfoResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountInfoResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.account = Account.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountInfoResult {
    return { account: isSet(object.account) ? Account.fromJSON(object.account) : undefined };
  },

  toJSON(message: AccountInfoResult): unknown {
    const obj: any = {};
    if (message.account !== undefined) {
      obj.account = Account.toJSON(message.account);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountInfoResult>, I>>(base?: I): AccountInfoResult {
    return AccountInfoResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountInfoResult>, I>>(object: I): AccountInfoResult {
    const message = createBaseAccountInfoResult();
    message.account = (object.account !== undefined && object.account !== null)
      ? Account.fromPartial(object.account)
      : undefined;
    return message;
  },
};

function createBaseBalanceRecordsResult(): BalanceRecordsResult {
  return { balanceRecord: [] };
}

export const BalanceRecordsResult = {
  encode(message: BalanceRecordsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.balanceRecord) {
      BalanceRecord.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BalanceRecordsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalanceRecordsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.balanceRecord.push(BalanceRecord.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BalanceRecordsResult {
    return {
      balanceRecord: globalThis.Array.isArray(object?.balanceRecord)
        ? object.balanceRecord.map((e: any) => BalanceRecord.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BalanceRecordsResult): unknown {
    const obj: any = {};
    if (message.balanceRecord?.length) {
      obj.balanceRecord = message.balanceRecord.map((e) => BalanceRecord.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BalanceRecordsResult>, I>>(base?: I): BalanceRecordsResult {
    return BalanceRecordsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BalanceRecordsResult>, I>>(object: I): BalanceRecordsResult {
    const message = createBaseBalanceRecordsResult();
    message.balanceRecord = object.balanceRecord?.map((e) => BalanceRecord.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateBalanceRecordResult(): CreateBalanceRecordResult {
  return { balanceRecordId: 0 };
}

export const CreateBalanceRecordResult = {
  encode(message: CreateBalanceRecordResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.balanceRecordId !== 0) {
      writer.uint32(24).sint32(message.balanceRecordId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBalanceRecordResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBalanceRecordResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 24) {
            break;
          }

          message.balanceRecordId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBalanceRecordResult {
    return { balanceRecordId: isSet(object.balanceRecordId) ? globalThis.Number(object.balanceRecordId) : 0 };
  },

  toJSON(message: CreateBalanceRecordResult): unknown {
    const obj: any = {};
    if (message.balanceRecordId !== 0) {
      obj.balanceRecordId = Math.round(message.balanceRecordId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateBalanceRecordResult>, I>>(base?: I): CreateBalanceRecordResult {
    return CreateBalanceRecordResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateBalanceRecordResult>, I>>(object: I): CreateBalanceRecordResult {
    const message = createBaseCreateBalanceRecordResult();
    message.balanceRecordId = object.balanceRecordId ?? 0;
    return message;
  },
};

function createBaseUpdateBalanceRecordResult(): UpdateBalanceRecordResult {
  return {};
}

export const UpdateBalanceRecordResult = {
  encode(_: UpdateBalanceRecordResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateBalanceRecordResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateBalanceRecordResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateBalanceRecordResult {
    return {};
  },

  toJSON(_: UpdateBalanceRecordResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateBalanceRecordResult>, I>>(base?: I): UpdateBalanceRecordResult {
    return UpdateBalanceRecordResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateBalanceRecordResult>, I>>(_: I): UpdateBalanceRecordResult {
    const message = createBaseUpdateBalanceRecordResult();
    return message;
  },
};

function createBaseAccountSearchResult(): AccountSearchResult {
  return { accountRecord: [], isComplete: true };
}

export const AccountSearchResult = {
  encode(message: AccountSearchResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.accountRecord) {
      AccountSearchResultRecord.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      writer.uint32(16).bool(message.isComplete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountSearchResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountSearchResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountRecord.push(AccountSearchResultRecord.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isComplete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountSearchResult {
    return {
      accountRecord: globalThis.Array.isArray(object?.accountRecord)
        ? object.accountRecord.map((e: any) => AccountSearchResultRecord.fromJSON(e))
        : [],
      isComplete: isSet(object.isComplete) ? globalThis.Boolean(object.isComplete) : true,
    };
  },

  toJSON(message: AccountSearchResult): unknown {
    const obj: any = {};
    if (message.accountRecord?.length) {
      obj.accountRecord = message.accountRecord.map((e) => AccountSearchResultRecord.toJSON(e));
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      obj.isComplete = message.isComplete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountSearchResult>, I>>(base?: I): AccountSearchResult {
    return AccountSearchResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountSearchResult>, I>>(object: I): AccountSearchResult {
    const message = createBaseAccountSearchResult();
    message.accountRecord = object.accountRecord?.map((e) => AccountSearchResultRecord.fromPartial(e)) || [];
    message.isComplete = object.isComplete ?? true;
    return message;
  },
};

function createBaseAccountRouteListResult(): AccountRouteListResult {
  return { accountRouteRecord: [] };
}

export const AccountRouteListResult = {
  encode(message: AccountRouteListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.accountRouteRecord) {
      AccountRouteRecord.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountRouteListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountRouteListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountRouteRecord.push(AccountRouteRecord.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountRouteListResult {
    return {
      accountRouteRecord: globalThis.Array.isArray(object?.accountRouteRecord)
        ? object.accountRouteRecord.map((e: any) => AccountRouteRecord.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountRouteListResult): unknown {
    const obj: any = {};
    if (message.accountRouteRecord?.length) {
      obj.accountRouteRecord = message.accountRouteRecord.map((e) => AccountRouteRecord.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountRouteListResult>, I>>(base?: I): AccountRouteListResult {
    return AccountRouteListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountRouteListResult>, I>>(object: I): AccountRouteListResult {
    const message = createBaseAccountRouteListResult();
    message.accountRouteRecord = object.accountRouteRecord?.map((e) => AccountRouteRecord.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccountUserAuthorizationListResult(): AccountUserAuthorizationListResult {
  return { accountUserLink: [], isComplete: true };
}

export const AccountUserAuthorizationListResult = {
  encode(message: AccountUserAuthorizationListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.accountUserLink) {
      AccountUserLink.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      writer.uint32(16).bool(message.isComplete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountUserAuthorizationListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountUserAuthorizationListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountUserLink.push(AccountUserLink.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isComplete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountUserAuthorizationListResult {
    return {
      accountUserLink: globalThis.Array.isArray(object?.accountUserLink)
        ? object.accountUserLink.map((e: any) => AccountUserLink.fromJSON(e))
        : [],
      isComplete: isSet(object.isComplete) ? globalThis.Boolean(object.isComplete) : true,
    };
  },

  toJSON(message: AccountUserAuthorizationListResult): unknown {
    const obj: any = {};
    if (message.accountUserLink?.length) {
      obj.accountUserLink = message.accountUserLink.map((e) => AccountUserLink.toJSON(e));
    }
    if (message.isComplete !== undefined && message.isComplete !== true) {
      obj.isComplete = message.isComplete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountUserAuthorizationListResult>, I>>(
    base?: I,
  ): AccountUserAuthorizationListResult {
    return AccountUserAuthorizationListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountUserAuthorizationListResult>, I>>(
    object: I,
  ): AccountUserAuthorizationListResult {
    const message = createBaseAccountUserAuthorizationListResult();
    message.accountUserLink = object.accountUserLink?.map((e) => AccountUserLink.fromPartial(e)) || [];
    message.isComplete = object.isComplete ?? true;
    return message;
  },
};

function createBaseAccountSettingsResult(): AccountSettingsResult {
  return { accountSettings: undefined };
}

export const AccountSettingsResult = {
  encode(message: AccountSettingsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountSettings !== undefined) {
      AccountSettings.encode(message.accountSettings, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountSettingsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountSettingsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountSettings = AccountSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountSettingsResult {
    return {
      accountSettings: isSet(object.accountSettings) ? AccountSettings.fromJSON(object.accountSettings) : undefined,
    };
  },

  toJSON(message: AccountSettingsResult): unknown {
    const obj: any = {};
    if (message.accountSettings !== undefined) {
      obj.accountSettings = AccountSettings.toJSON(message.accountSettings);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountSettingsResult>, I>>(base?: I): AccountSettingsResult {
    return AccountSettingsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountSettingsResult>, I>>(object: I): AccountSettingsResult {
    const message = createBaseAccountSettingsResult();
    message.accountSettings = (object.accountSettings !== undefined && object.accountSettings !== null)
      ? AccountSettings.fromPartial(object.accountSettings)
      : undefined;
    return message;
  },
};

function createBaseAccountRiskParametersResult(): AccountRiskParametersResult {
  return { accountRiskParameters: undefined };
}

export const AccountRiskParametersResult = {
  encode(message: AccountRiskParametersResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountRiskParameters !== undefined) {
      RiskParameters.encode(message.accountRiskParameters, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountRiskParametersResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountRiskParametersResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountRiskParameters = RiskParameters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountRiskParametersResult {
    return {
      accountRiskParameters: isSet(object.accountRiskParameters)
        ? RiskParameters.fromJSON(object.accountRiskParameters)
        : undefined,
    };
  },

  toJSON(message: AccountRiskParametersResult): unknown {
    const obj: any = {};
    if (message.accountRiskParameters !== undefined) {
      obj.accountRiskParameters = RiskParameters.toJSON(message.accountRiskParameters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountRiskParametersResult>, I>>(base?: I): AccountRiskParametersResult {
    return AccountRiskParametersResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountRiskParametersResult>, I>>(object: I): AccountRiskParametersResult {
    const message = createBaseAccountRiskParametersResult();
    message.accountRiskParameters =
      (object.accountRiskParameters !== undefined && object.accountRiskParameters !== null)
        ? RiskParameters.fromPartial(object.accountRiskParameters)
        : undefined;
    return message;
  },
};

function createBaseUpdateRiskParametersResult(): UpdateRiskParametersResult {
  return {};
}

export const UpdateRiskParametersResult = {
  encode(_: UpdateRiskParametersResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateRiskParametersResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRiskParametersResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateRiskParametersResult {
    return {};
  },

  toJSON(_: UpdateRiskParametersResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateRiskParametersResult>, I>>(base?: I): UpdateRiskParametersResult {
    return UpdateRiskParametersResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateRiskParametersResult>, I>>(_: I): UpdateRiskParametersResult {
    const message = createBaseUpdateRiskParametersResult();
    return message;
  },
};

function createBaseAccountExchangeGroupsResult(): AccountExchangeGroupsResult {
  return { exchangeGroup: [], instrumentType: [] };
}

export const AccountExchangeGroupsResult = {
  encode(message: AccountExchangeGroupsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.exchangeGroup) {
      ExchangeGroup.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.instrumentType) {
      InstrumentType.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountExchangeGroupsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountExchangeGroupsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.exchangeGroup.push(ExchangeGroup.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.instrumentType.push(InstrumentType.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountExchangeGroupsResult {
    return {
      exchangeGroup: globalThis.Array.isArray(object?.exchangeGroup)
        ? object.exchangeGroup.map((e: any) => ExchangeGroup.fromJSON(e))
        : [],
      instrumentType: globalThis.Array.isArray(object?.instrumentType)
        ? object.instrumentType.map((e: any) => InstrumentType.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountExchangeGroupsResult): unknown {
    const obj: any = {};
    if (message.exchangeGroup?.length) {
      obj.exchangeGroup = message.exchangeGroup.map((e) => ExchangeGroup.toJSON(e));
    }
    if (message.instrumentType?.length) {
      obj.instrumentType = message.instrumentType.map((e) => InstrumentType.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountExchangeGroupsResult>, I>>(base?: I): AccountExchangeGroupsResult {
    return AccountExchangeGroupsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountExchangeGroupsResult>, I>>(object: I): AccountExchangeGroupsResult {
    const message = createBaseAccountExchangeGroupsResult();
    message.exchangeGroup = object.exchangeGroup?.map((e) => ExchangeGroup.fromPartial(e)) || [];
    message.instrumentType = object.instrumentType?.map((e) => InstrumentType.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccountMarketLimitsResult(): AccountMarketLimitsResult {
  return { accountMarketLimits: undefined };
}

export const AccountMarketLimitsResult = {
  encode(message: AccountMarketLimitsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountMarketLimits !== undefined) {
      MarketLimits.encode(message.accountMarketLimits, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountMarketLimitsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountMarketLimitsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountMarketLimits = MarketLimits.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountMarketLimitsResult {
    return {
      accountMarketLimits: isSet(object.accountMarketLimits)
        ? MarketLimits.fromJSON(object.accountMarketLimits)
        : undefined,
    };
  },

  toJSON(message: AccountMarketLimitsResult): unknown {
    const obj: any = {};
    if (message.accountMarketLimits !== undefined) {
      obj.accountMarketLimits = MarketLimits.toJSON(message.accountMarketLimits);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountMarketLimitsResult>, I>>(base?: I): AccountMarketLimitsResult {
    return AccountMarketLimitsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountMarketLimitsResult>, I>>(object: I): AccountMarketLimitsResult {
    const message = createBaseAccountMarketLimitsResult();
    message.accountMarketLimits = (object.accountMarketLimits !== undefined && object.accountMarketLimits !== null)
      ? MarketLimits.fromPartial(object.accountMarketLimits)
      : undefined;
    return message;
  },
};

function createBaseAccountPositionsResult(): AccountPositionsResult {
  return { contractPosition: [] };
}

export const AccountPositionsResult = {
  encode(message: AccountPositionsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.contractPosition) {
      ContractPosition.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountPositionsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountPositionsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.contractPosition.push(ContractPosition.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountPositionsResult {
    return {
      contractPosition: globalThis.Array.isArray(object?.contractPosition)
        ? object.contractPosition.map((e: any) => ContractPosition.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountPositionsResult): unknown {
    const obj: any = {};
    if (message.contractPosition?.length) {
      obj.contractPosition = message.contractPosition.map((e) => ContractPosition.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountPositionsResult>, I>>(base?: I): AccountPositionsResult {
    return AccountPositionsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountPositionsResult>, I>>(object: I): AccountPositionsResult {
    const message = createBaseAccountPositionsResult();
    message.contractPosition = object.contractPosition?.map((e) => ContractPosition.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccountEquityResult(): AccountEquityResult {
  return { equity: undefined };
}

export const AccountEquityResult = {
  encode(message: AccountEquityResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.equity !== undefined) {
      Equity.encode(message.equity, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountEquityResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountEquityResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.equity = Equity.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountEquityResult {
    return { equity: isSet(object.equity) ? Equity.fromJSON(object.equity) : undefined };
  },

  toJSON(message: AccountEquityResult): unknown {
    const obj: any = {};
    if (message.equity !== undefined) {
      obj.equity = Equity.toJSON(message.equity);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountEquityResult>, I>>(base?: I): AccountEquityResult {
    return AccountEquityResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountEquityResult>, I>>(object: I): AccountEquityResult {
    const message = createBaseAccountEquityResult();
    message.equity = (object.equity !== undefined && object.equity !== null)
      ? Equity.fromPartial(object.equity)
      : undefined;
    return message;
  },
};

function createBaseAccountCollateralResult(): AccountCollateralResult {
  return { collateral: undefined };
}

export const AccountCollateralResult = {
  encode(message: AccountCollateralResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collateral !== undefined) {
      Collateral.encode(message.collateral, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountCollateralResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountCollateralResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collateral = Collateral.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountCollateralResult {
    return { collateral: isSet(object.collateral) ? Collateral.fromJSON(object.collateral) : undefined };
  },

  toJSON(message: AccountCollateralResult): unknown {
    const obj: any = {};
    if (message.collateral !== undefined) {
      obj.collateral = Collateral.toJSON(message.collateral);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountCollateralResult>, I>>(base?: I): AccountCollateralResult {
    return AccountCollateralResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountCollateralResult>, I>>(object: I): AccountCollateralResult {
    const message = createBaseAccountCollateralResult();
    message.collateral = (object.collateral !== undefined && object.collateral !== null)
      ? Collateral.fromPartial(object.collateral)
      : undefined;
    return message;
  },
};

function createBaseCloneAccountResult(): CloneAccountResult {
  return { newAccountId: 0 };
}

export const CloneAccountResult = {
  encode(message: CloneAccountResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.newAccountId !== 0) {
      writer.uint32(8).sint32(message.newAccountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CloneAccountResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCloneAccountResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.newAccountId = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CloneAccountResult {
    return { newAccountId: isSet(object.newAccountId) ? globalThis.Number(object.newAccountId) : 0 };
  },

  toJSON(message: CloneAccountResult): unknown {
    const obj: any = {};
    if (message.newAccountId !== 0) {
      obj.newAccountId = Math.round(message.newAccountId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CloneAccountResult>, I>>(base?: I): CloneAccountResult {
    return CloneAccountResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CloneAccountResult>, I>>(object: I): CloneAccountResult {
    const message = createBaseCloneAccountResult();
    message.newAccountId = object.newAccountId ?? 0;
    return message;
  },
};

function createBaseUpdateAccountResult(): UpdateAccountResult {
  return {};
}

export const UpdateAccountResult = {
  encode(_: UpdateAccountResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateAccountResult {
    return {};
  },

  toJSON(_: UpdateAccountResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountResult>, I>>(base?: I): UpdateAccountResult {
    return UpdateAccountResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountResult>, I>>(_: I): UpdateAccountResult {
    const message = createBaseUpdateAccountResult();
    return message;
  },
};

function createBaseUpdateAccountSettingsResult(): UpdateAccountSettingsResult {
  return {};
}

export const UpdateAccountSettingsResult = {
  encode(_: UpdateAccountSettingsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountSettingsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountSettingsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateAccountSettingsResult {
    return {};
  },

  toJSON(_: UpdateAccountSettingsResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountSettingsResult>, I>>(base?: I): UpdateAccountSettingsResult {
    return UpdateAccountSettingsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountSettingsResult>, I>>(_: I): UpdateAccountSettingsResult {
    const message = createBaseUpdateAccountSettingsResult();
    return message;
  },
};

function createBaseAccountGroupResult(): AccountGroupResult {
  return { acountGroup: undefined };
}

export const AccountGroupResult = {
  encode(message: AccountGroupResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.acountGroup !== undefined) {
      AccountGroup.encode(message.acountGroup, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountGroupResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountGroupResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.acountGroup = AccountGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountGroupResult {
    return { acountGroup: isSet(object.acountGroup) ? AccountGroup.fromJSON(object.acountGroup) : undefined };
  },

  toJSON(message: AccountGroupResult): unknown {
    const obj: any = {};
    if (message.acountGroup !== undefined) {
      obj.acountGroup = AccountGroup.toJSON(message.acountGroup);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountGroupResult>, I>>(base?: I): AccountGroupResult {
    return AccountGroupResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountGroupResult>, I>>(object: I): AccountGroupResult {
    const message = createBaseAccountGroupResult();
    message.acountGroup = (object.acountGroup !== undefined && object.acountGroup !== null)
      ? AccountGroup.fromPartial(object.acountGroup)
      : undefined;
    return message;
  },
};

function createBaseUpdateAccountRiskParametersResult(): UpdateAccountRiskParametersResult {
  return {};
}

export const UpdateAccountRiskParametersResult = {
  encode(_: UpdateAccountRiskParametersResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountRiskParametersResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountRiskParametersResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateAccountRiskParametersResult {
    return {};
  },

  toJSON(_: UpdateAccountRiskParametersResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountRiskParametersResult>, I>>(
    base?: I,
  ): UpdateAccountRiskParametersResult {
    return UpdateAccountRiskParametersResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountRiskParametersResult>, I>>(
    _: I,
  ): UpdateAccountRiskParametersResult {
    const message = createBaseUpdateAccountRiskParametersResult();
    return message;
  },
};

function createBaseAccountAvailableRouteListResult(): AccountAvailableRouteListResult {
  return { routes: [] };
}

export const AccountAvailableRouteListResult = {
  encode(message: AccountAvailableRouteListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.routes) {
      AvailableRoute.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountAvailableRouteListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountAvailableRouteListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.routes.push(AvailableRoute.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountAvailableRouteListResult {
    return {
      routes: globalThis.Array.isArray(object?.routes) ? object.routes.map((e: any) => AvailableRoute.fromJSON(e)) : [],
    };
  },

  toJSON(message: AccountAvailableRouteListResult): unknown {
    const obj: any = {};
    if (message.routes?.length) {
      obj.routes = message.routes.map((e) => AvailableRoute.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountAvailableRouteListResult>, I>>(base?: I): AccountAvailableRouteListResult {
    return AccountAvailableRouteListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountAvailableRouteListResult>, I>>(
    object: I,
  ): AccountAvailableRouteListResult {
    const message = createBaseAccountAvailableRouteListResult();
    message.routes = object.routes?.map((e) => AvailableRoute.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateAccountRouteListResult(): UpdateAccountRouteListResult {
  return {};
}

export const UpdateAccountRouteListResult = {
  encode(_: UpdateAccountRouteListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountRouteListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountRouteListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateAccountRouteListResult {
    return {};
  },

  toJSON(_: UpdateAccountRouteListResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountRouteListResult>, I>>(base?: I): UpdateAccountRouteListResult {
    return UpdateAccountRouteListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountRouteListResult>, I>>(_: I): UpdateAccountRouteListResult {
    const message = createBaseUpdateAccountRouteListResult();
    return message;
  },
};

function createBaseUpdateAccountMarketLimitsResult(): UpdateAccountMarketLimitsResult {
  return {};
}

export const UpdateAccountMarketLimitsResult = {
  encode(_: UpdateAccountMarketLimitsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountMarketLimitsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountMarketLimitsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateAccountMarketLimitsResult {
    return {};
  },

  toJSON(_: UpdateAccountMarketLimitsResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountMarketLimitsResult>, I>>(base?: I): UpdateAccountMarketLimitsResult {
    return UpdateAccountMarketLimitsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountMarketLimitsResult>, I>>(_: I): UpdateAccountMarketLimitsResult {
    const message = createBaseUpdateAccountMarketLimitsResult();
    return message;
  },
};

function createBaseCreateAccountResult(): CreateAccountResult {
  return { id: "" };
}

export const CreateAccountResult = {
  encode(message: CreateAccountResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAccountResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAccountResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAccountResult {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: CreateAccountResult): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAccountResult>, I>>(base?: I): CreateAccountResult {
    return CreateAccountResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAccountResult>, I>>(object: I): CreateAccountResult {
    const message = createBaseCreateAccountResult();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseAccountClusterResult(): AccountClusterResult {
  return { obsoleteAccountCluster: undefined, obsoleteAccountClusterPriceOffsets: [] };
}

export const AccountClusterResult = {
  encode(message: AccountClusterResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.obsoleteAccountCluster !== undefined) {
      AccountCluster.encode(message.obsoleteAccountCluster, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.obsoleteAccountClusterPriceOffsets) {
      AccountClusterPriceOffset.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountClusterResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountClusterResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.obsoleteAccountCluster = AccountCluster.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.obsoleteAccountClusterPriceOffsets.push(AccountClusterPriceOffset.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountClusterResult {
    return {
      obsoleteAccountCluster: isSet(object.obsoleteAccountCluster)
        ? AccountCluster.fromJSON(object.obsoleteAccountCluster)
        : undefined,
      obsoleteAccountClusterPriceOffsets: globalThis.Array.isArray(object?.obsoleteAccountClusterPriceOffsets)
        ? object.obsoleteAccountClusterPriceOffsets.map((e: any) => AccountClusterPriceOffset.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountClusterResult): unknown {
    const obj: any = {};
    if (message.obsoleteAccountCluster !== undefined) {
      obj.obsoleteAccountCluster = AccountCluster.toJSON(message.obsoleteAccountCluster);
    }
    if (message.obsoleteAccountClusterPriceOffsets?.length) {
      obj.obsoleteAccountClusterPriceOffsets = message.obsoleteAccountClusterPriceOffsets.map((e) =>
        AccountClusterPriceOffset.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountClusterResult>, I>>(base?: I): AccountClusterResult {
    return AccountClusterResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountClusterResult>, I>>(object: I): AccountClusterResult {
    const message = createBaseAccountClusterResult();
    message.obsoleteAccountCluster =
      (object.obsoleteAccountCluster !== undefined && object.obsoleteAccountCluster !== null)
        ? AccountCluster.fromPartial(object.obsoleteAccountCluster)
        : undefined;
    message.obsoleteAccountClusterPriceOffsets =
      object.obsoleteAccountClusterPriceOffsets?.map((e) => AccountClusterPriceOffset.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateAccountClusterResult(): CreateAccountClusterResult {
  return { id: "" };
}

export const CreateAccountClusterResult = {
  encode(message: CreateAccountClusterResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAccountClusterResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAccountClusterResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAccountClusterResult {
    return { id: isSet(object.id) ? globalThis.String(object.id) : "" };
  },

  toJSON(message: CreateAccountClusterResult): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAccountClusterResult>, I>>(base?: I): CreateAccountClusterResult {
    return CreateAccountClusterResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAccountClusterResult>, I>>(object: I): CreateAccountClusterResult {
    const message = createBaseCreateAccountClusterResult();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseUpdateAccountClusterResult(): UpdateAccountClusterResult {
  return {};
}

export const UpdateAccountClusterResult = {
  encode(_: UpdateAccountClusterResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountClusterResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountClusterResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateAccountClusterResult {
    return {};
  },

  toJSON(_: UpdateAccountClusterResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountClusterResult>, I>>(base?: I): UpdateAccountClusterResult {
    return UpdateAccountClusterResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountClusterResult>, I>>(_: I): UpdateAccountClusterResult {
    const message = createBaseUpdateAccountClusterResult();
    return message;
  },
};

function createBaseUpdateAccountClusterPriceOffsetsResult(): UpdateAccountClusterPriceOffsetsResult {
  return {};
}

export const UpdateAccountClusterPriceOffsetsResult = {
  encode(_: UpdateAccountClusterPriceOffsetsResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountClusterPriceOffsetsResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountClusterPriceOffsetsResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateAccountClusterPriceOffsetsResult {
    return {};
  },

  toJSON(_: UpdateAccountClusterPriceOffsetsResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountClusterPriceOffsetsResult>, I>>(
    base?: I,
  ): UpdateAccountClusterPriceOffsetsResult {
    return UpdateAccountClusterPriceOffsetsResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountClusterPriceOffsetsResult>, I>>(
    _: I,
  ): UpdateAccountClusterPriceOffsetsResult {
    const message = createBaseUpdateAccountClusterPriceOffsetsResult();
    return message;
  },
};

function createBaseOrderChain(): OrderChain {
  return {
    chainOrderId: "",
    orderId: "",
    chainOrigAccountId: 0,
    submittedUtcTime: 0,
    contractSymbol: "",
    side: 0,
    obsoleteSize: 0,
    limitPrice: 0,
    stopPrice: 0,
    execInstruction: [],
    duration: 0,
    goodThruDate: 0,
    goodThruUtcTime: 0,
    status: 0,
    orderType: 0,
    orderRequest: [],
    orderFill: [],
    orderClasses: [],
    displayLimitPrice: 0,
    displayStopPrice: 0,
    executionAccountName: "",
    trailing: undefined,
    sizes: undefined,
    accounts: [],
    compoundTreeId: "",
    rootChainOrderId: "",
    checked: false,
    tradingExpression: "",
    legs: [],
    contractDescription: "",
    userAttributes: [],
    clientAlgoStrategy: "",
    algoStrategy: "",
    route: undefined,
    expirationTime: 0,
    openCloseType: 0,
    speculationType: 0,
    archived: false,
    orderIdAtExchange: "",
    obsoleteDiscretionaryOffset: 0,
    size: undefined,
    extendedSide: 0,
    locateId: "",
    exchangeExtraValues: [],
    isExcludedFromRiskCalculation: false,
  };
}

export const OrderChain = {
  encode(message: OrderChain, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainOrderId !== "") {
      writer.uint32(10).string(message.chainOrderId);
    }
    if (message.orderId !== "") {
      writer.uint32(18).string(message.orderId);
    }
    if (message.chainOrigAccountId !== 0) {
      writer.uint32(32).sint32(message.chainOrigAccountId);
    }
    if (message.submittedUtcTime !== 0) {
      writer.uint32(40).sint64(message.submittedUtcTime);
    }
    if (message.contractSymbol !== "") {
      writer.uint32(50).string(message.contractSymbol);
    }
    if (message.side !== 0) {
      writer.uint32(56).uint32(message.side);
    }
    if (message.obsoleteSize !== undefined && message.obsoleteSize !== 0) {
      writer.uint32(64).uint32(message.obsoleteSize);
    }
    if (message.limitPrice !== undefined && message.limitPrice !== 0) {
      writer.uint32(73).double(message.limitPrice);
    }
    if (message.stopPrice !== undefined && message.stopPrice !== 0) {
      writer.uint32(81).double(message.stopPrice);
    }
    writer.uint32(90).fork();
    for (const v of message.execInstruction) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.duration !== 0) {
      writer.uint32(96).uint32(message.duration);
    }
    if (message.goodThruDate !== undefined && message.goodThruDate !== 0) {
      writer.uint32(104).sint64(message.goodThruDate);
    }
    if (message.goodThruUtcTime !== undefined && message.goodThruUtcTime !== 0) {
      writer.uint32(112).sint64(message.goodThruUtcTime);
    }
    if (message.status !== 0) {
      writer.uint32(120).uint32(message.status);
    }
    if (message.orderType !== 0) {
      writer.uint32(128).uint32(message.orderType);
    }
    for (const v of message.orderRequest) {
      OrderRequest.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    for (const v of message.orderFill) {
      OrderFill.encode(v!, writer.uint32(146).fork()).ldelim();
    }
    for (const v of message.orderClasses) {
      writer.uint32(154).string(v!);
    }
    if (message.displayLimitPrice !== undefined && message.displayLimitPrice !== 0) {
      writer.uint32(160).sint64(message.displayLimitPrice);
    }
    if (message.displayStopPrice !== undefined && message.displayStopPrice !== 0) {
      writer.uint32(168).sint64(message.displayStopPrice);
    }
    if (message.executionAccountName !== undefined && message.executionAccountName !== "") {
      writer.uint32(178).string(message.executionAccountName);
    }
    if (message.trailing !== undefined) {
      OrderTrailing.encode(message.trailing, writer.uint32(186).fork()).ldelim();
    }
    if (message.sizes !== undefined) {
      OrderSizes.encode(message.sizes, writer.uint32(194).fork()).ldelim();
    }
    for (const v of message.accounts) {
      ChainAccount.encode(v!, writer.uint32(202).fork()).ldelim();
    }
    if (message.compoundTreeId !== undefined && message.compoundTreeId !== "") {
      writer.uint32(210).string(message.compoundTreeId);
    }
    if (message.rootChainOrderId !== undefined && message.rootChainOrderId !== "") {
      writer.uint32(218).string(message.rootChainOrderId);
    }
    if (message.checked !== undefined && message.checked !== false) {
      writer.uint32(224).bool(message.checked);
    }
    if (message.tradingExpression !== undefined && message.tradingExpression !== "") {
      writer.uint32(234).string(message.tradingExpression);
    }
    for (const v of message.legs) {
      OrderContractLeg.encode(v!, writer.uint32(242).fork()).ldelim();
    }
    if (message.contractDescription !== undefined && message.contractDescription !== "") {
      writer.uint32(250).string(message.contractDescription);
    }
    for (const v of message.userAttributes) {
      Tuple.encode(v!, writer.uint32(258).fork()).ldelim();
    }
    if (message.clientAlgoStrategy !== undefined && message.clientAlgoStrategy !== "") {
      writer.uint32(266).string(message.clientAlgoStrategy);
    }
    if (message.algoStrategy !== undefined && message.algoStrategy !== "") {
      writer.uint32(274).string(message.algoStrategy);
    }
    if (message.route !== undefined) {
      OrderRoute.encode(message.route, writer.uint32(282).fork()).ldelim();
    }
    if (message.expirationTime !== undefined && message.expirationTime !== 0) {
      writer.uint32(288).sint64(message.expirationTime);
    }
    if (message.openCloseType !== undefined && message.openCloseType !== 0) {
      writer.uint32(296).uint32(message.openCloseType);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(304).uint32(message.speculationType);
    }
    if (message.archived !== undefined && message.archived !== false) {
      writer.uint32(312).bool(message.archived);
    }
    if (message.orderIdAtExchange !== undefined && message.orderIdAtExchange !== "") {
      writer.uint32(322).string(message.orderIdAtExchange);
    }
    if (message.obsoleteDiscretionaryOffset !== undefined && message.obsoleteDiscretionaryOffset !== 0) {
      writer.uint32(328).uint32(message.obsoleteDiscretionaryOffset);
    }
    if (message.size !== undefined) {
      Decimal.encode(message.size, writer.uint32(338).fork()).ldelim();
    }
    if (message.extendedSide !== undefined && message.extendedSide !== 0) {
      writer.uint32(344).uint32(message.extendedSide);
    }
    if (message.locateId !== undefined && message.locateId !== "") {
      writer.uint32(354).string(message.locateId);
    }
    for (const v of message.exchangeExtraValues) {
      Tuple.encode(v!, writer.uint32(362).fork()).ldelim();
    }
    if (message.isExcludedFromRiskCalculation !== undefined && message.isExcludedFromRiskCalculation !== false) {
      writer.uint32(368).bool(message.isExcludedFromRiskCalculation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderChain {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderChain();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chainOrderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.chainOrigAccountId = reader.sint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.submittedUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.contractSymbol = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.obsoleteSize = reader.uint32();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.limitPrice = reader.double();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.stopPrice = reader.double();
          continue;
        case 11:
          if (tag === 88) {
            message.execInstruction.push(reader.uint32());

            continue;
          }

          if (tag === 90) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.execInstruction.push(reader.uint32());
            }

            continue;
          }

          break;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.duration = reader.uint32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.goodThruDate = longToNumber(reader.sint64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.goodThruUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.status = reader.uint32();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.orderType = reader.uint32();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.orderRequest.push(OrderRequest.decode(reader, reader.uint32()));
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.orderFill.push(OrderFill.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.orderClasses.push(reader.string());
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.displayLimitPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.displayStopPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.executionAccountName = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.trailing = OrderTrailing.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.sizes = OrderSizes.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.accounts.push(ChainAccount.decode(reader, reader.uint32()));
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.compoundTreeId = reader.string();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.rootChainOrderId = reader.string();
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.checked = reader.bool();
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.tradingExpression = reader.string();
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.legs.push(OrderContractLeg.decode(reader, reader.uint32()));
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.contractDescription = reader.string();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.userAttributes.push(Tuple.decode(reader, reader.uint32()));
          continue;
        case 33:
          if (tag !== 266) {
            break;
          }

          message.clientAlgoStrategy = reader.string();
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.algoStrategy = reader.string();
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.route = OrderRoute.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag !== 288) {
            break;
          }

          message.expirationTime = longToNumber(reader.sint64() as Long);
          continue;
        case 37:
          if (tag !== 296) {
            break;
          }

          message.openCloseType = reader.uint32();
          continue;
        case 38:
          if (tag !== 304) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
        case 39:
          if (tag !== 312) {
            break;
          }

          message.archived = reader.bool();
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.orderIdAtExchange = reader.string();
          continue;
        case 41:
          if (tag !== 328) {
            break;
          }

          message.obsoleteDiscretionaryOffset = reader.uint32();
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.size = Decimal.decode(reader, reader.uint32());
          continue;
        case 43:
          if (tag !== 344) {
            break;
          }

          message.extendedSide = reader.uint32();
          continue;
        case 44:
          if (tag !== 354) {
            break;
          }

          message.locateId = reader.string();
          continue;
        case 45:
          if (tag !== 362) {
            break;
          }

          message.exchangeExtraValues.push(Tuple.decode(reader, reader.uint32()));
          continue;
        case 46:
          if (tag !== 368) {
            break;
          }

          message.isExcludedFromRiskCalculation = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderChain {
    return {
      chainOrderId: isSet(object.chainOrderId) ? globalThis.String(object.chainOrderId) : "",
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      chainOrigAccountId: isSet(object.chainOrigAccountId) ? globalThis.Number(object.chainOrigAccountId) : 0,
      submittedUtcTime: isSet(object.submittedUtcTime) ? globalThis.Number(object.submittedUtcTime) : 0,
      contractSymbol: isSet(object.contractSymbol) ? globalThis.String(object.contractSymbol) : "",
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      obsoleteSize: isSet(object.obsoleteSize) ? globalThis.Number(object.obsoleteSize) : 0,
      limitPrice: isSet(object.limitPrice) ? globalThis.Number(object.limitPrice) : 0,
      stopPrice: isSet(object.stopPrice) ? globalThis.Number(object.stopPrice) : 0,
      execInstruction: globalThis.Array.isArray(object?.execInstruction)
        ? object.execInstruction.map((e: any) => globalThis.Number(e))
        : [],
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      goodThruDate: isSet(object.goodThruDate) ? globalThis.Number(object.goodThruDate) : 0,
      goodThruUtcTime: isSet(object.goodThruUtcTime) ? globalThis.Number(object.goodThruUtcTime) : 0,
      status: isSet(object.status) ? globalThis.Number(object.status) : 0,
      orderType: isSet(object.orderType) ? globalThis.Number(object.orderType) : 0,
      orderRequest: globalThis.Array.isArray(object?.orderRequest)
        ? object.orderRequest.map((e: any) => OrderRequest.fromJSON(e))
        : [],
      orderFill: globalThis.Array.isArray(object?.orderFill)
        ? object.orderFill.map((e: any) => OrderFill.fromJSON(e))
        : [],
      orderClasses: globalThis.Array.isArray(object?.orderClasses)
        ? object.orderClasses.map((e: any) => globalThis.String(e))
        : [],
      displayLimitPrice: isSet(object.displayLimitPrice) ? globalThis.Number(object.displayLimitPrice) : 0,
      displayStopPrice: isSet(object.displayStopPrice) ? globalThis.Number(object.displayStopPrice) : 0,
      executionAccountName: isSet(object.executionAccountName) ? globalThis.String(object.executionAccountName) : "",
      trailing: isSet(object.trailing) ? OrderTrailing.fromJSON(object.trailing) : undefined,
      sizes: isSet(object.sizes) ? OrderSizes.fromJSON(object.sizes) : undefined,
      accounts: globalThis.Array.isArray(object?.accounts)
        ? object.accounts.map((e: any) => ChainAccount.fromJSON(e))
        : [],
      compoundTreeId: isSet(object.compoundTreeId) ? globalThis.String(object.compoundTreeId) : "",
      rootChainOrderId: isSet(object.rootChainOrderId) ? globalThis.String(object.rootChainOrderId) : "",
      checked: isSet(object.checked) ? globalThis.Boolean(object.checked) : false,
      tradingExpression: isSet(object.tradingExpression) ? globalThis.String(object.tradingExpression) : "",
      legs: globalThis.Array.isArray(object?.legs)
        ? object.legs.map((e: any) => OrderContractLeg.fromJSON(e))
        : [],
      contractDescription: isSet(object.contractDescription) ? globalThis.String(object.contractDescription) : "",
      userAttributes: globalThis.Array.isArray(object?.userAttributes)
        ? object.userAttributes.map((e: any) => Tuple.fromJSON(e))
        : [],
      clientAlgoStrategy: isSet(object.clientAlgoStrategy) ? globalThis.String(object.clientAlgoStrategy) : "",
      algoStrategy: isSet(object.algoStrategy) ? globalThis.String(object.algoStrategy) : "",
      route: isSet(object.route) ? OrderRoute.fromJSON(object.route) : undefined,
      expirationTime: isSet(object.expirationTime) ? globalThis.Number(object.expirationTime) : 0,
      openCloseType: isSet(object.openCloseType) ? globalThis.Number(object.openCloseType) : 0,
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
      archived: isSet(object.archived) ? globalThis.Boolean(object.archived) : false,
      orderIdAtExchange: isSet(object.orderIdAtExchange) ? globalThis.String(object.orderIdAtExchange) : "",
      obsoleteDiscretionaryOffset: isSet(object.obsoleteDiscretionaryOffset)
        ? globalThis.Number(object.obsoleteDiscretionaryOffset)
        : 0,
      size: isSet(object.size) ? Decimal.fromJSON(object.size) : undefined,
      extendedSide: isSet(object.extendedSide) ? globalThis.Number(object.extendedSide) : 0,
      locateId: isSet(object.locateId) ? globalThis.String(object.locateId) : "",
      exchangeExtraValues: globalThis.Array.isArray(object?.exchangeExtraValues)
        ? object.exchangeExtraValues.map((e: any) => Tuple.fromJSON(e))
        : [],
      isExcludedFromRiskCalculation: isSet(object.isExcludedFromRiskCalculation)
        ? globalThis.Boolean(object.isExcludedFromRiskCalculation)
        : false,
    };
  },

  toJSON(message: OrderChain): unknown {
    const obj: any = {};
    if (message.chainOrderId !== "") {
      obj.chainOrderId = message.chainOrderId;
    }
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.chainOrigAccountId !== 0) {
      obj.chainOrigAccountId = Math.round(message.chainOrigAccountId);
    }
    if (message.submittedUtcTime !== 0) {
      obj.submittedUtcTime = Math.round(message.submittedUtcTime);
    }
    if (message.contractSymbol !== "") {
      obj.contractSymbol = message.contractSymbol;
    }
    if (message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.obsoleteSize !== undefined && message.obsoleteSize !== 0) {
      obj.obsoleteSize = Math.round(message.obsoleteSize);
    }
    if (message.limitPrice !== undefined && message.limitPrice !== 0) {
      obj.limitPrice = message.limitPrice;
    }
    if (message.stopPrice !== undefined && message.stopPrice !== 0) {
      obj.stopPrice = message.stopPrice;
    }
    if (message.execInstruction?.length) {
      obj.execInstruction = message.execInstruction.map((e) => Math.round(e));
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.goodThruDate !== undefined && message.goodThruDate !== 0) {
      obj.goodThruDate = Math.round(message.goodThruDate);
    }
    if (message.goodThruUtcTime !== undefined && message.goodThruUtcTime !== 0) {
      obj.goodThruUtcTime = Math.round(message.goodThruUtcTime);
    }
    if (message.status !== 0) {
      obj.status = Math.round(message.status);
    }
    if (message.orderType !== 0) {
      obj.orderType = Math.round(message.orderType);
    }
    if (message.orderRequest?.length) {
      obj.orderRequest = message.orderRequest.map((e) => OrderRequest.toJSON(e));
    }
    if (message.orderFill?.length) {
      obj.orderFill = message.orderFill.map((e) => OrderFill.toJSON(e));
    }
    if (message.orderClasses?.length) {
      obj.orderClasses = message.orderClasses;
    }
    if (message.displayLimitPrice !== undefined && message.displayLimitPrice !== 0) {
      obj.displayLimitPrice = Math.round(message.displayLimitPrice);
    }
    if (message.displayStopPrice !== undefined && message.displayStopPrice !== 0) {
      obj.displayStopPrice = Math.round(message.displayStopPrice);
    }
    if (message.executionAccountName !== undefined && message.executionAccountName !== "") {
      obj.executionAccountName = message.executionAccountName;
    }
    if (message.trailing !== undefined) {
      obj.trailing = OrderTrailing.toJSON(message.trailing);
    }
    if (message.sizes !== undefined) {
      obj.sizes = OrderSizes.toJSON(message.sizes);
    }
    if (message.accounts?.length) {
      obj.accounts = message.accounts.map((e) => ChainAccount.toJSON(e));
    }
    if (message.compoundTreeId !== undefined && message.compoundTreeId !== "") {
      obj.compoundTreeId = message.compoundTreeId;
    }
    if (message.rootChainOrderId !== undefined && message.rootChainOrderId !== "") {
      obj.rootChainOrderId = message.rootChainOrderId;
    }
    if (message.checked !== undefined && message.checked !== false) {
      obj.checked = message.checked;
    }
    if (message.tradingExpression !== undefined && message.tradingExpression !== "") {
      obj.tradingExpression = message.tradingExpression;
    }
    if (message.legs?.length) {
      obj.legs = message.legs.map((e) => OrderContractLeg.toJSON(e));
    }
    if (message.contractDescription !== undefined && message.contractDescription !== "") {
      obj.contractDescription = message.contractDescription;
    }
    if (message.userAttributes?.length) {
      obj.userAttributes = message.userAttributes.map((e) => Tuple.toJSON(e));
    }
    if (message.clientAlgoStrategy !== undefined && message.clientAlgoStrategy !== "") {
      obj.clientAlgoStrategy = message.clientAlgoStrategy;
    }
    if (message.algoStrategy !== undefined && message.algoStrategy !== "") {
      obj.algoStrategy = message.algoStrategy;
    }
    if (message.route !== undefined) {
      obj.route = OrderRoute.toJSON(message.route);
    }
    if (message.expirationTime !== undefined && message.expirationTime !== 0) {
      obj.expirationTime = Math.round(message.expirationTime);
    }
    if (message.openCloseType !== undefined && message.openCloseType !== 0) {
      obj.openCloseType = Math.round(message.openCloseType);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    if (message.archived !== undefined && message.archived !== false) {
      obj.archived = message.archived;
    }
    if (message.orderIdAtExchange !== undefined && message.orderIdAtExchange !== "") {
      obj.orderIdAtExchange = message.orderIdAtExchange;
    }
    if (message.obsoleteDiscretionaryOffset !== undefined && message.obsoleteDiscretionaryOffset !== 0) {
      obj.obsoleteDiscretionaryOffset = Math.round(message.obsoleteDiscretionaryOffset);
    }
    if (message.size !== undefined) {
      obj.size = Decimal.toJSON(message.size);
    }
    if (message.extendedSide !== undefined && message.extendedSide !== 0) {
      obj.extendedSide = Math.round(message.extendedSide);
    }
    if (message.locateId !== undefined && message.locateId !== "") {
      obj.locateId = message.locateId;
    }
    if (message.exchangeExtraValues?.length) {
      obj.exchangeExtraValues = message.exchangeExtraValues.map((e) => Tuple.toJSON(e));
    }
    if (message.isExcludedFromRiskCalculation !== undefined && message.isExcludedFromRiskCalculation !== false) {
      obj.isExcludedFromRiskCalculation = message.isExcludedFromRiskCalculation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderChain>, I>>(base?: I): OrderChain {
    return OrderChain.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderChain>, I>>(object: I): OrderChain {
    const message = createBaseOrderChain();
    message.chainOrderId = object.chainOrderId ?? "";
    message.orderId = object.orderId ?? "";
    message.chainOrigAccountId = object.chainOrigAccountId ?? 0;
    message.submittedUtcTime = object.submittedUtcTime ?? 0;
    message.contractSymbol = object.contractSymbol ?? "";
    message.side = object.side ?? 0;
    message.obsoleteSize = object.obsoleteSize ?? 0;
    message.limitPrice = object.limitPrice ?? 0;
    message.stopPrice = object.stopPrice ?? 0;
    message.execInstruction = object.execInstruction?.map((e) => e) || [];
    message.duration = object.duration ?? 0;
    message.goodThruDate = object.goodThruDate ?? 0;
    message.goodThruUtcTime = object.goodThruUtcTime ?? 0;
    message.status = object.status ?? 0;
    message.orderType = object.orderType ?? 0;
    message.orderRequest = object.orderRequest?.map((e) => OrderRequest.fromPartial(e)) || [];
    message.orderFill = object.orderFill?.map((e) => OrderFill.fromPartial(e)) || [];
    message.orderClasses = object.orderClasses?.map((e) => e) || [];
    message.displayLimitPrice = object.displayLimitPrice ?? 0;
    message.displayStopPrice = object.displayStopPrice ?? 0;
    message.executionAccountName = object.executionAccountName ?? "";
    message.trailing = (object.trailing !== undefined && object.trailing !== null)
      ? OrderTrailing.fromPartial(object.trailing)
      : undefined;
    message.sizes = (object.sizes !== undefined && object.sizes !== null)
      ? OrderSizes.fromPartial(object.sizes)
      : undefined;
    message.accounts = object.accounts?.map((e) => ChainAccount.fromPartial(e)) || [];
    message.compoundTreeId = object.compoundTreeId ?? "";
    message.rootChainOrderId = object.rootChainOrderId ?? "";
    message.checked = object.checked ?? false;
    message.tradingExpression = object.tradingExpression ?? "";
    message.legs = object.legs?.map((e) => OrderContractLeg.fromPartial(e)) || [];
    message.contractDescription = object.contractDescription ?? "";
    message.userAttributes = object.userAttributes?.map((e) => Tuple.fromPartial(e)) || [];
    message.clientAlgoStrategy = object.clientAlgoStrategy ?? "";
    message.algoStrategy = object.algoStrategy ?? "";
    message.route = (object.route !== undefined && object.route !== null)
      ? OrderRoute.fromPartial(object.route)
      : undefined;
    message.expirationTime = object.expirationTime ?? 0;
    message.openCloseType = object.openCloseType ?? 0;
    message.speculationType = object.speculationType ?? 0;
    message.archived = object.archived ?? false;
    message.orderIdAtExchange = object.orderIdAtExchange ?? "";
    message.obsoleteDiscretionaryOffset = object.obsoleteDiscretionaryOffset ?? 0;
    message.size = (object.size !== undefined && object.size !== null) ? Decimal.fromPartial(object.size) : undefined;
    message.extendedSide = object.extendedSide ?? 0;
    message.locateId = object.locateId ?? "";
    message.exchangeExtraValues = object.exchangeExtraValues?.map((e) => Tuple.fromPartial(e)) || [];
    message.isExcludedFromRiskCalculation = object.isExcludedFromRiskCalculation ?? false;
    return message;
  },
};

function createBaseChainAccount(): ChainAccount {
  return { accountId: "", accountName: "", brokerageAccountNumber: "" };
}

export const ChainAccount = {
  encode(message: ChainAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    if (message.accountName !== undefined && message.accountName !== "") {
      writer.uint32(18).string(message.accountName);
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      writer.uint32(26).string(message.brokerageAccountNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChainAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChainAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.brokerageAccountNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChainAccount {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      accountName: isSet(object.accountName) ? globalThis.String(object.accountName) : "",
      brokerageAccountNumber: isSet(object.brokerageAccountNumber)
        ? globalThis.String(object.brokerageAccountNumber)
        : "",
    };
  },

  toJSON(message: ChainAccount): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.accountName !== undefined && message.accountName !== "") {
      obj.accountName = message.accountName;
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      obj.brokerageAccountNumber = message.brokerageAccountNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChainAccount>, I>>(base?: I): ChainAccount {
    return ChainAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChainAccount>, I>>(object: I): ChainAccount {
    const message = createBaseChainAccount();
    message.accountId = object.accountId ?? "";
    message.accountName = object.accountName ?? "";
    message.brokerageAccountNumber = object.brokerageAccountNumber ?? "";
    return message;
  },
};

function createBaseOrderRoute(): OrderRoute {
  return { code: "", name: "", orderId: "" };
}

export const OrderRoute = {
  encode(message: OrderRoute, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== undefined && message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.orderId !== undefined && message.orderId !== "") {
      writer.uint32(26).string(message.orderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderRoute {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderRoute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orderId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderRoute {
    return {
      code: isSet(object.code) ? globalThis.String(object.code) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
    };
  },

  toJSON(message: OrderRoute): unknown {
    const obj: any = {};
    if (message.code !== undefined && message.code !== "") {
      obj.code = message.code;
    }
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.orderId !== undefined && message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderRoute>, I>>(base?: I): OrderRoute {
    return OrderRoute.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderRoute>, I>>(object: I): OrderRoute {
    const message = createBaseOrderRoute();
    message.code = object.code ?? "";
    message.name = object.name ?? "";
    message.orderId = object.orderId ?? "";
    return message;
  },
};

function createBaseOrderContractLeg(): OrderContractLeg {
  return { number: 0, obsoleteSize: 0, side: 0, contractSymbol: "", size: undefined, extendedSide: 0, locateId: "" };
}

export const OrderContractLeg = {
  encode(message: OrderContractLeg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== undefined && message.number !== 0) {
      writer.uint32(8).uint32(message.number);
    }
    if (message.obsoleteSize !== undefined && message.obsoleteSize !== 0) {
      writer.uint32(16).uint32(message.obsoleteSize);
    }
    if (message.side !== undefined && message.side !== 0) {
      writer.uint32(24).uint32(message.side);
    }
    if (message.contractSymbol !== undefined && message.contractSymbol !== "") {
      writer.uint32(34).string(message.contractSymbol);
    }
    if (message.size !== undefined) {
      Decimal.encode(message.size, writer.uint32(42).fork()).ldelim();
    }
    if (message.extendedSide !== undefined && message.extendedSide !== 0) {
      writer.uint32(48).uint32(message.extendedSide);
    }
    if (message.locateId !== undefined && message.locateId !== "") {
      writer.uint32(58).string(message.locateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderContractLeg {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderContractLeg();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.number = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.obsoleteSize = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.contractSymbol = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.size = Decimal.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.extendedSide = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.locateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderContractLeg {
    return {
      number: isSet(object.number) ? globalThis.Number(object.number) : 0,
      obsoleteSize: isSet(object.obsoleteSize) ? globalThis.Number(object.obsoleteSize) : 0,
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      contractSymbol: isSet(object.contractSymbol) ? globalThis.String(object.contractSymbol) : "",
      size: isSet(object.size) ? Decimal.fromJSON(object.size) : undefined,
      extendedSide: isSet(object.extendedSide) ? globalThis.Number(object.extendedSide) : 0,
      locateId: isSet(object.locateId) ? globalThis.String(object.locateId) : "",
    };
  },

  toJSON(message: OrderContractLeg): unknown {
    const obj: any = {};
    if (message.number !== undefined && message.number !== 0) {
      obj.number = Math.round(message.number);
    }
    if (message.obsoleteSize !== undefined && message.obsoleteSize !== 0) {
      obj.obsoleteSize = Math.round(message.obsoleteSize);
    }
    if (message.side !== undefined && message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.contractSymbol !== undefined && message.contractSymbol !== "") {
      obj.contractSymbol = message.contractSymbol;
    }
    if (message.size !== undefined) {
      obj.size = Decimal.toJSON(message.size);
    }
    if (message.extendedSide !== undefined && message.extendedSide !== 0) {
      obj.extendedSide = Math.round(message.extendedSide);
    }
    if (message.locateId !== undefined && message.locateId !== "") {
      obj.locateId = message.locateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderContractLeg>, I>>(base?: I): OrderContractLeg {
    return OrderContractLeg.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderContractLeg>, I>>(object: I): OrderContractLeg {
    const message = createBaseOrderContractLeg();
    message.number = object.number ?? 0;
    message.obsoleteSize = object.obsoleteSize ?? 0;
    message.side = object.side ?? 0;
    message.contractSymbol = object.contractSymbol ?? "";
    message.size = (object.size !== undefined && object.size !== null) ? Decimal.fromPartial(object.size) : undefined;
    message.extendedSide = object.extendedSide ?? 0;
    message.locateId = object.locateId ?? "";
    return message;
  },
};

function createBaseAccountAndUserInfo(): AccountAndUserInfo {
  return {
    accountId: 0,
    userInfo: "",
    rejectDetails: "",
    rejectDetailsInternal: "",
    userId: "",
    userName: "",
    accountName: "",
    brokerageAccountNumber: "",
  };
}

export const AccountAndUserInfo = {
  encode(message: AccountAndUserInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.userInfo !== "") {
      writer.uint32(18).string(message.userInfo);
    }
    if (message.rejectDetails !== undefined && message.rejectDetails !== "") {
      writer.uint32(26).string(message.rejectDetails);
    }
    if (message.rejectDetailsInternal !== undefined && message.rejectDetailsInternal !== "") {
      writer.uint32(34).string(message.rejectDetailsInternal);
    }
    if (message.userId !== undefined && message.userId !== "") {
      writer.uint32(42).string(message.userId);
    }
    if (message.userName !== undefined && message.userName !== "") {
      writer.uint32(50).string(message.userName);
    }
    if (message.accountName !== undefined && message.accountName !== "") {
      writer.uint32(58).string(message.accountName);
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      writer.uint32(66).string(message.brokerageAccountNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountAndUserInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountAndUserInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userInfo = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.rejectDetails = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rejectDetailsInternal = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.userName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.accountName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.brokerageAccountNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountAndUserInfo {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      userInfo: isSet(object.userInfo) ? globalThis.String(object.userInfo) : "",
      rejectDetails: isSet(object.rejectDetails) ? globalThis.String(object.rejectDetails) : "",
      rejectDetailsInternal: isSet(object.rejectDetailsInternal) ? globalThis.String(object.rejectDetailsInternal) : "",
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      userName: isSet(object.userName) ? globalThis.String(object.userName) : "",
      accountName: isSet(object.accountName) ? globalThis.String(object.accountName) : "",
      brokerageAccountNumber: isSet(object.brokerageAccountNumber)
        ? globalThis.String(object.brokerageAccountNumber)
        : "",
    };
  },

  toJSON(message: AccountAndUserInfo): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.userInfo !== "") {
      obj.userInfo = message.userInfo;
    }
    if (message.rejectDetails !== undefined && message.rejectDetails !== "") {
      obj.rejectDetails = message.rejectDetails;
    }
    if (message.rejectDetailsInternal !== undefined && message.rejectDetailsInternal !== "") {
      obj.rejectDetailsInternal = message.rejectDetailsInternal;
    }
    if (message.userId !== undefined && message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.userName !== undefined && message.userName !== "") {
      obj.userName = message.userName;
    }
    if (message.accountName !== undefined && message.accountName !== "") {
      obj.accountName = message.accountName;
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      obj.brokerageAccountNumber = message.brokerageAccountNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountAndUserInfo>, I>>(base?: I): AccountAndUserInfo {
    return AccountAndUserInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountAndUserInfo>, I>>(object: I): AccountAndUserInfo {
    const message = createBaseAccountAndUserInfo();
    message.accountId = object.accountId ?? 0;
    message.userInfo = object.userInfo ?? "";
    message.rejectDetails = object.rejectDetails ?? "";
    message.rejectDetailsInternal = object.rejectDetailsInternal ?? "";
    message.userId = object.userId ?? "";
    message.userName = object.userName ?? "";
    message.accountName = object.accountName ?? "";
    message.brokerageAccountNumber = object.brokerageAccountNumber ?? "";
    return message;
  },
};

function createBaseOrderRequest(): OrderRequest {
  return {
    orderId: "",
    submittedUtcTime: 0,
    requestType: 0,
    orderType: 0,
    obsoleteSize: 0,
    limitPrice: 0,
    stopPrice: 0,
    duration: 0,
    goodThruDate: 0,
    goodThruUtcTime: 0,
    state: 0,
    linkOrderId: "",
    transactionStatus: [],
    accountAndUserInfo: [],
    displayLimitPrice: 0,
    displayStopPrice: 0,
    trailing: undefined,
    sizes: undefined,
    ticketNumber: "",
    comments: "",
    orderClasses: [],
    nativeOrderType: 0,
    activationTime: 0,
    suspensionTime: 0,
    expirationTime: 0,
    routes: [],
    clientRegulatoryAlgorithmId: "",
    effectiveRegulatoryAlgorithmId: "",
    operationalDetails: undefined,
    instructions: "",
    clientExtraValues: [],
    routeClientOrderId: "",
    obsoleteDiscretionaryOffset: 0,
    execInstruction: [],
    size: undefined,
    executionSourceCode: "",
    extendedSide: 0,
    locateId: "",
  };
}

export const OrderRequest = {
  encode(message: OrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.submittedUtcTime !== 0) {
      writer.uint32(16).sint64(message.submittedUtcTime);
    }
    if (message.requestType !== 0) {
      writer.uint32(24).uint32(message.requestType);
    }
    if (message.orderType !== undefined && message.orderType !== 0) {
      writer.uint32(32).uint32(message.orderType);
    }
    if (message.obsoleteSize !== undefined && message.obsoleteSize !== 0) {
      writer.uint32(48).uint32(message.obsoleteSize);
    }
    if (message.limitPrice !== undefined && message.limitPrice !== 0) {
      writer.uint32(57).double(message.limitPrice);
    }
    if (message.stopPrice !== undefined && message.stopPrice !== 0) {
      writer.uint32(65).double(message.stopPrice);
    }
    if (message.duration !== undefined && message.duration !== 0) {
      writer.uint32(72).uint32(message.duration);
    }
    if (message.goodThruDate !== undefined && message.goodThruDate !== 0) {
      writer.uint32(80).sint64(message.goodThruDate);
    }
    if (message.goodThruUtcTime !== undefined && message.goodThruUtcTime !== 0) {
      writer.uint32(88).sint64(message.goodThruUtcTime);
    }
    if (message.state !== 0) {
      writer.uint32(96).uint32(message.state);
    }
    if (message.linkOrderId !== undefined && message.linkOrderId !== "") {
      writer.uint32(106).string(message.linkOrderId);
    }
    for (const v of message.transactionStatus) {
      OrderTransactionStatus.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.accountAndUserInfo) {
      AccountAndUserInfo.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    if (message.displayLimitPrice !== undefined && message.displayLimitPrice !== 0) {
      writer.uint32(128).sint64(message.displayLimitPrice);
    }
    if (message.displayStopPrice !== undefined && message.displayStopPrice !== 0) {
      writer.uint32(136).sint64(message.displayStopPrice);
    }
    if (message.trailing !== undefined) {
      OrderTrailing.encode(message.trailing, writer.uint32(154).fork()).ldelim();
    }
    if (message.sizes !== undefined) {
      OrderSizes.encode(message.sizes, writer.uint32(162).fork()).ldelim();
    }
    if (message.ticketNumber !== undefined && message.ticketNumber !== "") {
      writer.uint32(170).string(message.ticketNumber);
    }
    if (message.comments !== undefined && message.comments !== "") {
      writer.uint32(178).string(message.comments);
    }
    for (const v of message.orderClasses) {
      writer.uint32(186).string(v!);
    }
    if (message.nativeOrderType !== undefined && message.nativeOrderType !== 0) {
      writer.uint32(192).uint32(message.nativeOrderType);
    }
    if (message.activationTime !== undefined && message.activationTime !== 0) {
      writer.uint32(200).sint64(message.activationTime);
    }
    if (message.suspensionTime !== undefined && message.suspensionTime !== 0) {
      writer.uint32(208).sint64(message.suspensionTime);
    }
    if (message.expirationTime !== undefined && message.expirationTime !== 0) {
      writer.uint32(216).sint64(message.expirationTime);
    }
    for (const v of message.routes) {
      OrderRoute.encode(v!, writer.uint32(226).fork()).ldelim();
    }
    if (message.clientRegulatoryAlgorithmId !== undefined && message.clientRegulatoryAlgorithmId !== "") {
      writer.uint32(234).string(message.clientRegulatoryAlgorithmId);
    }
    if (message.effectiveRegulatoryAlgorithmId !== undefined && message.effectiveRegulatoryAlgorithmId !== "") {
      writer.uint32(242).string(message.effectiveRegulatoryAlgorithmId);
    }
    if (message.operationalDetails !== undefined) {
      OperationalDetails.encode(message.operationalDetails, writer.uint32(274).fork()).ldelim();
    }
    if (message.instructions !== undefined && message.instructions !== "") {
      writer.uint32(282).string(message.instructions);
    }
    for (const v of message.clientExtraValues) {
      Tuple.encode(v!, writer.uint32(290).fork()).ldelim();
    }
    if (message.routeClientOrderId !== undefined && message.routeClientOrderId !== "") {
      writer.uint32(298).string(message.routeClientOrderId);
    }
    if (message.obsoleteDiscretionaryOffset !== undefined && message.obsoleteDiscretionaryOffset !== 0) {
      writer.uint32(304).uint32(message.obsoleteDiscretionaryOffset);
    }
    writer.uint32(314).fork();
    for (const v of message.execInstruction) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.size !== undefined) {
      Decimal.encode(message.size, writer.uint32(322).fork()).ldelim();
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      writer.uint32(330).string(message.executionSourceCode);
    }
    if (message.extendedSide !== undefined && message.extendedSide !== 0) {
      writer.uint32(336).uint32(message.extendedSide);
    }
    if (message.locateId !== undefined && message.locateId !== "") {
      writer.uint32(346).string(message.locateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.submittedUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.requestType = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.orderType = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.obsoleteSize = reader.uint32();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.limitPrice = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.stopPrice = reader.double();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.duration = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.goodThruDate = longToNumber(reader.sint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.goodThruUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.state = reader.uint32();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.linkOrderId = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.transactionStatus.push(OrderTransactionStatus.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.accountAndUserInfo.push(AccountAndUserInfo.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.displayLimitPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.displayStopPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.trailing = OrderTrailing.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.sizes = OrderSizes.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.ticketNumber = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.comments = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.orderClasses.push(reader.string());
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.nativeOrderType = reader.uint32();
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.activationTime = longToNumber(reader.sint64() as Long);
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.suspensionTime = longToNumber(reader.sint64() as Long);
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.expirationTime = longToNumber(reader.sint64() as Long);
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.routes.push(OrderRoute.decode(reader, reader.uint32()));
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.clientRegulatoryAlgorithmId = reader.string();
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.effectiveRegulatoryAlgorithmId = reader.string();
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.operationalDetails = OperationalDetails.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.instructions = reader.string();
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.clientExtraValues.push(Tuple.decode(reader, reader.uint32()));
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.routeClientOrderId = reader.string();
          continue;
        case 38:
          if (tag !== 304) {
            break;
          }

          message.obsoleteDiscretionaryOffset = reader.uint32();
          continue;
        case 39:
          if (tag === 312) {
            message.execInstruction.push(reader.uint32());

            continue;
          }

          if (tag === 314) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.execInstruction.push(reader.uint32());
            }

            continue;
          }

          break;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.size = Decimal.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.executionSourceCode = reader.string();
          continue;
        case 42:
          if (tag !== 336) {
            break;
          }

          message.extendedSide = reader.uint32();
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.locateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderRequest {
    return {
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      submittedUtcTime: isSet(object.submittedUtcTime) ? globalThis.Number(object.submittedUtcTime) : 0,
      requestType: isSet(object.requestType) ? globalThis.Number(object.requestType) : 0,
      orderType: isSet(object.orderType) ? globalThis.Number(object.orderType) : 0,
      obsoleteSize: isSet(object.obsoleteSize) ? globalThis.Number(object.obsoleteSize) : 0,
      limitPrice: isSet(object.limitPrice) ? globalThis.Number(object.limitPrice) : 0,
      stopPrice: isSet(object.stopPrice) ? globalThis.Number(object.stopPrice) : 0,
      duration: isSet(object.duration) ? globalThis.Number(object.duration) : 0,
      goodThruDate: isSet(object.goodThruDate) ? globalThis.Number(object.goodThruDate) : 0,
      goodThruUtcTime: isSet(object.goodThruUtcTime) ? globalThis.Number(object.goodThruUtcTime) : 0,
      state: isSet(object.state) ? globalThis.Number(object.state) : 0,
      linkOrderId: isSet(object.linkOrderId) ? globalThis.String(object.linkOrderId) : "",
      transactionStatus: globalThis.Array.isArray(object?.transactionStatus)
        ? object.transactionStatus.map((e: any) => OrderTransactionStatus.fromJSON(e))
        : [],
      accountAndUserInfo: globalThis.Array.isArray(object?.accountAndUserInfo)
        ? object.accountAndUserInfo.map((e: any) => AccountAndUserInfo.fromJSON(e))
        : [],
      displayLimitPrice: isSet(object.displayLimitPrice) ? globalThis.Number(object.displayLimitPrice) : 0,
      displayStopPrice: isSet(object.displayStopPrice) ? globalThis.Number(object.displayStopPrice) : 0,
      trailing: isSet(object.trailing) ? OrderTrailing.fromJSON(object.trailing) : undefined,
      sizes: isSet(object.sizes) ? OrderSizes.fromJSON(object.sizes) : undefined,
      ticketNumber: isSet(object.ticketNumber) ? globalThis.String(object.ticketNumber) : "",
      comments: isSet(object.comments) ? globalThis.String(object.comments) : "",
      orderClasses: globalThis.Array.isArray(object?.orderClasses)
        ? object.orderClasses.map((e: any) => globalThis.String(e))
        : [],
      nativeOrderType: isSet(object.nativeOrderType) ? globalThis.Number(object.nativeOrderType) : 0,
      activationTime: isSet(object.activationTime) ? globalThis.Number(object.activationTime) : 0,
      suspensionTime: isSet(object.suspensionTime) ? globalThis.Number(object.suspensionTime) : 0,
      expirationTime: isSet(object.expirationTime) ? globalThis.Number(object.expirationTime) : 0,
      routes: globalThis.Array.isArray(object?.routes) ? object.routes.map((e: any) => OrderRoute.fromJSON(e)) : [],
      clientRegulatoryAlgorithmId: isSet(object.clientRegulatoryAlgorithmId)
        ? globalThis.String(object.clientRegulatoryAlgorithmId)
        : "",
      effectiveRegulatoryAlgorithmId: isSet(object.effectiveRegulatoryAlgorithmId)
        ? globalThis.String(object.effectiveRegulatoryAlgorithmId)
        : "",
      operationalDetails: isSet(object.operationalDetails)
        ? OperationalDetails.fromJSON(object.operationalDetails)
        : undefined,
      instructions: isSet(object.instructions) ? globalThis.String(object.instructions) : "",
      clientExtraValues: globalThis.Array.isArray(object?.clientExtraValues)
        ? object.clientExtraValues.map((e: any) => Tuple.fromJSON(e))
        : [],
      routeClientOrderId: isSet(object.routeClientOrderId) ? globalThis.String(object.routeClientOrderId) : "",
      obsoleteDiscretionaryOffset: isSet(object.obsoleteDiscretionaryOffset)
        ? globalThis.Number(object.obsoleteDiscretionaryOffset)
        : 0,
      execInstruction: globalThis.Array.isArray(object?.execInstruction)
        ? object.execInstruction.map((e: any) => globalThis.Number(e))
        : [],
      size: isSet(object.size) ? Decimal.fromJSON(object.size) : undefined,
      executionSourceCode: isSet(object.executionSourceCode) ? globalThis.String(object.executionSourceCode) : "",
      extendedSide: isSet(object.extendedSide) ? globalThis.Number(object.extendedSide) : 0,
      locateId: isSet(object.locateId) ? globalThis.String(object.locateId) : "",
    };
  },

  toJSON(message: OrderRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.submittedUtcTime !== 0) {
      obj.submittedUtcTime = Math.round(message.submittedUtcTime);
    }
    if (message.requestType !== 0) {
      obj.requestType = Math.round(message.requestType);
    }
    if (message.orderType !== undefined && message.orderType !== 0) {
      obj.orderType = Math.round(message.orderType);
    }
    if (message.obsoleteSize !== undefined && message.obsoleteSize !== 0) {
      obj.obsoleteSize = Math.round(message.obsoleteSize);
    }
    if (message.limitPrice !== undefined && message.limitPrice !== 0) {
      obj.limitPrice = message.limitPrice;
    }
    if (message.stopPrice !== undefined && message.stopPrice !== 0) {
      obj.stopPrice = message.stopPrice;
    }
    if (message.duration !== undefined && message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.goodThruDate !== undefined && message.goodThruDate !== 0) {
      obj.goodThruDate = Math.round(message.goodThruDate);
    }
    if (message.goodThruUtcTime !== undefined && message.goodThruUtcTime !== 0) {
      obj.goodThruUtcTime = Math.round(message.goodThruUtcTime);
    }
    if (message.state !== 0) {
      obj.state = Math.round(message.state);
    }
    if (message.linkOrderId !== undefined && message.linkOrderId !== "") {
      obj.linkOrderId = message.linkOrderId;
    }
    if (message.transactionStatus?.length) {
      obj.transactionStatus = message.transactionStatus.map((e) => OrderTransactionStatus.toJSON(e));
    }
    if (message.accountAndUserInfo?.length) {
      obj.accountAndUserInfo = message.accountAndUserInfo.map((e) => AccountAndUserInfo.toJSON(e));
    }
    if (message.displayLimitPrice !== undefined && message.displayLimitPrice !== 0) {
      obj.displayLimitPrice = Math.round(message.displayLimitPrice);
    }
    if (message.displayStopPrice !== undefined && message.displayStopPrice !== 0) {
      obj.displayStopPrice = Math.round(message.displayStopPrice);
    }
    if (message.trailing !== undefined) {
      obj.trailing = OrderTrailing.toJSON(message.trailing);
    }
    if (message.sizes !== undefined) {
      obj.sizes = OrderSizes.toJSON(message.sizes);
    }
    if (message.ticketNumber !== undefined && message.ticketNumber !== "") {
      obj.ticketNumber = message.ticketNumber;
    }
    if (message.comments !== undefined && message.comments !== "") {
      obj.comments = message.comments;
    }
    if (message.orderClasses?.length) {
      obj.orderClasses = message.orderClasses;
    }
    if (message.nativeOrderType !== undefined && message.nativeOrderType !== 0) {
      obj.nativeOrderType = Math.round(message.nativeOrderType);
    }
    if (message.activationTime !== undefined && message.activationTime !== 0) {
      obj.activationTime = Math.round(message.activationTime);
    }
    if (message.suspensionTime !== undefined && message.suspensionTime !== 0) {
      obj.suspensionTime = Math.round(message.suspensionTime);
    }
    if (message.expirationTime !== undefined && message.expirationTime !== 0) {
      obj.expirationTime = Math.round(message.expirationTime);
    }
    if (message.routes?.length) {
      obj.routes = message.routes.map((e) => OrderRoute.toJSON(e));
    }
    if (message.clientRegulatoryAlgorithmId !== undefined && message.clientRegulatoryAlgorithmId !== "") {
      obj.clientRegulatoryAlgorithmId = message.clientRegulatoryAlgorithmId;
    }
    if (message.effectiveRegulatoryAlgorithmId !== undefined && message.effectiveRegulatoryAlgorithmId !== "") {
      obj.effectiveRegulatoryAlgorithmId = message.effectiveRegulatoryAlgorithmId;
    }
    if (message.operationalDetails !== undefined) {
      obj.operationalDetails = OperationalDetails.toJSON(message.operationalDetails);
    }
    if (message.instructions !== undefined && message.instructions !== "") {
      obj.instructions = message.instructions;
    }
    if (message.clientExtraValues?.length) {
      obj.clientExtraValues = message.clientExtraValues.map((e) => Tuple.toJSON(e));
    }
    if (message.routeClientOrderId !== undefined && message.routeClientOrderId !== "") {
      obj.routeClientOrderId = message.routeClientOrderId;
    }
    if (message.obsoleteDiscretionaryOffset !== undefined && message.obsoleteDiscretionaryOffset !== 0) {
      obj.obsoleteDiscretionaryOffset = Math.round(message.obsoleteDiscretionaryOffset);
    }
    if (message.execInstruction?.length) {
      obj.execInstruction = message.execInstruction.map((e) => Math.round(e));
    }
    if (message.size !== undefined) {
      obj.size = Decimal.toJSON(message.size);
    }
    if (message.executionSourceCode !== undefined && message.executionSourceCode !== "") {
      obj.executionSourceCode = message.executionSourceCode;
    }
    if (message.extendedSide !== undefined && message.extendedSide !== 0) {
      obj.extendedSide = Math.round(message.extendedSide);
    }
    if (message.locateId !== undefined && message.locateId !== "") {
      obj.locateId = message.locateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderRequest>, I>>(base?: I): OrderRequest {
    return OrderRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderRequest>, I>>(object: I): OrderRequest {
    const message = createBaseOrderRequest();
    message.orderId = object.orderId ?? "";
    message.submittedUtcTime = object.submittedUtcTime ?? 0;
    message.requestType = object.requestType ?? 0;
    message.orderType = object.orderType ?? 0;
    message.obsoleteSize = object.obsoleteSize ?? 0;
    message.limitPrice = object.limitPrice ?? 0;
    message.stopPrice = object.stopPrice ?? 0;
    message.duration = object.duration ?? 0;
    message.goodThruDate = object.goodThruDate ?? 0;
    message.goodThruUtcTime = object.goodThruUtcTime ?? 0;
    message.state = object.state ?? 0;
    message.linkOrderId = object.linkOrderId ?? "";
    message.transactionStatus = object.transactionStatus?.map((e) => OrderTransactionStatus.fromPartial(e)) || [];
    message.accountAndUserInfo = object.accountAndUserInfo?.map((e) => AccountAndUserInfo.fromPartial(e)) || [];
    message.displayLimitPrice = object.displayLimitPrice ?? 0;
    message.displayStopPrice = object.displayStopPrice ?? 0;
    message.trailing = (object.trailing !== undefined && object.trailing !== null)
      ? OrderTrailing.fromPartial(object.trailing)
      : undefined;
    message.sizes = (object.sizes !== undefined && object.sizes !== null)
      ? OrderSizes.fromPartial(object.sizes)
      : undefined;
    message.ticketNumber = object.ticketNumber ?? "";
    message.comments = object.comments ?? "";
    message.orderClasses = object.orderClasses?.map((e) => e) || [];
    message.nativeOrderType = object.nativeOrderType ?? 0;
    message.activationTime = object.activationTime ?? 0;
    message.suspensionTime = object.suspensionTime ?? 0;
    message.expirationTime = object.expirationTime ?? 0;
    message.routes = object.routes?.map((e) => OrderRoute.fromPartial(e)) || [];
    message.clientRegulatoryAlgorithmId = object.clientRegulatoryAlgorithmId ?? "";
    message.effectiveRegulatoryAlgorithmId = object.effectiveRegulatoryAlgorithmId ?? "";
    message.operationalDetails = (object.operationalDetails !== undefined && object.operationalDetails !== null)
      ? OperationalDetails.fromPartial(object.operationalDetails)
      : undefined;
    message.instructions = object.instructions ?? "";
    message.clientExtraValues = object.clientExtraValues?.map((e) => Tuple.fromPartial(e)) || [];
    message.routeClientOrderId = object.routeClientOrderId ?? "";
    message.obsoleteDiscretionaryOffset = object.obsoleteDiscretionaryOffset ?? 0;
    message.execInstruction = object.execInstruction?.map((e) => e) || [];
    message.size = (object.size !== undefined && object.size !== null) ? Decimal.fromPartial(object.size) : undefined;
    message.executionSourceCode = object.executionSourceCode ?? "";
    message.extendedSide = object.extendedSide ?? 0;
    message.locateId = object.locateId ?? "";
    return message;
  },
};

function createBaseOrderSizes(): OrderSizes {
  return {
    obsoleteTriggerSize: 0,
    obsoleteVisibleSize: 0,
    obsoleteMinVisibleSize: 0,
    obsoleteRemainingSize: 0,
    triggerSize: undefined,
    visibleSize: undefined,
    minVisibleSize: undefined,
    remainingSize: undefined,
  };
}

export const OrderSizes = {
  encode(message: OrderSizes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.obsoleteTriggerSize !== undefined && message.obsoleteTriggerSize !== 0) {
      writer.uint32(8).uint32(message.obsoleteTriggerSize);
    }
    if (message.obsoleteVisibleSize !== undefined && message.obsoleteVisibleSize !== 0) {
      writer.uint32(16).uint32(message.obsoleteVisibleSize);
    }
    if (message.obsoleteMinVisibleSize !== undefined && message.obsoleteMinVisibleSize !== 0) {
      writer.uint32(24).uint32(message.obsoleteMinVisibleSize);
    }
    if (message.obsoleteRemainingSize !== undefined && message.obsoleteRemainingSize !== 0) {
      writer.uint32(32).uint32(message.obsoleteRemainingSize);
    }
    if (message.triggerSize !== undefined) {
      Decimal.encode(message.triggerSize, writer.uint32(42).fork()).ldelim();
    }
    if (message.visibleSize !== undefined) {
      Decimal.encode(message.visibleSize, writer.uint32(50).fork()).ldelim();
    }
    if (message.minVisibleSize !== undefined) {
      Decimal.encode(message.minVisibleSize, writer.uint32(58).fork()).ldelim();
    }
    if (message.remainingSize !== undefined) {
      Decimal.encode(message.remainingSize, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderSizes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderSizes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.obsoleteTriggerSize = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.obsoleteVisibleSize = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.obsoleteMinVisibleSize = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.obsoleteRemainingSize = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.triggerSize = Decimal.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.visibleSize = Decimal.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.minVisibleSize = Decimal.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.remainingSize = Decimal.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderSizes {
    return {
      obsoleteTriggerSize: isSet(object.obsoleteTriggerSize) ? globalThis.Number(object.obsoleteTriggerSize) : 0,
      obsoleteVisibleSize: isSet(object.obsoleteVisibleSize) ? globalThis.Number(object.obsoleteVisibleSize) : 0,
      obsoleteMinVisibleSize: isSet(object.obsoleteMinVisibleSize)
        ? globalThis.Number(object.obsoleteMinVisibleSize)
        : 0,
      obsoleteRemainingSize: isSet(object.obsoleteRemainingSize) ? globalThis.Number(object.obsoleteRemainingSize) : 0,
      triggerSize: isSet(object.triggerSize) ? Decimal.fromJSON(object.triggerSize) : undefined,
      visibleSize: isSet(object.visibleSize) ? Decimal.fromJSON(object.visibleSize) : undefined,
      minVisibleSize: isSet(object.minVisibleSize) ? Decimal.fromJSON(object.minVisibleSize) : undefined,
      remainingSize: isSet(object.remainingSize) ? Decimal.fromJSON(object.remainingSize) : undefined,
    };
  },

  toJSON(message: OrderSizes): unknown {
    const obj: any = {};
    if (message.obsoleteTriggerSize !== undefined && message.obsoleteTriggerSize !== 0) {
      obj.obsoleteTriggerSize = Math.round(message.obsoleteTriggerSize);
    }
    if (message.obsoleteVisibleSize !== undefined && message.obsoleteVisibleSize !== 0) {
      obj.obsoleteVisibleSize = Math.round(message.obsoleteVisibleSize);
    }
    if (message.obsoleteMinVisibleSize !== undefined && message.obsoleteMinVisibleSize !== 0) {
      obj.obsoleteMinVisibleSize = Math.round(message.obsoleteMinVisibleSize);
    }
    if (message.obsoleteRemainingSize !== undefined && message.obsoleteRemainingSize !== 0) {
      obj.obsoleteRemainingSize = Math.round(message.obsoleteRemainingSize);
    }
    if (message.triggerSize !== undefined) {
      obj.triggerSize = Decimal.toJSON(message.triggerSize);
    }
    if (message.visibleSize !== undefined) {
      obj.visibleSize = Decimal.toJSON(message.visibleSize);
    }
    if (message.minVisibleSize !== undefined) {
      obj.minVisibleSize = Decimal.toJSON(message.minVisibleSize);
    }
    if (message.remainingSize !== undefined) {
      obj.remainingSize = Decimal.toJSON(message.remainingSize);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderSizes>, I>>(base?: I): OrderSizes {
    return OrderSizes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderSizes>, I>>(object: I): OrderSizes {
    const message = createBaseOrderSizes();
    message.obsoleteTriggerSize = object.obsoleteTriggerSize ?? 0;
    message.obsoleteVisibleSize = object.obsoleteVisibleSize ?? 0;
    message.obsoleteMinVisibleSize = object.obsoleteMinVisibleSize ?? 0;
    message.obsoleteRemainingSize = object.obsoleteRemainingSize ?? 0;
    message.triggerSize = (object.triggerSize !== undefined && object.triggerSize !== null)
      ? Decimal.fromPartial(object.triggerSize)
      : undefined;
    message.visibleSize = (object.visibleSize !== undefined && object.visibleSize !== null)
      ? Decimal.fromPartial(object.visibleSize)
      : undefined;
    message.minVisibleSize = (object.minVisibleSize !== undefined && object.minVisibleSize !== null)
      ? Decimal.fromPartial(object.minVisibleSize)
      : undefined;
    message.remainingSize = (object.remainingSize !== undefined && object.remainingSize !== null)
      ? Decimal.fromPartial(object.remainingSize)
      : undefined;
    return message;
  },
};

function createBaseOrderTrailing(): OrderTrailing {
  return {
    trailType: 0,
    offset: 0,
    stopPrice: 0,
    limitPrice: 0,
    displayOffset: 0,
    displayStopPrice: 0,
    displayLimitPrice: 0,
    updateTime: 0,
  };
}

export const OrderTrailing = {
  encode(message: OrderTrailing, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.trailType !== undefined && message.trailType !== 0) {
      writer.uint32(8).uint32(message.trailType);
    }
    if (message.offset !== undefined && message.offset !== 0) {
      writer.uint32(17).double(message.offset);
    }
    if (message.stopPrice !== undefined && message.stopPrice !== 0) {
      writer.uint32(25).double(message.stopPrice);
    }
    if (message.limitPrice !== undefined && message.limitPrice !== 0) {
      writer.uint32(33).double(message.limitPrice);
    }
    if (message.displayOffset !== undefined && message.displayOffset !== 0) {
      writer.uint32(40).sint64(message.displayOffset);
    }
    if (message.displayStopPrice !== undefined && message.displayStopPrice !== 0) {
      writer.uint32(48).sint64(message.displayStopPrice);
    }
    if (message.displayLimitPrice !== undefined && message.displayLimitPrice !== 0) {
      writer.uint32(56).sint64(message.displayLimitPrice);
    }
    if (message.updateTime !== undefined && message.updateTime !== 0) {
      writer.uint32(64).sint64(message.updateTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderTrailing {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderTrailing();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.trailType = reader.uint32();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.offset = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.stopPrice = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.limitPrice = reader.double();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.displayOffset = longToNumber(reader.sint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.displayStopPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.displayLimitPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.updateTime = longToNumber(reader.sint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderTrailing {
    return {
      trailType: isSet(object.trailType) ? globalThis.Number(object.trailType) : 0,
      offset: isSet(object.offset) ? globalThis.Number(object.offset) : 0,
      stopPrice: isSet(object.stopPrice) ? globalThis.Number(object.stopPrice) : 0,
      limitPrice: isSet(object.limitPrice) ? globalThis.Number(object.limitPrice) : 0,
      displayOffset: isSet(object.displayOffset) ? globalThis.Number(object.displayOffset) : 0,
      displayStopPrice: isSet(object.displayStopPrice) ? globalThis.Number(object.displayStopPrice) : 0,
      displayLimitPrice: isSet(object.displayLimitPrice) ? globalThis.Number(object.displayLimitPrice) : 0,
      updateTime: isSet(object.updateTime) ? globalThis.Number(object.updateTime) : 0,
    };
  },

  toJSON(message: OrderTrailing): unknown {
    const obj: any = {};
    if (message.trailType !== undefined && message.trailType !== 0) {
      obj.trailType = Math.round(message.trailType);
    }
    if (message.offset !== undefined && message.offset !== 0) {
      obj.offset = message.offset;
    }
    if (message.stopPrice !== undefined && message.stopPrice !== 0) {
      obj.stopPrice = message.stopPrice;
    }
    if (message.limitPrice !== undefined && message.limitPrice !== 0) {
      obj.limitPrice = message.limitPrice;
    }
    if (message.displayOffset !== undefined && message.displayOffset !== 0) {
      obj.displayOffset = Math.round(message.displayOffset);
    }
    if (message.displayStopPrice !== undefined && message.displayStopPrice !== 0) {
      obj.displayStopPrice = Math.round(message.displayStopPrice);
    }
    if (message.displayLimitPrice !== undefined && message.displayLimitPrice !== 0) {
      obj.displayLimitPrice = Math.round(message.displayLimitPrice);
    }
    if (message.updateTime !== undefined && message.updateTime !== 0) {
      obj.updateTime = Math.round(message.updateTime);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderTrailing>, I>>(base?: I): OrderTrailing {
    return OrderTrailing.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderTrailing>, I>>(object: I): OrderTrailing {
    const message = createBaseOrderTrailing();
    message.trailType = object.trailType ?? 0;
    message.offset = object.offset ?? 0;
    message.stopPrice = object.stopPrice ?? 0;
    message.limitPrice = object.limitPrice ?? 0;
    message.displayOffset = object.displayOffset ?? 0;
    message.displayStopPrice = object.displayStopPrice ?? 0;
    message.displayLimitPrice = object.displayLimitPrice ?? 0;
    message.updateTime = object.updateTime ?? 0;
    return message;
  },
};

function createBaseOperationalDetails(): OperationalDetails {
  return {
    clientOrderId: "",
    clientGuid: "",
    clientSystemId: "",
    clientIp: "",
    clientVersion: "",
    tradeLocationId: "",
    productId: "",
    clientAppTypeName: "",
    platformName: "",
    privateLabelName: "",
    operatorId: "",
    senderOperatorId: "",
    senderLocationId: "",
    originatorLocationId: "",
  };
}

export const OperationalDetails = {
  encode(message: OperationalDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientOrderId !== undefined && message.clientOrderId !== "") {
      writer.uint32(10).string(message.clientOrderId);
    }
    if (message.clientGuid !== undefined && message.clientGuid !== "") {
      writer.uint32(18).string(message.clientGuid);
    }
    if (message.clientSystemId !== undefined && message.clientSystemId !== "") {
      writer.uint32(26).string(message.clientSystemId);
    }
    if (message.clientIp !== undefined && message.clientIp !== "") {
      writer.uint32(34).string(message.clientIp);
    }
    if (message.clientVersion !== undefined && message.clientVersion !== "") {
      writer.uint32(42).string(message.clientVersion);
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      writer.uint32(50).string(message.tradeLocationId);
    }
    if (message.productId !== undefined && message.productId !== "") {
      writer.uint32(58).string(message.productId);
    }
    if (message.clientAppTypeName !== undefined && message.clientAppTypeName !== "") {
      writer.uint32(66).string(message.clientAppTypeName);
    }
    if (message.platformName !== undefined && message.platformName !== "") {
      writer.uint32(74).string(message.platformName);
    }
    if (message.privateLabelName !== undefined && message.privateLabelName !== "") {
      writer.uint32(82).string(message.privateLabelName);
    }
    if (message.operatorId !== undefined && message.operatorId !== "") {
      writer.uint32(90).string(message.operatorId);
    }
    if (message.senderOperatorId !== undefined && message.senderOperatorId !== "") {
      writer.uint32(98).string(message.senderOperatorId);
    }
    if (message.senderLocationId !== undefined && message.senderLocationId !== "") {
      writer.uint32(106).string(message.senderLocationId);
    }
    if (message.originatorLocationId !== undefined && message.originatorLocationId !== "") {
      writer.uint32(114).string(message.originatorLocationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OperationalDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOperationalDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientOrderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientGuid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientSystemId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clientIp = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.clientVersion = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tradeLocationId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.productId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.clientAppTypeName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.platformName = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.privateLabelName = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.operatorId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.senderOperatorId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.senderLocationId = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.originatorLocationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OperationalDetails {
    return {
      clientOrderId: isSet(object.clientOrderId) ? globalThis.String(object.clientOrderId) : "",
      clientGuid: isSet(object.clientGuid) ? globalThis.String(object.clientGuid) : "",
      clientSystemId: isSet(object.clientSystemId) ? globalThis.String(object.clientSystemId) : "",
      clientIp: isSet(object.clientIp) ? globalThis.String(object.clientIp) : "",
      clientVersion: isSet(object.clientVersion) ? globalThis.String(object.clientVersion) : "",
      tradeLocationId: isSet(object.tradeLocationId) ? globalThis.String(object.tradeLocationId) : "",
      productId: isSet(object.productId) ? globalThis.String(object.productId) : "",
      clientAppTypeName: isSet(object.clientAppTypeName) ? globalThis.String(object.clientAppTypeName) : "",
      platformName: isSet(object.platformName) ? globalThis.String(object.platformName) : "",
      privateLabelName: isSet(object.privateLabelName) ? globalThis.String(object.privateLabelName) : "",
      operatorId: isSet(object.operatorId) ? globalThis.String(object.operatorId) : "",
      senderOperatorId: isSet(object.senderOperatorId) ? globalThis.String(object.senderOperatorId) : "",
      senderLocationId: isSet(object.senderLocationId) ? globalThis.String(object.senderLocationId) : "",
      originatorLocationId: isSet(object.originatorLocationId) ? globalThis.String(object.originatorLocationId) : "",
    };
  },

  toJSON(message: OperationalDetails): unknown {
    const obj: any = {};
    if (message.clientOrderId !== undefined && message.clientOrderId !== "") {
      obj.clientOrderId = message.clientOrderId;
    }
    if (message.clientGuid !== undefined && message.clientGuid !== "") {
      obj.clientGuid = message.clientGuid;
    }
    if (message.clientSystemId !== undefined && message.clientSystemId !== "") {
      obj.clientSystemId = message.clientSystemId;
    }
    if (message.clientIp !== undefined && message.clientIp !== "") {
      obj.clientIp = message.clientIp;
    }
    if (message.clientVersion !== undefined && message.clientVersion !== "") {
      obj.clientVersion = message.clientVersion;
    }
    if (message.tradeLocationId !== undefined && message.tradeLocationId !== "") {
      obj.tradeLocationId = message.tradeLocationId;
    }
    if (message.productId !== undefined && message.productId !== "") {
      obj.productId = message.productId;
    }
    if (message.clientAppTypeName !== undefined && message.clientAppTypeName !== "") {
      obj.clientAppTypeName = message.clientAppTypeName;
    }
    if (message.platformName !== undefined && message.platformName !== "") {
      obj.platformName = message.platformName;
    }
    if (message.privateLabelName !== undefined && message.privateLabelName !== "") {
      obj.privateLabelName = message.privateLabelName;
    }
    if (message.operatorId !== undefined && message.operatorId !== "") {
      obj.operatorId = message.operatorId;
    }
    if (message.senderOperatorId !== undefined && message.senderOperatorId !== "") {
      obj.senderOperatorId = message.senderOperatorId;
    }
    if (message.senderLocationId !== undefined && message.senderLocationId !== "") {
      obj.senderLocationId = message.senderLocationId;
    }
    if (message.originatorLocationId !== undefined && message.originatorLocationId !== "") {
      obj.originatorLocationId = message.originatorLocationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OperationalDetails>, I>>(base?: I): OperationalDetails {
    return OperationalDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OperationalDetails>, I>>(object: I): OperationalDetails {
    const message = createBaseOperationalDetails();
    message.clientOrderId = object.clientOrderId ?? "";
    message.clientGuid = object.clientGuid ?? "";
    message.clientSystemId = object.clientSystemId ?? "";
    message.clientIp = object.clientIp ?? "";
    message.clientVersion = object.clientVersion ?? "";
    message.tradeLocationId = object.tradeLocationId ?? "";
    message.productId = object.productId ?? "";
    message.clientAppTypeName = object.clientAppTypeName ?? "";
    message.platformName = object.platformName ?? "";
    message.privateLabelName = object.privateLabelName ?? "";
    message.operatorId = object.operatorId ?? "";
    message.senderOperatorId = object.senderOperatorId ?? "";
    message.senderLocationId = object.senderLocationId ?? "";
    message.originatorLocationId = object.originatorLocationId ?? "";
    return message;
  },
};

function createBaseOrderTransactionStatus(): OrderTransactionStatus {
  return {
    status: 0,
    transId: 0,
    transUtcTime: 0,
    orderState: 0,
    orderChainStatus: 0,
    textMessage: "",
    obsoleteNewDiscretionaryOffset: 0,
    obsoletePrevDiscretionaryOffset: 0,
  };
}

export const OrderTransactionStatus = {
  encode(message: OrderTransactionStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).uint32(message.status);
    }
    if (message.transId !== 0) {
      writer.uint32(16).uint64(message.transId);
    }
    if (message.transUtcTime !== 0) {
      writer.uint32(24).sint64(message.transUtcTime);
    }
    if (message.orderState !== 0) {
      writer.uint32(32).uint32(message.orderState);
    }
    if (message.orderChainStatus !== 0) {
      writer.uint32(40).uint32(message.orderChainStatus);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      writer.uint32(50).string(message.textMessage);
    }
    if (message.obsoleteNewDiscretionaryOffset !== undefined && message.obsoleteNewDiscretionaryOffset !== 0) {
      writer.uint32(56).uint32(message.obsoleteNewDiscretionaryOffset);
    }
    if (message.obsoletePrevDiscretionaryOffset !== undefined && message.obsoletePrevDiscretionaryOffset !== 0) {
      writer.uint32(64).uint32(message.obsoletePrevDiscretionaryOffset);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderTransactionStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderTransactionStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.transId = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.transUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.orderState = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.orderChainStatus = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.textMessage = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.obsoleteNewDiscretionaryOffset = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.obsoletePrevDiscretionaryOffset = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderTransactionStatus {
    return {
      status: isSet(object.status) ? globalThis.Number(object.status) : 0,
      transId: isSet(object.transId) ? globalThis.Number(object.transId) : 0,
      transUtcTime: isSet(object.transUtcTime) ? globalThis.Number(object.transUtcTime) : 0,
      orderState: isSet(object.orderState) ? globalThis.Number(object.orderState) : 0,
      orderChainStatus: isSet(object.orderChainStatus) ? globalThis.Number(object.orderChainStatus) : 0,
      textMessage: isSet(object.textMessage) ? globalThis.String(object.textMessage) : "",
      obsoleteNewDiscretionaryOffset: isSet(object.obsoleteNewDiscretionaryOffset)
        ? globalThis.Number(object.obsoleteNewDiscretionaryOffset)
        : 0,
      obsoletePrevDiscretionaryOffset: isSet(object.obsoletePrevDiscretionaryOffset)
        ? globalThis.Number(object.obsoletePrevDiscretionaryOffset)
        : 0,
    };
  },

  toJSON(message: OrderTransactionStatus): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = Math.round(message.status);
    }
    if (message.transId !== 0) {
      obj.transId = Math.round(message.transId);
    }
    if (message.transUtcTime !== 0) {
      obj.transUtcTime = Math.round(message.transUtcTime);
    }
    if (message.orderState !== 0) {
      obj.orderState = Math.round(message.orderState);
    }
    if (message.orderChainStatus !== 0) {
      obj.orderChainStatus = Math.round(message.orderChainStatus);
    }
    if (message.textMessage !== undefined && message.textMessage !== "") {
      obj.textMessage = message.textMessage;
    }
    if (message.obsoleteNewDiscretionaryOffset !== undefined && message.obsoleteNewDiscretionaryOffset !== 0) {
      obj.obsoleteNewDiscretionaryOffset = Math.round(message.obsoleteNewDiscretionaryOffset);
    }
    if (message.obsoletePrevDiscretionaryOffset !== undefined && message.obsoletePrevDiscretionaryOffset !== 0) {
      obj.obsoletePrevDiscretionaryOffset = Math.round(message.obsoletePrevDiscretionaryOffset);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderTransactionStatus>, I>>(base?: I): OrderTransactionStatus {
    return OrderTransactionStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderTransactionStatus>, I>>(object: I): OrderTransactionStatus {
    const message = createBaseOrderTransactionStatus();
    message.status = object.status ?? 0;
    message.transId = object.transId ?? 0;
    message.transUtcTime = object.transUtcTime ?? 0;
    message.orderState = object.orderState ?? 0;
    message.orderChainStatus = object.orderChainStatus ?? 0;
    message.textMessage = object.textMessage ?? "";
    message.obsoleteNewDiscretionaryOffset = object.obsoleteNewDiscretionaryOffset ?? 0;
    message.obsoletePrevDiscretionaryOffset = object.obsoletePrevDiscretionaryOffset ?? 0;
    return message;
  },
};

function createBaseOrderFill(): OrderFill {
  return {
    id: 0,
    execId: "",
    obsoleteQuantity: 0,
    price: 0,
    fillUtcTime: 0,
    isAggressive: false,
    legFills: [],
    orderId: "",
    fillStatus: 0,
    fillCareOrderRequestId: "",
    displayPrice: 0,
    openCloseType: 0,
    speculationType: 0,
    commission: 0,
    commissionCurrency: "",
    quantity: undefined,
  };
}

export const OrderFill = {
  encode(message: OrderFill, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== 0) {
      writer.uint32(8).sint64(message.id);
    }
    if (message.execId !== undefined && message.execId !== "") {
      writer.uint32(18).string(message.execId);
    }
    if (message.obsoleteQuantity !== undefined && message.obsoleteQuantity !== 0) {
      writer.uint32(24).uint32(message.obsoleteQuantity);
    }
    if (message.price !== undefined && message.price !== 0) {
      writer.uint32(33).double(message.price);
    }
    if (message.fillUtcTime !== undefined && message.fillUtcTime !== 0) {
      writer.uint32(40).sint64(message.fillUtcTime);
    }
    if (message.isAggressive !== undefined && message.isAggressive !== false) {
      writer.uint32(48).bool(message.isAggressive);
    }
    for (const v of message.legFills) {
      LegFill.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.orderId !== undefined && message.orderId !== "") {
      writer.uint32(66).string(message.orderId);
    }
    if (message.fillStatus !== undefined && message.fillStatus !== 0) {
      writer.uint32(72).uint32(message.fillStatus);
    }
    if (message.fillCareOrderRequestId !== undefined && message.fillCareOrderRequestId !== "") {
      writer.uint32(82).string(message.fillCareOrderRequestId);
    }
    if (message.displayPrice !== undefined && message.displayPrice !== 0) {
      writer.uint32(88).sint64(message.displayPrice);
    }
    if (message.openCloseType !== undefined && message.openCloseType !== 0) {
      writer.uint32(96).uint32(message.openCloseType);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(104).uint32(message.speculationType);
    }
    if (message.commission !== undefined && message.commission !== 0) {
      writer.uint32(113).double(message.commission);
    }
    if (message.commissionCurrency !== undefined && message.commissionCurrency !== "") {
      writer.uint32(122).string(message.commissionCurrency);
    }
    if (message.quantity !== undefined) {
      Decimal.encode(message.quantity, writer.uint32(130).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderFill {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderFill();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.sint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.execId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.obsoleteQuantity = reader.uint32();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.price = reader.double();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.fillUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isAggressive = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.legFills.push(LegFill.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.fillStatus = reader.uint32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.fillCareOrderRequestId = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.displayPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.openCloseType = reader.uint32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
        case 14:
          if (tag !== 113) {
            break;
          }

          message.commission = reader.double();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.commissionCurrency = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.quantity = Decimal.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderFill {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      execId: isSet(object.execId) ? globalThis.String(object.execId) : "",
      obsoleteQuantity: isSet(object.obsoleteQuantity) ? globalThis.Number(object.obsoleteQuantity) : 0,
      price: isSet(object.price) ? globalThis.Number(object.price) : 0,
      fillUtcTime: isSet(object.fillUtcTime) ? globalThis.Number(object.fillUtcTime) : 0,
      isAggressive: isSet(object.isAggressive) ? globalThis.Boolean(object.isAggressive) : false,
      legFills: globalThis.Array.isArray(object?.legFills) ? object.legFills.map((e: any) => LegFill.fromJSON(e)) : [],
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      fillStatus: isSet(object.fillStatus) ? globalThis.Number(object.fillStatus) : 0,
      fillCareOrderRequestId: isSet(object.fillCareOrderRequestId)
        ? globalThis.String(object.fillCareOrderRequestId)
        : "",
      displayPrice: isSet(object.displayPrice) ? globalThis.Number(object.displayPrice) : 0,
      openCloseType: isSet(object.openCloseType) ? globalThis.Number(object.openCloseType) : 0,
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
      commission: isSet(object.commission) ? globalThis.Number(object.commission) : 0,
      commissionCurrency: isSet(object.commissionCurrency) ? globalThis.String(object.commissionCurrency) : "",
      quantity: isSet(object.quantity) ? Decimal.fromJSON(object.quantity) : undefined,
    };
  },

  toJSON(message: OrderFill): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.execId !== undefined && message.execId !== "") {
      obj.execId = message.execId;
    }
    if (message.obsoleteQuantity !== undefined && message.obsoleteQuantity !== 0) {
      obj.obsoleteQuantity = Math.round(message.obsoleteQuantity);
    }
    if (message.price !== undefined && message.price !== 0) {
      obj.price = message.price;
    }
    if (message.fillUtcTime !== undefined && message.fillUtcTime !== 0) {
      obj.fillUtcTime = Math.round(message.fillUtcTime);
    }
    if (message.isAggressive !== undefined && message.isAggressive !== false) {
      obj.isAggressive = message.isAggressive;
    }
    if (message.legFills?.length) {
      obj.legFills = message.legFills.map((e) => LegFill.toJSON(e));
    }
    if (message.orderId !== undefined && message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.fillStatus !== undefined && message.fillStatus !== 0) {
      obj.fillStatus = Math.round(message.fillStatus);
    }
    if (message.fillCareOrderRequestId !== undefined && message.fillCareOrderRequestId !== "") {
      obj.fillCareOrderRequestId = message.fillCareOrderRequestId;
    }
    if (message.displayPrice !== undefined && message.displayPrice !== 0) {
      obj.displayPrice = Math.round(message.displayPrice);
    }
    if (message.openCloseType !== undefined && message.openCloseType !== 0) {
      obj.openCloseType = Math.round(message.openCloseType);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    if (message.commission !== undefined && message.commission !== 0) {
      obj.commission = message.commission;
    }
    if (message.commissionCurrency !== undefined && message.commissionCurrency !== "") {
      obj.commissionCurrency = message.commissionCurrency;
    }
    if (message.quantity !== undefined) {
      obj.quantity = Decimal.toJSON(message.quantity);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderFill>, I>>(base?: I): OrderFill {
    return OrderFill.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderFill>, I>>(object: I): OrderFill {
    const message = createBaseOrderFill();
    message.id = object.id ?? 0;
    message.execId = object.execId ?? "";
    message.obsoleteQuantity = object.obsoleteQuantity ?? 0;
    message.price = object.price ?? 0;
    message.fillUtcTime = object.fillUtcTime ?? 0;
    message.isAggressive = object.isAggressive ?? false;
    message.legFills = object.legFills?.map((e) => LegFill.fromPartial(e)) || [];
    message.orderId = object.orderId ?? "";
    message.fillStatus = object.fillStatus ?? 0;
    message.fillCareOrderRequestId = object.fillCareOrderRequestId ?? "";
    message.displayPrice = object.displayPrice ?? 0;
    message.openCloseType = object.openCloseType ?? 0;
    message.speculationType = object.speculationType ?? 0;
    message.commission = object.commission ?? 0;
    message.commissionCurrency = object.commissionCurrency ?? "";
    message.quantity = (object.quantity !== undefined && object.quantity !== null)
      ? Decimal.fromPartial(object.quantity)
      : undefined;
    return message;
  },
};

function createBaseLegFill(): LegFill {
  return {
    id: 0,
    execId: "",
    contractSymbol: "",
    obsoleteQuantity: 0,
    side: 0,
    price: 0,
    fillUtcTime: 0,
    fillStatus: 0,
    displayPrice: 0,
    openCloseType: 0,
    speculationType: 0,
    isAggressive: false,
    quantity: undefined,
    extendedSide: 0,
    locateId: "",
    fillExtraValues: [],
    legNumber: "",
  };
}

export const LegFill = {
  encode(message: LegFill, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== 0) {
      writer.uint32(8).sint64(message.id);
    }
    if (message.execId !== undefined && message.execId !== "") {
      writer.uint32(18).string(message.execId);
    }
    if (message.contractSymbol !== undefined && message.contractSymbol !== "") {
      writer.uint32(26).string(message.contractSymbol);
    }
    if (message.obsoleteQuantity !== undefined && message.obsoleteQuantity !== 0) {
      writer.uint32(32).uint32(message.obsoleteQuantity);
    }
    if (message.side !== undefined && message.side !== 0) {
      writer.uint32(40).uint32(message.side);
    }
    if (message.price !== undefined && message.price !== 0) {
      writer.uint32(49).double(message.price);
    }
    if (message.fillUtcTime !== undefined && message.fillUtcTime !== 0) {
      writer.uint32(56).sint64(message.fillUtcTime);
    }
    if (message.fillStatus !== undefined && message.fillStatus !== 0) {
      writer.uint32(64).uint32(message.fillStatus);
    }
    if (message.displayPrice !== undefined && message.displayPrice !== 0) {
      writer.uint32(72).sint64(message.displayPrice);
    }
    if (message.openCloseType !== undefined && message.openCloseType !== 0) {
      writer.uint32(80).uint32(message.openCloseType);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(88).uint32(message.speculationType);
    }
    if (message.isAggressive !== undefined && message.isAggressive !== false) {
      writer.uint32(96).bool(message.isAggressive);
    }
    if (message.quantity !== undefined) {
      Decimal.encode(message.quantity, writer.uint32(106).fork()).ldelim();
    }
    if (message.extendedSide !== undefined && message.extendedSide !== 0) {
      writer.uint32(112).uint32(message.extendedSide);
    }
    if (message.locateId !== undefined && message.locateId !== "") {
      writer.uint32(122).string(message.locateId);
    }
    for (const v of message.fillExtraValues) {
      Tuple.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    if (message.legNumber !== undefined && message.legNumber !== "") {
      writer.uint32(138).string(message.legNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LegFill {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLegFill();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.sint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.execId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.contractSymbol = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.obsoleteQuantity = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.price = reader.double();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.fillUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.fillStatus = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.displayPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.openCloseType = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.isAggressive = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.quantity = Decimal.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.extendedSide = reader.uint32();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.locateId = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.fillExtraValues.push(Tuple.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.legNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LegFill {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      execId: isSet(object.execId) ? globalThis.String(object.execId) : "",
      contractSymbol: isSet(object.contractSymbol) ? globalThis.String(object.contractSymbol) : "",
      obsoleteQuantity: isSet(object.obsoleteQuantity) ? globalThis.Number(object.obsoleteQuantity) : 0,
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      price: isSet(object.price) ? globalThis.Number(object.price) : 0,
      fillUtcTime: isSet(object.fillUtcTime) ? globalThis.Number(object.fillUtcTime) : 0,
      fillStatus: isSet(object.fillStatus) ? globalThis.Number(object.fillStatus) : 0,
      displayPrice: isSet(object.displayPrice) ? globalThis.Number(object.displayPrice) : 0,
      openCloseType: isSet(object.openCloseType) ? globalThis.Number(object.openCloseType) : 0,
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
      isAggressive: isSet(object.isAggressive) ? globalThis.Boolean(object.isAggressive) : false,
      quantity: isSet(object.quantity) ? Decimal.fromJSON(object.quantity) : undefined,
      extendedSide: isSet(object.extendedSide) ? globalThis.Number(object.extendedSide) : 0,
      locateId: isSet(object.locateId) ? globalThis.String(object.locateId) : "",
      fillExtraValues: globalThis.Array.isArray(object?.fillExtraValues)
        ? object.fillExtraValues.map((e: any) => Tuple.fromJSON(e))
        : [],
      legNumber: isSet(object.legNumber) ? globalThis.String(object.legNumber) : "",
    };
  },

  toJSON(message: LegFill): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.execId !== undefined && message.execId !== "") {
      obj.execId = message.execId;
    }
    if (message.contractSymbol !== undefined && message.contractSymbol !== "") {
      obj.contractSymbol = message.contractSymbol;
    }
    if (message.obsoleteQuantity !== undefined && message.obsoleteQuantity !== 0) {
      obj.obsoleteQuantity = Math.round(message.obsoleteQuantity);
    }
    if (message.side !== undefined && message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.price !== undefined && message.price !== 0) {
      obj.price = message.price;
    }
    if (message.fillUtcTime !== undefined && message.fillUtcTime !== 0) {
      obj.fillUtcTime = Math.round(message.fillUtcTime);
    }
    if (message.fillStatus !== undefined && message.fillStatus !== 0) {
      obj.fillStatus = Math.round(message.fillStatus);
    }
    if (message.displayPrice !== undefined && message.displayPrice !== 0) {
      obj.displayPrice = Math.round(message.displayPrice);
    }
    if (message.openCloseType !== undefined && message.openCloseType !== 0) {
      obj.openCloseType = Math.round(message.openCloseType);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    if (message.isAggressive !== undefined && message.isAggressive !== false) {
      obj.isAggressive = message.isAggressive;
    }
    if (message.quantity !== undefined) {
      obj.quantity = Decimal.toJSON(message.quantity);
    }
    if (message.extendedSide !== undefined && message.extendedSide !== 0) {
      obj.extendedSide = Math.round(message.extendedSide);
    }
    if (message.locateId !== undefined && message.locateId !== "") {
      obj.locateId = message.locateId;
    }
    if (message.fillExtraValues?.length) {
      obj.fillExtraValues = message.fillExtraValues.map((e) => Tuple.toJSON(e));
    }
    if (message.legNumber !== undefined && message.legNumber !== "") {
      obj.legNumber = message.legNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LegFill>, I>>(base?: I): LegFill {
    return LegFill.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LegFill>, I>>(object: I): LegFill {
    const message = createBaseLegFill();
    message.id = object.id ?? 0;
    message.execId = object.execId ?? "";
    message.contractSymbol = object.contractSymbol ?? "";
    message.obsoleteQuantity = object.obsoleteQuantity ?? 0;
    message.side = object.side ?? 0;
    message.price = object.price ?? 0;
    message.fillUtcTime = object.fillUtcTime ?? 0;
    message.fillStatus = object.fillStatus ?? 0;
    message.displayPrice = object.displayPrice ?? 0;
    message.openCloseType = object.openCloseType ?? 0;
    message.speculationType = object.speculationType ?? 0;
    message.isAggressive = object.isAggressive ?? false;
    message.quantity = (object.quantity !== undefined && object.quantity !== null)
      ? Decimal.fromPartial(object.quantity)
      : undefined;
    message.extendedSide = object.extendedSide ?? 0;
    message.locateId = object.locateId ?? "";
    message.fillExtraValues = object.fillExtraValues?.map((e) => Tuple.fromPartial(e)) || [];
    message.legNumber = object.legNumber ?? "";
    return message;
  },
};

function createBaseRelatedOrder(): RelatedOrder {
  return { orderChain: undefined, children: [], legPath: "" };
}

export const RelatedOrder = {
  encode(message: RelatedOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderChain !== undefined) {
      OrderChain.encode(message.orderChain, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.children) {
      RelatedOrder.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.legPath !== undefined && message.legPath !== "") {
      writer.uint32(26).string(message.legPath);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RelatedOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRelatedOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderChain = OrderChain.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.children.push(RelatedOrder.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.legPath = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RelatedOrder {
    return {
      orderChain: isSet(object.orderChain) ? OrderChain.fromJSON(object.orderChain) : undefined,
      children: globalThis.Array.isArray(object?.children)
        ? object.children.map((e: any) => RelatedOrder.fromJSON(e))
        : [],
      legPath: isSet(object.legPath) ? globalThis.String(object.legPath) : "",
    };
  },

  toJSON(message: RelatedOrder): unknown {
    const obj: any = {};
    if (message.orderChain !== undefined) {
      obj.orderChain = OrderChain.toJSON(message.orderChain);
    }
    if (message.children?.length) {
      obj.children = message.children.map((e) => RelatedOrder.toJSON(e));
    }
    if (message.legPath !== undefined && message.legPath !== "") {
      obj.legPath = message.legPath;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RelatedOrder>, I>>(base?: I): RelatedOrder {
    return RelatedOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RelatedOrder>, I>>(object: I): RelatedOrder {
    const message = createBaseRelatedOrder();
    message.orderChain = (object.orderChain !== undefined && object.orderChain !== null)
      ? OrderChain.fromPartial(object.orderChain)
      : undefined;
    message.children = object.children?.map((e) => RelatedOrder.fromPartial(e)) || [];
    message.legPath = object.legPath ?? "";
    return message;
  },
};

function createBaseCompoundOrder(): CompoundOrder {
  return { id: "", nodeRole: 0, type: 0, clientGuid: "", triggerType: 0, orderChain: undefined, children: [] };
}

export const CompoundOrder = {
  encode(message: CompoundOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.nodeRole !== undefined && message.nodeRole !== 0) {
      writer.uint32(16).uint32(message.nodeRole);
    }
    if (message.type !== undefined && message.type !== 0) {
      writer.uint32(24).uint32(message.type);
    }
    if (message.clientGuid !== undefined && message.clientGuid !== "") {
      writer.uint32(42).string(message.clientGuid);
    }
    if (message.triggerType !== undefined && message.triggerType !== 0) {
      writer.uint32(48).uint32(message.triggerType);
    }
    if (message.orderChain !== undefined) {
      OrderChain.encode(message.orderChain, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.children) {
      CompoundOrder.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompoundOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompoundOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.nodeRole = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.clientGuid = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.triggerType = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.orderChain = OrderChain.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.children.push(CompoundOrder.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompoundOrder {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      nodeRole: isSet(object.nodeRole) ? globalThis.Number(object.nodeRole) : 0,
      type: isSet(object.type) ? globalThis.Number(object.type) : 0,
      clientGuid: isSet(object.clientGuid) ? globalThis.String(object.clientGuid) : "",
      triggerType: isSet(object.triggerType) ? globalThis.Number(object.triggerType) : 0,
      orderChain: isSet(object.orderChain) ? OrderChain.fromJSON(object.orderChain) : undefined,
      children: globalThis.Array.isArray(object?.children)
        ? object.children.map((e: any) => CompoundOrder.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CompoundOrder): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.nodeRole !== undefined && message.nodeRole !== 0) {
      obj.nodeRole = Math.round(message.nodeRole);
    }
    if (message.type !== undefined && message.type !== 0) {
      obj.type = Math.round(message.type);
    }
    if (message.clientGuid !== undefined && message.clientGuid !== "") {
      obj.clientGuid = message.clientGuid;
    }
    if (message.triggerType !== undefined && message.triggerType !== 0) {
      obj.triggerType = Math.round(message.triggerType);
    }
    if (message.orderChain !== undefined) {
      obj.orderChain = OrderChain.toJSON(message.orderChain);
    }
    if (message.children?.length) {
      obj.children = message.children.map((e) => CompoundOrder.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompoundOrder>, I>>(base?: I): CompoundOrder {
    return CompoundOrder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompoundOrder>, I>>(object: I): CompoundOrder {
    const message = createBaseCompoundOrder();
    message.id = object.id ?? "";
    message.nodeRole = object.nodeRole ?? 0;
    message.type = object.type ?? 0;
    message.clientGuid = object.clientGuid ?? "";
    message.triggerType = object.triggerType ?? 0;
    message.orderChain = (object.orderChain !== undefined && object.orderChain !== null)
      ? OrderChain.fromPartial(object.orderChain)
      : undefined;
    message.children = object.children?.map((e) => CompoundOrder.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStrategyLeg(): StrategyLeg {
  return {
    legPath: "",
    contractSymbol: "",
    obsoleteQuantityRatio: 0,
    priceRatio: 0,
    mode: 0,
    account: undefined,
    yieldModel: [],
    legs: [],
    openCloseType: 0,
    speculationType: 0,
    quantityRatio: undefined,
  };
}

export const StrategyLeg = {
  encode(message: StrategyLeg, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.legPath !== undefined && message.legPath !== "") {
      writer.uint32(10).string(message.legPath);
    }
    if (message.contractSymbol !== undefined && message.contractSymbol !== "") {
      writer.uint32(18).string(message.contractSymbol);
    }
    if (message.obsoleteQuantityRatio !== undefined && message.obsoleteQuantityRatio !== 0) {
      writer.uint32(25).double(message.obsoleteQuantityRatio);
    }
    if (message.priceRatio !== undefined && message.priceRatio !== 0) {
      writer.uint32(33).double(message.priceRatio);
    }
    if (message.mode !== undefined && message.mode !== 0) {
      writer.uint32(40).uint32(message.mode);
    }
    if (message.account !== undefined) {
      ChainAccount.encode(message.account, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.yieldModel) {
      Tuple.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.legs) {
      StrategyLeg.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.openCloseType !== undefined && message.openCloseType !== 0) {
      writer.uint32(72).uint32(message.openCloseType);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(80).uint32(message.speculationType);
    }
    if (message.quantityRatio !== undefined) {
      Decimal.encode(message.quantityRatio, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StrategyLeg {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStrategyLeg();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.legPath = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.contractSymbol = reader.string();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.obsoleteQuantityRatio = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.priceRatio = reader.double();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.mode = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.account = ChainAccount.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.yieldModel.push(Tuple.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.legs.push(StrategyLeg.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.openCloseType = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.quantityRatio = Decimal.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StrategyLeg {
    return {
      legPath: isSet(object.legPath) ? globalThis.String(object.legPath) : "",
      contractSymbol: isSet(object.contractSymbol) ? globalThis.String(object.contractSymbol) : "",
      obsoleteQuantityRatio: isSet(object.obsoleteQuantityRatio) ? globalThis.Number(object.obsoleteQuantityRatio) : 0,
      priceRatio: isSet(object.priceRatio) ? globalThis.Number(object.priceRatio) : 0,
      mode: isSet(object.mode) ? globalThis.Number(object.mode) : 0,
      account: isSet(object.account) ? ChainAccount.fromJSON(object.account) : undefined,
      yieldModel: globalThis.Array.isArray(object?.yieldModel)
        ? object.yieldModel.map((e: any) => Tuple.fromJSON(e))
        : [],
      legs: globalThis.Array.isArray(object?.legs) ? object.legs.map((e: any) => StrategyLeg.fromJSON(e)) : [],
      openCloseType: isSet(object.openCloseType) ? globalThis.Number(object.openCloseType) : 0,
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
      quantityRatio: isSet(object.quantityRatio) ? Decimal.fromJSON(object.quantityRatio) : undefined,
    };
  },

  toJSON(message: StrategyLeg): unknown {
    const obj: any = {};
    if (message.legPath !== undefined && message.legPath !== "") {
      obj.legPath = message.legPath;
    }
    if (message.contractSymbol !== undefined && message.contractSymbol !== "") {
      obj.contractSymbol = message.contractSymbol;
    }
    if (message.obsoleteQuantityRatio !== undefined && message.obsoleteQuantityRatio !== 0) {
      obj.obsoleteQuantityRatio = message.obsoleteQuantityRatio;
    }
    if (message.priceRatio !== undefined && message.priceRatio !== 0) {
      obj.priceRatio = message.priceRatio;
    }
    if (message.mode !== undefined && message.mode !== 0) {
      obj.mode = Math.round(message.mode);
    }
    if (message.account !== undefined) {
      obj.account = ChainAccount.toJSON(message.account);
    }
    if (message.yieldModel?.length) {
      obj.yieldModel = message.yieldModel.map((e) => Tuple.toJSON(e));
    }
    if (message.legs?.length) {
      obj.legs = message.legs.map((e) => StrategyLeg.toJSON(e));
    }
    if (message.openCloseType !== undefined && message.openCloseType !== 0) {
      obj.openCloseType = Math.round(message.openCloseType);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    if (message.quantityRatio !== undefined) {
      obj.quantityRatio = Decimal.toJSON(message.quantityRatio);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StrategyLeg>, I>>(base?: I): StrategyLeg {
    return StrategyLeg.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StrategyLeg>, I>>(object: I): StrategyLeg {
    const message = createBaseStrategyLeg();
    message.legPath = object.legPath ?? "";
    message.contractSymbol = object.contractSymbol ?? "";
    message.obsoleteQuantityRatio = object.obsoleteQuantityRatio ?? 0;
    message.priceRatio = object.priceRatio ?? 0;
    message.mode = object.mode ?? 0;
    message.account = (object.account !== undefined && object.account !== null)
      ? ChainAccount.fromPartial(object.account)
      : undefined;
    message.yieldModel = object.yieldModel?.map((e) => Tuple.fromPartial(e)) || [];
    message.legs = object.legs?.map((e) => StrategyLeg.fromPartial(e)) || [];
    message.openCloseType = object.openCloseType ?? 0;
    message.speculationType = object.speculationType ?? 0;
    message.quantityRatio = (object.quantityRatio !== undefined && object.quantityRatio !== null)
      ? Decimal.fromPartial(object.quantityRatio)
      : undefined;
    return message;
  },
};

function createBaseAccount(): Account {
  return {
    id: 0,
    name: "",
    brokerageAccountNumber: "",
    obsoleteSalesSeriesNumber: "",
    obsoleteSalesSeriesName: "",
    obsoleteBrokerageName: "",
    class: 0,
    customerId: "",
    riskServerInstance: "",
    accountTypeId: "",
    salesSeriesId: "",
    brokerageId: "",
    currency: "",
    subClass: 0,
    riskServerInstanceId: "",
    obsoleteAccountClusterId: "",
    removed: false,
    isMaster: false,
    groupRelationType: 0,
    isCollection: false,
    profileId: "",
    profileSalesSeriesId: "",
    isInMigration: false,
  };
}

export const Account = {
  encode(message: Account, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== 0) {
      writer.uint32(8).sint32(message.id);
    }
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      writer.uint32(26).string(message.brokerageAccountNumber);
    }
    if (message.obsoleteSalesSeriesNumber !== undefined && message.obsoleteSalesSeriesNumber !== "") {
      writer.uint32(34).string(message.obsoleteSalesSeriesNumber);
    }
    if (message.obsoleteSalesSeriesName !== undefined && message.obsoleteSalesSeriesName !== "") {
      writer.uint32(42).string(message.obsoleteSalesSeriesName);
    }
    if (message.obsoleteBrokerageName !== undefined && message.obsoleteBrokerageName !== "") {
      writer.uint32(50).string(message.obsoleteBrokerageName);
    }
    if (message.class !== undefined && message.class !== 0) {
      writer.uint32(64).uint32(message.class);
    }
    if (message.customerId !== undefined && message.customerId !== "") {
      writer.uint32(74).string(message.customerId);
    }
    if (message.riskServerInstance !== undefined && message.riskServerInstance !== "") {
      writer.uint32(82).string(message.riskServerInstance);
    }
    if (message.accountTypeId !== undefined && message.accountTypeId !== "") {
      writer.uint32(90).string(message.accountTypeId);
    }
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      writer.uint32(98).string(message.salesSeriesId);
    }
    if (message.brokerageId !== undefined && message.brokerageId !== "") {
      writer.uint32(106).string(message.brokerageId);
    }
    if (message.currency !== undefined && message.currency !== "") {
      writer.uint32(114).string(message.currency);
    }
    if (message.subClass !== undefined && message.subClass !== 0) {
      writer.uint32(120).uint32(message.subClass);
    }
    if (message.riskServerInstanceId !== undefined && message.riskServerInstanceId !== "") {
      writer.uint32(130).string(message.riskServerInstanceId);
    }
    if (message.obsoleteAccountClusterId !== undefined && message.obsoleteAccountClusterId !== "") {
      writer.uint32(138).string(message.obsoleteAccountClusterId);
    }
    if (message.removed !== undefined && message.removed !== false) {
      writer.uint32(144).bool(message.removed);
    }
    if (message.isMaster !== undefined && message.isMaster !== false) {
      writer.uint32(152).bool(message.isMaster);
    }
    if (message.groupRelationType !== undefined && message.groupRelationType !== 0) {
      writer.uint32(160).uint32(message.groupRelationType);
    }
    if (message.isCollection !== undefined && message.isCollection !== false) {
      writer.uint32(168).bool(message.isCollection);
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      writer.uint32(178).string(message.profileId);
    }
    if (message.profileSalesSeriesId !== undefined && message.profileSalesSeriesId !== "") {
      writer.uint32(186).string(message.profileSalesSeriesId);
    }
    if (message.isInMigration !== undefined && message.isInMigration !== false) {
      writer.uint32(192).bool(message.isInMigration);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Account {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.brokerageAccountNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.obsoleteSalesSeriesNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.obsoleteSalesSeriesName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.obsoleteBrokerageName = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.class = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.riskServerInstance = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.accountTypeId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.salesSeriesId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.brokerageId = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.subClass = reader.uint32();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.riskServerInstanceId = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.obsoleteAccountClusterId = reader.string();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.removed = reader.bool();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.isMaster = reader.bool();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.groupRelationType = reader.uint32();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.isCollection = reader.bool();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.profileId = reader.string();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.profileSalesSeriesId = reader.string();
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.isInMigration = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Account {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      brokerageAccountNumber: isSet(object.brokerageAccountNumber)
        ? globalThis.String(object.brokerageAccountNumber)
        : "",
      obsoleteSalesSeriesNumber: isSet(object.obsoleteSalesSeriesNumber)
        ? globalThis.String(object.obsoleteSalesSeriesNumber)
        : "",
      obsoleteSalesSeriesName: isSet(object.obsoleteSalesSeriesName)
        ? globalThis.String(object.obsoleteSalesSeriesName)
        : "",
      obsoleteBrokerageName: isSet(object.obsoleteBrokerageName) ? globalThis.String(object.obsoleteBrokerageName) : "",
      class: isSet(object.class) ? globalThis.Number(object.class) : 0,
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "",
      riskServerInstance: isSet(object.riskServerInstance) ? globalThis.String(object.riskServerInstance) : "",
      accountTypeId: isSet(object.accountTypeId) ? globalThis.String(object.accountTypeId) : "",
      salesSeriesId: isSet(object.salesSeriesId) ? globalThis.String(object.salesSeriesId) : "",
      brokerageId: isSet(object.brokerageId) ? globalThis.String(object.brokerageId) : "",
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
      subClass: isSet(object.subClass) ? globalThis.Number(object.subClass) : 0,
      riskServerInstanceId: isSet(object.riskServerInstanceId) ? globalThis.String(object.riskServerInstanceId) : "",
      obsoleteAccountClusterId: isSet(object.obsoleteAccountClusterId)
        ? globalThis.String(object.obsoleteAccountClusterId)
        : "",
      removed: isSet(object.removed) ? globalThis.Boolean(object.removed) : false,
      isMaster: isSet(object.isMaster) ? globalThis.Boolean(object.isMaster) : false,
      groupRelationType: isSet(object.groupRelationType) ? globalThis.Number(object.groupRelationType) : 0,
      isCollection: isSet(object.isCollection) ? globalThis.Boolean(object.isCollection) : false,
      profileId: isSet(object.profileId) ? globalThis.String(object.profileId) : "",
      profileSalesSeriesId: isSet(object.profileSalesSeriesId) ? globalThis.String(object.profileSalesSeriesId) : "",
      isInMigration: isSet(object.isInMigration) ? globalThis.Boolean(object.isInMigration) : false,
    };
  },

  toJSON(message: Account): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      obj.brokerageAccountNumber = message.brokerageAccountNumber;
    }
    if (message.obsoleteSalesSeriesNumber !== undefined && message.obsoleteSalesSeriesNumber !== "") {
      obj.obsoleteSalesSeriesNumber = message.obsoleteSalesSeriesNumber;
    }
    if (message.obsoleteSalesSeriesName !== undefined && message.obsoleteSalesSeriesName !== "") {
      obj.obsoleteSalesSeriesName = message.obsoleteSalesSeriesName;
    }
    if (message.obsoleteBrokerageName !== undefined && message.obsoleteBrokerageName !== "") {
      obj.obsoleteBrokerageName = message.obsoleteBrokerageName;
    }
    if (message.class !== undefined && message.class !== 0) {
      obj.class = Math.round(message.class);
    }
    if (message.customerId !== undefined && message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    if (message.riskServerInstance !== undefined && message.riskServerInstance !== "") {
      obj.riskServerInstance = message.riskServerInstance;
    }
    if (message.accountTypeId !== undefined && message.accountTypeId !== "") {
      obj.accountTypeId = message.accountTypeId;
    }
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      obj.salesSeriesId = message.salesSeriesId;
    }
    if (message.brokerageId !== undefined && message.brokerageId !== "") {
      obj.brokerageId = message.brokerageId;
    }
    if (message.currency !== undefined && message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.subClass !== undefined && message.subClass !== 0) {
      obj.subClass = Math.round(message.subClass);
    }
    if (message.riskServerInstanceId !== undefined && message.riskServerInstanceId !== "") {
      obj.riskServerInstanceId = message.riskServerInstanceId;
    }
    if (message.obsoleteAccountClusterId !== undefined && message.obsoleteAccountClusterId !== "") {
      obj.obsoleteAccountClusterId = message.obsoleteAccountClusterId;
    }
    if (message.removed !== undefined && message.removed !== false) {
      obj.removed = message.removed;
    }
    if (message.isMaster !== undefined && message.isMaster !== false) {
      obj.isMaster = message.isMaster;
    }
    if (message.groupRelationType !== undefined && message.groupRelationType !== 0) {
      obj.groupRelationType = Math.round(message.groupRelationType);
    }
    if (message.isCollection !== undefined && message.isCollection !== false) {
      obj.isCollection = message.isCollection;
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      obj.profileId = message.profileId;
    }
    if (message.profileSalesSeriesId !== undefined && message.profileSalesSeriesId !== "") {
      obj.profileSalesSeriesId = message.profileSalesSeriesId;
    }
    if (message.isInMigration !== undefined && message.isInMigration !== false) {
      obj.isInMigration = message.isInMigration;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Account>, I>>(base?: I): Account {
    return Account.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Account>, I>>(object: I): Account {
    const message = createBaseAccount();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.brokerageAccountNumber = object.brokerageAccountNumber ?? "";
    message.obsoleteSalesSeriesNumber = object.obsoleteSalesSeriesNumber ?? "";
    message.obsoleteSalesSeriesName = object.obsoleteSalesSeriesName ?? "";
    message.obsoleteBrokerageName = object.obsoleteBrokerageName ?? "";
    message.class = object.class ?? 0;
    message.customerId = object.customerId ?? "";
    message.riskServerInstance = object.riskServerInstance ?? "";
    message.accountTypeId = object.accountTypeId ?? "";
    message.salesSeriesId = object.salesSeriesId ?? "";
    message.brokerageId = object.brokerageId ?? "";
    message.currency = object.currency ?? "";
    message.subClass = object.subClass ?? 0;
    message.riskServerInstanceId = object.riskServerInstanceId ?? "";
    message.obsoleteAccountClusterId = object.obsoleteAccountClusterId ?? "";
    message.removed = object.removed ?? false;
    message.isMaster = object.isMaster ?? false;
    message.groupRelationType = object.groupRelationType ?? 0;
    message.isCollection = object.isCollection ?? false;
    message.profileId = object.profileId ?? "";
    message.profileSalesSeriesId = object.profileSalesSeriesId ?? "";
    message.isInMigration = object.isInMigration ?? false;
    return message;
  },
};

function createBaseBalanceRecord(): BalanceRecord {
  return {
    accountId: 0,
    balanceRecordId: 0,
    currency: "",
    endCashBalance: 0,
    collateral: 0,
    asOfDate: 0,
    origin: 0,
    regulated: false,
  };
}

export const BalanceRecord = {
  encode(message: BalanceRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.balanceRecordId !== 0) {
      writer.uint32(16).sint32(message.balanceRecordId);
    }
    if (message.currency !== "") {
      writer.uint32(26).string(message.currency);
    }
    if (message.endCashBalance !== undefined && message.endCashBalance !== 0) {
      writer.uint32(33).double(message.endCashBalance);
    }
    if (message.collateral !== undefined && message.collateral !== 0) {
      writer.uint32(41).double(message.collateral);
    }
    if (message.asOfDate !== undefined && message.asOfDate !== 0) {
      writer.uint32(48).sint64(message.asOfDate);
    }
    if (message.origin !== undefined && message.origin !== 0) {
      writer.uint32(56).uint32(message.origin);
    }
    if (message.regulated !== undefined && message.regulated !== false) {
      writer.uint32(64).bool(message.regulated);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BalanceRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalanceRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.balanceRecordId = reader.sint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.endCashBalance = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.collateral = reader.double();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.asOfDate = longToNumber(reader.sint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.origin = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.regulated = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BalanceRecord {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      balanceRecordId: isSet(object.balanceRecordId) ? globalThis.Number(object.balanceRecordId) : 0,
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
      endCashBalance: isSet(object.endCashBalance) ? globalThis.Number(object.endCashBalance) : 0,
      collateral: isSet(object.collateral) ? globalThis.Number(object.collateral) : 0,
      asOfDate: isSet(object.asOfDate) ? globalThis.Number(object.asOfDate) : 0,
      origin: isSet(object.origin) ? globalThis.Number(object.origin) : 0,
      regulated: isSet(object.regulated) ? globalThis.Boolean(object.regulated) : false,
    };
  },

  toJSON(message: BalanceRecord): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.balanceRecordId !== 0) {
      obj.balanceRecordId = Math.round(message.balanceRecordId);
    }
    if (message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.endCashBalance !== undefined && message.endCashBalance !== 0) {
      obj.endCashBalance = message.endCashBalance;
    }
    if (message.collateral !== undefined && message.collateral !== 0) {
      obj.collateral = message.collateral;
    }
    if (message.asOfDate !== undefined && message.asOfDate !== 0) {
      obj.asOfDate = Math.round(message.asOfDate);
    }
    if (message.origin !== undefined && message.origin !== 0) {
      obj.origin = Math.round(message.origin);
    }
    if (message.regulated !== undefined && message.regulated !== false) {
      obj.regulated = message.regulated;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BalanceRecord>, I>>(base?: I): BalanceRecord {
    return BalanceRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BalanceRecord>, I>>(object: I): BalanceRecord {
    const message = createBaseBalanceRecord();
    message.accountId = object.accountId ?? 0;
    message.balanceRecordId = object.balanceRecordId ?? 0;
    message.currency = object.currency ?? "";
    message.endCashBalance = object.endCashBalance ?? 0;
    message.collateral = object.collateral ?? 0;
    message.asOfDate = object.asOfDate ?? 0;
    message.origin = object.origin ?? 0;
    message.regulated = object.regulated ?? false;
    return message;
  },
};

function createBaseAccountSearchResultRecord(): AccountSearchResultRecord {
  return {
    accountId: 0,
    name: "",
    brokerageAccountNumber: "",
    salesSeriesNumber: "",
    salesSeriesName: "",
    brokerageName: "",
    customerId: "",
    customerName: "",
    removed: false,
    salesSeriesId: "",
    brokerageId: "",
    riskServerInstanceId: "",
    groupId: "",
    class: 0,
    profileId: "",
    profileSalesSeriesId: "",
  };
}

export const AccountSearchResultRecord = {
  encode(message: AccountSearchResultRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.brokerageAccountNumber !== "") {
      writer.uint32(26).string(message.brokerageAccountNumber);
    }
    if (message.salesSeriesNumber !== "") {
      writer.uint32(34).string(message.salesSeriesNumber);
    }
    if (message.salesSeriesName !== "") {
      writer.uint32(42).string(message.salesSeriesName);
    }
    if (message.brokerageName !== "") {
      writer.uint32(50).string(message.brokerageName);
    }
    if (message.customerId !== undefined && message.customerId !== "") {
      writer.uint32(58).string(message.customerId);
    }
    if (message.customerName !== undefined && message.customerName !== "") {
      writer.uint32(66).string(message.customerName);
    }
    if (message.removed !== undefined && message.removed !== false) {
      writer.uint32(72).bool(message.removed);
    }
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      writer.uint32(82).string(message.salesSeriesId);
    }
    if (message.brokerageId !== undefined && message.brokerageId !== "") {
      writer.uint32(90).string(message.brokerageId);
    }
    if (message.riskServerInstanceId !== undefined && message.riskServerInstanceId !== "") {
      writer.uint32(98).string(message.riskServerInstanceId);
    }
    if (message.groupId !== undefined && message.groupId !== "") {
      writer.uint32(106).string(message.groupId);
    }
    if (message.class !== undefined && message.class !== 0) {
      writer.uint32(112).uint32(message.class);
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      writer.uint32(122).string(message.profileId);
    }
    if (message.profileSalesSeriesId !== undefined && message.profileSalesSeriesId !== "") {
      writer.uint32(130).string(message.profileSalesSeriesId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountSearchResultRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountSearchResultRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.brokerageAccountNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.salesSeriesNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.salesSeriesName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.brokerageName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.customerName = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.removed = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.salesSeriesId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.brokerageId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.riskServerInstanceId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.groupId = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.class = reader.uint32();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.profileId = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.profileSalesSeriesId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountSearchResultRecord {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      brokerageAccountNumber: isSet(object.brokerageAccountNumber)
        ? globalThis.String(object.brokerageAccountNumber)
        : "",
      salesSeriesNumber: isSet(object.salesSeriesNumber) ? globalThis.String(object.salesSeriesNumber) : "",
      salesSeriesName: isSet(object.salesSeriesName) ? globalThis.String(object.salesSeriesName) : "",
      brokerageName: isSet(object.brokerageName) ? globalThis.String(object.brokerageName) : "",
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "",
      customerName: isSet(object.customerName) ? globalThis.String(object.customerName) : "",
      removed: isSet(object.removed) ? globalThis.Boolean(object.removed) : false,
      salesSeriesId: isSet(object.salesSeriesId) ? globalThis.String(object.salesSeriesId) : "",
      brokerageId: isSet(object.brokerageId) ? globalThis.String(object.brokerageId) : "",
      riskServerInstanceId: isSet(object.riskServerInstanceId) ? globalThis.String(object.riskServerInstanceId) : "",
      groupId: isSet(object.groupId) ? globalThis.String(object.groupId) : "",
      class: isSet(object.class) ? globalThis.Number(object.class) : 0,
      profileId: isSet(object.profileId) ? globalThis.String(object.profileId) : "",
      profileSalesSeriesId: isSet(object.profileSalesSeriesId) ? globalThis.String(object.profileSalesSeriesId) : "",
    };
  },

  toJSON(message: AccountSearchResultRecord): unknown {
    const obj: any = {};
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.brokerageAccountNumber !== "") {
      obj.brokerageAccountNumber = message.brokerageAccountNumber;
    }
    if (message.salesSeriesNumber !== "") {
      obj.salesSeriesNumber = message.salesSeriesNumber;
    }
    if (message.salesSeriesName !== "") {
      obj.salesSeriesName = message.salesSeriesName;
    }
    if (message.brokerageName !== "") {
      obj.brokerageName = message.brokerageName;
    }
    if (message.customerId !== undefined && message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    if (message.customerName !== undefined && message.customerName !== "") {
      obj.customerName = message.customerName;
    }
    if (message.removed !== undefined && message.removed !== false) {
      obj.removed = message.removed;
    }
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      obj.salesSeriesId = message.salesSeriesId;
    }
    if (message.brokerageId !== undefined && message.brokerageId !== "") {
      obj.brokerageId = message.brokerageId;
    }
    if (message.riskServerInstanceId !== undefined && message.riskServerInstanceId !== "") {
      obj.riskServerInstanceId = message.riskServerInstanceId;
    }
    if (message.groupId !== undefined && message.groupId !== "") {
      obj.groupId = message.groupId;
    }
    if (message.class !== undefined && message.class !== 0) {
      obj.class = Math.round(message.class);
    }
    if (message.profileId !== undefined && message.profileId !== "") {
      obj.profileId = message.profileId;
    }
    if (message.profileSalesSeriesId !== undefined && message.profileSalesSeriesId !== "") {
      obj.profileSalesSeriesId = message.profileSalesSeriesId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountSearchResultRecord>, I>>(base?: I): AccountSearchResultRecord {
    return AccountSearchResultRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountSearchResultRecord>, I>>(object: I): AccountSearchResultRecord {
    const message = createBaseAccountSearchResultRecord();
    message.accountId = object.accountId ?? 0;
    message.name = object.name ?? "";
    message.brokerageAccountNumber = object.brokerageAccountNumber ?? "";
    message.salesSeriesNumber = object.salesSeriesNumber ?? "";
    message.salesSeriesName = object.salesSeriesName ?? "";
    message.brokerageName = object.brokerageName ?? "";
    message.customerId = object.customerId ?? "";
    message.customerName = object.customerName ?? "";
    message.removed = object.removed ?? false;
    message.salesSeriesId = object.salesSeriesId ?? "";
    message.brokerageId = object.brokerageId ?? "";
    message.riskServerInstanceId = object.riskServerInstanceId ?? "";
    message.groupId = object.groupId ?? "";
    message.class = object.class ?? 0;
    message.profileId = object.profileId ?? "";
    message.profileSalesSeriesId = object.profileSalesSeriesId ?? "";
    return message;
  },
};

function createBaseAccountRouteRecord(): AccountRouteRecord {
  return {
    clearedFields: [],
    routeCode: 0,
    obsoleteRouteName: "",
    priority: 0,
    omnibusAccountId: 0,
    obsoleteOmnibusAccountName: "",
    attributes: [],
    isInUse: false,
    allowSyntheticOrderTypes: false,
    enableAlgoStrategiesWhitelist: false,
    algoStrategiesWhitelist: [],
    cqgAlgoOrdersOnly: false,
    forceVariableLatencyAlgo: 0,
  };
}

export const AccountRouteRecord = {
  encode(message: AccountRouteRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(74).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.routeCode !== undefined && message.routeCode !== 0) {
      writer.uint32(8).sint32(message.routeCode);
    }
    if (message.obsoleteRouteName !== undefined && message.obsoleteRouteName !== "") {
      writer.uint32(18).string(message.obsoleteRouteName);
    }
    if (message.priority !== undefined && message.priority !== 0) {
      writer.uint32(24).sint32(message.priority);
    }
    if (message.omnibusAccountId !== undefined && message.omnibusAccountId !== 0) {
      writer.uint32(32).sint32(message.omnibusAccountId);
    }
    if (message.obsoleteOmnibusAccountName !== undefined && message.obsoleteOmnibusAccountName !== "") {
      writer.uint32(42).string(message.obsoleteOmnibusAccountName);
    }
    for (const v of message.attributes) {
      AccountRouteAttribute.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.isInUse !== undefined && message.isInUse !== false) {
      writer.uint32(56).bool(message.isInUse);
    }
    if (message.allowSyntheticOrderTypes !== undefined && message.allowSyntheticOrderTypes !== false) {
      writer.uint32(64).bool(message.allowSyntheticOrderTypes);
    }
    if (message.enableAlgoStrategiesWhitelist !== undefined && message.enableAlgoStrategiesWhitelist !== false) {
      writer.uint32(80).bool(message.enableAlgoStrategiesWhitelist);
    }
    for (const v of message.algoStrategiesWhitelist) {
      writer.uint32(90).string(v!);
    }
    if (message.cqgAlgoOrdersOnly !== undefined && message.cqgAlgoOrdersOnly !== false) {
      writer.uint32(96).bool(message.cqgAlgoOrdersOnly);
    }
    if (message.forceVariableLatencyAlgo !== undefined && message.forceVariableLatencyAlgo !== 0) {
      writer.uint32(104).uint32(message.forceVariableLatencyAlgo);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountRouteRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountRouteRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 9:
          if (tag === 72) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 74) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.routeCode = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.obsoleteRouteName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.priority = reader.sint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.omnibusAccountId = reader.sint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.obsoleteOmnibusAccountName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.attributes.push(AccountRouteAttribute.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.isInUse = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.allowSyntheticOrderTypes = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.enableAlgoStrategiesWhitelist = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.algoStrategiesWhitelist.push(reader.string());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.cqgAlgoOrdersOnly = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.forceVariableLatencyAlgo = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountRouteRecord {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      routeCode: isSet(object.routeCode) ? globalThis.Number(object.routeCode) : 0,
      obsoleteRouteName: isSet(object.obsoleteRouteName) ? globalThis.String(object.obsoleteRouteName) : "",
      priority: isSet(object.priority) ? globalThis.Number(object.priority) : 0,
      omnibusAccountId: isSet(object.omnibusAccountId) ? globalThis.Number(object.omnibusAccountId) : 0,
      obsoleteOmnibusAccountName: isSet(object.obsoleteOmnibusAccountName)
        ? globalThis.String(object.obsoleteOmnibusAccountName)
        : "",
      attributes: globalThis.Array.isArray(object?.attributes)
        ? object.attributes.map((e: any) => AccountRouteAttribute.fromJSON(e))
        : [],
      isInUse: isSet(object.isInUse) ? globalThis.Boolean(object.isInUse) : false,
      allowSyntheticOrderTypes: isSet(object.allowSyntheticOrderTypes)
        ? globalThis.Boolean(object.allowSyntheticOrderTypes)
        : false,
      enableAlgoStrategiesWhitelist: isSet(object.enableAlgoStrategiesWhitelist)
        ? globalThis.Boolean(object.enableAlgoStrategiesWhitelist)
        : false,
      algoStrategiesWhitelist: globalThis.Array.isArray(object?.algoStrategiesWhitelist)
        ? object.algoStrategiesWhitelist.map((e: any) => globalThis.String(e))
        : [],
      cqgAlgoOrdersOnly: isSet(object.cqgAlgoOrdersOnly) ? globalThis.Boolean(object.cqgAlgoOrdersOnly) : false,
      forceVariableLatencyAlgo: isSet(object.forceVariableLatencyAlgo)
        ? globalThis.Number(object.forceVariableLatencyAlgo)
        : 0,
    };
  },

  toJSON(message: AccountRouteRecord): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.routeCode !== undefined && message.routeCode !== 0) {
      obj.routeCode = Math.round(message.routeCode);
    }
    if (message.obsoleteRouteName !== undefined && message.obsoleteRouteName !== "") {
      obj.obsoleteRouteName = message.obsoleteRouteName;
    }
    if (message.priority !== undefined && message.priority !== 0) {
      obj.priority = Math.round(message.priority);
    }
    if (message.omnibusAccountId !== undefined && message.omnibusAccountId !== 0) {
      obj.omnibusAccountId = Math.round(message.omnibusAccountId);
    }
    if (message.obsoleteOmnibusAccountName !== undefined && message.obsoleteOmnibusAccountName !== "") {
      obj.obsoleteOmnibusAccountName = message.obsoleteOmnibusAccountName;
    }
    if (message.attributes?.length) {
      obj.attributes = message.attributes.map((e) => AccountRouteAttribute.toJSON(e));
    }
    if (message.isInUse !== undefined && message.isInUse !== false) {
      obj.isInUse = message.isInUse;
    }
    if (message.allowSyntheticOrderTypes !== undefined && message.allowSyntheticOrderTypes !== false) {
      obj.allowSyntheticOrderTypes = message.allowSyntheticOrderTypes;
    }
    if (message.enableAlgoStrategiesWhitelist !== undefined && message.enableAlgoStrategiesWhitelist !== false) {
      obj.enableAlgoStrategiesWhitelist = message.enableAlgoStrategiesWhitelist;
    }
    if (message.algoStrategiesWhitelist?.length) {
      obj.algoStrategiesWhitelist = message.algoStrategiesWhitelist;
    }
    if (message.cqgAlgoOrdersOnly !== undefined && message.cqgAlgoOrdersOnly !== false) {
      obj.cqgAlgoOrdersOnly = message.cqgAlgoOrdersOnly;
    }
    if (message.forceVariableLatencyAlgo !== undefined && message.forceVariableLatencyAlgo !== 0) {
      obj.forceVariableLatencyAlgo = Math.round(message.forceVariableLatencyAlgo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountRouteRecord>, I>>(base?: I): AccountRouteRecord {
    return AccountRouteRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountRouteRecord>, I>>(object: I): AccountRouteRecord {
    const message = createBaseAccountRouteRecord();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.routeCode = object.routeCode ?? 0;
    message.obsoleteRouteName = object.obsoleteRouteName ?? "";
    message.priority = object.priority ?? 0;
    message.omnibusAccountId = object.omnibusAccountId ?? 0;
    message.obsoleteOmnibusAccountName = object.obsoleteOmnibusAccountName ?? "";
    message.attributes = object.attributes?.map((e) => AccountRouteAttribute.fromPartial(e)) || [];
    message.isInUse = object.isInUse ?? false;
    message.allowSyntheticOrderTypes = object.allowSyntheticOrderTypes ?? false;
    message.enableAlgoStrategiesWhitelist = object.enableAlgoStrategiesWhitelist ?? false;
    message.algoStrategiesWhitelist = object.algoStrategiesWhitelist?.map((e) => e) || [];
    message.cqgAlgoOrdersOnly = object.cqgAlgoOrdersOnly ?? false;
    message.forceVariableLatencyAlgo = object.forceVariableLatencyAlgo ?? 0;
    return message;
  },
};

function createBaseAccountRouteAttribute(): AccountRouteAttribute {
  return { Name: "", Value: "", id: "" };
}

export const AccountRouteAttribute = {
  encode(message: AccountRouteAttribute, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Name !== "") {
      writer.uint32(10).string(message.Name);
    }
    if (message.Value !== undefined && message.Value !== "") {
      writer.uint32(18).string(message.Value);
    }
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(26).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountRouteAttribute {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountRouteAttribute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.Name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.Value = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountRouteAttribute {
    return {
      Name: isSet(object.Name) ? globalThis.String(object.Name) : "",
      Value: isSet(object.Value) ? globalThis.String(object.Value) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
    };
  },

  toJSON(message: AccountRouteAttribute): unknown {
    const obj: any = {};
    if (message.Name !== "") {
      obj.Name = message.Name;
    }
    if (message.Value !== undefined && message.Value !== "") {
      obj.Value = message.Value;
    }
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountRouteAttribute>, I>>(base?: I): AccountRouteAttribute {
    return AccountRouteAttribute.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountRouteAttribute>, I>>(object: I): AccountRouteAttribute {
    const message = createBaseAccountRouteAttribute();
    message.Name = object.Name ?? "";
    message.Value = object.Value ?? "";
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseAccountUserLink(): AccountUserLink {
  return {
    accountId: 0,
    userId: "",
    isViewOnly: false,
    isForceCare: false,
    userName: "",
    accountName: "",
    brokerageAccountNumber: "",
    brokerageName: "",
    allowExternalAccounts: false,
    implicitAuthorization: false,
    executionSourceCodeId: "",
    accountBrokerageId: "",
    accountSalesSeriesId: "",
    isMifidDea: false,
    transformMifidAlgoCqgToClientAlgo: false,
    isPrimaryLogin: false,
  };
}

export const AccountUserLink = {
  encode(message: AccountUserLink, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.userId !== undefined && message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.isViewOnly !== undefined && message.isViewOnly !== false) {
      writer.uint32(24).bool(message.isViewOnly);
    }
    if (message.isForceCare !== undefined && message.isForceCare !== false) {
      writer.uint32(32).bool(message.isForceCare);
    }
    if (message.userName !== undefined && message.userName !== "") {
      writer.uint32(42).string(message.userName);
    }
    if (message.accountName !== undefined && message.accountName !== "") {
      writer.uint32(50).string(message.accountName);
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      writer.uint32(58).string(message.brokerageAccountNumber);
    }
    if (message.brokerageName !== undefined && message.brokerageName !== "") {
      writer.uint32(66).string(message.brokerageName);
    }
    if (message.allowExternalAccounts !== undefined && message.allowExternalAccounts !== false) {
      writer.uint32(72).bool(message.allowExternalAccounts);
    }
    if (message.implicitAuthorization !== undefined && message.implicitAuthorization !== false) {
      writer.uint32(80).bool(message.implicitAuthorization);
    }
    if (message.executionSourceCodeId !== undefined && message.executionSourceCodeId !== "") {
      writer.uint32(90).string(message.executionSourceCodeId);
    }
    if (message.accountBrokerageId !== undefined && message.accountBrokerageId !== "") {
      writer.uint32(98).string(message.accountBrokerageId);
    }
    if (message.accountSalesSeriesId !== undefined && message.accountSalesSeriesId !== "") {
      writer.uint32(106).string(message.accountSalesSeriesId);
    }
    if (message.isMifidDea !== undefined && message.isMifidDea !== false) {
      writer.uint32(112).bool(message.isMifidDea);
    }
    if (
      message.transformMifidAlgoCqgToClientAlgo !== undefined && message.transformMifidAlgoCqgToClientAlgo !== false
    ) {
      writer.uint32(120).bool(message.transformMifidAlgoCqgToClientAlgo);
    }
    if (message.isPrimaryLogin !== undefined && message.isPrimaryLogin !== false) {
      writer.uint32(128).bool(message.isPrimaryLogin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountUserLink {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountUserLink();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isViewOnly = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isForceCare = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.userName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.accountName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.brokerageAccountNumber = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.brokerageName = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.allowExternalAccounts = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.implicitAuthorization = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.executionSourceCodeId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.accountBrokerageId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.accountSalesSeriesId = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.isMifidDea = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.transformMifidAlgoCqgToClientAlgo = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.isPrimaryLogin = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountUserLink {
    return {
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      userId: isSet(object.userId) ? globalThis.String(object.userId) : "",
      isViewOnly: isSet(object.isViewOnly) ? globalThis.Boolean(object.isViewOnly) : false,
      isForceCare: isSet(object.isForceCare) ? globalThis.Boolean(object.isForceCare) : false,
      userName: isSet(object.userName) ? globalThis.String(object.userName) : "",
      accountName: isSet(object.accountName) ? globalThis.String(object.accountName) : "",
      brokerageAccountNumber: isSet(object.brokerageAccountNumber)
        ? globalThis.String(object.brokerageAccountNumber)
        : "",
      brokerageName: isSet(object.brokerageName) ? globalThis.String(object.brokerageName) : "",
      allowExternalAccounts: isSet(object.allowExternalAccounts)
        ? globalThis.Boolean(object.allowExternalAccounts)
        : false,
      implicitAuthorization: isSet(object.implicitAuthorization)
        ? globalThis.Boolean(object.implicitAuthorization)
        : false,
      executionSourceCodeId: isSet(object.executionSourceCodeId) ? globalThis.String(object.executionSourceCodeId) : "",
      accountBrokerageId: isSet(object.accountBrokerageId) ? globalThis.String(object.accountBrokerageId) : "",
      accountSalesSeriesId: isSet(object.accountSalesSeriesId) ? globalThis.String(object.accountSalesSeriesId) : "",
      isMifidDea: isSet(object.isMifidDea) ? globalThis.Boolean(object.isMifidDea) : false,
      transformMifidAlgoCqgToClientAlgo: isSet(object.transformMifidAlgoCqgToClientAlgo)
        ? globalThis.Boolean(object.transformMifidAlgoCqgToClientAlgo)
        : false,
      isPrimaryLogin: isSet(object.isPrimaryLogin) ? globalThis.Boolean(object.isPrimaryLogin) : false,
    };
  },

  toJSON(message: AccountUserLink): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.userId !== undefined && message.userId !== "") {
      obj.userId = message.userId;
    }
    if (message.isViewOnly !== undefined && message.isViewOnly !== false) {
      obj.isViewOnly = message.isViewOnly;
    }
    if (message.isForceCare !== undefined && message.isForceCare !== false) {
      obj.isForceCare = message.isForceCare;
    }
    if (message.userName !== undefined && message.userName !== "") {
      obj.userName = message.userName;
    }
    if (message.accountName !== undefined && message.accountName !== "") {
      obj.accountName = message.accountName;
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      obj.brokerageAccountNumber = message.brokerageAccountNumber;
    }
    if (message.brokerageName !== undefined && message.brokerageName !== "") {
      obj.brokerageName = message.brokerageName;
    }
    if (message.allowExternalAccounts !== undefined && message.allowExternalAccounts !== false) {
      obj.allowExternalAccounts = message.allowExternalAccounts;
    }
    if (message.implicitAuthorization !== undefined && message.implicitAuthorization !== false) {
      obj.implicitAuthorization = message.implicitAuthorization;
    }
    if (message.executionSourceCodeId !== undefined && message.executionSourceCodeId !== "") {
      obj.executionSourceCodeId = message.executionSourceCodeId;
    }
    if (message.accountBrokerageId !== undefined && message.accountBrokerageId !== "") {
      obj.accountBrokerageId = message.accountBrokerageId;
    }
    if (message.accountSalesSeriesId !== undefined && message.accountSalesSeriesId !== "") {
      obj.accountSalesSeriesId = message.accountSalesSeriesId;
    }
    if (message.isMifidDea !== undefined && message.isMifidDea !== false) {
      obj.isMifidDea = message.isMifidDea;
    }
    if (
      message.transformMifidAlgoCqgToClientAlgo !== undefined && message.transformMifidAlgoCqgToClientAlgo !== false
    ) {
      obj.transformMifidAlgoCqgToClientAlgo = message.transformMifidAlgoCqgToClientAlgo;
    }
    if (message.isPrimaryLogin !== undefined && message.isPrimaryLogin !== false) {
      obj.isPrimaryLogin = message.isPrimaryLogin;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountUserLink>, I>>(base?: I): AccountUserLink {
    return AccountUserLink.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountUserLink>, I>>(object: I): AccountUserLink {
    const message = createBaseAccountUserLink();
    message.accountId = object.accountId ?? 0;
    message.userId = object.userId ?? "";
    message.isViewOnly = object.isViewOnly ?? false;
    message.isForceCare = object.isForceCare ?? false;
    message.userName = object.userName ?? "";
    message.accountName = object.accountName ?? "";
    message.brokerageAccountNumber = object.brokerageAccountNumber ?? "";
    message.brokerageName = object.brokerageName ?? "";
    message.allowExternalAccounts = object.allowExternalAccounts ?? false;
    message.implicitAuthorization = object.implicitAuthorization ?? false;
    message.executionSourceCodeId = object.executionSourceCodeId ?? "";
    message.accountBrokerageId = object.accountBrokerageId ?? "";
    message.accountSalesSeriesId = object.accountSalesSeriesId ?? "";
    message.isMifidDea = object.isMifidDea ?? false;
    message.transformMifidAlgoCqgToClientAlgo = object.transformMifidAlgoCqgToClientAlgo ?? false;
    message.isPrimaryLogin = object.isPrimaryLogin ?? false;
    return message;
  },
};

function createBaseAccountSettings(): AccountSettings {
  return {
    clearedFields: [],
    accountId: 0,
    obsoleteIsBlock: false,
    obsoleteBlockSize: 0,
    isGiveup: false,
    obsoleteGiveupBrokerageName: "",
    statementOption: 0,
    zeroBalance: false,
    reconciliation: false,
    giveupBrokerageId: "",
    tradingTimeFrom: "",
    tradingTimeTo: "",
    tradingTimeZone: "",
    liquidationOrdersOutsideTradingTime: false,
    isInstruct: false,
    externallyProvidedExecution: false,
    modesAccountGroups: [],
    requirePreTradeMidMarketMark: false,
    allowExtendedSide: false,
    allowGoFlat: false,
    goFlatDefaultSpeculationType: 0,
  };
}

export const AccountSettings = {
  encode(message: AccountSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(146).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.accountId !== undefined && message.accountId !== 0) {
      writer.uint32(8).sint32(message.accountId);
    }
    if (message.obsoleteIsBlock !== undefined && message.obsoleteIsBlock !== false) {
      writer.uint32(16).bool(message.obsoleteIsBlock);
    }
    if (message.obsoleteBlockSize !== undefined && message.obsoleteBlockSize !== 0) {
      writer.uint32(24).sint32(message.obsoleteBlockSize);
    }
    if (message.isGiveup !== undefined && message.isGiveup !== false) {
      writer.uint32(32).bool(message.isGiveup);
    }
    if (message.obsoleteGiveupBrokerageName !== undefined && message.obsoleteGiveupBrokerageName !== "") {
      writer.uint32(42).string(message.obsoleteGiveupBrokerageName);
    }
    if (message.statementOption !== undefined && message.statementOption !== 0) {
      writer.uint32(48).uint32(message.statementOption);
    }
    if (message.zeroBalance !== undefined && message.zeroBalance !== false) {
      writer.uint32(56).bool(message.zeroBalance);
    }
    if (message.reconciliation !== undefined && message.reconciliation !== false) {
      writer.uint32(64).bool(message.reconciliation);
    }
    if (message.giveupBrokerageId !== undefined && message.giveupBrokerageId !== "") {
      writer.uint32(74).string(message.giveupBrokerageId);
    }
    if (message.tradingTimeFrom !== undefined && message.tradingTimeFrom !== "") {
      writer.uint32(82).string(message.tradingTimeFrom);
    }
    if (message.tradingTimeTo !== undefined && message.tradingTimeTo !== "") {
      writer.uint32(90).string(message.tradingTimeTo);
    }
    if (message.tradingTimeZone !== undefined && message.tradingTimeZone !== "") {
      writer.uint32(98).string(message.tradingTimeZone);
    }
    if (
      message.liquidationOrdersOutsideTradingTime !== undefined && message.liquidationOrdersOutsideTradingTime !== false
    ) {
      writer.uint32(104).bool(message.liquidationOrdersOutsideTradingTime);
    }
    if (message.isInstruct !== undefined && message.isInstruct !== false) {
      writer.uint32(112).bool(message.isInstruct);
    }
    if (message.externallyProvidedExecution !== undefined && message.externallyProvidedExecution !== false) {
      writer.uint32(120).bool(message.externallyProvidedExecution);
    }
    for (const v of message.modesAccountGroups) {
      writer.uint32(130).string(v!);
    }
    if (message.requirePreTradeMidMarketMark !== undefined && message.requirePreTradeMidMarketMark !== false) {
      writer.uint32(136).bool(message.requirePreTradeMidMarketMark);
    }
    if (message.allowExtendedSide !== undefined && message.allowExtendedSide !== false) {
      writer.uint32(152).bool(message.allowExtendedSide);
    }
    if (message.allowGoFlat !== undefined && message.allowGoFlat !== false) {
      writer.uint32(160).bool(message.allowGoFlat);
    }
    if (message.goFlatDefaultSpeculationType !== undefined && message.goFlatDefaultSpeculationType !== 0) {
      writer.uint32(168).uint32(message.goFlatDefaultSpeculationType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 18:
          if (tag === 144) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 146) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.obsoleteIsBlock = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.obsoleteBlockSize = reader.sint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isGiveup = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.obsoleteGiveupBrokerageName = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.statementOption = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.zeroBalance = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.reconciliation = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.giveupBrokerageId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.tradingTimeFrom = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.tradingTimeTo = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.tradingTimeZone = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.liquidationOrdersOutsideTradingTime = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.isInstruct = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.externallyProvidedExecution = reader.bool();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.modesAccountGroups.push(reader.string());
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.requirePreTradeMidMarketMark = reader.bool();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.allowExtendedSide = reader.bool();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.allowGoFlat = reader.bool();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.goFlatDefaultSpeculationType = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountSettings {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      obsoleteIsBlock: isSet(object.obsoleteIsBlock) ? globalThis.Boolean(object.obsoleteIsBlock) : false,
      obsoleteBlockSize: isSet(object.obsoleteBlockSize) ? globalThis.Number(object.obsoleteBlockSize) : 0,
      isGiveup: isSet(object.isGiveup) ? globalThis.Boolean(object.isGiveup) : false,
      obsoleteGiveupBrokerageName: isSet(object.obsoleteGiveupBrokerageName)
        ? globalThis.String(object.obsoleteGiveupBrokerageName)
        : "",
      statementOption: isSet(object.statementOption) ? globalThis.Number(object.statementOption) : 0,
      zeroBalance: isSet(object.zeroBalance) ? globalThis.Boolean(object.zeroBalance) : false,
      reconciliation: isSet(object.reconciliation) ? globalThis.Boolean(object.reconciliation) : false,
      giveupBrokerageId: isSet(object.giveupBrokerageId) ? globalThis.String(object.giveupBrokerageId) : "",
      tradingTimeFrom: isSet(object.tradingTimeFrom) ? globalThis.String(object.tradingTimeFrom) : "",
      tradingTimeTo: isSet(object.tradingTimeTo) ? globalThis.String(object.tradingTimeTo) : "",
      tradingTimeZone: isSet(object.tradingTimeZone) ? globalThis.String(object.tradingTimeZone) : "",
      liquidationOrdersOutsideTradingTime: isSet(object.liquidationOrdersOutsideTradingTime)
        ? globalThis.Boolean(object.liquidationOrdersOutsideTradingTime)
        : false,
      isInstruct: isSet(object.isInstruct) ? globalThis.Boolean(object.isInstruct) : false,
      externallyProvidedExecution: isSet(object.externallyProvidedExecution)
        ? globalThis.Boolean(object.externallyProvidedExecution)
        : false,
      modesAccountGroups: globalThis.Array.isArray(object?.modesAccountGroups)
        ? object.modesAccountGroups.map((e: any) => globalThis.String(e))
        : [],
      requirePreTradeMidMarketMark: isSet(object.requirePreTradeMidMarketMark)
        ? globalThis.Boolean(object.requirePreTradeMidMarketMark)
        : false,
      allowExtendedSide: isSet(object.allowExtendedSide) ? globalThis.Boolean(object.allowExtendedSide) : false,
      allowGoFlat: isSet(object.allowGoFlat) ? globalThis.Boolean(object.allowGoFlat) : false,
      goFlatDefaultSpeculationType: isSet(object.goFlatDefaultSpeculationType)
        ? globalThis.Number(object.goFlatDefaultSpeculationType)
        : 0,
    };
  },

  toJSON(message: AccountSettings): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.accountId !== undefined && message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.obsoleteIsBlock !== undefined && message.obsoleteIsBlock !== false) {
      obj.obsoleteIsBlock = message.obsoleteIsBlock;
    }
    if (message.obsoleteBlockSize !== undefined && message.obsoleteBlockSize !== 0) {
      obj.obsoleteBlockSize = Math.round(message.obsoleteBlockSize);
    }
    if (message.isGiveup !== undefined && message.isGiveup !== false) {
      obj.isGiveup = message.isGiveup;
    }
    if (message.obsoleteGiveupBrokerageName !== undefined && message.obsoleteGiveupBrokerageName !== "") {
      obj.obsoleteGiveupBrokerageName = message.obsoleteGiveupBrokerageName;
    }
    if (message.statementOption !== undefined && message.statementOption !== 0) {
      obj.statementOption = Math.round(message.statementOption);
    }
    if (message.zeroBalance !== undefined && message.zeroBalance !== false) {
      obj.zeroBalance = message.zeroBalance;
    }
    if (message.reconciliation !== undefined && message.reconciliation !== false) {
      obj.reconciliation = message.reconciliation;
    }
    if (message.giveupBrokerageId !== undefined && message.giveupBrokerageId !== "") {
      obj.giveupBrokerageId = message.giveupBrokerageId;
    }
    if (message.tradingTimeFrom !== undefined && message.tradingTimeFrom !== "") {
      obj.tradingTimeFrom = message.tradingTimeFrom;
    }
    if (message.tradingTimeTo !== undefined && message.tradingTimeTo !== "") {
      obj.tradingTimeTo = message.tradingTimeTo;
    }
    if (message.tradingTimeZone !== undefined && message.tradingTimeZone !== "") {
      obj.tradingTimeZone = message.tradingTimeZone;
    }
    if (
      message.liquidationOrdersOutsideTradingTime !== undefined && message.liquidationOrdersOutsideTradingTime !== false
    ) {
      obj.liquidationOrdersOutsideTradingTime = message.liquidationOrdersOutsideTradingTime;
    }
    if (message.isInstruct !== undefined && message.isInstruct !== false) {
      obj.isInstruct = message.isInstruct;
    }
    if (message.externallyProvidedExecution !== undefined && message.externallyProvidedExecution !== false) {
      obj.externallyProvidedExecution = message.externallyProvidedExecution;
    }
    if (message.modesAccountGroups?.length) {
      obj.modesAccountGroups = message.modesAccountGroups;
    }
    if (message.requirePreTradeMidMarketMark !== undefined && message.requirePreTradeMidMarketMark !== false) {
      obj.requirePreTradeMidMarketMark = message.requirePreTradeMidMarketMark;
    }
    if (message.allowExtendedSide !== undefined && message.allowExtendedSide !== false) {
      obj.allowExtendedSide = message.allowExtendedSide;
    }
    if (message.allowGoFlat !== undefined && message.allowGoFlat !== false) {
      obj.allowGoFlat = message.allowGoFlat;
    }
    if (message.goFlatDefaultSpeculationType !== undefined && message.goFlatDefaultSpeculationType !== 0) {
      obj.goFlatDefaultSpeculationType = Math.round(message.goFlatDefaultSpeculationType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountSettings>, I>>(base?: I): AccountSettings {
    return AccountSettings.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountSettings>, I>>(object: I): AccountSettings {
    const message = createBaseAccountSettings();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.accountId = object.accountId ?? 0;
    message.obsoleteIsBlock = object.obsoleteIsBlock ?? false;
    message.obsoleteBlockSize = object.obsoleteBlockSize ?? 0;
    message.isGiveup = object.isGiveup ?? false;
    message.obsoleteGiveupBrokerageName = object.obsoleteGiveupBrokerageName ?? "";
    message.statementOption = object.statementOption ?? 0;
    message.zeroBalance = object.zeroBalance ?? false;
    message.reconciliation = object.reconciliation ?? false;
    message.giveupBrokerageId = object.giveupBrokerageId ?? "";
    message.tradingTimeFrom = object.tradingTimeFrom ?? "";
    message.tradingTimeTo = object.tradingTimeTo ?? "";
    message.tradingTimeZone = object.tradingTimeZone ?? "";
    message.liquidationOrdersOutsideTradingTime = object.liquidationOrdersOutsideTradingTime ?? false;
    message.isInstruct = object.isInstruct ?? false;
    message.externallyProvidedExecution = object.externallyProvidedExecution ?? false;
    message.modesAccountGroups = object.modesAccountGroups?.map((e) => e) || [];
    message.requirePreTradeMidMarketMark = object.requirePreTradeMidMarketMark ?? false;
    message.allowExtendedSide = object.allowExtendedSide ?? false;
    message.allowGoFlat = object.allowGoFlat ?? false;
    message.goFlatDefaultSpeculationType = object.goFlatDefaultSpeculationType ?? 0;
    return message;
  },
};

function createBaseRiskParameters(): RiskParameters {
  return {
    obsoleteAccountId: 0,
    liquidationOnly: false,
    allowFutures: false,
    allowOptions: 0,
    enforceTradeSizeLimit: false,
    obsoleteTradeSizeLimit: 0,
    enforceTradeMarginLimit: false,
    tradeMarginLimit: 0,
    enforceTradePriceLimitTicks: false,
    tradePriceLimitTicks: undefined,
    enforceCommodityPositionLimit: false,
    obsoleteCommodityPositionLimit: undefined,
    enforceContractPositionLimit: false,
    obsoleteContractPositionLimit: undefined,
    enforceMarginSubsystemParameters: false,
    purchasingPowerParameters: undefined,
    enforceDailyLossLimit: false,
    dailyLossLimit: undefined,
    enforceDeltaDailyLossLimit: false,
    deltaDailyLossLimit: undefined,
    maximumOrderRate: 0,
    rejectRiskyMarketOrders: false,
    enforceTradePriceLimitPercent: false,
    tradePriceLimitPercent: undefined,
    tradeSizeLimit: undefined,
    commodityPositionLimit: undefined,
    contractPositionLimit: undefined,
    lastTradingDateLimit: undefined,
    tradeSizeLimitWithModes: undefined,
    enforceTotalGrossOpenPositionLimit: false,
    totalGrossOpenPositionLimit: undefined,
    longOptionPremiumCheckAgainstPurchasingPower: false,
    useIntradayCurrencyRate: false,
  };
}

export const RiskParameters = {
  encode(message: RiskParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.obsoleteAccountId !== undefined && message.obsoleteAccountId !== 0) {
      writer.uint32(8).sint32(message.obsoleteAccountId);
    }
    if (message.liquidationOnly !== undefined && message.liquidationOnly !== false) {
      writer.uint32(16).bool(message.liquidationOnly);
    }
    if (message.allowFutures !== undefined && message.allowFutures !== false) {
      writer.uint32(24).bool(message.allowFutures);
    }
    if (message.allowOptions !== undefined && message.allowOptions !== 0) {
      writer.uint32(32).uint32(message.allowOptions);
    }
    if (message.enforceTradeSizeLimit !== undefined && message.enforceTradeSizeLimit !== false) {
      writer.uint32(40).bool(message.enforceTradeSizeLimit);
    }
    if (message.obsoleteTradeSizeLimit !== undefined && message.obsoleteTradeSizeLimit !== 0) {
      writer.uint32(48).sint32(message.obsoleteTradeSizeLimit);
    }
    if (message.enforceTradeMarginLimit !== undefined && message.enforceTradeMarginLimit !== false) {
      writer.uint32(56).bool(message.enforceTradeMarginLimit);
    }
    if (message.tradeMarginLimit !== undefined && message.tradeMarginLimit !== 0) {
      writer.uint32(65).double(message.tradeMarginLimit);
    }
    if (message.enforceTradePriceLimitTicks !== undefined && message.enforceTradePriceLimitTicks !== false) {
      writer.uint32(72).bool(message.enforceTradePriceLimitTicks);
    }
    if (message.tradePriceLimitTicks !== undefined) {
      TradePriceLimit.encode(message.tradePriceLimitTicks, writer.uint32(82).fork()).ldelim();
    }
    if (message.enforceCommodityPositionLimit !== undefined && message.enforceCommodityPositionLimit !== false) {
      writer.uint32(88).bool(message.enforceCommodityPositionLimit);
    }
    if (message.obsoleteCommodityPositionLimit !== undefined) {
      LimitValue.encode(message.obsoleteCommodityPositionLimit, writer.uint32(98).fork()).ldelim();
    }
    if (message.enforceContractPositionLimit !== undefined && message.enforceContractPositionLimit !== false) {
      writer.uint32(104).bool(message.enforceContractPositionLimit);
    }
    if (message.obsoleteContractPositionLimit !== undefined) {
      LimitValue.encode(message.obsoleteContractPositionLimit, writer.uint32(114).fork()).ldelim();
    }
    if (message.enforceMarginSubsystemParameters !== undefined && message.enforceMarginSubsystemParameters !== false) {
      writer.uint32(120).bool(message.enforceMarginSubsystemParameters);
    }
    if (message.purchasingPowerParameters !== undefined) {
      PurchasingPowerParameters.encode(message.purchasingPowerParameters, writer.uint32(130).fork()).ldelim();
    }
    if (message.enforceDailyLossLimit !== undefined && message.enforceDailyLossLimit !== false) {
      writer.uint32(136).bool(message.enforceDailyLossLimit);
    }
    if (message.dailyLossLimit !== undefined) {
      LossLimit.encode(message.dailyLossLimit, writer.uint32(146).fork()).ldelim();
    }
    if (message.enforceDeltaDailyLossLimit !== undefined && message.enforceDeltaDailyLossLimit !== false) {
      writer.uint32(152).bool(message.enforceDeltaDailyLossLimit);
    }
    if (message.deltaDailyLossLimit !== undefined) {
      LossLimit.encode(message.deltaDailyLossLimit, writer.uint32(162).fork()).ldelim();
    }
    if (message.maximumOrderRate !== undefined && message.maximumOrderRate !== 0) {
      writer.uint32(168).sint32(message.maximumOrderRate);
    }
    if (message.rejectRiskyMarketOrders !== undefined && message.rejectRiskyMarketOrders !== false) {
      writer.uint32(176).bool(message.rejectRiskyMarketOrders);
    }
    if (message.enforceTradePriceLimitPercent !== undefined && message.enforceTradePriceLimitPercent !== false) {
      writer.uint32(184).bool(message.enforceTradePriceLimitPercent);
    }
    if (message.tradePriceLimitPercent !== undefined) {
      TradePriceLimitPercent.encode(message.tradePriceLimitPercent, writer.uint32(194).fork()).ldelim();
    }
    if (message.tradeSizeLimit !== undefined) {
      Decimal.encode(message.tradeSizeLimit, writer.uint32(202).fork()).ldelim();
    }
    if (message.commodityPositionLimit !== undefined) {
      LimitValueDecimal.encode(message.commodityPositionLimit, writer.uint32(210).fork()).ldelim();
    }
    if (message.contractPositionLimit !== undefined) {
      LimitValueDecimal.encode(message.contractPositionLimit, writer.uint32(218).fork()).ldelim();
    }
    if (message.lastTradingDateLimit !== undefined) {
      LimitValue.encode(message.lastTradingDateLimit, writer.uint32(226).fork()).ldelim();
    }
    if (message.tradeSizeLimitWithModes !== undefined) {
      LimitValueDecimal.encode(message.tradeSizeLimitWithModes, writer.uint32(234).fork()).ldelim();
    }
    if (
      message.enforceTotalGrossOpenPositionLimit !== undefined && message.enforceTotalGrossOpenPositionLimit !== false
    ) {
      writer.uint32(240).bool(message.enforceTotalGrossOpenPositionLimit);
    }
    if (message.totalGrossOpenPositionLimit !== undefined) {
      LimitValueDecimal.encode(message.totalGrossOpenPositionLimit, writer.uint32(250).fork()).ldelim();
    }
    if (
      message.longOptionPremiumCheckAgainstPurchasingPower !== undefined &&
      message.longOptionPremiumCheckAgainstPurchasingPower !== false
    ) {
      writer.uint32(256).bool(message.longOptionPremiumCheckAgainstPurchasingPower);
    }
    if (message.useIntradayCurrencyRate !== undefined && message.useIntradayCurrencyRate !== false) {
      writer.uint32(264).bool(message.useIntradayCurrencyRate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RiskParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRiskParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.obsoleteAccountId = reader.sint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.liquidationOnly = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.allowFutures = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.allowOptions = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.enforceTradeSizeLimit = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.obsoleteTradeSizeLimit = reader.sint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.enforceTradeMarginLimit = reader.bool();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.tradeMarginLimit = reader.double();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.enforceTradePriceLimitTicks = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.tradePriceLimitTicks = TradePriceLimit.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.enforceCommodityPositionLimit = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.obsoleteCommodityPositionLimit = LimitValue.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.enforceContractPositionLimit = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.obsoleteContractPositionLimit = LimitValue.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.enforceMarginSubsystemParameters = reader.bool();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.purchasingPowerParameters = PurchasingPowerParameters.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.enforceDailyLossLimit = reader.bool();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.dailyLossLimit = LossLimit.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.enforceDeltaDailyLossLimit = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.deltaDailyLossLimit = LossLimit.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.maximumOrderRate = reader.sint32();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.rejectRiskyMarketOrders = reader.bool();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.enforceTradePriceLimitPercent = reader.bool();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.tradePriceLimitPercent = TradePriceLimitPercent.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.tradeSizeLimit = Decimal.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.commodityPositionLimit = LimitValueDecimal.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.contractPositionLimit = LimitValueDecimal.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.lastTradingDateLimit = LimitValue.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.tradeSizeLimitWithModes = LimitValueDecimal.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.enforceTotalGrossOpenPositionLimit = reader.bool();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.totalGrossOpenPositionLimit = LimitValueDecimal.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.longOptionPremiumCheckAgainstPurchasingPower = reader.bool();
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.useIntradayCurrencyRate = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RiskParameters {
    return {
      obsoleteAccountId: isSet(object.obsoleteAccountId) ? globalThis.Number(object.obsoleteAccountId) : 0,
      liquidationOnly: isSet(object.liquidationOnly) ? globalThis.Boolean(object.liquidationOnly) : false,
      allowFutures: isSet(object.allowFutures) ? globalThis.Boolean(object.allowFutures) : false,
      allowOptions: isSet(object.allowOptions) ? globalThis.Number(object.allowOptions) : 0,
      enforceTradeSizeLimit: isSet(object.enforceTradeSizeLimit)
        ? globalThis.Boolean(object.enforceTradeSizeLimit)
        : false,
      obsoleteTradeSizeLimit: isSet(object.obsoleteTradeSizeLimit)
        ? globalThis.Number(object.obsoleteTradeSizeLimit)
        : 0,
      enforceTradeMarginLimit: isSet(object.enforceTradeMarginLimit)
        ? globalThis.Boolean(object.enforceTradeMarginLimit)
        : false,
      tradeMarginLimit: isSet(object.tradeMarginLimit) ? globalThis.Number(object.tradeMarginLimit) : 0,
      enforceTradePriceLimitTicks: isSet(object.enforceTradePriceLimitTicks)
        ? globalThis.Boolean(object.enforceTradePriceLimitTicks)
        : false,
      tradePriceLimitTicks: isSet(object.tradePriceLimitTicks)
        ? TradePriceLimit.fromJSON(object.tradePriceLimitTicks)
        : undefined,
      enforceCommodityPositionLimit: isSet(object.enforceCommodityPositionLimit)
        ? globalThis.Boolean(object.enforceCommodityPositionLimit)
        : false,
      obsoleteCommodityPositionLimit: isSet(object.obsoleteCommodityPositionLimit)
        ? LimitValue.fromJSON(object.obsoleteCommodityPositionLimit)
        : undefined,
      enforceContractPositionLimit: isSet(object.enforceContractPositionLimit)
        ? globalThis.Boolean(object.enforceContractPositionLimit)
        : false,
      obsoleteContractPositionLimit: isSet(object.obsoleteContractPositionLimit)
        ? LimitValue.fromJSON(object.obsoleteContractPositionLimit)
        : undefined,
      enforceMarginSubsystemParameters: isSet(object.enforceMarginSubsystemParameters)
        ? globalThis.Boolean(object.enforceMarginSubsystemParameters)
        : false,
      purchasingPowerParameters: isSet(object.purchasingPowerParameters)
        ? PurchasingPowerParameters.fromJSON(object.purchasingPowerParameters)
        : undefined,
      enforceDailyLossLimit: isSet(object.enforceDailyLossLimit)
        ? globalThis.Boolean(object.enforceDailyLossLimit)
        : false,
      dailyLossLimit: isSet(object.dailyLossLimit) ? LossLimit.fromJSON(object.dailyLossLimit) : undefined,
      enforceDeltaDailyLossLimit: isSet(object.enforceDeltaDailyLossLimit)
        ? globalThis.Boolean(object.enforceDeltaDailyLossLimit)
        : false,
      deltaDailyLossLimit: isSet(object.deltaDailyLossLimit)
        ? LossLimit.fromJSON(object.deltaDailyLossLimit)
        : undefined,
      maximumOrderRate: isSet(object.maximumOrderRate) ? globalThis.Number(object.maximumOrderRate) : 0,
      rejectRiskyMarketOrders: isSet(object.rejectRiskyMarketOrders)
        ? globalThis.Boolean(object.rejectRiskyMarketOrders)
        : false,
      enforceTradePriceLimitPercent: isSet(object.enforceTradePriceLimitPercent)
        ? globalThis.Boolean(object.enforceTradePriceLimitPercent)
        : false,
      tradePriceLimitPercent: isSet(object.tradePriceLimitPercent)
        ? TradePriceLimitPercent.fromJSON(object.tradePriceLimitPercent)
        : undefined,
      tradeSizeLimit: isSet(object.tradeSizeLimit) ? Decimal.fromJSON(object.tradeSizeLimit) : undefined,
      commodityPositionLimit: isSet(object.commodityPositionLimit)
        ? LimitValueDecimal.fromJSON(object.commodityPositionLimit)
        : undefined,
      contractPositionLimit: isSet(object.contractPositionLimit)
        ? LimitValueDecimal.fromJSON(object.contractPositionLimit)
        : undefined,
      lastTradingDateLimit: isSet(object.lastTradingDateLimit)
        ? LimitValue.fromJSON(object.lastTradingDateLimit)
        : undefined,
      tradeSizeLimitWithModes: isSet(object.tradeSizeLimitWithModes)
        ? LimitValueDecimal.fromJSON(object.tradeSizeLimitWithModes)
        : undefined,
      enforceTotalGrossOpenPositionLimit: isSet(object.enforceTotalGrossOpenPositionLimit)
        ? globalThis.Boolean(object.enforceTotalGrossOpenPositionLimit)
        : false,
      totalGrossOpenPositionLimit: isSet(object.totalGrossOpenPositionLimit)
        ? LimitValueDecimal.fromJSON(object.totalGrossOpenPositionLimit)
        : undefined,
      longOptionPremiumCheckAgainstPurchasingPower: isSet(object.longOptionPremiumCheckAgainstPurchasingPower)
        ? globalThis.Boolean(object.longOptionPremiumCheckAgainstPurchasingPower)
        : false,
      useIntradayCurrencyRate: isSet(object.useIntradayCurrencyRate)
        ? globalThis.Boolean(object.useIntradayCurrencyRate)
        : false,
    };
  },

  toJSON(message: RiskParameters): unknown {
    const obj: any = {};
    if (message.obsoleteAccountId !== undefined && message.obsoleteAccountId !== 0) {
      obj.obsoleteAccountId = Math.round(message.obsoleteAccountId);
    }
    if (message.liquidationOnly !== undefined && message.liquidationOnly !== false) {
      obj.liquidationOnly = message.liquidationOnly;
    }
    if (message.allowFutures !== undefined && message.allowFutures !== false) {
      obj.allowFutures = message.allowFutures;
    }
    if (message.allowOptions !== undefined && message.allowOptions !== 0) {
      obj.allowOptions = Math.round(message.allowOptions);
    }
    if (message.enforceTradeSizeLimit !== undefined && message.enforceTradeSizeLimit !== false) {
      obj.enforceTradeSizeLimit = message.enforceTradeSizeLimit;
    }
    if (message.obsoleteTradeSizeLimit !== undefined && message.obsoleteTradeSizeLimit !== 0) {
      obj.obsoleteTradeSizeLimit = Math.round(message.obsoleteTradeSizeLimit);
    }
    if (message.enforceTradeMarginLimit !== undefined && message.enforceTradeMarginLimit !== false) {
      obj.enforceTradeMarginLimit = message.enforceTradeMarginLimit;
    }
    if (message.tradeMarginLimit !== undefined && message.tradeMarginLimit !== 0) {
      obj.tradeMarginLimit = message.tradeMarginLimit;
    }
    if (message.enforceTradePriceLimitTicks !== undefined && message.enforceTradePriceLimitTicks !== false) {
      obj.enforceTradePriceLimitTicks = message.enforceTradePriceLimitTicks;
    }
    if (message.tradePriceLimitTicks !== undefined) {
      obj.tradePriceLimitTicks = TradePriceLimit.toJSON(message.tradePriceLimitTicks);
    }
    if (message.enforceCommodityPositionLimit !== undefined && message.enforceCommodityPositionLimit !== false) {
      obj.enforceCommodityPositionLimit = message.enforceCommodityPositionLimit;
    }
    if (message.obsoleteCommodityPositionLimit !== undefined) {
      obj.obsoleteCommodityPositionLimit = LimitValue.toJSON(message.obsoleteCommodityPositionLimit);
    }
    if (message.enforceContractPositionLimit !== undefined && message.enforceContractPositionLimit !== false) {
      obj.enforceContractPositionLimit = message.enforceContractPositionLimit;
    }
    if (message.obsoleteContractPositionLimit !== undefined) {
      obj.obsoleteContractPositionLimit = LimitValue.toJSON(message.obsoleteContractPositionLimit);
    }
    if (message.enforceMarginSubsystemParameters !== undefined && message.enforceMarginSubsystemParameters !== false) {
      obj.enforceMarginSubsystemParameters = message.enforceMarginSubsystemParameters;
    }
    if (message.purchasingPowerParameters !== undefined) {
      obj.purchasingPowerParameters = PurchasingPowerParameters.toJSON(message.purchasingPowerParameters);
    }
    if (message.enforceDailyLossLimit !== undefined && message.enforceDailyLossLimit !== false) {
      obj.enforceDailyLossLimit = message.enforceDailyLossLimit;
    }
    if (message.dailyLossLimit !== undefined) {
      obj.dailyLossLimit = LossLimit.toJSON(message.dailyLossLimit);
    }
    if (message.enforceDeltaDailyLossLimit !== undefined && message.enforceDeltaDailyLossLimit !== false) {
      obj.enforceDeltaDailyLossLimit = message.enforceDeltaDailyLossLimit;
    }
    if (message.deltaDailyLossLimit !== undefined) {
      obj.deltaDailyLossLimit = LossLimit.toJSON(message.deltaDailyLossLimit);
    }
    if (message.maximumOrderRate !== undefined && message.maximumOrderRate !== 0) {
      obj.maximumOrderRate = Math.round(message.maximumOrderRate);
    }
    if (message.rejectRiskyMarketOrders !== undefined && message.rejectRiskyMarketOrders !== false) {
      obj.rejectRiskyMarketOrders = message.rejectRiskyMarketOrders;
    }
    if (message.enforceTradePriceLimitPercent !== undefined && message.enforceTradePriceLimitPercent !== false) {
      obj.enforceTradePriceLimitPercent = message.enforceTradePriceLimitPercent;
    }
    if (message.tradePriceLimitPercent !== undefined) {
      obj.tradePriceLimitPercent = TradePriceLimitPercent.toJSON(message.tradePriceLimitPercent);
    }
    if (message.tradeSizeLimit !== undefined) {
      obj.tradeSizeLimit = Decimal.toJSON(message.tradeSizeLimit);
    }
    if (message.commodityPositionLimit !== undefined) {
      obj.commodityPositionLimit = LimitValueDecimal.toJSON(message.commodityPositionLimit);
    }
    if (message.contractPositionLimit !== undefined) {
      obj.contractPositionLimit = LimitValueDecimal.toJSON(message.contractPositionLimit);
    }
    if (message.lastTradingDateLimit !== undefined) {
      obj.lastTradingDateLimit = LimitValue.toJSON(message.lastTradingDateLimit);
    }
    if (message.tradeSizeLimitWithModes !== undefined) {
      obj.tradeSizeLimitWithModes = LimitValueDecimal.toJSON(message.tradeSizeLimitWithModes);
    }
    if (
      message.enforceTotalGrossOpenPositionLimit !== undefined && message.enforceTotalGrossOpenPositionLimit !== false
    ) {
      obj.enforceTotalGrossOpenPositionLimit = message.enforceTotalGrossOpenPositionLimit;
    }
    if (message.totalGrossOpenPositionLimit !== undefined) {
      obj.totalGrossOpenPositionLimit = LimitValueDecimal.toJSON(message.totalGrossOpenPositionLimit);
    }
    if (
      message.longOptionPremiumCheckAgainstPurchasingPower !== undefined &&
      message.longOptionPremiumCheckAgainstPurchasingPower !== false
    ) {
      obj.longOptionPremiumCheckAgainstPurchasingPower = message.longOptionPremiumCheckAgainstPurchasingPower;
    }
    if (message.useIntradayCurrencyRate !== undefined && message.useIntradayCurrencyRate !== false) {
      obj.useIntradayCurrencyRate = message.useIntradayCurrencyRate;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RiskParameters>, I>>(base?: I): RiskParameters {
    return RiskParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RiskParameters>, I>>(object: I): RiskParameters {
    const message = createBaseRiskParameters();
    message.obsoleteAccountId = object.obsoleteAccountId ?? 0;
    message.liquidationOnly = object.liquidationOnly ?? false;
    message.allowFutures = object.allowFutures ?? false;
    message.allowOptions = object.allowOptions ?? 0;
    message.enforceTradeSizeLimit = object.enforceTradeSizeLimit ?? false;
    message.obsoleteTradeSizeLimit = object.obsoleteTradeSizeLimit ?? 0;
    message.enforceTradeMarginLimit = object.enforceTradeMarginLimit ?? false;
    message.tradeMarginLimit = object.tradeMarginLimit ?? 0;
    message.enforceTradePriceLimitTicks = object.enforceTradePriceLimitTicks ?? false;
    message.tradePriceLimitTicks = (object.tradePriceLimitTicks !== undefined && object.tradePriceLimitTicks !== null)
      ? TradePriceLimit.fromPartial(object.tradePriceLimitTicks)
      : undefined;
    message.enforceCommodityPositionLimit = object.enforceCommodityPositionLimit ?? false;
    message.obsoleteCommodityPositionLimit =
      (object.obsoleteCommodityPositionLimit !== undefined && object.obsoleteCommodityPositionLimit !== null)
        ? LimitValue.fromPartial(object.obsoleteCommodityPositionLimit)
        : undefined;
    message.enforceContractPositionLimit = object.enforceContractPositionLimit ?? false;
    message.obsoleteContractPositionLimit =
      (object.obsoleteContractPositionLimit !== undefined && object.obsoleteContractPositionLimit !== null)
        ? LimitValue.fromPartial(object.obsoleteContractPositionLimit)
        : undefined;
    message.enforceMarginSubsystemParameters = object.enforceMarginSubsystemParameters ?? false;
    message.purchasingPowerParameters =
      (object.purchasingPowerParameters !== undefined && object.purchasingPowerParameters !== null)
        ? PurchasingPowerParameters.fromPartial(object.purchasingPowerParameters)
        : undefined;
    message.enforceDailyLossLimit = object.enforceDailyLossLimit ?? false;
    message.dailyLossLimit = (object.dailyLossLimit !== undefined && object.dailyLossLimit !== null)
      ? LossLimit.fromPartial(object.dailyLossLimit)
      : undefined;
    message.enforceDeltaDailyLossLimit = object.enforceDeltaDailyLossLimit ?? false;
    message.deltaDailyLossLimit = (object.deltaDailyLossLimit !== undefined && object.deltaDailyLossLimit !== null)
      ? LossLimit.fromPartial(object.deltaDailyLossLimit)
      : undefined;
    message.maximumOrderRate = object.maximumOrderRate ?? 0;
    message.rejectRiskyMarketOrders = object.rejectRiskyMarketOrders ?? false;
    message.enforceTradePriceLimitPercent = object.enforceTradePriceLimitPercent ?? false;
    message.tradePriceLimitPercent =
      (object.tradePriceLimitPercent !== undefined && object.tradePriceLimitPercent !== null)
        ? TradePriceLimitPercent.fromPartial(object.tradePriceLimitPercent)
        : undefined;
    message.tradeSizeLimit = (object.tradeSizeLimit !== undefined && object.tradeSizeLimit !== null)
      ? Decimal.fromPartial(object.tradeSizeLimit)
      : undefined;
    message.commodityPositionLimit =
      (object.commodityPositionLimit !== undefined && object.commodityPositionLimit !== null)
        ? LimitValueDecimal.fromPartial(object.commodityPositionLimit)
        : undefined;
    message.contractPositionLimit =
      (object.contractPositionLimit !== undefined && object.contractPositionLimit !== null)
        ? LimitValueDecimal.fromPartial(object.contractPositionLimit)
        : undefined;
    message.lastTradingDateLimit = (object.lastTradingDateLimit !== undefined && object.lastTradingDateLimit !== null)
      ? LimitValue.fromPartial(object.lastTradingDateLimit)
      : undefined;
    message.tradeSizeLimitWithModes =
      (object.tradeSizeLimitWithModes !== undefined && object.tradeSizeLimitWithModes !== null)
        ? LimitValueDecimal.fromPartial(object.tradeSizeLimitWithModes)
        : undefined;
    message.enforceTotalGrossOpenPositionLimit = object.enforceTotalGrossOpenPositionLimit ?? false;
    message.totalGrossOpenPositionLimit =
      (object.totalGrossOpenPositionLimit !== undefined && object.totalGrossOpenPositionLimit !== null)
        ? LimitValueDecimal.fromPartial(object.totalGrossOpenPositionLimit)
        : undefined;
    message.longOptionPremiumCheckAgainstPurchasingPower = object.longOptionPremiumCheckAgainstPurchasingPower ?? false;
    message.useIntradayCurrencyRate = object.useIntradayCurrencyRate ?? false;
    return message;
  },
};

function createBaseAccountGroup(): AccountGroup {
  return { groupId: "", masterAccountId: "", relationType: 0, subAccounts: [] };
}

export const AccountGroup = {
  encode(message: AccountGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupId !== undefined && message.groupId !== "") {
      writer.uint32(10).string(message.groupId);
    }
    if (message.masterAccountId !== undefined && message.masterAccountId !== "") {
      writer.uint32(18).string(message.masterAccountId);
    }
    if (message.relationType !== undefined && message.relationType !== 0) {
      writer.uint32(24).uint32(message.relationType);
    }
    for (const v of message.subAccounts) {
      SubAccount.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.masterAccountId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.relationType = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.subAccounts.push(SubAccount.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountGroup {
    return {
      groupId: isSet(object.groupId) ? globalThis.String(object.groupId) : "",
      masterAccountId: isSet(object.masterAccountId) ? globalThis.String(object.masterAccountId) : "",
      relationType: isSet(object.relationType) ? globalThis.Number(object.relationType) : 0,
      subAccounts: globalThis.Array.isArray(object?.subAccounts)
        ? object.subAccounts.map((e: any) => SubAccount.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountGroup): unknown {
    const obj: any = {};
    if (message.groupId !== undefined && message.groupId !== "") {
      obj.groupId = message.groupId;
    }
    if (message.masterAccountId !== undefined && message.masterAccountId !== "") {
      obj.masterAccountId = message.masterAccountId;
    }
    if (message.relationType !== undefined && message.relationType !== 0) {
      obj.relationType = Math.round(message.relationType);
    }
    if (message.subAccounts?.length) {
      obj.subAccounts = message.subAccounts.map((e) => SubAccount.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountGroup>, I>>(base?: I): AccountGroup {
    return AccountGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountGroup>, I>>(object: I): AccountGroup {
    const message = createBaseAccountGroup();
    message.groupId = object.groupId ?? "";
    message.masterAccountId = object.masterAccountId ?? "";
    message.relationType = object.relationType ?? 0;
    message.subAccounts = object.subAccounts?.map((e) => SubAccount.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSubAccount(): SubAccount {
  return { accountId: "", accountName: "", brokerageAccountNumber: "", purchasingPowerFraction: 0 };
}

export const SubAccount = {
  encode(message: SubAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    if (message.accountName !== undefined && message.accountName !== "") {
      writer.uint32(18).string(message.accountName);
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      writer.uint32(26).string(message.brokerageAccountNumber);
    }
    if (message.purchasingPowerFraction !== undefined && message.purchasingPowerFraction !== 0) {
      writer.uint32(33).double(message.purchasingPowerFraction);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.brokerageAccountNumber = reader.string();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.purchasingPowerFraction = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubAccount {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      accountName: isSet(object.accountName) ? globalThis.String(object.accountName) : "",
      brokerageAccountNumber: isSet(object.brokerageAccountNumber)
        ? globalThis.String(object.brokerageAccountNumber)
        : "",
      purchasingPowerFraction: isSet(object.purchasingPowerFraction)
        ? globalThis.Number(object.purchasingPowerFraction)
        : 0,
    };
  },

  toJSON(message: SubAccount): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.accountName !== undefined && message.accountName !== "") {
      obj.accountName = message.accountName;
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      obj.brokerageAccountNumber = message.brokerageAccountNumber;
    }
    if (message.purchasingPowerFraction !== undefined && message.purchasingPowerFraction !== 0) {
      obj.purchasingPowerFraction = message.purchasingPowerFraction;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubAccount>, I>>(base?: I): SubAccount {
    return SubAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubAccount>, I>>(object: I): SubAccount {
    const message = createBaseSubAccount();
    message.accountId = object.accountId ?? "";
    message.accountName = object.accountName ?? "";
    message.brokerageAccountNumber = object.brokerageAccountNumber ?? "";
    message.purchasingPowerFraction = object.purchasingPowerFraction ?? 0;
    return message;
  },
};

function createBaseTradePriceLimit(): TradePriceLimit {
  return { mode: 0, obsoleteTicks: 0, value: undefined };
}

export const TradePriceLimit = {
  encode(message: TradePriceLimit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mode !== undefined && message.mode !== 0) {
      writer.uint32(8).uint32(message.mode);
    }
    if (message.obsoleteTicks !== undefined && message.obsoleteTicks !== 0) {
      writer.uint32(16).sint32(message.obsoleteTicks);
    }
    if (message.value !== undefined) {
      LimitValue.encode(message.value, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradePriceLimit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradePriceLimit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.mode = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.obsoleteTicks = reader.sint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = LimitValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradePriceLimit {
    return {
      mode: isSet(object.mode) ? globalThis.Number(object.mode) : 0,
      obsoleteTicks: isSet(object.obsoleteTicks) ? globalThis.Number(object.obsoleteTicks) : 0,
      value: isSet(object.value) ? LimitValue.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: TradePriceLimit): unknown {
    const obj: any = {};
    if (message.mode !== undefined && message.mode !== 0) {
      obj.mode = Math.round(message.mode);
    }
    if (message.obsoleteTicks !== undefined && message.obsoleteTicks !== 0) {
      obj.obsoleteTicks = Math.round(message.obsoleteTicks);
    }
    if (message.value !== undefined) {
      obj.value = LimitValue.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradePriceLimit>, I>>(base?: I): TradePriceLimit {
    return TradePriceLimit.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradePriceLimit>, I>>(object: I): TradePriceLimit {
    const message = createBaseTradePriceLimit();
    message.mode = object.mode ?? 0;
    message.obsoleteTicks = object.obsoleteTicks ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? LimitValue.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseTradePriceLimitPercent(): TradePriceLimitPercent {
  return { mode: 0, value: undefined };
}

export const TradePriceLimitPercent = {
  encode(message: TradePriceLimitPercent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mode !== undefined && message.mode !== 0) {
      writer.uint32(8).uint32(message.mode);
    }
    if (message.value !== undefined) {
      LimitValueDouble.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradePriceLimitPercent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradePriceLimitPercent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.mode = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = LimitValueDouble.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradePriceLimitPercent {
    return {
      mode: isSet(object.mode) ? globalThis.Number(object.mode) : 0,
      value: isSet(object.value) ? LimitValueDouble.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: TradePriceLimitPercent): unknown {
    const obj: any = {};
    if (message.mode !== undefined && message.mode !== 0) {
      obj.mode = Math.round(message.mode);
    }
    if (message.value !== undefined) {
      obj.value = LimitValueDouble.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradePriceLimitPercent>, I>>(base?: I): TradePriceLimitPercent {
    return TradePriceLimitPercent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradePriceLimitPercent>, I>>(object: I): TradePriceLimitPercent {
    const message = createBaseTradePriceLimitPercent();
    message.mode = object.mode ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? LimitValueDouble.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseLimitValue(): LimitValue {
  return { mode: 0, value: 0, obsoleteExpirationLimit: [], enforce: false };
}

export const LimitValue = {
  encode(message: LimitValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mode !== undefined && message.mode !== 0) {
      writer.uint32(8).uint32(message.mode);
    }
    if (message.value !== undefined && message.value !== 0) {
      writer.uint32(16).sint32(message.value);
    }
    for (const v of message.obsoleteExpirationLimit) {
      ExpirationLimit.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.enforce !== undefined && message.enforce !== false) {
      writer.uint32(32).bool(message.enforce);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LimitValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLimitValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.mode = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.sint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.obsoleteExpirationLimit.push(ExpirationLimit.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enforce = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LimitValue {
    return {
      mode: isSet(object.mode) ? globalThis.Number(object.mode) : 0,
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
      obsoleteExpirationLimit: globalThis.Array.isArray(object?.obsoleteExpirationLimit)
        ? object.obsoleteExpirationLimit.map((e: any) => ExpirationLimit.fromJSON(e))
        : [],
      enforce: isSet(object.enforce) ? globalThis.Boolean(object.enforce) : false,
    };
  },

  toJSON(message: LimitValue): unknown {
    const obj: any = {};
    if (message.mode !== undefined && message.mode !== 0) {
      obj.mode = Math.round(message.mode);
    }
    if (message.value !== undefined && message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    if (message.obsoleteExpirationLimit?.length) {
      obj.obsoleteExpirationLimit = message.obsoleteExpirationLimit.map((e) => ExpirationLimit.toJSON(e));
    }
    if (message.enforce !== undefined && message.enforce !== false) {
      obj.enforce = message.enforce;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LimitValue>, I>>(base?: I): LimitValue {
    return LimitValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LimitValue>, I>>(object: I): LimitValue {
    const message = createBaseLimitValue();
    message.mode = object.mode ?? 0;
    message.value = object.value ?? 0;
    message.obsoleteExpirationLimit = object.obsoleteExpirationLimit?.map((e) => ExpirationLimit.fromPartial(e)) || [];
    message.enforce = object.enforce ?? false;
    return message;
  },
};

function createBaseLimitValueDouble(): LimitValueDouble {
  return { mode: 0, value: 0, enforce: false };
}

export const LimitValueDouble = {
  encode(message: LimitValueDouble, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mode !== undefined && message.mode !== 0) {
      writer.uint32(8).uint32(message.mode);
    }
    if (message.value !== undefined && message.value !== 0) {
      writer.uint32(17).double(message.value);
    }
    if (message.enforce !== undefined && message.enforce !== false) {
      writer.uint32(24).bool(message.enforce);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LimitValueDouble {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLimitValueDouble();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.mode = reader.uint32();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.value = reader.double();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.enforce = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LimitValueDouble {
    return {
      mode: isSet(object.mode) ? globalThis.Number(object.mode) : 0,
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
      enforce: isSet(object.enforce) ? globalThis.Boolean(object.enforce) : false,
    };
  },

  toJSON(message: LimitValueDouble): unknown {
    const obj: any = {};
    if (message.mode !== undefined && message.mode !== 0) {
      obj.mode = Math.round(message.mode);
    }
    if (message.value !== undefined && message.value !== 0) {
      obj.value = message.value;
    }
    if (message.enforce !== undefined && message.enforce !== false) {
      obj.enforce = message.enforce;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LimitValueDouble>, I>>(base?: I): LimitValueDouble {
    return LimitValueDouble.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LimitValueDouble>, I>>(object: I): LimitValueDouble {
    const message = createBaseLimitValueDouble();
    message.mode = object.mode ?? 0;
    message.value = object.value ?? 0;
    message.enforce = object.enforce ?? false;
    return message;
  },
};

function createBaseLimitValueDecimal(): LimitValueDecimal {
  return { clearedFields: [], mode: 0, value: undefined, expirationLimit: [], enforce: false, loginEnforce: false };
}

export const LimitValueDecimal = {
  encode(message: LimitValueDecimal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(42).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.mode !== undefined && message.mode !== 0) {
      writer.uint32(8).uint32(message.mode);
    }
    if (message.value !== undefined) {
      Decimal.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.expirationLimit) {
      ExpirationLimitDecimal.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.enforce !== undefined && message.enforce !== false) {
      writer.uint32(32).bool(message.enforce);
    }
    if (message.loginEnforce !== undefined && message.loginEnforce !== false) {
      writer.uint32(48).bool(message.loginEnforce);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LimitValueDecimal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLimitValueDecimal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag === 40) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.mode = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Decimal.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.expirationLimit.push(ExpirationLimitDecimal.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enforce = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.loginEnforce = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LimitValueDecimal {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      mode: isSet(object.mode) ? globalThis.Number(object.mode) : 0,
      value: isSet(object.value) ? Decimal.fromJSON(object.value) : undefined,
      expirationLimit: globalThis.Array.isArray(object?.expirationLimit)
        ? object.expirationLimit.map((e: any) => ExpirationLimitDecimal.fromJSON(e))
        : [],
      enforce: isSet(object.enforce) ? globalThis.Boolean(object.enforce) : false,
      loginEnforce: isSet(object.loginEnforce) ? globalThis.Boolean(object.loginEnforce) : false,
    };
  },

  toJSON(message: LimitValueDecimal): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.mode !== undefined && message.mode !== 0) {
      obj.mode = Math.round(message.mode);
    }
    if (message.value !== undefined) {
      obj.value = Decimal.toJSON(message.value);
    }
    if (message.expirationLimit?.length) {
      obj.expirationLimit = message.expirationLimit.map((e) => ExpirationLimitDecimal.toJSON(e));
    }
    if (message.enforce !== undefined && message.enforce !== false) {
      obj.enforce = message.enforce;
    }
    if (message.loginEnforce !== undefined && message.loginEnforce !== false) {
      obj.loginEnforce = message.loginEnforce;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LimitValueDecimal>, I>>(base?: I): LimitValueDecimal {
    return LimitValueDecimal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LimitValueDecimal>, I>>(object: I): LimitValueDecimal {
    const message = createBaseLimitValueDecimal();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.mode = object.mode ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? Decimal.fromPartial(object.value)
      : undefined;
    message.expirationLimit = object.expirationLimit?.map((e) => ExpirationLimitDecimal.fromPartial(e)) || [];
    message.enforce = object.enforce ?? false;
    message.loginEnforce = object.loginEnforce ?? false;
    return message;
  },
};

function createBaseLimitValueBoolean(): LimitValueBoolean {
  return { mode: 0, value: false, enforce: false };
}

export const LimitValueBoolean = {
  encode(message: LimitValueBoolean, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.mode !== undefined && message.mode !== 0) {
      writer.uint32(8).uint32(message.mode);
    }
    if (message.value !== undefined && message.value !== false) {
      writer.uint32(16).bool(message.value);
    }
    if (message.enforce !== undefined && message.enforce !== false) {
      writer.uint32(24).bool(message.enforce);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LimitValueBoolean {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLimitValueBoolean();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.mode = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.enforce = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LimitValueBoolean {
    return {
      mode: isSet(object.mode) ? globalThis.Number(object.mode) : 0,
      value: isSet(object.value) ? globalThis.Boolean(object.value) : false,
      enforce: isSet(object.enforce) ? globalThis.Boolean(object.enforce) : false,
    };
  },

  toJSON(message: LimitValueBoolean): unknown {
    const obj: any = {};
    if (message.mode !== undefined && message.mode !== 0) {
      obj.mode = Math.round(message.mode);
    }
    if (message.value !== undefined && message.value !== false) {
      obj.value = message.value;
    }
    if (message.enforce !== undefined && message.enforce !== false) {
      obj.enforce = message.enforce;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LimitValueBoolean>, I>>(base?: I): LimitValueBoolean {
    return LimitValueBoolean.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LimitValueBoolean>, I>>(object: I): LimitValueBoolean {
    const message = createBaseLimitValueBoolean();
    message.mode = object.mode ?? 0;
    message.value = object.value ?? false;
    message.enforce = object.enforce ?? false;
    return message;
  },
};

function createBaseExpirationLimit(): ExpirationLimit {
  return { daysBeforeExpiration: 0, value: 0 };
}

export const ExpirationLimit = {
  encode(message: ExpirationLimit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.daysBeforeExpiration !== undefined && message.daysBeforeExpiration !== 0) {
      writer.uint32(8).uint32(message.daysBeforeExpiration);
    }
    if (message.value !== undefined && message.value !== 0) {
      writer.uint32(16).sint32(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpirationLimit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpirationLimit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.daysBeforeExpiration = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.sint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpirationLimit {
    return {
      daysBeforeExpiration: isSet(object.daysBeforeExpiration) ? globalThis.Number(object.daysBeforeExpiration) : 0,
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: ExpirationLimit): unknown {
    const obj: any = {};
    if (message.daysBeforeExpiration !== undefined && message.daysBeforeExpiration !== 0) {
      obj.daysBeforeExpiration = Math.round(message.daysBeforeExpiration);
    }
    if (message.value !== undefined && message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpirationLimit>, I>>(base?: I): ExpirationLimit {
    return ExpirationLimit.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExpirationLimit>, I>>(object: I): ExpirationLimit {
    const message = createBaseExpirationLimit();
    message.daysBeforeExpiration = object.daysBeforeExpiration ?? 0;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseExpirationLimitDecimal(): ExpirationLimitDecimal {
  return { daysBeforeExpiration: 0, value: undefined };
}

export const ExpirationLimitDecimal = {
  encode(message: ExpirationLimitDecimal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.daysBeforeExpiration !== undefined && message.daysBeforeExpiration !== 0) {
      writer.uint32(8).uint32(message.daysBeforeExpiration);
    }
    if (message.value !== undefined) {
      Decimal.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExpirationLimitDecimal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExpirationLimitDecimal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.daysBeforeExpiration = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Decimal.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExpirationLimitDecimal {
    return {
      daysBeforeExpiration: isSet(object.daysBeforeExpiration) ? globalThis.Number(object.daysBeforeExpiration) : 0,
      value: isSet(object.value) ? Decimal.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ExpirationLimitDecimal): unknown {
    const obj: any = {};
    if (message.daysBeforeExpiration !== undefined && message.daysBeforeExpiration !== 0) {
      obj.daysBeforeExpiration = Math.round(message.daysBeforeExpiration);
    }
    if (message.value !== undefined) {
      obj.value = Decimal.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExpirationLimitDecimal>, I>>(base?: I): ExpirationLimitDecimal {
    return ExpirationLimitDecimal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExpirationLimitDecimal>, I>>(object: I): ExpirationLimitDecimal {
    const message = createBaseExpirationLimitDecimal();
    message.daysBeforeExpiration = object.daysBeforeExpiration ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? Decimal.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBasePurchasingPowerParameters(): PurchasingPowerParameters {
  return {
    msAllowableMarginCredit: 0,
    msCrossMargining: false,
    msIncludeOtePp: 0,
    msIncludeNovPp: 0,
    includeUplLl: 0,
    includeOteLl: 0,
    checkNegativeBalance: false,
    useTheoPrices: false,
    theoTicks: 0,
    useBbaOte: false,
    useBbaNovUpl: false,
    adjustPriceByNetchange: false,
    useBrokerageMarginsOnly: false,
    obsoleteMarginMultiplier: 0,
    msUseInstrumentGroupMarginMultiplier: false,
    msInstrumentGroupMarginMultiplier: 0,
    marginMultiplier: undefined,
    alwaysAllowLiquidation: false,
    enforceMaxPurchasingPower: false,
    maxPurchasingPower: 0,
  };
}

export const PurchasingPowerParameters = {
  encode(message: PurchasingPowerParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.msAllowableMarginCredit !== undefined && message.msAllowableMarginCredit !== 0) {
      writer.uint32(9).double(message.msAllowableMarginCredit);
    }
    if (message.msCrossMargining !== undefined && message.msCrossMargining !== false) {
      writer.uint32(16).bool(message.msCrossMargining);
    }
    if (message.msIncludeOtePp !== undefined && message.msIncludeOtePp !== 0) {
      writer.uint32(24).uint32(message.msIncludeOtePp);
    }
    if (message.msIncludeNovPp !== undefined && message.msIncludeNovPp !== 0) {
      writer.uint32(32).uint32(message.msIncludeNovPp);
    }
    if (message.includeUplLl !== undefined && message.includeUplLl !== 0) {
      writer.uint32(40).uint32(message.includeUplLl);
    }
    if (message.includeOteLl !== undefined && message.includeOteLl !== 0) {
      writer.uint32(48).uint32(message.includeOteLl);
    }
    if (message.checkNegativeBalance !== undefined && message.checkNegativeBalance !== false) {
      writer.uint32(56).bool(message.checkNegativeBalance);
    }
    if (message.useTheoPrices !== undefined && message.useTheoPrices !== false) {
      writer.uint32(64).bool(message.useTheoPrices);
    }
    if (message.theoTicks !== undefined && message.theoTicks !== 0) {
      writer.uint32(72).sint32(message.theoTicks);
    }
    if (message.useBbaOte !== undefined && message.useBbaOte !== false) {
      writer.uint32(80).bool(message.useBbaOte);
    }
    if (message.useBbaNovUpl !== undefined && message.useBbaNovUpl !== false) {
      writer.uint32(88).bool(message.useBbaNovUpl);
    }
    if (message.adjustPriceByNetchange !== undefined && message.adjustPriceByNetchange !== false) {
      writer.uint32(96).bool(message.adjustPriceByNetchange);
    }
    if (message.useBrokerageMarginsOnly !== undefined && message.useBrokerageMarginsOnly !== false) {
      writer.uint32(104).bool(message.useBrokerageMarginsOnly);
    }
    if (message.obsoleteMarginMultiplier !== undefined && message.obsoleteMarginMultiplier !== 0) {
      writer.uint32(113).double(message.obsoleteMarginMultiplier);
    }
    if (
      message.msUseInstrumentGroupMarginMultiplier !== undefined &&
      message.msUseInstrumentGroupMarginMultiplier !== false
    ) {
      writer.uint32(120).bool(message.msUseInstrumentGroupMarginMultiplier);
    }
    if (message.msInstrumentGroupMarginMultiplier !== undefined && message.msInstrumentGroupMarginMultiplier !== 0) {
      writer.uint32(129).double(message.msInstrumentGroupMarginMultiplier);
    }
    if (message.marginMultiplier !== undefined) {
      LimitValueDouble.encode(message.marginMultiplier, writer.uint32(138).fork()).ldelim();
    }
    if (message.alwaysAllowLiquidation !== undefined && message.alwaysAllowLiquidation !== false) {
      writer.uint32(144).bool(message.alwaysAllowLiquidation);
    }
    if (message.enforceMaxPurchasingPower !== undefined && message.enforceMaxPurchasingPower !== false) {
      writer.uint32(152).bool(message.enforceMaxPurchasingPower);
    }
    if (message.maxPurchasingPower !== undefined && message.maxPurchasingPower !== 0) {
      writer.uint32(161).double(message.maxPurchasingPower);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PurchasingPowerParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePurchasingPowerParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.msAllowableMarginCredit = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.msCrossMargining = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.msIncludeOtePp = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.msIncludeNovPp = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.includeUplLl = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.includeOteLl = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.checkNegativeBalance = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.useTheoPrices = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.theoTicks = reader.sint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.useBbaOte = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.useBbaNovUpl = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.adjustPriceByNetchange = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.useBrokerageMarginsOnly = reader.bool();
          continue;
        case 14:
          if (tag !== 113) {
            break;
          }

          message.obsoleteMarginMultiplier = reader.double();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.msUseInstrumentGroupMarginMultiplier = reader.bool();
          continue;
        case 16:
          if (tag !== 129) {
            break;
          }

          message.msInstrumentGroupMarginMultiplier = reader.double();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.marginMultiplier = LimitValueDouble.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.alwaysAllowLiquidation = reader.bool();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.enforceMaxPurchasingPower = reader.bool();
          continue;
        case 20:
          if (tag !== 161) {
            break;
          }

          message.maxPurchasingPower = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PurchasingPowerParameters {
    return {
      msAllowableMarginCredit: isSet(object.msAllowableMarginCredit)
        ? globalThis.Number(object.msAllowableMarginCredit)
        : 0,
      msCrossMargining: isSet(object.msCrossMargining) ? globalThis.Boolean(object.msCrossMargining) : false,
      msIncludeOtePp: isSet(object.msIncludeOtePp) ? globalThis.Number(object.msIncludeOtePp) : 0,
      msIncludeNovPp: isSet(object.msIncludeNovPp) ? globalThis.Number(object.msIncludeNovPp) : 0,
      includeUplLl: isSet(object.includeUplLl) ? globalThis.Number(object.includeUplLl) : 0,
      includeOteLl: isSet(object.includeOteLl) ? globalThis.Number(object.includeOteLl) : 0,
      checkNegativeBalance: isSet(object.checkNegativeBalance)
        ? globalThis.Boolean(object.checkNegativeBalance)
        : false,
      useTheoPrices: isSet(object.useTheoPrices) ? globalThis.Boolean(object.useTheoPrices) : false,
      theoTicks: isSet(object.theoTicks) ? globalThis.Number(object.theoTicks) : 0,
      useBbaOte: isSet(object.useBbaOte) ? globalThis.Boolean(object.useBbaOte) : false,
      useBbaNovUpl: isSet(object.useBbaNovUpl) ? globalThis.Boolean(object.useBbaNovUpl) : false,
      adjustPriceByNetchange: isSet(object.adjustPriceByNetchange)
        ? globalThis.Boolean(object.adjustPriceByNetchange)
        : false,
      useBrokerageMarginsOnly: isSet(object.useBrokerageMarginsOnly)
        ? globalThis.Boolean(object.useBrokerageMarginsOnly)
        : false,
      obsoleteMarginMultiplier: isSet(object.obsoleteMarginMultiplier)
        ? globalThis.Number(object.obsoleteMarginMultiplier)
        : 0,
      msUseInstrumentGroupMarginMultiplier: isSet(object.msUseInstrumentGroupMarginMultiplier)
        ? globalThis.Boolean(object.msUseInstrumentGroupMarginMultiplier)
        : false,
      msInstrumentGroupMarginMultiplier: isSet(object.msInstrumentGroupMarginMultiplier)
        ? globalThis.Number(object.msInstrumentGroupMarginMultiplier)
        : 0,
      marginMultiplier: isSet(object.marginMultiplier) ? LimitValueDouble.fromJSON(object.marginMultiplier) : undefined,
      alwaysAllowLiquidation: isSet(object.alwaysAllowLiquidation)
        ? globalThis.Boolean(object.alwaysAllowLiquidation)
        : false,
      enforceMaxPurchasingPower: isSet(object.enforceMaxPurchasingPower)
        ? globalThis.Boolean(object.enforceMaxPurchasingPower)
        : false,
      maxPurchasingPower: isSet(object.maxPurchasingPower) ? globalThis.Number(object.maxPurchasingPower) : 0,
    };
  },

  toJSON(message: PurchasingPowerParameters): unknown {
    const obj: any = {};
    if (message.msAllowableMarginCredit !== undefined && message.msAllowableMarginCredit !== 0) {
      obj.msAllowableMarginCredit = message.msAllowableMarginCredit;
    }
    if (message.msCrossMargining !== undefined && message.msCrossMargining !== false) {
      obj.msCrossMargining = message.msCrossMargining;
    }
    if (message.msIncludeOtePp !== undefined && message.msIncludeOtePp !== 0) {
      obj.msIncludeOtePp = Math.round(message.msIncludeOtePp);
    }
    if (message.msIncludeNovPp !== undefined && message.msIncludeNovPp !== 0) {
      obj.msIncludeNovPp = Math.round(message.msIncludeNovPp);
    }
    if (message.includeUplLl !== undefined && message.includeUplLl !== 0) {
      obj.includeUplLl = Math.round(message.includeUplLl);
    }
    if (message.includeOteLl !== undefined && message.includeOteLl !== 0) {
      obj.includeOteLl = Math.round(message.includeOteLl);
    }
    if (message.checkNegativeBalance !== undefined && message.checkNegativeBalance !== false) {
      obj.checkNegativeBalance = message.checkNegativeBalance;
    }
    if (message.useTheoPrices !== undefined && message.useTheoPrices !== false) {
      obj.useTheoPrices = message.useTheoPrices;
    }
    if (message.theoTicks !== undefined && message.theoTicks !== 0) {
      obj.theoTicks = Math.round(message.theoTicks);
    }
    if (message.useBbaOte !== undefined && message.useBbaOte !== false) {
      obj.useBbaOte = message.useBbaOte;
    }
    if (message.useBbaNovUpl !== undefined && message.useBbaNovUpl !== false) {
      obj.useBbaNovUpl = message.useBbaNovUpl;
    }
    if (message.adjustPriceByNetchange !== undefined && message.adjustPriceByNetchange !== false) {
      obj.adjustPriceByNetchange = message.adjustPriceByNetchange;
    }
    if (message.useBrokerageMarginsOnly !== undefined && message.useBrokerageMarginsOnly !== false) {
      obj.useBrokerageMarginsOnly = message.useBrokerageMarginsOnly;
    }
    if (message.obsoleteMarginMultiplier !== undefined && message.obsoleteMarginMultiplier !== 0) {
      obj.obsoleteMarginMultiplier = message.obsoleteMarginMultiplier;
    }
    if (
      message.msUseInstrumentGroupMarginMultiplier !== undefined &&
      message.msUseInstrumentGroupMarginMultiplier !== false
    ) {
      obj.msUseInstrumentGroupMarginMultiplier = message.msUseInstrumentGroupMarginMultiplier;
    }
    if (message.msInstrumentGroupMarginMultiplier !== undefined && message.msInstrumentGroupMarginMultiplier !== 0) {
      obj.msInstrumentGroupMarginMultiplier = message.msInstrumentGroupMarginMultiplier;
    }
    if (message.marginMultiplier !== undefined) {
      obj.marginMultiplier = LimitValueDouble.toJSON(message.marginMultiplier);
    }
    if (message.alwaysAllowLiquidation !== undefined && message.alwaysAllowLiquidation !== false) {
      obj.alwaysAllowLiquidation = message.alwaysAllowLiquidation;
    }
    if (message.enforceMaxPurchasingPower !== undefined && message.enforceMaxPurchasingPower !== false) {
      obj.enforceMaxPurchasingPower = message.enforceMaxPurchasingPower;
    }
    if (message.maxPurchasingPower !== undefined && message.maxPurchasingPower !== 0) {
      obj.maxPurchasingPower = message.maxPurchasingPower;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PurchasingPowerParameters>, I>>(base?: I): PurchasingPowerParameters {
    return PurchasingPowerParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PurchasingPowerParameters>, I>>(object: I): PurchasingPowerParameters {
    const message = createBasePurchasingPowerParameters();
    message.msAllowableMarginCredit = object.msAllowableMarginCredit ?? 0;
    message.msCrossMargining = object.msCrossMargining ?? false;
    message.msIncludeOtePp = object.msIncludeOtePp ?? 0;
    message.msIncludeNovPp = object.msIncludeNovPp ?? 0;
    message.includeUplLl = object.includeUplLl ?? 0;
    message.includeOteLl = object.includeOteLl ?? 0;
    message.checkNegativeBalance = object.checkNegativeBalance ?? false;
    message.useTheoPrices = object.useTheoPrices ?? false;
    message.theoTicks = object.theoTicks ?? 0;
    message.useBbaOte = object.useBbaOte ?? false;
    message.useBbaNovUpl = object.useBbaNovUpl ?? false;
    message.adjustPriceByNetchange = object.adjustPriceByNetchange ?? false;
    message.useBrokerageMarginsOnly = object.useBrokerageMarginsOnly ?? false;
    message.obsoleteMarginMultiplier = object.obsoleteMarginMultiplier ?? 0;
    message.msUseInstrumentGroupMarginMultiplier = object.msUseInstrumentGroupMarginMultiplier ?? false;
    message.msInstrumentGroupMarginMultiplier = object.msInstrumentGroupMarginMultiplier ?? 0;
    message.marginMultiplier = (object.marginMultiplier !== undefined && object.marginMultiplier !== null)
      ? LimitValueDouble.fromPartial(object.marginMultiplier)
      : undefined;
    message.alwaysAllowLiquidation = object.alwaysAllowLiquidation ?? false;
    message.enforceMaxPurchasingPower = object.enforceMaxPurchasingPower ?? false;
    message.maxPurchasingPower = object.maxPurchasingPower ?? 0;
    return message;
  },
};

function createBaseLossLimit(): LossLimit {
  return { type: 0, percent: 0, amount: 0 };
}

export const LossLimit = {
  encode(message: LossLimit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== undefined && message.type !== 0) {
      writer.uint32(8).uint32(message.type);
    }
    if (message.percent !== undefined && message.percent !== 0) {
      writer.uint32(17).double(message.percent);
    }
    if (message.amount !== undefined && message.amount !== 0) {
      writer.uint32(25).double(message.amount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LossLimit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLossLimit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.uint32();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.percent = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.amount = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LossLimit {
    return {
      type: isSet(object.type) ? globalThis.Number(object.type) : 0,
      percent: isSet(object.percent) ? globalThis.Number(object.percent) : 0,
      amount: isSet(object.amount) ? globalThis.Number(object.amount) : 0,
    };
  },

  toJSON(message: LossLimit): unknown {
    const obj: any = {};
    if (message.type !== undefined && message.type !== 0) {
      obj.type = Math.round(message.type);
    }
    if (message.percent !== undefined && message.percent !== 0) {
      obj.percent = message.percent;
    }
    if (message.amount !== undefined && message.amount !== 0) {
      obj.amount = message.amount;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LossLimit>, I>>(base?: I): LossLimit {
    return LossLimit.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LossLimit>, I>>(object: I): LossLimit {
    const message = createBaseLossLimit();
    message.type = object.type ?? 0;
    message.percent = object.percent ?? 0;
    message.amount = object.amount ?? 0;
    return message;
  },
};

function createBaseExchangeGroup(): ExchangeGroup {
  return { id: 0, name: undefined, fungibleCommodity: [] };
}

export const ExchangeGroup = {
  encode(message: ExchangeGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).sint32(message.id);
    }
    if (message.name !== undefined) {
      Text.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.fungibleCommodity) {
      FungibleCommodity.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExchangeGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExchangeGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Text.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fungibleCommodity.push(FungibleCommodity.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExchangeGroup {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? Text.fromJSON(object.name) : undefined,
      fungibleCommodity: globalThis.Array.isArray(object?.fungibleCommodity)
        ? object.fungibleCommodity.map((e: any) => FungibleCommodity.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ExchangeGroup): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== undefined) {
      obj.name = Text.toJSON(message.name);
    }
    if (message.fungibleCommodity?.length) {
      obj.fungibleCommodity = message.fungibleCommodity.map((e) => FungibleCommodity.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExchangeGroup>, I>>(base?: I): ExchangeGroup {
    return ExchangeGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExchangeGroup>, I>>(object: I): ExchangeGroup {
    const message = createBaseExchangeGroup();
    message.id = object.id ?? 0;
    message.name = (object.name !== undefined && object.name !== null) ? Text.fromPartial(object.name) : undefined;
    message.fungibleCommodity = object.fungibleCommodity?.map((e) => FungibleCommodity.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFungibleCommodity(): FungibleCommodity {
  return {
    id: 0,
    name: undefined,
    tradableCommodity: [],
    isUs: false,
    instrumentTypeId: [],
    marginGroupId: "",
    prefix: "",
    extendedCode: "",
  };
}

export const FungibleCommodity = {
  encode(message: FungibleCommodity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== 0) {
      writer.uint32(8).sint32(message.id);
    }
    if (message.name !== undefined) {
      Text.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.tradableCommodity) {
      TradableCommodity.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.isUs !== undefined && message.isUs !== false) {
      writer.uint32(32).bool(message.isUs);
    }
    writer.uint32(42).fork();
    for (const v of message.instrumentTypeId) {
      writer.sint32(v);
    }
    writer.ldelim();
    if (message.marginGroupId !== undefined && message.marginGroupId !== "") {
      writer.uint32(50).string(message.marginGroupId);
    }
    if (message.prefix !== undefined && message.prefix !== "") {
      writer.uint32(58).string(message.prefix);
    }
    if (message.extendedCode !== undefined && message.extendedCode !== "") {
      writer.uint32(66).string(message.extendedCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FungibleCommodity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFungibleCommodity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Text.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tradableCommodity.push(TradableCommodity.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isUs = reader.bool();
          continue;
        case 5:
          if (tag === 40) {
            message.instrumentTypeId.push(reader.sint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.instrumentTypeId.push(reader.sint32());
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.marginGroupId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.prefix = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.extendedCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FungibleCommodity {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? Text.fromJSON(object.name) : undefined,
      tradableCommodity: globalThis.Array.isArray(object?.tradableCommodity)
        ? object.tradableCommodity.map((e: any) => TradableCommodity.fromJSON(e))
        : [],
      isUs: isSet(object.isUs) ? globalThis.Boolean(object.isUs) : false,
      instrumentTypeId: globalThis.Array.isArray(object?.instrumentTypeId)
        ? object.instrumentTypeId.map((e: any) => globalThis.Number(e))
        : [],
      marginGroupId: isSet(object.marginGroupId) ? globalThis.String(object.marginGroupId) : "",
      prefix: isSet(object.prefix) ? globalThis.String(object.prefix) : "",
      extendedCode: isSet(object.extendedCode) ? globalThis.String(object.extendedCode) : "",
    };
  },

  toJSON(message: FungibleCommodity): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== undefined) {
      obj.name = Text.toJSON(message.name);
    }
    if (message.tradableCommodity?.length) {
      obj.tradableCommodity = message.tradableCommodity.map((e) => TradableCommodity.toJSON(e));
    }
    if (message.isUs !== undefined && message.isUs !== false) {
      obj.isUs = message.isUs;
    }
    if (message.instrumentTypeId?.length) {
      obj.instrumentTypeId = message.instrumentTypeId.map((e) => Math.round(e));
    }
    if (message.marginGroupId !== undefined && message.marginGroupId !== "") {
      obj.marginGroupId = message.marginGroupId;
    }
    if (message.prefix !== undefined && message.prefix !== "") {
      obj.prefix = message.prefix;
    }
    if (message.extendedCode !== undefined && message.extendedCode !== "") {
      obj.extendedCode = message.extendedCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FungibleCommodity>, I>>(base?: I): FungibleCommodity {
    return FungibleCommodity.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FungibleCommodity>, I>>(object: I): FungibleCommodity {
    const message = createBaseFungibleCommodity();
    message.id = object.id ?? 0;
    message.name = (object.name !== undefined && object.name !== null) ? Text.fromPartial(object.name) : undefined;
    message.tradableCommodity = object.tradableCommodity?.map((e) => TradableCommodity.fromPartial(e)) || [];
    message.isUs = object.isUs ?? false;
    message.instrumentTypeId = object.instrumentTypeId?.map((e) => e) || [];
    message.marginGroupId = object.marginGroupId ?? "";
    message.prefix = object.prefix ?? "";
    message.extendedCode = object.extendedCode ?? "";
    return message;
  },
};

function createBaseTradableCommodity(): TradableCommodity {
  return { id: "", symbol: "" };
}

export const TradableCommodity = {
  encode(message: TradableCommodity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.symbol !== "") {
      writer.uint32(18).string(message.symbol);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradableCommodity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradableCommodity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.symbol = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradableCommodity {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      symbol: isSet(object.symbol) ? globalThis.String(object.symbol) : "",
    };
  },

  toJSON(message: TradableCommodity): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.symbol !== "") {
      obj.symbol = message.symbol;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradableCommodity>, I>>(base?: I): TradableCommodity {
    return TradableCommodity.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradableCommodity>, I>>(object: I): TradableCommodity {
    const message = createBaseTradableCommodity();
    message.id = object.id ?? "";
    message.symbol = object.symbol ?? "";
    return message;
  },
};

function createBaseInstrumentType(): InstrumentType {
  return { id: 0, name: undefined, allowedLimits: [], instrumentGroup: 0 };
}

export const InstrumentType = {
  encode(message: InstrumentType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).sint32(message.id);
    }
    if (message.name !== undefined) {
      Text.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.allowedLimits) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.instrumentGroup !== undefined && message.instrumentGroup !== 0) {
      writer.uint32(32).uint32(message.instrumentGroup);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Text.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag === 24) {
            message.allowedLimits.push(reader.uint32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.allowedLimits.push(reader.uint32());
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.instrumentGroup = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InstrumentType {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      name: isSet(object.name) ? Text.fromJSON(object.name) : undefined,
      allowedLimits: globalThis.Array.isArray(object?.allowedLimits)
        ? object.allowedLimits.map((e: any) => globalThis.Number(e))
        : [],
      instrumentGroup: isSet(object.instrumentGroup) ? globalThis.Number(object.instrumentGroup) : 0,
    };
  },

  toJSON(message: InstrumentType): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.name !== undefined) {
      obj.name = Text.toJSON(message.name);
    }
    if (message.allowedLimits?.length) {
      obj.allowedLimits = message.allowedLimits.map((e) => Math.round(e));
    }
    if (message.instrumentGroup !== undefined && message.instrumentGroup !== 0) {
      obj.instrumentGroup = Math.round(message.instrumentGroup);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentType>, I>>(base?: I): InstrumentType {
    return InstrumentType.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InstrumentType>, I>>(object: I): InstrumentType {
    const message = createBaseInstrumentType();
    message.id = object.id ?? 0;
    message.name = (object.name !== undefined && object.name !== null) ? Text.fromPartial(object.name) : undefined;
    message.allowedLimits = object.allowedLimits?.map((e) => e) || [];
    message.instrumentGroup = object.instrumentGroup ?? 0;
    return message;
  },
};

function createBaseMarketLimits(): MarketLimits {
  return {
    clearedFields: [],
    allMarketLimits: undefined,
    usMarketLimits: undefined,
    nonUsMarketLimits: undefined,
    exchangeMarketLimits: [],
  };
}

export const MarketLimits = {
  encode(message: MarketLimits, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(42).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.allMarketLimits !== undefined) {
      MarketLimitsRecord.encode(message.allMarketLimits, writer.uint32(10).fork()).ldelim();
    }
    if (message.usMarketLimits !== undefined) {
      MarketLimitsRecord.encode(message.usMarketLimits, writer.uint32(18).fork()).ldelim();
    }
    if (message.nonUsMarketLimits !== undefined) {
      MarketLimitsRecord.encode(message.nonUsMarketLimits, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.exchangeMarketLimits) {
      ExchangeMarketLimits.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketLimits {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketLimits();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag === 40) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.allMarketLimits = MarketLimitsRecord.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.usMarketLimits = MarketLimitsRecord.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nonUsMarketLimits = MarketLimitsRecord.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.exchangeMarketLimits.push(ExchangeMarketLimits.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MarketLimits {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      allMarketLimits: isSet(object.allMarketLimits) ? MarketLimitsRecord.fromJSON(object.allMarketLimits) : undefined,
      usMarketLimits: isSet(object.usMarketLimits) ? MarketLimitsRecord.fromJSON(object.usMarketLimits) : undefined,
      nonUsMarketLimits: isSet(object.nonUsMarketLimits)
        ? MarketLimitsRecord.fromJSON(object.nonUsMarketLimits)
        : undefined,
      exchangeMarketLimits: globalThis.Array.isArray(object?.exchangeMarketLimits)
        ? object.exchangeMarketLimits.map((e: any) => ExchangeMarketLimits.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MarketLimits): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.allMarketLimits !== undefined) {
      obj.allMarketLimits = MarketLimitsRecord.toJSON(message.allMarketLimits);
    }
    if (message.usMarketLimits !== undefined) {
      obj.usMarketLimits = MarketLimitsRecord.toJSON(message.usMarketLimits);
    }
    if (message.nonUsMarketLimits !== undefined) {
      obj.nonUsMarketLimits = MarketLimitsRecord.toJSON(message.nonUsMarketLimits);
    }
    if (message.exchangeMarketLimits?.length) {
      obj.exchangeMarketLimits = message.exchangeMarketLimits.map((e) => ExchangeMarketLimits.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketLimits>, I>>(base?: I): MarketLimits {
    return MarketLimits.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketLimits>, I>>(object: I): MarketLimits {
    const message = createBaseMarketLimits();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.allMarketLimits = (object.allMarketLimits !== undefined && object.allMarketLimits !== null)
      ? MarketLimitsRecord.fromPartial(object.allMarketLimits)
      : undefined;
    message.usMarketLimits = (object.usMarketLimits !== undefined && object.usMarketLimits !== null)
      ? MarketLimitsRecord.fromPartial(object.usMarketLimits)
      : undefined;
    message.nonUsMarketLimits = (object.nonUsMarketLimits !== undefined && object.nonUsMarketLimits !== null)
      ? MarketLimitsRecord.fromPartial(object.nonUsMarketLimits)
      : undefined;
    message.exchangeMarketLimits = object.exchangeMarketLimits?.map((e) => ExchangeMarketLimits.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMarketLimitsRecord(): MarketLimitsRecord {
  return {
    clearedFields: [],
    allowedToTrade: undefined,
    obsoleteMarginMultiplier: 0,
    positionAndTradeLimits: undefined,
    marginMultiplier: undefined,
  };
}

export const MarketLimitsRecord = {
  encode(message: MarketLimitsRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(34).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.allowedToTrade !== undefined) {
      EntityAllowedToTrade.encode(message.allowedToTrade, writer.uint32(10).fork()).ldelim();
    }
    if (message.obsoleteMarginMultiplier !== undefined && message.obsoleteMarginMultiplier !== 0) {
      writer.uint32(17).double(message.obsoleteMarginMultiplier);
    }
    if (message.positionAndTradeLimits !== undefined) {
      PositionAndTradeLimits.encode(message.positionAndTradeLimits, writer.uint32(26).fork()).ldelim();
    }
    if (message.marginMultiplier !== undefined) {
      LimitValueDouble.encode(message.marginMultiplier, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MarketLimitsRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarketLimitsRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag === 32) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.allowedToTrade = EntityAllowedToTrade.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.obsoleteMarginMultiplier = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.positionAndTradeLimits = PositionAndTradeLimits.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.marginMultiplier = LimitValueDouble.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MarketLimitsRecord {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      allowedToTrade: isSet(object.allowedToTrade) ? EntityAllowedToTrade.fromJSON(object.allowedToTrade) : undefined,
      obsoleteMarginMultiplier: isSet(object.obsoleteMarginMultiplier)
        ? globalThis.Number(object.obsoleteMarginMultiplier)
        : 0,
      positionAndTradeLimits: isSet(object.positionAndTradeLimits)
        ? PositionAndTradeLimits.fromJSON(object.positionAndTradeLimits)
        : undefined,
      marginMultiplier: isSet(object.marginMultiplier) ? LimitValueDouble.fromJSON(object.marginMultiplier) : undefined,
    };
  },

  toJSON(message: MarketLimitsRecord): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.allowedToTrade !== undefined) {
      obj.allowedToTrade = EntityAllowedToTrade.toJSON(message.allowedToTrade);
    }
    if (message.obsoleteMarginMultiplier !== undefined && message.obsoleteMarginMultiplier !== 0) {
      obj.obsoleteMarginMultiplier = message.obsoleteMarginMultiplier;
    }
    if (message.positionAndTradeLimits !== undefined) {
      obj.positionAndTradeLimits = PositionAndTradeLimits.toJSON(message.positionAndTradeLimits);
    }
    if (message.marginMultiplier !== undefined) {
      obj.marginMultiplier = LimitValueDouble.toJSON(message.marginMultiplier);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MarketLimitsRecord>, I>>(base?: I): MarketLimitsRecord {
    return MarketLimitsRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MarketLimitsRecord>, I>>(object: I): MarketLimitsRecord {
    const message = createBaseMarketLimitsRecord();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.allowedToTrade = (object.allowedToTrade !== undefined && object.allowedToTrade !== null)
      ? EntityAllowedToTrade.fromPartial(object.allowedToTrade)
      : undefined;
    message.obsoleteMarginMultiplier = object.obsoleteMarginMultiplier ?? 0;
    message.positionAndTradeLimits =
      (object.positionAndTradeLimits !== undefined && object.positionAndTradeLimits !== null)
        ? PositionAndTradeLimits.fromPartial(object.positionAndTradeLimits)
        : undefined;
    message.marginMultiplier = (object.marginMultiplier !== undefined && object.marginMultiplier !== null)
      ? LimitValueDouble.fromPartial(object.marginMultiplier)
      : undefined;
    return message;
  },
};

function createBasePositionAndTradeLimits(): PositionAndTradeLimits {
  return {
    clearedFields: [],
    obsoleteCommodityPositionLimit: undefined,
    obsoleteInstrumentPositionLimit: undefined,
    obsoleteContractPositionLimit: undefined,
    obsoleteTradeSizeLimit: undefined,
    tradePriceLimitTicks: undefined,
    tradePriceLimitPercent: undefined,
    tradePriceLimitTicksContinuous: undefined,
    tradePriceLimitTicksNonContinuous: undefined,
    tradePriceLimitPercentContinuous: undefined,
    tradePriceLimitPercentNonContinuous: undefined,
    lastTradingDateLimit: undefined,
    commodityPositionLimit: undefined,
    instrumentPositionLimit: undefined,
    contractPositionLimit: undefined,
    tradeSizeLimit: undefined,
    longPositionsOnly: undefined,
    commodityGrossOpenPositionLimit: undefined,
  };
}

export const PositionAndTradeLimits = {
  encode(message: PositionAndTradeLimits, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(98).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.obsoleteCommodityPositionLimit !== undefined) {
      LimitValue.encode(message.obsoleteCommodityPositionLimit, writer.uint32(10).fork()).ldelim();
    }
    if (message.obsoleteInstrumentPositionLimit !== undefined) {
      LimitValue.encode(message.obsoleteInstrumentPositionLimit, writer.uint32(18).fork()).ldelim();
    }
    if (message.obsoleteContractPositionLimit !== undefined) {
      LimitValue.encode(message.obsoleteContractPositionLimit, writer.uint32(26).fork()).ldelim();
    }
    if (message.obsoleteTradeSizeLimit !== undefined) {
      LimitValue.encode(message.obsoleteTradeSizeLimit, writer.uint32(34).fork()).ldelim();
    }
    if (message.tradePriceLimitTicks !== undefined) {
      LimitValue.encode(message.tradePriceLimitTicks, writer.uint32(42).fork()).ldelim();
    }
    if (message.tradePriceLimitPercent !== undefined) {
      LimitValueDouble.encode(message.tradePriceLimitPercent, writer.uint32(50).fork()).ldelim();
    }
    if (message.tradePriceLimitTicksContinuous !== undefined) {
      TradePriceLimitsTicks.encode(message.tradePriceLimitTicksContinuous, writer.uint32(58).fork()).ldelim();
    }
    if (message.tradePriceLimitTicksNonContinuous !== undefined) {
      TradePriceLimitsTicks.encode(message.tradePriceLimitTicksNonContinuous, writer.uint32(66).fork()).ldelim();
    }
    if (message.tradePriceLimitPercentContinuous !== undefined) {
      TradePriceLimitsPercent.encode(message.tradePriceLimitPercentContinuous, writer.uint32(74).fork()).ldelim();
    }
    if (message.tradePriceLimitPercentNonContinuous !== undefined) {
      TradePriceLimitsPercent.encode(message.tradePriceLimitPercentNonContinuous, writer.uint32(82).fork()).ldelim();
    }
    if (message.lastTradingDateLimit !== undefined) {
      LimitValue.encode(message.lastTradingDateLimit, writer.uint32(90).fork()).ldelim();
    }
    if (message.commodityPositionLimit !== undefined) {
      LimitValueDecimal.encode(message.commodityPositionLimit, writer.uint32(106).fork()).ldelim();
    }
    if (message.instrumentPositionLimit !== undefined) {
      LimitValueDecimal.encode(message.instrumentPositionLimit, writer.uint32(114).fork()).ldelim();
    }
    if (message.contractPositionLimit !== undefined) {
      LimitValueDecimal.encode(message.contractPositionLimit, writer.uint32(122).fork()).ldelim();
    }
    if (message.tradeSizeLimit !== undefined) {
      LimitValueDecimal.encode(message.tradeSizeLimit, writer.uint32(130).fork()).ldelim();
    }
    if (message.longPositionsOnly !== undefined) {
      LimitValueBoolean.encode(message.longPositionsOnly, writer.uint32(138).fork()).ldelim();
    }
    if (message.commodityGrossOpenPositionLimit !== undefined) {
      LimitValueDecimal.encode(message.commodityGrossOpenPositionLimit, writer.uint32(146).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PositionAndTradeLimits {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePositionAndTradeLimits();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 12:
          if (tag === 96) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 98) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.obsoleteCommodityPositionLimit = LimitValue.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.obsoleteInstrumentPositionLimit = LimitValue.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.obsoleteContractPositionLimit = LimitValue.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.obsoleteTradeSizeLimit = LimitValue.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tradePriceLimitTicks = LimitValue.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tradePriceLimitPercent = LimitValueDouble.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tradePriceLimitTicksContinuous = TradePriceLimitsTicks.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tradePriceLimitTicksNonContinuous = TradePriceLimitsTicks.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.tradePriceLimitPercentContinuous = TradePriceLimitsPercent.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.tradePriceLimitPercentNonContinuous = TradePriceLimitsPercent.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.lastTradingDateLimit = LimitValue.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.commodityPositionLimit = LimitValueDecimal.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.instrumentPositionLimit = LimitValueDecimal.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.contractPositionLimit = LimitValueDecimal.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.tradeSizeLimit = LimitValueDecimal.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.longPositionsOnly = LimitValueBoolean.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.commodityGrossOpenPositionLimit = LimitValueDecimal.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PositionAndTradeLimits {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      obsoleteCommodityPositionLimit: isSet(object.obsoleteCommodityPositionLimit)
        ? LimitValue.fromJSON(object.obsoleteCommodityPositionLimit)
        : undefined,
      obsoleteInstrumentPositionLimit: isSet(object.obsoleteInstrumentPositionLimit)
        ? LimitValue.fromJSON(object.obsoleteInstrumentPositionLimit)
        : undefined,
      obsoleteContractPositionLimit: isSet(object.obsoleteContractPositionLimit)
        ? LimitValue.fromJSON(object.obsoleteContractPositionLimit)
        : undefined,
      obsoleteTradeSizeLimit: isSet(object.obsoleteTradeSizeLimit)
        ? LimitValue.fromJSON(object.obsoleteTradeSizeLimit)
        : undefined,
      tradePriceLimitTicks: isSet(object.tradePriceLimitTicks)
        ? LimitValue.fromJSON(object.tradePriceLimitTicks)
        : undefined,
      tradePriceLimitPercent: isSet(object.tradePriceLimitPercent)
        ? LimitValueDouble.fromJSON(object.tradePriceLimitPercent)
        : undefined,
      tradePriceLimitTicksContinuous: isSet(object.tradePriceLimitTicksContinuous)
        ? TradePriceLimitsTicks.fromJSON(object.tradePriceLimitTicksContinuous)
        : undefined,
      tradePriceLimitTicksNonContinuous: isSet(object.tradePriceLimitTicksNonContinuous)
        ? TradePriceLimitsTicks.fromJSON(object.tradePriceLimitTicksNonContinuous)
        : undefined,
      tradePriceLimitPercentContinuous: isSet(object.tradePriceLimitPercentContinuous)
        ? TradePriceLimitsPercent.fromJSON(object.tradePriceLimitPercentContinuous)
        : undefined,
      tradePriceLimitPercentNonContinuous: isSet(object.tradePriceLimitPercentNonContinuous)
        ? TradePriceLimitsPercent.fromJSON(object.tradePriceLimitPercentNonContinuous)
        : undefined,
      lastTradingDateLimit: isSet(object.lastTradingDateLimit)
        ? LimitValue.fromJSON(object.lastTradingDateLimit)
        : undefined,
      commodityPositionLimit: isSet(object.commodityPositionLimit)
        ? LimitValueDecimal.fromJSON(object.commodityPositionLimit)
        : undefined,
      instrumentPositionLimit: isSet(object.instrumentPositionLimit)
        ? LimitValueDecimal.fromJSON(object.instrumentPositionLimit)
        : undefined,
      contractPositionLimit: isSet(object.contractPositionLimit)
        ? LimitValueDecimal.fromJSON(object.contractPositionLimit)
        : undefined,
      tradeSizeLimit: isSet(object.tradeSizeLimit) ? LimitValueDecimal.fromJSON(object.tradeSizeLimit) : undefined,
      longPositionsOnly: isSet(object.longPositionsOnly)
        ? LimitValueBoolean.fromJSON(object.longPositionsOnly)
        : undefined,
      commodityGrossOpenPositionLimit: isSet(object.commodityGrossOpenPositionLimit)
        ? LimitValueDecimal.fromJSON(object.commodityGrossOpenPositionLimit)
        : undefined,
    };
  },

  toJSON(message: PositionAndTradeLimits): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.obsoleteCommodityPositionLimit !== undefined) {
      obj.obsoleteCommodityPositionLimit = LimitValue.toJSON(message.obsoleteCommodityPositionLimit);
    }
    if (message.obsoleteInstrumentPositionLimit !== undefined) {
      obj.obsoleteInstrumentPositionLimit = LimitValue.toJSON(message.obsoleteInstrumentPositionLimit);
    }
    if (message.obsoleteContractPositionLimit !== undefined) {
      obj.obsoleteContractPositionLimit = LimitValue.toJSON(message.obsoleteContractPositionLimit);
    }
    if (message.obsoleteTradeSizeLimit !== undefined) {
      obj.obsoleteTradeSizeLimit = LimitValue.toJSON(message.obsoleteTradeSizeLimit);
    }
    if (message.tradePriceLimitTicks !== undefined) {
      obj.tradePriceLimitTicks = LimitValue.toJSON(message.tradePriceLimitTicks);
    }
    if (message.tradePriceLimitPercent !== undefined) {
      obj.tradePriceLimitPercent = LimitValueDouble.toJSON(message.tradePriceLimitPercent);
    }
    if (message.tradePriceLimitTicksContinuous !== undefined) {
      obj.tradePriceLimitTicksContinuous = TradePriceLimitsTicks.toJSON(message.tradePriceLimitTicksContinuous);
    }
    if (message.tradePriceLimitTicksNonContinuous !== undefined) {
      obj.tradePriceLimitTicksNonContinuous = TradePriceLimitsTicks.toJSON(message.tradePriceLimitTicksNonContinuous);
    }
    if (message.tradePriceLimitPercentContinuous !== undefined) {
      obj.tradePriceLimitPercentContinuous = TradePriceLimitsPercent.toJSON(message.tradePriceLimitPercentContinuous);
    }
    if (message.tradePriceLimitPercentNonContinuous !== undefined) {
      obj.tradePriceLimitPercentNonContinuous = TradePriceLimitsPercent.toJSON(
        message.tradePriceLimitPercentNonContinuous,
      );
    }
    if (message.lastTradingDateLimit !== undefined) {
      obj.lastTradingDateLimit = LimitValue.toJSON(message.lastTradingDateLimit);
    }
    if (message.commodityPositionLimit !== undefined) {
      obj.commodityPositionLimit = LimitValueDecimal.toJSON(message.commodityPositionLimit);
    }
    if (message.instrumentPositionLimit !== undefined) {
      obj.instrumentPositionLimit = LimitValueDecimal.toJSON(message.instrumentPositionLimit);
    }
    if (message.contractPositionLimit !== undefined) {
      obj.contractPositionLimit = LimitValueDecimal.toJSON(message.contractPositionLimit);
    }
    if (message.tradeSizeLimit !== undefined) {
      obj.tradeSizeLimit = LimitValueDecimal.toJSON(message.tradeSizeLimit);
    }
    if (message.longPositionsOnly !== undefined) {
      obj.longPositionsOnly = LimitValueBoolean.toJSON(message.longPositionsOnly);
    }
    if (message.commodityGrossOpenPositionLimit !== undefined) {
      obj.commodityGrossOpenPositionLimit = LimitValueDecimal.toJSON(message.commodityGrossOpenPositionLimit);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PositionAndTradeLimits>, I>>(base?: I): PositionAndTradeLimits {
    return PositionAndTradeLimits.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PositionAndTradeLimits>, I>>(object: I): PositionAndTradeLimits {
    const message = createBasePositionAndTradeLimits();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.obsoleteCommodityPositionLimit =
      (object.obsoleteCommodityPositionLimit !== undefined && object.obsoleteCommodityPositionLimit !== null)
        ? LimitValue.fromPartial(object.obsoleteCommodityPositionLimit)
        : undefined;
    message.obsoleteInstrumentPositionLimit =
      (object.obsoleteInstrumentPositionLimit !== undefined && object.obsoleteInstrumentPositionLimit !== null)
        ? LimitValue.fromPartial(object.obsoleteInstrumentPositionLimit)
        : undefined;
    message.obsoleteContractPositionLimit =
      (object.obsoleteContractPositionLimit !== undefined && object.obsoleteContractPositionLimit !== null)
        ? LimitValue.fromPartial(object.obsoleteContractPositionLimit)
        : undefined;
    message.obsoleteTradeSizeLimit =
      (object.obsoleteTradeSizeLimit !== undefined && object.obsoleteTradeSizeLimit !== null)
        ? LimitValue.fromPartial(object.obsoleteTradeSizeLimit)
        : undefined;
    message.tradePriceLimitTicks = (object.tradePriceLimitTicks !== undefined && object.tradePriceLimitTicks !== null)
      ? LimitValue.fromPartial(object.tradePriceLimitTicks)
      : undefined;
    message.tradePriceLimitPercent =
      (object.tradePriceLimitPercent !== undefined && object.tradePriceLimitPercent !== null)
        ? LimitValueDouble.fromPartial(object.tradePriceLimitPercent)
        : undefined;
    message.tradePriceLimitTicksContinuous =
      (object.tradePriceLimitTicksContinuous !== undefined && object.tradePriceLimitTicksContinuous !== null)
        ? TradePriceLimitsTicks.fromPartial(object.tradePriceLimitTicksContinuous)
        : undefined;
    message.tradePriceLimitTicksNonContinuous =
      (object.tradePriceLimitTicksNonContinuous !== undefined && object.tradePriceLimitTicksNonContinuous !== null)
        ? TradePriceLimitsTicks.fromPartial(object.tradePriceLimitTicksNonContinuous)
        : undefined;
    message.tradePriceLimitPercentContinuous =
      (object.tradePriceLimitPercentContinuous !== undefined && object.tradePriceLimitPercentContinuous !== null)
        ? TradePriceLimitsPercent.fromPartial(object.tradePriceLimitPercentContinuous)
        : undefined;
    message.tradePriceLimitPercentNonContinuous =
      (object.tradePriceLimitPercentNonContinuous !== undefined && object.tradePriceLimitPercentNonContinuous !== null)
        ? TradePriceLimitsPercent.fromPartial(object.tradePriceLimitPercentNonContinuous)
        : undefined;
    message.lastTradingDateLimit = (object.lastTradingDateLimit !== undefined && object.lastTradingDateLimit !== null)
      ? LimitValue.fromPartial(object.lastTradingDateLimit)
      : undefined;
    message.commodityPositionLimit =
      (object.commodityPositionLimit !== undefined && object.commodityPositionLimit !== null)
        ? LimitValueDecimal.fromPartial(object.commodityPositionLimit)
        : undefined;
    message.instrumentPositionLimit =
      (object.instrumentPositionLimit !== undefined && object.instrumentPositionLimit !== null)
        ? LimitValueDecimal.fromPartial(object.instrumentPositionLimit)
        : undefined;
    message.contractPositionLimit =
      (object.contractPositionLimit !== undefined && object.contractPositionLimit !== null)
        ? LimitValueDecimal.fromPartial(object.contractPositionLimit)
        : undefined;
    message.tradeSizeLimit = (object.tradeSizeLimit !== undefined && object.tradeSizeLimit !== null)
      ? LimitValueDecimal.fromPartial(object.tradeSizeLimit)
      : undefined;
    message.longPositionsOnly = (object.longPositionsOnly !== undefined && object.longPositionsOnly !== null)
      ? LimitValueBoolean.fromPartial(object.longPositionsOnly)
      : undefined;
    message.commodityGrossOpenPositionLimit =
      (object.commodityGrossOpenPositionLimit !== undefined && object.commodityGrossOpenPositionLimit !== null)
        ? LimitValueDecimal.fromPartial(object.commodityGrossOpenPositionLimit)
        : undefined;
    return message;
  },
};

function createBaseTradePriceLimitsTicks(): TradePriceLimitsTicks {
  return { bidAsk: undefined, last: undefined, open: undefined, settlement: undefined };
}

export const TradePriceLimitsTicks = {
  encode(message: TradePriceLimitsTicks, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bidAsk !== undefined) {
      LimitValue.encode(message.bidAsk, writer.uint32(10).fork()).ldelim();
    }
    if (message.last !== undefined) {
      LimitValue.encode(message.last, writer.uint32(18).fork()).ldelim();
    }
    if (message.open !== undefined) {
      LimitValue.encode(message.open, writer.uint32(26).fork()).ldelim();
    }
    if (message.settlement !== undefined) {
      LimitValue.encode(message.settlement, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradePriceLimitsTicks {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradePriceLimitsTicks();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bidAsk = LimitValue.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.last = LimitValue.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.open = LimitValue.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.settlement = LimitValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradePriceLimitsTicks {
    return {
      bidAsk: isSet(object.bidAsk) ? LimitValue.fromJSON(object.bidAsk) : undefined,
      last: isSet(object.last) ? LimitValue.fromJSON(object.last) : undefined,
      open: isSet(object.open) ? LimitValue.fromJSON(object.open) : undefined,
      settlement: isSet(object.settlement) ? LimitValue.fromJSON(object.settlement) : undefined,
    };
  },

  toJSON(message: TradePriceLimitsTicks): unknown {
    const obj: any = {};
    if (message.bidAsk !== undefined) {
      obj.bidAsk = LimitValue.toJSON(message.bidAsk);
    }
    if (message.last !== undefined) {
      obj.last = LimitValue.toJSON(message.last);
    }
    if (message.open !== undefined) {
      obj.open = LimitValue.toJSON(message.open);
    }
    if (message.settlement !== undefined) {
      obj.settlement = LimitValue.toJSON(message.settlement);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradePriceLimitsTicks>, I>>(base?: I): TradePriceLimitsTicks {
    return TradePriceLimitsTicks.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradePriceLimitsTicks>, I>>(object: I): TradePriceLimitsTicks {
    const message = createBaseTradePriceLimitsTicks();
    message.bidAsk = (object.bidAsk !== undefined && object.bidAsk !== null)
      ? LimitValue.fromPartial(object.bidAsk)
      : undefined;
    message.last = (object.last !== undefined && object.last !== null)
      ? LimitValue.fromPartial(object.last)
      : undefined;
    message.open = (object.open !== undefined && object.open !== null)
      ? LimitValue.fromPartial(object.open)
      : undefined;
    message.settlement = (object.settlement !== undefined && object.settlement !== null)
      ? LimitValue.fromPartial(object.settlement)
      : undefined;
    return message;
  },
};

function createBaseTradePriceLimitsPercent(): TradePriceLimitsPercent {
  return { bidAsk: undefined, last: undefined, open: undefined, settlement: undefined };
}

export const TradePriceLimitsPercent = {
  encode(message: TradePriceLimitsPercent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bidAsk !== undefined) {
      LimitValueDouble.encode(message.bidAsk, writer.uint32(10).fork()).ldelim();
    }
    if (message.last !== undefined) {
      LimitValueDouble.encode(message.last, writer.uint32(18).fork()).ldelim();
    }
    if (message.open !== undefined) {
      LimitValueDouble.encode(message.open, writer.uint32(26).fork()).ldelim();
    }
    if (message.settlement !== undefined) {
      LimitValueDouble.encode(message.settlement, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TradePriceLimitsPercent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTradePriceLimitsPercent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bidAsk = LimitValueDouble.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.last = LimitValueDouble.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.open = LimitValueDouble.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.settlement = LimitValueDouble.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TradePriceLimitsPercent {
    return {
      bidAsk: isSet(object.bidAsk) ? LimitValueDouble.fromJSON(object.bidAsk) : undefined,
      last: isSet(object.last) ? LimitValueDouble.fromJSON(object.last) : undefined,
      open: isSet(object.open) ? LimitValueDouble.fromJSON(object.open) : undefined,
      settlement: isSet(object.settlement) ? LimitValueDouble.fromJSON(object.settlement) : undefined,
    };
  },

  toJSON(message: TradePriceLimitsPercent): unknown {
    const obj: any = {};
    if (message.bidAsk !== undefined) {
      obj.bidAsk = LimitValueDouble.toJSON(message.bidAsk);
    }
    if (message.last !== undefined) {
      obj.last = LimitValueDouble.toJSON(message.last);
    }
    if (message.open !== undefined) {
      obj.open = LimitValueDouble.toJSON(message.open);
    }
    if (message.settlement !== undefined) {
      obj.settlement = LimitValueDouble.toJSON(message.settlement);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TradePriceLimitsPercent>, I>>(base?: I): TradePriceLimitsPercent {
    return TradePriceLimitsPercent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TradePriceLimitsPercent>, I>>(object: I): TradePriceLimitsPercent {
    const message = createBaseTradePriceLimitsPercent();
    message.bidAsk = (object.bidAsk !== undefined && object.bidAsk !== null)
      ? LimitValueDouble.fromPartial(object.bidAsk)
      : undefined;
    message.last = (object.last !== undefined && object.last !== null)
      ? LimitValueDouble.fromPartial(object.last)
      : undefined;
    message.open = (object.open !== undefined && object.open !== null)
      ? LimitValueDouble.fromPartial(object.open)
      : undefined;
    message.settlement = (object.settlement !== undefined && object.settlement !== null)
      ? LimitValueDouble.fromPartial(object.settlement)
      : undefined;
    return message;
  },
};

function createBaseExchangeMarketLimits(): ExchangeMarketLimits {
  return { clearedFields: [], exchangeGroupId: 0, defaultMarketLimits: undefined, commodityMarketLimits: [] };
}

export const ExchangeMarketLimits = {
  encode(message: ExchangeMarketLimits, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(34).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.exchangeGroupId !== 0) {
      writer.uint32(8).sint32(message.exchangeGroupId);
    }
    if (message.defaultMarketLimits !== undefined) {
      MarketLimitsRecord.encode(message.defaultMarketLimits, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.commodityMarketLimits) {
      CommodityMarketLimits.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExchangeMarketLimits {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExchangeMarketLimits();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag === 32) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.exchangeGroupId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.defaultMarketLimits = MarketLimitsRecord.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.commodityMarketLimits.push(CommodityMarketLimits.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExchangeMarketLimits {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      exchangeGroupId: isSet(object.exchangeGroupId) ? globalThis.Number(object.exchangeGroupId) : 0,
      defaultMarketLimits: isSet(object.defaultMarketLimits)
        ? MarketLimitsRecord.fromJSON(object.defaultMarketLimits)
        : undefined,
      commodityMarketLimits: globalThis.Array.isArray(object?.commodityMarketLimits)
        ? object.commodityMarketLimits.map((e: any) => CommodityMarketLimits.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ExchangeMarketLimits): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.exchangeGroupId !== 0) {
      obj.exchangeGroupId = Math.round(message.exchangeGroupId);
    }
    if (message.defaultMarketLimits !== undefined) {
      obj.defaultMarketLimits = MarketLimitsRecord.toJSON(message.defaultMarketLimits);
    }
    if (message.commodityMarketLimits?.length) {
      obj.commodityMarketLimits = message.commodityMarketLimits.map((e) => CommodityMarketLimits.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ExchangeMarketLimits>, I>>(base?: I): ExchangeMarketLimits {
    return ExchangeMarketLimits.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExchangeMarketLimits>, I>>(object: I): ExchangeMarketLimits {
    const message = createBaseExchangeMarketLimits();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.exchangeGroupId = object.exchangeGroupId ?? 0;
    message.defaultMarketLimits = (object.defaultMarketLimits !== undefined && object.defaultMarketLimits !== null)
      ? MarketLimitsRecord.fromPartial(object.defaultMarketLimits)
      : undefined;
    message.commodityMarketLimits = object.commodityMarketLimits?.map((e) => CommodityMarketLimits.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseCommodityMarketLimits(): CommodityMarketLimits {
  return {
    clearedFields: [],
    fungibleCommodityId: 0,
    obsoleteTradableCommodityId: [],
    positionAndTradeLimits: undefined,
    instrumentMarketLimits: [],
    allowedToTradeCommodities: [],
    marginMultiplier: undefined,
  };
}

export const CommodityMarketLimits = {
  encode(message: CommodityMarketLimits, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(50).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.fungibleCommodityId !== 0) {
      writer.uint32(8).sint32(message.fungibleCommodityId);
    }
    for (const v of message.obsoleteTradableCommodityId) {
      writer.uint32(18).string(v!);
    }
    if (message.positionAndTradeLimits !== undefined) {
      PositionAndTradeLimits.encode(message.positionAndTradeLimits, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.instrumentMarketLimits) {
      InstrumentMarketLimits.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.allowedToTradeCommodities) {
      EntityAllowedToTrade.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.marginMultiplier !== undefined) {
      LimitValueDouble.encode(message.marginMultiplier, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommodityMarketLimits {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommodityMarketLimits();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 6:
          if (tag === 48) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.fungibleCommodityId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.obsoleteTradableCommodityId.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.positionAndTradeLimits = PositionAndTradeLimits.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.instrumentMarketLimits.push(InstrumentMarketLimits.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.allowedToTradeCommodities.push(EntityAllowedToTrade.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.marginMultiplier = LimitValueDouble.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommodityMarketLimits {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      fungibleCommodityId: isSet(object.fungibleCommodityId) ? globalThis.Number(object.fungibleCommodityId) : 0,
      obsoleteTradableCommodityId: globalThis.Array.isArray(object?.obsoleteTradableCommodityId)
        ? object.obsoleteTradableCommodityId.map((e: any) => globalThis.String(e))
        : [],
      positionAndTradeLimits: isSet(object.positionAndTradeLimits)
        ? PositionAndTradeLimits.fromJSON(object.positionAndTradeLimits)
        : undefined,
      instrumentMarketLimits: globalThis.Array.isArray(object?.instrumentMarketLimits)
        ? object.instrumentMarketLimits.map((e: any) => InstrumentMarketLimits.fromJSON(e))
        : [],
      allowedToTradeCommodities: globalThis.Array.isArray(object?.allowedToTradeCommodities)
        ? object.allowedToTradeCommodities.map((e: any) => EntityAllowedToTrade.fromJSON(e))
        : [],
      marginMultiplier: isSet(object.marginMultiplier) ? LimitValueDouble.fromJSON(object.marginMultiplier) : undefined,
    };
  },

  toJSON(message: CommodityMarketLimits): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.fungibleCommodityId !== 0) {
      obj.fungibleCommodityId = Math.round(message.fungibleCommodityId);
    }
    if (message.obsoleteTradableCommodityId?.length) {
      obj.obsoleteTradableCommodityId = message.obsoleteTradableCommodityId;
    }
    if (message.positionAndTradeLimits !== undefined) {
      obj.positionAndTradeLimits = PositionAndTradeLimits.toJSON(message.positionAndTradeLimits);
    }
    if (message.instrumentMarketLimits?.length) {
      obj.instrumentMarketLimits = message.instrumentMarketLimits.map((e) => InstrumentMarketLimits.toJSON(e));
    }
    if (message.allowedToTradeCommodities?.length) {
      obj.allowedToTradeCommodities = message.allowedToTradeCommodities.map((e) => EntityAllowedToTrade.toJSON(e));
    }
    if (message.marginMultiplier !== undefined) {
      obj.marginMultiplier = LimitValueDouble.toJSON(message.marginMultiplier);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommodityMarketLimits>, I>>(base?: I): CommodityMarketLimits {
    return CommodityMarketLimits.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommodityMarketLimits>, I>>(object: I): CommodityMarketLimits {
    const message = createBaseCommodityMarketLimits();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.fungibleCommodityId = object.fungibleCommodityId ?? 0;
    message.obsoleteTradableCommodityId = object.obsoleteTradableCommodityId?.map((e) => e) || [];
    message.positionAndTradeLimits =
      (object.positionAndTradeLimits !== undefined && object.positionAndTradeLimits !== null)
        ? PositionAndTradeLimits.fromPartial(object.positionAndTradeLimits)
        : undefined;
    message.instrumentMarketLimits = object.instrumentMarketLimits?.map((e) => InstrumentMarketLimits.fromPartial(e)) ||
      [];
    message.allowedToTradeCommodities =
      object.allowedToTradeCommodities?.map((e) => EntityAllowedToTrade.fromPartial(e)) || [];
    message.marginMultiplier = (object.marginMultiplier !== undefined && object.marginMultiplier !== null)
      ? LimitValueDouble.fromPartial(object.marginMultiplier)
      : undefined;
    return message;
  },
};

function createBaseInstrumentMarketLimits(): InstrumentMarketLimits {
  return {
    clearedFields: [],
    instrumentTypeId: 0,
    positionAndTradeLimits: undefined,
    allowedToTradeCommodities: [],
    allowedToTradeContracts: [],
  };
}

export const InstrumentMarketLimits = {
  encode(message: InstrumentMarketLimits, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(42).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.instrumentTypeId !== 0) {
      writer.uint32(8).sint32(message.instrumentTypeId);
    }
    if (message.positionAndTradeLimits !== undefined) {
      PositionAndTradeLimits.encode(message.positionAndTradeLimits, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.allowedToTradeCommodities) {
      EntityAllowedToTrade.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.allowedToTradeContracts) {
      EntityAllowedToTrade.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentMarketLimits {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentMarketLimits();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag === 40) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.instrumentTypeId = reader.sint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.positionAndTradeLimits = PositionAndTradeLimits.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.allowedToTradeCommodities.push(EntityAllowedToTrade.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.allowedToTradeContracts.push(EntityAllowedToTrade.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InstrumentMarketLimits {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      instrumentTypeId: isSet(object.instrumentTypeId) ? globalThis.Number(object.instrumentTypeId) : 0,
      positionAndTradeLimits: isSet(object.positionAndTradeLimits)
        ? PositionAndTradeLimits.fromJSON(object.positionAndTradeLimits)
        : undefined,
      allowedToTradeCommodities: globalThis.Array.isArray(object?.allowedToTradeCommodities)
        ? object.allowedToTradeCommodities.map((e: any) => EntityAllowedToTrade.fromJSON(e))
        : [],
      allowedToTradeContracts: globalThis.Array.isArray(object?.allowedToTradeContracts)
        ? object.allowedToTradeContracts.map((e: any) => EntityAllowedToTrade.fromJSON(e))
        : [],
    };
  },

  toJSON(message: InstrumentMarketLimits): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.instrumentTypeId !== 0) {
      obj.instrumentTypeId = Math.round(message.instrumentTypeId);
    }
    if (message.positionAndTradeLimits !== undefined) {
      obj.positionAndTradeLimits = PositionAndTradeLimits.toJSON(message.positionAndTradeLimits);
    }
    if (message.allowedToTradeCommodities?.length) {
      obj.allowedToTradeCommodities = message.allowedToTradeCommodities.map((e) => EntityAllowedToTrade.toJSON(e));
    }
    if (message.allowedToTradeContracts?.length) {
      obj.allowedToTradeContracts = message.allowedToTradeContracts.map((e) => EntityAllowedToTrade.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentMarketLimits>, I>>(base?: I): InstrumentMarketLimits {
    return InstrumentMarketLimits.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InstrumentMarketLimits>, I>>(object: I): InstrumentMarketLimits {
    const message = createBaseInstrumentMarketLimits();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.instrumentTypeId = object.instrumentTypeId ?? 0;
    message.positionAndTradeLimits =
      (object.positionAndTradeLimits !== undefined && object.positionAndTradeLimits !== null)
        ? PositionAndTradeLimits.fromPartial(object.positionAndTradeLimits)
        : undefined;
    message.allowedToTradeCommodities =
      object.allowedToTradeCommodities?.map((e) => EntityAllowedToTrade.fromPartial(e)) || [];
    message.allowedToTradeContracts = object.allowedToTradeContracts?.map((e) => EntityAllowedToTrade.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseEntityAllowedToTrade(): EntityAllowedToTrade {
  return { clearedFields: [], id: "", allowedToTrade: false, enforce: false };
}

export const EntityAllowedToTrade = {
  encode(message: EntityAllowedToTrade, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(34).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.allowedToTrade !== undefined && message.allowedToTrade !== false) {
      writer.uint32(16).bool(message.allowedToTrade);
    }
    if (message.enforce !== undefined && message.enforce !== false) {
      writer.uint32(24).bool(message.enforce);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EntityAllowedToTrade {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntityAllowedToTrade();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag === 32) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.allowedToTrade = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.enforce = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EntityAllowedToTrade {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      allowedToTrade: isSet(object.allowedToTrade) ? globalThis.Boolean(object.allowedToTrade) : false,
      enforce: isSet(object.enforce) ? globalThis.Boolean(object.enforce) : false,
    };
  },

  toJSON(message: EntityAllowedToTrade): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.allowedToTrade !== undefined && message.allowedToTrade !== false) {
      obj.allowedToTrade = message.allowedToTrade;
    }
    if (message.enforce !== undefined && message.enforce !== false) {
      obj.enforce = message.enforce;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EntityAllowedToTrade>, I>>(base?: I): EntityAllowedToTrade {
    return EntityAllowedToTrade.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntityAllowedToTrade>, I>>(object: I): EntityAllowedToTrade {
    const message = createBaseEntityAllowedToTrade();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.id = object.id ?? "";
    message.allowedToTrade = object.allowedToTrade ?? false;
    message.enforce = object.enforce ?? false;
    return message;
  },
};

function createBaseContractPosition(): ContractPosition {
  return { symbol: "", isShortOpenPosition: false, openPosition: [], contractId: "" };
}

export const ContractPosition = {
  encode(message: ContractPosition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.symbol !== "") {
      writer.uint32(10).string(message.symbol);
    }
    if (message.isShortOpenPosition !== false) {
      writer.uint32(16).bool(message.isShortOpenPosition);
    }
    for (const v of message.openPosition) {
      OpenPosition.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.contractId !== undefined && message.contractId !== "") {
      writer.uint32(34).string(message.contractId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContractPosition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContractPosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.symbol = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isShortOpenPosition = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.openPosition.push(OpenPosition.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.contractId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContractPosition {
    return {
      symbol: isSet(object.symbol) ? globalThis.String(object.symbol) : "",
      isShortOpenPosition: isSet(object.isShortOpenPosition) ? globalThis.Boolean(object.isShortOpenPosition) : false,
      openPosition: globalThis.Array.isArray(object?.openPosition)
        ? object.openPosition.map((e: any) => OpenPosition.fromJSON(e))
        : [],
      contractId: isSet(object.contractId) ? globalThis.String(object.contractId) : "",
    };
  },

  toJSON(message: ContractPosition): unknown {
    const obj: any = {};
    if (message.symbol !== "") {
      obj.symbol = message.symbol;
    }
    if (message.isShortOpenPosition !== false) {
      obj.isShortOpenPosition = message.isShortOpenPosition;
    }
    if (message.openPosition?.length) {
      obj.openPosition = message.openPosition.map((e) => OpenPosition.toJSON(e));
    }
    if (message.contractId !== undefined && message.contractId !== "") {
      obj.contractId = message.contractId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ContractPosition>, I>>(base?: I): ContractPosition {
    return ContractPosition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ContractPosition>, I>>(object: I): ContractPosition {
    const message = createBaseContractPosition();
    message.symbol = object.symbol ?? "";
    message.isShortOpenPosition = object.isShortOpenPosition ?? false;
    message.openPosition = object.openPosition?.map((e) => OpenPosition.fromPartial(e)) || [];
    message.contractId = object.contractId ?? "";
    return message;
  },
};

function createBaseOpenPosition(): OpenPosition {
  return {
    obsoleteQty: 0,
    price: 0,
    tradeDate: 0,
    statementDate: 0,
    tradeUtcTime: 0,
    positionId: "",
    status: 0,
    qty: undefined,
    obsoleteCurrencyCode: "",
    speculationType: 0,
    orderChainId: "",
    displayPrice: 0,
    currencyIsoCode: "",
  };
}

export const OpenPosition = {
  encode(message: OpenPosition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.obsoleteQty !== undefined && message.obsoleteQty !== 0) {
      writer.uint32(16).uint32(message.obsoleteQty);
    }
    if (message.price !== 0) {
      writer.uint32(25).double(message.price);
    }
    if (message.tradeDate !== 0) {
      writer.uint32(32).sint64(message.tradeDate);
    }
    if (message.statementDate !== 0) {
      writer.uint32(40).sint64(message.statementDate);
    }
    if (message.tradeUtcTime !== undefined && message.tradeUtcTime !== 0) {
      writer.uint32(48).sint64(message.tradeUtcTime);
    }
    if (message.positionId !== undefined && message.positionId !== "") {
      writer.uint32(58).string(message.positionId);
    }
    if (message.status !== undefined && message.status !== 0) {
      writer.uint32(64).uint32(message.status);
    }
    if (message.qty !== undefined) {
      Decimal.encode(message.qty, writer.uint32(74).fork()).ldelim();
    }
    if (message.obsoleteCurrencyCode !== undefined && message.obsoleteCurrencyCode !== "") {
      writer.uint32(82).string(message.obsoleteCurrencyCode);
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      writer.uint32(88).uint32(message.speculationType);
    }
    if (message.orderChainId !== undefined && message.orderChainId !== "") {
      writer.uint32(98).string(message.orderChainId);
    }
    if (message.displayPrice !== undefined && message.displayPrice !== 0) {
      writer.uint32(104).sint64(message.displayPrice);
    }
    if (message.currencyIsoCode !== undefined && message.currencyIsoCode !== "") {
      writer.uint32(114).string(message.currencyIsoCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OpenPosition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpenPosition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.obsoleteQty = reader.uint32();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.price = reader.double();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.tradeDate = longToNumber(reader.sint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.statementDate = longToNumber(reader.sint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.tradeUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.positionId = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.status = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.qty = Decimal.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.obsoleteCurrencyCode = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.speculationType = reader.uint32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.orderChainId = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.displayPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.currencyIsoCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OpenPosition {
    return {
      obsoleteQty: isSet(object.obsoleteQty) ? globalThis.Number(object.obsoleteQty) : 0,
      price: isSet(object.price) ? globalThis.Number(object.price) : 0,
      tradeDate: isSet(object.tradeDate) ? globalThis.Number(object.tradeDate) : 0,
      statementDate: isSet(object.statementDate) ? globalThis.Number(object.statementDate) : 0,
      tradeUtcTime: isSet(object.tradeUtcTime) ? globalThis.Number(object.tradeUtcTime) : 0,
      positionId: isSet(object.positionId) ? globalThis.String(object.positionId) : "",
      status: isSet(object.status) ? globalThis.Number(object.status) : 0,
      qty: isSet(object.qty) ? Decimal.fromJSON(object.qty) : undefined,
      obsoleteCurrencyCode: isSet(object.obsoleteCurrencyCode) ? globalThis.String(object.obsoleteCurrencyCode) : "",
      speculationType: isSet(object.speculationType) ? globalThis.Number(object.speculationType) : 0,
      orderChainId: isSet(object.orderChainId) ? globalThis.String(object.orderChainId) : "",
      displayPrice: isSet(object.displayPrice) ? globalThis.Number(object.displayPrice) : 0,
      currencyIsoCode: isSet(object.currencyIsoCode) ? globalThis.String(object.currencyIsoCode) : "",
    };
  },

  toJSON(message: OpenPosition): unknown {
    const obj: any = {};
    if (message.obsoleteQty !== undefined && message.obsoleteQty !== 0) {
      obj.obsoleteQty = Math.round(message.obsoleteQty);
    }
    if (message.price !== 0) {
      obj.price = message.price;
    }
    if (message.tradeDate !== 0) {
      obj.tradeDate = Math.round(message.tradeDate);
    }
    if (message.statementDate !== 0) {
      obj.statementDate = Math.round(message.statementDate);
    }
    if (message.tradeUtcTime !== undefined && message.tradeUtcTime !== 0) {
      obj.tradeUtcTime = Math.round(message.tradeUtcTime);
    }
    if (message.positionId !== undefined && message.positionId !== "") {
      obj.positionId = message.positionId;
    }
    if (message.status !== undefined && message.status !== 0) {
      obj.status = Math.round(message.status);
    }
    if (message.qty !== undefined) {
      obj.qty = Decimal.toJSON(message.qty);
    }
    if (message.obsoleteCurrencyCode !== undefined && message.obsoleteCurrencyCode !== "") {
      obj.obsoleteCurrencyCode = message.obsoleteCurrencyCode;
    }
    if (message.speculationType !== undefined && message.speculationType !== 0) {
      obj.speculationType = Math.round(message.speculationType);
    }
    if (message.orderChainId !== undefined && message.orderChainId !== "") {
      obj.orderChainId = message.orderChainId;
    }
    if (message.displayPrice !== undefined && message.displayPrice !== 0) {
      obj.displayPrice = Math.round(message.displayPrice);
    }
    if (message.currencyIsoCode !== undefined && message.currencyIsoCode !== "") {
      obj.currencyIsoCode = message.currencyIsoCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OpenPosition>, I>>(base?: I): OpenPosition {
    return OpenPosition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OpenPosition>, I>>(object: I): OpenPosition {
    const message = createBaseOpenPosition();
    message.obsoleteQty = object.obsoleteQty ?? 0;
    message.price = object.price ?? 0;
    message.tradeDate = object.tradeDate ?? 0;
    message.statementDate = object.statementDate ?? 0;
    message.tradeUtcTime = object.tradeUtcTime ?? 0;
    message.positionId = object.positionId ?? "";
    message.status = object.status ?? 0;
    message.qty = (object.qty !== undefined && object.qty !== null) ? Decimal.fromPartial(object.qty) : undefined;
    message.obsoleteCurrencyCode = object.obsoleteCurrencyCode ?? "";
    message.speculationType = object.speculationType ?? 0;
    message.orderChainId = object.orderChainId ?? "";
    message.displayPrice = object.displayPrice ?? 0;
    message.currencyIsoCode = object.currencyIsoCode ?? "";
    return message;
  },
};

function createBaseEquity(): Equity {
  return {
    currency: "",
    securities: 0,
    balance: 0,
    ote: 0,
    totalEquity: 0,
    mvo: 0,
    totalValue: 0,
    cashExcess: 0,
    initialMargin: 0,
    maintMargin: 0,
    postedMargin: 0,
  };
}

export const Equity = {
  encode(message: Equity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currency !== "") {
      writer.uint32(10).string(message.currency);
    }
    if (message.securities !== 0) {
      writer.uint32(17).double(message.securities);
    }
    if (message.balance !== 0) {
      writer.uint32(25).double(message.balance);
    }
    if (message.ote !== 0) {
      writer.uint32(33).double(message.ote);
    }
    if (message.totalEquity !== 0) {
      writer.uint32(41).double(message.totalEquity);
    }
    if (message.mvo !== 0) {
      writer.uint32(49).double(message.mvo);
    }
    if (message.totalValue !== 0) {
      writer.uint32(57).double(message.totalValue);
    }
    if (message.cashExcess !== undefined && message.cashExcess !== 0) {
      writer.uint32(65).double(message.cashExcess);
    }
    if (message.initialMargin !== undefined && message.initialMargin !== 0) {
      writer.uint32(73).double(message.initialMargin);
    }
    if (message.maintMargin !== undefined && message.maintMargin !== 0) {
      writer.uint32(81).double(message.maintMargin);
    }
    if (message.postedMargin !== undefined && message.postedMargin !== 0) {
      writer.uint32(89).double(message.postedMargin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Equity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEquity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.securities = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.balance = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.ote = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.totalEquity = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.mvo = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.totalValue = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.cashExcess = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.initialMargin = reader.double();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.maintMargin = reader.double();
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.postedMargin = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Equity {
    return {
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
      securities: isSet(object.securities) ? globalThis.Number(object.securities) : 0,
      balance: isSet(object.balance) ? globalThis.Number(object.balance) : 0,
      ote: isSet(object.ote) ? globalThis.Number(object.ote) : 0,
      totalEquity: isSet(object.totalEquity) ? globalThis.Number(object.totalEquity) : 0,
      mvo: isSet(object.mvo) ? globalThis.Number(object.mvo) : 0,
      totalValue: isSet(object.totalValue) ? globalThis.Number(object.totalValue) : 0,
      cashExcess: isSet(object.cashExcess) ? globalThis.Number(object.cashExcess) : 0,
      initialMargin: isSet(object.initialMargin) ? globalThis.Number(object.initialMargin) : 0,
      maintMargin: isSet(object.maintMargin) ? globalThis.Number(object.maintMargin) : 0,
      postedMargin: isSet(object.postedMargin) ? globalThis.Number(object.postedMargin) : 0,
    };
  },

  toJSON(message: Equity): unknown {
    const obj: any = {};
    if (message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.securities !== 0) {
      obj.securities = message.securities;
    }
    if (message.balance !== 0) {
      obj.balance = message.balance;
    }
    if (message.ote !== 0) {
      obj.ote = message.ote;
    }
    if (message.totalEquity !== 0) {
      obj.totalEquity = message.totalEquity;
    }
    if (message.mvo !== 0) {
      obj.mvo = message.mvo;
    }
    if (message.totalValue !== 0) {
      obj.totalValue = message.totalValue;
    }
    if (message.cashExcess !== undefined && message.cashExcess !== 0) {
      obj.cashExcess = message.cashExcess;
    }
    if (message.initialMargin !== undefined && message.initialMargin !== 0) {
      obj.initialMargin = message.initialMargin;
    }
    if (message.maintMargin !== undefined && message.maintMargin !== 0) {
      obj.maintMargin = message.maintMargin;
    }
    if (message.postedMargin !== undefined && message.postedMargin !== 0) {
      obj.postedMargin = message.postedMargin;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Equity>, I>>(base?: I): Equity {
    return Equity.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Equity>, I>>(object: I): Equity {
    const message = createBaseEquity();
    message.currency = object.currency ?? "";
    message.securities = object.securities ?? 0;
    message.balance = object.balance ?? 0;
    message.ote = object.ote ?? 0;
    message.totalEquity = object.totalEquity ?? 0;
    message.mvo = object.mvo ?? 0;
    message.totalValue = object.totalValue ?? 0;
    message.cashExcess = object.cashExcess ?? 0;
    message.initialMargin = object.initialMargin ?? 0;
    message.maintMargin = object.maintMargin ?? 0;
    message.postedMargin = object.postedMargin ?? 0;
    return message;
  },
};

function createBaseCollateral(): Collateral {
  return { currency: "", totalMargin: 0, purchasingPower: 0 };
}

export const Collateral = {
  encode(message: Collateral, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currency !== "") {
      writer.uint32(34).string(message.currency);
    }
    if (message.totalMargin !== 0) {
      writer.uint32(41).double(message.totalMargin);
    }
    if (message.purchasingPower !== 0) {
      writer.uint32(49).double(message.purchasingPower);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Collateral {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCollateral();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.totalMargin = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.purchasingPower = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Collateral {
    return {
      currency: isSet(object.currency) ? globalThis.String(object.currency) : "",
      totalMargin: isSet(object.totalMargin) ? globalThis.Number(object.totalMargin) : 0,
      purchasingPower: isSet(object.purchasingPower) ? globalThis.Number(object.purchasingPower) : 0,
    };
  },

  toJSON(message: Collateral): unknown {
    const obj: any = {};
    if (message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.totalMargin !== 0) {
      obj.totalMargin = message.totalMargin;
    }
    if (message.purchasingPower !== 0) {
      obj.purchasingPower = message.purchasingPower;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Collateral>, I>>(base?: I): Collateral {
    return Collateral.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Collateral>, I>>(object: I): Collateral {
    const message = createBaseCollateral();
    message.currency = object.currency ?? "";
    message.totalMargin = object.totalMargin ?? 0;
    message.purchasingPower = object.purchasingPower ?? 0;
    return message;
  },
};

function createBaseOrderSearchResultRecord(): OrderSearchResultRecord {
  return {
    brokerageAccountNumber: "",
    orderId: "",
    ticketNumber: "",
    side: 0,
    type: 0,
    limitPrice: 0,
    stopPrice: 0,
    contractName: "",
    submittedUtcTime: 0,
    status: 0,
    userName: "",
    customerLastName: "",
    brokerageName: "",
    routeName: "",
    accountId: 0,
    accountName: "",
    nativeRouteName: "",
    routeClientOrderId: "",
    exchangeName: "",
    chainOrderId: "",
    displayLimitPrice: 0,
    displayStopPrice: 0,
    obsoleteSize: 0,
    salesSeriesNumber: "",
    salesSeriesName: "",
    salesSeriesId: "",
    size: undefined,
    profileSalesSeriesId: "",
    extendedSide: 0,
    batchRequestGuid: "",
    orderState: 0,
  };
}

export const OrderSearchResultRecord = {
  encode(message: OrderSearchResultRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brokerageAccountNumber !== "") {
      writer.uint32(10).string(message.brokerageAccountNumber);
    }
    if (message.orderId !== "") {
      writer.uint32(18).string(message.orderId);
    }
    if (message.ticketNumber !== undefined && message.ticketNumber !== "") {
      writer.uint32(26).string(message.ticketNumber);
    }
    if (message.side !== 0) {
      writer.uint32(32).uint32(message.side);
    }
    if (message.type !== 0) {
      writer.uint32(40).uint32(message.type);
    }
    if (message.limitPrice !== undefined && message.limitPrice !== 0) {
      writer.uint32(49).double(message.limitPrice);
    }
    if (message.stopPrice !== undefined && message.stopPrice !== 0) {
      writer.uint32(57).double(message.stopPrice);
    }
    if (message.contractName !== "") {
      writer.uint32(66).string(message.contractName);
    }
    if (message.submittedUtcTime !== 0) {
      writer.uint32(72).sint64(message.submittedUtcTime);
    }
    if (message.status !== 0) {
      writer.uint32(80).uint32(message.status);
    }
    if (message.userName !== undefined && message.userName !== "") {
      writer.uint32(90).string(message.userName);
    }
    if (message.customerLastName !== undefined && message.customerLastName !== "") {
      writer.uint32(98).string(message.customerLastName);
    }
    if (message.brokerageName !== "") {
      writer.uint32(106).string(message.brokerageName);
    }
    if (message.routeName !== "") {
      writer.uint32(114).string(message.routeName);
    }
    if (message.accountId !== 0) {
      writer.uint32(120).sint32(message.accountId);
    }
    if (message.accountName !== "") {
      writer.uint32(130).string(message.accountName);
    }
    if (message.nativeRouteName !== undefined && message.nativeRouteName !== "") {
      writer.uint32(138).string(message.nativeRouteName);
    }
    if (message.routeClientOrderId !== "") {
      writer.uint32(146).string(message.routeClientOrderId);
    }
    if (message.exchangeName !== "") {
      writer.uint32(154).string(message.exchangeName);
    }
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      writer.uint32(162).string(message.chainOrderId);
    }
    if (message.displayLimitPrice !== undefined && message.displayLimitPrice !== 0) {
      writer.uint32(168).sint64(message.displayLimitPrice);
    }
    if (message.displayStopPrice !== undefined && message.displayStopPrice !== 0) {
      writer.uint32(176).sint64(message.displayStopPrice);
    }
    if (message.obsoleteSize !== undefined && message.obsoleteSize !== 0) {
      writer.uint32(184).uint32(message.obsoleteSize);
    }
    if (message.salesSeriesNumber !== undefined && message.salesSeriesNumber !== "") {
      writer.uint32(194).string(message.salesSeriesNumber);
    }
    if (message.salesSeriesName !== undefined && message.salesSeriesName !== "") {
      writer.uint32(202).string(message.salesSeriesName);
    }
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      writer.uint32(210).string(message.salesSeriesId);
    }
    if (message.size !== undefined) {
      Decimal.encode(message.size, writer.uint32(218).fork()).ldelim();
    }
    if (message.profileSalesSeriesId !== undefined && message.profileSalesSeriesId !== "") {
      writer.uint32(226).string(message.profileSalesSeriesId);
    }
    if (message.extendedSide !== undefined && message.extendedSide !== 0) {
      writer.uint32(232).uint32(message.extendedSide);
    }
    if (message.batchRequestGuid !== undefined && message.batchRequestGuid !== "") {
      writer.uint32(242).string(message.batchRequestGuid);
    }
    if (message.orderState !== undefined && message.orderState !== 0) {
      writer.uint32(248).uint32(message.orderState);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderSearchResultRecord {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderSearchResultRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerageAccountNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ticketNumber = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.uint32();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.limitPrice = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.stopPrice = reader.double();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.contractName = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.submittedUtcTime = longToNumber(reader.sint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.status = reader.uint32();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.userName = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.customerLastName = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.brokerageName = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.routeName = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.accountId = reader.sint32();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.accountName = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.nativeRouteName = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.routeClientOrderId = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.exchangeName = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.chainOrderId = reader.string();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.displayLimitPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.displayStopPrice = longToNumber(reader.sint64() as Long);
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.obsoleteSize = reader.uint32();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.salesSeriesNumber = reader.string();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.salesSeriesName = reader.string();
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.salesSeriesId = reader.string();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.size = Decimal.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.profileSalesSeriesId = reader.string();
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.extendedSide = reader.uint32();
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.batchRequestGuid = reader.string();
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.orderState = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderSearchResultRecord {
    return {
      brokerageAccountNumber: isSet(object.brokerageAccountNumber)
        ? globalThis.String(object.brokerageAccountNumber)
        : "",
      orderId: isSet(object.orderId) ? globalThis.String(object.orderId) : "",
      ticketNumber: isSet(object.ticketNumber) ? globalThis.String(object.ticketNumber) : "",
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      type: isSet(object.type) ? globalThis.Number(object.type) : 0,
      limitPrice: isSet(object.limitPrice) ? globalThis.Number(object.limitPrice) : 0,
      stopPrice: isSet(object.stopPrice) ? globalThis.Number(object.stopPrice) : 0,
      contractName: isSet(object.contractName) ? globalThis.String(object.contractName) : "",
      submittedUtcTime: isSet(object.submittedUtcTime) ? globalThis.Number(object.submittedUtcTime) : 0,
      status: isSet(object.status) ? globalThis.Number(object.status) : 0,
      userName: isSet(object.userName) ? globalThis.String(object.userName) : "",
      customerLastName: isSet(object.customerLastName) ? globalThis.String(object.customerLastName) : "",
      brokerageName: isSet(object.brokerageName) ? globalThis.String(object.brokerageName) : "",
      routeName: isSet(object.routeName) ? globalThis.String(object.routeName) : "",
      accountId: isSet(object.accountId) ? globalThis.Number(object.accountId) : 0,
      accountName: isSet(object.accountName) ? globalThis.String(object.accountName) : "",
      nativeRouteName: isSet(object.nativeRouteName) ? globalThis.String(object.nativeRouteName) : "",
      routeClientOrderId: isSet(object.routeClientOrderId) ? globalThis.String(object.routeClientOrderId) : "",
      exchangeName: isSet(object.exchangeName) ? globalThis.String(object.exchangeName) : "",
      chainOrderId: isSet(object.chainOrderId) ? globalThis.String(object.chainOrderId) : "",
      displayLimitPrice: isSet(object.displayLimitPrice) ? globalThis.Number(object.displayLimitPrice) : 0,
      displayStopPrice: isSet(object.displayStopPrice) ? globalThis.Number(object.displayStopPrice) : 0,
      obsoleteSize: isSet(object.obsoleteSize) ? globalThis.Number(object.obsoleteSize) : 0,
      salesSeriesNumber: isSet(object.salesSeriesNumber) ? globalThis.String(object.salesSeriesNumber) : "",
      salesSeriesName: isSet(object.salesSeriesName) ? globalThis.String(object.salesSeriesName) : "",
      salesSeriesId: isSet(object.salesSeriesId) ? globalThis.String(object.salesSeriesId) : "",
      size: isSet(object.size) ? Decimal.fromJSON(object.size) : undefined,
      profileSalesSeriesId: isSet(object.profileSalesSeriesId) ? globalThis.String(object.profileSalesSeriesId) : "",
      extendedSide: isSet(object.extendedSide) ? globalThis.Number(object.extendedSide) : 0,
      batchRequestGuid: isSet(object.batchRequestGuid) ? globalThis.String(object.batchRequestGuid) : "",
      orderState: isSet(object.orderState) ? globalThis.Number(object.orderState) : 0,
    };
  },

  toJSON(message: OrderSearchResultRecord): unknown {
    const obj: any = {};
    if (message.brokerageAccountNumber !== "") {
      obj.brokerageAccountNumber = message.brokerageAccountNumber;
    }
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.ticketNumber !== undefined && message.ticketNumber !== "") {
      obj.ticketNumber = message.ticketNumber;
    }
    if (message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.type !== 0) {
      obj.type = Math.round(message.type);
    }
    if (message.limitPrice !== undefined && message.limitPrice !== 0) {
      obj.limitPrice = message.limitPrice;
    }
    if (message.stopPrice !== undefined && message.stopPrice !== 0) {
      obj.stopPrice = message.stopPrice;
    }
    if (message.contractName !== "") {
      obj.contractName = message.contractName;
    }
    if (message.submittedUtcTime !== 0) {
      obj.submittedUtcTime = Math.round(message.submittedUtcTime);
    }
    if (message.status !== 0) {
      obj.status = Math.round(message.status);
    }
    if (message.userName !== undefined && message.userName !== "") {
      obj.userName = message.userName;
    }
    if (message.customerLastName !== undefined && message.customerLastName !== "") {
      obj.customerLastName = message.customerLastName;
    }
    if (message.brokerageName !== "") {
      obj.brokerageName = message.brokerageName;
    }
    if (message.routeName !== "") {
      obj.routeName = message.routeName;
    }
    if (message.accountId !== 0) {
      obj.accountId = Math.round(message.accountId);
    }
    if (message.accountName !== "") {
      obj.accountName = message.accountName;
    }
    if (message.nativeRouteName !== undefined && message.nativeRouteName !== "") {
      obj.nativeRouteName = message.nativeRouteName;
    }
    if (message.routeClientOrderId !== "") {
      obj.routeClientOrderId = message.routeClientOrderId;
    }
    if (message.exchangeName !== "") {
      obj.exchangeName = message.exchangeName;
    }
    if (message.chainOrderId !== undefined && message.chainOrderId !== "") {
      obj.chainOrderId = message.chainOrderId;
    }
    if (message.displayLimitPrice !== undefined && message.displayLimitPrice !== 0) {
      obj.displayLimitPrice = Math.round(message.displayLimitPrice);
    }
    if (message.displayStopPrice !== undefined && message.displayStopPrice !== 0) {
      obj.displayStopPrice = Math.round(message.displayStopPrice);
    }
    if (message.obsoleteSize !== undefined && message.obsoleteSize !== 0) {
      obj.obsoleteSize = Math.round(message.obsoleteSize);
    }
    if (message.salesSeriesNumber !== undefined && message.salesSeriesNumber !== "") {
      obj.salesSeriesNumber = message.salesSeriesNumber;
    }
    if (message.salesSeriesName !== undefined && message.salesSeriesName !== "") {
      obj.salesSeriesName = message.salesSeriesName;
    }
    if (message.salesSeriesId !== undefined && message.salesSeriesId !== "") {
      obj.salesSeriesId = message.salesSeriesId;
    }
    if (message.size !== undefined) {
      obj.size = Decimal.toJSON(message.size);
    }
    if (message.profileSalesSeriesId !== undefined && message.profileSalesSeriesId !== "") {
      obj.profileSalesSeriesId = message.profileSalesSeriesId;
    }
    if (message.extendedSide !== undefined && message.extendedSide !== 0) {
      obj.extendedSide = Math.round(message.extendedSide);
    }
    if (message.batchRequestGuid !== undefined && message.batchRequestGuid !== "") {
      obj.batchRequestGuid = message.batchRequestGuid;
    }
    if (message.orderState !== undefined && message.orderState !== 0) {
      obj.orderState = Math.round(message.orderState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderSearchResultRecord>, I>>(base?: I): OrderSearchResultRecord {
    return OrderSearchResultRecord.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrderSearchResultRecord>, I>>(object: I): OrderSearchResultRecord {
    const message = createBaseOrderSearchResultRecord();
    message.brokerageAccountNumber = object.brokerageAccountNumber ?? "";
    message.orderId = object.orderId ?? "";
    message.ticketNumber = object.ticketNumber ?? "";
    message.side = object.side ?? 0;
    message.type = object.type ?? 0;
    message.limitPrice = object.limitPrice ?? 0;
    message.stopPrice = object.stopPrice ?? 0;
    message.contractName = object.contractName ?? "";
    message.submittedUtcTime = object.submittedUtcTime ?? 0;
    message.status = object.status ?? 0;
    message.userName = object.userName ?? "";
    message.customerLastName = object.customerLastName ?? "";
    message.brokerageName = object.brokerageName ?? "";
    message.routeName = object.routeName ?? "";
    message.accountId = object.accountId ?? 0;
    message.accountName = object.accountName ?? "";
    message.nativeRouteName = object.nativeRouteName ?? "";
    message.routeClientOrderId = object.routeClientOrderId ?? "";
    message.exchangeName = object.exchangeName ?? "";
    message.chainOrderId = object.chainOrderId ?? "";
    message.displayLimitPrice = object.displayLimitPrice ?? 0;
    message.displayStopPrice = object.displayStopPrice ?? 0;
    message.obsoleteSize = object.obsoleteSize ?? 0;
    message.salesSeriesNumber = object.salesSeriesNumber ?? "";
    message.salesSeriesName = object.salesSeriesName ?? "";
    message.salesSeriesId = object.salesSeriesId ?? "";
    message.size = (object.size !== undefined && object.size !== null) ? Decimal.fromPartial(object.size) : undefined;
    message.profileSalesSeriesId = object.profileSalesSeriesId ?? "";
    message.extendedSide = object.extendedSide ?? 0;
    message.batchRequestGuid = object.batchRequestGuid ?? "";
    message.orderState = object.orderState ?? 0;
    return message;
  },
};

function createBaseAccountCluster(): AccountCluster {
  return { id: "", brokerageId: "", name: "", isRemoved: false };
}

export const AccountCluster = {
  encode(message: AccountCluster, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.brokerageId !== undefined && message.brokerageId !== "") {
      writer.uint32(18).string(message.brokerageId);
    }
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.isRemoved !== undefined && message.isRemoved !== false) {
      writer.uint32(32).bool(message.isRemoved);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountCluster {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountCluster();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.brokerageId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isRemoved = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountCluster {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      brokerageId: isSet(object.brokerageId) ? globalThis.String(object.brokerageId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      isRemoved: isSet(object.isRemoved) ? globalThis.Boolean(object.isRemoved) : false,
    };
  },

  toJSON(message: AccountCluster): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.brokerageId !== undefined && message.brokerageId !== "") {
      obj.brokerageId = message.brokerageId;
    }
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.isRemoved !== undefined && message.isRemoved !== false) {
      obj.isRemoved = message.isRemoved;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountCluster>, I>>(base?: I): AccountCluster {
    return AccountCluster.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountCluster>, I>>(object: I): AccountCluster {
    const message = createBaseAccountCluster();
    message.id = object.id ?? "";
    message.brokerageId = object.brokerageId ?? "";
    message.name = object.name ?? "";
    message.isRemoved = object.isRemoved ?? false;
    return message;
  },
};

function createBaseAccountClusterPriceOffset(): AccountClusterPriceOffset {
  return {
    clearedFields: [],
    commodityId: "",
    instrumentTypeId: 0,
    priceOffsetTicks: 0,
    hedgeOffsetTicks: 0,
    hedgeExecInstruction: [],
    icebergVisibleQtyPercent: 0,
  };
}

export const AccountClusterPriceOffset = {
  encode(message: AccountClusterPriceOffset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(58).fork();
    for (const v of message.clearedFields) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.commodityId !== undefined && message.commodityId !== "") {
      writer.uint32(10).string(message.commodityId);
    }
    if (message.instrumentTypeId !== undefined && message.instrumentTypeId !== 0) {
      writer.uint32(16).sint32(message.instrumentTypeId);
    }
    if (message.priceOffsetTicks !== undefined && message.priceOffsetTicks !== 0) {
      writer.uint32(24).sint32(message.priceOffsetTicks);
    }
    if (message.hedgeOffsetTicks !== undefined && message.hedgeOffsetTicks !== 0) {
      writer.uint32(32).sint32(message.hedgeOffsetTicks);
    }
    writer.uint32(42).fork();
    for (const v of message.hedgeExecInstruction) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.icebergVisibleQtyPercent !== undefined && message.icebergVisibleQtyPercent !== 0) {
      writer.uint32(48).uint32(message.icebergVisibleQtyPercent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountClusterPriceOffset {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountClusterPriceOffset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 7:
          if (tag === 56) {
            message.clearedFields.push(reader.uint32());

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.clearedFields.push(reader.uint32());
            }

            continue;
          }

          break;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.commodityId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.instrumentTypeId = reader.sint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.priceOffsetTicks = reader.sint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.hedgeOffsetTicks = reader.sint32();
          continue;
        case 5:
          if (tag === 40) {
            message.hedgeExecInstruction.push(reader.uint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.hedgeExecInstruction.push(reader.uint32());
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.icebergVisibleQtyPercent = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountClusterPriceOffset {
    return {
      clearedFields: globalThis.Array.isArray(object?.clearedFields)
        ? object.clearedFields.map((e: any) => globalThis.Number(e))
        : [],
      commodityId: isSet(object.commodityId) ? globalThis.String(object.commodityId) : "",
      instrumentTypeId: isSet(object.instrumentTypeId) ? globalThis.Number(object.instrumentTypeId) : 0,
      priceOffsetTicks: isSet(object.priceOffsetTicks) ? globalThis.Number(object.priceOffsetTicks) : 0,
      hedgeOffsetTicks: isSet(object.hedgeOffsetTicks) ? globalThis.Number(object.hedgeOffsetTicks) : 0,
      hedgeExecInstruction: globalThis.Array.isArray(object?.hedgeExecInstruction)
        ? object.hedgeExecInstruction.map((e: any) => globalThis.Number(e))
        : [],
      icebergVisibleQtyPercent: isSet(object.icebergVisibleQtyPercent)
        ? globalThis.Number(object.icebergVisibleQtyPercent)
        : 0,
    };
  },

  toJSON(message: AccountClusterPriceOffset): unknown {
    const obj: any = {};
    if (message.clearedFields?.length) {
      obj.clearedFields = message.clearedFields.map((e) => Math.round(e));
    }
    if (message.commodityId !== undefined && message.commodityId !== "") {
      obj.commodityId = message.commodityId;
    }
    if (message.instrumentTypeId !== undefined && message.instrumentTypeId !== 0) {
      obj.instrumentTypeId = Math.round(message.instrumentTypeId);
    }
    if (message.priceOffsetTicks !== undefined && message.priceOffsetTicks !== 0) {
      obj.priceOffsetTicks = Math.round(message.priceOffsetTicks);
    }
    if (message.hedgeOffsetTicks !== undefined && message.hedgeOffsetTicks !== 0) {
      obj.hedgeOffsetTicks = Math.round(message.hedgeOffsetTicks);
    }
    if (message.hedgeExecInstruction?.length) {
      obj.hedgeExecInstruction = message.hedgeExecInstruction.map((e) => Math.round(e));
    }
    if (message.icebergVisibleQtyPercent !== undefined && message.icebergVisibleQtyPercent !== 0) {
      obj.icebergVisibleQtyPercent = Math.round(message.icebergVisibleQtyPercent);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountClusterPriceOffset>, I>>(base?: I): AccountClusterPriceOffset {
    return AccountClusterPriceOffset.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountClusterPriceOffset>, I>>(object: I): AccountClusterPriceOffset {
    const message = createBaseAccountClusterPriceOffset();
    message.clearedFields = object.clearedFields?.map((e) => e) || [];
    message.commodityId = object.commodityId ?? "";
    message.instrumentTypeId = object.instrumentTypeId ?? 0;
    message.priceOffsetTicks = object.priceOffsetTicks ?? 0;
    message.hedgeOffsetTicks = object.hedgeOffsetTicks ?? 0;
    message.hedgeExecInstruction = object.hedgeExecInstruction?.map((e) => e) || [];
    message.icebergVisibleQtyPercent = object.icebergVisibleQtyPercent ?? 0;
    return message;
  },
};

function createBaseAvailableRoute(): AvailableRoute {
  return { routeInformation: undefined, priority: 0, omnibusSupportType: 0, omnibusAccounts: [] };
}

export const AvailableRoute = {
  encode(message: AvailableRoute, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.routeInformation !== undefined) {
      RouteInformation.encode(message.routeInformation, writer.uint32(10).fork()).ldelim();
    }
    if (message.priority !== undefined && message.priority !== 0) {
      writer.uint32(16).uint32(message.priority);
    }
    if (message.omnibusSupportType !== undefined && message.omnibusSupportType !== 0) {
      writer.uint32(24).uint32(message.omnibusSupportType);
    }
    for (const v of message.omnibusAccounts) {
      RouteOmnibusAccount.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AvailableRoute {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAvailableRoute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.routeInformation = RouteInformation.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.priority = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.omnibusSupportType = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.omnibusAccounts.push(RouteOmnibusAccount.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AvailableRoute {
    return {
      routeInformation: isSet(object.routeInformation) ? RouteInformation.fromJSON(object.routeInformation) : undefined,
      priority: isSet(object.priority) ? globalThis.Number(object.priority) : 0,
      omnibusSupportType: isSet(object.omnibusSupportType) ? globalThis.Number(object.omnibusSupportType) : 0,
      omnibusAccounts: globalThis.Array.isArray(object?.omnibusAccounts)
        ? object.omnibusAccounts.map((e: any) => RouteOmnibusAccount.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AvailableRoute): unknown {
    const obj: any = {};
    if (message.routeInformation !== undefined) {
      obj.routeInformation = RouteInformation.toJSON(message.routeInformation);
    }
    if (message.priority !== undefined && message.priority !== 0) {
      obj.priority = Math.round(message.priority);
    }
    if (message.omnibusSupportType !== undefined && message.omnibusSupportType !== 0) {
      obj.omnibusSupportType = Math.round(message.omnibusSupportType);
    }
    if (message.omnibusAccounts?.length) {
      obj.omnibusAccounts = message.omnibusAccounts.map((e) => RouteOmnibusAccount.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AvailableRoute>, I>>(base?: I): AvailableRoute {
    return AvailableRoute.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AvailableRoute>, I>>(object: I): AvailableRoute {
    const message = createBaseAvailableRoute();
    message.routeInformation = (object.routeInformation !== undefined && object.routeInformation !== null)
      ? RouteInformation.fromPartial(object.routeInformation)
      : undefined;
    message.priority = object.priority ?? 0;
    message.omnibusSupportType = object.omnibusSupportType ?? 0;
    message.omnibusAccounts = object.omnibusAccounts?.map((e) => RouteOmnibusAccount.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRouteOmnibusAccount(): RouteOmnibusAccount {
  return { accountId: "", accountName: "", brokerageAccountNumber: "", priority: 0, riskServerInstanceId: "" };
}

export const RouteOmnibusAccount = {
  encode(message: RouteOmnibusAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    if (message.accountName !== undefined && message.accountName !== "") {
      writer.uint32(18).string(message.accountName);
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      writer.uint32(26).string(message.brokerageAccountNumber);
    }
    if (message.priority !== undefined && message.priority !== 0) {
      writer.uint32(32).uint32(message.priority);
    }
    if (message.riskServerInstanceId !== undefined && message.riskServerInstanceId !== "") {
      writer.uint32(42).string(message.riskServerInstanceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RouteOmnibusAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRouteOmnibusAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.brokerageAccountNumber = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.priority = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.riskServerInstanceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RouteOmnibusAccount {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      accountName: isSet(object.accountName) ? globalThis.String(object.accountName) : "",
      brokerageAccountNumber: isSet(object.brokerageAccountNumber)
        ? globalThis.String(object.brokerageAccountNumber)
        : "",
      priority: isSet(object.priority) ? globalThis.Number(object.priority) : 0,
      riskServerInstanceId: isSet(object.riskServerInstanceId) ? globalThis.String(object.riskServerInstanceId) : "",
    };
  },

  toJSON(message: RouteOmnibusAccount): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.accountName !== undefined && message.accountName !== "") {
      obj.accountName = message.accountName;
    }
    if (message.brokerageAccountNumber !== undefined && message.brokerageAccountNumber !== "") {
      obj.brokerageAccountNumber = message.brokerageAccountNumber;
    }
    if (message.priority !== undefined && message.priority !== 0) {
      obj.priority = Math.round(message.priority);
    }
    if (message.riskServerInstanceId !== undefined && message.riskServerInstanceId !== "") {
      obj.riskServerInstanceId = message.riskServerInstanceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RouteOmnibusAccount>, I>>(base?: I): RouteOmnibusAccount {
    return RouteOmnibusAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RouteOmnibusAccount>, I>>(object: I): RouteOmnibusAccount {
    const message = createBaseRouteOmnibusAccount();
    message.accountId = object.accountId ?? "";
    message.accountName = object.accountName ?? "";
    message.brokerageAccountNumber = object.brokerageAccountNumber ?? "";
    message.priority = object.priority ?? 0;
    message.riskServerInstanceId = object.riskServerInstanceId ?? "";
    return message;
  },
};

function createBaseRouteInformation(): RouteInformation {
  return {
    routeCode: "",
    routeName: "",
    syntheticStrategies: false,
    attributes: [],
    obsoleteHasOverlappedInstruments: false,
    authorizationRequired: false,
    allowSyntheticOrderTypes: false,
    routeAlgoStrategies: [],
    primaryLoginRequired: false,
  };
}

export const RouteInformation = {
  encode(message: RouteInformation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.routeCode !== undefined && message.routeCode !== "") {
      writer.uint32(10).string(message.routeCode);
    }
    if (message.routeName !== undefined && message.routeName !== "") {
      writer.uint32(18).string(message.routeName);
    }
    if (message.syntheticStrategies !== undefined && message.syntheticStrategies !== false) {
      writer.uint32(24).bool(message.syntheticStrategies);
    }
    for (const v of message.attributes) {
      AccountRouteAttribute.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.obsoleteHasOverlappedInstruments !== undefined && message.obsoleteHasOverlappedInstruments !== false) {
      writer.uint32(40).bool(message.obsoleteHasOverlappedInstruments);
    }
    if (message.authorizationRequired !== undefined && message.authorizationRequired !== false) {
      writer.uint32(48).bool(message.authorizationRequired);
    }
    if (message.allowSyntheticOrderTypes !== undefined && message.allowSyntheticOrderTypes !== false) {
      writer.uint32(56).bool(message.allowSyntheticOrderTypes);
    }
    for (const v of message.routeAlgoStrategies) {
      RouteAlgoStrategy.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.primaryLoginRequired !== undefined && message.primaryLoginRequired !== false) {
      writer.uint32(72).bool(message.primaryLoginRequired);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RouteInformation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRouteInformation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.routeCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.routeName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.syntheticStrategies = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.attributes.push(AccountRouteAttribute.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.obsoleteHasOverlappedInstruments = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.authorizationRequired = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.allowSyntheticOrderTypes = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.routeAlgoStrategies.push(RouteAlgoStrategy.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.primaryLoginRequired = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RouteInformation {
    return {
      routeCode: isSet(object.routeCode) ? globalThis.String(object.routeCode) : "",
      routeName: isSet(object.routeName) ? globalThis.String(object.routeName) : "",
      syntheticStrategies: isSet(object.syntheticStrategies) ? globalThis.Boolean(object.syntheticStrategies) : false,
      attributes: globalThis.Array.isArray(object?.attributes)
        ? object.attributes.map((e: any) => AccountRouteAttribute.fromJSON(e))
        : [],
      obsoleteHasOverlappedInstruments: isSet(object.obsoleteHasOverlappedInstruments)
        ? globalThis.Boolean(object.obsoleteHasOverlappedInstruments)
        : false,
      authorizationRequired: isSet(object.authorizationRequired)
        ? globalThis.Boolean(object.authorizationRequired)
        : false,
      allowSyntheticOrderTypes: isSet(object.allowSyntheticOrderTypes)
        ? globalThis.Boolean(object.allowSyntheticOrderTypes)
        : false,
      routeAlgoStrategies: globalThis.Array.isArray(object?.routeAlgoStrategies)
        ? object.routeAlgoStrategies.map((e: any) => RouteAlgoStrategy.fromJSON(e))
        : [],
      primaryLoginRequired: isSet(object.primaryLoginRequired)
        ? globalThis.Boolean(object.primaryLoginRequired)
        : false,
    };
  },

  toJSON(message: RouteInformation): unknown {
    const obj: any = {};
    if (message.routeCode !== undefined && message.routeCode !== "") {
      obj.routeCode = message.routeCode;
    }
    if (message.routeName !== undefined && message.routeName !== "") {
      obj.routeName = message.routeName;
    }
    if (message.syntheticStrategies !== undefined && message.syntheticStrategies !== false) {
      obj.syntheticStrategies = message.syntheticStrategies;
    }
    if (message.attributes?.length) {
      obj.attributes = message.attributes.map((e) => AccountRouteAttribute.toJSON(e));
    }
    if (message.obsoleteHasOverlappedInstruments !== undefined && message.obsoleteHasOverlappedInstruments !== false) {
      obj.obsoleteHasOverlappedInstruments = message.obsoleteHasOverlappedInstruments;
    }
    if (message.authorizationRequired !== undefined && message.authorizationRequired !== false) {
      obj.authorizationRequired = message.authorizationRequired;
    }
    if (message.allowSyntheticOrderTypes !== undefined && message.allowSyntheticOrderTypes !== false) {
      obj.allowSyntheticOrderTypes = message.allowSyntheticOrderTypes;
    }
    if (message.routeAlgoStrategies?.length) {
      obj.routeAlgoStrategies = message.routeAlgoStrategies.map((e) => RouteAlgoStrategy.toJSON(e));
    }
    if (message.primaryLoginRequired !== undefined && message.primaryLoginRequired !== false) {
      obj.primaryLoginRequired = message.primaryLoginRequired;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RouteInformation>, I>>(base?: I): RouteInformation {
    return RouteInformation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RouteInformation>, I>>(object: I): RouteInformation {
    const message = createBaseRouteInformation();
    message.routeCode = object.routeCode ?? "";
    message.routeName = object.routeName ?? "";
    message.syntheticStrategies = object.syntheticStrategies ?? false;
    message.attributes = object.attributes?.map((e) => AccountRouteAttribute.fromPartial(e)) || [];
    message.obsoleteHasOverlappedInstruments = object.obsoleteHasOverlappedInstruments ?? false;
    message.authorizationRequired = object.authorizationRequired ?? false;
    message.allowSyntheticOrderTypes = object.allowSyntheticOrderTypes ?? false;
    message.routeAlgoStrategies = object.routeAlgoStrategies?.map((e) => RouteAlgoStrategy.fromPartial(e)) || [];
    message.primaryLoginRequired = object.primaryLoginRequired ?? false;
    return message;
  },
};

function createBaseRouteAlgoStrategy(): RouteAlgoStrategy {
  return { id: "", name: undefined, removed: false };
}

export const RouteAlgoStrategy = {
  encode(message: RouteAlgoStrategy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      Text.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    if (message.removed !== undefined && message.removed !== false) {
      writer.uint32(24).bool(message.removed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RouteAlgoStrategy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRouteAlgoStrategy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Text.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.removed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RouteAlgoStrategy {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? Text.fromJSON(object.name) : undefined,
      removed: isSet(object.removed) ? globalThis.Boolean(object.removed) : false,
    };
  },

  toJSON(message: RouteAlgoStrategy): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== undefined) {
      obj.name = Text.toJSON(message.name);
    }
    if (message.removed !== undefined && message.removed !== false) {
      obj.removed = message.removed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RouteAlgoStrategy>, I>>(base?: I): RouteAlgoStrategy {
    return RouteAlgoStrategy.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RouteAlgoStrategy>, I>>(object: I): RouteAlgoStrategy {
    const message = createBaseRouteAlgoStrategy();
    message.id = object.id ?? "";
    message.name = (object.name !== undefined && object.name !== null) ? Text.fromPartial(object.name) : undefined;
    message.removed = object.removed ?? false;
    return message;
  },
};

function createBaseRestoreAccount(): RestoreAccount {
  return { accountId: "" };
}

export const RestoreAccount = {
  encode(message: RestoreAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RestoreAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRestoreAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RestoreAccount {
    return { accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "" };
  },

  toJSON(message: RestoreAccount): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RestoreAccount>, I>>(base?: I): RestoreAccount {
    return RestoreAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RestoreAccount>, I>>(object: I): RestoreAccount {
    const message = createBaseRestoreAccount();
    message.accountId = object.accountId ?? "";
    return message;
  },
};

function createBaseAccountTypeOverrideListRequest(): AccountTypeOverrideListRequest {
  return { accountId: "" };
}

export const AccountTypeOverrideListRequest = {
  encode(message: AccountTypeOverrideListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountTypeOverrideListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountTypeOverrideListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountTypeOverrideListRequest {
    return { accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "" };
  },

  toJSON(message: AccountTypeOverrideListRequest): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountTypeOverrideListRequest>, I>>(base?: I): AccountTypeOverrideListRequest {
    return AccountTypeOverrideListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountTypeOverrideListRequest>, I>>(
    object: I,
  ): AccountTypeOverrideListRequest {
    const message = createBaseAccountTypeOverrideListRequest();
    message.accountId = object.accountId ?? "";
    return message;
  },
};

function createBaseUpdateAccountTypeOverrideList(): UpdateAccountTypeOverrideList {
  return { accountId: "", overridesToSet: [], overridesToRemove: [] };
}

export const UpdateAccountTypeOverrideList = {
  encode(message: UpdateAccountTypeOverrideList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    for (const v of message.overridesToSet) {
      AccountTypeOverride.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.overridesToRemove) {
      Tuple.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountTypeOverrideList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountTypeOverrideList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.overridesToSet.push(AccountTypeOverride.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.overridesToRemove.push(Tuple.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountTypeOverrideList {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      overridesToSet: globalThis.Array.isArray(object?.overridesToSet)
        ? object.overridesToSet.map((e: any) => AccountTypeOverride.fromJSON(e))
        : [],
      overridesToRemove: globalThis.Array.isArray(object?.overridesToRemove)
        ? object.overridesToRemove.map((e: any) => Tuple.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateAccountTypeOverrideList): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.overridesToSet?.length) {
      obj.overridesToSet = message.overridesToSet.map((e) => AccountTypeOverride.toJSON(e));
    }
    if (message.overridesToRemove?.length) {
      obj.overridesToRemove = message.overridesToRemove.map((e) => Tuple.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountTypeOverrideList>, I>>(base?: I): UpdateAccountTypeOverrideList {
    return UpdateAccountTypeOverrideList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountTypeOverrideList>, I>>(
    object: I,
  ): UpdateAccountTypeOverrideList {
    const message = createBaseUpdateAccountTypeOverrideList();
    message.accountId = object.accountId ?? "";
    message.overridesToSet = object.overridesToSet?.map((e) => AccountTypeOverride.fromPartial(e)) || [];
    message.overridesToRemove = object.overridesToRemove?.map((e) => Tuple.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRestoreAccountResult(): RestoreAccountResult {
  return {};
}

export const RestoreAccountResult = {
  encode(_: RestoreAccountResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RestoreAccountResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRestoreAccountResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RestoreAccountResult {
    return {};
  },

  toJSON(_: RestoreAccountResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RestoreAccountResult>, I>>(base?: I): RestoreAccountResult {
    return RestoreAccountResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RestoreAccountResult>, I>>(_: I): RestoreAccountResult {
    const message = createBaseRestoreAccountResult();
    return message;
  },
};

function createBaseAccountTypeOverrideListResult(): AccountTypeOverrideListResult {
  return { overrides: [] };
}

export const AccountTypeOverrideListResult = {
  encode(message: AccountTypeOverrideListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.overrides) {
      AccountTypeOverride.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountTypeOverrideListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountTypeOverrideListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.overrides.push(AccountTypeOverride.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountTypeOverrideListResult {
    return {
      overrides: globalThis.Array.isArray(object?.overrides)
        ? object.overrides.map((e: any) => AccountTypeOverride.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountTypeOverrideListResult): unknown {
    const obj: any = {};
    if (message.overrides?.length) {
      obj.overrides = message.overrides.map((e) => AccountTypeOverride.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountTypeOverrideListResult>, I>>(base?: I): AccountTypeOverrideListResult {
    return AccountTypeOverrideListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountTypeOverrideListResult>, I>>(
    object: I,
  ): AccountTypeOverrideListResult {
    const message = createBaseAccountTypeOverrideListResult();
    message.overrides = object.overrides?.map((e) => AccountTypeOverride.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccountTypeOverride(): AccountTypeOverride {
  return { exchangeId: "", loginId: "", typeId: "", originId: "" };
}

export const AccountTypeOverride = {
  encode(message: AccountTypeOverride, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.exchangeId !== undefined && message.exchangeId !== "") {
      writer.uint32(18).string(message.exchangeId);
    }
    if (message.loginId !== undefined && message.loginId !== "") {
      writer.uint32(26).string(message.loginId);
    }
    if (message.typeId !== undefined && message.typeId !== "") {
      writer.uint32(34).string(message.typeId);
    }
    if (message.originId !== undefined && message.originId !== "") {
      writer.uint32(42).string(message.originId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountTypeOverride {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountTypeOverride();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.exchangeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.loginId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.typeId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.originId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountTypeOverride {
    return {
      exchangeId: isSet(object.exchangeId) ? globalThis.String(object.exchangeId) : "",
      loginId: isSet(object.loginId) ? globalThis.String(object.loginId) : "",
      typeId: isSet(object.typeId) ? globalThis.String(object.typeId) : "",
      originId: isSet(object.originId) ? globalThis.String(object.originId) : "",
    };
  },

  toJSON(message: AccountTypeOverride): unknown {
    const obj: any = {};
    if (message.exchangeId !== undefined && message.exchangeId !== "") {
      obj.exchangeId = message.exchangeId;
    }
    if (message.loginId !== undefined && message.loginId !== "") {
      obj.loginId = message.loginId;
    }
    if (message.typeId !== undefined && message.typeId !== "") {
      obj.typeId = message.typeId;
    }
    if (message.originId !== undefined && message.originId !== "") {
      obj.originId = message.originId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountTypeOverride>, I>>(base?: I): AccountTypeOverride {
    return AccountTypeOverride.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountTypeOverride>, I>>(object: I): AccountTypeOverride {
    const message = createBaseAccountTypeOverride();
    message.exchangeId = object.exchangeId ?? "";
    message.loginId = object.loginId ?? "";
    message.typeId = object.typeId ?? "";
    message.originId = object.originId ?? "";
    return message;
  },
};

function createBaseUpdateAccountTypeOverrideListResult(): UpdateAccountTypeOverrideListResult {
  return {};
}

export const UpdateAccountTypeOverrideListResult = {
  encode(_: UpdateAccountTypeOverrideListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountTypeOverrideListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountTypeOverrideListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateAccountTypeOverrideListResult {
    return {};
  },

  toJSON(_: UpdateAccountTypeOverrideListResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountTypeOverrideListResult>, I>>(
    base?: I,
  ): UpdateAccountTypeOverrideListResult {
    return UpdateAccountTypeOverrideListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountTypeOverrideListResult>, I>>(
    _: I,
  ): UpdateAccountTypeOverrideListResult {
    const message = createBaseUpdateAccountTypeOverrideListResult();
    return message;
  },
};

function createBaseUpdateAccountUserAuthorizationList(): UpdateAccountUserAuthorizationList {
  return { linksToSet: [], linksToRemove: [] };
}

export const UpdateAccountUserAuthorizationList = {
  encode(message: UpdateAccountUserAuthorizationList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.linksToSet) {
      AccountUserLink.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.linksToRemove) {
      Tuple.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountUserAuthorizationList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountUserAuthorizationList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linksToSet.push(AccountUserLink.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.linksToRemove.push(Tuple.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountUserAuthorizationList {
    return {
      linksToSet: globalThis.Array.isArray(object?.linksToSet)
        ? object.linksToSet.map((e: any) => AccountUserLink.fromJSON(e))
        : [],
      linksToRemove: globalThis.Array.isArray(object?.linksToRemove)
        ? object.linksToRemove.map((e: any) => Tuple.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateAccountUserAuthorizationList): unknown {
    const obj: any = {};
    if (message.linksToSet?.length) {
      obj.linksToSet = message.linksToSet.map((e) => AccountUserLink.toJSON(e));
    }
    if (message.linksToRemove?.length) {
      obj.linksToRemove = message.linksToRemove.map((e) => Tuple.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountUserAuthorizationList>, I>>(
    base?: I,
  ): UpdateAccountUserAuthorizationList {
    return UpdateAccountUserAuthorizationList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountUserAuthorizationList>, I>>(
    object: I,
  ): UpdateAccountUserAuthorizationList {
    const message = createBaseUpdateAccountUserAuthorizationList();
    message.linksToSet = object.linksToSet?.map((e) => AccountUserLink.fromPartial(e)) || [];
    message.linksToRemove = object.linksToRemove?.map((e) => Tuple.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateAccountUserAuthorizationListResult(): UpdateAccountUserAuthorizationListResult {
  return {};
}

export const UpdateAccountUserAuthorizationListResult = {
  encode(_: UpdateAccountUserAuthorizationListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountUserAuthorizationListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountUserAuthorizationListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateAccountUserAuthorizationListResult {
    return {};
  },

  toJSON(_: UpdateAccountUserAuthorizationListResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountUserAuthorizationListResult>, I>>(
    base?: I,
  ): UpdateAccountUserAuthorizationListResult {
    return UpdateAccountUserAuthorizationListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountUserAuthorizationListResult>, I>>(
    _: I,
  ): UpdateAccountUserAuthorizationListResult {
    const message = createBaseUpdateAccountUserAuthorizationListResult();
    return message;
  },
};

function createBaseRemoveAccount(): RemoveAccount {
  return { accountId: "" };
}

export const RemoveAccount = {
  encode(message: RemoveAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveAccount {
    return { accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "" };
  },

  toJSON(message: RemoveAccount): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveAccount>, I>>(base?: I): RemoveAccount {
    return RemoveAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveAccount>, I>>(object: I): RemoveAccount {
    const message = createBaseRemoveAccount();
    message.accountId = object.accountId ?? "";
    return message;
  },
};

function createBaseRemoveAccountResult(): RemoveAccountResult {
  return {};
}

export const RemoveAccountResult = {
  encode(_: RemoveAccountResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveAccountResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveAccountResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RemoveAccountResult {
    return {};
  },

  toJSON(_: RemoveAccountResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveAccountResult>, I>>(base?: I): RemoveAccountResult {
    return RemoveAccountResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveAccountResult>, I>>(_: I): RemoveAccountResult {
    const message = createBaseRemoveAccountResult();
    return message;
  },
};

function createBaseAccountAvailableServiceGroupListRequest(): AccountAvailableServiceGroupListRequest {
  return { accountId: "" };
}

export const AccountAvailableServiceGroupListRequest = {
  encode(message: AccountAvailableServiceGroupListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountAvailableServiceGroupListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountAvailableServiceGroupListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountAvailableServiceGroupListRequest {
    return { accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "" };
  },

  toJSON(message: AccountAvailableServiceGroupListRequest): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountAvailableServiceGroupListRequest>, I>>(
    base?: I,
  ): AccountAvailableServiceGroupListRequest {
    return AccountAvailableServiceGroupListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountAvailableServiceGroupListRequest>, I>>(
    object: I,
  ): AccountAvailableServiceGroupListRequest {
    const message = createBaseAccountAvailableServiceGroupListRequest();
    message.accountId = object.accountId ?? "";
    return message;
  },
};

function createBaseAccountServiceGroupListRequest(): AccountServiceGroupListRequest {
  return { accountId: "" };
}

export const AccountServiceGroupListRequest = {
  encode(message: AccountServiceGroupListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountServiceGroupListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountServiceGroupListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountServiceGroupListRequest {
    return { accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "" };
  },

  toJSON(message: AccountServiceGroupListRequest): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountServiceGroupListRequest>, I>>(base?: I): AccountServiceGroupListRequest {
    return AccountServiceGroupListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountServiceGroupListRequest>, I>>(
    object: I,
  ): AccountServiceGroupListRequest {
    const message = createBaseAccountServiceGroupListRequest();
    message.accountId = object.accountId ?? "";
    return message;
  },
};

function createBaseUpdateAccountServiceGroupList(): UpdateAccountServiceGroupList {
  return { accountId: "", serviceGroupIdsToSet: [], serviceGroupIdsToRemove: [] };
}

export const UpdateAccountServiceGroupList = {
  encode(message: UpdateAccountServiceGroupList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    for (const v of message.serviceGroupIdsToSet) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.serviceGroupIdsToRemove) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountServiceGroupList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountServiceGroupList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serviceGroupIdsToSet.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.serviceGroupIdsToRemove.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountServiceGroupList {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      serviceGroupIdsToSet: globalThis.Array.isArray(object?.serviceGroupIdsToSet)
        ? object.serviceGroupIdsToSet.map((e: any) => globalThis.String(e))
        : [],
      serviceGroupIdsToRemove: globalThis.Array.isArray(object?.serviceGroupIdsToRemove)
        ? object.serviceGroupIdsToRemove.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: UpdateAccountServiceGroupList): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.serviceGroupIdsToSet?.length) {
      obj.serviceGroupIdsToSet = message.serviceGroupIdsToSet;
    }
    if (message.serviceGroupIdsToRemove?.length) {
      obj.serviceGroupIdsToRemove = message.serviceGroupIdsToRemove;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountServiceGroupList>, I>>(base?: I): UpdateAccountServiceGroupList {
    return UpdateAccountServiceGroupList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountServiceGroupList>, I>>(
    object: I,
  ): UpdateAccountServiceGroupList {
    const message = createBaseUpdateAccountServiceGroupList();
    message.accountId = object.accountId ?? "";
    message.serviceGroupIdsToSet = object.serviceGroupIdsToSet?.map((e) => e) || [];
    message.serviceGroupIdsToRemove = object.serviceGroupIdsToRemove?.map((e) => e) || [];
    return message;
  },
};

function createBaseAccountAvailableServiceGroupListResult(): AccountAvailableServiceGroupListResult {
  return { serviceGroups: [] };
}

export const AccountAvailableServiceGroupListResult = {
  encode(message: AccountAvailableServiceGroupListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.serviceGroups) {
      ServiceGroup.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountAvailableServiceGroupListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountAvailableServiceGroupListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serviceGroups.push(ServiceGroup.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountAvailableServiceGroupListResult {
    return {
      serviceGroups: globalThis.Array.isArray(object?.serviceGroups)
        ? object.serviceGroups.map((e: any) => ServiceGroup.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountAvailableServiceGroupListResult): unknown {
    const obj: any = {};
    if (message.serviceGroups?.length) {
      obj.serviceGroups = message.serviceGroups.map((e) => ServiceGroup.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountAvailableServiceGroupListResult>, I>>(
    base?: I,
  ): AccountAvailableServiceGroupListResult {
    return AccountAvailableServiceGroupListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountAvailableServiceGroupListResult>, I>>(
    object: I,
  ): AccountAvailableServiceGroupListResult {
    const message = createBaseAccountAvailableServiceGroupListResult();
    message.serviceGroups = object.serviceGroups?.map((e) => ServiceGroup.fromPartial(e)) || [];
    return message;
  },
};

function createBaseServiceGroup(): ServiceGroup {
  return { id: "", name: "", removed: false };
}

export const ServiceGroup = {
  encode(message: ServiceGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.removed !== undefined && message.removed !== false) {
      writer.uint32(24).bool(message.removed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServiceGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServiceGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.removed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServiceGroup {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      removed: isSet(object.removed) ? globalThis.Boolean(object.removed) : false,
    };
  },

  toJSON(message: ServiceGroup): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.removed !== undefined && message.removed !== false) {
      obj.removed = message.removed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServiceGroup>, I>>(base?: I): ServiceGroup {
    return ServiceGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServiceGroup>, I>>(object: I): ServiceGroup {
    const message = createBaseServiceGroup();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.removed = object.removed ?? false;
    return message;
  },
};

function createBaseAccountServiceGroupListResult(): AccountServiceGroupListResult {
  return { serviceGroupIds: [] };
}

export const AccountServiceGroupListResult = {
  encode(message: AccountServiceGroupListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.serviceGroupIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountServiceGroupListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountServiceGroupListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serviceGroupIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountServiceGroupListResult {
    return {
      serviceGroupIds: globalThis.Array.isArray(object?.serviceGroupIds)
        ? object.serviceGroupIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: AccountServiceGroupListResult): unknown {
    const obj: any = {};
    if (message.serviceGroupIds?.length) {
      obj.serviceGroupIds = message.serviceGroupIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountServiceGroupListResult>, I>>(base?: I): AccountServiceGroupListResult {
    return AccountServiceGroupListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountServiceGroupListResult>, I>>(
    object: I,
  ): AccountServiceGroupListResult {
    const message = createBaseAccountServiceGroupListResult();
    message.serviceGroupIds = object.serviceGroupIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateAccountServiceGroupListResult(): UpdateAccountServiceGroupListResult {
  return {};
}

export const UpdateAccountServiceGroupListResult = {
  encode(_: UpdateAccountServiceGroupListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountServiceGroupListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountServiceGroupListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateAccountServiceGroupListResult {
    return {};
  },

  toJSON(_: UpdateAccountServiceGroupListResult): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountServiceGroupListResult>, I>>(
    base?: I,
  ): UpdateAccountServiceGroupListResult {
    return UpdateAccountServiceGroupListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountServiceGroupListResult>, I>>(
    _: I,
  ): UpdateAccountServiceGroupListResult {
    const message = createBaseUpdateAccountServiceGroupListResult();
    return message;
  },
};

function createBaseBillableExchangeListRequest(): BillableExchangeListRequest {
  return {};
}

export const BillableExchangeListRequest = {
  encode(_: BillableExchangeListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BillableExchangeListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBillableExchangeListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): BillableExchangeListRequest {
    return {};
  },

  toJSON(_: BillableExchangeListRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<BillableExchangeListRequest>, I>>(base?: I): BillableExchangeListRequest {
    return BillableExchangeListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BillableExchangeListRequest>, I>>(_: I): BillableExchangeListRequest {
    const message = createBaseBillableExchangeListRequest();
    return message;
  },
};

function createBaseBillableExchangeListResult(): BillableExchangeListResult {
  return { billableExchanges: [] };
}

export const BillableExchangeListResult = {
  encode(message: BillableExchangeListResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.billableExchanges) {
      BillableExchange.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BillableExchangeListResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBillableExchangeListResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.billableExchanges.push(BillableExchange.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BillableExchangeListResult {
    return {
      billableExchanges: globalThis.Array.isArray(object?.billableExchanges)
        ? object.billableExchanges.map((e: any) => BillableExchange.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BillableExchangeListResult): unknown {
    const obj: any = {};
    if (message.billableExchanges?.length) {
      obj.billableExchanges = message.billableExchanges.map((e) => BillableExchange.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BillableExchangeListResult>, I>>(base?: I): BillableExchangeListResult {
    return BillableExchangeListResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BillableExchangeListResult>, I>>(object: I): BillableExchangeListResult {
    const message = createBaseBillableExchangeListResult();
    message.billableExchanges = object.billableExchanges?.map((e) => BillableExchange.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBillableExchange(): BillableExchange {
  return { number: "", name: "", description: "", serviceGroupAuthorizationLevel: 0, serviceGroupIds: [] };
}

export const BillableExchange = {
  encode(message: BillableExchange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== undefined && message.number !== "") {
      writer.uint32(10).string(message.number);
    }
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== undefined && message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.serviceGroupAuthorizationLevel !== undefined && message.serviceGroupAuthorizationLevel !== 0) {
      writer.uint32(32).uint32(message.serviceGroupAuthorizationLevel);
    }
    for (const v of message.serviceGroupIds) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BillableExchange {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBillableExchange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.serviceGroupAuthorizationLevel = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.serviceGroupIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BillableExchange {
    return {
      number: isSet(object.number) ? globalThis.String(object.number) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      serviceGroupAuthorizationLevel: isSet(object.serviceGroupAuthorizationLevel)
        ? globalThis.Number(object.serviceGroupAuthorizationLevel)
        : 0,
      serviceGroupIds: globalThis.Array.isArray(object?.serviceGroupIds)
        ? object.serviceGroupIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: BillableExchange): unknown {
    const obj: any = {};
    if (message.number !== undefined && message.number !== "") {
      obj.number = message.number;
    }
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== undefined && message.description !== "") {
      obj.description = message.description;
    }
    if (message.serviceGroupAuthorizationLevel !== undefined && message.serviceGroupAuthorizationLevel !== 0) {
      obj.serviceGroupAuthorizationLevel = Math.round(message.serviceGroupAuthorizationLevel);
    }
    if (message.serviceGroupIds?.length) {
      obj.serviceGroupIds = message.serviceGroupIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BillableExchange>, I>>(base?: I): BillableExchange {
    return BillableExchange.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BillableExchange>, I>>(object: I): BillableExchange {
    const message = createBaseBillableExchange();
    message.number = object.number ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.serviceGroupAuthorizationLevel = object.serviceGroupAuthorizationLevel ?? 0;
    message.serviceGroupIds = object.serviceGroupIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreateAccountGroup(): CreateAccountGroup {
  return { newAccountGroup: undefined };
}

export const CreateAccountGroup = {
  encode(message: CreateAccountGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.newAccountGroup !== undefined) {
      AccountGroup.encode(message.newAccountGroup, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAccountGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAccountGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.newAccountGroup = AccountGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAccountGroup {
    return {
      newAccountGroup: isSet(object.newAccountGroup) ? AccountGroup.fromJSON(object.newAccountGroup) : undefined,
    };
  },

  toJSON(message: CreateAccountGroup): unknown {
    const obj: any = {};
    if (message.newAccountGroup !== undefined) {
      obj.newAccountGroup = AccountGroup.toJSON(message.newAccountGroup);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAccountGroup>, I>>(base?: I): CreateAccountGroup {
    return CreateAccountGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAccountGroup>, I>>(object: I): CreateAccountGroup {
    const message = createBaseCreateAccountGroup();
    message.newAccountGroup = (object.newAccountGroup !== undefined && object.newAccountGroup !== null)
      ? AccountGroup.fromPartial(object.newAccountGroup)
      : undefined;
    return message;
  },
};

function createBaseCreateAccountGroupResult(): CreateAccountGroupResult {
  return { newAccountGroupId: "" };
}

export const CreateAccountGroupResult = {
  encode(message: CreateAccountGroupResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.newAccountGroupId !== undefined && message.newAccountGroupId !== "") {
      writer.uint32(10).string(message.newAccountGroupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateAccountGroupResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAccountGroupResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.newAccountGroupId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAccountGroupResult {
    return { newAccountGroupId: isSet(object.newAccountGroupId) ? globalThis.String(object.newAccountGroupId) : "" };
  },

  toJSON(message: CreateAccountGroupResult): unknown {
    const obj: any = {};
    if (message.newAccountGroupId !== undefined && message.newAccountGroupId !== "") {
      obj.newAccountGroupId = message.newAccountGroupId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateAccountGroupResult>, I>>(base?: I): CreateAccountGroupResult {
    return CreateAccountGroupResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateAccountGroupResult>, I>>(object: I): CreateAccountGroupResult {
    const message = createBaseCreateAccountGroupResult();
    message.newAccountGroupId = object.newAccountGroupId ?? "";
    return message;
  },
};

function createBaseUpdateAccountGroup(): UpdateAccountGroup {
  return { accountGroup: undefined, subAccountsToSet: [], subAccountIdsToRemove: [] };
}

export const UpdateAccountGroup = {
  encode(message: UpdateAccountGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountGroup !== undefined) {
      AccountGroup.encode(message.accountGroup, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.subAccountsToSet) {
      SubAccount.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.subAccountIdsToRemove) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountGroup = AccountGroup.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.subAccountsToSet.push(SubAccount.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subAccountIdsToRemove.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountGroup {
    return {
      accountGroup: isSet(object.accountGroup) ? AccountGroup.fromJSON(object.accountGroup) : undefined,
      subAccountsToSet: globalThis.Array.isArray(object?.subAccountsToSet)
        ? object.subAccountsToSet.map((e: any) => SubAccount.fromJSON(e))
        : [],
      subAccountIdsToRemove: globalThis.Array.isArray(object?.subAccountIdsToRemove)
        ? object.subAccountIdsToRemove.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: UpdateAccountGroup): unknown {
    const obj: any = {};
    if (message.accountGroup !== undefined) {
      obj.accountGroup = AccountGroup.toJSON(message.accountGroup);
    }
    if (message.subAccountsToSet?.length) {
      obj.subAccountsToSet = message.subAccountsToSet.map((e) => SubAccount.toJSON(e));
    }
    if (message.subAccountIdsToRemove?.length) {
      obj.subAccountIdsToRemove = message.subAccountIdsToRemove;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountGroup>, I>>(base?: I): UpdateAccountGroup {
    return UpdateAccountGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountGroup>, I>>(object: I): UpdateAccountGroup {
    const message = createBaseUpdateAccountGroup();
    message.accountGroup = (object.accountGroup !== undefined && object.accountGroup !== null)
      ? AccountGroup.fromPartial(object.accountGroup)
      : undefined;
    message.subAccountsToSet = object.subAccountsToSet?.map((e) => SubAccount.fromPartial(e)) || [];
    message.subAccountIdsToRemove = object.subAccountIdsToRemove?.map((e) => e) || [];
    return message;
  },
};

function createBaseRemoveAccountGroup(): RemoveAccountGroup {
  return { accountGroupId: "" };
}

export const RemoveAccountGroup = {
  encode(message: RemoveAccountGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountGroupId !== undefined && message.accountGroupId !== "") {
      writer.uint32(10).string(message.accountGroupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveAccountGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveAccountGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountGroupId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoveAccountGroup {
    return { accountGroupId: isSet(object.accountGroupId) ? globalThis.String(object.accountGroupId) : "" };
  },

  toJSON(message: RemoveAccountGroup): unknown {
    const obj: any = {};
    if (message.accountGroupId !== undefined && message.accountGroupId !== "") {
      obj.accountGroupId = message.accountGroupId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoveAccountGroup>, I>>(base?: I): RemoveAccountGroup {
    return RemoveAccountGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveAccountGroup>, I>>(object: I): RemoveAccountGroup {
    const message = createBaseRemoveAccountGroup();
    message.accountGroupId = object.accountGroupId ?? "";
    return message;
  },
};

function createBaseDeleteAccountPositions(): DeleteAccountPositions {
  return { accountId: "", positionIds: [] };
}

export const DeleteAccountPositions = {
  encode(message: DeleteAccountPositions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    for (const v of message.positionIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAccountPositions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAccountPositions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.positionIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteAccountPositions {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      positionIds: globalThis.Array.isArray(object?.positionIds)
        ? object.positionIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: DeleteAccountPositions): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.positionIds?.length) {
      obj.positionIds = message.positionIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteAccountPositions>, I>>(base?: I): DeleteAccountPositions {
    return DeleteAccountPositions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteAccountPositions>, I>>(object: I): DeleteAccountPositions {
    const message = createBaseDeleteAccountPositions();
    message.accountId = object.accountId ?? "";
    message.positionIds = object.positionIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseAccountTradingFeaturesRequest(): AccountTradingFeaturesRequest {
  return { accountId: "" };
}

export const AccountTradingFeaturesRequest = {
  encode(message: AccountTradingFeaturesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountTradingFeaturesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountTradingFeaturesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountTradingFeaturesRequest {
    return { accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "" };
  },

  toJSON(message: AccountTradingFeaturesRequest): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountTradingFeaturesRequest>, I>>(base?: I): AccountTradingFeaturesRequest {
    return AccountTradingFeaturesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountTradingFeaturesRequest>, I>>(
    object: I,
  ): AccountTradingFeaturesRequest {
    const message = createBaseAccountTradingFeaturesRequest();
    message.accountId = object.accountId ?? "";
    return message;
  },
};

function createBaseAccountTradingFeaturesResult(): AccountTradingFeaturesResult {
  return { accountTradingInterfaceElements: [] };
}

export const AccountTradingFeaturesResult = {
  encode(message: AccountTradingFeaturesResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.accountTradingInterfaceElements) {
      AccountTradingInterfaceElement.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountTradingFeaturesResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountTradingFeaturesResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountTradingInterfaceElements.push(AccountTradingInterfaceElement.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountTradingFeaturesResult {
    return {
      accountTradingInterfaceElements: globalThis.Array.isArray(object?.accountTradingInterfaceElements)
        ? object.accountTradingInterfaceElements.map((e: any) => AccountTradingInterfaceElement.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountTradingFeaturesResult): unknown {
    const obj: any = {};
    if (message.accountTradingInterfaceElements?.length) {
      obj.accountTradingInterfaceElements = message.accountTradingInterfaceElements.map((e) =>
        AccountTradingInterfaceElement.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountTradingFeaturesResult>, I>>(base?: I): AccountTradingFeaturesResult {
    return AccountTradingFeaturesResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountTradingFeaturesResult>, I>>(object: I): AccountTradingFeaturesResult {
    const message = createBaseAccountTradingFeaturesResult();
    message.accountTradingInterfaceElements =
      object.accountTradingInterfaceElements?.map((e) => AccountTradingInterfaceElement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateAccountTradingFeatures(): UpdateAccountTradingFeatures {
  return { accountId: "", tradingInterfaceElementsToSet: [], tradingInterfaceElementIdsToRemove: [] };
}

export const UpdateAccountTradingFeatures = {
  encode(message: UpdateAccountTradingFeatures, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== undefined && message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    for (const v of message.tradingInterfaceElementsToSet) {
      AccountTradingInterfaceElement.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.tradingInterfaceElementIdsToRemove) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateAccountTradingFeatures {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAccountTradingFeatures();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tradingInterfaceElementsToSet.push(AccountTradingInterfaceElement.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tradingInterfaceElementIdsToRemove.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAccountTradingFeatures {
    return {
      accountId: isSet(object.accountId) ? globalThis.String(object.accountId) : "",
      tradingInterfaceElementsToSet: globalThis.Array.isArray(object?.tradingInterfaceElementsToSet)
        ? object.tradingInterfaceElementsToSet.map((e: any) => AccountTradingInterfaceElement.fromJSON(e))
        : [],
      tradingInterfaceElementIdsToRemove: globalThis.Array.isArray(object?.tradingInterfaceElementIdsToRemove)
        ? object.tradingInterfaceElementIdsToRemove.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: UpdateAccountTradingFeatures): unknown {
    const obj: any = {};
    if (message.accountId !== undefined && message.accountId !== "") {
      obj.accountId = message.accountId;
    }
    if (message.tradingInterfaceElementsToSet?.length) {
      obj.tradingInterfaceElementsToSet = message.tradingInterfaceElementsToSet.map((e) =>
        AccountTradingInterfaceElement.toJSON(e)
      );
    }
    if (message.tradingInterfaceElementIdsToRemove?.length) {
      obj.tradingInterfaceElementIdsToRemove = message.tradingInterfaceElementIdsToRemove;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateAccountTradingFeatures>, I>>(base?: I): UpdateAccountTradingFeatures {
    return UpdateAccountTradingFeatures.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateAccountTradingFeatures>, I>>(object: I): UpdateAccountTradingFeatures {
    const message = createBaseUpdateAccountTradingFeatures();
    message.accountId = object.accountId ?? "";
    message.tradingInterfaceElementsToSet =
      object.tradingInterfaceElementsToSet?.map((e) => AccountTradingInterfaceElement.fromPartial(e)) || [];
    message.tradingInterfaceElementIdsToRemove = object.tradingInterfaceElementIdsToRemove?.map((e) => e) || [];
    return message;
  },
};

function createBaseAccountTradingInterfaceElement(): AccountTradingInterfaceElement {
  return { id: "", enabled: false };
}

export const AccountTradingInterfaceElement = {
  encode(message: AccountTradingInterfaceElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined && message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.enabled !== undefined && message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountTradingInterfaceElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountTradingInterfaceElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AccountTradingInterfaceElement {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
    };
  },

  toJSON(message: AccountTradingInterfaceElement): unknown {
    const obj: any = {};
    if (message.id !== undefined && message.id !== "") {
      obj.id = message.id;
    }
    if (message.enabled !== undefined && message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AccountTradingInterfaceElement>, I>>(base?: I): AccountTradingInterfaceElement {
    return AccountTradingInterfaceElement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountTradingInterfaceElement>, I>>(
    object: I,
  ): AccountTradingInterfaceElement {
    const message = createBaseAccountTradingInterfaceElement();
    message.id = object.id ?? "";
    message.enabled = object.enabled ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
