import { IconButton } from "@mui/material";
import { CancelRoundedGreen, CancelRoundedRed, LocationSearchingOutlined } from "../../../images/Icons";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useOrdersList } from "../../../cqg-api/hooks/ServiceHooks";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { OrderSide } from "../../../types";
import { cancelOrders } from "../orders/actions";
import useOrderCancel from "./hooks/useOrderCancel";
import ConfirmCancelDialog from "../../shared/ConfirmCancelDialog";

interface PriceLadderFooterProps {
  scrollUpHandler: () => void;
  scrollToCenterHandler: () => void;
  scrollDownHandler: () => void;
  contract: Instrument;
}

const PriceLadderFooter = ({
  scrollUpHandler,
  scrollToCenterHandler,
  scrollDownHandler,
  contract,
}: PriceLadderFooterProps) => {
  const { ordersList } = useOrdersList();

  const { isDialogOpen, requestCancel, confirmCancel, closeDialog } = useOrderCancel();

  const handleCancelClick = (side: OrderSide) => {
    requestCancel(() => cancelAllOrderClick(side));
  };

  const cancelAllOrderClick = (side: OrderSide) => {
    cancelOrders(ordersList, { contract, side });
  };

  return (
    <>
      <div className="price-ladder-footer-container">
        <div className="price-ladder-footer">
          <IconButton onClick={() => handleCancelClick(OrderSide.Buy)}>
            <CancelRoundedGreen className="price-ladder-pointer" />
          </IconButton>
          <IconButton onClick={scrollUpHandler}>
            <KeyboardArrowUpOutlinedIcon className="price-ladder-pointer" style={{ color: "#081D37" }} />
          </IconButton>
          <IconButton onClick={scrollToCenterHandler}>
            <LocationSearchingOutlined className="price-ladder-pointer" />
          </IconButton>
          <IconButton onClick={scrollDownHandler}>
            <KeyboardArrowDownOutlinedIcon className="price-ladder-pointer" style={{ color: "#081D37" }} />
          </IconButton>
          <IconButton onClick={() => handleCancelClick(OrderSide.Sell)}>
            <CancelRoundedRed className="price-ladder-pointer" />
          </IconButton>
        </div>
      </div>
      <ConfirmCancelDialog
        open={isDialogOpen}
        onClose={() => closeDialog()}
        onConfirm={confirmCancel}
        loading={false}
        title="Confirm Order Cancellation"
        message="Are you sure you want to cancel orders?"
      />
    </>
  );
};

export default PriceLadderFooter;
