import React from 'react';
import "../../components/widgets//orders/Orders.css";
import './orders.css';
import OrderDetails from './orderModal/OrderModal';
import clsx from 'clsx';
import { OrderRow } from '../../types';
import { formatNumber } from '../../utils/getPrice';
import { OrderStatus_Status } from '../../cqg-api/proto/common/shared_1';
import { capitalizeFirstLetter, getOrderStatusLabel } from '../widgets/orders/actions';
import useOrders from './useOrders';
import { convertSymbolToYear, YearFormat } from '../tree-view/utils/convertSymbolToDate';

type OrdersListProps = {
  dataRows: OrderRow[];
}

const OrdersList = ({ dataRows }: OrdersListProps) => {
  const { 
    showOrderDetails,
    closeOrderDetails,
    openSelectedDialog,
    selectedOrder,
    handleAmmendOrder,
  } = useOrders();
  const getSide = (side: string) => {
    if (side === "BUY") {
      return <span className='side buy'>B</span>
    }
    return <span className='side sell'>S</span>
  }
  const handleShowOrderDetails = (selectedOrder: OrderRow) => {
    if (selectedOrder.status === OrderStatus_Status.WORKING) {
      showOrderDetails(selectedOrder);
    }
  }
  return (
    <>
      {
        dataRows?.map((row: OrderRow) => (
          <div
            key={row.id}
            className='flex w-full justify-between row py-2'
            onClick={() => handleShowOrderDetails(row)}
          >
          <div className='flex flex-col orderSide'>
            <div className='text-sm contract'>{getSide(row.side)}</div>
          </div>
          <div className='tableData-order'>
            <span className='text-sm'>{row.contract}</span>
            <span className='text-xs justify-end'>{row.strike === 0 ? "" : row.strike}</span>
            <span className='text-xs justify-end'>{row.qty}</span>
            <span
              className={clsx(
                "justify-end",
                "orderStatusValue",
                {"order-status-value--expired":
                  row.status === OrderStatus_Status.EXPIRED ||
                  row.status === OrderStatus_Status.REJECTED ||
                  row.status === OrderStatus_Status.CANCELLED,
                "order-status-value--working": row.status === OrderStatus_Status.WORKING,
              })}
            >
              {capitalizeFirstLetter(getOrderStatusLabel(OrderStatus_Status, row.status))}
            </span>
            {row.symbol && (
              <span className='text-xs sm-info'>
                {row.symbol} - {convertSymbolToYear(row.symbol, row.month, YearFormat.SHORT)}
              </span>
            )}
            <span className='text-xs justify-end'>{row.cp}</span>
            <span className='text-xs justify-end'>{row.type}</span>
            <span className='text-xs justify-end'>{formatNumber(parseFloat(row.limitPX ?? row.fillPX))}</span>
          </div>
          </div>))
      }
      <OrderDetails
        handleAmmendOrder={handleAmmendOrder}
        data={selectedOrder}
        open={openSelectedDialog}
        onClose={closeOrderDetails}
      />
      </>
  );
};

export default OrdersList;
