const prefix = "WORSKPACES";

export const workspacesActions = {
  WORKSPACE_SET_SUCCESS: `${prefix}_SET_SUCCESS`,

  dispatchSetWorkspace: (data) => async (dispatch) => {
    dispatch({
      type: workspacesActions.WORKSPACE_SET_SUCCESS,
      payload: data,
    });
  },
};

export default workspacesActions;
