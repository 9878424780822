const MenuIcon = () => {
  return (
<svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.99992 3.66683C2.91659 3.66683 3.66659 2.91683 3.66659 2.00016C3.66659 1.0835 2.91659 0.333496 1.99992 0.333496C1.08325 0.333496 0.333252 1.0835 0.333252 2.00016C0.333252 2.91683 1.08325 3.66683 1.99992 3.66683ZM1.99992 5.3335C1.08325 5.3335 0.333252 6.0835 0.333252 7.00016C0.333252 7.91683 1.08325 8.66683 1.99992 8.66683C2.91659 8.66683 3.66659 7.91683 3.66659 7.00016C3.66659 6.0835 2.91659 5.3335 1.99992 5.3335ZM1.99992 10.3335C1.08325 10.3335 0.333252 11.0835 0.333252 12.0002C0.333252 12.9168 1.08325 13.6668 1.99992 13.6668C2.91659 13.6668 3.66659 12.9168 3.66659 12.0002C3.66659 11.0835 2.91659 10.3335 1.99992 10.3335Z" fill="#006EB6"/>
</svg>

  )
};

export default MenuIcon;
