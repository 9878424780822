import clsx from "clsx";
import { convertSymbolToYear } from "../../tree-view/utils/convertSymbolToDate";
import { capitalizeFirstLetter, getOrderStatusLabel } from "../../widgets/orders/actions";
import { formatNumber } from "../../../utils/getPrice";
import useStyles from "../styles";
import { OrderStatus_Status } from "../../../cqg-api/proto/common/shared_1";

interface ModalTableProps {
  data: any;
  isOption: boolean;
}

const ModalTable = ({ data, isOption }: ModalTableProps) => {
  const classes = useStyles();
  return (
    <div className={clsx("tableData-orders", { threeCol: !isOption })}>
      <div className={classes.tableCell}>Contract</div>
      <div className={classes.tableCell}>Month</div>
      <div className={classes.tableCell}>Type</div>
      {isOption && <div className={classes.tableCell}>C/P</div>}
      <div className={classes.tableCellBody}>{data.symbol}</div>
      <div className={classes.tableCellBody}>{convertSymbolToYear(data.symbol, data.month)}</div>
      <div className={classes.tableCellBody}>{data.type}</div>
      {isOption && <div className={classes.tableCellBody}>{data.cp}</div>}
      <div className={classes.tableCell}>Status</div>
      <div className={classes.tableCell}>QTY</div>
      <div className={classes.tableCell}>Price</div>
      {isOption && <div className={classes.tableCell}>Strike</div>}
      <div className={classes.tableCellBody}>
        <div className="orderStatus">
          <span
            className={clsx({
              "order-status-value": true,
              "order-status-value--expired":
                data.status === OrderStatus_Status.EXPIRED ||
                data.status === OrderStatus_Status.REJECTED ||
                data.status === OrderStatus_Status.CANCELLED,
              "order-status-value--working": data.status === OrderStatus_Status.WORKING,
            })}
          >
            {capitalizeFirstLetter(getOrderStatusLabel(OrderStatus_Status, data.status))}
          </span>
        </div>
      </div>
      <div className={classes.tableCellBody}>{data.qty}</div>
      <div className={classes.tableCellBody}>{formatNumber(parseFloat(data.limitPX ?? data.fillPX))}</div>
      {isOption && <div className={classes.tableCellBody}>{data.strike}</div>}
    </div>
  );
};

export default ModalTable;
