import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useRef, useState } from "react";
import { Contract } from "../../availableProducts/types";
import { subscribeToContracts, unsubscribeContracts } from "../../../../utils/subscriptions";
import { contractSelectStyles, menuItemStyles } from "../optionsStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import { checkIconStyles } from "../../availableProducts/marketStyles";
import { convertSymbolToDate } from "../../../tree-view/utils/convertSymbolToDate";

const ContractSelect = ({
  monthlyContracts,
  selectedInstrument,
  handleRowStateChange,
}: {
  monthlyContracts: any;
  selectedInstrument: any;
  handleRowStateChange: (id: string, contract: any) => void;
}) => {
  const [value, setValue] = useState("");
  const prevStateRef = useRef<Contract>();

  const handleChange = (event: SelectChangeEvent) => {
    const contract = monthlyContracts.find((contract: any) => contract?.displayName === event.target.value);
    handleRowStateChange("", contract);
  };

  useEffect(() => {
    if (selectedInstrument) {
      prevStateRef.current = selectedInstrument;
      subscribeToContracts([selectedInstrument.contractId]);
    }
    return () => {
      if (prevStateRef.current) unsubscribeContracts([prevStateRef.current.contractId]);
    };
  }, [selectedInstrument]);

  useEffect(() => {
    setValue(selectedInstrument?.displayName);
  }, [selectedInstrument?.displayName]);

  return (
    <FormControl fullWidth>
      <Select
        sx={contractSelectStyles}
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          PaperProps: {
            sx: {
              width: "100%",
            },
          },
        }}
        renderValue={(selected) => selected}
      >
        {monthlyContracts?.map((contract: any) => {
          return (
            <MenuItem value={contract?.displayName} sx={menuItemStyles}>
              <span>{convertSymbolToDate(contract?.displayName)}</span>
              {contract?.displayName === value && <CheckIcon style={checkIconStyles} />}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ContractSelect;
