import { Controller } from "react-hook-form";
import React from "react";
import "./PriceLadder.css";

const TimeInForce = (props: { control: any; name: string }) => {
  const { control, name } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className="price-ladder-timeInForce">
          <button type="button" onClick={() => onChange(1)} className={`timeInDay ${value === 1 && "active"}`}>
            DAY
          </button>
          <button type="button" onClick={() => onChange(2)} className={`timeInDay ${value === 2 && "active"}`}>
            GTC
          </button>
        </div>
      )}
    />
  );
};

export default TimeInForce;
