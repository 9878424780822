import React from 'react';
import './TreeView.css'

const TreeViewHeading = () => {
  return (
    <div className="tree-view-heading" onClick={(e) => e.stopPropagation()}>
      <div className='label1'>Contract</div>
      <div className='label2'>Change</div>
      <div className='label3'>Volume</div>
    </div>
  );
};

export default TreeViewHeading;
