import React, { useCallback } from "react";
import { Menu, MenuItem, Typography } from "@mui/material";
import { PriceLadderGreyIcon } from "../../images/Icons";
import { setChartWidgetValue } from "../../redux/products/chartWidget";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { WidgetType } from "../../types";

interface CustomMenuProps {
  anchorMenu: HTMLElement | null;
  handleCloseMenu: () => void;
  paramRowData: any;
}

const CustomMenu: React.FC<CustomMenuProps> = ({ anchorMenu, handleCloseMenu, paramRowData }) => {
  const dispatch = useDispatch();

  const handlePriceLadder = useCallback(() => {
    if (!paramRowData) return;
    const contractDisplayName = paramRowData?.displayName;
    const priceLadderWidgetValue = {
      contractIds: [contractDisplayName],
      id: uuidv4(),
      key: 4,
      title: WidgetType.PriceLadder,
      widgetName: WidgetType.PriceLadder,
      widgetType: paramRowData?.cmeSymbolName,
    };
    dispatch(setChartWidgetValue(priceLadderWidgetValue));
    handleCloseMenu();
  }, [dispatch, handleCloseMenu, paramRowData]);
  
  return (
    <Menu
      anchorEl={anchorMenu}
      open={Boolean(anchorMenu)}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiMenu-paper": {
          boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.05)",
          width: "180px",
        },
      }}
    >
      <MenuItem onClick={handlePriceLadder}>
        <PriceLadderGreyIcon />
        <Typography
          sx={{
            marginLeft: "14px",
            color: "#081D37",
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.17px",
            fontFamily: "Averta-Regular",
          }}
        >
          Price Ladder
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default CustomMenu;
