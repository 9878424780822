import { useContext, useEffect, useMemo, useState } from "react";
import { AvailableProductsContext } from "../../AvailableProductsProvider";
import * as _ from "../../vendors/underscore-esm";
import { ProductWithContracts, Instrument } from "./types";

export const useRunningMonth = () => {
  const [ cmeSymbolContracts, setCmeSymbolContracts ] = useState({})
  const { monthlyContractsByDisplayNameMap } = useContext(AvailableProductsContext);

  const symbols = useMemo(() => {
    const cmeSymbolContracts: any = {};
    _.forEach( monthlyContractsByDisplayNameMap, function( value: ProductWithContracts, key: string ) { 
      value.monthlyContracts?.map( (ins: Instrument) => {
        cmeSymbolContracts[ins.cmeSymbol] = value.monthlyContracts;
        return;
      })
    });

    return cmeSymbolContracts;
  }, [monthlyContractsByDisplayNameMap])

  const isContractExists = (symbol: string): boolean => {
    return monthlyContractsByDisplayNameMap[symbol] !== undefined;
  }

  const getRunningMonthContract = (symbol: string) => {   
    const rootSymbol = symbol.slice(0, -2); 
    const contracts: Instrument[] =  symbols[rootSymbol as keyof typeof symbols];
    return contracts ? contracts[0].displayName : "AA";
  }

  return { isContractExists, getRunningMonthContract, monthlyContractsByDisplayNameMap, cmeSymbolContracts };
}
