import { useEffect, useRef, useId, useState, useCallback, useMemo } from "react";
import { cqgUDFDataFeed } from '../../../cqg-api/services/CQGUDFDataFeed';
import { useChartConfig, generateMultiChartLayout, chartLayout } from "./chartConfig";
import { WidgetAction, WidgetType } from "../../../../src/types";
import { useDispatch } from "react-redux";
import workspacesActions from "../../../../src/redux/workspaces/workspacesActions";
import { setTradeTicketValue } from "../../../../src/redux/products/chartWidget";
import { useRunningMonth } from "../../../hooks/api/useRunningMonth";

const TradingChart = (props) => {
  const chartContainerRef = useRef();
  const [tvWidget, setTvWidget] = useState(null);
  const { isContractExists, getRunningMonthContract, monthlyContractsByDisplayNameMap } = useRunningMonth();

  const symbols = useMemo(() => {
    const symbols = []
    props.symbols?.map(sym => {
      const isExists = isContractExists(sym);
      if (!isExists) {
        symbols.push(getRunningMonthContract(sym));
      } else {
        symbols.push(sym);
      }
    })

    return symbols;
  }, [props.symbols, monthlyContractsByDisplayNameMap])

  const containerId = useId();
  const dispatch = useDispatch();

  const workspaceId = props.workspaceId;

  const openOrderTicket = useCallback((order, instrument) => {
    if (workspaceId) {
      const chartWidgetValue = {
        key: 3,
        title: WidgetType.TradeTicket,
        widgetType: WidgetType.TradeTicket,
        widgetData: {
          action: WidgetAction.CreateOrder,
          data: {
            size: order.qty,
            side: order.side,
            limitPrice: order.price,
            orderType: order.type,
          },
        },
      };

      dispatch(
        workspacesActions.dispatchSetWorkspace({
          workspaceId: workspaceId,
          instrument: instrument,
        }),
      );

      dispatch(setTradeTicketValue(chartWidgetValue));
    }
  }, [workspaceId]);

  const chartConfig = useChartConfig(cqgUDFDataFeed, symbols[0], containerId, openOrderTicket, props.widget);

  const createTradingwidget = useCallback(async () => {
    const widget = new window.TradingView.widget(chartConfig);
    setTvWidget(widget);
  }, [cqgUDFDataFeed, symbols, containerId, openOrderTicket]);

  useEffect(() => {
    createTradingwidget();
  }, [symbols])

  useEffect(() => {
    let saveInterval = null;
    if (tvWidget) {
      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          const size = symbols.length;
  
          if (size === 7 || size > 8) {
            console.log(`Trading chart does not support ${size} layout.`);
          } else {
            tvWidget.setLayout(chartLayout(size));
            generateMultiChartLayout(tvWidget, size, symbols)
          }

          const strLayout = window.localStorage.getItem(`chart-${props.widget?.id}`);
          if (strLayout && props.widget?.widgetType === WidgetType.Chart) {
            tvWidget.load(JSON.parse(strLayout));
          }
        });

        // TODO: Could not find any event which tells if any indicator is added or removed from the chart.
        // This is a workaround and should be improved.
        saveInterval = setInterval(() => {
          if (props.widget?.widgetType === WidgetType.Chart) {
            tvWidget.save(layout => {
              const strLayout = JSON.stringify(layout);
              window.localStorage.setItem(`chart-${props.widget?.id}`, strLayout);
            });
          }
        }, 2000);
      });
    }

    return () => {
      if (tvWidget)
        tvWidget.remove();

      clearInterval(saveInterval);
    }
  }, [tvWidget])

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      id={"TVChartContainer" + containerId}
      ref={chartContainerRef}
      className={"TVChartContainer"}
    />
  );
};

export default TradingChart;