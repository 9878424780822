import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Box, SelectChangeEvent } from "@mui/material";
import { TradeLog } from "../../../../../hooks/api/types";
import StyledLink from "../StyledLink";
import HeaderFilterDropdown from "./HeaderFilterDropdown";
import MobileProductFilter from "./MobileProductFilter";

const boxStyles = {
  display: "flex",
  gap: "16px",
  alignItems: "center",
};

const DEFAULT_FILTERS = {
  monthOptions: ["Today", "This week", "This month", "Last month"],
};

const CategoriesDropdown = ({
  tradelog,
  onFilterChange,
  setLoading,
  isMobileView,
}: {
  tradelog: TradeLog[];
  onFilterChange: (filteredData: TradeLog[]) => void;
  setLoading: (loading: boolean) => void;
  isMobileView: boolean;
}) => {
  const [filters, setFilters] = useState({
    product: "",
    month: DEFAULT_FILTERS.monthOptions[1],
  });

  const categories = useMemo(
    () => Array.from(new Set(tradelog.map((trade) => trade.cmeSymbol?.cmeSymbol || ""))).filter(Boolean),
    [tradelog],
  );

  const today = useMemo(() => new Date(), []);

  const handleFilterChange = useCallback(
    (key: keyof typeof filters) => (event: SelectChangeEvent<string>) => {
      const value = event.target.value;
      setFilters((prev) => ({ ...prev, [key]: value }));
      setLoading(true);
    },
    [setLoading],
  );

  const startOfWeek = useMemo(() => {
    const date = new Date(today);
    date.setDate(today.getDate() - today.getDay());
    return date;
  }, [today]);

  const startOfMonth = useMemo(() => new Date(today.getFullYear(), today.getMonth(), 1), [today]);
  const startOfLastMonth = useMemo(() => new Date(today.getFullYear(), today.getMonth() - 1, 1), [today]);
  const endOfLastMonth = useMemo(() => new Date(today.getFullYear(), today.getMonth(), 0), [today]);

  const filterTrades = useCallback(() => {
    setLoading(true);
    const filtered = tradelog.filter((trade) => {
      const matchesProduct = filters.product ? trade.cmeSymbol?.cmeSymbol === filters.product : true;
      const tradeTime = new Date(trade.tradeTime);
      const todayDate = today;

      const matchesMonth = (() => {
        switch (filters.month) {
          case "Today":
            return tradeTime.toDateString() === todayDate.toDateString();
          case "This week":
            return tradeTime >= startOfWeek && tradeTime <= todayDate;
          case "This month":
            return tradeTime >= startOfMonth && tradeTime <= todayDate;
          case "Last month":
            return tradeTime >= startOfLastMonth && tradeTime <= endOfLastMonth;
          default:
            return true;
        }
      })();

      return matchesProduct && matchesMonth;
    });
    onFilterChange(filtered);
    setLoading(false);
  }, [
    setLoading,
    tradelog,
    onFilterChange,
    filters.product,
    filters.month,
    today,
    startOfWeek,
    startOfMonth,
    startOfLastMonth,
    endOfLastMonth,
  ]);

  useEffect(() => {
    filterTrades();
  }, [filterTrades]);

  const allMonths = useMemo(
    () => [{ label: "All months", value: filters.month, options: DEFAULT_FILTERS.monthOptions, key: "month" }],
    [filters.month],
  );

  const allProducts = useMemo(
    () => [{ label: "All Products", value: filters.product, options: categories, key: "product" }],
    [categories, filters.product],
  );

  return (
    <>
      <Box sx={{ ...boxStyles, justifyContent: "space-between" }}>
        <Box sx={{ ...boxStyles, justifyContent: "start" }}>
          {isMobileView ? (
            <>
              <HeaderFilterDropdown listItems={allProducts} handleFilterChange={handleFilterChange} filters={filters} />
            </>
          ) : (
            <>
              <HeaderFilterDropdown listItems={allMonths} handleFilterChange={handleFilterChange} filters={filters} />
              <HeaderFilterDropdown listItems={allProducts} handleFilterChange={handleFilterChange} filters={filters} />
            </>
          )}
        </Box>

        <div style={{ display: "flex", gap: "5px" }}>
          {isMobileView && (
            <MobileProductFilter
              categories={DEFAULT_FILTERS.monthOptions}
              filters={filters}
              setFilters={setFilters}
              setLoading={setLoading}
            />
          )}
          <StyledLink isMobileView={isMobileView} />
        </div>
      </Box>
    </>
  );
};

export default CategoriesDropdown;
