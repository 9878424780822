import { Instrument } from "../cqg-api/models/Instrument";
import { Position } from "../cqg-api/models/Position";
import { OrderState } from "../cqg-api/models/OrderState";
import { InstrumentMarketData, Product } from "../types";
import { produce } from "immer";
import { CQGEnvironment } from "./../cqg-api/services/CQGEnvironment";
import * as _ from "../vendors/underscore-esm";
import { Contract } from "../components/widgets/availableProducts/types";
import { OptionCalculationReport } from "../cqg-api/proto/market_data_2";

export const MapInstrumentMarketData2 = (watchlistData: InstrumentMarketData[], realTimeMarketData: Instrument[]) => {
  return produce(watchlistData, (draft) => {
    realTimeMarketData.forEach((instrumentMarketData) => {
      const index = watchlistData.findIndex(
        (data) =>  data.monthlyContract?.contractId === instrumentMarketData.contractId,
      );
      if (index >= 0 && isValidData(instrumentMarketData)) {
        draft[index] = {
          ...watchlistData[index],
          title: quickCmeTitle(instrumentMarketData ? instrumentMarketData.title : null, watchlistData[index]),
          askPrice: instrumentMarketData ? instrumentMarketData.labelAsk : null,
          askQty: instrumentMarketData ? instrumentMarketData.labelOpen : null,
          bidPrice: instrumentMarketData ? instrumentMarketData.labelHigh : null,
          bidQty: instrumentMarketData ? instrumentMarketData.labelLow : null,
          lastPrice: instrumentMarketData ? instrumentMarketData.lastPrice : null,
          lastQty: instrumentMarketData ? instrumentMarketData.labelTradeVolume : null,
          change: instrumentMarketData ? instrumentMarketData.labelPriceNetChange : null,
          changePercentage: instrumentMarketData ? instrumentMarketData.labelPriceNetChangePercent?.toFixed(2) : null,
          price: instrumentMarketData ? instrumentMarketData.labelPrice : null,
          tradeVolume: instrumentMarketData ? instrumentMarketData.labelTradeVolume : null,
          labelTotalVolume: instrumentMarketData ? instrumentMarketData.labelTotalVolume : null,
          yesterdaySettlement: instrumentMarketData ? instrumentMarketData.yesterdaySettlement : null,
        };
      }
    });
  });
};

export const createRealTimeMarketDataMap = (realTimeMarketData: Instrument[]) => {
  const realTimeMarketDataMap: any = {};
  realTimeMarketData.forEach((data) => {
    if (data.contractId) {
      realTimeMarketDataMap[data.contractId] = data ?? {};
    }
  });
  return realTimeMarketDataMap;
};
export const createRealTimeOptionsDataMap = (realTimeOptionsData: OptionCalculationReport[]) => {
  const realTimeMarketDataMap: any = {};
  realTimeOptionsData.forEach(request => {
    request.values.forEach(value => {
        if(value.strikeContractId) {
          realTimeMarketDataMap[value.strikeContractId] = value;
        }
    });
});
  return realTimeMarketDataMap;
};

export const MapInstrumentOptionsData = (optionData: InstrumentMarketData[], realTimeMarketData: Instrument[], realTimeOptionsData: OptionCalculationReport[]) => {
  const realTimeMarketDataMap: any = createRealTimeMarketDataMap(realTimeMarketData);
  const realTimeOptionsDataMap: any = createRealTimeOptionsDataMap(realTimeOptionsData);

  return produce(optionData, (draft) => {
    draft.forEach((data: any, index: number) => {
      const callContractId = data?.callOption?.contractId;
      const putContractId = data?.callOption?.contractId;
      const callOptionData = realTimeMarketDataMap && realTimeMarketDataMap[data?.callOption?.contractId];
      const putOptionData = realTimeMarketDataMap && realTimeMarketDataMap[data?.putOption?.contractId];

      draft[index] = {
        ...data,

        putsLow: putOptionData?.labelLow ?? null,
        putsHigh: putOptionData?.labelHigh ?? null,
        putsPrior: putOptionData?.labelSettlement ?? null,
        putsChange: putOptionData?.labelPriceNetChange ?? null,
        putsLast: putOptionData?.labelPrice ?? null,
        putsQty: putOptionData?.labelTotalVolume ?? null,
        putsBid: putOptionData?.labelBid ?? null,
        putsOffer: putOptionData?.labelAsk ?? null,

        putsTheo: realTimeOptionsDataMap[putContractId]?.theov ?? null,
        putsVol: putOptionData?.labelBidVolume ?? null,
        putsTheta: realTimeOptionsDataMap[putContractId]?.theta ?? null,
        putsVega: realTimeOptionsDataMap[putContractId]?.vega ?? null,
        putsGamma: realTimeOptionsDataMap[putContractId]?.gamma ?? null,
        putsDelta: realTimeOptionsDataMap[putContractId]?.delta ?? null,
        putsImpVol: realTimeOptionsDataMap[putContractId]?.impliedVolatility ?? null,

        callsLow: callOptionData?.labelLow ?? null,
        callsHigh: callOptionData?.labelHigh ?? null,
        callsPrior: callOptionData?.labelSettlement ?? null,
        callsChange: callOptionData?.labelPriceNetChange ?? null,
        callsLast: callOptionData?.labelPrice ?? null,
        callsQty: callOptionData?.labelTotalVolume ?? null,
        callsBid: callOptionData?.labelBid ?? null,
        callsOffer: callOptionData?.labelAsk ?? null,

        callsTheo: realTimeOptionsDataMap[callContractId]?.theov ?? null,
        callsVol: callOptionData?.labelAskVolume ?? null,
        callsTheta: realTimeOptionsDataMap[callContractId]?.theta ?? null,
        callsVega: realTimeOptionsDataMap[callContractId]?.vega ?? null,
        callsGamma: realTimeOptionsDataMap[callContractId]?.gamma ?? null,
        callsDelta: realTimeOptionsDataMap[callContractId]?.delta ?? null,
        callsImpVol: realTimeOptionsDataMap[callContractId]?.impliedVolatility ?? null,
      };
    });
  });
};

/*
This is temporary quick and dierty way to convert CQG title to CME title. Ideally the
Instrument these updtes are reading from MUST be CME resolved from Cme Symbol Service.

This method should be removed and appropriate displayName property should be used once
the CME resolution is in place.
*/
const quickCmeTitle = (cqgTitle: any, product: Product) => {
  if (cqgTitle) {
    let title = cqgTitle as string;
    return title.replace(product.cqgSymbol, product.cmeSymbol);
  }
  return cqgTitle;
};

export const MapInstrumentMarketData = (products: Product[], marketData: Instrument[]) => {
  return products?.map<InstrumentMarketData>((product: Product) => {
    const instrumentMarketData = marketData.find(
      (instrument: any) => instrument.contractMetadata?.shortInstrumentGroupName === product.cqgSymbol,
    );
    if (instrumentMarketData) {
      return {
        ...product,
        title: quickCmeTitle(instrumentMarketData ? instrumentMarketData.title : null, product),
        askPrice: instrumentMarketData ? instrumentMarketData.labelAsk : null,
        askQty: instrumentMarketData ? instrumentMarketData.labelAskVolume : null,
        bidPrice: instrumentMarketData ? instrumentMarketData.labelBid : null,
        bidQty: instrumentMarketData ? instrumentMarketData.labelBidVolume : null,
        lastPrice: instrumentMarketData ? instrumentMarketData.labelPrice : null,
        lastQty: instrumentMarketData ? instrumentMarketData.labelTradeVolume : null,
        change: instrumentMarketData ? instrumentMarketData.labelPriceNetChange : null,
        changePercentage: instrumentMarketData ? instrumentMarketData.labelPriceNetChangePercent?.toFixed(2) : null,
        price: instrumentMarketData ? instrumentMarketData.labelPrice : null,
      };
    } else {
      return product;
    }
  });
};

export const MapPositionData = (products: Product[], positionData: Position[]) => {
  return products?.map<InstrumentMarketData>((product: Product) => {
    const instrumentMarketData = positionData.find((instrument: any) => instrument.displaySymbol === product.cqgSymbol);
    return {
      ...product,
      trade: instrumentMarketData ? instrumentMarketData.displayLastTradeDate : null,
      symbol: instrumentMarketData ? instrumentMarketData.displaySymbol : null,
      mo: instrumentMarketData ? instrumentMarketData.displayMvo : null,
      strike: instrumentMarketData ? instrumentMarketData.displayStrikePrice : null,
      cp: instrumentMarketData ? instrumentMarketData.price : null, // need to confirm,
      position: instrumentMarketData ? instrumentMarketData.price : null, // need to confirm
      buys: instrumentMarketData ? instrumentMarketData.price : null, // need to confirm
      sells: instrumentMarketData ? instrumentMarketData.price : null, // need to confirm
      averagePX: instrumentMarketData ? instrumentMarketData.price : null, // need to confirm
      unrealizedPL: instrumentMarketData ? instrumentMarketData.price : null, // need to confirm
      realizedPL: instrumentMarketData ? instrumentMarketData.price : null, // need to confirm
      flatten: instrumentMarketData ? instrumentMarketData.price : null, // need to confirm
    };
  });
};

export const MapOrderData = (products: Product[], orderData: OrderState[]) => {
  return products?.map<InstrumentMarketData>((product: Product) => {
    const instrumentMarketData = orderData.find((instrument: any) => instrument.displaySymbol === product.cqgSymbol);
    return {
      ...product,
      cxl: instrumentMarketData ? instrumentMarketData.stopPrice : null, // need to confirm,
      amend: instrumentMarketData ? instrumentMarketData.stopPrice : null, // need to confirm,
      side: instrumentMarketData ? instrumentMarketData.side : null,
      mo: instrumentMarketData ? instrumentMarketData.stopPrice : null, // need to confirm,
      strike: instrumentMarketData ? instrumentMarketData.strikePrice : null,
      cp: instrumentMarketData ? instrumentMarketData.stopPrice : null, // need to confirm,
      qty: instrumentMarketData ? instrumentMarketData.stopPrice : null, // need to confirm,
      leaves: instrumentMarketData ? instrumentMarketData.stopPrice : null, // need to confirm,
      type: instrumentMarketData ? instrumentMarketData.type : null,
      fillPX: instrumentMarketData ? instrumentMarketData.stopPrice : null, // need to confirm,
      limitPX: instrumentMarketData ? instrumentMarketData.stopPrice : null, // need to confirm,
      stopPX: instrumentMarketData ? instrumentMarketData.stopPrice : null, // need to confirm,
      status: instrumentMarketData ? instrumentMarketData.status : null,
    };
  });
};

export const MapTreeData = (instruments: Instrument[], realTimeMarketData: Instrument[]) => {
  return produce(instruments, (draft) => {
    realTimeMarketData.forEach((instrumentMarketData) => {
      const index = instruments.findIndex((data) => data.contractId === instrumentMarketData.contractId);
      if (index >= 0) {
        draft[index] = {
          ...draft[index],
          ...instrumentMarketData,
        };
      }
    });
  });
};
export const mapInstrumentData = (instrument: Instrument, realTimeMarketData: Instrument[]): Instrument => {
  const updatedInstrument = realTimeMarketData.find((data) => data.contractId === instrument.contractId);

  if (updatedInstrument) {
    return { ...instrument, ...updatedInstrument };
  }

  return instrument;
};

export const mapContractData = (instrument: Contract, realTimeMarketData: Instrument[]) => {
  const updatedInstrument = realTimeMarketData.find((data) => data.contractId === instrument.contractId);

  if (updatedInstrument) {
    return { ...instrument, ...updatedInstrument };
  }

  return instrument;
};

export const getOrderDuration = (duration: number) => {
  const env = CQGEnvironment.Instance;
  switch (duration) {
    case 1:
      return "DAY";
    case 2:
      return "GTC";
    case 3:
      return "GTD";
    case 4:
      return "GTT";
    case 5:
      return "FAK";
    case 6:
      return "FOK";
    case 7:
      return "ATO";
    case 8:
      return "ATC";
    case 9:
      return "GFA";
    default:
      console.log("Invalid order duration: " + duration);
      return duration;
  }
};

export const getDuration = (duration: string) => {
  switch (duration) {
    case "DAY":
      return 1;
    case "GTC":
      return 2;
    case "GTD":
      return 3;
    case "GTT":
      return 4;
    case "FAK":
      return 5;
    case "FOK":
      return 6;
    case "ATO":
      return 7;
    case "ATC":
      return 8;
    case "GFA":
      return 9;
    default:
    console.log("Invalid duration: " + duration);
    return null;
  }
};

export const getOrderType = (orderType: number) => {
  switch (orderType) {
    case 1:
      return "MKT";
    case 2:
      return "LMT";
    case 3:
      return "STP";
    case 4:
      return "STL";
    case 5:
      return "CROSS";
    default:
      console.log("Invalid order type: " + orderType);
      return orderType;
  }
};

export const getOrderTypeLabel = (orderType: string) => {
  switch (orderType) {
    case "MKT":
      return 1;
    case "LMT":
      return 2;
    case "STP":
      return 3;
    case "STL":
      return 4;
    case "CROSS":
      return 5;
    default:
      console.log("Invalid order type: " + orderType);
      return null;
  }
};

export const isValidData = (instrument: Instrument) => {
  return (
    !_.isNull(instrument.labelBid) &&
    !_.isNull(instrument.labelAskVolume) &&
    !_.isNull(instrument.labelBidVolume) &&
    !_.isNull(instrument.labelPrice)
  );
};

export const areDatesWithinRange = (range: number, date1?: Date, date2?: Date): boolean => {
  if (date1 && date2) {
    const diffInMs = Math.abs(date1?.getTime() - date2?.getTime());
    const twoMinutesInMs = range * 1000;
    return diffInMs <= twoMinutesInMs;
  }
  return false;
}

export const capitalizeKeys = (obj: { [key: string]: any }): { [key: string]: any } => obj && typeof obj === 'object'
  ? Object.entries(obj).reduce((acc, [key, value]) => ({ ...acc, [key.toUpperCase()]: value }), {})
  : obj;
