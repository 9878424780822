import { useEffect } from "react";
import { disableResize, tradeTicketHeader } from "../utils";

const useCustomizeTradeTicketUI = (isMobile?: boolean) => {
  useEffect(() => {
    if (isMobile) {
      return;
    }
    const target: NodeListOf<HTMLElement> = document.querySelectorAll(".dv-resize-container");

    let tradeUIContainer: HTMLElement | null = null

    target.forEach((container: HTMLElement) => {
      const titel = container.querySelector("div.dv-default-tab-content");

      if (titel?.innerText === "Trade") {
        tradeUIContainer = container;
      }
    });

    if (!tradeUIContainer) {
      return;
    }

    const container: HTMLElement = tradeUIContainer;
    disableResize(container);
    const rightActions = container.querySelector(".dv-right-actions-container") as HTMLElement;
    const tab = container.querySelector(".dv-tab.dv-active-tab") as HTMLElement;
    const tabContent = container.querySelector(".dv-default-tab-content") as HTMLElement;
    const tabContentArrow = container.querySelector(".dv-default-tab-action") as HTMLElement;
    if (rightActions) {
      rightActions.style.display = "none";
    }
    tabContent.style.display = "none";
    if (tab) {
      tab.style.position = "absolute";
      tab.style.right = "0";
      tab.style.background = "none";
      tab.style.border = "none";
    }
    tabContentArrow.style.right = "8px";
    tabContentArrow.style.position = "absolute";
    tab?.addEventListener("click", (e) => {
      e.preventDefault();
    });
    // tabContentArrow.style.top = '9px';
    // tabContentArrow.style.fontSize = '14px';
    if (tab) {
      tab.style.height = "35px";
    }
    container.style.borderBottom = "1px solid #ccc !important";
    // tab.style.width = '100%';
    // tab.style.height = '35px';
    const heading = container.querySelector("h4");
    if (!heading) {
      const imageContainer = tradeTicketHeader();
      container.appendChild(imageContainer);
    }
  }, [isMobile]);
};

export default useCustomizeTradeTicketUI;
