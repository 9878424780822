import React, { useMemo } from "react";
import { Instrument } from "../../../cqg-api/models/Instrument";
import "./PriceLadder.css";
import { usePositionsList } from "../../../cqg-api/hooks/ServiceHooks";
import { PositionDisplayEnum } from "../../../types";
import { getPosition } from "../positions/utils";

const PositionsCount = (props: { contract: Instrument }) => {
  const { contract } = props;
  const { positionsList } = usePositionsList();

  const positionsCount = useMemo(() => {
    const aggregatedPositions = positionsList.filter((position) => position.contractId === contract?.contractId);
    const count = getPosition(
      aggregatedPositions?.[0]?.displayLong,
      aggregatedPositions?.[0]?.displayShort,
      PositionDisplayEnum.PlusMinus,
    );
    return count;
  }, [contract?.contractId, positionsList]);

  return (
    <div className="price-ladder-positions">
      <div>
        Position <span className="price-ladder-position-count">{positionsCount ?? 0}</span>
      </div>
    </div>
  );
};

export default PositionsCount;
