import { getPrice } from "../../../utils/getPrice";
import { convertSymbolToYear } from "../../tree-view/utils/convertSymbolToDate";
import useStyles from "../styles";
import clsx from "clsx";

interface ModalTableProps {
  data: any;
  isOption: boolean;
}

const ModalTable = ({ data, isOption }: ModalTableProps) => {
  const classes = useStyles();
  return (
    <div className={clsx("tableData-modal", { threeCol: !isOption })}>
      <div className={classes.tableCell}>Contract</div>
      <div className={classes.tableCell}>Month</div>
      <div className={classes.tableCell}>PL</div>
      {isOption && <div className={classes.tableCell}>C/P</div>}
      <div className={classes.tableCellBody}>{data.symbol}</div>
      <div className={classes.tableCellBody}>{convertSymbolToYear(data.symbol, data.month)}</div>
      <div className={classes.tableCellBody}>{getPrice(data.realizedPL, data.unrealizedPL)}</div>
      {isOption && <div className={classes.tableCellBody}>{data.cp}</div>}
      <div className={classes.tableCell}>Position</div>
      <div className={classes.tableCell}>Qty</div>
      <div className={classes.tableCell}>Avg PX</div>
      {isOption && <div className={classes.tableCell}>Strike</div>}
      <div className={classes.tableCellBody}>{data.direction}</div>
      <div className={classes.tableCellBody}>{data.size}</div>
      <div className={classes.tableCellBody}>{data.averagePX}</div>
      {isOption && <div className={classes.tableCellBody}>{data.strike}</div>}
    </div>
  );
};

export default ModalTable;
