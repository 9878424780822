import React, { useCallback, useContext, useState } from "react";
import { SimpleTreeView, TreeItem2 } from "@mui/x-tree-view";
import { AssetProductsDictionary, CONTRACT_ID_NAME, ProductWithContracts, TreeViewMode, WidgetType } from "../../types";
import { subscribeToContracts, unsubscribeInstuments } from "../../utils/subscriptions";
import TreeViewHeading from "./TreeViewHeading";
import ProductTreeItem from "./ProductTreeItem";
import MonthlyContractTreeItem from "./MonthlyContractTreeItem";
import { handleContractCheckboxToggle, handleProductCheckboxToggle } from "./utils/checkboxHandlers";
import { Instrument } from "../../cqg-api/models/Instrument";
import { useAvailableProductsContext } from "../../AvailableProductsProvider";
import TextWrapper from "../common/TextWrapper";
import { ActiveDotSelectedInstrument } from "../common/ActiveDotSelectedInstrument";

const TreeView = (props: {
  mode: TreeViewMode;
  selectedItems: string[];
  filteredTreeViewDataSource: AssetProductsDictionary;
  expandedItems: string[];
  onSelect: (selectedItems: string[]) => void;
  onExpandedItemsChange: (event: React.SyntheticEvent, itemIds: string[]) => void;
  isMobileView?: boolean;
}) => {
  const { mode, selectedItems, filteredTreeViewDataSource, expandedItems, onSelect, onExpandedItemsChange, isMobileView } = props;
  const { productsMapById } = useAvailableProductsContext();
  const [treeViewData, setTreeViewData] = useState<Instrument[]>([]);

  const onItemExpansionToggle = useCallback(
    (e: React.SyntheticEvent<Element, Event>, itemId: string, isExpanded: boolean) => {
      const product = productsMapById[itemId];
      if (!product) {
        return;
      }
      if (isExpanded) {
        if (product?.monthlyContracts?.length) {
          setTreeViewData([...treeViewData, ...product.monthlyContracts]);
          const contractIds = product.monthlyContracts.map((contract) => contract.contractId);
          subscribeToContracts(contractIds as number[]);
        }
      } else {
        const filteredTreeViewData: Instrument[] = treeViewData.filter((treeData) => {
          return (
            product.monthlyContracts?.findIndex(
              (instrument: Instrument) => instrument.contractId === treeData.contractId,
            ) === -1
          );
        });
        setTreeViewData(filteredTreeViewData);
        unsubscribeInstuments(product.monthlyContracts as Instrument[]);
      }
    },
    [productsMapById, treeViewData],
  );
  return (
    <>
      <SimpleTreeView
        expandedItems={expandedItems}
        onExpandedItemsChange={onExpandedItemsChange}
        onItemExpansionToggle={onItemExpansionToggle}
        expansionTrigger="content"
        selectedItems={selectedItems}
        multiSelect
      >
        {Object.keys(filteredTreeViewDataSource).sort().map((asset) => {
          const allContracts = filteredTreeViewDataSource[asset]?.map(k => k?.monthlyContracts?.map(l => l?.displayName)).flat();
          const activeContract = allContracts?.find(thisContract => thisContract === selectedItems[0]);
          const label = (activeContract && isMobileView) ? <TextWrapper>{asset} <ActiveDotSelectedInstrument /></TextWrapper> : asset;
          return (
            <div key={asset}>
              <TreeItem2 className="tree-view-head" itemId={asset} label={label} key={asset}>
                {filteredTreeViewDataSource[asset]?.map((product: ProductWithContracts) => {
                  const activeItem = product.monthlyContracts?.findIndex((instrument: Instrument) => instrument.displayName === selectedItems[0]);
                  return (
                    <ProductTreeItem
                      key={product.id}
                      product={product}
                      isSelected={selectedItems.includes(product.id)}
                      handleCheckboxToggle={() => handleProductCheckboxToggle(product, selectedItems, onSelect, mode)}
                      selectedItems={selectedItems}
                      mode={mode}
                      activeContract={(activeItem ?? -1) > -1}
                      isMobileView={isMobileView}
                    >
                      <TreeViewHeading />
                      {product?.monthlyContracts?.map((contract, index) => {
                        const isFirstContract = index === 0;
                        return (
                          <MonthlyContractTreeItem
                            key={contract[CONTRACT_ID_NAME]}
                            contract={contract}
                            product={product}
                            isSelected={selectedItems.includes(String(contract[CONTRACT_ID_NAME]))}
                            handleCheckboxToggle={() =>
                              handleContractCheckboxToggle(contract, product, selectedItems, onSelect, mode)
                            }
                            treeViewData={treeViewData}
                            mode={mode}
                            isFirstContract={isFirstContract}
                          />
                        );
                      })}
                    </ProductTreeItem>
                  );
                })}
              </TreeItem2>
            </div>
          );
        })}
      </SimpleTreeView>
    </>
  );
};

export default TreeView;
