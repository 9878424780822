import { InstrumentMarketData, Product, Widget } from "../../../types";
import { useCallback, useEffect, useMemo, useState } from "react";
import AddProduct from "./AddProduct";
import { useFetchProducts } from "../../../hooks/api/product";
import { useFetchWorkspaceWatchlist, useUpdateWatchlist } from "../../../hooks/api/watchlist";
import { Delete } from "@mui/icons-material";
import ConfirmDialog from "../../shared/ConfirmDialog";
import { MapInstrumentMarketData2 } from "../../../utils/utils";
import { GridColDef } from "@mui/x-data-grid";
import { useRealTimeMarketData } from "../../../cqg-api/hooks/ServiceHooks";
import { subscribeToInstruments, unsubscribeInstuments } from "../../../utils/subscriptions";
import { Instrument } from "../../../cqg-api/models/Instrument";
import PlusIcon from "../../common/icons/PlusIcon";
import { useSelector } from "react-redux";
import watchlistSelectors from "../../../redux/watchlist/watchlistSelector";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import WatchlistDataGrid from "./WatchlistDataGrid";
import LoadingComponent from "../../shared/LoadingComponent";

const Watchlist = (props: {
  workspaceId: number;
  workspaceClassName?: string;
  widget: Widget;
  isPopupWindow?: boolean;
}) => {
  const { widget } = props;
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const { products, loadProducts, loaded } = useFetchProducts();
  const { updateWatchlistAsync, updateWatchlistLoaded } = useUpdateWatchlist();
  const { watchlist, loadWorkspaceWatchlist } = useFetchWorkspaceWatchlist();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState<any>();
  const productToRemove = useSelector(watchlistSelectors.getRemovedProduct);
  const productToAdd = useSelector(watchlistSelectors.getAddProduct);
  const filteredProductList = useMemo(
    () => products.filter((product) => !watchlist?.associatedProducts?.includes(product.id)),
    [products, watchlist?.associatedProducts],
  );
  const { realTimeMarketData } = useRealTimeMarketData();

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  useEffect(() => {
    if (!widget.widgetId) {
      console.error("Watchalist id not found.");
      return;
    }
    loadWorkspaceWatchlist(widget.widgetId);
  }, [loadWorkspaceWatchlist, widget.widgetId]);

  const watchlistProducts = useMemo(() => {
    if (!watchlist?.associatedProducts) return [];
    const associatedProductIds = new Set(watchlist.associatedProducts.split(",").map((id) => id.trim()));
    const filteredProducts = products.filter((product) => associatedProductIds.has(product.id.toString()));
    const idOrder = Array.from(associatedProductIds);
    filteredProducts.sort((a, b) => {
      return idOrder.indexOf(a.id.toString()) - idOrder.indexOf(b.id.toString());
    });

    return filteredProducts;
  }, [products, watchlist?.associatedProducts]);

  useEffect(() => {
    console.log(
      "Subscribing Watchlist Products",
      watchlistProducts.map((value: Product) => value.cmeSymbol),
    );
    let resolvedInstruments: Instrument[] = [];
    subscribeToInstruments(watchlistProducts).then((symbols) => {
      resolvedInstruments = symbols;
    });
    return () => {
      console.log(
        "Unsubscribing Watchlist Products",
        watchlistProducts.map((value: Product) => value.cmeSymbol),
        resolvedInstruments,
      );
      unsubscribeInstuments(resolvedInstruments);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchlistProducts]);

  const [watchlistData, setWatchlistData] = useState<InstrumentMarketData[]>([]);

  useEffect(() => {
    const newWatchlistData: InstrumentMarketData[] = [];
    watchlistProducts.forEach(function (data: Product) {
      let index = watchlistData.findIndex((product: Product) => product.id === data.id);
      if (index !== -1) {
        newWatchlistData.push(watchlistData[index]);
      } else {
        newWatchlistData.push({ ...data });
      }
    });
    setWatchlistData(newWatchlistData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchlistProducts]);

  useEffect(() => {
    setWatchlistData((prev) => MapInstrumentMarketData2(prev, realTimeMarketData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "title",
        headerName: "Symbol",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "bidPrice",
        headerName: "Bid Price",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "bidQty",
        headerName: "Bid Qty",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "askPrice",
        headerName: "Ask Price",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "askQty",
        headerName: "Ask Qty",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "lastPrice",
        headerName: "Last Price",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "lastQty",
        headerName: "Last Qty",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "change",
        headerName: "Change",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "changePercentage",
        headerName: "Change %",
        flex: 1,
        minWidth: 75,
        sortable: true,
      },
      {
        field: "+",
        headerName: "+",
        flex: 1,
        minWidth: 75,
        sortable: false,
        renderCell: (param) => {
          return (
            <Delete
              onClick={() => {
                setOpenConfirmDialog(true);
                setDeleteRowId(param?.row?.id);
              }}
              style={{ cursor: "pointer", height: "18px" }}
            />
          );
        },
        renderHeader: (params) => (
          <>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenAddProduct(true);
              }}
            >
              <PlusIcon />
            </span>
          </>
        ),
      },
    ],
    [],
  );

  const handleCloseDialog = () => {
    setDeleteRowId("");
    setOpenConfirmDialog(false);
  };

  const onDeleteProduct = async (e?: any, rowId?: number) => {
    const rowIdToDelete = rowId ?? deleteRowId;
    const updatedAssociatedProducts: any = watchlist?.associatedProducts?.split(",")
      .filter((product) => product.trim() !== rowIdToDelete.toString())
      .join(",");
    if (watchlist) {
      setLoading(true);
      await updateWatchlistAsync(watchlist.id, updatedAssociatedProducts);
      await loadWorkspaceWatchlist(watchlist.id);
      handleCloseDialog();
      setLoading(false);
    }
  };

  useEffect(() => {
    if (productToRemove) {
      onDeleteProduct(null, productToRemove);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productToRemove]);

  useEffect(() => {
    if (productToAdd) {
      onAddProduct(productToAdd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productToAdd]);

  const onAddProduct = async (productIds: string) => {
    const existingAssociatedProducts = watchlist?.associatedProducts;
    if (watchlist) {
      await updateWatchlistAsync(watchlist.id, `${productIds},` + existingAssociatedProducts);
      await loadWorkspaceWatchlist(watchlist.id);
      
      setOpenAddProduct(false);
    }
  };

  const onRowDrop = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (!watchlist) return;

      if (fromIndex < 0 || fromIndex >= watchlistData.length || toIndex < 0 || toIndex >= watchlistData.length) {
        console.error("Invalid indices provided.");
        return;
      }

      setWatchlistData((prev: InstrumentMarketData[]) => {
        const updatedRows = [...prev];
        const [movedRow] = updatedRows.splice(fromIndex, 1);
        updatedRows.splice(toIndex, 0, movedRow);
        const watchlistNewOrder = updatedRows.map((row) => row.id).join(",");
        updateWatchlistAsync(watchlist.id, watchlistNewOrder);
        return updatedRows;
      });
    },
    [updateWatchlistAsync, watchlist, watchlistData.length],
  );

  return (
    <>
      <div className="widget-parent-container">
        {loading && <LoadingComponent withOverlay />}
        <div className="draggableCancelSelector widget-grid-container">
          <DndProvider context={window} backend={HTML5Backend}>
            <WatchlistDataGrid
              watchlistRows={watchlistData}
              columns={columns}
              onRowDrop={onRowDrop}
              watchlistId={watchlist?.id}
            />
          </DndProvider>
        </div>
      </div>
      <AddProduct
        open={openAddProduct}
        setOpen={setOpenAddProduct}
        onAddProduct={onAddProduct}
        products={filteredProductList}
        loaded={loaded}
        updateWatchlistLoaded={updateWatchlistLoaded}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        handleClose={handleCloseDialog}
        onDelete={onDeleteProduct}
        loading={loading}
        confirmText={"Are you sure you want to remove"}
      />
    </>
  );
};

export default Watchlist;
