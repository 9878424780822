import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Contract } from './types';
import ContractMenuItem from './ContractList';
import { subscribeToInstruments2 } from '../../../utils/subscriptions';

interface SelectContractProps {
  contracts: Contract[];
  onSelect: (id: string, contract: Contract) => void;
  id: string;
  selectedState: Contract;
}

const SelectContract: React.FC<SelectContractProps> = ({ contracts, onSelect, id, selectedState }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedContract, setSelectedContract] = React.useState(selectedState);

  const open = Boolean(anchorEl);  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    
    const contractIds = contracts.map((contract) => contract.contractId);
    subscribeToInstruments2(contractIds as number[]);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  }
  const handleClose = (contract: Contract) => {
    handleCloseMenu();
    if (contract) {
      setSelectedContract(contract);
      onSelect(id, contract);
    }
  };
  const getContractName = (contract: Contract) => {
    const indexOfSelectedMonth = Object.values(contract.MONTH_CODES).findIndex((month) => month === contract.month);
    const monthCode = Object.keys(contract.MONTH_CODES)[indexOfSelectedMonth];
    return `${monthCode} - ${contract.month.toUpperCase()} ${contract.title.substr(contract.title.length - 2)}`;
  }
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 0px",
          minWidth: "90px",
          alignItems: "center",
          fontFamily: "Averta-Regular",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "26px",
          letterSpacing: "1px",
          textAlign: "left",
        }}
      >
        <span>
          {selectedContract ? selectedContract.displayName : ''}
        </span>
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {contracts.map((contract) => (
          <ContractMenuItem
            key={`${contract.id}-${contract.month}`}
            contract={contract}
            selectedContract={selectedContract}
            onSelect={handleClose}
            getContractName={getContractName}
          />
        ))}
      </Menu>
    </div>
  );
};

export default SelectContract;