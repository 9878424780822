import { Box, Link, Stack, Typography } from "@mui/material";
import ControlledRadioGroup from "../RadioGroup";
import { Control } from "react-hook-form";
import { TradeLogProps } from "../../../../../types";
import { MARKET_TREND_OPTIONS } from "../types";

const MarketTrends = ({ control }: { control: Control<TradeLogProps, any> }) => {
  return (
    <Box component="section" className="section-outer">
      <Stack
        direction={{ md: "column", lg: "row" }}
        spacing={2}
        sx={{
          justifyContent: { xs: "center", md: "space-between" },
          alignItems: { xs: "center", md: "flex-start" },
          gap: { xs: 1, md: 2, lg: 10 },
        }}
      >
        <Box className="section-left" sx={{ width: { xs: "100%", md: "auto" } }}>
          <Box className="section-sub-left">
            <Typography className="section-title">Section C:</Typography>
            <Typography className="section-sub-title">Types of Market Trends</Typography>
          </Box>
          <Box className="section-sub-right">
            <Typography className="section-middle" fontSize="12px">
              If you’re like many traders, you have an innate preference and aptitude for trading market trends. A
              momentum trader likes to go with the flow and believes the “trend is your friend.” A reversion trader
              takes a short-term view and looks to capture market extremes.
            </Typography>
            <Link
              href="https://www.cmegroup.com/education/courses/trading-and-analysis/trend-vs-anti-trend.html"
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more in Trading and Analysis
            </Link>
          </Box>
        </Box>
        <Box sx={{ width: { xs: "100%", lg: "auto" } }}>
          <ControlledRadioGroup
            control={control}
            title="Please select a type of Market Trends"
            options={MARKET_TREND_OPTIONS}
            name="trend"
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default MarketTrends;
