import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import { Contract } from './types';

interface ContractMenuItemProps {
  contract: Contract;
  selectedContract: Contract;
  onSelect: (contract: Contract) => void;
  getContractName: (contract: Contract) => string;
}

const ContractMenuItem: React.FC<ContractMenuItemProps> = ({ contract, selectedContract, onSelect, getContractName }) => {
  return (
    <MenuItem
      key={contract.id}
      onClick={() => onSelect(contract)}
      sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <ListItemText>
        {getContractName(contract)}
      </ListItemText>
      {contract.title === selectedContract.title && (
        <ListItemIcon sx={{justifyContent: "right"}}>
          <Check color="primary" />
        </ListItemIcon>
      )}
    </MenuItem>
  );
};

export default ContractMenuItem;