import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { contractSelectStyles } from "../optionsStyles";
import { MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { WidgetType } from "../../../../types";
import MobileTreeViewModal from "../../../tree-view/mobile/MobileTreeViewModal";

const InstrumentSelect = ({
  selectedInstrument,
  handleRowStateChange,
}: {
  selectedInstrument: any;
  handleRowStateChange: (id: string, contract: any) => void;
}) => {
  const [value, setValue] = useState("");
  const [showTreeView, setShowTreeView] = useState(false);
  const openProductSelection = () => {
    setShowTreeView(true);
  };

  useEffect(() => {
    setValue(selectedInstrument?.cmeSymbolName);
  }, [selectedInstrument?.cmeSymbolName]);

  const updateValue = (displayName: string): void => {
    handleRowStateChange("", displayName);
  };

  return (
    <>
      <FormControl fullWidth>
        <Select
          sx={contractSelectStyles}
          IconComponent={ExpandMoreIcon}
          id="demo-simple-select"
          value={value}
          onOpen={openProductSelection}
          open={false}
        >
          <MenuItem value={value}>{value}</MenuItem>
        </Select>
      </FormControl>
      {showTreeView && (
        <MobileTreeViewModal
          value={selectedInstrument?.displayName}
          updateValue={updateValue}
          widgetType={WidgetType.Options}
          showTreeView={showTreeView}
          setShowTreeView={setShowTreeView}
        />
      )}
    </>
  );
};

export default InstrumentSelect;
