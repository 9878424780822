const HIGH_IMPORTANCE_BIT = 4;
const MEDIUM_IMPORTANCE_BIT = 64;
const LOW_IMPORTANCE_BIT = 512;

export const getImpact = (eventAttribute: number) => {
    if (eventAttribute) {
        if ((eventAttribute & HIGH_IMPORTANCE_BIT) > 0) {
            return "High";
        }
        else if ((eventAttribute & MEDIUM_IMPORTANCE_BIT) > 0) {
            return "Medium";
        }
        else if ((eventAttribute & LOW_IMPORTANCE_BIT) > 0) {
            return "Low";
        }
    }
    return "";
}