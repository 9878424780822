import { styled, SxProps, Theme } from "@mui/material/styles";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const colors = {
    primary: "#006EB6",    
    background: "#ffffff", 
    selected: "#006EB6",   
    hover: "#006EB6",      
    textColor: "#081D37DE", 
    eventLinkColor: "#006EB6", 
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F5F5F5",
    fontSize: '12px',
    fontFamily: 'Averta-regular',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0',
    minWidth: '20px',
    maxWidth: '200px',
    lineHeight: '24px',
    letterSpacing: '0.17px',
    color: "#081D37"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '12px',
    fontFamily: 'Averta-regular',
    color: '#081D3799',
    lineHeight: '24px',
    letterSpacing: '0.17px',
  },
  [`&.${tableCellClasses.root}`]: {
    height: '24px',
    padding: '0px 10px', 
  },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  height: 450,
  width: '100%',
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: "#fff",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.selected-product-row": {
    backgroundColor: "#fff",
  },
  '& td, & th': {
    fontSize: '12px',
    fontFamily: 'Averta-regular',
    padding: '0',
  }
}));

export const StyledTableRowTitle = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: "var(--_components-alert-info-background, #E5F6FD)",
  height: '29px',

  "&.selected-product-row": {
    backgroundColor: "var(--_components-alert-info-background, #fff)",
  },

  '& td, & th': {
    fontSize: '12px',
    fontFamily: 'Averta-regular',
    padding: '0',
    paddingLeft: "10px",
  },
  [`&.MuiTableCell-root`]: {
    Padding: 0,
    paddingLeft: "10px !important"
  },
}));

// src/styles/typographyStyles.ts
export const typographyStyles = {
  title: {
    fontFamily: "Averta-Regular",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "22px",
    letterSpacing: "1.1px",
    color: "#081D37",
  },
  heading: {
    fontFamily: "Averta-Regular",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "32px",
    letterSpacing: "1.1px",
    color: "#081D37",
  },
  subTitle: {
    fontFamily: "Averta-Regular",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "17.16px",
    letterSpacing: "0.17px",
    color: "var(--text-secondary, #081D3799)",
  },
  header: {
    fontFamily: "Averta-Regular",
    fontSize: "11px",
    fontWeight: 600,
    lineHeight: "29.26px",
    letterSpacing: "1px",
    color: "#081D37",
  },
  content: {
    fontFamily: "Averta-Regular",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "17.16px",
    letterSpacing: "0.17px",
    color: "#081D37DE",
  },
};

// src/styles/buttonStyles.ts
export const buttonStyles = {
  color: "#006EB6",
  fontFamily: "Averta-Regular",
  fontSize: "15px",
  lineHeight: "26px",
  fontWeight: 600,
  letterSpacing: "1.5px",
};

export const datePickerStyles: SxProps<Theme> = {
  '.MuiOutlinedInput-root': {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  '.MuiOutlinedInput-input': {
    padding: '9px 14px',
  },
};

export const datePickerDayStyles: SxProps<Theme> = {
  '&[data-mui-date="true"] .Mui-selected': {
    backgroundColor: colors.primary,
  },
  '&.MuiPickersDay-root.Mui-selected': {
    border: `1px solid ${colors.primary}`,
    color: '#fff',
  },
  ':not(.Mui-selected)': {
    backgroundColor: colors.background,
    borderColor: colors.primary,
    color: colors.textColor,
  },
  '&.Mui-selected': {
    color: colors.textColor,
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    ':hover': {
      color: '#fff',
      backgroundColor: colors.primary,
      borderColor: colors.primary,
    },
  },
  ':hover': {
    color: '#fff',
    backgroundColor: colors.primary,
    borderColor: colors.primary,
  },
};

export const calendarActionButtonStyles = {
  letterSpacing: '1.5px',
  fontFamily: 'Averta-Regular',
  fontWeight: 600, 
  fontSize: '14px'
}