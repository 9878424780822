// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActionsButtons {
  width: calc(100% - 16px);
}
.mobile-action-button.disabled {
  background-color: #f1f1f1;
  color: #b0b0b0;
  cursor: not-allowed;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/PositionsWidget/PositionModal/flattenModal.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;AACA;EACE,yBAAyB;EACzB,cAAc;EACd,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".ActionsButtons {\n  width: calc(100% - 16px);\n}\n.mobile-action-button.disabled {\n  background-color: #f1f1f1;\n  color: #b0b0b0;\n  cursor: not-allowed;\n  border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
