import React, { useMemo } from "react";
import { TreeItem2 } from "@mui/x-tree-view";
import { Instrument } from "../../cqg-api/models/Instrument";
import { CONTRACT_ID_NAME, ProductWithContracts, TreeViewMode } from "../../types";
import Checkbox from "@mui/material/Checkbox";
import { DoneIcon } from "../../images/Icons";
import clsx from "clsx";
import { convertSymbolToDate } from "./utils/convertSymbolToDate";

const MonthlyContractTreeItem = (props: {
  product: ProductWithContracts;
  contract: Instrument;
  isSelected: boolean;
  handleCheckboxToggle: () => void;
  treeViewData: Instrument[];
  mode: TreeViewMode;
}) => {
  const { contract, isSelected, treeViewData, mode } = props;

  const volume = useMemo(() => {
    const index = treeViewData.findIndex((item) => item.contractId === contract.contractId);
    if (index > -1) return treeViewData[index].labelTotalVolume;
    return "";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeViewData]);

  const handleCheckboxToggle = () => {
    props.handleCheckboxToggle();
  };

  return (
    <TreeItem2
      itemId={String(contract[CONTRACT_ID_NAME])}
      className="monthly-contract-tree-item-container"
      onClick={(e) => {
        e.stopPropagation();
        handleCheckboxToggle();
      }}
      label={
        mode === TreeViewMode.MULTIPLE ? (
          <div
            className={clsx({
              "tree-view-item-row": true,
              "tree-view-item-row--selected": isSelected,
            })}
          >
            <Checkbox style={{ position: "absolute" }} checked={isSelected} />
            <div style={{ marginLeft: "36px" }}>{convertSymbolToDate(contract.displayName)}</div>
            <div>{volume}</div>
          </div>
        ) : (
          <div
            className={clsx({
              "tree-view-item-row": true,
              "tree-view-item-row--selected": isSelected,
            })}
          >
            {isSelected && <DoneIcon style={{ height: "12px", width: "12px", left: "4px", position: "absolute" }} />}
            <div style={{ marginLeft: "24px" }}>{convertSymbolToDate(contract.displayName)}</div>
            <div>{volume}</div>
          </div>
        )
      }
    />
  );
};

export default MonthlyContractTreeItem;
