import { Button } from "@mui/material";
import styled from "@emotion/styled";

const baseButtonStyles = {
  fontFamily: "Averta-Regular",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "26px",
  letterSpacing: "1.5px",
  textTransform: "uppercase" as "uppercase", 
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "none",
  border: "none",
  whiteSpace: "nowrap",
};

export const StyledCancelButton = styled(Button)(() => ({
  ...baseButtonStyles,
  color: "rgba(8, 29, 55, 0.87)",
  "&:hover": {
    border: "none",
    borderColor: "none",
    color: "#081D37",
  },
}));

interface StyledSaveButtonProps {
  loading: boolean;
}

export const StyledSaveButton = styled(Button)<StyledSaveButtonProps>(({ loading }) => ({
  ...baseButtonStyles,
  color: loading ? "#B0BEC5" : "#006EB6",
  "&:hover": {
    color: loading ? "#B0BEC5" : "#004F8A",
  },
}));
