import { TextField, Typography } from "@mui/material";
import TreeViewContainerDescription from "./TreeViewContainerDescription";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import TreeView from "./TreeView";
import { TreeViewMode, WidgetType } from "../../types";
import TreeViewChip from "./TreeViewChip";
import useFilteredAssets from "./hooks/useFilteredAssets";
import * as _ from "../../vendors/underscore-esm";
import { AvailableProductsContext } from "../../App";

const TreeViewContainer = ({
  widgetType,
  selectedTreeItems,
  onChangeTreeItems,
  errorMessage,
}: {
  widgetType: WidgetType;
  selectedTreeItems: string[];
  onChangeTreeItems: (selectedItems: string[]) => void;
  errorMessage: string | null;
}) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const { assetProductsDictionary, monthlyContractsByDisplayNameMap } = useContext(AvailableProductsContext);
  const filteredTreeViewDataSource = useFilteredAssets(assetProductsDictionary, searchQuery);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const assets = useMemo(() => {
    return Object.keys(filteredTreeViewDataSource ?? {}).sort();
  }, [filteredTreeViewDataSource]);

  useEffect(() => {
    if (searchQuery.trim()) {
      const allItemIds: string[] = assets.reduce((ids: string[], asset: string) => {
        const productIds = filteredTreeViewDataSource[asset]?.map((product) => product.id) || [];
        return [...ids, asset, ...productIds];
      }, []);
      setExpandedItems(allItemIds);
    } else {
      setExpandedItems([]);
    }
  }, [searchQuery, assets, filteredTreeViewDataSource]);

  const handleDelete = React.useCallback(
    (item: string) => {
      // {/* TODO: based on Mode */}
      const filteredTreeViewItems =
        widgetType === WidgetType.PriceLadder
          ? []
          : selectedTreeItems.filter((treeItem) => monthlyContractsByDisplayNameMap[treeItem] && treeItem !== item);
      onChangeTreeItems(filteredTreeViewItems);
    },
    [monthlyContractsByDisplayNameMap, onChangeTreeItems, selectedTreeItems, widgetType],
  );

  const onSelect = (selectedItems: string[]) => {
    onChangeTreeItems(selectedItems);
  };

  const handleExpandedItemsChange = useCallback((event: React.SyntheticEvent, itemIds: string[]) => {
    setExpandedItems(itemIds);
  }, []);

  return (
    <>
      <div className="widget-dialog-description">
        {/* TODO: pass from menu drawer */}
        <TreeViewContainerDescription widgetType={widgetType} />
      </div>
      <div className="widget-dialog-search">
        <TextField
          id="title"
          label={"Search"}
          size="small"
          sx={{ width: "100%" }}
          className="basic-info-title-field"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="selected-items-container">
        {/* TODO: need to remove widget type in chip */}
        <TreeViewChip selectedTreeItems={selectedTreeItems} handleDelete={handleDelete} widgetType={widgetType} isEnabled={!!_.size(monthlyContractsByDisplayNameMap)}/>
      </div>
      {errorMessage && (
        <Typography color="error" variant="caption" sx={{ marginBottom: 1, marginLeft: "24px" }}>
          {errorMessage}
        </Typography>
      )}
      <div className="widget-dialog-menu">
        <TreeView
          mode={widgetType === WidgetType.PriceLadder ? TreeViewMode.SINGLE : TreeViewMode.MULTIPLE}
          selectedItems={selectedTreeItems}
          filteredTreeViewDataSource={filteredTreeViewDataSource}
          expandedItems={expandedItems}
          onSelect={onSelect}
          onExpandedItemsChange={handleExpandedItemsChange}
        />
      </div>
    </>
  );
};

export default TreeViewContainer;
