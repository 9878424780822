import React, { useMemo, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { 
  TextField, Select, MenuItem, Switch, Button, FormControl, 
  FormControlLabel, Grid, 
} from '@mui/material';
import { styled } from '@mui/system';
import './Trade.css';
import { Tabs, Tab } from '@mui/material';
import PlusIcon from './PlusIcon';
import MinusIcon from './MinusIcon';
import ConfirmTradeDialog from './ConfirmTradeDialog';
import SelectedContractInfo from './SelectedContractInfo';
import { CQGEnvironment } from '../../cqg-api/services/CQGEnvironment';
import { OrderState } from '../../cqg-api/models/OrderState';
import { makeOrder } from '../../cqg-api/controllers/tradingTicketController';
import { Instrument } from '../../cqg-api/models/Instrument';

// Define the form input types
interface IFormInput {
  contract: string;
  side: 'BUY' | 'SELL';
  orderType: string;
  timeInForce: 'DAY' | 'GTC';
  quantity: number;
  limitPrice: number;
  stopPrice: number;
  takeProfit: boolean;
  stopLoss: boolean;
  profitTicks: number;
  profitPrice: number;
  profitPercentage: number;
  profit: number;
}

// Custom styling using MUI's styled utility
const StyledFormControl = styled(FormControl)({
  marginBottom: '16px',
});

const StyledButton = styled(Button)({
  backgroundColor: '#1976d2',
  color: '#fff',
  height: '48px',
  borderRadius: '8px',
  fontSize: '16px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#1565c0',
  },
});

const StyledSelect = styled(Select)({
  height: '36px',
  borderRadius: '8px',
  backgroundColor: '#fff',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#d1d1d1',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#1976d2',
  },
});
const inputStylesWithIcons = {
  height: '36px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    backgroundColor: 'transparent',
    '& input': {
      padding: '6.5px 12px',
      textAlign: 'center',
    },
  },
};
const inputStyles= {
  height: '36px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    backgroundColor: 'transparent',
    '& input': {
      padding: '6.5px 12px',
      border: 'none',
      textAlign: 'center',
    },
    '& fieldset': {
      border: 'none',
    },
  },
};
type FuturesFormProps = {
  contracts: Instrument[];
}
const placeOrder = (order: OrderState) => {
  CQGEnvironment.Instance.cqgService.placeOrder(order);
}

const FuturesForm: React.FC<FuturesFormProps> = ({ contracts }) => {
  const [showConfirmTradeDialog, setShowConfirmTradeDialog] = useState(false);
  // Initialize the form with default values
  const { control, handleSubmit, watch, setValue } = useForm<IFormInput>({
    defaultValues: {
      contract: contracts[0].displayName,
      side: 'BUY',
      orderType: 'Limit',
      timeInForce: 'DAY',
      quantity: 1,
      limitPrice: contracts[0].lastPrice ?? 0,
      stopPrice: 0,
      takeProfit: false,
      stopLoss: false,
      profitTicks: 1,
      profitPrice: 0,
      profitPercentage: 0,
      profit: 0,
    }
  });
  const contractValue = watch('contract');
  const [activeTab, setActiveTab] = useState('futures');
  const [orde, setOrde] = useState<OrderState>();
  const selectedContract = useMemo(() => contracts.find(
    (contract) => contract.displayName === contractValue
  ), [contracts, contractValue]);
  console.log('selectedContract>>>', selectedContract);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setActiveTab(newValue);
  };
  const createOrder = () => {
    console.log('Order created!!', orde);
    setShowConfirmTradeDialog(false);
    placeOrder(orde as OrderState);
  };
  // Handle form submission
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {

    const accounts = CQGEnvironment.Instance.accountsManager.getAccount(CQGEnvironment.cqgAccountAuthInfo?.accountId as number);
    
    console.log('Order created!! to be', data);
    const orderState: OrderState = {
      account: accounts, // Replace with actual account value
      instrument: selectedContract as Instrument, // Replace with actual instrument value
      when: new Date(),
      clientOrderId: 'defaultClientOrderId', // Replace with actual client order ID
      size: data.quantity,
      ...data,
    };
    setShowConfirmTradeDialog(true);
    const orde = makeOrder(orderState);
    setOrde(orde);
  };
  const onlyNumbersRules = {
    pattern: {
      value: /^[0-9]*$/,
      message: "Please enter a valid number",
    },
  };
  const insertNumberInput = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    const isValid = /^[0-9]*$/.test(value);
    if (!isValid) {
      target.value = value.slice(0, value.length - 1);
    }
  }
  console.log('contract', contracts);
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '10px', maxWidth: '500px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledFormControl fullWidth>
            <Controller
              name="contract"
              control={control}
              render={({ field }) => (
                <StyledSelect {...field}>
                  {contracts.map((contract) => (
                    <MenuItem key={contract.displayName} value={contract.displayName}>
                      {contract.displayName}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12}>
          <SelectedContractInfo selectedContract={selectedContract} />
        </Grid>

        <Tabs
          sx={{
            '& .MuiTabs-flexContainer': {
              justifyContent: 'space-around',
            },
            '& .MuiTab-root': {
              borderBottom: '1px solid #d1d1d1',
            },
          }}
          value={activeTab} onChange={handleTabChange} className="tabs">
          <Tab label="Futures" value="futures" />
          <Tab label="Options" value="options" />
        </Tabs>
        {/* Side (BUY/SELL) */}
        <Grid item xs={6}>
          <StyledFormControl fullWidth>
            <label className="label">Side</label>
            <Controller
              name="side"
              control={control}
              render={({ field }) => (
                <div className="instrumentContainer">
                  <button type="button" className={`instrumentAction instrumentAction-buy ${field.value === 'BUY' && 'active'}`} onClick={() => setValue(field.name, 'BUY')} value="BUY">BUY</button>
                  <button type="button" className={`instrumentAction instrumentAction-sell ${field.value === 'SELL' && 'active'}`} value="SELL" onClick={() => setValue(field.name, 'SELL')}>SELL</button>
                </div>
              )}
            />
          </StyledFormControl>
        </Grid>

        {/* Quantity */}
        <Grid item xs={6}>
        <label className="label">Quantity (1-10)</label>
          <Controller
            name="quantity"
            rules={onlyNumbersRules}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                onInput={insertNumberInput}
                variant="outlined"
                sx={inputStylesWithIcons}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <label className="label">Order Type</label>
          <StyledFormControl fullWidth>
            <Controller
              name="orderType"
              control={control}
              render={({ field }) => (
                <StyledSelect {...field}>
                  <MenuItem value="Limit">
                    Limit
                  </MenuItem>
                </StyledSelect>
              )}
            />
          </StyledFormControl>
        </Grid>

{/* Limit Price */}
<Grid item xs={6}>
  <label className="label">Limit Price</label>
          <div className="control">
            <Controller
              name="limitPrice"
              control={control}
              render={({ field }) => (
                <button
                  type="button"
                  className='minusButton'
                  onClick={() => {
                    setValue(field.name, field.value - 1);
                  }}
                >
                  <MinusIcon />
                </button>
              )}
            />
          <Controller
            name="limitPrice"
            control={control}
            rules={onlyNumbersRules}
            render={({ field }) => (
              <TextField
                {...field}
                onInput={insertNumberInput}
                fullWidth
                variant="outlined"
                sx={inputStyles}
              />
            )}
          />
          <Controller
            name="limitPrice"
            control={control}
            render={({ field }) => (
              <button
                type="button"
                className='minusButton'
                onClick={() => {
                  setValue(field.name, field.value + 1);
                }}
              >
                <PlusIcon />
              </button>
            )}
          />
        </div>
      </Grid>
        {/* Side (BUY/SELL) */}
        <Grid item xs={6}>
          <StyledFormControl fullWidth>
            <label className="label">Time in Force</label>
            <Controller
              name="timeInForce"
              control={control}
              render={({ field }) => (
                <div className="timeInForce">
                  <button type="button" onClick={
                      () => setValue(field.name, 'DAY')
                    }
                    className={`timeInDay ${field.value === 'DAY' && 'active'}`}
                    value="DAY"
                  >
                    DAY
                  </button>
                  <button
                    type="button"
                    onClick={() => setValue(field.name, 'GTC')}
                    className={`timeInDay ${field.value === 'GTC' && 'active'}`}
                    value="GTC"
                  >
                    GTC
                  </button>
                </div>
              )}
            />
          </StyledFormControl>
        </Grid>
      {/* Stop Price */}
      <Grid item xs={6}>
      <label className="label">Stop Price</label>
      <div className='control'>
        <Controller
          name="stopPrice"
          control={control}
          render={({ field }) => (
            <button
             type="button"
              className='minusButton'
              onClick={() => {
                setValue(field.name, field.value - 1);
              }}
            >
              <MinusIcon />
            </button>
          )}
        />
        <Controller
          name="stopPrice"
          control={control}
          rules={onlyNumbersRules}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              onInput={insertNumberInput}
              variant="outlined"
              sx={inputStyles}
            />
          )}
        />
        <Controller
          name="stopPrice"
          control={control}
          render={({ field }) => (
            <button
             type="button"
              className='minusButton'
              onClick={() => {
                setValue(field.name, field.value + 1);
              }}
            >
              <PlusIcon />
            </button>
          )}
        />
        </div>
      </Grid>
        {/* Take Profit */}
        <Grid item xs={12}>
          <FormControlLabel
            labelPlacement='start'
            control={
              <Controller
                name="takeProfit"
                control={control}
                render={({ field }) => (
                  <Switch {...field} checked={field.value} />
                )}
              />
            }
            label="Take Profit"
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          />
        </Grid>
{watch('takeProfit') && (<>
        <Grid item xs={6}>
          <label className="label">Ticks</label>
          <div className='control'>
          <Controller
            name="profitTicks"
            control={control}
            render={({ field }) => (
              <button
               type="button"
                className='minusButton'
                onClick={() => {
                  setValue(field.name, field.value - 1);
                }}
              >
                <MinusIcon />
              </button>
            )}
          />
          <Controller
            name="profitTicks"
            control={control}
            rules={onlyNumbersRules}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                onInput={insertNumberInput}
                variant="outlined"
                sx={inputStyles}
              />
            )}
          />
          <Controller
            name="profitTicks"
            control={control}
            render={({ field }) => (
              <button
                 type="button"
                className='minusButton'
                onClick={() => {
                  setValue(field.name, field.value + 1);
                }}
              >
                <PlusIcon />
              </button>
            )}
          />
          </div>
        </Grid>

        {/* Profit Price */}
        <Grid item xs={6}>
          <label className="label">Price</label>
          <div className='control'>
          <Controller
            name="profitPrice"
            control={control}
            render={({ field }) => (
              <button className='minusButton' type="button"
                onClick={() => {
                  setValue(field.name, field.value - 1);
                }}
              >
                <MinusIcon />
              </button>
            )}
          />
          <Controller
            name="profitPrice"
            control={control}
            rules={onlyNumbersRules}
            render={({ field }) => (
              <TextField
                {...field}
                onInput={insertNumberInput}
                fullWidth
                variant="outlined"
                sx={inputStyles}
              />
            )}
          />
          <Controller
            name="profitPrice"
            control={control}
            render={({ field }) => (
              <button className='minusButton' type="button"
                onClick={() => {
                  setValue(field.name, field.value + 1);
                }}
              >
                <PlusIcon />
              </button>
            )}
          />
          </div>
        </Grid>

        {/* Profit Price */}
        <Grid item xs={6}>
          <label className="label">Profit $</label>
          <div className='control'>
          <Controller
            name="profit"
            control={control}
            render={({ field }) => (
              <button className='minusButton' type="button"
                onClick={() => {
                  setValue(field.name, field.value - 1);
                }}
              >
                <MinusIcon />
              </button>
            )}
          />
          <Controller
            name="profit"
            control={control}
            rules={onlyNumbersRules}
            render={({ field }) => (
              <TextField
                {...field}
                onInput={insertNumberInput}
                fullWidth
                variant="outlined"
                sx={inputStyles}
              />
            )}
          />
          <Controller
            name="profit"
            control={control}
            render={({ field }) => (
              <button className='minusButton' type="button"
                onClick={() => {
                  setValue(field.name, field.value + 1);
                }}
              >
                <PlusIcon />
              </button>
            )}
          />
          </div>
        </Grid>
        {/* Ticks (Take Profit) */}
        <Grid item xs={6}>
          <label className="label">Percentage %</label>
          <div className='control'>
          <Controller
            name="profitPercentage"
            control={control}
            render={({ field }) => (
              <button
               type="button"
                className='minusButton'
                onClick={() => {
                  setValue(field.name, field.value - 1);
                }}
              >
                <MinusIcon />
              </button>
            )}
          />
          <Controller
            name="profitPercentage"
            control={control}
            rules={onlyNumbersRules}
            render={({ field }) => (
              <TextField
                {...field}
                onInput={insertNumberInput}
                fullWidth
                variant="outlined"
                sx={inputStyles}
              />
            )}
          />
          <Controller
            name="profitPercentage"
            control={control}
            render={({ field }) => (
              <button
               type="button"
                className='minusButton'
                onClick={() => {
                  setValue(field.name, field.value + 1);
                }}
              >
                <PlusIcon />
              </button>
            )}
          />
          </div>
        </Grid>
        </>)}

        {/* Stop Loss */}
        <Grid item xs={12}>
          <FormControlLabel
            labelPlacement='start'
            control={
              <Controller
                name="stopLoss"
                control={control}
                render={({ field }) => (
                  <Switch {...field} checked={field.value} />
                )}
              />
            }
            label="Stop Loss"
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          />
        </Grid>
        {/* Ticks (Take Profit) */}
        {watch('stopLoss') && (<>
        <Grid item xs={6}>
          <label className="label">Ticks</label>
          <div className='control'>
          <Controller
            name="profitTicks"
            control={control}
            render={({ field }) => (
              <button
               type="button"
                className='minusButton'
                onClick={() => {
                  setValue(field.name, field.value - 1);
                }}
              >
                <MinusIcon />
              </button>
            )}
          />
          <Controller
            name="profitTicks"
            control={control}
            rules={onlyNumbersRules}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                onInput={insertNumberInput}
                variant="outlined"
                sx={inputStyles}
              />
            )}
          />
          <Controller
            name="profitTicks"
            control={control}
            render={({ field }) => (
              <button
                 type="button"
                className='minusButton'
                onClick={() => {
                  setValue(field.name, field.value + 1);
                }}
              >
                <PlusIcon />
              </button>
            )}
          />
          </div>
        </Grid>

        {/* Profit Price */}
        <Grid item xs={6}>
          <label className="label">Price</label>
          <div className='control'>
          <Controller
            name="profitPrice"
            control={control}
            render={({ field }) => (
              <button className='minusButton' type="button"
                onClick={() => {
                  setValue(field.name, field.value - 1);
                }}
              >
                <MinusIcon />
              </button>
            )}
          />
          <Controller
            name="profitPrice"
            control={control}
            rules={onlyNumbersRules}
            render={({ field }) => (
              <TextField
                {...field}
                onInput={insertNumberInput}
                fullWidth
                variant="outlined"
                sx={inputStyles}
              />
            )}
          />
          <Controller
            name="profitPrice"
            control={control}
            render={({ field }) => (
              <button className='minusButton' type="button"
                onClick={() => {
                  setValue(field.name, field.value + 1);
                }}
              >
                <PlusIcon />
              </button>
            )}
          />
          </div>
        </Grid>

        {/* Profit Price */}
        <Grid item xs={6}>
          <label className="label">Profit $</label>
          <div className='control'>
          <Controller
            name="profit"
            control={control}
            render={({ field }) => (
              <button className='minusButton' type="button"
                onClick={() => {
                  setValue(field.name, field.value - 1);
                }}
              >
                <MinusIcon />
              </button>
            )}
          />
          <Controller
            name="profit"
            control={control}
            rules={onlyNumbersRules}
            render={({ field }) => (
              <TextField
                {...field}
                onInput={insertNumberInput}
                fullWidth
                variant="outlined"
                sx={inputStyles}
              />
            )}
          />
          <Controller
            name="profit"
            control={control}
            render={({ field }) => (
              <button className='minusButton' type="button"
                onClick={() => {
                  setValue(field.name, field.value + 1);
                }}
              >
                <PlusIcon />
              </button>
            )}
          />
          </div>
        </Grid>
        {/* Ticks (Take Profit) */}
        <Grid item xs={6}>
          <label className="label">Percentage %</label>
          <div className='control'>
          <Controller
            name="profitPercentage"
            control={control}
            render={({ field }) => (
              <button
               type="button"
                className='minusButton'
                onClick={() => {
                  setValue(field.name, field.value - 1);
                }}
              >
                <MinusIcon />
              </button>
            )}
          />
          <Controller
            name="profitPercentage"
            control={control}
            rules={onlyNumbersRules}
            render={({ field }) => (
              <TextField
                {...field}
                onInput={insertNumberInput}
                fullWidth
                variant="outlined"
                sx={inputStyles}
              />
            )}
          />
          <Controller
            name="profitPercentage"
            control={control}
            render={({ field }) => (
              <button
               type="button"
                className='minusButton'
                onClick={() => {
                  setValue(field.name, field.value + 1);
                }}
              >
                <PlusIcon />
              </button>
            )}
          />
          </div>
        </Grid>

        </>)}
        {/* Submit Button */}
        <Grid item xs={12}>
          <StyledButton fullWidth type="submit">
            Submit
          </StyledButton>
        </Grid>
      </Grid>
      <ConfirmTradeDialog
        open={showConfirmTradeDialog}
        onClose={() => setShowConfirmTradeDialog(false)}
        cancel={() => setShowConfirmTradeDialog(false)}
        confirm={createOrder}
      />
    </form>
  );
};

export default FuturesForm;
