import React from 'react';
import { IconButton, Popover, MenuItem, Select } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { checkIconStyles, menuStyles } from './marketStyles';
import { FilterIcon } from '../../../images/Icons';

const filters = [
  "Volume", "Price Change", "A-Z", "Z-A"
]

interface FilterProductsProps {
  selectedAssetType: string;
  handleFilterIconClick: (event: React.MouseEvent<HTMLElement>) => void;
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  setSelectedFilter: (value: string) => void;
  isSmallPanelFilter: boolean;
  isMobile?: boolean;
}

const FilterProducts = ({
  selectedAssetType,
  handleFilterIconClick,
  anchorEl,
  open,
  handleClose,
  setSelectedFilter,
  isSmallPanelFilter,
  isMobile,
}: FilterProductsProps) => (
  <div className="asset-type-container" style={{flex:isMobile ? 1 : undefined}}>
    {isSmallPanelFilter && !isMobile ? (
      <div className={selectedAssetType === "" ? "asset-type" : ""}>
        <IconButton onClick={handleFilterIconClick}>
          <FilterIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{
            padding: 0,
            fontSize: '12px',
            '& .MuiSelect-select': {
              width: "196px",
              padding: '5px',
              fontSize: '12px',
              background: 'white',
            },
          }}
        >
            {filters.map(value => (
                <MenuItem key={value} sx={{ width: "150px", fontSize: "14px", fontFamily: "Averta-Regular" }} onClick={() => { setSelectedFilter(value); handleClose(); }}>
                {value}
                </MenuItem>
            ))}
        </Popover>
      </div>
    ) : (
      <Select
        sx={{
          padding: 0,
          minWidth: isMobile ? "100%":"196px",
          height: isMobile ? '36px' : undefined,
          fontFamily: 'Averta-Regular',
          fontSize: '14px',
          '& .MuiSelect-select': {
            padding: '5px 5px 5px 10px',
            fontSize: '14px',
            background: 'white',
            display: "flex",
            alignItems: "center",
            paddingLeft: isMobile ? '14px' : undefined,
          },
        }}
        MenuProps={{
          PaperProps: {
             sx: {
              width:isMobile ? '100%' : undefined,
             },
           },
         }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedAssetType}
        renderValue={(selected) => selected}
        onChange={(event) => setSelectedFilter(event.target.value)}
      >
        {filters.map(value => (
          <MenuItem key={value} value={value} sx={menuStyles}>
            <span>{value}</span>
            {isMobile && value === selectedAssetType && <CheckIcon style={checkIconStyles} />}
          </MenuItem>
        ))}
      </Select>
    )}
  </div>
);

export default FilterProducts;
