import { Box, Grid } from "@mui/material";
import Icon from './Icon';
import "./Trade.css";
import { Instrument } from "../../cqg-api/models/Instrument";
import TradeTicketDrawer from "./Trade";
import { CloseIcon } from "../../images/Icons";

type TradeTicketMobileProps = {
  contracts?: Instrument[];
  workspaceId: number;
  externalData: any;
  expired?: boolean;
  instrument: any;
  closeTrade: () => void;
}
export const TradeTicketMobile = ({workspaceId, externalData, instrument, closeTrade}: TradeTicketMobileProps) => {
  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', padding: '10px 10px 2px 10px' }} justifyContent="space-between" alignItems="center">
          <Box sx={{ display: 'flex', fontWeight: 600, letterSpacing: '1.5px' }} alignItems="center" gap="4px" justifyContent="center">
            <Icon />
            <span className="trade-title-text">TRADE</span>
          </Box>
          <Box><button type="button" style={{ background: 'none', border: 'none' }} onClick={closeTrade}><CloseIcon /></button></Box>
        </Box>
      </Grid>
    </Grid>
    <TradeTicketDrawer workspaceId={workspaceId} externalData={externalData} isMobile instrument={instrument} closeTrade={closeTrade} />
    </>
  )
}
export default TradeTicketMobile;
