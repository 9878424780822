import "../ProductsNav/productsNav.css";
import MenuItem from '../Menutem';
import { Settings, Create, LiveHelp } from '@mui/icons-material';

const items = [
  { text: 'Education', icon: "/images/icons/education.svg" },
  { text: 'Trade Plan', icon: Create },
  { text: 'Settings', icon: Settings },
  { text: 'Help', icon: LiveHelp },
];

const ToolsList = () => (
  <ul>
    {items.map((item, index) => (
      <li key={index}>
        <MenuItem text={item.text} icon={item.icon} />
      </li>
    ))}
  </ul>
);

const ToolsNav = () => (
  <div className="productsNav">
    <h3 className="heading">TOOLS</h3>
    <ToolsList />
  </div>
);

export default ToolsNav;
