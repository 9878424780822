import { FormControl, Select, styled } from "@mui/material";

export const inputStyles = {
  height: "36px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    backgroundColor: "transparent",
    "& input": {
      padding: "6.5px 6px",
      border: "none",
      textAlign: "center",
      fontSize: "15px",
      minWidth: "50px",
    },
    "& fieldset": {
      border: "none",
    },
  },
};

export const StyledSelect = styled(Select)({
  height: "36px",
  borderRadius: "4px",
  backgroundColor: "#fff",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#d1d1d1",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2",
  },
});

export const StyledFormControl = styled(FormControl)({});

export const flattenButtonStyles = { width: "100%", height: "36px", color: "#006EB6", fontWeight: 600 };

export const cancelAllButtonStyles = { color: "#006EB6", fontWeight: 600, minWidth: "115px" };

export const buyMarketButtonStyles = {
  background: "#4CAF50",
  minWidth: "80px",
  width: "100%",
  "&:hover": { background: "#4CAF50" },
};
export const sellMarketButtonStyles = {
  background: "#EF5350",
  minWidth: "80px",
  width: "100%",
  "&:hover": { background: "#EF5350" },
};

export const cancelRoundedBuyStyles = { height: "14px", width: "14px", cursor: "pointer", color: "#2E7D32" };

export const cancelRoundedSellStyles = { height: "14px", width: "14px", color: "red", cursor: "pointer" };

export const menuIconStyles = { height: "14px", width: "12px" };

export const settingsIconStyles = { color: "rgba(8, 29, 55,0.56)", cursor: "pointer" };
