import { Controller } from "react-hook-form";
import { FormControlLabel, Radio, RadioGroup as MuiRadioGroup, Typography } from "@mui/material";

const ControlledRadioGroup = (props: any) => {
    const { control, title, options, name, onChange } = props;
    return (
        <>
            <label style={{ color:"#081D37", fontSize: "14px", lineHeight: "20.02px", letterSpacing: "0.17px"}} >{title}</label>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <MuiRadioGroup {...field} onChange={(e) => { field.onChange(e); onChange && onChange(e.target.value); }}>
                        {options.map((option: any) => (
                            <FormControlLabel
                            key={option}
                            value={option}
                            control={
                                <Radio
                                sx={{
                                    "& .MuiSvgIcon-root": {
                                        width: "20px",
                                        height: "20px",
                                    },
                                }}
                            />
                            }
                            label={
                                <Typography
                                    sx={{
                                        fontFamily: "Averta-Regular",
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        lineHeight: "24px",
                                        letterSpacing: "0.15px",
                                    }}
                                    data-testid={`radio-${option}`}
                                >
                                    {option}
                                </Typography>
                            }
                            sx={{ paddingLeft: '8px'}}
                            data-testid={`technical-option-${option}`}
                        />

                        ))}
                    </MuiRadioGroup>
                )}
            />
        </>
    );
};

export default ControlledRadioGroup;