import React from 'react';

const TreeViewHeading = () => {
  return (
    <div className="tree-view-heading" onClick={(e) => e.stopPropagation()}>
      <div>Contract</div>
      <div>Volume</div>
    </div>
  );
};

export default TreeViewHeading;
