import { Button, Grid } from "@mui/material";

type ModalFooterProps = {
  openTradeWindow: () => void;
};
const ModalFooter = ({ openTradeWindow }: ModalFooterProps) => {
  return (
    <Grid item xs={12} sx={{ padding: "4px 16px" }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button variant="outlined" className="chart-price-ladder-button">
            CHART
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" className="chart-price-ladder-button">
            PRICE LADDER
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button onClick={openTradeWindow} variant="contained" className="trade-button">
            TRADE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModalFooter;
