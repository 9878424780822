import { Switch } from "@mui/material";
import React from "react";
import "./sidebar.css";

interface FooterProps {
  isOpen: boolean;
}

const Footer: React.FC<FooterProps> = ({ isOpen }) => {
  return (
    <div className={`switch-container ${isOpen ? "open" : "closed"}`}>
      <Switch className="toggle-switch" />
      <span className="toggle-label">1-Click</span>
    </div>
  );
};

export default Footer;
