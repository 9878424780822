import React, { useEffect, useState, useRef } from 'react';

interface RenderColorCellProps {
  value: number | null | undefined;
}

const COLORS = {
  increaseBackground: '#4CAF50',
  decreaseBackground: '#C62828',
  increaseText: '#00695C',
  decreaseText: '#C62828',
  white: 'white',
  initialText: '#081D37DE',
  transparent: 'transparent',
};

const RenderColorCell: React.FC<RenderColorCellProps> = React.memo(({ value }) => {
  const [backgroundColor, setBackgroundColor] = useState<string>(COLORS.transparent);
  const [textColor, setTextColor] = useState<string>(COLORS.initialText);
  const previousValueRef = useRef<number>(value ?? 0);
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    const previousValue = previousValueRef.current;

    if (value != null && value !== previousValue) {
      const newBackgroundColor =
        value > previousValue ? COLORS.increaseBackground : COLORS.decreaseBackground;

      setBackgroundColor(newBackgroundColor);
      setTextColor(COLORS.white);
      previousValueRef.current = value;
    }
  }, [value]);

  useEffect(() => {
    if (backgroundColor !== COLORS.white && backgroundColor !== COLORS.transparent) {

      timeoutRef.current = window.setTimeout(() => {
        setBackgroundColor(COLORS.transparent);
      
        const newTextColor =
          backgroundColor === COLORS.increaseBackground
            ? COLORS.increaseText
            : COLORS.decreaseText;

        setTextColor(newTextColor);
      }, 250);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [backgroundColor, value]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor,
        padding: '0px 6px',
        transition: 'background-color 0.5s ease',
        width: '100%',
        height: '100%',
      }}
    >
      <span style={{ color: textColor }}>{(value ?? 0)}</span>
    </div>
  );
});

export default RenderColorCell;
