// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialogTitle {
  display: flex;
  width: calc(100% - 20px);
  justify-content: space-between;
  padding: 10px !important;
  margin: 0;
  background: #f2f2f2;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  text-transform: uppercase;

}`, "",{"version":3,"sources":["webpack://./src/components/TradeTicketDrawer/TradeTicketDrawer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;EACxB,8BAA8B;EAC9B,wBAAwB;EACxB,SAAS;EACT,mBAAmB;EACnB,6BAA6B;EAC7B,eAAe;EACf,yBAAyB;;AAE3B","sourcesContent":[".dialogTitle {\n  display: flex;\n  width: calc(100% - 20px);\n  justify-content: space-between;\n  padding: 10px !important;\n  margin: 0;\n  background: #f2f2f2;\n  border-bottom: 1px solid #ccc;\n  font-size: 14px;\n  text-transform: uppercase;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
