import { Instrument } from "../../../../cqg-api/models/Instrument";
import { OrderSide, OrderType } from "../../../../types";
import { Depth } from "../types";
import clsx from "clsx";
import OrderCell from "./OrderCell";
import { OrderState } from "../../../../cqg-api/models/OrderState";
import { useMemo, useState } from "react";
import {
  convertToKFormat,
  createPriceLookup,
  createPriceSeries,
  generateDepth,
  getDecimalPlaces,
  updatePriceSeries,
} from "../utils";

interface LadderTableProps {
  priceLadderBookRef: React.MutableRefObject<any>;
  selectedContract: Instrument;
  centerDivRef: React.MutableRefObject<any>;
  quantityValue: number;
  handleDragStart: (index: number) => void;
  handleDrop: (depth: Depth, index: number, depthList: Depth[]) => void;
  createOrderHandlerClick: (orderSide: OrderSide, orderType: OrderType, price: number | null) => void;
  handleCancelClick: (order: OrderState) => void;
}

const LadderTable = ({
  priceLadderBookRef,
  selectedContract,
  centerDivRef,
  quantityValue,
  handleDragStart,
  handleDrop,
  createOrderHandlerClick,
  handleCancelClick,
}: LadderTableProps) => {
  const [selectedDepth, setSelectedDepth] = useState<(Depth & { orderSide: OrderSide }) | null>(null);
  const tickSize = useMemo(() => selectedContract?.tickSize, [selectedContract?.tickSize]);
  const count = 50;
  const decimals = getDecimalPlaces(tickSize);

  // Update bids
  const bidPriceSeries = createPriceSeries(selectedContract?.bestBid, tickSize, count, true);
  const bidPriceLookup = createPriceLookup(selectedContract?.bids, decimals);
  const updatedBidsArray = updatePriceSeries(bidPriceSeries, bidPriceLookup, decimals);

  // Update asks
  const askPriceSeries = createPriceSeries(selectedContract?.bestAsk, tickSize, count);
  const askPriceLookup = createPriceLookup(selectedContract?.asks, decimals);
  const updatedAsksArray = updatePriceSeries(askPriceSeries, askPriceLookup, decimals);

  const asks = useMemo(() => generateDepth(updatedAsksArray, tickSize, count), [tickSize, updatedAsksArray]);
  const bids = useMemo(() => generateDepth(updatedBidsArray, tickSize, count, true), [tickSize, updatedBidsArray]);

  return (
    <div className="price-ladder-book-container" ref={priceLadderBookRef}>
      <table cellPadding={0} cellSpacing={0} className="price-ladder-book-table">
        <thead className="price-ladder-book-header">
          <tr>
            <th>Qty</th>
            <th>Buy</th>
            <th>Price</th>
            <th>Sell</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>
          {asks &&
            [...asks]?.reverse()?.map((depth, index, depthList) => (
              <tr key={index}>
                <td></td>
                <td></td>
                <td
                  className={clsx({
                    "price-ladder-book--last-price":
                      depth?.displayPrice === selectedContract?.lastPrice?.toFixed(decimals),
                    "price-ladder-book--high-price":
                      depth?.displayPrice === selectedContract?.labelHigh?.toFixed(decimals),
                    "price-ladder-book--low-price":
                      depth?.displayPrice === selectedContract?.labelLow?.toFixed(decimals),
                  })}
                >
                  {depth?.displayPrice}
                </td>
                <td className="price-ladder-book-sell">{convertToKFormat(depth?.volume)}</td>
                <OrderCell
                  depth={depth}
                  orderSide={OrderSide.Sell}
                  index={index}
                  depthList={depthList}
                  quantityValue={quantityValue}
                  handleDragStart={handleDragStart}
                  handleDrop={handleDrop}
                  createOrderHandlerClick={createOrderHandlerClick}
                  handleCancelClick={handleCancelClick}
                  selectedDepth={selectedDepth}
                  setSelectedDepth={setSelectedDepth}
                  decimals={decimals}
                />
              </tr>
            ))}
          <div ref={centerDivRef} className="price-ladder-ask-body"></div>
          {bids?.map((depth, index, depthList) => (
            <tr key={index}>
              <OrderCell
                depth={depth}
                orderSide={OrderSide.Buy}
                index={index}
                depthList={depthList}
                quantityValue={quantityValue}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
                createOrderHandlerClick={createOrderHandlerClick}
                handleCancelClick={handleCancelClick}
                selectedDepth={selectedDepth}
                setSelectedDepth={setSelectedDepth}
                decimals={decimals}
              />
              <td className="price-ladder-book-buy">{convertToKFormat(depth?.volume)}</td>
              <td
                className={clsx({
                  "price-ladder-book--last-price":
                    depth?.displayPrice === selectedContract?.lastPrice?.toFixed(decimals),
                  "price-ladder-book--high-price":
                    depth?.displayPrice === selectedContract?.labelHigh?.toFixed(decimals),
                  "price-ladder-book--low-price": depth?.displayPrice === selectedContract?.labelLow?.toFixed(decimals),
                })}
              >
                {depth?.displayPrice}
              </td>
              <td></td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LadderTable;
