import { useCallback, useMemo } from "react";
import { DisplayUtil } from "../../../../../../cqg-api/utils/DisplayUtil";
import { UseFormClearErrors } from "react-hook-form";
import { IFormInput } from "../../../../../TradeTicketDrawer/types";

interface Strategy {
  ticks: number;
  pnl: number;
  percent: number;
}

const usePriceChange = (
  limitPrice: number,
  quantity: number,
  side: string,
  sectionKey: string,
  selectedContract: any,
  setValue: (key: string, value: any) => void,
  clearErrors?: UseFormClearErrors<IFormInput>,
) => {
  const calculateStrategy = useMemo(
    () =>
      (price: number): Strategy => {
        const priceDifference = Math.abs(limitPrice - price);
        const ticks = selectedContract ? DisplayUtil.priceToTicks(priceDifference, selectedContract, true) : 0;

        const pnl =
          quantity && selectedContract?.multiplier
            ? Math.round(selectedContract.multiplier * quantity * priceDifference * 100) / 100
            : 0;

        const percent = limitPrice ? Math.round((priceDifference / limitPrice) * 100 * 100) / 100 : 0;

        return { ticks, pnl, percent };
      },
    [limitPrice, quantity, selectedContract],
  );

  const onPriceChange = useCallback(
    (price: number) => {
      const strategy = calculateStrategy(price);

      const pnlKey = sectionKey === "takeProfit" ? "profit" : "loss";
      const percentKey = sectionKey === "takeProfit" ? "profitPercentage" : "lossPercentage";

      setValue(`oco.${side}.${sectionKey}.tick`, strategy.ticks);
      setValue(`oco.${side}.${sectionKey}.${pnlKey}`, strategy.pnl);
      setValue(`oco.${side}.${sectionKey}.${percentKey}`, strategy.percent);
      if (clearErrors) {
        clearErrors(`oco.${side}.${sectionKey}.tick` as any);
        clearErrors(`oco.${side}.${sectionKey}.${pnlKey}` as any);
        clearErrors(`oco.${side}.${sectionKey}.${percentKey}` as any);
      }
    },
    [calculateStrategy, sectionKey, setValue, side, clearErrors],
  );

  return onPriceChange;
};

export default usePriceChange;
