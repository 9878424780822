import axios, { AxiosError } from "axios";
import { useCallback, useState } from "react";
import { Workspace } from "../../types";
import Notification from "../../components/shared/notification/Notification";
import { useGetCurrentUser } from "./authentication";

const url = "member_workspaces";

export const useFetchWorkspaces = () => {
  const [workSpaces, setWorkSpaces] = useState<Workspace[]>([]);
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState(false);
  const currentUser = useGetCurrentUser();
  const loadWorkSpaces = useCallback(async () => {
    try {
      const response = await axios.get<Workspace[]>(`get_member_workspaces/${currentUser.id}`);
      setWorkSpaces(response.data);
    } catch (error: any) {
      setError(error);
      setWorkSpaces([]);
      Notification.error(`Could not load workspaces, ${error?.message}`);
    } finally {
      setLoaded(true);
    }
  }, [currentUser.id]);

  return { workSpaces, error, loaded, loadWorkSpaces, setWorkSpaces };
};

export const useFetchWorkspaceById = () => {
  const [workspace, setWorkspace] = useState<Workspace>();
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const fetchWorkspaceById = useCallback(async (id: string) => {
    try {
      setLoaded(true);
      const response = await axios.get<Workspace>(`${url}/${id}`);
      setWorkspace(response.data);
    } catch (error: any) {
      setError(error);
      alert("error..");
    } finally {
      setLoaded(false);
    }
  }, []);

  return { fetchWorkspaceById, loaded, error, workspace };
};

export const useCreateWorkspace = () => {
  const [error, setError] = useState<Error>();
  const [createWorkspacedLoaded, setCreateWorkspaceLoaded] = useState(true);
  const currentUser = useGetCurrentUser();

  const createWorkspaceAsync = useCallback(
    async (workspace: Workspace, onSuccess?: (response: Workspace) => void, onFail?: () => void) => {
      try {
        setCreateWorkspaceLoaded(false);
        const response = await axios.post(`member_workspaces?member_id=${currentUser.id}`, { ...workspace });
        Notification.success("Workspace created successfully");
        onSuccess?.(response?.data);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not create workspace, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setCreateWorkspaceLoaded(true);
      }
    },
    [currentUser.id],
  );

  return { createWorkspaceAsync, error, createWorkspacedLoaded };
};

export const useUpdateWorkspace = () => {
  const [error, setError] = useState<Error>();
  const [updateWorkspaceLoaded, setUpdateWorkspaceLoaded] = useState(true);
  const currentUser = useGetCurrentUser();

  const updateWorkspaceAsync = useCallback(
    async (workspace: Workspace, onSuccess?: (response: Workspace) => void, onFail?: () => void) => {
      try {
        setUpdateWorkspaceLoaded(false);
        const response: Workspace = await axios.put(`${url}/${workspace.id}`, {
          data: workspace.data,
        });
        // Notification.success("Workspace updated successfully");
        onSuccess?.(response);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not update workspace, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setUpdateWorkspaceLoaded(true);
      }
    },
    [],
  );

  const updateDefaultWorkspaceAsync = useCallback(
    async (workspace: Workspace, onSuccess?: (response: Workspace) => void, onFail?: () => void) => {
      try {
        setUpdateWorkspaceLoaded(false);
        const response = await axios.put(`set_member_default_workspace/${currentUser.id}`, {
          workspace_id: workspace.id,
        });
        onSuccess?.(response?.data);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not update workspace, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setUpdateWorkspaceLoaded(true);
      }
    },
    [currentUser.id],
  );

  return { updateWorkspaceAsync, updateDefaultWorkspaceAsync, error, updateWorkspaceLoaded };
};

export const useWorkspaceUpdater = () => {
  const [error, setError] = useState<Error | null>(null);
  const [updateWorkspaceLoaded, setUpdateWorkspaceLoaded] = useState(true);

  const updateWorkspaceAsync = useCallback(
    async (workspace: Workspace, onSuccess?: (response: Workspace) => void, onFail?: () => void) => {
      try {
        setUpdateWorkspaceLoaded(false);
        const response: Workspace = await axios.put(`${url}/${workspace.id}`, {
          title: workspace.title,
        });
        onSuccess?.(response);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not update workspace: ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setUpdateWorkspaceLoaded(true);
      }
    },
    [],
  );

  return { updateWorkspaceAsync, error, updateWorkspaceLoaded };
};

export const useDeleteWorkspace = () => {
  const [error, setError] = useState<Error>();
  const [deleteWorkspaceLoading, setDeleteWorkspaceLoading] = useState(false);

  const deleteWorkspaceAsync = useCallback(
    async (workspaceId: number, onSuccess?: (response: Workspace) => void, onFail?: () => void) => {
      try {
        setDeleteWorkspaceLoading(true);
        const response: Workspace = await axios.delete(`${url}/${workspaceId}`);
        Notification.success("Workspace removed successfully");
        onSuccess?.(response);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not remove workspace, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setDeleteWorkspaceLoading(false);
      }
    },
    [],
  );

  return { deleteWorkspaceAsync, error, deleteWorkspaceLoading };
};

export const useSelectedWorkspace = () => {
  const [error, setError] = useState<Error>();

  const updateSelectedWorkspaceAsync = useCallback(
    async (id: number | undefined, onSuccess?: (response: any) => void, onFail?: () => void) => {
      try {
        const response: Workspace = await axios.put(`set_selected_workspace`, {
          selected_workspace: id,
        });
        onSuccess?.(response);
      } catch (error: any) {
        console.error(error);
        setError(error);
        onFail?.();
      }
    },
    [],
  );

  return { updateSelectedWorkspaceAsync, error };
};

export const useCreateDuplicateWorkspace = () => {
  const [error, setError] = useState<Error | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const createDuplicateWorkspaceAsync = useCallback(
    async (workspace: Workspace, onSuccess?: (response: Workspace) => void, onFail?: () => void) => {
      try {
        setIsCreating(true);
        const response = await axios.post('set_duplicate_workspace', {  
          title: workspace.title, 
          workspaceId: workspace.id
        });
        Notification.success("Duplicate Workspace created successfully");
        onSuccess?.(response?.data);
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          Notification.error(`Could not create workspace: ${error.response?.data?.message || error.message}`);
        } else {
          Notification.error(`Unexpected error occurred: ${error instanceof Error ? error.message : 'Unknown error'}`);
        }
        setError(error as Error);
        onFail?.();
      } finally {
        setIsCreating(false);
      }
    },
    []
  );

  return { createDuplicateWorkspaceAsync, error, isCreating };
};