import { BootstrapTooltip } from "../../../shared/BootstrapTooltip";
import MenuIcon from "@mui/icons-material/Menu";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { menuIconStyles } from "../PriceLadderStyles";
import { OrderState } from "../../../../cqg-api/models/OrderState";

interface OrderContentProps {
  orderClass: string;
  cancelStyles: {
    height: string;
    width: string;
    cursor: string;
    color: string;
  };
  placedOrder: OrderState;
  handleCancelClick: (order: OrderState) => void;
}

const OrderContent = ({ orderClass, cancelStyles, placedOrder, handleCancelClick }: OrderContentProps) => {
  return (
    <span className={orderClass}>
      <BootstrapTooltip title="Drag to adjust" placement="top">
        <MenuIcon style={menuIconStyles} className="price-ladder-pointer" />
      </BootstrapTooltip>
      <span>{placedOrder?.size}</span>
      <BootstrapTooltip title="Cancel order" placement="top">
        <CancelRoundedIcon style={cancelStyles} onClick={() => handleCancelClick(placedOrder)} />
      </BootstrapTooltip>
    </span>
  );
};

export default OrderContent;
