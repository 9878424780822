import { useCallback, useMemo } from "react";
import { DisplayUtil } from "../../../../../../cqg-api/utils/DisplayUtil";
import { UseFormClearErrors } from "react-hook-form";
import { IFormInput } from "../../../../../TradeTicketDrawer/types";

interface Strategy {
  price: number;
  ticks: number;
  displayPrice: number;
  pnl: number;
}

const usePercentageChange = (
  limitPrice: number,
  quantity: number,
  side: string,
  sectionKey: string,
  selectedContract: any,
  setValue: (key: string, value: any) => void,
  getSign: (isProfit: boolean) => number,
  clearErrors?: UseFormClearErrors<IFormInput>,
) => {
  const calculateStrategy = useMemo(
    () =>
      (percent: number): Strategy => {
        const sign = getSign(sectionKey === "takeProfit");
        const basePrice = limitPrice + (percent / 100) * limitPrice * sign;

        const steppedPrice = selectedContract ? DisplayUtil.stepPrice(basePrice, selectedContract, 0) : basePrice;

        const displayPrice = selectedContract
          ? parseFloat(DisplayUtil.toDisplayPrice(steppedPrice, selectedContract)?.toString() || "") || 0
          : steppedPrice;

        const ticks = selectedContract
          ? DisplayUtil.priceToTicks(Math.abs(limitPrice - steppedPrice), selectedContract, true)
          : 0;

        const pnl =
          quantity && selectedContract?.multiplier
            ? Math.round(selectedContract.multiplier * quantity * Math.abs(limitPrice - steppedPrice) * 100) / 100
            : 0;

        return { price: steppedPrice, ticks, displayPrice, pnl };
      },
    [getSign, limitPrice, quantity, sectionKey, selectedContract],
  );

  const onPercentageChange = useCallback(
    (percent: number) => {
      const strategy = calculateStrategy(percent);
      const pnlKey = sectionKey === "takeProfit" ? "profit" : "loss";

      setValue(`oco.${side}.${sectionKey}.tick`, strategy.ticks);
      setValue(`oco.${side}.${sectionKey}.price`, strategy.displayPrice);
      setValue(`oco.${side}.${sectionKey}.${pnlKey}`, strategy.pnl);
      if (clearErrors) {
        clearErrors(`oco.${side}.${sectionKey}.tick` as any);
        clearErrors(`oco.${side}.${sectionKey}.price` as any);
        clearErrors(`oco.${side}.${sectionKey}.${pnlKey}` as any);
      }
    },
    [calculateStrategy, sectionKey, setValue, side, clearErrors],
  );

  return onPercentageChange;
};

export default usePercentageChange;
