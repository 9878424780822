import { useEffect, useState } from "react";
import { useFetchProducts } from "../../../hooks/api/product"; // Assume this hook is already defined
import { InstrumentLoadService } from "../../../cqg-api/services/InstrumentLoadService";
import { AssetProductsDictionary, Product, ProductWithContracts } from "../../../types";
import { Instrument } from "../../../cqg-api/models/Instrument";

const useAvailableProducts = () => {
  const { products, loadProducts } = useFetchProducts();
  const [assetProductsDictionary, setAssetProductsDictionary] = useState<AssetProductsDictionary>({});
  const [productsMapById, setProductsMapById] = useState<Record<string, ProductWithContracts>>({});
  const [monthlyContractsByIdMap, setMonthlyContractsByIdMap] = useState<Record<string, Instrument>>({});

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  useEffect(() => {
    const loadContracts = async () => {
      if (products) {
        const productsByAssetClass: AssetProductsDictionary = {};

        try {
          // Create an array of promises
          const promises = products.map(async (product: Product) => {
            const monthlyContracts = await InstrumentLoadService.loadMonthsFromCqgRoot(product.cqgSymbol, 4);
            return { ...product, monthlyContracts }; // Return the updated product
          });

          // Wait for all promises to resolve
          const productsWithContracts = await Promise.all(promises);

          // Group products by asset class
          productsWithContracts.forEach((productWithContracts: ProductWithContracts) => {
            const assetClassName = productWithContracts?.assetClass?.name;
            if (assetClassName) {
              productsByAssetClass[assetClassName] = productsByAssetClass[assetClassName] || [];
              productsByAssetClass[assetClassName].push(productWithContracts);
            }
          });

          console.log("prodcutByassetclass", productsByAssetClass);

          setAssetProductsDictionary(productsByAssetClass);
        } catch (error) {
          console.error("Error loading contracts:", error);
        }
      }
    };

    loadContracts();
  }, [products]);

  useEffect(() => {
    const productMapById: Record<string, ProductWithContracts> = Object.values(assetProductsDictionary)
      .flatMap((products) => products)
      .reduce(
        (map, product) => {
          map[product.id] = product;
          return map;
        },
        {} as Record<string, ProductWithContracts>,
      );
    setProductsMapById(productMapById);
  }, [assetProductsDictionary]);

  useEffect(() => {
    const monthlyContractsByIdMap = Object.values(assetProductsDictionary)
      .flatMap((products) => products) // Flatten the products arrays
      .flatMap((product) => product.monthlyContracts || []) // Flatten all monthlyContracts arrays
      .filter((contract) => contract) // Filter out null/undefined contracts
      .reduce(
        (map, contract) => {
          map[contract.contractId as any] = contract; // Use contractId as the key in the map
          return map;
        },
        {} as { [contractId: string]: Instrument },
      );
    setMonthlyContractsByIdMap(monthlyContractsByIdMap);
  }, [assetProductsDictionary]);

  return { assetProductsDictionary, products, productsMapById, monthlyContractsByIdMap };
};

export default useAvailableProducts;
