import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SimpleTreeView, TreeItem2 } from "@mui/x-tree-view";
import { MapTreeData } from "../../utils/utils";
import { useRealTimeMarketData } from "../../cqg-api/hooks/ServiceHooks";
import { AssetProductsDictionary, CONTRACT_ID_NAME, ProductWithContracts, TreeViewMode, WidgetType } from "../../types";
import { subscribeToInstruments2, unsubscribeInstuments } from "../../utils/subscriptions";
import useFilteredAssets from "./hooks/useFilteredAssets";
import TreeViewHeading from "./TreeViewHeading";
import ProductTreeItem from "./ProductTreeItem";
import MonthlyContractTreeItem from "./MonthlyContractTreeItem";
import { handleContractCheckboxToggle, handleProductCheckboxToggle } from "./utils/checkboxHandlers";
import { Instrument } from "../../cqg-api/models/Instrument";

const TreeView = (props: {
  mode: TreeViewMode;
  searchQuery: string;
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  assetProductsDictionary: AssetProductsDictionary;
  productsMapById: Record<string, ProductWithContracts>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
  widgetType?: WidgetType;
}) => {
  const { mode, searchQuery, selectedItems, setSelectedItems, assetProductsDictionary, productsMapById, setErrorMessage, widgetType } = props;

  const [treeViewData, setTreeViewData] = useState<Instrument[]>([]);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const { realTimeMarketData } = useRealTimeMarketData();

  const filteredTreeViewDataSource = useFilteredAssets(assetProductsDictionary, searchQuery);

  const assets = useMemo(() => {
    return Object.keys(filteredTreeViewDataSource ?? {});
  }, [filteredTreeViewDataSource]);

  useEffect(() => {
    if (treeViewData.length) {
      setTreeViewData(MapTreeData(treeViewData, realTimeMarketData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData]);

  useEffect(() => {
    if (searchQuery.trim()) {
      const allItemIds: string[] = assets.reduce((ids: string[], asset: string) => {
        const productIds = filteredTreeViewDataSource[asset]?.map(product => product.id) || [];
        return [...ids, asset, ...productIds];
      }, []);
      setExpandedItems(allItemIds);
    } else {
      setExpandedItems([]);
    }
  }, [searchQuery, assets, filteredTreeViewDataSource]);

  const handleExpandedItemsChange = useCallback(( event: React.SyntheticEvent, itemIds: string[], ) => {
    setExpandedItems(itemIds);
  },[]);

  const onItemExpansionToggle = useCallback(
    (e: React.SyntheticEvent<Element, Event>, itemId: string, isExpanded: boolean) => {
      const product = productsMapById[itemId];
      if (!product) {
        return;
      }
      if (isExpanded) {
        if (product?.monthlyContracts?.length) {
          setTreeViewData([...treeViewData, ...product.monthlyContracts]);
          const contractIds = product.monthlyContracts.map((contract) => contract.contractId);
          subscribeToInstruments2(contractIds as number[]);
        }
      } else {
        const filteredTreeViewData: Instrument[] = treeViewData.filter((treeData) => {
          return (
            product.monthlyContracts?.findIndex(
              (instrument: Instrument) => instrument.contractId === treeData.contractId,
            ) === -1
          );
        });
        setTreeViewData(filteredTreeViewData);
        unsubscribeInstuments(product.monthlyContracts as Instrument[]);
      }
    },
    [productsMapById, treeViewData],
  );

  return (
    <>
      <SimpleTreeView
        expandedItems={expandedItems}
        onExpandedItemsChange={handleExpandedItemsChange}
        onItemExpansionToggle={onItemExpansionToggle}
        expansionTrigger="content"
        selectedItems={selectedItems}
        multiSelect
      >
        {assets.map((asset) => {
          return (
            <div key={asset}>
              <TreeItem2 className="tree-view-head" itemId={asset} label={asset} key={asset}>
                {filteredTreeViewDataSource[asset]?.map((product: ProductWithContracts) => {
                  return (
                    <ProductTreeItem
                      key={product.id}
                      product={product}
                      isSelected={selectedItems.includes(product.id)}
                      handleCheckboxToggle={() =>
                        handleProductCheckboxToggle(product, selectedItems, setSelectedItems, setErrorMessage, mode, widgetType as WidgetType)
                      }
                      selectedItems={selectedItems}
                      mode={mode}
                    >
                      <TreeViewHeading />
                      {product?.monthlyContracts?.map((contract) => {
                        return (
                          <MonthlyContractTreeItem
                            key={contract[CONTRACT_ID_NAME]}
                            contract={contract}
                            product={product}
                            isSelected={selectedItems.includes(String(contract[CONTRACT_ID_NAME]))}
                            handleCheckboxToggle={() =>
                              handleContractCheckboxToggle(contract, product, selectedItems, setSelectedItems, setErrorMessage, mode, widgetType as WidgetType)
                            }
                            treeViewData={treeViewData}
                            mode={mode}
                          />
                        );
                      })}
                    </ProductTreeItem>
                  );
                })}
              </TreeItem2>
            </div>
          );
        })}
      </SimpleTreeView>
    </>
  );
};

export default TreeView;
