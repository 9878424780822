import { Instrument } from "../../../cqg-api/models/Instrument";
import { ArrowDownIcon, ArrowUpIcon } from "../../../images/Icons";

type SelectedContractInfoProps = {
  selectedContract?: Instrument | null;
  activeTab?: string;
  putCallValue?: any;
  isPriceLadder?: boolean;
};

const SelectedContractInfo = ({ selectedContract, activeTab, putCallValue, isPriceLadder }: SelectedContractInfoProps) => {
  const priceNetChange = selectedContract?.labelPriceNetChange;

  const renderPriceNetChange = (value?: number | null) => {
    if (value == null) return "-";

    const isPositive = value > 0;
    const sign = isPositive ? "+" : "";
    const ArrowIcon = isPositive ? ArrowUpIcon : ArrowDownIcon;
    const color = isPositive ? "#00695C" : "#D32F2F";

    return (
      <span style={{ color, whiteSpace: 'nowrap' }}>
        <ArrowIcon style={{ width: '8px', height: '8px', marginRight: '4px' }} /> 
        {sign}{activeTab === "options" ? value : value.toFixed(2)}
      </span>
    );
  };

  const displayNoValue = activeTab === "options" && !putCallValue;

  return (
    <table cellPadding={0} cellSpacing={0} className="contractDataTable">
      <thead>
        <tr>
          <th>Bid</th>
          <th>Ask</th>
          <th>Last</th>
          <th>Volume</th>
          <th>Chng</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{!displayNoValue ? (activeTab === "options" ? selectedContract?.labelBid : selectedContract?.labelBid?.toFixed(2)) : '-'}</td>
          <td>{!displayNoValue ? (activeTab === "options" ? selectedContract?.labelAsk : selectedContract?.labelAsk?.toFixed(2)) : '-'}</td>
          <td>{!displayNoValue ? (selectedContract?.lastPrice?.toFixed(2)) : '-'}</td>
          <td>{!displayNoValue ? selectedContract?.labelTotalVolume : '-'}</td>
          <td>{!displayNoValue ? renderPriceNetChange(priceNetChange) : '-'}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default SelectedContractInfo;
