import { Button } from "@mui/material";
import "./PriceLadder.css";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useMemo, useState } from "react";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { OrderSide, OrderType, Widget, Workspace } from "../../../types";
import SelectedContractInfo from "../../TradeTicketDrawer/SelectedContractInfo";
import { useRealTimeMarketData } from "../../../cqg-api/hooks/ServiceHooks";
import { subscribeToInstrumentsDepths } from "../../../utils/subscriptions";
import { mapInstrumentData } from "../../../utils/utils";
import { buyMarketButtonStyles, sellMarketButtonStyles } from "./PriceLadderStyles";
import PriceLadderBook from "./PriceLadderBook/PriceLadderBook";
import PriceLadderFooter from "./PriceLadderFooter";
import useScrollHandlers from "./hooks/useScrollHandler";
import TimeInForce from "./TimeInForce";
import Quantity from "./Quantity";
import FlattenAllPositions from "./FlattenAllPositions";
import CancelAllOrders from "./CancelAllOrders";
import PositionsCount from "./PostionsCount";
import SelectContract from "./SelectContract";
import OrderSettings from "./OrderSettings";
import OrderSettingsDialog from "./OrderSettingsDialog/OrderSettingsDialog";
import { IFormInput } from "./types";
import { CQGEnvironment } from "../../../cqg-api/services/CQGEnvironment";
import { OrderState } from "../../../cqg-api/models/OrderState";
import { makeOrder } from "../../../cqg-api/controllers/tradingTicketController";
import Notification from "../../shared/notification/Notification";
import { AvailableProductsContext } from "../../../App";

interface PriceLadderProps {
  workspace: Workspace;
  widget: Widget;
}

const PriceLadder = ({ workspace, widget }: PriceLadderProps) => {
  const [openOrderSettings, setOpenOrderSettings] = useState(false);
  const onClose = () => {
    setOpenOrderSettings(false);
  };
  const { centerDivRef, priceLadderBookRef, scrollUpHandler, scrollDownHandler, scrollToCenterHandler } =
    useScrollHandlers();
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      contract: widget.contractIds?.[0],
      timeInForce: 1,
      side: undefined,
      // orderType: "LMT",
      quantity: 1,
      limitPrice: 0,
      stopPrice: 0,
      takeProfit: false,
      stopLoss: false,
      profitTicks: 1,
      profitPrice: 0,
      profitPercentage: 0,
      profit: 0,
      lossTicks: 1,
      lossPrice: 0,
      lossPercentage: 0,
      loss: 0,
      // rawLimitPrice:0,
      rawStopPrice: 0,
    },
  });
  const contractValue = watch("contract");
  const quantityValue = watch("quantity");
  const { monthlyContractsByDisplayNameMap } = useContext(AvailableProductsContext);

  const contracts = useMemo(() => {
    const contract: any = monthlyContractsByDisplayNameMap[contractValue];
    return contract?.monthlyContracts ?? [];
  }, [contractValue, monthlyContractsByDisplayNameMap]);

  const contract = useMemo(
    () => contracts.find((contract: Instrument) => contract.displayName === contractValue),
    [contracts, contractValue],
  );

  const [contractRealTimeData, setContractRealTimeData] = useState<Instrument>({ ...contract });

  const { realTimeMarketData } = useRealTimeMarketData();

  useEffect(() => {
    if (contract) {
      setContractRealTimeData({ ...contract });
      subscribeToInstrumentsDepths([contract.contractId] as number[]);
    }
  }, [contract]);

  useEffect(() => {
    if (contract) {
      setContractRealTimeData(mapInstrumentData(contract, realTimeMarketData));
    }
  }, [realTimeMarketData, contract]);

  const handleOpenOrderSettings = () => {
    setOpenOrderSettings(true);
  };

  const placeOrder = (order: OrderState) => {
    CQGEnvironment.Instance.cqgService.placeOrder(order);
  };

  const createOrderHandler = async (orderSide: OrderSide, orderType: OrderType, price: number | null) => {
    try {
      // Extract account and data values
      const accountId = CQGEnvironment.cqgAccountAuthInfo?.accountId as number;
      const accounts = CQGEnvironment.Instance.accountsManager.getAccount(accountId);
      const data = getValues();

      // const profitPrice = (profitStateValue?.price / contract?.correctPriceScale).toFixed();
      // const lossPrice = (lossStateValue?.price / contract?.correctPriceScale).toFixed();

      // Helper function to build the order state
      const buildOrderState = (): OrderState => ({
        ...data,
        account: accounts,
        instrument: contract, // Replace with the actual instrument value
        when: new Date(),
        clientOrderId: "defaultClientOrderId", // Replace with the actual client order ID
        size: data.quantity,
        side: orderSide,
        orderType,
        limitPrice: price,
        strikePrice: price,
        rawLimitPrice: price,

        // Uncomment and update if profit/loss strategy is needed:
        // profitLossStrategy: {
        //   profit: { price: profitStateValue.enabled ? profitPrice : false },
        //   loss: { price: lossStateValue.enabled ? lossPrice : false },
        // },
      });

      // Create order
      const orderState = buildOrderState();
      const newOrder = makeOrder(orderState);

      // Attempt to place the order
      await placeOrder(newOrder as OrderState);
      Notification.orderInfo(newOrder);
    } catch (error) {
      console.error("Order placement failed:", error);
      Notification.error(`Order could not be placed`);
    }
  };

  const placeMarkerOrder = (orderSide: OrderSide) => {
    createOrderHandler(orderSide, OrderType.Mkt, null);
  };

  return (
    <>
      <div className="price-ladder-container">
        <div className="price-ladder-top">
          <SelectContract control={control} name="contract" contracts={contracts}></SelectContract>
          <PositionsCount contract={contract}></PositionsCount>
          <OrderSettings handleOpenOrderSettings={handleOpenOrderSettings} />
        </div>

        <div className="price-ladder-top">
          <TimeInForce control={control} name="timeInForce" />
          <FlattenAllPositions contract={contract}></FlattenAllPositions>
          <CancelAllOrders contract={contract}></CancelAllOrders>
        </div>

        <div className="price-ladder-top">
          <Button variant="contained" sx={buyMarketButtonStyles} onClick={() => placeMarkerOrder(OrderSide.Buy)}>
            BUY MKT
          </Button>
          <Quantity name="quantity" control={control}></Quantity>
          <Button variant="contained" sx={sellMarketButtonStyles} onClick={() => placeMarkerOrder(OrderSide.Sell)}>
            SELL MKT
          </Button>
        </div>

        <div style={{ margin: "10px" }}>
          <SelectedContractInfo selectedContract={contractRealTimeData} />
        </div>

        <PriceLadderBook
          centerDivRef={centerDivRef}
          priceLadderBookRef={priceLadderBookRef}
          quantityValue={quantityValue}
          selectedContract={contractRealTimeData}
          createOrderHandler={createOrderHandler}
        />

        <PriceLadderFooter
          scrollUpHandler={scrollUpHandler}
          scrollToCenterHandler={scrollToCenterHandler}
          scrollDownHandler={scrollDownHandler}
          contract={contract}
        />
      </div>
      {openOrderSettings && (
        <OrderSettingsDialog
          open={openOrderSettings}
          onClose={onClose}
          control={control}
          selectedContract={contractRealTimeData}
          watch={watch}
          selectedInstrument={contract}
          setValue={setValue}
        />
      )}
    </>
  );
};

export default PriceLadder;
