import { OrderState } from "../../../cqg-api/models/OrderState";
import { CQGService } from "../../../cqg-api/services/CQGService";
import { numberNullable, stringNullable } from "../../../cqg-api/types/Types";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { OrderSide } from "../../../types";

export type OrderStateFilter = {
  contract?: Instrument;
  side?: OrderSide;
};

export const capitalizeFirstLetter = (text: stringNullable) => {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const getOrderStatusLabel = (obj: any, value: numberNullable) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const cancelOrders = (orders: OrderState[], filter?: OrderStateFilter) => {
  const filteredOrders = orders
    .filter((order) => !filter?.contract || order.getInstrument().contractId === filter.contract.contractId)
    .filter((order) => !filter?.side || order.displaySide === filter.side);

  CQGService.cancelOrders(filteredOrders);
};
