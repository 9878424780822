import actions from "./workspacesActions";
import { produce } from "immer";
const initialData = {
  workspaces: {},
};

export const workspaces = (state = initialData, { type, payload }) => {
  if (type === actions.WORKSPACE_SET_SUCCESS) {
    return produce(state, (draft) => {
      draft.workspaces = { ...state.workspaces, [payload.workspaceId]: {ticketInstrument: payload.instrument} };
    });
  }
  return state;
};
