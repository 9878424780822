import { useCallback, useState } from "react";
import { prepareAmendOrder } from "../../../cqg-api/controllers/tradingTicketController";
import { placeAmendOrder, placeOrder } from "../utils";
import { OrderState } from "../../../cqg-api/models/OrderState";
import Notification from "../../shared/notification/Notification";
import { CQGService } from "../../../cqg-api/services/CQGService";
import { useDispatch } from "react-redux";
import workspacesActions from "../../../redux/workspaces/workspacesActions";
import { AppMessages } from "../../../cqg-api/utils/AppMessages";

const useCreateOrder = (workspaceId: number, closeTrade?: () => void, isMobile?: boolean) => {
  const [showConfirmTradeDialog, setShowConfirmTradeDialog] = useState(false);
  const dispatch = useDispatch();
  const hideTradeTicket = useCallback(() => {
    dispatch(workspacesActions.dispatchSetWorkspace({ workspaceId, instrument: null }));
  }, [dispatch, workspaceId]);

  const createOrder = useCallback(
    (amendOrder: OrderState | undefined, createOrderState: OrderState | undefined) => {
      try {
        setShowConfirmTradeDialog(false);
        if (!!amendOrder) {
          const data = { ...createOrderState };
          const amendOrderState = prepareAmendOrder(amendOrder, data);
          AppMessages.workingOrders.addWorkingOrder({...amendOrderState} as OrderState);
          placeAmendOrder(amendOrderState as OrderState);
        } else {
          placeOrder(createOrderState as OrderState);
        }
        if(isMobile && closeTrade) {
          closeTrade();
        } else {
          hideTradeTicket();
        }
      } catch (e) {
        console.error(e);
        Notification.error(`Order ${createOrderState?.displayContract} could not be placed`);
      }
    },
    [closeTrade, hideTradeTicket, isMobile],
  );

  const cancelOrder = useCallback(
    (amendOrder: OrderState | undefined) => {
      if (!!amendOrder) {
        CQGService.cancelOrders(amendOrder);
        hideTradeTicket();
      }
    },
    [hideTradeTicket],
  );

  return {
    showConfirmTradeDialog,
    setShowConfirmTradeDialog,
    createOrder,
    cancelOrder,
    hideTradeTicket,
  };
};

export default useCreateOrder;
