// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.watchlist-table--header {
  background-color: #eeeeee;
  height: 36px !important;
}

.watchlist-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
}

.watchlist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
}

.watchlist-grid-panel {
  flex: 3 1;
  position: relative;
}

.watchlist-header--actions {
  display: flex;
  align-items: center;
  gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/widgets/watchlist/Watchlist.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".watchlist-table--header {\n  background-color: #eeeeee;\n  height: 36px !important;\n}\n\n.watchlist-container {\n  display: flex;\n  gap: 10px;\n  flex-direction: column;\n  width: 100%;\n}\n\n.watchlist-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 0 10px;\n}\n\n.watchlist-grid-panel {\n  flex: 3 1;\n  position: relative;\n}\n\n.watchlist-header--actions {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
