import { Box, Link, Typography } from "@mui/material";
import { SymbolType, TradePlanSymbolType } from "../TradePlanType";
interface MarketsProps {
  tradePlanSymbolsState: TradePlanSymbolType[] | undefined;
  assestsWithSymbols: Record<string, SymbolType[]>;
  isSymbolSelected: (symbolId: number) => boolean | undefined;
  onSymbolUpdate: (checked: boolean, symbol: SymbolType) => void;
  isMobileView: boolean;
}

const Markets = ({
  // tradePlanSymbolsState,
  // assestsWithSymbols,
  // isSymbolSelected,
  // onSymbolUpdate,
  isMobileView,
}: MarketsProps) => {
  return (
    <Box
      component="section"
      className="section-outer"
      sx={{ display: "flex", flexDirection: { md: "column", lg: "row" }, gap: { xs: 1, md: 2, lg: 10 } }}
    >
      <Box
        className="section-left"
        sx={{
          position: { lg: "sticky" },
          top: { lg: 10 },
          width: { xs: "100%", md: "auto" },
          mb: { md: 2, lg: 0 },
        }}
      >
        <Box className="section-sub-left">
          <Typography className="section-title">Section F:</Typography>
          <Typography className="section-sub-title">Markets</Typography>
        </Box>
        <Box className="section-sub-right">
          <Typography className="section-middle" fontSize="12px">
            When selecting markets to trade, traders might consider factors that would align their strategies with their
            goals, risk tolerance, and resources. Starting with markets that are most familiar or of interest can make
            the learning curve less daunting. CME Group offers access to all major asset classes, whether you're
            experienced at trading or building your foundation of knowledge, our{" "}
            {
              <Link
                href="https://www.cmegroup.com/education/courses.html"
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                educational courses and tools help you stay a step ahead.
              </Link>
            }
          </Typography>
        </Box>
      </Box>

      {/* TODO: need to clean */}
      {/* {!isMobileView && (
        <Box
          display="flex"
          flexWrap="wrap"
          sx={{ paddingLeft: 2, width: { xs: "100%", md: "auto", xl: "556px" }, flexGrow: 1 }}
        >
          {tradePlanSymbolsState &&
            Object.entries(assestsWithSymbols).map(([key, symbols]) => (
              <Box key={key} sx={{ width: "calc(50% - 8px)", marginBottom: 2 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "14px", letterSpacing: "0.17px", fontFamily: "Averta-Regular", color: "#081D37DE" }}
                >
                  {key}
                </Typography>
                <Grid item xs={12} sm={6} md={4} key={key}>
                  {symbols.map((symbol: SymbolType) => (
                    <Box key={symbol.symbolId} marginLeft={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSymbolSelected(Number(symbol.symbolId))}
                            onChange={(e) => onSymbolUpdate(e.target.checked, symbol)}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                width: "19px",
                                height: "19px",
                              },
                            }}
                          />
                        }
                        label={symbol.name}
                      />
                    </Box>
                  ))}
                </Grid>
              </Box>
            ))}
        </Box>
      )} */}
    </Box>
  );
};

export default Markets;
