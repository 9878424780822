import { Button, DialogActions } from "@mui/material";
import { calendarActionButtonStyles } from "../style";

interface ActionsBarProps {
  onCancel: () => void;
  onApplyDate: (onAccept: () => void) => void;
  onAccept: () => void;
}

const ActionsBar = ({ onCancel, onApplyDate, onAccept }: ActionsBarProps) => {
  return (
    <DialogActions
      sx={{
        gridColumn: "1/4",
        gridRow: "3",
      }}
    >
      <Button
        className="calendar-cancel-btn"
        sx={{ ...calendarActionButtonStyles, color: "#081D37DE" }}
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        className="calendar-apply-btn"
        sx={{ ...calendarActionButtonStyles, color: "#006EB6" }}
        onClick={() => onApplyDate(onAccept)}
      >
        Apply
      </Button>
    </DialogActions>
  );
};

export default ActionsBar;
