export const getWatchlist = (state) => state.watchlist.watchlist;
export const getRemovedProduct = (state) => state.watchlist.removeProduct;
export const getAddProduct = (state) => state.watchlist.addProduct;
export const getLoading = (state) => state.watchlist.loading;
const watchlistSelectors = {
  getWatchlist,
  getRemovedProduct,
  getAddProduct,
  getLoading,
};

export default watchlistSelectors;
