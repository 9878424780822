const SET_CHART_WIDGET_VALUE = 'SET_CHART_WIDGET_VALUE';
const CLEAR_CHART_WIDGET_VALUE = 'CLEAR_CHART_WIDGET_VALUE';

const initialState = {
  chartWidgetValue: null,
};

const chartWidgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHART_WIDGET_VALUE:
      return {
        ...state,
        chartWidgetValue: action.payload,
      };
    case CLEAR_CHART_WIDGET_VALUE:
      return {
        ...state,
        chartWidgetValue: null,
      };
    default:
      return state;
  }
};

export const setChartWidgetValue = (widget) => ({
  type: SET_CHART_WIDGET_VALUE,
  payload: widget,
});

export const clearChartWidgetValue = () => ({
  type: CLEAR_CHART_WIDGET_VALUE,
});

export default chartWidgetReducer;
