import React from "react";
import UserInfo from "../../../common/userinfo";
import { ChevronLeft } from "@mui/icons-material";
const SidebarHeader = ({ onToggle }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      padding: "6px 8px 6px 16px",
      borderBottom: "1px solid var(--divider, #0000001F)",
      alignItems: "center",
      cursor: "pointer",
    }}
    onClick={onToggle}
  >
    <UserInfo />
    <ChevronLeft />
  </div>
);

export default SidebarHeader;
