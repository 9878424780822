import { connectRouter } from "connected-react-router";
import { auth } from "./auth/authReducers";
import { watchlist } from "./watchlist/watchlistReducers";
import { products } from "./products/productsReducers";
import { layoutReducer } from "./layout/layoutReducers";

import { combineReducers } from "redux";
import chartWidgetReducer from "./products/chartWidget";

export const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    layout: layoutReducer,
    products,
    watchlist,
    chartWidget: chartWidgetReducer
  });
