import { IconButton, Tooltip } from "@mui/material";
import { Workspace } from "../../../../types";
import DotsIcon from "../../../common/icons/dots-vertical";

const truncateText = (text: string, length: number) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};

const WorkspaceTab: React.FC<{
  workspace: Workspace;
  active: boolean;
  onClick: () => void;
  onMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
}> = ({ workspace, active, onClick, onMenuOpen }) => {
  const title = workspace.title || String(workspace.id || "");
  const truncatedTitle = truncateText(title, 15);

  return (
    <div className={`Tab ${active ? "active" : ""}`}>
      <button onClick={onClick} className="workspace-button">
        {workspace.isDefault && <img src="/images/icons/star.svg" alt="default" width={'12px'} height={'12px'} style={{ paddingRight: '8px' }} />}
        <Tooltip title={title} arrow>
          <span className="workspace-title">{truncatedTitle}</span>
        </Tooltip>
      </button>
      <IconButton onClick={onMenuOpen} sx={{padding: "5px", "&:focus-visible": { color: "rgb(0 0 0 / 0%)" } }}>
        <DotsIcon />
      </IconButton>
    </div>
  );
};

export default WorkspaceTab;
