import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Control, UseFormWatch } from "react-hook-form";
import { DisplayUtil } from "../../../../cqg-api/utils/DisplayUtil";
import { useCallback, useEffect, useReducer } from "react";
import { CQGConstants } from "../../../../cqg-api/constants/CQGConstants";
import * as Order2 from "../../../../cqg-api/proto/order_2";
import * as _ from "../../../../vendors/underscore-esm";
import { IFormInput } from "../types";
import { Instrument } from "../../../../cqg-api/models/Instrument";
import OrderSettingsContent from "./OrderSettingsContent";
import { OrderType } from "../../../../types";
import { State } from "../../../TradeTicketDrawer/reducerTypes";
import { createStrategyReducer } from "../../../TradeTicketDrawer/strategyReducer";

interface OrderSettingsDialogProps {
  open: boolean;
  onClose: () => void;
  control: Control<IFormInput, any>;
  selectedContract: Instrument;
  watch: UseFormWatch<IFormInput>;
  selectedInstrument: any;
  setValue: any;
}

const OrderSettingsDialog = ({
  open,
  onClose,
  control,
  selectedContract,
  watch,
  selectedInstrument,
  setValue,
}: OrderSettingsDialogProps) => {
  const orderQuantity = watch("quantity");
  const orderType = watch("orderType");
  const takeProfitValue = watch("takeProfit");
  const stopLossValue = watch("stopLoss");
  const orderSide = watch("side");
  const getProfitSign = useCallback(() => {
    return 1;
    // return orderSide === CQGConstants.getOrderSide(Order2.Order_Side.SIDE_BUY) ? 1 : -1;
  }, []);

  const getLossSign = useCallback(() => {
    return 1;
    // return orderSide === CQGConstants.getOrderSide(Order2.Order_Side.SIDE_BUY) ? -1 : 1;
  }, []);

  const orderLimitPrice = useCallback(() => {
    return parseFloat(selectedContract?.lastPrice?.toString() || "");
  }, [selectedContract?.lastPrice]);

  const getOrderPrice = useCallback(() => {
    if (orderType === OrderType.Mkt) {
      var ins = selectedContract;

      if (ins?.lastPrice) {
        return ins?.lastPrice;
      } else {
        if (orderSide === CQGConstants.getOrderSide(Order2.Order_Side.SIDE_BUY)) {
          return ins?.bestAsk;
        } else {
          return ins?.bestBid;
        }
      }
    } else if (orderType === OrderType.Lmt) {
      return orderLimitPrice();
    }
    // need to return 0
    return orderLimitPrice();
  }, [orderLimitPrice, orderSide, orderType, selectedContract]);

  const profitInitData = useCallback(() => {
    const startProfitPrice = selectedInstrument
      ? DisplayUtil.rawStepPrice(getOrderPrice(), selectedInstrument, getProfitSign() * 75)
      : 0;
    const profitData = {
      price: startProfitPrice,
      ticks: 75,
      pnl: selectedInstrument
        ? Math.round(
            selectedInstrument?.multiplier * orderQuantity * Math.abs(getOrderPrice() - startProfitPrice) * 100,
          ) / 100
        : 0,
      percent: selectedInstrument
        ? Math.round((Math.abs(getOrderPrice() - startProfitPrice) / getOrderPrice()) * 100 * 100) / 100
        : 0,
      displayPrice: parseFloat(DisplayUtil.toDisplayPrice(startProfitPrice, selectedInstrument)?.toString() || "") || 0,
    };

    return getOrderPrice() === 0 ? { price: 0, ticks: 0, pnl: 0, percent: 0, displayPrice: 0 } : profitData;
  }, [getOrderPrice, getProfitSign, orderQuantity, selectedInstrument]);

  const lossInitData = useCallback(() => {
    const startLossPrice = selectedInstrument
      ? DisplayUtil.rawStepPrice(getOrderPrice(), selectedInstrument, getLossSign() * 25)
      : 0;
    const lossData = {
      price: startLossPrice,
      ticks: 25,
      pnl: selectedInstrument
        ? Math.round(
            (selectedInstrument.multiplier || 0) * orderQuantity * Math.abs(getOrderPrice() - startLossPrice) * 100,
          ) / 100
        : 0,
      percent: selectedInstrument
        ? Math.round((Math.abs(getOrderPrice() - startLossPrice) / getOrderPrice()) * 100 * 100) / 100
        : 0,
      displayPrice: parseFloat(DisplayUtil.toDisplayPrice(startLossPrice, selectedInstrument)?.toString() || "") || 0,
    };

    return getOrderPrice() === 0 ? { price: 0, ticks: 0, pnl: 0, percent: 0, displayPrice: 0 } : lossData;
  }, [getLossSign, getOrderPrice, orderQuantity, selectedInstrument]);

  useEffect(() => {
    if (takeProfitValue && selectedInstrument) {
      profitDispatch({ type: "set_data", data: profitInitData() });
    } else {
      const profitData = {
        price: 0.0,
        ticks: 0,
        pnl: 0,
        percent: 0.0,
        displayPrice: 0,
      };
      profitDispatch({ type: "set_data", data: profitData });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstrument, takeProfitValue]);

  useEffect(() => {
    if (stopLossValue && selectedInstrument) {
      lossDispatch({ type: "set_data", data: lossInitData() });
    } else {
      const lossData = {
        price: 0.0,
        ticks: 0,
        pnl: 0,
        percent: 0.0,
        displayPrice: 0,
      };
      lossDispatch({ type: "set_data", data: lossData });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstrument, stopLossValue]);

  const updateStrategyByTicks = (strategy: State, skipFields: string[]) => {
    if (skipFields === undefined || skipFields === null) {
      skipFields = [];
    }

    let orderPrice = getOrderPrice() || 0;

    if (!skipFields.includes("price") && selectedContract) {
      strategy.price = DisplayUtil.rawStepPrice(orderPrice, selectedContract, getSign(strategy) * strategy.ticks);
    }

    if (!skipFields.includes("pnl") && orderQuantity && selectedContract && selectedContract.multiplier) {
      strategy.pnl =
        Math.round(selectedContract.multiplier * orderQuantity * Math.abs(orderPrice - strategy.price) * 100) / 100;
    }

    if (!skipFields.includes("percent") && strategy.pnl) {
      strategy.percent = Math.round((Math.abs(orderPrice - strategy.price) / orderPrice) * 100 * 100) / 100;
    }
  };
  const isProfitStrategy = (strategy: State) => {
    return strategy.hasOwnProperty("canTakeProfit");
  };

  const getSign = (strategy: State) => {
    const isProfit = isProfitStrategy(strategy);

    if (isProfit) {
      return orderSide === CQGConstants.getOrderSide(Order2.Order_Side.SIDE_BUY) ? 1 : -1;
    } else {
      return orderSide === CQGConstants.getOrderSide(Order2.Order_Side.SIDE_BUY) ? -1 : 1;
    }
  };

  const calculateChangeByField = (strategy: State, field?: string, updateByTicks?: boolean) => {
    if (strategy.enabled) {
      if (field === undefined || field === null) {
        field = "ticks";
      }

      if (updateByTicks === undefined || updateByTicks === null) {
        updateByTicks = true;
      }

      var skipFields = [field];

      switch (field) {
        case "price":
          let price = orderLimitPrice();

          if (orderType === "MKT") {
            price = getOrderPrice() || 0;
          }

          strategy.ticks = selectedInstrument
            ? DisplayUtil.priceToTicks(Math.abs(price - strategy.price), selectedInstrument, true)
            : 0;
          break;

        case "pnl":
          if (_.isNumber(getOrderPrice()) && selectedInstrument && selectedInstrument.multiplier && strategy.pnl) {
            strategy.price =
              (getOrderPrice() || 0) +
              (strategy.pnl / (orderQuantity * selectedInstrument.multiplier)) * getSign(strategy);

            // move price to nearest tick
            strategy.price = DisplayUtil.rawStepPrice(strategy.price, selectedInstrument, 0);
            strategy.displayPrice =
              parseFloat(DisplayUtil.toDisplayPrice(strategy.price, selectedInstrument)?.toString() || "") || 0;
          }

          calculateChangeByField(strategy, "price", false);
          skipFields.push("price");

          break;

        case "percent":
          let orderPrice = getOrderPrice() || 0;
          strategy.price = orderPrice + (strategy.percent / 100) * orderPrice * getSign(strategy);

          // move price to nearest tick
          if (selectedInstrument) {
            strategy.price = DisplayUtil.stepPrice(strategy.price, selectedInstrument, 0);
            strategy.displayPrice =
              parseFloat(DisplayUtil.toDisplayPrice(strategy.price, selectedInstrument)?.toString() || "") || 0;
          }

          calculateChangeByField(strategy, "price", false);
          skipFields.push("price");

          break;

        case "ticks":
          // Do nothing let the updateStrategyByTicks call handle it
          break;

        default:
          return;
      }

      if (updateByTicks) {
        updateStrategyByTicks(strategy, skipFields);
      }
    } else {
      strategy.ticks = 0;
      strategy.price = 0;
    }

    return strategy;
  };

  const strategyReducer = createStrategyReducer(calculateChangeByField, setValue, watch, selectedInstrument);
  const [profitState, profitDispatch] = useReducer(strategyReducer, {
    price: 0,
    displayPrice: 0,
    ticks: 75,
    pnl: 0,
    percent: 0,
    enabled: false,
    canTakeProfit: false,
  });

  const [lossState, lossDispatch] = useReducer(strategyReducer, {
    price: 0,
    displayPrice: 0,
    ticks: 25,
    pnl: 0,
    percent: 0,
    enabled: false,
    canStopLoss: false,
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "444px",
          padding: "14px",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle>Settings</DialogTitle>
      <OrderSettingsContent
        profitState={profitState}
        control={control}
        profitDispatch={profitDispatch}
        lossState={lossState}
        lossDispatch={lossDispatch}
      />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!takeProfitValue && !stopLossValue}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderSettingsDialog;
