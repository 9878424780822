interface ModalFooterProps {
  data: any;
  handleConfirmFlatten: () => void;
  openTradeTicket: () => void;
}

const ModalFooter = ({ data, handleConfirmFlatten, openTradeTicket }: ModalFooterProps) => {
  return (
    <div className="flex w-full justify-between p-2 ActionsButtons">
      <button onClick={openTradeTicket} className="mobile-action-button cancel-all-orders">TRADE</button>
      <button
        onClick={handleConfirmFlatten}
        className={`mobile-action-button cancel-all-orders ${data.direction === "" ? "disabled" : "active"}`}
        disabled={data.direction === ""}
      >
        FLATTEN
      </button>
    </div>
  );
};

export default ModalFooter;
