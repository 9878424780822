import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { TextField, MenuItem, Switch, Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import "./Trade.css";
import { Tabs, Tab } from "@mui/material";
import PlusIcon from "./PlusIcon";
import MinusIcon from "./MinusIcon";
import ConfirmTradeDialog from "./ConfirmTradeDialog";
import SelectedContractInfo from "./SelectedContractInfo";
import { CQGEnvironment } from "../../cqg-api/services/CQGEnvironment";
import { OrderState } from "../../cqg-api/models/OrderState";
import { makeOrder } from "../../cqg-api/controllers/tradingTicketController";
import { Instrument } from "../../cqg-api/models/Instrument";
import { useDispatch, useSelector } from "react-redux";

import { DisplayUtil } from "../../cqg-api/utils/DisplayUtil";
import * as Order2 from "../../../src/cqg-api/proto/order_2";
import * as _ from "../../vendors/underscore-esm";
import { CQGConstants } from "../../cqg-api/constants/CQGConstants";
import Notification from "../shared/notification/Notification";
import { subscribeToContracts } from "../../utils/subscriptions";
import { useRealTimeMarketData } from "../../cqg-api/hooks/ServiceHooks";
import { mapInstrumentData } from "../../utils/utils";
import workspacesSelectors from "../../redux/workspaces/workspacesSelector";
import workspacesActions from "../../redux/workspaces/workspacesActions";
import { disableResize, tradeTicketHeader } from "./utils";
import SimulatorOptions from "./OptionProcessor";
import { createStrategyReducer } from "./strategyReducer";
import { State } from "./reducerTypes";
import PutCallSelector from "./TradeComponent/PutCallSelector";
import {
  defaultBorderColor,
  gridItemStyles,
  inputStyles,
  redErrorColor,
  StyledButton,
  StyledFormControl,
  StyledSelect,
  tabStyles,
  toggleButtonStyles,
} from "./styles";
import SideController from "./components/SideController";
import { IFormInput } from "./types";
import useStrikePrice from "./useStrikePrice";
import { AvailableProductsContext } from "../../App";
// Define the form input types
type FuturesFormProps = {
  contracts?: Instrument[];
  workspaceId: number;
};
const placeOrder = (order: OrderState) => {
  CQGEnvironment.Instance.cqgService.placeOrder(order);
};

const FuturesForm: React.FC<FuturesFormProps> = (props) => {
  const dispatch = useDispatch();
  const [showConfirmTradeDialog, setShowConfirmTradeDialog] = useState(false);
  const defaultContract = useSelector(workspacesSelectors.getWorkspaces)[props.workspaceId]?.ticketInstrument;
  const { monthlyContractsByDisplayNameMap } = useContext(AvailableProductsContext);
  const contracts = useMemo(() => {
    const contract: any = monthlyContractsByDisplayNameMap[defaultContract?.displayName];
    return contract?.monthlyContracts ?? [];
  }, [defaultContract?.displayName, monthlyContractsByDisplayNameMap]);
  const { controller } = useStrikePrice(defaultContract);
  const findOrderType = (types: any, cqgOrderType: any) => {
    return _.find(types, function (ot: any) {
      return ot.value == cqgOrderType;
    });
  };

  const orderTypes = useMemo(() => {
    const types: { value: number; name: string }[] = [];
    _.map(Order2.Order_OrderType, function (value: number) {
      if (_.isNumber(value) && value !== -1) {
        if (value !== Order2.Order_OrderType.ORDER_TYPE_CROSS) {
          types.push({
            value: value,
            name: CQGConstants.getOrderType(value),
          });
        }
      }
    });
    return types;
  }, []);

  const getOrderType = (cqgOrderType: number) => {
    return findOrderType(orderTypes, cqgOrderType);
  };

  // Initialize the form with default values
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      contract: defaultContract?.displayName,
      side: undefined,
      orderType: "LMT",
      timeInForce: 1,
      quantity: 1,
      limitPrice: defaultContract?.lastPrice ?? 0,
      stopPrice: defaultContract?.lastPrice ?? 0,
      takeProfit: false,
      stopLoss: false,
      profitTicks: 1,
      profitPrice: 0,
      profitPercentage: 0,
      profit: 0,
      lossTicks: 1,
      lossPrice: 0,
      lossPercentage: 0,
      loss: 0,
      rawLimitPrice: defaultContract?.lastPrice,
      rawStopPrice: defaultContract?.lastPrice,
      putCall: undefined,
      strikePrice: "",
    },
  });
  const contractValue = watch("contract");
  const limitPrice = watch("limitPrice");
  const [activeTab, setActiveTab] = useState("futures");
  const [createOrderState, setCreateOrderState] = useState<OrderState>();
  const selectedInstrument = useMemo(
    () => contracts?.find((contract: Instrument) => contract.displayName === contractValue),
    [contracts, contractValue],
  );
  const [isEnabledLmtPrice, setEnabledLmtPrice] = useState<boolean>(true);
  const [isEnabledStpPrice, setEnabledStpPrice] = useState<boolean>(true);
  const [selectedStrike, setSelectedStrike] = useState<number | null>(null);
  const [hasLoadError, setHasLoadError] = useState(false);

  useEffect(() => {
    reset({
      side: undefined,
      contract: contractValue,
    });
  }, [activeTab]);

  useEffect(() => {
    setValue("contract", defaultContract?.displayName);
  }, [defaultContract, setValue]);

  const { realTimeMarketData } = useRealTimeMarketData();
  const [selectedContract, setSelectedContract] = useState<Instrument>(selectedInstrument);

  useEffect(() => {
    if (selectedInstrument && activeTab === "futures") {
      subscribeToContracts([selectedInstrument.contractId] as number[]);
      setSelectedContract(selectedInstrument);
      setValue("limitPrice", 0);
    }
    // return () => {
    //   unsubscribeInstuments(watchlistProducts);
    // };
  }, [selectedInstrument, setValue]);

  useEffect(() => {
    if (selectedInstrument) {
      profitDispatch({ type: "set_data", data: profitInitData() });

      lossDispatch({ type: "set_data", data: lossInitData() });
    }
  }, [selectedInstrument]);

  useEffect(() => {
    if (selectedContract && activeTab === "options") {
      setSelectedContract(mapInstrumentData(selectedContract, realTimeMarketData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setActiveTab(newValue);
    if (newValue === "options") {
      setSelectedContract(null);
    } else if (newValue === "futures") {
      setSelectedContract(null);
      setSelectedStrike(null);
    }
    reset({
      limitPrice: 0,
      stopPrice: 0,
      rawLimitPrice: 0,
      rawStopPrice: 0,
    });
  };
  const createOrder = () => {
    try {
      setShowConfirmTradeDialog(false);
      placeOrder(createOrderState as OrderState);
      dispatch(workspacesActions.dispatchSetWorkspace({ workspaceId: props.workspaceId, instrument: null }));
    } catch (e) {
      Notification.error(`Order ${createOrderState?.displayContract} could not be placed`);
    }
  };

  const orderQuantity = watch("quantity");
  const orderSide = watch("side");
  const orderType = watch("orderType");
  const rawLimitPrice = watch("rawLimitPrice");
  const rawStopPrice = watch("rawStopPrice");
  const putCallValue = watch("putCall");
  const takeProfit = watch("takeProfit");
  const stopLoss = watch("stopLoss");

  useEffect(() => {
    validateAndCalculateProfitLossStrategy();
  }, [orderQuantity, orderType, orderSide]);

  useEffect(() => {
    validateAndCalculateProfitLossStrategy();
  }, [limitPrice]);

  useEffect(() => {
    if (takeProfit) {
      setTicksAndCalculateStrategy(profitState, 75);
    }
  }, [takeProfit]);

  useEffect(() => {
    if (stopLoss) {
      setTicksAndCalculateStrategy(lossState, 25);
    }
  }, [stopLoss]);

  const isValidPrice = () => {
    if (
      orderType &&
      orderType === CQGConstants.getOrderType(Order2.Order_OrderType.ORDER_TYPE_LMT) &&
      !_.isNumber(orderLimitPrice())
    ) {
      return false;
    }

    if (
      orderType &&
      orderType === CQGConstants.getOrderType(Order2.Order_OrderType.ORDER_TYPE_STP) &&
      !_.isNumber(orderStopPrice())
    ) {
      return false;
    }

    if (
      orderType &&
      orderType === CQGConstants.getOrderType(Order2.Order_OrderType.ORDER_TYPE_STL) &&
      (!_.isNumber(orderStopPrice()) || !_.isNumber(orderLimitPrice()))
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    setValue(
      "limitPrice",
      parseFloat(DisplayUtil.toDisplayPrice(Number(selectedContract?.lastPrice), selectedContract)?.toString() || "") ||
        0,
    );
    setValue(
      "stopPrice",
      parseFloat(DisplayUtil.toDisplayPrice(Number(selectedContract?.lastPrice), selectedContract)?.toString() || "") ||
        0,
    );
    setValue("rawLimitPrice", selectedContract?.lastPrice || 0);
    setValue("rawStopPrice", selectedContract?.lastPrice || 0);
    setValue("timeInForce", 1);
  }, [orderType]);

  useEffect(() => {
    switch (orderType) {
      case "STL": {
        setEnabledLmtPrice(true);
        setEnabledStpPrice(true);
        setValue("takeProfit", false);
        setValue("stopLoss", false);
        break;
      }
      case "STP": {
        setEnabledLmtPrice(false);
        setEnabledStpPrice(true);
        setValue("takeProfit", false);
        setValue("stopLoss", false);
        break;
      }
      case "MKT": {
        setEnabledLmtPrice(false);
        setEnabledStpPrice(false);
        break;
      }
      case "LMT": {
        setEnabledLmtPrice(true);
        setEnabledStpPrice(false);
        break;
      }
    }
  }, [orderType]);

  const isValidQuantity = useCallback(() => {
    if (!orderQuantity || orderQuantity <= 0 || orderQuantity % 1 != 0) {
      return false;
    }

    return true;
  }, [orderQuantity]);

  const isValidOrderSide = useCallback(() => {
    if (!orderSide) {
      return false;
    }

    return true;
  }, [orderSide]);

  const isAlowedProfitLossOrderType = useCallback(() => {
    if (
      orderType === CQGConstants.getOrderType(Order2.Order_OrderType.ORDER_TYPE_STL) ||
      orderType === CQGConstants.getOrderType(Order2.Order_OrderType.ORDER_TYPE_STP)
    ) {
      return false;
    }

    return true;
  }, [orderType]);

  const validateAndCalculateProfitLossStrategy = () => {
    profitState.canTakeProfit = lossState.canStopLoss =
      isValidOrderSide() && isValidPrice() && isValidQuantity() && isAlowedProfitLossOrderType();

    if (profitState.canTakeProfit) {
      profitDispatch({ type: "canTakeProfit", payload: 1 });
      lossDispatch({ type: "canStopLoss", payload: 1 });
    } else {
      profitDispatch({ type: "canTakeProfit", payload: 0 });
      lossDispatch({ type: "canStopLoss", payload: 0 });
      setValue("takeProfit", false);
      setValue("stopLoss", false);
    }

    const profit = calculateChangeByField(profitState);
    const loss = calculateChangeByField(lossState);

    setValue("profitPrice", Number(profit?.displayPrice));
    setValue("lossPrice", Number(loss?.displayPrice));
    setValue("profit", Number(profit?.pnl));
    setValue("loss", Number(loss?.pnl));
    setValue("profitPercentage", Number(profit?.percent));
    setValue("lossPercentage", Number(loss?.percent));
  };

  const isProfitStrategy = (strategy: State) => {
    return strategy.hasOwnProperty("canTakeProfit");
  };

  const getSign = (strategy: State) => {
    const isProfit = isProfitStrategy(strategy);

    if (isProfit) {
      return orderSide === CQGConstants.getOrderSide(Order2.Order_Side.SIDE_BUY) ? 1 : -1;
    } else {
      return orderSide === CQGConstants.getOrderSide(Order2.Order_Side.SIDE_BUY) ? -1 : 1;
    }
  };

  const getProfitSign = useCallback(() => {
    return orderSide === CQGConstants.getOrderSide(Order2.Order_Side.SIDE_BUY) ? 1 : -1;
  }, [orderSide]);

  const getLossSign = useCallback(() => {
    return orderSide === CQGConstants.getOrderSide(Order2.Order_Side.SIDE_BUY) ? -1 : 1;
  }, [orderSide]);

  const updateStrategyByTicks = (strategy: State, skipFields?: string[]) => {
    if (skipFields === undefined || skipFields === null) {
      skipFields = [];
    }

    let orderPrice = getOrderPrice() || 0;

    if (!skipFields.includes("price") && selectedInstrument) {
      strategy.price = DisplayUtil.rawStepPrice(orderPrice, selectedInstrument, getSign(strategy) * strategy.ticks);
      strategy.displayPrice =
        parseFloat(DisplayUtil.toDisplayPrice(strategy.price, selectedInstrument)?.toString() || "") || 0;
    }

    if (!skipFields.includes("pnl") && orderQuantity && selectedInstrument && selectedInstrument.multiplier) {
      strategy.pnl =
        Math.round(selectedInstrument.multiplier * orderQuantity * Math.abs(orderPrice - strategy.price) * 100) / 100;
    }

    if (!skipFields.includes("percent") && strategy.pnl) {
      strategy.percent = Math.round((Math.abs(orderPrice - strategy.price) / orderPrice) * 100 * 100) / 100;
    }
  };

  const calculateChangeByField = (strategy: State, field?: string, updateByTicks?: boolean) => {
    if (strategy.enabled) {
      if (field === undefined || field === null) {
        field = "ticks";
      }

      if (updateByTicks === undefined || updateByTicks === null) {
        updateByTicks = true;
      }

      var skipFields = [field];

      switch (field) {
        case "price":
          let price = orderLimitPrice();

          if (orderType === "MKT") {
            price = getOrderPrice() || 0;
          }

          strategy.ticks = selectedInstrument
            ? DisplayUtil.priceToTicks(Math.abs(price - strategy.price), selectedInstrument, true)
            : 0;
          break;

        case "pnl":
          if (
            _.isNumber(getOrderPrice()) &&
            selectedInstrument &&
            selectedInstrument.multiplier &&
            _.isNumber(strategy.pnl)
          ) {
            strategy.price =
              (getOrderPrice() || 0) +
              (strategy.pnl / (orderQuantity * selectedInstrument.multiplier)) * getSign(strategy);

            // move price to nearest tick
            strategy.price = DisplayUtil.rawStepPrice(strategy.price, selectedInstrument, 0);
            strategy.displayPrice =
              parseFloat(DisplayUtil.toDisplayPrice(strategy.price, selectedInstrument)?.toString() || "") || 0;
          }

          calculateChangeByField(strategy, "price", false);
          skipFields.push("price");

          break;

        case "percent":
          let orderPrice = getOrderPrice() || 0;
          strategy.price = orderPrice + (strategy.percent / 100) * orderPrice * getSign(strategy);

          // move price to nearest tick
          if (selectedInstrument) {
            strategy.price = DisplayUtil.stepPrice(strategy.price, selectedInstrument, 0);
            strategy.displayPrice =
              parseFloat(DisplayUtil.toDisplayPrice(strategy.price, selectedInstrument)?.toString() || "") || 0;
          }

          calculateChangeByField(strategy, "price", false);
          skipFields.push("price");

          break;

        case "ticks":
          // Do nothing let the updateStrategyByTicks call handle it
          break;

        default:
          return;
      }

      if (updateByTicks) {
        updateStrategyByTicks(strategy, skipFields);
      }
    } else {
      strategy.ticks = 0;
      strategy.price = 0;
    }

    return strategy;
  };

  const orderLimitPrice = () => {
    return rawLimitPrice;
  };

  const orderStopPrice = () => {
    return rawStopPrice;
  };

  const getOrderPrice = () => {
    if (orderType === "MKT") {
      var ins = selectedInstrument;

      if (ins?.lastPrice) {
        return ins?.lastPrice;
      } else {
        if (orderSide === CQGConstants.getOrderSide(Order2.Order_Side.SIDE_BUY)) {
          return ins?.bestAsk;
        } else {
          return ins?.bestBid;
        }
      }
    } else if (orderType === "LMT") {
      return orderLimitPrice();
    }

    return null;
  };

  const setTicksAndCalculateStrategy = (strategy: State, ticks: number) => {
    strategy.ticks = ticks;
    updateStrategyByTicks(strategy);
  };

  const profitInitData = () => {
    const startProfitPrice = selectedInstrument
      ? DisplayUtil.rawStepPrice(getOrderPrice(), selectedInstrument, getProfitSign() * 75)
      : 0;
    const profitData = {
      price: startProfitPrice,
      ticks: 75,
      pnl: selectedInstrument
        ? Math.round(
            selectedInstrument?.multiplier * orderQuantity * Math.abs(getOrderPrice() - startProfitPrice) * 100,
          ) / 100
        : 0,
      percent: selectedInstrument
        ? Math.round((Math.abs(getOrderPrice() - startProfitPrice) / getOrderPrice()) * 100 * 100) / 100
        : 0,
      displayPrice: parseFloat(DisplayUtil.toDisplayPrice(startProfitPrice, selectedInstrument)?.toString() || "") || 0,
    };

    return profitData;
  };

  const lossInitData = () => {
    const startLossPrice = selectedInstrument
      ? DisplayUtil.rawStepPrice(getOrderPrice(), selectedInstrument, getLossSign() * 25)
      : 0;
    const lossData = {
      price: startLossPrice,
      ticks: 25,
      pnl: selectedInstrument
        ? Math.round(
            (selectedInstrument.multiplier || 0) * orderQuantity * Math.abs(getOrderPrice() - startLossPrice) * 100,
          ) / 100
        : 0,
      percent: selectedInstrument
        ? Math.round((Math.abs(getOrderPrice() - startLossPrice) / getOrderPrice()) * 100 * 100) / 100
        : 0,
      displayPrice: parseFloat(DisplayUtil.toDisplayPrice(startLossPrice, selectedInstrument)?.toString() || "") || 0,
    };

    return lossData;
  };

  const strategyReducer = createStrategyReducer(calculateChangeByField, setValue, watch, selectedInstrument);

  const [profitState, profitDispatch] = useReducer(strategyReducer, {
    price: 0,
    displayPrice: 0,
    ticks: 75,
    pnl: 0,
    percent: 0,
    enabled: false,
    canTakeProfit: false,
  });

  const [lossState, lossDispatch] = useReducer(strategyReducer, {
    price: 0,
    displayPrice: 0,
    ticks: 25,
    pnl: 0,
    percent: 0,
    enabled: false,
    canStopLoss: false,
  });

  // Handle form submission
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const accounts = CQGEnvironment.Instance.accountsManager.getAccount(
      CQGEnvironment.cqgAccountAuthInfo?.accountId as number,
    );
    const profitPrice = (profitState.price / selectedInstrument?.correctPriceScale).toFixed();
    const lossPrice = (lossState.price / selectedInstrument?.correctPriceScale).toFixed();

    const orderState: OrderState = {
      account: accounts, // Replace with actual account value
      instrument: activeTab === "options" ? selectedContract : selectedInstrument, // Replace with actual instrument value
      when: new Date(),
      clientOrderId: "defaultClientOrderId", // Replace with actual client order ID
      size: data.quantity,
      putCall: data.putCall,
      strikePrice: selectedStrike,
      ...data,
      profitLossStrategy: {
        profit: { price: profitState.enabled ? profitPrice : false },
        loss: { price: lossState.enabled ? lossPrice : false },
      },
    };
    setShowConfirmTradeDialog(true);
    const newOrder = makeOrder(orderState);
    setCreateOrderState(newOrder);
  };
  const onlyNumbersRules = {
    pattern: {
      value: /^[0-9]*$/,
      message: "Please enter a valid number",
    },
  };
  const numbersWithDecimalRules = {
    pattern: { value: /^[0-9]*\.?[0-9]*$/, message: "Please enter a valid number" },
  };
  const insertNumberInput = (e: React.FormEvent<HTMLInputElement>, decimal?: boolean) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    const isValid = decimal ? /^[0-9]*\.?[0-9]*$/.test(value) : /^[0-9]*$/.test(value);
    if (!isValid) {
      target.value = value.slice(0, value.length - 1);
    }
  };

  useEffect(() => {
    if (selectedInstrument && limitPrice === 0 && activeTab === "futures") {
      setValue(
        "limitPrice",
        parseFloat(
          DisplayUtil.toDisplayPrice(Number(selectedInstrument?.lastPrice), selectedInstrument)?.toString() || "",
        ) || 0,
      );
      setValue(
        "stopPrice",
        parseFloat(
          DisplayUtil.toDisplayPrice(Number(selectedInstrument?.lastPrice), selectedInstrument)?.toString() || "",
        ) || 0,
      );
      setValue("rawLimitPrice", selectedInstrument?.lastPrice || 0);
      setValue("rawStopPrice", selectedInstrument?.lastPrice || 0);
    }
  }, [activeTab, limitPrice, selectedInstrument, setValue]);

  useEffect(() => {
    if (putCallValue) {
      setValue("limitPrice", 0);
      setValue("stopPrice", 0);
      setValue("rawLimitPrice", 0);
      setValue("rawStopPrice", 0);
    }
  }, [putCallValue, setValue, selectedStrike, contractValue, activeTab]);

  useEffect(() => {
    if (selectedStrike && selectedContract && limitPrice === 0 && activeTab === "options") {
      setValue(
        "limitPrice",
        parseFloat(
          DisplayUtil.toDisplayPrice(Number(selectedContract?.lastPrice), selectedContract)?.toString() || "",
        ) || 0,
      );
      setValue(
        "stopPrice",
        parseFloat(
          DisplayUtil.toDisplayPrice(Number(selectedContract?.lastPrice), selectedContract)?.toString() || "",
        ) || 0,
      );
      setValue("rawLimitPrice", selectedContract?.lastPrice || 0);
      setValue("rawStopPrice", selectedContract?.lastPrice || 0);
    }
  }, [activeTab, limitPrice, selectedStrike, selectedContract, setValue, contractValue]);
  useEffect(() => {
    const target = document.querySelectorAll(".tabs-and-actions-container");
    console.log("target", target[target.length - 1]);
    const container = target[target.length - 1];
    disableResize(container);
    const rightActions = target[target.length - 1].querySelector(".right-actions-container");
    const tab = target[target.length - 1].querySelector(".tab.active-tab");
    const tabContent = target[target.length - 1].querySelector(".dv-default-tab-content");
    const tabContentArrow = target[target.length - 1].querySelector(".dv-default-tab-action");
    rightActions.style.display = "none";
    tabContent.style.display = "none";
    tab.style.position = "absolute";
    tab.style.right = 0;
    tab.style.background = "none";
    tab.style.border = "none";
    tabContentArrow.style.right = "8px";
    tabContentArrow.style.position = "absolute";
    tab?.addEventListener("click", (e) => {
      e.preventDefault();
    });
    // tabContentArrow.style.top = '9px';
    // tabContentArrow.style.fontSize = '14px';
    tab.style.height = "35px";
    container.style.borderBottom = "1px solid #ccc !important";
    // tab.style.width = '100%';
    // tab.style.height = '35px';
    const heading = container.querySelector("h4");
    if (!heading) {
      const imageContainer = tradeTicketHeader();
      container.appendChild(imageContainer);
    }
  }, []);

  const handleStrikeChange = useCallback(
    (strikeDetails: { strike: number | null; callOption: any; putOption: any }) => {
      const { callOption, putOption, strike } = strikeDetails;

      if (putCallValue === "P" && putOption) {
        setSelectedStrike(strike);
        setSelectedContract(putOption);
      } else if (putCallValue === "C" && callOption) {
        setSelectedStrike(strike);
        setSelectedContract(callOption);
      } else {
        setSelectedStrike(strike);
      }
    },
    [putCallValue],
  );
  const handleOptionChange = useCallback(
    (option: "P" | "C") => {
      setValue("putCall", option);
      const relevantOption = option === "P" ? selectedInstrument?.putOptions : selectedInstrument?.callOptions;
      handleStrikeChange({
        strike: selectedStrike,
        putOption: option === "P" ? relevantOption : null,
        callOption: option === "C" ? relevantOption : null,
      });
    },
    [handleStrikeChange, selectedInstrument?.callOptions, selectedInstrument?.putOptions, selectedStrike, setValue],
  );
  const hasOptions = useMemo(() => {
    return (
      controller?.loadStatus === "This product does not have an options contract" ||
      controller?.strikeOptions?.length === 0
    );
  }, [controller]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ padding: "10px", maxWidth: "500px", color: "#222", overflowX: "hidden" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledFormControl fullWidth>
            <Controller
              name="contract"
              control={control}
              render={({ field }) => (
                <StyledSelect {...field}>
                  {contracts?.map((contract) => (
                    <MenuItem key={contract.displayName} value={contract.displayName}>
                      {contract.displayName}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: "0 !important" }}>
          <SelectedContractInfo selectedContract={selectedContract} activeTab={activeTab} putCallValue={putCallValue} />
        </Grid>
        <Grid item xs={12}>
          {Object.keys(errors).length > 0 && (
            <Alert severity="error">
              {Object.values(errors).map((error: any) => (
                <div key={error?.message}>{error?.message}</div>
              ))}
            </Alert>
          )}
        </Grid>
        <Tabs sx={tabStyles} value={activeTab} onChange={handleTabChange} className="tabs">
          <Tab label="Futures" value="futures" />
          {/* {hasOptions ? <Tab label="Options" value="options" /> : <OptionsTab />} */}
          <Tab label="Options" value="options" />
        </Tabs>
        {/* Side (BUY/SELL) */}
        <Grid item xs={6} sx={gridItemStyles}>
          <SideController control={control} side={errors.side} setValue={setValue} />
        </Grid>

        {/* Quantity */}
        <Grid item xs={6} sx={gridItemStyles}>
          <label className="label">Quantity (1-10)</label>

          <div
            className="control"
            style={{
              borderColor: errors.quantity ? redErrorColor : defaultBorderColor,
            }}
          >
            <Controller
              name="quantity"
              control={control}
              rules={{
                required: "Quantity is required",
                min: { value: 1, message: "Minimum quantity is 1" },
              }}
              render={({ field }) => (
                <button
                  type="button"
                  className="minusButton"
                  onClick={() => {
                    const currentValue = field.value - 1;
                    if (currentValue >= 1) {
                      setValue(field.name, currentValue);
                    }
                    if (isNaN(currentValue)) {
                      setValue(field.name, 1);
                    }
                  }}
                >
                  <MinusIcon />
                </button>
              )}
            />
            <Controller
              name="quantity"
              rules={onlyNumbersRules}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  onInput={(e) => {
                    const val = e.target.value;
                    if (val <= 10 && val >= 1) {
                      insertNumberInput(e);
                    } else {
                      const filteredVal = val.slice(0, val.length - 1);
                      e.target.value = filteredVal;
                    }
                  }}
                  variant="outlined"
                  sx={inputStyles}
                />
              )}
            />
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <button
                  type="button"
                  className="minusButton"
                  onClick={() => {
                    const currentValue = parseInt(field.value) + 1;
                    if (currentValue <= 10) {
                      setValue(field.name, currentValue);
                    }
                    if (isNaN(currentValue)) {
                      setValue(field.name, 1);
                    }
                  }}
                >
                  <PlusIcon />
                </button>
              )}
            />
          </div>
        </Grid>

        {/* Side (BUY/SELL) */}
        <Grid item xs={6}>
          <StyledFormControl fullWidth>
            <label className="label">Time-in-force</label>
            <Controller
              name="timeInForce"
              control={control}
              render={({ field }) => (
                <div className="timeInForce">
                  <button
                    type="button"
                    onClick={() => setValue(field.name, 1)}
                    className={`timeInDay ${field.value === 1 && "active"}`}
                    value="DAY"
                  >
                    DAY
                  </button>
                  <button
                    type="button"
                    onClick={() => setValue(field.name, 2)}
                    className={`timeInDay ${field.value === 2 && "active"}`}
                    value="GTC"
                  >
                    GTC
                  </button>
                </div>
              )}
            />
          </StyledFormControl>
        </Grid>

        <Grid item xs={6}>
          <label className="label">Order Type</label>
          <StyledFormControl fullWidth>
            <Controller
              name="orderType"
              control={control}
              render={({ field }) => (
                <StyledSelect {...field}>
                  <MenuItem value="MKT">Market</MenuItem>
                  <MenuItem value="LMT">Limit</MenuItem>
                  {activeTab === "futures" && <MenuItem value="STP">Stop</MenuItem>}
                  {activeTab === "futures" && <MenuItem value="STL">Stop Limit</MenuItem>}
                </StyledSelect>
              )}
            />
          </StyledFormControl>
        </Grid>
        <Grid item xs={6} sx={gridItemStyles}>
          <label className="label">Limit Price</label>
          <div className="control" style={{ borderColor: errors.limitPrice ? redErrorColor : defaultBorderColor }}>
            <Controller
              name="limitPrice"
              control={control}
              rules={{
                required: ["STL", "LMT"].includes(watch("orderType")) ? "Limit Price is required" : false,
              }}
              render={({ field }) => (
                <button
                  type="button"
                  className="minusButton"
                  disabled={!isEnabledLmtPrice}
                  onClick={() => {
                    const instrumentOrContract = activeTab === "options" ? selectedContract : selectedInstrument;
                    const lprice = DisplayUtil.rawStepPrice(Number(rawLimitPrice), instrumentOrContract, -1);
                    const limitPrice =
                      parseFloat(DisplayUtil.toDisplayPrice(lprice, instrumentOrContract)?.toString() || "") || 0;

                    if (limitPrice >= 0) {
                      setValue("rawLimitPrice", lprice);
                      setValue(field.name, limitPrice);
                    }
                  }}
                >
                  <MinusIcon />
                </button>
              )}
            />
            <Controller
              name="limitPrice"
              control={control}
              rules={{
                validate: (value) => {
                  const isMktOrder = watch("orderType") === "MKT";
                  return (isMktOrder && value === 0) || value > 0 || "Limit price is invalid";
                },
                pattern: {
                  value: /^[0-9]*\.?[0-9]*$/,
                  message: "Please enter a valid number",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={activeTab === "options" && watch("orderType") === "MKT" ? "" : field.value}
                  onInput={(e) => {
                    insertNumberInput(e, true);
                    if (e.target.value === "") {
                      setValue("rawLimitPrice", e.target.value);
                    } else {
                      setValue("rawLimitPrice", Number(e.target.value));
                    }
                  }}
                  disabled={!isEnabledLmtPrice}
                  fullWidth
                  variant="outlined"
                  sx={inputStyles}
                />
              )}
            />
            <Controller
              name="limitPrice"
              control={control}
              render={({ field }) => (
                <button
                  type="button"
                  className="minusButton"
                  disabled={!isEnabledLmtPrice}
                  onClick={() => {
                    const instrumentOrContract = activeTab === "options" ? selectedContract : selectedInstrument;
                    const lprice = DisplayUtil.rawStepPrice(Number(rawLimitPrice), instrumentOrContract, 1);
                    const limitPrice =
                      parseFloat(DisplayUtil.toDisplayPrice(lprice, instrumentOrContract)?.toString() || "") || 0;
                    setValue("rawLimitPrice", lprice);
                    setValue(field.name, limitPrice);
                  }}
                >
                  <PlusIcon />
                </button>
              )}
            />
          </div>
        </Grid>
        {/* Stop Price */}
        <Grid item xs={6} sx={gridItemStyles} style={{ marginBottom: "8px" }}>
          <label className="label">Stop Price</label>
          <div className="control" style={{ borderColor: errors.stopPrice ? redErrorColor : defaultBorderColor }}>
            <Controller
              name="stopPrice"
              control={control}
              rules={{
                required: ["STP", "STL"].includes(watch("orderType")) ? "Stop Price is required" : false,
                pattern: { value: /^[0-9]*\.?[0-9]*$/, message: "Please enter a valid number" },
              }}
              render={({ field }) => (
                <button
                  type="button"
                  className="minusButton"
                  disabled={!isEnabledStpPrice}
                  onClick={() => {
                    const sPrice = DisplayUtil.rawStepPrice(Number(rawStopPrice), selectedInstrument, -1);
                    const stopPrice =
                      parseFloat(DisplayUtil.toDisplayPrice(sPrice, selectedInstrument)?.toString() || "") || 0;

                    if (stopPrice >= 0) {
                      setValue("rawStopPrice", sPrice);
                      setValue(field.name, stopPrice);
                    }
                  }}
                >
                  <MinusIcon />
                </button>
              )}
            />
            <Controller
              name="stopPrice"
              control={control}
              rules={numbersWithDecimalRules}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  value={activeTab === "options" ? "" : field.value}
                  disabled={!isEnabledStpPrice}
                  onInput={(e) => {
                    insertNumberInput(e, true);
                    setValue("rawStopPrice", Number(e.target.value));
                  }}
                  variant="outlined"
                  sx={inputStyles}
                />
              )}
            />
            <Controller
              name="stopPrice"
              control={control}
              render={({ field }) => (
                <button
                  type="button"
                  className="minusButton"
                  disabled={!isEnabledStpPrice}
                  onClick={() => {
                    const sPrice = DisplayUtil.rawStepPrice(Number(rawStopPrice), selectedInstrument, 1);
                    const stopPrice =
                      parseFloat(DisplayUtil.toDisplayPrice(sPrice, selectedInstrument)?.toString() || "") || 0;

                    if (stopPrice >= 0) {
                      setValue("rawStopPrice", sPrice);
                      setValue(field.name, stopPrice);
                    }
                  }}
                >
                  <PlusIcon />
                </button>
              )}
            />
          </div>
        </Grid>
        {/* Take Profit */}
        {activeTab === "futures" && (
          <>
            <div className="dividerTrade"></div>
            <Grid item xs={12} sx={toggleButtonStyles}>
              <label>Take Profit</label>
              <Controller
                name="takeProfit"
                control={control}
                disabled={!profitState.canTakeProfit}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    sx={{
                      marginRight: "-12px",
                    }}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      profitDispatch({ type: "enabled", payload: e.target.checked ? 1 : 0 });
                      if (!!e.target.checked) {
                        profitDispatch({ type: "set_data", data: profitInitData() });
                      }
                    }}
                  />
                )}
              />
            </Grid>
            {watch("takeProfit") && (
              <>
                <div className="dividerTradeTrade"></div>
                <Grid item xs={6} sx={{ padding: 0 }}>
                  <label className="label">Ticks</label>
                  <div className="control">
                    <Controller
                      name="profitTicks"
                      control={control}
                      render={({ field }) => (
                        <button
                          type="button"
                          className="minusButton"
                          onClick={() => {
                            const currentValue = profitState.ticks - 1;
                            if (currentValue >= 0) {
                              setValue(field.name, currentValue);
                              profitDispatch({ type: "ticks", payload: currentValue });
                            }
                          }}
                        >
                          <MinusIcon />
                        </button>
                      )}
                    />
                    <Controller
                      name="profitTicks"
                      control={control}
                      rules={onlyNumbersRules}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          fullWidth
                          value={field.value || profitState.ticks}
                          onChange={(e) => {
                            insertNumberInput(e);
                            setValue(field.name, Number(e.target.value));
                            profitDispatch({ type: "ticks", payload: Number(e.target.value) });
                          }}
                          variant="outlined"
                          sx={inputStyles}
                        />
                      )}
                    />
                    <Controller
                      name="profitTicks"
                      control={control}
                      render={({ field }) => (
                        <button
                          type="button"
                          className="minusButton"
                          onClick={() => {
                            const currentValue = profitState.ticks + 1;
                            setValue(field.name, currentValue);
                            profitDispatch({ type: "ticks", payload: currentValue });
                          }}
                        >
                          <PlusIcon />
                        </button>
                      )}
                    />
                  </div>
                </Grid>

                {/* Profit Price */}
                <Grid item xs={6}>
                  <label className="label">Price</label>
                  <div className="control">
                    <Controller
                      name="profitPrice"
                      control={control}
                      render={({ field }) => (
                        <button
                          className="minusButton"
                          type="button"
                          onClick={() => {
                            const price = DisplayUtil.rawStepPrice(profitState.price, selectedInstrument, -1);
                            const displayPrice = DisplayUtil.toDisplayPrice(price, selectedInstrument);

                            if (displayPrice && displayPrice >= 0) {
                              setValue(field.name, Number(displayPrice));
                              profitDispatch({ type: "price", prices: { price: price, displayPrice: displayPrice } });
                            }
                          }}
                        >
                          <MinusIcon />
                        </button>
                      )}
                    />
                    <Controller
                      name="profitPrice"
                      control={control}
                      rules={numbersWithDecimalRules}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          value={field.value || profitState.displayPrice}
                          onChange={(e) => {
                            insertNumberInput(e, true);
                            setValue(field.name, e.target.value);
                            profitDispatch({ type: "price", payload: Number(e.target.value), isProfitPrice: true });
                          }}
                          fullWidth
                          variant="outlined"
                          sx={inputStyles}
                        />
                      )}
                    />
                    <Controller
                      name="profitPrice"
                      control={control}
                      render={({ field }) => (
                        <button
                          className="minusButton"
                          type="button"
                          onClick={() => {
                            const price = DisplayUtil.rawStepPrice(profitState.price, selectedInstrument, 1);
                            const displayPrice = DisplayUtil.toDisplayPrice(price, selectedInstrument);
                            setValue(field.name, Number(displayPrice));
                            profitDispatch({
                              type: "price",
                              prices: { price: price, displayPrice: Number(displayPrice) },
                            });
                          }}
                        >
                          <PlusIcon />
                        </button>
                      )}
                    />
                  </div>
                </Grid>

                {/* Profit Price */}
                <Grid item xs={6}>
                  <label className="label">Profit $</label>
                  <div className="control">
                    <Controller
                      name="profit"
                      control={control}
                      render={({ field }) => (
                        <button
                          className="minusButton"
                          type="button"
                          onClick={() => {
                            const currentValue = Number((profitState.pnl - 0.01).toFixed(2));
                            if (currentValue >= 0) {
                              setValue(field.name, currentValue);
                              profitDispatch({ type: "pnl", payload: currentValue });
                            }
                          }}
                        >
                          <MinusIcon />
                        </button>
                      )}
                    />
                    <Controller
                      name="profit"
                      control={control}
                      rules={numbersWithDecimalRules}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value?.toString() || profitState.pnl}
                          onChange={(e) => {
                            insertNumberInput(e, true);
                            setValue(field.name, e.target.value);
                            profitDispatch({ type: "pnl", payload: Number(e.target.value), isProfit: true });
                          }}
                          onBlur={(e) => {
                            insertNumberInput(e, true);
                            profitDispatch({ type: "pnl", payload: Number(e.target.value) });
                          }}
                          fullWidth
                          variant="outlined"
                          sx={inputStyles}
                        />
                      )}
                    />
                    <Controller
                      name="profit"
                      control={control}
                      render={({ field }) => (
                        <button
                          className="minusButton"
                          type="button"
                          onClick={() => {
                            const currentValue = Number((profitState.pnl + 0.01).toFixed(2));
                            setValue(field.name, currentValue);
                            profitDispatch({ type: "pnl", payload: currentValue });
                          }}
                        >
                          <PlusIcon />
                        </button>
                      )}
                    />
                  </div>
                </Grid>
                {/* Ticks (Take Profit) */}
                <Grid item xs={6}>
                  <label className="label">Percentage %</label>
                  <div className="control">
                    <Controller
                      name="profitPercentage"
                      control={control}
                      render={({ field }) => (
                        <button
                          type="button"
                          className="minusButton"
                          onClick={() => {
                            const currentValue = Number((profitState.percent - 0.01).toFixed(2));
                            if (currentValue >= 0) {
                              setValue(field.name, currentValue);
                              profitDispatch({ type: "percent", payload: currentValue });
                            }
                          }}
                        >
                          <MinusIcon />
                        </button>
                      )}
                    />
                    <Controller
                      name="profitPercentage"
                      control={control}
                      rules={numbersWithDecimalRules}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          value={field.value || profitState.percent}
                          onChange={(e) => {
                            insertNumberInput(e, true);
                            setValue(field.name, e.target.value);
                            profitDispatch({ type: "percent", payload: Number(e.target.value), isProfitPercent: true });
                          }}
                          fullWidth
                          variant="outlined"
                          sx={inputStyles}
                        />
                      )}
                    />
                    <Controller
                      name="profitPercentage"
                      control={control}
                      render={({ field }) => (
                        <button
                          type="button"
                          className="minusButton"
                          onClick={() => {
                            const currentValue = Number((profitState.percent + 0.01).toFixed(2));
                            setValue(field.name, currentValue);
                            profitDispatch({ type: "percent", payload: currentValue });
                          }}
                        >
                          <PlusIcon />
                        </button>
                      )}
                    />
                  </div>
                </Grid>
              </>
            )}

            {/* Stop Loss */}

            <div className="dividerTrade"></div>
            <Grid item xs={12} sx={toggleButtonStyles}>
              <label>Stop Loss</label>
              <Controller
                name="stopLoss"
                control={control}
                disabled={!lossState.canStopLoss}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    sx={{
                      marginRight: "-12px",
                    }}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      lossDispatch({ type: "enabled", payload: e.target.checked ? 1 : 0 });
                      if (!!e.target.checked) {
                        lossDispatch({ type: "set_data", data: lossInitData() });
                      }
                    }}
                  />
                )}
              />
            </Grid>
            {/* Ticks (Take Profit) */}
            {watch("stopLoss") && (
              <>
                <Grid item xs={6}>
                  <label className="label">Ticks</label>
                  <div className="control">
                    <Controller
                      name="lossTicks"
                      control={control}
                      render={({ field }) => (
                        <button
                          type="button"
                          className="minusButton"
                          onClick={() => {
                            const currentValue = lossState.ticks - 1;
                            if (currentValue >= 0) {
                              setValue(field.name, currentValue);
                              lossDispatch({ type: "ticks", payload: currentValue });
                            }
                          }}
                        >
                          <MinusIcon />
                        </button>
                      )}
                    />
                    <Controller
                      name="lossTicks"
                      control={control}
                      rules={onlyNumbersRules}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          type="number"
                          value={field.value || lossState.ticks}
                          onChange={(e) => {
                            insertNumberInput(e);
                            setValue(field.name, Number(e.target.value));
                            lossDispatch({ type: "ticks", payload: Number(e.target.value) });
                          }}
                          variant="outlined"
                          sx={inputStyles}
                        />
                      )}
                    />
                    <Controller
                      name="lossTicks"
                      control={control}
                      render={({ field }) => (
                        <button
                          type="button"
                          className="minusButton"
                          onClick={() => {
                            const currentValue = lossState.ticks + 1;
                            setValue(field.name, currentValue);
                            lossDispatch({ type: "ticks", payload: currentValue });
                          }}
                        >
                          <PlusIcon />
                        </button>
                      )}
                    />
                  </div>
                </Grid>

                {/* Profit Price */}
                <Grid item xs={6}>
                  <label className="label">Price</label>
                  <div className="control">
                    <Controller
                      name="lossPrice"
                      control={control}
                      render={({ field }) => (
                        <button
                          className="minusButton"
                          type="button"
                          onClick={() => {
                            const price = DisplayUtil.rawStepPrice(lossState.price, selectedInstrument, -1);
                            const displayPrice = DisplayUtil.toDisplayPrice(price, selectedInstrument);

                            if (displayPrice && displayPrice >= 0) {
                              setValue(field.name, Number(displayPrice));
                              lossDispatch({
                                type: "price",
                                prices: { price: price, displayPrice: Number(displayPrice) },
                              });
                            }
                          }}
                        >
                          <MinusIcon />
                        </button>
                      )}
                    />
                    <Controller
                      name="lossPrice"
                      control={control}
                      rules={numbersWithDecimalRules}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          value={field.value ? (field.value < 0 ? "" : field.value) : lossState.displayPrice}
                          onChange={(e) => {
                            insertNumberInput(e, true);
                            setValue(field.name, e.target.value);
                            lossDispatch({ type: "price", payload: Number(e.target.value), isLossPrice: true });
                          }}
                          fullWidth
                          variant="outlined"
                          sx={inputStyles}
                        />
                      )}
                    />
                    <Controller
                      name="lossPrice"
                      control={control}
                      render={({ field }) => (
                        <button
                          className="minusButton"
                          type="button"
                          onClick={() => {
                            const price = DisplayUtil.rawStepPrice(lossState.price, selectedInstrument, 1);
                            const displayPrice = DisplayUtil.toDisplayPrice(price, selectedInstrument);
                            setValue(field.name, Number(displayPrice));
                            lossDispatch({
                              type: "price",
                              prices: { price: price, displayPrice: Number(displayPrice) },
                            });
                          }}
                        >
                          <PlusIcon />
                        </button>
                      )}
                    />
                  </div>
                </Grid>

                {/* Profit Price */}
                <Grid item xs={6}>
                  <label className="label">Loss $</label>
                  <div className="control">
                    <Controller
                      name="loss"
                      control={control}
                      render={({ field }) => (
                        <button
                          className="minusButton"
                          type="button"
                          onClick={() => {
                            const currentValue = Number((lossState.pnl - 0.01).toFixed(2));
                            if (currentValue >= 0) {
                              setValue(field.name, currentValue);
                              lossDispatch({ type: "pnl", payload: currentValue });
                            }
                          }}
                        >
                          <MinusIcon />
                        </button>
                      )}
                    />
                    <Controller
                      name="loss"
                      control={control}
                      rules={numbersWithDecimalRules}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          value={field.value || lossState.pnl}
                          onChange={(e) => {
                            insertNumberInput(e, true);
                            setValue(field.name, e.target.value);
                            lossDispatch({ type: "pnl", payload: Number(e.target.value), isLoss: true });
                          }}
                          fullWidth
                          variant="outlined"
                          sx={inputStyles}
                        />
                      )}
                    />
                    <Controller
                      name="loss"
                      control={control}
                      render={({ field }) => (
                        <button
                          className="minusButton"
                          type="button"
                          onClick={() => {
                            const currentValue = Number((lossState.pnl + 0.01).toFixed(2));
                            setValue(field.name, currentValue);
                            lossDispatch({ type: "pnl", payload: currentValue });
                          }}
                        >
                          <PlusIcon />
                        </button>
                      )}
                    />
                  </div>
                </Grid>
                {/* Ticks (Take Profit) */}
                <Grid item xs={6}>
                  <label className="label">Percentage %</label>
                  <div className="control">
                    <Controller
                      name="lossPercentage"
                      control={control}
                      render={({ field }) => (
                        <button
                          type="button"
                          className="minusButton"
                          onClick={() => {
                            const currentValue = Number((lossState.percent - 0.01).toFixed(2));
                            if (currentValue >= 0) {
                              setValue(field.name, currentValue);
                              lossDispatch({ type: "percent", payload: currentValue });
                            }
                          }}
                        >
                          <MinusIcon />
                        </button>
                      )}
                    />
                    <Controller
                      name="lossPercentage"
                      control={control}
                      rules={numbersWithDecimalRules}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          value={field.value || lossState.percent}
                          onChange={(e) => {
                            insertNumberInput(e, true);
                            setValue(field.name, e.target.value);
                            lossDispatch({ type: "percent", payload: Number(e.target.value), isLossPercent: true });
                          }}
                          fullWidth
                          variant="outlined"
                          sx={inputStyles}
                        />
                      )}
                    />
                    <Controller
                      name="lossPercentage"
                      control={control}
                      render={({ field }) => (
                        <button
                          type="button"
                          className="minusButton"
                          onClick={() => {
                            const currentValue = Number((lossState.percent + 0.01).toFixed(2));
                            setValue(field.name, currentValue);
                            lossDispatch({ type: "percent", payload: currentValue });
                          }}
                        >
                          <PlusIcon />
                        </button>
                      )}
                    />
                  </div>
                </Grid>
              </>
            )}
            <div className="dividerTrade"></div>
          </>
        )}
        {activeTab === "options" && (
          <>
            <Grid item xs={6}>
              <StyledFormControl fullWidth>
                <label className="label">Put Call</label>
                <PutCallSelector
                  control={control}
                  putCallValue={putCallValue}
                  onOptionChange={handleOptionChange}
                  disabled={hasLoadError}
                />
              </StyledFormControl>
            </Grid>
            <Grid item xs={6}>
              <label className="label">Strike Price</label>
              <SimulatorOptions
                instrument={selectedInstrument}
                putCall={putCallValue}
                selectedStrike={selectedStrike}
                onStrikeChange={handleStrikeChange}
                onError={(hasError) => setHasLoadError(hasError)}
              />
            </Grid>
          </>
        )}

        {/* Submit Button */}
        <Grid item xs={12} sx={gridItemStyles}>
          <StyledButton fullWidth type="submit" orderSide={orderSide}>
            {orderSide ? orderSide.toUpperCase() : "SUBMIT"}
          </StyledButton>
        </Grid>
      </Grid>
      <ConfirmTradeDialog
        open={showConfirmTradeDialog}
        onClose={() => setShowConfirmTradeDialog(false)}
        cancel={() => setShowConfirmTradeDialog(false)}
        confirm={createOrder}
        trade={{
          side: watch("side"),
          quantity: watch("quantity"),
          contract: selectedStrike ? selectedContract?.displayName : watch("contract"),
          orderType: watch("orderType"),
          limitPrice: watch("limitPrice"),
          stopPrice: watch("stopPrice"),
          takeProfit: watch("takeProfit"),
          stopLoss: watch("stopLoss"),
          profit: profitState,
          loss: lossState,
          contractSize: selectedContract?.contractSize,
          tickSize: selectedContract?.tickSize,
          lastPrice:
            activeTab === "options"
              ? watch("limitPrice")
              : DisplayUtil.toDisplayPrice(selectedInstrument?.lastPrice, selectedContract),
          tickValue: selectedContract?.tickValue,
          marginCommited: selectedContract?.marginInitialRate,
          tif: watch("timeInForce") === 1 ? "Day" : "GTC",
          putCall: watch("putCall"),
          strikePrice: selectedStrike ? selectedContract?.strikePrice : "",
          instrument: selectedInstrument,
        }}
      />
    </form>
  );
};

export default FuturesForm;
