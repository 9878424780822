import { Control, Controller, FieldError, UseFormSetValue } from "react-hook-form";
import { StyledFormControl } from "../styles";
import SideButton from "./BuyButton";
import { IFormInput } from "../types";

type SideControllerProps = {
  control: Control<IFormInput>;
  side?: FieldError;
  setValue: UseFormSetValue<IFormInput>;
};
const SideController = ({ control, side, setValue }: SideControllerProps) => {
  return (

    <StyledFormControl fullWidth>
    <label className="label">Side</label>
    <Controller
      name="side"
      control={control}
      rules={{
        required: 'Side is required',
      }}
      render={({ field }) => (
        <>
        <div className="instrumentContainer">
          <SideButton
            side={side}
            value={field.value}
            onClick={() => setValue(field.name, "BUY")}
            cssClass="instrumentAction-buy"
            text="BUY"
          />
          <SideButton
            side={side}
            value={field.value}
            onClick={() => setValue(field.name, "SELL")}
            cssClass="instrumentAction-sell"
            text="SELL"
          />
        </div>
        </>
      )}
    />
  </StyledFormControl>
  )
};

export default SideController;
