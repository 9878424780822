// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v3.6.1
// source: order_1.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "order_1";

/** CMS message for BatchOrdersOperation */
export interface BatchOrdersOperation {
  /** [required] Main criteria to search orders for cancel. */
  batchOrdersOperationCriteria?:
    | BatchOrdersOperationCriteria
    | undefined;
  /** [required] Secondary criteria to search orders for cancel. */
  batchOrdersOperationSecondaryCriteria?: BatchOrdersOperationSecondaryCriteria | undefined;
}

/**
 * CMS message for BatchOrdersOperationCriteria.
 * Set of criteria to search orders for cancel.
 * Either brokerage_ids or profile_ids with sales series profiles is required.
 */
export interface BatchOrdersOperationCriteria {
  /**
   * ID of Brokerage to limit scope by accounts of this Brokerage.
   * [required] for cqg/brokerage scope.
   */
  brokerageIds: string[];
  /**
   * ID of different profile types to limit scope by these profiles.
   * Supported profile types: admin, customer, sales series.
   * [required] for sales series scope.
   */
  profileIds: string[];
  /** ID of Account to limit scope by this Account. */
  accountIds: string[];
  /**
   * Login identifier to limit scope by orders, initiated by this login.
   * Supported login domains: trade-routing.
   */
  loginIds: string[];
  /** ID of RouteGroup to limit scope of orders by this RouteGroup. */
  routeGroupIds: string[];
  /** ID of gateway route to limit scope by this Route. */
  routeCodes: string[];
  /** ID of Contract to limit scope of orders by this Contract. */
  contractIds: string[];
  /** ID of MiFID CQG Algorithm to cancel orders initiated by this Algorithm. */
  internalAlgorithmsIds: string[];
  /** ID of MiFID External Algorithm to cancel orders initiated by this Algorithm. */
  externalAlgorithmsIds: string[];
}

/** Set of order criteria to search orders for cancel. */
export interface BatchOrdersOperationSecondaryCriteria {
  /** Specifies whether only order(s) expiring during the current/nearest session should be considered. */
  currentDayOnly?:
    | boolean
    | undefined;
  /** Specifies whether only externally reported orders that were created via dedicated reporting session (not a regular CQG session) should be considered. */
  inactiveExchangeReportedOrdersOnly?:
    | boolean
    | undefined;
  /** Specifies state of orders to be cancelled. True - cancel only parked orders, False - cancel working and parked orders. */
  parkedOnly?:
    | boolean
    | undefined;
  /**
   * Specifies side of orders to cancel.
   * This field is associated with OrderChain.Side enum type.
   * If this field wasn't provided it's interpreted as 'Both' option.
   */
  side?:
    | number
    | undefined;
  /**
   * [required] Order operation type that needs to be performed.
   * This field is associated with OrderOperationType enum.
   */
  orderOperationType?: number | undefined;
}

/** Order operation type enum. */
export enum BatchOrdersOperationSecondaryCriteria_OrderOperationType {
  /** CANCEL - Send cancel request. */
  CANCEL = 1,
  /** REFLECT_AS_CANCELED - Reflect order as canceled without sending real cancel request to exchange. */
  REFLECT_AS_CANCELED = 2,
  UNRECOGNIZED = -1,
}

export function batchOrdersOperationSecondaryCriteria_OrderOperationTypeFromJSON(
  object: any,
): BatchOrdersOperationSecondaryCriteria_OrderOperationType {
  switch (object) {
    case 1:
    case "CANCEL":
      return BatchOrdersOperationSecondaryCriteria_OrderOperationType.CANCEL;
    case 2:
    case "REFLECT_AS_CANCELED":
      return BatchOrdersOperationSecondaryCriteria_OrderOperationType.REFLECT_AS_CANCELED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BatchOrdersOperationSecondaryCriteria_OrderOperationType.UNRECOGNIZED;
  }
}

export function batchOrdersOperationSecondaryCriteria_OrderOperationTypeToJSON(
  object: BatchOrdersOperationSecondaryCriteria_OrderOperationType,
): string {
  switch (object) {
    case BatchOrdersOperationSecondaryCriteria_OrderOperationType.CANCEL:
      return "CANCEL";
    case BatchOrdersOperationSecondaryCriteria_OrderOperationType.REFLECT_AS_CANCELED:
      return "REFLECT_AS_CANCELED";
    case BatchOrdersOperationSecondaryCriteria_OrderOperationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface BatchOrdersOperationResult {
  /** Operation unique identifier, by which all cancel requests can be found then. */
  batchRequestGuid?:
    | string
    | undefined;
  /** In case of successful operation contains result of each risk server instance. */
  riskInstancesResults: BatchOrdersOperationActionResult[];
}

/** The result of the batch orders operation for a specific risk server instance. */
export interface BatchOrdersOperationActionResult {
  /**
   * Risk server instance identifier.
   * LookupPropertyListRequest { property_type = traderouting_1.TradeRoutingLookupPropertyType.RISK_SERVER_INSTANCE }
   */
  riskServerInstanceId?:
    | string
    | undefined;
  /** Determines count of processed orders on current risk instance. */
  processedOrdersCount?:
    | number
    | undefined;
  /** In case of failure action contains error message. */
  resultDescription?: string | undefined;
}

function createBaseBatchOrdersOperation(): BatchOrdersOperation {
  return { batchOrdersOperationCriteria: undefined, batchOrdersOperationSecondaryCriteria: undefined };
}

export const BatchOrdersOperation = {
  encode(message: BatchOrdersOperation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.batchOrdersOperationCriteria !== undefined) {
      BatchOrdersOperationCriteria.encode(message.batchOrdersOperationCriteria, writer.uint32(10).fork()).ldelim();
    }
    if (message.batchOrdersOperationSecondaryCriteria !== undefined) {
      BatchOrdersOperationSecondaryCriteria.encode(
        message.batchOrdersOperationSecondaryCriteria,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BatchOrdersOperation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchOrdersOperation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.batchOrdersOperationCriteria = BatchOrdersOperationCriteria.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.batchOrdersOperationSecondaryCriteria = BatchOrdersOperationSecondaryCriteria.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchOrdersOperation {
    return {
      batchOrdersOperationCriteria: isSet(object.batchOrdersOperationCriteria)
        ? BatchOrdersOperationCriteria.fromJSON(object.batchOrdersOperationCriteria)
        : undefined,
      batchOrdersOperationSecondaryCriteria: isSet(object.batchOrdersOperationSecondaryCriteria)
        ? BatchOrdersOperationSecondaryCriteria.fromJSON(object.batchOrdersOperationSecondaryCriteria)
        : undefined,
    };
  },

  toJSON(message: BatchOrdersOperation): unknown {
    const obj: any = {};
    if (message.batchOrdersOperationCriteria !== undefined) {
      obj.batchOrdersOperationCriteria = BatchOrdersOperationCriteria.toJSON(message.batchOrdersOperationCriteria);
    }
    if (message.batchOrdersOperationSecondaryCriteria !== undefined) {
      obj.batchOrdersOperationSecondaryCriteria = BatchOrdersOperationSecondaryCriteria.toJSON(
        message.batchOrdersOperationSecondaryCriteria,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BatchOrdersOperation>, I>>(base?: I): BatchOrdersOperation {
    return BatchOrdersOperation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BatchOrdersOperation>, I>>(object: I): BatchOrdersOperation {
    const message = createBaseBatchOrdersOperation();
    message.batchOrdersOperationCriteria =
      (object.batchOrdersOperationCriteria !== undefined && object.batchOrdersOperationCriteria !== null)
        ? BatchOrdersOperationCriteria.fromPartial(object.batchOrdersOperationCriteria)
        : undefined;
    message.batchOrdersOperationSecondaryCriteria =
      (object.batchOrdersOperationSecondaryCriteria !== undefined &&
          object.batchOrdersOperationSecondaryCriteria !== null)
        ? BatchOrdersOperationSecondaryCriteria.fromPartial(object.batchOrdersOperationSecondaryCriteria)
        : undefined;
    return message;
  },
};

function createBaseBatchOrdersOperationCriteria(): BatchOrdersOperationCriteria {
  return {
    brokerageIds: [],
    profileIds: [],
    accountIds: [],
    loginIds: [],
    routeGroupIds: [],
    routeCodes: [],
    contractIds: [],
    internalAlgorithmsIds: [],
    externalAlgorithmsIds: [],
  };
}

export const BatchOrdersOperationCriteria = {
  encode(message: BatchOrdersOperationCriteria, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.brokerageIds) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.profileIds) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.accountIds) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.loginIds) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.routeGroupIds) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.routeCodes) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.contractIds) {
      writer.uint32(58).string(v!);
    }
    for (const v of message.internalAlgorithmsIds) {
      writer.uint32(66).string(v!);
    }
    for (const v of message.externalAlgorithmsIds) {
      writer.uint32(74).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BatchOrdersOperationCriteria {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchOrdersOperationCriteria();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brokerageIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.profileIds.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountIds.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.loginIds.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.routeGroupIds.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.routeCodes.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.contractIds.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.internalAlgorithmsIds.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.externalAlgorithmsIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchOrdersOperationCriteria {
    return {
      brokerageIds: globalThis.Array.isArray(object?.brokerageIds)
        ? object.brokerageIds.map((e: any) => globalThis.String(e))
        : [],
      profileIds: globalThis.Array.isArray(object?.profileIds)
        ? object.profileIds.map((e: any) => globalThis.String(e))
        : [],
      accountIds: globalThis.Array.isArray(object?.accountIds)
        ? object.accountIds.map((e: any) => globalThis.String(e))
        : [],
      loginIds: globalThis.Array.isArray(object?.loginIds) ? object.loginIds.map((e: any) => globalThis.String(e)) : [],
      routeGroupIds: globalThis.Array.isArray(object?.routeGroupIds)
        ? object.routeGroupIds.map((e: any) => globalThis.String(e))
        : [],
      routeCodes: globalThis.Array.isArray(object?.routeCodes)
        ? object.routeCodes.map((e: any) => globalThis.String(e))
        : [],
      contractIds: globalThis.Array.isArray(object?.contractIds)
        ? object.contractIds.map((e: any) => globalThis.String(e))
        : [],
      internalAlgorithmsIds: globalThis.Array.isArray(object?.internalAlgorithmsIds)
        ? object.internalAlgorithmsIds.map((e: any) => globalThis.String(e))
        : [],
      externalAlgorithmsIds: globalThis.Array.isArray(object?.externalAlgorithmsIds)
        ? object.externalAlgorithmsIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: BatchOrdersOperationCriteria): unknown {
    const obj: any = {};
    if (message.brokerageIds?.length) {
      obj.brokerageIds = message.brokerageIds;
    }
    if (message.profileIds?.length) {
      obj.profileIds = message.profileIds;
    }
    if (message.accountIds?.length) {
      obj.accountIds = message.accountIds;
    }
    if (message.loginIds?.length) {
      obj.loginIds = message.loginIds;
    }
    if (message.routeGroupIds?.length) {
      obj.routeGroupIds = message.routeGroupIds;
    }
    if (message.routeCodes?.length) {
      obj.routeCodes = message.routeCodes;
    }
    if (message.contractIds?.length) {
      obj.contractIds = message.contractIds;
    }
    if (message.internalAlgorithmsIds?.length) {
      obj.internalAlgorithmsIds = message.internalAlgorithmsIds;
    }
    if (message.externalAlgorithmsIds?.length) {
      obj.externalAlgorithmsIds = message.externalAlgorithmsIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BatchOrdersOperationCriteria>, I>>(base?: I): BatchOrdersOperationCriteria {
    return BatchOrdersOperationCriteria.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BatchOrdersOperationCriteria>, I>>(object: I): BatchOrdersOperationCriteria {
    const message = createBaseBatchOrdersOperationCriteria();
    message.brokerageIds = object.brokerageIds?.map((e) => e) || [];
    message.profileIds = object.profileIds?.map((e) => e) || [];
    message.accountIds = object.accountIds?.map((e) => e) || [];
    message.loginIds = object.loginIds?.map((e) => e) || [];
    message.routeGroupIds = object.routeGroupIds?.map((e) => e) || [];
    message.routeCodes = object.routeCodes?.map((e) => e) || [];
    message.contractIds = object.contractIds?.map((e) => e) || [];
    message.internalAlgorithmsIds = object.internalAlgorithmsIds?.map((e) => e) || [];
    message.externalAlgorithmsIds = object.externalAlgorithmsIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchOrdersOperationSecondaryCriteria(): BatchOrdersOperationSecondaryCriteria {
  return {
    currentDayOnly: false,
    inactiveExchangeReportedOrdersOnly: false,
    parkedOnly: false,
    side: 0,
    orderOperationType: 0,
  };
}

export const BatchOrdersOperationSecondaryCriteria = {
  encode(message: BatchOrdersOperationSecondaryCriteria, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currentDayOnly !== undefined && message.currentDayOnly !== false) {
      writer.uint32(8).bool(message.currentDayOnly);
    }
    if (
      message.inactiveExchangeReportedOrdersOnly !== undefined && message.inactiveExchangeReportedOrdersOnly !== false
    ) {
      writer.uint32(16).bool(message.inactiveExchangeReportedOrdersOnly);
    }
    if (message.parkedOnly !== undefined && message.parkedOnly !== false) {
      writer.uint32(24).bool(message.parkedOnly);
    }
    if (message.side !== undefined && message.side !== 0) {
      writer.uint32(32).uint32(message.side);
    }
    if (message.orderOperationType !== undefined && message.orderOperationType !== 0) {
      writer.uint32(40).uint32(message.orderOperationType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BatchOrdersOperationSecondaryCriteria {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchOrdersOperationSecondaryCriteria();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.currentDayOnly = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.inactiveExchangeReportedOrdersOnly = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.parkedOnly = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.side = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.orderOperationType = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchOrdersOperationSecondaryCriteria {
    return {
      currentDayOnly: isSet(object.currentDayOnly) ? globalThis.Boolean(object.currentDayOnly) : false,
      inactiveExchangeReportedOrdersOnly: isSet(object.inactiveExchangeReportedOrdersOnly)
        ? globalThis.Boolean(object.inactiveExchangeReportedOrdersOnly)
        : false,
      parkedOnly: isSet(object.parkedOnly) ? globalThis.Boolean(object.parkedOnly) : false,
      side: isSet(object.side) ? globalThis.Number(object.side) : 0,
      orderOperationType: isSet(object.orderOperationType) ? globalThis.Number(object.orderOperationType) : 0,
    };
  },

  toJSON(message: BatchOrdersOperationSecondaryCriteria): unknown {
    const obj: any = {};
    if (message.currentDayOnly !== undefined && message.currentDayOnly !== false) {
      obj.currentDayOnly = message.currentDayOnly;
    }
    if (
      message.inactiveExchangeReportedOrdersOnly !== undefined && message.inactiveExchangeReportedOrdersOnly !== false
    ) {
      obj.inactiveExchangeReportedOrdersOnly = message.inactiveExchangeReportedOrdersOnly;
    }
    if (message.parkedOnly !== undefined && message.parkedOnly !== false) {
      obj.parkedOnly = message.parkedOnly;
    }
    if (message.side !== undefined && message.side !== 0) {
      obj.side = Math.round(message.side);
    }
    if (message.orderOperationType !== undefined && message.orderOperationType !== 0) {
      obj.orderOperationType = Math.round(message.orderOperationType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BatchOrdersOperationSecondaryCriteria>, I>>(
    base?: I,
  ): BatchOrdersOperationSecondaryCriteria {
    return BatchOrdersOperationSecondaryCriteria.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BatchOrdersOperationSecondaryCriteria>, I>>(
    object: I,
  ): BatchOrdersOperationSecondaryCriteria {
    const message = createBaseBatchOrdersOperationSecondaryCriteria();
    message.currentDayOnly = object.currentDayOnly ?? false;
    message.inactiveExchangeReportedOrdersOnly = object.inactiveExchangeReportedOrdersOnly ?? false;
    message.parkedOnly = object.parkedOnly ?? false;
    message.side = object.side ?? 0;
    message.orderOperationType = object.orderOperationType ?? 0;
    return message;
  },
};

function createBaseBatchOrdersOperationResult(): BatchOrdersOperationResult {
  return { batchRequestGuid: "", riskInstancesResults: [] };
}

export const BatchOrdersOperationResult = {
  encode(message: BatchOrdersOperationResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.batchRequestGuid !== undefined && message.batchRequestGuid !== "") {
      writer.uint32(10).string(message.batchRequestGuid);
    }
    for (const v of message.riskInstancesResults) {
      BatchOrdersOperationActionResult.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BatchOrdersOperationResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchOrdersOperationResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.batchRequestGuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.riskInstancesResults.push(BatchOrdersOperationActionResult.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchOrdersOperationResult {
    return {
      batchRequestGuid: isSet(object.batchRequestGuid) ? globalThis.String(object.batchRequestGuid) : "",
      riskInstancesResults: globalThis.Array.isArray(object?.riskInstancesResults)
        ? object.riskInstancesResults.map((e: any) => BatchOrdersOperationActionResult.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BatchOrdersOperationResult): unknown {
    const obj: any = {};
    if (message.batchRequestGuid !== undefined && message.batchRequestGuid !== "") {
      obj.batchRequestGuid = message.batchRequestGuid;
    }
    if (message.riskInstancesResults?.length) {
      obj.riskInstancesResults = message.riskInstancesResults.map((e) => BatchOrdersOperationActionResult.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BatchOrdersOperationResult>, I>>(base?: I): BatchOrdersOperationResult {
    return BatchOrdersOperationResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BatchOrdersOperationResult>, I>>(object: I): BatchOrdersOperationResult {
    const message = createBaseBatchOrdersOperationResult();
    message.batchRequestGuid = object.batchRequestGuid ?? "";
    message.riskInstancesResults =
      object.riskInstancesResults?.map((e) => BatchOrdersOperationActionResult.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchOrdersOperationActionResult(): BatchOrdersOperationActionResult {
  return { riskServerInstanceId: "", processedOrdersCount: 0, resultDescription: "" };
}

export const BatchOrdersOperationActionResult = {
  encode(message: BatchOrdersOperationActionResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.riskServerInstanceId !== undefined && message.riskServerInstanceId !== "") {
      writer.uint32(10).string(message.riskServerInstanceId);
    }
    if (message.processedOrdersCount !== undefined && message.processedOrdersCount !== 0) {
      writer.uint32(16).uint32(message.processedOrdersCount);
    }
    if (message.resultDescription !== undefined && message.resultDescription !== "") {
      writer.uint32(26).string(message.resultDescription);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BatchOrdersOperationActionResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchOrdersOperationActionResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.riskServerInstanceId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.processedOrdersCount = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.resultDescription = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchOrdersOperationActionResult {
    return {
      riskServerInstanceId: isSet(object.riskServerInstanceId) ? globalThis.String(object.riskServerInstanceId) : "",
      processedOrdersCount: isSet(object.processedOrdersCount) ? globalThis.Number(object.processedOrdersCount) : 0,
      resultDescription: isSet(object.resultDescription) ? globalThis.String(object.resultDescription) : "",
    };
  },

  toJSON(message: BatchOrdersOperationActionResult): unknown {
    const obj: any = {};
    if (message.riskServerInstanceId !== undefined && message.riskServerInstanceId !== "") {
      obj.riskServerInstanceId = message.riskServerInstanceId;
    }
    if (message.processedOrdersCount !== undefined && message.processedOrdersCount !== 0) {
      obj.processedOrdersCount = Math.round(message.processedOrdersCount);
    }
    if (message.resultDescription !== undefined && message.resultDescription !== "") {
      obj.resultDescription = message.resultDescription;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BatchOrdersOperationActionResult>, I>>(
    base?: I,
  ): BatchOrdersOperationActionResult {
    return BatchOrdersOperationActionResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BatchOrdersOperationActionResult>, I>>(
    object: I,
  ): BatchOrdersOperationActionResult {
    const message = createBaseBatchOrdersOperationActionResult();
    message.riskServerInstanceId = object.riskServerInstanceId ?? "";
    message.processedOrdersCount = object.processedOrdersCount ?? 0;
    message.resultDescription = object.resultDescription ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
