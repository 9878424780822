import React, { FC } from "react";
import "./App.css";
import { configureStore, getHistory } from "./redux/store";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import RoutesComponent from "./routes/RoutesComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AvailableProductsProvider } from "./AvailableProductsProvider";
import { LicenseInfo } from '@mui/x-license';

const KEY = "edb92bdabfff65d18cc7cdf76277734eTz0xMDcyMTAsRT0xNzcwMjQ5NTk5MDAwLFM9cHJvLExNPXBlcnBldHVhbCxQVj1RMy0yMDI0LEtWPTI="

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_DATA_GRID_PRO_KEY ?? KEY);


const store = configureStore();

const App: FC = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <AvailableProductsProvider>
          <AppWithRoute />
        </AvailableProductsProvider>
      </Provider>
    </div>
  );
};

const AppWithRoute: FC = () => {
  return (
    <>
      <ToastContainer theme="colored" autoClose={5000} style={{ fontSize: "15px", zIndex: 10000, top: '50px' }} />
      <ConnectedRouter history={getHistory()}>
        <RoutesComponent />
      </ConnectedRouter>
    </>
  );
};

export default App;