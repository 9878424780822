import { useRef, useEffect, useCallback } from "react";

const useScrollHandlers = () => {
  const centerDivRef = useRef<HTMLDivElement | null>(null);
  const priceLadderBookRef = useRef<HTMLDivElement | null>(null);
  const LIST_ITEM_HEIGHT = 30;

  // Helper function to scroll the target to center
  const scrollToCenter = useCallback(() => {
    if (centerDivRef.current) {
      centerDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, []);

  // Automatically scroll to center on mount or ref change
  useEffect(() => {
    scrollToCenter();
  }, [scrollToCenter]);

  const scrollUpHandler = useCallback(() => {
    if (priceLadderBookRef.current) {
      priceLadderBookRef.current.scrollTop -= LIST_ITEM_HEIGHT;
    }
  }, []);

  const scrollDownHandler = useCallback(() => {
    if (priceLadderBookRef.current) {
      priceLadderBookRef.current.scrollTop += LIST_ITEM_HEIGHT;
    }
  }, []);

  // Reusable handler for triggering the scroll to center
  const scrollToCenterHandler = useCallback(() => {
    scrollToCenter();
  }, [scrollToCenter]);

  return { centerDivRef, priceLadderBookRef, scrollUpHandler, scrollDownHandler, scrollToCenterHandler };
};

export default useScrollHandlers;
