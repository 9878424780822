import "./productsNav.css";
import MenuItem from "../Menutem";
import { widgets } from "../../../workspace/mockData";
import { Widget } from "../../../../types";

const ProductList = ({ onWidgetAdd }: ProductsNavProps) => {
  const addWidgetHandler = (widget: Widget) => {
    onWidgetAdd(widget);
  };

  return (
    <ul>
      {widgets.map((item, index) => {
        return (
          <li key={index} style={{ cursor: "pointer" }} onClick={() => addWidgetHandler(item)}>
            <MenuItem text={item.title} icon={item.icon} />
          </li>
        );
      })}
    </ul>
  );
};

type ProductsNavProps = { onWidgetAdd: (widget: Widget) => void };
const ProductsNav = ({ onWidgetAdd }: ProductsNavProps) => (
  <div className="productsNav">
    <h3 className="heading">ADD WIDGETS</h3>
    <ProductList onWidgetAdd={onWidgetAdd} />
  </div>
);

export default ProductsNav;
