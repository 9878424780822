import { Chip } from "@mui/material";
import { Contract } from "../../types";
import { ContractChipStyles } from "../../marketStyles";
import { convertSymbolToYear } from "../../../../tree-view/utils/convertSymbolToDate";

interface ContractChipProps {
  contract: Contract[];
  selectedContract: Contract | null;
  handleContractSelect: (selected: Contract) => void;
}

const ContractChip = ({ contract, selectedContract, handleContractSelect }: ContractChipProps) => {
  const getContractName = (contract: Contract) => {
    return `${contract?.displayName} - ${convertSymbolToYear(contract?.displayName, contract?.month)}`;
  };
  return (
    <div className="contract-chip-container">
      {contract?.map((contractItem) => {
        const isSelected = selectedContract?.contractId === contractItem?.contractId;
        return (
          <Chip
            key={contractItem?.contractId}
            label={getContractName(contractItem)}
            onClick={() => handleContractSelect(contractItem)}
            sx={{
              ...ContractChipStyles,
              backgroundColor: isSelected ? "#006EB6" : "#081D3714",
              color: isSelected ? "white" : "#081D37DE",
              borderColor: isSelected ? "#006EB6" : "transparent",
              "&:hover": {
                backgroundColor: isSelected ? "#006EB6" : "#081D3714",
              },
            }}
          />
        );
      })}
    </div>
  );
};

export default ContractChip;
