import { useForm } from "react-hook-form";
import { useFetchMyTradePlan, useUpdateTradePlan } from "../../../../hooks/api/tradePlan";
import { useEffect, useMemo, useState } from "react";
import { useFetchProducts } from "../../../../hooks/api/product";
import * as _ from "../../../../vendors/underscore-esm";
import { TradePlanSymbolType, SymbolType } from "./TradePlanType";
import { TradeLogProps } from "../../../../types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import mobile from "is-mobile";
import clsx from "clsx";
import Header from "./components/Header";
import Goals from "./components/Goals";
import RiskParameter from "./components/RiskParameter";
import TradingDecision from "./components/TradingDecision";
import MarketTrends from "./components/MarketTrends";
import RiskReward from "./components/RiskReward";
import UseStops from "./components/UseStops";
import Markets from "./components/Markets";
import SaveButton from "./components/SaveButton";
import "./TradePlan.css";

const TradePlan = ({ onHandleClose }: { onHandleClose: () => void }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm")) || mobile();
  const { myTradePlan, loadMyTradePlanAsync } = useFetchMyTradePlan();
  const { products, loadProducts } = useFetchProducts();
  const [tradePlanSymbolsState, setTradePlanSymbolsState] = useState<TradePlanSymbolType[]>();
  const { updateTradePlanAsync } = useUpdateTradePlan();
  const [approach, setApproach] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    loadMyTradePlanAsync();
    loadProducts();
  }, [loadMyTradePlanAsync, loadProducts]);

  const { control, handleSubmit, reset, setValue } = useForm<TradeLogProps>({
    defaultValues: {
      capitalRiskPerTrade: "",
      pctCapitalRiskPerTrade: "",
      pctCapitalToMargin: "",
      approach: "",
      trend: "",
      ratio: "",
      useStopLoss: "",
      tradePlanSymbols: [] as TradePlanSymbolType[],
      approachFundamentalAnalysis: [],
      approachTechnicalType: "",
      approachTechnicalChartType: "",
      approachTechnicalIndicator: [],
    },
  });

  const assestsWithSymbols = useMemo(() => {
    return (
      products?.reduce((acc: Record<string, SymbolType[]>, item) => {
        const assetClass = item.assetClass.name;
        const symbol = { symbol: item.cmeSymbol, symbolId: Number(item.id), name: item.name };

        if (!acc[assetClass]) acc[assetClass] = [];
        acc[assetClass].push(symbol);

        return acc;
      }, {}) || {}
    );
  }, [products]);

  useEffect(() => {
    if (myTradePlan) {
      reset({
        capitalRiskPerTrade: myTradePlan.capitalRiskPerTrade || "",
        pctCapitalRiskPerTrade: myTradePlan.pctCapitalRiskPerTrade || "",
        pctCapitalToMargin: myTradePlan.pctCapitalToMargin || "",
        approach: myTradePlan.approach || "",
        trend: myTradePlan.trend || "",
        ratio: myTradePlan.ratio || "",
        useStopLoss: myTradePlan.useStopLoss || "",
        tradePlanSymbols: myTradePlan.tradePlanSymbols || [],
        approachTechnicalType: myTradePlan.approachTechnicalType || "",
        approachFundamentalAnalysis: myTradePlan.approachFundamentalAnalysis
          ? JSON.parse(myTradePlan.approachFundamentalAnalysis.toString())
          : null,
        approachTechnicalChartType: myTradePlan.approachTechnicalChartType || "",
        approachTechnicalIndicator: myTradePlan.approachTechnicalIndicator
          ? JSON.parse(myTradePlan.approachTechnicalIndicator.toString())
          : null,
        approachTechnicalIndicatorOther: myTradePlan.approachTechnicalIndicatorOther || "",
      });
      setApproach(myTradePlan.approach);
      setType(myTradePlan.approachTechnicalType);
    }
  }, [myTradePlan, reset]);

  useEffect(() => {
    if (myTradePlan) {
      const symbols = myTradePlan.tradePlanSymbols || [];
      setTradePlanSymbolsState(symbols);
    }
  }, [myTradePlan]);

  const existingSymbols = useMemo(() => {
    const symbols: Record<number, TradePlanSymbolType> = {};
    if (myTradePlan) {
      myTradePlan.tradePlanSymbols.forEach((symbol: TradePlanSymbolType) => {
        symbols[symbol.cmeSymbolId] = symbol;
      });
    }
    return symbols;
  }, [myTradePlan]);

  const onSubmit = (data: any) => {
    const tradePlan = { ...myTradePlan };
    if (!_.isEmpty(data.capitalRiskPerTrade)) tradePlan.capitalRiskPerTrade = data.capitalRiskPerTrade;
    if (!_.isEmpty(data.capitalRiskPerTrade)) tradePlan.pctCapitalRiskPerTrade = data.pctCapitalRiskPerTrade;
    if (!_.isEmpty(data.pctCapitalToMargin)) tradePlan.pctCapitalToMargin = data.pctCapitalToMargin;
    if (!_.isEmpty(data.approach)) tradePlan.approach = data.approach;
    if (!_.isEmpty(data.trend)) tradePlan.trend = data.trend;
    if (!_.isEmpty(data.ratio)) tradePlan.ratio = data.ratio;
    if (!_.isEmpty(data.useStopLoss)) tradePlan.useStopLoss = data.useStopLoss;
    tradePlan.tradePlanSymbolsAttributes = {};
    for (var i = 0; i < data.tradePlanSymbols.length; i++) {
      tradePlan.tradePlanSymbolsAttributes[i] = data.tradePlanSymbols[i];
    }

    tradePlan.approachFundamentalAnalysis = JSON.stringify(data.approachFundamentalAnalysis);
    tradePlan.approachTechnicalType = data.approachTechnicalType;
    tradePlan.approachTechnicalChartType = data.approachTechnicalChartType;
    tradePlan.approachTechnicalIndicator = JSON.stringify(data.approachTechnicalIndicator);
    tradePlan.approachTechnicalIndicatorOther = data.approachTechnicalIndicatorOther;

    updateTradePlanAsync(tradePlan, () => loadMyTradePlanAsync());
  };

  const onSymbolUpdate = (checked: boolean, symbol: SymbolType) => {
    let updatedSymbols = [...(tradePlanSymbolsState as Array<any>)];
    const index = updatedSymbols.findIndex((cmeSymbol) => cmeSymbol.cmeSymbolId === symbol.symbolId);

    const newSymbolEntry = {
      cmeSymbolId: symbol.symbolId,
      cmeSymbol: { cmeSymbol: symbol.symbol, id: symbol.symbolId },
    };

    if (checked) {
      if (index === -1) {
        updatedSymbols.push(newSymbolEntry);
      } else {
        updatedSymbols[index] = newSymbolEntry;
      }
    } else {
      if (index !== -1) {
        updatedSymbols.splice(index, 1);
      }
      if (existingSymbols[symbol.symbolId]) {
        updatedSymbols.push({ ...newSymbolEntry, _destroy: true, id: (existingSymbols[symbol.symbolId] as TradePlanSymbolType).id });
      }
    }

    setValue("tradePlanSymbols", updatedSymbols);
    setTradePlanSymbolsState(updatedSymbols);
  };

  const isSymbolSelected = (symbolId: number) => {
    if (tradePlanSymbolsState) {
      return !!tradePlanSymbolsState.find((symbol) => symbol.cmeSymbolId === symbolId && !symbol._destroy);
    }
  };
  const handleApproachChange = (value: string) => {
    setApproach(value);

    if (value === "Fundamental") {
      setType("");
      setValue("approachTechnicalType", "");
      setValue("approachTechnicalChartType", "");
      setValue("approachTechnicalIndicator", []);
    } else if (value === "Technical") {
      setType(myTradePlan?.approachTechnicalType || "");
      setValue("approachTechnicalType", myTradePlan?.approachTechnicalType || "");
      setValue("approachTechnicalChartType", myTradePlan?.approachTechnicalChartType || "");
      setValue(
        "approachTechnicalIndicator", 
        JSON.parse(typeof myTradePlan?.approachTechnicalIndicator === 'string' ? myTradePlan.approachTechnicalIndicator : "[]")
      );      
    }
  };

  return (
    <div className={clsx({ "fluid-tradeplan": true, "fluid-tradeplan--mobile": isMobileView })}>
      <div className="filter-tradeplan">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={clsx({ "trade-plan-layout": true, "trade-plan-layout--mobile": isMobileView })}>
            <Header onHandleClose={onHandleClose} isMobileView={isMobileView} />
            <Goals isMobileView={isMobileView}/>
            <RiskParameter control={control} />
            <TradingDecision
              control={control}
              handleApproachChange={handleApproachChange}
              approach={approach}
              setType={setType}
              type={type}
            />
            <MarketTrends control={control} />
            <RiskReward control={control} />
            <UseStops control={control} />
            <Markets
              tradePlanSymbolsState={tradePlanSymbolsState}
              assestsWithSymbols={assestsWithSymbols}
              isSymbolSelected={isSymbolSelected}
              onSymbolUpdate={onSymbolUpdate}
              isMobileView={isMobileView}
            />
            <SaveButton isMobileView={isMobileView}/>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TradePlan;
