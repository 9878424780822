// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
  max-width: 180px;
  color: #000;
  background: #fff;
  border-right: 3px solid #f2f2f2
}
.switch-container {
  display: flex;
  position: fixed;
  bottom: 0;
  align-items: center;
  width: 180px;
  height: 70px;
  z-index: 1200;
}

.toggle-label {
  font-size: 12px;
  margin-left: 8px;
}

.switch-container.open {
  display: flex;
  align-items: center;
}

.switch-container.closed {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/home/components/SideMenu/sidebar.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB;AACF;AACA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":["nav {\n  max-width: 180px;\n  color: #000;\n  background: #fff;\n  border-right: 3px solid #f2f2f2\n}\n.switch-container {\n  display: flex;\n  position: fixed;\n  bottom: 0;\n  align-items: center;\n  width: 180px;\n  height: 70px;\n  z-index: 1200;\n}\n\n.toggle-label {\n  font-size: 12px;\n  margin-left: 8px;\n}\n\n.switch-container.open {\n  display: flex;\n  align-items: center;\n}\n\n.switch-container.closed {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
