export const FUNDAMENTAL_OPTIONS = [
  "Economic Reports",
  "Geopolitical Events",
  "Change in Supply",
  "Change in Demand",
];

export const TECHNICAL_OPTIONS = [
  "Indicator", 
  "Chart", 
  "Both"
];

export const INDICATOR_OPTIONS = [
  "Moving Average (MA)",
  "RSI",
  "Stochastic (STOCH)",
  "Fibonacci Retracements",
  "Other",
];

export const CHART_OPTIONS = [
  "Intra-day",
  "Daily",
  "Pattern",
];

export const MARKET_TREND_OPTIONS = [
  "Momentum", 
  "Contra Trend"
];

export const RISK_REWARD_RATIO_OPTIONS = [
  "1:1",
  "2:1",
  "3:1",
  "4:1",
  "5:1"
];