import { WidgetType } from "../../types";

const TreeViewContainerHeader = ({ widgetType }: { widgetType: WidgetType | undefined }) => {
  switch (widgetType) {
    case WidgetType.Watchlist:
      return <div>NEW WATCHLIST</div>;
    case WidgetType.Chart:
      return <div>NEW CHART</div>;
    case WidgetType.PriceLadder:
      return <div>NEW PRICE LADDER</div>;
    default:
      return <></>;
  }
};

export default TreeViewContainerHeader;
