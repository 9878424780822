import { InformationSubscriptions, SubscriptionService } from "./InformationSubscriptions";
import * as WebAPI from "../proto/webapi_2";
import { InstrumentGroupItem } from "../proto/metadata_2";

export class ChildSymbolsSubscription {
  public parentSymbolId: string;
  public requestId: string;
  public statusCode: number | null = null;
  public textMessage: string | undefined | null = null;
  public childSymbols: InstrumentGroupItem[] = [];

  private resolvePromise: Promise<any>;
  private resolveFunction!: (value?: any) => void;
  private rejectFunction!: (reason?: any) => void;

  constructor(parentSymbolId: string, requestId: string) {
    this.parentSymbolId = parentSymbolId;
    this.requestId = requestId;

    this.resolvePromise = new Promise<any>((resolve, reject) => {
      this.resolveFunction = resolve;
      this.rejectFunction = reject;
    });
  }

  public getPromise(): Promise<any> {
    return this.resolvePromise;
  }

  public update(informationReport: WebAPI.InformationReport): void {
    this.statusCode = informationReport.statusCode;
    this.textMessage = informationReport.textMessage;
    let childSymbols = informationReport.instrumentGroupReport?.instruments;

    if (childSymbols) {
      this.childSymbols = this.childSymbols ? this.childSymbols.concat(childSymbols) : childSymbols;
    }

    if (this.statusCode >= WebAPI.InformationReport_StatusCode.STATUS_CODE_FAILURE) {
      this.rejectFunction({ parentSymbolId: this.parentSymbolId, reason: this.textMessage });
    } else if (
      this.statusCode === WebAPI.InformationReport_StatusCode.STATUS_CODE_SUCCESS &&
      informationReport.isReportComplete
    ) {
      this.resolveFunction(this.childSymbols);
    }
  }
}

export class ChildSymbolsSubscriptions {
  private static instance: ChildSymbolsSubscriptions;
  private service!: SubscriptionService;

  static get Instance() {
    return ChildSymbolsSubscriptions.instance || new ChildSymbolsSubscriptions();
  }

  constructor() {
    if (!ChildSymbolsSubscriptions.instance) {
      const informationSubscriptions = new InformationSubscriptions();
      this.service = informationSubscriptions.SubscriptionsManager;
      this.service.createSubscription = (key: string, requestId: string) => {
        return new ChildSymbolsSubscription(key, requestId);
      };

      ChildSymbolsSubscriptions.instance = this;
    }
  }
  public get SubscriptionManager() {
    return this.service;
  }
}
