import { Grid, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { onlyNumbersRules } from "../utils";
import { IFormInput } from "../types";
import { Action } from "./types";

const inputStyles = {
  height: "36px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    backgroundColor: "transparent",
    "& input": {
      padding: "6.5px 12px",
      border: "none",
      textAlign: "center",
      fontSize: "15px",
    },
    "& fieldset": {
      border: "none",
    },
  },
};

interface LabelledFieldProps {
  name: any;
  label: string;
  value: any;
  dispatchType: any;
  control: Control<IFormInput, any>;
  dispatch: React.Dispatch<Action>;
  xs: number;
}

const LabelledField = ({ name, label, value, dispatchType, control, dispatch, xs }: LabelledFieldProps) => (
  <Grid item xs={xs}>
    <label className="label">{label}</label>
    <div className="control">
      <Controller
        name={name}
        control={control}
        rules={onlyNumbersRules}
        render={({ field }) => (
          <TextField
            {...field}
            value={value}
            onChange={(e) => dispatch({ type: dispatchType, payload: Number(e.target.value) })}
            fullWidth
            variant="outlined"
            sx={inputStyles}
          />
        )}
      />
    </div>
  </Grid>
);

export default LabelledField;
