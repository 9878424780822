import { useSelector } from "react-redux";
import { OrderState } from "../../../cqg-api/models/OrderState";
import { WidgetAction } from "../../../types";

const useAmendOrder = (externalData: any) => {
  const tradeTicketWidgetValue = useSelector((state: any) => state.chartWidget.tradeTicketValue);
  const widgetData = tradeTicketWidgetValue
    ? tradeTicketWidgetValue?.widgetData
    : externalData
      ? externalData?.widgetData
      : null;

  let showCancelButton = false;
  let showCancelOrderButton = false;
  let canChangeInstrument = true;

  let amendOrder: OrderState | undefined = undefined;

  if (externalData?.action === WidgetAction.AmendOrder) {
    amendOrder = widgetData?.data;
    showCancelButton = true;
    showCancelOrderButton = true;
    canChangeInstrument = false;
  }

  let orderData: Record<string, any> | undefined = undefined;

  if (externalData?.action === WidgetAction.CreateOrder) {
    orderData = widgetData?.data;
  }
  return { amendOrder, showCancelButton, showCancelOrderButton, canChangeInstrument, orderData, widgetData };
};

export default useAmendOrder;
