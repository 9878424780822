import { DataGrid, GridColDef, GridRowProps, GridSortModel, RowPropsOverrides } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUpdateWatchlist } from "../../../hooks/api/watchlist";
import DraggableGridRow from "./DraggableGridRow";
import { sortRows } from "./utils";
import { dataGridStyles } from "../../shared/DataGrid";
import { Instrument } from "../../../cqg-api/models/Instrument";

const WatchlistDataGrid = ({
  watchlistRows,
  columns,
  onRowDrop,
  watchlistId,
}: {
  watchlistRows: Instrument[];
  columns: GridColDef[];
  onRowDrop: (fromIndex: number, toIndex: number) => void;
  watchlistId: number | undefined;
}) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [rows, setRows] = useState(watchlistRows);
  const { updateWatchlistAsync } = useUpdateWatchlist();

  useEffect(() => {
    setRows(sortRows(watchlistRows, sortModel));
  }, [watchlistRows, sortModel]);

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    const sortedRows = sortRows(watchlistRows, model);
    const watchlistNewOrder = sortedRows.map((row: Instrument) => row.displayName).join(",");
    if (watchlistId) {
      updateWatchlistAsync(watchlistId, watchlistNewOrder);
    }
  };

  const funcDraggableGridRow = useCallback(
    (props: GridRowProps & RowPropsOverrides) => {
      return DraggableGridRow({ props, onRowDrop });
    },
    [onRowDrop],
  );

  const slots = useMemo(() => {
    return {
      row: (props: GridRowProps & RowPropsOverrides) => funcDraggableGridRow(props),
    };
  }, [funcDraggableGridRow]);

  return (
    <div style={{ position: "absolute", top: 0, bottom: 0, width: "100%" }}>
      <DataGrid
        rowHeight={42}
        rows={rows}
        columns={columns}
        onSortModelChange={handleSortModelChange}
        sortModel={sortModel}
        slots={slots}
        sx={dataGridStyles}
        disableRowSelectionOnClick
        disableColumnMenu
        hideFooterPagination
      />
    </div>
  );
};

export default WatchlistDataGrid;
